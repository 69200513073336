import { ValueProvider } from "@angular/core";

import {
    LG_COL_DEFINITION_TYPE_CONFIGURATION,
    TableTypeConfiguration
} from "./column-type-configuration";

export const DEFAULT_COLUMN_TYPE_CONFIGURATION: ValueProvider[] = [
    {
        provide: LG_COL_DEFINITION_TYPE_CONFIGURATION,

        useValue: <TableTypeConfiguration>{
            name: "default",
            defaultRowWidth: 868,
            defaultPadding: 12,
            defaultEndsPadding: 16,
            defaultColumnClass: "table__column",

            columns: {
                standard: {
                    classNames: []
                },

                icons: {
                    paddingLeft: 0,
                    paddingRight: 0,
                    classNames: ["table__column--icons"],
                    defaultWidth: 28
                },

                headerLevels: {
                    paddingLeft: 0,
                    paddingLeftOfFirst: 0,
                    classNames: ["table__column--header-levels"]
                },

                expand: {
                    paddingLeft: 0,
                    paddingLeftOfFirst: 8,
                    paddingRight: -12,
                    defaultWidth: 24,
                    classNames: ["table__column--expand"]
                },

                empty: {
                    paddingLeft: 0,
                    paddingRight: 0,
                    paddingLeftOfFirst: 4,
                    defaultWidth: 24,
                    classNames: ["table__column--empty"]
                },

                hidden: {
                    paddingLeft: 0,
                    paddingRight: 0,
                    defaultWidth: 0,
                    classNames: ["hidden"],
                    isHidden: true
                }
            }
        },

        multi: true
    },

    {
        provide: LG_COL_DEFINITION_TYPE_CONFIGURATION,

        useValue: <TableTypeConfiguration>{
            name: "miniTable",
            defaultPadding: 4,
            inherit: "default",

            columns: {}
        },

        multi: true
    }
];
