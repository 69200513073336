/**
 * Sorting function used by some of the sorters (accepting scope). The function must return a number representing the order of the item
 */
// export interface IScopedSortPredicateFunction {
//     ( item: any, scope: ng.IScope ): number
// }

// export type IScopedSortPredicate = IScopedSortPredicateFunction | string;

export type IScopedSortPredicateFunction = (item: any) => number;

export type IScopedSortPredicate = IScopedSortPredicateFunction | string;
