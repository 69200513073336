import { Nullable } from "@logex/framework/types";
import {
    ILgFormatterOptions,
    IParseResult,
    LgFormattingSymbolMetadata,
    ILgFormatter
} from "./formatter-types";
import { parse } from "./number-helpers/parse";
import { format } from "./number-helpers/format";
import { formatForEditing } from "./number-helpers/formatForEditing";

const defaultOptions: ILgFormatterOptions = {
    decimals: 0,
    viewScale: 0,
    forceSign: false,
    forceFormat: true,
    hideScaleSymbol: false
};

const HAIR_SPACE_SYMBOL = "&hairsp;";
const THOUSANDS_SYMBOL = "k";
const MILLIONS_SYMBOL = "M";
const TRAILING_REGEX = /[kM]/;

export class MoneyScaledFormatter implements ILgFormatter<number> {
    names = ["moneyScaled"];

    _scaleSymbol?: string;

    constructor(
        public options: ILgFormatterOptions,
        private _symbol: LgFormattingSymbolMetadata,
        private _locale: string
    ) {
        this.options = { ...defaultOptions, ...this.options };
    }

    parse(val: string, options?: ILgFormatterOptions): IParseResult<number> {
        val = val.replace(this._symbol.removeRegex, "");
        val = val.replace(TRAILING_REGEX, "");
        const { decimals, viewScale } = { ...this.options, ...options };
        return parse(val, decimals, viewScale);
    }

    format(val: Nullable<number>, options?: ILgFormatterOptions): string {
        const result = format(val, this._locale, { ...this.options, ...options });

        return this._decorateWithSymbol(result, this._symbol, result === "−");
    }

    formatForEditing(val: Nullable<number>, options?: ILgFormatterOptions): string {
        if (val == null || "" + val === "") {
            return "";
        }

        return formatForEditing(val, this._locale, { ...this.options, ...options });
    }

    private _decorateWithSymbol(
        val: string,
        formattingSymbol: LgFormattingSymbolMetadata,
        isDash: boolean
    ): string {
        this._scaleSymbol = this._getScaleSymbol();

        return isDash
            ? val
            : formattingSymbol.position === "before"
            ? formattingSymbol.symbol + formattingSymbol.separator + val + this._scaleSymbol
            : val + this._scaleSymbol + formattingSymbol.separator + formattingSymbol.symbol;
    }

    private _getScaleSymbol(): string {
        if (this.options.hideScaleSymbol) return "";

        if (this.options.viewScale === -3) {
            return `${HAIR_SPACE_SYMBOL}${THOUSANDS_SYMBOL}`;
        }
        if (this.options.viewScale === -6) {
            return `${HAIR_SPACE_SYMBOL}${MILLIONS_SYMBOL}`;
        }
        return "";
    }
}
