@if (_isEditing) {
    <lg-grid-input
        [defaultFocus]="true"
        [(model)]="_value"
        type="text"
        [effective]="_value"
        [invalid]="!_value"
        [placeholder]="placeholder"
        [markFocusOn]="_markFocusOn"
        modelUpdateOn="change"
        (lgFocus)="_onFocus()"
        (lgBlur)="_onBlur()"
        (lgPressEnter)="_onPressEnter()"
    />
} @else {
    <div class="flex flexrow flexrow--center">
        <div class="lg-editable-grid-text__value">{{ _value }}</div>
        <div *ngIf="!_disabled">
            <lg-icon
                icon="icon-edit"
                class="table__row__hover-only"
                [clickable]="true"
                (click)="_startEditing($event)"
            />
        </div>
        <ng-content></ng-content>
    </div>
}
