import {
    Directive,
    Input,
    TemplateRef,
    OnDestroy,
    OnChanges,
    Injector,
    inject
} from "@angular/core";
import { toBoolean } from "@logex/framework/utilities";
import { LgPlaceholderStorageService } from "./lg-placeholder-storage.service";

/**
 * Defines a content that can be rendered by the specified placeholder(s). The content
 * will be linked using the context specified by the placeholder
 *
 * Usage
 * <h1 *lgPlaceholderContent="'app.welcome'">
 *   Hello world
 * </h1>
 *
 * If you want to inject some variables (including implicit) from the placeholder's context, use
 * <h1 *lgPlaceholderContent="let impl using 'app.welcome'; let color=color"
 *   <span [style.color]="color">Hello world</span>
 * </h1>
 *
 * When the content is destroyed, it will be removed also from the placeholders - because the storage implements
 * a stack, it will be either replaced by the previous content, or by placeholder's default.
 *
 * The content storage is global for the whole app.
 *
 * The content is rendered with the injector of the placeholder (technically, it would be element injector of the definition
 * point, but module injector of the placeholder). You might want to use the local injector instead, especially
 * when projecting content across the lazy-loaded module boundary
 * <h1 *lgPlaceholderContent="'app.welcome'; localInjector: true">
 *   <component-dependent-on-local-services></component-dependent-on-local-services>
 * </h1>
 */
@Directive({
    selector: "[lgPlaceholderContent]"
})
export class LgPlaceholderContentDirective implements OnChanges, OnDestroy {
    private _injector = inject(Injector);
    private _storage = inject(LgPlaceholderStorageService);
    private _templateRef = inject(TemplateRef<any>);
    // Angular currently won't let us write something like *lgPlaceholderContent="using 'app.welcome'", the using keyword
    // is parsed only when the implicit let is present. That's why we need to have 2 different name properties - one for the
    // full syntax, one for name only
    // TODO: figure out better way?
    @Input("lgPlaceholderContent") public name = "";
    @Input("lgPlaceholderContentUsing") public name2 = "";
    @Input({ alias: "lgPlaceholderContentLocalInjector", transform: toBoolean }) localInjector =
        false;

    private _remover: (() => void) | null = null;

    public ngOnChanges(): void {
        if (this._remover) {
            this._remover();
            this._remover = null;
        }

        const name = this.name2 || this.name;

        if (name) {
            this._remover = this._storage.addContent(
                name,
                this._templateRef,
                this.localInjector ? this._injector : undefined
            );
        }
    }

    public ngOnDestroy(): void {
        if (this._remover) this._remover();
    }
}
