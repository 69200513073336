const NUMBER_OF_PIXELS_THAT_POINTS_NEED_TO_OVERLAP_TO_BE_CONSIDERED_ONE = 12;

export function doCirclePointsOverlap(
    mousecoords: [number, number],
    coordinates: [number, number],
    overlap = NUMBER_OF_PIXELS_THAT_POINTS_NEED_TO_OVERLAP_TO_BE_CONSIDERED_ONE
): boolean {
    const xDist = coordinates[0] - mousecoords[0];
    const yDist = coordinates[1] - mousecoords[1];
    const distFromCircleCenter = Math.sqrt(Math.pow(xDist, 2) + Math.pow(yDist, 2)); // Pythagoras stuff
    return distFromCircleCenter <= overlap;
}
