import { Directive, Input, OnChanges, SimpleChanges } from "@angular/core";
import {
    AbstractControl,
    NG_VALIDATORS,
    ValidationErrors,
    Validator,
    ValidatorFn
} from "@angular/forms";

const validateMaxFactory = (max: string | number | null | undefined): ValidatorFn => {
    return (control: AbstractControl) => {
        const isValid = max == null || control.value == null || control.value <= max;

        return isValid ? null : { max: { valid: false } };
    };
};

@Directive({
    standalone: true,
    selector: "[max][ngModel]",
    providers: [{ provide: NG_VALIDATORS, useExisting: MaxValidator, multi: true }]
})
export class MaxValidator implements Validator, OnChanges {
    validator!: ValidatorFn;
    private _onChanges!: () => void;

    /**
     * Maximum allowable value (required).
     */
    @Input({ required: true }) max!: string | number | null | undefined;

    constructor() {
        this.validator = validateMaxFactory(this.max);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ("max" in changes) {
            this.validator = validateMaxFactory(this.max);
            if (this._onChanges) {
                this._onChanges();
            }
        }
    }

    validate(c: AbstractControl): ValidationErrors | null {
        return this.validator(c);
    }

    public registerOnValidatorChange(fn: () => void): void {
        this._onChanges = fn;
    }
}
