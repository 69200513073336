import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    TemplateRef,
    inject
} from "@angular/core";
import { Router } from "@angular/router";
import { useTranslationNamespace } from "@logex/framework/lg-localization";
import { IChatMessage } from "../lg-chat/lg-chat.types";
import { Subject, takeUntil } from "rxjs";
import { LgChatStateService } from "../lg-chat/lg-chat-state.service";

@Component({
    selector: "lg-chat-window",
    templateUrl: "./lg-chat-window.component.html",
    providers: [useTranslationNamespace("FW._Chat")]
})
export class LgChatWindowComponent implements OnInit, OnDestroy {
    @Input({ required: true }) chatTitle!: string;
    @Input({ required: true }) introductionTemplate!: TemplateRef<any>;
    @Output() readonly closed = new EventEmitter();
    @Output() readonly sendMessage = new EventEmitter<string>();
    @Output() readonly prepareMail = new EventEmitter();

    _newMessage = "";
    _loadingResponse?: boolean;

    private _destroyed$ = new Subject<void>();
    private _router: Router = inject(Router);
    protected _chatState = inject(LgChatStateService);

    ngOnInit(): void {
        this._chatState.onLoadingResponseChange$
            .pipe(takeUntil(this._destroyed$))
            .subscribe(isLoadingResponse => (this._loadingResponse = isLoadingResponse));
    }

    ngOnDestroy(): void {
        this._destroyed$.next();
        this._destroyed$.complete();
    }

    _close(): void {
        this.closed.emit();
    }

    _prepareMail(): void {
        this.prepareMail.emit();
    }

    _sendMessage(): void {
        if (!this._loadingResponse && this._newMessage.trim() !== "") {
            this.sendMessage.emit(this._newMessage);
            this._newMessage = "";
        }
    }

    _resetChat(): void {
        this._chatState.resetChat();
    }

    _onButtonClick(toolUrl: string): void {
        this._router.navigate([toolUrl]);
    }

    get _reversedMessages(): IChatMessage[] {
        return this._chatState.messages?.slice().reverse() ?? [];
    }
}
