import { InjectionToken } from "@angular/core";

export const LG_LAST_FILTER_STORAGE = new InjectionToken<ILgLastFilterStorage>(
    "LG_LAST_FILTER_STORAGE"
);

export interface ILgLastFilterStorage {
    get(page: string): Record<string, string>;
    store(page: string, state: Record<string, string>): void;
}
