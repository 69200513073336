import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    inject,
    Input,
    OnInit
} from "@angular/core";
import { LgConsole } from "@logex/framework/core";

import {
    IDataCell,
    IPasteButtonColumnInfo,
    IPreprocessDataCallback,
    LG_PASTE_DIALOG_CONFIGURATION,
    PasteDialogConfiguration,
    UnmatchedHandlingType
} from "./copy-paste.types";
import { LgPasteDialogFactory } from "./lg-paste-dialog.factory";
import { toBoolean } from "@logex/framework/utilities";

@Component({
    selector: "lg-paste-button",
    // eslint-disable-next-line @angular-eslint/component-max-inline-declarations
    template: `
        <lg-button
            icon="icon-paste"
            [buttonClass]="buttonClass"
            titleLc="FW._Directives.PasteButton_Tooltip"
            (click)="showDialog()"
            *ngIf="!asIcon"
        ></lg-button>

        <lg-icon
            icon="icon-paste"
            [class]="iconClass"
            (click)="showDialog()"
            [lgTooltip]="'FW._Directives.PasteButton_Tooltip' | lgTranslate"
            [clickable]="true"
            *ngIf="asIcon"
        ></lg-icon>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LgPasteButtonComponent implements OnInit {
    protected console = inject(LgConsole).withSource("Logex.Directives.PasteButtonController");
    private _configurationDefaults = inject(LG_PASTE_DIALOG_CONFIGURATION, { optional: true });
    private _changeDetectorRef = inject(ChangeDetectorRef);
    private _dialogFactory = inject(LgPasteDialogFactory);

    // ----------------------------------------------------------------------------------
    @Input() onGetData?: () => any[];

    @Input() onPutData?: (args: {
        values: any[];
        updatedValues: any[];
        newValues: any[];
        deletedKeys: object[];
    }) => void;

    @Input() preprocessData?: (args: {
        columns: IPasteButtonColumnInfo[];
        data: IDataCell[][];
    }) => IDataCell[][] | void;

    @Input("configuration") configurationOverride?: PasteDialogConfiguration;

    @Input() title?: string;

    @Input() icon?: string;

    @Input() unmatched?: UnmatchedHandlingType;

    @Input() customDialog?: LgPasteDialogFactory;

    @Input() extraInformation?: string;

    @Input() context: any = null;

    @Input() set columnsDefinitions(value: IPasteButtonColumnInfo[]) {
        this._columns = value;
    }

    get columnsDefinitions(): IPasteButtonColumnInfo[] {
        return this._columns;
    }

    @Input() columnsDefinitionsRef?: HTMLElement;

    @Input() set buttonClass(value: string) {
        this._buttonClass = value;
    }

    get buttonClass(): string {
        if (this._buttonClass != null) return this._buttonClass;
        return "button--condensed disable-overlay-focus";
    }

    @Input() asIcon = false;

    @Input() iconClass?: string;

    // ----------------------------------------------------------------------------------
    private _buttonClass: string | null = null;

    _columns: IPasteButtonColumnInfo[] = [];

    // ----------------------------------------------------------------------------------

    ngOnInit(): void {
        if ((!this._columns || !this._columns.length) && this.columnsDefinitionsRef) {
            const columnElements = Array.from(this.columnsDefinitionsRef.children);

            this._columns = columnElements.map(x => ({
                field: x.getAttribute("field")!,
                name: x.getAttribute("name")!,
                type: x.getAttribute("type")!,
                format: x.getAttribute("format")!,
                key: toBoolean(x.getAttribute("key"), false),
                optional: x.getAttribute("optional")!
            }));
        }
    }

    showDialog(): void {
        const dialog = this.customDialog || this._dialogFactory;

        const preprocessData: IPreprocessDataCallback = (
            columns: IPasteButtonColumnInfo[],
            data: IDataCell[][]
        ) => {
            if (this.preprocessData) {
                const result = this.preprocessData({ columns, data });
                return result === undefined ? data : (result as any);
            }

            return data;
        };

        let originalData = null;

        if (this.onGetData) {
            originalData = this.onGetData();
        }

        // Combine configuration defaults and overrides
        const configuration = Object.assign(
            {
                allowNullsToZeros: false,
                nullsToZeros: false,
                allowSkipErrors: false,
                skipErrors: false,
                allowPasteMode: false,
                pasteMode: "update"
            } as PasteDialogConfiguration,
            this._configurationDefaults,
            this.configurationOverride
        );

        dialog
            .show(
                this._columns,
                originalData,
                this.title,
                preprocessData,
                this.unmatched,
                this.extraInformation,
                configuration
            )
            .subscribe(data => {
                if (data && this.onPutData) {
                    this.onPutData({
                        values: data.updatedData,
                        updatedValues: data.updatedData,
                        newValues: data.newData,
                        deletedKeys: data.deletedData
                    });
                    this._changeDetectorRef.markForCheck();
                }
            });
    }
}
