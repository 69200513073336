import { IStringLookup } from "@logex/framework/types";

const internalDictionary: IStringLookup<RegExp> = {
    DOT: /\./g
};

export const getRegex = (value: string): RegExp => {
    if (!internalDictionary[value]) {
        throw new Error(`No RegEx for '${value}'`);
    }

    return internalDictionary[value];
};
