import { LogexPivotService } from "./lg-pivot.service";
import type {
    INormalizedLogexPivotDefinition,
    ILogexPivotDefinition,
    ILogexPivotDefinitionOptions
} from "./lg-pivot.types";
import { inject } from "@angular/core";

export type {
    ICalculatorFunction,
    ICalculatorCallback,
    ICalculatorCallbackDefinition,
    ICalculationBlock
} from "./lg-pivot-calculator.types";
export { LogexPivotService } from "./lg-pivot.service";

export abstract class PivotProviderBase {
    protected _pivotService = inject(LogexPivotService);

    public get definition(): INormalizedLogexPivotDefinition {
        if (this._definition === undefined) {
            this._definition = this._pivotService.prepareDefinition(
                this.getPivotDefinition(),
                this.getPivotOptions() ?? undefined
            );
        }
        return this._definition;
    }

    private _definition?: INormalizedLogexPivotDefinition;

    // ----------------------------------------------------------------------------------
    getLevelIndex(levelId: string): number {
        return this.definition.$levels[levelId].$levelIndex;
    }

    // ----------------------------------------------------------------------------------
    protected abstract getPivotDefinition(): ILogexPivotDefinition;

    // ----------------------------------------------------------------------------------
    protected getPivotOptions(): ILogexPivotDefinitionOptions | null {
        return null;
    }
}
