<div [ngSwitch]="_state" class="paste-dialog__content">
    <div class="form-group paste-dialog__first-screen" *ngSwitchCase="0">
        <input type="text" class="paste-dialog__paste-target" lgCaptureFocus />
        <div class="paste-dialog__first-screen-logo"></div>
        <div
            class="paste-dialog__first-screen-text"
            [innerHTML]="'FW._Directives.PasteButton_Dialog_PasteTsv' | lgTranslate"
        ></div>
        <div class="paste-dialog__first-screen-text2">
            {{ "FW._Directives.PasteButton_Dialog_SelectDataInExcel" | lgTranslate }}
        </div>
        <div
            *ngIf="_extraInformation"
            class="paste-dialog__first-screen-extra"
            [innerHtml]="_extraInformation"
        ></div>
    </div>

    <div class="form-group form-group--paste-dialog" *ngSwitchCase="1">
        <div class="form-row">
            <h3>{{ "FW._Directives.PasteButton_Dialog_SelectColumns" | lgTranslate }}</h3>
        </div>
        <div class="form-row form-row--paste-dialog-flexible form-row--table">
            <div class="form-row form-row__controls">
                <table class="paste-dialog-preview">
                    <colgroup>
                        <col
                            *ngFor="let header of _preview[0]"
                            [style.width.%]="100 / _preview[0].length"
                        />
                    </colgroup>
                    <tr>
                        <th *ngFor="let header of _preview[0]; let index = index">
                            <lg-reference-dropdown
                                [definition]="_columnsSelectorDropdown"
                                [(current)]="_mapping[index]"
                                [matchWidth]="_preview[0].length < 6"
                                (currentChange)="_onColumnMappingChanged(index)"
                            ></lg-reference-dropdown>
                        </th>
                    </tr>
                    <tr *ngFor="let row of _preview">
                        <td
                            *ngFor="let cell of row; let index = index"
                            class="paste-dialog-preview__column"
                            [ngClass]="{
                                'paste-dialog-preview__column_selected': _mapping[index] !== null
                            }"
                        >
                            {{ cell }}
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="form-row">
            <div class="form-row__controls form-row__controls--3col">
                <label class="lg-styled-radio-container">
                    <input
                        type="checkbox"
                        lgStyledCheckbox
                        [(ngModel)]="_firstRowIsHeader"
                        (change)="_onFirstRowIsHeaderChanged()"
                    />
                    {{ "FW._Directives.PasteButton_Dialog_FirstRowNames" | lgTranslate }}
                </label>

                <label *ngIf="_configuration.allowNullsToZeros" class="lg-styled-radio-container">
                    <input type="checkbox" lgStyledCheckbox [(ngModel)]="_nullsToZeros" />
                    {{ "FW._Directives.PasteButton_Dialog_NullsToZeros" | lgTranslate }}
                </label>
            </div>
            <div
                class="form-row__controls form-row__controls--3col form-row__controls--right-align-text"
            >
                {{
                    "FW._Directives.PasteButton_Dialog_RowsNo"
                        | lgTranslate
                            : {
                                  previewRows: _preview.length,
                                  dataRows: _pastedData.length - (_firstRowIsHeader ? 1 : 0)
                              }
                }}
            </div>
        </div>
    </div>

    <div class="form-group form-group--paste-dialog" *ngSwitchCase="2">
        <lg-col-definition
            #pasteDialogPreviewCols
            [source]="_tableColDefinition"
        ></lg-col-definition>
        <div class="form-row form-row--items-bottom">
            <div *ngIf="_configuration.allowPasteMode" class="form-row__controls">
                <h3>{{ "FW._Directives.PasteButton_Dialog_PasteOptions" | lgTranslate }}</h3>

                <label class="lg-styled-radio-container">
                    <input
                        type="radio"
                        lgStyledRadio
                        [(ngModel)]="_pasteMode"
                        name="pasteOptions"
                        value="update"
                    />
                    {{ "FW._Directives.PasteButton_Dialog_PasteOptions_Update" | lgTranslate }}
                </label>
                <label class="lg-styled-radio-container">
                    <input
                        type="radio"
                        lgStyledRadio
                        [(ngModel)]="_pasteMode"
                        name="pasteOptions"
                        value="replace"
                    />
                    {{ "FW._Directives.PasteButton_Dialog_PasteOptions_Replace" | lgTranslate }}
                </label>
            </div>

            <div class="form-row__controls form-row__controls--right-align">
                <lg-button
                    icon="icon-copy"
                    buttonClass="button--condensed"
                    textLc="FW._Directives.CopyButton_Tooltip"
                    (click)="_copyReviewValues()"
                ></lg-button>
            </div>
        </div>
        <div class="form-row form-row--table form-row--paste-dialog-flexible">
            <div
                class="form-row__controls flexcol flex-flexible"
                *ngIf="_tableColDefinition"
                [lgColDefinition]="pasteDialogPreviewCols"
                [lgColDefinitionWidth]="1008"
            >
                <div class="table__row table__row--header" lgColRow="header">
                    <ng-container *ngFor="let column of _columns">
                        <div
                            lgCol="col-{{ column.field }}"
                            *ngIf="!_hasOriginalData || column.key; else twoColumnsHeader"
                        >
                            <span [lgTooltip]="column.name" lgTooltipPosition="top-left">
                                {{ column.name }}
                            </span>
                        </div>
                        <ng-template #twoColumnsHeader>
                            <div lgCol="col-{{ column.field }}-org">
                                <span
                                    lgTooltip="{{
                                        column.name +
                                            ' ' +
                                            ('FW._Directives.PasteButton_Dialog_Org' | lgTranslate)
                                    }}"
                                    lgTooltipPosition="top-left"
                                >
                                    {{ column.name }}
                                    {{ "FW._Directives.PasteButton_Dialog_Org" | lgTranslate }}
                                </span>
                            </div>
                            <div lgCol="col-{{ column.field }}">
                                <span [lgTooltip]="column.name" lgTooltipPosition="top-left">
                                    {{ column.name }}
                                </span>
                            </div>
                        </ng-template>
                    </ng-container>
                    <div lgCol="error">
                        <lg-icon
                            *ngIf="_hasInvalidData"
                            icon="icon-warning"
                            [clickable]="true"
                            (click)="_showOnlyInvalid = !_showOnlyInvalid; _refilter()"
                            lgSimpleTooltip="FW._Directives.PasteButton_Dialog_ContainsErrors"
                        ></lg-icon>
                    </div>
                </div>

                <div class="flex-flexible flexcol">
                    <div lgScrollable [lgScrollableWrapper]="false" lgColRow="data">
                        <div
                            *lgVirtualFor="let row of _filteredData; height: 29"
                            class="table__row"
                        >
                            <ng-container *ngFor="let col of _columns; let i = index">
                                <div
                                    lgCol="col-{{ col.field }}"
                                    *ngIf="!_hasOriginalData || col.key; else twoColumnsBody"
                                    [ngClass]="{ 'paste-dialog-data__cell_error': !row[i].valid }"
                                >
                                    {{ row[i].value | fmtType: col.format || _formatter }}
                                </div>
                                <ng-template #twoColumnsBody>
                                    <div lgCol="col-{{ col.field }}">
                                        {{
                                            row[i].originalValue | fmtType: col.format || _formatter
                                        }}
                                    </div>
                                    <div
                                        lgCol="col-{{ col.field }}"
                                        [ngClass]="{
                                            'paste-dialog-data__cell_error': !row[i].valid
                                        }"
                                    >
                                        <span *ngIf="row[i].valid">
                                            {{ row[i].value | fmtType: col.format || _formatter }}
                                        </span>
                                        <span *ngIf="!row[i].valid">
                                            {{ row[i].rawValue }}
                                        </span>
                                    </div>
                                </ng-template>
                            </ng-container>
                            <div lgCol="error">
                                <lg-icon
                                    *ngIf="!_isValidRow(row)"
                                    icon="icon-warning"
                                    [lgTooltip]="_bindErrorMessage(row)"
                                ></lg-icon>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-row" *ngIf="_hasInvalidData">
            <div class="form-row__controls">
                <label class="lg-styled-radio-container">
                    <input
                        type="checkbox"
                        lgStyledCheckbox
                        [(ngModel)]="_showOnlyInvalid"
                        (change)="_refilter()"
                    />
                    {{ "FW._Directives.PasteButton_Dialog_ShowOnlyErrornous" | lgTranslate }}
                </label>

                <label *ngIf="_configuration.allowSkipErrors" class="lg-styled-radio-container">
                    <input type="checkbox" lgStyledCheckbox [(ngModel)]="_skipErrors" />
                    {{ "FW._Directives.PasteButton_Dialog_SkipErrors" | lgTranslate }}
                </label>
            </div>
        </div>
    </div>

    <div
        class="form-group form-group--buttons"
        [class.form-group--blocked]="_isWorking"
        #pasteDialogButtonRow
    >
        <div class="form-row">
            <lg-button
                class="form-row--button-left"
                *ngIf="_state > 0"
                (click)="_back()"
                textLc="FW._Directives.PasteButton_Dialog_PrevButton"
            ></lg-button>

            <lg-button
                *ngIf="_state === 1"
                (click)="_onPrepareData()"
                buttonClass="button--primary"
                textLc="FW._Directives.PasteButton_Dialog_NextButton"
                [isDisabled]="!_isMappingComplete()"
            ></lg-button>

            <lg-button
                *ngIf="_state === 2"
                (click)="_onApply()"
                buttonClass="button--primary"
                textLc="FW._Directives.PasteButton_Dialog_ApplyButton"
                [isDisabled]="!_skipErrors && _hasInvalidData"
            ></lg-button>

            <lg-button
                (click)="_close()"
                [textLc]="
                    _state === 3
                        ? 'FW._Directives.PasteButton_Dialog_CloseButton'
                        : 'FW._Directives.PasteButton_Dialog_CancelButton'
                "
            ></lg-button>
        </div>
    </div>
</div>
