import { Directive, HostBinding } from "@angular/core";

import type { IOrderBySpecification } from "@logex/framework/lg-pivot";
import type { LgPivotTableComponent } from "../lg-pivot-table.component";

@Directive({
    selector: "lg-pivot-table-header,lg-pivot-table-footer"
})
export class LgPivotTableHeaderDirective {
    public static _lastInstance: LgPivotTableHeaderDirective | null = null;
    public static _lastInstanceCount = 0;

    public get table(): LgPivotTableComponent {
        return this._table;
    }

    public set table(value: LgPivotTableComponent) {
        this._table = value;
    }

    // eslint-disable-next-line accessor-pairs
    public set header(value: boolean) {
        // todo: remove once CSS is modified
        this._className = value
            ? "table__row table__row--header lg-pivot-table__header"
            : "table__row table__row--footer lg-pivot-table__footer";
    }

    @HostBinding("class")
    public _className = "";

    private _table!: LgPivotTableComponent;

    constructor() {
        LgPivotTableHeaderDirective._lastInstance = this;
        LgPivotTableHeaderDirective._lastInstanceCount += 1;
    }

    public getAllExpanded(level: number): boolean {
        return this._table._body.getAllExpanded(level);
    }

    public toggleLevel(level: number, state?: boolean): void {
        return this._table._body.toggleLevel(level, state);
    }

    public get maxVisibleLevel(): number {
        return this._table._body.maxVisibleLevel;
    }

    public getOrderBy(level: number | string): IOrderBySpecification | null {
        return this._table._getOrderBy(level);
    }

    public setOrderBy(level: number | string, sortBy: string | string[]): void {
        this._table._setOrderBy(level, sortBy);
    }
}
