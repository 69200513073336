import { Injectable, ViewContainerRef, inject } from "@angular/core";
import { Subject, map, race } from "rxjs";

import { IConfirmDialogOptions } from "./lg-confirmation-dialog.types";
import { LgPromptDialog } from "./lg-prompt-dialog/lg-prompt-dialog.component";
import { LgPromptDialogButton } from "./lg-prompt-dialog/lg-prompt-dialog.types";

/**
 * @deprecated use LgPromptDialog instead
 */
@Injectable({ providedIn: "root" })
export class LgConfirmationDialog {
    private _promptDialog = inject(LgPromptDialog);

    private static _buttonCodesLookup = {
        ok: 1,
        cancel: 2
    };

    bindViewContainerRef(viewRef?: ViewContainerRef): LgConfirmationDialog {
        const boundDialog = new LgConfirmationDialog();
        boundDialog._promptDialog = this._promptDialog.bindViewContainerRef(viewRef);
        return boundDialog;
    }

    /**
     * @deprecated use LgPromptDialog.alert instead
     *
     * Shows an information dialog with the specified message. The dialog contains only OK button (though it is possible to override it)
     *
     * @param title is the title of the window
     * @param message is the message to show
     * @param options specifies additional options (when necessary), such as the button texts
     * @returns promise that resolves with the index of the button pressed (so normally 1).
     */
    public alert(title: string, message: string, options?: IConfirmDialogOptions): Promise<number> {
        this._updateButtonsConfiguration(options);

        const dialogResult = this._promptDialog.alert(title, message, options);
        return this._convertDialogResult(dialogResult);
    }

    /**
     * @deprecated use LgPromptDialog.alertLc instead
     */
    public alertLC(
        titleLC: string,
        messageLC: string,
        localizationContext?: any,
        options?: IConfirmDialogOptions
    ): Promise<number> {
        this._updateButtonsConfiguration(options);
        this._updateLocalizationContext(options, localizationContext);

        const dialogResult = this._promptDialog.confirmLc(titleLC, messageLC, options);
        return this._convertDialogResult(dialogResult);
    }

    /**
     * @deprecated use LgPromptDialog.confirm instead
     *
     * Shows a confirmation dialog with the specified big icon and message. The dialog can contain up to three buttons (in which case
     * they're grouped as 2 + 1). By default, a warning-type dialog with OK/cancel buttons is shown
     *
     * @param title is the title of the window
     * @param message is the message to show
     * @param options specifies additional options (when necessary), such as the button texts
     * @returns promise that resolves with the index of the button pressed (1, 2 or 3). Closed dialog (where allowed) will resolve as button 2.
     *      if button2AsReject is specified in the options, the button 2 will result in reject instead.
     */
    public confirm(
        title: string,
        message: string,
        options: IConfirmDialogOptions = {}
    ): Promise<number> {
        this._updateButtonsConfiguration(options);

        const dialogResult = this._promptDialog.confirm(title, message, options);
        return this._convertDialogResult(dialogResult);
    }

    /**
     * @deprecated use LgPromptDialog.confirmLc instead
     */
    public confirmLC(
        titleLC: string,
        messageLC: string,
        localizationContext?: any,
        options?: IConfirmDialogOptions
    ): Promise<number> {
        this._updateButtonsConfiguration(options);
        this._updateLocalizationContext(options, localizationContext);

        const dialogResult = this._promptDialog.confirmLc(titleLC, messageLC, options);
        return this._convertDialogResult(dialogResult);
    }

    /**
     * @deprecated use LgPromptDialog.warning instead
     */
    public warning(
        title: string,
        message: string,
        options?: IConfirmDialogOptions
    ): Promise<number> {
        this._updateButtonsConfiguration(options);

        const dialogResult = this._promptDialog.warning(title, message, options);
        return this._convertDialogResult(dialogResult);
    }

    /**
     * @deprecated use LgPromptDialog.warningLc instead
     */
    public warningLc(
        titleLC: string,
        messageLC: string,
        localizationContext?: any,
        options?: IConfirmDialogOptions
    ): Promise<number> {
        this._updateButtonsConfiguration(options);
        this._updateLocalizationContext(options, localizationContext);

        const dialogResult = this._promptDialog.warningLc(titleLC, messageLC, options);
        return this._convertDialogResult(dialogResult);
    }

    /**
     * @deprecated use LgPromptDialog.info instead
     */
    public info(
        title: string,
        message: string,
        options?: IConfirmDialogOptions
    ): Subject<number | null | void> {
        this._updateButtonsConfiguration(options);
        const forceClick = this._getForceClick(options);
        this._promptDialog.info(title, message, options);

        return forceClick;
    }

    /**
     * @deprecated use LgPromptDialog.infoLc instead
     */
    public infoLC(
        titleLC: string,
        messageLC: string,
        localizationContext?: any,
        options?: IConfirmDialogOptions
    ): Subject<number | null | void> {
        this._updateLocalizationContext(options, localizationContext);
        const forceClick = this._getForceClick(options);
        this._promptDialog.infoLc(titleLC, messageLC, options);

        return forceClick;
    }

    private _updateButtonsConfiguration(
        options: IConfirmDialogOptions | undefined,
        { areNamesLocalized }: { areNamesLocalized?: boolean } = {}
    ): void {
        const buttons: LgPromptDialogButton[] = [];
        if (options == null) {
            return;
        }
        if (options.button1) {
            const firstButton: LgPromptDialogButton = { id: "1" };
            if (areNamesLocalized) {
                firstButton.nameLc = options.button1;
            } else {
                firstButton.name = options.button1;
            }
            if (options.button1Class) firstButton.class = options.button1Class ?? "button--primary";
            if (options.button1Icon) firstButton.icon = options.button1Icon;
            firstButton.isConfirmAction = true;
            buttons.push(firstButton);
        }
        if (options.button2) {
            const secondButton: LgPromptDialogButton = { id: "2" };
            if (areNamesLocalized) {
                secondButton.nameLc = options.button2;
            } else {
                secondButton.name = options.button2;
            }
            if (options.button2Class) secondButton.class = options.button2Class;
            if (options.button2Icon) secondButton.icon = options.button2Icon;
            if (options.button2AsReject) secondButton.isReject = options.button2AsReject;
            secondButton.isCancelAction = true;
            buttons.push(secondButton);
        }
        if (options.button3) {
            const thirdButton: LgPromptDialogButton = {
                id: "3",
                name: options.button3
            };
            if (areNamesLocalized) {
                thirdButton.nameLc = options.button3;
            } else {
                thirdButton.name = options.button3;
            }
            if (options.button3Class) thirdButton.class = options.button3Class;
            if (options.button3Icon) thirdButton.icon = options.button3Icon;
            buttons.push(thirdButton);
        }

        if (buttons.length) {
            options = { ...options, buttons };
        }
    }

    private _updateLocalizationContext(
        options: IConfirmDialogOptions | undefined,
        localizationContext: any
    ): void {
        if (localizationContext && options) {
            options.localizationContext = localizationContext;
        } else if (localizationContext && options == null) {
            options = { localizationContext };
        }
    }

    private _getForceClick(
        options: IConfirmDialogOptions | undefined
    ): Subject<number | null | void> {
        const forceClick = new Subject<number | null | void>();
        if (options?.forceClick$) {
            options.forceClick$ = race(
                options.forceClick$,
                forceClick.pipe(
                    map(clickId => (typeof clickId === "number" ? clickId.toString() : clickId))
                )
            );
        }
        return forceClick;
    }

    private _convertDialogResult(dialogResult: Promise<string>): Promise<number> {
        return dialogResult.then(result => {
            const numberedResult = Number(result);
            if (isNaN(numberedResult)) {
                return (
                    LgConfirmationDialog._buttonCodesLookup[
                        result as keyof typeof LgConfirmationDialog._buttonCodesLookup
                    ] ?? 2
                );
            }
            return numberedResult;
        });
    }
}
