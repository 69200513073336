import { Component, ViewContainerRef, inject } from "@angular/core";

@Component({
    selector: "lg-pivot-table-body-container",
    template: ``,
    host: {
        class: "lg-pivot-table__body__container"
    }
})
export class LgPivotTableBodyContainerComponent {
    _viewContainerRef = inject(ViewContainerRef);
}
