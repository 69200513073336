import ldIsEmpty from "lodash-es/isEmpty";
import ldFlatten from "lodash-es/flatten";
import ldFilter from "lodash-es/filter";
import { HttpResponse } from "@angular/common/http";
import { MaybeStaleData } from "./ServerGatewayBase.types";

const staleHeaderRegex = /^110 - "stale(?::((?:[a-z0-9-]+,?)+))?"$/i;

export function asMaybeStaleData<T>(response: HttpResponse<T>): MaybeStaleData<T> {
    let isStale = false;
    let jobs: string[] | null = null;

    if (response.headers != null && "getAll" in response.headers) {
        const warnings = response.headers.getAll("Warning") || [];
        const staleDataWarnings = ldFilter(warnings.map(x => staleHeaderRegex.exec(x)));
        isStale = !ldIsEmpty(staleDataWarnings);
        jobs = ldFlatten(ldFilter(staleDataWarnings.map(x => (x ? x[1]?.split(",") : ""))));
    }

    return {
        data: response.body,
        isStale,
        jobs
    };
}
