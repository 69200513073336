import { EmptyUserInfo } from "../user/empty-user-info";
import { LgVersionModel } from "../version/lg-version.model";
import { IAppSession } from "./app-session";

// for older backend which don't have session
export function lgAppSessionMockFactory(): IAppSession {
    const userInfo = new EmptyUserInfo();
    return {
        scenarioId: LgVersionModel.version.id ?? undefined,
        clientId: userInfo.ziekenhuiscode,
        client: {
            id: userInfo.ziekenhuiscode,
            code: userInfo.ziekenhuiscode + "",
            name: userInfo.ziekenhuisnaam
        }
    };
}
