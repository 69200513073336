import { Observable, Subject } from "rxjs";
import {
    ChangeDetectionStrategy,
    Component,
    HostListener,
    QueryList,
    ViewChildren
} from "@angular/core";
import { ConnectedOverlayPositionChange } from "@angular/cdk/overlay";
import { AnimationEvent } from "@angular/animations";

import { atNextFrame } from "@logex/framework/utilities";
import { LgTranslatePipe, useTranslationNamespace } from "@logex/framework/lg-localization";

import { Animations, LgTagsSelectorBaseDirective } from "./lg-tags-selector-base";
import { LgTagsSelectorTagComponent } from "./lg-tags-selector-tag.component";
import { LgIconMenuComponent } from "../lg-icon-menu";
import { LgTooltipDirective } from "../../lg-tooltip";
import { NgForOf } from "@angular/common";
import { IdType } from "./lg-tags-selector.types";

@Component({
    standalone: true,
    selector: "lg-tags-selector-popup",
    templateUrl: "./lg-tags-selector-popup.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [Animations],
    host: {
        "[@state]": "_visibility",
        "(@state.done)": "_animationDone( $event )",
        class: "lg-tags-selector__popup",
        "[class.lg-tags-selector__popup--disabled]": "_isDisabled",
        "[class.lg-tags-selector__popup--adding]": "_isAddingNewTag"
    },
    imports: [
        LgTagsSelectorTagComponent,
        LgIconMenuComponent,
        LgTooltipDirective,
        LgTranslatePipe,
        NgForOf
    ],
    viewProviders: [useTranslationNamespace("FW._Directives._TagsSelector")]
})
export class LgTagsSelectorPopupComponent extends LgTagsSelectorBaseDirective {
    constructor() {
        super();
        this._initMixins();
    }

    // ----------------------------------------------------------------------------------
    //
    @ViewChildren(LgTagsSelectorTagComponent)
    protected override _tagComponents!: QueryList<LgTagsSelectorTagComponent>;

    private _result$!: Subject<void>;
    _isDisabled = false;

    public _parentTagComponents: LgTagsSelectorTagComponent[] = [];

    // ----------------------------------------------------------------------------------
    //

    init(args: {
        tagComponents: LgTagsSelectorTagComponent[];
        disabled: boolean;
    }): Observable<void> {
        this._isDisabled = args.disabled;
        this._visibility = "visible";

        this._parentTagComponents = args.tagComponents;
        this._changeDetectorRef.markForCheck();
        atNextFrame(() => {
            this._bindToTagComponents();
            this._syncTags();
        });

        this._result$ = new Subject<void>();
        return this._result$.asObservable();
    }

    override attemptClosePopup(): void {
        this._result$.next();
        this._result$.complete();
    }

    override updatePopupPosition(_change: ConnectedOverlayPositionChange): void {
        // empty function
    }

    @HostListener("@state.done", ["$event"])
    _animationDone(event: AnimationEvent): void {
        if (event.toState === "hidden") {
            this._pmOnHide$!.next();
        }
    }

    // ----------------------------------------------------------------------------------
    //

    _tagCurrentChange(tag: LgTagsSelectorTagComponent, value: IdType[] | null): void {
        tag.currentChange.emit(value);
    }
}
