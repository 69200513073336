import { Input, Component, Output, EventEmitter } from "@angular/core";
import { LgYearMonthRangeSelectorComponent } from "./lg-year-month-range-selector.component";
import { RangePreviewType } from "./lg-year-month-range-selector.types";
import { LgIconComponent } from "../lg-icon/lg-icon.component";

@Component({
    selector: "lg-year-month-range-preview",
    template: `
        <div class="lg-filterset-preview__item" [title]="_currentValue">
            {{ _currentValue }}
            <lg-icon icon="icon-close" (click)="_clear($event)"></lg-icon>
        </div>
    `,
    // code relies on internal state of other component so cannot be on-push without refactoring
    // changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [LgIconComponent],
    host: {
        class: "lg-filterset-preview",
        "[style.display]": "_allSelected ? 'none' : 'inline-block'"
    }
})
export class LgYearMonthRangePreviewComponent {
    /**
     * Source range selector component (required).
     */
    @Input({ required: true }) source!: LgYearMonthRangeSelectorComponent;

    /**
     * Range preview type.
     */
    @Input() mode: RangePreviewType = "month";

    /**
     * Emit `true` on clear selection
     */
    @Output() readonly clear = new EventEmitter<boolean>();

    _clear($event: { stopPropagation: () => void; preventDefault: () => void }): void {
        $event.stopPropagation();
        $event.preventDefault();
        this.clear.emit(true);
    }

    get _currentValue(): string {
        return this.mode === "year"
            ? this.source.previewYearInterval
            : this.source.previewMonthInterval;
    }

    get _allSelected(): boolean {
        return this.mode === "year" ? this.source.allYearsSelected : this.source.allMonthsSelected;
    }
}
