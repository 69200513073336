import { ElementRef } from "@angular/core";

/**
 * @deprecated
 * Use Web API Element closest() method instead
 */
export function closestElement(
    element: ElementRef | HTMLElement,
    selector: string
): HTMLElement | null {
    const target = "nativeElement" in element ? element.nativeElement : element;
    return target.closest(selector);
}
