/* eslint-disable @typescript-eslint/no-this-alias */
import * as d3 from "d3";
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    isDevMode,
    NgZone,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    TemplateRef,
    ViewChild,
    inject,
    ViewContainerRef
} from "@angular/core";
import { TemplatePortal } from "@angular/cdk/portal";
import {
    ILgFormatter,
    ILgFormatterOptions,
    LgConsole,
    LgFormatterFactoryService
} from "@logex/framework/core";
import { LgSimpleChanges } from "@logex/framework/types";
import { useTranslationNamespace } from "@logex/framework/lg-localization";
import { D3TooltipApi, ID3TooltipOptions, LgD3TooltipService } from "../d3";
import { getDefaultLegendOptions } from "../shared/getDefaultLegendOptions";
import {
    ChartClickEvent,
    LegendItem,
    LegendOptions,
    LgChartIconSymbol,
    Margin,
    PointReference
} from "../shared/chart.types";
import { getRecommendedPosition } from "../shared/getRecommendedPosition";
import { getLegendWidth } from "../shared/getLegendWidth";
import { doCirclePointsOverlap } from "../shared/doCirclePointsOverlap";
import { coerceNumberProperty } from "@angular/cdk/coercion";
import {
    LgFunnelChartLine,
    LgFunnelChartBoundaries,
    LgFunnelChartDatum,
    LgFunnelChartQuadrants,
    LgFunnelChartTooltipContext,
    LgFunnelChartTooltipItem
} from "./lg-funnel-chart.types";
import { IRange } from "@logex/framework/ui-core";
import { LgFunnelChartConfidence } from "..";
import { IExportableChart, LgChartExportContainerDirective } from "../shared/lg-chart-export";
import {
    IChartTooltipProvider,
    IImplicitContext
} from "../shared/lg-chart-template-context.directive";

const QUADRANT_OPACITY = 0.08;
const RISK_QUADRANT_COLOR = "#EB2844";
const SAFE_QUADRANT_COLOR = "#4AC92C";
const DEFAULT_MARGIN: Margin = { top: 40, right: 16, bottom: 16, left: 16 };
export const DEFAULT_QUADRANT_DEFINITION: LgFunnelChartQuadrants = {
    upperQuadrantColorHex: RISK_QUADRANT_COLOR,
    lowerQuadrantColorHex: SAFE_QUADRANT_COLOR,
    upperQuadrantOpacity: QUADRANT_OPACITY,
    lowerQuadrantOpacity: QUADRANT_OPACITY
};

const DEFAULT_TICKS_FORMATTER_OPTIONS: ILgFormatterOptions = { decimals: 0 };

const SPACE_BETWEEN_LABELS_AND_GRID = 8;
const Y_AXIS_TITLE_WIDTH = 20;
const X_AXIS_LABELS_LINE_HEIGHT = 20;
const SPACE_FOR_LEGEND_BELOW = 30;
const DEFAULT_MAX_TITLE_LENGTH = 23;

const X_AXIS_TITLE_HEIGHT = 24;
const DEFAULT_TICK_COUNT = 5;
const DEFAULT_Y_AXIS_LABELS_WIDTH = 30;

const POINT_RADIUS = 5;
const POINT_HOVER_RADIUS = POINT_RADIUS + 2;
const POINT_STROKE_WIDTH = 1;
const POINT_STROKE_COLOR = "white";
const SHADOW_RADIUS = 12;
const SHADOW_POINT_OPACITY = 0.3;
const SHADOW_STROKE = 0;
const LINE_STROKE_WIDTH = 1.5;
const LINE_DASHARRAY = "5";

const DATA_POINT_SHADOW_SELECTOR = "shadow-point";
const DATA_POINT_SELECTOR = "point";

const DEFAULT_LINE_DENSITY = 200;

function fill(
    element: d3.Selection<any, any, any, any>
): (color: string) => d3.Selection<any, any, any, any> {
    return (color: string) => element.attr("fill", color);
}

@Component({
    selector: "lg-funnel-chart",
    templateUrl: "./lg-funnel-chart.component.html",
    viewProviders: [useTranslationNamespace("FW._Directives._Charts._LgFunnelPlot")],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LgFunnelChartComponent<TChartDatum extends LgFunnelChartDatum = LgFunnelChartDatum>
    implements
        OnInit,
        OnChanges,
        AfterViewInit,
        OnDestroy,
        IExportableChart,
        IChartTooltipProvider<LgFunnelChartTooltipContext<TChartDatum>>
{
    private _elementRef = inject(ElementRef);
    private _exportContainer = inject(LgChartExportContainerDirective, { optional: true });
    private _formatterFactory = inject(LgFormatterFactoryService);
    private _lgConsole = inject(LgConsole).withSource("Logex.Charts.LgFunnelChart");
    private _ngZone = inject(NgZone);
    private _renderer = inject(Renderer2);
    private _tooltipService = inject(LgD3TooltipService);
    private _viewContainerRef = inject(ViewContainerRef);
    /**
     * @required
     * Specifies the width of the chart area in pixels.
     */
    @Input({ required: true }) width!: number;
    /**
     * @required
     * Specifies the height of the chart area in pixels.
     */
    @Input({ required: true }) height!: number;
    /**
     * @required
     * Specifies the input data for funnel chart.
     */
    @Input({ required: true }) data!: TChartDatum[];
    /**
     * Specifies the margin for chart.
     * Defaults to 16 on all sides.
     *
     * @property { number } top specifies the margin of SVG from top.
     * @property { number } right specifies the margin of SVG from right.
     * @property { number } bottom specifies the margin of SVG from bottom.
     * @property { number } left specifies the margin of SVG from left.
     */
    @Input() margin?: Margin;
    /**
     * @optional
     * Specifies maximum number of ticks on axis. Defaults to 5.
     */
    @Input() tickCount?: number;
    /**
     * @optional
     * Specifies the Y axis title. Defaults to "Y axis title not defined".
     * Can receive empty string to hide Y axis title entirely.
     */
    @Input() yAxisLabel?: string | null;
    /**
     * @optional
     * Specifies the X axis title. Defaults to "X axis title not defined".
     * Can receive empty string to hide X axis title entirely.
     */
    @Input() xAxisLabel?: string | null;
    /**
     * Specifies whether X axis labels are visible or not. Defaults to true.
     *
     * @default true
     */
    @Input() showXAxisLabels = true;
    /**
     * @optional
     * Specifies whether Y axis labels are visible or not. Defaults to true.
     *
     * @default true
     */
    @Input() showYAxisLabels = true;
    /**
     * Specifies the length of the Y axis title text. Defaults to 10 characters.
     */
    @Input() yAxisLabelLength: number = DEFAULT_MAX_TITLE_LENGTH;
    /**
     * Specifies the length of the X axis title text. Defaults to 10 characters.
     */
    @Input() xAxisLabelLength: number = DEFAULT_MAX_TITLE_LENGTH;
    /**
     * @optional
     * Specifies the width of the Y axis labels in pixels. Defaults to 30.
     *
     * @default 30
     */
    @Input() yAxisLabelsWidth!: number;
    /**
     * @optional
     * Specifies formatter type for X axis. Defaults to "float".
     *
     * @default "float"
     */
    @Input() xAxisFormatterType!: string;
    /**
     * @optional
     * Specifies the options for X axis formatter. Defaults to 0 decimals.
     *
     * @default 0 decimals
     */
    @Input() xAxisFormatterOptions!: ILgFormatterOptions;
    /**
     * @optional
     * Specifies formatter type for Y axis. Defaults to "percentage"
     *
     * @default "percentage"
     */
    @Input() yAxisFormatterType!: string;
    /**
     * @optional
     * Specifies the options for Y axis formatter. Defaults to 0 decimals.
     *
     * @default 0 decimals
     */
    @Input() yAxisFormatterOptions!: ILgFormatterOptions;
    /**
     * @optional
     * Specifies the legend options. If not specified, legend is not visible.
     */
    @Input() legendOptions: LegendOptions = getDefaultLegendOptions();
    /**
     * Specifies the function to return color of group based on id.
     */
    @Input() groupColorFn!: (datum: TChartDatum) => string;
    /**
     * Specifies the function to return name of group based on id.
     */
    @Input({ required: true }) groupNameFn?: (datum: TChartDatum) => string;
    /**
     * @optional
     * Specifies class of tooltip. Default to empty.
     */
    @Input() tooltipClass?: string;
    /**
     * @optional
     * Specifies the template to be used when hovering over elements. Defaults to own template
     */
    @Input() tooltipTemplate?: TemplateRef<
        IImplicitContext<LgFunnelChartTooltipContext<TChartDatum>>
    >;

    /**
     * @optional
     * Specifies whether points are clickable.
     *
     * @default false
     */
    @Input() clickable = false;
    /**
     * @optional
     * Specifies the maximum value on X axis.
     * If not specified, maximum value is calculated from data.
     */
    @Input() xMax?: number;
    /**
     * @optional
     * Specifies the minimum value on X axis.
     * If not specified, minimum value is calculated from data.
     */
    @Input() xMin?: number;
    /**
     * @optional
     * Specifies the minimum value on Y axis.
     * If not specified, minimum value is calculated from data.
     */
    @Input() yMax?: number;
    /**
     * @optional
     * Specifies the maximum value on Y axis.
     * If not specified, maximum value is calculated from data.
     */
    @Input() yMin?: number;
    /**
     * @optional
     * Specifies the average constant.
     * If specified, constant is drawn with regards to Y axis.
     */
    @Input() yAxisAverage?: LgFunnelChartLine | null;
    /**
     * @optional
     * Specifies additional average constants on Y axis.
     * If specified, constant is drawn with regards to Y axis.
     */
    @Input() yAxisAdditionalAverages?: LgFunnelChartLine[] | null;
    /**
     * @optional
     * Specifies the average constant.
     * If specified, constant is drawn with regards to X axis.
     */
    @Input() xAxisAverage?: LgFunnelChartLine | null;
    /**
     * @optional
     * Specifies additional average constants on X axis.
     * If specified, constant is drawn with regards to X axis.
     */
    @Input() xAxisAdditionalAverages?: LgFunnelChartLine[] | null;
    /**
     * @optional
     * Specifies the confidence interval.
     * If specified, creates confidence interval with regards to the X axis average.
     * https://www.mathsisfun.com/data/confidence-interval.html
     */
    @Input() confidence?: LgFunnelChartConfidence | null;
    /**
     * @optional
     * Specifies the additional isolines values
     * If specified, creates iso line with regards to both axes.
     * Should be used only with percentage formatters.
     */
    @Input() isoline?: LgFunnelChartLine | null;
    /**
     * @optional
     * Specifies isolines value
     * If specified, creates iso lines with regards to both axes.
     * Should be used only with percentage formatters.
     */
    @Input() additionalIsolines?: LgFunnelChartLine[] | null;
    /**
     * @optional
     * Specifies the definition for quadrants.
     * Defaults to reddish and greenish color with opacity 0.08 per design.
     */
    @Input() quadrantDefinition: LgFunnelChartQuadrants = DEFAULT_QUADRANT_DEFINITION;
    /**
     * @optional
     * Specifies the density with which isolines are drawn. Higher density allows for smoother lines.
     * If specified, the number of points calculated for isolines are equal to the density.
     * The density corresponds to number of points from which the lines are drawn.
     * Higher density provides smoother and more correct lines at the expense of speed.
     * Defaults to 200.
     */
    @Input() isolineDensity?: number = DEFAULT_LINE_DENSITY;

    /**
     * Emits data in clicked box, if the clickable input is set to true.
     */
    @Output() readonly itemClick = new EventEmitter<ChartClickEvent<TChartDatum, TChartDatum>>();

    /**
     * Emits data in clicked legend item.
     */
    @Output() readonly legendClick = new EventEmitter<LegendItem>();

    @ViewChild("chart", { static: true }) private _chartHolder!: ElementRef<HTMLDivElement>;
    @ViewChild("chartWithLegend", { static: true })
    private _chartWithLegendDivRef!: ElementRef<HTMLDivElement>;

    @ViewChild("defaultTemplate", { static: true })
    private _defaultTooltipTemplate!: TemplateRef<
        IImplicitContext<LgFunnelChartTooltipContext<TChartDatum>>
    >;

    _width!: number;
    _height!: number;
    _margin!: Margin;
    private _data: TChartDatum[] = [];

    private _svg!: d3.Selection<any, any, any, any>;
    private _svgG!: d3.Selection<any, TChartDatum, any, any>;
    private _yScale!: d3.ScaleLinear<number, number>;
    private _xScale!: d3.ScaleLinear<number, number>;
    private _xAxisGroup!: d3.Selection<any, any, any, any>;
    private _yAxisGroup!: d3.Selection<any, any, any, any>;
    private _yAxisGridG!: d3.Selection<any, any, any, any>;
    private _xAxisGridG!: d3.Selection<any, any, any, any>;
    private _dataPointsShadowG!: d3.Selection<any, TChartDatum, any, any>;
    private _dataPointsShadow!: d3.Selection<any, any, any, any>;
    private _dataPointsG!: d3.Selection<any, TChartDatum, any, any>;
    private _dataPoints!: d3.Selection<any, any, any, any>;

    private _yAxisLabel!: d3.Selection<any, any, any, any>;
    private _xAxisLabel!: d3.Selection<any, any, any, any>;
    private _spaceForYAxisLabels!: number;

    private _groupNames: string[] = [];

    _xAxisFormatter!: ILgFormatter<any>;
    _yAxisFormatter!: ILgFormatter<any>;
    _yAxisFormat!: (x: number) => string;
    _xAxisFormat!: (x: number) => string;

    private _trackListener!: () => void;
    private _tooltip?: D3TooltipApi;
    private _tooltipContext: LgFunnelChartTooltipContext<TChartDatum> | null = null;
    private _tooltipPortal?: TemplatePortal<
        IImplicitContext<LgFunnelChartTooltipContext<TChartDatum>>
    >;

    get tooltipContext(): LgFunnelChartTooltipContext<TChartDatum> | null {
        return this._tooltipContext;
    }

    set tooltipContext(context: LgFunnelChartTooltipContext<TChartDatum>) {
        if (this._tooltipPortal?.context) {
            this._tooltipPortal.context.$implicit = context;
        }
        this._tooltipContext = context;
    }

    private _lastMouseY = 0;
    private _lastMouseX = 0;

    private _initialized = false;

    private _pointRefs: Array<PointReference<TChartDatum>> = [];
    private _shadowPointRefs: Array<PointReference<TChartDatum>> = [];
    private _overlappingPointRefs: Array<PointReference<TChartDatum>> = [];
    private _overlappingShadowPointRefs: Array<PointReference<TChartDatum>> = [];

    private _boundaries!: LgFunnelChartBoundaries;

    _legendDefinition: LegendItem[] = [];
    _legendWidth = 0;
    _legendPaddingBottom = 0;

    private _isoLineToPointDictionary: Record<string, number[]> = {};

    ngOnInit(): void {
        this._setDefaultProperties();
        this._initializeTooltip();
        this._trackMousePosition();
        this._triggerDataSpecificMethods();
    }

    ngAfterViewInit(): void {
        this._exportContainer?.register(this);
        this._render();
        this._initialized = true;
    }

    ngOnChanges(changes: LgSimpleChanges<LgFunnelChartComponent<TChartDatum>>): void {
        if (!this._initialized) return;

        if (
            changes.xAxisFormatterOptions ||
            changes.yAxisFormatterOptions ||
            changes.xAxisFormatterType ||
            changes.yAxisFormatterType
        ) {
            this._setDefaultProperties();
        }
        if (
            changes.data ||
            changes.groupColorFn ||
            changes.groupNameFn ||
            changes.xMin ||
            changes.xMax ||
            changes.yMin ||
            changes.yMax ||
            changes.xAxisAverage ||
            changes.yAxisAverage ||
            changes.xAxisAdditionalAverages ||
            changes.yAxisAdditionalAverages
        ) {
            this._triggerDataSpecificMethods();
        }

        if (changes.width || changes.height || changes.tickCount) {
            this._width = this.width;
            this._height = this.height;
        }

        if (changes.yAxisLabelsWidth) {
            this._spaceForYAxisLabels =
                this.yAxisLabelsWidth == null ? DEFAULT_Y_AXIS_LABELS_WIDTH : this.yAxisLabelsWidth;
        }

        if (changes.xAxisLabel) {
            this._xAxisLabel.text(
                this._getVisibleAxisTitle(this.xAxisLabel ?? "", this.xAxisLabelLength)
            );
        }

        if (changes.margin) this._margin = this.margin!;

        if (changes.yAxisLabel) {
            this._yAxisLabel.text(
                this._getVisibleAxisTitle(this.yAxisLabel ?? "", this.yAxisLabelLength)
            );
        }

        if (changes.legendOptions || changes.confidence) {
            this._updateLegend();
        }

        if (this._svg) this._svg.remove();
        this._isoLineToPointDictionary = {};
        this._setDefaultHoverOverlapRefs();
        this._render();
    }

    ngOnDestroy(): void {
        this._exportContainer?.unregister(this);
        this._tooltip?.destroy();
    }

    getHtmlElement(): HTMLElement {
        return this._chartWithLegendDivRef.nativeElement;
    }

    getSvgElement(): SVGElement {
        return this._svg.node();
    }

    private _setDefaultProperties(): void {
        this.tooltipTemplate = this.tooltipTemplate ?? this._defaultTooltipTemplate;

        this._margin = this.margin == null ? DEFAULT_MARGIN : this.margin;
        this.xAxisFormatterType = this.xAxisFormatterType || "float";
        this.yAxisFormatterType = this.yAxisFormatterType || "percent";
        this.xAxisFormatterOptions = {
            ...DEFAULT_TICKS_FORMATTER_OPTIONS,
            ...this.xAxisFormatterOptions
        };
        this.yAxisFormatterOptions = {
            ...DEFAULT_TICKS_FORMATTER_OPTIONS,
            ...this.yAxisFormatterOptions
        };
        this._xAxisFormatter = this._formatterFactory.getFormatter(
            this.xAxisFormatterType,
            this.xAxisFormatterOptions
        );
        this._yAxisFormatter = this._formatterFactory.getFormatter(
            this.yAxisFormatterType,
            this.yAxisFormatterOptions
        );

        this.tooltipTemplate = this.tooltipTemplate || this._defaultTooltipTemplate;

        this.tickCount = this.tickCount == null ? DEFAULT_TICK_COUNT : this.tickCount;
        this._spaceForYAxisLabels =
            this.yAxisLabelsWidth == null ? DEFAULT_Y_AXIS_LABELS_WIDTH : this.yAxisLabelsWidth;
        this._xAxisFormat = x => this._xAxisFormatter.format(x);
        this._yAxisFormat = x => this._yAxisFormatter.format(x);
        this.legendOptions =
            this.legendOptions == null ? getDefaultLegendOptions() : this.legendOptions;
        this._width = this.width;
        this._height = this.height;
        this.showXAxisLabels = this.showXAxisLabels == null ? true : this.showXAxisLabels;
        this.showYAxisLabels = this.showYAxisLabels == null ? true : this.showYAxisLabels;
        this.groupColorFn = this.groupColorFn == null ? () => "#69b3a2" : this.groupColorFn;
        this._groupNames = [];
        this._setDefaultHoverOverlapRefs();
    }

    private _setDefaultHoverOverlapRefs(): void {
        this._pointRefs = [];
        this._shadowPointRefs = [];
        this._overlappingPointRefs = [];
        this._overlappingShadowPointRefs = [];
    }

    private _initializeTooltip(): void {
        this._tooltipPortal = this._getTooltipContent();
        const commonOptions: ID3TooltipOptions = {
            stay: false,
            trapFocus: false,
            position: "top-left",
            tooltipClass: `lg-tooltip lg-tooltip--d3 ${
                this.tooltipClass ? " " + this.tooltipClass : ""
            }`,
            panelClass: "chart-overlay",
            content: this._tooltipPortal,
            delayHide: 150,
            target: this._elementRef
        };
        this._tooltip = this._tooltipService.create({
            ...commonOptions
        });
    }

    private _getTooltipContent(): TemplatePortal<
        IImplicitContext<LgFunnelChartTooltipContext<TChartDatum>>
    > {
        return new TemplatePortal<IImplicitContext<LgFunnelChartTooltipContext<TChartDatum>>>(
            this.tooltipTemplate!,
            this._viewContainerRef,
            { $implicit: this.tooltipContext! }
        );
    }

    private _trackMousePosition(): void {
        this._ngZone.runOutsideAngular(() => {
            this._trackListener = this._renderer.listen(
                this._elementRef.nativeElement,
                "mousemove",
                (event: MouseEvent) => {
                    this._lastMouseX = event.clientX;
                    this._lastMouseY = event.clientY;
                    this._updateTooltipPosition();
                }
            );
        });
    }

    private _updateTooltipPosition(): void {
        if (!this._tooltip || !this._tooltip.visible) return;
        this._tooltip.setPositionAt(
            this._lastMouseX,
            this._lastMouseY,
            getRecommendedPosition(
                { x: this._lastMouseX, y: this._lastMouseY },
                this._tooltip.getOverlayElement()
            )
        );
    }

    private _triggerDataSpecificMethods(): void {
        if (this.data == null || this.data.length === 0) {
            if (isDevMode()) {
                this._lgConsole.warn(
                    "No data are available for data transformation. Stopping code execution"
                );
            }
            return;
        }
        this._convertData();
        this._updateLegend();
    }

    private _convertData(): void {
        this._data = [...this.data];
    }

    private _updateLegend(): void {
        this._setLegendDefinition();
        this._setLegendSize();
    }

    private _setLegendDefinition(): void {
        this._legendDefinition = [];
        this._groupNames = [];
        this._addScatterGroups();
        this._addAverageGroups();
        this._addIsolineGroups();
        this._addConfidenceGroups();
        this._addQuadrantGroups();
    }

    private _addScatterGroups(): void {
        const alreadyHasGroup: Record<string, boolean> = {};
        this._data.forEach(d => {
            const name = this.groupNameFn?.call(this, d) ?? "";
            if (alreadyHasGroup[name]) return;
            this._groupNames.push(name);
            this._legendDefinition.push({
                color: this._getColor(d),
                name,
                opacity: 1,
                symbol: "circle"
            });

            alreadyHasGroup[name] = true;
        });
    }

    private _addAverageGroups(): void {
        if (
            this.xAxisAverage ||
            this.yAxisAverage ||
            this.xAxisAdditionalAverages ||
            this.yAxisAdditionalAverages
        ) {
            const averages: LgFunnelChartLine[] = [];
            if (this.xAxisAverage) averages.push(this.xAxisAverage);
            if (this.yAxisAverage) averages.push(this.yAxisAverage);
            if (this.xAxisAdditionalAverages) averages.push(...this.xAxisAdditionalAverages);
            if (this.yAxisAdditionalAverages) averages.push(...this.yAxisAdditionalAverages);
            this._addLinesToLegend(averages, "line");
        }
    }

    private _addIsolineGroups(): void {
        if (this.isoline) {
            const isolines: LgFunnelChartLine[] = [this.isoline];
            if (this.additionalIsolines) isolines.push(...this.additionalIsolines);
            this._addLinesToLegend(isolines, "line-dashed");
        }
    }

    private _addLinesToLegend(
        lines: LgFunnelChartLine[],
        symbol: LgChartIconSymbol = "line"
    ): void {
        const linesToAdd: LegendItem[] = lines
            .filter(line => line && line.legendName)
            .map(line => ({
                color: line.colorHex,
                name: line.legendName ?? "",
                opacity: line.legendOpacity ?? 1,
                symbol
            }));
        if (linesToAdd.length === 0) return;
        this._legendDefinition.push(...linesToAdd);
        this._groupNames.push(...linesToAdd.map(line => line.name));
    }

    private _addConfidenceGroups(): void {
        if (!this.confidence || !this.confidence.legendName) return;
        this._legendDefinition.push({
            color: this.confidence.colorHex,
            name: this.confidence.legendName,
            opacity: this.confidence.legendOpacity ?? 1,
            symbol: "line-dashed"
        });
        this._groupNames.push(this.confidence.legendName);
    }

    private _addQuadrantGroups(): void {
        const hasUpperQuadrant = !!this.quadrantDefinition.upperQuadrantLegendName;
        const hasLowerQuadrant = !!this.quadrantDefinition.lowerQuadrantLegendName;
        if (hasUpperQuadrant) {
            this._legendDefinition.push({
                color: this.quadrantDefinition.upperQuadrantColorHex,
                name: this.quadrantDefinition.upperQuadrantLegendName ?? "",
                opacity: this.quadrantDefinition.upperQuadrantOpacity,
                symbol: "square",
                symbolBorder: true
            });
            this._groupNames.push(this.quadrantDefinition.upperQuadrantLegendName ?? "");
        }
        if (hasLowerQuadrant) {
            this._legendDefinition.push({
                color: this.quadrantDefinition.lowerQuadrantColorHex,
                name: this.quadrantDefinition.lowerQuadrantLegendName ?? "",
                opacity: this.quadrantDefinition.lowerQuadrantOpacity,
                symbol: "square",
                symbolBorder: true
            });
            this._groupNames.push(this.quadrantDefinition.lowerQuadrantLegendName ?? "");
        }
    }

    private _setLegendSize(): void {
        const legendVisible = this.legendOptions.visible;
        const legendBelow = legendVisible && this.legendOptions.position === "bottom";
        const legendSize = this._getLegendSize();
        const legendOnTheRight = legendVisible && this.legendOptions.position === "right";

        const spaceBelowAxis =
            this._svgHeight -
            this._verticalPositionOfXAxis +
            (legendBelow ? legendSize : 0) +
            (this.xAxisLabel ? X_AXIS_TITLE_HEIGHT : 0);

        this._legendWidth = legendOnTheRight ? legendSize : 0;
        this._legendPaddingBottom = legendOnTheRight ? spaceBelowAxis : 0;
    }

    private _render(): void {
        if (this._data == null || this._data.length === 0) {
            if (isDevMode()) {
                this._lgConsole.warn(
                    "No data are available for rendering. Stopping code execution"
                );
            }
            return;
        }
        this._drawMainSVGHolder();
        this._initializeScales();
        this._drawAxes();
        this._drawAverages();
        this._drawQuadrants();
        this._drawConfidenceIntervals();
        this._drawAllIsolines();
        this._drawPoints();
    }

    private _drawMainSVGHolder(): void {
        this._svg = d3
            .select(this._chartHolder.nativeElement)
            .append("svg")
            .attr("width", this._svgWidth)
            .attr("height", this._svgHeight);

        this._svgG = this._svg.append("g");
    }

    private _initializeScales(): void {
        this._boundaries = this._getBoundaries();

        this._yScale = d3
            .scaleLinear()
            .range([this._verticalPositionOfXAxis, this._margin.top ?? 0])
            .domain([this._boundaries.yMin, this._boundaries.yMax])
            .nice();
        this._xScale = d3
            .scaleLinear()
            .range([this._horizontalPositionOfYAxis, this._svgWidth - (this._margin.right ?? 0)])
            .domain([this._boundaries.xMin, this._boundaries.xMax])
            .nice();
    }

    private _getBoundaries(): LgFunnelChartBoundaries {
        const xValues = this._data.map(i => i.xValue);
        const yValues = this._data.map(i => i.yValue);
        const xMin = this.xMin ?? Math.min(...xValues);
        const yMin = this.yMin ?? Math.min(...yValues);
        const xMax = this.xMax ?? Math.max(...xValues);
        const yMax = this.yMax ?? Math.max(...yValues);
        return { yMin, yMax, xMin, xMax };
    }

    private _drawAxes(): void {
        this._addYAxis();
        this._addYAxisGrid();
        this._addXAxis();
        this._addXAxisGrid();
    }

    private _addYAxis(): void {
        this._yAxisGroup = this._svgG
            .append("g")
            .attr("class", "y__axis")
            .attr("transform", `translate(${this._horizontalPositionOfYAxis}, 0)`);
        this._yAxisLabel = this._svgG
            .append("text")
            .attr("class", "axis__title y__axis")
            .attr("text-anchor", "start")
            .text(this._getVisibleAxisTitle(this.yAxisLabel ?? "", this.yAxisLabelLength))
            .attr(
                "transform",
                `translate(${this._margin.left}, ${this._verticalPositionOfXAxis}) rotate(-90)`
            );

        this._yAxisLabel.append("title").text(this.yAxisLabel ?? "");

        this._yAxisGroup.transition().duration(250).call(this._getYAxis());

        this._yAxisGroup
            .selectAll(".tick text")
            .transition()
            .duration(250)
            .attr("transform", `translate(${-SPACE_BETWEEN_LABELS_AND_GRID}, 0)`);
    }

    private _getVisibleAxisTitle(axisTitle: string, titleLength: number): string {
        return axisTitle?.length > titleLength
            ? axisTitle.substr(0, titleLength) + "..."
            : axisTitle;
    }

    private _getYAxis(): d3.Axis<d3.NumberValue> {
        return d3
            .axisLeft<any>(this._yScale)
            .tickSize(0)
            .tickPadding(3)
            .tickFormat(this._yAxisFormat)
            .ticks(coerceNumberProperty(this.tickCount, 5));
    }

    private _addYAxisGrid(): void {
        this._yAxisGridG = this._svgG
            .append("g")
            .attr("class", "y__axis__grid")
            .attr("transform", `translate(${this._horizontalPositionOfYAxis}, 0)`);

        this._yAxisGridG.transition().duration(250).call(this._getYAxisGrid(this._yScale));
    }

    private _getYAxisGrid(scale: d3.ScaleLinear<number, number>): d3.Axis<d3.NumberValue> {
        return d3
            .axisRight(scale)
            .tickPadding(0)
            .tickFormat(() => "")
            .ticks(coerceNumberProperty(this.tickCount, 5))
            .tickSizeOuter(0)
            .tickSizeInner(
                this._svgWidth - this._horizontalPositionOfYAxis - (this._margin.right ?? 0)
            );
    }

    private _addXAxis(): void {
        this._xAxisGroup = this._svgG
            .append("g")
            .attr("class", "x__axis")
            .attr("transform", `translate(0,${this._verticalPositionOfXAxis})`);

        this._xAxisLabel = this._svgG
            .append("text")
            .attr("class", "axis__title x__axis")
            .text(this._getVisibleAxisTitle(this.xAxisLabel ?? "", this.xAxisLabelLength))
            .attr("text-anchor", "start")
            .attr("dy", "1em")
            .attr(
                "transform",
                `translate(
                    ${this._horizontalPositionOfYAxis + this._svgWidth / 4},
                    ${
                        this._verticalPositionOfXAxis +
                        SPACE_BETWEEN_LABELS_AND_GRID +
                        X_AXIS_TITLE_HEIGHT
                    }
                )`
            );

        this._xAxisLabel.append("title").text(this.xAxisLabel ?? "");

        this._xAxisGroup.transition().duration(250).call(this._getXAxis());

        this._xAxisGroup
            .selectAll(".tick text")
            .transition()
            .duration(250)
            .attr("transform", `translate(0, ${SPACE_BETWEEN_LABELS_AND_GRID})`);
    }

    private _getXAxis(): d3.Axis<d3.NumberValue> {
        return d3
            .axisBottom<any>(this._xScale)
            .tickSize(0)
            .tickPadding(3)
            .tickFormat(this._xAxisFormat)
            .ticks(this.tickCount);
    }

    private _addXAxisGrid(): void {
        this._xAxisGridG = this._svgG
            .append("g")
            .attr("class", "x__axis__grid")
            .attr("transform", `translate(0, ${this._margin.top}) rotate(-90)`);

        this._xAxisGridG.transition().duration(250).call(this._getXAxisGrid(this._xScale));
    }

    private _getXAxisGrid(scale: d3.ScaleLinear<number, number>): d3.Axis<d3.NumberValue> {
        return d3
            .axisLeft(scale)
            .tickPadding(0)
            .tickFormat(() => "")
            .ticks(this.tickCount)
            .tickSizeOuter(0)
            .tickSizeInner(this._yScale.range()[0] - this._yScale.range()[1]);
    }

    private _drawAverages(): void {
        if (this.yAxisAverage) this._drawAverage(this.yAxisAverage, true);
        if (this.xAxisAverage) this._drawAverage(this.xAxisAverage, false);
        this._drawAdditionalAverages();
    }

    private _drawAverage(average: LgFunnelChartLine, isOnY: boolean): void {
        const min = isOnY ? this._xScale.domain()[0] : this._yScale.domain()[0];
        const max = isOnY ? this._xScale.domain()[1] : this._yScale.domain()[1];
        const constant = average.value;
        this._svgG
            .append("line")
            .attr("fill", "none")
            .attr("stroke", average.colorHex)
            .attr("stroke-width", LINE_STROKE_WIDTH)
            .attr("x1", isOnY ? this._xScale(min) : this._xScale(constant))
            .attr("x2", isOnY ? this._xScale(max) : this._xScale(constant))
            .attr("y1", isOnY ? this._yScale(average.value) : this._yScale(min))
            .attr("y2", isOnY ? this._yScale(average.value) : this._yScale(max));
    }

    private _drawAdditionalAverages(): void {
        if (this.yAxisAdditionalAverages) {
            this.yAxisAdditionalAverages.forEach(average => {
                this._drawAverage(average, true);
            });
        }
        if (this.xAxisAdditionalAverages) {
            this.xAxisAdditionalAverages.forEach(average => {
                this._drawAverage(average, false);
            });
        }
    }

    private _drawQuadrants(): void {
        if (!this.xAxisAverage || !this.yAxisAverage) return;
        const quadrantBoundaries = [this.xAxisAverage.value, this.yAxisAverage.value];

        const lowerQuadrantWidth = this._xScale(quadrantBoundaries[0]);
        const upperQuadrantWidth = this._xScale(this._boundaries.xMax) - lowerQuadrantWidth;
        const upperQuadrantHeight = this._yScale(quadrantBoundaries[1]);
        const lowerQuadrantHeight = this._yScale(this._boundaries.yMin) - upperQuadrantHeight;
        this._svgG
            .append("rect")
            .attr("width", lowerQuadrantWidth - this._horizontalPositionOfYAxis)
            .attr("height", lowerQuadrantHeight)
            .attr(
                "transform",
                `translate(${this._horizontalPositionOfYAxis}, ${
                    this._verticalPositionOfXAxis - lowerQuadrantHeight
                })`
            )
            .attr("fill", this.quadrantDefinition.lowerQuadrantColorHex)
            .attr("opacity", this.quadrantDefinition.lowerQuadrantOpacity);

        this._svgG
            .append("rect")
            .attr("width", upperQuadrantWidth)
            .attr("height", upperQuadrantHeight - (this._margin.top ?? 0))
            .attr("transform", `translate(${lowerQuadrantWidth}, ${this._margin.top})`)
            .attr("fill", this.quadrantDefinition.upperQuadrantColorHex)
            .attr("opacity", this.quadrantDefinition.upperQuadrantOpacity);
    }

    private _drawConfidenceIntervals(): void {
        if (!this.confidence || !this.yAxisAverage) return;
        const xValues = this._getXAxisDomain();
        const yUpper: Array<[number, number]> = this._getConfidenceIntervals(
            xValues,
            this.confidence.upperConfidenceFn,
            this._boundaries.yMax
        );
        const yLower: Array<[number, number]> = this._getConfidenceIntervals(
            xValues,
            this.confidence.lowerConfidenceFn,
            this._boundaries.yMin
        );

        this._drawLine(yUpper, this.confidence.colorHex);
        this._drawLine(yLower, this.confidence.colorHex);
    }

    private _getXAxisDomain(): number[] {
        const domainToDrawConfidenceIn: IRange = {
            from: this._xScale.domain()[0],
            to: this._xScale.domain()[1]
        };

        const xExtremesDistance = domainToDrawConfidenceIn.to - domainToDrawConfidenceIn.from;
        let xStep = 1;
        if (xExtremesDistance < 10) xStep = 0.5;
        if (xExtremesDistance < 5) xStep = 0.05;
        const domainLength = xExtremesDistance / xStep + 1;

        const xValues = Array.from(
            { length: domainLength },
            (_, i) => domainToDrawConfidenceIn.from + i * xStep
        );
        return xValues;
    }

    private _getConfidenceIntervals(
        xValues: number[],
        confidenceFn: (x: number) => number,
        firstPointY: number
    ): Array<[number, number]> {
        const result: Array<[number, number]> = [];
        let firstPoint: [number, number] | undefined;
        let previousValue: number | undefined;
        let index = 0;

        const changeThreshold = Math.abs(
            (this._yScale.domain()[1] - this._yScale.domain()[0]) /
                (this._yScale.range()[1] - this._yScale.range()[0])
        );

        for (const x of xValues) {
            const currentValue = confidenceFn(x);

            const isFirst = index === 0;
            const isLast = index === xValues.length - 1;

            index++;

            if (
                currentValue !== 0 &&
                Math.abs(previousValue! - currentValue) < changeThreshold &&
                !(isFirst || isLast)
            )
                continue;
            else {
                previousValue = currentValue;
            }

            const scaledY = this._yScale(currentValue);
            const isOutsideBoundaries =
                scaledY < this._yScale(this._boundaries.yMax) ||
                scaledY > this._yScale(this._boundaries.yMin);

            if (!isFinite(scaledY)) continue;
            if (isOutsideBoundaries) {
                firstPoint = [this._xScale(x), this._yScale(firstPointY)];
                continue;
            }
            result.push([this._xScale(x), scaledY]);
        }

        if (firstPoint) {
            result.unshift(firstPoint);
        }
        return result;
    }

    private _drawLine(data: Array<[number, number]>, color: string): void {
        const line = d3
            .line()
            .x(d => d[0])
            .y(d => d[1])
            .curve(d3.curveMonotoneX);

        this._svgG
            .append("path")
            .datum(data)
            .attr("fill", "none")
            .attr("stroke", color)
            .attr("stroke-width", LINE_STROKE_WIDTH)
            .attr("stroke-dasharray", LINE_DASHARRAY)
            .attr("d", line);
    }

    private _drawAllIsolines(): void {
        if (!this.isoline && !this.additionalIsolines) return;
        if (this.isoline) this._drawIsolines([this.isoline]);
        if (this.additionalIsolines) this._drawIsolines(this.additionalIsolines);
        this._drawTopXAxis();
    }

    private _drawIsolines(isolines: LgFunnelChartLine[]): void {
        const lines = isolines.map(i => i.value);
        const isoLinesToDraw = this._getIsolinesPoints(lines);
        isoLinesToDraw.forEach((item, index) => {
            const isoline = isolines[index];
            this._isoLineToPointDictionary[isoline.value] = item[0];
            this._drawLine(item, isoline.colorHex);
        });
    }

    private _getIsolinesPoints(lines: number[]): Array<Array<[number, number]>> {
        const self = this;
        const numberOfSteps = this.isolineDensity ?? 1;
        const xMax = this._boundaries.xMax;
        const yMax = this._boundaries.yMax;
        const xMin = this._boundaries.xMin;
        const step = (xMax - xMin) / numberOfSteps;
        return lines.map(isoline => {
            /**
             * Points can be only added in range <yMin; yMax>
             * If current point is higher than yMax, it is thrown away.
             * The last "thrown" point is the first to draw => unshifting array
             */
            const currentLinePoints: Array<[number, number]> = [];
            let y = isoline;
            let firstDrawablePoints: number[] = [];
            for (let x = xMin; x <= xMax; x += step) {
                y = isoline / x;
                if (y >= yMax) {
                    firstDrawablePoints = [x + step, yMax];
                    continue;
                }
                const xPosition = self._xScale(x);
                const yPosition = self._yScale(y);
                currentLinePoints.push([xPosition, yPosition]);
            }
            addFirstDrawableStep();
            return currentLinePoints;

            function addFirstDrawableStep(): void {
                const xPosition = self._xScale(firstDrawablePoints[0]);
                const yPosition = self._yScale(firstDrawablePoints[1]);
                currentLinePoints.unshift([xPosition, yPosition]);
            }
        });
    }

    private _drawTopXAxis(): void {
        Object.entries(this._isoLineToPointDictionary).forEach(([key, value]) => {
            this._svgG
                .append("text")
                .text(this._xAxisFormat(+key))
                .attr("class", "top__x__axis")
                .attr("dx", "0.5em")
                .attr("dy", "0.25em")
                .attr("transform", `translate(${value[0]}, ${value[1]}) rotate(-90)`);
        });
    }

    private _drawPoints(): void {
        this._dataPointsShadowG = this._svgG.append("g");
        this._drawShadowPoints();
        this._dataPointsG = this._svgG.append("g").attr("class", "lg-funnel-chart__data-points");
        this._drawDataPoints();
    }

    private _drawShadowPoints(): void {
        const self = this;

        this._dataPointsShadow = this._dataPointsShadowG
            .selectAll(DATA_POINT_SHADOW_SELECTOR)
            .data(this._data)
            .enter()
            .append("circle");

        this._dataPointsShadow
            .attr("cx", d => this._xScale(d.xValue))
            .attr("cy", d => this._yScale(d.yValue))
            .attr("stroke-width", SHADOW_STROKE)
            .attr("r", SHADOW_RADIUS)
            .attr("fill", "transparent")
            .style("opacity", SHADOW_POINT_OPACITY)
            .on("mousemove", function (event: MouseEvent) {
                self._onMouseOverPointShadow(d3.select(this), d3.pointer(event));
            })
            .on("mouseout", function (_event: MouseEvent) {
                d3.select(this)
                    .style("cursor", self.clickable ? "pointer" : "default")
                    .attr("fill", "transparent");

                self._tooltip?.hide();
                self._clearOverlappingElements();
            })
            .each(function (d: TChartDatum) {
                const shadowPointRef = <PointReference<TChartDatum>>{
                    coordinates: [
                        Math.round(self._xScale(d.xValue)),
                        Math.round(self._yScale(d.yValue))
                    ],
                    fillElementWith: fill(d3.select(this)),
                    data: d
                };
                self._shadowPointRefs.push(shadowPointRef);
            })
            .on("click", function (_event: MouseEvent, d: TChartDatum) {
                const index = self._dataPointsShadow.nodes().indexOf(this);
                self._onClick(d, index);
            });
    }

    private _onMouseOverPointShadow(
        target: d3.Selection<SVGCircleElement, TChartDatum, null, undefined>,
        mousecoords: [number, number]
    ): void {
        this._clearOverlappingElements();

        this._overlappingPointRefs = this._pointRefs.filter(x =>
            doCirclePointsOverlap(mousecoords, x.coordinates)
        );
        this._overlappingShadowPointRefs = this._shadowPointRefs.filter(x =>
            doCirclePointsOverlap(mousecoords, x.coordinates)
        );

        this._overlappingPointRefs.forEach(point =>
            point.resizeElement?.call(this, POINT_HOVER_RADIUS)
        );
        this._overlappingShadowPointRefs.forEach(point =>
            point.fillElementWith(this._getColor(point.data))
        );

        if (this._overlappingShadowPointRefs.length === 0) {
            this._tooltip?.hide();
            return;
        }

        const alreadyAddedGroups: any = [];
        const tooltipItems = this._overlappingShadowPointRefs.map(point => {
            const alreadyAdded = alreadyAddedGroups.indexOf(point.data.groupId) !== -1;
            if (!alreadyAdded) alreadyAddedGroups.push(point.data.groupId);

            return <LgFunnelChartTooltipItem<TChartDatum>>{
                datum: point.data
            };
        });

        const data = target.data();

        this.tooltipContext = {
            displayingItems: tooltipItems,
            activeItem: {
                column: data?.[0],
                group: data?.[0].groupId
            }
        };

        this._tooltip?.show({ target: target.node() as any });
        this._updateTooltipPosition();
    }

    private _clearOverlappingElements(): void {
        this._overlappingPointRefs.forEach(element => {
            element.resizeElement?.call(this, POINT_RADIUS);
        });
        this._overlappingShadowPointRefs.forEach(element => {
            element.fillElementWith("transparent");
        });
    }

    private _drawDataPoints(): void {
        const self = this;
        this._dataPoints = this._dataPointsG
            .selectAll(DATA_POINT_SELECTOR)
            .data(this._data)
            .enter()
            .append("circle")
            .attr("cx", d => this._xScale(d.xValue))
            .attr("cy", d => this._yScale(d.yValue))
            .attr("r", POINT_RADIUS)
            .attr("stroke", POINT_STROKE_COLOR)
            .attr("stroke-width", POINT_STROKE_WIDTH)
            .attr("fill", d => this._getColor(d))
            .each(function (d: TChartDatum) {
                const pointRef = <PointReference<TChartDatum>>{
                    coordinates: [
                        Math.round(self._xScale(d.xValue)),
                        Math.round(self._yScale(d.yValue))
                    ],
                    fillElementWith: fill(d3.select(this)),
                    resizeElement: (radius: number) => {
                        return d3.select(this).attr("r", radius);
                    },
                    data: d
                };
                self._pointRefs.push(pointRef);
            });
    }

    private _getColor(datum: TChartDatum): string {
        const color = this.groupColorFn(datum);
        return d3.rgb(color).formatHex();
    }

    private _onClick(value: TChartDatum, itemIndex: number): void {
        if (!this.clickable) return;

        this.itemClick.emit({ item: value, datum: value, index: itemIndex });
    }

    _onLegendItemClick(item: LegendItem): void {
        this.legendClick.emit(item);
    }

    private get _verticalPositionOfXAxis(): number {
        const margin = this._margin.bottom ?? 0;
        const spaceForLabels = this.showXAxisLabels ? X_AXIS_LABELS_LINE_HEIGHT : 0;
        const spaceForTitle = this.xAxisLabel ? X_AXIS_TITLE_HEIGHT : 0;
        return this._svgHeight - margin - spaceForLabels - spaceForTitle;
    }

    private get _horizontalPositionOfYAxis(): number {
        return (
            (this._margin.left ?? 0) +
            (this.yAxisLabel && this.showYAxisLabels ? Y_AXIS_TITLE_WIDTH : 0) +
            this._spaceForYAxisLabels +
            SPACE_BETWEEN_LABELS_AND_GRID
        );
    }

    private get _svgWidth(): number {
        const legendVisible = this.legendOptions.visible;
        const legendOnTheRight = legendVisible && this.legendOptions.position === "right";

        const { left = 0, right = 0 } = this._margin;
        const legendSize = this._getLegendSize();
        return this._width - left - right - (legendOnTheRight ? legendSize : 0);
    }

    get _svgHeight(): number {
        const legendVisible = this.legendOptions.visible;
        const legendBelow = legendVisible && this.legendOptions.position === "bottom";

        const { top = 0, bottom = 0 } = this._margin;
        const legendSize = this._getLegendSize();
        return (
            this._height -
            top -
            bottom -
            (this.xAxisLabel ? X_AXIS_TITLE_HEIGHT : 0) -
            (legendBelow ? legendSize : 0)
        );
    }

    private _getLegendSize(): number {
        const isLegendBelow = this.legendOptions.position === "bottom";
        const isLegendOnRight = this.legendOptions.position === "right";
        if (!isLegendBelow && !isLegendOnRight) return 0;
        if (isLegendBelow) return SPACE_FOR_LEGEND_BELOW;
        return getLegendWidth(
            this._width,
            this.legendOptions.widthInPercents ?? 0,
            this._groupNames
        );
    }
}
