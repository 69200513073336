<span *ngIf="_initialized">
    <span class="lg-month-range-selector-popup__select-all" (click)="_selectAll()">{{
        "FW._Directives.Month_Range_Selector__All_months" | lgTranslate
    }}</span>
    <span
        #month
        *ngFor="let m of _months; let index = index"
        class="lg-month-range-selector-popup__month"
        [attr.data-id]="index + 1"
        [ngClass]="_className(index)"
        (mouseover)="_onMouseOver($event, index + 1)"
        (mouseout)="_onMouseOut($event, index + 1)"
        (mousedown)="_onMouseDown(index + 1)"
        title="{{ _getMonthTooltip(index + 1) | capitalize }}"
        (click)="_onClick($event)"
        >{{ _shortNames[m] | removeSymbol : "DOT" | capitalize }}</span
    >
</span>
