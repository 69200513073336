import { Injectable, inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { Downtime } from "./lg-downtime-notification.types";

@Injectable({ providedIn: "root" })
export class LgDowntimeNotificationGateway {
    private _http = inject(HttpClient);

    /* Url example: https://downtimeapi.dev.nl.logex.cloud/v1/downtimes/mva */
    getDowntimes(downtimeUrl: string): Observable<Downtime[] | undefined> {
        try {
            return this._http.get<Downtime[]>(downtimeUrl);
        } catch {
            console.error("Unable to fetch Downtime API");
            return of(undefined);
        }
    }
}
