import { NgModule } from "@angular/core";
import { UiCoreModule } from "@logex/framework/ui-core";

import { LgPivotModule } from "@logex/framework/lg-pivot";
import { LgExcelExportDialogComponent } from "./lg-excel-exports/lg-excel-export-dialog.component";
import { LgExcelFactoryService } from "./lg-excel-exports/lg-excel-factory.service";
import { LgExcelBase64Service } from "./lg-excel-exports/lg-excel-base64";
import { LgCsvExportService } from "./lg-csv-exports/lg-csv-export.service";

@NgModule({
    imports: [LgPivotModule, UiCoreModule],
    exports: [LgExcelExportDialogComponent],
    declarations: [LgExcelExportDialogComponent],
    providers: [LgExcelFactoryService, LgCsvExportService, LgExcelBase64Service]
})
export class LgExportsModule {}
