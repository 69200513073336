<lg-icon-menu
    *ngIf="!_isSingleOption"
    [definition]="_menuDefinition"
    (currentChange)="_onMenuSelect($event)"
    icon="icon-copy"
    [class]="iconClass"
></lg-icon-menu>

<lg-icon
    *ngIf="_isSingleOption"
    icon="icon-copy"
    class="lg-panel__header-icon"
    [clickable]="true"
    (click)="_copyFirst()"
    [lgTooltip]="'FW._Directives.CopyButton_Tooltip' | lgTranslate"
></lg-icon>
