import { Directive } from "@angular/core";

import { SidebarButtonBase } from "./sidebar-button-base";

@Directive({
    selector: "[lgSidebarPanel]",
    // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
    inputs: [
        "nameLc: lgSidebarPanelNameLc",
        "nameAlwaysVisible: lgSidebarPanelNameAlwaysVisible",
        "onTop: lgSidebarPanelTop",
        "icon: lgSidebarPanelIcon",
        "imageClass: lgSidebarPanelImageClass",
        "large: lgSidebarPanelLarge",
        "badge: lgSidebarPanelBadge",
        "disabled: lgSidebarPanelDisabled",
        "isVisible: lgSidebarPanelIsVisible",
        "order: lgSidebarPanelOrder",
        "id: lgSidebarPanelId",
        "nameClass: lgSidebarPanelNameClass",
        "titleLc: lgSidebarPanelTitleLc"
    ],
    // eslint-disable-next-line @angular-eslint/no-outputs-metadata-property, @angular-eslint/no-output-native, @angular-eslint/no-output-on-prefix
    outputs: ["onClick:click"]
})
export class LgSidebarPanelDirective extends SidebarButtonBase {}
