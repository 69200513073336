import {
    Component,
    Input,
    Output,
    EventEmitter,
    ChangeDetectionStrategy,
    OnChanges,
    OnDestroy,
    ChangeDetectorRef,
    inject,
    SimpleChanges
} from "@angular/core";
import type { LgFilterSet, IVisibleDefinitionGroup } from "../lg-filterset";
import type { IFilterDefinition } from "../filter-definition";
import { Subscription } from "rxjs";

/** @deprecated The component is no longer used by the filterset panel */
@Component({
    standalone: false,
    selector: "lg-filterset-list",
    // eslint-disable-next-line @angular-eslint/component-max-inline-declarations
    template: `
        <div
            class="filter-group"
            *ngFor="
                let group of list.visibleGroups;
                let first = first;
                let last = last;
                trackBy: _groupIdentity
            "
            [ngClass]="{ hasTitle: group.name, first: first, last: last }"
        >
            <div class="filter-group-title" *ngIf="group.name">{{ group.name }}</div>
            <div
                class="line"
                *ngFor="let entry of group.filters; trackBy: _entryIdentity"
                [attr.lg-filter-id]="entry.id"
            >
                <lg-filterset-list-item
                    [definition]="entry"
                    [list]="list"
                    (filterChange)="_filterChanged(entry)"
                ></lg-filterset-list-item>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: "lg-filteset-list"
    }
})
export class LgFiltersetListComponent implements OnChanges, OnDestroy {
    private _changeDetectorRef = inject(ChangeDetectorRef);
    /**
     * List of all filters (required).
     */
    @Input({ required: true }) list!: LgFilterSet;

    @Output() readonly filterChange = new EventEmitter<void>();

    private _subscription: Subscription | null = null;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.list) {
            if (this._subscription) {
                this._subscription.unsubscribe();
                this._subscription = null;
            }
            if (this.list) {
                this._subscription = this.list.onChanged.subscribe(() => {
                    this._changeDetectorRef.markForCheck();
                });
                this._subscription.add(
                    this.list.visibleGroups$.subscribe(() => {
                        this._changeDetectorRef.markForCheck();
                    })
                );
            }
        }
    }

    ngOnDestroy(): void {
        this._subscription?.unsubscribe();
    }

    _filterChanged(entry: IFilterDefinition): void {
        this.list.triggerOnChanged(entry);
        this.filterChange.next();
    }

    _groupIdentity(_index: number, entry: IVisibleDefinitionGroup): number {
        return entry.index;
    }

    _entryIdentity(_index: number, entry: IFilterDefinition): string {
        return entry.id;
    }
}
