<div class="lg-dynamic-input__frame">
    <ng-container *ngIf="!_editing">
        <div class="lg-dynamic-input__frame__text" (click)="_showEditor($event)">
            <span
                [ngClass]="{ 'lg-dynamic-input__frame__text--value': !_empty }"
                title="{{ _formattedValue }}"
            >
                {{ _formattedValue }}
            </span>
        </div>
        <div
            class="lg-dynamic-input__frame__clear"
            (click)="_clearValue($event)"
            [hidden]="_empty || isDisabled"
        >
            <lg-icon icon="icon-close"></lg-icon>
        </div>
    </ng-container>
    <input
        #inputField
        *ngIf="_editing"
        class="input input--no-border input--no-highlight"
        type="text"
        [ngModel]="_formattedForEditValue"
        (ngModelChange)="_inputChanged($event)"
        [ngModelOptions]="{ updateOn: 'blur' }"
        (blur)="_editing = false"
        lgSelectOnFocus
        lgDefaultFocus
        lgUndoOnEsc
    />
</div>
<div *ngIf="!_empty || _editing" class="lg-dynamic-input__label">
    <div [title]="label">
        {{ label }}
    </div>
</div>
