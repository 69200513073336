export function traverseTypeConstructors(
    obj: any,
    callback: (typeConstructor: Function) => void
): void {
    let proto: any;

    if (typeof obj === "function") proto = obj.prototype;
    else proto = Object.getPrototypeOf(obj);

    while (proto != null) {
        callback(proto.constructor);
        proto = Object.getPrototypeOf(proto);
    }
}
