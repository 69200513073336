import { ILookup } from "@logex/framework/types";

export enum RegexValidatorType {
    PositiveNumber = 0
}

const dictionary: ILookup<RegExp> = {};
dictionary[RegexValidatorType.PositiveNumber] = /[0-9]+/;

export const is = (underTest: string, type: RegexValidatorType): boolean => {
    return dictionary[type].test(underTest);
};
