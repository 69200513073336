import { Directive, ElementRef, Input, HostListener, inject } from "@angular/core";

@Directive({
    standalone: true,
    selector: "[lgSelectOnFocus]"
})
export class LgSelectOnFocusDirective {
    private _el = inject(ElementRef);

    /**
     * Specifies, if the INPUT's content should be automatically selected when obtaining focus.
     *
     * @default true
     */
    @Input("lgSelectOnFocus") set selectOnFocus(condition: boolean | "") {
        this._enabled = condition !== false;
    }

    get selectOnFocus(): boolean {
        return this._enabled;
    }

    private _enabled = true;

    @HostListener("focus")
    _onFocus(): void {
        if (this._enabled) {
            this._el.nativeElement.select();
        }
    }
}
