import { animate, group, query, state, style, transition, trigger } from "@angular/animations";
import { ChangeDetectionStrategy, Component, ViewEncapsulation } from "@angular/core";
import { easingDefs } from "@logex/framework/utilities";

import { LgDropdownPopupComponent } from "./lg-dropdown-popup.component";
import { LgDropdownAnimationParams } from "./lg-dropdown-popup.animations";

interface AnimationParameters {
    pointThreeOrZero: number;
    pointTwoOrZero: number;
    shortOrZero: number;
}

// as function because of AOT
export function lgRederenceDropdownPopupComponentGetDefaultParameters(): {
    params: AnimationParameters;
} {
    return { params: { pointThreeOrZero: 0, pointTwoOrZero: 0, shortOrZero: 0 } };
}

@Component({
    selector: "lg-reference-dropdown-popup",
    templateUrl: "./lg-dropdown-popup.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,

    // eslint-disable-next-line @angular-eslint/component-max-inline-declarations
    animations: [
        trigger("state", [
            state("initial, hidden", style({ opacity: 0 })),
            state("onTop, onBottom", style({ opacity: 1 })),

            transition(
                "* => onBottom",
                group([
                    style({ opacity: 1 }),
                    animate("{{pointThreeOrZero}}s", style({ opacity: 1 })),
                    query(".lg-dropdown-popup__main", [
                        style({ transform: "translateY(-100%)" }),
                        animate(
                            `{{pointThreeOrZero}}s ${easingDefs.easeOutCubic}`,
                            style({ transform: "translateY(0px)" })
                        )
                    ])
                ]),
                lgRederenceDropdownPopupComponentGetDefaultParameters()
            ),

            transition(
                "* => onTop",
                group([
                    style({ opacity: 1 }),
                    animate("{{pointThreeOrZero}}s", style({ opacity: 1 })),
                    query(".lg-dropdown-popup__main", [
                        style({ transform: "translateY(+100%)" }),
                        animate(
                            `{{pointThreeOrZero}}s ${easingDefs.easeOutCubic}`,
                            style({ transform: "translateY(0px)" })
                        )
                    ])
                ]),
                lgRederenceDropdownPopupComponentGetDefaultParameters()
            ),

            transition(
                "onBottom => hidden",
                [
                    group([
                        style({ opacity: 1 }),
                        animate("{{pointTwoOrZero}}s", style({ opacity: 1 })),
                        query(".lg-dropdown-popup__main", [
                            style({ transform: "translateY(0%)" }),
                            animate(
                                `{{pointTwoOrZero}}s ${easingDefs.easeInCubic}`,
                                style({ transform: "translateY(-100%)" })
                            )
                        ]),
                        query(
                            ".lg-dropdown-popup__wrapper",
                            [
                                style({ opacity: "1" }),
                                animate(
                                    `{{shortOrZero}}s ${easingDefs.easeInCubic}`,
                                    style({ opacity: 0 })
                                )
                            ],
                            { delay: "{{pointTwoOrZero}}s" }
                        )
                    ])
                ],
                lgRederenceDropdownPopupComponentGetDefaultParameters()
            ),

            transition(
                "onTop => hidden",
                [
                    group([
                        style({ opacity: 1 }),
                        animate("{{pointTwoOrZero}}s", style({ opacity: 1 })),
                        query(".lg-dropdown-popup__main", [
                            style({ transform: "translateY(0%)" }),
                            animate(
                                `{{pointTwoOrZero}}s ${easingDefs.easeInCubic}`,
                                style({ transform: "translateY(+100%)" })
                            )
                        ]),
                        query(
                            ".lg-dropdown-popup__wrapper",
                            [
                                style({ opacity: "1" }),
                                animate(
                                    `{{shortOrZero}}s ${easingDefs.easeInCubic}`,
                                    style({ opacity: 0 })
                                )
                            ],
                            { delay: "{{pointTwoOrZero}}s" }
                        )
                    ])
                ],
                lgRederenceDropdownPopupComponentGetDefaultParameters()
            )
        ])
    ]
})
export class LgReferenceDropdownPopupComponent<T> extends LgDropdownPopupComponent<T> {
    constructor() {
        super();
        this._isReference = true;
    }

    protected override getAnimationParams(): LgDropdownAnimationParams {
        return {
            animationDuration: this._animationEnabled ? 250 : 0
        };
    }
}
