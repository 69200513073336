<div
    lgFileDrop
    class="upload-form"
    [class.upload-form--dragging]="_dragging"
    [class.upload-form--files-ready]="smallUploadZone"
    (fileOver)="_draggingOverInput($event)"
    (fileDrop)="_dropped($event)"
    [uploader]="_uploader"
>
    <div *ngIf="!smallUploadZone" class="upload-form__image"></div>
    <div class="upload-form__caption">
        {{ "FW._Uploader.DropAreaText1" | lgTranslate }}
        <br *ngIf="!smallUploadZone" />
        {{ "FW._Uploader.DropAreaText2" | lgTranslate }}
        <label for="file" lgTranslate="FW._Uploader.DropAreaText3"></label>.

        <input
            type="file"
            id="file"
            (change)="_fileSelected($event)"
            [multiple]="true"
            class="upload-form__input"
            #fileInput
        />
    </div>
</div>
<div *ngIf="_uploads.length">
    <div class="upload-table">
        <div class="upload-resumable__table">
            <div
                *ngFor="let upload of _uploads; index as i"
                class="table__row upload-table__row"
                [lgTooltip]="
                    upload.options.metadata?.abortedUpload
                        ? ('FW._Uploader.ResumeOrCancelUpload' | lgTranslate)
                        : ''
                "
            >
                <div class="table__column table__column--level upload-table__row--spacer"></div>
                <div class="table__column table__column--level crop upload-resumable__row--name">
                    {{ upload.file.name }}
                </div>
                <div class="upload-resumable__progress-holder">
                    <div class="upload-form__progress-bar__progress" style="width: 200px">
                        <lg-progress-bar
                            [current]="upload.options.progress"
                            [total]="100"
                            style="width: 100px"
                            [completed]="upload.options.progress === 100"
                        ></lg-progress-bar>
                    </div>
                    <div
                        class="upload-resumable__progress-bar__text"
                        [textContent]="_getUploadDisplayedSize(upload)"
                    ></div>
                </div>
                <div class="table__column table__column--icons upload-table__row--icons">
                    <lg-icon
                        icon="icon-close"
                        [clickable]="true"
                        (click)="_deleteUpload(i)"
                        [title]="'FW._Uploader.CancelUpload' | lgTranslate"
                    ></lg-icon>
                </div>
                <div class="table__column table__column--level upload-table__row--spacer"></div>
            </div>
        </div>
    </div>
</div>
