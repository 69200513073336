import { Component, Input, OnDestroy, OnChanges, SimpleChanges, inject } from "@angular/core";
import { LgPlaceholderStorageService } from "./lg-placeholder-storage.service";
import { IContextSetterSubscription } from "./internal.types";

/**
 * Defines a content that can be rendered by the specified placeholder(s). The content
 * will be linked using the context specified by the placeholder
 *
 * Usage
 * <h1 *lgPlaceholderContent="'app.welcome'">
 *   Hello world
 * </h1>
 *
 * If you want to inject some variables (including implicit) from the placeholder's context, use
 * <h1 *lgPlaceholderContent="let impl using 'app.welcome'; let color=color"
 *   <span [style.color]="color">Hello world</span>
 * </h1>
 *
 * When the content is destroyed, it will be removed also from the placeholders - because the storage implements
 * a stack, it will be either replaced by the previous content, or by placeholder's default.
 *
 * The content storage is global for the whole app.
 *
 * Note that the context can be mutated (due to the way it's implemented in ngTemplateOutlet) and therefore you
 * should just plain js dictionary rather than some class.
 */
@Component({
    selector: "lg-placeholder-context",
    template: ""
})
export class LgPlaceholderContextComponent implements OnChanges, OnDestroy {
    private _storage = inject(LgPlaceholderStorageService);
    @Input("using") name?: string;

    @Input() public set context(val: any) {
        // We add unique marker to the context to increase chance that the template
        // will replace it rather than mutate it, otherwise we cannot get the context stack
        // working reliably.
        // TODO: consider using own template rendering rather than ngTemplateOutlet, and
        //   make sure the context there is never mutated
        if (this._context) {
            delete this._context[this._uniqueMarker];
        }
        this._context = val;
        if (this._context) {
            this._context[this._uniqueMarker] = true;
        }
    }

    get context(): any {
        return this._context;
    }

    private _subscription: IContextSetterSubscription | null = null;
    private _uniqueMarker: string;
    private _context: any;
    private static _uniqueMarkerCounter = 0;

    public constructor() {
        this._uniqueMarker =
            "__contextMarker" + (++LgPlaceholderContextComponent._uniqueMarkerCounter).toString();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.name) {
            if (this._subscription) {
                this._subscription.unsubscribe();
                this._subscription = null;
            }
            if (this.name) {
                this._subscription = this._storage.addContext(this.name, this._context || {});
            }
        } else if (this._subscription) {
            this._subscription.update(this._context || {});
        }
    }

    public ngOnDestroy(): void {
        if (this._subscription) {
            this._subscription.unsubscribe();
            this._subscription = null;
        }
    }
}
