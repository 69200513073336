import { Injectable, inject } from "@angular/core";

import {
    LG_LOCALIZATION_SETTINGS,
    LgLocalizationSettings
} from "../translate/lg-localization.types";

@Injectable({ providedIn: "root" })
export class LgI18nService {
    private _settings = inject(LG_LOCALIZATION_SETTINGS);

    get settings(): LgLocalizationSettings {
        return this._settings;
    }

    setLanguage(val: string): void {
        this._settings.setPreferredLanguage(val);
        window.location.reload();
    }
}
