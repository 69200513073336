import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    inject,
    Input,
    OnDestroy,
    OnInit
} from "@angular/core";
import { Subscription } from "rxjs";
import {
    IVersion,
    LG_APP_CONFIGURATION,
    LG_APP_CONTROL,
    LG_USER_INFO,
    VersionService
} from "@logex/framework/lg-application";
import { LgTranslateService, useTranslationNamespace } from "@logex/framework/lg-localization";

import {
    LG_SCENARIO_DIALOG_CONFIG,
    LG_SCENARIO_DIALOG_WITH_PIVOT_CONFIG,
    LgScenarioDialog,
    LgScenarioDialogWithPivot
} from "../lg-scenario-dialog";
import { dropdownFlat } from "@logex/framework/utilities";
import { IDropdownDefinition } from "@logex/framework/ui-core";

@Component({
    standalone: false,
    selector: "lg-breadcrumb-data-context",
    templateUrl: "./lg-breadcrumb-data-context.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: "lg-breadcrumb-data-context"
    },
    viewProviders: [useTranslationNamespace("FW._ScenarioManagement")]
})
export class LgBreadcrumbDataContextComponent implements OnInit, OnDestroy {
    _userInfo = inject(LG_USER_INFO);
    private _appConfiguration = inject(LG_APP_CONFIGURATION);
    private _appControlService = inject(LG_APP_CONTROL);
    private _changeDetectorRef = inject(ChangeDetectorRef);
    private _dialogConfig? = inject(LG_SCENARIO_DIALOG_CONFIG, { optional: true });
    private _dialogWithPivotConfig? = inject(LG_SCENARIO_DIALOG_WITH_PIVOT_CONFIG, {
        optional: true
    });

    private _lgTranslate = inject(LgTranslateService);
    private _scenarioDialog = inject(LgScenarioDialog);
    private _scenarioDialogWithPivot = inject(LgScenarioDialogWithPivot);
    private _versionService = inject(VersionService<IVersion>);

    @Input() hideHospital = false;
    @Input() hideScenario = false;
    @Input() hideLock = false;

    @Input() showScenarioButton = false;
    @Input() useScenarioDialogWithPivot = false;
    @Input() showHospitalButton = false;

    _version!: IVersion;
    private _subscription!: Subscription;
    _dropdownDef: IDropdownDefinition<string> | undefined;

    ngOnInit(): void {
        const dropdownOptions: Array<{ type: string; name: string }> = [];
        if (this.showHospitalButton)
            dropdownOptions.push({
                type: "client",
                name: this._lgTranslate.translate(".Client")
            });
        if (this.showScenarioButton)
            dropdownOptions.push({
                type: "scenario",
                name: this._lgTranslate.translate(".Scenario")
            });
        this._dropdownDef = dropdownFlat({
            entryId: "type",
            entryName: "name",
            entries: dropdownOptions
        });

        this._subscription = this._versionService.get().subscribe(v => {
            this._version = v;
            this._changeDetectorRef.markForCheck();
        });
    }

    ngOnDestroy(): void {
        this._subscription.unsubscribe();
    }

    _switch(event: string): void {
        if (event === "client") {
            this._switchHospital();
        } else {
            this._switchScenario();
        }
    }

    _switchHospital(): void {
        this._appControlService.showContextSelector();
    }

    _switchScenario(): void {
        if (this.useScenarioDialogWithPivot) {
            this._scenarioDialogWithPivot.show(this._dialogWithPivotConfig);
        } else {
            this._scenarioDialog.show(this._dialogConfig);
        }
    }

    _onScenarioClicked(): void {
        if (this.useScenarioDialogWithPivot) {
            this._scenarioDialogWithPivot.show(this._dialogWithPivotConfig);
        } else {
            this._scenarioDialog.show(this._dialogConfig);
        }
    }
}
