import { Component, ViewEncapsulation, ChangeDetectionStrategy } from "@angular/core";

@Component({
    selector: "lg-folding-loader",
    template: `
        <div class="lg-folding-loader">
            <div class="lg-folding-loader__cube lg-folding-loader__cube--0"></div>
            <div class="lg-folding-loader__cube lg-folding-loader__cube--1"></div>
            <div class="lg-folding-loader__cube lg-folding-loader__cube--3"></div>
            <div class="lg-folding-loader__cube lg-folding-loader__cube--2"></div>
        </div>
    `,
    host: {
        class: "lg-folding-loader-wrapper"
    },
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LgFoldingLoaderComponent {
    // empty
}
