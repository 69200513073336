import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
    OnChanges,
    ViewChild
} from "@angular/core";
import { LgSimpleChanges } from "@logex/framework/types";

@Component({
    selector: "lg-single-value-chart",
    templateUrl: "./lg-single-value-chart.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LgSingleValueChartComponent implements OnChanges {
    /**
     * Specifies data value for chart.
     */
    @Input() value?: number;

    /**
     * Specifies value limit.
     * If not specified then is defined by `value` input.
     */
    @Input() limit?: number;

    /**
     * Specifies text space width in pixels.
     */
    @Input() textSpace?: number;

    /**
     * Callback for providing formatting for data value.
     */
    @Input() formatFn?: (value: number | undefined) => string;

    @ViewChild("axis") axis: ElementRef | undefined;

    protected _axisValueLeft = "";
    protected _axisValueWidth = "";

    protected _getFormattedValue(): string {
        return this.formatFn !== undefined
            ? this.formatFn(this.value)
            : this.value?.toString() ?? "-";
    }

    ngOnChanges(changes: LgSimpleChanges<LgSingleValueChartComponent>): void {
        if (changes.value || changes.limit) {
            this.calcAxisDimensions();
        }
    }

    protected calcAxisDimensions(): void {
        if (this.value === undefined || !isFinite(this.value) || isNaN(this.value)) return;

        const limit =
            this.limit !== undefined && this.limit > 0 ? this.limit : Math.abs(this.value);
        let value = Math.abs(this.value);
        if (value > limit) {
            value = limit;
        }

        const axisValue = `${value / limit} * 50%`;
        this._axisValueWidth = `calc(${axisValue})`;

        if (this.value > 0) {
            this._axisValueLeft = "50%";
        } else {
            this._axisValueLeft = `calc(50% - ${axisValue})`;
        }
    }
}
