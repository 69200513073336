import { inject, Injectable } from "@angular/core";
import { lastValueFrom } from "rxjs";
import { LG_USER_INFO } from "../user/index";

import {
    ContextSettings,
    UserSettingsService,
    WellKnownSettingCode
} from "../user/user-settings.service";
import { LgAppControlV2Gateway } from "./app-control-v2-gateway";
import { ClientInfo, IAppControlService } from "./app-control.service";
import { LG_APP_LOAD_INDICATOR } from "./app-load-indicator.types";

@Injectable()
export class LgAppControlV2Service implements IAppControlService {
    private _gateway = inject(LgAppControlV2Gateway);
    private _loadIndicator = inject(LG_APP_LOAD_INDICATOR);
    private _userInfo = inject(LG_USER_INFO);
    private _userSettingsService = inject(UserSettingsService);

    // ----------------------------------------------------------------------------------
    // Fields
    private _clientsPromise?: Promise<ClientInfo[]>;
    private _canSwitchClientPromise?: Promise<boolean>;
    private _canSwitchContextPromise?: Promise<boolean>;

    // ----------------------------------------------------------------------------------

    get canSwitchClient(): Promise<boolean> {
        if (this._canSwitchClientPromise === undefined) {
            this._canSwitchClientPromise = this.getClients().then(data => data.length > 0);
        }

        return this._canSwitchClientPromise;
    }

    async getClients(): Promise<ClientInfo[]> {
        if (this._clientsPromise == null) {
            this._clientsPromise = lastValueFrom(this._gateway.selectClients());
        }

        return this._clientsPromise;
    }

    async switchClient(clientId: number): Promise<void> {
        this._loadIndicator.show();

        await lastValueFrom(
            this._userSettingsService.set({
                key: { storageId: WellKnownSettingCode.context },
                value: { clientId } as ContextSettings
            })
        );

        window.location.reload();
    }

    // ----------------------------------------------------------------------------------

    get canSwitchContext(): Promise<boolean> {
        if (this._canSwitchContextPromise == null) {
            this._canSwitchContextPromise = Promise.resolve(
                this._userInfo.hasPermission("switch_all_clients")
            );
        }

        return this._canSwitchContextPromise;
    }

    async showContextSelector(): Promise<void> {
        this._loadIndicator.show();

        await lastValueFrom(
            this._userSettingsService.set({
                key: { storageId: WellKnownSettingCode.context },
                value: null
            })
        );

        window.location.reload();
    }
}
