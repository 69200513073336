export abstract class EventsPluginBase {
    abstract supports(eventName: string): boolean;

    public addEventListener(
        element: HTMLElement,
        eventName: string,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        originalHandler: (event: Event) => void
    ): () => void {
        throw new Error(`Unsupported event target ${element.nodeName} for event ${eventName}`);
    }

    public addGlobalEventListener(
        element: string,
        eventName: string,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        handler: Function
    ): () => void {
        throw new Error(`Unsupported event target ${element} for event ${eventName}`);
    }
}
