import { InjectionToken, Provider } from "@angular/core";

import { ILgFormatterOptions } from "../implementation";
import { IntegerFormatterFactory } from "./lg-integer-formatter-factory.service";
import { MoneyFormatterFactory } from "./lg-money-formatter-factory.service";
import { MoneyScaledFormatterFactory } from "./lg-money-scaled-formatter-factory.service";
import { NumberFormatterFactory } from "./lg-number-formatter-factory.service";
import { PercentageFormatterFactory } from "./lg-percentage-formatter-factory.service";
import { LG_FORMATTER_FACTORIES } from "./formatter-factory-types";
import { LgFormatterFactoryService } from "./lg-formatter-factory.service";
import { NoFormatterFactory } from "./lg-no-formatter-factory.service";

export const LG_FORMATTER_OPTIONS = new InjectionToken<ILgFormatterOptions>("LG_FORMATTER_OPTIONS");

export function useFormatterDefaults(defaults: ILgFormatterOptions): Provider[] {
    return [
        {
            provide: LG_FORMATTER_OPTIONS,
            useValue: defaults
        },
        MoneyFormatterFactory,
        MoneyScaledFormatterFactory,
        PercentageFormatterFactory,
        NumberFormatterFactory,
        IntegerFormatterFactory,

        { provide: LG_FORMATTER_FACTORIES, useClass: MoneyFormatterFactory, multi: true },
        { provide: LG_FORMATTER_FACTORIES, useClass: MoneyScaledFormatterFactory, multi: true },
        { provide: LG_FORMATTER_FACTORIES, useClass: NoFormatterFactory, multi: true },
        { provide: LG_FORMATTER_FACTORIES, useClass: PercentageFormatterFactory, multi: true },
        { provide: LG_FORMATTER_FACTORIES, useClass: NumberFormatterFactory, multi: true },
        { provide: LG_FORMATTER_FACTORIES, useClass: IntegerFormatterFactory, multi: true },
        LgFormatterFactoryService
    ];
}
