/**
 * For given keyDown event, consume the corresponding keyUp. This can be used in situations,
 * where we listen to keydown, but we want to prevent the browser default event that normally happens in keyup
 * (sample usage: calendar)
 *
 * @param   keyDownEvent  the original keydown event to match
 */
export function eatOneKeyUpEvent(keyDownEvent: KeyboardEvent): void;
/**
 * For given keyCode, consume the corresponding keyUp. This can be used in situations,
 * where we listen to keydown, but we want to prevent the browser default event that normally happens in keyup
 * (sample usage: calendar)
 *
 * @param   keyCode  the keycode to match
 */
// eslint-disable-next-line @typescript-eslint/unified-signatures
export function eatOneKeyUpEvent(keyCode: number): void;
export function eatOneKeyUpEvent(param: KeyboardEvent | number): void {
    const originalKeyCode = typeof param === "number" ? param : param.keyCode;

    const unregister = (): void => {
        document.removeEventListener("keyup", keyUpListener, true);
        document.removeEventListener("visibilitychange", windowSwitchListener);
    };

    const keyUpListener = (event: KeyboardEvent): void => {
        if (event.keyCode === originalKeyCode) {
            event.stopPropagation();
            event.preventDefault();
            unregister();
        }
    };

    const windowSwitchListener = (): void => {
        if (document.hidden) unregister();
    };

    document.addEventListener("keyup", keyUpListener, true);
    document.addEventListener("visibilitychange", windowSwitchListener);
}
// note: we place it under helpers rather than @logex/framework/utilities, because it should be used only
// when you really know what you're doing.
