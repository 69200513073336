import { Directive, ElementRef, Input, OnInit, inject } from "@angular/core";

/**
 * Directive that ensures that the target component obtains the focus automatically when created.
 */
@Directive({
    selector: "[lgDefaultFocus]"
})
export class LgDefaultFocusDirective implements OnInit {
    private _el = inject(ElementRef);

    /**
     * Specifies if component is focused by default.
     */
    @Input({ alias: "lgDefaultFocus", required: true }) set defaultFocus(condition: boolean | "") {
        this._enabled = condition !== false;
    }

    get defaultFocus(): boolean {
        return this._enabled;
    }

    private _enabled = true;

    ngOnInit(): void {
        if (this._enabled) {
            window.setTimeout(() => this._el.nativeElement.focus());
        }
    }
}
