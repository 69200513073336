import { ChangeDetectionStrategy, Component, DestroyRef, inject } from "@angular/core";

import { LgTranslateService } from "@logex/framework/lg-localization";
import { IFilterExportDefinition } from "@logex/framework/lg-exports";

import { ComponentType } from "@angular/cdk/portal";
import { LgFilterSet } from "../lg-filterset";
import { IFilterDefinition } from "../filter-definition";
import { IFilterRenderer, IFilterRendererFactory } from "../filter-renderer";
import { FilterRendererComponentBase } from "../filter-renderer-component-base";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export interface ITextInputFilterDefinition extends IFilterDefinition {
    filterType: "textInput";
}

/**
 * Renderer for a filter based on text input.
 */
export class TextInputFilterRenderer implements IFilterRenderer {
    constructor(
        private _definition: ITextInputFilterDefinition,
        private _filters: any,
        lgTranslate: LgTranslateService
    ) {}

    createStorage(): void {
        if (this._filters[this._definition.storage!] === undefined) {
            this._filters[this._definition.storage!] = null;
        }
    }

    active(): boolean {
        return this._filters[this._definition.storage!] != null;
    }

    previewVisible(): boolean {
        return this._filters[this._definition.storage!] != null;
    }

    clear(): boolean {
        if (this._filters[this._definition.storage!] != null) {
            this._filters[this._definition.storage!] = null;
            return true;
        }
        return false;
    }

    getFilterLineComponent(): ComponentType<FilterRendererComponentBase<any, any>> {
        return TextInputFilterRendererLineComponent;
    }

    getPopupComponent(): ComponentType<FilterRendererComponentBase<any, any>> {
        return TextInputFilterRendererPopupComponent;
    }

    setValue(value: string | null): void {
        if (value?.trim() === "") {
            value = null;
        }
        this._filters[this._definition.storage!] = value;
    }

    getValue(): string {
        return this._filters[this._definition.storage!] ?? null;
    }

    getExportDefinition(): IFilterExportDefinition {
        return {
            name: this._definition.name ?? "",
            activeFn: () => this.active(),
            exportFn: () => [this.getValue()]
        };
    }

    serialize(): string | null {
        if (!this.active()) return null;
        return this._filters[this._definition.storage!];
    }

    deserialize(state: string): boolean {
        const oldState = this._filters[this._definition.storage!];
        this._filters[this._definition.storage!] = state;
        return state !== oldState;
    }
}

// Factory ---------------------------------------------------------------------------------------------------------
export class TextInputFilterRendererFactory<T> implements IFilterRendererFactory {
    readonly name: string = "textInput";

    create(
        definition: ITextInputFilterDefinition,
        filters: Record<string, any>,
        _definitions: IFilterDefinition[],
        filterSet: LgFilterSet
    ): IFilterRenderer {
        return new TextInputFilterRenderer(definition, filters, filterSet.lgTranslate);
    }
}

// Line template  --------------------------------------------------------------------------------------------------
@Component({
    standalone: false,
    selector: "lg-text-input-filter-renderer-line",
    // eslint-disable-next-line @angular-eslint/component-max-inline-declarations
    template: `
        <label>{{ _definition.label }}:</label>
        <div class="control">
            <input
                lgSelectOnFocus
                type="text"
                class="input"
                [value]="_renderer.getValue()"
                (input)="_change($event)"
            />
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextInputFilterRendererLineComponent extends FilterRendererComponentBase<
    ITextInputFilterDefinition,
    TextInputFilterRenderer
> {
    _change(event: Event): void {
        this._renderer.setValue((event.target as HTMLInputElement).value);
        this._triggerChange();
    }
}

// Popup template --------------------------------------------------------------------------------------------------
@Component({
    standalone: false,
    selector: "lg-text-input-filter-renderer-popup",
    // eslint-disable-next-line @angular-eslint/component-max-inline-declarations
    template: `
        <div class="header">
            {{ _definition.name }}
        </div>
        <input
            lgDefaultFocus
            lgSelectOnFocus
            type="text"
            class="input"
            [value]="_renderer.getValue()"
            (change)="_change($event)"
        />
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextInputFilterRendererPopupComponent<T> extends FilterRendererComponentBase<
    ITextInputFilterDefinition,
    TextInputFilterRenderer
> {
    _change(event: Event): void {
        this._renderer.setValue((event.target as HTMLInputElement).value);
        this._triggerChange();
    }
}
