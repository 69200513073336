<div class="lg-slideout__underlay" *ngIf="!_pinned && _expanded" (click)="_hide()"></div>

<lg-slideout-holder
    [variant]="variant"
    [width]="width"
    [expanded]="_expanded"
    [pinned]="_pinned"
    [hidePin]="_hidePin"
    (pinToggled)="_togglePin()"
    (hidden)="hidden.emit()"
>
    <lg-slideout-holder-header [slideoutApi]="slideoutApi" (pinToggled)="pinToggled.emit()">
    </lg-slideout-holder-header>
    <ng-container *ngIf="_template">
        <ng-template
            *ngTemplateOutlet="_template; context: { $implicit: _templateContext }"
        ></ng-template>
    </ng-container>

    <ng-content *ngIf="!_template"></ng-content>
</lg-slideout-holder>
