import { Directive, TemplateRef, OnInit, OnDestroy } from "@angular/core";

import type { IHeaderFooterTemplate } from "../types";

import { LgPivotTableComponent } from "../lg-pivot-table.component";

@Directive({
    selector: "[lgPivotTableHeaderDef]"
})
export class LgPivotTableHeaderDefDirective
    implements IHeaderFooterTemplate<any>, OnInit, OnDestroy
{
    public constructor(
        private _table: LgPivotTableComponent,
        private _template: TemplateRef<any>
    ) {
        // empty
    }

    public get template(): TemplateRef<any> {
        return this._template;
    }

    public ngOnInit(): void {
        this._table.addHeaderTemplate(this);
    }

    public ngOnDestroy(): void {
        this._table.removeHeaderTemplate(this);
    }
}
