import { IAppInfo } from "./app-info";

export class EmptyAppInfo implements IAppInfo {
    environment = "dev";
    fullAppName = "";
    productId = "";
    toolInstanceName = "";
    versionNumber = "1.0.0";
    overrideCurrencyPrefix = "";
    overrideCurrencySuffix = "";

    doNotDoGaTracking = (): boolean => false;
    isProduction = (): boolean => false;
}
