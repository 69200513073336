import { inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { ServerGatewayBase } from "../base/ServerGatewayBase";
import { ClientInfo } from "./app-control.service";
import { LG_APP_CONFIGURATION } from "./app-configuration";

@Injectable({ providedIn: "root" })
export class LgAppControlV2Gateway extends ServerGatewayBase {
    constructor() {
        super();
        this._setBaseUrl(inject(LG_APP_CONFIGURATION).applicationRoot);
    }

    selectClients(): Observable<ClientInfo[]> {
        return this._postQuery<ClientInfo[]>("boot/clients");
    }
}
