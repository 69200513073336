<div class="lg-range-slider__main">
    <div class="lg-range-slider__background-track">
        <div
            #focusHandle
            class="lg-range-slider__focus-handle"
            [style.left.%]="_focusHandlePosition"
        ></div>
    </div>

    <div class="lg-range-slider__background-bar"></div>
    <div class="lg-range-slider__active-bar">
        <div
            class="lg-range-slider__active-bar__strip"
            [style.width.%]="_stripWidth"
            [style.left.%]="min"
        ></div>
    </div>
    <div class="lg-range-slider__ruler" [style.visibility]="hideTicks ? 'hidden' : 'visibile'">
        <div
            *ngFor="let item of _tickers; let index = index"
            [ngClass]="{
                'lg-range-slider__ruler__tick': true,
                'lg-range-slider__ruler__tick--highlighted': item.css === 'highlight',
                'lg-range-slider__ruler__tick--zero': item.css === 'zero',
                'lg-range-slider__ruler__tick--active': _state >= item.value
            }"
            [style.left.%]="item.position"
        ></div>
    </div>
    <div #track class="lg-range-slider__track">
        <a
            href="#"
            #handle
            class="lg-range-slider__track__handle lg-range-slider__track__handle--from"
            [attr.disabled]="disabled"
            [style.left.%]="_fromHandlePosition"
            (keydown)="_onKeyDown($event)"
            (mousedown)="_onMouseDown()"
        >
            <div class="lg-range-slider__track__handle__element"></div>
            <div
                #tooltip
                class="lg-tooltip lg-tooltip--simple lg-tooltip--simple lg-range-slider__track__handle__tooltip lg-range-slider__track__handle__tooltip--from"
            >
                {{ _handleTooltip }}
                <div class="lg-range-slider__track__handle__tooltip__arrow-holder"></div>
            </div>
        </a>
    </div>
</div>
