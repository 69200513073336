import {
    Directive,
    ElementRef,
    HostListener,
    inject,
    Input,
    OnChanges,
    SimpleChanges,
    AfterViewInit,
    Renderer2,
    OnDestroy
} from "@angular/core";
import { LgSimpleTooltipDirective } from "./lg-simple-tooltip.directive";
import { LgObserveSizeService } from "../behavior";
import { Subject, takeUntil } from "rxjs";

/**
 * This tooltip only shows up if the content is overflowing
 * Works with one line ellipsis as well as with more line ellipsis -
 * see the LgSimpleOverflowTooltip story in the lg-tooltip-directive.stories.ts for examples.
 */

@Directive({
    selector: "[lgSimpleOverflowTooltip]",
    hostDirectives: [
        {
            directive: LgSimpleTooltipDirective,
            inputs: ["tooltipClass", "tooltipPosition", "tooltipOptions", "tooltipParams"]
        }
    ]
})
export class LgSimpleOverflowTooltipDirective implements OnChanges, AfterViewInit, OnDestroy {
    private _elementRef = inject(ElementRef);
    public lgSimpleTooltip = inject(LgSimpleTooltipDirective);
    private _renderer = inject(Renderer2);
    private _resizeObserver = inject(LgObserveSizeService);

    private readonly _destroyed = new Subject<void>();

    @Input() lgSimpleOverflowTooltip: string | undefined;

    constructor() {
        this._resizeObserver
            .observe(this._elementRef, this._renderer)
            .change({ auditTime: 0, type: "all" })
            .pipe(takeUntil(this._destroyed))
            .subscribe(() => this._updateTooltip());
    }

    @HostListener("window:resize", ["$event.target"])
    setToolTip(): void {
        this._updateTooltip();
    }

    ngAfterViewInit(): void {
        this._updateTooltip();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.lgSimpleOverflowTooltip) this._updateTooltip();
    }

    ngOnDestroy(): void {
        this._destroyed.next();
        this._destroyed.complete();
    }

    private _updateTooltip(): void {
        if (
            this._elementRef.nativeElement.offsetHeight <
                this._elementRef.nativeElement.scrollHeight ||
            this._elementRef.nativeElement.offsetWidth < this._elementRef.nativeElement.scrollWidth
        ) {
            if (this.lgSimpleOverflowTooltip)
                this.lgSimpleTooltip.content = this.lgSimpleOverflowTooltip;
            else this.lgSimpleTooltip.content = this._elementRef.nativeElement.innerText;
        } else {
            this.lgSimpleTooltip.content = "";
        }
    }
}
