// this whole thing is copied from latest AngularJS 1.7.0 - 18/06/2018
// implementation $httpParamSerializerProvider in http.js

export function ngParamSerializer(params: Record<any, any>): string {
    if (!params) return "";
    const parts: string[] = [];
    forEachSorted(params, function (value: unknown, key: string) {
        if (value === null || isUndefined(value) || isFunction(value)) return;
        if (isArray(value)) {
            forEach(value, function (v: any) {
                parts.push(encodeUriQuery(key) + "=" + encodeUriQuery(serializeValue(v)!));
            });
        } else {
            parts.push(encodeUriQuery(key) + "=" + encodeUriQuery(serializeValue(value)!));
        }
    });
    return parts.join("&");
}

function encodeUriQuery(val: string, pctEncodeSpaces?: undefined): string {
    return encodeURIComponent(val)
        .replace(/%40/gi, "@")
        .replace(/%3A/gi, ":")
        .replace(/%24/g, "$")
        .replace(/%2C/gi, ",")
        .replace(/%3B/gi, ";")
        .replace(/%20/g, pctEncodeSpaces ? "%20" : "+");
}

function serializeValue(v: any): string | null {
    if (isObject(v)) {
        return isDate(v) ? v.toISOString() : toJson(v)!;
    }
    return v ?? null;
}

function forEachSorted(obj: Record<any, any>, iterator: any, context?: unknown): string[] {
    const keys = Object.keys(obj).sort();
    for (const key of keys) {
        iterator.call(context, obj[key], key);
    }
    return keys;
}

function forEach(obj: Record<any, any>, iterator: any, context?: unknown): any {
    let key, length;
    if (obj) {
        if (isFunction(obj)) {
            for (key in obj) {
                if (
                    key !== "prototype" &&
                    key !== "length" &&
                    key !== "name" &&
                    Object.prototype.hasOwnProperty.call(obj, key)
                ) {
                    iterator.call(context, (obj as any)[key], key, obj);
                }
            }
        } else if (isArray(obj) || isArrayLike(obj)) {
            const isPrimitive = typeof obj !== "object";
            for (key = 0, length = obj.length; key < length; key++) {
                if (isPrimitive || key in obj) {
                    iterator.call(context, obj[key], key, obj);
                }
            }
        } else if (obj.forEach && obj.forEach !== forEach) {
            obj.forEach(iterator, context, obj);
        } else if (isBlankObject(obj)) {
            // createMap() fast path --- Safe to avoid hasOwnProperty check because prototype chain is empty
            // eslint-disable-next-line guard-for-in
            for (key in obj) {
                iterator.call(context, obj[key], key, obj);
            }
        } else if (typeof obj.hasOwnProperty === "function") {
            // Slow path for objects inheriting Object.prototype, hasOwnProperty check needed
            for (key in obj) {
                if (Object.prototype.hasOwnProperty.call(obj, key)) {
                    iterator.call(context, obj[key], key, obj);
                }
            }
        } else {
            // Slow path for objects which do not have a method `hasOwnProperty`
            for (key in obj) {
                if (Object.hasOwnProperty.call(obj, key)) {
                    iterator.call(context, obj[key], key, obj);
                }
            }
        }
    }
    return obj;
}

function isObject(value: unknown): value is object {
    return value !== null && typeof value === "object";
}
function isDate(value: unknown): value is Date {
    return toString.call(value) === "[object Date]";
}
function isUndefined(value: unknown): value is undefined {
    return typeof value === "undefined";
}
function isFunction(value: unknown): value is Function {
    return typeof value === "function";
}
function isArray(arr: unknown): arr is any[] {
    return Array.isArray(arr) || arr instanceof Array;
}
function isBlankObject(value: unknown): boolean {
    return value !== null && typeof value === "object" && !Object.getPrototypeOf(value);
}
function isWindow(obj: any): obj is Window {
    return obj && obj.window === obj;
}
function isNumber(value: unknown): value is number {
    return typeof value === "number";
}
function isString(value: unknown): value is string {
    return typeof value === "string";
}

function isArrayLike(obj: Record<any, any>): boolean {
    // `null`, `undefined` and `window` are not array-like
    if (obj == null || isWindow(obj)) return false;
    // arrays, strings and jQuery/jqLite objects are array like
    // * jqLite is either the jQuery or jqLite constructor function
    // * we have to check the existence of jqLite first as this method is called
    // via the forEach method when constructing the jqLite object in the first place
    // if (isArray(obj) || isString(obj) || (jqLite && obj instanceof jqLite)) return true;
    if (isArray(obj) || isString(obj)) return true;
    // Support: iOS 8.2 (not reproducible in simulator)
    // "length" in obj used to prevent JIT error (gh-11508)
    const length = "length" in Object(obj) && obj.length;
    // NodeList objects (with `item` method) and
    // other objects with suitable length characteristics are array-like
    return (
        isNumber(length) && ((length >= 0 && length - 1 in obj) || typeof obj.item === "function")
    );
}

function toJsonReplacer(key: string, value: any): any {
    let val = value;
    if (typeof key === "string" && key.charAt(0) === "$" && key.charAt(1) === "$") {
        val = undefined;
    } else if (isWindow(value)) {
        val = "$WINDOW";
    } else if (value && window.document === value) {
        val = "$DOCUMENT";
    }
    return val;
}

function toJson(obj: object, pretty?: number): string | undefined {
    if (isUndefined(obj)) return undefined;
    if (!isNumber(pretty)) {
        pretty = pretty ? 2 : undefined;
    }
    return JSON.stringify(obj, toJsonReplacer, pretty);
}
