import ldIndexOf from "lodash-es/indexOf";
import { Directive, HostListener, ElementRef, Renderer2, inject } from "@angular/core";
import { TAB, DOWN_ARROW, UP_ARROW } from "@angular/cdk/keycodes";

// TODO: pivot support
@Directive({
    selector: "[lgTableInputNavigator]"
})
// eslint-disable-next-line @typescript-eslint/naming-convention
export class lgTableInputNavigatorDirective {
    private _elementRef = inject(ElementRef);
    private _renderer = inject(Renderer2);

    @HostListener("keyup.enter", ["$event"])
    public _onEnterClick(event: KeyboardEvent): void {
        if (this._disabled) return;
        event.preventDefault();
        this._goBy(0, event.shiftKey ? -1 : 1);
    }

    @HostListener("keydown", ["$event"])
    public _onKeyDown(event: KeyboardEvent): boolean {
        if (this._disabled) return true;

        if (event.keyCode === TAB) {
            this._goBy(event.shiftKey ? -1 : 1, 0);
            return false;
        } else if (event.keyCode === DOWN_ARROW) {
            this._goBy(0, 1);
            return false;
        } else if (event.keyCode === UP_ARROW) {
            this._goBy(0, -1);
            return false;
        }

        return true;
    }

    private _row: HTMLElement | null = null;
    private _table: HTMLElement | null = null;
    private _columnIndex = 0;
    private _inputCount = 0;
    private _disabled = false;

    private _initialize(): void {
        if (this._row || this._disabled) return;

        this._row = this._elementRef.nativeElement.closest(".table__row");
        if (!this._row) {
            this._disabled = true;
            return;
        }

        const inputs = this._row.querySelectorAll("[lgTableInputNavigator]");
        this._columnIndex = ldIndexOf(inputs, this._elementRef.nativeElement);
        this._inputCount = inputs.length;
        this._table = this._row.closest(".lg-pivot-table__body");
        if (!this._table) {
            this._disabled = true;
        }
    }

    private _goBy(inputDelta: number, lineDelta: number): void {
        this._initialize();
        if (this._disabled) return;

        const allRows = this._table!.querySelectorAll<HTMLElement>(".table__row");
        const rowIndex = ldIndexOf(allRows, this._row);

        let newColumnIndex = this._columnIndex + inputDelta;
        if (newColumnIndex === -1) {
            lineDelta -= 1;
            newColumnIndex = this._inputCount - 1;
        } else if (newColumnIndex === this._inputCount) {
            lineDelta += 1;
            newColumnIndex = 0;
        }

        this._elementRef.nativeElement.blur();

        let tries = 10;
        while (--tries) {
            const targetRow = allRows[rowIndex + lineDelta];

            if (targetRow) {
                const inputs = targetRow.querySelectorAll("[lgTableInputNavigator]");
                if (inputs.length === 0) {
                    lineDelta += Math.sign(lineDelta);
                    continue;
                }

                const targetInput = inputs[Math.min(inputs.length - 1, newColumnIndex)];

                if (targetInput) {
                    this._renderer.addClass(targetRow, "lg-contains-focus");
                    (targetInput as HTMLElement).focus();
                }
            }
            break;
        }
    }
}
