import { Pipe, PipeTransform, inject, isDevMode } from "@angular/core";
import {
    ILgFormatter,
    NumberFormatterFactory,
    PercentageFormatterFactory,
    MoneyFormatterFactory,
    MoneyScaledFormatterFactory,
    ILgFormatterOptions,
    IntegerFormatterFactory,
    ILgFormatterFactory
} from "@logex/framework/core";
import { Nullable } from "@logex/framework/types";

// TODO logic to formatting service

export type IFmtTypeOptions = Partial<
    Pick<
        ILgFormatterOptions,
        "decimals" | "forceSign" | "zeroDash" | "viewScale" | "hideScaleSymbol"
    >
> & {
    ceiling?: number | null | undefined;
};

const defaultOptions: ILgFormatterOptions = {
    decimals: 2
};

@Pipe({
    name: "fmtType",
    pure: true
})
export class LgFormatTypePipe implements PipeTransform {
    private _integerFormatterFactory = inject(IntegerFormatterFactory);
    private _numberFormatterFactory = inject(NumberFormatterFactory);
    private _percentageFormatterFactory = inject(PercentageFormatterFactory);
    private _moneyFormatterFactory = inject(MoneyFormatterFactory);
    private _moneyScaledFormatterFactory = inject(MoneyScaledFormatterFactory);

    private _formatter?: ILgFormatter<number>;

    static hasSentPercentSimpleWarning = false;

    public transform(
        input: Nullable<string | number>,
        type: string,
        options?: IFmtTypeOptions
    ): string;

    /**
     * @deprecated Use options variant instead
     */
    public transform(
        input: Nullable<string | number>,
        type: string,
        decimals?: number,
        forceSign?: boolean,
        zeroDash?: boolean
    ): string;

    public transform(
        input: Nullable<string | number>,
        type: string,
        options?: number | IFmtTypeOptions,
        forceSign?: boolean,
        zeroDash?: boolean
    ): string {
        const parameterOptions = this._getParameterOptions(options, forceSign, zeroDash);
        let formatterFactory: ILgFormatterFactory;

        switch ((type || "").toLowerCase()) {
            case "int":
                formatterFactory = this._integerFormatterFactory;
                break;

            case "float":
            case "number":
                formatterFactory = this._numberFormatterFactory;
                break;

            case "percent":
            case "percentage":
                formatterFactory = this._percentageFormatterFactory;
                break;

            case "percentSimple":
                if (isDevMode() && !LgFormatTypePipe.hasSentPercentSimpleWarning) {
                    console.warn(
                        "The fmtType:'percentSimple' was deprecated, please update the code to use 'percent' type instead"
                    );
                    LgFormatTypePipe.hasSentPercentSimpleWarning = true;
                }

                formatterFactory = this._percentageFormatterFactory;
                break;

            case "eur":
            case "money":
                formatterFactory = this._moneyFormatterFactory;
                break;

            case "eurscaled":
            case "moneyscaled":
                formatterFactory = this._moneyScaledFormatterFactory;
                break;

            case "text":
            case "none":
                return "" + input;

            default:
                return "FMTERROR (unknown type)";
        }
        this._formatter = formatterFactory.create(defaultOptions);

        if (typeof input === "string") {
            input = +input;
        }
        return this._formatter.format(input, parameterOptions);
    }

    private _getParameterOptions(
        options?: number | IFmtTypeOptions,
        forceSign?: boolean,
        zeroDash?: boolean
    ): ILgFormatterOptions {
        let ceiling = 0;
        let parameterOptions: ILgFormatterOptions = {};
        if (typeof options === "object") {
            parameterOptions = options;
            ceiling = options.ceiling || 0;
        } else if (typeof options === "number") {
            parameterOptions = { decimals: options };
        }

        if (forceSign !== undefined) {
            parameterOptions.forceSign = forceSign;
        }
        if (zeroDash !== undefined) {
            parameterOptions.zeroDash = zeroDash;
        }

        if (ceiling > 0) {
            parameterOptions.max = ceiling;
        } else if (ceiling < 0) {
            parameterOptions.min = ceiling;
        }

        return parameterOptions;
    }
}
