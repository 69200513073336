import {
    Component,
    Input,
    HostBinding,
    ViewEncapsulation,
    ChangeDetectionStrategy
} from "@angular/core";
import { toBoolean } from "@logex/framework/utilities";
import { NgClass, NgIf } from "@angular/common";
import { LgTranslatePipe } from "@logex/framework/lg-localization";
import { LgIconComponent } from "./lg-icon/lg-icon.component";
import { LgSpinnerComponent } from "./lg-spinner";

@Component({
    selector: "lg-submit-button",
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    // eslint-disable-next-line @angular-eslint/component-max-inline-declarations
    template: `
        <button
            type="submit"
            [attr.tabindex]="disabled ? -1 : tabIndex"
            [disabled]="disabled || _working"
            class="button {{ buttonClass }}"
            [ngClass]="{
                disabled: disabled,
                'no-text': !text && !textLc,
                'cursor-no-click': _working
            }"
            title="{{ titleLc ? (titleLc | lgTranslate) : buttonTitle }}"
        >
            <lg-icon [icon]="icon" [inline]="true" [hidden]="!icon || _working"></lg-icon>

            <lg-spinner *ngIf="_working" class="button__spinner"></lg-spinner>

            <span [innerHTML]="textLc ? (textLc | lgTranslate) : text"></span>
        </button>
    `,
    imports: [NgClass, LgTranslatePipe, LgIconComponent, LgSpinnerComponent, NgIf],
    host: {
        "[class.lg-button]": "true"
    }
})
export class LgSubmitButtonComponent {
    /**
     * Specifies if button is disabled.
     */
    @Input("isDisabled") disabled = false;

    /**
     * Button label. Used if `textLc` is not specified.
     */
    @Input() text = "";

    /**
     * HTML title attribute of button. Used if `titleLc` is not specified.
     */
    @Input("title") buttonTitle: string | null = null;

    @Input() tabIndex = 0;

    /**
     * Button icon.
     */
    @Input() icon: string | null = null;

    /**
     * Apply css class to the button element
     */
    @Input() buttonClass?: string;

    /**
     * Localized button label. If not specified `text` is used.
     */
    @Input() textLc?: string;

    /**
     * Localized HTML title attribute of button. If not specified `title` is used.
     */
    @Input() titleLc?: string;

    /**
     * Show working spinner.
     */
    @Input() set working(value: any) {
        this._working = toBoolean(value, false);
    }

    get working(): any {
        return this._working;
    }

    @HostBinding("attr.title") hostTitle: string | null = null;

    _working = false;
}
