import ldOrderBy from "lodash-es/orderBy";

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    inject,
    Input,
    OnDestroy,
    OnInit
} from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { toBoolean } from "@logex/framework/utilities";

import { LgSlideoutApi, LgSlideoutPanel, LgSlideoutVariant } from "./lg-slideout.types";
import { LgSlideoutService } from "./lg-slideout.service";
import { LG_FW_UI_STATE_SERVICE } from "../lg-fw-ui-state/lg-fw-ui-state.types";

@Component({
    selector: "lg-slideout-button-row",
    templateUrl: "./lg-slideout-button-row.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    styles: [":host { white-space: nowrap; }"]
})
export class LgSlideoutButtonRowComponent implements OnInit, OnDestroy {
    private _changeDetectorRef = inject(ChangeDetectorRef);
    private _slideoutService = inject(LgSlideoutService);
    private _uiState = inject(LG_FW_UI_STATE_SERVICE);

    /**
     * Slideout panel identifier (required).
     */
    @Input({ required: true }) slideoutPanelId!: string;

    /**
     * Slideout variant.
     *
     * @type {"left" | "right"}
     */
    @Input("variant") _variant: LgSlideoutVariant = "right";

    @Input() set disabled(val: boolean | "true" | "false") {
        this._disabled = toBoolean(val);
    }

    get disabled(): boolean {
        return this._disabled;
    }

    @Input() initializeAsPinned = false;

    @Input() initializeAsExpanded = false;

    @Input() set hidePin(value: boolean | "true" | "false") {
        this._hidePin = toBoolean(value);
    }

    get hidePin(): boolean {
        return this._hidePin;
    }

    private _hidePin = false;
    protected buttonVisible = false;
    protected _disabled = false;

    protected _destroyed$ = new Subject<void>();
    protected _pinned: boolean = this.initializeAsPinned;
    protected _expanded = false;
    protected _slideoutApi!: LgSlideoutApi;
    protected _availablePanels: LgSlideoutPanel[] = [];

    async ngOnInit(): Promise<void> {
        this._slideoutApi = this._slideoutService.api(this.slideoutPanelId, this._variant);

        this._slideoutApi.state$.pipe(takeUntil(this._destroyed$)).subscribe(state => {
            this._pinned = state.pinned;
            this._expanded = state.expanded;

            const buttonVisible = !state.expanded || !state.pinned;
            Promise.resolve().then(() => (this.buttonVisible = buttonVisible));
            this._changeDetectorRef.markForCheck();
        });

        this._slideoutService.availablePanelsState$
            .pipe(takeUntil(this._destroyed$))
            .subscribe(panels => {
                this._availablePanels = ldOrderBy(Object.values(panels), x => x.order ?? 0);
                this._changeDetectorRef.markForCheck();
            });

        let initializeAsPinned = this.initializeAsPinned;

        const uiStatePinned = await this._uiState.getSidebarPinned(this._variant);
        if (uiStatePinned != null) {
            initializeAsPinned = uiStatePinned;
        }

        const panelTemplate =
            this._slideoutService.availablePanels[this._slideoutService.currentPanelVariant ?? ""]
                ?.panelTemplate;

        this._slideoutApi.set({
            expanded: this._expanded || this.initializeAsExpanded || initializeAsPinned,
            pinned: this._pinned || initializeAsPinned,
            hidePin: this._hidePin,
            template: panelTemplate
        });
    }

    _keepKeyOrder(): number {
        return 0;
    }

    _togglePanel(panel: LgSlideoutPanel): void {
        this._slideoutApi.setTemplate(panel.panelTemplate);
        this._slideoutService.setActivePanel(panel.panelVariant);
        this._slideoutApi.toggleExpanded();
    }

    ngOnDestroy(): void {
        this._slideoutApi.set({
            expanded: false,
            pinned: this._pinned
        });
        this._destroyed$.next();
        this._destroyed$.complete();
    }
}
