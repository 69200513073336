import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LgSidebarAccountComponent } from "../panels/lg-sidebar-account.component";
import { useTranslationNamespace } from "@logex/framework/lg-localization";

/**
 * Sidebar account widget component.
 * Designed to encapsulate the impersonation mode logic.
 * Namely, automatically change sidebar button appearance depends on user mode, hide panel title.
 */
@Component({
    selector: "lg-sidebar-account-widget",
    // eslint-disable-next-line @angular-eslint/component-max-inline-declarations
    template: `
        <lg-sidebar-account
            *lgSidebarPanel="
                let sidebar;
                id: id;
                nameLc: nameLc;
                titleLc: false;
                icon: icon;
                isVisible: isVisible;
                top: top;
                order: order;
                nameAlwaysVisible: nameAlwaysVisible;
                badge: badge
            "
            [onlineLink]="onlineLink"
            [customLinks]="customLinks"
        ></lg-sidebar-account>
    `,
    viewProviders: [useTranslationNamespace("FW._Sidebar._Account")],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LgSidebarAccountWidgetComponent extends LgSidebarAccountComponent {
    /**
     * Identifier of the sidebar account panel to be registered.
     */
    @Input({ required: true }) id!: string;

    /**
     * Value to be shown on the sidebar account button as a badge.
     * If the value is 0, the badge will be hidden.
     *
     * @default 0
     */
    @Input() badge = 0;

    /**
     * Specifies if the account panel is visible.
     *
     * @default true
     */
    @Input() isVisible = true;

    /**
     * Specifies if the account name is visible always or only on hover.
     *
     * @default false
     */
    @Input() nameAlwaysVisible = false;

    /**
     * Specifies the name to be used for related sidebar button label.
     *
     * @default "FW._Sidebar._Account.Account"
     */
    @Input() nameLc = ".User";

    /**
     * Specifies order of the account sidebar button.
     *
     * @default 0
     */
    @Input() order = 0;

    /**
     * Specifies if the account sidebar button is placed on the top of the sidebar.
     *
     * @default true
     */
    @Input() top = true;

    get icon(): string {
        return this.impersonationMode ? "icon-impersonation" : "icon-account";
    }
}
