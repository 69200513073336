import { InjectionToken } from "@angular/core";

export const LG_HELP_TOOLTIP_CONFIG = new InjectionToken<IHelpTooltipConfig>("lgHelpTooltipConfig");

export interface IHelpTooltipConfig {
    appId: string;
    baseUrl?: string;
    isDisabled?: boolean;
}

export interface LgHelpTooltipsResponse {
    ok: boolean;
    tooltips: LgHelpTooltipItem[];
}

export interface LgHelpTooltipItem {
    id: string;
    title: string;
    link: string;
    text: string;
    status?: LgHelpTooltipStatusEnum;
}

export enum LgHelpTooltipStatusEnum {
    ERROR = "404"
}
