<div class="downtime-holder" *ngIf="_currentNotification !== undefined && _showNotification">
    <div class="downtime-holder__left-side">
        <div>
            <h2>{{ _getNotificationName() }}</h2>
        </div>
        <div class="downtime-holder__message">
            <p class="downtime-message">{{ _currentNotification.message }}</p>
        </div>
        <div *ngIf="!_currentNotification.hideButtonLc">
            <lg-button
                [buttonClass]="'button--condensed button--primary'"
                [text]="_getButtonText()"
                (click)="_openNotification()"
            ></lg-button>
        </div>
        <div>
            <lg-button
                *ngIf="_showDismissButton()"
                [buttonClass]="'button--condensed'"
                [text]="'.Dismiss' | lgTranslate"
                (click)="_dismissNotification()"
            ></lg-button>
        </div>
    </div>
    <div class="downtime-holder__right-side">
        <lg-icon
            class="downtime-holder__close-icon"
            [icon]="'icon-close'"
            [clickable]="true"
            (click)="_closeNotification()"
        ></lg-icon>
    </div>
</div>
