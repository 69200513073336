import {
    Component,
    ViewEncapsulation,
    ChangeDetectionStrategy,
    Input,
    OnInit,
    OnDestroy,
    ChangeDetectorRef,
    inject
} from "@angular/core";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import {
    ProcessedNavNode,
    LgNavigationService,
    LG_USER_INFO
} from "@logex/framework/lg-application";
import { LgRouterStateService } from "@logex/framework/ui-core";

import { ISidebar } from "../sidebar-context";

export interface ISettings {
    name: string;
    items: string[];
    expanded: boolean;
}

@Component({
    standalone: false,
    selector: "lg-sidebar-settings",
    templateUrl: "./lg-sidebar-settings.component.html",
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: "lg-sidebar-menu lg-sidebar-panel flexcol"
    }
})
export class LgSidebarSettingsComponent implements OnInit, OnDestroy {
    private _changeDetectorRef = inject(ChangeDetectorRef);
    private _navigationService = inject(LgNavigationService);
    private _router = inject(Router);
    private _routerState = inject(LgRouterStateService);
    private _userInfo = inject(LG_USER_INFO);

    /**
     * Sidebar handler (required).
     */
    @Input({ required: true }) sidebar!: ISidebar;

    _navigation: ProcessedNavNode[] = [];
    _currentUrl = "";
    _languageSwitchVisible = false;

    private readonly _destroyed$ = new Subject<void>();

    constructor() {
        this._routerState
            .route()
            .pipe(takeUntil(this._destroyed$))
            .subscribe(() => {
                this._updateCurrentUrl();
                this._changeDetectorRef.markForCheck();
            });
    }

    ngOnInit(): void {
        this._navigationService
            .getNavigation$("settings")
            .pipe(takeUntil(this._destroyed$))
            .subscribe(navigation => {
                this._navigation = navigation;
                for (const nav of this._navigation) {
                    if (nav.data) nav.data.expanded = true;
                }
                const current = this._navigationService.getCurrentNodePathSync();
                for (const item of current) {
                    if (item.data) {
                        item.data.expanded = true;
                    }
                }
                this._changeDetectorRef.markForCheck();
            });

        this._languageSwitchVisible = this._userInfo.hasPermission("switch_language");
    }

    ngOnDestroy(): void {
        this._destroyed$.next();
        this._destroyed$.complete();
    }

    _navigate(navNode: ProcessedNavNode): void {
        if (navNode.disabled) return;
        if (this.sidebar) {
            this.sidebar.dismissPanel();
        }
        this._navigationService.navigateTo(navNode);
    }

    private _updateCurrentUrl(): void {
        let url = this._navigationService.getUrlPrefix() + this._router.url;
        if (url[0] === "/") url = url.substring(1);
        const queryIndex = url.indexOf("?");
        if (queryIndex !== -1) {
            url = url.substring(0, queryIndex);
        }
        this._currentUrl = url;
    }
}
