import { Injectable, inject } from "@angular/core";

import { urlConcat } from "@logex/framework/utilities";
import { LgConsole } from "@logex/framework/core";

import { IVersion, VersionService } from "../version/version-service";
import { LG_APP_CONFIGURATION } from "../application/app-configuration";
import { LG_APP_SESSION } from "../application/app-session";
import { lastValueFrom } from "rxjs";

// ----------------------------------------------------------------------------------
//
export interface IScenario {
    id: number;
    name: string | null;
    clientId: number;
    description: string | null;
    isDefault: boolean;
    isLocked: boolean;
}

export interface IGetVersionsListResponse {
    list: IVersion[];
}

@Injectable({ providedIn: "root" })
export class ScenarioService<
    TScenario extends IScenario = IScenario
> extends VersionService<IVersion> {
    private _applicationConfiguration = inject(LG_APP_CONFIGURATION);
    private _lgConsole = inject(LgConsole);
    private _session = inject(LG_APP_SESSION);

    private _scenario?: TScenario;

    async load(clientId: number, scenarioId: number): Promise<TScenario | null> {
        try {
            const params = {} as any;
            if (clientId != null) params.clientId = clientId;
            if (scenarioId != null) params.scenarioId = scenarioId;

            this._scenario = await lastValueFrom(
                this.http.get<TScenario>(
                    urlConcat(this._applicationConfiguration.applicationRoot, "boot/scenario"),
                    { params }
                )
            );

            return this._scenario;
        } catch (err) {
            this._lgConsole.error(
                `Error getting scenario ${scenarioId} for client ${clientId}`,
                err
            );
            return null;
        }
    }

    // ----------------------------------------------------------------------------------
    // Compatibility with VersionService<IVersion>
    // Needed to make scenario dialog working w/o big changes to the dialog itself.
    // It is a temporary solution.
    // Version is never reloaded, only scenario information is republished.
    // Scenario is only loaded once: when the application is started

    override reload(): void {
        this._version.next({
            scenario_naam: this._scenario?.name,
            scenario_omschrijving: this._scenario?.description,
            is_primary: this._scenario?.isDefault,
            locked: this._scenario?.isLocked,
            ziekenhuiscode: this._session.clientId,
            id: this._session.scenarioId
        } as IVersion);
    }

    override set(_version: IVersion): void {
        throw Error("Not implemented");
    }
}
