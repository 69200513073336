export const isAnyPropInSimpleChanges = (
    propNames: string[],
    changes: { [propName: string]: any }
): boolean => {
    return propNames.reduce((previous: boolean, propName: string) => {
        if (previous) {
            return true;
        }

        return propName in changes;
    }, false);
};
