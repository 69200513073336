/* eslint-disable @typescript-eslint/no-this-alias */
import ldIsString from "lodash-es/isString";
import { coerceBooleanProperty, coerceNumberProperty } from "@angular/cdk/coercion";
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    HostBinding,
    inject,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    OnInit,
    Renderer2,
    TemplateRef,
    ViewChild
} from "@angular/core";
import * as d3 from "d3";

import { getCurrencyMetadata, LG_CURRENCY_CODE } from "@logex/framework/core";
import { toBoolean } from "@logex/framework/utilities";
import { LgSimpleChanges } from "@logex/framework/types";
import { BaseBarChartWithReferenceLineComponent } from "../shared/base-bar-chart-with-reference-line.component";
import { getRecommendedPosition } from "../shared/getRecommendedPosition";
import { WaterFallItem } from "./lg-waterfall-chart.types";
import { IExportableChart, LgChartExportContainerDirective } from "../shared/lg-chart-export";
import { IImplicitContext } from "../shared/lg-chart-template-context.directive";

const SPACE_FOR_Y_AXIS_LABELS = 50;
const SPACE_FOR_Y_AXIS_TITLE = 26;
const Y_RANGE_MULTIPLIER_WHEN_SHOWING_VALUE_LABELS = 1.15;
const X_SCALE_OUTER_PADDING = 0.2;

@Component({
    selector: "lg-waterfall-chart",
    template: `
        <div #chart></div>
        <ng-template #defaultTemplate [lgChartTemplateContextType]="this" let-context>
            <b>{{ context.name }}: </b>{{ this._numberFormat(context.value) }}
        </ng-template>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LgWaterFallChartComponent
    extends BaseBarChartWithReferenceLineComponent<WaterFallItem, WaterFallItem>
    implements OnInit, OnChanges, OnDestroy, AfterViewInit, IExportableChart
{
    private _currencyCode = inject(LG_CURRENCY_CODE);
    private _exportContainer = inject(LgChartExportContainerDirective, { optional: true });
    private _ngZone = inject(NgZone);
    private _renderer = inject(Renderer2);

    /**
     * Specifies maximum number of ticks on axis. Defaults to 10.
     *
     * @default 10
     */
    @Input() tickCount?: number;

    /**
     * Callback for providing data item values.
     */
    @Input() value?: (locals: any) => number;

    /**
     * Callback for providing data value names.
     */
    @Input() valueName?: (locals: any) => string;

    /**
     * Callback for providing the X-axis title. Defaults to "X axis title not defined".
     * Can receive empty string to hide X axis title entirely.
     */
    @Input() xAxisLabel?: (locals: any) => string;

    /**
     * Callback for specifying whether data item value is total or not.
     * Can receive empty string to hide X axis title entirely.
     */
    @Input() valueIsTotal?: (locals: any) => boolean;

    /**
     * Specifies chart bars colors.
     */
    @Input() colors?: string | string[];

    /**
     * Callback for providing the chart bars colors when hovered
     */
    @Input() hoverColorsFn?: (item: WaterFallItem | null) => string | string[];

    /**
     * Y-axis offset.
     *
     * @default 0
     */
    @Input() yOffset = 0;

    /**
     * Specifies which part of the group area is occupied by the spacing. Valid value is number from 0 to 1.
     *
     * @default 0.25
     */
    @Input() spacing?: number;

    /**
     * Specifies whether negative values are allowed or not.
     *
     * @default false
     */
    @Input() allowNegative?: boolean;

    /**
     * @optional
     * Callback for providing opacity of group bar column. Valid returned value is number from 0 to 1.
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    @Input() columnOpacity: any = () => 1;

    /**
     * @optional
     * Specifies whether X axis labels are visible or not. Defaults to true.
     *
     * @default true
     */
    @Input() showXAxisLabels = true;

    /**
     * Specifies whether X-axis labels are wrapped or not.
     *
     * @default false
     */
    @Input() wrapXAxisLabelsOnNewLine = false;

    /**
     * Specifies whether Y-axis labels are visible or not. Defaults to true.
     *
     * @default true
     */
    @Input() showYAxisLabel: boolean | string = true;

    /**
     * Specifies the Y-axis title. Defaults to "Y axis title not defined".
     * Can receive empty string to hide Y axis title entirely.
     */
    @Input() yAxisLabel = "";
    @Input() showxAxisLabels = false;

    @HostBinding("class") get class(): string {
        return "lg-waterfall-chart";
    }

    @HostBinding("style.maxWidth.px") get maxWidth(): number {
        return this.width;
    }

    @HostBinding("style.maxHeight.px") get maxHeight(): number {
        return this.height;
    }

    @ViewChild("chart", { static: true }) private _chartDivRef!: ElementRef;
    @ViewChild("defaultTemplate", { static: true })
    override _defaultTooltipTemplate!: TemplateRef<IImplicitContext<WaterFallItem>>;

    protected _yScale!: d3.ScaleLinear<number, number>;
    private _xAxis!: d3.Axis<any>;
    private _yAxis!: d3.Axis<any>;
    private _spacing!: number;
    private _names: string[] = [];
    private _showXAxisLabels?: boolean;
    private _xScale!: d3.ScaleBand<any>;
    private _xAxisG!: d3.Selection<any, any, any, any>;
    private _xAxisLabelG!: d3.Selection<any, any, any, any>;
    private _yAxisG!: d3.Selection<any, any, any, any>;
    private _yAxisGridG!: d3.Selection<any, any, any, any>;
    private _labelG!: d3.Selection<any, any, any, any>;
    private _xAxisLabelsG!: d3.Selection<any, any, any, any>;
    private _yAxisGrid!: d3.Axis<any>;
    private _yMin = 0;
    private _yMax = 0;
    private _hasLabels = false;
    private _oldYOffset: number | null = null;
    private _yAxisLabel: d3.Selection<any, any, any, any> | null = null;
    private _lastMouseX = 0;
    private _lastMouseY = 0;
    private _trackListener!: () => void;

    constructor() {
        super();
        this._trackMousePosition();
    }

    ngOnInit(): void {
        super._onInit();

        this._convertData();
        this._propsToState();
        this._create();
        this._updateSize();
        this._render(false);
        this._initializeTooltip();

        this._initialized = true;
    }

    ngOnChanges(changes: LgSimpleChanges<LgWaterFallChartComponent>): void {
        if (!this._initialized) {
            return;
        }

        super._onBaseChartChanges(changes);

        let needsRender = false;
        let renderImmediate = false;
        let renderAsTransition = false;

        if (
            changes.colors ||
            changes.hoverColorsFn ||
            changes.data ||
            changes.valueName ||
            changes.xAxisLabel ||
            changes.valueIsTotal ||
            changes.value ||
            changes.columnOpacity
        ) {
            this._convertData();
            needsRender = true;
            renderAsTransition = true;
        }

        if (changes.width || changes._height || changes.spacing) {
            this._sizePropsToState();
            this._updateSize();
            needsRender = true;
            renderImmediate = true;
        }

        if (changes.yAxisLabel && this._chart && this._yAxisLabel) {
            this._yAxisLabel.text(this.yAxisLabel);
        }

        if (changes.showYAxisLabel && this._chart) {
            if (changes.showYAxisLabel.currentValue) {
                this._createyAxisLabel();
            } else {
                this._yAxisLabel?.remove();
                this._yAxisLabel = null;
            }
            this._sizePropsToState();
            this._updateSize();
            needsRender = true;
            renderImmediate = true;
        }

        if (changes.tickCount && this._chart) {
            const count = coerceNumberProperty(this.tickCount, 10);
            this._yAxis.ticks(count);
            this._yAxisGrid.ticks(count);
            needsRender = true;
        }

        if (changes.showXAxisLabels && this._chart) {
            this.showXAxisLabels = coerceBooleanProperty(this.showXAxisLabels);
            needsRender = true;
        }

        if (changes.yOffset && this._chart) {
            this.yOffset = coerceNumberProperty(changes.yOffset.currentValue, 0);
            needsRender = true;
        }

        if (changes.allowNegative && this._chart) {
            this.allowNegative = coerceBooleanProperty(changes.allowNegative.currentValue);
            needsRender = true;
            renderImmediate = true;
        }

        if (changes.referenceLine && this._chart) {
            needsRender = true;
        }

        if (needsRender) {
            if (renderAsTransition) {
                d3.transition()
                    .duration(500)
                    .each(() => this._render(false));
            } else {
                this._render(renderImmediate);
            }
        }
    }

    ngAfterViewInit(): void {
        this._exportContainer?.register(this);
    }

    ngOnDestroy(): void {
        this._exportContainer?.unregister(this);
        super._onDestroy();
    }

    getHtmlElement(): HTMLElement {
        return this._chartDivRef.nativeElement;
    }

    getSvgElement(): SVGElement {
        return this._svg.node();
    }

    private _propsToState(): void {
        this._sizePropsToState();
    }

    private _sizePropsToState(): void {
        this._spacing = this.spacing || 0.25;
        this._margin = { top: 20, right: 16, bottom: 0, left: 16 };
        this._width =
            this.width -
            this._margin.left! -
            this._margin.right! -
            SPACE_FOR_Y_AXIS_LABELS -
            (this._yAxisLabel ? SPACE_FOR_Y_AXIS_TITLE : 0);
        this._setBottomMargin();
        this._height = this.height - this._margin.top! - (this._margin.bottom ?? 0);
    }

    private _setBottomMargin(): void {
        if (!this.showXAxisLabels) {
            this._margin = { ...this._margin, bottom: 20 };
            return;
        }

        const bottom = this.wrapXAxisLabelsOnNewLine
            ? this._getBottomMarginWhenWrappingLines()
            : 38;

        this._margin = { ...this._margin, bottom };
    }

    private _updateSize(): void {
        this._svgG.attr(
            "transform",
            `translate( ${
                (this._margin.left ?? 0) +
                SPACE_FOR_Y_AXIS_LABELS +
                (this._yAxisLabel ? SPACE_FOR_Y_AXIS_TITLE : 0)
            } , ${this._margin.top} )`
        );
        this._svg.attr("width", this.width);
        this._svg.attr("height", this.height);
        this._xScale
            .rangeRound([0, this._width])
            .paddingInner(this._spacing)
            .paddingOuter(X_SCALE_OUTER_PADDING);
        this._yScale.range([this._height, 0]);
        this._xAxisG.attr("transform", `translate( 0, ${this._height} )`);
        if (this._yAxisLabel) {
            this._yAxisLabel.attr("y", -(this._margin.left ?? 0) - 54);
            this._yAxisLabel.attr("x", -this._height);
        }
    }

    private _create(): void {
        this._svg = d3.select(this._chartDivRef.nativeElement).append("svg");
        this._svgG = this._svg.append("g");

        this._xScale = d3
            .scaleBand()
            .rangeRound([0, this._width])
            .paddingInner(this._spacing)
            .paddingOuter(X_SCALE_OUTER_PADDING);
        this._yScale = d3.scaleLinear().range([this._height, 0]);

        this._yAxisGridG = this._svgG.append("g").attr("class", "lg-waterfall-chart__y-axis-grid");

        this._yAxisGrid = d3
            .axisRight(this._yScale)
            .scale(this._yScale)
            .tickPadding(0)
            .tickFormat(() => "")
            .ticks(this.tickCount || 10);

        this._createReferenceLine();

        this._chart = this._svgG.append("g").attr("class", "lg-waterfall-chart__bars");

        this._yAxis = d3
            .axisLeft<any>(this._yScale)
            .scale(this._yScale)
            .tickSizeInner(0)
            .tickPadding(8)
            .tickFormat(this._numberFormat)
            .ticks(this.tickCount || 10);

        this._xAxis = d3
            .axisBottom(this._xScale)
            .scale(this._xScale)
            .tickSize(0)
            .tickFormat(() => "");

        this._yAxisG = this._svgG.append("g").attr("class", "lg-waterfall-chart__y-axis");

        this._xAxisG = this._svgG
            .append("g")
            .attr("class", "lg-waterfall-chart__x-axis")
            .attr("transform", `translate( 0, ${this._height} )`);

        this._xAxisLabelsG = this._svgG
            .append("g")
            .attr("class", "lg-waterfall-chart__x-axis-labels")
            .attr("transform", `translate( 0, ${this._height - 10} )`);

        if (toBoolean(this.showYAxisLabel)) {
            this._createyAxisLabel();
        }

        this._labelG = this._svgG.append("g").attr("class", "lg-waterfall-chart__value-labels");
    }

    private _createyAxisLabel(): void {
        this._yAxisLabel = this._svgG
            .append("text")
            .attr("class", "lg-waterfall-chart__y-axis-title")
            .text(this.yAxisLabel || this._getDefaultYAxistTitle())
            .attr("transform", "rotate(-90)")
            .attr("y", -(this._margin.left ?? 0) - 54)
            .attr("x", -this._height)
            .attr("text-anchor", "start");
    }

    private _render(immediate?: boolean): void {
        if (!this._data || !this._data.length || !this._height || this._height < 0) {
            return;
        }

        const oldYScale = this._yScale.copy();

        if (this.allowNegative) {
            this._yScale.domain([Math.min(this._yMin, this.yOffset), this._yMax]);
        } else if (this.yOffset) {
            // make sure our y offset isn't moved
            const scale = d3.scaleLinear().domain([this.yOffset, this._yMax]).nice();
            this._yScale.domain([this.yOffset, scale.domain()[1]]);
        } else {
            this._yScale.domain([0, this._yMax]).nice();
        }

        if (immediate) {
            oldYScale.domain([this._yMin, this._yMax]);
        }

        if (immediate || this._oldYOffset == null) {
            this._oldYOffset = this.yOffset;
        }

        this._xScale.domain(this._names);

        this._yAxisGrid.tickSizeInner(this._width).tickSizeOuter(0);

        if (immediate) {
            this._xAxisG.call(this._xAxis);
            this._yAxisG.call(this._yAxis);
            this._xAxisG.attr("transform", `translate(0, ${this._yScale(this.yOffset)} )`);
            this._yAxisGridG.call(this._yAxisGrid);
        } else {
            this._xAxisG.transition().call(this._xAxis);
            this._yAxisG.transition().call(this._yAxis);
            this._xAxisG
                .transition()
                .attr("transform", `translate(0, ${this._yScale(this.yOffset)} )`);
            this._yAxisGridG.transition().call(this._yAxisGrid);
        }
        this._yAxisGridG.selectAll("g").classed("hundred", d => d === 1);

        const columns = this._chart
            .selectAll<SVGRectElement, WaterFallItem[]>(".lg-waterfall-chart__bars__bar")
            .data(this._data, (d: any) => d.name);

        const self = this;

        columns
            .exit()
            .transition()
            .attr("y", this._yScale(this.yOffset))
            .attr("height", 0)
            .style("opacity", 0)
            .remove();

        const newColumns = columns.enter().append("rect");

        let columnsMerged = newColumns
            .attr("class", "lg-waterfall-chart__bars__bar")
            .attr("x", (d: WaterFallItem) => this._xScale(d.name) ?? null)
            .attr("width", this._xScale.bandwidth())
            .attr("y", oldYScale(this._oldYOffset))
            .attr("height", 0)
            .style("fill", (d: WaterFallItem) => d.colors[0])
            .style("opacity", (d: WaterFallItem) => d.opacity)
            .style("cursor", this.clickable ? "pointer" : "default")
            .on("mouseover", function (_event: MouseEvent, d: WaterFallItem) {
                self.tooltipContext = d;
                d3.select(this).style("fill", d.colors[1]);
                self._tooltip.hideShow();
                self._updateTooltipPosition();
            })
            .on("mouseleave", function (_event: MouseEvent, d: WaterFallItem) {
                d3.select(this).style("fill", d.colors[0]);
                self._tooltip.scheduleHide();
            })
            .on("click", function (_event: MouseEvent, d: WaterFallItem) {
                if (!self.clickable) return;
                const index = newColumns.nodes().indexOf(this);
                self.itemClick.emit({ item: d.item, datum: d, index });
            })
            .merge(columns);

        columnsMerged = immediate ? columnsMerged : (columnsMerged.transition() as any);

        columnsMerged
            .attr("x", (d: WaterFallItem) => this._xScale(d.name) ?? null)
            .attr("width", this._xScale.bandwidth())
            .attr("y", (d: WaterFallItem) =>
                this._yScale(
                    this.allowNegative
                        ? Math.max(d.offset, d.value)
                        : Math.max(Math.max(d.offset, d.value), this.yOffset)
                )
            )
            .attr("height", (d: WaterFallItem) =>
                Math.max(
                    0,
                    this._yScale(
                        this.allowNegative
                            ? Math.min(d.offset, d.value)
                            : Math.max(Math.min(d.offset, d.value), this.yOffset)
                    ) -
                        this._yScale(
                            this.allowNegative
                                ? Math.max(d.offset, d.value)
                                : Math.max(Math.max(d.offset, d.value), this.yOffset)
                        )
                )
            )
            .style("fill", (d: WaterFallItem) => d.colors[0])
            .style("opacity", (d: WaterFallItem) => d.opacity);

        const xAxisLabels = this._xAxisLabelsG
            .selectAll<SVGTextElement, WaterFallItem>("text")
            .data(this._data, (d: WaterFallItem) => d.name);
        const labelY = this._yMin < 0 && this._hasLabels && this.allowNegative ? 13 : 6;

        xAxisLabels.exit().transition().style("opacity", 0).remove();

        let mergedXAxisLabels = xAxisLabels
            .enter()
            .append("text")
            .attr("class", "lg-waterfall-chart__label")
            .attr("text-anchor", "middle")
            .attr("dy", "0.71em")
            .style("opacity", 0)
            .merge(xAxisLabels)
            .on("mouseover", function (_event: MouseEvent, d: WaterFallItem) {
                self.tooltipContext = d;
                self._chart.selectAll<SVGRectElement, WaterFallItem>("rect").each(function (barD) {
                    if (barD.name === d.name) d3.select(this).style("fill", barD.colors[1]);
                });
                self._tooltip.hideShow();
                self._updateTooltipPosition();
            })
            .on("mouseleave", function (_event: MouseEvent, d) {
                self._chart.selectAll<SVGRectElement, WaterFallItem>("rect").each(function (barD) {
                    if (barD.name === d.name) d3.select(this).style("fill", barD.colors[0]);
                });
                self._tooltip.scheduleHide();
            });

        mergedXAxisLabels = immediate ? mergedXAxisLabels : (mergedXAxisLabels.transition() as any);

        mergedXAxisLabels
            .call(
                this._wrapText,
                d => (this._xScale(d.name) ?? 0) + this._xScale.bandwidth() / 2,
                this._xScale.bandwidth(),
                labelY,
                this
            )
            .attr("y", this._yMin < 0 && this._hasLabels && this.allowNegative ? 13 : 6)
            .style("opacity", 1);

        this._xAxisLabelsG.style("opacity", this.showXAxisLabels ? 1 : 0);

        if (this._hasLabels) {
            const labels = this._labelG
                .selectAll<SVGTextElement, WaterFallItem>("text")
                .data(this._data, (d: WaterFallItem) => d.name);

            labels
                .exit()
                .transition()
                .attr("y", () => this._yScale(this.yOffset))
                .style("opacity", 0)
                .remove();

            let lastValue: number;
            let mergedLabels = labels
                .enter()
                .append("text")
                .attr(
                    "class",
                    d =>
                        `lg-waterfall-chart__labels__label${
                            d.value < d.offset
                                ? " lg-waterfall-chart__labels+__label--negative"
                                : ""
                        }`
                )
                .attr("text-anchor", "middle")
                .attr("y", () => oldYScale(this._oldYOffset ?? 0))
                .attr("x", d => (this._xScale(d.name) ?? 0) + this._xScale.bandwidth() / 2)
                .attr("dy", -2)
                .style("opacity", 0)
                .text(d => d.label ?? "")
                .merge(labels);

            mergedLabels = immediate ? mergedLabels : (mergedLabels.transition() as any);

            mergedLabels
                .attr(
                    "class",
                    d =>
                        `lg-waterfall-chart__labels__label${
                            d.value < d.offset ? " lg-waterfall-chart__labels__label--negative" : ""
                        }`
                )
                .attr(
                    "x",
                    (d: WaterFallItem) => (this._xScale(d.name) ?? 0) + this._xScale.bandwidth() / 2
                )
                .attr("y", (d: WaterFallItem) => this._yScale(d.value))
                .attr("dy", (d: WaterFallItem) => {
                    if (d.value === lastValue && !d.isTotal) {
                        return 7;
                    }

                    lastValue = d.value;
                    return d.value < d.offset ? 20 : -10;
                })
                .style("opacity", this.showxAxisLabels ? 1 : 0)
                .text(d => d.label ?? "");
        } else {
            this._labelG
                .selectAll("text")
                .data([])
                .exit()
                .transition()
                .style("opacity", 0)
                .remove();
        }

        this._renderReferenceLine(!!immediate);

        this._oldYOffset = this.yOffset;
    }

    private _convertData(): void {
        if (!this.data) {
            return;
        }

        const colors = this._getColors();
        const hoverColors = this._getHoverColors(colors);

        this._data = [];
        this._names = [];
        this._yMin = this._yMax = 0;
        this._hasLabels = false;
        let currentY = 0;

        this.data.forEach((item, index) => {
            const name = this.valueName ? this.valueName(item) : index.toString();
            let label = this.xAxisLabel ? this.xAxisLabel(item) : "";
            const isTotal = this.valueIsTotal ? this.valueIsTotal(item) : false;
            if (label != null) {
                this._hasLabels = true;
                if (!ldIsString(label)) {
                    label = this._labelFormat(label);
                }
            }
            let value = this.value ? this.value(item) : +item;
            const offset = isTotal ? 0 : currentY;
            if (isNaN(value) || !isFinite(value)) {
                value = 0;
            } else {
                value += offset;
            }
            if (this._yMin > value) {
                this._yMin = value;
            } else if (this._yMax < value) {
                this._yMax = value;
            }
            currentY = value;
            const colorIndex = isTotal ? 2 : value >= offset ? 0 : 1;
            const opacity = this.columnOpacity({ itemIndex: index });

            this._data.push({
                value,
                offset,
                isTotal,
                item,
                opacity,
                name,
                label,
                colors: [colors[colorIndex], hoverColors[colorIndex]]
            });

            this._names.push(name);
        });

        this._yMax =
            this._yMax * (this.showxAxisLabels ? Y_RANGE_MULTIPLIER_WHEN_SHOWING_VALUE_LABELS : 1);

        if (this._yMin === this._yMax) {
            this._yMax = 1;
            this._yMin = -1;
        }
    }

    private _getColors(): string[] {
        let colors: string[] = [];

        if (this.colors) {
            colors =
                typeof this.colors === "string"
                    ? this.colors.split(",").map(x => x.trim())
                    : this.colors;
        }
        if (colors.length === 0) {
            colors = ["#46D082", "#F45151", "#3FB3E4"];
        } else if (colors.length === 1) {
            colors[1] = d3.rgb(colors[0]).darker(0.2).toString();
            colors[2] = "#3FB3E4";
        } else if (colors.length === 2) {
            colors[2] = "#3FB3E4";
        }

        return colors;
    }

    private _getHoverColors(colors: string[]): string[] {
        let hoverColors: string[] = [];

        if (this.hoverColorsFn) {
            const parsedColors = this.hoverColorsFn(null);
            if (typeof parsedColors === "string") {
                hoverColors = parsedColors.split(",");
            } else {
                hoverColors = parsedColors;
            }
        }

        if (!hoverColors.length) {
            hoverColors.push("-0.2");
        }

        while (hoverColors.length < 3) {
            hoverColors.push(hoverColors[hoverColors.length - 1]);
        }

        for (let i = 0; i < 3; ++i) {
            const colorVal = +hoverColors[i];
            if (!isNaN(colorVal)) {
                hoverColors[i] = (
                    colorVal > 0
                        ? d3.rgb(colors[i]).brighter(colorVal)
                        : d3.rgb(colors[i]).darker(-colorVal)
                ).toString();
            }
        }

        return hoverColors;
    }

    // eslint-disable-next-line consistent-this
    private _wrapText(
        labels: d3.Selection<any, any, any, any>,
        newX: (datum: WaterFallItem) => number,
        width: number,
        labelY: number,
        self: LgWaterFallChartComponent
    ): void {
        const MEASURED_TO_ACCEPTABLE_WIDTH_RATIO = 0.92;

        labels.each(function () {
            const currentText = d3.select<any, WaterFallItem>(this);
            const currentDatum = currentText.datum();
            const words = currentDatum.name.split(/\s+/);
            const x = currentText.attr("x");
            const y = labelY;
            const x2 = newX(currentDatum);

            currentText.selectAll("tspan").remove();

            let word;
            let line: string[] = [];
            let lineNumber = 0;
            const lineHeight = 1.1;
            const dy = parseFloat(currentText.attr("dy"));
            let tspan = currentText.append<SVGTextContentElement>("tspan").attr("dy", dy + "em");

            if (!self.wrapXAxisLabelsOnNewLine) {
                let name = currentDatum.name;
                tspan.text(name);
                let acceptableWidth =
                    tspan.node()!.getComputedTextLength() * MEASURED_TO_ACCEPTABLE_WIDTH_RATIO;

                while (acceptableWidth > width && name.length > 0) {
                    name = name.slice(0, -1);
                    tspan.text(name + "...");
                    acceptableWidth =
                        tspan.node()!.getComputedTextLength() * MEASURED_TO_ACCEPTABLE_WIDTH_RATIO;
                }

                tspan
                    .attr("y", y)
                    .attr("dy", ++lineNumber * lineHeight + dy + "em")
                    .attr("x", x2);

                currentText.attr("x", x2);
            } else {
                tspan.attr("dy", ++lineNumber * lineHeight + dy + "em");

                while ((word = words.shift())) {
                    line.push(word);
                    tspan.text(line.join(" "));
                    if (tspan.node()!.getComputedTextLength() > width && line.length > 1) {
                        line.pop();
                        tspan.text(line.join(" "));
                        line = [word];
                        tspan = currentText
                            .append<SVGTextContentElement>("tspan")
                            .attr("dy", ++lineNumber * lineHeight + dy + "em")
                            .attr("y", y)
                            .text(word);
                    }
                }

                if (x == null) {
                    currentText.attr("x", x2);
                    currentText.selectAll("tspan").attr("x", x2);
                } else {
                    currentText.transition().attr("x", x2);
                    currentText.selectAll("tspan").transition().attr("x", x2);
                }
            }
        });
    }

    private _trackMousePosition(): void {
        this._ngZone.runOutsideAngular(() => {
            this._trackListener = this._renderer.listen(
                this._elementRef.nativeElement,
                "mousemove",
                (event: MouseEvent) => {
                    this._lastMouseX = event.clientX;
                    this._lastMouseY = event.clientY;
                    this._updateTooltipPosition();
                }
            );
        });
    }

    private _updateTooltipPosition(): void {
        if (this._tooltip && this._tooltip.visible) {
            if (this._lastMouseX && this._lastMouseY)
                this._tooltip.setPositionAt(
                    this._lastMouseX,
                    this._lastMouseY,
                    getRecommendedPosition(
                        { x: this._lastMouseX, y: this._lastMouseY },
                        this._tooltip.getOverlayElement()
                    )
                );
            else this._tooltip.hide();
        }
    }

    private _getBottomMarginWhenWrappingLines(): number {
        const xScale = d3
            .scaleBand()
            .domain(this._names)
            .rangeRound([0, this._width])
            .paddingInner(this._spacing)
            .paddingOuter(X_SCALE_OUTER_PADDING);
        const barWidth = xScale.bandwidth();

        const svg = d3.select("body").append("svg");
        const text = svg.append("text");
        const tspan = text.append<SVGTextContentElement>("tspan");

        let numberOfExtraLines = 0;
        let currentWord: string | undefined;

        this._data.forEach(item => {
            let extraLinesForCurrentLabel = 0;
            const words = item.name.split(/\s+/);
            let line: string[] = [];

            while ((currentWord = words.shift())) {
                const remainingWordsCount = words.length;
                line.push(currentWord);
                tspan.text(line.join(" "));
                const length = tspan.node()!.getComputedTextLength();

                if (line.length === 1) {
                    if (length >= barWidth && remainingWordsCount) {
                        extraLinesForCurrentLabel++;
                        line = [];
                    }
                } else if (length >= barWidth) {
                    line = [currentWord];
                    extraLinesForCurrentLabel++;
                }
            }

            numberOfExtraLines = Math.max(numberOfExtraLines, extraLinesForCurrentLabel);
        });

        svg.remove();

        return 38 + numberOfExtraLines * 18;
    }

    private _getDefaultYAxistTitle(): string {
        const m = getCurrencyMetadata(this._currencyCode);
        return `${m.symbol} x 1.000`;
    }
}
