import { InjectionToken } from "@angular/core";
import { FiltersPanelViewMode } from "../lg-filterset/lg-filters-panel.component";
import { LgSlideoutVariant } from "../lg-slideout/lg-slideout.types";

export const LG_FW_UI_STATE_SERVICE = new InjectionToken<ILgFwUiStateService>(
    "LG_FW_UI_STATE_SERVICE"
);

export interface ILgFwUiStateService {
    setSidebarPinned(variant: LgSlideoutVariant, pinned: boolean): void;
    getSidebarPinned(variant: LgSlideoutVariant): Promise<boolean>;

    setSidebarPinnedPanelId(variant: LgSlideoutVariant, panelVariant: string): void;
    getSidebarPinnedPanelId(variant: LgSlideoutVariant): Promise<string>;

    setLeftSidebarPinned(pinned: boolean): void;
    getLeftSidebarPinned(): Promise<boolean>;

    setRightSidebarPinned(pinned: boolean): void;
    getRightSidebarPinned(): Promise<boolean>;

    setSidebarButton(widget: LgFwUiStateSidebarButton | null): void;
    getSidebarButton(): Promise<LgFwUiStateSidebarButton | null>;

    setFilterPanelMode(mode: FiltersPanelViewMode): void;
    getFilterPanelMode(): Promise<FiltersPanelViewMode | undefined>;
}

export class LgFwUiState {
    leftSidebarPinned?: boolean;
    rightSidebarPinned?: boolean;
    rightSidebarPinnedPanelId?: string | undefined;
    leftSidebarPinnedPanelId?: string | undefined;

    sidebarWidget?: LgFwUiStateSidebarButton | undefined;
    filtersPanelViewMode?: FiltersPanelViewMode;
}

export class LgFwUiStateSidebarButton {
    top?: boolean;
    id?: string;
}
