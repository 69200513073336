import { RowType } from "../types";

type IndexMap = Map<any, number>;

// Note: for now we use the row as key, i.e. rely on object identity. This seems to be acceptable for our typical
// pivot usage (and if we fetch data from db, the whole process is far costlier than rendering of few rows).
// If this situation changes, we can consider using the pivot keys (but then we need to use the whole hierarchy)

// Todo: consider building offset map instead of updating the indices

// This class stores view indices of rendered rows. It assumes that on every render, new collection is built: it doesn't
// support any updates of the store apart from tracing the offset changes
export class RowIndexStore {
    // Total number of rows. Note that this just counts calls to addRowIndex: if there would be any overlaps (there shouldn't)
    // the number won't be correct
    public get size(): number {
        return this._size;
    }

    // Get the current view index of the specified row
    public getRowIndex(entry: any, type: RowType): number | undefined {
        const map = this._store[type];
        if (!map) return undefined;
        return map.get(entry);
    }

    // Store index of newly rendered rows. It is assumed the index is after any others (no insert operation)
    public addRowIndex(entry: any, type: RowType, index: number): void {
        let map = this._store[type];
        if (!map) {
            map = this._store[type] = new Map();
        }
        map.set(entry, index);
        this._size += 1;
    }

    // Mark insertion of new rows at given position. The new row is not stored, we only update indices of the others
    public markInsert(_type: RowType, at: number, entries: number): void {
        this._forEach((map, entry, index) => {
            if (index >= at) map.set(entry, index + entries);
        });
    }

    // Mark move of the row. We update indices of the others
    public markMove(entry: any, type: RowType, to: number): void {
        const map = this._store[type];
        if (!map) throw new Error("Inconsistent usage of markMove");
        const from = map.get(entry);
        if (from === undefined) throw new Error("Inconsistent usage of markMove");

        let rangeFrom: number;
        let rangeTo: number;
        let delta: number;
        if (from < to) {
            rangeFrom = from + 1;
            rangeTo = to;
            delta = -1;
        } else {
            rangeFrom = to;
            rangeTo = from - 1;
            delta = 1;
        }

        this._forEach((map2, entry2, index2) => {
            if (index2 < rangeFrom || index2 > rangeTo) return;
            map2.set(entry2, index2 + delta);
        });

        map.set(entry, to);
    }

    private _forEach(callback: (map: IndexMap, entry: any, index: number) => void): void {
        let map = this._store[RowType.Row];
        if (map) map.forEach((val, key) => callback(map, key, val));
        map = this._store[RowType.Separator];
        if (map) map.forEach((val, key) => callback(map, key, val));
        map = this._store[RowType.RowFooter];
        if (map) map.forEach((val, key) => callback(map, key, val));
    }

    private _store: IndexMap[] = [];
    private _size = 0;
}
