import { Injectable, inject } from "@angular/core";

import * as Pivots from "@logex/framework/lg-pivot";

import { IFilterPreprocessor } from "../filter-preprocessor";
import { IFilterDefinition } from "../filter-definition";
import { IDropdownFilterDefinition } from "../renderers/dropdown-filter-renderer";

// Dropdown renderer extensions ------------------------------------------------------------------------------------
export type IDropdownFilterDefinitionSeed<T> = Partial<IDropdownFilterDefinition<T>> & {
    id: string;

    filterType: "dropdown";

    /**
     * sourceDefinition for the filter, as used by logexPivot.gatherFilterOptionsFactory
     */
    sourceDefinition?: Pivots.IGatherFilterFactoryOptions<T>;

    /**
     * Base parameters for sourceDefinition, as use dby logexPivot.gatherFilterOptionsFactory
     */
    sourceDefinitionBase?: Pivots.IGatherFilterFactoryOptions<T>;
};

// Dropdown preprocessor -------------------------------------------------------------------------------------------
@Injectable()
export class DropdownFilterPreprocessor implements IFilterPreprocessor {
    private _logexPivot = inject(Pivots.LogexPivotService);

    public readonly name = "dropdown";

    public preprocess<T>(
        definition: IDropdownFilterDefinitionSeed<T>,
        _context: any
    ): IFilterDefinition {
        if (definition.sourceDefinition) {
            definition.source = this._logexPivot.gatherFilterOptionsFactory<any>(
                definition.sourceDefinition,
                definition.sourceDefinitionBase,
                definition.context
            );
        }
        return definition;
    }
}
