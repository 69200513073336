import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from "@angular/core";
import { toBoolean } from "@logex/framework/utilities";
import { NgClass, NgIf } from "@angular/common";
import { LgIconComponent } from "./lg-icon/lg-icon.component";

@Component({
    selector: "lg-collapsible-dialog-block",
    imports: [NgClass, LgIconComponent, NgIf],
    // eslint-disable-next-line @angular-eslint/component-max-inline-declarations
    template: `
        <div
            class="lg-collapsible-dialog-block-title"
            (click)="_toggle()"
            [ngClass]="{
                disabled: _disabled,
                'lg-collapsible-dialog-block-title--expanded': !collapsed
            }"
        >
            <div class="lg-collapsible-dialog-block-title__text">
                <div></div>
                {{ title }}
            </div>
            <div class="lg-collapsible-dialog-block-title__icon">
                <lg-icon icon="icon-arrow-down" *ngIf="collapsed"></lg-icon>
                <lg-icon icon="icon-arrow-up" *ngIf="!collapsed"></lg-icon>
            </div>
        </div>
        <div class="lg-collapsible-dialog-block--expanded" *ngIf="!collapsed">
            <ng-content></ng-content>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LgCollapsibleDialogBlockComponent {
    /**
     * Block title (required).
     */
    @Input({ alias: "blockTitle", required: true }) title!: string;

    /**
     * Specifies if block is collapsed or not.
     *
     * @default true
     */
    @Input() set collapsed(value: boolean) {
        this._collapsed = toBoolean(value);
    }

    get collapsed(): boolean {
        return this._collapsed;
    }

    /**
     * Specifies if the collapse toggle is disabled or not.
     * Setting it to `true` enforces the block to stay in its current state.
     *
     * @default false
     */
    @Input() set disabled(value: boolean) {
        this._disabled = toBoolean(value);
    }

    get disabled(): boolean {
        return this._disabled;
    }

    @Output() readonly collapsedChange = new EventEmitter<boolean>();

    public _disabled = false;
    private _collapsed = true;

    public _toggle(): boolean {
        if (this._disabled) return false;

        this._collapsed = !this._collapsed;
        this.collapsedChange.next(this.collapsed);
        return false;
    }
}
