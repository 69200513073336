import { Pipe, PipeTransform } from "@angular/core";

const matchSymbol = /[^-−+.,0-9\s]+/g;

@Pipe({
    name: "lgMarkSymbols",
    pure: true
})
export class LgMarkSymbolsPipe implements PipeTransform {
    public transform(value: string | null | undefined, className?: string): string {
        if (value == null) {
            return "";
        }

        if (!className) className = "lg-symbol";
        const span = `<span class="${className || "lg-symbol"}">$&</span>`;
        return value.replace(matchSymbol, span);
    }
}
