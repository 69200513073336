import { InjectionToken, Provider, Type } from "@angular/core";
import { HTTP_INTERCEPTORS, HttpInterceptor } from "@angular/common/http";

export type LgProvider<T> = (
    | { useValue: T } // ValueSansProvider
    | { useClass: Type<T> } // ClassSansProvider
    | { useExisting: Type<T> } // ExistingSansProvider
    | { useFactory: (...deps: any[]) => T; deps?: any[] } // FactorySansProvider
) & { multi?: boolean };

export type LgProviders<T extends object> = (config?: T) => Provider[];

export function lgProvide<T>(
    provide: Type<T> | InjectionToken<T>,
    provider: LgProvider<T>
): Provider {
    return { provide, ...provider };
}

export function lgProvideInterceptor(interceptor: Type<HttpInterceptor>): Provider {
    return { provide: HTTP_INTERCEPTORS, useClass: interceptor, multi: true };
}
