import { Pipe, PipeTransform, inject } from "@angular/core";
import {
    ILgFormatter,
    ILgFormatterOptions,
    PercentageFormatterFactory
} from "@logex/framework/core";
import { Nullable } from "@logex/framework/types";

export type IFmtPercentOptions = Partial<
    Pick<ILgFormatterOptions, "decimals" | "forceSign" | "zeroDash" | "min" | "max">
> & {
    minMax?: number;
};

const defaultOptions: ILgFormatterOptions = {
    decimals: 0
};

@Pipe({
    name: "fmtPercent",
    pure: true
})
export class LgFormatPercentPipe implements PipeTransform {
    private _formatter: ILgFormatter<number> = inject(PercentageFormatterFactory).create(
        defaultOptions
    );

    public transform(value: Nullable<string | number>, options?: IFmtPercentOptions): string;

    /**
     * @deprecated Use options variant instead
     */
    public transform(
        value: Nullable<string | number>,
        decimals?: number,
        forceSign?: boolean,
        minMax?: number
    ): string;

    public transform(
        value: Nullable<string | number>,
        options?: number | IFmtPercentOptions,
        forceSign?: boolean,
        minMax?: number
    ): string {
        const parameterOptions = this._getParameterOptions(options, forceSign, minMax);

        if (typeof value === "string") {
            value = +value;
        }
        return this._formatter.format(value, parameterOptions);
    }

    private _getParameterOptions(
        options?: number | IFmtPercentOptions,
        forceSign?: boolean,
        minMax?: number
    ): ILgFormatterOptions {
        let parameterOptions: ILgFormatterOptions = {};
        if (typeof options === "number") {
            parameterOptions = {
                decimals: options,
                ...(forceSign !== undefined ? { forceSign } : {})
            };
        } else if (options === undefined) {
            parameterOptions = { ...(forceSign !== undefined ? { forceSign } : {}) };
        } else {
            parameterOptions = { ...options };
            minMax = options.minMax;
        }

        if (minMax) {
            parameterOptions.min = -minMax;
            parameterOptions.max = minMax;
        }
        return parameterOptions;
    }
}
