<lg-filterset-preview [definition]="definition"></lg-filterset-preview>
<lg-slideout-button-row
    [slideoutPanelId]="slideoutPanelId"
    [variant]="_variant"
    [disabled]="disabled"
    [initializeAsPinned]="initializeAsPinned"
    [initializeAsExpanded]="initializeAsExpanded"
    [hidePin]="hidePin"
></lg-slideout-button-row>
<ng-content></ng-content>
