import { Pipe, PipeTransform } from "@angular/core";

// TODO logic to formatting service

@Pipe({
    standalone: true,
    // eslint-disable-next-line @angular-eslint/pipe-prefix
    name: "lpad",
    pure: true
})
export class LgLeftPadPipe implements PipeTransform {
    transform(value: any, length: number, padChar?: string): string {
        value = "" + value;
        length = ~~length;
        if (!padChar) padChar = " ";
        if (padChar.length > 1) padChar = padChar.charAt(0);

        const padding = length - value.length;
        if (padding <= 0) return value;
        return new Array(padding + 1).join(padChar) + value;
    }
}
