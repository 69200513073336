import ldIsEqual from "lodash-es/isEqual";
import { ComponentPortal } from "@angular/cdk/portal";
import {
    AfterViewChecked,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    forwardRef,
    inject,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    TemplateRef,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import { DatePipe, NgForOf, NgIf, NgTemplateOutlet } from "@angular/common";
import { FormsModule, NG_VALUE_ACCESSOR } from "@angular/forms";
import { ENTER } from "@angular/cdk/keycodes";
import { ScrollDispatcher } from "@angular/cdk/overlay";

import {
    LgDate,
    LgTranslatePipe,
    LgTranslateService,
    useTranslationNamespace
} from "@logex/framework/lg-localization";
import { atNextFrame, toBoolean } from "@logex/framework/utilities";
import { IOverlayResultApi, LgOverlayService } from "../../lg-overlay";
import { LgPromptDialog } from "../lg-prompt-dialog/lg-prompt-dialog.component";
import { ValueAccessorBase } from "../inputs";
import { CalendarInputElement, CalendarInputPart } from "./calendar.types";
import {
    ILgCalendarTooltipComponentProps,
    LgCalendarTooltipComponent
} from "./lg-calendar-tooltip.component";
import { LgTime, LgTimePickerComponent } from "../lg-time-picker";
import { LgCopyHandlerDirective, LgPasteHandlerDirective } from "../../behavior";
import { LgLeftPadPipe } from "../../pipes";

interface CalendarInputElementEx extends CalendarInputElement {
    template?: TemplateRef<any>;
}

@Component({
    selector: "lg-calendar",
    templateUrl: "./lg-calendar.component.html",
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => LgCalendarComponent),
            multi: true
        }
    ],
    viewProviders: [useTranslationNamespace("FW._Directives")],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    imports: [
        LgCopyHandlerDirective,
        LgPasteHandlerDirective,
        NgForOf,
        NgTemplateOutlet,
        LgTimePickerComponent,
        NgIf,
        FormsModule,
        LgTranslatePipe,
        LgLeftPadPipe
    ],
    host: {
        class: "lg-calendar",
        "[class.lg-calendar--with-time]": "_withTime",
        "[class.lg-calendar--disabled]": "disabled"
    }
})
export class LgCalendarComponent
    extends ValueAccessorBase<Date>
    implements OnChanges, OnInit, OnDestroy, AfterViewChecked
{
    private _promptDialog = inject(LgPromptDialog);
    private _datePipe = inject(DatePipe);
    private _lgTranslate = inject(LgTranslateService);
    private _overlayService = inject(LgOverlayService);
    private _scrollDispatcher = inject(ScrollDispatcher);

    /**
     * Minimum date allowed.
     */
    @Input() minDate: Date | null = null;
    /**
     * Maximum date allowed.
     */
    @Input() maxDate: Date | null = null;
    /**
     * Default date.
     */
    @Input() defaultDate: Date | null = null;
    /**
     * Calendar position.
     */
    @Input() position: "left" | "right" = "left";
    /**
     * Date format
     *
     * @default "dd/MM/yyyy"
     */
    @Input() dateFormat = "dd/MM/yyyy";
    /**
     * Time format.
     *
     * @default "HH:mm"
     */
    @Input() timeFormat = "HH:mm";

    @Input()
    set disabled(value: boolean | "true" | "false") {
        this._disabled = toBoolean(value);
    }

    get disabled(): boolean {
        return this._disabled;
    }

    /**
     * Specifies if value is required.
     *
     * @default false
     */
    @Input()
    set required(value: boolean | "true" | "false") {
        this._required = toBoolean(value);
    }

    get required(): boolean {
        return this._required;
    }

    /**
     * Reduces the size of the input field if `true`.
     *
     * @default false
     */
    @Input()
    set condensed(value: boolean | "true" | "false") {
        this._condensed = toBoolean(value);
    }

    get condensed(): boolean {
        return this._condensed;
    }

    /**
     * Specifies if value contains time.
     *
     * @default false
     */
    @Input()
    set withTime(value: boolean | "true" | "false") {
        this._withTime = toBoolean(value);
    }

    get withTime(): boolean {
        return this._withTime;
    }

    /**
     * Specifies if calendar should be activated on focus.
     *
     * @default false
     */
    @Input()
    set activateOnFocus(value: boolean | "true" | "false") {
        this._activateOnFocus = toBoolean(value);
    }

    get activateOnFocus(): boolean {
        return this._activateOnFocus;
    }

    @Input()
    set fillPotentialDate(value: boolean | "true" | "false") {
        this._fillPotentialDate = toBoolean(value);
    }

    get fillPotentialDate(): boolean {
        return this._fillPotentialDate;
    }

    @Output() readonly postSelect = new EventEmitter<Date>();

    @ViewChild("dayTemplate", { static: true }) _dayTemplate!: TemplateRef<any>;
    @ViewChild("monthTemplate", { static: true }) _monthTemplate!: TemplateRef<any>;
    @ViewChild("yearTemplate", { static: true }) _yearTemplate!: TemplateRef<any>;
    @ViewChild("inputDiv", { static: true }) _inputDiv!: ElementRef<HTMLElement>;
    @ViewChild("timePicker", { read: LgTimePickerComponent }) _timePicker!: LgTimePickerComponent;

    _elements: CalendarInputElementEx[] = [];
    _withTime = false;
    _condensed = false;
    _visibleValue: Date | null = null;

    _timeValue: LgTime | null = null;
    _timeDefault: LgTime | null = null;
    _timeMin: LgTime | null = null;
    _timeMax: LgTime | null = null;
    _pendingDate: Date | null = null;
    _dayPadding = 1;
    _monthPadding = 1;
    _invalid = false;

    readonly _copyHandler = (): string | null => this._getCopyData(this.value);
    readonly _pasteHandler = (text: string): boolean => this._pasteData(text) ?? true;

    private _disabled = false;
    private _required = false;
    private _activateOnFocus = false;
    private _focused = false;
    private _fillPotentialDate = true;
    private _overlayInstance?: IOverlayResultApi;
    private _popupInstance: LgCalendarTooltipComponent | null = null;

    constructor() {
        super(true);
    }

    ngOnInit(): void {
        if (this._elements.length === 0) {
            this._parseDateFormat();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ("minDate" in changes || "maxDate" in changes) {
            this._updateTimeMinMax();
            if (this._popupInstance) {
                this._popupInstance.updateProps(this._getTooltipProps());
            }
        }
        if ("defaultDate" in changes) {
            this._timeDefault = this._extractTime(this.defaultDate, true);
        }
        if ("dateFormat" in changes) {
            this._parseDateFormat();
        }
        if ("disabled" in changes || "required" in changes) {
            this._updateValidation();
        }
    }

    ngOnDestroy(): void {
        if (this._popupInstance) this._attemptClose();
    }

    ngAfterViewChecked(): void {
        if (this._timePicker && this._timePicker._getCopyDataOverride === null) {
            this._timePicker._getCopyDataOverride = time =>
                this._getCopyData(this._pendingDate ?? this.value, true, time);
            this._timePicker._pasteDataOverride = (text, pending): boolean =>
                this._pasteData(text, pending) ?? true;
        }
    }

    writeValue(value: Date): void {
        this._writeValue(value);
        this._visibleValue = this.value;
        this._timeValue = this._extractTime(value, false);
        this._updateTimeMinMax();
        this._updateValidation();
    }

    setDisabledState(isDisabled: boolean): void {
        if (this._popupInstance != null) {
            this._attemptClose();
        }
        this._disabled = isDisabled;
    }

    _onKeyDown(event: KeyboardEvent): void {
        if (event.key === "Enter" || event.keyCode === ENTER) {
            event.stopPropagation();
            event.preventDefault();

            if (!this._popupInstance) {
                this._onOpenTooltip(false);
            }
        }
    }

    _onFocus(/* event: FocusEvent */): void {
        if (this._focused) return;
        if (!this._activateOnFocus) return;
        if (!this._popupInstance) this._onOpenTooltip(false);
    }

    _onClick(/* event: MouseEvent */): void {
        if (!this._popupInstance) this._onOpenTooltip(false);
    }

    _onOpenTooltip(preselectLast: boolean): void {
        if (this._disabled) return;

        this._focused = true;

        const strategy = this._overlayService.positionStrategies
            .flexibleConnectedTo(this._inputDiv)
            .withFlexibleDimensions(false)
            .withPush(false)
            .withViewportMargin(0)
            .withPositions([
                {
                    originX: this.position === "right" ? "start" : "end",
                    originY: "top",
                    overlayX: this.position === "right" ? "start" : "end",
                    overlayY: "top"
                },
                {
                    originX: this.position === "right" ? "start" : "end",
                    originY: "bottom",
                    overlayX: this.position === "right" ? "start" : "end",
                    overlayY: "bottom"
                }
            ]);

        strategy.withScrollableContainers(
            this._scrollDispatcher.getAncestorScrollContainers(this._elementRef)
        );

        this._overlayInstance = this._overlayService.show({
            onClick: () => this._attemptClose(),
            hasBackdrop: true,
            sourceElement: this._elementRef,
            positionStrategy: strategy,
            focusPostHide: "ignore",
            scrollStrategy: this._overlayService.scrollStrategies.reposition({ scrollThrottle: 0 })
        });

        const portal = new ComponentPortal<LgCalendarTooltipComponent>(LgCalendarTooltipComponent);
        this._popupInstance = this._overlayInstance.overlayRef.attach(portal).instance;

        this._popupInstance.initialize(this._getTooltipProps(), preselectLast);

        strategy.positionChanges.subscribe(change => {
            this._popupInstance!._showAbove =
                change.connectionPair.originY === "bottom" &&
                change.connectionPair.overlayY === "bottom";
        });
    }

    _postTimeSelect = (
        time: LgTime | null,
        cancelled: boolean,
        navigationDelta: -1 | 0 | 1 | null
    ): void => {
        this._changeDetectorRef.markForCheck();
        if (cancelled) {
            if (navigationDelta === -1) {
                this._onOpenTooltip(true);
            } else {
                this._pendingDate = null;
                this._visibleValue = this.value;
                this._timeValue = this._extractTime(this.value, false);
            }
        } else {
            const date = this._addTime(this._pendingDate ?? this.value, time);
            if (
                date &&
                ((this.minDate != null && this.minDate > date) ||
                    (this.maxDate != null && this.maxDate < date))
            ) {
                this._pendingDate = null;
                this._visibleValue = this.value;
                // to ensure the change will be detected, we need to temporarily allow the input
                this._timeValue = time;
                atNextFrame(() => {
                    this._timeValue = this._timeValue = this._extractTime(this.value, false);
                    this._changeDetectorRef.markForCheck();
                });
                this._inputDiv.nativeElement.focus();
                return;
            }
            this._timeValue = time;
            this._pendingDate = null;
            this.value = date!;
            this.postSelect.emit(date ?? undefined);
        }

        this._updateValidation();
    };

    protected override _setValueImpl(value: Date): void {
        super._setValueImpl(value);
        this._visibleValue = this.value;
        this._timeValue = this._extractTime(value, false);
        this._updateTimeMinMax();
        this._updateValidation();
    }

    private _getCopyData(
        currentDate: Date | null | undefined,
        injectTime = false,
        currentTime: LgTime | null = null
    ): string | null {
        if (!currentDate) return null;
        if (injectTime && this._timeValue) {
            currentDate = this._addTime(currentDate, currentTime ?? this._timeValue);
        }
        return this._getSafeFormatter(false).format(currentDate ?? null);
    }

    private _pasteData(text: string, timePopup = false): boolean | null {
        let date = this._getSafeFormatter(false).parse(text);
        if (!date && this.withTime) {
            // if full parse failed, try date only
            date = this._getSafeFormatter(true).parse(text);
            // note: do we want any special time handling for when the time popup is open?
            if (date) {
                date = this._addTime(date, this._timeValue) ?? null;
            }
        }
        if (date) {
            if (
                (this.minDate != null && this.minDate > date) ||
                (this.maxDate != null && this.maxDate < date)
            ) {
                if (this._popupInstance) return null;
                this._promptDialog
                    .alert(
                        this._lgTranslate.translate(".Calendar_InvalidDateDialog_Title"),
                        this._lgTranslate.translate(".Calendar_InvalidDateDialog_Text")
                    )
                    .then(() => {
                        if (!timePopup) {
                            setTimeout(() => this._inputDiv.nativeElement.focus(), 0);
                        }
                    });
            } else if (this._popupInstance) {
                this._pendingDate = date;
                this._timeValue = this._extractTime(date, false);
                this._popupInstance.updateProps(this._getTooltipProps());
            } else if (timePopup) {
                this._pendingDate = date;
                this._visibleValue = date;
                this._timeValue = this._extractTime(date, false);
                this._changeDetectorRef.markForCheck();
            } else {
                this.value = date;
            }

            this._updateValidation();
            return true;
        }
        return false;
    }

    private _getSafeFormatter(dateOnly: boolean): LgDate {
        try {
            return new LgDate(
                this.withTime && !dateOnly
                    ? `${this.dateFormat} ${this._timePicker.timeFormat}`
                    : this.dateFormat,
                this._datePipe
            );
        } catch {
            return new LgDate(this.withTime && !dateOnly ? "yyyy-MM-dd HH:mm" : "yyyy-MM-dd");
        }
    }

    private _getTooltipProps(): ILgCalendarTooltipComponentProps {
        const value = this._pendingDate ?? this.value;
        return {
            canSeeToday: this._isTodayVisible(),
            maxDate: this.maxDate!,
            minDate: this.minDate!,
            required: this._required,
            selectedDate: value ?? undefined,
            defaultDate: this.defaultDate ?? undefined,
            onSelect: (date, shouldClose, cancelled, navigationDelta) =>
                this._onDateSelect(date!, shouldClose, cancelled, navigationDelta),
            position: this.position,
            condensed: this._condensed,
            fillPotentialDate: this._fillPotentialDate,
            elements: this._elements.map(({ part, separator, fullSize }) => ({
                part,
                separator,
                fullSize
            })),
            getCopyData: date => this._getCopyData(date, true) ?? "",
            pasteData: text => this._pasteData(text)
        };
    }

    private _onDateSelect(
        date: Date,
        shouldClose: boolean,
        cancelled: boolean,
        navigationDelta: 0 | 1
    ): void {
        this._changeDetectorRef.markForCheck();
        if (this._withTime && shouldClose && !cancelled && date != null) {
            this._pendingDate = date;
            this._visibleValue = date;
            this._attemptClose();
            this._updateTimeMinMax();
            setTimeout(() => this._timePicker._onOpenPopup(), 0);
        } else {
            if (cancelled && this._pendingDate) {
                this._pendingDate = null;
                date = this.value;
                this._visibleValue = this.value;
            }
            this.value = date;
            this.postSelect.emit(date);
            this._updateTimeMinMax();
            if (shouldClose) {
                this._attemptClose();
                if (navigationDelta === 1) {
                    this._overlayService.focusFirstTabbableElementAfter(
                        this._inputDiv.nativeElement,
                        true,
                        false
                    );
                } else {
                    this._inputDiv.nativeElement.focus();
                }
            }
        }

        this._updateValidation();
    }

    private _isTodayVisible(): boolean {
        const today = new Date();
        const todayYear = today.getFullYear();
        const todayMonth = today.getMonth();

        if (this.minDate != null) {
            const year = this.minDate.getFullYear();
            if (year > todayYear || (year === todayYear && this.minDate.getMonth() > todayMonth)) {
                return false;
            }
        }

        if (this.maxDate != null) {
            const year = this.maxDate.getFullYear();
            if (year < todayYear || (year === todayYear && this.maxDate.getMonth() < todayMonth)) {
                return false;
            }
        }

        return true;
    }

    private _attemptClose(): void {
        this._overlayInstance!.hide();
        this._popupInstance = null;
        setTimeout(() => (this._focused = false));
    }

    private _formatRegex = /(d|dd|M|MM|yy|yyyy)(?:(\W)|$)/g;

    private _parseDateFormat(): void {
        // note: we want to be a bit flexible about the format, but ultimately we require all 3 fields to be present,
        // and only 1 char separators
        let match = this._formatRegex.exec(this.dateFormat ?? "");
        const elements: CalendarInputElementEx[] = [];
        let dayFound = false;
        let monthFound = false;
        let yearFound = false;
        while (match !== null) {
            switch (match[1]) {
                case "d":
                case "dd":
                    if (!dayFound) {
                        elements.push({
                            part: CalendarInputPart.Day,
                            separator: match[2] ?? "",
                            fullSize: match[1] === "dd",
                            template: this._dayTemplate
                        });
                        this._dayPadding = match[1].length;
                        dayFound = true;
                    }
                    break;
                case "M":
                case "MM":
                    if (!monthFound) {
                        elements.push({
                            part: CalendarInputPart.Month,
                            separator: match[2] ?? "",
                            fullSize: match[1] === "MM",
                            template: this._monthTemplate
                        });
                        this._monthPadding = match[1].length;
                        monthFound = true;
                    }
                    break;
                case "yy":
                case "yyyy":
                    if (!yearFound) {
                        elements.push({
                            part: CalendarInputPart.Year,
                            separator: match[2] ?? "",
                            fullSize: match[1] === "yyyy",
                            template: this._yearTemplate
                        });
                        yearFound = true;
                    }
                    break;
            }
            match = this._formatRegex.exec(this.dateFormat);
        }
        if (!dayFound || !monthFound || !yearFound) {
            console.warn("Invalid calendar format", this.dateFormat);
            this.dateFormat = "dd/MM/yyyy";
            this._parseDateFormat();
        } else {
            elements[2].separator = "";
            if (!ldIsEqual(elements, this._elements)) {
                this._elements = elements;
                if (this._popupInstance) {
                    this._popupInstance.updateProps(this._getTooltipProps());
                }
            }
        }
    }

    private _extractTime(date: Date | null | undefined, roundUp: boolean): LgTime | null {
        if (date == null) return null;
        if (roundUp && (date.getSeconds() > 0 || date.getMilliseconds() > 0)) {
            date = new Date(date);
            date.setMinutes(date.getMinutes() + 1);
            date.setSeconds(0);
            date.setMilliseconds(0);
        }
        return {
            hours: date.getHours(),
            minutes: date.getMinutes()
        };
    }

    private _addTime(date: Date | null | undefined, time: LgTime | null): Date | null | undefined {
        if (date == null) return date;
        time = time ?? { hours: 0, minutes: 0 };
        date = new Date(date);
        date.setHours(time.hours);
        date.setMinutes(time.minutes);
        date.setSeconds(0);
        return date;
    }

    private _updateTimeMinMax(): void {
        this._timeMin = null;
        this._timeMax = null;
        const date = this._pendingDate ?? this.value;
        if (date == null || (date as any) === "") return;
        if (this.minDate != null) {
            if (
                date.getFullYear() === this.minDate.getFullYear() &&
                date.getMonth() === this.minDate.getMonth() &&
                date.getDate() === this.minDate.getDate()
            ) {
                this._timeMin = this._extractTime(this.minDate, true);
            }
        }
        if (this.maxDate != null) {
            if (
                date.getFullYear() === this.maxDate.getFullYear() &&
                date.getMonth() === this.maxDate.getMonth() &&
                date.getDate() === this.maxDate.getDate()
            ) {
                // note: no need to round for the max time
                this._timeMax = this._extractTime(this.maxDate, false);
            }
        }
    }

    private _updateValidation(): void {
        this._invalid = !this._disabled && this._required && !this.value;
    }
}
