import {
    Component,
    HostBinding,
    Input,
    OnChanges,
    SimpleChanges,
    Output,
    EventEmitter,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
    AfterViewInit,
    ChangeDetectorRef,
    ChangeDetectionStrategy,
    inject
} from "@angular/core";
import { LG_USE_NEW_LABELS } from "../shared/lg-color-palette-v2/lg-colors.types";

import { LegendItem, LegendPosition } from "./chart.types";

const LEGEND_CLASS = "lg-chart-legend";
const LEGEND_ON_BOTTOM_CLASS = "lg-chart-legend--row";
const LEGEND_ON_RIGHT_CLASS = "lg-chart-legend--column";
const MULTILINE_SUFFIX = "--multiline";

@Component({
    selector: "lg-chart-legend",
    templateUrl: "./lg-chart-legend.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LgChartLegendComponent implements OnChanges, AfterViewInit {
    _useNewLabels = inject(LG_USE_NEW_LABELS);
    private _changeDetectorRef = inject(ChangeDetectorRef);

    @HostBinding("style.height.px") _height?: number;
    @HostBinding("style.width.px") _width?: number;
    @HostBinding("style.line-height.px") _lineHeight?: number;
    @HostBinding("class") get _class(): string {
        const isBottom = this.position === "bottom";
        const positionClass = isBottom ? LEGEND_ON_BOTTOM_CLASS : LEGEND_ON_RIGHT_CLASS;
        const isMultiline = this.wrapMultilineDefinition && isBottom;
        const multilineSuffix = positionClass + MULTILINE_SUFFIX;

        return `${LEGEND_CLASS} ${this.className || ""} ${positionClass} ${
            isMultiline ? multilineSuffix : ""
        }`;
    }

    @Input() position?: LegendPosition;
    @Input() legendTemplate?: TemplateRef<any>;
    @Input() definition: LegendItem[] = [];
    @Input() className: string | undefined = "";
    @Input() cropLongNames: boolean | undefined = false;
    @Input() wrapMultilineDefinition: boolean | undefined = false;

    @Input() set height(value: number) {
        this._height = value;
        this._lineHeight = value;
    }

    get height(): number {
        return this._height ?? 0;
    }

    @Input() set width(value: number) {
        this._width = value;
    }

    get width(): number {
        return this._width ?? 0;
    }

    @ViewChild("legendContainer", { read: ViewContainerRef })
    legendContainerRef?: ViewContainerRef;

    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output() readonly onMouseEnter = new EventEmitter<LegendItem>();

    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output() readonly onClick = new EventEmitter<LegendItem>();

    get shouldItemHavePointerCursor(): boolean {
        return this.onMouseEnter.observed || this.onClick.observed;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.definition) {
            const newVal = changes.definition.currentValue;
            this.definition = newVal ? [...newVal] : [];
        }
        if (changes.height) {
            const val = changes.height.currentValue;

            this._height = val;
            this._lineHeight = val;
        }
    }

    ngAfterViewInit(): void {
        if (this.legendTemplate) {
            this.legendContainerRef?.createEmbeddedView(this.legendTemplate);
            this._changeDetectorRef.detectChanges();
        }
    }

    _onMouseEnter(item: LegendItem): void {
        this.onMouseEnter.emit(item);
    }

    _onClick(item: LegendItem): void {
        this.onClick.emit(item);
    }
}
