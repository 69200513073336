import { NgZone } from "@angular/core";
import { Observable, Observer } from "rxjs";

export function observeOnZone<T>(ngZone: NgZone) {
    return (source: Observable<T>) =>
        Observable.create((observer: Observer<T>) => {
            const onNext = (value: T): any => ngZone.run(() => observer.next(value));
            const onError = (e: any): any => ngZone.run(() => observer.error(e));
            const onComplete = (): any => ngZone.run(() => observer.complete());
            return source.subscribe(onNext, onError, onComplete);
        });
}
