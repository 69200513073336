import { Component, Input, ChangeDetectionStrategy, OnChanges } from "@angular/core";

import { LgSimpleChanges } from "@logex/framework/types";

import { LgChartIconSymbol } from "../chart.types";

const DEFAULT_SYMBOL: LgChartIconSymbol = "circle";
const DEFAULT_SYMBOL_BORDER_WIDTH = 1;
const DEFAULT_LINE_STROKE_WIDTH = 2;

@Component({
    selector: "lg-chart-icon",
    templateUrl: "./lg-chart-icon.component.html",
    styles: [":host {height: 16px}"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LgChartIconComponent implements OnChanges {
    /**
     * Specifies chart icon symbol.
     *
     * @default "circle"
     */
    @Input() symbol?: undefined | LgChartIconSymbol;

    /**
     * Specifies chart icon color. Initial color is used if not specified.
     */
    @Input() color?: string;

    /**
     * Specifies chart icon opacity.
     *
     * @default 1
     */
    @Input() opacity?: number | undefined = 1;

    /**
     * Specifies whether icon has border or not.
     *
     * @default false
     */
    @Input() hasBorder? = false;

    protected _symbol: LgChartIconSymbol = DEFAULT_SYMBOL;
    protected _symbolBorderWidth = 0;
    protected readonly _lineStrokeWidth = DEFAULT_LINE_STROKE_WIDTH;

    ngOnChanges(changes: LgSimpleChanges<this>): void {
        if (changes.symbol && this.symbol) {
            this._symbol = this.symbol ?? DEFAULT_SYMBOL;
        }
        if (changes.hasBorder && this.hasBorder) {
            this._symbolBorderWidth = this.hasBorder ? DEFAULT_SYMBOL_BORDER_WIDTH : 0;
        }
    }
}
