<div class="lg-release-notes__wrapper">
    <div class="form-group" [lgLoaderOverlay]="!_notes" lgScrollable>
        <div class="lg-release-notes">
            <div class="lg-release-notes__body" [innerHTML]="_notes"></div>
        </div>
    </div>
    <div class="lg-release-notes__buttons">
        <lg-button buttonClass="button--primary" textLc=".Ok" (click)="_hide()"></lg-button>
    </div>
</div>
