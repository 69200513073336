import ldIsArray from "lodash-es/isArray";
import ldIsString from "lodash-es/isString";
import { IScopedSortPredicate } from "@logex/framework/types";

/**
 * Based on $filter("orderBy"), but takes scope into account, thus allowing the sort-by expressions to
 * reference 'global variables'
 *
 * @param scope is the scope to be optionally used by the predicates
 * @param array is the array of the top-level nodes
 * @param sortPredicate is the sorting specification to be used
 * @param reverseOrder indicates, if the array should be sorted in reverse order
 * @return the sorted array
 */
export function scopedOrderByFilter<T = any>(
    array: T[],
    sortPredicate: IScopedSortPredicate | IScopedSortPredicate[],
    reverseOrder?: boolean
): T[] {
    if (!ldIsArray(array)) return array;
    if (!sortPredicate) return array;

    sortPredicate = ldIsArray(sortPredicate) ? sortPredicate : [sortPredicate];
    const convertedSortPredicate = sortPredicate.map(predicate => {
        let descending = false;
        if (ldIsString(predicate)) {
            if (predicate.charAt(0) === "+" || predicate.charAt(0) === "-") {
                descending = predicate.charAt(0) === "-";
                predicate = predicate.substring(1);
            }

            const key = predicate;
            return reverseComparator(function (a: any, b: any) {
                return compare(a[key], b[key]);
            }, descending);
        } else {
            const predicateAsFn = predicate;
            return reverseComparator(function (a: any, b: any) {
                return compare(predicateAsFn(a), predicateAsFn(b));
            }, descending);
        }
    });

    const arrayCopy: T[] = array.slice();
    return arrayCopy.sort(reverseComparator(comparator, reverseOrder));

    function comparator(o1: unknown, o2: unknown): number {
        for (const predicate of convertedSortPredicate) {
            const comp = predicate(o1, o2);
            if (comp !== 0) return comp;
        }
        return 0;
    }

    function reverseComparator(
        comp: (a: unknown, b: unknown) => number,
        descending?: boolean
    ): (a: unknown, b: unknown) => number {
        return descending
            ? function (a, b) {
                  return comp(b, a);
              }
            : comp;
    }

    function compare(v1: any, v2: any): number {
        const t1 = typeof v1;
        const t2 = typeof v2;
        if (t1 === t2) {
            if (t1 === "string") {
                v1 = v1.toLowerCase();
                v2 = v2.toLowerCase();
            }
            if (v1 === v2) return 0;
            return v1 < v2 ? -1 : 1;
        } else {
            return t1 < t2 ? -1 : 1;
        }
    }
}
