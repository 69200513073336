import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
    ViewEncapsulation
} from "@angular/core";
import { ProcessedNavNode } from "@logex/framework/lg-application";

@Component({
    selector: "lg-sidebar-menu-item",
    templateUrl: "./lg-sidebar-menu-item.component.html",
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LgSidebarMenuItemComponent {
    /**
     * Current active URL
     */
    @Input({ required: true })
    currentUrl!: string;

    /**
     * Navigation node to be processed
     */
    @Input({ required: true })
    navNode!: ProcessedNavNode;

    /**
     * Menu level of processed navigation node
     *
     * @default 1
     */
    @Input()
    level = 1;

    /**
     * Activate children navigation by query params
     *
     * @default 1
     */
    @Input()
    matchChildrenByQueryParams?: string[] | undefined;

    /**
     * Navigate to processed navigation node
     * */
    @Output()
    readonly navigate = new EventEmitter<ProcessedNavNode>();

    get cssMenuItem(): { [klass: string]: boolean } {
        return {
            active: this.isActive(),
            disabled: !!this.navNode.disabled,
            "lg-sidebar-menu-item--collapsible": this.hasChildren(),
            "lg-sidebar-menu-item--regular": !this.hasChildren(),
            "lg-sidebar-menu-item--secondary": this.level > 1
        };
    }

    get marginLeftPx(): number {
        return 6 + (this.level - 1) * 12;
    }

    isActive(): boolean {
        const [currentUrlPath, queryString] = this.currentUrl.split("?");
        const queryParams = new URLSearchParams(queryString);

        const check = (item: ProcessedNavNode, matchChildrenByQueryParams?: string[]): boolean => {
            const isCurrentUrlMatch = item.getHrefNoQuery() === currentUrlPath;
            matchChildrenByQueryParams =
                item.matchChildrenByQueryParams ?? matchChildrenByQueryParams;
            if (
                matchChildrenByQueryParams !== undefined ||
                item.matchChildrenByQueryParams !== undefined
            ) {
                let isQueryParamsMatch = false;
                if (isCurrentUrlMatch) {
                    isQueryParamsMatch = matchChildrenByQueryParams!.every(
                        name =>
                            item?.queryParams?.[name] !== undefined &&
                            item?.queryParams?.[name] === queryParams.get(name)
                    );
                }

                return (
                    isQueryParamsMatch ||
                    !!item.children?.some(child => check(child, matchChildrenByQueryParams))
                );
            } else {
                return isCurrentUrlMatch || !!item.children?.some(child => check(child));
            }
        };

        return check(this.navNode, this.matchChildrenByQueryParams);
    }

    isExpanded(): boolean {
        return !!this.navNode.data?.expanded;
    }

    hasChildren(item?: ProcessedNavNode): boolean {
        return !!(item || this.navNode).children?.length;
    }

    hasDivider(): boolean {
        return this.level === 1 && this.hasToggle() && this.isExpanded();
    }

    hasToggle(): boolean {
        return !this.navNode.disabled && this.hasChildren();
    }

    onContextMenu($event: MouseEvent): void {
        if (this.navNode.disabled) $event.preventDefault();
    }

    onClick($event: Event): void {
        $event.stopPropagation();
        $event.preventDefault();
        if (this.navNode.disabled) return;

        if (typeof this.navNode.onClickFn === "function") {
            this.navNode.onClickFn(this.navNode);
            return;
        }

        const itemToNavigate = (item: ProcessedNavNode): ProcessedNavNode | null => {
            if (item.disabled) return null;
            item.data!.expanded = true;
            return this.hasChildren(item)
                ? item.children!.find(child => !!itemToNavigate(child))!
                : item;
        };

        let navigateTo: ProcessedNavNode | null;

        if (!this.navNode.data?.expanded && this.navNode.data?.navigable) {
            this.onToggle();
            navigateTo = null;
        } else if (this.navNode.data?.navigable) {
            navigateTo = this.navNode;
        } else {
            navigateTo = itemToNavigate(this.navNode);
        }
        if (navigateTo) {
            this.navigate.emit(navigateTo);
        }
    }

    onToggle(): void {
        this.navNode.data!.expanded = !this.navNode.data!.expanded;
    }
}
