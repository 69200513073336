<div class="lg-range-slider__main">
    <div class="lg-range-slider__background-track">
        <div
            #focusHandle
            class="lg-range-slider__focus-handle"
            [style.left.%]="_focusHandlePosition"
        ></div>
    </div>

    <div class="lg-range-slider__background-bar"></div>
    <div class="lg-range-slider__active-bar">
        <div
            class="lg-range-slider__active-bar__strip"
            [style.width.%]="_stripWidth"
            [style.left.%]="_stripLeftPosition"
        ></div>
    </div>
    <div class="lg-range-slider__ruler" [style.visibility]="hideTicks ? 'hidden' : 'visibile'">
        <div
            *ngFor="let item of _tickers; let index = index"
            [ngClass]="{
                'lg-range-slider__ruler__tick': true,
                'lg-range-slider__ruler__tick--highlighted': item.css === 'highlight',
                'lg-range-slider__ruler__tick--zero': item.css === 'zero',
                'lg-range-slider__ruler__tick--active':
                    _state.from <= item.value && item.value <= _state.to
            }"
            [style.left.%]="item.position"
        ></div>
    </div>
    <div #track class="lg-range-slider__track">
        <a
            href="#"
            #handleFrom
            class="lg-range-slider__track__handle lg-range-slider__track__handle--from"
            [attr.disabled]="disabled"
            [style.left.%]="_fromHandlePosition"
            (keydown)="_onKeyDown($event, true)"
            (mousedown)="_onMouseDown(true)"
            (mouseover)="_onHandleMouseOver()"
            (focus)="_onHandleFocus()"
        >
            <div class="lg-range-slider__track__handle__element"></div>
            <div
                #fromTooltip
                [class.lg-range-slider__track__handle__tooltip--hidden]="showFixedSelectedValues"
                class="lg-tooltip lg-tooltip--simple lg-tooltip--simple lg-range-slider__track__handle__tooltip lg-range-slider__track__handle__tooltip--from"
            >
                {{ _fromHandleTooltip }}
                <div class="lg-range-slider__track__handle__tooltip__arrow-holder"></div>
            </div>
            <div
                #joinedTooltipFrom
                [class.lg-range-slider__track__handle__tooltip--hidden]="showFixedSelectedValues"
                class="lg-tooltip lg-tooltip--simple lg-tooltip--simple lg-range-slider__track__handle__tooltip"
            >
                {{ _joinedHandleTooltip }}
                <div class="lg-range-slider__track__handle__tooltip__arrow-holder"></div>
            </div>
        </a>
        <a
            href="#"
            #handleTo
            class="lg-range-slider__track__handle lg-range-slider__track__handle--to"
            [attr.disabled]="disabled"
            [style.left.%]="_toHandlePosition"
            (keydown)="_onKeyDown($event, false)"
            (mousedown)="_onMouseDown(false)"
            (mouseover)="_onHandleMouseOver()"
            (focus)="_onHandleFocus()"
        >
            <div class="lg-range-slider__track__handle__element"></div>
            <div
                #toTooltip
                [class.lg-range-slider__track__handle__tooltip--hidden]="showFixedSelectedValues"
                class="lg-tooltip lg-tooltip--simple lg-tooltip--simple lg-range-slider__track__handle__tooltip lg-range-slider__track__handle__tooltip--to"
            >
                {{ _toHandleTooltip }}
                <div class="lg-range-slider__track__handle__tooltip__arrow-holder"></div>
            </div>
            <div
                #joinedTooltipTo
                [class.lg-range-slider__track__handle__tooltip--hidden]="showFixedSelectedValues"
                class="lg-tooltip lg-tooltip--simple lg-tooltip--simple lg-range-slider__track__handle__tooltip"
            >
                {{ _joinedHandleTooltip }}
                <div class="lg-range-slider__track__handle__tooltip__arrow-holder"></div>
            </div>
        </a>
    </div>
</div>

<div
    *ngIf="showFixedSelectedValues"
    class="lg-range-slider__fixed-values"
    [attr.ignore-click]="true"
>
    <span [attr.ignore-click]="true">{{ _fromHandleTooltip }}</span>
    <span [attr.ignore-click]="true">{{ _toHandleTooltip }}</span>
</div>
