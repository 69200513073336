import { coerceBooleanProperty } from "@angular/cdk/coercion";
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    forwardRef,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    Renderer2,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation,
    Output,
    EventEmitter,
    AfterViewInit,
    inject
} from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { fromEvent, Subject } from "rxjs";
import { take, takeUntil, takeWhile } from "rxjs/operators";
import { getRangeSliderRuler, ITicker } from "./getRangeSliderRuler";
import { NgClass, NgForOf, NgIf } from "@angular/common";

const MIN_MONTH = 0;
const MAX_MONTH = 11;
const VISUAL_MIN_MONTH = 1;
const VISUAL_MAX_MONTH = 12;
const RANGE = 12;
const TICKER_STEP = 1;
const HIGHLIGHT_STEP = 13;

interface IRange {
    from: number;
    to: number;
}

enum HoveredOn {
    None,
    From,
    To
}

@Component({
    selector: "lg-month-range-slider",
    templateUrl: "./lg-month-range-slider.component.html",
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => LgMonthRangeSliderComponent),
            multi: true
        }
    ],
    host: {
        "(click)": "_onElementClick($event)",
        "[class.lg-month-range-slider]": "true",
        "[class.lg-month-range-slider--disabled]": "inactive"
    },
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [NgClass, NgIf, NgForOf],
    encapsulation: ViewEncapsulation.None
})
export class LgMonthRangeSliderComponent implements OnChanges, OnDestroy, AfterViewInit {
    private _changeDetectorRef = inject(ChangeDetectorRef);
    private _ngZone = inject(NgZone);
    private _renderer = inject(Renderer2);
    /**
     * Specifies whether the slider is inactive.
     */
    @Input() inactive = false;

    /**
     * Specifies the range to be shown in slider. The regular range slider uses ngmodel instead.
     */
    @Input({ required: true }) range!: IRange;

    /**
     * Specifies the invalid range for the slider. The invalid range has different colors can be used in validation.
     * For example of usage, see FCM Production Tariffs page.
     */
    @Input("invalidRange") tariffInvalidRange?: IRange;

    /**
     * Specifies whether the user is authorized to edit the slider or whether it is view only.
     * For example of usage, see FCM Production Tariffs page.
     */
    @Input("disabled") isDisabled = false;

    @Input("canBeChanged") canChange = true;

    /**
     * Emits whenever the value is changed after dragging stops.
     * Can be used whenever ngModelChange happens too often and we want this to trigger only after dragging ends.
     */
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output("onChange") readonly _onChange: EventEmitter<IRange> = new EventEmitter<IRange>();

    @ViewChild("focusHandle", { static: true }) private _focusHandleRef!: ElementRef;
    @ViewChild("handleFrom") private _handleFromRef?: ElementRef;
    @ViewChild("handleTo") private _handleToRef?: ElementRef;
    @ViewChild("track") private _trackRef?: ElementRef;

    _tickers: ITicker[] = [];
    _focusHandlePosition?: number;
    _fromHandlePosition!: number;
    _toHandlePosition!: number;

    _value!: IRange;
    _stripLeftPosition!: number;
    _stripWidth!: number;
    _invalidStripLeftPosition?: number;
    _invalidStripWidth?: number;

    private _isFromSelected = false;
    private _isHoveredOn: HoveredOn = HoveredOn.None;
    private _ready = false;
    private _destroyed = new Subject<void>();

    private _invalidValue: IRange | null = null;
    private _valueForOutput!: IRange;

    ngOnChanges(changes: SimpleChanges): void {
        if (!this._ready && !changes.range) return;
        if (!this._ready && changes.range) this._setDefaultProps();
        if (changes.range) this._updateRegularValues();
        if (changes.tariffInvalidRange) this._updateInvalidValues();
        if (changes.inactive) this.inactive = coerceBooleanProperty(this.inactive);
    }

    private _setDefaultProps(): void {
        this._tickers = getRangeSliderRuler(
            MIN_MONTH,
            VISUAL_MAX_MONTH,
            TICKER_STEP,
            HIGHLIGHT_STEP
        );
        this._updateRegularValues();
        this._updateInvalidValues();
        this._ready = true;
    }

    private _updateRegularValues(): void {
        if (!this.canChange) return;
        this._value = {
            from: this.range.from - 1,
            to: this.range.to
        };
        this._valueForOutput = this.range;
        this._updatePosition();
    }

    private _updateInvalidValues(): void {
        if (!this.tariffInvalidRange) {
            this._invalidValue = null;
            return;
        }
        this._invalidValue = {
            from: Math.max(
                MIN_MONTH,
                Math.min(MAX_MONTH, Math.round(this.tariffInvalidRange.from - 1))
            ),
            to: Math.max(
                VISUAL_MIN_MONTH,
                Math.min(VISUAL_MAX_MONTH, Math.round(this.tariffInvalidRange.to))
            )
        };
        this._updatePosition();
    }

    ngAfterViewInit(): void {
        if (!this.canChange) return;
        this._listenToMouseoverEvents();
        this._listenToMouseleaveEvents();
    }

    private _listenToMouseoverEvents(): void {
        this._showFromHandle();
        this._showToHandle();
    }

    private _showFromHandle(): void {
        if (!this._handleFromRef) return;
        this._renderer.listen(this._handleFromRef.nativeElement, "mouseover", () => {
            this._isHoveredOn = HoveredOn.From;
            this._focusHandlePosition = this._fromHandlePosition;
            if (!this.inactive && !this.isDisabled)
                this._renderer.setStyle(
                    this._focusHandleRef.nativeElement,
                    "visibility",
                    "visible"
                );
            this._changeDetectorRef.markForCheck();
        });
    }

    private _showToHandle(): void {
        if (!this._handleToRef) return;
        this._renderer.listen(this._handleToRef.nativeElement, "mouseover", () => {
            this._isHoveredOn = HoveredOn.To;
            this._focusHandlePosition = this._toHandlePosition;
            if (!this.inactive && !this.isDisabled)
                this._renderer.setStyle(
                    this._focusHandleRef.nativeElement,
                    "visibility",
                    "visible"
                );
            this._changeDetectorRef.markForCheck();
        });
    }

    private _listenToMouseleaveEvents(): void {
        this._hideFromHandle();
        this._hideToHandle();
    }

    private _hideFromHandle(): void {
        if (!this._handleFromRef) return;
        this._renderer.listen(this._handleFromRef.nativeElement, "mouseleave", () => {
            this._isHoveredOn = HoveredOn.None;
            this._renderer.setStyle(this._focusHandleRef.nativeElement, "visibility", "hidden");
        });
    }

    private _hideToHandle(): void {
        if (!this._handleToRef) return;
        this._renderer.listen(this._handleToRef.nativeElement, "mouseleave", () => {
            this._isHoveredOn = HoveredOn.None;
            this._renderer.setStyle(this._focusHandleRef.nativeElement, "visibility", "hidden");
        });
    }

    ngOnDestroy(): void {
        this._destroyed.next();
        this._destroyed.complete();
    }

    _onMouseDown(isFromSelected: boolean): boolean {
        if (!this.canChange || this.isDisabled) return true;

        this._isFromSelected = isFromSelected;
        let dragging = true;

        this._ngZone.runOutsideAngular(() => {
            fromEvent<MouseEvent>(document, "mousemove")
                .pipe(
                    takeUntil(this._destroyed),
                    takeWhile(() => dragging)
                )
                .subscribe((event2: MouseEvent) => {
                    this._reactToMouseEvent(event2, () => this._isFromSelected);
                    return false;
                });
        });

        fromEvent<MouseEvent>(document, "mouseup")
            .pipe(takeUntil(this._destroyed), take(1))
            .subscribe(() => {
                this._isFromSelected = false;
                dragging = false;
                this._onChange.emit(this._valueForOutput);
                return false;
            });

        return false;
    }

    _onElementClick(event: MouseEvent): boolean {
        if (!this.canChange) return true;
        if (this.isDisabled) return false;

        this._reactToMouseEvent(event, pos => this._isFromNearest(pos));
        this._onChange.emit(this._valueForOutput);
        return false;
    }

    private _reactToMouseEvent(
        event: MouseEvent,
        isFromHandleNearest: (cursorPosition: number) => boolean
    ): void {
        let currentCursorPosition = this._getRelativeCursorPositionFromLeft(event);

        if (isFromHandleNearest(currentCursorPosition)) {
            currentCursorPosition = this._clampValue(currentCursorPosition, null, this._value.to);
            if (currentCursorPosition !== this._value.from)
                this._writeValue({ from: currentCursorPosition, to: this._value.to });
            this._handleFromRef?.nativeElement.focus();
        } else {
            currentCursorPosition = this._clampValue(currentCursorPosition, this._value.from, null);
            if (currentCursorPosition !== this._value.to)
                this._writeValue({ from: this._value.from, to: currentCursorPosition });
            this._handleToRef?.nativeElement.focus();
        }
    }

    private _isFromNearest(currentCursorPosition: number): boolean {
        const currentLeft = Math.max(this._value.from, MIN_MONTH);
        const currentRight = Math.min(this._value.to, MAX_MONTH);

        if (currentLeft === currentRight) return currentCursorPosition < currentLeft;

        return (
            Math.abs(currentCursorPosition - currentLeft) <
            Math.abs(currentCursorPosition - currentRight)
        );
    }

    private _getRelativeCursorPositionFromLeft(event: MouseEvent): number {
        const leftOffset = this._trackRef?.nativeElement.getBoundingClientRect().left + 5;
        const frac =
            (event.clientX - leftOffset) / (this._trackRef?.nativeElement.offsetWidth - 10);

        return RANGE * frac;
    }

    private _clampValue(value: number, min: number | null, max: number | null): number {
        if (value > VISUAL_MAX_MONTH) value = VISUAL_MAX_MONTH;
        if (value < MIN_MONTH) value = MIN_MONTH;
        if (min !== null && value <= min) return min + 1;
        if (max !== null && value >= max) return max - 1;

        return value;
    }

    private _writeValue(value: IRange): void {
        this._value = this._getValue(value);
        this._valueForOutput = this._getValueForOutput(this._value);
        this._updatePosition();
    }

    private _getValue(value: IRange): IRange {
        return {
            from: Math.max(MIN_MONTH, Math.min(MAX_MONTH, Math.round(value.from))),
            to: Math.max(VISUAL_MIN_MONTH, Math.min(VISUAL_MAX_MONTH, Math.round(value.to)))
        };
    }

    private _getValueForOutput(value: IRange): IRange {
        return {
            from: value.from + 1,
            to: value.to
        };
    }

    private _updatePosition(): void {
        this._ngZone.run(() => {
            this._updateStripsPositions();
            this._changeDetectorRef.markForCheck();
        });
    }

    private _updateStripsPositions(): void {
        if (this._value) this._updateRegularStripPosition();
        if (this._invalidValue) this._updateInvalidStripPosition();
    }

    private _updateRegularStripPosition(): void {
        this._setHandlePositions(this._value.from, this._value.to);
        this._focusHandlePosition =
            this._isHoveredOn === HoveredOn.From
                ? this._fromHandlePosition
                : this._toHandlePosition;
        this._setStripPosition(this._value.from, this._value.to);
    }

    private _setStripPosition(from: number, to: number): void {
        this._stripLeftPosition = (100 * from) / RANGE;
        this._stripWidth = (100 * (to - from)) / RANGE;
    }

    private _setHandlePositions(from: number, to: number): void {
        this._fromHandlePosition = (100 * from) / RANGE;
        this._toHandlePosition = (100 * to) / RANGE;
    }

    private _updateInvalidStripPosition(): void {
        if (!this._invalidValue) return;
        this._invalidStripLeftPosition = (100 * this._invalidValue.from) / RANGE;
        this._invalidStripWidth = (100 * (this._invalidValue.to - this._invalidValue.from)) / RANGE;
    }

    _isTickInvalid(ticker: ITicker): boolean {
        if (!this._invalidValue) return false;
        if (this._value)
            return (
                !(this._value.from <= ticker.value && ticker.value <= this._value.to) &&
                this._invalidValue.from <= ticker.value &&
                ticker.value <= this._invalidValue.to
            );
        return this._invalidValue.from <= ticker.value && ticker.value <= this._invalidValue.to;
    }

    _hasInvalidBar(): boolean {
        return this._invalidValue != null;
    }

    _invalidStartsOnFirst(): boolean {
        return this._invalidValue?.from === 0;
    }

    _invalidEndsOnLast(): boolean {
        return this._invalidValue?.to === 12;
    }
}
