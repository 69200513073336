import { Observable } from "rxjs";

import { FilterSetState } from "./lg-filterset.types";

export class BookmarkStoreCreateError extends Error {
    readonly bookmarkName: string;
    constructor(bookmarkName: string, message: string, error?: any) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        super(message, { cause: error });
        this.bookmarkName = bookmarkName;
    }
}

export interface ILgKeyedBookmarkStore<TState extends FilterSetState = FilterSetState> {
    /** Get stream of bookmarks for the specified key. The stream MUST emit new value after succesfull create/update/delete operation.
     * Where applicable (db store), this operation should reload the bookmarks, rather than storing them
     */
    getBookmarks(): Observable<TState[]>;

    /** Get the specified bookmark, or undefined */
    getBookmark(id: string): Promise<TState | undefined>;

    /** Delete bookmark and return result of the operation. The code can assume, that getBookmarks was called first */
    delete(id: string): Promise<void>;

    /** Update the filter store of the specified bookmark. */
    updateFilters(id: string, stateParts: Record<string, string>): Promise<void>;

    /** Initialize editing of the specified bookmark. If the user confirms it, the saves should be changed */
    startEdit(id: string): Promise<void>;

    /** Initialize creating a new bookmark. If the user confirms it, it should be stored. Return the new ID.
     * If possible, failed attempt should throw exception fulfilling IBookmarkStoreCreateFailure */
    startSaveAs(stateParts: Record<string, string>): Promise<string>;
}

export interface ILgBookmarkStore<TState extends FilterSetState = FilterSetState> {
    /** Get store keeping state for the specified key (page) */
    getKeyedStore(key: string): ILgKeyedBookmarkStore<TState>;

    /** Localized name of the menu group  */
    readonly menuGroupName: Observable<string>;

    /** Is the store readonly? This affects both editing, and creation of new bookmarks */
    readonly isReadOnly: Observable<boolean>;

    /** Specifies, if this store should be used exclusively for saving new bookmarks */
    readonly isExclusive: boolean;

    /** Specifies stores priority. Lower = higher */
    readonly priority: number;

    /** Localized text to be shown for empty menu group, if text is "" then group is not shown at all */
    readonly emptyGroupText: Observable<string>;
}

export interface ILgBookmarkGroup {
    name: string;
    storeId: number;
    readOnly: boolean;
    exclusive: boolean;
    bookmarks: FilterSetState[];
    emptyGroupText: string;
}
