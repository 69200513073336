import { Pipe, PipeTransform, inject } from "@angular/core";
import { ILgFormatter, ILgFormatterOptions, NumberFormatterFactory } from "@logex/framework/core";
import { Nullable } from "@logex/framework/types";

export type IFmtFloatOptions = Partial<
    Pick<ILgFormatterOptions, "decimals" | "forceSign" | "zeroDash">
>;

const defaultOptions: ILgFormatterOptions = {};

@Pipe({
    name: "fmtFloat",
    pure: true
})
export class LgFormatFloatPipe implements PipeTransform {
    private _formatter: ILgFormatter<number> =
        inject(NumberFormatterFactory).create(defaultOptions);

    public transform(input: Nullable<string | number>, options?: IFmtFloatOptions): string;

    /**
     * @deprecated Use options variant instead
     */
    public transform(
        value: Nullable<string | number>,
        decimals?: number,
        forceSign?: boolean
    ): string;

    public transform(
        value: Nullable<string | number>,
        options?: number | IFmtFloatOptions,
        forceSign?: boolean
    ): string {
        const parameterOptions = this._getParameterOptions(options, forceSign);

        if (typeof value === "string") {
            value = +value;
        }
        return this._formatter.format(value, parameterOptions);
    }

    private _getParameterOptions(
        options?: number | IFmtFloatOptions,
        forceSign?: boolean
    ): ILgFormatterOptions {
        let parameterOptions: ILgFormatterOptions;
        if (typeof options === "number") {
            parameterOptions = { decimals: options };
        } else {
            parameterOptions = { ...options };
        }

        if (forceSign !== undefined) {
            parameterOptions.forceSign = forceSign;
        }
        return parameterOptions;
    }
}
