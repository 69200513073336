import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    standalone: true,
    // eslint-disable-next-line @angular-eslint/pipe-prefix
    name: "highlightByRegex",
    pure: true
})
export class LgHighlightByRegexPipe implements PipeTransform {
    public transform(text: string, regex: RegExp): string {
        if (regex != null) {
            text = text.toString();
            return text.replace(new RegExp(regex, "gi"), `<span class="text-highlight">$&</span>`);
        } else {
            return text;
        }
    }
}
