import { Directive } from "@angular/core";
import { SidebarWidgetBase } from "./sidebar-widget-base";

@Directive({
    selector: "[lgSidebarWidget]",
    // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
    inputs: [
        "onTop:lgSidebarWidgetOnTop",
        "isVisible: lgSidebarWidgetIsVisible",
        "order: lgSidebarWidgetOrder",
        "id: lgSidebarWidgetId"
    ]
})
export class LgSidebarWidgetDirective extends SidebarWidgetBase {}
