import { Directive, ElementRef, HostListener, inject, Input, Renderer2 } from "@angular/core";

/**
 * When the target element obtains a focus, the specified parent automatically gets the class lg-contains-focus.
 * This is typically used in tables to mark the active row.
 */
@Directive({
    selector: "[lgMarkFocusOn]"
})
export class LgMarkFocusOnDirective {
    private _el = inject(ElementRef);
    private _renderer = inject(Renderer2);

    /**
     * Specifies the CSS selector of the closest parent  that will obtain the focus marker.
     */
    @Input("lgMarkFocusOn") markFocusOn!: string | null | undefined;

    private _className = "lg-contains-focus";
    private _target: HTMLElement | null = null;

    @HostListener("focus")
    _onFocus(): void {
        if (this.markFocusOn) {
            this._target = this._el.nativeElement.closest(this.markFocusOn);
            if (this._target) {
                this._renderer.addClass(this._target, this._className);
            }
        }
    }

    @HostListener("blur")
    _onBlur(): void {
        if (this._target) {
            this._renderer.removeClass(this._target, this._className);
            this._target = null;
        }
    }
}
