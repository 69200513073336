import { CanDeactivateFn } from "@angular/router";
import { LgDialogService } from "./lg-dialog.service";
import { inject } from "@angular/core";

// The guard which initiates dialogs closing process on route leave.
// Can be used as `canDeactivate` guard used on a specific page route
// or as `canActivateChild` guard at the root route of lazy loaded module.
// Set route.runGuardsAndResolvers = "pathParamsOrQueryParamsChange"
// in cases when `canDeactivate` guard does not react to query params change.
export const LgDialogCloseControlGuard: CanDeactivateFn<unknown> = () => {
    const dialogService = inject(LgDialogService);
    return dialogService.tryCloseAllDialogs("routeGuard");
};
