import { Directive, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { ILgFormatterOptions } from "@logex/framework/core";

import { BigNumberDisplayBase } from "./big-number-display.base";

@Directive({
    selector: "[lgBigGrowth]"
})
export class LgBigGrowthDirective extends BigNumberDisplayBase implements OnInit, OnChanges {
    @Input("lgBigGrowth") inputValue: string | null = null;
    @Input("lgBigGrowthDecimals") decimals?: number;
    @Input("lgBigGrowthMin") min?: number;
    @Input("lgBigGrowthMax") max?: number;

    ngOnInit(): void {
        super._onInit();
    }

    ngOnChanges(changes: SimpleChanges): void {
        super._onChanges(changes);
    }

    protected _defaultProps(): void {
        this.decimals = this.decimals === undefined ? 1 : this.decimals;
        this.max = this.max === undefined ? 9.9 : this.max;
        this.min = this.min === undefined ? -9.9 : this.min;
    }

    protected _getFormatterOptions(): ILgFormatterOptions {
        return {
            decimals: this.decimals,
            forceSign: true,
            viewScale: 2,
            forceFormat: true,
            zeroDash: true
        };
    }
}
