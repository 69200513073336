import { Injectable } from "@angular/core";

import { ILgFormatter } from "../implementation/formatter-types";
import { NoFormatter } from "../implementation/no-formatter";
import { ILgFormatterFactory } from "./formatter-factory-types";

@Injectable({ providedIn: "root" })
export class NoFormatterFactory implements ILgFormatterFactory {
    names = ["none", "text"];

    create(): ILgFormatter<number> {
        return new NoFormatter();
    }
}
