/* eslint-disable @typescript-eslint/no-this-alias */
import { coerceNumberProperty } from "@angular/cdk/coercion";
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    inject,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    Renderer2,
    SimpleChanges,
    TemplateRef,
    ViewChild,
    ViewContainerRef
} from "@angular/core";
import { TemplatePortal } from "@angular/cdk/portal";
import * as d3 from "d3";

import {
    ILgFormatter,
    ILgFormatterOptions,
    LgFormatterFactoryService
} from "@logex/framework/core";
import { D3TooltipApi, LgD3TooltipService } from "../d3";
import { toBoolean } from "@logex/framework/utilities";
import { LgTranslateService, useTranslationNamespace } from "@logex/framework/lg-localization";

import {
    ChartValueType,
    LegendItem,
    LegendOptions,
    LegendPosition,
    Margin,
    PointReference
} from "../shared/chart.types";
import { getDefaultLegendOptions } from "../shared/getDefaultLegendOptions";
import { getLegendWidth } from "../shared/getLegendWidth";
import { LgColorPalette } from "../shared/lg-color-palette";
import {
    ConversionResultStatus,
    ILineDataConverter,
    LgLineChartColumnIconOptions,
    LgLineChartSpreadVisual,
    LgLineChartTooltipContext,
    LineChartConverterResult,
    LineChartExtremes,
    LineChartItem
} from "./lg-line-chart.types";
import { doCirclePointsOverlap } from "../shared/doCirclePointsOverlap";
import { LineDataConverter } from "./LineDataConverter";
import {
    LG_DEFAULT_COLOR_CONFIGURATION,
    LG_USE_NEW_LABELS,
    LgColorsConfiguration
} from "../shared/lg-color-palette-v2/lg-colors.types";
import { LgColorPaletteV2 } from "../shared/lg-color-palette-v2/lg-color-palette-v2";
import { IExportableChart, LgChartExportContainerDirective } from "../shared/lg-chart-export";
import {
    IChartTooltipProvider,
    IImplicitContext
} from "../shared/lg-chart-template-context.directive";
import { getRecommendedPosition } from "../shared/getRecommendedPosition";

const STROKE_WIDTH = 2;
const DEFAULT_MARGIN: Required<Margin> = { top: 16, right: 16, bottom: 16, left: 16 };
const LEFT_MARGIN_WITH_Y_AXIS_TITLE = 40;
const RATIO_OF_STEP_ON_SIDES = 0.4; // step*ratio --> distance between X axis start and first tick and X axis end and last tick
const SPACE_FOR_X_AXIS_LABELS = 34;
const SPACE_FOR_X_AXIS_TITLE = 20;
const SPACE_FOR_X_AXIS_LABELS_TOP_PART = 20;
const DEFAULT_NUMBER_OF_TICKS = 5;
const POINT_RADIUS = 4;
const POINT_STROKE = 2;
const SPREAD_STROKE = 1;
const SPREAD_BOUNDARY_RADIUS = 2;
const DEFAULT_TICKS_FORMATTER_OPTIONS: ILgFormatterOptions = { decimals: 0 };
const DEFAULT_TOOLTIP_FORMATTER_OPTIONS: ILgFormatterOptions = { decimals: 2 };
const DEFAULT_VALUE_LABEL_FORMATTER_OPTIONS: ILgFormatterOptions = {
    decimals: 0
};
const DEFAULT_COLUMN_ICON_OPTIONS = {
    icon: "icon-warning",
    iconType: "regular",
    tooltip: false,
    tooltipText: ".IconText"
};
const SPACE_FOR_LEGEND_BELOW = 38;
const SPACE_BETWEEN_Y_AXIS_TICKS_AND_AXIS = 8;
const ICON_SIZE = 24;

const DEFAULT_SPACE_FOR_ROTATED_LABELS = 20;
const DEFAULT_MARGIN_FOR_ROTATED_LABELS = 35;
const rotatedXAxisOptions: (type: RotatedXAxisTypes) => {
    maxLength: number;
    rotationMargin: number;
} = type => {
    switch (type) {
        case "wide":
            return { maxLength: 30, rotationMargin: 105 };
        case "narrow":
            return { maxLength: 15, rotationMargin: 10 };
        default:
        case "medium":
            return {
                maxLength: DEFAULT_SPACE_FOR_ROTATED_LABELS,
                rotationMargin: DEFAULT_MARGIN_FOR_ROTATED_LABELS
            };
    }
};
export type RotatedXAxisTypes = "wide" | "medium" | "narrow";

const SHADOW_COLOR = "#F2FAFF";
const SHADOW_RADIUS = 12;
const SHADOW_STROKE = 0;
const SHADOW_SPREAD_HEIGHT_EXTEND = 16;
const SHADOW_SPREAD_WIDTH = 16;

function fill(
    element: d3.Selection<any, any, any, any>
): (color: string) => d3.Selection<any, any, any, any> {
    return (color: string) => element.attr("fill", color);
}

@Component({
    selector: "lg-line-chart",
    templateUrl: "./lg-line-chart.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    viewProviders: [useTranslationNamespace("FW._Directives._Charts._LgLineChart")]
})
export class LgLineChartComponent
    implements
        OnChanges,
        OnDestroy,
        AfterViewInit,
        IExportableChart,
        IChartTooltipProvider<LgLineChartTooltipContext>
{
    private _colorPalette = inject(LgColorPaletteV2);
    private _converter: ILineDataConverter = inject(LineDataConverter);
    private _detector = inject(ChangeDetectorRef);
    private _exportContainer = inject(LgChartExportContainerDirective, { optional: true });
    private _formatterFactory = inject(LgFormatterFactoryService);
    private _legacyColorPalette = inject(LgColorPalette);
    private _tooltipService = inject(LgD3TooltipService);
    private _translateService = inject(LgTranslateService);
    private _useNewLabels = inject(LG_USE_NEW_LABELS);
    private _elementRef = inject(ElementRef);
    private _ngZone = inject(NgZone);
    private _renderer = inject(Renderer2);
    private _viewContainerRef = inject(ViewContainerRef);

    /**
     * @required
     * Specifies the input data for the chart.
     */
    @Input({ required: true }) data!: any[];

    /**
     * Specifies data converter.
     *
     * @default `LineDataConverter`
     */
    @Input() dataConverter?: ILineDataConverter;

    /**
     * @required
     * Callback for providing the column name of related data item (required).
     */
    @Input({ required: true }) columnName!: (item: any) => string;

    /**
     * @optional
     * Callback for providing the column top name.
     */
    @Input() columnTopName?: (item: any) => string;

    /**
     * @optional
     * Callback for specifying whether the column top name is shown or not.
     */
    @Input() showColumnTopName?: (item: any) => boolean;

    /**
     * @required
     * Callback for providing the group values of related data item.
     */
    @Input({ required: true }) groupValues!: (item: any) => Array<number | null | undefined>;

    /**
     * @optional
     * Callback for providing group spread ranges.
     * If specified then chart contains spreads.
     */
    @Input() spreadValues?: (locals: any) => Array<[number, number] | null> | null;

    /**
     * @optional
     * Specifies spread visualisation type.
     *
     * @default "error-bars"
     */
    @Input() spreadVisualisationType? = LgLineChartSpreadVisual.ErrorBars;

    /**
     * @required
     * Specifies group names of the chart.
     * Value can be either `string[]` or callback for providing group names.
     */
    @Input({ required: true }) groupNames!: string[] | (() => string[]);

    /**
     * @optional
     * Specifies whether double axis is used or not.
     *
     * @default false
     */
    @Input() useDoubleAxis?: boolean;

    /**
     * @deprecated use colorConfiguration instead
     */
    @Input() customPalette?: string[];

    /**
     * @optional
     * Callback for specifying whether group column contains icon or not.
     */
    @Input() columnIcons?: (item: any) => boolean;

    /**
     * @optional
     * Specifies group column icon options.
     *
     * @default  `{ icon: "icon-warning", iconType: "regular",  tooltip: false, tooltipText: ".IconText" }`
     */
    @Input() columnIconOptions?: LgLineChartColumnIconOptions;

    /**
     * @optional
     * Specifies the tooltip template to be used when hovering over column icons. Defaults to own template.
     */
    @Input() columnIconTooltipTemplate?: TemplateRef<IImplicitContext<LgLineChartTooltipContext>>;

    /**
     * @optional
     * Specifies whether title should be aligned with axis or not.
     *
     * @default false
     */
    @Input() alignTitleWithAxis = false;

    /**
     * @optional
     * Specifies whether X axis line is hidden or not.
     *
     * @default false
     */
    @Input() hideXAxisLine = false;

    /**
     * @optional
     * Specifies whether X axis is hidden or not.
     *
     * @default false
     */
    @Input() hideXAxis = false;

    /**
     * @optional
     * Specifies whether Y axis is hidden or not.
     *
     * @default false
     */
    @Input() hideYAxis = false;

    /**
     * Specifies the X-axis title. If not specified then there is no X-axis title.
     */
    @Input() xAxisLabel = "";

    /**
     * @optional
     * Specifies the Y-axis title. If not specified then there is no X-axis title.
     */
    @Input() yAxisLabel = "";

    /**
     * @optional
     * Specifies space for X-axis labels in pixels.
     * Max allowed value is 8.
     */
    @Input() spaceForYAxisLabels = 0;

    /**
     * @optional
     * Specifies rotated type of X-axis.
     *
     * @type {"wide" | "medium" | "narrow" | null}
     *
     * @default "medium
     */
    @Input() rotatedXAxisType: RotatedXAxisTypes = "medium";

    /**
     * Specifies the height of X-axis labels area in pixels.
     *
     * @default 40
     */
    @Input() rotatedXAxisLabelsHeight = 40;

    /**
     * @optional
     * Specifies if X-axis labels should be rotated.
     *
     * @default false
     */
    @Input() rotateXAxisLabels = false;

    /**
     * @deprecated
     */
    @Input() comparingAgainst?: ChartValueType;

    /**
     * @optional
     * Specifies number of ticks.
     *
     * @default 5
     */
    @Input() numberOfTicks = DEFAULT_NUMBER_OF_TICKS;

    /**
     * @optional
     * Specifies whether number of ticks are forced to specified number or not.
     *
     * @default false
     */
    @Input() forceNumberOfTicks = false;

    /**
     * @deprecated use colorConfiguration instead
     */
    @Input() groupColors: string[] = [];

    /**
     * @optional
     * Specifies whether Y scale should start from 0 or not.
     *
     * @default false
     */
    @Input() startWithZero = false;

    /**
     * @optional
     * Callback for providing custom min/max values.
     */
    @Input() setCustomMinMax?: (data?: LineChartItem[][]) => LineChartExtremes;

    /**
     * @requires
     * Specifies the height of the chart area in pixels.
     */
    @Input({ required: true }) height!: number;

    /**
     * @requires
     * Specifies the width of the chart area in pixels.
     */
    @Input({ required: true }) width!: number;

    /**
     * @optional
     * Specifies class of tooltip. Default to empty.
     */
    @Input() tooltipClass = "";

    /**
     * @optional
     * Specifies the legend options. If not specified, legend is not visible.
     */
    @Input() legendOptions: LegendOptions = getDefaultLegendOptions();

    /**
     * @optional
     * Specifies formatter type for number axis. Defaults to "float".
     *
     * @default "float"
     */
    @Input() axisFormatterType = "float";

    /**
     * @optional
     * Specifies the options for number axis formatter. Defaults to 0 decimals.
     *
     * @default `{ decimals: 0 }`
     */
    @Input() axisFormatterOptions: ILgFormatterOptions = DEFAULT_TICKS_FORMATTER_OPTIONS;

    /**
     * @optional
     * Specifies the template to be used when hovering over elements. Defaults to own template.
     */
    @Input() tooltipTemplate?: TemplateRef<IImplicitContext<LgLineChartTooltipContext>>;

    /**
     * @optional
     * Specifies formatter type for tooltip numbers. Defaults to "float"
     *
     * @default "float"
     */
    @Input() tooltipFormatterType = "float";

    /**
     * @optional
     * Specifies the options for number axis formatter. Defaults to 2 decimals.
     *
     * @default `{ decimals: 2 }`
     */
    @Input() tooltipFormatterOptions: ILgFormatterOptions = DEFAULT_TOOLTIP_FORMATTER_OPTIONS;

    /**
     * @optional
     * Specifies X-axis labels to be shown.
     *
     * @default `[]`
     */
    @Input() showxAxisLabels: string[] = [];

    /**
     * @optional
     * Specifies formatter type for X-axis labels.
     *
     * @fefault "float"
     */
    @Input() xAxisLabelFormatterType = "float";

    /**
     * @optional
     * Specifies the options for X-axis label numbers formatter.
     *
     * @default `{ decimals: 0 }`
     */
    @Input() xAxisLabelFormatterOptions: ILgFormatterOptions =
        DEFAULT_VALUE_LABEL_FORMATTER_OPTIONS;

    /**
     * Specifies the color configuration. Defaults to categorical palette.
     *
     * If specified, allows four different configuration
     * - default/categorical - using 20 predefined colors
     * - sequential by color scheme - using predefined sequence of colors by name
     * - predefined - using predefined dictionary
     * - own - array of hexadecimal values
     *
     * For usage, see New Palette in storybook under LgCharts.
     * Palette story contains all possible colors.
     * Gallery story contains all charts using new colors.
     *
     * Example can be seen in 'getAllChartsProps.ts:62'
     */
    @Input() colorConfiguration: LgColorsConfiguration = LG_DEFAULT_COLOR_CONFIGURATION;

    /**
     * Specifies the frequency for X axis label ticks.
     * Frequency is applied on scale level before the chart is drawn
     * Applies before `xAxisLabelGroup` is called.
     *
     * @example xAxisLabelFrequency = 2 => every second tick is visible.
     */
    @Input() xAxisLabelFrequency: number | undefined;

    /**
     * Specifies offset of the x-axis from the left side.
     * Supported units: px, (r)em, %. Percentage values are translated
     */
    @Input() xAxisLabelOffset: string | undefined;

    /**
     * @optional
     * Specifies whether Y-axis should be prettyfied or not.
     *
     * @default false
     */
    @Input() makeYAxisNice = false;

    /**
     * @optional
     * Specifies whether data point circles should be shown or not.
     *
     * @default = true
     */
    @Input() showDataPointCircles = true;

    /**
     * @optional
     * Specifies whether horizontal guide lines should be shown or not.
     *
     * @default = true
     */
    @Input() showHorizontalGuideLines = true;

    /**
     * @optional
     * Specifies whether vertical guide lines should be shown or not.
     *
     * @default = true
     */
    @Input() showVerticalGuideLines? = false;

    @ViewChild("chart", { static: true }) private _chartHolder!: ElementRef;
    @ViewChild("chartWithLegend", { static: true })
    private _chartWithLegendDivRef!: ElementRef<HTMLDivElement>;

    @ViewChild("defaultTemplate", { static: true })
    private _defaultTooltipTemplate!: TemplateRef<IImplicitContext<LgLineChartTooltipContext>>;

    @ViewChild("columnIconTemplate", { static: true })
    private _columnIconTooltipTemplate!: TemplateRef<IImplicitContext<LgLineChartTooltipContext>>;

    _tooltipFormatter!: ILgFormatter<any>;
    _groupIsOnTop = false;
    _legendDefinition: LegendItem[] = [];
    _data: LineChartItem[][] = [];
    _spaceForYAxisLabels = 0;
    _legendWidth = 0;

    private _yMin = 0;
    private _yMax = 0;
    private _xMax = 0;
    private _margin!: Required<Margin>;

    private _svg!: d3.Selection<any, any, any, any>;
    private _xAxisGroup!: d3.Selection<any, any, any, any>;
    private _xAxisTopGroup!: d3.Selection<any, any, any, any>;
    private _yAxisGroup!: d3.Selection<any, any, any, any>;
    private _horizontalLinesGroup!: d3.Selection<any, any, any, any>;
    private _verticalLinesGroup!: d3.Selection<any, any, any, any>;
    private _dataGroups!: d3.Selection<any, any, any, any>;
    private _columnIconsGroup!: d3.Selection<any, any, any, any>;
    protected _yAxisLabel!: d3.Selection<any, any, any, any>;
    protected _xAxisLabel!: d3.Selection<any, any, any, any>;

    private _dataPointsShadows!: d3.Selection<any, any, any, any>;

    private _legendPosition: "none" | LegendPosition = "none";

    private _xScale!: d3.ScalePoint<any>;
    private _yScale!: d3.ScaleLinear<any, any>;

    private _groupColors!: d3.ScaleOrdinal<string, string>;

    private _pointRefs: Array<PointReference<LineChartItem>> = [];
    private _shadowPointRefs: Array<PointReference<LineChartItem>> = [];
    private _shadowSpreadRefs: Array<PointReference<LineChartItem>> = [];
    private _overlappingPointRefs: Array<PointReference<LineChartItem>> = [];
    private _overlappingShadowPointRefs: Array<PointReference<LineChartItem>> = [];
    private _overlappingShadowSpreadRefs: Array<PointReference<LineChartItem>> = [];

    private _tooltip!: D3TooltipApi;
    private _columnIconTooltip!: D3TooltipApi;
    private _tooltipContext: LgLineChartTooltipContext | null = null;
    private _tooltipPortal?: TemplatePortal<IImplicitContext<LgLineChartTooltipContext>>;
    private _columnIconTooltipPortal?: TemplatePortal<IImplicitContext<LgLineChartTooltipContext>>;

    get tooltipContext(): LgLineChartTooltipContext | null {
        return this._tooltipContext;
    }

    set tooltipContext(context: LgLineChartTooltipContext) {
        if (this._tooltipPortal?.context) {
            this._tooltipPortal.context.$implicit = context;
        }
        if (this._columnIconTooltipPortal?.context) {
            this._columnIconTooltipPortal.context.$implicit = context;
        }
        this._tooltipContext = context;
    }

    private _highlightedGroup: d3.Selection<any, any, any, any> | null = null;

    private _yTicksFormatter!: ILgFormatter<any>;
    private _xAxisLabelFormatter!: ILgFormatter<any>;

    private _initialized = false;

    private _xAxisLabels: number[] = [];

    private _lastMouseX = 0;
    private _lastMouseY = 0;
    private _trackListener!: () => void;

    ngOnChanges(changes: SimpleChanges): void {
        if (!this._initialized) {
            this._defaultProps();
            this._initialize();
            return;
        }

        let redrawNeeded = false;

        if (changes.axisFormatterType || changes.axisFormatterOptions) {
            this._yTicksFormatter = this._getFormatter(
                this.axisFormatterType,
                this.axisFormatterOptions
            );
            redrawNeeded = true;
        }

        if (changes.tooltipFormatterType || changes.tooltipFormatterOptions) {
            this._tooltipFormatter = this._getFormatter(
                this.tooltipFormatterType,
                this.tooltipFormatterOptions
            );
            redrawNeeded = true;
        }
        if (changes.xAxisLabelFormatterType || changes.xAxisLabelFormatterOptions) {
            this._xAxisLabelFormatter = this._getFormatter(
                this.xAxisLabelFormatterType,
                this.xAxisLabelFormatterOptions
            );
            redrawNeeded = true;
        }

        if (
            changes.columnName ||
            changes.columnTopName ||
            changes.groupValues ||
            changes.groupNames
        ) {
            this._configureConverter();
        }

        if (changes.comparingAgainst || changes.customPalette) {
            this._initializeOrUpdateColorScales(this._data);
        }

        if (changes.legendOptions) {
            this._updateLegendInfo();
        }

        if (changes.data || changes.groupNames) {
            this._configureConverter();
            this._convertDataAndSetExtremes();
            this._initializeOrUpdateColorScales(this._data);
            this._setLegendDefinition();
            redrawNeeded = true;
        }

        if (changes.setCustomMinMax) {
            this._convertDataAndSetExtremes();
            redrawNeeded = true;
        }

        if (changes.yAxisLabel || changes.rotateXAxisLabels || changes.rotatedXAxisType) {
            this._yAxisLabel.text(this.yAxisLabel);
            this._updateLeftMargin();
            redrawNeeded = true;
        }

        if (changes.xAxisLabel) {
            this._xAxisLabel.text(this.xAxisLabel);
            redrawNeeded = true;
        }

        if (
            changes.height ||
            changes.width ||
            changes.rotateXAxisLabels ||
            changes.useDoubleAxis ||
            changes.alignTitleWithAxis ||
            changes.removeDataPoints ||
            changes.xAxisLabelOffset ||
            changes.numberOfTicks ||
            changes.columnIcons ||
            changes.columnIconOptions
        ) {
            redrawNeeded = true;
        }

        if (redrawNeeded) {
            this._update();
            this._draw();
        }
    }

    ngAfterViewInit(): void {
        this._exportContainer?.register(this);
    }

    ngOnDestroy(): void {
        this._exportContainer?.unregister(this);
        if (this._tooltip) {
            this._tooltip.destroy();
        }
        if (this._columnIconTooltip) {
            this._columnIconTooltip.destroy();
        }
    }

    _onLegendItemHover(groupName: string): void {
        const groupIndex = this._findGroupIndex(groupName);
        if (groupIndex < 0) {
            this._onLegendItemLeave();
            return;
        }
        this._highlightGroup(groupIndex);
        this._moveGroupToTop(groupName);
    }

    _onLegendItemLeave(): void {
        if (this._highlightedGroup) {
            this._cancelGroupHighligt();
            this._groupIsOnTop = false;
        }
    }

    getHtmlElement(): HTMLElement {
        return this._chartWithLegendDivRef.nativeElement;
    }

    getSvgElement(): SVGElement {
        return this._svg.node();
    }

    private _initialize(): void {
        this._defaultProps();
        this._updateLegendInfo();
        this._updateLeftMargin();
        this._addElementsToDOM();
        this._initializeTooltip();
        this._initializeColumnIconTooltip();
        this._configureConverter();
        this._convertDataAndSetExtremes();
        this._initializeFormatters();
        this._initializeOrUpdateColorScales(this._data);
        this._initializeScales();
        this._setLegendDefinition();
        this._update();
        this._draw();
        this._trackMousePosition();

        this._initialized = true;
    }

    private _initializeTooltip(): void {
        this._tooltipPortal = this._getTooltipContent();
        this._tooltip = this._tooltipService.create({
            stay: false,
            trapFocus: false,
            position: "top-left",
            tooltipClass: `lg-tooltip lg-tooltip--d3 lg-line-chart__tooltip ${
                this.tooltipClass ? " " + this.tooltipClass : ""
            }`,
            content: this._tooltipPortal
        });
    }

    private _initializeColumnIconTooltip(): void {
        this._columnIconTooltipPortal = this._getColumnIconTooltipContent();
        this._columnIconTooltip = this._tooltipService.create({
            stay: false,
            trapFocus: false,
            position: "top-left",
            tooltipClass: `lg-tooltip lg-tooltip--d3 lg-line-chart__tooltip ${
                this.tooltipClass ? " " + this.tooltipClass : ""
            }`,
            content: this._columnIconTooltipPortal
        });
    }

    private _defaultProps(): void {
        this._margin = { ...DEFAULT_MARGIN }; // TODO discuss if we need to have this as input
        this.numberOfTicks = coerceNumberProperty(this.numberOfTicks, DEFAULT_NUMBER_OF_TICKS);
        this.forceNumberOfTicks = toBoolean(this.forceNumberOfTicks, false);
        this.comparingAgainst = this.comparingAgainst || "none";
        this.axisFormatterType = this.axisFormatterType || "float";
        this.tooltipFormatterType = this.tooltipFormatterType || "float";
        this.xAxisLabelFormatterType = this.xAxisLabelFormatterType || "float";
        this.tooltipFormatterOptions = {
            ...DEFAULT_TOOLTIP_FORMATTER_OPTIONS,
            ...this.tooltipFormatterOptions
        };
        this.axisFormatterOptions = {
            ...DEFAULT_TICKS_FORMATTER_OPTIONS,
            ...this.axisFormatterOptions
        };
        this.xAxisLabelFormatterOptions = {
            ...DEFAULT_VALUE_LABEL_FORMATTER_OPTIONS,
            ...this.xAxisLabelFormatterOptions
        };
        this.startWithZero = toBoolean(this.startWithZero, false);
        this.hideXAxisLine = toBoolean(this.hideXAxisLine, false);
        this.hideXAxis = toBoolean(this.hideXAxis, false);
        this.hideYAxis = toBoolean(this.hideYAxis, false);
        this.xAxisLabel = this.xAxisLabel || "";
        this.yAxisLabel = this.yAxisLabel || "";
        this._converter = this.dataConverter || this._converter;
        this.tooltipTemplate = this.tooltipTemplate || this._defaultTooltipTemplate;
        this.showxAxisLabels = this.showxAxisLabels || [];
        this.columnIconOptions = {
            ...DEFAULT_COLUMN_ICON_OPTIONS,
            tooltipText: this._translateService.translate(DEFAULT_COLUMN_ICON_OPTIONS.tooltipText),
            ...this.columnIconOptions
        };
        this.columnIconTooltipTemplate =
            this.columnIconTooltipTemplate || this._columnIconTooltipTemplate;
    }

    private _updateLeftMargin(): void {
        const rotationMargin = this.rotateXAxisLabels
            ? rotatedXAxisOptions(this.rotatedXAxisType).rotationMargin
            : 0;
        if (this.yAxisLabel) {
            this._margin.left = LEFT_MARGIN_WITH_Y_AXIS_TITLE + rotationMargin;
        } else {
            this._margin.left = DEFAULT_MARGIN.left + rotationMargin;
        }
    }

    private _updateLegendInfo(): void {
        const legendVisible = this.legendOptions.visible;

        if (!legendVisible) {
            this._legendPosition = "none";
        } else {
            this._legendPosition = this.legendOptions.position;
        }
    }

    private _configureConverter(): void {
        this._converter.configure({
            getColumnName: this.columnName,
            getColumnTopName: this.columnTopName,
            showColumnTopName: this.showColumnTopName,
            getGroupNames: this.groupNames,
            getGroupValues: this.groupValues,
            getSpreadValues: this.spreadValues
        });
    }

    private _convertDataAndSetExtremes(): void {
        const result = this._converter.convert(this.data);

        if (result.status === ConversionResultStatus.Empty) {
            this._data = [];
        }
        if (result.status === ConversionResultStatus.Valid) {
            this._setExtremes(result);
            this._data = result.data?.reverse() ?? [];
        }
    }

    private _setExtremes(result: LineChartConverterResult): void {
        if (this.setCustomMinMax) {
            const customExtremes = this.setCustomMinMax(result.data);
            this._yMin = Math.min(customExtremes.min, result.extremes?.min ?? 0);
            this._yMax = Math.max(customExtremes.max, result.extremes?.max ?? 0);
        } else {
            this._yMin = result.extremes?.min ?? 0;
            this._yMax = result.extremes?.max ?? 0;
        }

        result.data?.forEach(element => {
            const currentMaxValue = element[element.length - 1].indexWithinGroup;
            if (currentMaxValue > this._xMax || this._xMax === undefined) {
                this._xMax = currentMaxValue;
            }
        });
    }

    private _initializeFormatters(): void {
        this._yTicksFormatter = this._getFormatter(
            this.axisFormatterType,
            this.axisFormatterOptions
        );
        this._tooltipFormatter = this._getFormatter(
            this.tooltipFormatterType,
            this.tooltipFormatterOptions
        );
        this._xAxisLabelFormatter = this._getFormatter(
            this.xAxisLabelFormatterType,
            this.xAxisLabelFormatterOptions
        );
    }

    private _addElementsToDOM(): void {
        this._svg = d3.select(this._chartHolder.nativeElement).append("svg");
        this._dataPointsShadows = this._svg
            .append("g")
            .attr("class", "lg-line-chart__data-points-shadows-groups");
        this._horizontalLinesGroup = this._svg
            .append("g")
            .attr("class", "lg-line-chart__vertical-lines-group");
        this._verticalLinesGroup = this._svg
            .append("g")
            .attr("class", "lg-line-chart__vertical-lines-group");
        this._xAxisGroup = this._svg
            .append("g")
            .attr(
                "class",
                `${this._useNewLabels ? "lg-line-chart__x-axis" : "lg-line-chart__x-axis__legacy"}`
            );
        this._xAxisTopGroup = this._svg.append("g").attr(
            "class",
            `${this._useNewLabels ? "lg-line-chart__x-axis" : "lg-line-chart__x-axis__legacy"}
        `
        );
        this._xAxisLabel = this._svg
            .append("text")
            .attr(
                "class",
                `${
                    this._useNewLabels
                        ? "lg-line-chart__x-axis__title"
                        : "lg-line-chart__x-axis__title__legacy"
                }`
            )
            .attr("text-anchor", "start");
        this._yAxisGroup = this._svg
            .append("g")
            .attr(
                "class",
                `${this._useNewLabels ? "lg-line-chart__y-axis" : "lg-line-chart__y-axis__legacy"}`
            );
        this._yAxisLabel = this._svg
            .append("text")
            .attr("transform", "rotate(-90)")
            .attr(
                "class",
                `${
                    this._useNewLabels
                        ? "lg-line-chart__y-axis__title"
                        : "lg-line-chart__y-axis__title__legacy"
                }`
            )
            .style("text-anchor", "start");
        this._columnIconsGroup = this._svg.append("g").attr("class", "lg-line-chart__columnIcons");
        this._dataGroups = this._svg.append("g").attr("class", "lg-line-chart__data-groups");
    }

    private _initializeScales(): void {
        this._xScale = d3.scalePoint().padding(RATIO_OF_STEP_ON_SIDES);
        this._yScale = d3.scaleLinear();
    }

    private _getLegendSize(below: boolean, onTheRight: boolean): number {
        if (!below && !onTheRight) return 0;

        if (below) {
            return SPACE_FOR_LEGEND_BELOW;
        }

        return getLegendWidth(
            this.width,
            this.legendOptions.widthInPercents ?? 0,
            this._getGroupNames()
        );
    }

    private _update(): void {
        if (!this._data || !this._data.length) return;

        this._updateLegendWidth();
        this._updateSvgSize();
        this._updateSpaceForYAxisLabels();
        this._updateXScale();
        this._updateYScale();
    }

    private _updateSpaceForYAxisLabels(): void {
        this._spaceForYAxisLabels = this._getSpaceForYAxisLabels(this._yScale);
    }

    private _updateLegendWidth(): void {
        this._legendWidth = 0;

        if (this._legendPosition === "right") {
            this._legendWidth = this._getLegendSize(false, true);
        }
    }

    private _updateSvgSize(): void {
        const legendBelow = this._legendPosition === "bottom";
        const legendOnTheRight = this._legendPosition === "right";
        const legendSize = this._getLegendSize(legendBelow, legendOnTheRight);

        this._svg
            .attr("height", Math.max(this.height - (legendBelow ? legendSize : 0), 0))
            .attr("width", Math.max(this.width - (legendOnTheRight ? legendSize : 0), 0));
    }

    private _updateXScale(): void {
        const legendBelow = this._legendPosition === "bottom";
        const legendOnTheRight = this._legendPosition === "right";
        const legendSize = this._getLegendSize(legendBelow, legendOnTheRight);
        const longestDataIndex = this._getLongestDataIndex();

        this._xScale
            .domain(
                this._data[longestDataIndex]
                    .map(x => x.indexWithinGroup)
                    .filter((index, i, array) => array.indexOf(index) === i) // filter out duplicates [NUF-256]
            )
            .range([
                this._spaceForYAxisLabels + SPACE_BETWEEN_Y_AXIS_TICKS_AND_AXIS + this._margin.left,
                this.width - this._margin.right - (legendOnTheRight ? legendSize : 0)
            ]); // will need to add condition for axis title as well
    }

    private _updateYScale(): void {
        this._yScale
            .domain([this.startWithZero ? 0 : this._yMin, this._yMax])
            .range([this._verticalPositionOfXAxis, this._margin.top]);

        if (this.makeYAxisNice)
            this._yScale = this._yScale.nice(
                this.forceNumberOfTicks ? this.numberOfTicks : undefined
            );
    }

    private get _verticalPositionOfXAxis(): number {
        return (
            this.height -
            this._getXAxisGroupHeight() -
            (this.alignTitleWithAxis ? 0 : SPACE_FOR_X_AXIS_TITLE) -
            (this._legendPosition === "bottom" ? SPACE_FOR_LEGEND_BELOW : 0) -
            (this.useDoubleAxis ? SPACE_FOR_X_AXIS_LABELS_TOP_PART : 0)
        );
    }

    private _draw(): void {
        this._pointRefs = [];
        this._shadowPointRefs = [];
        this._shadowSpreadRefs = [];
        this._drawAxes();
        if (this.showHorizontalGuideLines) this._drawHorizontalGuideLines();
        if (this.showVerticalGuideLines) this._drawVerticalGuideLines();
        this._drawDataLines();
        this._drawDataPointsShadows();
        this._drawDataPoints();
        this._drawColumnIcons();
    }

    private _drawDataPointsShadows(): void {
        const self = this;

        const dataHolders = this._dataPointsShadows
            .selectAll<
                SVGGElement,
                LineChartItem[]
            >(".lg-line-chart__data-points-shadows-groups__group")
            .data(this._data);

        dataHolders
            .exit()
            .transition()
            .duration(500)
            .ease(d3.easeCubicOut)
            .style("opacity", 0)
            .remove();

        dataHolders
            .enter()
            .append("g")
            .classed("lg-line-chart__data-points-shadows-groups__group", true)
            .merge(dataHolders);

        this._dataPointsShadows
            .selectAll<
                d3.BaseType,
                LineChartItem[]
            >(".lg-line-chart__data-points-shadows-groups__group")
            .each(function (d: LineChartItem[]) {
                const group = d3.select(this);
                const circleItems = d.filter(i => i.value != null);

                if (self.spreadValues) {
                    group.selectAll("rect").remove();

                    const spreadShadow = group
                        .selectAll<SVGRectElement, LineChartItem>("rect")
                        .data(circleItems);

                    const spreadCoords = (
                        selection: d3.Selection<
                            SVGRectElement,
                            LineChartItem,
                            d3.BaseType,
                            LineChartItem[]
                        >
                    ): d3.Selection<SVGRectElement, LineChartItem, d3.BaseType, LineChartItem[]> =>
                        selection
                            .attr(
                                "x",
                                d =>
                                    (self._xScale(d.indexWithinGroup ?? 0) ?? 0) -
                                    SHADOW_SPREAD_WIDTH / 2
                            )
                            .attr(
                                "y",
                                d =>
                                    self._yScale(d.spread ? d.spread[1] : 0) -
                                    SHADOW_SPREAD_HEIGHT_EXTEND / 2
                            );

                    const getHeightAndWidth = (
                        selection: d3.Selection<
                            SVGRectElement,
                            LineChartItem,
                            d3.BaseType,
                            LineChartItem[]
                        >
                    ): d3.Selection<SVGRectElement, LineChartItem, d3.BaseType, LineChartItem[]> =>
                        selection
                            .attr("height", d => {
                                const [spread0 = 0, spread1 = 0] = d.spread ?? [];
                                const upper = self._yScale(spread0);
                                const lower = self._yScale(spread1);
                                return upper - lower + SHADOW_SPREAD_HEIGHT_EXTEND;
                            })
                            .attr("width", () => {
                                return SHADOW_SPREAD_WIDTH;
                            });

                    spreadShadow
                        .enter()
                        .filter(d => d.spread != null)
                        .append<SVGRectElement>("rect")
                        .attr("fill", "transparent")
                        .attr("rx", 6)
                        .call(spreadCoords)
                        .call(getHeightAndWidth)
                        .each(function (d2) {
                            self._shadowSpreadRefs.push({
                                coordinates: [
                                    Math.round(
                                        (self._xScale(d2.indexWithinGroup) ?? 0) +
                                            self._xScale.bandwidth() / 2
                                    ),
                                    Math.round(self._yScale(d2.value))
                                ],
                                color: self._groupColors(d2.group),
                                fillElementWith: fill(d3.select(this)),
                                data: d2
                            });
                        });
                }

                group.selectAll("circle").remove();

                const circlesShadow = group
                    .selectAll<SVGCircleElement, LineChartItem>("circle")
                    .data(circleItems);

                circlesShadow
                    .enter()
                    .append("circle")
                    .attr("fill", "transparent")
                    .attr("r", SHADOW_RADIUS)
                    .attr("stroke-width", SHADOW_STROKE)
                    .merge(circlesShadow)
                    .attr("stroke", dStroke => self._groupColors(dStroke.group))
                    .on("mousemove", function (event: MouseEvent) {
                        self._onMouseOverPointShadow(d3.select(this), d3.pointer(event));
                    })
                    .on("mouseover", (_event: MouseEvent, data) => {
                        self._moveGroupToTop(data.group);
                    })
                    .on("mouseout", (_event: MouseEvent) => {
                        self._groupIsOnTop = false;
                        self._tooltip.hide();
                        self._clearOverlappingElements();
                    })
                    .transition()
                    .duration(0)
                    .ease(d3.easeCubicOut)
                    .attr(
                        "cx",
                        d2 =>
                            (self._xScale(d2.indexWithinGroup) ?? 0) +
                                self._xScale.bandwidth() / 2 || 0
                    )
                    .attr("cy", d2 => self._yScale(d2.value))
                    .each(function (d2) {
                        self._shadowPointRefs.push({
                            coordinates: [
                                Math.round(
                                    (self._xScale(d2.indexWithinGroup) ?? 0) +
                                        self._xScale.bandwidth() / 2
                                ),
                                Math.round(self._yScale(d2.value))
                            ],
                            color: self._groupColors(d2.group),
                            fillElementWith: fill(d3.select(this)),
                            data: d2
                        });
                    });

                circlesShadow
                    .exit()
                    .transition()
                    .attr("cx", self.width + 10 || 0)
                    .duration(0)
                    .ease(d3.easeCubicOut)
                    .style("opacity", 0)
                    .remove();
            });
    }

    private _onMouseOverPointShadow(
        target: d3.Selection<any, LineChartItem, any, any>,
        mousecoords: [number, number]
    ): void {
        this._clearOverlappingElements();

        this._overlappingPointRefs = this._pointRefs.filter(x =>
            doCirclePointsOverlap(mousecoords, x.coordinates)
        );
        this._overlappingShadowPointRefs = this._shadowPointRefs.filter(x =>
            doCirclePointsOverlap(mousecoords, x.coordinates)
        );
        this._overlappingShadowSpreadRefs = this._shadowSpreadRefs.filter(x =>
            doCirclePointsOverlap(mousecoords, x.coordinates)
        );

        this._overlappingPointRefs.forEach(x => x.fillElementWith(x.color ?? ""));
        this._overlappingShadowPointRefs.forEach(x => x.fillElementWith(SHADOW_COLOR));
        this._overlappingShadowSpreadRefs.forEach(x => x.fillElementWith(SHADOW_COLOR));

        if (this._overlappingShadowPointRefs.length === 0) {
            this._tooltip.hide();
            this._columnIconTooltip.hide();
            return;
        }

        const alreadyAddedGroups: string[] = [];
        const tooltipItems = this._overlappingShadowPointRefs.map(point => {
            const alreadyAdded = alreadyAddedGroups.indexOf(point.data.group) !== -1;
            if (!alreadyAdded) alreadyAddedGroups.push(point.data.group);

            return {
                color: point.color ?? "",
                columnName: point.data.column,
                groupName: point.data.group,
                value: point.data.value,
                showValueOnly: alreadyAdded,
                lineChartItem: point.data
            };
        });

        const data = target.data();

        this.tooltipContext = {
            displayingItems: tooltipItems,
            activeItem: {
                column: data?.[0]?.column ?? "",
                group: data?.[0]?.group ?? ""
            }
        };

        this._tooltip.show({ target: target.node() });
    }

    private _onMouseOverColumnIcon(target: d3.Selection<any, LineChartItem, any, any>): void {
        const data = target.data();
        const alreadyAddedGroups: string[] = [];
        const tooltipItems = data.map(d => {
            const alreadyAdded = alreadyAddedGroups.indexOf(d.group) !== -1;
            if (!alreadyAdded) alreadyAddedGroups.push(d.group);
            return {
                color: "",
                columnName: d.column,
                groupName: d.group,
                value: 0,
                showValueOnly: false,
                lineChartItem: d,
                isColumnIconTooltip: this.columnIconOptions?.tooltip,
                text: this.columnIconOptions?.tooltipText
            };
        });

        this.tooltipContext = {
            displayingItems: tooltipItems,
            activeItem: {
                column: data?.[0]?.column ?? "",
                group: data?.[0]?.group ?? ""
            }
        };

        this._columnIconTooltip.show({ target: target.node() });
    }

    private _trackMousePosition(): void {
        this._ngZone.runOutsideAngular(() => {
            this._trackListener = this._renderer.listen(
                this._elementRef.nativeElement,
                "mousemove",
                (event: MouseEvent) => {
                    this._lastMouseX = event.clientX;
                    this._lastMouseY = event.clientY;
                    this._updateTooltipPosition();
                }
            );
        });
    }

    private _updateTooltipPosition(): void {
        if (this._tooltip && this._tooltip.visible) {
            if (this._lastMouseX && this._lastMouseY)
                this._tooltip.setPositionAt(
                    this._lastMouseX,
                    this._lastMouseY,
                    getRecommendedPosition(
                        { x: this._lastMouseX, y: this._lastMouseY },
                        this._tooltip.getOverlayElement()
                    )
                );
            else this._tooltip.hide();
        }

        if (this._columnIconTooltip && this._columnIconTooltip.visible) {
            if (this._lastMouseX && this._lastMouseY)
                this._columnIconTooltip.setPositionAt(
                    this._lastMouseX,
                    this._lastMouseY,
                    getRecommendedPosition(
                        { x: this._lastMouseX, y: this._lastMouseY },
                        this._columnIconTooltip.getOverlayElement()
                    )
                );
            else this._columnIconTooltip.hide();
        }
    }

    private _clearOverlappingElements(): void {
        this._overlappingPointRefs.forEach(element => {
            element.fillElementWith(this.showDataPointCircles ? "white" : "transparent");
        });
        this._overlappingShadowPointRefs.forEach(element => {
            element.fillElementWith("transparent");
        });
        this._overlappingShadowSpreadRefs.forEach(element => {
            element.fillElementWith("transparent");
        });
    }

    private _drawHorizontalGuideLines(): void {
        const yAxis = d3.axisRight(this._yScale);
        this._setTicksOnYAxis(yAxis);

        this._horizontalLinesGroup
            .attr(
                "transform",
                `translate( ${
                    this._spaceForYAxisLabels +
                    SPACE_BETWEEN_Y_AXIS_TICKS_AND_AXIS +
                    this._margin.left
                }, 0 )`
            )
            .call(
                yAxis
                    .tickFormat(_ => "")
                    .tickSize(
                        this.width -
                            this._margin.left -
                            this._margin.right -
                            this._spaceForYAxisLabels -
                            SPACE_BETWEEN_Y_AXIS_TICKS_AND_AXIS
                    )
            );
    }

    private _drawVerticalGuideLines(): void {
        const xAxis = d3.axisBottom(this._xScale);
        this._setTicksOnXAxis(xAxis);
        const legendSize = this._getLegendSize(this._legendPosition === "bottom", false);
        this._verticalLinesGroup.call(
            xAxis
                .tickFormat(_ => "")
                .tickSize(
                    this.height - legendSize - SPACE_FOR_X_AXIS_LABELS - SPACE_FOR_X_AXIS_TITLE
                )
        );
    }

    private _drawAxes(): void {
        this._drawXAxis();
        this._drawYAxis();
    }

    private _drawXAxis(): void {
        if (this.hideXAxis) {
            this._xAxisGroup.style("display", "none");
            this._xAxisTopGroup.style("display", "none");
            return;
        }

        const longestDataIndex = this._getLongestDataIndex();

        this._xAxisLabels = [];

        this._xAxisGroup.style("display", "").call(
            d3
                .axisBottom(this._xScale)
                .tickFormat((indexWithinGroup: number, index: number) => {
                    const hasFrequency = this.xAxisLabelFrequency != null;
                    if (hasFrequency && index % this.xAxisLabelFrequency! !== 0) {
                        return "";
                    }

                    this._xAxisLabels.push(indexWithinGroup);

                    return this._getXAxisLabel(indexWithinGroup, longestDataIndex);
                })
                .tickSize(0)
        );
        this._xAxisGroup
            .selectAll(".tick text")
            .attr(
                "transform",
                `translate( 0, ${this.columnIcons && !this.rotateXAxisLabels ? ICON_SIZE : 12} )`
            ); // @font-specific

        if (this.hideXAxisLine) {
            this._xAxisGroup.selectAll(".domain").style("display", "none");
        } else {
            this._xAxisGroup.selectAll(".domain").style("display", "");
        }

        if (this.rotateXAxisLabels) {
            this._xAxisGroup
                .selectAll(".tick text")
                .style("transform-origin", "top left")
                .style("transform", "translate(-35px, 50px) rotate(315deg)");
        } else {
            this._xAxisGroup.selectAll(".tick text").style("transform", "");
        }

        const offset =
            this.height -
            this._getXAxisGroupHeight() -
            (this.alignTitleWithAxis ? 0 : SPACE_FOR_X_AXIS_TITLE) -
            (this._legendPosition === "bottom" ? SPACE_FOR_LEGEND_BELOW : 0);

        this._xAxisGroup.attr("transform", `translate( 0, ${offset} )`);

        if (this.useDoubleAxis) {
            this._xAxisGroup.attr(
                "class",
                `${
                    this._useNewLabels
                        ? "lg-line-chart__x-axis lg-line-chart__x-axis--double-bottom"
                        : "lg-line-chart__x-axis__legacy lg-line-chart__x-axis__legacy--double-bottom"
                }
                `
            );
            this._xAxisTopGroup
                .attr(
                    "class",
                    `${
                        this._useNewLabels
                            ? "lg-line-chart__x-axis lg-line-chart__x-axis--double-top"
                            : "lg-line-chart__x-axis__legacy lg-line-chart__x-axis__legacy--double-top"
                    }
                `
                )
                .style("display", "")
                .call(
                    d3
                        .axisBottom(this._xScale)
                        .tickFormat(
                            indexWithinGroup =>
                                this._data[longestDataIndex].find(
                                    item => item.indexWithinGroup === indexWithinGroup
                                )?.columnTop ?? ""
                        )
                        .tickSize(0)
                );

            if (this.hideXAxisLine) {
                this._xAxisTopGroup.selectAll(".domain").style("display", "none");
            } else {
                this._xAxisTopGroup.selectAll(".domain").style("display", "");
                this._xAxisTopGroup.attr(
                    "transform",
                    `translate( 0, ${offset - SPACE_FOR_X_AXIS_LABELS_TOP_PART} )`
                );
            }

            this._xAxisTopGroup
                .selectAll(".tick text")
                .style("display", "")
                .attr("transform", `translate( 0, 12 )`); // @font-specific
        } else {
            this._xAxisGroup.attr(
                "class",
                `${this._useNewLabels ? "lg-line-chart__x-axis" : "lg-line-chart__x-axis__legacy"}`
            );
            this._xAxisTopGroup.attr(
                "class",
                `${this._useNewLabels ? "lg-line-chart__x-axis" : "lg-line-chart__x-axis__legacy"}`
            );
            this._xAxisTopGroup.selectAll(".domain").style("display", "none");
            this._xAxisTopGroup.selectAll(".tick text").style("display", "none");
        }

        this._updateYScale();
    }

    private _getXAxisLabel(indexWithinGroup: number, longestDataIndex: number): string {
        return this._formatXAxisLabel(
            this._data[longestDataIndex].find(item => item.indexWithinGroup === indexWithinGroup)
                ?.column ?? ""
        );
    }

    private _formatXAxisLabel(label: string): string {
        if (this.rotateXAxisLabels) {
            const maxLength = rotatedXAxisOptions(this.rotatedXAxisType).maxLength;
            if (label.length > maxLength) {
                label = `${label.substring(0, maxLength)}...`;
            }
        }
        return label;
    }

    private _drawYAxis(): void {
        const yAxis = d3.axisLeft(this._yScale);
        this._setTicksOnYAxis(yAxis);

        this._yAxisGroup
            .attr(
                "transform",
                `translate( ${
                    this._spaceForYAxisLabels +
                    this._margin.left +
                    SPACE_BETWEEN_Y_AXIS_TICKS_AND_AXIS
                }, 0 )`
            )
            .style("display", this.hideYAxis ? "none" : "")
            .call(yAxis.tickSize(0).tickFormat(d => this._yTicksFormatter.format(d)));

        this._yAxisGroup
            .selectAll(".tick text")
            .attr("text-anchor", "end")
            .attr("transform", `translate(-${SPACE_BETWEEN_Y_AXIS_TICKS_AND_AXIS}, 0)`);

        const xAxisLabelOffset =
            this.xAxisLabelOffset?.trim() ?? (this.yAxisLabel ? "11em" : "9.5em");

        this._xAxisLabel
            .text(this.xAxisLabel)
            .attr(
                "y",
                this.height -
                    this._getXAxisGroupHeight() -
                    (this._legendPosition === "bottom" ? SPACE_FOR_LEGEND_BELOW : 0) +
                    (this.rotateXAxisLabels ? this.rotatedXAxisLabelsHeight : 0) +
                    (this.columnIcons ? ICON_SIZE / 2 : 0)
            )
            .attr("dx", () => {
                if (this.alignTitleWithAxis) return "0.8em";
                return xAxisLabelOffset;
            })
            .attr("dy", "1.75em");

        if (xAxisLabelOffset?.endsWith("%")) {
            this._xAxisLabel.attr("transform", function () {
                const textWidth = d3.select(this).node().getBBox().width;
                const rate = parseFloat(xAxisLabelOffset) / 100;
                return `translate(${-textWidth * rate}, 0)`;
            });
        } else {
            this._xAxisLabel.attr("transform", null);
        }

        this._yAxisLabel
            .text(this.yAxisLabel)
            .attr(
                "x",
                -this.height +
                    this._getXAxisGroupHeight() +
                    SPACE_FOR_X_AXIS_TITLE +
                    (this._legendPosition === "bottom" ? SPACE_FOR_LEGEND_BELOW : 0) +
                    (this.useDoubleAxis ? SPACE_FOR_X_AXIS_LABELS_TOP_PART : 0)
            )
            .attr("dx", "-0.5em")
            .attr("dy", "1.5em");
    }

    private _drawDataLines(): void {
        const drawLine: any = d3
            .line<LineChartItem>()
            .x(d => (this._xScale(d.indexWithinGroup) ?? 0) + this._xScale.bandwidth() / 2)
            .y(d => this._yScale(d.value))
            .defined(d => d == null || d.value != null)
            .curve(d3.curveLinear);

        const dataHolders = this._dataGroups
            .selectAll<SVGGElement, LineChartItem[]>(".lg-line-chart__data-groups__group")
            .data(this._data);

        dataHolders
            .exit()
            .transition()
            .duration(500)
            .ease(d3.easeCubicOut)
            .style("opacity", 0)
            .remove();

        const dataHoldersMerged = dataHolders
            .enter()
            .append("g")
            .classed("lg-line-chart__data-groups__group", true)
            .merge(dataHolders);

        const self = this;

        dataHoldersMerged.each(function () {
            const group = d3.select(this);

            const existing = group.select<SVGLineElement>(
                "path.lg-line-chart__data-groups__group__line"
            );

            const line = !existing.empty()
                ? existing
                : group
                      .append<SVGLineElement>("path")
                      .classed("lg-line-chart__data-groups__group__line", true)
                      .attr("stroke-width", STROKE_WIDTH)
                      .attr("fill", "none");
            line.attr("stroke", (d2: any) => {
                return self._groupColors(d2[0].group);
            }).attr("d", drawLine);
        });
    }

    private _drawDataPoints(): void {
        const self = this;
        this._dataGroups
            .selectAll<d3.BaseType, LineChartItem[]>(".lg-line-chart__data-groups__group")
            .each(function (d: LineChartItem[]) {
                const group = d3.select(this);
                const circleItems = d.filter(i => i.value != null);

                if (self.spreadValues) {
                    group.selectAll("line").remove();

                    if (self.spreadVisualisationType === LgLineChartSpreadVisual.ErrorBars) {
                        self._drawErrorBars(group, circleItems);
                    } else if (self.spreadVisualisationType === LgLineChartSpreadVisual.Band) {
                        self._drawErrorBand(group, d);
                    }
                }

                const circles = group
                    .selectAll<SVGCircleElement, LineChartItem>("circle")
                    .data(circleItems);

                circles
                    .enter()
                    .append("circle")
                    .attr("fill", self.showDataPointCircles ? "white" : "transparent")
                    .attr("r", POINT_RADIUS)
                    .attr("stroke-width", self.showDataPointCircles ? POINT_STROKE : 0)
                    .merge(circles)
                    .attr("stroke", d2 => self._groupColors(d2.group))
                    .transition()
                    .duration(0)
                    .ease(d3.easeCubicOut)
                    .attr(
                        "cx",
                        d2 =>
                            (self._xScale(d2.indexWithinGroup) ?? 0) +
                                self._xScale.bandwidth() / 2 || 0
                    )
                    .attr("cy", d2 => self._yScale(d2.value))
                    .each(function (d2) {
                        self._pointRefs.push({
                            coordinates: [
                                Math.round(
                                    (self._xScale(d2.indexWithinGroup) ?? 0) +
                                        self._xScale.bandwidth() / 2
                                ),
                                Math.round(self._yScale(d2.value))
                            ],
                            color: self._groupColors(d2.group),
                            fillElementWith: fill(d3.select(this)),
                            data: d2
                        });
                    });

                circles
                    .exit()
                    .transition()
                    .duration(0)
                    .ease(d3.easeCubicOut)
                    .attr("cx", self.width + 10 || 0)
                    .style("opacity", 0)
                    .remove();

                const labelItems = circleItems.filter(i => self.showxAxisLabels.includes(i.group));
                const labels = group
                    .selectAll<SVGTextElement, LineChartItem>("text")
                    .data(labelItems);

                if (labelItems.length > 0) {
                    const allLabelItems = self._data.filter(
                        d =>
                            d.filter(i => i.value != null && self.showxAxisLabels.includes(i.group))
                                .length > 0
                    );

                    labels
                        .enter()
                        .append("text")
                        .attr("text-anchor", "middle")
                        .attr("font-weight", "bold")
                        .attr("fill", "currentcolor")
                        .merge(labels)
                        .transition()
                        .duration(0)
                        .ease(d3.easeCubicOut)
                        .text(d => self._xAxisLabelFormatter.format(d.value))
                        .attr("transform", d => {
                            const x =
                                (self._xScale(d.indexWithinGroup) ?? 0) +
                                    self._xScale.bandwidth() / 2 || 0;
                            let y = self._yScale(d.value);
                            let hasOverlappingPoints = false;
                            allLabelItems.forEach(labelItemGroup => {
                                // skip first item
                                if (
                                    !(d.indexOfGroup === 0 && d.indexWithinGroup === 0) &&
                                    labelItemGroup.length >= d.indexWithinGroup
                                ) {
                                    for (let i = 0; i <= d.indexWithinGroup; i++) {
                                        const item = labelItemGroup[i];
                                        if (item) {
                                            if (
                                                d.indexOfGroup >= item.indexOfGroup &&
                                                !(
                                                    item.indexOfGroup === d.indexOfGroup &&
                                                    item.indexWithinGroup === d.indexWithinGroup
                                                )
                                            ) {
                                                const xPrevious =
                                                    (self._xScale(item.indexWithinGroup) ?? 0) +
                                                        self._xScale.bandwidth() / 2 || 0;
                                                const yPrevious = self._yScale(item.value);
                                                const doesPointOverlap = doCirclePointsOverlap(
                                                    [xPrevious, yPrevious],
                                                    [x, y]
                                                );
                                                if (doesPointOverlap) {
                                                    hasOverlappingPoints = doesPointOverlap;
                                                }
                                            }
                                        }
                                    }
                                }
                            });
                            // move label above circle
                            y = y - 11;
                            // if outside of svg, move label below circle
                            if (y < 20) {
                                y = y + 30;
                            }
                            return hasOverlappingPoints
                                ? "translate(" + 0 + "," + 0 + ")"
                                : "translate(" + x + "," + y + ")";
                        });
                }

                labels
                    .exit()
                    .transition()
                    .duration(0)
                    .ease(d3.easeCubicOut)
                    .attr("transform", "translate(0,0)")
                    .style("opacity", 0)
                    .remove();
            });
    }

    private _drawErrorBars(
        group: d3.Selection<d3.BaseType, unknown, null, undefined>,
        circleItems: LineChartItem[]
    ): void {
        const spread = group.selectAll<SVGLineElement, LineChartItem>("line").data(circleItems);
        const spreadXCoords = (
            selection: d3.Selection<SVGLineElement, LineChartItem, any, LineChartItem[]>,
            isMiddle: boolean
        ): d3.Selection<SVGLineElement, LineChartItem, SVGGElement, LineChartItem[]> =>
            selection
                .attr(
                    "x1",
                    d =>
                        (this._xScale(d.indexWithinGroup) ?? 0) -
                        (isMiddle ? 0 : SPREAD_BOUNDARY_RADIUS)
                )
                .attr(
                    "x2",
                    d =>
                        (this._xScale(d.indexWithinGroup) ?? 0) +
                        (isMiddle ? 0 : SPREAD_BOUNDARY_RADIUS)
                );

        const spreadYCoords = (
            selection: d3.Selection<SVGLineElement, LineChartItem, any, LineChartItem[]>,
            upperIndex: number,
            lowerIndex: number
        ): d3.Selection<SVGLineElement, LineChartItem, SVGGElement, LineChartItem[]> => {
            return selection
                .attr("y1", d => {
                    return this._yScale(d.spread ? (upperIndex ? d.spread[0] : d.spread[1]) : 0);
                })
                .attr("y2", d => {
                    return this._yScale(d.spread ? (lowerIndex ? d.spread[1] : d.spread[0]) : 0);
                });
        };

        // vertical bar
        spread
            .enter()
            .append<SVGLineElement>("line")
            .filter(d => d.spread != null)
            .style("stroke", d2 => this._groupColors(d2.group))
            .style("stroke-width", SPREAD_STROKE)
            .call(spreadYCoords, 1, 1)
            .call(spreadXCoords, true);

        // horizontal lower bar
        spread
            .enter()
            .append<SVGLineElement>("line")
            .filter(d => d.spread != null)
            .style("stroke", d2 => this._groupColors(d2.group))
            .style("stroke-width", SPREAD_STROKE)
            .style("opacity", d2 => (d2.value === d2.spread![1] ? 0 : 1))
            .call(spreadYCoords, 0, 1)
            .call(spreadXCoords, false);

        // horizontal upper bar
        spread
            .enter()
            .append<SVGLineElement>("line")
            .filter(d => d.spread != null)
            .style("stroke", d2 => this._groupColors(d2.group))
            .style("stroke-width", SPREAD_STROKE)
            .style("opacity", d2 => (d2.value === d2.spread![0] ? 0 : 1))
            .call(spreadYCoords, 1, 0)
            .call(spreadXCoords, false);
    }

    private _drawErrorBand(
        group: d3.Selection<d3.BaseType, unknown, null, undefined>,
        datum: LineChartItem[]
    ): void {
        const areaGenerator = d3
            .area<LineChartItem>()
            .defined(d => d.spread != null)
            .x(d => this._xScale(d.indexWithinGroup) ?? 0)
            .y0(d => this._yScale(d.spread![0]))
            .y1(d => this._yScale(d.spread![1]));

        const existingGroup = group.select<SVGPathElement>("path.lg-line-chart__confidence-area");
        let areaPath: d3.Selection<SVGPathElement, unknown, null, undefined>;
        if (existingGroup.empty()) {
            areaPath = group
                .append<SVGPathElement>("path")
                .attr("fill", this._groupColors(datum[0].group))
                .style("opacity", 0.15)
                .classed("lg-line-chart__confidence-area", true);
        } else {
            areaPath = existingGroup;
        }

        areaPath.attr("d", () => areaGenerator(datum)).lower();
    }

    private _drawColumnIcons(): void {
        this._columnIconsGroup.selectAll<SVGGElement, LineChartItem>(".lg-icon").remove();

        if (this.columnIcons) {
            const self = this;
            const icon = this.columnIconOptions?.icon;
            const longestDataIndex = this._getLongestDataIndex();
            const columnItems = this._data[longestDataIndex].filter(d => this.columnIcons!(d.item));

            const columnIconsSelection = this._columnIconsGroup
                .selectAll<SVGGElement, LineChartItem>(".lg-icon")
                .data(columnItems);

            const columnIcons = columnIconsSelection
                .enter()
                .append("g")
                .attr(
                    "transform",
                    d =>
                        "translate(" +
                        ((this._xScale(d.indexWithinGroup) ?? 0) +
                            this._xScale.bandwidth() / 2 -
                            ICON_SIZE / 2 || 0) +
                        "," +
                        this._verticalPositionOfXAxis +
                        ")"
                )
                .attr(
                    "class",
                    `${icon} lg-icon lg-icon--${this.columnIconOptions?.iconType} lg-tooltip-visible`
                )
                .merge(columnIconsSelection)
                .append("use")
                .attr("xlink:href", `#${icon}`)
                .attr("height", ICON_SIZE)
                .attr("width", ICON_SIZE);

            columnIcons
                .on("mousemove", function (_event: MouseEvent) {
                    if (self.columnIconOptions?.tooltip && self.columnIcons)
                        self._onMouseOverColumnIcon(d3.select(this));
                })
                .on("mouseout", (_event: MouseEvent) => {
                    if (self.columnIconOptions?.tooltip && self.columnIcons)
                        self._columnIconTooltip.hide();
                })
                .transition()
                .duration(0)
                .ease(d3.easeCubicOut);
        }
    }

    private _setTicksOnYAxis(yAxis: d3.Axis<d3.NumberValue>): void {
        if (this.forceNumberOfTicks && this._yMax > this._yMin) {
            if (this.startWithZero) {
                const tickStep = this._yMax / (this.numberOfTicks - 1);
                yAxis.tickValues(d3.range(0, this._yMax + tickStep, tickStep));
            } else {
                const tickStep = (this._yMax - this._yMin) / (this.numberOfTicks - 1);
                yAxis.tickValues(d3.range(this._yMin, this._yMax + tickStep, tickStep));
            }
        } else {
            yAxis.ticks(this.numberOfTicks);
        }
    }

    private _setTicksOnXAxis(xAxis: d3.Axis<d3.NumberValue>): void {
        const xMin = this._xAxisLabels[0];
        const xMax = this._xAxisLabels[this._xAxisLabels.length - 1];
        const tickStep = (xMax - xMin) / (this._xAxisLabels.length - 1);
        xAxis.tickValues(d3.range(xMin, this._xMax, tickStep));
    }

    private _initializeOrUpdateColorScales(data: LineChartItem[][]): void {
        if (this._colorPalette.useNewColorPalette) {
            const colors = this._colorPalette.getColorsForType(this.colorConfiguration);
            this._groupColors = d3.scaleOrdinal(colors);
            return;
        }
        this._initializeOrUpdateLegacyColorScales(data);
    }

    /**
     * @deprecated
     */
    private _initializeOrUpdateLegacyColorScales(data: LineChartItem[][]): void {
        this._groupColors = d3.scaleOrdinal();

        if (this.groupColors && this.groupColors.length) {
            this._groupColors.range(this._legacyColorPalette.getPaletteForColors(this.groupColors));
            return;
        }

        if (!data || !data.length) {
            this._groupColors.range([]);
            return;
        }

        const colors =
            this.comparingAgainst === "none"
                ? this.customPalette
                    ? this.customPalette
                    : this._legacyColorPalette.getPalette(data.length)
                : [
                      this._legacyColorPalette.getColorForCompareColumn(this.comparingAgainst),
                      ...this._legacyColorPalette.getPalette(1)
                  ];

        this._groupColors = d3.scaleOrdinal(colors);
    }

    private _getXAxisGroupHeight(): number {
        return Math.max(
            this._xAxisGroup.node().getBoundingClientRect().height,
            SPACE_FOR_X_AXIS_LABELS
        );
    }

    private _getTooltipContent(): TemplatePortal<IImplicitContext<LgLineChartTooltipContext>> {
        return new TemplatePortal<IImplicitContext<LgLineChartTooltipContext>>(
            this.tooltipTemplate!,
            this._viewContainerRef,
            { $implicit: this.tooltipContext! }
        );
    }

    private _getColumnIconTooltipContent(): TemplatePortal<
        IImplicitContext<LgLineChartTooltipContext>
    > {
        return new TemplatePortal<IImplicitContext<LgLineChartTooltipContext>>(
            this.columnIconTooltipTemplate!,
            this._viewContainerRef,
            { $implicit: this.tooltipContext! }
        );
    }

    protected _getFormatter(
        type: string,
        formatterOptions: ILgFormatterOptions
    ): ILgFormatter<any> {
        return this._formatterFactory.getFormatter(type, formatterOptions);
    }

    private _getSpaceForYAxisLabels(scale: d3.ScaleLinear<number, number>): number {
        if (this.hideYAxis) return 0;

        let maxWidth = 0;

        // if text nodes were rendered inside the chart svg,
        // then sometimes `getComputedTextLength` returned 0
        // so appending to `body` instead
        // no line-chart specific font-size styling so not adding css class
        const fakeSvg = d3.select("body").append("svg");

        fakeSvg
            .append("g")
            .selectAll("text")
            .data(scale.ticks().map(x => this._yTicksFormatter.format(x)))
            .enter()
            .append("text")
            .text(d => d)
            .each(function () {
                maxWidth = Math.max(
                    maxWidth,
                    (this as SVGTextContentElement).getComputedTextLength()
                );
            });

        fakeSvg.remove();
        return Math.min(this.spaceForYAxisLabels || maxWidth, this.width / Math.PI);
    }

    private _getLongestDataIndex(): number {
        return this._data.reduce(
            (
                previousIndex: number,
                currentArray: LineChartItem[],
                currentIndex: number,
                array: LineChartItem[][]
            ) => (array[previousIndex].length > currentArray.length ? previousIndex : currentIndex),
            0
        );
    }

    private _setLegendDefinition(): void {
        this._legendDefinition = this._getGroupNames().map(name => ({
            name,
            color: this._groupColors(name),
            symbol: this.showDataPointCircles ? "line-with-circle" : "line"
        }));

        this._detector.markForCheck();
    }

    private _getGroupNames(): string[] {
        return Array.isArray(this.groupNames) ? this.groupNames : this.groupNames();
    }

    private _moveGroupToTop(groupName: string): void {
        if (this._groupIsOnTop) {
            return;
        }

        const indexOfHoveredGroup = this._findGroupIndex(groupName);

        const shadowGroups = this._dataPointsShadows.selectAll(
            ".lg-line-chart__data-points-shadows-groups__group"
        );
        this._dataPointsShadows.node().appendChild(shadowGroups.nodes()[indexOfHoveredGroup]);

        const dataGroupsNodes = this._dataGroups
            .selectAll(".lg-line-chart__data-groups__group")
            .nodes();
        this._dataGroups.node().appendChild(dataGroupsNodes[indexOfHoveredGroup]);

        this._groupIsOnTop = true;
    }

    private _findGroupIndex(groupName: string): number {
        const shadowGroups: d3.Selection<d3.BaseType, any, any, any> =
            this._dataPointsShadows.selectAll(".lg-line-chart__data-points-shadows-groups__group");
        return shadowGroups
            .data()
            .map((group: LineChartItem[]) => group[0])
            .map((groupItem: LineChartItem) => groupItem.group)
            .indexOf(groupName);
    }

    private _highlightGroup(index: number): void {
        const dataGroupsNodes = this._dataGroups
            .selectAll(".lg-line-chart__data-groups__group")
            .nodes();
        this._highlightedGroup = d3.select(dataGroupsNodes[index]);
        this._highlightedGroup.selectAll("circle").each(function () {
            const currentCircle = d3.select(this);
            currentCircle.attr("fill", currentCircle.attr("stroke"));
        });
    }

    private _cancelGroupHighligt(): void {
        this._highlightedGroup
            ?.selectAll("circle")
            .attr("fill", this.showDataPointCircles ? "white" : "transparent");
        this._highlightedGroup = null;
    }
}
