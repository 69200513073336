import {
    ComponentRef,
    Directive,
    EmbeddedViewRef,
    EventEmitter,
    inject,
    OnDestroy,
    OnInit,
    Output,
    ViewContainerRef
} from "@angular/core";
import {
    BasePortalOutlet,
    CdkPortalOutletAttachedRef,
    ComponentPortal,
    Portal,
    TemplatePortal
} from "@angular/cdk/portal";

// This is pretty much copy of cdkTemplateOutlet https://github.com/angular/material2/blob/master/src/cdk/portal/portal-directives.ts
// with one exception: for component portals, we get the component factory from the portal's injector (if any) rather than from own
// view ref.
// The original implementation used DomPortalOutlet, but it seems the code is just cleaner this way
@Directive({
    standalone: true,
    selector: "[lgPortalOutlet]",
    exportAs: "lgPortalOutlet"
})
export class LgPortalOutletDirective extends BasePortalOutlet implements OnInit, OnDestroy {
    private _viewContainerRef = inject(ViewContainerRef);

    private _initialized = false;
    private _attachedRef: CdkPortalOutletAttachedRef = null;

    // todo: expose to directive?
    attachInside = false;

    set portal(portal: Portal<any> | null) {
        if (this.hasAttached() && !portal && !this._initialized) {
            return;
        }

        if (this.hasAttached()) {
            super.detach();
        }

        if (portal) {
            super.attach(portal);
        }

        this._attachedPortal = portal;
    }

    get portal(): Portal<any> | null {
        return this._attachedPortal;
    }

    get attachedRef(): CdkPortalOutletAttachedRef {
        return this._attachedRef;
    }

    @Output() readonly attached: EventEmitter<CdkPortalOutletAttachedRef> =
        new EventEmitter<CdkPortalOutletAttachedRef>();

    attachComponentPortal<T>(portal: ComponentPortal<T>): ComponentRef<T> {
        const injector = portal.injector || this._viewContainerRef.parentInjector;

        portal.setAttachedHost(this);

        const viewContainerRef =
            portal.viewContainerRef != null ? portal.viewContainerRef : this._viewContainerRef;

        const ref = viewContainerRef.createComponent(portal.component, {
            index: viewContainerRef.length,
            injector
        });

        super.setDisposeFn(() => ref.destroy());
        this._attachedPortal = portal;
        this._attachedRef = ref;

        if (this.attachInside) {
            this._viewContainerRef.element.nativeElement.appendChild(
                this._getComponentRootNode(ref)
            );
        }

        this.attached.emit(ref);

        return ref;
    }

    attachTemplatePortal<C>(portal: TemplatePortal<C>): EmbeddedViewRef<C> {
        portal.setAttachedHost(this);
        const viewRef = this._viewContainerRef.createEmbeddedView(
            portal.templateRef,
            portal.context
        );
        super.setDisposeFn(() => this._viewContainerRef.clear());

        this._attachedPortal = portal;
        this._attachedRef = viewRef;

        if (this.attachInside) {
            viewRef.rootNodes.forEach(rootNode =>
                this._viewContainerRef.element.nativeElement.appendChild(rootNode)
            );
        }

        this.attached.emit(viewRef);

        return viewRef;
    }

    ngOnInit(): void {
        this._initialized = true;
    }

    ngOnDestroy(): void {
        super.dispose();
        this._attachedPortal = null;
        this._attachedRef = null;
    }

    private _getComponentRootNode(componentRef: ComponentRef<any>): HTMLElement {
        return (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    }
}
