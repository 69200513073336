/**
 * Make sure that the type of the array-literal will be a tuple, not an array
 * For example:
 * const a = asTuple([1, true]) will result in type [number, boolean] rather than (number|boolean)[]
 *
 * source: https://stackoverflow.com/a/48687313
 */
export function asTuple<T extends any[]>(...data: T): T {
    return data;
}
