import { lgProvide, LgProvider, LgProviders } from "@logex/framework/types";
import { Provider } from "@angular/core";
import { LG_COL_DEFINITION_TYPE_CONFIGURATION, TableTypeConfiguration } from "../tables";
import {
    LG_ICON_LOADER_CONTENT,
    LG_ICON_LOADER_URL,
    LG_NEW_PASSWORD_CONFIG,
    PasswordConfig
} from "../controls";

export interface UiCoreConfig {
    /**
     * Provides definition of table columns configuration (token `LG_COL_DEFINITION_TYPE_CONFIGURATION`).
     * Optional. No default value.
     */
    columnDefinitionTypeConfiguration?: LgProvider<TableTypeConfiguration>;
    /**
     * Provides content of loader icon (token `LG_ICON_LOADER_CONTENT`).
     * Optional. `iconAtlas` is used by default in `UiCoreModule`.
     */
    iconLoaderContent?: LgProvider<string>;
    /**
     * Provides URL of loader icon (token `LG_ICON_LOADER_URL`).
     * Optional. No default value.
     */
    iconLoaderUrl?: LgProvider<string>;
    /**
     * Provides configuration for new password (token `LG_NEW_PASSWORD_CONFIG`).
     * Optional. No default value.
     */
    newPasswordConfig?: LgProvider<PasswordConfig>;
}

/**
 * Helper utility to configure and provide framework's `UiCoreModule` providers
 */
export const uiCoreProviders: LgProviders<UiCoreConfig> = (config?: UiCoreConfig): Provider[] => {
    const providers: Provider[] = [];
    if (config?.columnDefinitionTypeConfiguration) {
        providers.push(
            lgProvide(
                LG_COL_DEFINITION_TYPE_CONFIGURATION,
                config.columnDefinitionTypeConfiguration
            )
        );
    }
    if (config?.iconLoaderContent) {
        providers.push(
            lgProvide(LG_ICON_LOADER_CONTENT, { ...config.iconLoaderContent, multi: true })
        );
    }
    if (config?.iconLoaderUrl) {
        providers.push(lgProvide(LG_ICON_LOADER_URL, config.iconLoaderUrl));
    }
    if (config?.newPasswordConfig) {
        providers.push(lgProvide(LG_NEW_PASSWORD_CONFIG, config.newPasswordConfig));
    }

    return providers;
};
