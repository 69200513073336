import ldRange from "lodash-es/range";
import { coerceNumberProperty } from "@angular/cdk/coercion";
import { ESCAPE } from "@angular/cdk/keycodes";
import { ComponentPortal } from "@angular/cdk/portal";
import { FormStyle, getLocaleMonthNames, NgForOf, TranslationWidth } from "@angular/common";
import {
    ChangeDetectionStrategy,
    Component,
    ComponentRef,
    EventEmitter,
    forwardRef,
    HostListener,
    inject,
    Input,
    LOCALE_ID,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    TemplateRef,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { Subject } from "rxjs";

import { toBoolean } from "@logex/framework/utilities";

import { LgTooltipService } from "../../lg-tooltip/lg-tooltip.service";
import { TooltipApi } from "../../lg-tooltip/lg-tooltip.types";
import { ValueAccessorBase } from "../inputs/value-accessor-base";
import { LgCapitalizePipe, LgRemoveSymbolPipe } from "../../pipes";
import {
    ALL_MONTHS,
    FIRST_MONTH,
    FIRST_YEAR_DEFAULT,
    LAST_MONTH,
    LAST_YEAR_DEFAULT,
    LgYearMonthRangeSelectorPopupComponent
} from "./lg-year-month-range-selector-popup.component";
import {
    DateDisplayType,
    IRange,
    YearMonthRange,
    YearMonthRangeConfiguration
} from "./lg-year-month-range-selector.types";
import { LgTooltipDirective } from "../../lg-tooltip";

type Coordinates = { x: number; y: number };

type Line = {
    start: Coordinates;
    width: number;
};

type ActiveMonthsHighlightDefinition = Line[];

@Component({
    standalone: true,
    selector: "lg-year-month-range-selector",
    templateUrl: "./lg-year-month-range-selector.component.html",
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => LgYearMonthRangeSelectorComponent),
            multi: true
        }
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    imports: [LgTooltipDirective, NgForOf],
    host: {
        class: "lg-year-month-range-selector",
        "[class.lg-year-month-range-calendar__container--open]": "_opened",
        "[class.lg-year-month-range-calendar__container--close]": "!_opened",
        "[class.lg-year-month-range-selector--active]":
            "!_isEmptySelectionOrWholeYear(this.value?.month)"
    }
})
export class LgYearMonthRangeSelectorComponent
    extends ValueAccessorBase<YearMonthRange>
    implements OnInit, OnChanges, OnDestroy
{
    private _capitalize = inject(LgCapitalizePipe);
    private _locale = inject(LOCALE_ID);
    private _removeSymbolPipe = inject(LgRemoveSymbolPipe);
    private _tooltipService = inject(LgTooltipService);

    @Input() disabled = false;

    /**
     * Specifies if selector should be closed after select.
     */
    @Input() shouldCloseAfterSelect = false;

    /**
     * Start month of the selection.
     */
    @Input() startMonth?: number;

    /**
     * End year of the selection.
     */
    @Input("year") endYear?: number;

    /**
     * Range selector display type.
     */
    @Input() tooltipDisplayType?: DateDisplayType;

    /**
     * End of the first interval.
     */
    @Input() firstIntervalEnd?: number;

    /**
     * Name of the first interval.
     */
    @Input() firstIntervalName?: string;

    /**
     * Name of the second interval.
     */
    @Input() secondIntervalName?: string;

    @Input() hasTooltip = false;

    /**
     * Split intervals by periods.
     */
    @Input() showTooltipIntervalSplitBasedOnPeriods = false;

    /**
     * Range of years to pick.
     */
    @Input() yearPickerRange?: IRange;

    /**
     * Allow to pick only one year.
     */
    @Input() singleYearPicking = false;

    /**
     * Allow to pick only one month.
     */
    @Input() singleMonthPicking = false;

    /**
     * Hide month selection until year is selected.
     */
    @Input() disableMonthsWithoutYear = false;

    // eslint-disable-next-line @angular-eslint/no-output-native
    @Output() readonly select = new EventEmitter<YearMonthRange>();

    @ViewChild("tooltip", { static: true }) _tooltipTemplate!: TemplateRef<any>;

    @HostListener("document:keydown", ["$event.keyCode"])
    public _onEsc(keyCode: number): boolean {
        if (this._opened) {
            if (keyCode === ESCAPE) {
                this._onBackdropClick();
                this._tooltip.hide();
                return false;
            }
        }
        return true;
    }

    get numberOfMonthColumnsInTooltip(): number {
        return this.firstIntervalName && this.secondIntervalName ? 2 : 1;
    }

    get allMonthsSelected(): boolean {
        return this.value?.month?.from === FIRST_MONTH && this.value?.month.to === LAST_MONTH;
    }

    get allYearsSelected(): boolean {
        return (
            this.value?.year?.from === this.yearPickerRange?.from &&
            this.value?.year?.to === this.yearPickerRange?.to
        );
    }

    _definition: ActiveMonthsHighlightDefinition | undefined;
    _opened = false;

    _firstIntervalMonthData = "";
    _secondIntervalMonthData = "";
    _firstIntervalYearData = "";
    _secondIntervalYearData = "";
    _names: string[] = [];
    previewMonthInterval = "";
    previewYearInterval = "";

    private _tooltip!: TooltipApi;
    private _destroyed = new Subject<void>();

    constructor() {
        super();
    }

    ngOnInit(): void {
        this._defaultProps();
        this._initTooltip();

        this._names = [
            "",
            ...getLocaleMonthNames(this._locale, FormStyle.Format, TranslationWidth.Abbreviated)
        ];
        this._names.forEach((month, index, array) => {
            array[index] = this._capitalize.transform(
                this._removeSymbolPipe.transform(month, "DOT")
            );
        });

        this._updateTooltipData(this.value);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!this.value) return;
        if (changes.yearPickerRange) {
            this.yearPickerRange = {
                from: coerceNumberProperty(
                    changes.yearPickerRange.currentValue.from,
                    FIRST_YEAR_DEFAULT
                ),
                to: coerceNumberProperty(changes.yearPickerRange.currentValue.to, LAST_YEAR_DEFAULT)
            };
            this._updateTooltipData(this.value);
        }
        if (changes.startMonth) {
            this.startMonth = coerceNumberProperty(changes.startMonth.currentValue, 1);
            this._updateDefinition(this._getRangeWithOffset(this.value.month));
            this._updateTooltipData(this.value);
        }
    }

    ngOnDestroy(): void {
        this._destroyed.next();
        this._destroyed.complete();
    }

    writeValue(value: YearMonthRange): void {
        if (!value) return;
        this.value = { ...value };
        this._updateTooltipData(value);
        this._updateDefinition(this._getRangeWithOffset(value.month));
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    _toggleRangePopup(): void {
        if (this.disabled) return;

        this._opened = !this._opened;

        if (this._opened) {
            this._onOpenTooltip();
        } else {
            this._tooltip.hide();
        }
    }

    private _defaultProps(): void {
        this.disabled = toBoolean(this.disabled, false);
        this.shouldCloseAfterSelect = toBoolean(this.shouldCloseAfterSelect, false);
        this.startMonth = coerceNumberProperty(this.startMonth, 1);
        this.tooltipDisplayType = this.tooltipDisplayType || "month";
        this.firstIntervalEnd = coerceNumberProperty(this.firstIntervalEnd, 12);
        this.firstIntervalName = this.firstIntervalName || "";
        this.secondIntervalName = this.secondIntervalName || "";
        this.yearPickerRange = {
            from: coerceNumberProperty(this.yearPickerRange?.from, FIRST_YEAR_DEFAULT),
            to: coerceNumberProperty(this.yearPickerRange?.to, LAST_YEAR_DEFAULT)
        };
    }

    private _initTooltip(): void {
        this._tooltip = this._tooltipService.create({
            content: new ComponentPortal(LgYearMonthRangeSelectorPopupComponent),
            target: this._elementRef,
            stay: true,
            offset: 7,
            arrowOffset: 7,
            trapFocus: true,
            backdropClickCallback: _ => this._onBackdropClick(),
            tooltipClass:
                "lg-tooltip lg-tooltip--year-month-range-selector lg-year-month-range-selector-popup"
        });
    }

    private _onOpenTooltip(): void {
        this._tooltip.show();
        this._subscribeToTooltipInstance();
    }

    selectAllMonths(): void {
        this._onRangeSelected({ month: ALL_MONTHS(), year: this.value?.year });
    }

    selectAllYears(): void {
        this._onRangeSelected({
            month: this.disableMonthsWithoutYear ? ALL_MONTHS() : this.value?.month,
            year: this.yearPickerRange!
        });
    }

    private _onBackdropClick(): void {
        this._opened = false;
        this._changeDetectorRef.markForCheck();
    }

    private _subscribeToTooltipInstance(): void {
        const config: YearMonthRangeConfiguration = {
            initialMonthRange: this.value?.month,
            initialYearRange: this.value?.year,
            startMonth: this.startMonth,
            year: this.endYear,
            tooltipDisplayType: this.tooltipDisplayType,
            firstIntervalEnd: this.firstIntervalEnd,
            firstIntervalName: this.firstIntervalName,
            secondIntervalName: this.secondIntervalName,
            yearPickerRange: this.yearPickerRange,
            singleYearPicking: this.singleYearPicking,
            singleMonthPicking: this.singleMonthPicking,
            disableMonthsWithoutYear: this.disableMonthsWithoutYear
        };

        this._getTooltipInstance()!
            .initialize(config)
            .subscribe(range => {
                this._onRangeSelected(range);
            });
    }

    private _getTooltipInstance(): LgYearMonthRangeSelectorPopupComponent | null {
        if (!this._tooltip || !this._tooltip.getPortalReference()) {
            return null;
        }

        return this._tooltip.getPortalReference<
            ComponentRef<LgYearMonthRangeSelectorPopupComponent>
        >()!.instance;
    }

    private _onRangeSelected(range: YearMonthRange): void {
        if (!this.value || !range) return;
        let doWriteValue = false;
        if (
            this.value.year !== range.year ||
            (this.value.year &&
                range.year &&
                (this.value.year.from !== range.year.from || this.value.year.to !== range.year.to))
        ) {
            this.value.year = range.year;
            doWriteValue = true;
        }
        if (
            this.value.month !== range.month ||
            (this.value.month &&
                range.month &&
                (this.value.month.from !== range.month.from ||
                    this.value.month.to !== range.month.to))
        ) {
            this.value.month = range.month;
            doWriteValue = true;
        }
        if (doWriteValue) {
            this.writeValue(range);
            this.select.emit(range);
        }
    }

    private _updateTooltipData(range: YearMonthRange): void {
        if (!range || !range.month || !range.year) return;
        this.previewMonthInterval = this._getTooltipRowSecondColumn(
            range.month.from,
            range.month.to
        );

        this.previewYearInterval = this._getTooltipRowSecondColumn(
            range.year.from,
            range.year.to,
            true
        );
        this._firstIntervalYearData = this._getTooltipRowSecondColumn(
            range.year.from,
            range.year.to,
            true
        );
        this._secondIntervalYearData = this._getTooltipRowSecondColumn(
            range.year.from,
            range.year.to,
            true
        );

        if (!this.showTooltipIntervalSplitBasedOnPeriods) {
            this._firstIntervalMonthData = this._getTooltipRowSecondColumn(
                range.month.from,
                range.month.to
            );
            this._secondIntervalMonthData = "";
        } else if (range.month.to === range.month.from) {
            this._firstIntervalMonthData =
                range.month.to <= this.firstIntervalEnd!
                    ? this._getTooltipRowSecondColumn(range.month.from, range.month.to)
                    : "";
            this._secondIntervalMonthData =
                range.month.to > this.firstIntervalEnd!
                    ? this._getTooltipRowSecondColumn(range.month.from, range.month.to)
                    : "";
        } else if (range.month.to < this.firstIntervalEnd!) {
            this._firstIntervalMonthData = this._getTooltipRowSecondColumn(
                range.month.from,
                range.month.to
            );
            this._secondIntervalMonthData = "";
        } else if (this.firstIntervalEnd && range.month.from > this.firstIntervalEnd) {
            this._firstIntervalMonthData = "";
            this._secondIntervalMonthData = this._getTooltipRowSecondColumn(
                coerceSecondDateForInterval(range.month, this.firstIntervalEnd + 1),
                range.month.to
            );
        } else {
            this._firstIntervalMonthData = this._getTooltipRowSecondColumn(
                range.month.from,
                coerceSecondDateForInterval(range.month, this.firstIntervalEnd!)
            );
            this._secondIntervalMonthData = this._getTooltipRowSecondColumn(
                coerceSecondDateForInterval(range.month, this.firstIntervalEnd! + 1),
                range.month.to
            );
        }
    }

    private _getTooltipRowSecondColumn(from: number, to: number, yearDisplay = false): string {
        return from === to
            ? yearDisplay
                ? from.toString()
                : this._getMonthForDisplay(from)
            : yearDisplay
              ? from + " - " + to
              : this._getMonthForDisplay(from) + " – " + this._getMonthForDisplay(to);
    }

    private _getMonthForDisplay(month: number): string {
        return this._names[(month + this.startMonth! - 1) % 12 || 12];
    }

    private _updateDefinition(currentValue: IRange | undefined): void {
        const allMonthsInTertiles = this._getMonthsInTertiles(currentValue);
        const monthsSortedWithinTertiles = this._getMonthsSortedWithinTertiles(allMonthsInTertiles);
        this._definition = this._getDefinitionFromTertiles(monthsSortedWithinTertiles);
    }

    private _getMonthsInTertiles(currentValue: IRange | undefined): [number[], number[], number[]] {
        const emptyResult: [number[], number[], number[]] = [[], [], []];

        if (!currentValue || this._isEmptySelectionOrWholeYear(currentValue)) {
            return emptyResult;
        }

        let allMonthsInRange;
        if (currentValue.from <= currentValue.to) {
            allMonthsInRange = ldRange(currentValue.from, currentValue.to + 1);
        } else {
            allMonthsInRange = [
                ...ldRange(currentValue.from, 13),
                ...ldRange(1, currentValue.to + 1)
            ];
        }

        return allMonthsInRange.reduce(
            (result: [number[], number[], number[]], current: number) => {
                result[this._getZeroIndexedTertileForMonth(current)].push(current);
                return result;
            },
            [[], [], []]
        );
    }

    _getMonthsSortedWithinTertiles(monthsInTertiles: number[][]): number[][] {
        return monthsInTertiles.map(x => x.sort((a, b) => (a > b ? 1 : -1)));
    }

    _isEmptySelectionOrWholeYear(range: IRange): boolean {
        return !range || (range.from % 12 || 12) === (range.to % 12) + 1;
    }

    private _getDefinitionFromTertiles(monthsInTertiles: number[][]): Line[] {
        const result: Line[] = [];
        monthsInTertiles.forEach((array: number[], index): void => {
            array.forEach((tertile: number): void => {
                result.push({
                    start: {
                        x: (tertile % 4 || 4) * 4,
                        y: 4 + 4 * (index + 1)
                    },
                    width: 2
                });
            });
        });

        return result;
    }

    private _getZeroIndexedTertileForMonth(month: number): 0 | 1 | 2 {
        switch (true) {
            case month >= 1 && month <= 4:
                return 0;
            case month >= 5 && month <= 8:
                return 1;
            case month >= 9 && month <= 12:
                return 2;
            default:
                throw new Error("month needs to be between 1 and 12");
        }
    }

    private _getRangeWithOffset(range: IRange | undefined): IRange | undefined {
        if (!range) {
            return range;
        }

        return {
            from: this._getMonthWithOffset(range.from),
            to: this._getMonthWithOffset(range.to)
        };
    }

    private _getMonthWithOffset(month: number): number {
        return (month - 1 + this.startMonth!) % 12 || 12;
    }
}

function coerceSecondDateForInterval(range: IRange, fallbackValue: number): number {
    return range.from === range.to ? range.from : fallbackValue;
}
