import {
    Component,
    ViewEncapsulation,
    ChangeDetectionStrategy,
    HostListener,
    Output,
    EventEmitter,
    OnDestroy,
    ChangeDetectorRef,
    inject
} from "@angular/core";
import { LgContentGridItemComponent } from "./lg-content-grid-item.component";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
    selector: "lg-content-grid-maximize-button",
    template: `
        <span
            [class.icon-fullscreen-enter]="!_isMaximized"
            [class.icon-fullscreen-exit]="_isMaximized"
        ></span>
    `,
    host: {
        class: "lg-content-grid-maximize-button lg-block__button"
    },
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LgContentGridMaximizeButtonComponent implements OnDestroy {
    private _changeDetectorRef = inject(ChangeDetectorRef);
    private _gridItem = inject(LgContentGridItemComponent);

    public _isMaximized = false;

    @Output() public readonly maximizedChange = new EventEmitter<boolean>();

    public get isMaximized(): boolean {
        return this._isMaximized;
    }

    private readonly _destroyed$ = new Subject<void>();

    public constructor() {
        this._isMaximized = this._gridItem.isMaximized;

        this._gridItem.maximizedChange.pipe(takeUntil(this._destroyed$)).subscribe(maximized => {
            this._isMaximized = maximized;
            this.maximizedChange.next(maximized);
            this._changeDetectorRef.markForCheck();
        });
    }

    @HostListener("click", ["$event"])
    public _onClick($event: MouseEvent): void {
        $event.stopPropagation();
        $event.preventDefault();

        this._gridItem.toggleMaximize();
    }

    public ngOnDestroy(): void {
        this._destroyed$.next();
        this._destroyed$.complete();
    }
}
