import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    ViewEncapsulation,
    inject
} from "@angular/core";
import { Subject } from "rxjs";
import { switchMap, takeUntil } from "rxjs/operators";
import { LgFilterSet } from "@logex/framework/lg-filterset";
import { LgSimpleChanges } from "@logex/framework/types";
import { useTranslationNamespace } from "@logex/framework/lg-localization";

import { LgBookmarksStateService } from "../services/lg-bookmarks-state.service";

@Component({
    standalone: false,
    selector: "lg-filters-panel-footer",
    templateUrl: "./lg-filters-panel-footer.component.html",
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    viewProviders: [useTranslationNamespace("FW._Directives._FiltersetSlideout")],
    host: { class: "lg-filters-panel__footer" }
})
export class LgFiltersPanelFooterComponent implements OnChanges, OnDestroy {
    private _bookmarkState = inject(LgBookmarksStateService);
    private _changeDetector = inject(ChangeDetectorRef);
    /**
     * Filter set definition (required).
     */
    @Input({ required: true }) filterSet!: LgFilterSet;

    /**
     * Filters state key (required).
     */
    @Input({ required: true }) filterSetStateKey!: string;

    /**
     * Specifies if filters panel is pinned.
     *
     * @default false
     */
    @Input() pinned = false;

    @Output() readonly clearAndHide = new EventEmitter();
    @Output() readonly hide = new EventEmitter();

    get _isAnyFilterActive(): boolean {
        return this.filterSet.isAnyActive();
    }

    private readonly _killSubscriptions$ = new Subject<void>();
    private _initialized = false;

    ngOnChanges(changes: LgSimpleChanges<LgFiltersPanelFooterComponent>): void {
        if (!this._initialized) {
            this._initialize();
        } else if (changes.filterSetStateKey) {
            this._killSubscriptions$.next();
            this._initialize();
        }

        this._initialized = true;
    }

    ngOnDestroy(): void {
        this._killSubscriptions$.next();
        this._killSubscriptions$.complete();
    }

    _onClearFiltersAndHideSideMenu($event: MouseEvent): void {
        $event.preventDefault();
        this.clearAndHide.emit();
    }

    _onHideSideMenu(): void {
        this.hide.emit();
    }

    private _initialize(): void {
        this._subscribeToCurrentApi();
        this._markForCheckWhenFilterSetChanges();
    }

    private _subscribeToCurrentApi(): void {
        this._bookmarkState
            .getCurrentBookmark(this.filterSetStateKey)
            .pipe(
                switchMap(bookmark => bookmark.changed$),
                takeUntil(this._killSubscriptions$)
            )
            .subscribe(() => this._changeDetector.markForCheck());
    }

    private _markForCheckWhenFilterSetChanges(): void {
        this.filterSet.onChanged
            .pipe(takeUntil(this._killSubscriptions$))
            .subscribe(() => this._changeDetector.markForCheck());
    }
}
