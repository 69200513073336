import ldGroupBy from "lodash-es/groupBy";
import { Injectable, inject } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { LgHelpTooltipServiceGateway } from "./gateway/lg-help-tooltip-gateway";
import { LgHelpTooltipItem } from "./types/types";
import { LG_LOCALIZATION_SETTINGS } from "@logex/framework/lg-localization";

const THROTTLE_INTERVAL = 50;

interface RequestedTooltip {
    key: string;
    lang: string;
}

@Injectable({ providedIn: "root" })
export class LgHelpTooltipService {
    private _gateway = inject(LgHelpTooltipServiceGateway);
    private _localizationSettings = inject(LG_LOCALIZATION_SETTINGS);
    private _cache: Record<string, Promise<LgHelpTooltipItem | null>> = {};
    private _requestedTooltips: RequestedTooltip[] = [];
    private _throttleHandler: any;
    private _responseHandlers: Record<string, (value: LgHelpTooltipItem | null) => void> = {};

    async getValue(key: string, lang?: string): Promise<LgHelpTooltipItem | null> {
        if (this._cache[key] != null) {
            return this._cache[key];
        }

        this._cache[key] = new Promise<LgHelpTooltipItem | null>(resolve => {
            this._responseHandlers[key] = resolve;
        });

        // If lang is undefined, use this._localizationSettings.locale
        const definedLang: string = lang || this._localizationSettings.locale;
        this._requestedTooltips.push({ key, lang: definedLang });

        clearTimeout(this._throttleHandler);
        this._throttleHandler = setTimeout(async () => {
            const requests = this._requestedTooltips;
            const handlers = this._responseHandlers;
            this._requestedTooltips = [];
            this._responseHandlers = {};
            await this._loadTooltips(requests, handlers);
        }, THROTTLE_INTERVAL);

        return this._cache[key];
    }

    private async _loadTooltips(
        requestedTooltips: RequestedTooltip[],
        responseHandlers: Record<string, (value: LgHelpTooltipItem | null) => void>
    ): Promise<void> {
        const requestedTooltipsGroupedByLang = ldGroupBy(requestedTooltips, x => x.lang);

        for (const lang in requestedTooltipsGroupedByLang) {
            if (Object.prototype.hasOwnProperty.call(requestedTooltipsGroupedByLang, lang)) {
                const requestedTooltips = requestedTooltipsGroupedByLang[lang];
                const requestedTooltipKeys = requestedTooltips.map(x => x.key);

                const tooltipsData = await firstValueFrom(
                    this._gateway.getValues(requestedTooltipKeys, lang)
                );

                if (tooltipsData.tooltips?.length > 0) {
                    for (const tooltip of tooltipsData.tooltips) {
                        responseHandlers[tooltip.id](tooltip);
                    }

                    // Resolve the handlers for which no tooltip data has been received (in order to keep Promise.all calls loading multiple tooltips working)
                    const loadedTooltipIds = tooltipsData.tooltips.map(x => x.id);
                    const missingTooltipIds = Object.keys(responseHandlers).filter(
                        x => !loadedTooltipIds.includes(x)
                    );
                    for (const x of missingTooltipIds) {
                        responseHandlers[x](null);
                    }
                }
            }
        }
    }
}
