import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from "@angular/core";
import { LgIconComponent } from "./lg-icon.component";

@Component({
    standalone: true,
    selector: "lg-hoverable-icon",
    template: `
        <svg class="lg-icon--hoverable__primary" viewBox="0 0 24 24">
            <use [attr.xlink:href]="'#' + icon"></use>
        </svg>
        <svg class="lg-icon--hoverable__secondary">
            <use [attr.xlink:href]="'#' + hoverIcon"></use>
        </svg>
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LgHoverableIconComponent extends LgIconComponent {
    /**
     * Icon to be shown on hover.
     */
    @Input({ required: true }) hoverIcon: string | "" = "";

    protected override _getMainClassName(): string {
        return "lg-icon lg-icon--hoverable";
    }
}
