import { lgProvide, LgProvider, LgProviders } from "@logex/framework/types";
import { Provider } from "@angular/core";
import {
    IScenarioDialogConfig,
    IScenarioDialogWithPivotConfig,
    LG_SCENARIO_DIALOG_CONFIG,
    LG_SCENARIO_DIALOG_WITH_PIVOT_CONFIG,
    LG_SCENARIO_GATEWAY,
    LgScenarioGateway
} from "../lg-scenario-dialog";
import { IAppLoadIndicatorService, LG_APP_LOAD_INDICATOR } from "@logex/framework/lg-application";
import { LgLoaderService } from "../lg-loader";
import {
    ILgFwUiStateService,
    LG_FW_UI_STATE_SERVICE,
    LgUserStorageFwUiStateService
} from "../lg-fw-ui-state";

export interface LgLayoutConfig {
    /**
     * Provides load indicator service (token `LG_APP_LOAD_INDICATOR`).
     * Optional. `LgLoaderService` is used by default in `LgLayoutModule`.
     */
    loadIndicator?: LgProvider<IAppLoadIndicatorService>;
    /**
     * Provides scenario dialog configuration (token `LG_SCENARIO_DIALOG_CONFIG`).
     * Optional. No default value.
     */
    scenarioDialogConfig?: LgProvider<IScenarioDialogConfig>;
    /**
     * Provides scenario dialog with pivot configuration (token `LG_SCENARIO_DIALOG_WITH_PIVOT_CONFIG`).
     * Optional. No default value.
     */
    scenarioDialogWithPivotConfig?: LgProvider<IScenarioDialogWithPivotConfig>;
    /**
     * Provides scenario gateway (token `LG_SCENARIO_GATEWAY`).
     * Optional. `LgScenarioGateway` is used by default in `LgLayoutModule`.
     */
    scenarioGateway?: LgProvider<LgScenarioGateway>;
    /**
     * Provides UI state service (token `LG_FW_UI_STATE_SERVICE`).
     * Optional. `LgUserStorageFwUiStateService` is used by default in `LgLayoutModule`.
     */
    uiStateService?: LgProvider<ILgFwUiStateService>;
}

/**
 * Helper utility to configure and provide framework `LgLayoutModule` providers
 */
export const lgLayoutProviders: LgProviders<LgLayoutConfig> = (
    config?: LgLayoutConfig
): Provider[] => {
    const providers = [
        lgProvide(LG_SCENARIO_GATEWAY, config?.scenarioGateway ?? { useClass: LgScenarioGateway }),
        lgProvide(LG_APP_LOAD_INDICATOR, config?.loadIndicator ?? { useClass: LgLoaderService }),
        lgProvide(
            LG_FW_UI_STATE_SERVICE,
            config?.uiStateService ?? { useClass: LgUserStorageFwUiStateService }
        )
    ];
    if (config?.scenarioDialogConfig) {
        providers.push(lgProvide(LG_SCENARIO_DIALOG_CONFIG, config.scenarioDialogConfig));
    }
    if (config?.scenarioDialogWithPivotConfig) {
        providers.push(
            lgProvide(LG_SCENARIO_DIALOG_WITH_PIVOT_CONFIG, config.scenarioDialogWithPivotConfig)
        );
    }
    return providers;
};
