import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { UiCoreModule } from "@logex/framework/ui-core";
import { LgLocalizationModule } from "@logex/framework/lg-localization";

import { LgPivotTableBodyComponent } from "./lg-pivot-table-body.component";
import { LgPivotTableRowDefDirective } from "./templates/lg-pivot-table-row-def.directive";
import { LgPivotTableRowSeparatorDefDirective } from "./templates/lg-pivot-table-row-separator-def.directive";
import { LgPivotTableRowFooterDefDirective } from "./templates/lg-pivot-table-row-footer-def.directive";
import { LgPivotTableRowDirective } from "./templates/lg-pivot-table-row.directive";
import { LgPivotTableExpandComponent } from "./lg-pivot-table-expand.component";
import { LgPivotTableEmptyColumnComponent } from "./lg-pivot-table-empty-column.component";
import { LgPivotTableHeaderDirective } from "./templates/lg-pivot-table-header.directive";
import { LgPivotTableFooterDefDirective } from "./templates/lg-pivot-table-footer-def.directive";
import { LgPivotTableHeaderDefDirective } from "./templates/lg-pivot-table-header-def.directive";
import { LgPivotTableEmptyRowDefDirective } from "./templates/lg-pivot-table-empty-row-def.directive";
import { LgPivotTableFilterableComponent } from "./lg-pivot-table-filterable.component";
import { LgPivotTableSortByColumn } from "./lg-pivot-table-sort-by-column.directive";
import { LgPivotTableBodyContainerComponent } from "./helpers/lg-pivot-table-body-container.component";
import { LgPivotTableHeaderContainerComponent } from "./helpers/lg-pivot-table-header-container.component";
import { LgPivotTableComponent } from "./lg-pivot-table.component";
import { LgPivotTableQuickMenuComponent } from "./lg-pivot-table-quick-menu.component";
import { LgPivotTableBodyLevelHeadersComponent } from "./lg-pivot-table-body-level-headers.component";
import { LgPivotTableLevelHeadersComponent } from "./lg-pivot-table-level-headers.component";
import { LgPivotInstanceSyncComponent } from "./lg-pivot-instance-sync.component";

const STANDALONE = [
    LgPivotTableRowDefDirective,
    LgPivotTableRowSeparatorDefDirective,
    LgPivotTableRowFooterDefDirective,
    LgPivotTableRowDirective,
    LgPivotTableHeaderDirective,
    LgPivotTableFooterDefDirective,
    LgPivotTableHeaderDefDirective,
    LgPivotTableEmptyRowDefDirective,
    LgPivotTableSortByColumn
];

@NgModule({
    imports: [CommonModule, LgLocalizationModule, UiCoreModule, ...STANDALONE],
    exports: [
        LgPivotTableBodyComponent,
        LgPivotTableRowDefDirective,
        LgPivotTableRowSeparatorDefDirective,
        LgPivotTableRowFooterDefDirective,
        LgPivotTableRowDirective,
        LgPivotTableExpandComponent,
        LgPivotTableEmptyColumnComponent,
        LgPivotTableHeaderDirective,
        LgPivotTableFooterDefDirective,
        LgPivotTableHeaderDefDirective,
        LgPivotTableEmptyRowDefDirective,
        LgPivotTableComponent,
        LgPivotTableFilterableComponent,
        LgPivotTableQuickMenuComponent,
        LgPivotTableSortByColumn,
        LgPivotTableBodyLevelHeadersComponent,
        LgPivotTableLevelHeadersComponent,
        LgPivotInstanceSyncComponent,
        ...STANDALONE
    ],
    declarations: [
        LgPivotTableBodyComponent,
        LgPivotTableExpandComponent,
        LgPivotTableEmptyColumnComponent,
        LgPivotTableBodyContainerComponent,
        LgPivotTableHeaderContainerComponent,
        LgPivotTableComponent,
        LgPivotTableFilterableComponent,
        LgPivotTableQuickMenuComponent,
        LgPivotTableBodyLevelHeadersComponent,
        LgPivotTableLevelHeadersComponent,
        LgPivotInstanceSyncComponent
    ]
})
export class LgPivotTableModule {}
