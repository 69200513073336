import { DatePipe } from "@angular/common";
import { Pipe, PipeTransform, inject } from "@angular/core";
import { LgCapitalizePipe } from "./lg-capitalize.pipe";

@Pipe({
    // eslint-disable-next-line @angular-eslint/pipe-prefix
    name: "monthName",
    pure: true
})
export class LgMonthNamePipe implements PipeTransform {
    private _capitalizePipe = inject(LgCapitalizePipe);
    private _datePipe = inject(DatePipe);

    public transform(
        value: number | null | undefined,
        format = "MMMM",
        capitalize = true
    ): string | null {
        if (value == null) {
            return "";
        }

        let result = this._datePipe.transform(new Date(2014, value - 1), format);
        if (result == null) return "";

        if (format === "MMM") result = result.substring(0, 3);

        if (capitalize) result = this._capitalizePipe.transform(result);

        return result;
    }
}
