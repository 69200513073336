<div *ngIf="_getFiles() && _getFiles().length">
    <div class="upload-table">
        <div style="max-height: 300px">
            <div
                *ngFor="let file of _getFiles(); index as i; trackBy: trackByFn"
                class="table__row upload-table__row"
            >
                <div class="table__column table__column--level upload-table__row--spacer"></div>
                <div
                    class="table__column table__column--level crop upload-table__row--name upload-table__row--name-full"
                >
                    {{ file.name }}
                </div>
                <div class="table__column table__column--icons last upload-table__row--icons">
                    <lg-icon
                        icon="icon-close"
                        [clickable]="true"
                        (click)="_removeFile(i)"
                    ></lg-icon>
                </div>
            </div>
        </div>
    </div>
</div>
