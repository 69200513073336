import { inject, Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { IDebugEvent } from "./debug-event";
import { LgConsoleConfiguration } from "./lg-console-configuration";

/**
 * Service for aggregating console events
 * (fka ErrorService).
 */
@Injectable({ providedIn: "root" })
export class DebugEventAggregator {
    private readonly _events$ = new BehaviorSubject<IDebugEvent[]>([]);
    private readonly _eventsMax = inject(LgConsoleConfiguration).eventsMax;

    /**
     * Clear all published events.
     */
    clear(): void {
        this._events$.next([]);
    }

    /**
     * Listen published events.
     */
    listen$(): Observable<IDebugEvent[]> {
        return this._events$.asObservable();
    }

    /**
     * Add an event to the published list.
     */
    publish(event: IDebugEvent): void {
        const events = this._events$.getValue();
        if (events.length < this._eventsMax) {
            this._events$.next([...events, event]);
        }
    }
}
