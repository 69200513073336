import {
    inject,
    LOCALE_ID,
    ModuleWithProviders,
    NgModule,
    provideAppInitializer
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateMessageFormatCompiler } from "ngx-translate-messageformat-compiler";

import { LG_CURRENCY_CODE, LG_LANGUAGE_ID } from "@logex/framework/core";
import { useTranslationNamespace } from "./translate/util";
import { LgTranslatePipe } from "./translate/lg-translate.pipe";
import { LgTranslateDirective } from "./translate/lg-translate.directive";
import {
    LG_BASE_TRANSLATIONS,
    LG_LOCALIZATION_SOURCE_URLS,
    LG_LOCALIZATION_URL_PREFIX,
    LG_TRANSLATE_STARTUP_SERVICE_ACTIVE,
    LgTranslateStartupService,
    LocalizationSourceCallback
} from "./translate/lg-translate-startup.service";
import {
    lgI18nCurrencyProviderFactory,
    lgI18nLanguageProviderFactory,
    lgI18nLocaleProviderFactory
} from "./i18n/lg-i18n-provider-factories";
import { LgI18nService } from "./i18n/lg-i18n.service";
import { LG_LOCALIZATION_SETTINGS } from "./translate/lg-localization.types";
import { LgLocalizationSettingsWithGlobals } from "./translate/lg-localization-settings-with-globals";

export interface LgLocalizationModuleOptions {
    useStandardLoader?: boolean;
    useGlobalObjectSettings?: boolean;
    /**
     * Prefix (or domain in case of absolute urls) for default langauge source url.
     */
    localizationUrlPrefix?: string;
    /**
     * Callbacks for language source urls.
     * If you define this, then `localizationUrlPrefix` is not used.
     */
    getSourceUrls?: LocalizationSourceCallback[];

    /**
     * Translations to be used in cases when getting application translations is failed. E.g. on initialization errors.
     */
    baseTranslations?: object;
}

@NgModule({
    imports: [CommonModule, LgTranslateDirective, LgTranslatePipe],
    exports: [LgTranslatePipe, LgTranslateDirective],
    providers: [useTranslationNamespace(), TranslateMessageFormatCompiler]
})
export class LgLocalizationModule {
    static forRoot(
        options?: LgLocalizationModuleOptions
    ): ModuleWithProviders<LgLocalizationModule> {
        return <ModuleWithProviders<LgLocalizationModule>>{
            ngModule: LgLocalizationModule,
            providers: [
                {
                    provide: LOCALE_ID,
                    useFactory: lgI18nLocaleProviderFactory,
                    deps: [LgI18nService]
                },
                {
                    provide: LG_LANGUAGE_ID,
                    useFactory: lgI18nLanguageProviderFactory,
                    deps: [LgI18nService]
                },
                {
                    provide: LG_CURRENCY_CODE,
                    useFactory: lgI18nCurrencyProviderFactory,
                    deps: [LgI18nService]
                },
                {
                    provide: LG_LOCALIZATION_SETTINGS,
                    useClass: LgLocalizationSettingsWithGlobals
                },
                {
                    provide: LG_LOCALIZATION_URL_PREFIX,
                    useValue:
                        !options || options.localizationUrlPrefix == null
                            ? "dist/localization"
                            : options.localizationUrlPrefix
                },
                {
                    provide: LG_TRANSLATE_STARTUP_SERVICE_ACTIVE,
                    useValue:
                        !options || options.useGlobalObjectSettings == null
                            ? true
                            : options.useGlobalObjectSettings
                },
                {
                    provide: LG_LOCALIZATION_SOURCE_URLS,
                    useValue: options?.getSourceUrls
                },
                provideAppInitializer(() => {
                    const active = inject(LG_TRANSLATE_STARTUP_SERVICE_ACTIVE);
                    if (active) {
                        const service = inject(LgTranslateStartupService);
                        return service.initialize();
                    } else {
                        return Promise.resolve();
                    }
                }),
                {
                    provide: LG_BASE_TRANSLATIONS,
                    useValue: options?.baseTranslations ?? {}
                }
            ]
        };
    }
}
