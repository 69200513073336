import type { LgTime } from "./lg-time-picker.types";
import { LgPromptDialog } from "../lg-prompt-dialog/lg-prompt-dialog.component";
import type { LgTranslateService } from "@logex/framework/lg-localization";
import { DatePipe } from "@angular/common";

export function showInvalidTimeDialog(
    minTime: LgTime | null | undefined,
    maxTime: LgTime | null | undefined,
    timeFormat: string,
    datePipe: DatePipe,
    lgTranslate: LgTranslateService,
    promptDialog: LgPromptDialog
): Promise<string> {
    let minText = "",
        maxText = "",
        errorText: string;
    if (minTime) {
        minText = datePipe.transform(
            new Date(2020, 0, 1, minTime.hours, minTime.minutes),
            timeFormat
        )!;
        errorText = maxTime ? ".InvalidTime_MinMax" : ".InvalidTime_Min";
    } else {
        errorText = ".InvalidTime_Max";
    }
    if (maxTime) {
        maxText = datePipe.transform(
            new Date(2020, 0, 1, maxTime.hours, maxTime.minutes),
            timeFormat
        )!;
    }

    return promptDialog.alert(
        lgTranslate.translate(".InvalidTimeDialog_Title"),
        lgTranslate.translate(errorText, { min: minText, max: maxText })
    );
}
