import {
    Directive,
    ElementRef,
    HostListener,
    Input,
    OnInit,
    Renderer2,
    inject
} from "@angular/core";
import { elementHasClass } from "@logex/framework/utilities";

@Directive({
    standalone: true,
    selector: "[lgClickForInput]"
})
export class LgClickForInputDirective implements OnInit {
    private _el = inject(ElementRef);
    private _renderer = inject(Renderer2);

    /**
     * Ensures that click on the element is forwarded to first dropdown within (obsolete).
     *
     * @deprecated
     */
    @Input("lgClickForInput") set clickForInput(condition: boolean) {
        this._enabled = condition !== false;
    }

    get clickForInput(): boolean {
        return this._enabled;
    }

    private _enabled = true;

    ngOnInit(): void {
        this._renderer.addClass(this._el.nativeElement, "lg-click-for-input");
    }

    @HostListener("click")
    private _onClick(): void {
        if (
            !this._enabled ||
            elementHasClass(this._el, "collapsible-indicator") ||
            elementHasClass(this._el, "icon")
        ) {
            return;
        }

        const inputs = Array.from(
            this._el.nativeElement.getElementsByTagName("input")
        ) as HTMLElement[];

        if (!inputs) {
            return;
        }

        const inputsWithClass = inputs.filter(x => x.classList.contains("lg-dropdown"));

        if (!inputsWithClass || !inputsWithClass.length) {
            return;
        }

        inputsWithClass[0].click();
    }
}
