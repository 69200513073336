import {
    Component,
    Input,
    TemplateRef,
    OnDestroy,
    OnChanges,
    SimpleChanges,
    Injector,
    inject
} from "@angular/core";
import { LgPlaceholderStorageService } from "./lg-placeholder-storage.service";
import { NgIf, NgTemplateOutlet } from "@angular/common";

/**
 * Defines a placeholder, which will be filled by a content of the specified name (see lgPlaceholderContent). The
 * inside of the placeholder itself defines a default, which will be used if no other content definition exists (this
 * is not part of the regular stack and thus has really the lowest priority). The name attribute supports interpolation.
 *
 * Usage:
 * <lg-placeholder name="app.welcome" [context]="{color:'red'}">
 *    <b>I don't know, how to welcome you!</b>
 * </lg-placeholder>
 *
 * The content is normally rendered using the context specified for the placeholder. Typically this is what
 * you want. It can be however overriden for a special cases by using the scope-source attribute. The allowed values are:
 * - content: use the content's own scope (this is the default)
 * - placeholder: use the placeholder's scope
 * - view: use the view's scope (i.e. scope of the ng-view directive). This is useful especially for defining default
 *   content outside the view. Example:  <lg-placeholder name="currentControl" scope-source="view">Current controller: {{page.constructor.name}}</lg-placeholder>
 * - view-for-default: use the view's scope when displaying the default, use content's scope otherwise.
 *
 */
@Component({
    standalone: true,
    selector: "lg-placeholder",
    imports: [NgTemplateOutlet, NgIf],
    template: `
        <ng-container
            [ngTemplateOutlet]="_template"
            [ngTemplateOutletContext]="_storedContext || defaultContext"
            [ngTemplateOutletInjector]="_injector"
        ></ng-container>
        <ng-content *ngIf="!_template"></ng-content>
    `
})
export class LgPlaceholderComponent implements OnChanges, OnDestroy {
    private _storage = inject(LgPlaceholderStorageService);
    @Input({ required: true }) public name!: string;

    @Input("context") public defaultContext?: any;

    protected _storedContext: any;
    protected _template: TemplateRef<any> | null = null;
    protected _injector: Injector | null = null;
    private _unsubscriber: (() => void) | null = null;

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.name) {
            this._unsubscribe();

            if (changes.name.currentValue) {
                this._unsubscriber = this._storage.watchContent(
                    this.name,
                    (_name, content) => this._updateTemplate(content?.template, content?.injector),
                    (_name, context) => this._updateContext(context)
                );
            }
        }
    }

    public ngOnDestroy(): void {
        this._unsubscribe();
    }

    private _unsubscribe(): void {
        if (this._unsubscriber) {
            this._unsubscriber();
            this._unsubscriber = null;
            this._template = null;
            this._storedContext = null;
        }
    }

    private _updateTemplate(
        content: TemplateRef<any> | undefined,
        injector: Injector | undefined
    ): void {
        this._template = content ?? null;
        this._injector = injector ?? null;
    }

    private _updateContext(context: any): void {
        this._storedContext = context;
    }
}
