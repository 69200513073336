import { PortalInjector, TemplatePortal } from "@angular/cdk/portal";
import {
    Directive,
    ElementRef,
    EventEmitter,
    inject,
    InjectionToken,
    Injector,
    Input,
    Output,
    TemplateRef,
    ViewChild,
    ViewContainerRef
} from "@angular/core";
import * as d3 from "d3";
import {
    ILgFormatter,
    ILgFormatterOptions,
    LgFormatterFactoryService
} from "@logex/framework/core";
import { LgSimpleChanges, Nullable } from "@logex/framework/types";
import { Subject } from "rxjs";

import { D3TooltipApi, ID3TooltipOptions, LgD3TooltipService } from "../d3";
import { ChartClickEvent, Margin } from "./chart.types";
import { IChartTooltipProvider, IImplicitContext } from "./lg-chart-template-context.directive";

const defaultFormatterOptions = {
    decimals: 0
};

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class BaseChartComponent<TConvertedItem, TTooltipContext extends object>
    implements IChartTooltipProvider<TTooltipContext>
{
    protected _elementRef = inject(ElementRef);
    protected _injector = inject(Injector);
    protected _tooltipService = inject(LgD3TooltipService);
    protected _viewContainerRef = inject(ViewContainerRef);
    private _formatterFactory = inject(LgFormatterFactoryService);

    /**
     * Specifies the input data for chart.
     */
    @Input() data: any[] = [];

    // TODO: Use object instead of string for margins?
    @Input() margins = "";

    /**
     * Specifies the width of the chart area in pixels. Required parameter without default.
     */
    @Input({ required: true }) width!: number;

    /**
     * Specifies the height of the chart area in pixels. Required parameter without default.
     */
    @Input({ required: true }) height!: number;

    /**
     * Specifies value formatter type.
     */
    @Input() formatterType!: string;

    /**
     * Specifies value formatter options.
     */
    @Input() formatterOptions?: ILgFormatterOptions;

    /**
     * Specifies label formatter type.
     */
    @Input() labelFormatterType?: string;

    /**
     * Specifies label formatter options.
     */
    @Input() labelFormatterOptions?: ILgFormatterOptions;

    /**
     * @optional
     * Specifies the template to be used when hovering over elements. Defaults to own template.
     */
    @Input() tooltipTemplate?: TemplateRef<IImplicitContext<TTooltipContext>>;

    /**
     * @optional
     * Specifies class of tooltip. Default to empty.
     */
    @Input() tooltipClass?: string;

    /**
     * @optional
     * Specifies whether points are clickable.
     *
     * @default false
     */
    @Input() clickable = false;

    /**
     * Emits data in clicked box, if the clickable input is set to true.
     */
    // todo: better typing? We need to get the individual item, and grouped chart sends array as TConvertedItem
    @Output() readonly itemClick = new EventEmitter<ChartClickEvent<any, any>>();

    @ViewChild("defaultTemplate", { static: true }) _defaultTooltipTemplate?: TemplateRef<
        IImplicitContext<TTooltipContext>
    >;

    _numberFormat!: (x: Nullable<number>) => string;
    _height = 0;

    protected _data: TConvertedItem[] = [];

    protected _formatter!: ILgFormatter<any>;
    protected _labelFormatter!: ILgFormatter<any>;
    protected _labelFormat!: (x: number) => string;

    protected _tooltipContext: TTooltipContext | null = null;
    protected _tooltip!: D3TooltipApi;
    protected _tooltipPortal?: TemplatePortal<IImplicitContext<TTooltipContext>>;

    get tooltipContext(): TTooltipContext | null {
        return this._tooltipContext;
    }

    set tooltipContext(context: TTooltipContext) {
        if (this._tooltipPortal?.context) {
            this._tooltipPortal.context.$implicit = context;
        }
        this._tooltipContext = context;
    }

    protected _width = 0;
    protected _margin!: Required<Margin>;

    protected _svg!: d3.Selection<any, any, any, any>;
    protected _svgG!: d3.Selection<any, any, any, any>;
    protected _chart!: d3.Selection<any, any, any, any>;

    protected _initialized = false;
    protected _destroyed$: Subject<void> = new Subject();

    protected _onInit(): void {
        this._initializeFormatters();
    }

    protected _onBaseChartChanges(
        changes: LgSimpleChanges<BaseChartComponent<TConvertedItem, TTooltipContext>>
    ): void {
        let needsNewFormat = false;

        if (changes.formatterType) {
            this._formatter = this._getFormatter(this.formatterType, this.formatterOptions ?? {});
            needsNewFormat = true;
        } else if (changes.formatterOptions) {
            needsNewFormat = true;
        }

        if (needsNewFormat) {
            this._numberFormat = x => this._formatter.format(x, this.formatterOptions);
        }
    }

    protected _onDestroy(): void {
        this._tooltip?.destroy();

        this._destroyed$.next();
        this._destroyed$.complete();
    }

    protected _drawMainSvgHolder(holder: HTMLElement): void {
        this._svg = d3.select(holder).append("svg");
        this._svgG = this._svg.append("g");
    }

    protected _getFormatter(
        type: string,
        formatterOptions: ILgFormatterOptions
    ): ILgFormatter<any> {
        return this._formatterFactory.getFormatter(type, {
            ...defaultFormatterOptions,
            ...formatterOptions
        });
    }

    protected _initializeTooltip(overrideOptions?: ID3TooltipOptions): void {
        this._tooltipPortal = this._getTooltipContent();
        const commonOptions: ID3TooltipOptions = {
            stay: false,
            trapFocus: false,
            position: "top-left",
            tooltipClass: `lg-tooltip lg-tooltip--d3 ${
                this.tooltipClass ? " " + this.tooltipClass : ""
            }`,
            panelClass: "chart-overlay",
            content: this._tooltipPortal,
            delayHide: 150,
            target: this._elementRef
        };
        this._tooltip = this._tooltipService.create({
            ...commonOptions,
            ...overrideOptions
        });
    }

    protected _getMargin(parts: string[]): Required<Margin> {
        const top = parts.length > 0 ? parseInt(parts[0]) : 20;
        const right = parts.length > 1 ? parseInt(parts[1]) : top;
        const bottom = parts.length > 2 ? parseInt(parts[2]) : top;
        const left = parts.length > 3 ? parseInt(parts[3]) : right;

        return { top, left, right, bottom };
    }

    protected _runIf(fn: () => void, predicate: () => boolean): void {
        if (predicate()) {
            fn();
        }
    }

    protected _initializeFormatters(): void {
        this.formatterType = this.formatterType || "int";
        this._formatter = this._getFormatter(this.formatterType, this.formatterOptions ?? {});
        this._numberFormat = x => this._formatter.format(x, this.formatterOptions);

        this.labelFormatterType = this.labelFormatterType || "int";
        this._labelFormatter = this._getFormatter(
            this.labelFormatterType,
            this.labelFormatterOptions ?? {}
        );
        this._labelFormat = x => this._labelFormatter.format(x, this.formatterOptions);
    }

    private _getTooltipContent(): TemplatePortal<IImplicitContext<TTooltipContext>> {
        return new TemplatePortal<IImplicitContext<TTooltipContext>>(
            (this.tooltipTemplate || this._defaultTooltipTemplate)!,
            this._viewContainerRef,
            { $implicit: this.tooltipContext! }
        );
    }

    private _createInjector<TProps>(
        injectionToken: InjectionToken<any>,
        dataToPass: TProps
    ): PortalInjector {
        const injectorTokens = new WeakMap();

        injectorTokens.set(injectionToken, dataToPass);

        return new PortalInjector(this._injector, injectorTokens);
    }
}
