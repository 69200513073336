<lg-icon
    class="lg-help-button"
    [class.lg-help-button--active]="_showTooltip"
    icon="icon-help"
    [lgTooltip]="_showTooltip ? tooltipContent : null"
></lg-icon>

<ng-template #tooltipContent>
    <div class="lg-help-tooltip__body">
        <strong>{{ data!.title }}</strong>
        <p class="lg-help-tooltip__text">{{ data!.text }}</p>
        <a target="_blank" [href]="data!.link">{{ ".MoreInfo" | lgTranslate }}</a>
    </div>
</ng-template>
