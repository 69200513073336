import { Injectable, NgZone, inject } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { FocusTrap, InteractivityChecker } from "@angular/cdk/a11y";
import { take } from "rxjs/operators";
import { getFirstTabbableElementAfter } from "./getFirstTabbableElementAfter";

/**
 * Extension of the CDK class. Consider dropping it if we modify our overlay focus behaviour so that it's no longer necessary
 */
// TODO: implement  public FocusTrap api, once we have conditional types
export class LgFocusTrap {
    private _trap: FocusTrap;

    constructor(
        private _element: HTMLElement,
        private _checker: InteractivityChecker,
        private _ngZone: NgZone,
        _document: Document,
        deferAnchors = false
    ) {
        this._trap = new FocusTrap(_element, _checker, _ngZone, _document, deferAnchors);
    }

    public focusFirstTabbableElementForOverlayWhenReady(): Promise<boolean> {
        return new Promise<boolean>(resolve => {
            this._executeOnStable(() => resolve(this.focusFirstTabbableElementForOverlay()));
        });
    }

    public focusFirstTabbableElementAfterWhenReady(
        after: HTMLElement,
        forOverlay: boolean
    ): Promise<boolean> {
        return new Promise<boolean>(resolve => {
            this._executeOnStable(() =>
                resolve(this.focusFirstTabbableElementAfter(after, forOverlay))
            );
        });
    }

    public focusFirstTabbableElementForOverlay(): boolean {
        const redirectToElement = getFirstTabbableElementAfter(
            this._element,
            null,
            { value: true },
            true,
            this._checker
        );

        if (redirectToElement && redirectToElement.focus) {
            redirectToElement.focus();
        }

        return !!redirectToElement;
    }

    public focusFirstTabbableElementAfter(after: HTMLElement, forOverlay: boolean): boolean {
        // note: if after is null, we behave like we already found it
        const redirectToElement = getFirstTabbableElementAfter(
            this._element,
            after,
            { value: after == null },
            forOverlay,
            this._checker
        );

        if (redirectToElement) {
            if (redirectToElement.focus) redirectToElement.focus();
            return true;
        }

        return forOverlay
            ? this.focusFirstTabbableElementForOverlay()
            : this.focusFirstTabbableElement();
    }

    public destroy(): void {
        this._trap.destroy();
    }

    public attachAnchors(): void {
        this._trap.attachAnchors();
    }

    public focusInitialElementWhenReady(): Promise<boolean> {
        return this._trap.focusInitialElementWhenReady();
    }

    public focusFirstTabbableElementWhenReady(): Promise<boolean> {
        return this._trap.focusFirstTabbableElementWhenReady();
    }

    public focusLastTabbableElementWhenReady(): Promise<boolean> {
        return this._trap.focusLastTabbableElementWhenReady();
    }

    public focusInitialElement(): boolean {
        return this._trap.focusInitialElement();
    }

    public focusFirstTabbableElement(): boolean {
        return this._trap.focusFirstTabbableElement();
    }

    public focusLastTabbableElement(): boolean {
        return this._trap.focusLastTabbableElement();
    }

    private _executeOnStable(fn: () => any): void {
        if (this._ngZone.isStable) {
            fn();
        } else {
            this._ngZone.onStable.asObservable().pipe(take(1)).subscribe(fn);
        }
    }
}

@Injectable({ providedIn: "root" })
export class LgFocusTrapFactory {
    private _checker = inject(InteractivityChecker);
    private _ngZone = inject(NgZone);
    private _document: Document;

    constructor() {
        const _document = inject<any>(DOCUMENT); // any because of ng-packagr
        this._document = _document;
    }

    /**
     * Creates a focus-trapped region around the given element.
     *
     * @param element The element around which focus will be trapped.
     * @param deferCaptureElements Defers the creation of focus-capturing elements to be done
     *     manually by the user.
     * @returns The created focus trap instance.
     */
    create(element: HTMLElement, deferCaptureElements = false): LgFocusTrap {
        return new LgFocusTrap(
            element,
            this._checker,
            this._ngZone,
            this._document,
            deferCaptureElements
        );
    }
}
