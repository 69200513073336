import { Directive, ComponentRef, AfterViewInit, OnDestroy, Input } from "@angular/core";
import { ComponentPortal } from "@angular/cdk/portal";

import { toBoolean, toInteger } from "@logex/framework/utilities";

import { LgPortalOutletDirective } from "../../templating";
import { LgLoaderTabComponent } from "./lg-loader-tab.component";

@Directive({
    standalone: true,
    selector: "[lgLoaderOverlay]",
    host: {
        "[attr.lgLoaderOverlayHost]": "true"
    }
})
export class LgLoaderOverlayDirective implements AfterViewInit, OnDestroy {
    /**
     * Specifies if loader overlay should be shown.
     *
     * @default false
     */
    @Input("lgLoaderOverlay") set visible(val: boolean | "true" | "false") {
        val = toBoolean(val);
        if (val === this._visible) return;

        if (val) {
            this.show();
        } else {
            this.hide();
        }
        this._visible = val;
    }

    get visible(): boolean {
        return this._visible;
    }

    /**
     * Specifies delay before show overlay (in ms, if true then 100).
     *
     * @default 0
     */
    @Input("lgLoaderOverlayDelay") set delay(val: boolean | number | string) {
        if (val === true || val === "true") {
            this._delay = 100;
        } else {
            this._delay = toInteger(val);
        }
    }

    get delay(): number {
        return this._delay;
    }

    /**
     * HTML element to skip overlay with.
     */
    @Input("lgLoaderOverlaySkip") _lgLoaderOverlaySkip?: HTMLElement | undefined;

    private _outlet: LgPortalOutletDirective;
    private _instance: ComponentRef<LgLoaderTabComponent> | null = null;
    private _refCount = 0;
    private _initialized = false;
    private _visible = false;
    private _delay = 0;
    private _timer: number | null = null;

    constructor() {
        this._outlet = new LgPortalOutletDirective();
        this._outlet.attachInside = true;
    }

    show(): void {
        this._refCount += 1;
        if (this._initialized && this._refCount === 1) {
            if (!this._delay) {
                this._create();
                this._cancelTimer();
            } else if (!this._timer) {
                this._timer = window.setTimeout(() => {
                    this._timer = null;
                    this._create();
                }, this._delay);
            }
        }
    }

    hide(): void {
        this._refCount -= 1;
        if (this._initialized && this._refCount === 0) {
            this._remove();
            this._cancelTimer();
        }
    }

    ngAfterViewInit(): void {
        this._initialized = true;
        if (this._refCount) {
            this._create();
        }
    }

    ngOnDestroy(): void {
        this._remove();
        this._cancelTimer();
    }

    private _cancelTimer(): void {
        if (this._timer !== null) {
            clearTimeout(this._timer);
            this._timer = null;
        }
    }

    private _create(): void {
        if (!this._instance) {
            const portal = new ComponentPortal(LgLoaderTabComponent);
            this._instance = this._outlet.attach(portal);
            if (this._lgLoaderOverlaySkip) {
                this._instance.instance._topOffset = this._lgLoaderOverlaySkip.clientHeight;
            }
        }
    }

    private _remove(): void {
        if (this._instance) {
            this._outlet.detach();
            this._instance = null;
        }
    }
}
