<div
    class="lg-sidebar__body"
    [class.lg-sidebar__body--expanded]="_visible && _pinned"
    [class.lg-sidebar__body--slideout]="_visible && !_pinned"
>
    <div class="lg-sidebar__top">
        <ng-container *ngFor="let widget of _topWidgets | async">
            <ng-container
                *ngTemplateOutlet="
                    widget.widgetTemplate || buttonTemplate;
                    context: { $implicit: widget }
                "
            ></ng-container>
        </ng-container>
    </div>
    <div class="lg-sidebar__bottom">
        <ng-container *ngFor="let widget of _bottomWidgets | async">
            <ng-container
                *ngTemplateOutlet="
                    widget.widgetTemplate || buttonTemplate;
                    context: { $implicit: widget }
                "
            ></ng-container>
        </ng-container>
    </div>
    <ng-template #buttonTemplate let-button>
        <div
            class="lg-sidebar-btn {{ button.buttonClass }}"
            (click)="_toggle(button)"
            [class.lg-sidebar-btn--active]="button === _selectedButton"
            [class.lg-sidebar-btn--always-visible]="button.nameAlwaysVisible"
            [class.lg-sidebar-btn--disabled]="button.disabled"
        >
            <lg-icon
                *ngIf="button.icon"
                [icon]="button.icon"
                [size]="button.large ? 'medium' : 'regular'"
                class="lg-sidebar-btn__icon"
            ></lg-icon>
            <div
                *ngIf="button.imageClass"
                class="{{ button.imageClass }} lg-sidebar-btn__image"
            ></div>
            <div class="lg-sidebar-btn__name {{ button.nameClass }}">
                {{ button.nameLc | lgTranslate }}
            </div>
            <div class="lg-sidebar-btn__badge" *ngIf="button.badge > 0">
                {{ _clamp(button.badge) }}
            </div>
        </div>
    </ng-template>
</div>
<div
    class="lg-sidebar-panel__underlay"
    *ngIf="!_pinned && _selectedButton != null"
    (click)="_toggle(_selectedButton)"
></div>
<lg-sidebar-panel-holder
    #holder
    [sidebar]="this"
    [panel]="_selectedButton"
    [pinned]="_pinned"
    (pinClicked)="_togglePin()"
    (hidden)="_panelHidden()"
></lg-sidebar-panel-holder>
