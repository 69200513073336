import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    forwardRef,
    HostBinding,
    HostListener,
    inject,
    Input,
    OnChanges,
    SimpleChanges,
    ViewChild
} from "@angular/core";
import {
    AbstractControl,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator
} from "@angular/forms";

import {
    ILgFormatter,
    ILgFormatterOptions,
    LgFormatterFactoryService
} from "@logex/framework/core";
import { toBoolean } from "@logex/framework/utilities";
import { ValueAccessorBase } from "../value-accessor-base";
import { LgTooltipService, TooltipApi } from "../../../lg-tooltip";

@Component({
    selector: "lg-dynamic-input",
    templateUrl: "./lg-dynamic-input.component.html",
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => LgDynamicInputComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => LgDynamicInputComponent),
            multi: true
        }
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LgDynamicInputComponent
    extends ValueAccessorBase<number | string | null>
    implements OnChanges, Validator
{
    private _formatterFactory = inject(LgFormatterFactoryService);
    private _tooltipService = inject(LgTooltipService);

    /**
     * Input label. No label if not specified.
     */
    @Input() label?: string;

    /**
     * Value formatter type.
     */
    @Input("formatter") formatterType?: string;

    /**
     * Value formatter options.
     */
    @Input("formatterOptions") options?: ILgFormatterOptions;

    /**
     * Specifies text for tooltip if value is invalid.
     */
    @Input()
    set invalidTooltipMessage(value: string | undefined) {
        this._invalidTooltipMessage = value;
        if (this._invalidTooltipMessage) this._setUpErrorTooltip();
        if (!this._invalidTooltipMessage && this._tooltip) {
            this._tooltip.hide();
        }
    }

    get invalidTooltipMessage(): string | undefined {
        return this._invalidTooltipMessage;
    }

    private _disabled = false;
    @Input() set isDisabled(value: boolean | "true" | "false") {
        this._disabled = toBoolean(value);
    }

    get isDisabled(): boolean {
        return this._disabled;
    }

    @ViewChild("inputField") _input?: ElementRef;

    @HostBinding("class") get class(): string {
        return "lg-dynamic-input";
    }

    private _isValidFormat = true;
    private _invalidTooltipMessage: string | undefined = undefined;
    private _formatter: ILgFormatter<number | string> | null = null;
    private _tooltip?: TooltipApi;

    _editing = false;
    _onValidatorChange!: () => void;

    constructor() {
        super(true);
    }

    get _empty(): boolean {
        return this.value == null;
    }

    get _formattedForEditValue(): string | number {
        return this._formatter ? this._formatter.formatForEditing(this.value) : this.value ?? "";
    }

    get _formattedValue(): string | number {
        return this.value != null
            ? this._formatter
                ? this._formatter.format(this.value)
                : this.value
            : this.label ?? "";
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.formatterType || (changes.options && !!this.formatterType)) {
            this._formatter = this._formatterFactory.getFormatter(
                this.formatterType!,
                this.options
            );
        }
        if (changes.isDisabled) {
            this._setDisabledStateInternal(this._disabled);
        }
    }

    _showEditor(event: Event): void {
        if (!this._disabled) {
            event.stopPropagation();
            event.preventDefault();

            this._editing = !this._editing;
        }
    }

    _clearValue(event: Event): void {
        if (!this._disabled) {
            event.stopPropagation();
            event.preventDefault();

            this._inputChanged(null);
        }
    }

    _inputChanged(event: string | null): void {
        if (this._formatter && event) {
            const parseResult = this._formatter.parse(event);
            if (parseResult.result !== this.value) {
                this._isValidFormat = parseResult.isValid;
                this.value = parseResult.result;
            }
        } else {
            this._isValidFormat = true;
            this.value = event;
        }
        this.touch();
    }

    validate(_control: AbstractControl): ValidationErrors | null {
        if (!this._isValidFormat) {
            return { invalidFormat: { invalid: true } };
        }
        return null;
    }

    registerOnValidatorChange?(fn: () => void): void {
        this._onValidatorChange = fn;
    }

    writeValue(value: number): void {
        this._writeValue(value);
    }

    private _setUpErrorTooltip(): void {
        if (!this._tooltip) {
            this._tooltip = this._tooltipService.create({
                content: this._invalidTooltipMessage,
                target: this._elementRef,
                tooltipClass: "lg-tooltip lg-tooltip--simple lg-tooltip--invalid"
            });
        }
    }

    private _setDisabledStateInternal(isDisabled: boolean): void {
        this._editing = false;
        if (isDisabled) {
            this._renderer.addClass(this._elementRef.nativeElement, "dynamic-input--disabled");
        } else {
            this._renderer.removeClass(this._elementRef.nativeElement, "dynamic-input--disabled");
        }
    }

    @HostListener("keydown.enter")
    _onEnter(): void {
        if (this._editing) {
            this._input?.nativeElement.blur();
        }
    }

    @HostListener("mouseenter", ["$event"])
    _onMouseEnter(): void {
        if (this._invalidTooltipMessage && !this._isValidFormat)
            this._tooltip?.show({ content: this._invalidTooltipMessage });
    }

    @HostListener("mouseleave", ["$event"])
    _onMouseLeave(): void {
        if (this._invalidTooltipMessage) this._tooltip?.hide();
    }
}
