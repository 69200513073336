import { ChangeDetectorRef, ElementRef, inject } from "@angular/core";
import { FlexibleConnectedPositionStrategy, Overlay, ScrollDispatcher } from "@angular/cdk/overlay";
import { ComponentPortal } from "@angular/cdk/portal";
import { Observable, of, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { LgTranslateService } from "@logex/framework/lg-localization";

import { IOverlayResultApi, LgOverlayService } from "../../lg-overlay";
import {
    IQuickSettingsMenuPopupOptions,
    LgQuickSettingsMenuPopupComponent
} from "./lg-quick-settings-menu-popup.component";

// Note: cannot have this, because of the constructor parameter
// @Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class LgQuickSettingsMenuHost {
    protected _overlay = inject(Overlay);
    protected _overlayService = inject(LgOverlayService);
    protected _scrollDispatcher = inject(ScrollDispatcher);
    protected _translateService = inject(LgTranslateService);
    protected _changeDetectorRef = inject(ChangeDetectorRef);

    _popupActive = false;

    protected readonly _destroyed$ = new Subject<void>();
    protected _overlayInstance: IOverlayResultApi | null = null;
    protected _popupInstance: LgQuickSettingsMenuPopupComponent | null = null;
    protected _popupHidden$: Subject<void> | null = null;

    constructor(
        // must be passed by implemention class to break circular dependency
        protected _popupComponentClass: new (...args: any[]) => LgQuickSettingsMenuPopupComponent
    ) {}

    _onDestroy(): void {
        this._hideSettingsPopup();
        this._destroyed$.next();
        this._destroyed$.complete();
    }

    _showSettingsPopup(
        element: ElementRef,
        positionStrategy: FlexibleConnectedPositionStrategy,
        hasBackdrop: boolean,
        options: IQuickSettingsMenuPopupOptions
    ): Observable<any> {
        if (this._popupActive) return of(null);

        this._popupHidden$ = new Subject<void>();

        positionStrategy.withScrollableContainers(
            this._scrollDispatcher.getAncestorScrollContainers(element)
        );

        this._overlayInstance = this._overlayService.show({
            onClick: () => this._hideSettingsPopup(), // todo: remove?
            hasBackdrop,
            sourceElement: element,
            positionStrategy,
            onDeactivate: () => {
                if (this._popupInstance) this._popupInstance._isTop = false;
            },
            onActivate: () => {
                if (this._popupInstance) this._popupInstance._isTop = true;
            },
            scrollStrategy: this._overlay.scrollStrategies.reposition({ scrollThrottle: 0 })
        });

        const portal = new ComponentPortal<LgQuickSettingsMenuPopupComponent>(
            this._popupComponentClass
        );
        this._popupInstance = this._overlayInstance.overlayRef.attach(portal).instance;

        positionStrategy.positionChanges.pipe(takeUntil(this._popupHidden$)).subscribe(change => {
            this._popupInstance?._updatePosition(change);
        });

        const result$ = this._popupInstance
            .initialize({
                ...options,
                target: element,
                translateService: this._translateService
            })
            .pipe(takeUntil(this._popupHidden$));

        this._popupActive = true;
        this._changeDetectorRef.markForCheck();

        return result$;
    }

    protected _hideSettingsPopup(): void {
        if (!this._popupActive) return;
        this._popupActive = false;
        this._popupHidden$!.next();
        this._popupHidden$!.complete();
        this._popupHidden$ = null;
        this._overlayInstance!.hide();
        this._overlayInstance = null;
        this._popupInstance = null;
        this._changeDetectorRef.markForCheck();
    }
}
