import { inject, Injectable } from "@angular/core";
import { ICustomProperties } from "@microsoft/applicationinsights-web";
import { LgConsole } from "@logex/framework/core";
import { IAppConfiguration, LG_APP_CONFIGURATION, LG_APP_SESSION } from "../application/index";
import { LG_AUTHENTICATION_SERVICE } from "../auth/index";
import {
    ApplicationInsightsCustomData,
    IApplicationInsightsConfiguration
} from "./application-insights-configuration";

@Injectable({ providedIn: "root" })
export class LgBackendApplicationInsightsConfiguration
    implements IApplicationInsightsConfiguration
{
    private _appConfiguration!: IAppConfiguration;
    private _authService = inject(LG_AUTHENTICATION_SERVICE);
    private _session = inject(LG_APP_SESSION);
    private _lgConsole = inject(LgConsole).withSource(
        "Logex.Application.LgBackendApplicationInsightsConfiguration"
    );

    constructor() {
        const appConfiguration = inject(LG_APP_CONFIGURATION);

        appConfiguration.ready.then(() => {
            if (appConfiguration.applicationInsightsInstrumentationKey == null) {
                this._lgConsole.warn("Application Insights instrumentation key is not specified");
            }

            this.instrumentationKey = appConfiguration.applicationInsightsInstrumentationKey ?? "";
            this.correlationHeaderExcludedDomains =
                appConfiguration.applicationInsightsExcludeDomains ?? undefined;
            this._authService.isLoggedIn().then(loggedIn => {
                if (loggedIn) this.userId = this._authService.user?.login;
            });
        });

        this._appConfiguration = appConfiguration;
    }

    autoInit = true;
    instrumentationKey: string | null = null;
    userId: string | undefined;
    correlationHeaderExcludedDomains: string[] | undefined;

    doNotDoAiTracking(): boolean {
        return this.instrumentationKey == null || this.instrumentationKey === "";
    }

    customData(): ICustomProperties {
        return {
            [ApplicationInsightsCustomData.ApplicationInstance]: this._appConfiguration.instance,
            [ApplicationInsightsCustomData.ClientId]: this._session.clientId?.toString(),
            [ApplicationInsightsCustomData.ClientCode]: this._session.client?.code?.toString(),
            [ApplicationInsightsCustomData.Environment]: this._appConfiguration.environment,
            [ApplicationInsightsCustomData.ScenarioId]: this._session.scenarioId?.toString()
        };
    }
}
