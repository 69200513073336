<button
    *ngIf="!pinned"
    class="button button--primary button lg-filters-panel__footer__ok-button"
    (click)="_onHideSideMenu()"
>
    {{ ".Done" | lgTranslate }}
</button>

<div *ngIf="!pinned">
    <a
        href="#"
        class="link lg-filters-panel__footer__link-button"
        (click)="_onClearFiltersAndHideSideMenu($event)"
    >
        {{ (_isAnyFilterActive ? ".Clear_and_close" : ".Close") | lgTranslate }}
    </a>
</div>

<div *ngIf="pinned && _isAnyFilterActive">
    <a
        href="#"
        class="link lg-filters-panel__foter__link-button"
        (click)="_onClearFiltersAndHideSideMenu($event)"
    >
        {{ ".Clear_and_close" | lgTranslate }}
    </a>
</div>
