import { InjectionToken } from "@angular/core";

export const LG_PASTE_DIALOG_CONFIGURATION = new InjectionToken<PasteDialogConfiguration>(
    "lgPasteDialogConfiguration"
);

export interface PasteDialogConfiguration {
    /**
     * if true, null values conversion to zeros will be allowed (default: false)
     */
    allowNullsToZeros: boolean;

    /**
     * if true, skip errors checkbox will be available (default: false)
     */
    allowSkipErrors: boolean;

    /**
     * if true, paste mode selection will be allowed (default: false, paste mode - "update")
     */
    allowPasteMode: boolean;

    /**
     * if true, null values will be converted to zeros (default: false)
     */
    nullsToZeros: boolean;

    /**
     * if true, errors will be skipped (default: false)
     */
    skipErrors: boolean;

    /**
     * "update" update only pasted values, "replace" update pasted and remove absent values (default: "update")
     */
    pasteMode: "replace" | "update";
}

export interface ICopyColumnInfo {
    field: string;
    name: string;
}

export type ICopyColumnInfoLayered = ICopyColumnInfo &
    (
        | {
              key: true;
          }
        | {
              key: false;
              parentKeyColumn?: string;
          }
    );

export interface IPasteResult {
    updatedData: any[];
    newData: any[];
    deletedData: any[];
}

export type IPasteButtonColumnInfo = ICopyColumnInfoLayered & {
    type: string;
    format?: string;
    unique?: boolean;
    optional?: string | boolean;
    validateKey?: (data: IDataCell, column: IPasteButtonColumnInfo) => void;
};

export interface ICopyLayer {
    name: string;
    columns: string[];
    onGetData?: () => any[];
}

export enum DialogStateEnum {
    Initial = 0,
    Pasted = 1,
    Converted = 2,
    Done = 3
}

export interface IColumnSelectorEntry {
    id: string | null;
    name?: string;
    displayName: string;
    required: boolean;
}

export interface IDataCell {
    originalValue?: any;
    rawValue: any;
    value: any;
    valid: boolean;
    error?: string;
    matched: boolean;
}

export type IPreprocessDataCallback = (
    columns: IPasteButtonColumnInfo[],
    data: IDataCell[][]
) => IDataCell[][];

export type UnmatchedHandlingType =
    | "ignore" // silently remove them
    | "allow" // silently allow them
    | "refuse" // refuse the paste
    | "confirm" // ask for confirmation
    | "warn"; // allow, but notify the user
