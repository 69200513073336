<div *ngIf="_differenceTypes.length" class="lg-menu-popup__group">
    <div class="lg-menu-popup__group__title">
        {{ ".Unit_title" | lgTranslate }}
    </div>
    <div
        *ngFor="let diffType of _differenceTypes"
        class="lg-menu-popup__item"
        [class.lg-menu-popup__item--active]="_currentDifferenceTypeId === diffType.id"
        tabindex="0"
        (click)="_onDifferenceTypeSelect(diffType.id)"
        (keyup.enter)="_onDifferenceTypeSelect(diffType.id)"
    >
        {{ diffType.name || diffType.nameLc | lgTranslate }}
    </div>
</div>
<div *ngIf="_showComparisonTypeSwitch" class="lg-menu-popup__group lg-menu-popup--leftIcons">
    <div *ngIf="_differenceTypes.length" class="lg-menu-popup__group__title">
        {{ ".Difference_title" | lgTranslate }}
    </div>
    <div
        class="lg-menu-popup__item lg-menu-popup__item--left-icon"
        [class.lg-menu-popup__item--active]="_isComparsionTypeActive('relative')"
        tabindex="0"
        (click)="_onComparisonTypeSelect('relative')"
        (keyup.enter)="_onComparisonTypeSelect('relative')"
    >
        <lg-icon
            icon="icon-relativefigures"
            class="lg-menu-popup__item__icon"
            [inline]="true"
        ></lg-icon>
        <span>
            {{ ".Relative_comparison" | lgTranslate }}
        </span>
    </div>
    <div
        class="lg-menu-popup__item lg-menu-popup__item--left-icon"
        [class.lg-menu-popup__item--active]="_isComparsionTypeActive('absolute')"
        tabindex="0"
        (click)="_onComparisonTypeSelect('absolute')"
        (keyup.enter)="_onComparisonTypeSelect('absolute')"
    >
        <lg-icon
            icon="icon-absolutefigures"
            class="lg-menu-popup__item__icon"
            [inline]="true"
        ></lg-icon>
        <span>
            {{ ".Absolute_comparison" | lgTranslate }}
        </span>
    </div>
</div>
<div class="lg-menu-popup__separator"></div>
<form [formGroup]="_form" class="lg-menu-popup__group" (ngSubmit)="_onSubmit()">
    <div class="lg-menu-popup__group__title">
        {{ ".Comparison_title" | lgTranslate }}
    </div>
    <div
        *ngFor="let source of _sources$ | async"
        class="lg-menu-popup__item mi-comparison-source-menu-popup__radio-row"
    >
        <div class="mi-comparison-source-menu-popup__radio-row__radios-wrap">
            <span class="lg-radio-button">
                <input
                    [id]="'left-' + source.id"
                    type="radio"
                    class="lg-radio-button__hidden"
                    formControlName="firstComparate"
                    [value]="source.id"
                    tabindex="-1"
                />
                <span
                    class="lg-radio-button__visible"
                    (click)="_onFakeRadioSelect('left-', source.id)"
                    (keyup.enter)="_onFakeRadioSelect('left-', source.id)"
                    tabindex="0"
                >
                    <span class="lg-radio-button__visible__number">1</span>
                </span>
            </span>
            <span class="lg-radio-button">
                <input
                    [id]="'right-' + source.id"
                    type="radio"
                    class="lg-radio-button__hidden"
                    formControlName="secondComparate"
                    [value]="source.id"
                    tabindex="-1"
                />
                <span
                    class="lg-radio-button__visible"
                    (click)="_onFakeRadioSelect('right-', source.id)"
                    (keyup.enter)="_onFakeRadioSelect('right-', source.id)"
                    tabindex="0"
                >
                    <span class="lg-radio-button__visible__number">2</span>
                </span>
            </span>
        </div>
        {{ source.name }}
    </div>
    <div class="mi-comparison-source-menu-popup__button-row">
        <button class="button button--condensed button--primary">
            {{ ".Confirm_button" | lgTranslate }}
        </button>
    </div>
</form>
