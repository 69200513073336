import { LgI18nService } from "./lg-i18n.service";

export function lgI18nLanguageProviderFactory(service: LgI18nService): string {
    return service.settings.preferredLanguage;
}

export function lgI18nLocaleProviderFactory(service: LgI18nService): string {
    return service.settings.locale;
}

export function lgI18nCurrencyProviderFactory(service: LgI18nService): string {
    return service.settings.currency;
}
