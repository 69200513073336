<div class="lg-scenario-dialog-with-pivot__panel" *ngIf="_config.showRecentlyOpened">
    <div class="lg-scenario-dialog-with-pivot__panel-title">
        <lg-icon [icon]="'icon-recent'" class="icon__color-grey lg-icon--inline"></lg-icon
        ><span class="strong-text">{{ ".RecentlyOpenedScenarios" | lgTranslate }}</span>
    </div>
    {{ _scenarioDescription }}
</div>
<h2>{{ ".AvailableScenarios" | lgTranslate }}</h2>
<div
    class="lg-scenario-dialog-with-pivot__panel lg-scenario-dialog-with-pivot__panel--in-pivot flexcol"
>
    <lg-rectangle
        #theRect
        class="flexcol"
        [lgColDefinition]="columns"
        [lgColDefinitionWidth]="700"
        [ngStyle]="{
            'background-color': 'white',
            height: '300px'
        }"
    >
        <lg-col-definition #columns columnClass="table__column">
            <lg-col-row id="header">
                <ng-container *ngIf="_showDataVersionLevel">
                    <lg-col id="name" width="140"></lg-col>
                </ng-container>
                <lg-col id="scenario" width="*"></lg-col>
                <lg-col id="id" class="right-align" width="20"></lg-col>
                <lg-col id="primary" type="icons"></lg-col>
                <lg-col id="locked" type="icons"></lg-col>
                <lg-col id="quickSettings" type="icons"></lg-col>
            </lg-col-row>
            <ng-container *ngIf="_showDataVersionLevel">
                <lg-col-row id="dataVersionName">
                    <lg-col id="expand0" type="expand"></lg-col>
                    <lg-col id="name" class="crop table__column--level" width="*"></lg-col>
                </lg-col-row>
            </ng-container>
            <lg-col-row id="scenario">
                <lg-col id="empty0" type="empty"></lg-col>
                <lg-col id="name" class="crop table__column--level" width="*"></lg-col>
                <lg-col id="id" class="right-align" width="20"></lg-col>
                <lg-col id="primary" type="icons"></lg-col>
                <lg-col id="locked" type="icons"></lg-col>
                <lg-col id="quickSettings" type="icons"></lg-col>
            </lg-col-row>
        </lg-col-definition>
        <lg-pivot-table
            [definition]="
                _showDataVersionLevel
                    ? _scenarioDialogPivot.definition
                    : _scenarioDialogSimplePivot.definition
            "
            [source]="_scenarioDialogPivotNodes"
            [stretchable]="true"
            [(data)]="pivotData"
            #table
        >
            <lg-pivot-table-header *lgPivotTableHeaderDef lgColRow="header">
                <ng-container *ngIf="_showDataVersionLevel">
                    <div lgCol="name">
                        <lg-pivot-table-expand [level]="0"></lg-pivot-table-expand
                        ><span>{{ ".ScenarioGroupTitle" | lgTranslate }}</span>
                    </div>
                </ng-container>
                <div lgCol="scenario">
                    <span>{{ ".ScenarioNameTitle" | lgTranslate }}</span>
                </div>
                <div lgCol="id">
                    <span lgPivotTableSortByColumn="id">{{
                        ".ScenarioIdTitle" | lgTranslate
                    }}</span>
                </div>
                <div lgCol="primary"><span></span></div>
                <div lgCol="locked"><span></span></div>
                <div lgCol="quickSettings"><span></span></div>
            </lg-pivot-table-header>
            <ng-container *ngIf="_showDataVersionLevel">
                <lg-pivot-table-row
                    *lgPivotTableRowDef="let dataVersionName; onLevel: 'dataVersionName'"
                    class="table__row clickable"
                    lgColRow="dataVersionName"
                >
                    <div lgCol="expand0">
                        <lg-pivot-table-expand expand="dataVersionName"></lg-pivot-table-expand>
                    </div>
                    <div lgCol="name" colspan="3">{{ dataVersionName.data_version_full_name }}</div>
                </lg-pivot-table-row>
            </ng-container>
            <lg-pivot-table-row
                *lgPivotTableRowDef="let scenario; onLevel: 'scenario'"
                class="table__row clickable"
                lgColRow="scenario"
                (click)="_openScenario(scenario.id)"
                (hover)="_hoverPivotLine($event, scenario.id)"
            >
                <ng-container *ngIf="_showDataVersionLevel">
                    <lg-pivot-table-empty-column
                        lgCol="empty0"
                        [level]="0"
                    ></lg-pivot-table-empty-column>
                </ng-container>
                <div lgCol="name">{{ scenario.scenario_naam }}</div>
                <div lgCol="id">{{ scenario.id }}</div>
                <div lgCol="primary">
                    <lg-hoverable-icon
                        *ngIf="
                            scenario.allowPrimary && _hoveredScenario === scenario.id;
                            else disabledStatusIconPrimary
                        "
                        type="highlighted"
                        [icon]="scenario.is_primary ? 'icon-star' : 'icon-empty-star'"
                        hoverIcon="icon-star"
                        [lgTooltip]="scenario.is_primary ? null : ('.MakePrimary' | lgTranslate)"
                        lgTooltipPosition="top-left"
                        (click)="_makePrimary($event, scenario.id, scenario.is_primary)"
                    ></lg-hoverable-icon>
                    <ng-template #disabledStatusIconPrimary>
                        <lg-icon
                            *ngIf="scenario.is_primary"
                            [icon]="'icon-star'"
                            type="disabled"
                            [clickable]="false"
                        ></lg-icon>
                    </ng-template>
                </div>
                <div lgCol="locked">
                    <lg-hoverable-icon
                        *ngIf="
                            scenario.allowLock && _hoveredScenario === scenario.id;
                            else disabledStatusIconLocked
                        "
                        [icon]="scenario.locked ? 'icon-lock' : 'icon-unlock'"
                        [hoverIcon]="!scenario.locked ? 'icon-lock' : 'icon-unlock'"
                        [type]="'highlighted'"
                        [lgTooltip]="(scenario.locked ? '.Unlock' : '.Lock') | lgTranslate"
                        lgTooltipPosition="top-left"
                        (click)="_lockVersion($event, scenario.id, scenario.locked)"
                    ></lg-hoverable-icon>
                    <ng-template #disabledStatusIconLocked>
                        <lg-icon
                            *ngIf="scenario.locked"
                            [icon]="'icon-lock'"
                            type="disabled"
                            [clickable]="false"
                        ></lg-icon>
                    </ng-template>
                </div>
                <div lgCol="quickSettings">
                    <lg-pivot-table-quick-menu
                        [definition]="scenario.quickSettingsMenu"
                        [disabled]="!scenario.quickSettingsMenu?.length"
                        icon="icon-dots"
                        type="highlighted"
                    ></lg-pivot-table-quick-menu>
                </div>
            </lg-pivot-table-row>
        </lg-pivot-table>
    </lg-rectangle>
</div>
<div class="form-group form-group--buttons">
    <div class="form-row">
        <div class="form-group--buttons form-row--button-left">
            <lg-button
                *ngIf="_allowGoToScenarioManagement"
                (click)="_goToScenarioManagement()"
                textLc=".GoToScenarioManagement"
            ></lg-button>
            <lg-button
                *ngIf="_showCustomButton"
                (click)="_customButtonClick()"
                [text]="_config.customButtonText"
                [textLc]="_config.customButtonTextLc"
            ></lg-button>
        </div>
        <lg-button (click)="_dialogRef.close()" textLc=".Cancel"></lg-button>
    </div>
</div>
