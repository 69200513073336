import { inject, Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";
import { IAppConfiguration, LG_APP_CONFIGURATION } from "../application/index";
import { IMessageBusService, LG_MESSAGE_BUS_SERVICE } from "../network/index";

@Injectable()
export class LgMessageBusConnectionIdInterceptor implements HttpInterceptor {
    private _appConfiguration: IAppConfiguration = inject(LG_APP_CONFIGURATION);
    private _messageBus: IMessageBusService = inject(LG_MESSAGE_BUS_SERVICE);

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (
            this._appConfiguration?.applicationRoot != null &&
            req.url.startsWith(this._appConfiguration.applicationRoot)
        ) {
            const connectionId = this._messageBus.getConnectionId();
            if (connectionId != null) {
                let headers = req.headers;
                headers = headers.set("lg-connection-id", connectionId);
                req = req.clone({
                    headers
                });
            }
        }

        return next.handle(req);
    }
}
