import {
    Directive,
    Input,
    Renderer2,
    ElementRef,
    OnChanges,
    OnDestroy,
    InjectionToken,
    inject
} from "@angular/core";
import { Subscription } from "rxjs";

import { toInteger } from "@logex/framework/utilities";
import { LgColRowDirective } from "./lg-col-row.directive";

export const MarkElementAsSorted = new InjectionToken<boolean>("MarkElementAsSorted");
/**
 * This directive, used as attribute, defines which column settings should be used for the current element. This currently means taking 3 parameters from the definition:
 *   classes, as defined using the class or type parameters of the column definition
 *   column classes, as defined by the column-class parameter of the row definition (or overriden by lg-col-row)
 *   width, as defined or calculated by the definition
 * Nothing else is touched (specifically, the paddings are not set explicitly)
 *
 * @example
<div lgCol="costs" />
<div lgCol="header.costs" [lgColSpan]="2" />
 *
 * Parameters:
 *   lgCol: defines the ID of the column. It is possible to use both row and column id (second example) to override. The specified row is searched first, so if it actually contained 
 *     column named "header.costs", it would be used. Avoid dots in column IDs, I guess
 *   lgColSpan (optional): defines the colspan of the column. The classes of the specified column ID are used, but the widths will span multiple columns. Because width is not the
 *      only parameter set by this directive, it is possible (and useful) to have negative colspan! Then it refers to columns to the left of the current one. 
 * The parameters are interpolated and it should be possible to change them at runtime.
 * Note: when removing the column settings (because interpolated ID has changed?), we unregister all the classes associated with the columns, even if they were set on the element manually!
 *
 * Note: the directive detects the presence of the lg-pivot-table-sort-by-column and sort-by-column directives, and modifies the width calculation accordingly. It was considered
 *    moving this to the definition instead of relying on knowledge of the sort directives, but that would prevent header columns to refer the same column definition in both sorted
 *    and unsorted variants
 *
 * TODO: consider allowing to use as element
 * TODO: consider adding lg-col-width-modify to allow explicitly modifying the width (adding a value)
 */
@Directive({
    selector: "[lgCol]"
})
export class LgColDirective implements OnChanges, OnDestroy {
    private _elementRef = inject(ElementRef);
    private _renderer = inject(Renderer2);
    private _row = inject(LgColRowDirective);

    @Input("lgCol") public id?: string;

    @Input("lgColSpan") public set colSpan(val: number | string) {
        this._colSpan = toInteger(val);
    }

    get colSpan(): number {
        return this._colSpan;
    }

    // ---------------------------------------------------------------------------------------------s
    private _colSpan = 1;
    private _subscription: Subscription;
    private _lastClasses: string[] | null = null;

    constructor() {
        this._subscription = this._row.onChange().subscribe(() => {
            this._reassign();
        });
    }

    // ---------------------------------------------------------------------------------------------
    public ngOnChanges(): void {
        this._reassign();
    }

    // ---------------------------------------------------------------------------------------------
    public ngOnDestroy(): void {
        this._subscription.unsubscribe();
    }

    // ---------------------------------------------------------------------------------------------
    private _reassign(): void {
        if (this._lastClasses) {
            for (const cls of this._lastClasses) {
                this._renderer.removeClass(this._elementRef.nativeElement, cls);
            }
            this._lastClasses = null;
        }

        if (!this.id) return;

        const params = this._row.getColumnParameters(this.id, this._colSpan);

        if (params) {
            this._lastClasses = [...params.columnClasses, ...params.rowColumnClasses];
            for (const cls of this._lastClasses) {
                this._renderer.addClass(this._elementRef.nativeElement, cls);
            }

            /*
            if ( this._sortByCol !== null ) {
                params.width -= params.last ? 14 : ( 12 - ( params.paddingRight - 2 ) );
            }
            */

            this._renderer.setStyle(this._elementRef.nativeElement, "width", params.width + "px");
        }
    }
}
