import {
    Directive,
    forwardRef,
    HostListener,
    inject,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges
} from "@angular/core";
import {
    AbstractControl,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator
} from "@angular/forms";
import {
    ILgFormatter,
    ILgFormatterOptions,
    LgFormatterFactoryService
} from "@logex/framework/core";

import { ValueAccessorBase } from "../value-accessor-base";

@Directive({
    standalone: true,
    selector: "input[lgFormatter][ngModel], input[lgFormatter][formControlName]",
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => LgFormatterDirective),
            multi: true
        },
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => LgFormatterDirective), multi: true }
    ]
})
export class LgFormatterDirective
    extends ValueAccessorBase<number | null>
    implements Validator, OnInit, OnChanges
{
    private _formatterFactory = inject(LgFormatterFactoryService);

    /**
     * Formatter type.
     *
     * @default "NONE"
     */
    @Input("lgFormatter") type = "NONE";

    /**
     * Formatter options definition.
     */
    @Input("lgFormatterOptions") options?: ILgFormatterOptions;

    private _formatter!: ILgFormatter<any>;
    private _focused = false;
    private _valid = false;

    constructor() {
        super();
    }

    ngOnInit(): void {
        this._focused = false;

        this._initializeFormatter();
    }

    ngOnChanges(changes: SimpleChanges): void {
        let isFormattingRequired = false;

        if ("type" in changes && changes.type.currentValue !== changes.type.previousValue) {
            this._initializeFormatter();
            isFormattingRequired = true;
        } else if ("options" in changes) {
            isFormattingRequired = true;
        }

        if (isFormattingRequired) {
            if (this._focused) {
                this._formatForEditing();
            } else {
                this._formatValue();
            }
        }
    }

    validate(c: AbstractControl): ValidationErrors | null {
        // todo: is this the right way to approach it?
        return this.type === "text"
            ? null
            : !this._valid || (c.value != null && isNaN(c.value))
              ? { lgFormatter: { valid: false } }
              : null;
    }

    @HostListener("input", ["$event.target.value"])
    public _input(value: string): void {
        if (value != null && value !== "") {
            const parseResult = this._formatter.parse(value, this.options);
            this._valid = parseResult.isValid;
            this.value = parseResult.result;
        } else {
            this._valid = true;
            this.value = null;
        }
    }

    @HostListener("blur")
    public _blur(): void {
        this._focused = false;
        this._formatValue();
    }

    @HostListener("focus")
    public _focus(): void {
        this._focused = true;
        this._formatForEditing();
    }

    writeValue(value: number): void {
        if (value == null) {
            this._renderer.setProperty(this._elementRef.nativeElement, "value", "");
        } else {
            const elemValue = this._focused
                ? this._formatter.formatForEditing(value, this.options)
                : this._formatter.format(value, this.options);
            this._renderer.setProperty(this._elementRef.nativeElement, "value", elemValue);
        }
        // TODO: is this correct? But otherwise undoOnEsc is broken // Rob
        this._valid = true;

        this._writeValue(value);
    }

    setDisabledState(isDisabled: boolean): void {
        this._renderer.setProperty(this._elementRef.nativeElement, "disabled", isDisabled);
    }

    private _initializeFormatter(): void {
        this._formatter = this._formatterFactory.getFormatter(this.type, this.options);
    }

    private _formatValue(): void {
        if (this._valid && this.value != null && !isNaN(this.value)) {
            const formatted = this._formatter.format(this.value, this.options);
            this._renderer.setProperty(this._elementRef.nativeElement, "value", formatted);
        }
        this.touch();
    }

    private _formatForEditing(): void {
        if (this._valid) {
            const formatted = this._formatter.formatForEditing(this.value);

            if (formatted !== this._elementRef.nativeElement.value) {
                this._renderer.setProperty(this._elementRef.nativeElement, "value", formatted);
            }
        }
    }
}
