import { Injectable, inject } from "@angular/core";
import { LgConsole } from "@logex/framework/core";

import { INormalizedLogexPivotDefinition } from "./lg-pivot.types";
import { LogexPivotService } from "./lg-pivot.service";
import { LgPivotInstance } from "./lg-pivot-instance";
import { PivotProviderBase } from "./PivotDefinitionBase";

/**
 * Factory for [[PivotInstance]] objects that help to simplify the use of a pivot instance.
 * [[create]] method allows for specifying pivot provider name and types of pivot rows.
 *
 * Usage:
 * ```typescript
 * import { MyPivot, MyPivotLevel1, MyPivotTotals } from "./pivot";
 * ...
 * constructor(
 *     pivotInstanceFactory: PivotInstanceFactory,
 * ) {
 *     this._pivot = pivotInstanceFactory.create<MyPivotLevel1, MyPivotTotal>( MyPivot, this );
 * }
 * ```
 */
@Injectable({ providedIn: "root" })
export class LgPivotInstanceFactory {
    private _lgConsole = inject(LgConsole).withSource("Logex.Application.PivotInstanceFactory");
    private _pivotService = inject(LogexPivotService);

    /**
     * Creates an instance of [[PivotInstanceManager]] for given pivot.
     *
     * @typeparam TTopRow The type of the pivot's top level
     * @typeparam TTotals Type of the pivot top-level totals object
     * @param pivotProvider
     * @param context Execution context, i.e. the page component.
     */
    create<TTopRow, TTotals>(
        pivotProvider: PivotProviderBase | INormalizedLogexPivotDefinition,
        context: any
    ): LgPivotInstance<TTopRow, TTotals> {
        this._lgConsole.debug("Creating pivot instance");

        let definition: INormalizedLogexPivotDefinition;
        if (pivotProvider.definition != null) {
            definition = pivotProvider.definition;
        } else {
            definition = pivotProvider as INormalizedLogexPivotDefinition;
        }

        return new LgPivotInstance<TTopRow, TTotals>(
            definition,
            context,
            this._pivotService,
            this._lgConsole
        );
    }
}
