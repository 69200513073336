import {
    Directive,
    Input,
    OnDestroy,
    AfterContentChecked,
    DoCheck,
    isDevMode,
    AfterViewInit
} from "@angular/core";
import { Subscription, Observable, ReplaySubject } from "rxjs";

import { LgColDefinitionPrepared, LgColDefinitionInstantiated } from "../services";
import { toInteger } from "@logex/framework/utilities";
import { LgColDefinitionComponent } from "./lg-col-definition.component";

@Directive({
    selector: "[lgColDefinition]"
})
export class LgColDefinitionDirective
    implements OnDestroy, AfterContentChecked, DoCheck, AfterViewInit
{
    @Input({ alias: "lgColDefinition", required: true }) set source(val: LgColDefinitionComponent) {
        if (this._sourceSubscription) {
            this._sourceSubscription.unsubscribe();
            this._sourceSubscription = null;
            this._updateDefinition(null);
        }
        this._source = val;
        if (this._source) {
            if (isDevMode()) {
                if (!(val instanceof LgColDefinitionComponent)) {
                    console.error(
                        "lgColDefinition: expected parameter of type LgColDefinitionComponent, got ",
                        val
                    );
                    return;
                }
            }
            this._sourceSubscription = this._source.definition().subscribe(def => {
                this._updateDefinition(def);
            });
        }
    }

    get source(): LgColDefinitionComponent | undefined {
        return this._source;
    }

    @Input("lgColDefinitionWidth") public set rowWidth(val: number | string) {
        const newWidth = toInteger(val);
        if (newWidth !== this._rowWidth) {
            this._rowWidth = newWidth;
            this._markDirty();
        }
    }

    get rowWidth(): number | null {
        return this._rowWidth;
    }

    // ---------------------------------------------------------------------------------------------
    public instantiatedDefinition(): Observable<LgColDefinitionInstantiated> {
        return this._instantiated$.asObservable();
    }

    // ---------------------------------------------------------------------------------------------
    private _source: LgColDefinitionComponent | undefined = undefined;
    private _rowWidth: number | null = null;
    private _sourceSubscription: Subscription | null = null;
    private _definition: LgColDefinitionPrepared | null = null;
    private _instantiated: LgColDefinitionInstantiated | null = null;
    private _sourceChangeSubscription: Subscription | null = null;
    private _dirty = false;
    private _contentChecked = false;
    private readonly _instantiated$ = new ReplaySubject<LgColDefinitionInstantiated>();

    // ---------------------------------------------------------------------------------------------
    public ngDoCheck(): void {
        this._contentChecked = false;
    }

    // ---------------------------------------------------------------------------------------------
    public ngAfterContentChecked(): void {
        this._contentChecked = true;
    }

    // ---------------------------------------------------------------------------------------------
    public ngAfterViewInit(): void {
        this._contentChecked = true;
    }

    // ---------------------------------------------------------------------------------------------
    public ngOnDestroy(): void {
        this._updateDefinition(null);

        if (this._sourceSubscription) {
            this._sourceSubscription.unsubscribe();
            this._sourceSubscription = null;
        }

        this._instantiated$.complete();
    }

    // ---------------------------------------------------------------------------------------------
    private _updateDefinition(definition: LgColDefinitionPrepared | null): void {
        if (this._sourceChangeSubscription) {
            this._sourceChangeSubscription.unsubscribe();
            this._sourceChangeSubscription = null;
        }

        this._definition = definition;

        if (this._definition) {
            this._requestInstantiation();

            this._sourceChangeSubscription = this._definition.onChange().subscribe(() => {
                this._requestInstantiation();
            });
        }
    }

    // ---------------------------------------------------------------------------------------------
    private _requestInstantiation(): void {
        if (this._contentChecked) {
            this._instantiateDefinition();
            if (this._instantiated) this._instantiated$.next(this._instantiated);
        } else {
            this._markDirty();
        }
    }

    // ---------------------------------------------------------------------------------------------
    private _markDirty(): void {
        if (this._dirty) return;

        this._dirty = true;
        Promise.resolve().then(() => {
            this._dirty = false;
            if (this._definition) {
                this._instantiateDefinition();
                this._instantiated$.next(this._instantiated!);
            }
        });
    }

    // ---------------------------------------------------------------------------------------------
    private _instantiateDefinition(): void {
        this._instantiated = this._definition!.instantiate(this._rowWidth);
    }
}
