import { ILookup } from "@logex/framework/types";

/**
 * Build a lookup for the specified item, based on one their property (which should be string or number).
 * No special handling is done for duplicate keys (the last item will be stored in the lookup)
 *
 * @param array is the input array of items to assign
 * @param keyName is the name of the property to lookup
 * @returns lookup based on the value of the specified property
 */
export function buildLookup<T extends Record<string, any> = any>(
    array: Array<T | null | undefined> | null | undefined,
    keyName: string
): ILookup<T> {
    const result: ILookup<T> = {};
    if (array == null) return result;

    for (let i = 0, l = array.length; i < l; ++i) {
        const element = array[i];
        if (element != null) {
            result[element[keyName]] = element;
        }
    }
    return result;
}
