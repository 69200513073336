export function dropdownFlat<
    TIdField extends keyof TEntry,
    TNameField extends keyof TEntry,
    TEntry extends Record<TIdField | TNameField, any>
>(args: {
    entryId: TIdField;
    entryName: TNameField;
    entries: TEntry[];
}): any /* : IDropdownDefinition<TEntry[TIdField]> prevent cross-module imports */ {
    return {
        entryId: args.entryId as string,
        entryName: args.entryName as string,
        groups: [
            {
                entries: args.entries
            }
        ]
    };
}
