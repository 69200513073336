import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    Output,
    ViewEncapsulation,
    inject
} from "@angular/core";
import { LgTranslateService } from "@logex/framework/lg-localization";

import { IDropdownDefinition } from "../lg-dropdown";
import { LgComboButtonDropdownComponent } from "./lg-combo-button-dropdown.component";
import { LgIconComponent } from "../lg-icon/lg-icon.component";
import { NgIf } from "@angular/common";

@Component({
    standalone: true,
    selector: "lg-combo-button",
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    // eslint-disable-next-line @angular-eslint/component-max-inline-declarations
    template: `
        <button
            type="button"
            [disabled]="bothDisabled || buttonDisabled"
            [class]="'button lg-combo-button__main-button'"
            [style.margin-right.px]="2"
            (click)="_onButtonClick()"
        >
            <lg-icon *ngIf="icon" [icon]="icon" [inline]="true"></lg-icon>
            {{ _localizedText }}
        </button>
        <lg-combo-button-dropdown
            [disabled]="bothDisabled || dropdownDisabled"
            [definition]="dropdownDefinition"
            [eraseSelectedOptionAfterEmit]="eraseSelectedOptionAfterEmit"
            [showPopupAbove]="showPopupAbove"
            (select)="_onOptionSelect($event)"
        >
        </lg-combo-button-dropdown>
    `,
    imports: [LgComboButtonDropdownComponent, LgIconComponent, NgIf]
})
export class LgComboButtonComponent<T extends number | string> {
    private _lgTranslateService = inject(LgTranslateService);

    /**
     * Css class to be applied to component host.
     *
     * @default ""
     */
    @Input("class") hostClass = "";

    /**
     * Specifies if component is disabled (both labeled button and dropdown one)
     */
    @Input("disabled") bothDisabled = false;

    /**
     * Button label.
     */
    @Input() set text(value: string) {
        this._localizedText = value;
    }

    get text(): string {
        return this._localizedText;
    }

    /**
     * Localized button label. If not specifies `text` input is used.
     */
    // eslint-disable-next-line accessor-pairs
    @Input() set textLc(value: string) {
        this._localizedText = this._lgTranslateService.translate(value);
    }

    /**
     * Labeled button icon. No icon if not specified.
     */
    @Input() icon?: string;

    /**
     * Specifies if labeled button is disabled.
     */
    @Input() buttonDisabled = false;

    /**
     * Dropdown definition.
     */
    @Input({ required: true }) dropdownDefinition!: IDropdownDefinition<T>;

    /**
     * Specifies if dropdown button is disabled.
     */
    @Input() dropdownDisabled = false;

    /**
     * Specifies if selected option should be erased after emit.
     *
     * @default true
     */
    @Input() eraseSelectedOptionAfterEmit = true;

    /**
     * Specifies if dropdown should be shown above button.
     *
     * @default true
     */
    @Input() showPopupAbove = true;

    // eslint-disable-next-line @angular-eslint/no-output-on-prefix, @angular-eslint/no-output-native
    @Output("onClick") readonly click = new EventEmitter();
    // eslint-disable-next-line @angular-eslint/no-output-native
    @Output("select") readonly select = new EventEmitter<T>();

    @HostBinding("class") get _hostClass(): string {
        return `lg-combo-button ${this.hostClass}`;
    }

    _localizedText = "";

    _onButtonClick(): void {
        this.click.emit();
    }

    _onOptionSelect(id: T): void {
        this.select.emit(id);
    }
}
