import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { toBoolean } from "@logex/framework/utilities";

import {
    ExportDefinition as ExportEntryDefinition,
    ExportType,
    SidebarExportFormats
} from "./lg-sidebar-export.types";
import { Observable } from "rxjs";

@Component({
    selector: "lg-export-entry",
    template: "",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LgExportEntryComponent {
    /**
     * Entry identifier (required).
     */
    @Input({ required: true }) set id(value: string) {
        this._id = value;
    }

    get id(): string {
        return this._id;
    }

    /**
     * Entry name, as shown to the user (won’t be localized automatically) (required).
     */
    @Input({ required: true }) set name(value: string) {
        this._name = value;
    }

    get name(): string {
        return this._name;
    }

    /**
     * Export type (required).
     *
     * @description
     * Specifies whether the export happens on client or in the server.
     *
     * @type {"client" | "server"}
     */
    @Input({ required: true }) set type(value: ExportType) {
        this._type = value;
    }

    get type(): ExportType {
        return this._type;
    }

    /**
     * Download URL, relevant and required for server exports.
     */
    @Input() set url(value: string) {
        this._url = value;
    }

    get url(): string {
        return this._url ?? "";
    }

    /**
     * @description: params to be sent as part of POST request, e.g. {hospitalId: 1}
     */
    @Input() set params(value: object) {
        this._params = value;
    }

    get params(): object {
        return this._params;
    }

    /**
     * @description: callback function that returns params object, takes precedence over 'params' input
     */
    @Input() set paramsCallback(value: () => object | Promise<any> | Observable<any>) {
        this._paramsCallback = value;
    }

    get paramsCallback(): undefined | (() => object | Promise<any> | Observable<any>) {
        return this._paramsCallback;
    }

    @Input() set visible(value: boolean | "true" | "false") {
        this._visible = toBoolean(value, true);
    }

    get visible(): boolean {
        return this._visible;
    }

    @Input() set enabled(value: boolean | "true" | "false") {
        this._enabled = toBoolean(value, true);
    }

    get enabled(): boolean {
        return this._enabled;
    }

    @Input() format?: SidebarExportFormats;

    @Output("callback") readonly callback = new EventEmitter<string>();

    private _id = "";
    private _name = "";
    private _type: ExportType = "client";
    private _url?: string;
    private _params: object = {};
    private _paramsCallback?: () => object | Promise<object> | Observable<object>;
    private _visible = true;
    private _enabled = true;

    getDefinition(): ExportEntryDefinition {
        return {
            id: this._id,
            name: this._name,
            type: this._type,
            url: this._url,
            callback: this.callback,
            params: this._params,
            paramsCallback: this._paramsCallback,
            visible: toBoolean(this._visible, true),
            enabled: toBoolean(this._enabled, true),
            format: this.format
        };
    }
}
