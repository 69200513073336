import { Pipe, PipeTransform, inject } from "@angular/core";
import {
    ILgFormatter,
    ILgFormatterOptions,
    MoneyScaledFormatterFactory
} from "@logex/framework/core";
import { Nullable } from "@logex/framework/types";

export type IFmtMoneyScaledOptions = Partial<
    Pick<
        ILgFormatterOptions,
        "decimals" | "forceSign" | "zeroDash" | "viewScale" | "hideScaleSymbol"
    >
>;

const defaultOptions: ILgFormatterOptions = {
    decimals: 0
};

@Pipe({
    standalone: true,
    name: "fmtMoneyScaled",
    pure: true
})
export class LgFormatMoneyScaledPipe implements PipeTransform {
    private _formatter: ILgFormatter<number> = inject(MoneyScaledFormatterFactory).create(
        defaultOptions
    );

    public transform(input: Nullable<string | number>, options?: IFmtMoneyScaledOptions): string;

    /**
     * @deprecated Use options variant instead
     */
    public transform(
        value: Nullable<string | number>,
        viewScale?: number,
        forceSign?: boolean,
        zeroDash?: boolean,
        hideScaleSymbol?: boolean
    ): string;

    public transform(
        value: Nullable<string | number>,
        options?: number | IFmtMoneyScaledOptions,
        forceSign?: boolean,
        zeroDash?: boolean,
        hideScaleSymbol?: boolean
    ): string {
        const parameterOptions = this._getParameterOptions(
            options,
            forceSign,
            zeroDash,
            hideScaleSymbol
        );

        if (typeof value === "string") {
            value = +value;
        }
        return this._formatter.format(value, parameterOptions);
    }

    private _getParameterOptions(
        options?: number | IFmtMoneyScaledOptions,
        forceSign?: boolean,
        zeroDash?: boolean,
        hideScaleSymbol?: boolean
    ): ILgFormatterOptions {
        let parameterOptions: ILgFormatterOptions;
        if (typeof options === "number") {
            parameterOptions = { viewScale: options };
        } else {
            parameterOptions = { ...options };
        }

        if (forceSign !== undefined) {
            parameterOptions.forceSign = forceSign;
        }
        if (zeroDash !== undefined) {
            parameterOptions.zeroDash = zeroDash;
        }
        if (hideScaleSymbol !== undefined) {
            parameterOptions.hideScaleSymbol = hideScaleSymbol;
        }

        return parameterOptions;
    }
}
