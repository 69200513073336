import { InjectionToken } from "@angular/core";

export const LG_LANGUAGE_ID = new InjectionToken<string>("LG_LANGUAGE_ID");

// ISO 4217 alpha code
export const LG_CURRENCY_CODE = new InjectionToken<string>("LG_CURRENCY_CODE");

export type CurrencySymbolPosition = "before" | "after";

export interface LgCurrencyMetadata {
    symbol: string;
    separator: string;
    removeRegex: RegExp;
    position: CurrencySymbolPosition;
}
