import { InjectionToken } from "@angular/core";

export const LG_APP_INFO = new InjectionToken<IAppInfo>("lgAppInfo");

export interface IAppInfo {
    environment: string;
    fullAppName: string;
    productId: string;
    toolInstanceName: string;
    versionNumber: string;
    overrideCurrencyPrefix: string;
    overrideCurrencySuffix: string;

    doNotDoGaTracking(): boolean;
    isProduction(): boolean;
}

/** Generic configuration served to the framework  */
export class AppInfo implements IAppInfo {
    environment = "";
    fullAppName = "";
    productId = "";
    toolInstanceName = "";
    versionNumber = "";
    overrideCurrencyPrefix = "";
    overrideCurrencySuffix = "";

    doNotDoGaTracking(): boolean {
        return true; // in reality might be connected to f.ex "localhost" domain
    }

    isProduction(): boolean {
        return this.environment?.toLowerCase() === "production";
    }
}
