import { lgProvide, LgProvider, LgProviders } from "@logex/framework/types";
import { LOCALE_ID, Provider } from "@angular/core";
import {
    LG_LOCALIZATION_SETTINGS,
    LgLocalizationSettings
} from "../translate/lg-localization.types";
import { LgLocalizationSettingsWithGlobals } from "../translate/lg-localization-settings-with-globals";
import { LG_CURRENCY_CODE, LG_LANGUAGE_ID } from "@logex/framework/core";
import {
    LG_LOCALIZATION_SOURCE_URLS,
    LG_LOCALIZATION_URL_PREFIX,
    LG_TRANSLATE_STARTUP_SERVICE_ACTIVE,
    LocalizationSourceCallback
} from "../translate/lg-translate-startup.service";

export interface LgLocalizationConfig {
    /**
     * Provides currency code (token `LG_CURRENCY_CODE`).
     * Optional. Factory `lgI18nCurrencyProviderFactory` is used by default in `LgLocalizationModule`.
     */
    currencyCode?: LgProvider<string>;
    /**
     * Provides language id (token `LG_LANGUAGE_ID`).
     * Optional. Factory `lgI18nLanguageProviderFactory` is used by default in `LgLocalizationModule`.
     */
    languageId?: LgProvider<string>;
    /**
     * Provides locale id (token `LOCALE_ID`).
     * Optional. Factory `lgI18nLocaleProviderFactory` is used by default in `LgLocalizationModule`.
     */
    localeId?: LgProvider<string>;
    /**
     * Provides localization settings (token `LG_LOCALIZATION_SETTINGS`).
     * Optional. Class `LgLocalizationSettingsWithGlobals` is used by default in `LgLocalizationModule`.
     */
    localizationSettings?: LgProvider<LgLocalizationSettings>;
    /**
     * Provides language source urls (token `LG_LOCALIZATION_SOURCE_URLS`).
     * Optional. No default value.
     */
    localizationSourceUrls?: LgProvider<LocalizationSourceCallback[]>;
    /**
     * Provides prefix (or domain in case of absolute urls) for default language source url (token `LG_LOCALIZATION_URL_PREFIX`).
     * Optional. Value "dist/localization" is used by default in `LgLocalizationModule`.
     */
    localizationUrlPrefix?: LgProvider<string>;
    /**
     * Provides flag to activate translate startup service (token `LG_TRANSLATE_STARTUP_SERVICE_ACTIVE`).
     * Optional. Value `false` is used by default in `LgLocalizationModule`.
     */
    translateStartupServiceActive?: LgProvider<boolean>;
}

/**
 * Helper utility to configure and provide framework `LgLocalizationModule` providers
 */
export const lgLocalizationProviders: LgProviders<LgLocalizationConfig> = (
    config?: LgLocalizationConfig
): Provider[] => {
    const providers: Provider[] = [
        lgProvide(
            LG_LOCALIZATION_SETTINGS,
            config?.localizationSettings ?? { useClass: LgLocalizationSettingsWithGlobals }
        )
    ];
    if (config?.currencyCode) {
        providers.push(lgProvide(LG_CURRENCY_CODE, config.currencyCode));
    }
    if (config?.languageId) {
        providers.push(lgProvide(LG_LANGUAGE_ID, config.languageId));
    }
    if (config?.localeId) {
        providers.push(lgProvide(LOCALE_ID, config.localeId));
    }
    if (config?.localizationUrlPrefix) {
        providers.push(lgProvide(LG_LOCALIZATION_URL_PREFIX, config.localizationUrlPrefix));
    }
    if (config?.localizationSourceUrls) {
        providers.push(lgProvide(LG_LOCALIZATION_SOURCE_URLS, config.localizationSourceUrls));
    }
    if (config?.translateStartupServiceActive) {
        providers.push(
            lgProvide(LG_TRANSLATE_STARTUP_SERVICE_ACTIVE, config.translateStartupServiceActive)
        );
    }
    return providers;
};
