import { inject, Injectable, OnDestroy } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { Observable, Subject } from "rxjs";

import {
    ApplicationTraceSeverity,
    IApplicationEventTracer,
    LgApplicationEventTracerNames
} from "@logex/framework/core";
import { loadScript } from "@logex/framework/utilities";

import { LG_APP_INFO } from "../application/app-info";
import { LG_USER_INFO } from "../user/user.types";
import {
    IMatomoConfiguration,
    IMatomoDimension,
    IMatomoPostponeConfiguration,
    LG_MATOMO_CONFIGURATION
} from "./matomo-configuration";
import { filter, first, takeUntil } from "rxjs/operators";

/**
 * Access to the global window variable.
 */
declare let window: {
    [key: string]: any;
    prototype: Window;
    new (): Window;
}; // todo: I've copypasted this from another file. Maybe make it accessible from types?

function isFullConfiguration(
    config: IMatomoConfiguration | IMatomoPostponeConfiguration
): config is IMatomoConfiguration {
    return "siteId" in config;
}

@Injectable({ providedIn: "root" })
export class LgMatTrackingService implements OnDestroy, IApplicationEventTracer {
    private _appInfo = inject(LG_APP_INFO, { optional: true });
    private _initialMatomoConfiguration = inject(LG_MATOMO_CONFIGURATION);
    private _router = inject(Router);
    private _user = inject(LG_USER_INFO, { optional: true });

    readonly tracerName = LgApplicationEventTracerNames.Matomo;
    private _isInited = false;
    private currentUrl = "";
    private readonly _destroyed$ = new Subject<void>();
    private readonly _navigation$: Observable<NavigationEnd>;
    private _navigated = false;
    private _matomoConfiguration!: IMatomoConfiguration;

    constructor() {
        if (typeof window._paq === "undefined") {
            window._paq = window._paq || [];
        }

        this._navigation$ = this._router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            takeUntil(this._destroyed$)
        ) as any; // we know it's NavigationEnd

        this._navigation$.pipe(first()).subscribe(() => {
            this._navigated = true;
            if (this._initialMatomoConfiguration.autoInit && !this._isInited) this.init();
        });
    }

    // ----------------------------------------------------------------------------------
    //
    ngOnDestroy(): void {
        this._destroyed$.next();
        this._destroyed$.complete();
    }

    init(finalConfiguration?: IMatomoConfiguration): void {
        if (finalConfiguration) {
            this._matomoConfiguration = finalConfiguration;
        } else if (isFullConfiguration(this._initialMatomoConfiguration)) {
            this._matomoConfiguration = this._initialMatomoConfiguration;
        } else {
            throw new Error("Incomplete matomo configuration provided");
        }

        const url = this._matomoConfiguration.statUrl;
        const siteId = this._matomoConfiguration.siteId;

        if (!url || !siteId || this._matomoConfiguration.doNotDoMatTracking()) {
            return;
        }

        this._isInited = true;

        window._paq.push(["enableLinkTracking"]);
        window._paq.push(["setTrackerUrl", url + "matomo.php"]);
        window._paq.push(["setSiteId", siteId]);
        window._paq.push(["enableHeartBeatTimer"]);

        // https://developer.matomo.org/guides/tracking-javascript-guide#measuring-domains-andor-sub-domains
        // https://matomo.org/faq/how-to/faq_23654/
        if (this._matomoConfiguration.cookieDomain) {
            window._paq.push(["setCookieDomain", this._matomoConfiguration.cookieDomain]);
        }
        if (this._matomoConfiguration.siteDomains?.length) {
            window._paq.push(["setDomains", this._matomoConfiguration.siteDomains]);
        }
        if (this._matomoConfiguration.enableCrossDomainLinking) {
            if (!this._matomoConfiguration.siteDomains?.length) {
                console.error("Matomo cross domain linking requires siteDomains to be set");
            }
            window._paq.push(["enableCrossDomainLinking"]);
        }

        loadScript(url + "matomo.js", false);

        if (this._user) {
            if (!this._matomoConfiguration.useUserEmail && (this._user.userid ?? this._user.id)) {
                this.setUserID((this._user.userid ?? this._user.id)!);
            } else if (this._matomoConfiguration.useUserEmail && this._user.login) {
                this.setUserID(this._user.login);
            }
        }

        this._setCommonDimensions();
        this._setCustomDimensions();

        if (this._matomoConfiguration.autoTrack) {
            if (this._navigated) this.pageChange();
            this._navigation$.subscribe(() => this.pageChange());
        }
    }

    /**
     * Logs a visit to this page.
     *
     * @param [customTitle] Optional title of the visited page.
     */
    pageChange(customTitle?: string): void {
        if (!this._isInited) return;

        const args: string[] = [];
        if (customTitle) {
            args.push(customTitle);
        }
        window._paq.push(["setReferrerUrl", this.currentUrl]);
        this.currentUrl = this._router.url;
        window._paq.push(["setCustomUrl", this.currentUrl]);

        window._paq.push(["trackPageView", ...args]);
    }

    /**
     * Logs an event with an event category, an event action, an optional event name and optional numeric value.
     *
     * @param category Category of the event.
     * @param action Action of the event.
     * @param [name] Optional name of the event.
     * @param [value] Optional value for the event.
     */
    trackEvent(category: string, action: string, label?: string, value?: number): void {
        if (!this._isInited) return;

        const args: Array<string | number> = [category, action];
        if (label) {
            args.push(label);
        }
        if (typeof value === "number") {
            args.push(value);
        }
        window._paq.push(["trackEvent", ...args]);
    }

    // ----------------------------------------------------------------------------------
    //
    trackTime(category: string, variable: string, value: number, _label?: string): void {
        if (!this._isInited) return;

        window._paq.push(["trackEvent", "Elapsed time", category, variable, value]);
    }

    // ----------------------------------------------------------------------------------
    //
    trackTrace(
        severity: ApplicationTraceSeverity,
        message: string,
        _customProperties?: unknown
    ): void {
        this.trackEvent("Track", "Trace", `${ApplicationTraceSeverity[severity]}: ${message}`);
    }

    // ----------------------------------------------------------------------------------
    //
    trackException(exception: Error, _customProperties?: unknown): void {
        this.trackEvent("Track", "Exception", `${exception.name}: ${exception.message}`);
    }

    /**
     * Sets a User Id.<br />
     * (Should be called before pageChange(customTitle?: string): void to be executed.)
     *
     * @param userId unique ID of user.
     */
    setUserID(userId: number | string): void {
        window._paq.push(["setUserId", userId.toString()]);
    }

    /**
     * Reset a User Id.<br />
     * (Should be called before pageChange(customTitle?: string): void to be executed.)
     */
    resetUserID(): void {
        window._paq.push(["resetUserId"]);
    }

    /**
     * Handles user logout. Tracks new visit.<br />
     */
    onUserLogout(): void {
        // User has just logged out, we reset the User ID
        window._paq.push(["resetUserId"]);

        // we also force a new visit to be created for the pageviews after logout
        window._paq.push(["appendToTrackingUrl", "new_visit=1"]);

        window._paq.push(["trackPageView"]);

        // we finally make sure to not again create a new visit afterwards (important for Single Page Applications)
        window._paq.push(["appendToTrackingUrl", ""]);
    }

    /**
     * Sets a custom dimension.<br />
     * (Should be called before pageChange(customTitle?: string): void to be executed.)
     *
     * @param customDimensionId ID of the custom dimension to set.
     * @param customDimensionValue Value to be set.
     */
    setCustomDimension(customDimensionId: number, customDimensionValue: string | number): void {
        if (!this._isInited) return;

        window._paq.push(["setCustomDimension", customDimensionId, customDimensionValue]);
    }

    /**
     * Deletes a custom dimension.<br />
     * (Should be called before pageChange(customTitle?: string): void to be executed.)
     *
     * @param customDimensionId ID of the custom dimension to delete.
     */
    deleteCustomDimension(customDimensionId: number): void {
        if (!this._isInited) return;

        window._paq.push(["deleteCustomDimension", customDimensionId]);
    }

    /**
     * Adds a click listener to a specific link element.<br />
     * When clicked, Matomo will log the click automatically.
     *
     * @param element Element on which to add a click listener.
     */
    addListener(element: Element): void {
        if (!this._isInited) return;

        window._paq.push(["addListener", element]);
    }

    private _setCommonDimensions(): void {
        if (
            this._matomoConfiguration.commonDimensionIds.organizationLogexDataCode != null &&
            this._user &&
            this._user.ziekenhuiscode
        ) {
            this.setCustomDimension(
                this._matomoConfiguration.commonDimensionIds.organizationLogexDataCode,
                this._user && this._user.ziekenhuiscode
            );
        }

        if (this._appInfo) {
            if (this._matomoConfiguration.commonDimensionIds.applicationId != null) {
                this.setCustomDimension(
                    this._matomoConfiguration.commonDimensionIds.applicationId,
                    this._appInfo.productId
                );
            }
            if (this._matomoConfiguration.commonDimensionIds.applicationInstanceName != null) {
                this.setCustomDimension(
                    this._matomoConfiguration.commonDimensionIds.applicationInstanceName,
                    this._appInfo.toolInstanceName
                );
            }
            if (this._matomoConfiguration.commonDimensionIds.versionNumber != null) {
                this.setCustomDimension(
                    this._matomoConfiguration.commonDimensionIds.versionNumber,
                    this._appInfo.versionNumber
                );
            }
        }
        if (
            this._matomoConfiguration.commonDimensionIds.registry != null &&
            this._matomoConfiguration.registry
        ) {
            this.setCustomDimension(
                this._matomoConfiguration.commonDimensionIds.registry,
                this._matomoConfiguration.registry
            );
        }
    }

    private _setCustomDimensions(): void {
        if (this._matomoConfiguration.customDimensions) {
            if (typeof this._matomoConfiguration.customDimensions === "function") {
                const customDimensions: IMatomoDimension[] =
                    this._matomoConfiguration.customDimensions();
                if (customDimensions) {
                    customDimensions.forEach(customDimension => {
                        this.setCustomDimension(customDimension.id, customDimension.value);
                    });
                }
            } else {
                this._matomoConfiguration.customDimensions.forEach(customDimension => {
                    this.setCustomDimension(customDimension.id, customDimension.value);
                });
            }
        }
    }
}
