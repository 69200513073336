import { Directive, ElementRef, HostListener, Input, inject } from "@angular/core";

@Directive({
    standalone: true,
    selector: "[lgNextInputOnEnter]"
})
export class LgNextInputOnEnterDirective {
    private _el = inject(ElementRef);

    /**
     * Specifies if next input should be focused on press enter.
     *
     * @default true
     */
    @Input("lgNextInputOnEnter") set nextInputOnEnter(condition: boolean | "") {
        this._enabled = condition !== false;
    }

    get nextInputOnEnter(): boolean {
        return this._enabled;
    }

    private _enabled = true;

    @HostListener("keyup.enter", ["$event"])
    onClick($event: MouseEvent): void {
        $event.preventDefault();

        if (!this._enabled) {
            return;
        }

        const inputs = document.getElementsByTagName("input");

        if (inputs) {
            const inputsAsArray = Array.from(inputs);
            const index = inputsAsArray.indexOf(this._el.nativeElement);

            if (index + 1 < inputsAsArray.length) {
                inputsAsArray[index + 1].focus();
            } else {
                this._el.nativeElement.blur();
            }
        }
    }
}
