import ldIsArray from "lodash-es/isArray";

/**
 * Expand the data packed by data wrapper's ResultToPackedArray method.
 *
 * @param data packed rows
 * @returns unpacked rows
 */
export function expandPackedRows<TSource extends any[] | null | undefined>(
    data: TSource,
    rename?: Record<string, string>
): TSource extends any[] ? any[] : TSource {
    if (data == null) return data as any;
    if (!ldIsArray(data)) return null as never;

    const result: any = [];
    if (!data[0]) {
        return result;
    }

    let lookup: string[] = data[0];
    if (rename) {
        lookup = lookup.map(column => rename[column] || column);
    }

    let previous: any = {};
    const columns = lookup.length;
    for (let i = 1, l = data.length; i < l; ++i) {
        const row = data[i];
        const current: any = {};
        for (let j = 0; j < columns; ++j) {
            const name = lookup[j];
            let val = row[j];
            if (val === "^") val = previous[name];
            current[name] = val;
        }
        result.push(current);
        previous = current;
    }
    return result;
}
