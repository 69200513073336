import {
    Component,
    Input,
    Output,
    EventEmitter,
    ViewEncapsulation,
    ChangeDetectionStrategy,
    DoCheck,
    ChangeDetectorRef,
    HostBinding,
    HostListener,
    inject
} from "@angular/core";

import { toInteger } from "@logex/framework/utilities";

import { LgPivotTableBodyComponent } from "./lg-pivot-table-body.component";
import { LgPivotTableRowDirective } from "./templates/lg-pivot-table-row.directive";
import { LgPivotTableLevelExpander } from "./types";

@Component({
    standalone: false,
    selector: "lg-pivot-table-expand",
    template: ` <lg-icon [icon]="_expanded ? _iconExpanded : _iconCollapsed"></lg-icon> `,
    host: {
        class: "lg-pivot-table-expand"
    },
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LgPivotTableExpandComponent implements DoCheck {
    private _body = inject(LgPivotTableBodyComponent, { optional: true });
    private _changeDetectorRef = inject(ChangeDetectorRef);
    private _expander = inject(LgPivotTableLevelExpander, { optional: true });
    private _row = inject(LgPivotTableRowDirective, { optional: true });

    /**
     * Row level.
     *
     * @default null
     */
    @Input()
    set level(value: number) {
        this._level = toInteger(value);
        this._expandLevel = true;
        this._iconExpanded = "icon-pivot-minus";
        this._iconCollapsed = "icon-pivot-plus";
    }

    get level(): number | null {
        return this._level;
    }

    /**
     * Data item.
     */
    @Input()
    set item(value: any) {
        this._item = value;
    }

    get item(): any {
        return this._item;
    }

    /**
     * Specifies if highlight expanded row of not.
     *
     * @default false
     */
    @Input()
    @HostBinding("class.lg-pivot-table-expand--highlight")
    highlight = false;

    // eslint-disable-next-line @angular-eslint/no-output-on-prefix, @angular-eslint/no-output-native
    @Output("toggle") readonly onToggle = new EventEmitter<boolean>();

    private _level: number | null = null;
    private _item: any | null = null;

    @HostBinding("class.lg-pivot-table-expand--expanded")
    _expanded = false;

    @HostBinding("class.lg-pivot-table-expand--level")
    _expandLevel = false;

    _iconExpanded = "icon-arrow-down";
    _iconCollapsed = "icon-arrow-right";

    // todo: make it work inside a header. Make both dependencies optional

    ngDoCheck(): void {
        if (!this._expander) {
            console.error("LgPivotTableExpand with level should be used inside pivot body");
            return;
        }
        const last = this._expanded;
        if (this._level !== null) {
            this._expanded = this._expander.getAllExpanded(this._level) ?? false;
        } else if (this._item !== null) {
            this._expanded = this._item.$expanded;
        } else if (this._row) {
            try {
                this._expanded = this._row.context.$implicit.$expanded;
            } catch (e) {}
        }

        if (last !== this._expanded) this._changeDetectorRef.markForCheck();
    }

    @HostListener("click", ["$event"])
    toggle($event: MouseEvent): boolean {
        if ($event) {
            $event.stopPropagation();
            $event.preventDefault();
        }

        if (this._level !== null) {
            this._expander?.toggleLevel(this._level, !this._expanded);
        } else if (this._item !== null) {
            if (this._body) {
                this._body.toggleExpand(this._item);
            } else {
                this._item.$expanded = !this._item.$expanded;
            }
        } else {
            this._body?.toggleExpand(this._row?.context.$implicit);
        }
        return false;
    }
}
