import ldIsNumber from "lodash-es/isNumber";
import { NgIterable } from "@angular/core";
import {
    LgVirtualForOfPrecomputed,
    LgVirtualForOfItemHeightCallback,
    IVirtualForOfCache
} from "./lg-virtual-for-of.types";

/**
 * The dynamic cache is used when itemHeight is a function.
 * Whenever there is any change in the cache, we need to recalculate it as every item can have different height.
 * Dynamic cache is always recalculated
 */
export class LgVirtualForOfDynamicCache<T> implements IVirtualForOfCache<T> {
    private _cache: Array<LgVirtualForOfPrecomputed<T>> = [];
    private _itemHeightFn: LgVirtualForOfItemHeightCallback<T>;

    get length(): number {
        return this._cache.length;
    }

    constructor(itemHeight: LgVirtualForOfItemHeightCallback<T>) {
        this._itemHeightFn = itemHeight;
    }

    updateCache(source: NgIterable<T>): void {
        this._createCache(source);
    }

    private _createCache(source: NgIterable<T>): void {
        this._cache = [];
        let cumulativeHeight = 0;
        let index = 0;
        for (const value of source) {
            const height = this._itemHeightFn(value);
            cumulativeHeight += height;
            const item = <LgVirtualForOfPrecomputed<T>>{
                value,
                height,
                startPosition: cumulativeHeight - height,
                endPosition: cumulativeHeight,
                index
            };
            index++;
            this._cache.push(item);
        }
    }

    getContentHeight(): number {
        return this._cache.length === 0 ? 0 : this._cache[this._cache.length - 1].endPosition;
    }

    getYPositionByIndex(index: number, isAbove: boolean): number {
        if (isAbove) return this._cache[index]?.startPosition ?? 0;
        return this._cache[index + 1]?.startPosition ?? this.getContentHeight();
    }

    getEnsureVisibleIndex(ensureVisible: number | T): number | undefined {
        if (ldIsNumber(ensureVisible)) return ensureVisible;
        return this._cache?.find(i => ensureVisible === i.value)?.index ?? undefined;
    }

    getIndexFromPosition(yPosition: number, isAbove?: boolean): number {
        if (isAbove) {
            for (const item of this._cache) {
                if (item.startPosition >= yPosition) return Math.max(0, item.index);
            }
        } else {
            for (const item of this._cache) {
                if (item.endPosition >= yPosition) return Math.max(0, item.index - 1);
            }
        }
        return Math.max(0, this._cache.length - 1);
    }
}
