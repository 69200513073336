import ldSortBy from "lodash-es/sortBy";
import ldUniqBy from "lodash-es/uniqBy";

/* eslint-disable @typescript-eslint/no-this-alias */
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    inject,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    Output,
    Renderer2,
    ViewChild
} from "@angular/core";
import * as d3 from "d3";
import { BaseChartComponent } from "../shared/base-chart.component";
import { LgSimpleChanges } from "@logex/framework/types";
import { IStackedBarHorizontalChartTooltipContext } from "./lg-stacked-bar-horizontal-chart.types";
import {
    CHART_SEPARATOR_SIZE,
    LegendItem,
    LegendOptions,
    LgGroupLabel,
    Margin
} from "../shared/chart.types";
import { getDefaultLegendOptions } from "../shared/getDefaultLegendOptions";
import { coerceBooleanProperty, coerceNumberProperty } from "@angular/cdk/coercion";
import { getRecommendedPosition } from "../shared/getRecommendedPosition";
import { getLegendWidth } from "../shared/getLegendWidth";
import {
    IStackedBarChartColumn,
    IStackedBarChartGroup
} from "../lg-stacked-bar-chart/stacked-bar-chart.types";
import { LgColorPalette } from "../shared/lg-color-palette";
import { ITooltipOptions, LgTooltipService, TooltipApi } from "@logex/framework/ui-core";
import { LgColorPaletteV2 } from "../shared/lg-color-palette-v2/lg-color-palette-v2";
import {
    LG_DEFAULT_COLOR_CONFIGURATION,
    LG_USE_NEW_LABELS,
    LgColorsConfiguration
} from "../shared/lg-color-palette-v2/lg-colors.types";
import { IExportableChart, LgChartExportContainerDirective } from "../shared/lg-chart-export";

const SPACE_FOR_LEGEND_BELOW = 28;
const X_AXIS_LABELS_LINE_HEIGHT = 22;
const X_AXIS_TITLE_FROM_AXIS = 18;
const MAX_SPACE_FOR_Y_AXIS_LABELS = 250;
const DEFAULT_TICK_COUNT = 5;
const MARGIN: Required<Margin> = { top: 10, right: 16, bottom: 8, left: 16 };
const DECIMAL_FOR_WHITE_COLOR = 16777215;
const SPACE_BETWEEN_GROUPS = 4;
const SPACE_BETWEEN_GROUP_LABELS_AND_AXIS = 8;
const MINIMUM_BAR_WIDTH = CHART_SEPARATOR_SIZE;

@Component({
    standalone: false,
    selector: "lg-stacked-bar-horizontal-chart",
    templateUrl: "./lg-stacked-bar-horizontal-chart.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LgStackedBarHorizontalChartComponent
    extends BaseChartComponent<IStackedBarChartColumn, IStackedBarHorizontalChartTooltipContext>
    implements OnChanges, OnDestroy, AfterViewInit, IExportableChart
{
    private _colorPalette = inject(LgColorPaletteV2);
    private _exportContainer = inject(LgChartExportContainerDirective, { optional: true });
    private _legacyColorPalette = inject(LgColorPalette);
    private _ngZone = inject(NgZone);
    private _renderer = inject(Renderer2);
    private _regularTooltipService = inject(LgTooltipService);
    private _useNewLabels = inject(LG_USE_NEW_LABELS);

    /**
     * @requires
     * Callback for providing the column name of related data item (required).
     */
    @Input({ required: true }) columnName!: (locals: any) => string;

    /**
     * @optional
     * Callback for providing opacity of group bar column. Valid returned value is number from 0 to 1.
     */
    @Input() columnOpacity?: (locals: number) => number;

    /**
     * @optional
     * Specifies property name of data to get groups data from.
     *
     * @default "groups"
     */
    @Input() groupsProperty!: string;

    /**
     * @optional
     * Specifies property name of data to sort groups by.
     *
     * @default "sorting"
     */
    @Input() groupsOrderByProperty?: string;

    /**
     * @optional
     * Specifies property name of data to get bars data from.
     *
     * @default "bars"
     */
    @Input() barsProperty!: string;

    /**
     * @optional
     * Specifies property name of data to sort groups by.
     *
     * @default "sorting"
     */
    @Input() barsOrderByProperty?: string;

    /**
     * @optional
     * Specifies property name of data to get bar id from.
     */
    @Input() barIdProperty?: string;

    /**
     * @requires
     * Specifies property name of data group to get value from.
     */
    @Input({ required: true }) barValueProperty!: string;

    /**
     * Specifies max charts count of Y-axis labels.
     *
     * @default 30
     */
    @Input() maxCharCountInYLabels = 30;

    /**
     * @deprecated use colorConfiguration instead
     */
    @Input() barColor?: (locals: any) => string[];

    /**
     * @deprecated use colorConfiguration instead
     */
    @Input() barColorProperty?: string;

    /**
     * @optional
     * Callback for providing opacity of specific bar. Valid returned value is number from 0 to 1.
     */
    @Input() barOpacity?: (locals: any) => number;

    /**
     * @optional
     * Specifies highlight type.
     *
     * @type {"bar" | "group"}
     *
     * @default "bar"
     */
    @Input() highlight: "bar" | "group" = "bar";

    /**
     * Specifies maximum number of ticks on axis. Defaults to 5.
     *
     * @default 5
     */
    @Input() tickCount?: number;

    /**
     * @optional
     * Specifies whether Y axis labels are visible or not. Defaults to true.
     *
     * @default true
     */
    @Input() showYAxisLabels = true;

    /**
     * @optional
     * Specifies space for X-axis labels in pixels.
     * Max allowed value is 250.
     */
    @Input() spaceForYAxisLabels = 0;

    /**
     * @optional
     * Specifies space for group labels in pixels.
     * Max allowed value is 250.
     *
     * @default 0
     */
    @Input() spaceForGroupLabels = 0;

    /**
     * group labels settings
     */
    @Input() groupLabels: LgGroupLabel[] = [];

    /**
     * Specifies whether group labels will show extended tooltip or not.
     * Note: the tool must implement the tooltip using groupLabels label, the framework doesn't handle it.
     *
     * @default false
     */
    @Input() showGroupLabelExtendedInfo = false;

    /**
     * Specifies whether group labels will return `completeColumnData` into basic `tooltipContext` or not.
     *
     * @default false
     */
    @Input() showGroupLabelGeneralInfo = false;

    /**
     * Specifies the X-axis title. If not specified then there is no Y-axis title.
     */
    @Input() xAxisLabel?: string;

    /**
     * Specifies whether initial transition animation is turned on or not.
     *
     * @default false
     */
    @Input() initialRenderAsTransition?: boolean;

    /**
     * @deprecated use colorConfiguration instead
     */
    @Input() groupColors?: string | string[];

    /**
     * @optional
     * Specifies the legend options. Legend is visible and right positioned by default.
     */
    @Input() legendOptions: LegendOptions = getDefaultLegendOptions({
        visible: true,
        position: "right"
    });

    /**
     * Specifies the color configuration. Defaults to categorical palette.
     *
     * If specified, allows four different configuration
     * - default/categorical - using 20 predefined colors
     * - sequential by color scheme - using predefined sequence of colors by name
     * - predefined - using predefined dictionary
     * - own - array of hexadecimal values
     *
     * For usage, see New Palette in storybook under LgCharts.
     * Palette story contains all possible colors.
     * Gallery story contains all charts using new colors.
     *
     * Example can be seen in 'getAllChartsProps.ts:62'
     */
    @Input() colorConfiguration: LgColorsConfiguration = LG_DEFAULT_COLOR_CONFIGURATION;

    @ViewChild("chart", { static: true }) private _chartDivRef!: ElementRef;
    @ViewChild("chartWithLegend", { static: true })
    private _chartWithLegendDivRef!: ElementRef<HTMLDivElement>;

    /**
     * Emits data in clicked legend item.
     */
    @Output() readonly legendClick: EventEmitter<LegendItem> = new EventEmitter<LegendItem>();

    _legendDefinition: LegendItem[] = [];
    _legendWidth = 0;
    _legendPaddingBottom = 0;

    private _groupColors!: d3.ScaleOrdinal<string, string>;

    private _columnNames: string[] = [];
    private _barItems: any[] = [];
    private _xMax: number | null = null;
    private _groupsCount: number | null = null;
    private _xScale!: d3.ScaleLinear<number, number>;
    private _xGridScale!: d3.ScaleLinear<number, number>;
    private _yScale!: d3.ScaleBand<any>;
    private _yGroupScale!: d3.ScaleBand<any>;
    private _verticalGridLinesG!: d3.Selection<any, any, any, any>;
    private _bars!: d3.Selection<any, any, any, any>;
    private _xAxisG!: d3.Selection<any, any, any, any>;
    private _yAxisG!: d3.Selection<any, any, any, any>;
    private _yAxisGroupLabelsG!: d3.Selection<any, any, any, any>;
    private _xAxisLabelG!: d3.Selection<any, any, any, any>;

    private _lastMouseX = 0;
    private _lastMouseY = 0;
    private _trackListener!: () => void;
    private _tooltipApi?: TooltipApi;

    private get _svgWidth(): number {
        const legendVisible = this.legendOptions.visible;
        const legendBelow = legendVisible && this.legendOptions.position === "bottom";
        const legendOnTheRight = legendVisible && this.legendOptions.position === "right";

        const legendSize = this._getLegendSize(legendBelow, legendOnTheRight);
        return Math.max(0, this._width - (legendOnTheRight ? legendSize : 0));
    }

    private get _svgHeight(): number {
        const legendVisible = this.legendOptions.visible;
        const legendBelow = legendVisible && this.legendOptions.position === "bottom";
        const legendOnTheRight = legendVisible && this.legendOptions.position === "right";

        const legendSize = this._getLegendSize(legendBelow, legendOnTheRight);
        return Math.max(0, this._height - (legendBelow ? legendSize : 0));
    }

    private get _verticalPositionOfXAxis(): number {
        return this._svgHeight - X_AXIS_LABELS_LINE_HEIGHT;
    }

    constructor() {
        super();
        this._margin = MARGIN;
        this._trackMousePosition();
    }

    ngOnChanges(changes: LgSimpleChanges<LgStackedBarHorizontalChartComponent>): void {
        if (!this._initialized) {
            this._initializeChart();
            return;
        }

        super._onBaseChartChanges(changes);

        let needsRender = false;
        let renderImmediate = false;
        let renderAsTransition = false;
        let wasSizeAlreadyUpdated = false;

        if (changes.data) {
            this._tooltip.hide();
        }

        if (changes.data || changes.columnName || this.legendOptions) {
            this._triggerDataSpecificMethods();
            this._updateSizeToState();
            this._updateSizeAndScales();
            wasSizeAlreadyUpdated = true;
            needsRender = true;
            renderAsTransition = true;
        }

        if (changes.width || changes.height) {
            if (!wasSizeAlreadyUpdated) {
                this._updateSizeToState();
                this._updateSizeAndScales();
            }
            needsRender = true;
            renderImmediate = true;
        }

        if (changes.formatterType || changes.formatterOptions) {
            needsRender = true;
        }

        if (changes.xAxisLabel) {
            if (this._chartDivRef) {
                this._xAxisLabelG.text(changes.xAxisLabel.currentValue);
            }
        }

        if (changes.tickCount) {
            needsRender = true;
        }

        if (needsRender) {
            this._bars.selectAll(".row").remove();
            if (renderAsTransition) {
                d3.transition()
                    .duration(500)
                    .each(() => this._render(false));
            } else {
                this._render(renderImmediate);
            }
        }
    }

    ngAfterViewInit(): void {
        this._exportContainer?.register(this);
    }

    ngOnDestroy(): void {
        this._exportContainer?.unregister(this);
        super._onDestroy();
    }

    getHtmlElement(): HTMLElement {
        return this._chartWithLegendDivRef.nativeElement;
    }

    getSvgElement(): SVGElement {
        return this._svg.node();
    }

    private _initializeChart(): void {
        this._initializeFormatters();
        this._setDefaultProperties();
        this._updateSizeToState();
        this._triggerDataSpecificMethods();

        this._drawMainSvgHolder(this._chartDivRef.nativeElement);
        this._create();
        this._updateSizeAndScales();
        this._render(!this.initialRenderAsTransition);

        this._initializeTooltip();
        this._trackMousePosition();

        this._initialized = true;
    }

    private _setDefaultProperties(): void {
        this.tickCount = this.tickCount || DEFAULT_TICK_COUNT;
        this.spaceForYAxisLabels = this.showYAxisLabels
            ? Math.min(this.spaceForYAxisLabels, MAX_SPACE_FOR_Y_AXIS_LABELS) +
              this.spaceForGroupLabels
            : 0;

        this.groupsProperty = this.groupsProperty || "groups";
        this.barsProperty = this.barsProperty || "bars";
        this.highlight = this.highlight || "bar";
    }

    private _updateSizeToState(): void {
        this._width = Math.max(0, this.width);
        this._height = Math.max(0, this.height);
    }

    private _triggerDataSpecificMethods(): void {
        this._initializeColorScales();
        this._convertData();
        this._initializeColorScales(this._data);
        this._setLegend();
    }

    private _initializeColorScales(data?: IStackedBarChartColumn[]): void {
        if (this._colorPalette.useNewColorPalette) {
            const colors = this._colorPalette.getColorsForType(this.colorConfiguration);
            this._groupColors = d3.scaleOrdinal(colors);
            return;
        }
        this._setLegacyColorScale(data ?? []);
    }

    /**
     * @deprecated
     */
    private _setLegacyColorScale(data: IStackedBarChartColumn[]): void {
        if (!data || !data.length) return;
        const colors: string[] = this.groupColors
            ? this._legacyColorPalette.getPaletteForColors(this.groupColors)
            : this._barItems.map(i => (this.barColor && this.barColor(i)[0]) ?? "");

        this._groupColors = d3.scaleOrdinal(colors);
    }

    private _convertData(): void {
        this._xMax = null;
        this._groupsCount = null;
        this._data = [];
        this._columnNames = [];
        const bars: any[] = [];

        this.data.forEach((column, columnIndex) => {
            const columnName = this.columnName(column);
            this._columnNames.push(columnName);

            const columnData: any = {
                columnName,
                columnIndex,
                groups: []
            };

            if (this.groupsOrderByProperty) {
                column[this.groupsProperty] = ldSortBy(
                    column[this.groupsProperty],
                    this.groupsOrderByProperty
                );
            }

            column[this.groupsProperty].forEach((group: any, groupIndex: number) => {
                const groupData: any = {
                    barData: [],
                    groupIndex
                };

                if (this.barIdProperty) groupData.barId = this.barIdProperty;

                const barData: any[] = [];

                if (this.barsOrderByProperty) {
                    group[this.barsProperty] = ldSortBy(
                        group[this.barsProperty],
                        this.barsOrderByProperty
                    );
                }

                let fromValue = 0;

                group[this.barsProperty].forEach((item: any, barIndex: any) => {
                    const { color, barColorValues } = this._getColors(item);
                    const hoverColor = this._getHoverColor(color, barColorValues);

                    const barObj: any = {
                        item,
                        columnName,
                        columnIndex,
                        groupIndex,
                        barIndex,
                        fromValue,
                        toValue: fromValue + item[this.barValueProperty as keyof any],
                        value: item[this.barValueProperty as keyof any],
                        color,
                        hoverColor,
                        opacity: this.barOpacity ? this.barOpacity(item) : 1
                    };

                    barData.push(barObj);
                    bars.push(barObj.item);

                    fromValue += item[this.barValueProperty as keyof any];
                });

                groupData.barData = barData;
                columnData.groups.push(groupData);

                this._groupsCount =
                    this._groupsCount == null
                        ? groupIndex + 1
                        : Math.max(this._groupsCount, Number(groupIndex + 1));
                this._xMax = this._xMax == null ? fromValue : Math.max(this._xMax, fromValue);
            });

            this._data.push(columnData);
        });

        this._barItems = ldUniqBy(bars, this.barIdProperty ?? "");
    }

    private _getColors(item: any): any {
        return this._colorPalette.useNewColorPalette
            ? this._getNewColors(item)
            : this._getLegacyColors(item);
    }

    private _getNewColors(item: any): any {
        const color = this._groupColors(item[this.barIdProperty ?? ""]);
        const barColorValues = this._colorPalette.getCategoricalPalette();
        return { color, barColorValues };
    }

    /**
     * @deprecated
     */
    private _getLegacyColors(item: any): { color: string; barColorValues: string[] } {
        let color = "#" + Math.floor(Math.random() * DECIMAL_FOR_WHITE_COLOR).toString(16);

        if (this.barColorProperty) {
            color = item[this.barColorProperty];
        }

        const barColorValues = this.barColor && this.barColor(item);
        if (barColorValues && barColorValues.length > 0) {
            color = barColorValues[0];
        }
        return { color, barColorValues: barColorValues ?? [] };
    }

    private _getHoverColor(color: string, barColorValues: any[]): string {
        if (this._colorPalette.useNewColorPalette) return d3.rgb(color).darker(0.2).toString();
        return this._getLegacyHoverColor(color, barColorValues);
    }

    /**
     * @deprecated
     */
    private _getLegacyHoverColor(color: string, barColorValues: any[]): string {
        let hoverColor = d3.rgb(color).darker(0.2).toString();

        if (barColorValues && barColorValues[1]) {
            switch (typeof barColorValues[1]) {
                case "string":
                    hoverColor = barColorValues[1];
                    break;
                case "number":
                    if (barColorValues[1] < 0) {
                        hoverColor = d3
                            .rgb(color)
                            .darker(barColorValues[1] * -1)
                            .toString();
                    } else {
                        hoverColor = d3.rgb(color).brighter(barColorValues[1]).toString();
                    }
                    break;
            }
        }
        return hoverColor;
    }

    private _setLegend(): void {
        const legend: LegendItem[] = [];

        if (!this._columnNames || !this._columnNames.length) {
            this._legendDefinition = legend;
            return;
        }

        this._barItems.forEach((bar, index) => {
            const row = {
                colors: [this._groupColors(bar[this.barIdProperty ?? ""])],
                name: bar[this.barIdProperty ?? ""],
                item: bar
            };

            legend.push({
                color: row.colors[0],
                name: row.name,
                opacity: this.columnOpacity ? this.columnOpacity(index) : 1
            });
        });

        this._legendDefinition = legend;
    }

    private _create(): void {
        this._xScale = d3.scaleLinear();
        this._xGridScale = d3.scaleLinear();
        this._yScale = d3.scaleBand();
        this._yGroupScale = d3.scaleBand<any>();

        this._verticalGridLinesG = this._svgG
            .append("g")
            .attr("class", `${this._useNewLabels ? "y__axis__grid" : "y__axis__grid__legacy"}`);
        this._bars = this._svgG.append("g").attr("class", "bars__group");

        this._xAxisG = this._svgG
            .append("g")
            .attr("class", `${this._useNewLabels ? "x__axis" : "x__axis__legacy"}`);
        this._yAxisG = this._svgG
            .append("g")
            .attr("class", `${this._useNewLabels ? "y__axis" : "y__axis__legacy"}`);

        this._yAxisGroupLabelsG = this._svgG.append("g").attr("class", "y__axis__group__labels");

        this._xAxisLabelG = this._xAxisG
            .append("text")
            .attr("class", "axis__title")
            .attr("x", -this.spaceForYAxisLabels + (this._margin.left ?? 0))
            .attr("y", X_AXIS_TITLE_FROM_AXIS)
            .attr("text-anchor", "start")
            .text(this.xAxisLabel ?? "");
    }

    private _updateSizeAndScales(): void {
        const legendVisible = this.legendOptions.visible;
        const legendBelow = legendVisible && this.legendOptions.position === "bottom";
        const legendOnTheRight = legendVisible && this.legendOptions.position === "right";

        const legendSize = this._getLegendSize(legendBelow, legendOnTheRight);

        const spaceBelowAxis =
            (this._margin.bottom ?? 0) +
            (legendBelow ? legendSize - 6 : 0) +
            X_AXIS_LABELS_LINE_HEIGHT;

        this._legendWidth = legendOnTheRight ? legendSize : 0;
        this._legendPaddingBottom = spaceBelowAxis ? spaceBelowAxis : 0;

        this._svg.attr("width", this._svgWidth).attr("height", this._svgHeight);

        this._xScale
            .range([0, this._svgWidth - this.spaceForYAxisLabels - (this._margin.right ?? 0)])
            .domain([0, this._xMax ?? 0])
            .interpolate(d3.interpolateRound);

        this._xGridScale
            .range([this.spaceForYAxisLabels, this._svgWidth - (this._margin.right ?? 0)])
            .domain([0, this._xMax ?? 0])
            .interpolate(d3.interpolateRound);

        this._yScale
            .range([this._margin.top ?? 0, this._verticalPositionOfXAxis])
            .domain(this._columnNames)
            .round(true);

        const { top = 0, bottom = 0 } = this._margin;
        this._yGroupScale
            .range([0, this._verticalPositionOfXAxis - (top + bottom)])
            .domain(this._columnNames)
            .round(true);

        this._xAxisG.attr(
            "transform",
            `translate(${this.spaceForYAxisLabels}, ${this._verticalPositionOfXAxis})`
        );
        this._yAxisG.attr("transform", `translate(${this.spaceForYAxisLabels}, 0) `);
        this._yAxisGroupLabelsG.attr(
            "transform",
            `translate(${this.spaceForYAxisLabels - SPACE_BETWEEN_GROUP_LABELS_AND_AXIS}, 0) `
        );
        this._bars.attr("transform", `translate(${this.spaceForYAxisLabels}, 0)`);

        this._calculateBarWidths();
    }

    private _calculateBarWidths(): void {
        this._data.forEach(datum => {
            datum.groups.forEach(group => {
                let groupWidth = 0;
                let totalGroupValue = 0;
                group.barData.forEach(bar => {
                    if (bar.value == null) bar.value = 0;
                    const barWidth = this._xScale(bar.value);
                    const barWidthLessThanMinimum = barWidth < MINIMUM_BAR_WIDTH;
                    bar.width = barWidthLessThanMinimum ? MINIMUM_BAR_WIDTH : barWidth;
                    bar.fromWidth = groupWidth;
                    bar.toWidth = bar.fromWidth + bar.width;
                    groupWidth += bar.width + CHART_SEPARATOR_SIZE;
                    totalGroupValue += bar.value;
                });
                const maxGroupWidth = this._xScale(totalGroupValue);
                const adjustedPixels = Math.abs(groupWidth - maxGroupWidth);
                this._adjustBarWidthToFitScale(group, adjustedPixels);
            });
        });
    }

    private _adjustBarWidthToFitScale(group: IStackedBarChartGroup, adjustedPixels: number): void {
        for (let i = adjustedPixels; i > 1; i--) {
            const widestBarIndex = group.barData.reduce((prev, curr) =>
                prev.value > curr.value ? prev : curr
            ).barIndex;
            const widestBar = group.barData[widestBarIndex];
            if ((widestBar.width ?? 0) <= MINIMUM_BAR_WIDTH) return;
            this._updateBarWidthFromIndex(group, widestBarIndex);
        }
    }

    private _updateBarWidthFromIndex(group: IStackedBarChartGroup, index: number): void {
        for (let j = index; j < group.barData.length; j++) {
            const item = group.barData[j];
            if (j === index) {
                item.width!--;
                continue;
            }
            item.fromWidth!--;
            item.toWidth!--;
        }
    }

    private _getLegendSize(below: boolean, onTheRight: boolean): number {
        if (!below && !onTheRight) return 0;

        if (below) {
            return SPACE_FOR_LEGEND_BELOW;
        }

        return getLegendWidth(
            this._width,
            this.legendOptions.widthInPercents ?? 0,
            this._legendDefinition.map(ele => ele.name),
            this.legendOptions.staticWidth
        );
    }

    private _render(immediate = false): void {
        this._renderAxes(immediate);
        this._renderChart(immediate);
    }

    private _renderAxes(immediate: boolean): void {
        let yScale: d3.ScaleBand<any> | null = null;

        if (!coerceBooleanProperty(this.showYAxisLabels)) {
            yScale = d3
                .scaleBand()
                .range([0, this._verticalPositionOfXAxis])
                .padding(0.1)
                .domain([""])
                .round(true);
        }

        const xAxis = this._getXAxis(this._xScale);
        const xAxisGrid = this._getXAxisGrid(this._xGridScale);
        const yAxis = this._getYAxis(yScale || this._yScale);
        const yAxisGroupLabels = this._getYAxisGroupLabels(yScale || this._yScale);

        const self = this;

        if (immediate) {
            this._xAxisG.call(xAxis);
            this._yAxisG.call(yAxis);
            this._yAxisGroupLabelsG.call(yAxisGroupLabels);
            this._renderGroupLabelsContent();
        } else {
            this._yAxisG.transition().call(yAxis);
            this._yAxisGroupLabelsG.transition().call(yAxisGroupLabels);
            this._renderGroupLabelsContent();

            this._xAxisG
                .transition()
                .call(xAxis)
                .on("start", function () {
                    d3.select(this)
                        .selectAll(".tick")
                        .each(function (d, i, all) {
                            const dNum = (d ? +d : 0) as number;
                            if (dNum > (self._xMax ?? 0)) {
                                d3.select(all[i]).transition("disappearing").style("opacity", 0);
                            }
                        });
                });
        }

        this._verticalGridLinesG
            .call(xAxisGrid)
            .attr("transform", `translate(0, ${this._margin.top})`);
    }

    private _getXAxis(scale: d3.ScaleLinear<number, number>): d3.Axis<any> {
        return d3
            .axisBottom(scale)
            .tickSizeOuter(0)
            .tickPadding(0)
            .tickFormat(this._numberFormat as any)
            .ticks(coerceNumberProperty(this.tickCount, 10));
    }

    private _getXAxisGrid(scale: d3.ScaleLinear<number, number>): d3.Axis<any> {
        return d3
            .axisBottom(scale)
            .tickPadding(0)
            .tickFormat(() => "")
            .ticks(coerceNumberProperty(this.tickCount, 10))
            .tickSizeOuter(0)
            .tickSizeInner(this._verticalPositionOfXAxis - 10);
    }

    private _getYAxis(scale: d3.ScaleBand<any>): d3.Axis<any> {
        return d3
            .axisLeft(scale)
            .tickSize(0)
            .tickPadding(10 + this.spaceForGroupLabels)
            .tickFormat(item =>
                item.length > this.maxCharCountInYLabels
                    ? item.substring(0, this.maxCharCountInYLabels - 2) + "..."
                    : item
            );
    }

    private _getYAxisGroupLabels(scale: d3.ScaleBand<any>): d3.Axis<any> {
        return d3
            .axisLeft(scale)
            .tickSize(0)
            .tickPadding(0)
            .tickFormat(() => "");
    }

    private _renderChart(immediate: boolean): void {
        const rows = this._renderRows();
        const groups = this._renderGroups(rows);
        this._renderBars(groups, immediate);
    }

    private _renderRows(): d3.Selection<SVGGElement, IStackedBarChartColumn, any, any> {
        const rows = this._bars.selectAll(".row").data(this._data, (d: any) => d.columnName);

        const rowsEntered = rows.enter().append("g").attr("class", "row");

        rows.exit().remove();
        return rowsEntered;
    }

    private _renderGroups(
        rowsEntered: d3.Selection<SVGGElement, IStackedBarChartColumn, any, any>
    ): d3.Selection<SVGGElement, IStackedBarChartGroup, SVGGElement, IStackedBarChartColumn> {
        const self = this;
        const groups = rowsEntered.selectAll<SVGGElement, any>(".group").data(
            d => d.groups,
            (d: any) => d.barId || d.groupIndex
        );

        const groupsEntered = groups
            .enter()
            .append("g")
            .attr("class", "group")
            .merge(groups)
            .order()
            .on("mouseover", function (_event: MouseEvent, g: any) {
                switch (self.highlight) {
                    case "bar":
                        d3.select(this)
                            .selectAll("rect")
                            .style("fill", (d: any) => d.hoverColor);
                        break;
                    case "group":
                        self._bars
                            .selectAll("rect")
                            .style("fill", (d: any) =>
                                g.groupIndex === d.groupIndex ? d.hoverColor : d.color
                            );
                        break;
                }
            })
            .on("mouseleave", function (_event: MouseEvent) {
                d3.select(this)
                    .selectAll("rect")
                    .style("fill", (d: any) => d.color);
            });

        groups.exit().remove();
        return groupsEntered;
    }

    private _renderBars(
        groupsEntered: d3.Selection<
            SVGGElement,
            IStackedBarChartGroup,
            SVGGElement,
            IStackedBarChartColumn
        >,
        immediate: boolean
    ): void {
        const self = this;
        const bars = groupsEntered
            .selectAll<SVGRectElement, any>("rect")
            .data((d: any) => d.barData);
        let numberOfGroups = 1;
        this._data.forEach(item => {
            if (item.groups?.length > numberOfGroups) numberOfGroups = item.groups.length;
        });

        let barsMerged = bars.enter().append("rect");

        barsMerged
            .attr("class", "bar")
            .attr("height", (this._yGroupScale.bandwidth() * 0.5) / numberOfGroups)
            .attr("x", this._xScale(0))
            .attr("y", (d: any) => {
                const columnPosition = this._yScale(d.columnName) ?? 0;
                const bandwidth = Math.round(this._yGroupScale.bandwidth() * 0.25);
                const position = columnPosition + bandwidth;
                if (numberOfGroups === 1) return position;

                return +d.groupIndex === 0
                    ? columnPosition + (bandwidth - SPACE_BETWEEN_GROUPS / 2)
                    : columnPosition + (2 * bandwidth + SPACE_BETWEEN_GROUPS / 2);
            })
            .attr("width", 0)
            .style("fill", (d: any) => d.color)
            .style("opacity", (d: any) => d.opacity)
            .style("cursor", () => (this.clickable ? "pointer" : "default"))
            .on("mouseover.1", function (_event: MouseEvent, data: any) {
                self.tooltipContext = {
                    data,
                    group: self._data[data.groupIndex as keyof IStackedBarChartColumn[]]
                };
                self._tooltip.hideShow();
                self._updateTooltipPosition();
            })
            .on("mouseleave.1", (_event: MouseEvent) => this._tooltip.scheduleHide())
            .on("click", function (_event: MouseEvent, d) {
                const index = barsMerged.nodes().indexOf(this);
                self._onClick(d, index);
            });

        barsMerged.interrupt();
        barsMerged = immediate ? barsMerged : (barsMerged.transition() as any);

        barsMerged
            .attr("x", (d: any) => {
                return d.fromWidth;
            })
            .attr("width", (d: any) => {
                return d.width;
            })
            .style("opacity", (d: any) => d.opacity)
            .attr("height", (this._yGroupScale.bandwidth() * 0.5) / numberOfGroups);

        bars.exit().remove();
    }

    private _renderGroupLabelsContent(): void {
        this._yAxisGroupLabelsG.selectAll<SVGGElement, any>("foreignObject").remove();
        const self = this;

        this.groupLabels.forEach((groupLabel, i) => {
            const groupLabelsContent = self._yAxisGroupLabelsG
                .selectAll<SVGGElement, any>(".tick")
                .append("svg:foreignObject");

            groupLabelsContent
                .attr("width", this.spaceForGroupLabels)
                .attr("height", this._yGroupScale.bandwidth() * 0.25)
                .attr("x", -this.spaceForGroupLabels)
                .attr("y", () => {
                    const barSize = this._yGroupScale.bandwidth() * 0.25;
                    if (i) return SPACE_BETWEEN_GROUPS / 2;
                    return -barSize - SPACE_BETWEEN_GROUPS / 2;
                })
                .append("xhtml:div")
                .attr("class", "y__axis__group__label")
                .style("height", `${this._yGroupScale.bandwidth() * 0.25}px`)
                .style("line-height", `${this._yGroupScale.bandwidth() * 0.25}px`)
                .html(() => {
                    return groupLabel.shortened;
                })
                .on("mouseover", function (_event: MouseEvent, _rowLabel: string) {
                    if (self.showGroupLabelExtendedInfo) {
                        const tooltipOptions: ITooltipOptions = {
                            content: groupLabel.label,
                            stay: false,
                            offset: 7,
                            arrowOffset: 7,
                            trapFocus: true,
                            target: d3.select(this).node() as HTMLElement,
                            tooltipClass: "lg-tooltip lg-tooltip--simple",
                            delayShow: 0,
                            delayHide: 0
                        };
                        self._tooltipApi = self._regularTooltipService.create(tooltipOptions);
                        self._tooltipApi.show();
                    } else if (self.showGroupLabelGeneralInfo) {
                        self._tooltipContext = {
                            completeColumnData: self._data.find(
                                item => item.columnName === _rowLabel
                            ),
                            group: i
                        };
                        self._tooltip.hideShow();
                        self._updateTooltipPosition();
                    }
                })
                .on("mouseleave", function (_event: MouseEvent) {
                    if (self.showGroupLabelExtendedInfo) {
                        self._tooltipApi?.hide();
                    } else if (self.showGroupLabelGeneralInfo) {
                        self._tooltip.scheduleHide();
                    }
                });
        });
    }

    private _trackMousePosition(): void {
        this._ngZone.runOutsideAngular(() => {
            this._trackListener = this._renderer.listen(
                this._elementRef.nativeElement,
                "mousemove",
                (event: MouseEvent) => {
                    this._lastMouseX = event.clientX;
                    this._lastMouseY = event.clientY;
                    this._updateTooltipPosition();
                }
            );
        });
    }

    private _updateTooltipPosition(): void {
        if (this._tooltip && this._tooltip.visible) {
            if (this._lastMouseX && this._lastMouseY)
                this._tooltip.setPositionAt(
                    this._lastMouseX,
                    this._lastMouseY,
                    getRecommendedPosition(
                        { x: this._lastMouseX, y: this._lastMouseY },
                        this._tooltip.getOverlayElement()
                    )
                );
            else this._tooltip.hide();
        }
    }

    private _onClick(value: any, itemIndex: number): void {
        if (!this.clickable) return;

        this.itemClick.emit({ item: value.item, datum: value, index: itemIndex });
    }

    _onLegendItemClick(item: LegendItem): void {
        this.legendClick.emit(item);
    }
}
