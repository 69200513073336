import { NgModule } from "@angular/core";
import { UiCoreModule } from "@logex/framework/ui-core";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";

import { LgLocalizationModule } from "@logex/framework/lg-localization";

import { LgChatGateway } from "./lg-chat/lg-chat-gateway";
import { LgChatComponent } from "./lg-chat/lg-chat.component";
import { LgChatBubbleComponent } from "./lg-chat-bubble/lg-chat-bubble.component";
import { LgChatWindowComponent } from "./lg-chat-window/lg-chat-window.component";

@NgModule({
    imports: [UiCoreModule, LgLocalizationModule, FormsModule, CommonModule],
    exports: [LgChatComponent],
    declarations: [LgChatComponent, LgChatBubbleComponent, LgChatWindowComponent],
    providers: [LgChatGateway]
})
export class LgChatModule {}
