const ALLOWED_SEPARATORS = [",", ".", " "];

/**
 * This is a very tolerant parser
 * It's not intended to be used as a validator
 * If input contains other symbols than "+- ,." then the result will be NaN
 */
export function parseNumber(input: string): number {
    if (input == null) return NaN;

    input = input.trim();

    if (input[0] === "+") {
        input = input.substr(1);
    }

    const separators = input.replace(/[\d-]/g, "").split("");

    if (separators.length === 0) return +input;

    if (separators.length === 1) {
        const [char] = separators;

        if (char === ".") {
            // it it's dot, we're good since that's how JS interprets decimal point
            return +input;
        }

        if (char === " ") {
            // space can't be used as a decimal point so it's thousands
            return +input.replace(char, "");
        }

        if (char === ",") {
            return +input.replace(char, ".");
        }

        return NaN;
    }

    // check for bad input which we could have done higher up
    // but most of the usages of parseNumber will never get to this line
    // so why not prematurely optimize it
    if (separators.some(disallowedSeparator)) return NaN;

    if (separators[0] === separators[separators.length - 1]) {
        // the first and the last symbol are the same so it's safe to assume they are thousands
        return +input.replace(new RegExp(`[${separators[0]}], "g"`), "");
    }

    // if execution got here, it means that
    // there's 2+ separators and first and last are not the same
    // if the last one is " ", then it surely isn't right
    if (separators[separators.length - 1] === " ") return NaN;

    const result = input
        .replace(new RegExp(`[${separators[0]}]`, "g"), "")
        .replace(new RegExp(`[${separators[separators.length - 1]}]`), ".");

    return +result;
}

function disallowedSeparator(char: string): boolean {
    return ALLOWED_SEPARATORS.indexOf(char) === -1;
}
