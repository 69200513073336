import { Pipe, PipeTransform, Injectable, inject } from "@angular/core";
import { ILgFormatter, ILgFormatterOptions, IntegerFormatterFactory } from "@logex/framework/core";
import { Nullable } from "@logex/framework/types";

export type IFmtIntegerOptions = Partial<Pick<ILgFormatterOptions, "forceSign" | "zeroDash">>;

const defaultOptions: ILgFormatterOptions = {
    forceFormat: true
};

@Injectable({ providedIn: "root" })
@Pipe({
    standalone: true,
    name: "fmtInteger",
    pure: true
})
export class LgFormatIntegerPipe implements PipeTransform {
    private _formatter: ILgFormatter<number> =
        inject(IntegerFormatterFactory).create(defaultOptions);

    public transform(input: Nullable<string | number>, options?: IFmtIntegerOptions): string;

    /**
     * @deprecated Use options variant instead
     */
    public transform(value: Nullable<string | number>, forceSign?: boolean): string;

    public transform(
        value: Nullable<string | number>,
        options?: boolean | IFmtIntegerOptions
    ): string {
        const parameterOptions = this._getParameterOptions(options);

        if (typeof value === "string") {
            value = +value;
        }
        return this._formatter.format(value, parameterOptions);
    }

    private _getParameterOptions(options?: boolean | IFmtIntegerOptions): ILgFormatterOptions {
        let parameterOptions: ILgFormatterOptions;
        if (typeof options === "boolean") {
            parameterOptions = { forceSign: options };
        } else {
            parameterOptions = { ...options };
        }
        return parameterOptions;
    }
}
