<ng-template #defaultTemplate [lgChartTemplateContextType]="this" let-context>
    <div>
        <table class="lg-chart__tooltip__table">
            <tr class="lg-chart__tooltip__table__item">
                <td class="lg-chart__tooltip__table__item__name_column lg-d3-tooltip__tip-name">
                    {{ ".Tooltip_group_title" | lgTranslate }}
                </td>
                <td class="lg-chart__tooltip__table__item__color_column lg-d3-tooltip__tip-name">
                    {{ xAxisLabel ? xAxisLabel : (".Tooltip_x_value_title" | lgTranslate) }}
                </td>
                <td class="lg-chart__tooltip__table__item__color_column lg-d3-tooltip__tip-name">
                    {{ yAxisLabel ? yAxisLabel : (".Tooltip_y_value_title" | lgTranslate) }}
                </td>
            </tr>
            <tr *ngFor="let item of context.displayingItems" class="lg-chart__tooltip__table__item">
                <td class="lg-chart__tooltip__table__item__name_column">
                    {{ groupNameFn && groupNameFn(item.datum) }}
                </td>
                <td class="lg-chart__tooltip__table__item__name_column">
                    {{ _xAxisFormat(item.datum.xValue) }}
                </td>
                <td class="lg-chart__tooltip__table__item__name_column">
                    {{ _yAxisFormat(item.datum.yValue) }}
                </td>
            </tr>
        </table>
    </div>
</ng-template>

<div [style.height.px]="height" [style.width.px]="width">
    <div
        #chartWithLegend
        class="lg-funnel-chart__wrapper"
        [class.lg-funnel-chart__wrapper--flex-row]="legendOptions.position === 'right'"
        [class.lg-funnel-chart__wrapper--flex-column]="legendOptions.position === 'bottom'"
        [style.height.%]="100"
        [style.width.%]="100"
    >
        <div
            #chart
            [style.height.px]="_svgHeight + (_margin.bottom ?? 0)"
            [style.marginTop.px]="_margin.top"
            [style.marginLeft.px]="_margin.left"
        ></div>

        <lg-chart-legend
            *ngIf="legendOptions.visible"
            [className]="legendOptions.className"
            [definition]="_legendDefinition"
            [position]="legendOptions.position"
            [cropLongNames]="!!legendOptions.cropLongNames"
            [wrapMultilineDefinition]="!!legendOptions.wrapMultilineDefinition"
            [style.paddingBottom.px]="_legendPaddingBottom"
            [width]="_legendWidth"
            (onClick)="_onLegendItemClick($event)"
        >
        </lg-chart-legend>
    </div>
</div>
