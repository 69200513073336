import { QueryList } from "@angular/core";

import { LgPanelChoiceComponent } from "./lg-panel-choice.component";
import {
    IPanelChoiceDefinition,
    IPanelChoiceVariantDefinition,
    IPanelSwitchDefinition
} from "./lg-panel.types";

export const getSwitchDefinition = (
    choicesDefinitions: QueryList<LgPanelChoiceComponent>
): IPanelSwitchDefinition => {
    let choices: IPanelChoiceDefinition[] = [];

    // single choice --> multiple variants
    if (choicesDefinitions.length === 1) {
        choices = choicesDefinitions.first._variantDefinitions.map(variantDef => {
            return {
                id: variantDef.id,
                name: variantDef.name,
                icon: variantDef.icon,
                title: variantDef.title,
                mainPaneTemplateRef: choicesDefinitions.first.mainPane,
                additionalMenuItemsTemplateRef: choicesDefinitions.first.additionalMenuItems,
                variants: undefined
            } as unknown as IPanelChoiceDefinition;
        });
    } else {
        // multiple choices - each can have 0 or >= 2 variants
        choices = choicesDefinitions.map(choiceDef => {
            return {
                id: choiceDef.id,
                name: choiceDef.name,
                icon: choiceDef.icon,
                title: choiceDef.title,
                mainPaneTemplateRef: choiceDef.mainPane,
                additionalMenuItemsTemplateRef: choiceDef.additionalMenuItems,
                variants: choiceDef._variantDefinitions.map(variantDef => {
                    return {
                        id: variantDef.id,
                        name: variantDef.name,
                        icon: variantDef.icon,
                        title: variantDef.title,
                        additionalMenuItemsTemplateRef: variantDef.additionalMenuItems
                    } as IPanelChoiceVariantDefinition;
                })
            } as IPanelChoiceDefinition;
        });
    }

    return { choices };
};
