import ldIsFunction from "lodash-es/isFunction";
import { Directive, Input, TemplateRef, Inject } from "@angular/core";

import { toBoolean } from "@logex/framework/utilities";
import { LgPivotTableRowDefBase } from "./lg-pivot-table-row-def-base";

import {
    LgPivotTableRowSeparatorType,
    ItemsMap,
    RowSeparatorType,
    LgPivotTableBodyRegister,
    IBodyRegister,
    IRowTemplate
} from "../types";

@Directive({
    standalone: true,
    selector: "[lgPivotTableRowDef]",
    // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
    inputs: [
        "level: lgPivotTableRowDefOnLevel",
        "visible: lgPivotTableRowDefVisible",
        "height: lgPivotTableRowDefHeight"
    ]
})
export class LgPivotTableRowDefDirective extends LgPivotTableRowDefBase implements IRowTemplate {
    /**
     * Row separator type.
     */
    @Input("lgPivotTableRowDefHasSeparator") public set separatorType(
        value: boolean | string | number | LgPivotTableRowSeparatorType<any, any>
    ) {
        if (value == null) {
            this._separatorTypeFn = null;
        } else if (ldIsFunction(value)) {
            this._separatorTypeFn = value;
        } else {
            this._separatorTypeFn = () => value;
        }

        if (this._initialized) this._body.rowTemplates.change(this);
    }

    get separatorType(): LgPivotTableRowSeparatorType<any, any> | null {
        return this._separatorTypeFn;
    }

    public getSeparatorType<T>(items: ItemsMap<T>, hasTemplate: boolean): RowSeparatorType {
        if (!this._separatorTypeFn) {
            return hasTemplate ? RowSeparatorType.Between : RowSeparatorType.None;
        }

        const value = this._separatorTypeFn(items);
        if (value === 2 || (typeof value === "string" && value.toLowerCase() === "always")) {
            return RowSeparatorType.Always;
        } else {
            return toBoolean(value) ? RowSeparatorType.Between : RowSeparatorType.None;
        }
    }

    public constructor(
        @Inject(LgPivotTableBodyRegister) _body: IBodyRegister,
        _template: TemplateRef<any>
    ) {
        super(_body, _template);
    }

    private _separatorTypeFn: LgPivotTableRowSeparatorType<any, any> | null = null;

    protected _notifyMove(oldLevel: number | string): void {
        this._body.rowTemplates.move(this, oldLevel);
    }

    protected _notifyChange(): void {
        this._body.rowTemplates.change(this);
    }

    protected _notifyInit(): void {
        this._body.rowTemplates.add(this);
    }

    protected _notifyDestroy(): void {
        this._body.rowTemplates.remove(this);
    }
}
