import { Directive, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { ILgFormatterOptions } from "@logex/framework/core";

import { BigNumberDisplayBase } from "./big-number-display.base";

@Directive({
    standalone: true,
    selector: "[lgBigPercent]"
})
export class LgBigPercentDirective extends BigNumberDisplayBase implements OnInit, OnChanges {
    @Input("lgBigPercent") inputValue: string | null = null;
    @Input("lgBigPercentDecimals") decimals?: number;
    @Input("lgBigPercentMax") max?: number;
    @Input("lgBigPercentMin") min?: number;

    ngOnInit(): void {
        super._onInit();
    }

    ngOnChanges(changes: SimpleChanges): void {
        super._onChanges(changes);
    }

    protected _defaultProps(): void {
        this.decimals = this.decimals === undefined ? 0 : this.decimals;
        this.max = this.max === undefined ? 9 : this.max;
        this.min = this.min === undefined ? -9 : this.min;
    }

    protected _getFormatterOptions(): ILgFormatterOptions {
        return {
            decimals: this.decimals,
            forceSign: false,
            viewScale: 2,
            forceFormat: true,
            zeroDash: true
        };
    }
}
