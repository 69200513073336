import { ChangeDetectionStrategy, Component, forwardRef, Input } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { toBoolean } from "@logex/framework/utilities";

@Component({
    selector: "lg-multi-checkbox",
    // eslint-disable-next-line @angular-eslint/component-max-inline-declarations
    template: `
        <input
            type="checkbox"
            *ngIf="!_noEdit"
            lgStyledCheckbox
            attr.id="lgMultiCheckbox_{{ _id }}"
            [(ngModel)]="_internalModel"
            (ngModelChange)="_onChange($event)"
            (blur)="_onBlur()"
            lgTableInputNavigator
            [lgMarkFocusOn]="markFocusOn"
            class="lg-styled-checkbox--advanced"
            [class.lg-styled-checkbox--intermediate]="_both || _bothCalculated"
        />
        <lg-selection-checkbox
            *ngIf="_noEdit"
            [disabled]="true"
            [state]="_both || _bothCalculated ? 2 : _effective ? 1 : 0"
        ></lg-selection-checkbox>
    `,
    host: {
        "[class.lg-multi-checkbox]": "true"
    },
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => LgMultiCheckboxComponent),
            multi: true
        }
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LgMultiCheckboxComponent implements ControlValueAccessor {
    /**
     * Specifies if checkbox is disabled.
     */
    @Input() set disabled(value: any) {
        this._noEdit = toBoolean(value);
    }

    get disabled(): any {
        return this._noEdit;
    }

    /**
     * Specifies if all related options are selected.
     */
    @Input() set effective(value: any) {
        this._effective = toBoolean(value);
        this._bothCalculated = value === 2;
    }

    get effective(): any {
        return this._effective;
    }

    /**
     * Specifies id options are partially selected.
     */
    @Input() set both(value: any) {
        this._both = toBoolean(value);
    }

    get both(): any {
        return this._both;
    }

    /**
     * Selector of the closest element on which to set class 'lg-contains-focus' when input is focused
     * (closest = https://developer.mozilla.org/en-US/docs/Web/API/Element/closest)
     *
     * Defaults to `".table__row"` but it's a no-op if not used inside the table so you don't need to
     * pass in `null` or `""`
     */
    @Input() markFocusOn = ".table__row";

    _effective = false;
    _noEdit = false;
    _both: boolean | undefined;
    _bothCalculated = false;
    _internalModel = false;
    _id: number;

    private static _nextId = 0;

    private _onChangeFn!: (newValue: any) => void;
    private _onTouchedFn!: () => void;

    constructor() {
        this._id = LgMultiCheckboxComponent._nextId++;
    }

    writeValue(val: boolean): void {
        this._internalModel = val;
    }

    registerOnChange(fn: (newValue: any) => void): void {
        this._onChangeFn = fn;
    }

    registerOnTouched(fn: () => void): void {
        this._onTouchedFn = fn;
    }

    _onChange(newValue: boolean): void {
        this._onChangeFn(newValue);
    }

    _onBlur(): void {
        this._onTouchedFn();
    }
}
