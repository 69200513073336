import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    inject,
    Input,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from "@angular/core";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { LgNavigationService, ProcessedNavNode } from "@logex/framework/lg-application";
import { LgRouterStateService } from "@logex/framework/ui-core";
import { ISidebar } from "../sidebar-context";

@Component({
    selector: "lg-sidebar-menu",
    templateUrl: "./lg-sidebar-menu.component.html",
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: "lg-sidebar-menu lg-sidebar-panel flexcol"
    }
})
export class LgSidebarMenuComponent implements OnInit, OnDestroy {
    private _changeDetectorRef = inject(ChangeDetectorRef);
    private _navigationService = inject(LgNavigationService);
    private _router = inject(Router);
    private _routerState = inject(LgRouterStateService);

    /**
     * Sidebar handler (required).
     */
    @Input({ required: true }) sidebar!: ISidebar;

    /**
     * Navigation root identify. Cannot be updated in runtime.
     * This is useful for showing only subtree of the whole navigation definition (for example for showing Settings pages only).
     */
    @Input() navigationRootId: string | null = null; // Cannot be updated in runtime

    _navigation: ProcessedNavNode[] = [];
    _currentUrl = "";

    private readonly _destroyed$ = new Subject<void>();

    constructor() {
        this._routerState
            .route()
            .pipe(takeUntil(this._destroyed$))
            .subscribe(() => {
                this._updateCurrentUrl();
                this._changeDetectorRef.markForCheck();
            });
    }

    ngOnInit(): void {
        const navigationRoot$ =
            this.navigationRootId != null
                ? this._navigationService.getNavigation$(this.navigationRootId)
                : this._navigationService.getTopNavigation$();

        navigationRoot$.pipe(takeUntil(this._destroyed$)).subscribe(navigation => {
            this._navigation = navigation;
            const current = this._navigationService.getCurrentNodePathSync();
            for (const item of current) {
                if (item.data) {
                    item.data.expanded = true;
                }
            }
            this._changeDetectorRef.markForCheck();
        });
    }

    ngOnDestroy(): void {
        this._destroyed$.next();
        this._destroyed$.complete();
    }

    _navigate(navNode: ProcessedNavNode): void {
        if (navNode.disabled) return;
        if (this.sidebar) {
            this.sidebar.dismissPanel();
        }
        this._navigationService.navigateTo(navNode);
    }

    private _updateCurrentUrl(): void {
        let url = this._navigationService.getUrlPrefix() + this._router.url;
        if (url[0] === "/") url = url.substring(1);
        this._currentUrl = url;
    }
}
