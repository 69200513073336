import { IUser } from "../user/user.types";
import { ProcessedNavNode } from "./navigation.types";
import { Injectable } from "@angular/core";

@Injectable({ providedIn: "root" })
export class NavigationProcessing {
    // ----------------------------------------------------------------------------------
    //
    public evaluateAccess(
        node: ProcessedNavNode,
        parentNode: ProcessedNavNode | null,
        userInfo: IUser
    ): void {
        this.evaluateAccessStrategy_PermissionsHideNoAccess(node, parentNode, userInfo);
        // this.evaluateAccessStrategy_PermissionsDisableNoAccess( node, parentNode, userInfo );
    }

    // / access strategy that hides nodes that the user cannot access
    // eslint-disable-next-line @typescript-eslint/naming-convention
    protected evaluateAccessStrategy_PermissionsHideNoAccess(
        node: ProcessedNavNode,
        parentNode: ProcessedNavNode | null,
        userInfo: IUser
    ): void {
        if (node.permissions && !node.permissions.some(x => userInfo.hasPermission(x))) {
            node.hidden = true;
            node.noAccessRights = true;
        }

        if (parentNode) {
            if (parentNode.noAccessRights) {
                node.noAccessRights = true;
            }
        }
    }

    // / access strategy that disables nodes that the user cannot access
    // eslint-disable-next-line @typescript-eslint/naming-convention
    protected evaluateAccessStrategy_PermissionsDisableNoAccess(
        node: ProcessedNavNode,
        parentNode: ProcessedNavNode,
        userInfo: IUser
    ): void {
        if (node.permissions && !node.permissions.some(x => userInfo.hasPermission(x))) {
            node.disabled = true;
            node.noAccessRights = true;
        }

        if (parentNode && parentNode.disabled) {
            node.disabled = true;
            if (parentNode.noAccessRights) {
                node.noAccessRights = true;
            }
        }
    }

    // ----------------------------------------------------------------------------------
    //
    public evaluateNumbering(node: ProcessedNavNode, depth: number, numberings: number[]): boolean {
        return this.evaluateNumberingStrategy_None(node, depth, numberings);
    }

    // / numbering strategy that assigns numbers only to visible navigation nodes (and without noNumber)
    // eslint-disable-next-line @typescript-eslint/naming-convention
    protected evaluateNumberingStrategy_VisibleOnly(
        node: ProcessedNavNode,
        depth: number,
        _numberings: number[]
    ): boolean {
        return !(depth > 2 || node.hidden || node.noNumber);
    }

    // / numbering strategy that assigns numbers to all nodes without noNumber
    // eslint-disable-next-line @typescript-eslint/naming-convention
    protected evaluateNumberingStrategy_All(
        node: ProcessedNavNode,
        depth: number,
        _numberings: number[]
    ): boolean {
        return !(depth > 2 || node.noNumber);
    }

    // / numbering strategy that doesn't assign any numbers
    // eslint-disable-next-line @typescript-eslint/naming-convention
    protected evaluateNumberingStrategy_None(
        _node: ProcessedNavNode,
        _depth: number,
        _numberings: number[]
    ): boolean {
        return false;
    }

    // ----------------------------------------------------------------------------------
    //
    public getNumberingPrefix(
        _node: ProcessedNavNode,
        depth: number,
        numberings: number[]
    ): string {
        if (depth === 0) return "";
        let prefix = numberings[1] + "."; // first level should end up with dot
        if (depth === 2) prefix += numberings[2];
        if (depth === 3) prefix += "." + numberings[3];
        return prefix + " ";
    }
}
