import { Injectable, NgZone, Renderer2, ElementRef, inject } from "@angular/core";
import { Observable, fromEventPattern, Subject, Subscription, ReplaySubject } from "rxjs";
import { auditTime, distinctUntilChanged, first, share } from "rxjs/operators";

import { isElementVerticalWriting, observeOnZone } from "@logex/framework/utilities";

import { ResizeObservedEvent } from "../../events";

export interface SizeEvent {
    width: number;
    height: number;
    blockSize: number;
    inlineSize: number;
}

export type LgObserveSizeType = "width" | "height" | "blockSize" | "inlineSize" | "all";

export interface LgObserveSizeOptions {
    type: LgObserveSizeType | null;
    auditTime: number;
    outsideZone: boolean;
}

const toSizeEvent = (element: HTMLElement): SizeEvent => {
    const width = element.clientWidth;
    const height = element.clientHeight;
    const isVertical = isElementVerticalWriting(element);
    return {
        width,
        height,
        blockSize: isVertical ? width : height,
        inlineSize: isVertical ? height : width
    };
};

@Injectable({
    providedIn: "root"
})
export class LgObserveSizeService {
    private _ngZone = inject(NgZone);
    static _defaults: LgObserveSizeOptions = {
        type: "all",
        auditTime: 100,
        outsideZone: false
    };

    private readonly _refreshTrigger = new Subject<void>();
    private _refreshPending = false;

    recalculate(): void {
        if (this._refreshPending) return;

        this._refreshPending = true;
        this._ngZone.onMicrotaskEmpty.pipe(first()).subscribe(() => {
            this._refreshPending = false;
            this._refreshTrigger.next();
        });
    }

    observe(elementRef: ElementRef, renderer: Renderer2): LgObserveSizeApi {
        let unlisten: () => void;

        let lastHandler: { (...args: any[]): void; (...args: any[]): void } | null = null;
        let refreshSubscription: Subscription | null;

        const change$ = fromEventPattern<SizeEvent>(
            handler => {
                unlisten = renderer.listen(
                    elementRef.nativeElement,
                    "resizeObserved",
                    (event: ResizeObservedEvent) => {
                        const { inlineSize, blockSize } = event.detail.contentBoxSize[0];
                        const isVertical = isElementVerticalWriting(elementRef.nativeElement);
                        handler({
                            blockSize,
                            inlineSize,
                            width: isVertical ? blockSize : inlineSize,
                            height: isVertical ? inlineSize : blockSize
                        });
                    }
                );

                refreshSubscription = this._refreshTrigger.subscribe(() =>
                    handler(toSizeEvent(elementRef.nativeElement))
                );

                Promise.resolve().then(() => handler(toSizeEvent(elementRef.nativeElement)));
                lastHandler = handler;
            },
            handler => {
                // note: we rely on the fact there should be only one subscription
                if (handler !== lastHandler) {
                    console.error("lgObserveSize: unsubscribe inconsitency");
                    return;
                }
                unlisten();
                refreshSubscription?.unsubscribe();
                refreshSubscription = null;
                lastHandler = null;
            }
        ).pipe(share({ connector: () => new ReplaySubject<SizeEvent>(1) }));

        return new LgObserveSizeApi(this._ngZone, change$, elementRef);
    }
}

export class LgObserveSizeApi {
    constructor(
        private _ngZone: NgZone,
        private _change$: Observable<SizeEvent>,
        private _elementRef: ElementRef
    ) {
        // empty
    }

    change(options?: Partial<LgObserveSizeOptions>): Observable<SizeEvent> {
        if (!options) {
            options = LgObserveSizeService._defaults;
        } else {
            options = { ...LgObserveSizeService._defaults, ...options };
        }

        let result$ = this._change$.pipe(distinctUntilChanged(this._getComparer(options.type!)));

        if (options.auditTime) {
            result$ = result$.pipe(auditTime(options.auditTime));
        }

        if (!options.outsideZone) {
            result$ = result$.pipe(observeOnZone(this._ngZone));
        }

        return result$;
    }

    getCurrentSize(): SizeEvent {
        return toSizeEvent(this._elementRef.nativeElement);
    }

    private _getComparer(type: LgObserveSizeType): (a: SizeEvent, b: SizeEvent) => boolean {
        switch (type) {
            case "width":
                return (a: SizeEvent, b: SizeEvent) => a.width === b.width;
            case "height":
                return (a: SizeEvent, b: SizeEvent) => a.height === b.height;
            case "blockSize":
                return (a: SizeEvent, b: SizeEvent) => a.blockSize === b.blockSize;
            case "inlineSize":
                return (a: SizeEvent, b: SizeEvent) => a.inlineSize === b.inlineSize;
            case "all":
            default:
                return (a: SizeEvent, b: SizeEvent) => a.width === b.width && a.height === b.height;
        }
    }
}
