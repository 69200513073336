import { inject, Injectable, ValueProvider } from "@angular/core";
import { TranslateCompiler } from "@ngx-translate/core";
import {
    TranslateMessageFormatCompiler,
    MESSAGE_FORMAT_CONFIG,
    MessageFormatConfig
} from "ngx-translate-messageformat-compiler";

export function messageFormatProps(v: any, _lc: any, p: string): any {
    const paths = p.split(".");
    while (v != null && paths.length) {
        v = v[paths[0]];
        paths.shift();
    }
    return v;
}

export function messageFormatMonthName(v: any, lc: any): any {
    console.log(lc);
    return v;
}

export function useMessageFormatLocales(locales: string[]): ValueProvider {
    return {
        provide: MESSAGE_FORMAT_CONFIG,
        useValue: <MessageFormatConfig>{
            locales,
            biDiSupport: false,
            formatters: {
                props: messageFormatProps,
                monthName: messageFormatMonthName
            }
        }
    };
}

// Based on https://github.com/ngx-translate/core/issues/469
@Injectable({ providedIn: "root" })
export class ReferenceTranslateCompiler extends TranslateCompiler {
    private _messageCompiler = inject(TranslateMessageFormatCompiler);

    /*
     * Needed by ngx-translate
     */
    compile(value: string, lang: string): (params: any) => string {
        return this._messageCompiler.compile(value, lang);
    }

    /*
     * Triggered once from TranslateCompiler
     * Initiates recurive this.parseReferencePointers()
     * Returns modified translations object for ngx-translate to process
     */
    public compileTranslations(translations: any, lang: string): any {
        this._parseReferencePointers(translations, translations);
        return this._messageCompiler.compileTranslations(translations, lang);
    }

    /*
     * Triggered once from this.compileTranslations()
     * Recursively loops through an object,
     * replacing any property value that has a string starting with "@APP_CORE." with the APP_CORE global string definition.
     * i.e. @APP_CORE.LOCATION.OVERVIEW becomes Location Overview
     */
    private _parseReferencePointers(
        currentTranslations: Record<string, any>,
        masterLanguageFile: Record<string, any>
    ): void {
        Object.keys(currentTranslations).forEach(key => {
            if (currentTranslations[key] !== null && typeof currentTranslations[key] === "object") {
                this._parseReferencePointers(currentTranslations[key], masterLanguageFile);
                return;
            }
            if (typeof currentTranslations[key] === "string") {
                if (currentTranslations[key].substring(0, 2) === "@:") {
                    let replacementProperty = this._getDescendantPropertyValue(
                        masterLanguageFile,
                        currentTranslations[key].substring(2)
                    );
                    if (replacementProperty === undefined) {
                        console.warn(
                            `Failed localization reference "${key}": "${currentTranslations[key]}`
                        );
                        replacementProperty = "<MISSING>";
                    }
                    currentTranslations[key] = replacementProperty;
                }

                // if ( typeof currentTranslations[key] === "string" && currentTranslations[key].indexOf( "{" ) > -1 ) {
                //     currentTranslations[key] =  currentTranslations[key].replace( /{/g, "{{" ).replace( /}/g, "}}" );
                // }
            }
        });
    }

    /*
     * Takes a string representation of an objects dot notation
     * i.e. "APP_CORE.LABEL.LOCATION"
     * and returns the property value of the input objects property
     */
    private _getDescendantPropertyValue(obj: { [x: string]: any }, desc: string): any {
        const arr = desc.split(".");
        while (arr.length && (obj = obj[arr.shift()!]));
        return obj;
    }
}
