import ldRange from "lodash-es/range";

import { coerceNumberProperty } from "@angular/cdk/coercion";
import { ESCAPE } from "@angular/cdk/keycodes";
import { ComponentPortal } from "@angular/cdk/portal";
import {
    FormStyle,
    getLocaleMonthNames,
    NgClass,
    NgForOf,
    NgIf,
    TranslationWidth
} from "@angular/common";
import {
    ChangeDetectionStrategy,
    Component,
    ComponentRef,
    EventEmitter,
    forwardRef,
    HostListener,
    inject,
    Input,
    LOCALE_ID,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    TemplateRef,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { Subject } from "rxjs";

import { toBoolean } from "@logex/framework/utilities";

import { LgCapitalizePipe, LgRemoveSymbolPipe } from "../../pipes";
import { LgTooltipDirective, LgTooltipService, TooltipApi } from "../../lg-tooltip";
import { ValueAccessorBase } from "../inputs";
import {
    FIRST_MONTH,
    LAST_MONTH,
    LgMonthRangeSelectorPopupComponent,
    WHOLE_YEAR
} from "./lg-month-range-selector-popup.component";
import {
    DateDisplayType,
    MonthRange,
    MonthRangeConfiguration
} from "./lg-month-range-selector.types";

type Coordinates = { x: number; y: number };

type Line = {
    start: Coordinates;
    width: number;
};

type ActiveMonthsHighlightDefinition = Line[];

@Component({
    standalone: true,
    selector: "lg-month-range-selector",
    templateUrl: "./lg-month-range-selector.component.html",
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => LgMonthRangeSelectorComponent),
            multi: true
        }
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    imports: [LgTooltipDirective, NgForOf, NgIf, NgClass],
    host: {
        class: "lg-month-range-selector",
        "[class.lg-month-range-calendar__container--open]": "_opened",
        "[class.lg-month-range-calendar__container--close]": "!_opened",
        "[class.lg-month-range-selector--active]": "!_isEmptySelectionOrWholeYear(this.value)"
    }
})
export class LgMonthRangeSelectorComponent
    extends ValueAccessorBase<MonthRange>
    implements OnInit, OnChanges, OnDestroy
{
    private _capitalize = inject(LgCapitalizePipe);
    private _locale = inject(LOCALE_ID);
    private _removeSymbolPipe = inject(LgRemoveSymbolPipe);
    private _tooltipService = inject(LgTooltipService);

    @Input() disabled = false;

    /**
     * Specifies if selector should be closed after select.
     */
    @Input() shouldCloseAfterSelect = false;

    /**
     * Start month of the selection.
     */
    @Input() startMonth = 1;

    /**
     * End year of the selection.
     */
    @Input("year") endYear: number | null = null;

    /**
     * Range selector display type.
     */
    @Input() tooltipDisplayType: DateDisplayType = "month";

    /**
     * End of the first interval.
     */
    @Input() firstIntervalEnd = 12;

    /**
     * Name of the first interval.
     */
    @Input() firstIntervalName = "";

    /**
     * Name of the second interval.
     */
    @Input() secondIntervalName = "";

    @Input() hasTooltip = false;

    /**
     * Split intervals by periods.
     */
    @Input() showTooltipIntervalSplitBasedOnPeriods = false;

    // eslint-disable-next-line @angular-eslint/no-output-native
    @Output() readonly select = new EventEmitter<MonthRange>();

    @ViewChild("tooltip", { static: true }) _tooltipTemplate!: TemplateRef<any>;

    @HostListener("document:keydown", ["$event.keyCode"])
    public _onEsc(keyCode: number): boolean {
        if (this._opened) {
            if (keyCode === ESCAPE) {
                this._onBackdropClick();
                this._tooltip.hide();
                return false;
            }
        }
        return true;
    }

    get numberOfRowsInTooltip(): number {
        return this._firstIntervalData && this._secondIntervalData ? 2 : 1;
    }

    get numberOfColumnsInTooltip(): number {
        return this.firstIntervalName && this.secondIntervalName ? 2 : 1;
    }

    get allMonthsSelected(): boolean {
        return this.value.from === FIRST_MONTH && this.value.to === LAST_MONTH;
    }

    _definition!: ActiveMonthsHighlightDefinition;
    _opened = false;

    _firstIntervalData = "";
    _secondIntervalData = "";
    _names: string[] = [];
    previewMonthInterval = "";

    private _tooltip!: TooltipApi;
    private _destroyed = new Subject<void>();

    constructor() {
        super();
    }

    ngOnInit(): void {
        this._defaultProps();
        this._initTooltip();

        this._names = [
            "",
            ...getLocaleMonthNames(this._locale, FormStyle.Format, TranslationWidth.Abbreviated)
        ];
        this._names.forEach((month, index, array) => {
            array[index] = this._capitalize.transform(
                this._removeSymbolPipe.transform(month, "DOT")
            );
        });

        this._updateTooltipData(this.value);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.startMonth) {
            this.startMonth = coerceNumberProperty(changes.startMonth.currentValue, 1);
            this._updateDefinition(this._getRangeWithOffset(this.value));
            this._updateTooltipData(this.value);
        }
    }

    ngOnDestroy(): void {
        this._destroyed.next();
        this._destroyed.complete();
    }

    writeValue(value: MonthRange): void {
        this._writeValue(value);
        this._updateTooltipData(value);
        this._updateDefinition(this._getRangeWithOffset(value));
    }

    _toggleRangePopup(): void {
        this._opened = !this._opened;

        if (this._opened) {
            this._onOpenTooltip();
        } else {
            this._tooltip.hide();
        }
    }

    private _defaultProps(): void {
        this.disabled = toBoolean(this.disabled, false);
        this.shouldCloseAfterSelect = toBoolean(this.shouldCloseAfterSelect, false);
        this.startMonth = coerceNumberProperty(this.startMonth, 1);
        this.tooltipDisplayType = this.tooltipDisplayType || "month";
        this.firstIntervalEnd = coerceNumberProperty(this.firstIntervalEnd, 12);
        this.firstIntervalName = this.firstIntervalName || "";
        this.secondIntervalName = this.secondIntervalName || "";
    }

    private _initTooltip(): void {
        this._tooltip = this._tooltipService.create({
            content: new ComponentPortal(LgMonthRangeSelectorPopupComponent),
            target: this._elementRef,
            stay: true,
            offset: 7,
            arrowOffset: 7,
            trapFocus: true,
            backdropClickCallback: _ => this._onBackdropClick(),
            tooltipClass:
                "lg-tooltip lg-tooltip--month-range-selector lg-month-range-selector-popup"
        });
    }

    private _onOpenTooltip(): void {
        this._tooltip.show();

        const config: MonthRangeConfiguration = {
            initialRange: this.value,
            startMonth: this.startMonth,
            year: this.endYear ?? 0,
            tooltipDisplayType: this.tooltipDisplayType,
            firstIntervalEnd: this.firstIntervalEnd,
            firstIntervalName: this.firstIntervalName,
            secondIntervalName: this.secondIntervalName
        };
        this._getTooltipInstance()
            ?.initialize(config)
            .subscribe(range => this._onRangeSelected(range ?? null));
    }

    selectAllMonths(): void {
        this._onRangeSelected(WHOLE_YEAR());
    }

    private _onBackdropClick(): void {
        this._opened = false;
        this._changeDetectorRef.markForCheck();
    }

    private _getTooltipInstance(): LgMonthRangeSelectorPopupComponent | null {
        if (!this._tooltip || !this._tooltip.getPortalReference()) {
            return null;
        }

        return (
            this._tooltip.getPortalReference<ComponentRef<LgMonthRangeSelectorPopupComponent>>()
                ?.instance || null
        );
    }

    private _onRangeSelected(range: MonthRange | null): void {
        if (
            this.value !== range ||
            (this.value && range && (this.value.from !== range.from || this.value.to !== range.to))
        ) {
            this.value = range!;

            this.writeValue(range!);
            this.select.emit(range!);
        }
    }

    private _updateTooltipData(range: MonthRange): void {
        if (!range) return;
        this.previewMonthInterval = this._getTooltipRowSecondColumn(range.from, range.to);

        if (!this.showTooltipIntervalSplitBasedOnPeriods) {
            this._firstIntervalData = this._getTooltipRowSecondColumn(range.from, range.to);
            this._secondIntervalData = "";
        } else if (range.to === range.from) {
            this._firstIntervalData =
                range.to <= this.firstIntervalEnd
                    ? this._getTooltipRowSecondColumn(range.from, range.to)
                    : "";
            this._secondIntervalData =
                range.to > this.firstIntervalEnd
                    ? this._getTooltipRowSecondColumn(range.from, range.to)
                    : "";
        } else if (range.to < this.firstIntervalEnd) {
            this._firstIntervalData = this._getTooltipRowSecondColumn(range.from, range.to);
            this._secondIntervalData = "";
        } else if (range.from > this.firstIntervalEnd) {
            this._firstIntervalData = "";
            this._secondIntervalData = this._getTooltipRowSecondColumn(
                coerceSecondDateForInterval(range, this.firstIntervalEnd + 1),
                range.to
            );
        } else {
            this._firstIntervalData = this._getTooltipRowSecondColumn(
                range.from,
                coerceSecondDateForInterval(range, this.firstIntervalEnd)
            );
            this._secondIntervalData = this._getTooltipRowSecondColumn(
                coerceSecondDateForInterval(range, this.firstIntervalEnd + 1),
                range.to
            );
        }
    }

    private _getTooltipRowSecondColumn(from: number, to: number): string {
        return from === to
            ? this._getMonthForDisplay(from)
            : this._getMonthForDisplay(from) + " – " + this._getMonthForDisplay(to);
    }

    private _getMonthForDisplay(month: number): string {
        return this._names[(month + this.startMonth - 1) % 12 || 12];
    }

    private _updateDefinition(currentValue: MonthRange): void {
        const allMonthsInTertiles = this._getMonthsInTertiles(currentValue);
        const monthsSortedWithinTertiles = this._getMonthsSortedWithinTertiles(allMonthsInTertiles);
        this._definition = this._getDefinitionFromTertiles(monthsSortedWithinTertiles);
    }

    private _getMonthsInTertiles(currentValue: MonthRange): [number[], number[], number[]] {
        const emptyResult: [number[], number[], number[]] = [[], [], []];

        if (this._isEmptySelectionOrWholeYear(currentValue)) {
            return emptyResult;
        }

        let allMonthsInRange;
        if (currentValue.from <= currentValue.to) {
            allMonthsInRange = ldRange(currentValue.from, currentValue.to + 1);
        } else {
            allMonthsInRange = [
                ...ldRange(currentValue.from, 13),
                ...ldRange(1, currentValue.to + 1)
            ];
        }

        return allMonthsInRange.reduce(
            (result: [number[], number[], number[]], current: number) => {
                result[this._getZeroIndexedTertileForMonth(current)].push(current);
                return result;
            },
            emptyResult
        );
    }

    _getMonthsSortedWithinTertiles(monthsInTertiles: number[][]): number[][] {
        return monthsInTertiles.map(x => x.sort((a, b) => (a > b ? 1 : -1)));
    }

    _isEmptySelectionOrWholeYear(range: MonthRange): boolean {
        return !range || (range.from % 12 || 12) === (range.to % 12) + 1;
    }

    private _getDefinitionFromTertiles(monthsInTertiles: number[][]): Line[] {
        const result: Line[] = [];
        monthsInTertiles.forEach((array: number[], index): void => {
            array.forEach((tertile: number): void => {
                result.push({
                    start: {
                        x: (tertile % 4 || 4) * 4,
                        y: 4 + 4 * (index + 1)
                    },
                    width: 2
                });
            });
        });

        return result;
    }

    private _getZeroIndexedTertileForMonth(month: number): 0 | 1 | 2 {
        switch (true) {
            case month >= 1 && month <= 4:
                return 0;
            case month >= 5 && month <= 8:
                return 1;
            case month >= 9 && month <= 12:
                return 2;
            default:
                throw new Error("month needs to be between 1 and 12");
        }
    }

    private _getRangeWithOffset(range: MonthRange): MonthRange {
        if (!range) {
            return range;
        }

        return {
            from: this._getMonthWithOffset(range.from),
            to: this._getMonthWithOffset(range.to)
        };
    }

    private _getMonthWithOffset(month: number): number {
        return (month - 1 + this.startMonth) % 12 || 12;
    }
}

function coerceSecondDateForInterval(range: MonthRange, fallbackMonth: number): number {
    return range.from === range.to ? range.from : fallbackMonth;
}
