export interface IDateCell {
    day: number;
    date: Date;
    other?: boolean;
    disabled?: boolean;
    today?: boolean;
}

export enum CalendarInputPart {
    Day = "day",
    Month = "month",
    Year = "year"
}

export interface CalendarInputElement {
    part: CalendarInputPart;
    fullSize: boolean;
    separator: string;
}
