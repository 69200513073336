import { Directive, ElementRef, HostListener, Input, inject } from "@angular/core";
import { NgModel } from "@angular/forms";

@Directive({
    selector: "[lgUndoOnEsc]"
})
export class LgUndoOnEscDirective {
    private _el = inject(ElementRef);
    private _model = inject(NgModel);

    /**
     * Specifies if changes must be undone on press 'esc'
     */
    @Input("lgUndoOnEsc") set undoOnEsc(condition: boolean | "") {
        this._enabled = condition !== false;
    }

    get undoOnEsc(): boolean {
        return this._enabled;
    }

    private _enabled = true;
    private _lastValidValue: string | null = null;

    @HostListener("focus")
    _onFocus(): void {
        if (!this._enabled || this._model.invalid) {
            return;
        }

        this._lastValidValue = this._model.value;
    }

    @HostListener("blur")
    _onBlur(): void {
        if (!this._enabled || this._model.invalid) {
            return;
        }

        this._lastValidValue = null;
    }

    @HostListener("keyup.esc", ["$event"])
    _onEsc($event: MouseEvent): void {
        if (!this._enabled || this._lastValidValue == null) {
            return;
        }

        $event.preventDefault();

        this._model.control.setValue(this._lastValidValue);
        this._el.nativeElement.select();
    }
}
