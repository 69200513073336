import ldIsFunction from "lodash-es/isFunction";
import ldIsString from "lodash-es/isString";
import ldIsArray from "lodash-es/isArray";
import ldIsNumber from "lodash-es/isNumber";
import ldIsObject from "lodash-es/isObject";

import {
    Directive,
    ElementRef,
    HostListener,
    inject,
    Input,
    OnChanges,
    OnDestroy,
    SimpleChanges,
    TemplateRef,
    ViewContainerRef
} from "@angular/core";
import { Portal, TemplatePortal } from "@angular/cdk/portal";
import { toBoolean } from "@logex/framework/utilities";
import {
    ITooltipContentCallback,
    ITooltipOptions,
    TooltipApi,
    TooltipContext,
    TooltipPosition
} from "./lg-tooltip.types";
import { LgTooltipService } from "./lg-tooltip.service";

@Directive({
    standalone: true,
    selector: "[lgTooltip]",
    host: {
        "[class.lg-tooltip-visible]": "_visible"
    }
})
export class LgTooltipDirective<T> implements OnChanges, OnDestroy {
    private _elementRef = inject(ElementRef);
    private _tooltipService = inject(LgTooltipService);
    private _viewContainerRef = inject(ViewContainerRef);

    /**
     * Tooltip content (required).
     *
     * @type {string | template | Portal | callback to get value}
     */
    @Input({ required: true }) lgTooltip!:
        | string
        | TemplateRef<TooltipContext<T>>
        | Portal<any>
        | ((context?: T) => string | TemplateRef<TooltipContext<T>> | Portal<any>)
        | null;

    @Input() lgTooltipContext?: T;
    @Input() lgTooltipOptions: ITooltipOptions | null = null;
    @Input() lgTooltipPosition: TooltipPosition | null = null;
    @Input() lgTooltipStay = false;
    @Input() lgTooltipClass?: string;
    @Input() lgTooltipForce = false;
    @Input("lgTooltipAnimationEnabled") animationEnabled = false;
    @Input() lgTooltipHideOnScroll = false;

    // eslint-disable-next-line accessor-pairs
    @Input()
    set lgTooltipDelay(val: string | number | [number, number] | [number] | null) {
        // ignore; process setting from ngOnChanges
    }

    @Input()
    set lgTooltipOnClick(val: boolean | "true" | "false") {
        this._onClick = toBoolean(val);
    }

    get lgTooltipOnClick(): boolean {
        return this._onClick;
    }

    _visible = false;

    private _api: TooltipApi | null = null;

    private _onClick = false;

    private _initialized = false;

    @HostListener("mouseenter")
    onMouseEnter(): void {
        this._api?.scheduleShow();
    }

    @HostListener("mouseleave")
    onMouseLeave(): void {
        this._api?.scheduleHide();
    }

    @HostListener("click")
    onClick(): boolean {
        if (this._onClick) {
            this._api?.show({ waitForMouse: true });
            return false;
        }
        return true;
    }

    private _onLgTooltipDelayChange(val: string | number | [number, number] | [number]): void {
        let converted: [number, number];
        if (ldIsString(val)) {
            const parts = val.split(",");
            if (parts.length === 1) {
                converted = [+parts[0], +parts[0]];
            } else {
                converted = [+parts[0], +parts[1]];
            }
        } else if (ldIsArray(val)) {
            if (val.length === 1) {
                converted = [val[0], val[0]];
            } else {
                converted = [val[0], val[1]];
            }
        } else {
            converted = [val, val];
        }
        if (converted[0] > 0 && converted[0] < 10) {
            converted[0] *= 1000; // assume seconds
            converted[1] *= 1000;
        }
        this._api?.options({ delayShow: converted[0], delayHide: converted[1] });
    }

    private _getContent():
        | string
        | number
        | Portal<any>
        | ITooltipContentCallback<any>
        | null
        | undefined {
        if (!this.lgTooltip) return null;

        // TODO: figure out a way to obtain proper this at this point, or just drop support?
        const content = ldIsFunction(this.lgTooltip)
            ? this.lgTooltip(this.lgTooltipContext)
            : this.lgTooltip;

        if (content === null || content === undefined) return undefined;

        if (ldIsString(content) || ldIsNumber(content)) return content;

        if ("createEmbeddedView" in content) {
            return new TemplatePortal<TooltipContext<T>>(content, this._viewContainerRef, {
                $implicit: this.lgTooltipContext
            });
        }

        return content;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!this._initialized) {
            this._api = this._tooltipService.create({
                content: (() => this._getContent()) as ITooltipContentCallback<any>,
                target: this._elementRef,
                animationEnabled: this.animationEnabled,
                tooltipClass: "lg-tooltip lg-tooltip--simple",
                hideOnScroll: this.lgTooltipHideOnScroll
            });

            this._api.afterVisibilityChanged().subscribe(visible => (this._visible = visible));

            this._initialized = true;
        }

        if (changes.lgTooltipDelay) {
            this._onLgTooltipDelayChange(changes.lgTooltipDelay.currentValue);
        }

        if (changes.lgTooltipOptions && changes.lgTooltipOptions.currentValue) {
            if (!ldIsObject(changes.lgTooltipOptions.currentValue)) {
                throw new Error("Invalid parameter lgTooltipOptions");
            }
            this._api!.options(changes.lgTooltipOptions.currentValue);
        }

        if (changes.lgTooltipPosition) {
            this._api!.options({
                position: changes.lgTooltipPosition.currentValue || "bottom-left"
            });
        }

        if (changes.lgTooltipStay) {
            this._api!.options({ stay: toBoolean(changes.lgTooltipStay.currentValue) });
        }

        if (changes.lgTooltipClass) {
            this._api!.options({
                tooltipClass: changes.lgTooltipClass.currentValue || "lg-tooltip lg-tooltip--simple"
            });
        }

        if (changes.lgTooltipForce) {
            if (toBoolean(changes.lgTooltipForce.currentValue)) {
                setTimeout(() => {
                    this._api?.show({ stay: true, closeButton: true });
                }, 0);
            }
        }
    }

    ngOnDestroy(): void {
        if (this._api) this._api.destroy();
    }
}
