import {
    ChangeDetectionStrategy,
    Component,
    inject,
    Input,
    ViewEncapsulation
} from "@angular/core";
import {
    IAuthenticationService,
    LG_APP_CONFIGURATION,
    LG_APP_CONTROL,
    LG_AUTHENTICATION_SERVICE,
    LG_USER_INFO,
    LG_USERFLOW_SERVICE
} from "@logex/framework/lg-application";
import { useTranslationNamespace } from "@logex/framework/lg-localization";

export interface SidebarAccountLinkItem {
    name: string;
    url: string;
    icon?: string;
}

@Component({
    standalone: false,
    selector: "lg-sidebar-account",
    templateUrl: "./lg-sidebar-account.component.html",
    encapsulation: ViewEncapsulation.None,
    host: {
        class: "lg-sidebar-account flexcol"
    },
    viewProviders: [useTranslationNamespace("FW._Sidebar._Account")],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LgSidebarAccountComponent {
    _appControlService = inject(LG_APP_CONTROL);
    _userInfo = inject(LG_USER_INFO);
    private _authService = inject<IAuthenticationService>(LG_AUTHENTICATION_SERVICE);
    private _userflowService = inject(LG_USERFLOW_SERVICE);

    _isUserInternal = this._userInfo.hasPermission("internal");
    _myAccountUrl = inject(LG_APP_CONFIGURATION).myAccountAppUrl;

    impersonationMode = !!(this._isUserInternal && this._userInfo.impersonator);

    /**
     * Specifies the list of custom links to be shown in the section "Links".
     * The links are shown in the order passed to the component.
     */
    @Input() customLinks: SidebarAccountLinkItem[] = [];

    /**
     * Specifies the link to the LOGEX online application.
     * If not provided, the default value is "https://online.logex.com".
     * If set to `null`, the link will be hidden.
     */
    @Input() onlineLink: string | null = "https://online.logex.com";

    get canSwitchContext(): Promise<boolean> {
        return this._appControlService.canSwitchContext;
    }

    async _logout(): Promise<void> {
        this._userflowService.reset();
        await this._authService.logout();
    }

    async _switchHospital(): Promise<void> {
        this._appControlService.showContextSelector();
    }
}
