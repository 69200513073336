const sanitizingRegExp = /[#-.]|[[-^]|[?|{}]/g;

/**
 * Sanitize the specified string so that it can be used to create a regular expresion used to search it in another string
 *
 * @example var r = new RegExp( this.utility.sanitizeForRegexp(filterBy), "i"); r.match("ItemName")
 *
 * @param str the string to be sanitized
 */
export function sanitizeForRegexp(str: string): string {
    str = str || "";
    return str.replace(sanitizingRegExp, "\\$&");
}
