export interface IChatResponse {
    conversationId: string;
    response: string;
    manualUrl: string | null;
    manualTitle: string | null;
}

export interface IChatMessage {
    sent: boolean;
    text: string;
    isError?: boolean | undefined;
    buttons?: IChatButton[] | undefined;
}

export interface IChatButton {
    label: string;
    url: string;
}

export interface IChatConversation {
    conversationId: string;
    conversationMessages: Array<{
        order: number;
        messageText: string;
        sender: TSender;
        manualUrl?: string;
        manualTitle?: string;
    }>;
}

export enum TSender {
    User = 0,
    Chatbot = 1
}
