import ldFind from "lodash-es/find";
import ldMap from "lodash-es/map";
import ldTimes from "lodash-es/times";
import ldCompact from "lodash-es/compact";
import ldValues from "lodash-es/values";
import ldIsEmpty from "lodash-es/isEmpty";

import {
    Component,
    ElementRef,
    Renderer2,
    ViewChild,
    OnDestroy,
    inject,
    ChangeDetectionStrategy,
    ChangeDetectorRef
} from "@angular/core";
import { Observable, Subject } from "rxjs";

import { LgConsole, NumberFormatterFactory, ILgFormatter } from "@logex/framework/core";
import { LgTranslateService } from "@logex/framework/lg-localization";
import { ILookup } from "@logex/framework/types";
import {
    LgColDefinitionColumnSource,
    LgColDefinitionRowSource,
    LgColDefinitionSource,
    LgDialogRef,
    LgPromptDialog,
    IDialogComponent,
    IPromptDialogOptions,
    IDropdownDefinition,
    copyTextToClipboard
} from "@logex/framework/ui-core";

import {
    DialogStateEnum,
    IColumnSelectorEntry,
    IDataCell,
    IPasteButtonColumnInfo,
    IPasteResult,
    IPreprocessDataCallback,
    PasteDialogConfiguration,
    UnmatchedHandlingType
} from "./copy-paste.types";

// eslint-disable-next-line @angular-eslint/use-component-selector
@Component({
    templateUrl: "./lg-paste-dialog.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LgPasteDialogComponent implements OnDestroy, IDialogComponent<LgPasteDialogComponent> {
    private _translateService = inject(LgTranslateService);
    private _changeDetectorRef = inject(ChangeDetectorRef);
    private _dialogRef = inject(LgDialogRef<LgPasteDialogComponent>);
    private _promptDialog = inject(LgPromptDialog).bindViewContainerRef();
    private _renderer = inject(Renderer2);
    private _console = inject(LgConsole).withSource("Logex.Dialogs.PasteDialogController");
    private _numberFormatter: ILgFormatter<number> = inject(NumberFormatterFactory).create({
        decimals: 2
    });

    @ViewChild("pasteDialogButtonRow", { static: true }) _pasteButtonRow!: ElementRef;

    _tableColDefinition!: LgColDefinitionSource;
    _columns!: IPasteButtonColumnInfo[];
    _columnsSelectorDropdown!: IDropdownDefinition<string>;
    _mapping: Array<string | null> = [];

    _firstRowIsHeader = false;

    _configuration!: PasteDialogConfiguration;
    _nullsToZeros!: boolean;
    _pasteMode: "replace" | "update" = "update";
    _skipErrors = false;

    _showOnlyInvalid = false;
    _hasInvalidData = false;
    _preview: string[][] = [];
    _pastedData: string[][] = [];
    _filteredData: IDataCell[][] = [];

    _state = DialogStateEnum.Initial;
    _isWorking = false;
    _extraInformation!: string | undefined;

    _formatter = "none";

    _readonly = false;
    _title = "";
    _allowClose = true;
    _allowMaximize = true;
    _dialogClass = "lg-dialog lg-dialog--6col paste-dialog";

    protected _pasteResult$!: Subject<IPasteResult>;
    protected _originalData!: any[] | null;
    protected _preprocessData!: IPreprocessDataCallback;

    protected _keyFields!: string[];
    protected _columnsSelector!: IColumnSelectorEntry[];
    protected _data: IDataCell[][] = [];
    protected _unmatched!: UnmatchedHandlingType | undefined;
    protected _absentKeys: Record<string, object> = {};

    protected _onPasteBound: ((this: Document, ev: ClipboardEvent) => any) | null = null;

    protected _alertActive = false;

    protected _hiddenColumns: Set<IPasteButtonColumnInfo> | null = null;

    get _hasOriginalData(): boolean {
        return this._originalData ? this._originalData?.length > 0 : false;
    }

    ngOnDestroy(): void {
        this._console.debug("onDestroy");
        if (this._onPasteBound) document.removeEventListener("paste", this._onPasteBound);
    }

    show(
        columns: IPasteButtonColumnInfo[],
        originalData: any[] | null,
        title: string | undefined,
        preprocessData: IPreprocessDataCallback,
        unmatched: UnmatchedHandlingType | undefined,
        extraInformation: string | undefined,
        configuration = {} as PasteDialogConfiguration
    ): Observable<IPasteResult> {
        this._pasteResult$ = new Subject();
        this._title = title
            ? title
            : this._translateService.translate("FW._Directives.PasteButton_Dialog_Title");
        this._extraInformation = extraInformation;
        this._columns = columns;
        this._originalData = originalData;
        this._preprocessData = preprocessData;
        this._unmatched = unmatched;
        this._keyFields = this._columns.filter(x => x.key).map(x => x.field);

        this._configuration = configuration;
        this._pasteMode = configuration.pasteMode;
        this._nullsToZeros = configuration.nullsToZeros;
        this._skipErrors = configuration.skipErrors;

        const requiredCount = ldCompact(this._columns.map(item => !item.optional)).length;

        this._columnsSelector = this._columns.map(x => ({
            id: x.field,
            name: x.name,
            displayName:
                x.name + (this._columns.length !== requiredCount && !x.optional ? " (*)" : ""),
            required: !x.optional
        }));

        this._columnsSelector.unshift({
            id: null,
            displayName: this._translateService.translate(
                "FW._Directives.PasteButton_Dialog_NoColumn"
            ),
            required: false
        });

        this._columnsSelectorDropdown = {
            groups: [
                {
                    entries: this._columnsSelector
                }
            ],
            entryName: "displayName"
        };

        this._makeTableColDefinition();

        this._onPasteBound = this.onPaste.bind(this);
        document.addEventListener("paste", this._onPasteBound);

        return this._pasteResult$.asObservable();
    }

    onPaste(e: ClipboardEvent): void {
        e.preventDefault();

        // If we see alert message, don't accept paste anymore.
        if (this._alertActive) {
            return;
        }

        const clipboardText = e.clipboardData?.getData("Text") ?? "";
        const parsed = this._splitIntoRowsAndValues(clipboardText);

        if (ldIsEmpty(parsed)) {
            return;
        }

        const itemsInRow = parsed[0].length;
        const requiredCount = ldCompact(this._columns.map(item => !item.optional)).length;

        if (!parsed.every(x => x.length === itemsInRow) || itemsInRow < requiredCount) {
            this._alert(
                this._translateService.translate(
                    "FW._Directives.PasteButton_Dialog_Error_WrongData",
                    {
                        columns: this._columns.map(x => x.name).join(", ")
                    }
                )
            );
            return;
        }

        this._state = DialogStateEnum.Pasted;
        this._pastedData = parsed;
        this._tryToMatchHeaders();
        this._makePreview();
        this._changeDetectorRef.markForCheck();
    }

    _onFirstRowIsHeaderChanged(): void {
        this._makePreview();
    }

    _onColumnMappingChanged(columnIdx: number): void {
        const columnMappingId = this._mapping[columnIdx];
        this._mapping.forEach((x, i) => {
            if (i !== columnIdx && x === columnMappingId) {
                this._mapping[i] = this._columnsSelector[0].id;
            }
        });
    }

    _onPrepareData(): void {
        if (!this._isMappingComplete()) return;

        this._isWorking = true;

        this._renderer.addClass(this._pasteButtonRow.nativeElement, "loadingOverlayBlock");

        setTimeout(() => {
            this._isWorking = false; // there won't be any frame rendered anyway, and this way it's safe in case we crash
            const colIndex = this._columns.map(col =>
                this._mapping.findIndex(x => x === col.field)
            );

            // Find out the columns that are not in the data to hide them in the preview
            this._hiddenColumns = new Set(
                this._columns.filter(x => !this._mapping.includes(x.field))
            );

            // Convert fields to desired type
            // Check if there are duplicate values in columns flagged as unique
            this._hasInvalidData = false;
            const valsInColumnsFlaggedAsUnique: ILookup<any[]> = this._columns.reduce(
                (res: any, _ignore, i) => (res[i] = []) && res,
                {}
            );

            let pastedData = this._pastedData;
            if (this._firstRowIsHeader) {
                pastedData = pastedData.slice(1);
            }

            this._data = pastedData.map(row =>
                this._columns.map((col, i) => {
                    const rawValue = row[colIndex[i]] as any;
                    let value = rawValue;
                    let valid = true;

                    if (col.type === "number") {
                        if (rawValue !== "") {
                            const parseResult = this._numberFormatter.parse(rawValue);
                            value = parseResult.result;
                            if (!parseResult.isValid) {
                                valid = false;
                                this._hasInvalidData = true;
                            }
                        } else {
                            value = this._nullsToZeros ? 0 : null;
                        }
                    }

                    if (col.unique && value != null) {
                        if (valsInColumnsFlaggedAsUnique[i].some(x => x === value)) {
                            valid = false;
                            this._hasInvalidData = true;
                        } else {
                            valsInColumnsFlaggedAsUnique[i].push(value);
                        }
                    }

                    return { rawValue, value, valid } as IDataCell;
                })
            );

            this._data = this._preprocessData(this._columns, this._data);

            let containsUnmatched = false;

            this._absentKeys = {};

            // Find original values
            if (this._originalData) {
                const separator = "_$SEP$_";
                const lookup: Record<string, any> = {};

                for (const dataRow of this._originalData) {
                    let key = "";
                    const keyValue = {};

                    for (const [i, field] of this._keyFields.entries()) {
                        if (i > 0) key += separator;
                        key += dataRow[field]?.toString().trim() ?? "";

                        (keyValue as any)[field] = dataRow[field];
                    }

                    lookup[key] = dataRow;
                    this._absentKeys[key] = keyValue;
                }

                const keyIndices = this._keyFields.reduce(
                    (o, x) => {
                        o[x] = this._columns.findIndex(y => y.field === x);
                        return o;
                    },
                    {} as Record<string, number>
                );

                this._data.forEach(row => {
                    const key = this._keyFields
                        .map(field => row[keyIndices[field]].value)
                        .join(separator);
                    const originalRow = lookup[key];
                    if (originalRow) {
                        delete this._absentKeys[key];
                        this._columns.forEach((col, i) => {
                            row[i].matched = true;
                            if (col.key && col.validateKey) {
                                col.validateKey(row[i], col);
                                return;
                            }
                            row[i].originalValue = originalRow[col.field];
                        });
                    } else {
                        this._columns.forEach((col, i) => {
                            row[i].matched = false;
                            if (col.key && col.validateKey) {
                                col.validateKey(row[i], col);
                                return;
                            }
                            row[i].originalValue = undefined;
                        });
                        containsUnmatched = true;
                    }
                });
            }

            if (containsUnmatched) {
                switch (this._unmatched) {
                    case "ignore":
                        this._data = this._data.filter(row => row[0].matched);
                        break;
                    case "allow":
                        // let them stay
                        break;
                    default:
                    case "refuse":
                        this._alert(
                            this._translateService.translate(
                                "FW._Directives.PasteButton_Dialog_UnmatchedRefused"
                            )
                        );
                        return;
                    case "warn":
                        this._alert(
                            this._translateService.translate(
                                "FW._Directives.PasteButton_Dialog_UnmatchedWarning"
                            )
                        );
                        break;
                    case "confirm":
                        this._alertActive = true;

                        this._promptDialog
                            .alertLc(
                                "FW._Directives.PasteButton_Dialog_Confirmation_needed",
                                "FW._Directives.PasteButton_Dialog_UnmatchedConfirmation",
                                this._getAlertOptions()
                            )
                            .then(choice => {
                                if (choice === "remove") {
                                    this._data = this._data.filter(row => row[0].matched);
                                }
                                this._updateHasInvalidData();
                                this._showOnlyInvalid = false;
                                this._refilter();
                                this._state = DialogStateEnum.Converted;
                                this._alertActive = false;
                                this._changeDetectorRef.markForCheck();
                            })
                            .catch(() => {
                                this._alertActive = false;
                            });

                        return;
                }
            }
            this._updateHasInvalidData();
            this._showOnlyInvalid = false;
            this._refilter();
            this._state = DialogStateEnum.Converted;
            this._changeDetectorRef.markForCheck();
        }, 10); // give IE at least one frame to render the loader
    }

    _refilter(): void {
        this._filteredData = this._showOnlyInvalid
            ? this._data.filter(row => row.some(cell => !cell.valid))
            : this._data;
    }

    _isValidRow(row: IDataCell[]): boolean {
        return !row || row.length === 0 ? true : !row.some(x => !x.valid);
    }

    _onApply(): void {
        this._returnResult();
    }

    _back(): void {
        this._state--;
    }

    _copyReviewValues(): void {
        const convertedData =
            this._columns.map(i => i.name).join("\t") +
            "\t" +
            this._translateService.translate("FW._Directives.PasteButton_Dialog_Errors") +
            "\n" +
            this._data
                .map(row => {
                    return (
                        ldMap(row, cell => `"${cell.rawValue.replace(/"/g, '""')}"`).join("\t") +
                        "\t" +
                        this._getRowErrorMessage(row, ". ")
                    );
                })
                .join("\n");
        copyTextToClipboard(convertedData).then(success => {
            if (success) {
                this._alertActive = true;
                this._promptDialog
                    .alertLc(
                        "FW._Directives.CopyButton_Notification_Header",
                        "FW._Directives.CopyButton_Notification",
                        { dialogType: "normal" }
                    )
                    .then(() => (this._alertActive = false));
            }
        });
    }

    _bindErrorMessage(row: IDataCell[]): () => string {
        return () => this._getRowErrorMessage(row);
    }

    _isMappingComplete(): boolean {
        const requiredCount = ldCompact(this._columns.map(item => !item.optional)).length;
        const requiredSetCount = ldCompact(
            this._mapping.map(item => this._columnsSelector.find(cs => cs.id === item)?.required)
        ).length;
        return requiredCount === requiredSetCount;
    }

    _close(): void {
        this._pasteResult$.complete();
        this._dialogRef.close();
    }

    _tryClose(): boolean {
        this._pasteResult$.complete();
        return true;
    }

    protected _splitIntoRowsAndValues(text: string): string[][] {
        const hasCR = text.indexOf("\r") !== -1;
        const hasLF = text.indexOf("\n") !== -1;
        let lineSeparator: string;
        let lineRegex: RegExp;
        if (hasCR && hasLF) {
            lineSeparator = "\r\n";
            lineRegex = /\r\n/g;
        } else if (hasCR) {
            lineSeparator = "\r";
            lineRegex = /\r/g;
        } else {
            lineSeparator = "\n";
            lineRegex = /\n/g;
        }

        return text
            .split(lineSeparator)
            .filter(x => x.trim() !== "")
            .map(x =>
                x.split("\t").map(y => {
                    y = y.trim();
                    // If this is multi-line string
                    if (y.charAt(0) === '"' && y.charAt(y.length - 1) === '"') {
                        // Strip surrounding double quotes
                        y = y.substr(1, y.length - 2);
                        // "" -> "
                        y = y.replace(/""/g, '"');
                        // \r\n -> \n
                        y = y.replace(lineRegex, "\n");
                    }
                    return y;
                })
            );
    }

    protected _updateHasInvalidData(): void {
        this._hasInvalidData = false;
        for (const row of this._data) {
            for (const cell of row) {
                if (!cell.valid) {
                    this._hasInvalidData = true;
                    return;
                }
            }
        }
    }

    protected _getRowErrorMessage(row: IDataCell[], separator = "<br />"): string {
        let message = "";

        row.forEach(cell => {
            if (cell.error && cell.error.length) {
                message += cell.error + separator;
            }
        });

        if (!message.length && !this._isValidRow(row)) {
            message +=
                this._translateService.translate("FW._Directives.PasteButton_Dialog_InvalidData") +
                separator;
        }

        return message;
    }

    protected _returnResult(): void {
        const data = this._data.filter(row => !this._skipErrors || row.every(cell => cell.valid));
        const updatedData = data.filter(row => row[0].matched);
        const newData = data.filter(row => !row[0].matched);

        const conversionFn = (row: Array<{ value: any }>): any => {
            return this._columns.reduce((val, col, i) => {
                val[col.field] = row[i].value;
                return val;
            }, {} as any);
        };

        this._pasteResult$.next({
            updatedData: updatedData.map(conversionFn),
            newData: newData.map(conversionFn),
            deletedData: this._pasteMode === "replace" ? ldValues(this._absentKeys) : []
        });
        this._pasteResult$.complete();

        this._state = DialogStateEnum.Done;
        this._changeDetectorRef.markForCheck();
        this._close();
    }

    protected _tryToMatchHeaders(): void {
        const firstRow = this._pastedData[0];

        const nullOption = this._columnsSelector[0];
        this._mapping = ldTimes(firstRow.length, () => nullOption.id);

        this._firstRowIsHeader = false;
        firstRow.forEach((x, i) => {
            const column = this._columns.find(y => y.name.toLowerCase() === x.toLowerCase());
            if (column && !this._mapping.some(y => y === column.field)) {
                this._mapping[i] = ldFind(this._columnsSelector, { id: column.field })
                    ? column.field
                    : null;
                this._firstRowIsHeader = true;
            }
        });
    }

    protected _makePreview(): void {
        let previewQuery = this._pastedData;
        if (this._firstRowIsHeader) {
            previewQuery = previewQuery.slice(1);
        }
        this._preview = previewQuery.slice(0, 15); // take first 15 items
    }

    protected _alert(message: string): void {
        this._alertActive = true;

        this._promptDialog
            .alert(
                this._translateService.translate("FW._Directives.PasteButton_Dialog_Error"),
                message,
                { columns: 3 }
            )
            .then(() => (this._alertActive = false));
    }

    protected _getAlertOptions(): IPromptDialogOptions {
        return {
            buttons: [
                {
                    id: "include",
                    nameLc: "FW._Directives.PasteButton_Dialog_IncludeButton",
                    isConfirmAction: true
                },
                {
                    id: "cancel",
                    nameLc: "FW.CANCEL",
                    isCancelAction: true,
                    isReject: true
                },
                {
                    id: "remove",
                    nameLc: "FW._Directives.PasteButton_Dialog_RemoveButton",
                    icon: "i-delete"
                }
            ]
        };
    }

    // --------------------------------------------------------------------------
    //  Col definition creation
    protected _makeTableColDefinition(): void {
        this._tableColDefinition = {
            tableType: "default",
            rows: [
                this._getHeaderRowDefinition(this._columns, this._hasOriginalData),
                this._getDataRowDefinition()
            ]
        };
    }

    protected _getDataRowDefinition(): LgColDefinitionRowSource {
        return {
            id: "data",
            columnClasses: ["table__column"],
            columns: [{ node: "inherit", row: "header" }]
        };
    }

    protected _getHeaderRowDefinition(
        columns: IPasteButtonColumnInfo[],
        hasOriginalData: boolean
    ): LgColDefinitionRowSource {
        return {
            id: "header",
            columnClasses: ["table__column"],
            columns: [
                ...this._getHeaderColumnDefinition(columns, hasOriginalData),
                { node: "column", id: "error", columnClasses: [], columnType: "icons" }
            ]
        };
    }

    protected _getHeaderColumnDefinition(
        columns: IPasteButtonColumnInfo[],
        hasOriginalData: boolean
    ): LgColDefinitionColumnSource[] {
        const result: LgColDefinitionColumnSource[] = [];

        columns.forEach(col => {
            if (!hasOriginalData || col.key) {
                result.push(this._getColumn(col, `col-${col.field}`));
            } else {
                result.push(
                    this._getColumn(
                        col,
                        `col-${col.field}-org`,
                        "paste-dialog-table__cell_original"
                    )
                );
                result.push(
                    this._getColumn(col, `col-${col.field}`, "paste-dialog-table__cell_new")
                );
            }
        });

        return result;
    }

    protected _getColumn(
        column: IPasteButtonColumnInfo,
        id: string,
        columnClass = ""
    ): LgColDefinitionColumnSource {
        const conditionalClass = column.type === "number" && !column.key ? "right-align" : "";
        const columnClasses = ["crop"];
        if (conditionalClass) {
            columnClasses.push(conditionalClass);
        }
        if (columnClass) {
            columnClasses.push(columnClass);
        }

        return {
            if: () => !this._hiddenColumns?.has(column),
            node: "column",
            id,
            columnClasses,
            flexibilityFactor: 1
        };
    }
}
