export interface ITicker {
    css: string;
    position: number;
    value: number;
}

// This is used also in lg-slider component
export const getRangeSliderRuler = (
    min: number,
    max: number,
    tickerStep: number,
    highlightStep: number
): ITicker[] => {
    const tickers: ITicker[] = [];
    const range = max - min;

    if (!tickerStep) {
        tickerStep = Math.max(0.01, Math.round((100 * range) / 10) / 100);
    }
    let i: number;
    let h: number;
    const highlightStepScaled: number = Math.round(highlightStep * 100);
    const hStep = tickerStep * 100;
    // We try to make sure that 0 is always rendered, and from that point step both left and right to display the ticks
    if (min < 0) {
        i = 0 - tickerStep;
        h = 0 - hStep;
        while (i >= min - 0.01) {
            tickers.unshift({
                css: highlightStep && i % highlightStep === 0 ? "highlight" : "",
                position: (100 * (i - min)) / range,
                value: i
            });
            i -= tickerStep;
            h -= hStep;
        }
    }
    if (max > 0) {
        if (min < 0) {
            i = tickerStep;
            h = hStep;
            tickers.push({
                css: "zero",
                position: (100 * (0 - min)) / range,
                value: 0
            });
        } else {
            i = min;
            h = min * 100;
        }
        while (i <= max + 0.01) {
            tickers.push({
                css: highlightStep && Math.round(h) % highlightStepScaled === 0 ? "highlight" : "",
                position: (100 * (i - min)) / range,
                value: i
            });
            i += tickerStep;
            h += hStep;
        }
    }

    // new design doesn't show first and last. This is quicker than changing the code above
    tickers.pop();
    tickers.shift();

    return tickers;
};
