import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { IChatMessage } from "./lg-chat.types";

@Injectable({ providedIn: "root" })
export class LgChatStateService {
    private _showChat = false;
    private _chatId: string | null = null;
    private _messages?: IChatMessage[];
    private _loadingResponse$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    _initChat(existingMessages?: IChatMessage[]): void {
        this._messages = existingMessages || [];
    }

    addMessage(message: IChatMessage): void {
        this._messages!.push(message);
    }

    get messages(): IChatMessage[] | undefined {
        return this._messages;
    }

    get onLoadingResponseChange$(): Observable<boolean> {
        return this._loadingResponse$.asObservable();
    }

    notifyLoadingResponseChanged(value: boolean): void {
        this._loadingResponse$.next(value);
    }

    chatToggle(toState?: boolean): void {
        this._showChat = toState ?? !this._showChat;
    }

    get isChatVisible(): boolean {
        return this._showChat;
    }

    get chatId(): string | null {
        return this._chatId;
    }

    setChatId(newId: string | null): void {
        this._chatId = newId;
    }

    resetChat(): void {
        this.setChatId(null);
        this._initChat();
    }
}
