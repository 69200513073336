import { CdkPortalOutlet, PortalModule, TemplatePortal } from "@angular/cdk/portal";
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
    ViewEncapsulation,
    inject
} from "@angular/core";
import { LgTranslatePipe, useTranslationNamespace } from "@logex/framework/lg-localization";

import ldIsString from "lodash-es/isString";
import ldIsEmpty from "lodash-es/isEmpty";
import ldOrderBy from "lodash-es/orderBy";

import { LgFrameworkIcons } from "../lg-icon/lg-framework-icons";
import {
    HelpInfoLinksKeys,
    IHelpInfoLinks,
    IHelpInfoPopupParams,
    IHelpInfoRecord
} from "./lg-help-info.types";
import { NgForOf, NgIf } from "@angular/common";
import { LgIconComponent } from "../lg-icon/lg-icon.component";

@Component({
    standalone: true,
    selector: "lg-help-info-popup",
    templateUrl: "./lg-help-info-popup.component.html",
    host: {
        class: "lg-help-info-popup"
    },
    viewProviders: [useTranslationNamespace("FW._HelpInfo")],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [NgIf, NgForOf, LgIconComponent, LgTranslatePipe, PortalModule],
    encapsulation: ViewEncapsulation.None
})
export class LgHelpInfoPopupComponent implements OnDestroy {
    private _changeDetector = inject(ChangeDetectorRef);
    private _viewContainerRef = inject(ViewContainerRef);
    @ViewChild("outlet", { read: CdkPortalOutlet, static: true }) _portalOutlet!: CdkPortalOutlet;

    _header: string | null = null;
    _sanitizedDescription = "";
    private _description: string | null = null;
    private _infoLinks: IHelpInfoRecord[] = [];

    get description(): string | null {
        return this._description;
    }

    set description(value: string) {
        this._detachPortal();

        this._description = value;
        this._sanitizedDescription = value?.replace(/\n/g, "<br/>") ?? "";
    }

    get descriptionPortal(): TemplatePortal<unknown> | null {
        return this._portalOutlet.portal instanceof TemplatePortal
            ? this._portalOutlet.portal
            : null;
    }

    set descriptionPortal(value: TemplatePortal<unknown>) {
        this._detachPortal();
        this.description = "";

        if (this._portalOutlet && value) {
            this._portalOutlet.attachTemplatePortal(value);
        }
    }

    get infoLinks(): IHelpInfoRecord[] | null {
        return ldIsEmpty(this._infoLinks) ? null : this._infoLinks;
    }

    _initialize(params: IHelpInfoPopupParams): void {
        this._header = params.header ?? "";
        this._initDescription(params.description ?? "");
        this.initLinks(params.links);
        this._changeDetector.markForCheck();
    }

    private _detachPortal(): void {
        if (this._portalOutlet && this._portalOutlet.attached) {
            this._portalOutlet.detach();
        }
    }

    public initLinks(links: Partial<IHelpInfoLinks> = {}): void {
        const keys = links ? Object.keys(links) : [];
        const infoLinks = keys.map(x => {
            const key = x.charAt(0).toUpperCase() + x.slice(1);
            // TODO: add linkType -> icon lookup instead of assuming it's 1:1
            return {
                order: HelpInfoLinksKeys[key as keyof typeof HelpInfoLinksKeys],
                icon: LgFrameworkIcons[key as keyof typeof LgFrameworkIcons],
                text: `.${key}`,
                url: links[x as keyof typeof links]
            } as IHelpInfoRecord;
        });
        this._infoLinks = ldOrderBy(infoLinks, "order");
    }

    private _initDescription(
        description: string | TemplateRef<unknown> | TemplatePortal<unknown>
    ): void {
        if (ldIsString(description)) {
            this.description = description;
        } else if (description instanceof TemplateRef) {
            this.descriptionPortal = new TemplatePortal(description, this._viewContainerRef);
        } else {
            this.descriptionPortal = description;
        }
    }

    ngOnDestroy(): void {
        this._detachPortal();
    }
}
