import { traverseTypeConstructors } from "./traverseTypeConstructors";

/**
 * Describes a type field.
 */
export interface IStaticFieldInfo {
    /**
     * Name of the field.
     */
    name: string;

    /**
     * Value of the field.
     */
    value: any;

    /**
     * In which type the field is defined.
     */
    type: Function;
}

export function gatherObjectStaticFields(obj: any, fields: string | string[]): IStaticFieldInfo[] {
    if (!Array.isArray(fields)) {
        fields = [fields];
    }

    const res: IStaticFieldInfo[] = [];

    traverseTypeConstructors(obj, x => {
        for (const field of fields) {
            const val = x[field as keyof typeof x];
            if (val !== undefined)
                res.push({
                    name: field,
                    value: val,
                    type: x
                });
        }
    });

    return res;
}
