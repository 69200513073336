import { Injectable, OnDestroy, inject } from "@angular/core";
import {
    BehaviorSubject,
    combineLatest,
    firstValueFrom,
    map,
    Observable,
    ReplaySubject,
    share,
    takeUntil
} from "rxjs";
import { LgTranslateService } from "@logex/framework/lg-localization";

import { LgBookmarkModel } from "./lg-bookmark-model";
import { NEW_FILTER_ID } from "./lg-filterset.types";
import { LG_BOOKMARK_STORE } from "./lg-filterset-state-store.service";
import {
    ILgBookmarkGroup,
    ILgBookmarkStore,
    ILgKeyedBookmarkStore
} from "./lg-bookmarks-state.types";

@Injectable({ providedIn: "root" })
export class LgBookmarksStateService implements OnDestroy {
    private _stores = inject<ILgBookmarkStore[]>(LG_BOOKMARK_STORE);
    private _translateService = inject(LgTranslateService);

    readonly #destroyed$ = new ReplaySubject<void>(1);
    private readonly _storeNames: Observable<string[]>;
    private readonly _emptyGroupTexts: Observable<string[]>;
    private readonly _readOnlyFlags: Observable<boolean[]>;
    private readonly _currentBookmarks: Record<string, BehaviorSubject<LgBookmarkModel>> = {};
    private readonly _keyedStores: Array<Record<string, ILgKeyedBookmarkStore>> = [];

    constructor() {
        this._stores.sort((a, b) => a.priority - b.priority);

        this._storeNames = combineLatest(this._stores.map(store => store.menuGroupName)).pipe(
            share({
                resetOnComplete: false,
                resetOnRefCountZero: false,
                connector: () => new ReplaySubject(1)
            }),
            takeUntil(this.#destroyed$)
        );

        this._readOnlyFlags = combineLatest(this._stores.map(store => store.isReadOnly)).pipe(
            share({
                resetOnComplete: false,
                resetOnRefCountZero: false,
                connector: () => new ReplaySubject(1)
            }),
            takeUntil(this.#destroyed$)
        );

        this._emptyGroupTexts = combineLatest(this._stores.map(store => store.emptyGroupText)).pipe(
            share({
                resetOnComplete: false,
                resetOnRefCountZero: false,
                connector: () => new ReplaySubject(1)
            }),
            takeUntil(this.#destroyed$)
        );
    }

    /**
     * Return observable with the currently active bookmark. The observable will fire any time new bookmark is
     * selected - make sure to unsubscribe
     */
    getCurrentBookmark(key: string): Observable<LgBookmarkModel> {
        if (!this._currentBookmarks[key]) {
            this.selectNewBookmark(key);
        }
        return this._currentBookmarks[key];
    }

    /** Return hierarchy of groups for given key. Note that this may trigger their reload (f.ex with the standard store).
     * Note that the observable will fire any time the bookmark set changes - rememeber to unsubscribe.
     */
    getBookmarks(key: string): Observable<ILgBookmarkGroup[]> {
        return combineLatest([
            this._storeNames,
            this._readOnlyFlags,
            this._emptyGroupTexts,
            combineLatest(
                this._stores.map((_store, index) => this._getKeyedStore(key, index).getBookmarks())
            )
        ]).pipe(
            map(([names, readOnlyFlags, emptyGroupTexts, states]) => {
                const result: ILgBookmarkGroup[] = [];
                for (let i = 0; i < states.length; ++i) {
                    result.push({
                        name: names[i],
                        readOnly: readOnlyFlags[i],
                        storeId: i,
                        exclusive: this._stores[i].isExclusive && !readOnlyFlags[i],
                        bookmarks: states[i],
                        emptyGroupText: emptyGroupTexts[i]
                    });
                }
                return result;
            }),
            takeUntil(this.#destroyed$)
        );
    }

    /** Delete the specified bookmark */
    delete(key: string, storeId: number, id: string): Promise<void> {
        if (this._currentBookmarks[key]?.value?.id === id) {
            this.selectNewBookmark(key);
        }
        return this._getKeyedStore(key, storeId).delete(id);
    }

    /** Select the specified bookmark (which should exist). Promise resolves to false if the bookmark isn't found */
    async selectBookmark(key: string, storeId: number, id: string): Promise<boolean> {
        const state = await this._getKeyedStore(key, storeId).getBookmark(id);
        if (state === undefined) return false;
        const bookmark = new LgBookmarkModel(
            state,
            (await firstValueFrom(this._readOnlyFlags))[storeId],
            storeId
        );
        this._setCurrentBookmark(key, bookmark);
        return true;
    }

    /** Select new empty bookmark */
    selectNewBookmark(key: string): void {
        const bookmark = this._getEmptyBookmark(key);
        this._setCurrentBookmark(key, bookmark);
    }

    /** Notify the bookmark that filter's been changed */
    notifyFiltersWereChanged(key: string, filterParts: Record<string, string>): void {
        this._currentBookmarks[key]?.value?.filtersWereChanged(filterParts);
    }

    /**
     * Return index of the specified store type. This may be useful when explicitly selecting a bookmark
     */
    findStoreIndexByType(type: NewableFunction): number {
        return this._stores.findIndex(store => store instanceof type);
    }

    /**
     * Return all the bookmark stores registered with the state. This may be useful to add
     * special handling like @logex/flexible layout bookmarks, but shouldn't be used
     * by regular code.
     *
     * @internal
     */
    _getBookmarkStores(): ILgBookmarkStore[] {
        return [...this._stores];
    }

    async updateFilters(
        key: string,
        storeId: number,
        id: string,
        stateParts: Record<string, string>
    ): Promise<void> {
        const readOnly = (await firstValueFrom(this._readOnlyFlags))[storeId];
        if (readOnly) {
            throw new Error("The specified store is read-only");
        }
        await this._getKeyedStore(key, storeId).updateFilters(id, stateParts);
        const currentBookmark = this._currentBookmarks[key]?.value;
        if (currentBookmark?.storeId === storeId && currentBookmark?.id === id) {
            await this.selectBookmark(key, storeId, id);
        }
    }

    async startEdit(key: string, storeId: number, id: string): Promise<void> {
        const readOnly = (await firstValueFrom(this._readOnlyFlags))[storeId];
        if (readOnly) {
            throw new Error("The specified store is read-only");
        }
        await this._getKeyedStore(key, storeId).startEdit(id);
        const currentBookmark = this._currentBookmarks[key]?.value;
        if (currentBookmark?.storeId === storeId && currentBookmark?.id === id) {
            await this.selectBookmark(key, storeId, id);
        }
    }

    async startSaveAs(
        key: string,
        storeId: number,
        stateParts: Record<string, string>
    ): Promise<string> {
        const readOnly = (await firstValueFrom(this._readOnlyFlags))[storeId];
        if (readOnly) {
            throw new Error("The specified store is read-only");
        }
        const id = await this._getKeyedStore(key, storeId).startSaveAs(stateParts);
        this.selectBookmark(key, storeId, id);
        return id;
    }

    ngOnDestroy(): void {
        this.#destroyed$.next();
        this.#destroyed$.complete();
    }

    private _getKeyedStore(key: string, storeId: number): ILgKeyedBookmarkStore {
        let cache = this._keyedStores[storeId];
        if (cache === undefined) {
            this._keyedStores[storeId] = cache = {};
        }
        // todo: consider cleaning older caches, based on LRU
        if (cache[key] === undefined) {
            cache[key] = this._stores[storeId].getKeyedStore(key);
        }
        return cache[key];
    }

    private _setCurrentBookmark(key: string, bookmark: LgBookmarkModel): void {
        if (this._currentBookmarks[key] === undefined) {
            this._currentBookmarks[key] = new BehaviorSubject(bookmark);
        } else {
            this._currentBookmarks[key].next(bookmark);
        }
    }

    private _getEmptyBookmark(key: string): LgBookmarkModel {
        const name = this._translateService.translate(
            "FW._Directives._FiltersetSlideout.New_filter"
        );

        return new LgBookmarkModel(
            {
                filterHostId: key,
                name,
                overwrite: true,
                parts: {},
                shared: false,
                stateId: NEW_FILTER_ID,
                isOwn: true
            },
            false
        );
    }
}
