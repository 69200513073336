import { Pipe, PipeTransform, inject, isDevMode } from "@angular/core";
import {
    ILgFormatter,
    ILgFormatterOptions,
    PercentageFormatterFactory
} from "@logex/framework/core";
import { Nullable } from "@logex/framework/types";

const defaultOptions: ILgFormatterOptions = {
    decimals: 0,
    forceSign: false,
    zeroDash: false
};

/**
 * @deprecated use fmtPercent instead
 */
@Pipe({
    standalone: true,
    name: "fmtPercentSimple",
    pure: true
})
export class LgFormatPercentSimplePipe implements PipeTransform {
    private _formatter: ILgFormatter<number> = inject(PercentageFormatterFactory).create(
        defaultOptions
    );

    private static hasSentWarning = false;

    constructor() {
        if (isDevMode() && !LgFormatPercentSimplePipe.hasSentWarning) {
            console.warn(
                "The fmtPercentSimple pipe was deprecated, please update the code to use fmtPercent pipe instead"
            );
            LgFormatPercentSimplePipe.hasSentWarning = true;
        }
    }

    public transform(value: Nullable<string | number>): string {
        if (typeof value === "string") {
            value = +value;
        }
        return this._formatter.format(value);
    }
}
