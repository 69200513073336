import { ElementRef, Renderer2, TemplateRef } from "@angular/core";
import type { LgCalendarTooltipComponent } from "./lg-calendar-tooltip.component";
import { CalendarInputElement, CalendarInputPart } from "./calendar.types";

export abstract class CalendarInputElementWrapper implements CalendarInputElement {
    abstract get template(): TemplateRef<any>;
    abstract inputRef: ElementRef<HTMLInputElement>;
    abstract applyValue(src: Date): Date;
    abstract applyValueOrPlaceholder(src: Date): Date;

    value = "";
    placeholder = "";
    preselect = false;

    get hasValue(): boolean {
        return !!this.value;
    }

    setInputValue(value: string, setElementValue = true): void {
        this.value = value;
        if (setElementValue)
            this._renderer.setProperty(this.inputRef.nativeElement, "value", value);
    }

    abstract setPotentialValue(src: Date): void;

    setValueFromPlaceholder(): void {
        this.value = this.placeholder;
    }

    abstract normalizeFormat(): void;
    abstract shouldGoToNext(): boolean;

    focus(delay?: number | undefined): void {
        if (delay === undefined) {
            this.inputRef.nativeElement.focus();
        } else {
            setTimeout(() => this.focus(), delay);
        }
    }

    /**
     * Prepare the fields to be filled. This would be applied for fields from focus to the right (inclusive)
     */
    prepareForInput(): void {
        if (this.value) {
            this.placeholder = this.value;
            this.setInputValue("");
        }
    }

    /**
     * Mark fields as having a value. This would be applied for fields from from focus
     */
    abstract markAsFilled(): void;

    constructor(
        public part: CalendarInputPart,
        public fullSize: boolean,
        public separator: string,
        protected _renderer: Renderer2,
        protected _component: LgCalendarTooltipComponent
    ) {
        const previous = _component._elements.find(el => el.part === part);
        if (previous) {
            this.value = previous.value;
            this.placeholder = previous.placeholder;
        }
    }

    static create(
        element: CalendarInputElement,
        renderer: Renderer2,
        component: LgCalendarTooltipComponent
    ): CalendarInputElementWrapper {
        switch (element.part) {
            case CalendarInputPart.Day:
                return new CalendarInputDayWrapper(
                    element.part,
                    element.fullSize,
                    element.separator,
                    renderer,
                    component
                );
            case CalendarInputPart.Month:
                return new CalendarInputMonthWrapper(
                    element.part,
                    element.fullSize,
                    element.separator,
                    renderer,
                    component
                );
            case CalendarInputPart.Year:
                return new CalendarInputYearWrapper(
                    element.part,
                    element.fullSize,
                    element.separator,
                    renderer,
                    component
                );
            default:
                throw new Error("Unexpected input type " + element.part);
        }
    }
}

export class CalendarInputDayWrapper extends CalendarInputElementWrapper {
    get template(): TemplateRef<any> {
        return this._component._dayTemplate;
    }

    get inputRef(): ElementRef<HTMLInputElement> {
        return this._component.dayInputRef;
    }

    applyValue(src: Date): Date {
        if (!this.value || this.value === "0") return src;
        const clone = new Date(src);
        clone.setDate(+this.value);
        return clone;
    }

    applyValueOrPlaceholder(src: Date): Date {
        const clone = new Date(src);
        clone.setDate(this.value === "" ? +this.placeholder : +this.value);
        return clone;
    }

    setPotentialValue(src: Date): void {
        this.placeholder = src.getDate().toString();
        if (this.fullSize && this.placeholder.length === 1)
            this.placeholder = "0" + this.placeholder;
    }

    normalizeFormat(): void {
        if (!this.fullSize && this.value[0] === "0") {
            this.setInputValue(this.value.substring(1));
        } else if (this.fullSize && this.value.length === 1) {
            this.setInputValue("0" + this.value);
        }
    }

    shouldGoToNext(): boolean {
        return this.value.length === 2 || +this.value > 3;
    }

    markAsFilled(): void {
        if (!this.value || this.value === "0") {
            const dayOfMonth = this.placeholder || new Date().getDate().toString();
            this.setInputValue(dayOfMonth);
        }
        this.normalizeFormat();
    }
}

export class CalendarInputMonthWrapper extends CalendarInputElementWrapper {
    get template(): TemplateRef<any> {
        return this._component._monthTemplate;
    }

    get inputRef(): ElementRef<HTMLInputElement> {
        return this._component.monthInputRef;
    }

    applyValue(src: Date): Date {
        if (!this.value || this.value === "0") return src;
        const clone = new Date(src);
        clone.setMonth(+this.value - 1);
        return clone;
    }

    applyValueOrPlaceholder(src: Date): Date {
        const clone = new Date(src);
        clone.setMonth((this.value === "" ? +this.placeholder : +this.value) - 1);
        return clone;
    }

    setPotentialValue(src: Date): void {
        this.placeholder = (src.getMonth() + 1).toString();
        if (this.fullSize && this.placeholder.length === 1)
            this.placeholder = "0" + this.placeholder;
    }

    normalizeFormat(): void {
        if (!this.fullSize && this.value[0] === "0") {
            this.setInputValue(this.value.substring(1));
        } else if (this.fullSize && this.value.length === 1) {
            this.setInputValue("0" + this.value);
        }
    }

    shouldGoToNext(): boolean {
        return this.value.length === 2 || +this.value > 1;
    }

    markAsFilled(): void {
        if (!this.value || this.value === "0") {
            this.setValueFromPlaceholder();
        }
        this.normalizeFormat();
    }
}

export class CalendarInputYearWrapper extends CalendarInputElementWrapper {
    get template(): TemplateRef<any> {
        return this._component._yearTemplate;
    }

    get inputRef(): ElementRef<HTMLInputElement> {
        return this._component.yearInputRef;
    }

    applyValue(src: Date): Date {
        if (!this.value) return src;
        const clone = new Date(src);
        clone.setFullYear(+this.value);
        return clone;
    }

    applyValueOrPlaceholder(src: Date): Date {
        const clone = new Date(src);
        clone.setFullYear(this.value === "" ? +this.placeholder : +this.value);
        return clone;
    }

    setPotentialValue(src: Date): void {
        this.placeholder = src.getFullYear().toString();
    }

    normalizeFormat(): void {
        // no op
    }

    shouldGoToNext(): boolean {
        return this.value.length === 4;
    }

    markAsFilled(): void {
        if (!this.value) {
            this.setValueFromPlaceholder();
        }
        this.normalizeFormat();
    }
}
