import { Observable, Subject } from "rxjs";
import { FilterSetState, NEW_FILTER_ID } from "./lg-filterset.types";

export class LgBookmarkModel {
    readonly #isReadonly: boolean;
    readonly #originalState: Readonly<FilterSetState>;
    readonly #originalFilters: string;
    readonly #storeId: number;
    #currentState: FilterSetState;
    #currentFilters: string;
    readonly #changed$ = new Subject<void>();

    get isExisting(): boolean {
        return this.#originalState.stateId !== NEW_FILTER_ID;
    }

    get isOwn(): boolean {
        return this.#originalState.isOwn ?? false;
    }

    get mergeWithPrevious(): boolean {
        return !this.#currentState.overwrite;
    }

    get shared(): boolean {
        return this.#currentState.shared;
    }

    get wasModified(): boolean {
        return (
            this.#currentState.overwrite !== this.#originalState.overwrite ||
            this.#currentState.shared !== this.#originalState.shared ||
            this.#currentFilters !== this.#originalFilters
        );
    }

    get id(): string {
        return this.#currentState.stateId ?? "";
    }

    get name(): string {
        return this.#currentState.name;
    }

    get storeId(): number {
        return this.#storeId;
    }

    get key(): string {
        return this.#currentState.filterHostId;
    }

    get changed$(): Observable<void> {
        return this.#changed$.asObservable();
    }

    get isReadonly(): boolean {
        return this.#isReadonly;
    }

    set<P extends keyof FilterSetState, T extends FilterSetState[P]>(
        property: P,
        value: T
    ): FilterSetState {
        if (property === "overwrite" || property === "shared" || property === "name") {
            this.#currentState[property] = value;
            this.#changed$.next();
        }
        return this.#currentState;
    }

    filtersWereChanged(filters: Record<string, string>): void {
        this.#currentFilters = JSON.stringify(filters);
        this.#currentState.parts = filters;
        this.#changed$.next();
    }

    getState(): FilterSetState {
        return { ...this.#currentState };
    }

    getOriginalState(): FilterSetState {
        return { ...this.#originalState };
    }

    reset(): void {
        this.#currentState = { ...this.#originalState };
        this.#currentFilters = JSON.stringify(this.#originalState.parts);
        this.#changed$.next();
    }

    constructor(state: FilterSetState, makeReadonly: boolean, storeId: number = -1) {
        if (!state) {
            throw new Error("'apiFactory', Argument null exception, 'state' cannot be null");
        }
        this.#storeId = storeId;
        this.#isReadonly = makeReadonly;
        this.#originalState = Object.freeze<FilterSetState>({ ...state });
        this.#originalFilters = JSON.stringify(this.#originalState.parts);
        this.#currentState = { ...state };
        this.#currentFilters = JSON.stringify(this.#currentState.parts);
    }
}
