<div
    [lgTooltip]="hasTooltip === true && !_opened ? _tooltipTemplate : null"
    [lgTooltipDelay]="hasTooltip === true ? '1000,0' : null"
>
    <svg
        (click)="_toggleRangePopup()"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 36 36"
        height="36"
        width="36"
    >
        <g>
            <g>
                <rect class="lg-month-range-calendar__background" width="36" height="36" rx="4" />
            </g>
            <g transform="translate(7 6)">
                <path
                    class="lg-month-range-calendar__edge"
                    d="M20,2H18V0H16V2H6V0H4V2H2A2.006,2.006,0,0,0,0,4V20.978a2.006,2.006,0,0,0,2,2H20a2.006,2.006,0,0,0,2-2V4A2.006,2.006,0,0,0,20,2Zm0,18.978H2V6H20Z"
                />

                <rect
                    class="lg-month-range-calendar__tick"
                    width="2"
                    height="2"
                    transform="translate(4 8)"
                />
                <rect
                    class="lg-month-range-calendar__tick"
                    width="2"
                    height="2"
                    transform="translate(8 8)"
                />
                <rect
                    class="lg-month-range-calendar__tick"
                    width="2"
                    height="2"
                    transform="translate(12 8)"
                />
                <rect
                    class="lg-month-range-calendar__tick"
                    width="2"
                    height="2"
                    transform="translate(16 8)"
                />

                <rect
                    class="lg-month-range-calendar__tick"
                    width="2"
                    height="2"
                    transform="translate(4 12)"
                />
                <rect
                    class="lg-month-range-calendar__tick"
                    width="2"
                    height="2"
                    transform="translate(8 12)"
                />
                <rect
                    class="lg-month-range-calendar__tick"
                    width="2"
                    height="2"
                    transform="translate(12 12)"
                />
                <rect
                    class="lg-month-range-calendar__tick"
                    width="2"
                    height="2"
                    transform="translate(16 12)"
                />

                <rect
                    class="lg-month-range-calendar__tick"
                    width="2"
                    height="2"
                    transform="translate(4 16)"
                />
                <rect
                    class="lg-month-range-calendar__tick"
                    width="2"
                    height="2"
                    transform="translate(8 16)"
                />
                <rect
                    class="lg-month-range-calendar__tick"
                    width="2"
                    height="2"
                    transform="translate(12 16)"
                />
                <rect
                    class="lg-month-range-calendar__tick"
                    width="2"
                    height="2"
                    transform="translate(16 16)"
                />

                <rect
                    *ngFor="let def of _definition"
                    class="calendar__active_period"
                    height="2"
                    [style.width.px]="def.width"
                    attr.transform="translate({{ def.start.x }} {{ def.start.y }})"
                />
            </g>
        </g>
    </svg>
</div>

<ng-template #tooltip>
    <ng-container *ngIf="!_opened">
        <table class="lg-month-range-selector__tooltip-table">
            <tr class="lg-month-range-selector__tooltip-row_first" *ngIf="_firstIntervalData">
                <td *ngIf="numberOfColumnsInTooltip === 2">{{ firstIntervalName }}:</td>
                <td
                    [ngClass]="{
                        'lg-month-range-selector__tooltip-column_second':
                            numberOfColumnsInTooltip === 2
                    }"
                >
                    {{ _firstIntervalData }}
                </td>
            </tr>
            <tr *ngIf="_secondIntervalData" class="lg-month-range-selector__tooltip-row_second">
                <td *ngIf="numberOfColumnsInTooltip === 2">{{ secondIntervalName }}:</td>
                <td
                    [ngClass]="{
                        'lg-month-range-selector__tooltip-column_second':
                            numberOfColumnsInTooltip === 2
                    }"
                >
                    {{ _secondIntervalData }}
                </td>
            </tr>
        </table>
    </ng-container>
</ng-template>
