import ldIsEqual from "lodash-es/isEqual";

import {
    Directive,
    ElementRef,
    AfterViewChecked,
    Input,
    ChangeDetectorRef,
    OnDestroy,
    inject
} from "@angular/core";

import { LgTranslateService } from "./lg-translate.service";
import {
    LgGoogleTranslateDetectionFactoryService,
    ILgGoogleTranslateDetectionService
} from "./lg-google-translate-detection.service";
import { Subscription } from "rxjs";

@Directive({
    standalone: true,
    selector: "[lgTranslate]"
})
export class LgTranslateDirective implements AfterViewChecked, OnDestroy {
    private _element = inject(ElementRef);
    private _ref = inject(ChangeDetectorRef);
    private _translateService = inject(LgTranslateService);

    key: string | undefined;
    lastParams: any;
    currentParams: any;
    firstCheckDone = false;

    /**
     * Translate key (required).
     *
     * @description
     * - When empty, use the content of the directive instead.
     * - When the key starts with dot, current namespace is automatically prefixed.
     */
    @Input({ required: true }) set lgTranslate(key: string) {
        if (key) {
            this.key = key;
            if (this.firstCheckDone) this.checkNodes();
        }
    }

    get lgTranslate(): string | undefined {
        return this.key;
    }

    /**
     * Translate parameters
     */
    @Input() set translateParams(params: any) {
        if (!ldIsEqual(this.currentParams, params)) {
            this.currentParams = params;
            if (this.firstCheckDone) this.checkNodes(true);
        }
    }

    get translateParams(): any {
        return this.currentParams;
    }

    private _googleTranslateService: ILgGoogleTranslateDetectionService;
    private _googleTranslateRan = false;
    private _subscription: Subscription;

    constructor() {
        const factory = inject(LgGoogleTranslateDetectionFactoryService);

        this._googleTranslateService = factory.create();
        this._subscription = this._googleTranslateService.ran$.subscribe(
            val => (this._googleTranslateRan = val)
        );
    }

    ngAfterViewChecked(): void {
        // change behaviour only for users that have translated page with Google
        if (this._googleTranslateRan && this.firstCheckDone) {
            return;
        }

        this.checkNodes();
        this.firstCheckDone = true;
    }

    ngOnDestroy(): void {
        this._subscription.unsubscribe();
    }

    checkNodes(forceUpdate = false, translations?: any): void {
        let nodes: NodeList = this._element.nativeElement.childNodes;
        // if the element is empty
        if (!nodes.length) {
            // we add the key as content
            this.setContent(this._element.nativeElement, this.key);
            nodes = this._element.nativeElement.childNodes;
        }

        let nodeFound = false;
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let i = 0; i < nodes.length; ++i) {
            const node: any = nodes[i];
            if (node.nodeType === 3) {
                // node type 3 is a text node
                nodeFound = true;
                let key: string;
                if (this.key) {
                    key = this.key;
                    if (forceUpdate) {
                        node.lastKey = null;
                    }
                } else {
                    const content = this.getContent(node).trim();
                    if (content.length) {
                        // we want to use the content as a key, not the translation value
                        if (content !== node.currentValue) {
                            key = content;
                            // the content was changed from the user, we'll use it as a reference if needed
                            node.originalContent = this.getContent(node);
                        } else if (node.originalContent && forceUpdate) {
                            // the content seems ok, but the lang has changed
                            node.lastKey = null;
                            // the current content is the translation, not the key, use the last real content as key
                            key = node.originalContent.trim();
                        }
                    }
                }
                this.updateValue(key!, node);
            }
        }

        if (!nodeFound && this.key && this._element.nativeElement.ownerDocument) {
            // The node has children, but no text node. Let's prepend one
            const textNode = this._element.nativeElement.ownerDocument.createTextNode("");
            this._element.nativeElement.prepend(textNode);
            this.checkNodes(forceUpdate, translations);
        }
    }

    updateValue(key: string, node: any): void {
        if (key) {
            if (node.lastKey === key && this.lastParams === this.currentParams) {
                return;
            }

            this.lastParams = this.currentParams;

            const translation = this._translateService.translate(key, this.currentParams);

            if (translation !== key) {
                node.lastKey = key;
            }
            if (!node.originalContent) {
                node.originalContent = this.getContent(node);
            }
            node.currentValue = translation != null ? translation : node.originalContent || key;
            // we replace in the original content to preserve spaces that we might have trimmed
            this.setContent(
                node,
                this.key ? node.currentValue : node.originalContent.replace(key, node.currentValue)
            );
            this._ref.markForCheck();
        }
    }

    getContent(node: any): string {
        return node.textContent != null ? node.textContent : node.data;
    }

    setContent(node: any, content?: string): void {
        if (node.textContent != null) {
            node.textContent = content;
        } else {
            node.data = content;
        }
    }
}
