import { Injectable, InjectionToken, inject } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

export const LgTranslateNamespace = new InjectionToken<string>("LgTranslateNamespace");

@Injectable()
export class LgTranslateService {
    private _namespace = inject(LgTranslateNamespace, { optional: true });
    private _translateService = inject(TranslateService);

    /*
     * Gets the current translation namespace configured for the service
     */
    public getTranslateNamespace(): string {
        return this._namespace || "";
    }

    public getLanguage(): string {
        return this._translateService.currentLang;
    }

    /*
     * Translate the specified string. Unlike translate's instant, this version supports relative paths (id starting with dot),
     * using namespace configured on the bound scope. See $translate.instant() for details
     */
    public translate(translationId: string, interpolateParams?: any): string;

    /*
     * Translate the specified array of strings. Unlike translate's instant, this version supports relative paths (ids starting with dot),
     * using namespace configured on the bound scope. See $translate.instant() for details
     */
    public translate(translationId: string[], interpolateParams?: any): { [key: string]: string };

    public translate(translationId: string | string[], interpolateParams?: any): any {
        if (!translationId) return <string | null>null;

        if (typeof translationId === "string") {
            let translationIdFinal = translationId;
            if (translationIdFinal.charAt(0) === ".") {
                if (this._namespace) {
                    translationIdFinal = this._namespace + translationIdFinal;
                }
            }
            return this._translateInstant(translationIdFinal, interpolateParams);
        } else {
            const translationIds = (translationId as string[]).map(id => {
                if (!id) return "";
                if (this._namespace && id.charAt(0) === ".") {
                    return this._namespace + id;
                }
                return id;
            });
            return this._translateInstant(translationIds, interpolateParams);
        }
    }

    private _translateInstant(
        translationIds: string | string[],
        interpolateParams: string
    ): string | Array<string | null> {
        // keep exception handling in separate function
        try {
            return this._translateService.instant(translationIds, interpolateParams);
        } catch (e) {
            console.error(e);
            return translationIds;
        }
    }
}
