/* eslint-disable @typescript-eslint/no-this-alias */
import * as d3 from "d3";
import { ILookup } from "@logex/framework/types";

export interface IChartIcon {
    src: string;
    width: number;
    height: number;
}

type ValueIdType = string | number;

interface ISliceParameters {
    s0: number;
    e0: number;
    s1: number;
    e1: number;
    valueId?: ValueIdType;
}

interface IValueData {
    index: ILookup<number>;
    parameters: ISliceParameters[];
    exits?: ISliceParameters[];
}

interface IPreparedValueData {
    chartId: ValueIdType;
    valueId: ValueIdType;
    newData: IValueData;
    oldData: IValueData;
}

type ChartIdFnType = (d: any, i: number) => string;
type ChartNameFnType = (d: any, i: number) => ValueIdType;
type ChartNumberFnType = (d: any, i: number) => number;
type ChartIconFnType = (d: any, i: number) => IChartIcon;

export const LG_D3_PIE_DEFAULT_DURATION = 250;

/* eslint-disable @typescript-eslint/naming-convention, @typescript-eslint/unified-signatures */
export const d3_functor = (v: any): any => (typeof v === "function" ? v : () => v);

export class LgD3PieInterpolator {
    private m_innerRadius = 0;
    private m_outerRadius = 100;
    private m_arc: d3.Arc<any, any>;
    private m_tickOffset = 3;
    private m_tickLength = 6;
    private m_tickPadding = 3;
    private m_labelHeight = 14;
    private m_layout: d3.Pie<any, any> | null = null;

    private newChartData: ILookup<IValueData> = {};
    private oldChartData: ILookup<IValueData> = {};

    private first: boolean | null = null;
    private m_chartIdFn: ChartIdFnType = function defaultChartId() {
        return "default";
    };

    private m_valueIdFn: ChartIdFnType = function defaultValueId(d) {
        return d.data.id;
    };

    private m_valueNameFn: ChartNameFnType = function defaultValueName(d) {
        return d.data.id;
    };

    private m_xAxisLabelFn: ChartNameFnType = function defaultxAxisLabel(d) {
        return d.data.value;
    };

    private m_opacityFn: ChartNumberFnType = function defaultOpacity() {
        return 1;
    };

    private m_valueIconFn: ChartIconFnType | null = null;

    constructor() {
        this.m_arc = d3.arc().innerRadius(this.m_innerRadius).outerRadius(this.m_outerRadius);
    }

    private findPrepareData(d: d3.PieArcDatum<any>, i: number): IPreparedValueData {
        if (isNaN(d.startAngle)) {
            d.startAngle = 0;
        }
        if (isNaN(d.endAngle)) {
            d.endAngle = 0;
        }

        const chartId: ValueIdType = this.m_chartIdFn(d, i);
        const valueId: ValueIdType = this.m_valueIdFn(d, i);

        let newData: IValueData = this.newChartData[chartId];
        if (!newData) {
            newData = this.newChartData[chartId] = { index: {}, parameters: [], exits: [] };
        }

        let oldData: IValueData = this.oldChartData[chartId];
        if (!oldData) {
            oldData = this.oldChartData[chartId] = { index: {}, parameters: [] };
        }

        return { chartId, valueId, newData, oldData };
    }

    private findUpdateData(d: d3.PieArcDatum<any>, i: number): ISliceParameters {
        const prepared = this.findPrepareData(d, i);
        const newData = prepared.newData;
        const oldData = prepared.oldData;
        const valueId = prepared.valueId;
        let parameters: ISliceParameters, previous: ISliceParameters;
        let previousId: number;
        let s0: number, e0: number;
        let search: number;

        if ((parameters = newData.parameters[i])) {
            // Already evaluated
            return parameters;
        }

        if (!this.first) {
            const index = oldData.index[valueId];
            if (index != null) {
                s0 = oldData.parameters[index].s1;
                e0 = oldData.parameters[index].e1;
            } else if (i > 0) {
                // Look through the new preceeding newighbours, and find one that existed in the previous state
                search = i - 1;
                while (
                    search >= 0 &&
                    (previousId = oldData.index[newData.parameters[search].valueId!]) == null
                ) {
                    --search;
                }
                if (search >= 0) {
                    previous = oldData.parameters[previousId!];
                    s0 = previous.e1;
                    e0 = previous.e1;
                } else {
                    // none, we'll start from zero
                    s0 = 0;
                    e0 = 0;
                }
            } else {
                // We are first in the new chart, so start from 0
                s0 = 0;
                e0 = 0;
            }
        } else {
            s0 = e0 = (d.startAngle + d.endAngle) / 2;
        }
        parameters = {
            s0,
            e0,
            s1: d.startAngle,
            e1: d.endAngle,
            valueId
        };
        newData.index[valueId] = i;
        newData.parameters[i] = parameters;
        return parameters;
    }

    private findExitData(d: any, i: number): ISliceParameters {
        const prepared = this.findPrepareData(d, i);
        const newData = prepared.newData;
        const oldData = prepared.oldData;
        const valueId = prepared.valueId;

        let parameters: ISliceParameters;
        let s1: number, e1: number;
        let neighbourIndex: number;
        let neighbour: ISliceParameters;
        if ((parameters = newData.exits![i])) {
            // Already evaluated
            return parameters;
        }
        const index = oldData.index[valueId];
        let search = index - 1;
        // try to find previous old neighbour that exists in the new chart
        while (
            search >= 0 &&
            (neighbourIndex = newData.index[oldData.parameters[search].valueId!]) === undefined
        ) {
            --search;
        }
        if (search >= 0) {
            neighbour = newData.parameters[neighbourIndex!];
            s1 = e1 = neighbour.e1;
        } else {
            // no luck, try to find following old neighbour that exists in the new chart
            search = index + 1;
            while (
                search < oldData.parameters.length &&
                (neighbourIndex = newData.index[oldData.parameters[search].valueId!]) === undefined
            ) {
                ++search;
            }
            if (search < oldData.parameters.length) {
                neighbour = newData.parameters[neighbourIndex!];
                s1 = e1 = neighbour.s1;
            } else {
                s1 = e1 = 2 * Math.PI;
            }
        }
        parameters = {
            s0: d.startAngle,
            e0: d.endAngle,
            s1,
            e1
        };
        newData.exits![i] = parameters;
        return parameters;
    }

    private textPositionInterpolator(
        element: any,
        radius: number,
        startAngle: number,
        endAngle: number
    ): (arg0: number) => void {
        // define the "middle" belt
        const midRight0 = (80 * Math.PI) / 180;
        const midRight1 = (100 * Math.PI) / 180;
        const midLeft0 = midRight0 + Math.PI;
        const midLeft1 = midRight1 + Math.PI;

        // eslint-disable-next-line consistent-this
        const _interpolator = this;

        return function (t) {
            const angle = (1 - t) * startAngle + t * endAngle;
            element.setAttribute("x", radius * Math.sin(angle));
            element.setAttribute("y", -radius * Math.cos(angle));
            element.style.setProperty("text-anchor", angle < Math.PI ? "start" : "end");
            if (angle < midRight0 || angle > midLeft1) {
                element.setAttribute("dy", 0);
            } else if (angle < midRight1) {
                element.setAttribute(
                    "dy",
                    ((angle - midRight0) / (midRight1 - midRight0)) * _interpolator.m_labelHeight
                );
            } else if (angle < midLeft0) {
                element.setAttribute("dy", _interpolator.m_labelHeight);
            } else {
                element.setAttribute(
                    "dy",
                    _interpolator.m_labelHeight -
                        ((angle - midLeft0) / (midLeft1 - midLeft0)) * _interpolator.m_labelHeight
                );
            }
        };
    }

    private textLabelPositionInterpolator(
        element: any,
        radius: number,
        startAngle: number,
        endAngle: number
    ) {
        return function (t: number) {
            const angle = (1 - t) * startAngle + t * endAngle;
            const x = radius * Math.sin(angle);
            const y = -radius * Math.cos(angle);
            element.setAttribute("x", x);
            element.setAttribute("y", y);
        };
    }

    private iconPositionInterpolator(
        element: any,
        radius: number,
        width: number,
        height: number,
        multiplier0: number,
        multiplier1: number,
        maxWidth: number,
        startAngle: number,
        endAngle: number
    ): (arg0: number) => void {
        // define the "middle" belt
        const midRight0 = (80 * Math.PI) / 180;
        const midRight1 = (100 * Math.PI) / 180;
        const midLeft0 = midRight0 + Math.PI;
        const midLeft1 = midRight1 + Math.PI;
        const h0 = height * multiplier0;
        const h1 = height * multiplier1;
        const w0 = width * multiplier0;
        const w1 = width * multiplier1;

        return function (t: number) {
            const angle = startAngle + t * (endAngle - startAngle);
            const x = radius * Math.sin(angle - 0.03);
            const y = -radius * Math.cos(angle - 0.03);
            let currentHeight = h0 + t * (h1 - h0);
            let currentWidth = w0 + t * (w1 - w0);
            let dx, factor;
            if (angle < Math.PI) {
                dx = 0;
                if (x + currentWidth > maxWidth) {
                    factor = (maxWidth - x) / currentWidth;
                    currentWidth *= factor;
                    currentHeight *= factor;
                }
            } else {
                dx = -currentWidth;
                if (x - currentWidth < -maxWidth) {
                    factor = (maxWidth + x) / currentWidth;
                    currentWidth *= factor;
                    currentHeight *= factor;
                    dx *= factor;
                }
            }
            let dy = 0;
            if (angle < midRight0 || angle > midLeft1) {
                dy = -currentHeight;
            } else if (angle < midRight1) {
                dy =
                    -currentHeight +
                    ((angle - midRight0) / (midRight1 - midRight0)) * currentHeight;
            } else if (angle < midLeft0) {
                dy = 0;
            } else {
                dy = (-(angle - midLeft0) / (midLeft1 - midLeft0)) * currentHeight;
            }
            element.setAttribute("x", x + dx);
            element.setAttribute("y", y + dy);
            element.setAttribute("width", currentWidth);
            element.setAttribute("height", currentHeight);
        };
    }

    /** Return the current outer radius */
    public outerRadius(): number;
    /** Set the outer or eventually both outer and inner radius */
    public outerRadius(outer: number, inner?: number): LgD3PieInterpolator;
    public outerRadius(outer?: number, inner?: number): any {
        if (!arguments.length) return this.m_outerRadius;
        this.m_outerRadius = outer!;
        this.m_arc.outerRadius(outer!);
        if (arguments.length === 2) {
            this.m_innerRadius = inner!;
            this.m_arc.innerRadius(inner!);
        }
        return this;
    }

    /** Return the current inner radius */
    public innerRadius(): number;
    /** Sets the current inner radius */
    public innerRadius(inner: number): LgD3PieInterpolator;
    public innerRadius(inner?: number): any {
        if (!arguments.length) return this.m_innerRadius;
        this.m_innerRadius = inner!;
        this.m_arc.innerRadius(inner!);
        return this;
    }

    /** Returns the current tick offet */
    public tickOffset(): number;
    /** Set the tick offset */
    public tickOffset(offset: number): LgD3PieInterpolator;
    public tickOffset(offset?: number): any {
        if (arguments.length === 0) return this.m_tickOffset;
        this.m_tickOffset = offset!;
        return this;
    }

    /** Return the current tick length */
    public tickLength(): number;
    /** Set the tick length */
    public tickLength(length: number): LgD3PieInterpolator;
    public tickLength(length?: number): any {
        if (arguments.length === 0) return this.m_tickLength;
        this.m_tickLength = length!;
        return this;
    }

    /** Return the current tick padding */
    public tickPadding(): number;
    /** Set the tick padding */
    public tickPadding(padding: number): LgD3PieInterpolator;
    public tickPadding(padding?: number): any {
        if (arguments.length === 0) return this.m_tickPadding;
        this.m_tickPadding = padding!;
        return this;
    }

    /** Return the current label height */
    public labelHeight(): number;
    /** Set the label height */
    public labelHeight(height: number): LgD3PieInterpolator;
    public labelHeight(height?: number): any {
        if (arguments.length === 0) return this.m_labelHeight;
        this.m_labelHeight = height!;
        return this;
    }

    /**
     * Prepate the data for rendering
     */
    public prepareRender(): LgD3PieInterpolator {
        this.oldChartData = this.newChartData;
        this.newChartData = {};
        this.first = this.first == null;
        return this;
    }

    /** Return the current function for assigning the chart ID */
    public chartId(): ChartIdFnType;
    /**
     * Set the function which identifies the target chart of the value. Use this when rendering multiple pie charts, from a groups of data.
     * Setting this is optional, if not used, one pie is assumed
     */
    public chartId(v: any): LgD3PieInterpolator;
    /**
     * Set the constant ID of the target chart. This assuems one pie only
     */
    public chartId(v: any): LgD3PieInterpolator;
    public chartId(v?: any): any {
        if (arguments.length === 0) return this.m_chartIdFn;
        this.m_chartIdFn = v == null ? v : d3_functor(v);
        return this;
    }

    /** Return the current value ID function */
    public valueId(): ChartIdFnType;
    /**
     * Set function, which identifies the value. This is needed, because for the interpolation to work, we need a constant mapping for the pie slices
     * Value which returns the same ID between renderings should refer to the same "object", have same colour etc. Typically this will be the same
     * value that you use as key for the group.selectAll(...).data call
     * Setting this function is pretty much compulsory for anything useful. The default implementation returns d.data.id
     */
    public valueId(v: ChartIdFnType): LgD3PieInterpolator;
    public valueId(v?: ChartIdFnType): any {
        if (arguments.length === 0) {
            return this.m_valueIdFn;
        }

        this.m_valueIdFn = v == null ? v : d3_functor(v);
        return this;
    }

    /** Return the current function which determines name of the value */
    public valueName(): ChartNameFnType;
    /** Set function, which determines the name of the value, to be used as label. The default implementation returns d.data.id */
    public valueName(v: ChartNameFnType): LgD3PieInterpolator;
    public valueName(v?: ChartNameFnType): any {
        if (arguments.length === 0) {
            return this.m_valueNameFn;
        }

        this.m_valueNameFn = v == null ? v : d3_functor(v);
        return this;
    }

    /** Returns the current function, which assigns icon labels to values.
        public valueIcon(): ChartIconFnType;
        /** Sets the function, which determines the icon label for the value. The default value is null */
    public valueIcon(v: ChartIconFnType): LgD3PieInterpolator;
    public valueIcon(v?: ChartIconFnType): any {
        if (arguments.length === 0) {
            return this.m_valueIconFn;
        }

        this.m_valueIconFn = v == null ? v : d3_functor(v);
        return this;
    }

    /** Returns the current function assigning labels to the values */
    public xAxisLabel(): ChartNameFnType;
    /** Set function, which determines the value label to be rendered over the slice. The default implementation returns d.data.value */
    public xAxisLabel(v: ChartNameFnType): LgD3PieInterpolator;
    public xAxisLabel(v?: ChartNameFnType): any {
        if (arguments.length === 0) {
            return this.m_xAxisLabelFn;
        }

        this.m_xAxisLabelFn = v == null ? v : d3_functor(v);
        return this;
    }

    /** Returns the current function determining opacity of a value slice */
    public opacity(): ChartNumberFnType;
    /** Sets the function determining the opacity of value slice */
    public opacity(v: ChartNumberFnType): LgD3PieInterpolator;
    /** Sets the opacity of all slices */
    // eslint-disable-next-line @typescript-eslint/unified-signatures
    public opacity(v: number): LgD3PieInterpolator;
    public opacity(v?: ChartNumberFnType | number): any {
        if (arguments.length === 0) {
            return this.m_opacityFn;
        }

        this.m_opacityFn = v == null ? <any>v : d3_functor(v);
        return this;
    }

    /** Gets the current pie layout */
    public layout(): d3.Pie<any, any>;
    /** Sets the current pie layout. The default value is null! The call is required for the axis-related functionality. */
    public layout(v?: d3.Pie<any, any>): LgD3PieInterpolator;
    public layout(v?: d3.Pie<any, any>): any {
        if (arguments.length === 0) return this.m_layout;
        this.m_layout = v ?? null;

        return this;
    }

    /**
     * Configure tween for creation and update of the pie slice. The tween targets the "d" attribute.
     * Typical usage:  slices = chart.selectAll(....)[snip].transition()..attrTween("d", interpolator.pieTween)
     */
    public pieTween = (d: any, i: number): ((arg0: number) => string) => {
        const parameters = this.findUpdateData(d, i);

        const interpolate = d3.interpolate(
            {
                startAngle: parameters.s0,
                endAngle: parameters.e0
            },
            { startAngle: parameters.s1, endAngle: parameters.e1 }
        );
        const self = this;

        return function (t: number) {
            const b = interpolate(t);
            return self.m_arc(b) ?? "";
        };
    };

    /**
     * Configure tween for exit of the pie slice. The tween targets the "d" attribute.
     * Typical usage:  slices.exit().transition()..attrTween("d", interpolator.pieExitTween);
     */
    public pieExitTween = (d: any, i: number): ((arg0: number) => string) => {
        const parameters = this.findExitData(d, i);

        const interpolate = d3.interpolate(
            {
                startAngle: parameters.s0,
                endAngle: parameters.e0
            },
            { startAngle: parameters.s1, endAngle: parameters.e1 }
        );
        const self = this;

        return function (t: number) {
            const b = interpolate(t);
            return self.m_arc(b) ?? "";
        };
    };

    /**
     * Configure tween for creation and update of a tick. The tween targets the tick's transformation
     * Typical usage: lines = chart.selectAll(.....)[snip].transition().attrTween("transform", interpolator.tickTween)
     */
    public tickTween = (d: any, i: number): ((arg0: number) => string) => {
        const parameters = this.findUpdateData(d, i);
        const convert = 180 / Math.PI;

        return d3.interpolate(
            "rotate(" + ((parameters.s0 + parameters.e0) / 2) * convert + ")",
            "rotate(" + ((parameters.s1 + parameters.e1) / 2) * convert + ")"
        );
    };

    /**
     * Configure tween for exit of a tick. The tween targets the tick's transformation
     * Typical usage: lines.exit().transition().attrTween("transform", interpolator.tickExitTween)
     */
    public tickExitTween = (d: any, i: number): ((arg0: number) => string) => {
        const parameters = this.findExitData(d, i);
        const convert = 180 / Math.PI;

        return d3.interpolate(
            "rotate(" + ((parameters.s0 + parameters.e0) / 2) * convert + ")",
            "rotate(" + ((parameters.s1 + parameters.e1) / 2) * convert + ")"
        );
    };

    /**
     * Render pie ticks. Typical usage axis.call( interpolator.renderTicks )
     */
    public renderTicks = (
        container: d3.Selection<any, any, any, any>,
        transitionDuration = LG_D3_PIE_DEFAULT_DURATION
    ): void => {
        if (!this.m_layout) {
            throw new Error("Cannot render pie ticks. The pie layout has not been set.");
        }
        const lines = container
            .selectAll<SVGLineElement, any>("line")
            .data(this.m_layout, this.m_valueIdFn);

        lines
            .enter()
            .append("line")
            .attr("x1", 0)
            .attr("y1", -this.m_outerRadius - this.m_tickOffset)
            .attr("x2", 0)
            .attr("y2", -this.m_outerRadius - this.m_tickOffset - this.m_tickLength)
            .style("opacity", 0)
            .merge(lines)
            .transition()
            .duration(transitionDuration)
            .style("opacity", (d, i) => {
                if (isNaN(d.endAngle) || d.endAngle - d.startAngle < (10 * Math.PI) / 180) {
                    return 0;
                }
                return this.m_opacityFn(d, i);
            })
            .attrTween("transform", this.tickTween);

        lines
            .exit()
            .transition()
            .duration(transitionDuration)
            .style("opacity", 0)
            .attrTween("transform", this.tickExitTween)
            .remove();
    };

    /**
     * Render pie slices labels - this will be names of the slices, rendered outside the pie itself.
     * Typical usage: axis.call(.interpolator.renderLabels)
     */
    public renderLabels = (
        container: d3.Selection<any, any, any, any>,
        transitionDuration = LG_D3_PIE_DEFAULT_DURATION
    ): void => {
        if (!this.m_layout) {
            throw new Error("Cannot render labels. The pie layout has not been set.");
        }
        const r = this.m_outerRadius + this.m_tickOffset + this.m_tickLength + this.m_tickPadding;
        const labels = container
            .selectAll<SVGTextElement, any>("text")
            .data(this.m_layout, this.m_valueIdFn);
        const self = this;

        labels
            .enter()
            .append("text")
            .text((d, i) => {
                return this.m_valueNameFn(d, i);
            })
            .style("opacity", 0)
            .merge(labels)
            .transition()
            .duration(transitionDuration)
            .style("opacity", (d, i) => {
                if (isNaN(d.endAngle) || d.endAngle - d.startAngle < (10 * Math.PI) / 180) return 0;
                return this.m_opacityFn(d, i);
            })
            .tween("sweep", function (d, i) {
                const parameters = self.findUpdateData(d, i);
                const startAngle = (parameters.s0 + parameters.e0) / 2;
                const endAngle = (parameters.s1 + parameters.e1) / 2;
                return self.textPositionInterpolator(this, r, startAngle, endAngle);
            });

        labels
            .exit()
            .transition()
            .duration(transitionDuration)
            .style("opacity", 0)
            .tween("sweep", function (d, i) {
                const parameters = self.findExitData(d, i);
                const startAngle = (parameters.s0 + parameters.e0) / 2;
                const endAngle = (parameters.s1 + parameters.e1) / 2;
                return self.textPositionInterpolator(this, r, startAngle, endAngle);
            })
            .remove();
    };

    /**
     * Render pie slices icon labels. This assumes that valueIcon has been configured. Note that the width
     * of the chart (meaning this particular pie) is requried too.
     * Typical use: axis.call( interpolator.renderIconLabels, x.rangeBand())
     */
    public renderIconLabels = (
        container: d3.Selection<any, any, any, any>,
        chartWidth: number,
        transitionDuration = LG_D3_PIE_DEFAULT_DURATION
    ): void => {
        if (!this.m_layout) {
            throw new Error("Cannot render icon labels. The pie layout has not been set.");
        }
        const r = this.m_outerRadius + this.m_tickOffset + this.m_tickLength + this.m_tickPadding;
        const labels = container
            .selectAll<SVGImageElement, any>("image")
            .data(this.m_layout, this.m_valueIdFn);
        // todo: make this dynamic, depending on the radius
        const sizeLimit = (20 * Math.PI) / 180;
        const visibilityLimit = (10 * Math.PI) / 180;
        const self = this;

        labels
            .enter()
            .append("image")
            .attr("xlink:href", (d, i) => this.m_valueIconFn!(d, i)?.src)
            .attr("data_name", d => d.data.valueName)
            .style("opacity", 0)
            .merge(labels)
            .transition()
            .duration(transitionDuration)
            .style("opacity", (d, i) => {
                if (isNaN(d.endAngle) || d.endAngle - d.startAngle < visibilityLimit) return 0;
                return this.m_opacityFn(d, i);
            })
            .tween("sweep", function (d, i) {
                const icon = self.m_valueIconFn!(d, i);
                const parameters = self.findUpdateData(d, i);
                const startAngle = (parameters.s0 + parameters.e0) / 2;
                const endAngle = (parameters.s1 + parameters.e1) / 2;
                let multiplier0 = 1;
                let multiplier1 = 1;
                if (parameters.e0 - parameters.s0 < sizeLimit) multiplier0 = 0.5;
                if (parameters.e1 - parameters.s1 < sizeLimit) multiplier1 = 0.5;
                if (self.first) multiplier0 = multiplier1;
                return icon
                    ? self.iconPositionInterpolator(
                          this,
                          r,
                          icon.width,
                          icon.height,
                          multiplier0,
                          multiplier1,
                          chartWidth / 2,
                          startAngle,
                          endAngle
                      )
                    : // eslint-disable-next-line @typescript-eslint/no-empty-function
                      () => {};
            });

        labels
            .exit()
            .transition()
            .duration(transitionDuration)
            .style("opacity", 0)
            .tween("sweep", function (d, i) {
                const icon = self.m_valueIconFn!(d, i);
                const parameters = self.findExitData(d, i);
                const startAngle = (parameters.s0 + parameters.e0) / 2;
                const endAngle = (parameters.s1 + parameters.e1) / 2;
                let multiplier0 = 1;
                if (parameters.e0 - parameters.s0 < sizeLimit) {
                    multiplier0 = 0.5;
                }
                return icon
                    ? self.iconPositionInterpolator(
                          this,
                          r,
                          icon.width,
                          icon.height,
                          multiplier0,
                          multiplier0,
                          chartWidth / 2,
                          startAngle,
                          endAngle
                      )
                    : // eslint-disable-next-line @typescript-eslint/no-empty-function
                      () => {};
            })
            .remove();
    };

    /**
     * Renders the value labels of the slices - these will be the actual values, rendered inside the pie itself.
     * Use: groups.select("g.xAxisLabels").call(interpolator.renderxAxisLabels)
     */
    public renderxAxisLabels = (
        container: d3.Selection<any, any, any, any>,
        transitionDuration = LG_D3_PIE_DEFAULT_DURATION
    ): void => {
        if (!this.m_layout) {
            throw new Error("Cannot render axis labels. The pie layout has not been set.");
        }
        const r = (this.m_outerRadius + this.m_innerRadius) * 0.5;
        const labels = container
            .selectAll<SVGTextElement, any>("text")
            .data(this.m_layout, this.m_valueIdFn);
        const self = this;

        labels
            .enter()
            .append("text")
            .text((d, i) => this.m_xAxisLabelFn(d, i))
            .style("text-anchor", "middle")
            .style("opacity", 0)
            .merge(labels)
            .transition()
            .duration(transitionDuration)
            .text((d, i) => this.m_xAxisLabelFn(d, i))
            .style("fill", "#ffffff")
            .style("opacity", d => {
                return isNaN(d.endAngle) || d.endAngle - d.startAngle < (10 * Math.PI) / 180
                    ? 0
                    : 1;
            })
            .tween("sweep", function (d, i) {
                const parameters = self.findUpdateData(d, i);
                const startAngle = (parameters.s0 + parameters.e0) / 2;
                const endAngle = (parameters.s1 + parameters.e1) / 2;
                return self.textLabelPositionInterpolator(this, r, startAngle, endAngle);
            });

        labels
            .exit()
            .transition()
            .duration(transitionDuration)
            .style("opacity", 0)
            .tween("sweep", function (d, i) {
                const parameters = self.findExitData(d, i);
                const startAngle = (parameters.s0 + parameters.e0) / 2;
                const endAngle = (parameters.s1 + parameters.e1) / 2;
                return self.textLabelPositionInterpolator(this, r, startAngle, endAngle);
            })
            .remove();
    };
}
