import { inject, Injectable, LOCALE_ID } from "@angular/core";

import { ILgFormatter, ILgFormatterOptions } from "../implementation/formatter-types";
import { NumberFormatter } from "../implementation/number-formatter";
import { ILgFormatterFactory } from "./formatter-factory-types";
import { LG_FORMATTER_OPTIONS } from "./lg-formatter-options";

@Injectable({ providedIn: "root" })
export class NumberFormatterFactory implements ILgFormatterFactory {
    private _locale = inject(LOCALE_ID);
    private _localFormatterOptions = inject(LG_FORMATTER_OPTIONS, { optional: true });
    names = ["float"];

    create(defaultOptions: ILgFormatterOptions = {}): ILgFormatter<number> {
        return new NumberFormatter(
            { ...defaultOptions, ...this._localFormatterOptions },
            this._locale
        );
    }
}
