const resolvedPromise = Promise.resolve();

/**
 * Makes sure another change detection cycle will be run. In browsers with native Promise support, the cycle should
 * run before next browser render.
 *
 * @deprecated use queueMicrotask() instead
 */
export function scheduleChangeDetection(): void {
    resolvedPromise.then(() => undefined);
}
