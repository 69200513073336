import { Injectable, ElementRef, Renderer2, NgZone, RendererFactory2, inject } from "@angular/core";
import { Subject, Observable, fromEvent, combineLatest, asapScheduler } from "rxjs";
import { auditTime, map, scan, skip, startWith, takeUntil } from "rxjs/operators";

import {
    LgScrollbarService,
    ScrollbarApi,
    ScrollbarOptionsBase,
    ScrollbarScrollEvent,
    ScrollbarState
} from "./lg-scrollbar.service";
import { LgMeasurementsService } from "./lg-measurements.service";

// ---------------------------------------------------------------------------------------------
//  Interfaces
// ---------------------------------------------------------------------------------------------
export interface ScrollerOptions extends ScrollbarOptionsBase {
    /**
     * Scrollbar offset from the top edge (unused for horizontal scrollers).
     *
     * @default 0
     */
    offsetTop?: number;

    /**
     * Scrollbar offset from the bottom edge (unused for horizontal scrollers)
     *
     * @default 0
     */
    offsetBottom?: number;

    /**
     * Scrollbar offset from the left edge (unused for vertical scrollers)
     *
     * @default 0
     */
    offsetLeft?: number;

    /**
     * Scrollbar offset from the right edge (unused for vertical scrollers)
     *
     * @default 0
     */
    offsetRight?: number;

    /**
     * Corner size. This offset is automatically applied at the bottom and right edge for bidirectional
     * scroller with both scrollbars visible. The offset replaces the specified offsets - so if you use
     * other offsets than 0, you might want to specify this as well. Use 0 to disable the functionality.
     *
     * When not specified, defaults to the width of the scrollbars
     */
    cornerSize?: number;
}

export interface ScrollerPair<T> {
    horizontal: T;
    vertical: T;
}

export interface ScrollbarVisibility {
    direction: "horizontal" | "vertical";
    visible: boolean;
}

/**
 * Scroller types - specifies, which scroll directions are permitted
 */
export type ScrollerType = "horizontal" | "vertical" | "both";

export class ScrollerApi {
    private _horizontalScrollbar: ScrollbarApi | undefined;
    private _verticalScrollbar: ScrollbarApi | undefined;
    private _corner: HTMLElement | undefined;
    private readonly _scrollbarsVisibilityChange$ = new Subject<ScrollbarVisibility>();
    private readonly _destroyed$ = new Subject<void>();

    public constructor(
        private _measurements: LgMeasurementsService,
        private _scrollbar: LgScrollbarService,
        private _ngZone: NgZone,
        private _renderer: Renderer2,
        private _owner: HTMLElement,
        private _options: ScrollerOptions,
        private _type: ScrollerType
    ) {
        this._create();
    }

    /**
     * Update the sizes of the scroller and scrollbar. This should be called whenever the owner changes its dimensions
     */
    resize(): void {
        this._horizontalScrollbar?.setSizes(this._owner.scrollWidth, this._owner.clientWidth);
        this._verticalScrollbar?.setSizes(this._owner.scrollHeight, this._owner.clientHeight);
    }

    /**
     * Get or set the current horizontal scroll position (in pixels)
     */
    scrollLeft(): number;
    scrollLeft(newPosition: number): void;
    scrollLeft(param?: number): number | undefined {
        if (this._horizontalScrollbar !== undefined) {
            return param !== undefined
                ? this._horizontalScrollbar.position(param)
                : this._horizontalScrollbar.position();
        }
        return param === undefined ? 0 : undefined;
    }

    /**
     * Get or set the current vertical scroll position (in pixels)
     */
    scrollTop(): number;
    scrollTop(newPosition: number): void;
    scrollTop(param?: number): number | undefined {
        if (this._verticalScrollbar !== undefined) {
            return param !== undefined
                ? this._verticalScrollbar.position(param)
                : this._verticalScrollbar.position();
        }
        return param === undefined ? 0 : undefined;
    }

    /**
     * Make sure, that the specified element (assumed to be within the scroller) is visible.
     * When verticalOnly is specified, ignore horizontal position (for bidirectional scrollers only)
     */
    ensureVisible(element: ElementRef, verticalOnly = false, verticalBuffer: number = 0): void {
        if (!element) return;
        const offset = element.nativeElement.getBoundingClientRect();
        if (!offset) return;

        const parentOffset = this._owner.getBoundingClientRect();

        if (this._verticalScrollbar !== undefined) {
            const parentScroll = this._verticalScrollbar.position();
            const height = element.nativeElement.clientHeight;
            const parentHeight = this._owner.clientHeight;

            const position = offset.top + parentScroll - parentOffset.top;
            const hscrollbar = this._horizontalScrollbar?.measureWidth(true) ?? 0;

            if (position + height + verticalBuffer > parentScroll + parentHeight - hscrollbar) {
                this._verticalScrollbar.position(
                    position + height + verticalBuffer - (parentHeight - hscrollbar)
                );
            } else if (position - verticalBuffer < parentScroll) {
                this._verticalScrollbar.position(position - verticalBuffer);
            }
        }

        if (this._verticalScrollbar === undefined) verticalOnly = false;

        if (!verticalOnly && this._horizontalScrollbar !== undefined) {
            const parentScroll = this._horizontalScrollbar.position();
            const width = element.nativeElement.clientWidth;
            const parentWidth = this._owner.clientWidth;

            const position = offset.left + parentScroll - parentOffset.left;
            const vscrollbar = this._verticalScrollbar?.measureWidth(true) ?? 0;

            if (position + width > parentScroll + parentWidth - vscrollbar) {
                this._horizontalScrollbar.position(position + width - (parentWidth - vscrollbar));
            } else if (position < parentScroll) {
                this._horizontalScrollbar.position(position);
            }
        }
    }

    /**
     * Get the height of the scrollable content
     */
    documentHeight(): number {
        return this._verticalScrollbar?.documentLength() ?? 0;
    }

    /**
     * Get the width of the scrollable content
     */
    documentWidth(): number {
        return this._horizontalScrollbar?.documentLength() ?? 0;
    }

    /**
     * Get both width and height of the scrollable content
     */
    documentSize(): ScrollerPair<number> {
        return {
            horizontal: this._horizontalScrollbar?.documentLength() ?? 0,
            vertical: this._verticalScrollbar?.documentLength() ?? 0
        };
    }

    /**
     * Get the height of the scrollable
     */
    pageHeight(): number {
        return this._verticalScrollbar?.pageLength() ?? 0;
    }

    /**
     * Get the width of the scrollable
     */
    pageWidth(): number {
        return this._horizontalScrollbar?.pageLength() ?? 0;
    }

    /**
     * Get both width and height of the scrollable
     */
    pageSize(): ScrollerPair<number> {
        return {
            horizontal: this._horizontalScrollbar?.pageLength() ?? 0,
            vertical: this._verticalScrollbar?.pageLength() ?? 0
        };
    }

    /**
     * Returns true if the scrollbar is at the top
     */
    isAtTop(): boolean {
        return this._verticalScrollbar?.isAtTop() ?? true;
    }

    /**
     * Returns true if the scrollbar is at the bottom
     */
    isAtBottom(): boolean {
        return this._verticalScrollbar?.isAtBottom() ?? true;
    }

    /**
     * Returns true if the scrollbar is on the top edge
     */
    isOnLeft(): boolean {
        return this._horizontalScrollbar?.isAtTop() ?? true;
    }

    /**
     * Returns true if the scrollbar is on the right edge
     */
    isOnRight(): boolean {
        return this._horizontalScrollbar?.isAtBottom() ?? true;
    }

    /**
     * Destroy the scroller. It is assumed that this method is called before the target
     * element itself is destroyed.
     */
    destroy(destroyScrollbar?: boolean): void {
        this._horizontalScrollbar?.destroy(destroyScrollbar);
        this._verticalScrollbar?.destroy(destroyScrollbar);
        if (this._renderer.destroyNode && this._corner) {
            this._renderer.destroyNode(this._corner);
        }
        if (destroyScrollbar) {
            // note: this is not quite perfect, ideally we would restore the original style. Do we need to bother?
            const wrapper = this._renderer.parentNode(this._owner);
            this._renderer.removeStyle(wrapper, "overflow");
            this._renderer.removeStyle(wrapper, "position");

            if (this._type !== "horizontal") {
                this._renderer.removeStyle(this._owner, "marginRight");
                this._renderer.removeStyle(this._owner, "overflowY");
            }
            if (this._type !== "vertical") {
                this._renderer.removeStyle(this._owner, "marginBottom");
                this._renderer.removeStyle(this._owner, "overflowX");
            }
            this._renderer.removeClass(this._owner, "lg-scrollbar-is-hidden");
            this._renderer.removeClass(this._owner, "lg-horizontal-scrollbar-is-hidden");

            if (this._corner) {
                this._renderer.removeChild(this._owner, this._corner);
            }
        }
        this._scrollbarsVisibilityChange$.complete();
        this._destroyed$.next();
        this._destroyed$.complete();
    }

    /**
     * Get observable that will be triggered whenever the state (inactive/normal or disabled/normal) of the scrollbar changes.
     * Note that the observable events are triggered outside angular zone, use ngZone.run() if you need the change detection to run
     */
    onStateChange(): Observable<ScrollerPair<ScrollbarState>> {
        if (this._verticalScrollbar !== undefined) {
            if (this._horizontalScrollbar !== undefined) {
                return combineLatest([
                    this._verticalScrollbar.onStateChange(),
                    this._horizontalScrollbar.onStateChange()
                ]).pipe(
                    auditTime(0, asapScheduler),
                    map(([vertical, horizontal]) => ({
                        vertical,
                        horizontal
                    }))
                );
            } else {
                return this._verticalScrollbar.onStateChange().pipe(
                    map(val => ({
                        vertical: val,
                        horizontal: "inactive"
                    }))
                );
            }
        } else {
            return this._horizontalScrollbar!.onStateChange().pipe(
                map(val => ({
                    vertical: "inactive",
                    horizontal: val
                }))
            );
        }
    }

    /**
     * Get the observable, which will be called whenever the current scroll position changes.
     * Note that the observable events are triggered outside angular zone, use ngZone.run() if you need the change detection to run
     */
    onScroll(): Observable<ScrollerPair<ScrollbarScrollEvent>> {
        if (this._verticalScrollbar !== undefined) {
            if (this._horizontalScrollbar !== undefined) {
                return combineLatest([
                    this._verticalScrollbar
                        .onScroll()
                        .pipe(startWith({ position: 0, oldPosition: 0 })),
                    this._horizontalScrollbar
                        .onScroll()
                        .pipe(startWith({ position: 0, oldPosition: 0 }))
                ]).pipe(
                    skip(1),
                    auditTime(0, asapScheduler),
                    map(([vertical, horizontal]) => ({
                        vertical,
                        horizontal
                    })),
                    scan(
                        (acc, current) => {
                            return {
                                vertical: {
                                    position: current.vertical.position,
                                    oldPosition: acc.vertical.position
                                },
                                horizontal: {
                                    position: current.horizontal.position,
                                    oldPosition: acc.horizontal.position
                                }
                            };
                        },
                        {
                            vertical: { position: 0, oldPosition: 0 },
                            horizontal: { position: 0, oldPosition: 0 }
                        }
                    )
                );
            } else {
                return this._verticalScrollbar.onScroll().pipe(
                    map(val => ({
                        vertical: val,
                        horizontal: { position: 0, oldPosition: 0 }
                    }))
                );
            }
        } else {
            return this._horizontalScrollbar!.onScroll().pipe(
                map(val => ({
                    vertical: { position: 0, oldPosition: 0 },
                    horizontal: val
                }))
            );
        }
    }

    /**
     * Get observable that will be triggered whenever the visibility of the scrollbars changes.
     */
    onScrollbarVisibilityChange() {
        return this._scrollbarsVisibilityChange$.asObservable();
    }

    /**
     * Return or change the class of the root scrollbar element. "lg-scrollbar" is the default
     */
    scrollbarClass(): string;
    scrollbarClass(className: string): string;
    scrollbarClass(param?: string): string | undefined {
        if (param === undefined) {
            return (this._verticalScrollbar ?? this._horizontalScrollbar)!.scrollbarClass();
        } else {
            this._verticalScrollbar?.scrollbarClass(param);
            this._horizontalScrollbar?.scrollbarClass(param);
            return undefined;
        }
    }

    private _create(): void {
        const scrollbarWidth = this._measurements.scrollbarWidth();
        const wrapper = this._renderer.parentNode(this._owner);
        this._renderer.setStyle(wrapper, "overflow", "hidden");
        this._renderer.setStyle(wrapper, "position", "relative");

        if (this._type !== "vertical") {
            this._renderer.setStyle(this._owner, "marginBottom", -scrollbarWidth + "px");
            this._renderer.setStyle(this._owner, "overflowX", "scroll");
            this._horizontalScrollbar = this._scrollbar.createHorizontal(new ElementRef(wrapper), {
                ...this._options,
                offsetStart: this._options.offsetLeft!,
                offsetEnd: this._options.offsetRight!
            });
            this._horizontalScrollbar
                .onScroll()
                .pipe(takeUntil(this._destroyed$))
                .subscribe(pos => {
                    this._renderer.setProperty(this._owner, "scrollLeft", pos.position);
                });
            this._horizontalScrollbar
                .onStateChange()
                .pipe(takeUntil(this._destroyed$))
                .subscribe(newState => {
                    if (newState === "inactive") {
                        this._renderer.addClass(this._owner, "lg-horizontal-scrollbar-is-hidden");
                        this._scrollbarsVisibilityChange$.next({
                            direction: "horizontal",
                            visible: false
                        });
                    } else {
                        this._renderer.removeClass(
                            this._owner,
                            "lg-horizontal-scrollbar-is-hidden"
                        );
                        this._scrollbarsVisibilityChange$.next({
                            direction: "horizontal",
                            visible: true
                        });
                    }
                });
        } else {
            this._renderer.addClass(this._owner, "lg-horizontal-scrollbar-is-hidden");
        }

        if (this._type !== "horizontal") {
            this._renderer.setStyle(this._owner, "marginRight", -scrollbarWidth + "px");
            this._renderer.setStyle(this._owner, "overflowY", "scroll");
            this._verticalScrollbar = this._scrollbar.createVertical(new ElementRef(wrapper), {
                ...this._options,
                offsetStart: this._options.offsetTop!,
                offsetEnd: this._options.offsetBottom!
            });
            this._verticalScrollbar
                .onScroll()
                .pipe(takeUntil(this._destroyed$))
                .subscribe(pos => {
                    this._renderer.setProperty(this._owner, "scrollTop", pos.position);
                });
            this._verticalScrollbar
                .onStateChange()
                .pipe(takeUntil(this._destroyed$))
                .subscribe(newState => {
                    if (newState === "inactive") {
                        this._renderer.addClass(this._owner, "lg-scrollbar-is-hidden");
                        this._scrollbarsVisibilityChange$.next({
                            direction: "vertical",
                            visible: false
                        });
                    } else {
                        this._renderer.removeClass(this._owner, "lg-scrollbar-is-hidden");
                        this._scrollbarsVisibilityChange$.next({
                            direction: "vertical",
                            visible: true
                        });
                    }
                });
        } else {
            this._renderer.addClass(this._owner, "lg-scrollbar-is-hidden");
        }

        if (this._type === "both" && this._options.cornerSize !== 0) {
            if (this._options.cornerSize == null)
                this._options.cornerSize = this._verticalScrollbar!.measureWidth();

            this._corner = this._renderer.createElement("div");
            this._renderer.addClass(this._corner, "lg-scrollbar-corner");
            this._renderer.setStyle(this._corner, "width", this._options.cornerSize + "px");
            this._renderer.setStyle(this._corner, "height", this._options.cornerSize + "px");
            this._renderer.appendChild(this._owner, this._corner);

            let shifted = false;
            combineLatest([
                this._verticalScrollbar!.onStateChange(),
                this._horizontalScrollbar!.onStateChange()
            ])
                .pipe(takeUntil(this._destroyed$))
                .subscribe(([vertical, horizontal]) => {
                    const shift = vertical !== "inactive" && horizontal !== "inactive";
                    if (shift !== shifted) {
                        shifted = shift;
                        this._verticalScrollbar!.offsetEnd(
                            shift ? this._options.cornerSize! : this._options.offsetBottom!
                        );
                        this._verticalScrollbar!.recalculate();
                        this._horizontalScrollbar!.offsetEnd(
                            shift ? this._options.cornerSize! : this._options.offsetRight!
                        );
                        this._horizontalScrollbar!.recalculate();
                        this._renderer.setStyle(this._corner, "display", shift ? "block" : "none");
                    }
                });
        }

        this._ngZone.runOutsideAngular(() => {
            fromEvent(this._owner, "scroll")
                .pipe(takeUntil(this._destroyed$))
                .subscribe(() => {
                    if (this._verticalScrollbar !== undefined) {
                        const current = this._owner.scrollTop;
                        if (current !== this._verticalScrollbar.position()) {
                            this._verticalScrollbar.position(current);
                        }
                    }
                    if (this._horizontalScrollbar !== undefined) {
                        const current = this._owner.scrollLeft;
                        if (current !== this._horizontalScrollbar.position()) {
                            this._horizontalScrollbar.position(current);
                        }
                    }
                });
        });

        this.resize();
    }
}

export class ScrollerApi1d {
    constructor(
        private _api: ScrollerApi,
        private _vertical: boolean
    ) {}

    /**
     * Update the sizes of the scroller and scrollbar. This should be called whenever the owner changes its dimensions
     */
    resize(): void {
        this._api.resize();
    }

    /**
     * Get or set  the current scroll position (in pixels)
     */
    position(): number;
    position(newPosition: number): void;
    position(param?: number): number | void {
        if (this._vertical) {
            return param !== undefined ? this._api.scrollTop(param) : this._api.scrollTop();
        } else {
            return param !== undefined ? this._api.scrollLeft(param) : this._api.scrollLeft();
        }
    }

    /**
     * Make sure, that the specified element (assumed to be within the scroller) is visible.
     */
    ensureVisible(element: ElementRef): void {
        this._api.ensureVisible(element);
    }

    /**
     * Get the height of the scrollable content
     */
    documentLength(): number {
        if (this._vertical) {
            return this._api.documentHeight();
        } else {
            return this._api.documentWidth();
        }
    }

    /**
     * Get the height of the scrollable
     */
    pageLength(): number {
        if (this._vertical) {
            return this._api.pageHeight();
        } else {
            return this._api.pageWidth();
        }
    }

    /**
     * Returns true if the scrollbar is at the top
     */
    isAtTop(): boolean {
        return this._api.isAtTop();
    }

    /**
     * Returns true if the scrollbar is at the bottom
     */
    isAtBottom(): boolean {
        return this._api.isAtBottom();
    }

    /**
     * Destroy the scroller. Note that this doesn't fully reverse the changes done by the creation. It is assumed that this
     * method is called before the target element itself is destroyed.
     */
    destroy(destroyScrollbar?: boolean): void {
        this._api.destroy(destroyScrollbar);
    }

    /**
     * Get observable that will be triggered whenever the state (inactive/normal or disabled/normal) of the scrollbar changes.
     * Note that the observable events are triggered outside angular zone, use ngZone.run() if you need the change detection to run
     */
    onStateChange(): Observable<ScrollbarState> {
        if (this._vertical) {
            return this._api.onStateChange().pipe(map(({ vertical }) => vertical));
        } else {
            return this._api.onStateChange().pipe(map(({ horizontal }) => horizontal));
        }
    }

    /**
     * Get the observable, which will be called whenever the current scroll position changes.
     * Note that the observable events are triggered outside angular zone, use ngZone.run() if you need the change detection to run
     */
    onScroll(): Observable<ScrollbarScrollEvent> {
        if (this._vertical) {
            return this._api.onScroll().pipe(map(({ vertical }) => vertical));
        } else {
            return this._api.onScroll().pipe(map(({ horizontal }) => horizontal));
        }
    }

    /**
     * Return or change the class of the root scrollbar element. "lg-scrollbar" is the default
     */
    scrollbarClass(): string;
    scrollbarClass(className: string): any;
    scrollbarClass(param?: string): string | undefined {
        return param !== undefined ? this._api.scrollbarClass(param) : this._api.scrollbarClass();
    }
}

// ---------------------------------------------------------------------------------------------
//  Implementation
// ---------------------------------------------------------------------------------------------
@Injectable({ providedIn: "root" })
export class LgScrollerService {
    private _measurements = inject(LgMeasurementsService);
    private _ngZone = inject(NgZone);
    private _scrollbar = inject(LgScrollbarService);
    private _renderer: Renderer2;

    public constructor() {
        const rendererFactory = inject(RendererFactory2);
        this._renderer = rendererFactory.createRenderer(null, null);
    }

    /**
     * Turn the specified object into a vertical scroller. The behaviour is that the specified DOM element will be scrolled within its
     * parent, using the custom logex scrollbar. The change involves adding "overflow:hidden" to the target's parent, and also
     * attaching the scrollbar under the parent. This specifically means that in order to be useable, the parent should have
     * fixed or maximum height specified, and absolute or relative css position.
     */
    createVertical(owner: ElementRef, options?: ScrollerOptions): ScrollerApi1d {
        options = options || {};
        const api = new ScrollerApi(
            this._measurements,
            this._scrollbar,
            this._ngZone,
            this._renderer,
            owner.nativeElement,
            options,
            "vertical"
        );
        return new ScrollerApi1d(api, true);
    }

    /**
     * Turn the specified object into a horizontal scroller. The behaviour is that the specified DOM element will be scrolled within its
     * parent, using the custom logex scrollbar. The change involves adding "overflow:hidden" to the target's parent, and also
     * attaching the scrollbar under the parent. This specifically means that in order to be useable, the parent should have
     * fixed or maximum height specified, and absolute or relative css position.
     */
    createHorizontal(owner: ElementRef, options?: ScrollerOptions): ScrollerApi1d {
        options = options || {};
        const api = new ScrollerApi(
            this._measurements,
            this._scrollbar,
            this._ngZone,
            this._renderer,
            owner.nativeElement,
            options,
            "horizontal"
        );
        return new ScrollerApi1d(api, false);
    }

    /**
     * Turn the specified object into a scroller. The behaviour is that the specified DOM element will be scrolled within its
     * parent, using the custom logex scrollbar. The change involves adding "overflow:hidden" to the target's parent, and also
     * attaching the scrollbar under the parent. This specifically means that in order to be useable, the parent should have
     * fixed or maximum width or  height specified (depending on its type), and absolute or relative css position.
     *
     * For 1 dimensional scrollers (vertical or horizontal), the api behaves like if the document had size 0 in the other dimension
     * (so scrollbars will be "inactive", positions will be always 0 etc).
     */
    create(owner: ElementRef, type: ScrollerType, options?: ScrollerOptions): ScrollerApi {
        options = options || {};
        const api = new ScrollerApi(
            this._measurements,
            this._scrollbar,
            this._ngZone,
            this._renderer,
            owner.nativeElement,
            options,
            type
        );
        return api;
    }
}
