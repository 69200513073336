import { Directive, EventEmitter, inject, OnDestroy, Output } from "@angular/core";
import { NgModel } from "@angular/forms";
import { Subscription } from "rxjs";
import { distinctUntilChanged } from "rxjs/operators";

@Directive({
    standalone: true,
    selector: "[lgOnValidityChange][ngModel]"
})
export class LgOnValidityChangeDirective implements OnDestroy {
    private _ngModel = inject(NgModel);
    @Output("lgOnValidityChange") public readonly validityChange = new EventEmitter<boolean>(true);

    private _subscription: Subscription | undefined;

    constructor() {
        this._subscription = this._ngModel.statusChanges
            ?.pipe(distinctUntilChanged())
            .subscribe(() => {
                this.validityChange.next(this._ngModel.valid ?? false);
            });
    }

    ngOnDestroy(): void {
        this._subscription?.unsubscribe();
    }
}
