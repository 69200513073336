<span *ngIf="_header" class="lg-help-info-popup__header">{{ _header }}</span>
<span
    *ngIf="description"
    class="lg-help-info-popup__description"
    [innerHTML]="_sanitizedDescription"
>
</span>
<ng-template cdkPortalOutlet #outlet></ng-template>
<ul *ngIf="infoLinks" class="lg-help-info-popup__links">
    <li *ngFor="let link of infoLinks" class="lg-help-info-popup__links__item">
        <a [href]="link.url" target="_blank">
            <lg-icon
                class="lg-help-info-popup__links__item__icon"
                [icon]="link.icon"
                [inline]="true"
            >
            </lg-icon>
            {{ link.text | lgTranslate }}
        </a>
    </li>
</ul>
