/* eslint-disable */
export enum LgFrameworkIcons {
    AbsLocked = "icon-abs-locked",
    Absolutefigures = "icon-absolutefigures",
    Account = "icon-account",
    AccountVerified = "icon-account-verified",
    Add = "icon-add",
    AddRegel = "icon-add-regel",
    Alphabetically = "icon-alphabetically",
    AlphabeticallyDesc = "icon-alphabetically-desc",
    Amountfigures = "icon-amountfigures",
    ArrowCurvedBig = "icon-arrow-curved-big",
    ArrowDown = "icon-arrow-down",
    ArrowDown2 = "icon-arrow-down2",
    ArrowDrillDown = "icon-arrow-drill-down",
    ArrowDrillDown1 = "icon-arrow-drill-down1",
    ArrowLeft = "icon-arrow-left",
    ArrowRight = "icon-arrow-right",
    ArrowRight2 = "icon-arrow-right2",
    ArrowUp = "icon-arrow-up",
    AuditAdd = "icon-audit-add",
    AuditBuildup = "icon-audit-buildup",
    AuditRemove = "icon-audit-remove",
    AuditTrail = "icon-audit-trail",
    AuditUpdate = "icon-audit-update",
    BarChart = "icon-bar-chart",
    BarChartHorizontal = "icon-bar-chart-horizontal",
    Base = "icon-base",
    BestPractices = "icon-best-practices",
    Bold = "icon-bold",
    Bookmark = "icon-bookmark",
    BookmarkUpdated = "icon-bookmark-updated",
    Boxplot = "icon-boxplot",
    BoxplotHorizontal = "icon-boxplot-horizontal",
    Building = "icon-building",
    BulletList = "icon-bullet-list",
    Calculator = "icon-calculator",
    Calendar = "icon-calendar",
    CalendarChain = "icon-calendar-chain",
    CalendarEdit = "icon-calendar-edit",
    CalendarHeader = "icon-calendar-header",
    CancelledState = "icon-cancelled-state",
    Categories = "icon-categories",
    ChangeOrder = "icon-change-order",
    ChatBot = "icon-chat-bot",
    ChatBot0 = "icon-chat-bot-0",
    ChatBot1 = "icon-chat-bot-1",
    Check = "icon-check",
    CheckThick = "icon-check-thick",
    Circle = "icon-circle",
    CircleCheck = "icon-circle-check",
    Close = "icon-close",
    Comment = "icon-comment",
    CommentAdd = "icon-comment-add",
    CommentEdit = "icon-comment-edit",
    ConfidenceInterval = "icon-confidence-interval",
    ContractCeiling = "icon-contract-ceiling",
    ContractCeilingSubstitution = "icon-contract-ceiling-substitution",
    ContractMovedBoth = "icon-contract-moved-both",
    ContractMovedFrom = "icon-contract-moved-from",
    ContractMovedTo = "icon-contract-moved-to",
    ContractPq = "icon-contract-pq",
    ContractShared = "icon-contract-shared",
    ContractStairs = "icon-contract-stairs",
    ContractSum = "icon-contract-sum",
    ContractUitleenFrom = "icon-contract-uitleen-from",
    ContractUitleenTo = "icon-contract-uitleen-to",
    Copy = "icon-copy",
    CreatedNotStartedState = "icon-created-not-started-state",
    CreatingState = "icon-creating-state",
    DataChanged = "icon-data-changed",
    DataStorage = "icon-data-storage",
    Datasource = "icon-datasource",
    Delete = "icon-delete",
    DoorbelastingExternal = "icon-doorbelasting-external",
    DoorbelastingFrom = "icon-doorbelasting-from",
    DoorbelastingTo = "icon-doorbelasting-to",
    Doorbleasting = "icon-doorbleasting",
    DotIndicator = "icon-dot-indicator",
    Dots = "icon-dots",
    Download = "icon-download",
    Draggable = "icon-draggable",
    Duplicate = "icon-duplicate",
    Edit = "icon-edit",
    EmptyStar = "icon-empty-star",
    Erase = "icon-erase",
    Error = "icon-error",
    ErrorBelow = "icon-error-below",
    ErrorX = "icon-error-x",
    ExcludeRow = "icon-exclude-row",
    Expand = "icon-expand",
    Experiment = "icon-experiment",
    Export = "icon-export",
    Eye = "icon-eye",
    EyeCrossed = "icon-eye-crossed",
    EyeSelected = "icon-eye-selected",
    Faq = "icon-faq",
    Filter = "icon-filter",
    FilterEmpty = "icon-filter-empty",
    Filter_full = "icon-filter_full",
    FiltersAdd = "icon-filters-add",
    FlagEmpty = "icon-flag-empty",
    FlagFilled = "icon-flag-filled",
    Folder = "icon-folder",
    FullscreenEnter = "icon-fullscreen-enter",
    FullscreenEnter2 = "icon-fullscreen-enter2",
    FullscreenExit = "icon-fullscreen-exit",
    FullscreenExit2 = "icon-fullscreen-exit2",
    Gear = "icon-gear",
    GliEmr = "icon-gli-emr",
    GliSurvey = "icon-gli-survey",
    GoBack = "icon-go-back",
    GoToDate = "icon-go-to-date",
    GraphFilter = "icon-graph-filter",
    Grid = "icon-grid",
    H1 = "icon-h1",
    H2 = "icon-h2",
    Help = "icon-help",
    HelpGliEmr = "icon-help-gli-emr",
    Helpcenter = "icon-helpcenter",
    Hidden = "icon-hidden",
    Hierarchy = "icon-hierarchy",
    Home = "icon-home",
    Impersonation = "icon-impersonation",
    InReview = "icon-in-review",
    Indent = "icon-indent",
    Info = "icon-info",
    Input = "icon-input",
    InputLocked = "icon-input-locked",
    Invert = "icon-invert",
    Italic = "icon-italic",
    LayoutChange = "icon-layout-change",
    Lightbulb = "icon-lightbulb",
    Line = "icon-line",
    LineChart = "icon-line-chart",
    Link = "icon-link",
    LocalSettings = "icon-local-settings",
    Lock = "icon-lock",
    LockAbove = "icon-lock-above",
    LockBelow = "icon-lock-below",
    LockElsewhere = "icon-lock-elsewhere",
    LockInScenario = "icon-lock-in-scenario",
    LockSmall = "icon-lock-small",
    LogOut = "icon-log-out",
    Manual = "icon-manual",
    MapChart = "icon-map-chart",
    Menu = "icon-menu",
    Merge = "icon-merge",
    Message = "icon-message",
    Minus = "icon-minus",
    Money = "icon-money",
    MoneyAdd = "icon-money-add",
    MoneyEur = "icon-money-eur",
    MoneyGbp = "icon-money-gbp",
    MoneyLocked = "icon-money-locked",
    MoneyUniversal = "icon-money-universal",
    MoveContract = "icon-move-contract",
    NoMoney = "icon-no-money",
    NumberedList = "icon-numbered-list",
    OpenInNew = "icon-open-in-new",
    Overall = "icon-overall",
    Overtime = "icon-overtime",
    Paragraph = "icon-paragraph",
    ParentalLeave = "icon-parental-leave",
    PasswordSet = "icon-password-set",
    Paste = "icon-paste",
    Patient = "icon-patient",
    PieChart = "icon-pie-chart",
    Pin = "icon-pin",
    Pin_down = "icon-pin_down",
    PivotDot = "icon-pivot-dot",
    PivotMinus = "icon-pivot-minus",
    PivotPlus = "icon-pivot-plus",
    Play = "icon-play",
    Plus = "icon-plus",
    Pregnant = "icon-pregnant",
    Pricefigures = "icon-pricefigures",
    Pricelist = "icon-pricelist",
    PricelistAdd = "icon-pricelist-add",
    PricelistEdit = "icon-pricelist-edit",
    PricelistSettings = "icon-pricelist-settings",
    ProductLogo36x36_bgm = "icon-product-logo-36x36_bgm",
    ProductLogo36x36_default = "icon-product-logo-36x36_default",
    ProductLogo36x36_dm = "icon-product-logo-36x36_dm",
    ProductLogo36x36_ohw = "icon-product-logo-36x36_ohw",
    ProductLogo36x36_uploadsystem = "icon-product-logo-36x36_uploadsystem",
    QueuedState = "icon-queued-state",
    RadioButtonChecked = "icon-radio-button-checked",
    RadioButtonUnchecked = "icon-radio-button-unchecked",
    Recalculation = "icon-recalculation",
    RecalculationCheck = "icon-recalculation-check",
    RecalculationErr = "icon-recalculation-err",
    RecalculationPending = "icon-recalculation-pending",
    Recent = "icon-recent",
    Regel = "icon-regel",
    RegelNewHierarchy = "icon-regel-new-hierarchy",
    Relativefigures = "icon-relativefigures",
    Removing = "icon-removing",
    Rule = "icon-rule",
    RuleAdd = "icon-rule-add",
    RunningState = "icon-running-state",
    Scales = "icon-scales",
    Search = "icon-search",
    SettingContinue = "icon-setting-continue",
    SettingStop = "icon-setting-stop",
    Settings = "icon-settings",
    Show = "icon-show",
    SidebarExport = "icon-sidebar-export",
    SidebarHelp = "icon-sidebar-help",
    SidebarScenario = "icon-sidebar-scenario",
    SidebarTicketManager = "icon-sidebar-ticket-manager",
    Sliders = "icon-sliders",
    SnoozeEmpty = "icon-snooze-empty",
    SnoozeFull = "icon-snooze-full",
    Sort = "icon-sort",
    SortAsc = "icon-sort-asc",
    SortDesc = "icon-sort-desc",
    SortIdAsc = "icon-sort-id-asc",
    SortIdDesc = "icon-sort-id-desc",
    Spanner = "icon-spanner",
    Specialism = "icon-specialism",
    Split = "icon-split",
    StackedBarChart = "icon-stacked-bar-chart",
    Star = "icon-star",
    Stopwatch = "icon-stopwatch",
    StopwatchCall = "icon-stopwatch-call",
    StopwatchCancel = "icon-stopwatch-cancel",
    StopwatchCancelCall = "icon-stopwatch-cancel-call",
    SuspendedState = "icon-suspended-state",
    SwitchedOff = "icon-switched-off",
    SwitchedOn = "icon-switched-on",
    Sync = "icon-sync",
    SyncScenario = "icon-sync-scenario",
    Table = "icon-table",
    Time = "icon-time",
    TimeToggle = "icon-time-toggle",
    TimeToggle1 = "icon-time-toggle1",
    ToolIconController = "icon-toolIcon-controller",
    ToolIconEmm = "icon-toolIcon-emm",
    ToolIconEmmOld = "icon-toolIcon-emm-old",
    ToolIconFcm = "icon-toolIcon-fcm",
    ToolIconForecastmodel_msb = "icon-toolIcon-forecastmodel_msb",
    ToolIconKpm = "icon-toolIcon-kpm",
    ToolIconRmm = "icon-toolIcon-rmm",
    TrendDown = "icon-trend-down",
    TrendUp = "icon-trend-up",
    TwoColumnLayout = "icon-two-column-layout",
    TwoRowLayout = "icon-two-row-layout",
    Underline = "icon-underline",
    Undo = "icon-undo",
    Unindent = "icon-unindent",
    UnknownState = "icon-unknown-state",
    Unlock = "icon-unlock",
    Upload = "icon-upload",
    UsedArrow = "icon-used-arrow",
    User = "icon-user",
    UserAdd = "icon-user-add",
    UserAddMultiple = "icon-user-add-multiple",
    UserEdit = "icon-user-edit",
    UserRemove = "icon-user-remove",
    UserTemplate = "icon-user-template",
    Warning = "icon-warning",
    WarningBelow = "icon-warning-below",
    WarningHereBelow = "icon-warning-here-below",
    Webinar = "icon-webinar",
    Workflows = "icon-workflows",
    X = "icon-x"
}
