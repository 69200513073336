import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    HostBinding,
    inject,
    Input,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { LgDropdownComponent } from "@logex/framework/ui-core";

import { LgExportService } from "./lg-export.service";

@Component({
    selector: "lg-export-menu",
    template: `
        <lg-icon icon="icon-export"></lg-icon>
        <div #target [hidden]="true"></div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    host: {
        "[class.lg-icon-menu--active]": "_active"
    }
})
export class LgExportMenuComponent<T extends number | string> extends LgDropdownComponent<T> {
    private _exportService = inject(LgExportService);

    @ViewChild("target", { static: true }) target!: ElementRef;

    @Input() url?: string;
    @Input() downloadParams?: BehaviorSubject<object>;
    @Input() class = "";

    @HostBinding("class")
    get outerClass(): string {
        return `lg-icon-menu ${this.class}`;
    }

    // ----------------------------------------------------------------------------------
    //
    constructor() {
        super();
        this.hideSearch = true;
        this.matchWidth = false;
        this._attachOnLeft = false;
        this.popupClassName = "lg-dropdown-popup lg-dropdown-popup--icon-menu";
        this.registerOnChange(this._onSelect);
    }

    // ----------------------------------------------------------------------------------
    //
    private _onSelect(id: T): void {
        if (this.definition && this.definition.lookup) {
            const element = this.definition.lookup[id as string];

            if (element.url) {
                this._exportService.downloadExport(element.url, element.params);
            } else if (element.callback) {
                element.callback();
            } else {
                throw Error(
                    "Download item should specify either a 'url' with 'params' or a 'callback'."
                );
            }
        }

        this.writeValue(null);
    }
}
