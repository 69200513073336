import {
    Component,
    ViewEncapsulation,
    ChangeDetectionStrategy,
    Input,
    HostBinding,
    OnInit,
    OnChanges,
    inject
} from "@angular/core";
import { toBoolean } from "@logex/framework/utilities";
import { LgSimpleChanges } from "@logex/framework/types";
import { LgIconRemappingService } from "./lg-icon-remapping.service";
import { NgClass } from "@angular/common";

@Component({
    standalone: true,
    selector: "lg-icon",
    template: `
        <svg viewBox="0 0 24 24" [ngClass]="svgClass">
            <use [attr.xlink:href]="'#' + icon"></use>
        </svg>
    `,
    imports: [NgClass],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LgIconComponent implements OnChanges, OnInit {
    private _iconRemapper = inject(LgIconRemappingService);

    /**
     * Specifies icon.
     */
    @Input({ required: true }) set icon(value: string | null | undefined) {
        this._icon = this._iconRemapper.remap(value ?? "");
    }

    get icon(): string | null {
        return this._icon;
    }

    /**
     * Specifies if icon is clickable.
     *
     * @default false
     */
    @Input()
    set clickable(value: boolean) {
        this._clickable = toBoolean(value);
    }

    get clickable(): boolean {
        return this._clickable;
    }

    /**
     * Specifies if icon is displayed inline.
     *
     * @default false
     */
    @Input()
    set inline(value: boolean) {
        this._inline = toBoolean(value);
    }

    get inline(): boolean {
        return this._inline;
    }

    /**
     * Icon width (in pixels).
     */
    @Input()
    set width(value: number | string) {
        this._width = this._parseSize(value);
        if (this._height === null) this._height = this._width;
    }

    get width(): string | number | null {
        return this._width;
    }

    /**
     * Icon height (in pixels).
     */
    @Input()
    set height(value: number | string) {
        this._height = this._parseSize(value);
        if (this._width === null) this._width = this._height;
    }

    get height(): string | number | null {
        return this._width;
    }

    /**
     * Icon size
     */
    @Input() size: "regular" | "medium" = "regular";

    /**
     * Icon type
     */
    @Input("type") type:
        | "regular"
        | "success"
        | "warning"
        | "info"
        | "error"
        | "highlighted"
        | "disabled" = "regular";

    /**
     * Applies css class to icon host.
     */
    @Input() class?: string | undefined;

    /**
     * Applies css class to icon svg.
     */
    @Input() svgClass?: any;

    @HostBinding("style.width") _width: string | number | null = null;
    @HostBinding("style.height") _height: string | number | null = null;
    @HostBinding("class") _composedClass!: string;

    _icon = "";

    private _clickable = false;
    private _inline = false;

    ngOnInit(): void {
        if (this._composedClass === undefined) this._composeClass();
    }

    ngOnChanges(changes: LgSimpleChanges<LgIconComponent>): void {
        if (changes.class || changes.size || changes.clickable || changes.type) {
            this._composeClass();
        }
    }

    protected _getMainClassName(): string {
        return "lg-icon";
    }

    private _composeClass(): void {
        const list = [this._getMainClassName()];

        if (this._clickable) list.push("clickable");
        if (this._inline) list.push("lg-icon--inline");

        switch (this.size) {
            case "regular":
            default:
                list.push("lg-icon--regular");
                break;
            case "medium":
                list.push("lg-icon--medium");
        }

        switch (this.type) {
            case "regular":
            default:
                break;
            case "error":
                list.push("lg-icon--error ");
                break;
            case "info":
                list.push("lg-icon--info");
                break;
            case "warning":
                list.push("lg-icon--warning");
                break;
            case "success":
                list.push("lg-icon--success");
                break;
            case "highlighted":
                list.push("lg-icon--highlighted");
                break;
            case "disabled":
                list.push("lg-icon--disabled");
                break;
        }

        if (this.class) list.push(this.class);

        this._composedClass = list.join(" ");
    }

    private _parseSize(size: number | string): string {
        const asNumber = +size;
        // eslint-disable-next-line eqeqeq
        if (size == asNumber) {
            return asNumber + "px";
        }

        return asNumber.toString();
    }
}
