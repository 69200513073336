import {
    Directive,
    ElementRef,
    EventEmitter,
    HostListener,
    inject,
    Input,
    Output
} from "@angular/core";
import { toBoolean } from "@logex/framework/utilities";

@Directive({
    selector: "[lgPressEnter]"
})
export class LgPressEnterDirective {
    private _el = inject(ElementRef);

    /**
     * Specifies if action has to be performed on Ctrl(Cmd)+Enter key press.
     *
     * @param controlKey default = undefined
     */
    @Input() lgControlKey?: string | boolean | null;

    /**
     * Emits the event when input has been submitted by pressing the Enter key
     *
     * @param lgPressEnter
     */
    @Output("lgPressEnter") readonly lgPressEnter = new EventEmitter<any>();

    @HostListener("keydown", ["$event"])
    _onKeyDown(event: KeyboardEvent): void {
        if (event.key === "Enter") {
            if (!toBoolean(this.lgControlKey) || event.ctrlKey || event.metaKey) {
                this._onPressEnter();
            }
        }
    }

    _onPressEnter(): void {
        const input =
            this._el.nativeElement instanceof HTMLInputElement
                ? this._el.nativeElement
                : this._el.nativeElement.getElementsByTagName("input")[0];

        if (input == null) return;

        if (document.activeElement === input) {
            this.lgPressEnter.emit();
        }
    }
}
