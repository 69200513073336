/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from "@angular/core";
import {
    ApplicationTraceSeverity,
    LgApplicationEventTracerNames
} from "./application-event-tracer";

export interface IApplicationEventTracerHubFilter {
    sendPageChange(trackerName: string): boolean;
    sendTrackEvent(
        trackerName: string,
        category: string,
        action: string,
        label?: string,
        value?: number
    ): boolean;
    sendTrackTime(
        trackerName: string,
        category: string,
        variable: string,
        value: number,
        label?: string
    ): boolean;
}

@Injectable({ providedIn: "root" })
export class ApplicationEventTracerHubFilter implements IApplicationEventTracerHubFilter {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public sendPageChange(trackerName: string): boolean {
        return true;
    }

    public sendTrackEvent(
        trackerName: string,
        category: string,
        action: string,
        label?: string,
        value?: number
    ): boolean {
        return true;
    }

    public sendTrackTime(
        trackerName: string,
        category: string,
        variable: string,
        value: number,
        label?: string
    ): boolean {
        return true;
    }

    public sendTrackTrace(
        trackerName: string,
        severity: ApplicationTraceSeverity,
        message: string,
        customProperties?: unknown
    ): boolean {
        return trackerName === LgApplicationEventTracerNames.ApplicationInsights;
    }

    public sendTrackException(
        trackerName: string,
        exception: Error,
        customProperties?: unknown
    ): boolean {
        return trackerName === LgApplicationEventTracerNames.ApplicationInsights;
    }
}
