import { InjectionToken } from "@angular/core";
import { Observable } from "rxjs";

export const LG_FEATURES = new InjectionToken<ILgFeatures>("LG_FEATURES");

export interface ILgFeatures {
    readonly onChanged: Observable<void>;
    load(params: Record<string, string | number | boolean>): Promise<void>;
    getFeature<T = any>(name: string): Feature<T>;
    getFeaturesByType<T = any>(typeName: string): Array<Feature<T>>;
    isFeatureEnabled(name: string): boolean;
}

export interface Feature<Configuration = any> {
    name: string;
    typeName: string | null;
    isEnabled: boolean;
    configuration: Configuration | null;
}
