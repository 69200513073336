import { CoreModule } from "@logex/framework/core";
import { UiCoreModule } from "@logex/framework/ui-core";

import { LgAreaChartComponent } from "./lg-area-chart/lg-area-chart.component";
import { LgBubbleChartComponent } from "./lg-bubble-chart/lg-bubble-chart.component";
import { LgComparePieChartComponent } from "./lg-compare-pie-chart/lg-compare-pie-chart.component";
import { LgComparePieChartMiniComponent } from "./lg-compare-pie-chart-mini/lg-compare-pie-chart-mini.component";
import { LgGroupedBarChartComponent } from "./lg-grouped-bar-chart/lg-grouped-bar-chart.component";
import { LgGroupedBarHorizontalChartComponent } from "./lg-grouped-bar-horizontal/lg-grouped-bar-horizontal-chart.component";
import { LgGrowthBarChartComponent } from "./lg-growth-bar-chart/lg-growth-bar-chart.component";
import { LgIconChartComponent } from "./lg-icon-chart/lg-icon-chart.component";
import { LgLineChartComponent } from "./lg-line-chart/lg-line-chart.component";
import { LgStackedBarChartComponent } from "./lg-stacked-bar-chart/lg-stacked-bar-chart.component";
import { LgWaterFallChartComponent } from "./lg-waterfall-chart/lg-waterfall-chart.component";
import { LgChartLegendComponent } from "./shared/lg-chart-legend.component";
import {
    DEFAULT_BACKGROUND_COLOR_FOR_OVERLAPS,
    DEFAULT_CHART_VALUE_TYPE_DICTIONARY,
    DEFAULT_PALETTE,
    LG_BACKGROUND_COLOR_FOR_OVERLAPS,
    LG_CHART_COLOR_PALETTE,
    LG_CHART_VALUE_TYPE_DICTIONARY,
    LgColorPalette,
    LG_INSURER_COLORS,
    INSURERS_COLORS
} from "./shared/lg-color-palette";
import { LineDataConverter } from "./lg-line-chart/LineDataConverter";
import { LgColorScaleBarComponent } from "./lg-color-scale-bar/lg-color-scale-bar.component";
import { LgLocalizationModule } from "@logex/framework/lg-localization";
import { LgBoxplotVerticalChartComponent } from "./lg-boxplot/lg-boxplot-vertical/lg-boxplot-vertical-chart.component";
import { LgBoxplotHorizontalChartComponent } from "./lg-boxplot/lg-boxplot-horizontal/lg-boxplot-horizontal-chart.component";
import { LgStackedBarHorizontalChartComponent } from "./lg-stacked-bar-horizontal-chart/lg-stacked-bar-horizontal-chart.component";
import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { LgSankeyChartComponent } from "./lg-sankey-chart/lg-sankey-chart.component";
import { LgScatterChartComponent } from "./lg-scatter-chart/lg-scatter-chart.component";
import { LgFunnelChartComponent } from "./lg-funnel-chart/lg-funnel-chart.component";
import { LgHistogramChartComponent } from "./lg-histogram/lg-histogram-chart.component";
import {
    LG_USE_NEW_COLOR_PALETTE,
    LG_USE_NEW_LABELS
} from "./shared/lg-color-palette-v2/lg-colors.types";
import { LgChartExportContainerDirective } from "./shared/lg-chart-export/lg-chart-export-container.directive";
import { LgChartExportComponent } from "./shared/lg-chart-export/lg-chart-export.component";
import { LgChartIconComponent } from "./shared/lg-chart-icon/lg-chart-icon.component";
import { LgChartTemplateContextTypeDirective } from "./shared/lg-chart-template-context.directive";
import { LgSingleValueChartComponent } from "./lg-single-value-chart/lg-single-value-chart.component";

export interface LgChartsModuleOptions {
    useNewColorPalette?: boolean;
    useNewLabels?: boolean;
}

@NgModule({
    imports: [CommonModule, CoreModule, UiCoreModule, LgLocalizationModule],
    exports: [
        LgAreaChartComponent,
        LgBoxplotVerticalChartComponent,
        LgComparePieChartComponent,
        LgComparePieChartMiniComponent,
        LgGroupedBarChartComponent,
        LgGroupedBarHorizontalChartComponent,
        LgGrowthBarChartComponent,
        LgSankeyChartComponent,
        LgStackedBarChartComponent,
        LgStackedBarHorizontalChartComponent,
        LgWaterFallChartComponent,
        LgLineChartComponent,
        LgChartLegendComponent,
        LgColorScaleBarComponent,
        LgBoxplotHorizontalChartComponent,
        LgScatterChartComponent,
        LgFunnelChartComponent,
        LgIconChartComponent,
        LgHistogramChartComponent,
        LgBubbleChartComponent,
        LgChartExportComponent,
        LgChartExportContainerDirective,
        LgChartTemplateContextTypeDirective,
        LgSingleValueChartComponent
    ],
    declarations: [
        LgAreaChartComponent,
        LgBoxplotVerticalChartComponent,
        LgComparePieChartComponent,
        LgComparePieChartMiniComponent,
        LgGroupedBarChartComponent,
        LgGroupedBarHorizontalChartComponent,
        LgGrowthBarChartComponent,
        LgSankeyChartComponent,
        LgStackedBarChartComponent,
        LgStackedBarHorizontalChartComponent,
        LgWaterFallChartComponent,
        LgLineChartComponent,
        LgChartLegendComponent,
        LgChartIconComponent,
        LgColorScaleBarComponent,
        LgBoxplotHorizontalChartComponent,
        LgScatterChartComponent,
        LgFunnelChartComponent,
        LgIconChartComponent,
        LgHistogramChartComponent,
        LgBubbleChartComponent,
        LgChartExportComponent,
        LgChartExportContainerDirective,
        LgChartTemplateContextTypeDirective,
        LgSingleValueChartComponent
    ],
    providers: [
        LgColorPalette,
        LineDataConverter,
        {
            provide: LG_CHART_COLOR_PALETTE,
            useValue: DEFAULT_PALETTE
        },
        {
            provide: LG_BACKGROUND_COLOR_FOR_OVERLAPS,
            useValue: DEFAULT_BACKGROUND_COLOR_FOR_OVERLAPS
        },
        {
            provide: LG_CHART_VALUE_TYPE_DICTIONARY,
            useValue: DEFAULT_CHART_VALUE_TYPE_DICTIONARY
        },
        {
            provide: LG_INSURER_COLORS,
            useValue: INSURERS_COLORS
        }
    ]
})
export class LgChartsModule {
    /**
     * Use this method in your root module to provide options to LgChartsModle
     * Usage can be seen in storybook. For new palette, see `lg-new-palette.stories.ts`
     *
     * @example to use new palette:
     * imports: [LgChartsModule.forRoot({ useNewColorPalette: true })]
     * @example to use legacy palette:
     * imports: [LgChartsModule]
     */
    static forRoot(options: LgChartsModuleOptions): ModuleWithProviders<LgChartsModule> {
        return <ModuleWithProviders<LgChartsModule>>{
            ngModule: LgChartsModule,
            providers: [
                {
                    provide: LG_USE_NEW_COLOR_PALETTE,
                    useValue: options ? !!options.useNewColorPalette : false
                },
                {
                    provide: LG_USE_NEW_LABELS,
                    useValue: options ? !!options.useNewLabels : false
                }
            ]
        };
    }
}
