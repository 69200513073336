import { ElementRef } from "@angular/core";

/**
 * Determine, if the target element has specified class.
 * TODO: drop if we abandon IE9
 *
 * @param element
 * @param className
 *
 * @deprecated Use Web API Element classList property instead
 */
export function elementHasClass(element: ElementRef | HTMLElement, className: string): boolean {
    if (!element) return false;

    let target: HTMLElement;
    if ("nativeElement" in element) {
        target = element.nativeElement;
    } else {
        target = element;
    }

    return target.classList.contains(className);
}
