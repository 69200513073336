import { ILookup, lgProvide, LgProvider, LgProviders } from "@logex/framework/types";
import { Provider } from "@angular/core";
import {
    DEFAULT_BACKGROUND_COLOR_FOR_OVERLAPS,
    DEFAULT_CHART_VALUE_TYPE_DICTIONARY,
    DEFAULT_PALETTE,
    INSURERS_COLORS,
    LG_BACKGROUND_COLOR_FOR_OVERLAPS,
    LG_CHART_COLOR_PALETTE,
    LG_CHART_VALUE_TYPE_DICTIONARY,
    LG_INSURER_COLORS,
    LgColorPalette
} from "../shared/lg-color-palette";
import { LineDataConverter } from "../lg-line-chart/LineDataConverter";
import { ChartValueTypeDictionary } from "../shared/chart.types";

export interface LgChartsConfig {
    /**
     * Provides dictionary of chart value types (token `LG_CHART_VALUE_TYPE_DICTIONARY`).
     * Optional. Default value is `DEFAULT_CHART_VALUE_TYPE_DICTIONARY`.
     *
     * @deprecated
     */
    chartValueTypeDictionary?: LgProvider<ChartValueTypeDictionary>;
    /**
     * Provides color palette for charts (provider `LgColorPalette`).
     * Optional. Default value is `DEFAULT_PALETTE`.
     *
     * @deprecated
     */
    colorPalette?: LgProvider<LgColorPalette>;
    /**
     * Provides color palette for charts (token `LG_CHART_COLOR_PALETTE`).
     * Optional. Default value is `DEFAULT_PALETTE`.
     */
    colorPaletteChart?: LgProvider<string[]>;
    /**
     * Provides dictionary of insurer colors (token `LG_INSURER_COLORS`).
     * Optional. Default value is `INSURERS_COLORS`.
     */
    insurerColors?: LgProvider<ILookup<string>>;
    /**
     * Provides line data converter (provider `LineDataConverter`).
     * Optional. Default value is `LineDataConverter`.
     */
    lineDataConverter?: LgProvider<LineDataConverter>;
    /**
     * Provides background color for overlapping charts (token `LG_BACKGROUND_COLOR_FOR_OVERLAPS`).
     * Optional. Default value is `DEFAULT_BACKGROUND_COLOR_FOR_OVERLAPS`.
     */
    overlapsBackgroundColor?: LgProvider<string>;
}

/**
 * Helper utility to configure and provide `LgChartsModule` providers
 */
export const lgChartsProviders: LgProviders<LgChartsConfig> = (
    config?: LgChartsConfig
): Provider[] => {
    const providers: Provider[] = [];

    providers.push(
        config?.colorPalette ? lgProvide(LgColorPalette, config!.colorPalette) : LgColorPalette,
        config?.lineDataConverter
            ? lgProvide(LineDataConverter, config!.lineDataConverter)
            : LineDataConverter,
        lgProvide(
            LG_CHART_COLOR_PALETTE,
            config?.colorPaletteChart ?? { useValue: DEFAULT_PALETTE }
        ),
        lgProvide(
            LG_BACKGROUND_COLOR_FOR_OVERLAPS,
            config?.overlapsBackgroundColor ?? { useValue: DEFAULT_BACKGROUND_COLOR_FOR_OVERLAPS }
        ),
        lgProvide(
            LG_CHART_VALUE_TYPE_DICTIONARY,
            // @ts-expect-error Mismatch of data types in the LgColorPalette implementation
            config?.chartValueTypeDictionary ?? { useValue: DEFAULT_CHART_VALUE_TYPE_DICTIONARY }
        ),
        lgProvide(
            LG_INSURER_COLORS,
            // @ts-expect-error Mismatch of data types in the LgColorPalette implementation
            config?.insurerColors ?? { useValue: INSURERS_COLORS }
        )
    );

    return providers;
};
