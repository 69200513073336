import { Directive, OnInit, ElementRef, Renderer2, OnDestroy, inject } from "@angular/core";

@Directive({
    standalone: true,
    selector: "[lgStyledCheckbox]"
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class LgStyledCheckbox implements OnInit, OnDestroy {
    private _elementRef = inject(ElementRef);
    private _renderer = inject(Renderer2);
    private _label!: HTMLElement;
    private _indicator!: HTMLElement;
    private _svg!: HTMLElement;
    private _use!: HTMLElement;
    private _unsubscribe!: () => void;

    ngOnInit(): void {
        const native: Element = this._elementRef.nativeElement;

        if (native.nodeName !== "INPUT" || native.getAttribute("type") !== "checkbox") {
            console.log("Invalid lgStyledCheckbox application on element %o", native);
            return;
        }

        this._label = this._renderer.createElement("label");
        this._renderer.addClass(this._label, "lg-styled-checkbox");
        this._indicator = this._renderer.createElement("span");
        this._svg = this._renderer.createElement("svg", "svg");
        this._renderer.setAttribute(this._svg, "viewBox", "0 0 24 24");
        this._use = this._renderer.createElement("use", "svg");
        this._renderer.setAttribute(this._use, "href", "#icon-check-thick", "xlink");
        this._renderer.appendChild(this._indicator, this._svg);
        this._renderer.appendChild(this._svg, this._use);
        this._renderer.insertBefore(this._renderer.parentNode(native), this._label, native);
        this._renderer.appendChild(this._label, native);
        this._renderer.appendChild(this._label, this._indicator);

        this._unsubscribe = this._renderer.listen(this._label, "click", (event: Event) => {
            event.stopPropagation();
        });

        // original code listened on element too but that doesn't seem to be necessary
    }

    ngOnDestroy(): void {
        if (this._unsubscribe) this._unsubscribe();
        if (this._label && this._renderer) {
            if (this._label.parentNode)
                this._renderer.removeChild(this._label.parentNode, this._label);
            if (this._renderer.destroyNode) {
                this._renderer.destroyNode(this._label);
                this._renderer.destroyNode(this._indicator);
                this._renderer.destroyNode(this._svg);
                this._renderer.destroyNode(this._use);
            }
        }
    }
}
