import { Injectable, inject } from "@angular/core";

import * as Pivots from "@logex/framework/lg-pivot";

import { IComboFilterDefinition } from "../renderers/combo-filter-renderer";
import { IFilterPreprocessor } from "../filter-preprocessor";

export type IComboFilterDefinitionSeed<T> = Partial<IComboFilterDefinition<T>> & {
    filterType: "combo";

    id: string;

    /**
     * sourceDefinition for the filter, as used by logexPivot.gatherFilterOptionsFactory
     */
    sourceDefinition?: Pivots.IGatherFilterFactoryOptions<T>;

    /**
     * Base parameters for sourceDefinition, as use dby logexPivot.gatherFilterOptionsFactory
     */
    sourceDefinitionBase?: Pivots.IGatherFilterFactoryOptions<T>;
};

// Combo box preprocessor ------------------------------------------------------------------------------------------
@Injectable()
export class ComboFilterPreprocessor implements IFilterPreprocessor {
    private _logexPivot = inject(Pivots.LogexPivotService);

    public readonly name = "combo";

    public preprocess<T>(
        definition: IComboFilterDefinitionSeed<T>,
        _context: any
    ): IComboFilterDefinition<T> {
        if (definition.sourceDefinition) {
            definition.source = this._logexPivot.gatherFilterOptionsFactory<any>(
                definition.sourceDefinition,
                definition.sourceDefinitionBase,
                definition.context
            );
        }
        return definition as IComboFilterDefinition<T>;
    }
}
