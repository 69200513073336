import ldIndexOf from "lodash-es/indexOf";
import ldIsFunction from "lodash-es/isFunction";
import ldIsString from "lodash-es/isString";
import ldIsArray from "lodash-es/isArray";

import {
    Component,
    Input,
    Output,
    ElementRef,
    OnDestroy,
    EventEmitter,
    HostListener,
    ChangeDetectionStrategy,
    ViewEncapsulation,
    forwardRef,
    ChangeDetectorRef,
    inject
} from "@angular/core";
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from "@angular/forms";

import { ScrollDispatcher, Overlay } from "@angular/cdk/overlay";
import { ComponentPortal } from "@angular/cdk/portal";

import { Subject } from "rxjs";
import { takeUntil, first } from "rxjs/operators";

// import { IDropdownDefinition, IDropdownIconDefinition, IDropdownGroup, IConvertedGroup, IConvertedEntry } from "./lg-dropdown-types";
import { ISimpleDropdownTextCallback, ISimpleDropdownDefinition } from "./lg-simple-dropdown.types";

import { toBoolean } from "@logex/framework/utilities";
import { LgOverlayService, IOverlayResultApi } from "../../lg-overlay";
import { LgSimpleDropdownPopupComponent } from "./lg-simple-dropdown-popup.component";
import { NgClass } from "@angular/common";

/*
 *  Simplified version of the dropdown (no pre/post callbacks, no grouping, no icons). The full format is:
 *  <lg-simple-dropdown current="selected" definition="myDef" text="{{name}}" is-disabled="false" class-name="lg-my-dropdown" popup-class-name="lg-my-dropdown-popup" trigger="mytrigger"></lg-simple-dropdown>
 *  The parameters are:
 *      current: is of the currently selected item
 *      definition: the definition of the dropdown values in one of the following formats
 *          - array of strings, ie ["monday", "tuesday", "wednesday", ...]. In this case the id is the same as the name
 *          - array of id/name objects, ie [{id:0, name:"monday"}, {id:1, name:"tuesday"}, .. ]
 *          - hash, ie {0:"monday, 1:"tuesday", ...}
 *      text: (optional) defines, how is the current selection displayed. The default is "name||'-'". You can refer to either id or name in the expression (and, if using the second
 *          definition form, to any other attribute of the item). If you want to show some default value for missing selection, do it as above. Note: this doesn't affect the popup
 *          The directionary form stores the complete value under "name", so you can always do something like "name.id + '-' + name.omschrijving"
 *      entry-text: (optional) behaves as text, but is used for the dropdown list popup. Note: if only entry-text is specified, it will be used for both!
 *      is-disabled: (optional) true/false
 *      class-name: (optional) class of the control. Defaults to "lg-simple-dropdown". See the css for for sample definition
 *      popup-class-name: (optional) class of the popup. This should include abs.positioning. Defaults to lg-simple-dropdown-popup
 *      trigger: (optional) this allows the code to activate the dropdown explicitly, optionally forcing a selection.  <div ng-click="mytrigger={mustPick:true}">Show it</div>. The trigger
 *          can also contain onCancelFn callback
 */
@Component({
    selector: "lg-simple-dropdown",

    template: `
        <div
            class="{{ className || 'lg-simple-dropdown' }}"
            [ngClass]="{ disabled: _isDropdownDisabled, unassigned: !_assigned, active: _active }"
            title="{{ _currentValueName }}"
        >
            {{ _currentValueName }}
        </div>
    `,

    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    imports: [NgClass],

    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => LgSimpleDropdownComponent),
            multi: true
        }
    ]
})
export class LgSimpleDropdownComponent implements OnDestroy, ControlValueAccessor {
    private _changeDetectorRef = inject(ChangeDetectorRef);
    private _elementRef = inject(ElementRef);
    private _overlay = inject(Overlay);
    private _overlayService = inject(LgOverlayService);
    private _scrollDispatcher = inject(ScrollDispatcher);
    // ---------------------------------------------------------------------------------------------
    //  Inputs and outputs
    // ---------------------------------------------------------------------------------------------
    /**
     * Dropdown definition (required).
     */
    @Input({ required: true }) set definition(value: ISimpleDropdownDefinition) {
        this._definition = value;
        this._updateValue();
    }

    get definition(): ISimpleDropdownDefinition {
        return this._definition;
    }

    /**
     * Current value.
     */
    @Input() set current(value: number | string) {
        this._current = value;
        this._updateValue();
    }

    get current(): number | string {
        return this._current;
    }

    @Output() readonly currentChange = new EventEmitter<number | string>();

    @Input() set disabled(value: boolean) {
        this._isDisabled = toBoolean(value);
        this._updateValue();
    }

    get disabled(): boolean {
        return this._isDisabled;
    }

    /**
     * Specifies if width should be matched.
     *
     * @default false
     */
    @Input() set matchWidth(value: boolean) {
        this._matchWidth = toBoolean(value);
    }

    get matchWidth(): boolean {
        return this._matchWidth;
    }

    /**
     * Apply css class to dropdown popup.
     */
    @Input() popupClassName?: string;

    /**
     * Apply css class to target element.
     */
    @Input() className?: string;

    @Input() popupPosition: "left" | "right" = "left";

    /**
     * Callback function to get selected value display text.
     * If not specified then `entryText` callback is used.
     */
    @Input() set text(fn: ISimpleDropdownTextCallback) {
        if (!ldIsFunction(fn))
            console.error("LgSimpleDropdown parameter text: expected function, got ", fn);
        this._textFn = fn;
        this._updateValue();
    }

    get text(): ISimpleDropdownTextCallback | undefined {
        return this._textFn;
    }

    /**
     * Callback function to get option item values to be displayed.
     */
    @Input() set entryText(fn: ISimpleDropdownTextCallback) {
        if (!ldIsFunction(fn))
            console.error("LgSimpleDropdown parameter entryText: expected function, got ", fn);
        this._entryTextFn = fn;
        this._updateValue();
        if (this._popupInstance) {
            this._popupInstance.entryText = fn;
        }
    }

    get entryText(): ISimpleDropdownTextCallback | undefined {
        return this._entryTextFn;
    }

    private _current: number | string = "";
    private _definition!: ISimpleDropdownDefinition;

    private _isDisabled = false;
    private _matchWidth = false;
    private _textFn?: ISimpleDropdownTextCallback;
    private _entryTextFn?: ISimpleDropdownTextCallback;

    // ---------------------------------------------------------------------------------------------
    //  State
    // ---------------------------------------------------------------------------------------------
    private readonly _destroyed$ = new Subject<void>();

    // used when event is used to show the dropdown
    private _mustPick = false;
    private _onCancelFn?: undefined | ((source: LgSimpleDropdownComponent) => void);

    public _assigned = false;
    public _currentValueName = "";
    public _isDropdownDisabled = true;

    public _active = false;
    private _overlayInstance: IOverlayResultApi | null = null;
    private _popupHidden$ = new Subject<void>();
    private _popupInstance: LgSimpleDropdownPopupComponent | null = null;

    // ---------------------------------------------------------------------------------------------
    //  Initialization
    // ---------------------------------------------------------------------------------------------
    // ---------------------------------------------------------------------------------------------
    //  Trigger the selection from the outside
    // ---------------------------------------------------------------------------------------------
    public triggerSelect(onCancel?: (source: LgSimpleDropdownComponent) => void): void {
        this._onCancelFn = onCancel;
        this._mustPick = onCancel == null;
        setTimeout(() => this._doShow(), 10);
    }

    // ---------------------------------------------------------------------------------------------
    @HostListener("click")
    public _onClick(): boolean {
        if (this._isDropdownDisabled) return true;

        this._mustPick = false;
        this._onCancelFn = undefined;
        this._doShow();
        return false;
    }

    // ---------------------------------------------------------------------------------------------
    public ngOnDestroy(): void {
        if (this._active) {
            this._doClose(true);
        }

        this._destroyed$.next();
        this._destroyed$.complete();
    }

    // ---------------------------------------------------------------------------------------------
    //  ngModel integration
    // ---------------------------------------------------------------------------------------------
    private _onChangeFn!: (value: number | string) => void;
    private _onTouchedFn!: () => void;
    public writeValue(obj: any): void {
        this.current = obj as number | string;
        this._changeDetectorRef.markForCheck();
    }

    public registerOnChange(fn: (value: number | string) => void): void {
        this._onChangeFn = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this._onTouchedFn = fn;
    }

    // ---------------------------------------------------------------------------------------------
    //  Update currently selected value
    // ---------------------------------------------------------------------------------------------
    private _updateValue(): void {
        let fn = this._textFn || this._entryTextFn;
        let selected;

        if (!fn) {
            fn = (src: any) => (src ? src.name || "-" : "-");
        }

        if (!this._definition) {
            this._assigned = false;
            this._currentValueName = fn(null);
            this._isDropdownDisabled = true;
            return;
        }

        if (ldIsArray(this._definition)) {
            if (ldIsString(this._definition[0])) {
                selected = ldIndexOf(this._definition as string[], this._current);
                this._currentValueName = fn(
                    selected > -1 ? { id: this._current, name: this._current } : null
                );
                this._assigned = selected > -1;
            } else {
                selected = (this._definition as Array<{ id: number | string; name?: string }>).find(
                    (el: any) => el.id === this._current
                );
                this._currentValueName = fn(selected);
                this._assigned = selected != null;
            }
        } else {
            selected = (this._definition as Record<number | string, string>)[this._current];
            this._currentValueName = fn(selected ? { id: this._current, name: selected } : null);
            this._assigned = selected != null;
        }
        this._isDropdownDisabled = this._isDisabled;
    }

    // ---------------------------------------------------------------------------------------------
    //  Make a selection
    // ---------------------------------------------------------------------------------------------
    private _doSelect(id: number | string): void {
        this.current = id;
        this._doClose(false);
        this.currentChange.next(id);

        if (this._onChangeFn) {
            this._onChangeFn(id);
        }
    }

    // ---------------------------------------------------------------------------------------------
    //  Attempt to close the popup without selection (vetoed by mustPick)
    // ---------------------------------------------------------------------------------------------
    private _attemptClose(): void {
        if (!this._mustPick) {
            this._doClose(false);
            if (this._onCancelFn) {
                this._onCancelFn(this);
            }
        }
    }

    // ---------------------------------------------------------------------------------------------
    //  Close the popup
    // ---------------------------------------------------------------------------------------------
    private _doClose(immediately: boolean): void {
        if (!this._active) return;

        this._active = false;
        this._popupHidden$.next();
        this._popupHidden$.complete();

        if (immediately) {
            this._overlayInstance!.hide();
        } else {
            const overlayInstance = this._overlayInstance;
            this._popupInstance
                ?.hide()
                .pipe(first())
                .subscribe(() => {
                    overlayInstance?.hide();
                });
        }

        this._overlayInstance = null;
        this._popupInstance = null;

        if (this._onTouchedFn) this._onTouchedFn();

        this._changeDetectorRef.markForCheck();
    }

    // ---------------------------------------------------------------------------------------------
    //  Show the popup
    // ---------------------------------------------------------------------------------------------
    protected _getPopupClass(): any {
        return LgSimpleDropdownPopupComponent;
    }

    private _doShow(): void {
        this._popupHidden$ = new Subject<void>();

        const alignment = this.popupPosition === "right" ? "start" : "end";

        const strategy = this._overlay
            .position()
            .flexibleConnectedTo(this._elementRef)
            .withFlexibleDimensions(false)
            .withPush(false)
            .withViewportMargin(0)
            .withPositions([
                { originX: alignment, originY: "bottom", overlayX: alignment, overlayY: "top" },
                { originX: alignment, originY: "top", overlayX: alignment, overlayY: "bottom" }
            ]);

        strategy.withScrollableContainers(
            this._scrollDispatcher.getAncestorScrollContainers(this._elementRef)
        );

        this._overlayInstance = this._overlayService.show({
            onClick: () => this._attemptClose(),
            hasBackdrop: true,
            // trapFocusTo: useBackground && showOptions.trapFocus ? holder : null,
            sourceElement: this._elementRef,
            positionStrategy: strategy,
            onDeactivate: () => {
                this._popupInstance!._isTop = false;
            },
            onActivate: () => {
                this._popupInstance!._isTop = true;
            },
            scrollStrategy: this._overlay.scrollStrategies.reposition({ scrollThrottle: 0 })
        });

        const portal = new ComponentPortal<LgSimpleDropdownPopupComponent>(this._getPopupClass());
        this._popupInstance = this._overlayInstance.overlayRef.attach(portal).instance;
        this._popupInstance.entryText = this._entryTextFn;
        this._popupInstance.definition = this._definition;

        strategy.positionChanges.pipe(takeUntil(this._popupHidden$)).subscribe(change => {
            this._popupInstance!._updatePosition(change);
        });

        this._popupInstance
            ._initialize(this._elementRef, this.matchWidth, this.popupClassName ?? "", this.current)
            .pipe(takeUntil(this._popupHidden$))
            .subscribe(result => {
                if (!result.selected) {
                    this._attemptClose();
                } else {
                    this._doSelect(result.id ?? "");
                }
            });

        this._active = true;
        this._changeDetectorRef.markForCheck();
    }
}
