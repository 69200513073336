import { Injectable, inject } from "@angular/core";
import { Observable } from "rxjs";
import { LgExportDialog } from "./lg-export-dialog.component";

@Injectable({ providedIn: "root" })
export class LgExportService {
    private _dialog = inject(LgExportDialog);

    downloadExport(url: string, params: object): Observable<void> {
        return this._dialog.show(url, params);
    }
}
