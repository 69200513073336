import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { PasswordRequirementState } from "./lg-new-password.types";
import { LgTranslatePipe, useTranslationNamespace } from "@logex/framework/lg-localization";
import { LgIconComponent } from "../lg-icon/lg-icon.component";

@Component({
    standalone: true,
    selector: "lg-new-password-requirement",
    templateUrl: "./lg-new-password-requirement.component.html",
    viewProviders: [useTranslationNamespace("FW._Directives._NewPasswordComponent")],
    imports: [LgIconComponent, LgTranslatePipe],
    host: {
        class: "lg-new-password__requirement",
        "[class.lg-new-password__requirement--level-0]": "requirement.level === 0",
        "[class.lg-new-password__requirement--level-1]": "requirement.level === 1",
        "[class.lg-new-password__requirement--passed]": "requirement.passed",
        "[class.lg-new-password__requirement--inactive]": "!requirement.active"
    },
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LgNewPasswordRequirementComponent {
    /**
     * Password requirements (required).
     */
    @Input({ required: true }) requirement!: PasswordRequirementState;
}
