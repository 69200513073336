import { Overlay, ScrollDispatcher } from "@angular/cdk/overlay";
import { ComponentPortal } from "@angular/cdk/portal";
import {
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    OnChanges,
    ChangeDetectionStrategy,
    EventEmitter,
    Output,
    inject
} from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { IOverlayResultApi, LgOverlayService } from "@logex/framework/ui-core";
import { LgSimpleChanges } from "@logex/framework/types";
import { LgTranslateService, useTranslationNamespace } from "@logex/framework/lg-localization";

import {
    LgComparisonType,
    LgSourcesBeingCompared,
    MiComparisonSourceMenuPopupResult,
    LgComparisonSourceDifferenceTypeItem
} from "./lg-comparison-source.types";
import { MiComparisonSourceMenuPopupComponent } from "./mi-comparison-source-menu-popup.component";
import { MiComparisonSourceService } from "./mi-comparison-source.service";

@Component({
    selector: "lg-comparison-source-menu",
    template: `
        <span (click)="_onClick($event)" class="lg-multilevel-select lg-multilevel-select">
            <span *ngIf="showLabel">
                {{ _label }}
            </span>
            <lg-icon [icon]="_symbolIconClass" [inline]="true"></lg-icon>
            <lg-icon
                [icon]="_popupActive ? 'icon-arrow-up' : 'icon-arrow-down'"
                [inline]="true"
            ></lg-icon>
        </span>
    `,
    host: {
        class: "lg-comparison-source-menu lg-multilevel-reference"
    },
    changeDetection: ChangeDetectionStrategy.OnPush,
    viewProviders: [useTranslationNamespace("FW._Dialogs._ComparisonSource")]
})
export class LgComparisonSourceMenuComponent implements OnInit, OnDestroy, OnChanges {
    private _elementRef = inject(ElementRef);
    private _overlay = inject(Overlay);
    private _overlayService = inject(LgOverlayService);
    private _scrollDispatcher = inject(ScrollDispatcher);
    private _service = inject(MiComparisonSourceService);
    private _translateService = inject(LgTranslateService);
    @Input() comparisonType: LgComparisonType = "absolute";
    @Input() showComparisonTypeSwitch = true;
    @Input({ required: true }) initialFirstComparateId!: string;
    @Input({ required: true }) initialSecondComparateId!: string;
    @Input() scope = "default";
    @Input() currentDifferenceTypeId: string | number | null = null;
    @Input() differenceTypes: LgComparisonSourceDifferenceTypeItem[] = [];
    @Input() showLabel = true;

    /**
     * `variant` makes a difference only when you work with `differenceTypes`
     */
    @Input() variant: "wide" | "narrow" = "narrow";
    @Output() readonly typeChange = new EventEmitter<LgComparisonType>();
    @Output() readonly sourcesChange = new EventEmitter<LgSourcesBeingCompared>();
    @Output() readonly differenceTypeChange = new EventEmitter<string | number>();

    _symbolIconClass!: string;
    _popupActive = false;
    _label: string | undefined = "";

    private readonly _destroyed$ = new Subject<void>();
    private _overlayInstance: IOverlayResultApi | null = null;
    private _popupInstance: MiComparisonSourceMenuPopupComponent | null = null;
    private _popupHidden$: Subject<void> | null = null;
    private _sourcesBeingCompared!: LgSourcesBeingCompared;
    private _initialized = false;

    ngOnInit(): void {
        if (!this._initialized) this._initialize();
    }

    ngOnChanges(changes: LgSimpleChanges<LgComparisonSourceMenuComponent>): void {
        if (!this._initialized) {
            this._initialize();
            return;
        }

        if (changes.comparisonType) {
            this._symbolIconClass = this._getClassForSymbol();
        }

        if (
            changes.variant ||
            (this.differenceTypes && this.differenceTypes.length && changes.currentDifferenceTypeId)
        ) {
            this._updateLabel();
        }
    }

    ngOnDestroy(): void {
        this._hideSettingsPopup();
        this._destroyed$.next();
        this._destroyed$.complete();
    }

    _onClick(event: MouseEvent): void {
        event.stopImmediatePropagation();
        event.preventDefault();

        this._showMenuPopup(this._elementRef, true);
    }

    private _initialize(): void {
        if (!this.initialFirstComparateId || !this.initialSecondComparateId) {
            console.error(
                "<lg-comparison-source-menu> pass in both initalFirstComparateId and initalSecondComparateId"
            );
            return;
        }

        this._symbolIconClass = this._getClassForSymbol();
        if (!this._service.firstComparedId || !this._service.secondComparedId) {
            Promise.resolve().then(() => {
                this._service.updateComparing(
                    this.initialFirstComparateId,
                    this.initialSecondComparateId
                );
            });
        }

        this._service.sourcesBeingCompared$.pipe(takeUntil(this._destroyed$)).subscribe(sources => {
            this._sourcesBeingCompared = sources;
        });

        this._service.triggerSourceChange$.pipe(takeUntil(this._destroyed$)).subscribe(sources => {
            if (!sources.firstId || !sources.secondId) {
                return;
            }

            this.sourcesChange.emit({
                firstId: sources.firstId,
                secondId: sources.secondId
            });
        });

        if (
            this.differenceTypes &&
            this.differenceTypes.length &&
            this.currentDifferenceTypeId == null
        ) {
            this.currentDifferenceTypeId = this.differenceTypes[0].id;
            this.differenceTypeChange.emit(this.currentDifferenceTypeId);
        }

        this._updateLabel();

        this._initialized = true;
    }

    private _updateLabel(): void {
        const staticLabel = this._translateService.translate(".Difference_title");

        if (this.differenceTypes && this.differenceTypes.length) {
            const item = this.differenceTypes.find(t => t.id === this.currentDifferenceTypeId);
            const dynamicLabel =
                item?.name || (item && this._translateService.translate(item.nameLc));
            this._label = this.variant === "wide" ? `${staticLabel} ${dynamicLabel}` : dynamicLabel;
        } else {
            this._label = staticLabel;
        }
    }

    private _showMenuPopup(element: ElementRef, hasBackdrop: boolean): void {
        if (this._popupActive) return;

        const positionStrategy = this._overlay
            .position()
            .flexibleConnectedTo(this._elementRef)
            .withFlexibleDimensions(false)
            .withPush(false)
            .withViewportMargin(0)
            .withPositions([
                { originX: "end", originY: "bottom", overlayX: "end", overlayY: "top" },
                { originX: "end", originY: "top", overlayX: "end", overlayY: "bottom" },
                { originX: "start", originY: "bottom", overlayX: "start", overlayY: "top" },
                { originX: "start", originY: "top", overlayX: "start", overlayY: "bottom" }
            ]);

        this._popupHidden$ = new Subject<void>();

        positionStrategy.withScrollableContainers(
            this._scrollDispatcher.getAncestorScrollContainers(element)
        );

        this._overlayInstance = this._overlayService.show({
            onClick: () => this._onOverlayBackDropClick(),
            hasBackdrop,
            sourceElement: element,
            positionStrategy,
            onDeactivate: () => {
                if (this._popupInstance) this._popupInstance._isTop = false;
            },
            onActivate: () => {
                if (this._popupInstance) this._popupInstance._isTop = true;
            },
            scrollStrategy: this._overlay.scrollStrategies.reposition({ scrollThrottle: 0 })
        });

        const portal = new ComponentPortal<MiComparisonSourceMenuPopupComponent>(
            MiComparisonSourceMenuPopupComponent
        );
        this._popupInstance = this._overlayInstance.overlayRef.attach(portal).instance;

        const firstId = this._sourcesBeingCompared.firstId ?? "";
        const secondId = this._sourcesBeingCompared.secondId ?? "";
        this._popupInstance
            .initialize(
                this.showComparisonTypeSwitch,
                this._service,
                firstId,
                secondId,
                this.comparisonType,
                this.differenceTypes || [],
                this.currentDifferenceTypeId!,
                this.scope
            )
            .pipe(takeUntil(this._destroyed$))
            .subscribe(result => this._onPopupResultReceived(result));

        this._popupActive = true;
    }

    private _onOverlayBackDropClick(): void {
        if (this._popupInstance) this._popupInstance._onSubmit();
    }

    private _onPopupResultReceived(result: MiComparisonSourceMenuPopupResult): void {
        if (result.type === "submit") {
            if (result.comparisonType) {
                this.typeChange.emit(result.comparisonType);
            }

            if (result.differenceTypeId != null) {
                this.differenceTypeChange.emit(result.differenceTypeId);
            }

            if (
                result.comparisonSources &&
                result.comparisonSources.firstId &&
                result.comparisonSources.secondId
            ) {
                this.sourcesChange.emit({
                    firstId: result.comparisonSources.firstId,
                    secondId: result.comparisonSources.secondId
                });

                if (result.comparisonSources.firstId && result.comparisonSources.secondId) {
                    this._service.updateComparing(
                        result.comparisonSources.firstId,
                        result.comparisonSources.secondId
                    );
                }
            }
        }

        this._hideSettingsPopup();
    }

    private _getClassForSymbol(): string {
        switch (this.comparisonType) {
            case "relative":
                return "icon-relativefigures";
            case "absolute":
                return "icon-absolutefigures";
            default:
                return "";
        }
    }

    private _hideSettingsPopup(): void {
        if (!this._popupActive) return;

        this._popupActive = false;
        if (this._popupHidden$ !== null) this._popupHidden$.next();
        if (this._popupHidden$ !== null) this._popupHidden$.complete();

        if (this._overlayInstance !== null) this._overlayInstance.hide();

        this._popupHidden$ = null;
        this._overlayInstance = null;
        this._popupInstance = null;
    }
}
