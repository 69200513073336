import {
    ElementRef,
    Renderer2,
    Component,
    Input,
    Output,
    EventEmitter,
    ViewChild,
    inject
} from "@angular/core";

import { Upload } from "./lg-upload-resumable.types";
import { FileUploader } from "../file-upload";

@Component({
    standalone: false,
    selector: "lg-upload-resumable",
    templateUrl: "./lg-upload-resumable.component.html"
    // component relies on internal state of Uploads so cannot be OnPush without refactoring
    // changeDetection: ChangeDetectionStrategy.OnPush
})
export class LgUploadResumableComponent {
    _elementRef = inject(ElementRef);
    private _renderer = inject(Renderer2);

    @Input("uploads") _uploads: Upload[] = [];

    @Output("uploadsAdded") readonly uploadsAdded = new EventEmitter<File[]>();
    @Output("uploadDeleted") readonly uploadDeleted = new EventEmitter<number>();

    @ViewChild("fileInput") _fileInput!: ElementRef<HTMLInputElement>;
    @Input("uploader") _uploader!: FileUploader;
    @Input() smallUploadZone = false;

    _dragging = false;
    _instance: number;

    private static _instanceCounter = 0;

    constructor() {
        this._instance = ++LgUploadResumableComponent._instanceCounter;
        // Adding styles this way in case component will be used as a base
        this._renderer.addClass(this._elementRef.nativeElement, "lg-upload-form");
        this._uploader = new FileUploader({ url: "" });
    }

    _draggingOverInput(dragging: boolean): void {
        this._dragging = dragging;
    }

    _fileSelected($e: Event): void {
        const targetFiles = (<HTMLInputElement>$e.target).files;
        if (targetFiles !== null) {
            this._dropped(targetFiles);
        }
    }

    _dropped(files: FileList): void {
        this.uploadsAdded.emit(Array.from(files || []));
        this._fileInput.nativeElement.value = "";
        this.smallUploadZone = true;
    }

    _deleteUpload(index: number): void {
        this.uploadDeleted.emit(index);
    }

    _getUploadDisplayedSize(upload: Upload): string {
        const specifiedFileSize =
            upload.options.metadata?.fileSize !== undefined
                ? parseInt(upload.options.metadata?.fileSize)
                : undefined;
        const fileSize =
            specifiedFileSize !== undefined && !isNaN(specifiedFileSize)
                ? specifiedFileSize
                : upload.file.size;
        const totalSize = Math.round(fileSize / 1024 / 1024);
        if (upload.options.progress && upload.options.size) {
            return upload.options.size;
        }
        return `0 MB / ${totalSize} MB`;
    }
}
