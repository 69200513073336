import { coerceNumberProperty } from "@angular/cdk/coercion";
import { Pipe, PipeTransform, LOCALE_ID, inject } from "@angular/core";
import { formatNumber, FormatNumberOptions } from "@logex/framework/utilities";

// TODO logic to formatting service

@Pipe({
    name: "fmtUnit",
    pure: true
})
export class LgFormatUnitPipe implements PipeTransform {
    private _locale = inject(LOCALE_ID);

    public transform(input: number | null | undefined, unit = 2): string {
        if (input == null) return "—";

        unit = coerceNumberProperty(unit);

        let result;
        switch (unit) {
            case 1:
                // integer
                result = formatNumber(options(input, this._locale, 0, true));
                break;
            case 2:
                // float
                result = formatNumber(options(input, this._locale, 2, true));
                break;
            case 3:
                result = formatNumber(options(input, this._locale, 2, false));
                break;
            case 5:
                result = formatNumber(options(input, this._locale, 2, true));
                if (result !== "-") result = result + "%";
                break;
            default:
                result = "FMTERROR";
                break;
        }
        return result;
    }
}

function options(
    val: number,
    locale: string,
    decimals: number,
    zeroDash: boolean
): FormatNumberOptions {
    return {
        val,
        locale,
        decimals,
        zeroDash
    };
}
