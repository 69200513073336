import { EventEmitter } from "@angular/core";
import { Observable } from "rxjs";

export type ExportType = "client" | "server";

export enum SidebarExportFormats {
    XLSX = "XLSX",
    PDF = "PDF",
    CSV = "CSV",
    TXT = "TXT",
    ZIP = "ZIP"
}

export interface ExportDefinition {
    id: string;
    name: string;
    type: ExportType;
    url?: string | undefined;
    callback?: EventEmitter<string> | undefined;
    params?: object | Promise<object | false> | Observable<object | false> | undefined;
    paramsCallback?:
        | undefined
        | (() => object | Promise<object | false> | Observable<object | false>);
    visible: boolean;
    enabled: boolean;
    format?: SidebarExportFormats | undefined;
}
