import type { RowType } from "../types";

export class RowContext<T, I, P> {
    public constructor(
        public $implicit: T,
        public items: Record<string, I>,

        /*
         * Parent node, if any
         */
        public parent: P,

        /*
         * Level of the row (zero-based)
         */
        public level: number,

        /*
         * Level of the previous row (in the visible sense, i.e. separator and footer counts as a row), or -1 for the first item
         */
        public prevLevel: number,

        /*
         * Level of the next row (in the visible sense, i.e. separator and footer counts as a row), or -1 for the last item
         */
        public nextLevel: number,

        /*
         * Index of the row within its sibling collection. The separator and the footer share the index
         * with their main row.
         */
        public index: number,

        /*
         * Similar to ngFor, this indicates the last row within its level (based on index)
         */
        public last: boolean,

        /*
         * Global index of the row (ie. index in the flatList, the array of all potentially visible rows)
         * The global index is not shared between separator, footer and their main row
         */
        public globalIndex: number,

        /*
         * Sign of next level presence according to hidden property
         */
        public deeper: boolean,

        /*
         * Type of the row (one of "row", "rowFooter", "separator")
         */
        public rowType: RowType
    ) {
        // empty
    }

    /*
     * Similar to ngFor, this indicates the first row within its level (based on $index).
     */
    public get first(): boolean {
        return this.index === 0;
    }

    public get even(): boolean {
        return this.index % 2 === 0;
    }

    public get odd(): boolean {
        return !this.even;
    }
}
