let cached: boolean | undefined = undefined;

export function isBigEndian(): boolean {
    if (cached === undefined) {
        try {
            const buffer = new ArrayBuffer(4);
            const asBytes = new Uint8Array(buffer);
            asBytes[0] = 1;
            const asInt = new Uint32Array(buffer);
            cached = asInt[0] !== 1;
        } catch (e) {
            console.warn(e);
            cached = false;
        }
    }

    return cached;
}
