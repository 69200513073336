// TODO: save + dialog
import { inject, Injectable } from "@angular/core";

import { IAppVersion } from "./app-version";
import { LG_APP_INFO } from "./app-info";

@Injectable({ providedIn: "root" })
export class AppVersionService implements IAppVersion {
    // ----------------------------------------------------------------------------------
    // Dependencies
    // @ld.inject userStorage: UserStorageService;
    // @ld.inject changeNotesDialog;
    private _appInfo = inject(LG_APP_INFO);

    // ----------------------------------------------------------------------------------
    //
    public version = this._appInfo.versionNumber;
    public isUpdated = false;

    // ----------------------------------------------------------------------------------
    //
    /* TODO
    constructor() {
        // Save tool-version into global storage.
        const globalStorage = "/global";
        this.userStorage.get( globalStorage )
            .then( data => {
                if ( data.version !== this.version ) {
                    this.userStorage.storage[globalStorage].data.version = this.version;
                    this._setVersionIsUpdated();
                }
            } );
    }
    */

    // ----------------------------------------------------------------------------------
    //
    private _setVersionIsUpdated(): void {
        // this._defer.resolve();
        this.isUpdated = true;

        // this.changeNotesDialog.show( this.$rootScope, {} );
    }
}
