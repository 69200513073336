import { ChangeDetectionStrategy, Component, inject, Injectable } from "@angular/core";
import {
    AbstractControl,
    FormControl,
    FormGroup,
    NonNullableFormBuilder,
    ValidationErrors,
    ValidatorFn,
    Validators
} from "@angular/forms";

import { getDialogFactoryBase, IDialogOptions, LgDialogRef } from "@logex/framework/ui-core";
import { LgTranslateService, useTranslationNamespace } from "@logex/framework/lg-localization";
import { LG_USER_INFO } from "@logex/framework/lg-application";

import { FilterSetStateUiModel } from "../services/lg-filterset.types";

export type LgBookmarkEditDialogResult = FilterSetStateUiModel;

export interface LgBookmarkEditDialogOptions {
    /** When specified, forces the value of the shared flag, and hides the relevant UI  */
    forceShared?: boolean | null | undefined;

    /** When specified, forces the value of the overwrite flag, and hides the relevant UI  */
    forceOverwrite?: boolean | null | undefined;

    /** Specifies the permission used to control access to the shared checkbox. Defaults to "admin". Irrelevant if forceShared is set  */
    permissionForShare?: string | null | undefined;

    /** Override the logic of share control (see also permissionForShare)  */
    canControlShare?: boolean | null | undefined;
}

const DEFAULT_BOOKMARK_STATE: FilterSetStateUiModel = {
    name: "",
    overwrite: true,
    shared: false
};

@Component({
    standalone: false,
    selector: "lg-bookmark-edit-dialog",
    templateUrl: "./lg-bookmark-edit-dialog.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    viewProviders: [useTranslationNamespace("FW._Directives._FiltersetSlideout._StateDialogs")]
})
export class LgBookmarkEditDialogComponent {
    private _dialogRef = inject(LgDialogRef<LgBookmarkEditDialogComponent>);
    private _formBuilder = inject(NonNullableFormBuilder);
    private _translateService = inject(LgTranslateService);
    private _userInfo = inject(LG_USER_INFO);
    _options?: IDialogOptions;
    _title = "";
    _dialogClass = "bookmark-edit-dialog";
    dialogType = "normal";

    _canSeeSharedCheckbox = false;
    _newBookmarkForm!: FormGroup<{
        name: FormControl<string>;
        shared: FormControl<boolean>;
        merge: FormControl<boolean>;
    }>;

    protected _forceShared: boolean | null = null;
    protected _forceOverwrite: boolean | null = null;

    readonly MAX_LENGTH = 64;

    private _resolve!: (result: LgBookmarkEditDialogResult) => void;

    show(
        existingStatesNames: string[],
        currentState: FilterSetStateUiModel = DEFAULT_BOOKMARK_STATE,
        options: LgBookmarkEditDialogOptions = {}
    ): Promise<LgBookmarkEditDialogResult> {
        this._canSeeSharedCheckbox =
            options.canControlShare ??
            this._userInfo.hasPermission(options.permissionForShare ?? "admin");

        this._newBookmarkForm = this._formBuilder.group({
            name: this._formBuilder.control(
                currentState.name,
                Validators.compose([
                    Validators.required,
                    nameAlreadyExistsValidator(existingStatesNames, currentState.name),
                    Validators.maxLength(this.MAX_LENGTH)
                ])
            ),
            shared: !!currentState.shared,
            merge: !currentState.overwrite
        });

        this._forceOverwrite = options.forceOverwrite ?? null;
        this._forceShared = options.forceShared ?? null;

        this._title = this._translateService.translate(
            currentState.name === "" ? ".Save_new_title" : ".Update_title"
        );

        return new Promise<LgBookmarkEditDialogResult>(resolve => {
            this._resolve = resolve;
        });
    }

    _onSave(): void {
        if (this._newBookmarkForm.valid) {
            const raw = this._newBookmarkForm.getRawValue();
            // this could happen when someone who doesn't have rights to create a shared bookmarks
            // is cloning an existing bookmark, in that case we want to set it to false
            const shared =
                this._forceShared === null
                    ? raw.shared && this._canSeeSharedCheckbox
                    : this._forceShared;
            const merge = this._forceOverwrite === null ? raw.merge : !this._forceOverwrite;
            this._resolve({ name: raw.name, shared, overwrite: !merge });
            this._dialogRef.close(1, true);
        }
    }

    _onCancel(): void {
        this._dialogRef.close(0, true);
    }
}

function nameAlreadyExistsValidator(existingNames: string[], allowedName: string): ValidatorFn {
    allowedName = allowedName.toLowerCase();
    const existingSet = new Set(existingNames.map(name => name.toLowerCase()));

    return (control: AbstractControl): ValidationErrors | null => {
        const lower = (control.value ?? "").toLowerCase();
        if (lower !== "" && lower === allowedName) return null;

        return existingSet.has(lower) ? { nameAlreadyExists: { value: control.value } } : null;
    };
}

@Injectable()
export class LgBookmarkEditDialog extends getDialogFactoryBase(
    LgBookmarkEditDialogComponent,
    "show"
) {}
