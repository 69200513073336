import {
    ChangeDetectionStrategy,
    Component,
    inject,
    Input,
    OnInit,
    ViewEncapsulation
} from "@angular/core";
import { isObservable, Observable } from "rxjs";
import { first } from "rxjs/operators";

import { LgExportService } from "@logex/framework/ui-toolbox";
import { isPromise } from "@logex/framework/utilities";

import { ISidebar } from "../sidebar-context";
import { ExportDefinition, LgSidebarExportsService } from "./exports";

@Component({
    standalone: false,
    selector: "lg-sidebar-exports",
    // eslint-disable-next-line @angular-eslint/component-max-inline-declarations
    template: `
        <ul class="lg-sidebar-export">
            <ul class="lg-sidebar-export__list">
                <li
                    *ngFor="let exportDef of _exports$ | async"
                    class="lg-sidebar-export__list__item"
                >
                    <a href="" (click)="_onClick($event, exportDef)">
                        {{ exportDef.name }}
                        <span *ngIf="exportDef.format" class="lg-sidebar-export__list__itemFormat">
                            {{ "FW._Common._ExportFormats." + exportDef.format | lgTranslate }}
                        </span>
                        <lg-icon icon="icon-download" [inline]="true"></lg-icon>
                    </a>
                </li>
            </ul>
            <ng-content></ng-content>
        </ul>
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: "lg-sidebar-export lg-sidebar-panel flexcol"
    }
})
export class LgSidebarExportsComponent implements OnInit {
    private _exportService = inject(LgExportService);
    private _sidebarExportsService = inject(LgSidebarExportsService);

    /**
     * Sidebar handler (required).
     */
    @Input({ required: true }) sidebar!: ISidebar;

    _exports$!: Observable<ExportDefinition[]>;

    ngOnInit(): void {
        this._exports$ = this._sidebarExportsService.exports$;
    }

    _onClick(event: Event, exportDef: ExportDefinition): void {
        event.preventDefault();

        if (this.sidebar) {
            this.sidebar.dismissPanel();
        }

        if (exportDef.type === "client") {
            this._onClientSideExportClick(exportDef);
        } else {
            this._onServerSideExportClick(exportDef);
        }
    }

    private _onClientSideExportClick(exportDef: ExportDefinition): void {
        exportDef.callback?.emit(exportDef.id);
    }

    private _onServerSideExportClick(exportDef: ExportDefinition): void {
        const params =
            exportDef.paramsCallback == null ? exportDef.params : exportDef.paramsCallback();
        if (isPromise(params)) {
            (params as Promise<object | false>).then(promiseParams => {
                if (promiseParams !== false)
                    this._exportService.downloadExport(exportDef.url!, promiseParams);
            });
        } else if (isObservable(params)) {
            (params as Observable<object | false>).pipe(first()).subscribe(observableParams => {
                if (observableParams !== false)
                    this._exportService.downloadExport(exportDef.url!, observableParams);
            });
        } else this._exportService.downloadExport(exportDef.url!, params ?? {});
    }
}
