import { inject, Injectable, LOCALE_ID } from "@angular/core";

import { ILgFormatter, ILgFormatterOptions } from "../implementation/formatter-types";
import { PercentageFormatter } from "../implementation/percentage-formatter";
import { ILgFormatterFactory } from "./formatter-factory-types";
import { LG_FORMATTER_OPTIONS } from "./lg-formatter-options";

export type PercentageFormatterType = "percent" | "percentage";

@Injectable({ providedIn: "root" })
export class PercentageFormatterFactory implements ILgFormatterFactory {
    private _locale = inject(LOCALE_ID);
    private _localFormatterOptions = inject(LG_FORMATTER_OPTIONS, { optional: true });

    names = ["percent", "percentage"];

    create(defaultOptions: ILgFormatterOptions = {}): ILgFormatter<number> {
        return new PercentageFormatter(
            { ...defaultOptions, ...this._localFormatterOptions },
            this._locale
        );
    }
}
