<div class="lg-calendar__wrapper">
    <div
        [tabindex]="disabled ? -1 : 0"
        class="lg-calendar__input"
        [class.lg-calendar__input--condensed]="_condensed"
        [class.lg-calendar__input--invalid]="_invalid"
        (focus)="_onFocus()"
        (click)="_onClick()"
        (keydown)="_onKeyDown($event)"
        [lgCopyHandler]="_copyHandler"
        [lgPasteHandler]="_pasteHandler"
        #inputDiv
    >
        <div class="center-things">
            <!--
            Note: Trick with combination of new lines and block comments below used to have
                  element separator without additional spaces (e.g. "/", not " / ")
            -->
            <ng-container *ngFor="let element of _elements"
                ><!--
            --><ng-container *ngTemplateOutlet="element.template ?? null"></ng-container
                >{{ element.separator
                }}<!--
            --></ng-container>
        </div>
    </div>
    <lg-time-picker
        #timePicker
        *ngIf="_withTime"
        [ngModel]="_timeValue"
        [disabled]="!!disabled || ((value == null || $any(value) === '') && _pendingDate == null)"
        [required]="true"
        [condensed]="_condensed"
        [timeFormat]="timeFormat"
        [minTime]="_timeMin"
        [maxTime]="_timeMax"
        [defaultTime]="_timeDefault"
        [_postSelectCallback]="_postTimeSelect"
        [tabBackCancels]="true"
    ></lg-time-picker>
</div>
<ng-template #dayTemplate>
    <input
        type="text"
        class="lg-calendar__input__part lg-calendar__input__part--day"
        [disabled]="true"
        [placeholder]="'.Calendar_placeholder_day' | lgTranslate"
        [value]="_visibleValue ? (_visibleValue.getDate() | lpad: _dayPadding : '0') : ''"
    />
</ng-template>
<ng-template #monthTemplate>
    <input
        type="text"
        _
        class="lg-calendar__input__part lg-calendar__input__part--month"
        [class.lg-calendar__input__part--month--active]="_visibleValue"
        [placeholder]="'.Calendar_placeholder_month' | lgTranslate"
        [disabled]="true"
        [value]="_visibleValue ? (_visibleValue.getMonth() + 1 | lpad: _monthPadding : '0') : ''"
    />
</ng-template>
<ng-template #yearTemplate>
    <input
        type="text"
        class="lg-calendar__input__part lg-calendar__input__part--year"
        [disabled]="true"
        [placeholder]="'.Calendar_placeholder_year' | lgTranslate"
        [value]="_visibleValue ? _visibleValue.getFullYear() : ''"
    />
</ng-template>
