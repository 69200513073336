import { inject, InjectOptions, ProviderToken } from "@angular/core";

// An alternative would be to change the type of all multi-providers to be an array (as it's done inside angular), but this would be a breaking change.
// We would also not be able to make sure multi is correctly used. Let's hope Angular will provide a better solution in the future.
// https://github.com/angular/angular/issues/55555

/**
 * Helper function for injecting providers regfistered with multi: true. The function will throw an error if the provider is not registered with multi: true, and handles
 * typing correctly.
 */
export function injectMultiple<T, P extends InjectOptions>(
    token: ProviderToken<T>,
    options?: P
): P extends { optional: true } ? null | T[] : T[] {
    const result = (options ? inject(token, options) : inject(token)) as T[] | null;
    if (result === null) {
        return result!; // optional must be true, otherwise angular would throw
    }
    if (!Array.isArray(result)) {
        throw new Error(`Expected array but got ${result}, please provide using multi:true`);
    }
    return result;
}
