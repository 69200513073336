import { inject, Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";
import {
    IAppConfiguration,
    IAppSession,
    LG_APP_CONFIGURATION,
    LG_APP_SESSION
} from "../application/index";

@Injectable()
export class LgScenarioInterceptor implements HttpInterceptor {
    private _appConfiguration: IAppConfiguration = inject(LG_APP_CONFIGURATION);
    private _session: IAppSession = inject(LG_APP_SESSION);

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const headers = req.headers;

        let modifiedRequest: HttpRequest<any> = req;

        /*
            We can't use Content-Type header:
            const isFormData = req.headers.get("Content-Type") === "multipart/form-data";

            Adding "Content-Type" header confuses angular when working with form data
            For more information, see https://github.com/angular/angular/issues/13241
        */
        if (!(req.body instanceof FormData)) {
            modifiedRequest = this._extendApiCallsWithScenarioIdInBody(modifiedRequest);
            modifiedRequest = this._extendApiCallsWithScenarioIdInParams(modifiedRequest);
        }

        if (headers !== modifiedRequest.headers) {
            modifiedRequest = modifiedRequest.clone({
                headers
            });
        }

        return next.handle(modifiedRequest);
    }

    private _extendApiCallsWithScenarioIdInBody(request: HttpRequest<any>): HttpRequest<any> {
        if (
            (request.method === "POST" ||
                request.method === "PUT" ||
                request.method === "DELETE") &&
            request.body != null &&
            request.url.startsWith(this._appConfiguration.applicationRoot + "/api")
        ) {
            const body = {
                ...request.body,
                clientId: this._session.clientId,
                scenarioId: this._session.scenarioId
            };
            request = request.clone({
                body
            });
        }
        return request;
    }

    private _extendApiCallsWithScenarioIdInParams(request: HttpRequest<any>): HttpRequest<any> {
        if (
            request.method === "GET" &&
            request.params != null &&
            request.url.startsWith(this._appConfiguration.applicationRoot + "/api")
        ) {
            let params = request.params;

            if (!request.params.has("clientId") && this._session.clientId !== undefined) {
                params = params.append("clientId", this._session.clientId.toString());
            }

            if (!request.params.has("scenarioId") && this._session.scenarioId !== undefined) {
                params = params.append("scenarioId", this._session.scenarioId.toString());
            }

            if (params !== request.params) {
                request = request.clone({
                    params
                });
            }
        }
        return request;
    }
}
