import { Pipe, PipeTransform } from "@angular/core";
import { getRegex } from "@logex/framework/utilities";

@Pipe({
    // eslint-disable-next-line @angular-eslint/pipe-prefix
    name: "removeSymbol",
    pure: true
})
export class LgRemoveSymbolPipe implements PipeTransform {
    public transform(value: string | null | undefined, regExpType: string): string {
        if (value == null) return "";

        return value.replace(getRegex(regExpType), "");
    }
}
