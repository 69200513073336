import { HttpStatusCode } from "@angular/common/http";
import { MonoTypeOperatorFunction, Observable, retry, throwError, timer } from "rxjs";

export interface RetryOnNetworkErrorArgs {
    maxRetries?: number;
    scalingDuration?: number;
    includedStatusCodes?: number[];
}

export function retryOnNetworkError<T>({
    maxRetries = 1,
    scalingDuration = 1000,
    includedStatusCodes = [0, HttpStatusCode.GatewayTimeout, HttpStatusCode.RequestTimeout]
}: RetryOnNetworkErrorArgs = {}): MonoTypeOperatorFunction<T> {
    return retry({
        count: maxRetries,
        delay: (error: any, retryCount: number): Observable<any> => {
            const status = error?.status;
            if (includedStatusCodes.includes(status)) {
                return timer(retryCount * scalingDuration);
            } else {
                return throwError(() => error);
            }
        },
        resetOnSuccess: true
    });
}
