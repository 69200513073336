<div
    class="lg-breadcrumb__element lg-breadcrumb__element--clickable"
    [class.lg-breadcrumb__element--active]="_activeIndex === index"
    #breadcrumb
    *ngFor="let element of _state; let index = index"
    (click)="_showMenu($event, index, breadcrumb)"
>
    <div class="lg-breadcrumb__arrow">
        <lg-icon icon="icon-arrow-down"></lg-icon>
    </div>
    <div class="lg-breadcrumb__element__text" [title]="element.name">
        {{ element.name }}
    </div>
</div>
<div
    class="lg-breadcrumb__element lg-breadcrumb__element--clickable lg-breadcrumb__element--hover-element"
    [class.breadcrumb-row__hover-element]="_activeIndex !== _extraIndex"
    [class.lg-breadcrumb__element--active]="_activeIndex === _extraIndex"
    #breadcrumb
    *ngIf="_extraIndex != null"
    (click)="_showMenu($event, _extraIndex, breadcrumb)"
>
    <div class="lg-breadcrumb__arrow">
        <lg-icon icon="icon-arrow-down"></lg-icon>
    </div>
</div>
