import {
    Component,
    Input,
    Output,
    EventEmitter,
    ChangeDetectionStrategy,
    OnChanges,
    SimpleChanges,
    ChangeDetectorRef,
    inject,
    OnDestroy
} from "@angular/core";

import type { LgFilterSet, IVisibleDefinitionGroup } from "../lg-filterset";
import type { IFilterDefinition } from "../filter-definition";
import { Subscription } from "rxjs";

@Component({
    selector: "lg-filterset-preview",
    template: `
        <ng-container *ngFor="let entry of list.potentiallyVisiblePreviewDefinitions">
            <lg-filterset-preview-item
                [definition]="entry"
                *ngIf="entry.renderer?.previewVisible()"
                [list]="list"
                (filterChange)="_filterChanged(entry)"
            ></lg-filterset-preview-item>
        </ng-container>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: "lg-filterset-preview"
    }
})
export class LgFiltersetPreviewComponent implements OnChanges, OnDestroy {
    private _changeDetectorRef = inject(ChangeDetectorRef);
    /**
     * Definition of all filters (required).
     */
    @Input({ alias: "definition", required: true }) list!: LgFilterSet;

    @Output() readonly filterChange = new EventEmitter<void>();

    private _subscription: Subscription | null = null;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.list) {
            if (this._subscription) {
                this._subscription.unsubscribe();
                this._subscription = null;
            }
            if (this.list) {
                this._subscription = this.list.onChanged.subscribe(() => {
                    this._changeDetectorRef.markForCheck();
                });
                this._subscription.add(
                    this.list.visibleGroups$.subscribe(() => {
                        this._changeDetectorRef.markForCheck();
                    })
                );
            }
        }
    }

    ngOnDestroy(): void {
        this._subscription?.unsubscribe();
    }

    _filterChanged(entry: IFilterDefinition): void {
        this.list.triggerOnChanged(entry);
        this.filterChange.next();
    }

    _groupIdentity(_index: number, entry: IVisibleDefinitionGroup): number {
        return entry.index;
    }

    _entryIdentity(_index: number, entry: IFilterDefinition): string {
        return entry.id;
    }
}
