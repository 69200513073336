import { IStringLookup } from "@logex/framework/types";

interface Separators {
    thousand: Separator;
    decimal: Separator;
}

interface Separator {
    symbol: string;
    removeRegex: RegExp;
}

interface Part {
    type: string;
    value: string;
}

const cache: IStringLookup<Separators> = {};

export function getNumberSeparators(locale: string): Separators {
    return cache[locale] || (cache[locale] = getNumberSeparatorsDo(locale));
}

function getNumberSeparatorsDo(locale: string): Separators {
    const val = 12345.6;
    const numberFormat = Intl.NumberFormat(locale);
    const parts: Part[] = numberFormat.formatToParts(val);
    const groupSeperator = parts.find(x => x.type === "group");
    const decimalSeparator = parts.find(x => x.type === "decimal");
    return {
        thousand: separatorFactory(groupSeperator ? groupSeperator.value : ""),
        decimal: separatorFactory(decimalSeparator ? decimalSeparator.value : "")
    };
}

function separatorFactory(symbol: string): Separator {
    return {
        symbol,
        // we could use non-g regexp for decimals since there will always be one
        // but we're reusing one regex multiple times so it would work just once in our scenario
        removeRegex: new RegExp(`[${symbol}]`, "g")
    };
}
