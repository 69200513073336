import { LgLocalizationSettings } from "./lg-localization.types";

interface GlobalLocalizationStore {
    fallbackLanguage: string;
    preferredLanguage: string;
    availableLanguages: string;
    locale: string;
    currency: string;
}

export class LgLocalizationSettingsWithGlobals implements LgLocalizationSettings {
    readonly bootstrapDone = Promise.resolve();

    readonly languages: Record<string, any[]> = {};

    get fallbackLanguage(): string {
        return this._store.fallbackLanguage;
    }

    get preferredLanguage(): string {
        return this._store.preferredLanguage;
    }

    get availableLanguages(): string[] {
        return this._store.availableLanguages.split(",");
    }

    get locale(): string {
        return this._store.locale;
    }

    get currency(): string {
        return this._store.currency;
    }

    private _store: GlobalLocalizationStore;

    constructor() {
        this._store = (window as any).lgLocalizationStore;
        if (this._store && !this._store.locale) {
            this._store.locale = this._store.preferredLanguage;
        }
    }

    addStrings(lang: string, strings: Record<string, any>): void {
        if (!this._store) return;
        if (this.languages[lang] == null) this.languages[lang] = [];
        this.languages[lang].push(strings);
    }

    setPreferredLanguage(lang: string): void {
        if (!this._store) return;
        this._store.preferredLanguage = lang;
    }

    switchCurrentLanguage(lang: string): void {
        window.location.assign(`${location.origin}${location.pathname}boot?lang=${lang}`);
    }
}
