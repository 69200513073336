import { Injectable } from "@angular/core";

@Injectable()
export class BrowserWindowRef implements WindowRef {
    getNativeWindow(): Window {
        return window;
    }
}

@Injectable({
    providedIn: "root",
    useClass: BrowserWindowRef
})
export abstract class WindowRef {
    abstract getNativeWindow(): Window;
}
