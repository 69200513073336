import { EventsPluginBase } from "./EventsPluginBase";
import { Injectable } from "@angular/core";

interface HoverEventDetail {
    over: boolean;
    originalEvent: MouseEvent;
}

export interface HoverEvent extends CustomEvent<HoverEventDetail> {
    // empty
}

// todo: detect non-DOM environment
@Injectable()
export class HoverEventsPlugin extends EventsPluginBase {
    public supports(eventName: string): boolean {
        return eventName === "hover";
    }

    private _createEvent(enter: boolean, event: MouseEvent): HoverEvent {
        const parameters: HoverEventDetail = {
            over: enter,
            originalEvent: event
        };

        return new CustomEvent<HoverEventDetail>("hover", { detail: parameters });
    }

    public override addEventListener(
        element: HTMLElement,
        _eventName: string,
        originalHandler: (event: Event) => void
    ): () => void {
        const enterHandler = (event: MouseEvent): void => {
            const newEvent = this._createEvent(true, event);
            originalHandler(newEvent);
        };

        const leaveHandler = (event: MouseEvent): void => {
            const newEvent = this._createEvent(false, event);
            originalHandler(newEvent);
        };

        element.addEventListener("mouseenter", enterHandler, false);
        element.addEventListener("mouseleave", leaveHandler, false);

        return () => {
            element.removeEventListener("mouseleave", leaveHandler, false);
            element.removeEventListener("mouseleave", leaveHandler, false);
        };
    }
}
