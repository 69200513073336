import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { IStringLookup } from "@logex/framework/types";
import {
    IUserStorageGetResponse,
    IUserStorageForSync,
    IUserStorageServiceGateway,
    IStoreIdentifier
} from "./lg-user-storage.types";

const SYNC_URL = "api/user/storage/save";
const GET_URL = "api/user/storage/get";

@Injectable({ providedIn: "root" })
export class LgUserStorageGateway implements IUserStorageServiceGateway {
    private _http = inject(HttpClient);

    get(keysToLoad: IStoreIdentifier[]): Observable<IUserStorageGetResponse> {
        // "binding" parameter of the keysToLoad is ignored here, because server side doesn't require it,
        // but it is used in the newer implementation of the gateway, using UserSettingsApi

        return this._http.post<IUserStorageGetResponse>(GET_URL, {
            id_list: keysToLoad.map(x => x.key).join(",")
        });
    }

    set(toSync: IStringLookup<IUserStorageForSync>): Observable<any> {
        return this._http.post(SYNC_URL, { batch: toSync });
    }
}
