import type { ILogexPivotDefinition, INormalizedLogexPivotDefinition } from "./lg-pivot.types";
import * as types from "@logex/framework/types";

/**
 * Wraps one function parameter.
 */
export class FunctionParameter {
    constructor(name: string, isGlobal: boolean) {
        this.name = name;
        this.isGlobal = isGlobal;
    }

    /**
     * Instantiate the parameter to be used on specified target ("element", "store" etc). If the parameter
     * is global, it will be returned unmodified (unless we implement advanced parsing)
     */
    public getInstanceOn(target: string): string {
        if (this.isGlobal) return this.name;
        return target + '["' + this.name + '"]';
    }

    /**
     * The parameter, as specified in the function string
     */
    name: string;

    /**
     * True if the parameter is global. That currently means that it's wrapped in brackets or curlies, starts with +/-/digit/"/',
     * is null, or starts with reference to context, element ro store
     */
    isGlobal: boolean;
}

/**
 * Options specified in the function string. Currently no option is supported!
 */
export interface IFunctionOptions {
    [id: string]: boolean;
}

/**
 * Location specified in the function string. The function implementation must specify, which parameters it supports
 */
export interface IFunctionLocations {
    /**
     * Execute the function on the parent node?
     */
    onParent?: boolean;

    /**
     * Execute the function on every node?
     */
    onNodes?: boolean;

    /**
     * Execute the function on top of the calculation (before the loop)
     */
    onTop?: boolean;

    /**
     * Execute the function at the bottom of the calculation (after the loop)
     */
    onBottom?: boolean;

    /**
     * Execute the function in the loop
     */
    inLoop?: boolean;

    /**
     * Execute the function only on the totals (on store of top pivot level)
     */
    onTotals?: boolean;
}

export type ICalculatorFunctionSource = string[];

/**
 * Implementation of the function generator. Currently, the code generated for a function may consist of three parts
 * - the initialization (code placed above the loop)
 * - the loop (code placed inside the loop through all nodes)
 * - finalizer, the code placed below the loop
 * All the parts are optional.
 *
 * Note that the generator methods should return array of strings, which will be joined together by the compiler without
 * any separator characters. Thus ["var", "a", ";"] will fail.
 * While not requires, for readability purposes it is recommended to use the \n character at the appropriate locations.
 *
 * The compiler provides the following variables accessible from the function's code:
 * length = the level size (number of nodes)
 * first = the first element, or empty object if none
 * index = the index variable of the loop (should be accessed inside the loop only)
 * element = the current node (should be accessed inside the loop only)
 * store = the parent node (or the totals object)
 *
 * Also these are accessible since they're parameters to the compiled function:
 * definition = definition of the current pivot level
 * data = the array of the nodes on the current level
 * context = the context object passed to the evaluateCalculations call (typically the controller, or $scope)
 */
export interface ICalculatorFunction {
    /**
     * Initialize the generator with the parameters extracted from the step string. Note that if the generator is used on
     * multiple levels of the pivot, the prepare call will be called only once.
     *
     * @param targetName is name of the target, if any. Many generators make this optional, getting the target from the
     *        first paramaters where suitable  (i.e. SUM(aantal) will set the target to aantal).
     * @param parameters are the parameters of the function
     * @param level is the current pivot level definition (not normalized at this point)
     * @param locations are the extracted locations, or null. Locations will be passed only if the getSupportedLocations call
     *        allows it
     * @param condition is the IF condition, if any
     * @param options are the extracted options, or null. Options will be passed only if the getSupportedOptions call allows it
     * @return true if the generator accepted the parameters, or false otherwise (it's up to the generator to log any errors)
     */
    prepare(
        targetName: string,
        parameters: FunctionParameter[],
        level: ILogexPivotDefinition,
        locations: IFunctionLocations | null,
        condition: string | null,
        options: IFunctionOptions | null
    ): boolean;

    /**
     * If the function is promoted to higher level due to merging, this method will be used to pass in the new level
     * definition (since prepare is not called). Use this to update any data dependent on the pivot level.
     *
     * @param level is the new pivot level definition on which the function will be rendered
     * @return true if the generator accepted the change, or false otherwise (it's up to the generator to log any errors)
     */
    updateLevel?(level: ILogexPivotDefinition): boolean;

    /**
     * Get target of the function. This will be used as ID for the purpose of merging levels
     */
    getTarget(): string;

    /**
     * Get the number of temporaries that the generator requires. Alternative, return list of postfixes for the temporaries.
     *
     * Existence of this method is optional, if it's missing, 0 is assumed.
     */
    getTemporaryCount?(): number | string[];

    /**
     * Set generated names of the requested temporaries. The method won't be called if 0 was requested. Note that the
     * compiler does not generate declaration of the temporaries (var temp;), it is up to the function generator to take
     * care of that!
     */
    setTemporaries?(names: string[]): void;

    /**
     * If the generator supports any options, it should return the option object with the supported options set to true.
     * Options that are not set won't be accepted. If null is returned (or the method is missing), then no options are
     * supported
     */
    getSupportedOptions?(): IFunctionOptions;

    /**
     * If the generator supports any locations, it should return the location object with the supported location set
     * to true. Locations that are not set won't be accepted. If null is returned (or the method is missing), then no
     * locations are supported.
     */
    getSupportedLocations?(): IFunctionLocations;

    /**
     * Return the initialization part of the function, if any (the part will be rendered above the loop)
     */
    getInitializationSource(): ICalculatorFunctionSource | null;

    /**
     * Return the loop part of the function, if any (the part will be rendered in the loop)
     */
    getLoopSource(): ICalculatorFunctionSource | null;

    /**
     * Return the finalizer part of the function, if any (the part will be rendered after the loop). Note that
     * independently of the step order, finalizers of aggregate functions (functions having loop part) will be rendered
     * before finalizers of the rest.
     */
    getFinalizerSource(): ICalculatorFunctionSource | null;

    /**
     * Return the code that should be evaluated on the totals (of any)
     */
    getOnTotalsSource?(): ICalculatorFunctionSource | null;

    /**
     * Source code (the step definition) of the function. This is used only internally when the debug flag
     * is specified, it won't be assigned otherwise!
     */
    sourceCode?: string;
}

/**
 * Definition for step that calls a user defined callback.
 */
export interface ICalculatorCallbackDefinition {
    /**
     * id of the callback for the merging purposes. Defaults to "callback"
     */
    id: string;

    /**
     * The callback function
     */
    fn: ICalculatorCallback | null;

    /**
     * Specifies if the callback can be moved to the bottom of the steps processing. This is useful especially
     * when merging, because callback in the  middle of the steps forces split into multiple loops. Defaults to true
     */
    moveable?: boolean;

    /**
     * Any optional parameters that will be passed to the callback. This is useful when we want to use shared callback
     * implementation in multiple levels / pivots, but modify their behaviour.
     */
    parameters?: any;
}

/**
 * Interface for callback functions defined in the steps.
 *
 * @param nodes are the nodes to be processed
 * @param store is the parent node
 * @definition is the current pivot level definition
 * @context is the context passed to the evalulateCalculations function
 * @parameters are parameters specified in the ICalculatorCallbackDefinition, if any
 */
export type ICalculatorCallback = (
    nodes: any[],
    store: any,
    definition: INormalizedLogexPivotDefinition,
    context: any,
    parameters: any
) => void;

/**
 * One calculation step is either string defining the compiled function, or callback definition, or alternatively
 * the callback itself (then the definition is created)
 */
export type ICalculationStep = string | ICalculatorCallback | ICalculatorCallbackDefinition;

/**
 * Definition of one calculation block
 */
export interface ICalculationBlock {
    /**
     * If specified, the block will be used for this pivot level only (it won't be propagated upwards)
     */
    thisLevelOnly?: boolean;

    /**
     * If specified, the block will be merged with the block (with same name) on lower level (if any).
     */
    merge?: boolean;

    /**
     * Specifies, if the block affects the filtered or unfiltered collection. This is used only if
     * the corresponding parameter of evaluateCalculations is not specified
     */
    unfiltered?: boolean | undefined;

    /**
     * When specified, the compiler extends the functions with additional comments, and dumps the code
     * of every compiled function to the console.
     */
    debug?: boolean;

    /**
     * The individual steps of the calculation.
     */
    steps: ICalculationStep[];
}

/**
 * Interface specifying the compiled function. Note that because we build the function individually for
 * every level, we don't have the pivot definition as one of the parameter (it is however embedded in the
 * closure). Compare with ICalculatorCallback
 *
 * @param nodes are the nodes of the current level to be processed
 * @param store is the parent node
 * @param context is the context as passed to evaluateCalculations (typically the owner controller, or scope)
 * @param onTopLevel specifies, whether the calculation runs on top level of the tree (or subtree)
 */
export type ICompiledFunction = (
    nodes: any[],
    store: any,
    context: any,
    onTopLevel: boolean
) => void;

/**
 * Preprocessed definition of one step of the calculation
 */
export interface IStepDefinition {
    /**
     * Id of the step, for the purpose of merging
     */
    id: string;

    /**
     * Function generator, if the step is based on string definition
     */
    function: ICalculatorFunction | null;

    /**
     * Name of the function, if the step is based on string declarations
     */
    functionName: string | null;

    /**
     * Callback definition, if the step is a user-specified callback
     */
    callback: ICalculatorCallbackDefinition | null;

    /**
     * True if the step should be placed (also) somewhere other than totals
     */
    outsideTotals: boolean;

    /**
     * True if the step should be placed on totals
     */
    onTotals: boolean;
}

/**
 * Helper store specifying some parameters of the current level, as needed for the merge operation.
 */
export interface ICalculatorLevelStore {
    levelSteps: null | IStepDefinition[];
    unfiltered: boolean | undefined;
    debug: boolean;
}

/**
 * Result of the compilation is a tupple of the compiled function, and level store.
 */
export interface ICompiledTupple extends Array<ICompiledFunction | any> {
    0: ICompiledFunction | null;
    1: ICalculatorLevelStore;
}

/**
 * Dictionary of function generators used by the compiler. The key should be uppercase name of the function, for
 * example SUM. It is trivially possible (though not recommended) to implement function aliases by adding the
 * same generator under multiple keys.
 */
export interface ICalculatorFunctionDictionary
    extends types.ILookup<new () => ICalculatorFunction> {}
