import ldIsString from "lodash-es/isString";
import ldIsArray from "lodash-es/isArray";

import {
    Directive,
    Input,
    Output,
    OnInit,
    EventEmitter,
    Renderer2,
    ElementRef,
    HostListener,
    OnDestroy,
    OnChanges,
    inject,
    ChangeDetectorRef
} from "@angular/core";
import { toBoolean } from "@logex/framework/utilities";
import { MarkElementAsSorted } from "./lg-col-definition/components/lg-col.directive";

interface IColumn {
    column: string;
    reverse: boolean;
}

@Directive({
    selector: "[lgSortByColumn],[lgSortByColumnIndicator]",
    providers: [{ provide: MarkElementAsSorted, useValue: true }],
    host: {
        "[class.lg-sort-by-column--indicator-click]": "_indicatorOnly",
        "[class.lg-sort-by-column--disabled]": "_sortByDisabled"
    }
})
export class LgSortByColumnDirective implements OnInit, OnChanges, OnDestroy {
    private _elementRef = inject(ElementRef);
    private _renderer = inject(Renderer2);
    private _changeDetectorRef = inject(ChangeDetectorRef);

    @Input("lgSortByColumn") set sortByColumn(val: string | string[]) {
        this._indicatorOnly = false;
        this._setColumnNames(val);
    }

    get sortByColumn(): string | string[] {
        if (this._sortByColumnArray) {
            return this._sortByColumnArray.map(
                (col, i) =>
                    `${
                        (
                            i === 0
                                ? this._sortByDefaultDesc
                                : col.reverse !== this._sortByDefaultDesc
                        )
                            ? "-"
                            : ""
                    }${col.column}`
            );
        } else {
            return `${this._sortByDefaultDesc ? "-" : ""}${this._sortByColumn}`;
        }
    }

    @Input("lgSortByColumnIndicator") set sortByColumnIndicator(val: string | string[]) {
        this._indicatorOnly = true;
        this._setColumnNames(val);
    }

    get sortByColumnIndicator(): string | string[] {
        return this.sortByColumn;
    }

    @Input() set sortByDefault(val: string) {
        if (!val) return;
        this._sortByDefaultDesc = this._sortByDefaultDescInput =
            val === "-" || val.toUpperCase() === "DESC";
    }

    get sortByDefault(): string {
        return this._sortByDefaultDesc ? "DESC" : "ASC";
    }

    @Input("sortBy") set current(val: string | string[]) {
        this._sortBy = val;
    }

    get current(): string | string[] | null {
        return this._sortBy;
    }

    @Output("sortByChange") readonly currentChange = new EventEmitter<string | string[] | null>();

    @Input() public set sortByDisabled(val: boolean | "true" | "false") {
        this._sortByDisabled = toBoolean(val);
    }

    get sortByDisabled(): boolean {
        return this._sortByDisabled;
    }

    private _sortByDefaultDesc = false;
    private _sortByDefaultDescInput: boolean | null = null;
    private _sortByColumn: string | null = null;
    private _sortByColumnArray: IColumn[] | null = null;
    private _sortBy: string[] | string | null = null;
    private _symbol!: HTMLElement | null;
    private _sub!: HTMLElement;
    private _direction: 1 | -1 | null = null;
    public _indicatorOnly = false;
    public _sortByDisabled = false;

    ngOnInit(): void {
        this._symbol = this._renderer.createElement("div");
        this._renderer.addClass(this._symbol, "lg-sort-by-column__indicator");
        this._sub = this._renderer.createElement("div");
        this._renderer.appendChild(this._symbol, this._sub);

        this._renderer.insertBefore(
            this._elementRef.nativeElement,
            this._symbol,
            this._elementRef.nativeElement.firstChild
        );

        this._renderer.addClass(this._elementRef.nativeElement, "lg-sort-by-column");
        this._renderer.listen(this._symbol, "click", event => {
            this._onMouseClick(event);
            this._changeDetectorRef.markForCheck();
        });
        this._updateDisplay();
    }

    ngOnChanges(): void {
        this._updateDirection();
    }

    ngOnDestroy(): void {
        this._renderer.removeChild(this._elementRef.nativeElement, this._symbol);
        if (this._renderer.destroyNode) {
            this._renderer.destroyNode(this._sub);
            this._renderer.destroyNode(this._symbol);
        }
        this._symbol = null;
    }

    @HostListener("click", ["$event"])
    _onMouseClick(event: MouseEvent): boolean {
        if (this._sortByDisabled) return true;
        if (
            this._elementRef.nativeElement.classList.contains("lg-sort-by-column--disabled") ||
            this._renderer
                .parentNode(this._elementRef.nativeElement)
                .classList.contains("lg-sort-by-column--disabled")
        )
            return true;

        if (
            this._indicatorOnly &&
            event.target !== this._symbol &&
            this._renderer.parentNode(event.target) !== this._symbol
        ) {
            return true;
        }

        if (this._direction === 1) {
            this._setOrderBy(false);
        } else if (this._direction === -1) {
            this._setOrderBy(true);
        } else {
            this._setOrderBy(!this._sortByDefaultDesc);
        }

        event.stopPropagation();
        return false;
    }

    private _updateDirection(): void {
        let stored = this._sortBy;
        if (ldIsArray(stored)) stored = stored[0];

        if (stored === this._sortByColumn || stored === "+" + this._sortByColumn) {
            this._direction = 1;
        } else if (stored === "-" + this._sortByColumn) {
            this._direction = -1;
        } else {
            this._direction = null;
        }

        this._updateDisplay();
    }

    private _updateDisplay(): void {
        this._updateClasses(this._elementRef.nativeElement);
    }

    private _updateClasses(target: HTMLElement): void {
        if (!target) return;

        if (this._direction === 1) {
            this._renderer.removeClass(target, "lg-sort-by-column--desc");
            this._renderer.addClass(target, "lg-sort-by-column--asc");
        } else if (this._direction === -1) {
            this._renderer.removeClass(target, "lg-sort-by-column--asc");
            this._renderer.addClass(target, "lg-sort-by-column--desc");
        } else {
            this._renderer.removeClass(target, "lg-sort-by-column--desc");
            this._renderer.removeClass(target, "lg-sort-by-column--asc");
        }
    }

    private _setOrderBy(ascending: boolean): void {
        let target: string | string[] | null;
        if (this._sortByColumnArray === null) {
            if (ascending) {
                target = this._sortByColumn;
            } else {
                target = "-" + this._sortByColumn;
            }
        } else {
            target = this._sortByColumnArray.map(a =>
                ascending === a.reverse ? "-" + a.column : a.column
            );
        }
        this._updateDirection();
        this.currentChange.emit(target);
    }

    private _setColumnNames(val: string | string[]): void {
        if (!val || !val.length) return;

        this._sortByColumnArray = null;

        if (ldIsString(val) && val[0] === "[") {
            val = (val as unknown as string)
                .substring(1, val.length - 1)
                .split(",")
                .map(s => s.trim());
        }

        if (ldIsArray(val)) {
            let firstDesc: boolean | null = null;
            this._sortByColumnArray = (val as unknown as string[]).map(s => {
                s = s.trim();
                let desc = false;
                if (s[0] === "-") {
                    desc = true;
                    s = s.substring(1);
                }
                if (firstDesc === null) {
                    firstDesc = desc;
                }
                return <IColumn>{
                    column: s,
                    reverse: firstDesc !== desc
                };
            });
            this._sortByColumn = this._sortByColumnArray[0].column;
            this._sortByDefaultDesc = firstDesc!;
        } else if (val[0] === "-") {
            this._sortByColumn = (val as unknown as string).substring(1);
            this._sortByDefaultDesc = true;
        } else {
            this._sortByColumn = val as unknown as string;
            this._sortByDefaultDesc = false;
        }

        if (this._sortByDefaultDescInput !== null)
            this._sortByDefaultDesc = this._sortByDefaultDescInput; // absolute priority
    }
}
