import { InjectionToken } from "@angular/core";

export const LG_APP_SESSION = new InjectionToken<IAppSession>("lgAppSession");

export interface IAppSession {
    clientId: number;
    client?: {
        id: number;
        code: string;
        name: string;
    };
    scenarioId?: number | undefined;
}
