import ldMerge from "lodash-es/merge";
import {
    FormsModule,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    UntypedFormControl,
    ValidationErrors
} from "@angular/forms";
import {
    ChangeDetectionStrategy,
    Component,
    forwardRef,
    inject,
    Input,
    OnDestroy,
    ViewEncapsulation
} from "@angular/core";
import { Subject } from "rxjs";
import {
    PASSWORD_CONTAIN_SPECIAL,
    PASSWORD_CONTAIN_UPPER_LOWER_NUMBER,
    PASSWORD_MIN_LENGTH,
    PASSWORD_SPECIAL_CHARS,
    X_OF_FOLLOWING_MUST_PASS
} from "./password-requirements";
import {
    LG_NEW_PASSWORD_CONFIG,
    PasswordConfigState,
    PasswordRequirementState
} from "./lg-new-password.types";
import { LgTranslateDirective, useTranslationNamespace } from "@logex/framework/lg-localization";
import { ValueAccessorBase } from "../inputs/index";
import { NgIf } from "@angular/common";
import { LgIconComponent } from "../lg-icon/lg-icon.component";
import { LgNewPasswordRequirementComponent } from "./lg-new-password-requirement.component";

@Component({
    standalone: true,
    selector: "lg-new-password",
    templateUrl: "./lg-new-password.component.html",
    viewProviders: [useTranslationNamespace("FW._Directives._NewPasswordComponent")],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => LgNewPasswordComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: LgNewPasswordComponent,
            multi: true
        }
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    imports: [
        FormsModule,
        NgIf,
        LgTranslateDirective,
        LgIconComponent,
        LgNewPasswordRequirementComponent
    ],
    host: {
        class: "lg-new-password"
    }
})
export class LgNewPasswordComponent extends ValueAccessorBase<string> implements OnDestroy {
    @Input() disabled = false;

    _passwordConfirm: string | null = null;
    _validatorChange!: () => void;
    _config!: PasswordConfigState;
    _passwordInvalid = false;

    _passwordVisible = false;
    _passwordConfirmVisible = false;

    private _destroyed = new Subject<void>();
    private _specialCharactersRegexp = new RegExp(PASSWORD_SPECIAL_CHARS.join("|"));

    constructor() {
        super();
        this._config = this._getDefaultConfig();
        const customConfig = inject(LG_NEW_PASSWORD_CONFIG, { optional: true });
        if (customConfig != null) {
            this._config = ldMerge(this._config, customConfig);
        }
        this._validateConfig();
    }

    ngOnDestroy(): void {
        this._destroyed.next();
        this._destroyed.complete();
    }

    writeValue(value: string): void {
        this._writeValue(value);
    }

    validate(control: UntypedFormControl): ValidationErrors | null {
        const minLength = !this._config.minLength.active || this._minLength(control.value);
        const confirmMatch =
            !this._config.confirmMatch.active || this._matchConfirmPassword(control.value);
        const xOfFollowing = !this._config.xOfFollowing.active || this._xOfFollowing(control.value);

        const valid = confirmMatch && minLength && xOfFollowing;
        this._passwordInvalid = !minLength || !xOfFollowing;

        return valid ? null : this._validationErrors;
    }

    registerOnValidatorChange(fn: () => void): void {
        this._validatorChange = fn;
    }

    private _getDefaultConfig(): PasswordConfigState {
        return {
            minLength: {
                active: true,
                passed: false,
                lcCode: "MinLength",
                level: 0,
                value: PASSWORD_MIN_LENGTH
            },
            xOfFollowing: {
                active: X_OF_FOLLOWING_MUST_PASS > 0,
                passed: false,
                lcCode: "XOfFollowing",
                level: 0,
                value: X_OF_FOLLOWING_MUST_PASS
            },
            confirmMatch: {
                active: true,
                passed: true,
                level: 0,
                lcCode: "ConfirmMatch"
            },
            upper: {
                active: PASSWORD_CONTAIN_UPPER_LOWER_NUMBER,
                passed: false,
                level: 1,
                lcCode: "Upper"
            },
            lower: {
                active: PASSWORD_CONTAIN_UPPER_LOWER_NUMBER,
                passed: false,
                level: 1,
                lcCode: "Lower"
            },
            number: {
                active: PASSWORD_CONTAIN_UPPER_LOWER_NUMBER,
                passed: false,
                level: 1,
                lcCode: "Number"
            },
            special: {
                active: PASSWORD_CONTAIN_SPECIAL,
                passed: false,
                level: 1,
                lcCode: "Special"
            }
        };
    }

    private _validateConfig(): void {
        // "following" check
        const oneOfFollowingActive =
            this._config.upper.active ||
            this._config.lower.active ||
            this._config.number.active ||
            this._config.special.active;

        if (!this._config.xOfFollowing.active && oneOfFollowingActive) {
            console.error(
                `PasswordConfig: "xOfFollowing" must be active to be able check upper/lower/number/special`
            );
        }

        // "level is correct" check
        // todo: drop? Since we don't allow configuring it (but arguably the source could still contain it)
        if (this._config.xOfFollowing.level !== 0) {
            console.error(`PasswordConfig: "xOfFollowing" should have level 0`);
        }

        if (this._config.confirmMatch.level !== 0) {
            console.error(`PasswordConfig: "confirmMatch" should have level 0`);
        }

        if (this._config.minLength.level !== 0) {
            console.error(`PasswordConfig: "minLength" should have level 0`);
        }

        if (this._config.lower.level !== 1) {
            console.error(`PasswordConfig: "lower" should have level 1`);
        }

        if (this._config.upper.level !== 1) {
            console.error(`PasswordConfig: "upper" should have level 1`);
        }

        if (this._config.number.level !== 1) {
            console.error(`PasswordConfig: "number" should have level 1`);
        }

        if (this._config.special.level !== 1) {
            console.error(`PasswordConfig: "special" should have level 1`);
        }

        // "passed is false" check
        Object.keys(this._config).forEach(key => {
            const requirement: PasswordRequirementState =
                this._config[key as keyof PasswordConfigState];
            if (requirement.passed && key !== "confirmMatch") {
                console.error(`PasswordConfig: "${key}" passed should be set to false`);
            }
        });
    }

    private get _validationErrors(): ValidationErrors {
        const errors: ValidationErrors = {};

        Object.keys(this._config).forEach(key => {
            const passwordCheck: PasswordRequirementState =
                this._config[key as keyof PasswordConfigState];
            if (passwordCheck.active && !passwordCheck.passed) {
                (errors as any)[this._toPascal(key)] = "invalid";
            }
        });

        return errors;
    }

    private _matchConfirmPassword(value: string): boolean {
        const valid = this._passwordConfirm === value;
        this._config.confirmMatch.passed = valid;
        return valid;
    }

    private _minLength(value: string): boolean {
        const valid = !!value && value.length >= this._config.minLength.value!;
        this._config.minLength.passed = valid;
        return valid;
    }

    private _xOfFollowing(value: string): boolean {
        let x = 0;

        if (this._config.special.active && this._hasSpecialChar(value)) {
            x++;
        }
        if (this._config.upper.active && this._hasUpperCase(value)) {
            x++;
        }
        if (this._config.lower.active && this._hasLowerCase(value)) {
            x++;
        }
        if (this._config.number.active && this._hasNumber(value)) {
            x++;
        }

        const valid = x >= this._config.xOfFollowing.value!;
        this._config.xOfFollowing.passed = valid;
        return valid;
    }

    private _hasSpecialChar(value: string): boolean {
        const valid = !!value && this._specialCharactersRegexp.test(value);
        this._config.special.passed = valid;
        return valid;
    }

    private _hasUpperCase(value: string): boolean {
        const valid = !!value && /[A-Z]/.test(value);
        this._config.upper.passed = valid;
        return valid;
    }

    private _hasLowerCase(value: string): boolean {
        const valid = !!value && /[a-z]/.test(value);
        this._config.lower.passed = valid;
        return valid;
    }

    private _hasNumber(value: string): boolean {
        const valid = !!value && /\d/.test(value);
        this._config.number.passed = valid;
        return valid;
    }

    private _toPascal(key: string): string {
        return key[0].toUpperCase() + key.substring(1);
    }
}
