import {
    Component,
    Input,
    Output,
    EventEmitter,
    ElementRef,
    HostBinding,
    ViewEncapsulation,
    ChangeDetectionStrategy,
    ViewChild
} from "@angular/core";
import { toBoolean } from "@logex/framework/utilities";
import { NgClass, NgIf } from "@angular/common";
import { LgTranslatePipe } from "@logex/framework/lg-localization";
import { LgIconComponent } from "./lg-icon/lg-icon.component";
import { LgSpinnerComponent } from "./lg-spinner";

@Component({
    selector: "lg-button",
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    // eslint-disable-next-line @angular-eslint/component-max-inline-declarations
    template: `
        <a
            [attr.href]="disabled ? null : '#'"
            #anchor
            (click)="onClick($event)"
            (dragStart)="onDragStart($event)"
            class="button {{ buttonClass ?? '' }}"
            [class.button--icons-right]="iconPositionedRight"
            [ngClass]="{
                disabled: disabled,
                'no-text': !text && !textLc,
                'cursor-no-click': _working
            }"
            title="{{ titleLc ? (titleLc | lgTranslate) : buttonTitle }}"
        >
            <lg-icon
                [icon]="icon"
                [inline]="true"
                [hidden]="!icon || _working"
                [style.color]="iconColor"
            ></lg-icon>

            <lg-spinner *ngIf="_working" class="button__spinner"></lg-spinner>

            <span
                [innerHTML]="textLc ? (textLc | lgTranslate) : text"
                [style.max-width.px]="maxWidth"
            ></span>
        </a>
    `,
    imports: [NgClass, LgTranslatePipe, LgIconComponent, NgIf, LgSpinnerComponent],
    host: {
        "[class.lg-button]": "true"
    }
})
export class LgButtonComponent {
    /**
     * Specifies if button is disabled.
     */
    @Input("isDisabled") disabled = false;

    /**
     * Button label. Used if `textLc` is not specified.
     */
    @Input() text: string | undefined = "";

    /**
     * HTML title attribute of button. Used if `titleLc` is not specified.
     */
    @Input("title") buttonTitle?: string | null;

    /**
     * Button icon.
     */
    @Input() icon?: string | null | undefined;

    @Input() iconPositionedRight = false;

    /**
     * Apply css class to the button element
     */
    @Input() buttonClass?: string | null | undefined;

    /**
     * Localized button label. If not specified `text` is used.
     */
    @Input() textLc?: string | null | undefined;

    /**
     * Localized HTML title attribute of button. If not specified `title` is used.
     */
    @Input() titleLc?: string | null;

    /**
     * Maximal width of the button.
     */
    @Input() maxWidth?: number;

    @Input() iconColor?: string | null;

    /**
     * Show working spinner.
     */
    @Input() set working(value: any) {
        this._working = toBoolean(value, false);
    }

    get working(): any {
        return this._working;
    }

    // eslint-disable-next-line @angular-eslint/no-output-native
    @Output() readonly click = new EventEmitter();

    @ViewChild("anchor", { static: true }) anchor!: ElementRef<HTMLAnchorElement>;

    @HostBinding("attr.title") hostTitle: string | null = null;

    _working = false;

    public onClick($event: Event): boolean {
        $event.stopPropagation();
        $event.preventDefault();

        if (this.disabled || this._working) {
            $event.stopImmediatePropagation();
            return false;
        }

        this._blur();
        // todo: make own event?
        this.click.next($event);
        return true;
    }

    public onDragStart($event: Event): boolean {
        $event.preventDefault();
        return false;
    }

    private _blur(): void {
        this._getAnchorElement().blur();
    }

    private _getAnchorElement(): HTMLAnchorElement {
        return this.anchor.nativeElement;
    }
}
