import { ChangeDetectionStrategy, Component, EventEmitter, Output } from "@angular/core";

@Component({
    standalone: false,
    selector: "lg-chat-bubble",
    templateUrl: "./lg-chat-bubble.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LgChatBubbleComponent {
    @Output() readonly chatToggled = new EventEmitter();

    _toggleChat(): void {
        this.chatToggled.emit();
    }
}
