import { IStringLookup } from "@logex/framework/types";
import { Observable } from "rxjs";
import { InjectionToken } from "@angular/core";

export const LG_USER_STORAGE_SERVICE_GATEWAY = new InjectionToken<IUserStorageServiceGateway>(
    "LG_USER_STORAGE_SERVICE_GATEWAY"
);

export interface IStoreIdentifier {
    key: string;
    binding: StorageBinding;
}

export interface IUserStorageForSync {
    data: any;
    binding: StorageBinding;
}

export enum StorageBinding {
    Hospital = 1,
    DataVersion = 2,
    Version = 3
}

export interface IUserStorageGetResponse {
    list: IStringLookup<any>;
}

export interface IUserStorageServiceGateway {
    get(keysToLoad: IStoreIdentifier[]): Observable<IUserStorageGetResponse>;
    set(toSync: IStringLookup<IUserStorageForSync>): Observable<any>;
}
