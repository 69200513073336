import {
    ApplicationRef,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    LOCALE_ID,
    OnDestroy,
    Renderer2,
    ViewChild,
    ViewEncapsulation,
    inject
} from "@angular/core";
import { DatePipe, NgIf } from "@angular/common";
import {
    DELETE,
    DOWN_ARROW,
    END,
    ENTER,
    ESCAPE,
    HOME,
    SHIFT,
    TAB,
    UP_ARROW
} from "@angular/cdk/keycodes";
import { FlexibleConnectedPositionStrategy } from "@angular/cdk/overlay";
import { Subject } from "rxjs";
import {
    LgTranslatePipe,
    LgTranslateService,
    useTranslationNamespace
} from "@logex/framework/lg-localization";
import { LgPromptDialog } from "../lg-prompt-dialog/lg-prompt-dialog.component";
import { eatOneKeyUpEvent } from "../../helpers";
import { LgTime } from "./lg-time-picker.types";
import { showInvalidTimeDialog } from "./showInvalidTimeDialog";
import { LgVirtualForOf } from "../../virtual-scrolling";
import { LgScrollableDirective } from "../../scrolling";
import {
    LgCopyHandlerDirective,
    LgDefaultFocusDirective,
    LgPasteHandlerDirective
} from "../../behavior";

export interface ILgTimePickerTooltipComponentProps {
    minTime: LgTime | undefined;
    maxTime: LgTime | undefined;
    required: boolean;
    onSelect: (time: LgTime | null, cancelled: boolean, navigationDelta: -1 | 0 | 1 | null) => void;
    selectedTime?: LgTime;
    defaultTime?: LgTime | undefined;
    condensed?: boolean;
    hoursFormat: string;
    hoursSeparator: string;
    minutesFormat: string;
    minutesSeparator: string;
    is24h: boolean;
    positionStrategy: FlexibleConnectedPositionStrategy | null;
    tabBackCancels: boolean;
    getCopyData(selectedTime: LgTime | null | undefined): string | null;
    pasteData(text: string): boolean | null;
}

type Element = "hours" | "minutes" | "period";

interface AutocompleteItem {
    text: string;
    time: LgTime | null;
}

@Component({
    standalone: true,
    selector: "lg-time-picker-popup",
    templateUrl: "./lg-time-picker-popup.component.html",
    // TODO: change once virtualForOf can handle OnPush
    changeDetection: ChangeDetectionStrategy.Default,
    encapsulation: ViewEncapsulation.None,
    viewProviders: [useTranslationNamespace("FW._Directives._LgTimePicker")],
    imports: [
        NgIf,
        LgVirtualForOf,
        LgScrollableDirective,
        LgCopyHandlerDirective,
        LgPasteHandlerDirective,
        LgDefaultFocusDirective,
        LgTranslatePipe
    ],
    host: {
        class: "lg-time-picker-popup",
        "[class.lg-time-picker-popup--condensed]": "_condensed",
        "[class.lg-time-picker-popup--above]": "_showAbove",
        "[class.lg-time-picker-popup--below]": "!_showAbove"
    }
})
export class LgTimePickerPopupComponent implements OnDestroy {
    private _applicationRef = inject(ApplicationRef);
    private _changeDetector = inject(ChangeDetectorRef);
    private _promptDialog = inject(LgPromptDialog);
    private _datePipe = inject(DatePipe);
    private _lgTranslate = inject(LgTranslateService);
    private _locale = inject(LOCALE_ID);
    private _renderer = inject(Renderer2);

    @HostListener("document:keydown", ["$event"]) keydownEvent(event: KeyboardEvent): void {
        this._onDocumentKeyPressed(event);
    }

    _showAbove = false;

    _props!: ILgTimePickerTooltipComponentProps;
    _initialized: boolean;
    _condensed = false;

    _is24h!: boolean;
    _hoursSeparator!: string;
    _minutesSeparator!: string;

    _focusedElement: Element | null = null;
    _hoursInputValue = "";
    _hoursInputPlaceholder = "";
    _minutesInputValue = "";
    _minutesInputPlaceholder = "";
    _periodInputValue = "";
    _periodInputPlaceholder = "";

    _autocomplete: AutocompleteItem[] = [];
    _autocompleteItemHeight = 36;
    _currentAutocompleteIndex = -1;

    readonly _copyHandler = (): string | null => this._props.getCopyData(this._getCurrentTime());
    readonly _pasteHandler = (text: string): boolean => this._pasteData(text);
    readonly _visibleAutocompleteIndex$ = new Subject<number>();

    @ViewChild("hoursInput") hoursInputRef!: ElementRef<HTMLInputElement>;
    @ViewChild("minutesInput") minutesInputRef!: ElementRef<HTMLInputElement>;
    @ViewChild("periodInput") periodInputRef!: ElementRef<HTMLInputElement>;

    private _minTime: LgTime | null = null;
    private _maxTime: LgTime | null = null;
    private _amPeriods: string[];
    private _pmPeriods: string[];
    private _amSymbol: string;
    private _pmSymbol: string;
    private _timeFormat!: string;
    private _timeSelected = false;

    constructor() {
        this._initialized = false;
        this._amSymbol = this._datePipe.transform(new Date(2020, 0, 1, 0, 0, 0), "a")!;
        this._pmSymbol = this._datePipe.transform(new Date(2020, 0, 1, 13, 0, 0), "a")!;
        let formatted = this._amSymbol.toLowerCase().substring(0, 3);
        this._amPeriods = [
            "am",
            formatted,
            formatted.substring(0, 3),
            formatted.replace(/\W/g, "")
        ];
        formatted = this._pmSymbol.toLowerCase().substring(0, 3);
        this._pmPeriods = [
            "pm",
            formatted,
            formatted.substring(0, 3),
            formatted.replace(/\W/g, "")
        ];
        this._amSymbol = this._amSymbol.substring(0, 3);
        this._pmSymbol = this._pmSymbol.substring(0, 3);
    }

    ngOnDestroy(): void {
        if (this._timeSelected) {
            return;
        }
        const time = this._getCurrentTime();
        this._attemptSelectTime(time, {
            navigationDelta: null,
            enterPressed: false,
            cancelEvent: true
        });
    }

    initialize(props: ILgTimePickerTooltipComponentProps, forceValue: boolean): void {
        this._props = { ...this._props, ...props };

        this._minTime = this._props.minTime ?? null;
        this._maxTime = this._props.maxTime ?? null;

        let setPending = false;
        if (this._hoursInputValue === "" || forceValue) {
            let initial = this._props.selectedTime;
            if (!initial) initial = this._props.defaultTime;
            if (initial) {
                // give angular chance to find the input templates
                setPending = true;
                setTimeout(() => {
                    this._setTime(initial!);
                    this._fillAutocomplete();
                }, 0);
            }
        }

        this._condensed = this._props.condensed || false;
        this._hoursSeparator = this._props.hoursSeparator;
        this._minutesSeparator = this._props.minutesSeparator;
        this._is24h = this._props.is24h;

        this._timeFormat = `${this._props.hoursFormat}${this._props.hoursSeparator}${this._props.minutesFormat}`;
        if (!this._is24h) {
            this._timeFormat += `${this._props.minutesSeparator}a`;
        }

        if (!setPending) this._fillAutocomplete();

        this._autocompleteItemHeight = this._condensed ? 24 : 36;

        this._initialized = true;
    }

    updateProps(props: ILgTimePickerTooltipComponentProps, forceValue: boolean): void {
        this._initialized = false;

        this.initialize(props, forceValue);
        this._changeDetector.markForCheck();
        setTimeout(() => {
            this._applicationRef.tick();
        }, 0);
    }

    _onHoursKeyUp(event: KeyboardEvent): void {
        if (
            event.keyCode === TAB ||
            event.keyCode === SHIFT ||
            event.keyCode === UP_ARROW ||
            event.keyCode === DOWN_ARROW
        )
            return;

        if (
            this._hoursInputValue.length === 2 ||
            +this._hoursInputValue > 2 ||
            (!this._is24h && +this._hoursInputValue > 1)
        ) {
            this._changeDetector.markForCheck();
            setTimeout(() => this.minutesInputRef.nativeElement.focus(), 50);
        }
    }

    _disableSpinner(event: KeyboardEvent): void {
        if (event.keyCode === UP_ARROW || event.keyCode === DOWN_ARROW) {
            event.preventDefault();
        }
    }

    _onHoursInput(event: InputEvent): void {
        let { value, overwriteInput } = this._coerceInput(event, 0, this._is24h ? 23 : 12);

        if (!overwriteInput && value === "00" && !this._is24h) {
            value = "0";
            overwriteInput = true;
        }

        if (overwriteInput) {
            this._setHoursValue(value);
        } else {
            this._hoursInputValue = value;
        }

        // maybe prefill am/pm if min/max is specified
        if (!this._is24h && this._periodInputPlaceholder === "") {
            if (this._minTime && this._minTime.hours >= 12) {
                this._periodInputPlaceholder = this._pmSymbol;
            } else if (this._maxTime && this._maxTime.hours < 12) {
                this._periodInputPlaceholder = this._amSymbol;
            }
        }
        this._fillAutocomplete();
    }

    _onHoursBlur(): void {
        if (this._hoursInputValue !== "") {
            this._setHoursValue(this._formatHours(this._hoursInputValue));
        } else if (this._hoursInputPlaceholder !== "") {
            this._hoursInputPlaceholder = this._formatHours(this._hoursInputPlaceholder);
        }
    }

    _onMinutesKeyUp(event: KeyboardEvent): void {
        if (
            event.keyCode === TAB ||
            event.keyCode === SHIFT ||
            event.keyCode === UP_ARROW ||
            event.keyCode === DOWN_ARROW ||
            this._is24h
        )
            return;

        if (this._minutesInputValue.length === 2 || +this._minutesInputValue > 6) {
            this._changeDetector.markForCheck();
            setTimeout(() => this.periodInputRef.nativeElement.focus(), 50);
        }
    }

    _onMinutesInput(event: InputEvent): void {
        const { value, overwriteInput } = this._coerceInput(event, 0, 59);

        this._minutesInputValue = value;
        if (overwriteInput) {
            this._setMinutesValue(value);
        } else {
            this._minutesInputValue = value;
        }
        this._fillAutocomplete();
    }

    _onMinutesBlur(): void {
        if (this._minutesInputValue !== "") {
            this._setMinutesValue(this._formatMinutes(this._minutesInputValue));
        } else if (this._minutesInputPlaceholder !== "") {
            this._minutesInputPlaceholder = this._formatMinutes(this._minutesInputPlaceholder);
        }
    }

    _onPeriodInput(event: InputEvent): void {
        const input = (event.target as HTMLInputElement).value ?? event.data ?? "";
        let coerced = this._coercePeriod(input);
        if (coerced === "") {
            coerced = this._coercePeriod(event.data ?? "");
        }
        if (coerced !== input) {
            this._setPeriodValue(coerced);
        } else {
            this._periodInputValue = coerced;
        }
        this._fillAutocomplete();
    }

    _onFocus(element: Element): void {
        this._focusedElement = element;

        switch (element) {
            // @ts-expect-error we evaluate the inputs top to bottom
            case "hours":
                if (this._hoursInputValue) {
                    this._hoursInputPlaceholder = this._hoursInputValue;
                    this._setHoursValue("");
                    // this._setDayAndTryToFindClosestDate("");
                }
            // @ts-expect-error we evaluate the inputs top to bottom
            // eslint-disable-next-line no-fallthrough
            case "minutes":
                if (this._minutesInputValue) {
                    this._minutesInputPlaceholder = this._minutesInputValue;
                    this._setMinutesValue("");
                }
            // eslint-disable-next-line no-fallthrough
            case "period":
                if (this._periodInputValue) {
                    this._periodInputPlaceholder = this._periodInputValue;
                    this._setPeriodValue("");
                }
        }
        this._fillAutocomplete();
    }

    _onInputClick(event: MouseEvent): void {
        const t = (event.target as HTMLInputElement).value;
        // remove value and reinsert it so the cursor is always after last character
        this._renderer.setProperty(event.target, "value", "");
        this._renderer.setProperty(event.target, "value", t);
    }

    _onClickAutocompleteItem(item: AutocompleteItem): boolean {
        this._selectTime(item.time, false, 0);
        return false;
    }

    private _pasteData(text: string): boolean {
        const result = this._props.pasteData(text);
        if (result === null) {
            this._onInvalidTimeEntered();
            return true;
        } else {
            return result;
        }
    }

    private _coerceInput(
        event: InputEvent,
        min: number,
        max: number
    ): { value: string; overwriteInput: boolean } {
        let input = (event.target as HTMLInputElement).value ?? event.data; // when user enters "-" or "+" then event.target.value is empty string which 'isNaN' treats like a number
        let value: string | null;
        let overwriteInput = true;
        let force = false;
        if (input.length > 2) {
            input = input.substring(1, 3);
            force = true;
        }

        if (input === "" || isNaN(+input)) {
            value = "";
        } else if (+input > max || +input < min) {
            value = event.data && +event.data > min ? event.data : "";
        } else if (force) {
            value = input;
        } else {
            value = (event.target as HTMLInputElement).value;
            overwriteInput = false;
        }

        return { value, overwriteInput };
    }

    private _coercePeriod(value: string): string {
        const lowerValue = value.toLowerCase();
        const amPrefix = this._amPeriods.find(p => p.indexOf(lowerValue) === 0);
        const pmPrefix = this._pmPeriods.find(p => p.indexOf(lowerValue) === 0);
        if (this._amPeriods.indexOf(lowerValue) !== -1 || (amPrefix && !pmPrefix)) {
            return this._amSymbol;
        }
        if (this._pmPeriods.indexOf(lowerValue) !== -1 || (!amPrefix && pmPrefix)) {
            return this._pmSymbol;
        }
        if (!amPrefix && !pmPrefix) {
            return "";
        }
        return value;
    }

    private _formatHours(value: string): string {
        return this._datePipe.transform(new Date(2020, 0, 1, +value, 0), this._props.hoursFormat)!;
    }

    private _formatMinutes(value: string): string {
        return this._datePipe.transform(
            new Date(2020, 0, 1, 0, +value),
            this._props.minutesFormat
        )!;
    }

    private _onDocumentKeyPressed(event: KeyboardEvent): void {
        const separator =
            this._focusedElement === "hours"
                ? this._hoursSeparator
                : this._is24h
                  ? null
                  : this._minutesSeparator;
        let cancelEvent = false;
        // always trigger on some default separators so that people's muscle memory works no matter the format
        if (
            event.key === "/" ||
            event.key === "-" ||
            event.key === "." ||
            event.key === "," ||
            event.key === ":" ||
            event.key === " " ||
            (separator && event.key === separator)
        ) {
            this._onTabbingForward();
            event.preventDefault();
            event.stopPropagation();
            return;
        }

        let attemptSelect = false;
        let enterPressed = false;
        switch (event.keyCode) {
            case TAB:
                event.preventDefault();
                if (event.shiftKey) {
                    if (this._onTabbingBackwards() && this._props.tabBackCancels) {
                        this._selectTime(this._props.selectedTime, true, -1);
                    }
                } else {
                    attemptSelect = this._onTabbingForward();
                }
                break;
            case ESCAPE:
                this._selectTime(this._props.selectedTime, true, 0);
                cancelEvent = true;
                break;
            case DELETE:
                this._setTime(this._props.required ? this._props.selectedTime : null);
                break;
            case ENTER:
                enterPressed = true;
                break;
            case UP_ARROW:
                if (this._autocomplete.length) {
                    if (this._currentAutocompleteIndex >= 0) {
                        event.preventDefault();
                        this._currentAutocompleteIndex -= 1;
                    }
                    this._visibleAutocompleteIndex$.next(this._currentAutocompleteIndex);
                }
                break;
            case DOWN_ARROW:
                if (this._autocomplete.length) {
                    if (this._currentAutocompleteIndex < this._autocomplete.length - 1) {
                        event.preventDefault();
                        this._currentAutocompleteIndex += 1;
                    }
                    this._visibleAutocompleteIndex$.next(this._currentAutocompleteIndex);
                }
                break;
            case HOME:
                if (this._autocomplete.length) {
                    if (this._currentAutocompleteIndex !== 0) {
                        event.preventDefault();
                        this._currentAutocompleteIndex = 0;
                    }
                    this._visibleAutocompleteIndex$.next(this._currentAutocompleteIndex);
                }
                break;
            case END:
                if (this._autocomplete.length) {
                    if (this._currentAutocompleteIndex !== this._autocomplete.length - 1) {
                        event.preventDefault();
                        this._currentAutocompleteIndex = this._autocomplete.length - 1;
                    }
                    this._visibleAutocompleteIndex$.next(this._currentAutocompleteIndex);
                }
                break;
        }
        if (attemptSelect || enterPressed) {
            let time: LgTime | null | undefined;
            if (this._currentAutocompleteIndex !== -1) {
                time = this._autocomplete[this._currentAutocompleteIndex].time;
            } else {
                time = this._getCurrentTime();
            }
            cancelEvent = this._attemptSelectTime(time, {
                navigationDelta: attemptSelect ? 1 : 0,
                enterPressed,
                cancelEvent
            });
        }
        if (cancelEvent) {
            event.stopPropagation();
            event.preventDefault();
            eatOneKeyUpEvent(event);
        }
    }

    private _attemptSelectTime(
        time: LgTime | null | undefined,
        {
            navigationDelta,
            enterPressed,
            cancelEvent
        }: {
            navigationDelta: -1 | 0 | 1 | null;
            enterPressed: boolean;
            cancelEvent: boolean;
        }
    ): boolean {
        if (time === undefined) return cancelEvent;
        if (time === null && this._props.required) time = this._props.selectedTime;
        const emptyOk = time !== null || !this._props.required;
        const minOk =
            time === null ||
            time === undefined ||
            !this._minTime ||
            time.hours > this._minTime.hours ||
            (time.hours === this._minTime.hours && time.minutes >= this._minTime.minutes);
        const maxOk =
            time === null ||
            time === undefined ||
            !this._maxTime ||
            time.hours < this._maxTime.hours ||
            (time.hours === this._maxTime.hours && time.minutes <= this._maxTime.minutes);
        if (emptyOk && minOk && maxOk) {
            this._selectTime(time, false, navigationDelta);
            cancelEvent = true;
        } else if (emptyOk && enterPressed) {
            this._onInvalidTimeEntered();
        }
        return cancelEvent;
    }

    private _onTabbingForward(): boolean {
        if (
            this._hoursInputValue === "" ||
            this._focusedElement === "hours" ||
            this._focusedElement === null
        ) {
            if (this._hoursInputValue === "" && this._hoursInputPlaceholder !== "") {
                this._setHoursValue(this._hoursInputPlaceholder);
            }
            if (this._hoursInputValue !== "") {
                // todo this._setSelectedDate(new Date(this._currentYear, this._currentMonth - 1, dayOfMonth));
                setTimeout(() => this.minutesInputRef.nativeElement.focus(), 0);
            }
            return false;
        }
        if (!this._is24h && this._focusedElement === "minutes") {
            if (this._minutesInputValue === "" && this._minutesInputPlaceholder !== "") {
                this._setMinutesValue(this._minutesInputPlaceholder);
            }
            if (this._minutesInputValue !== "") {
                // todo this._setSelectedDate(new Date(this._currentYear, this._currentMonth - 1, dayOfMonth));
                setTimeout(() => this.periodInputRef.nativeElement.focus(), 0);
            }
            return false;
        }
        return true;
    }

    private _onTabbingBackwards(): boolean {
        if (this._focusedElement === "period") {
            this.minutesInputRef.nativeElement.focus();
            return false;
        } else if (this._focusedElement === "minutes") {
            this.hoursInputRef.nativeElement.focus();
            return false;
        } else {
            return true;
        }
    }

    private _setHoursValue(value: string): void {
        this._hoursInputValue = value;
        this._renderer.setProperty(this.hoursInputRef.nativeElement, "value", value);
    }

    private _setMinutesValue(value: string): void {
        this._minutesInputValue = value;
        this._renderer.setProperty(this.minutesInputRef.nativeElement, "value", value);
    }

    private _setPeriodValue(value: string): void {
        this._renderer.setProperty(this.periodInputRef.nativeElement, "value", value);
        this._periodInputValue = value;
    }

    private _setTime(time: LgTime | null | undefined): void {
        setTimeout(() => this.hoursInputRef.nativeElement.focus(), 0);
        this._setHoursValue("");
        this._setMinutesValue("");
        if (this.periodInputRef) {
            this._setPeriodValue("");
        } else {
            this._periodInputValue = "";
        }
        if (!time) {
            this._hoursInputPlaceholder = "";
            this._minutesInputPlaceholder = "";
            this._periodInputPlaceholder = "";
        } else {
            this._hoursInputPlaceholder = this._formatHours(time.hours.toString());
            this._minutesInputPlaceholder = this._formatMinutes(time.minutes.toString());
            if (this._is24h) {
                this._periodInputPlaceholder = "";
            } else {
                this._periodInputPlaceholder =
                    time.hours % 24 >= 12 ? this._pmSymbol : this._amSymbol;
            }
        }
    }

    private _getCurrentTime(): LgTime | null | undefined {
        const hoursValue =
            this._hoursInputValue !== "" ? this._hoursInputValue : this._hoursInputPlaceholder;
        const minutesValue =
            this._minutesInputValue !== ""
                ? this._minutesInputValue
                : this._minutesInputPlaceholder;
        const periodValue = this._periodInputValue || this._periodInputPlaceholder;
        const periodCoerced = this._coercePeriod(periodValue);
        const periodOk =
            this._is24h || periodCoerced === this._amSymbol || periodCoerced === this._pmSymbol;
        // no input
        if (hoursValue === "" && minutesValue === "" && (this._is24h || periodValue === ""))
            return null;
        // invalid input
        if (hoursValue === "" || minutesValue === "" || !periodOk) return undefined;
        let hours = +hoursValue;
        if (!this._is24h) {
            if (hours > 12) hours -= 12;
            if (periodCoerced === this._pmSymbol) hours = (hours + 12) % 24;
        }
        return {
            hours,
            minutes: +minutesValue
        };
    }

    private _selectTime(
        time: LgTime | null | undefined,
        cancelled: boolean,
        navigationDelta: -1 | 0 | 1 | null
    ): void {
        this._timeSelected = true;
        this._props.onSelect(time || null, cancelled, navigationDelta);
    }

    private _onInvalidTimeEntered(): void {
        showInvalidTimeDialog(
            this._minTime,
            this._maxTime,
            this._timeFormat,
            this._datePipe,
            this._lgTranslate,
            this._promptDialog
        ).then(() => {
            switch (this._focusedElement) {
                default:
                case "hours":
                    this.hoursInputRef.nativeElement.focus();
                    break;
                case "minutes":
                    this.minutesInputRef.nativeElement.focus();
                    break;
                case "period":
                    this.periodInputRef.nativeElement.focus();
                    break;
            }
        });
    }

    private _fillAutocomplete(): void {
        const oldLength = this._autocomplete.length;
        this._autocomplete = [];
        const lastSelection =
            this._currentAutocompleteIndex === -1
                ? null
                : this._autocomplete[this._currentAutocompleteIndex]?.text ?? null;
        this._currentAutocompleteIndex = -1;

        let from = 0;
        let to = 23 * 60 + 45;
        let step = 15;
        if (this._focusedElement === "period") {
            from = +this._hoursInputValue * 60 + +this._minutesInputValue;
            step = 12 * 60;
            to = from + step;
        } else if (
            this._focusedElement === "minutes" &&
            this._minutesInputValue.length === 2 &&
            this._is24h
        ) {
            // do not show anything
            from = to + 1;
        }
        if (this._minTime) {
            const minTime =
                Math.ceil((this._minTime.hours * 60 + this._minTime.minutes) / step) * step;
            from = Math.max(from, minTime);
        }
        if (this._maxTime) {
            const maxTime =
                Math.floor((this._maxTime.hours * 60 + this._maxTime.minutes) / step) * step;
            to = Math.min(to, maxTime);
        }

        let zeroPrefix = false;
        let prefix1: string | null = null;
        let prefix2: string | null = null;
        if (
            this._minutesInputValue !== "" ||
            this._hoursInputValue.length === 2 ||
            (this._focusedElement !== "hours" && this._focusedElement !== null)
        ) {
            prefix1 = `${this._formatHours(this._hoursInputValue)}${this._hoursSeparator}${
                this._minutesInputValue
            }`;
            if (!this._is24h && this._periodInputValue !== "") {
                prefix1 += `${this._minutesSeparator}${this._periodInputValue}`;
            }
        } else if (this._hoursInputValue !== "") {
            if (this._hoursInputValue === "0" && this._props.hoursFormat?.length === 1) {
                zeroPrefix = true;
            } else {
                prefix1 = this._hoursInputValue;
                if (this._props.hoursFormat?.length === 2) {
                    prefix2 = "0" + prefix1;
                }
            }
        }

        for (let i = from; i <= to; i += step) {
            const hours = Math.floor(i / 60);
            if (zeroPrefix && hours > 9) continue;
            const minutes = i % 60;
            const text = this._datePipe.transform(
                new Date(2020, 0, 1, hours, minutes),
                this._timeFormat
            )!;
            const ok =
                prefix1 === null ||
                text.indexOf(prefix1) === 0 ||
                prefix2 === null ||
                text.indexOf(prefix2) === 0;
            if (ok) {
                if (lastSelection === text)
                    this._currentAutocompleteIndex = this._autocomplete.length;
                this._autocomplete.push({
                    text,
                    time: {
                        hours,
                        minutes
                    }
                });
            }
        }

        // If we would show only 1 am/pm value, and the period itself is already filled, hide it
        if (
            this._focusedElement === "period" &&
            (this._periodInputValue === this._amSymbol || this._periodInputValue === this._pmSymbol)
        ) {
            this._autocomplete = [];
        }

        if (!this._props.required) {
            const text = this._lgTranslate.translate(".Remove");
            if (this._currentAutocompleteIndex !== -1) {
                this._currentAutocompleteIndex += 1;
            } else if (lastSelection === text) {
                this._currentAutocompleteIndex = 0;
            }
            this._autocomplete.unshift({
                text,
                time: null
            });
        }
        if (this._autocomplete.length > oldLength) {
            setTimeout(() => {
                this._props.positionStrategy?.apply();
            }, 0);
        }
    }
}
