import { Provider, Type } from "@angular/core";
import { lgProvide, lgProvideInterceptor, LgProvider, LgProviders } from "@logex/framework/types";
import {
    AppUser,
    IUser,
    IUserStorageServiceGateway,
    LG_USER_INFO,
    LG_USER_STORAGE_SERVICE_GATEWAY,
    LgUserStorageGatewayV2
} from "../user";
import {
    AppInfo,
    IAppConfiguration,
    IAppControlService,
    IAppInfo,
    IAppSession,
    LG_APP_CONFIGURATION,
    LG_APP_CONTROL,
    LG_APP_GET_PERIOD_NAME,
    LG_APP_INFO,
    LG_APP_SESSION,
    LgAppControlV2Service
} from "../application";
import {
    LgAcceptLanguageInterceptor,
    LgAuthorizationInterceptor,
    LgMessageBusConnectionIdInterceptor
} from "../http";
import {
    IAuthenticationService,
    ILgAuthorizationService,
    ITokenAuthenticationService,
    LG_AUTHENTICATION_SERVICE,
    LG_AUTHORIZATION_SERVICE,
    LgStubAuthenticationService
} from "../auth";
import { IDefinitions, LG_APP_DEFINITIONS } from "../base";
import {
    ILgDefinitionsHierarchyService,
    LG_DEFINITIONS_HIERARCHY_SERVICE
} from "../definitions-hierarchy";
import {
    IMatomoConfiguration,
    IUserflowConfiguration,
    IUserflowService,
    LG_MATOMO_CONFIGURATION,
    LG_USERFLOW_CONFIGURATION,
    LG_USERFLOW_SERVICE,
    LgUserflowService
} from "../integration";
import {
    INavigationChangeHandler,
    INavNode,
    LG_NAVIGATION,
    LG_NAVIGATION_CHANGE_HANDLER
} from "../navigation";
import { Observable } from "rxjs";
import { IMessageBusService, LG_MESSAGE_BUS_SERVICE, LgMessageBusService } from "../network";
import { IMatomoPostponeConfiguration } from "../integration/matomo-configuration";

export interface LgApplicationConfig {
    /**
     * Provides application configuration (token `LG_APP_CONFIGURATION`).
     * Required. No default value.
     */
    appConfiguration: LgProvider<IAppConfiguration>;
    /**
     * Provides navigation data (token `LG_NAVIGATION`).
     * Required. No default value.
     */
    navigation: LgProvider<INavNode[] | Observable<INavNode[]>>;
    /**
     * Provides application control service (token `LG_APP_CONTROL`).
     * Optional. `LgAppControlV2Service` is used by default in `LgApplicationModule`.
     */
    appControl?: LgProvider<IAppControlService>;
    /**
     * Provides application information (token `LG_APP_INFO`).
     * Optional. Class `AppInfo` is used by default in `LgApplicationModule`.
     */
    appInfo?: LgProvider<IAppInfo>;
    /**
     * Provides application session (token `LG_APP_SESSION`).
     * Optional. Factory `lgAppSessionMockFactory` is used by default in `LgApplicationModule`.
     */
    appSession?: LgProvider<IAppSession>;
    /**
     * Provides authentication service (token `LG_AUTHENTICATION_SERVICE`).
     * Optional. Class `LgStubAuthenticationService` is used by default in `LgApplicationModule`.
     */
    authenticationService?: LgProvider<IAuthenticationService | ITokenAuthenticationService>;
    /**
     * Provides authorization service (token `LG_AUTHORIZATION_SERVICE`).
     * Optional. No default value.
     */
    authorizationService?: LgProvider<ILgAuthorizationService>;
    /**
     * Provides definitions (token `LG_APP_DEFINITIONS`).
     * Optional. No default value.
     */
    definitions?: LgProvider<IDefinitions<object>>;
    /**
     * Provides definitions hierarchy service (token `LG_DEFINITIONS_HIERARCHY_SERVICE`).
     * Optional. No default value.
     */
    definitionsHierarchyService?: LgProvider<ILgDefinitionsHierarchyService>;
    /**
     * Provides function to get period name (token `LG_APP_GET_PERIOD_NAME`).
     * Optional. No default value.
     */
    getPeriodName?: LgProvider<(data: unknown, showDataVersionPeriod?: boolean) => string>;
    /**
     * Specifies if http interceptor to accept language need to be provided (token `HTTP_INTERCEPTORS`).
     * Optional. No default value.
     */
    hasAcceptLanguageInterceptor?: boolean;
    /**
     * Specifies if http interceptor to add authorization header need to be provided (token `HTTP_INTERCEPTORS`).
     * Optional. No default value.
     */
    hasAuthorizationInterceptor?: boolean;
    /**
     * Specifies if http interceptor to add message bus connection id need to be provided (token `HTTP_INTERCEPTORS`).
     * Optional. No default value.
     */
    hasMessageBusConnectionIdInterceptor?: boolean;
    /**
     * Provides Matomo configuration (token `LG_MATOMO_CONFIGURATION`).
     * Optional. No default value.
     */
    matomoConfiguration?: LgProvider<IMatomoConfiguration | IMatomoPostponeConfiguration>;
    /**
     * Provides message bus service (token `LG_MESSAGE_BUS_SERVICE`).
     * Optional. `LgMessageBusService` is used by default in `LgApplicationModule`.
     */
    messageBusService?: LgProvider<IMessageBusService>;
    /**
     * Provides navigation change handler (token `LG_NAVIGATION_CHANGE_HANDLER`).
     * Optional. No default value.
     */
    navigationChangeHandler?: LgProvider<INavigationChangeHandler>;
    /**
     * Provides user information (token `LG_USER_INFO`).
     * Optional. Class `AppUser` is used by default in `LgApplicationModule`.
     */
    userInfo?: LgProvider<IUser>;
    /**
     * Provides user flow configuration (token `LG_USERFLOW_CONFIGURATION`).
     * Optional. No default value.
     */
    userFlowConfiguration?: LgProvider<IUserflowConfiguration>;
    /**
     * Provides user flow service (token `LG_USERFLOW_SERVICE`).
     * Optional. `LgUserflowService` is used by default in `LgApplicationModule`.
     */
    userFlowService?: LgProvider<IUserflowService>;
    /**
     * Provides user storage service gateway (token `LG_USER_STORAGE_SERVICE_GATEWAY`).
     * Optional. `LgUserStorageGatewayV2` is used by default in `LgApplicationModule`.
     */
    userStorageServiceGateway?: LgProvider<IUserStorageServiceGateway>;
}

/**
 * Helper utility to configure and provide `LgApplicationModule` providers
 */
export const lgApplicationProviders: LgProviders<LgApplicationConfig> = (
    config?: LgApplicationConfig
): Provider[] => {
    const providers: Provider[] = [];
    // Providers with defaults:
    providers.push(
        lgProvide(LG_APP_CONTROL, config?.appControl ?? { useClass: LgAppControlV2Service }),
        lgProvide(LG_APP_INFO, config?.appInfo ?? { useValue: new AppInfo() }),
        lgProvide(
            LG_AUTHENTICATION_SERVICE,
            config?.authenticationService ?? { useClass: LgStubAuthenticationService }
        ),
        lgProvide(
            LG_MESSAGE_BUS_SERVICE,
            config?.messageBusService ?? { useClass: LgMessageBusService }
        ),
        lgProvide(LG_USER_INFO, config?.userInfo ?? { useValue: new AppUser() }),
        lgProvide(
            LG_USER_STORAGE_SERVICE_GATEWAY,
            config?.userStorageServiceGateway ?? { useClass: LgUserStorageGatewayV2 }
        ),
        lgProvide(
            LG_USERFLOW_SERVICE,
            config?.userFlowService ?? { useExisting: LgUserflowService }
        )
    );
    // Providers without defaults (optional and required):
    if (config?.appConfiguration) {
        providers.push(lgProvide(LG_APP_CONFIGURATION, config.appConfiguration));
    }
    if (config?.appSession) {
        providers.push(lgProvide(LG_APP_SESSION, config.appSession));
    }
    if (config?.navigation) {
        providers.push(lgProvide(LG_NAVIGATION, config.navigation));
    }
    if (config?.getPeriodName) {
        providers.push(lgProvide(LG_APP_GET_PERIOD_NAME, config.getPeriodName));
    }
    if (config?.authorizationService) {
        providers.push(lgProvide(LG_AUTHORIZATION_SERVICE, config.authorizationService));
    }
    if (config?.definitions) {
        providers.push(lgProvide(LG_APP_DEFINITIONS, config.definitions));
    }
    if (config?.definitionsHierarchyService) {
        providers.push(
            lgProvide(LG_DEFINITIONS_HIERARCHY_SERVICE, config.definitionsHierarchyService)
        );
    }
    if (config?.matomoConfiguration) {
        providers.push(lgProvide(LG_MATOMO_CONFIGURATION, config.matomoConfiguration));
    }
    if (config?.navigationChangeHandler) {
        providers.push(lgProvide(LG_NAVIGATION_CHANGE_HANDLER, config.navigationChangeHandler));
    }
    if (config?.userFlowConfiguration) {
        providers.push(lgProvide(LG_USERFLOW_CONFIGURATION, config.userFlowConfiguration));
    }
    // HTTP_INTERCEPTORS:
    if (config?.hasAcceptLanguageInterceptor) {
        providers.push(lgProvideAcceptLanguageInterceptor(LgAcceptLanguageInterceptor));
    }
    if (config?.hasMessageBusConnectionIdInterceptor) {
        providers.push(
            lgProvideMessageBusConnectionIdInterceptor(LgMessageBusConnectionIdInterceptor)
        );
    }
    if (config?.hasAuthorizationInterceptor) {
        providers.push(lgProvideAuthorizationInterceptor(LgAuthorizationInterceptor));
    }
    return providers;
};

export const lgProvideAcceptLanguageInterceptor = (
    interceptor: Type<LgAcceptLanguageInterceptor>
): Provider => {
    return lgProvideInterceptor(interceptor);
};

export const lgProvideAuthorizationInterceptor = (
    interceptor: Type<LgAuthorizationInterceptor>
): Provider => {
    return lgProvideInterceptor(interceptor);
};

export const lgProvideMessageBusConnectionIdInterceptor = (
    interceptor: Type<LgMessageBusConnectionIdInterceptor>
): Provider => {
    return lgProvideInterceptor(interceptor);
};
