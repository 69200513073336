<div class="lg-sidebar-switch__header">{{ title }}</div>
<div class="lg-sidebar-switch__models">
    <ul>
        <li *ngFor="let product of products$ | async">
            <a
                [href]="_currentProductId !== product.id ? product.url : '#'"
                [class.lg-sidebar-switch__models--active]="_currentProductId === product.id"
                [class.lg-sidebar-switch__models--disabled]="product.disabled"
            >
                <div class="lg-sidebar-switch__models__logo">
                    <lg-icon
                        *ngIf="product.icon != null; else iconClass"
                        [icon]="product.icon"
                        size="medium"
                    >
                    </lg-icon>
                    <ng-template #iconClass>
                        <div
                            [class]="
                                'lg-sidebar-switch__models__logo--custom ' + product.iconClass ?? ''
                            "
                        ></div>
                    </ng-template>
                </div>
                <div class="lg-sidebar-switch__models__label">
                    <div class="lg-sidebar-switch__models__label__name">{{ product.name }}</div>
                    <div
                        *ngIf="product.shortDescription"
                        class="lg-sidebar-switch__models__label__description"
                    >
                        {{ product.shortDescription }}
                    </div>
                </div>
            </a>
        </li>
    </ul>
</div>
