import * as d3 from "d3";

export enum IntervalXLabels {
    Start,
    End,
    No
}

export enum Variant {
    Regular,
    Stacked,
    Stacked100
}

export interface Margin {
    top?: number;
    right?: number;
    bottom?: number;
    left?: number;
}

export type ReferenceLineAnchor = "start" | "middle" | "end";

export interface IReferenceLine {
    value: number;
    top: string;
    bottom: string;
    className: string;
    anchor: ReferenceLineAnchor;
}

export type LegendPosition = "bottom" | "right";

export interface LegendOptions {
    visible: boolean;
    position: LegendPosition;
    className?: string;
    widthInPercents?: number;
    cropLongNames?: boolean;
    staticWidth?: number;
    wrapMultilineDefinition?: boolean;
}

export interface LegendItem {
    color: string;
    name: string;
    opacity?: number;
    symbol?: LgChartIconSymbol;
    symbolBorder?: boolean;
}

export type LgChartIconSymbol =
    | "circle"
    | "square"
    | "triangle"
    | "line"
    | "line-dashed"
    | "line-with-circle";

export type AnyTooltipProps = any;

export type MouseCoordinates = { x: number; y: number };

/**
 * @deprecated
 */
interface ChartValueTypeInternal {
    none: string;
    custom: string;
    historic: string;
    input: string;

    forecast_lighter: string;
    forecast: string;
    forecast_darker: string;

    output_lighter: string;
    output: string;
    output_darker: string;

    budget_lighter: string;
    budget: string;
    budget_darker: string;

    efficiency_lighter: string;
    efficiency: string;
    efficiency_darker: string;

    benchmark_lighter: string;
    benchmark: string;
    benchmark_darker: string;
}

/**
 * @deprecated
 */
export type ChartValueTypeDictionary = { [K in keyof Partial<ChartValueTypeInternal>]: string };

/**
 * @deprecated
 */
export type ChartValueType = keyof ChartValueTypeInternal;

export interface ChartClickEvent<TData, TColumn = any> {
    item: TData;
    datum: TColumn;
    index?: number;
}

export interface PointReference<T> {
    coordinates: [number, number];
    color?: string;
    data: T;
    fillElementWith: (color: string) => d3.Selection<any, any, any, any>;
    resizeElement?: (radius: number) => d3.Selection<any, any, any, any>;
}

export interface LgGroupLabel {
    label: string;
    shortened: string;
}

export const CHART_SEPARATOR_SIZE = 1;
