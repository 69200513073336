import { inject, Injectable } from "@angular/core";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import {
    IAppSession,
    LG_APP_CONFIGURATION,
    LG_APP_SESSION,
    ServerGatewayBase
} from "@logex/framework/lg-application";

import { FilterSetState } from "./lg-filterset.types";
import { mapFiltersetStateToDb } from "./mappers/mapFiltersetStateToDb";
import { mapFiltersetStateFromDb } from "./mappers/mapFiltersetStateFromDb";
import { DbFilterSetStateResponse } from "./lg-filterset-state-gateway.types";

const BASE_API_PATH = "api/filterset/states";
const GET_SUFFIX = "all";
const SAVE_SUFFIX = "save";
const DELETE_SUFFIX = "delete";

@Injectable({ providedIn: "root" })
export class LgFiltersetStateGateway extends ServerGatewayBase {
    private _session: IAppSession = inject(LG_APP_SESSION);

    constructor() {
        super();
        this._setBaseUrl(inject(LG_APP_CONFIGURATION).applicationRoot);
    }

    load(filterHostId: string): Observable<FilterSetState[]> {
        return this._get<DbFilterSetStateResponse>(
            `${BASE_API_PATH}/${GET_SUFFIX}/${filterHostId}`,
            {
                params: {
                    clientId: this._session.clientId,
                    scenarioId: this._session.scenarioId
                }
            }
        ).pipe(map(data => mapFiltersetStateFromDb(data)));
    }

    save(set: FilterSetState): Observable<string> {
        return this._post<{ stateId: string }>(`${BASE_API_PATH}/${SAVE_SUFFIX}`, {
            body: {
                clientId: this._session.clientId,
                scenarioId: this._session.scenarioId,
                ...mapFiltersetStateToDb(set)
            }
        }).pipe(map(data => data.stateId));
    }

    deleteState(stateId: string): Observable<void> {
        return this._post<void>(`${BASE_API_PATH}/${DELETE_SUFFIX}`, {
            body: {
                clientId: this._session.clientId,
                scenarioId: this._session.scenarioId,
                state_id: stateId
            }
        });
    }
}
