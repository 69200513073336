import ldEach from "lodash-es/each";
import { Subject, Observable } from "rxjs";
import { map, filter } from "rxjs/operators";
import { OnDestroy, isDevMode, Injectable, inject, ChangeDetectorRef } from "@angular/core";
import {
    LgPanelState,
    LgEffectivePanelState,
    LgPanelStateExtension,
    LgPanelGridDefinition,
    LgPanelGridLeafDef,
    LgPanelGridRowDef,
    LgPanelGridColumnDef,
    LgPanelGridChangeEvent,
    LgPanelGridChange,
    ILgPanelComponent
} from "./lg-panel.types";
import { LgPanelGridNode, GridNodeType, HeaderRow } from "./lg-panel-grid-node";

interface HeaderGathering {
    above: HeaderRow[];
    below: HeaderRow[];
    target: HeaderRow[];
    maximizedChild: LgPanelGridNode | null;
    simpleTabbedRow: boolean;
}

@Injectable()
export class LgPanelGridService implements OnDestroy {
    private _changeDetectorRef = inject(ChangeDetectorRef);

    _root: LgPanelGridNode | null = null;
    private _state: Record<string, LgPanelState> = {};
    private _leafs: Record<string, LgPanelGridNode> = {};
    private _nodes: Record<string, LgPanelGridNode> = {};
    private _panels: ILgPanelComponent[] = [];
    private _panelsLookup: Record<string, ILgPanelComponent> = {};
    private _pendingAttachment = false;
    private _pendingNormalization = false;
    private _pendingUpdate = false;
    private readonly _changes = new Subject<LgPanelGridChangeEvent>();

    ngOnDestroy(): void {
        this._changes.complete();
    }

    getPanelState(id: string): LgEffectivePanelState {
        const panel = this._panelsLookup[id];
        if (!panel) {
            console.error(`getPanelState: unknown panel '${id}'`);
            return LgPanelState.Default;
        }
        return panel._effectiveState;
    }

    isPanelVisible(id: string): boolean {
        const state = this.getPanelState(id);
        return (
            state !== LgPanelStateExtension.Minimized && state !== LgPanelStateExtension.Detached
        );
    }

    hasFullscreenPanel(): boolean {
        return this._root?.effectiveState === LgPanelState.Fullscreen;
    }

    addPanel(panel: ILgPanelComponent): void {
        if (this._panelsLookup[panel.id] !== undefined)
            throw new Error(`Multiple panels with id '${panel.id}' defined`);
        this._panels.push(panel);
        this._panelsLookup[panel.id] = panel;
        this._state[panel.id] = LgPanelState.Default;
        this._scheduleAttachement();
    }

    removePanel(panel: ILgPanelComponent): void {
        const index = this._panels.indexOf(panel);
        if (index === -1) throw new Error(`Trying to remove panel not registered '${panel.id}'`);
        this._panels.splice(index, 1);
        delete this._state[panel.id];
        delete this._panelsLookup[panel.id];
        this._scheduleAttachement();
    }

    updatePanelLocation(_panel: ILgPanelComponent): void {
        this._scheduleAttachement();
    }

    updatePanelId(panel: ILgPanelComponent, previousId: string): void {
        // todo: delay to support swap?
        const oldState = this._state[previousId];
        delete this._state[previousId];
        this._state[panel.id] = oldState;
        delete this._panelsLookup[previousId];
        this._panelsLookup[panel.id] = panel;
    }

    setGrid(definition: LgPanelGridDefinition | null, gridWidth: number, gridHeight: number): void {
        this._nodes = {};
        this._leafs = {};
        if (definition != null) {
            this._root = this._convertGridDefinition(
                { size: 12, ...definition },
                { row: 0, col: 0 },
                gridWidth,
                gridHeight
            );
            this._root.sizeFraction = 1;
        } else {
            this._root = null;
        }
        this._scheduleAttachement();
    }

    maximizePanel(panel: ILgPanelComponent, fullscreen: boolean): void {
        if (!this._panelsLookup[panel.id]) {
            console.error(`Cannot maximize unknown panel '${panel.id}`);
            return;
        }
        if (panel._effectiveState === LgPanelStateExtension.Detached) {
            console.error(`Cannot maximize detached panel '${panel.id}`);
            return;
        }
        // note: we cannot rely on state normalization, because we must guarantee our panel wins
        if (fullscreen) {
            if (this._state[panel.id] === LgPanelState.Fullscreen) return;
            for (const other of this._panels) {
                if (this._state[other.id] === LgPanelState.Fullscreen) {
                    this._state[other.id] = LgPanelState.Default;
                    break;
                }
            }
            this._state[panel.id] = LgPanelState.Fullscreen;
        } else {
            if (panel._effectiveState === LgPanelState.Expanded) return;
            if (panel._effectiveState === LgPanelStateExtension.Minimized) {
                this.restorePanel(panel);
            }
            this._state[panel.id] = LgPanelState.Expanded;
        }
        // todo: should expansion automatically kill fullscreen mode too?
        this._scheduleLayout();
    }

    exitFullscreen(): void {
        if (this._root?.effectiveMaximizedChild?.attachedPanel) {
            this.restorePanel(this._root.effectiveMaximizedChild.attachedPanel);
        }
    }

    restoreAll(): void {
        for (const panel of this._panels) {
            this._state[panel.id] = LgPanelState.Default;
        }
        this._scheduleLayout();
    }

    restorePanel(panel: ILgPanelComponent): void {
        if (!this._panelsLookup[panel.id]) {
            console.error(`Cannot restore unknown panel '${panel.id}`);
            return;
        }
        if (panel._effectiveState === LgPanelStateExtension.Detached) {
            console.error(`Cannot restore detached panel '${panel.id}`);
            return;
        }
        if (panel._effectiveState === LgPanelState.Default) {
            console.debug(`Panel '${panel.id} is not minimized nor maximized`);
            return;
        }
        if (
            panel._effectiveState === LgPanelState.Fullscreen ||
            panel._effectiveState === LgPanelState.Expanded
        ) {
            this._state[panel.id] = LgPanelState.Default;
        } else {
            // Restore all the panels on the way up to the first visible node
            let maximizingRoot = this._leafs[panel.location!];
            while (
                maximizingRoot.parent &&
                maximizingRoot.effectiveState === LgPanelStateExtension.Minimized
            ) {
                if (maximizingRoot.effectiveMaximizedChild)
                    this._state[maximizingRoot.effectiveMaximizedChild.attachedPanel!.id] =
                        LgPanelState.Default;
                maximizingRoot = maximizingRoot.parent;
            }
            this._state[maximizingRoot.effectiveMaximizedChild!.attachedPanel!.id] =
                LgPanelState.Default;
        }
        this._scheduleLayout();
    }

    stateChanges(): Observable<LgPanelGridChangeEvent> {
        return this._changes.asObservable();
    }

    panelStateChanges(panelId: string): Observable<LgPanelGridChange> {
        return this._changes.pipe(
            map(changes => changes[panelId]),
            filter(state => state !== undefined)
        );
    }

    private _convertGridDefinition(
        definition: LgPanelGridRowDef | LgPanelGridColumnDef | LgPanelGridLeafDef,
        counters: { row: number; col: number },
        width: number,
        height: number
    ): LgPanelGridNode {
        let node: LgPanelGridNode;

        if ("rows" in definition) {
            if (!definition.id) {
                definition = { id: `col${counters.col++}`, ...definition } as LgPanelGridColumnDef;
            }
            node = new LgPanelGridNode(definition, GridNodeType.Column);
            let sumHeight = 0;
            for (const child of definition.rows) {
                const childNode = this._convertGridDefinition(child, counters, width, child.size);
                childNode.parent = node;
                childNode.sizeFraction = child.size / height;
                node.children.push(childNode);
                sumHeight += child.size;
            }
            if (isDevMode() && sumHeight > height) {
                console.warn(
                    `Grid node "${definition.id}" has height ${height}, but child rows sum to ${sumHeight}!`
                );
            }
        } else if ("columns" in definition) {
            if (!definition.id) {
                definition = { id: `row${counters.row++}`, ...definition } as LgPanelGridRowDef;
            }
            node = new LgPanelGridNode(definition, GridNodeType.Row);
            let sumWidth = 0;
            for (const child of definition.columns) {
                const childNode = this._convertGridDefinition(child, counters, child.size, height);
                childNode.parent = node;
                childNode.sizeFraction = child.size / width;
                node.children.push(childNode);
                sumWidth += child.size;
            }
            if (isDevMode() && sumWidth > width) {
                console.warn(
                    `Grid node "${definition.id}" has width ${width}, but child columns sum to ${sumWidth}!`
                );
            }
        } else {
            node = new LgPanelGridNode(definition, GridNodeType.Leaf);
        }

        if (node.isLeaf) {
            this._leafs[node.definition.id!] = node;
        }
        this._nodes[node.definition.id!] = node;

        return node;
    }

    // Update attachment of all panels
    private _updateAttachment(): void {
        ldEach(this._leafs, leaf => (leaf.attachedPanel = null));
        for (const panel of this._panels) {
            const target = this._leafs[panel.location!];
            if (panel.location && !target) {
                console.debug(
                    `Panel '${panel.id}' cannot be attached to unknown location '${panel.location}'`
                );
            }
            if (target && target.attachedPanel !== null) {
                console.debug(
                    `Panel '${panel.id}' cannot be attached to location '${panel.location}', already occupied by panel '${target.attachedPanel.id}'.`
                );
            }
            if (!panel.location || !target || target.attachedPanel) {
                this._state[panel.id] = LgPanelState.Default;
                continue;
            }
            target.attachedPanel = panel;
        }
    }

    // normalize state of the panels (only 1 fullscreen etc)
    private _normalizeState(): void {
        if (!this._root) return;
        this._normalizeFullscreenState();
        this._normalizeExpandedState(this._root);
    }

    private _normalizeFullscreenState(): void {
        // there can be only one fullscreen panel
        let foundFullscreen = false;
        for (const panel of this._panels) {
            if (this._state[panel.id] !== LgPanelState.Fullscreen) {
                if (this._state[panel.id] === undefined)
                    this._state[panel.id] = LgPanelState.Default;
                continue;
            }
            if (!foundFullscreen) {
                foundFullscreen = true;
            } else {
                this._state[panel.id] = LgPanelState.Default;
            }
        }
    }

    private _normalizeExpandedState(container: LgPanelGridNode): void {
        // there can be only only expanded panel per node
        let found = false;
        for (const child of container.children) {
            if (child.isLeaf) {
                // note that we ignore fullscreen states; those can later disappear and the expanded panel regains its place
                if (
                    !child.attachedPanel ||
                    this._state[child.attachedPanel.id] !== LgPanelState.Expanded
                )
                    continue;
                if (!found) {
                    found = true;
                } else {
                    this._state[child.attachedPanel.id] = LgPanelState.Default;
                }
            } else {
                this._normalizeExpandedState(child);
            }
        }
    }

    // update the layout according to the current state
    private _doLayout(): void {
        this._calculateEffectiveState();
        this._updatePanels();
    }

    // calculate effective state of the panels and grid nodes
    private _calculateEffectiveState(): void {
        if (this._root) {
            this._propagateExpansion(this._root);
            this._propagateVisibility(this._root, true);
            this._prepareHeaders(this._root);
        }
    }

    private _propagateExpansion(node: LgPanelGridNode): LgPanelState {
        if (node.isLeaf) {
            if (!node.attachedPanel) return LgPanelState.Default;
            return this._state[node.attachedPanel.id];
        }

        node.maximizedChild = null;
        let foundFullscreen = false;
        for (const child of node.children) {
            const state = this._propagateExpansion(child);
            if (state === LgPanelState.Default) continue;
            // we know the state is consistent, so there can be at most one fullscreen and one expanded child. Fullscreen wins
            if (state === LgPanelState.Fullscreen) {
                node.maximizedChild = child;
                foundFullscreen = true;
            } else if (node.maximizedChild === null) {
                node.maximizedChild = child;
            }
        }

        // note that this is not final, it can be updated in _propagateVisibility
        node.effectiveState = foundFullscreen ? LgPanelState.Fullscreen : LgPanelState.Default;
        return node.effectiveState;
    }

    private _propagateVisibility(node: LgPanelGridNode, visible: boolean): void {
        if (node.isLeaf) {
            if (!visible) {
                node.effectiveState = LgPanelStateExtension.Minimized;
            } else if (!node.attachedPanel) {
                node.effectiveState = LgPanelState.Default;
            } else {
                node.effectiveState = this._state[node.attachedPanel.id];
            }
        } else {
            if (!visible) node.effectiveState = LgPanelStateExtension.Minimized;
            for (const child of node.children) {
                const childVisible =
                    visible && (node.maximizedChild === null || node.maximizedChild === child);
                this._propagateVisibility(child, childVisible);
            }
        }
    }

    private _prepareHeaders(node: LgPanelGridNode): void {
        node.headersAbove = [];
        node.headersBelow = [];
        node.effectiveMaximizedChild = null;
        if (node.maximizedChild === null) {
            node.simpleTabbedRow = false;
            for (const child of node.children) {
                this._prepareHeaders(child);
            }
        } else {
            const gathering: HeaderGathering = {
                above: node.headersAbove,
                below: node.headersBelow,
                maximizedChild: null,
                target: node.headersAbove,
                simpleTabbedRow: false
            };
            this._gatherHeaderRowsAndMaximizedNode(node, gathering);
            node.simpleTabbedRow = gathering.simpleTabbedRow;
            node.effectiveMaximizedChild = gathering.maximizedChild;
        }
    }

    // gather all header rows from minimized nodes
    private _gatherHeaderRowsAndMaximizedNode(
        node: LgPanelGridNode,
        gathering: HeaderGathering
    ): void {
        let ownHeaders: LgPanelGridNode[] | null = null;
        const allLeafs =
            !node.isMinimized &&
            (node.type === GridNodeType.Row || this.hasFullscreenPanel()) &&
            node.hasOnlyLeafs();
        for (const child of node.children) {
            if (child.isLeaf) {
                if (child === node.maximizedChild && !node.isMinimized) {
                    gathering.maximizedChild = child;
                    if (!allLeafs) {
                        gathering.target = gathering.below;
                        continue;
                    }
                }
                if (!child.attachedPanel) continue;
                // own headers are lazily created so that they can be under those higher up
                if (ownHeaders === null) {
                    ownHeaders = [];
                    gathering.target.push({
                        nodes: ownHeaders,
                        columnHeaders: node.type === GridNodeType.Column
                    });
                }
                ownHeaders.push(child);
            } else {
                this._gatherHeaderRowsAndMaximizedNode(child, gathering);
            }
        }
        if (allLeafs) {
            gathering.simpleTabbedRow = true;
            // delayed switch
            gathering.target = gathering.below;
        }
    }

    // update panels (set actual effective state, send events)
    private _updatePanels(): void {
        const events: LgPanelGridChangeEvent = {};
        let sendEvent = false;

        for (const panel of this._panels) {
            const target = this._leafs[panel.location!];
            let previousState: LgEffectivePanelState;
            if (!target || target.attachedPanel !== panel) {
                previousState = panel._setEffectiveState(LgPanelStateExtension.Detached);
            } else {
                previousState = panel._setEffectiveState(target.effectiveState);
            }
            if (previousState !== panel._effectiveState) {
                events[panel.id] = {
                    previousState,
                    currentState: panel._effectiveState,
                    panel
                };
                sendEvent = true;
            }
        }

        if (sendEvent) this._changes.next(events);
    }

    private _scheduleAttachement(): void {
        this._pendingAttachment = true;
        this._scheduleNormalization();
    }

    private _scheduleNormalization(): void {
        this._pendingNormalization = true;
        this._scheduleLayout();
    }

    private _scheduleLayout(): void {
        if (this._pendingUpdate) return;

        this._pendingUpdate = true;
        Promise.resolve().then(() => {
            const attachment = this._pendingAttachment;
            const normalization = this._pendingNormalization;
            // if any of the event handlers retriggers update, we'll do another loop
            this._pendingAttachment = false;
            this._pendingNormalization = false;
            this._pendingUpdate = false;
            if (attachment) this._updateAttachment();
            if (normalization) this._normalizeState();
            this._doLayout();
            this._changeDetectorRef.markForCheck();
        });
    }
}
