export function memoizeByKey<TRes>(
    cache: Record<string, TRes>,
    key: any,
    getValue: () => TRes
): TRes {
    let res = cache[key];

    if (res == null) {
        res = getValue();
        cache[key] = res;
    }

    return res;
}
