<ng-container *ngIf="buttonVisible">
    <lg-button
        *ngFor="let panel of _availablePanels"
        [isDisabled]="_disabled"
        [icon]="panel.icon || ''"
        [textLc]="panel.nameLc"
        (click)="_togglePanel(panel)"
        style="margin-left: 10px"
    >
    </lg-button>
</ng-container>
