import {
    Component,
    ViewEncapsulation,
    ChangeDetectionStrategy,
    Input,
    Output,
    HostListener,
    EventEmitter
} from "@angular/core";
import { toBoolean } from "@logex/framework/utilities";

export enum LG_TRISTATE_SLIDER_STATE {
    CONTAINS = "contains",
    EXCLUDES = "excludes",
    UNSET = "unset"
}

@Component({
    standalone: true,
    selector: "lg-tristate-slider",
    template: `
        <div class="lg-tristate-slider__slide"></div>
        <div class="lg-tristate-slider__handle"></div>
    `,
    host: {
        class: "lg-tristate-slider",
        "[class.lg-tristate-slider--contains]": "state === TRISTATE_SLIDER_STATE.CONTAINS",
        "[class.lg-tristate-slider--excludes]": "state === TRISTATE_SLIDER_STATE.EXCLUDES",
        "[class.lg-tristate-slider--unset]": "state === TRISTATE_SLIDER_STATE.UNSET",
        "[class.lg-tristate-slider--disabled]": "disabled"
    },
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LgTristateSliderComponent {
    TRISTATE_SLIDER_STATE = LG_TRISTATE_SLIDER_STATE;

    /**
     * Slider state: contains, excludes, unset.
     *
     * @default "unset"
     */
    @Input() public set state(value: LG_TRISTATE_SLIDER_STATE) {
        if (value) this._state = value;
        else this._state = LG_TRISTATE_SLIDER_STATE.UNSET;
    }

    public get state(): LG_TRISTATE_SLIDER_STATE {
        return this._state;
    }

    @Input() public set disabled(value: boolean | "true" | "false") {
        this._disabled = toBoolean(value);
    }

    public get disabled(): boolean {
        return this._disabled;
    }

    /**
     * Emits state value on changes
     */
    @Output() public readonly stateChange = new EventEmitter<LG_TRISTATE_SLIDER_STATE>();

    @HostListener("click", ["$event"])
    _clicked($event: MouseEvent): void {
        $event.stopPropagation();
        $event.preventDefault();
        this.setState();
    }

    private _state: LG_TRISTATE_SLIDER_STATE = LG_TRISTATE_SLIDER_STATE.UNSET;
    private _disabled = false;

    setState(): void {
        if (this._disabled) return;
        this._state = this._getNextState();
        this.stateChange.next(this._state);
    }

    private _getNextState(): LG_TRISTATE_SLIDER_STATE {
        if (this._state === LG_TRISTATE_SLIDER_STATE.UNSET) {
            return LG_TRISTATE_SLIDER_STATE.CONTAINS;
        }
        if (this._state === LG_TRISTATE_SLIDER_STATE.CONTAINS) {
            return LG_TRISTATE_SLIDER_STATE.EXCLUDES;
        }
        return LG_TRISTATE_SLIDER_STATE.UNSET;
    }
}
