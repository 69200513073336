<h1 class="lg-sidebar-panel-title">{{ _userInfo.name }}</h1>
<div class="flex-stretch flexcol lg-sidebar-panel-scrollable lg-sidebar-panel-scrollable--full">
    <div
        lgScrollable
        [lgScrollableWrapper]="false"
        lgScrollbarClass="lg-scrollbar lg-scrollbar--sidebar"
        class="flex flexcol flex-stretch"
    >
        <div class="lg-sidebar-section">
            @if (impersonationMode) {
                <div class="lg-sidebar-account__impersonatee">
                    <lg-icon icon="icon-impersonation" [inline]="true" />
                    {{ ".Impersonatee" | lgTranslate: { login: _userInfo.impersonator } }}
                </div>
            }
            <div class="lg-sidebar-account__details">
                <span>{{ _userInfo.ziekenhuisnaam }}</span>
                <span>{{ _userInfo.login ?? _userInfo.email }}</span>
                <span>{{ _userInfo.title }}</span>
            </div>
        </div>
        <ul class="lg-sidebar-list">
            <h3 class="lg-sidebar-section__title">{{ ".Links" | lgTranslate }}</h3>
            @if (_myAccountUrl) {
                <li class="lg-sidebar-list__item">
                    <a class="lg-sidebar-nav-link" [href]="_myAccountUrl" target="_blank">
                        <lg-icon icon="icon-user" [inline]="true" />
                        {{ ".MyAccount" | lgTranslate }}
                    </a>
                </li>
            }
            @if (_appControlService.canSwitchContext | async) {
                <li class="lg-sidebar-list__item">
                    <button class="lg-sidebar-nav-link" (click)="_switchHospital()">
                        <lg-icon icon="icon-building" [inline]="true" />
                        {{ ".SwitchOrganisation" | lgTranslate }}
                    </button>
                </li>
            } @else {
                <lg-sidebar-merged-hospital-switch />
            }
            @if (onlineLink) {
                <li class="lg-sidebar-list__item">
                    <a class="lg-sidebar-nav-link" [href]="onlineLink" target="_blank">
                        <lg-icon icon="icon-grid" [inline]="true" />
                        {{ ".LogexOnline" | lgTranslate }}
                    </a>
                </li>
            }
            @for (link of customLinks; track link.name) {
                <li class="lg-sidebar-list__item">
                    <a class="lg-sidebar-nav-link" [href]="link" target="_blank">
                        <lg-icon [icon]="link.icon ?? 'icon-link'" [inline]="true" />
                        {{ link.name | lgTranslate }}
                    </a>
                </li>
            }
            <li class="lg-sidebar-list__item">
                <button class="lg-sidebar-nav-link" (click)="_logout()">
                    <lg-icon icon="icon-log-out" [inline]="true" />
                    {{ "FW.LOGOUT" | lgTranslate }}
                </button>
            </li>
        </ul>
        @if (_isUserInternal) {
            <div class="lg-sidebar-section lg-sidebar-account__userId">
                {{ ".UserId" | lgTranslate }}: {{ _userInfo.userid ?? _userInfo.id }}
            </div>
        }
    </div>
</div>
