<div
    class="input-wrap"
    [class.input-wrap--has-subinputs]="hasSubinputs || (hasSubinputs === undefined && overriden)"
>
    <span
        class="input-like input-like--small input-like--wide"
        [ngClass]="{
            'table__row__no-hover-focus-only': !noEdit,

            'input-like--missing': missing && !noEdit,
            'input-like--not-specified': !specified,
            'input-like--readonly': noEdit && specified
        }"
        *ngIf="
            ((!specified || hasSubinputs || overriden || overridenSilent) &&
                !_hasError &&
                !invalid) ||
            noEdit
        "
        [hidden]="hoverOnly"
    >
        {{
            effective
                | fmtType
                    : _formatter
                    : {
                          decimals: decimals,
                          forceSign: forceSign,
                          ceiling: effectiveCeiling,
                          viewScale: asFactor ? 2 : viewScale,
                          hideScaleSymbol: true
                      }
        }}
    </span>

    <ng-container *ngIf="!noEdit">
        <input
            type="text"
            #input="ngModel"
            class="input input--wide {{ _align }}"
            [class.input--small]="isInputFieldSmall"
            [class.input--invalid]="invalid"
            [class.input--not-specified]="specified"
            [class.table__row__hover-focus-only]="
                (!specified || hasSubinputs || overriden || overridenSilent) &&
                !_hasError &&
                !invalid
            "
            [ngModel]="model"
            (ngModelChange)="_handleModelChange($event, input)"
            [ngModelOptions]="{ updateOn: modelUpdateOn }"
            [lgDefaultFocus]="defaultFocus"
            [lgFormatter]="_formatter"
            [lgFormatterOptions]="{
                decimals: decimals,
                forceSign: forceSign,
                viewScale: asFactor ? 2 : viewScale,
                hideScaleSymbol: true,
                forceFormat: true
            }"
            [min]="min"
            [max]="max"
            placeholder="{{
                placeholder === true
                    ? (effective
                      | fmtType
                          : _formatter
                          : {
                                decimals: decimals,
                                forceSign: forceSign,
                                ceiling: effectiveCeiling,
                                viewScale: asFactor ? 2 : viewScale,
                                hideScaleSymbol: true
                            })
                    : placeholder
            }}"
            [required]="missing"
            [lgMarkFocusOn]="markFocusOn"
            (blur)="_onBlur($event)"
            (focus)="_onFocus($event)"
            (focusin)="_onFocusIn($event)"
            (focusout)="_onFocusOut($event)"
            (lgOnValidityChange)="_hasError = !$event"
            lgStopPropagation="click"
            lgSelectOnFocus
            lgUndoOnEsc
            lgTableInputNavigator
            [autocomplete]="_autoComplete"
        />
    </ng-container>
</div>
