import { TemplatePortal } from "@angular/cdk/portal";
import { TemplateRef } from "@angular/core";
import { ITooltipOptions } from "../../lg-tooltip/lg-tooltip.types";

export interface IHelpInfoLinks {
    manual: string;
    webinar: string;
    faq: string;
    helpcenter: string;
}

export enum HelpInfoLinksKeys {
    Manual = 0,
    Webinar = 1,
    Faq = 2,
    Helpcenter = 3
}

export interface IHelpInfoRecord {
    order: number;
    icon: string;
    text: string;
    url: string;
}

export interface IHelpInfoPopupParams {
    header?: string | undefined;
    description?: string | TemplateRef<unknown> | TemplatePortal<unknown> | undefined;
    links?: Partial<IHelpInfoLinks> | undefined;
}

export type HelpInfoDelayOptions = Pick<ITooltipOptions, "delayShow" | "delayHide">;
