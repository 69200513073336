import ldIsFunction from "lodash-es/isFunction";

import { Injectable, ViewContainerRef, inject } from "@angular/core";
import { ComponentType } from "@angular/cdk/portal";
import { take } from "rxjs/operators";

import { IDialogOptions, IDialogComponent } from "./lg-dialog.types";
import { LgDialogService } from "./lg-dialog.service";
import { LG_APPLICATION_EVENT_TRACER } from "@logex/framework/core";

export interface ILgDialogFactoryExtensions<T> {
    /**
     * Must be called in constructor context, otherwise ViewContainerRef must be passed as a parameter.
     */
    bindViewContainerRef(viewRef?: ViewContainerRef): T & ILgDialogFactoryExtensions<T>;
}

type Constructor<T> = new (...args: any[]) => T;
type DialogFactoryConstructor<T, M extends keyof T> = new (
    factory: LgDialogFactory
) => Pick<T, M> & ILgDialogFactoryExtensions<Pick<T, M>>;

// ---------------------------------------------------------------------------------------------
//  Factorizer
// ---------------------------------------------------------------------------------------------
export function getDialogFactoryBase<T extends Record<M1, Function>, M1 extends keyof T>(
    src: Constructor<T>,
    method1: M1
): DialogFactoryConstructor<T, M1>;

export function getDialogFactoryBase<
    T extends Record<M1 | M2, Function>,
    M1 extends keyof T,
    M2 extends keyof T
>(src: Constructor<T>, method1: M1, method2: M2): DialogFactoryConstructor<T, M1 | M2>;

export function getDialogFactoryBase<
    T extends Record<M1 | M2 | M3, Function>,
    M1 extends keyof T,
    M2 extends keyof T,
    M3 extends keyof T
>(
    src: Constructor<T>,
    method1: M1,
    method2: M2,
    method3: M3
): DialogFactoryConstructor<T, M1 | M2 | M3>;

export function getDialogFactoryBase<
    T extends Record<M1 | M2 | M3 | M4, Function>,
    M1 extends keyof T,
    M2 extends keyof T,
    M3 extends keyof T,
    M4 extends keyof T
>(
    src: Constructor<T>,
    method1: M1,
    method2: M2,
    method3: M3,
    method4: M4
): DialogFactoryConstructor<T, M1 | M2 | M3 | M4>;

export function getDialogFactoryBase<
    T extends Record<M1 | M2 | M3 | M4 | M5, Function>,
    M1 extends keyof T,
    M2 extends keyof T,
    M3 extends keyof T,
    M4 extends keyof T,
    M5 extends keyof T
>(
    src: Constructor<T>,
    method1: M1,
    method2: M2,
    method3: M3,
    method4: M4,
    method5: M5
): DialogFactoryConstructor<T, M1 | M2 | M3 | M4 | M5>;

export function getDialogFactoryBase<
    T extends Record<M1 | M2 | M3 | M4 | M5 | M6, Function>,
    M1 extends keyof T,
    M2 extends keyof T,
    M3 extends keyof T,
    M4 extends keyof T,
    M5 extends keyof T,
    M6 extends keyof T
>(
    src: Constructor<T>,
    method1: M1,
    method2: M2,
    method3: M3,
    method4: M4,
    method5: M5,
    method6: M6
): DialogFactoryConstructor<T, M1 | M2 | M3 | M4 | M5 | M6>;

export function getDialogFactoryBase<
    T extends Record<M1 | M2 | M3 | M4 | M5 | M6 | M7, Function>,
    M1 extends keyof T,
    M2 extends keyof T,
    M3 extends keyof T,
    M4 extends keyof T,
    M5 extends keyof T,
    M6 extends keyof T,
    M7 extends keyof T
>(
    src: Constructor<T>,
    method1: M1,
    method2: M2,
    method3: M3,
    method4: M4,
    method5: M5,
    method6: M6,
    method7: M7
): DialogFactoryConstructor<T, M1 | M2 | M3 | M4 | M5 | M6 | M7>;

export function getDialogFactoryBase<
    T extends Record<M1 | M2 | M3 | M4 | M5 | M6 | M7 | M8, Function>,
    M1 extends keyof T,
    M2 extends keyof T,
    M3 extends keyof T,
    M4 extends keyof T,
    M5 extends keyof T,
    M6 extends keyof T,
    M7 extends keyof T,
    M8 extends keyof T
>(
    src: Constructor<T>,
    method1: M1,
    method2: M2,
    method3: M3,
    method4: M4,
    method5: M5,
    method6: M6,
    method7: M7,
    method8: M8
): DialogFactoryConstructor<T, M1 | M2 | M3 | M4 | M5 | M6 | M7 | M8>;

export function getDialogFactoryBase<
    T extends Record<M1 | M2 | M3 | M4 | M5 | M6 | M7 | M8 | M9, Function>,
    M1 extends keyof T,
    M2 extends keyof T,
    M3 extends keyof T,
    M4 extends keyof T,
    M5 extends keyof T,
    M6 extends keyof T,
    M7 extends keyof T,
    M8 extends keyof T,
    M9 extends keyof T
>(
    src: Constructor<T>,
    method1: M1,
    method2: M2,
    method3: M3,
    method4: M4,
    method5: M5,
    method6: M6,
    method7: M7,
    method8: M8,
    method9: M9
): DialogFactoryConstructor<T, M1 | M2 | M3 | M4 | M5 | M6 | M7 | M8 | M9>;

export function getDialogFactoryBase<
    T extends Record<M1 | M2 | M3 | M4 | M5 | M6 | M7 | M8 | M9 | M10, Function>,
    M1 extends keyof T,
    M2 extends keyof T,
    M3 extends keyof T,
    M4 extends keyof T,
    M5 extends keyof T,
    M6 extends keyof T,
    M7 extends keyof T,
    M8 extends keyof T,
    M9 extends keyof T,
    M10 extends keyof T
>(
    src: Constructor<T>,
    method1: M1,
    method2: M2,
    method3: M3,
    method4: M4,
    method5: M5,
    method6: M6,
    method7: M7,
    method8: M8,
    method9: M9,
    method10: M10
): DialogFactoryConstructor<T, M1 | M2 | M3 | M4 | M5 | M6 | M7 | M8 | M9 | M10>;

export function getDialogFactoryBase(
    dialogComponent: new (...args: any[]) => any,
    ...exposedMethodNames: string[]
): any {
    const create = function (this: any): void {
        this._factory = inject(LgDialogFactory);
        this._factoryViewRef = undefined;
    };

    for (const methodName of exposedMethodNames) {
        create.prototype[methodName] = function (...parameters: any[]) {
            const factory: LgDialogFactory = this._factory;
            return factory.create(this._factoryViewRef, dialogComponent, methodName, parameters);
        };
    }

    create.prototype.bindViewContainerRef = function (viewContainerRef = inject(ViewContainerRef)) {
        const child = new (create as any)();
        child._factoryViewRef = viewContainerRef;
        return child;
    };

    return create;
}

export const getValue = <T extends IDialogComponent<any, any>>(
    dialogComponentInstance: T,
    member: keyof T,
    defaultReturnValue: any
): any => {
    const prop = dialogComponentInstance[member];
    return prop === undefined
        ? defaultReturnValue
        : ldIsFunction(prop)
          ? prop.apply(dialogComponentInstance)
          : prop;
};

// ---------------------------------------------------------------------------------------------
//  LgDialogFactory implementation
// ---------------------------------------------------------------------------------------------
@Injectable()
export class LgDialogFactory {
    private _dialogService = inject(LgDialogService);
    private _tracerService = inject(LG_APPLICATION_EVENT_TRACER);

    create(
        viewContainerRef: ViewContainerRef | undefined,
        component: ComponentType<IDialogComponent<any>>,
        methodName: string,
        currentDialogOptions: any[]
    ): any {
        const options: IDialogOptions = {
            data: currentDialogOptions,
            viewContainerRef
        };

        let result: any;

        this._dialogService.show(component, options, (_showOptions, dialogInstance, doFinish) => {
            // eslint-disable-next-line prefer-spread
            const dialogComponentInstance = dialogInstance!;
            result =
                methodName &&
                (dialogComponentInstance as any)[methodName].apply(
                    dialogComponentInstance,
                    currentDialogOptions
                );

            const runConfiguration = (): void => {
                let configuration: IDialogOptions = {
                    title: getValue(dialogComponentInstance, "_title", "Dialog"),
                    icon: getValue(dialogComponentInstance, "_icon", ""),
                    helpUrl: getValue(dialogComponentInstance, "_helpUrl", null),
                    allowClose: getValue(dialogComponentInstance, "_allowClose", true),
                    allowMaximize: getValue(dialogComponentInstance, "_allowMaximize", false),
                    relatedTo: getValue(dialogComponentInstance, "_relatedTo", null),
                    minHeight: getValue(dialogComponentInstance, "_minHeight", null),
                    type: getValue(
                        dialogComponentInstance,
                        "_dialogType",
                        // backwards compatibility due to old typo
                        getValue(dialogComponentInstance, "dialogType", null)
                    ),
                    dialogClass: getValue(dialogComponentInstance, "_dialogClass", null),
                    dialogBodyClass: getValue(dialogComponentInstance, "_dialogBodyClass", null),
                    closeOnEsc: getValue(dialogComponentInstance, "_closeOnEsc", true),
                    closeOnOverlayClick: getValue(
                        dialogComponentInstance,
                        "_closeOnOverlayClick",
                        false
                    ),
                    ready: getValue(dialogComponentInstance, "_ready", null),
                    dialogHeaderTemplate: getValue(
                        dialogComponentInstance,
                        "_dialogHeaderTemplate",
                        null
                    ),
                    forceCloseOnNavigation: getValue(
                        dialogComponentInstance,
                        "_forceCloseOnNavigation",
                        true
                    ),
                    dialogButtons: getValue(dialogComponentInstance, "_dialogButtons", undefined),
                    scrollerType: getValue(dialogComponentInstance, "_scrollerType", "vertical")
                };

                if (dialogComponentInstance._onClose)
                    configuration.onClose =
                        dialogComponentInstance._onClose.bind(dialogComponentInstance);
                if (dialogComponentInstance._tryClose)
                    configuration.tryClose =
                        dialogComponentInstance._tryClose.bind(dialogComponentInstance);

                if (dialogComponentInstance._configure != null) {
                    configuration =
                        // eslint-disable-next-line prefer-spread
                        dialogComponentInstance._configure.apply(dialogComponentInstance, [
                            configuration
                        ]) || configuration;
                }

                doFinish(configuration);

                if (dialogComponentInstance._activate) {
                    dialogComponentInstance._activate.apply(dialogComponentInstance);
                }
            };

            if (dialogComponentInstance._initializationDone) {
                dialogComponentInstance._initializationDone
                    .pipe(take(1))
                    .subscribe(runConfiguration);
            } else {
                runConfiguration();
            }
        });

        this._tracerService.trackEvent("Dialogs", "Opened", component.name);

        return result;
    }
}
