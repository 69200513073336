<div class="lg-new-password__container">
    <div class="form-row form-row--label-above">
        <label class="form-row__label" for="newPassword" lgTranslate=".NewPassword"></label>
        <div class="input-with-right-icon">
            <input
                [type]="_passwordVisible ? 'text' : 'password'"
                [disabled]="disabled"
                name="newPassword"
                id="newPassword"
                class="input input--wide"
                [class.ng-invalid]="_passwordInvalid"
                [(ngModel)]="value"
            />
            <lg-icon
                [icon]="_passwordVisible ? 'icon-eye-crossed' : 'icon-eye'"
                [clickable]="true"
                (click)="_passwordVisible = !_passwordVisible"
            ></lg-icon>
        </div>
    </div>
    <div class="form-row form-row--label-above" *ngIf="this._config.confirmMatch.active">
        <label class="form-row__label" for="passwordConfirm" lgTranslate=".PasswordConfirm"></label>
        <div class="input-with-right-icon">
            <input
                [type]="_passwordConfirmVisible ? 'text' : 'password'"
                [disabled]="disabled"
                name="passwordConfirm"
                id="passwordConfirm"
                class="input input--wide"
                [class.ng-invalid]="!_config.confirmMatch.passed"
                [(ngModel)]="_passwordConfirm"
                (ngModelChange)="_validatorChange()"
            />
            <lg-icon
                [icon]="_passwordConfirmVisible ? 'icon-eye-crossed' : 'icon-eye'"
                [clickable]="true"
                (click)="_passwordConfirmVisible = !_passwordConfirmVisible"
            ></lg-icon>
        </div>
        <div
            *ngIf="!_config.confirmMatch.passed"
            class="lg-new-password__confirm-not-match"
            lgTranslate=".CheckCodes.ConfirmMatch"
        ></div>
    </div>
</div>
<div class="lg-new-password__container">
    <div class="form-row form-row--label-above">
        <div class="input-label" lgTranslate=".Requirements"></div>
        <lg-new-password-requirement
            [requirement]="_config.minLength"
        ></lg-new-password-requirement>
        <lg-new-password-requirement
            [requirement]="_config.xOfFollowing"
        ></lg-new-password-requirement>
        <lg-new-password-requirement [requirement]="_config.lower"></lg-new-password-requirement>
        <lg-new-password-requirement [requirement]="_config.upper"></lg-new-password-requirement>
        <lg-new-password-requirement [requirement]="_config.number"></lg-new-password-requirement>
        <lg-new-password-requirement [requirement]="_config.special"></lg-new-password-requirement>
    </div>
</div>
