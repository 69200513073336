import { NgZone } from "@angular/core";
import { Subscription } from "rxjs";

export type ResizeWatcherCallback = (
    width: number,
    height: number,
    oldWidth: number,
    oldHeight: number
) => void;

/**
 * This class serves for notification about element size change. It is based on the busy waiting used in height-fit-parent,
 * however it doesn't spawn any iframes and thus should be more light-weight to create.
 * Use it for small short-term observations.
 *
 * Note that the observation code is tied to the digest cycle, so it won't be triggered by external library not using scope.$apply()
 *
 * @deprecated Try to use the browser Resize observer instead
 */
export class ResizeWatcher {
    private zone: NgZone;
    private element: HTMLElement | null;
    private callback: ResizeWatcherCallback | null;

    private timeout: number | null | undefined;
    private enqueueCount: number;
    private oldWidth: number | null | undefined;
    private oldHeight: number | null | undefined;

    private unsubscribe: Subscription | null;

    // ----------------------------------------------------------------------------------
    // Start observing the size of the specified element. Note that scope is required
    // for the actual observation mechanism.
    // ----------------------------------------------------------------------------------
    public constructor(
        zone: NgZone,
        element: HTMLElement,
        callback: ResizeWatcherCallback,
        firstCall?: boolean
    ) {
        this.zone = zone;
        this.element = element;
        this.callback = callback;

        this.enqueueCount = 0;
        if (!firstCall) {
            this.oldWidth = element.offsetWidth;
            this.oldHeight = element.offsetHeight;
        } else {
            this._evaluateChange();
        }

        this.unsubscribe = this.zone.onStable.subscribe(() => {
            this._evaluateChange();

            if (!this.timeout) {
                this.zone.runOutsideAngular(() => {
                    this.timeout = window.setTimeout(() => {
                        this.timeout = null;
                        this._evaluateChange();
                    }, 0);
                });
            }
        });
    }

    // ----------------------------------------------------------------------------------
    // Immediatelyl stop the observation
    // ----------------------------------------------------------------------------------
    public stop(): void {
        if (!this.element) return;

        if (this.unsubscribe !== null) this.unsubscribe.unsubscribe();
        this.unsubscribe = null;

        if (this.timeout) {
            clearTimeout(this.timeout);
            this.timeout = null;
        }

        this.element = null;
        this.callback = null;
    }

    // ----------------------------------------------------------------------------------
    private _evaluateChange(): void {
        if (!this.element) return;

        const width = this.element.offsetWidth;
        const height = this.element.offsetHeight;

        if (this.oldWidth !== width || this.oldHeight !== height) {
            const previousWidth = this.oldWidth;
            const previousHeight = this.oldHeight;
            this.oldWidth = width;
            this.oldHeight = height;
            this.zone.runTask(() => {
                this._callCallback(width, height, previousWidth, previousHeight);
            });
        }
    }

    // ----------------------------------------------------------------------------------
    private _callCallback(
        width: number,
        height: number,
        oldWidth: number | null | undefined,
        oldHeight: number | null | undefined
    ): void {
        try {
            if (this.callback && oldWidth && oldHeight)
                this.callback(width, height, oldWidth, oldHeight);
        } catch (e) {
            console.error(e);
        }
    }
}
