import { parseNumber, safelyShiftScale, toFixedFix } from "@logex/framework/utilities";

import { IParseResult } from "../formatter-types";

export function parse(val: string, decimals?: number, viewScale?: number): IParseResult<number> {
    if (val == null || val === "" || val === "−") {
        return getValidResult(null);
    }

    val = parseNumber(val).toString();
    if (val != null) {
        let result = parseFloat(val);

        if (isNaN(result)) {
            return getInvalidResult(val);
        }

        if (viewScale) {
            if (decimals === undefined) {
                decimals = 0;
            }
            result = safelyShiftScale(result, -viewScale);
            decimals += viewScale;
        }

        result = decimals ? toFixedFix(result, decimals) : Math.round(result);

        return getValidResult(result);
    } else {
        return getInvalidResult(null);
    }
}

function getValidResult(result: number | null): IParseResult<number> {
    return { isValid: true, result };
}

function getInvalidResult(result: string | null): IParseResult<number> {
    return { isValid: false, result };
}
