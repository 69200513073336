import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject } from "@angular/core";

@Component({
    selector: "lg-excel-export-dialog",
    template: `
        <div class="lg-excel-export-dialog__status-row">
            {{ _state }}
        </div>
        <div
            class="lg-excel-export-dialog__progress-row"
            [style.marginTop.px]="16"
            [style.marginBottom.px]="24"
        >
            <lg-progress-bar [progress]="_progress"></lg-progress-bar>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: { class: "lg-excel-export-dialog" }
})
export class LgExcelExportDialogComponent {
    private _changeDetector = inject(ChangeDetectorRef);

    set state(value: string) {
        this._state = value;
        this._changeDetector.markForCheck();
    }

    get state(): string {
        return this._state;
    }

    set progress(value: number) {
        this._progress = value;
        this._changeDetector.markForCheck();
    }

    get progress(): number {
        return this._progress;
    }

    _state = "Berekenen";
    _progress = 0;
}
