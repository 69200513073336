import { ChangeDetectionStrategy, Component, forwardRef, ViewEncapsulation } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { LgDropdownComponent } from "./lg-dropdown.component";

@Component({
    selector: "lg-grid-dropdown",
    templateUrl: "./lg-grid-dropdown.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => LgDropdownComponent),
            multi: true
        }
    ]
})
export class LgGridDropdownComponent<T extends number | string> extends LgDropdownComponent<T> {
    _alwaysShowFrame(): boolean {
        return !this.isValid || (!this._assigned && !this.emptyAcceptable) || this._active;
    }
}
