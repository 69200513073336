import { TemplateRef, EventEmitter, OnInit, OnDestroy, Directive, inject } from "@angular/core";

import { toBoolean, toInteger, toNumber } from "@logex/framework/utilities";

import { LgSidebarService } from "./lg-sidebar.service";
import { SidebarWidget } from "./sidebar-widget-base";

export interface SidebarButton extends SidebarWidget {
    nameLc: string;
    nameAlwaysVisible: boolean;

    /**
     * Pick this or the `imageClass`
     */
    icon: string;

    /**
     * Pick this or the `icon`
     * A CSS class that can either be:
     * - a reference to a class defined inside the FW (tool-logos.scss)
     * - custom CSS in app code
     * For the FW, the format for tool logos is (everything lowercase):
     * `.logo-<toolname>__bg--<bright|dark|transparent>`
     * For the custom app SVG, CSS could look like:
     * .my-custom-icon {
     *     background: transparent url("path/to/my.svg") no-repeat top left;
     * }
     */
    imageClass: string;
    large: boolean;
    disabled: boolean;
    badge: number;

    url: string | undefined;
    panelTemplate: TemplateRef<any> | undefined;
    /**
     * Specifies the panel title and its visibility.
     * If string value, the title will be the string.
     * If `true` or undefined, the title will be the same as the `nameLc`.
     * If `false`, the title will be hidden.
     */
    titleLc?: string | boolean;
    clicked(showPanel: boolean | undefined): void;
}

@Directive()
export class SidebarButtonBase implements OnInit, OnDestroy, SidebarButton {
    protected _panelTemplateRef = inject(TemplateRef<any>, { optional: true });

    protected _service = inject(LgSidebarService);

    widgetTemplate: TemplateRef<any> | null = null;

    onClick = new EventEmitter<SidebarButton>();

    set nameLc(value: string) {
        this._nameLc = value;
    }

    get nameLc(): string {
        return this._nameLc;
    }

    set nameAlwaysVisible(value: boolean) {
        this._nameAlwaysVisible = toBoolean(value);
    }

    get nameAlwaysVisible(): boolean {
        return this._nameAlwaysVisible;
    }

    set icon(value: string) {
        this._icon = value;
    }

    get icon(): string {
        return this._icon;
    }

    set imageClass(value: string) {
        this._imageClass = value;
    }

    get imageClass(): string {
        return this._imageClass;
    }

    get buttonClass(): string | null {
        return this._buttonClass;
    }

    set buttonClass(value: string | null) {
        this._buttonClass = value;
    }

    set large(value: boolean) {
        this._large = value;
    }

    get large(): boolean {
        return this._large;
    }

    set disabled(value: boolean) {
        this._disabled = value;
    }

    get disabled(): boolean {
        return this._disabled;
    }

    get panelTemplate(): TemplateRef<any> {
        return this._panelTemplateRef!;
    }

    set titleLc(value: string | boolean) {
        this._titleLc = value;
    }

    get titleLc(): string | false {
        if (typeof this._titleLc === "string") {
            return this._titleLc === "" ? false : this._titleLc;
        } else {
            return this._titleLc ? this._nameLc : false;
        }
    }

    set onTop(value: boolean) {
        const oldValue = this._onTop;
        this._onTop = toBoolean(value);
        if (oldValue !== this._onTop) {
            this._service.swapWidgetPosition(this);
        }
    }

    get onTop(): boolean {
        return this._onTop;
    }

    set isVisible(value: boolean) {
        const oldValue = this._isVisible;
        this._isVisible = toBoolean(value);
        if (oldValue !== this._isVisible) {
            this._service.handleWidgetVisibility(this);
        }
    }

    get isVisible(): boolean {
        return this._isVisible;
    }

    set order(value: number) {
        const oldValue = this._order;
        this._order = toNumber(value);
        if (oldValue !== this._order) {
            this._service.reorderWidget(this);
        }
    }

    get order(): number {
        return this._order;
    }

    set id(value: string) {
        this._id = value;
    }

    get id(): string {
        return this._id ?? "";
    }

    set url(value: string) {
        this._url = value;
    }

    get url(): string | undefined {
        return this._url;
    }

    set badge(value: number) {
        this._badge = toInteger(value, 0, 0, null);
    }

    get badge(): number {
        return this._badge;
    }

    clicked(_showPanel: boolean | undefined): void {
        this.onClick.next(this);
    }

    protected _nameLc = "";
    protected _nameAlwaysVisible = false;
    protected _icon = "";
    protected _imageClass = "";
    protected _large = false;
    protected _onTop = true;
    protected _isVisible = true;
    protected _order = 0;
    protected _id: string | undefined = undefined;
    protected _url: string | undefined = undefined;
    protected _badge = 0;
    protected _disabled = false;
    protected _initialized = false;
    protected _buttonClass: string | null = null;
    protected _titleLc: string | boolean = true;

    ngOnInit(): void {
        if (this._icon && this._imageClass) {
            throw new Error("Use either `icon` or `imageClass`");
        }

        this._initialized = true;
        this._service.addWidget(this);
    }

    ngOnDestroy(): void {
        this._service.removeWidget(this);
    }
}
