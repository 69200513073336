import ldSize from "lodash-es/size";
import ldIsArray from "lodash-es/isArray";
import ldIsEqual from "lodash-es/isEqual";
import ldEach from "lodash-es/each";
import ldClone from "lodash-es/clone";
import ldSortBy from "lodash-es/sortBy";

import { Injectable, Component, OnInit, inject, ChangeDetectionStrategy } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";

import {
    IFilterRenderer,
    IFilterRendererFactory,
    IFilterDefinition,
    LgFilterSet,
    FilterRendererComponentBase
} from "@logex/framework/lg-filterset";
import { LgTranslateService, useTranslationNamespace } from "@logex/framework/lg-localization";
import { LogexPivotService } from "@logex/framework/lg-pivot";
import { IFilterExportDefinition } from "@logex/framework/lg-exports";
import { isIterable } from "@logex/framework/utilities";
import { IFilterOption } from "@logex/framework/types";
import {
    IQuickSettingsMenuItem,
    QuickSettingsMenuType,
    IQuickSettingsMenuGroup
} from "@logex/framework/ui-core";

import {
    IItemClusterAdapter,
    IItemClusterFilterDefinition,
    IItemCluster
} from "./lg-itemcluster-filter.types";
import { LgItemClusterDialog } from "./lg-itemcluster-dialog.component";
import { ComponentType } from "@angular/cdk/portal";

export class ItemClusterFilterRenderer<T> implements IFilterRenderer {
    _adapter: IItemClusterAdapter<T>;
    private _becomeActiveTriggered = false;
    private readonly _previewName = new BehaviorSubject<string | null>(null);
    // ----------------------------------------------------------------------------------
    // Dependencies
    constructor(
        protected _definition: IItemClusterFilterDefinition<T>,
        protected _filters: any,
        protected _lgTranslate: LgTranslateService,
        protected _logexPivot: LogexPivotService
    ) {
        if (!this._definition.placeholder) {
            this._definition.placeholder = this._definition.name ?? "";
        }

        this._adapter = _definition.adapter;
        if (this._definition.wide == null) this._definition.wide = true;
    }

    createStorage(): void {
        if (this._definition.storage && this._filters[this._definition.storage] == null) {
            this._filters[this._definition.storage] = { $empty: true };
            this._filters[this._definition.storage + "$$cache"] = { $empty: true };
            this._checkPreviewName();
        }
    }

    active(): boolean {
        return !this._filters[this._definition!.storage!].$empty;
    }

    previewVisible(): boolean {
        return this._definition.storage ? !this._filters[this._definition.storage].$empty : false;
    }

    clear(): boolean {
        this._becomeActiveTriggered = false;
        if (this._definition.storage && !this._filters[this._definition.storage].$empty) {
            this._filters[this._definition.storage] = { $empty: true };
            this._filters[this._definition.storage + "$$cache"] = { $empty: true };
            this._checkPreviewName();
            return true;
        }
        return false;
    }

    getFilterLineComponent(): ComponentType<
        FilterRendererComponentBase<
            IItemClusterFilterDefinition<any>,
            ItemClusterFilterRenderer<any>
        >
    > {
        return ItemClusterFilterRendererLineComponent;
    }

    getPopupComponent(): ComponentType<
        FilterRendererComponentBase<
            IItemClusterFilterDefinition<any>,
            ItemClusterFilterRenderer<any>
        >
    > {
        return ItemClusterFilterRendererPopupComponent;
    }

    getPreviewName(): Observable<string | null> {
        this._checkPreviewName();
        return this._previewName.asObservable();
    }

    getExportDefinition(): IFilterExportDefinition {
        return {
            name: this._definition.name ?? "",
            filter: this._definition.storage && this._filters[this._definition.storage]
        };
    }

    onChanged(): void {
        this._checkPreviewName();
        this._updateCache();

        if (this._definition.onBecameActive) {
            const filter = this._definition.storage
                ? this._filters[this._definition.storage]
                : null;
            if (filter.$empty) {
                this._becomeActiveTriggered = false;
            } else if (!this._becomeActiveTriggered) {
                if (
                    (this._definition.onBecameActiveLimit || 0) <= 0 ||
                    (this._definition.onBecameActiveLimit &&
                        ldSize(filter) <= this._definition.onBecameActiveLimit)
                ) {
                    this._becomeActiveTriggered = true;
                    this._definition.onBecameActive(this._definition.id);
                }
            }
        }
    }

    serialize(): string | null {
        if (!this.active()) return null;

        const state = this._definition.storage ? this._filters[this._definition.storage] : null;
        if (state.$empty) return "[]";

        const ids: string[] = Object.keys(state);

        return JSON.stringify(ids);
    }

    deserialize(state: string): boolean {
        const newIds = JSON.parse(state);
        const newState: any = {};

        if (ldIsArray(newIds)) {
            if (newIds.length === 0) {
                newState.$empty = true;
            } else {
                const clusters = this._adapter.getClusters();
                let found = false;
                for (const id of newIds) {
                    const def = clusters[id];
                    if (!def) continue;
                    newState[id] = def.name;
                    found = true;
                }
                if (!found) {
                    newState.$empty = true;
                }
            }
        } else {
            newState.$empty = true;
        }

        if (
            this._definition.storage &&
            !ldIsEqual(this._filters[this._definition.storage], newState)
        ) {
            this._filters[this._definition.storage] = newState;
            this.onChanged();
            return true;
        }
        return false;
    }

    toggleItem(id: string): void {
        if (this._definition.storage) {
            const state = this._filters[this._definition.storage];
            if (state[id]) {
                delete state[id];
                if (ldSize(state) === 0) state.$empty = true;
            } else {
                const cluster = this._adapter.getClusters()[id];
                if (cluster) {
                    state[id] = cluster.name;
                    delete state.$empty;
                }
            }
            this._filters[this._definition.storage] = ldClone(state);
            this.onChanged();
        }
    }

    public _source: () => IFilterOption[] | Promise<IFilterOption[]> | Observable<IFilterOption[]> =
        () => {
            const ids = this._definition.source();

            if (ldIsArray(ids)) {
                return this._mapClusters(ids);
            } else if ("then" in ids) {
                return ids.then(data => this._mapClusters(data));
            } else if (isIterable(ids)) {
                return this._mapClusters(Array.from(ids));
            } else {
                return ids.pipe(map(data => this._mapClusters(data)));
            }
        };

    private _checkPreviewName(): void {
        const value = this._definition.storage ? this._filters[this._definition.storage] : null;
        if (!value || value.$empty) {
            this._previewName.next(null);
            return;
        }

        // Do not use _.key for performance reasons
        let name = null;
        let count = 0;
        for (const key in value) {
            if (!Object.prototype.hasOwnProperty.call(value, key)) continue;
            if (count === 0) name = value[key];
            ++count;
        }
        this._previewName.next(count === 1 ? name : `${this._definition.name} (${count})`);
    }

    private _updateCache(): void {
        const current = this._filters[this._definition.id];
        if (current.$empty) {
            this._filters[this._definition.id + "$$cache"] = { $empty: true };
        } else {
            const cache = (this._filters[this._definition.id + "$$cache"] = {});

            ldEach(current, (_name, clusterId) => {
                const items = this._adapter.getClusterItems(clusterId);
                for (const item of items) {
                    (cache as any)["" + item] = true;
                }
            });
        }
    }

    private _mapClusters(ids: T[]): IFilterOption[] {
        const itemLookup = this._adapter.getAssignment();
        const clusters = this._adapter.getClusters();

        const result: IFilterOption[] = [];
        const used = new Set<string>();

        for (const id of ids) {
            const list = itemLookup["" + id];
            if (!list) continue;

            for (const clusterId of list) {
                if (!used.has(clusterId)) {
                    result.push({
                        id: clusterId,
                        name: clusters[clusterId].name
                    });
                    used.add(clusterId);
                }
            }
        }

        return ldSortBy(result, "name");
    }

    // private gatherVisibleProducts(): number[] {
    //     var result: number[] = [];
    //     var used: types.ILookup<boolean> = {};

    //     this.gatherProductNodes( false, ( node: any ) => {
    //         var product = node[this.definition.optionId];
    //         if ( !used[product] ) {
    //             result.push( product );
    //             used[product] = true;
    //         }
    //     } );
    //     return result;
    // }
}

// Factory ---------------------------------------------------------------------------------------------------------
@Injectable({ providedIn: "root" })
export class ItemClusterFilterRendererFactory implements IFilterRendererFactory {
    private _pivotService = inject(LogexPivotService);
    public readonly name: string = "itemcluster";

    public create(
        definition: IItemClusterFilterDefinition<any>,
        filters: Record<string, any>,
        _definitions: IFilterDefinition[],
        filterSet: LgFilterSet
    ): IFilterRenderer {
        return new ItemClusterFilterRenderer(
            definition,
            filters,
            filterSet.lgTranslate,
            this._pivotService
        );
    }
}

// Line template  --------------------------------------------------------------------------------------------------
@Component({
    selector: "lg-item-cluster-filter-renderer-line",
    // eslint-disable-next-line @angular-eslint/component-max-inline-declarations
    template: `
        <ng-container *ngIf="!_definition.wide">
            <label class="filter__label">{{ _definition.label }}:</label>
            <div class="filter__control">
                <div class="lg-itemcluster-filter">
                    <lg-multi-filter
                        placeholder="{{ _definition.placeholder }}"
                        [source]="_renderer._source"
                        [(filter)]="_filters[_definition.storage!]"
                        (filterChange)="_onChanged()"
                    ></lg-multi-filter>
                    <lg-quick-settings-menu
                        class="lg-icon-menu__button"
                        icon="icon-dots"
                        [definition]="_menuDefinition"
                        *ngIf="_menuDefinition?.length"
                    ></lg-quick-settings-menu>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="_definition.wide">
            <div class="filter__control filter__control--wide">
                <div class="lg-itemcluster-filter">
                    <lg-multi-filter
                        placeholder="{{ _definition.placeholder }}"
                        [source]="_renderer._source"
                        [(filter)]="_filters[_definition.storage!]"
                        (filterChange)="_onChanged()"
                        [wide]="true"
                        [wideLabel]="_definition.label ?? ''"
                    ></lg-multi-filter>
                    <lg-quick-settings-menu
                        class="lg-icon-menu__button"
                        icon="icon-dots"
                        [definition]="_menuDefinition"
                        *ngIf="_menuDefinition?.length"
                    ></lg-quick-settings-menu>
                </div>
            </div>
        </ng-container>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    viewProviders: [useTranslationNamespace("FW._Directives._ItemClusterFilter")]
})
export class ItemClusterFilterRendererLineComponent
    extends FilterRendererComponentBase<
        IItemClusterFilterDefinition<any>,
        ItemClusterFilterRenderer<any>
    >
    implements OnInit
{
    private _dialog = inject(LgItemClusterDialog);

    _menuDefinition: IQuickSettingsMenuItem[] = [];

    _onChanged(): void {
        this._renderer.onChanged();
        this._triggerChange();
        this._changeDetectorRef.markForCheck();
    }

    ngOnInit(): void {
        this._prepareMenuDefinition();
    }

    private _prepareMenuDefinition(): void {
        this._menuDefinition = [];

        const clusters = this._renderer._adapter.getClusters();

        const clusterGroup: IQuickSettingsMenuGroup = {
            type: QuickSettingsMenuType.Group,
            nameLC: this._definition.adapter.canEdit() ? ".Edit_clusters" : ".View_clusters",
            children: []
        };

        ldEach(clusters, (cluster, clusterId) => {
            clusterGroup.children.push({
                type: QuickSettingsMenuType.Choice,
                name: cluster.name,
                help: cluster.description!,
                onClick: () => this._editCluster(clusterId, cluster)
            });
        });

        if (clusterGroup.children.length > 0) {
            this._menuDefinition.push(clusterGroup);
        }

        if (this._definition.adapter.canEdit()) {
            this._menuDefinition.unshift({
                type: QuickSettingsMenuType.Choice,
                nameLC: ".Add_cluster",
                onClick: () => this._addCluster(),
                icon: "icon-add"
            });
        }
        this._changeDetectorRef.markForCheck();
    }

    private _addCluster(): void {
        this._dialog.addCluster(this._definition.adapter).then(() => {
            this._prepareMenuDefinition();
        });
    }

    private _editCluster(clusterId: string, cluster: IItemCluster): void {
        this._dialog
            .editCluster(
                this._definition.adapter,
                clusterId,
                cluster,
                !this._definition.adapter.canEdit()
            )
            .then(deleted => {
                this._prepareMenuDefinition();
                const filter = this._definition.storage
                    ? this._filters[this._definition.storage]
                    : null;
                if (filter && filter[clusterId]) {
                    this._renderer.toggleItem(clusterId);
                    if (!deleted) this._renderer.toggleItem(clusterId);
                    this._triggerChange();
                    this._changeDetectorRef.markForCheck();
                }
            });
    }
}

// Popup template  ---------------------------------------------------------------------------------------------------
@Component({
    selector: "lg-item-cluster-filter-renderer-popup",
    // eslint-disable-next-line @angular-eslint/component-max-inline-declarations
    template: `
        <div class="lg-filter-preview__popup__header">
            {{ "FW._Directives.ComboFilterRenderer_Popup_title" | lgTranslate }}:
            {{ _definition.name }}
            <div
                class="icon-16 icon-16-erase"
                title="{{ 'FW._Directives.ComboFilterRenderer_Clear_selections' | lgTranslate }}"
                (click)="this._clear()"
            ></div>
        </div>
        <lg-multi-filter
            placeholder="{{ _definition.placeholder }}"
            [showOnInit]="true"
            [source]="_renderer._source"
            [(filter)]="_filters[_definition.storage!]"
            (filterChange)="_onChanged()"
        ></lg-multi-filter>
    `
})
export class ItemClusterFilterRendererPopupComponent extends FilterRendererComponentBase<
    IItemClusterFilterDefinition<any>,
    ItemClusterFilterRenderer<any>
> {
    _onChanged(): void {
        this._renderer.onChanged();
        this._triggerChange();
    }
}
