<div class="lg-filters-panel__header">
    <div class="lg-panel__header-buttons" *ngIf="!!_slideoutApi">
        <ng-container *ngFor="let panel of _availablePanels">
            <ng-container
                *ngTemplateOutlet="
                    !panel.isActive || tabButtonTemplate == null
                        ? DefaultButtonTemplate
                        : tabButtonTemplate
                "
            >
            </ng-container>
            <ng-template #DefaultButtonTemplate>
                <button
                    class="button lg-filters-panel__header__button"
                    [ngClass]="panel.isActive ? 'button--primary' : 'button--secondary'"
                    (click)="_changePanel(panel)"
                >
                    {{ panel.nameLc | lgTranslate }}
                </button>
            </ng-template>
        </ng-container>
    </div>

    <div
        class="lg-filters-panel__header-icons"
        [ngClass]="!_slideoutApi ? 'lg-filters-panel__header-icons-without-tabs' : ''"
    >
        <ng-container *ngIf="!!_slideoutApi; else headerWithoutTabs">
            <ng-template
                *ngIf="iconsTemplate != null"
                [ngTemplateOutlet]="iconsTemplate"
            ></ng-template>
            <lg-icon
                class="lg-icon-menu__default lg-icon-menu"
                [icon]="pinned ? 'icon-pin_down' : 'icon-pin'"
                (click)="_togglePin()"
            ></lg-icon>
            <lg-icon
                class="lg-icon-menu__default lg-icon-menu"
                icon="icon-close"
                (click)="_hide()"
            ></lg-icon>
        </ng-container>
        <ng-content *ngIf="!_slideoutApi"></ng-content>
        <ng-template #headerWithoutTabs>
            <ng-content></ng-content>
        </ng-template>
    </div>
</div>
