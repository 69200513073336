import {
    Component,
    ContentChildren,
    QueryList,
    AfterContentInit,
    AfterContentChecked,
    OnDestroy,
    Input,
    OnChanges,
    inject,
    ChangeDetectionStrategy,
    ChangeDetectorRef
} from "@angular/core";
import { Subject, Observable, from, ReplaySubject } from "rxjs";
import { takeUntil, startWith, switchMap, mergeMap, map, shareReplay } from "rxjs/operators";

import { toInteger } from "@logex/framework/utilities";

import { LgColDefinitionService } from "../services/lg-col-definition.service";
import { LgColDefinitionSource, LgColDefinitionPrepared } from "../services";
import { LgColRowComponent } from "./lg-col-row.component";

/**
 * @desccription define sets of rows to be used by a table. Please check the bottom for the id alternative
 * @example
<lg-column-definition #costs columnClass="column" tableType="default">
<lg-col-row id="kp" columnClass="table-column">
    <lg-col id="kostenplaats" width="*" class="crop" ></lg-col>
    <lg-col id="collapse" type="icons" width="16" ></lg-col>
    <lg-col id="costs" width="90" class="right-align" ></lg-col>
    <lg-col id="split" type="icons" width="16" ></lg-col>
    <lg-col id="key" width="120" class="crop" ></lg-col>
    <lg-col id="cluster" width="120" class="crop" ></lg-col>
    <lg-col id="actions" type="icons" width="36" ></lg-col>
</lg-col-row>
<lg-col-row id="gb" columnClass="table-column">
    <lg-col id="empty" type="empty" width="127" ></lg-col>
    <lg-col id="grootboek" width="*" class="crop" ></lg-col>
    <lg-col id="copy" type="icons" width="16" ></lg-col>
    <lg-col-inherit row="kp" from="costs" ></lg-col-inherit>
</lg-col-row>
<lg-col-row id="header" columnClass="table-header-column">
    <lg-col id="kostenplaats" width="127" ></lg-col>
    <lg-col id="grootboek" width="*" ></lg-col>
    <lg-col id="spacer" type="icons" width="16" ></lg-col>
    <lg-col-inherit row="kp" from="collapse" to="split" ></lg-col-inherit>
    <lg-col id="key" width="120" class="center" ></lg-col>
    <lg-col id="cluster" width="120" class="center" ></lg-col>
    <lg-col id="actions" type="icons" width="36" ></lg-col>
</lg-col-row>
<lg-col-row id="details" columnClass="table-column">
    <lg-col-inherit row="gb" from="empty" to="split"></inherit>
    <lg-col-like id="info" row="gb" column="key" colSpan="1" class="right-align red"></like>
    <lg-col-like id="status" row="gb" column="actions"></like>
</lg-col-row>
</lg-column-definition>
 * Note: remember to not use auto-closing elements
 * Parameters of lg-column-definition:
 *   #id: use angular template variable in order to refer to the definition
 *   tableType: defines the standard table configuration to use (set of available column types)
 *   padding: (optional): this defines the basic padding used for the calculations. Defaults to the table type's padding (8 for global)
 *   columnClass: (optional): the default value of column-class attribute of the row elemenets (see below)
 * Parameters of row:
 *   id: this is id of the row. This doesn't have to be globally unique, but of course must be within the definition
 *   columnClass: (optional): a class that is automatically added to the column (can be overriden by lg-col-row)
 * Note: none of the width units require (or should contain) the unit. Pixels is assumed
 * Parameters of column:
 *   id: this is id of the column. Must be unique within the row
 *   type: defines the type of the column. A type carries its class, padding and possibly width. The set of types depends on the tableType, for default currently these types are supported
 *      standard: left and right padding (see lg-column-definition), no class or predefined width. This is the default and can be ommited
 *      icons: no left and right padding, icons class
 *      collapse: left padding of 16, right padding of 8, width of 16 (unless overriden), class collapse-icon.
 *      empty: class "empty-column"
 *      hidden: 0-width column with no padding, and the "hidden" class. It also doesn't count to the first/last determination (so if hidden is first column, then the
 *              next visible column will be marked as first too)
 *   paddingLeft, paddingRight: (optional) overrides the column's padding. 
 *   class: (optional) additional classes added to the column. Note that the first and last columns will automaticall get the "first" and "last" classes
 *   width: the width of the column. Two variants are possible
 *      fixed: when a number is specified. This will be the width of the column
 *      variable: when asterix is specified. Any remaining space in the row is divided between the columns with variable width. When multiple such columns are used, the asterix
 *                may be followed by a number specifying the "factor". So if one column uses "*1" and the other "*3", the first column will get 1/4 of the free space and the later 3/4
 *   widthTweak: a tweak of the column's physical width. This doesn't affect the width of the column itself, but changes how much will be subtracted from the row's
 *      available space. Typical use is with the over-margins class, such as:  <column id="split" type="icons" class="over-margins" width="16" width-tweak="-16"></column>
 *      (the over-margins css class sets the left and right margin to -16, effectively rendering the icon over the padding's of the neighbour columns. Combined with the "icons" type,
 *      the column will occupy effectively 0 pixels. 
 *      No special care is taken when combining such column with neighbour without padding, but that is the same case with the css itself.
 *   if: (optional) define whether the column is visible or not ( [if]="hasReference" ). This is cheaper than using *ngIf, if sufficient
 * Note: the paddings (both automatic and user-specified) are used only for the width calculation. They're not actually added to the column's css - it is assumed that the respective CSS class takes care of that
 * 
 * It is possible to reuse columns from one row in another row, using the <inherit> element. The parameters are as follows:
 *   row: which row will the columns be taken from
 *   from: id of the first column to be included
 *   to: (optional) id of the last column to be included. If ommited, the rest of the row is copied
 *   rename: (optional): mapping of the old names to new names. See below for details
 * Currently the definitions are evaluated in one pass, so the element must refer only to row present above. When a column is inherited, it is possible to modify
 * the inherited value by following column element, ie <lg-col-inherit from="kp" from="key"></lg-col-inherit>  <lg-col id="key" class="+center"></lg-col>. Currently, it should
 * be possible to override all the values, but you cannot change the column's flexibility. You can prefix the column's class to indicate that the classes should be 
 * merged, not overwritten.
 * It is possible to define a renaming pattern, using one of the 2 patterns (when inheriting from "kp" in the example above)
 *   [rename]="{costs:'profiel', key:'profiel_new', cluster:'-'}"
 *   rename="profiel, ,profiel_new,-"   or   [rename]="['profiel',,'profiel_new','-']"
 * The first defines mapping from the source id to new id. Ids, which are missing, will be kept unchanged. The second variant specifies the target IDs sequentially,
 * following the order of the original columns. Empty string means that the ID should be unchanged.
 * When "-" is specified as the target name, the column will be skipped.
 * 
 * An alternative to column reuse is the <lg-col-like> element. Its parameters are as follows:
 *   id: the id of the column
 *   row: the source row
 *   column: (optional) id of the column to copy. If ommited, "id" is used
 *   class: (optional) new class to replace the source one. If prefixed with +, appends the class to the original.
 *   paddingLeft, paddingRight: (optional) overrides the column's padding. 
 *   colSpan: (optional) the colspan, if the copied column should span multiple columns in the source row.
 * Currently the definitions are evaluated in one pass, so the element must refer only to row present above. Note that width or type of the column cannot be overriden.
 * The colSpan behaves the same as lgColSpan attribute of lgCol directive (see below), however because the <lg-col-like> element is evaluated before associating the
 * definition with row width, it is not possible to colspan across any flexible columns.
 * Note: unlike COLUMN, the LIKE element cannot override column that's already present in the row.
 *
 * All the attributes are fully dynamic and can use interpolation or expressions. You can also use *ngIf to show/hide whole blocks. Alternatively, you can use the
 * if parameter of column: this is cheaper to evaluate as it doesn't require full recalculation.
 *
 * Note that the lg-col-definition  block should be present before the first use of said definition, otherwise proper change propagation is not guaranteed (this
 * isn't really relevant if the definition is static)
 * 
 * In addition to the elements, it is possible to specify the definitions in javascript. However <lg-col-definition> is still needed for handling the related processing.
 * <lg-col-definition #def source="columnDefinitions"></lg-col-definition>. If both source and the content are present, the source has precedence.
 */

@Component({
    standalone: false,
    selector: "lg-col-definition",
    template: "",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LgColDefinitionComponent
    implements AfterContentInit, AfterContentChecked, OnDestroy, OnChanges
{
    private _changeDetectorRef = inject(ChangeDetectorRef);
    private _lgColDefinitionService = inject(LgColDefinitionService);

    @Input() set columnClass(val: string | undefined) {
        this._columnClasses = undefined;
        if (val === undefined) return;

        val = val.trim();
        if (!val) return;

        this._columnClasses = val
            .split(" ")
            .map(c => c.trim())
            .filter(c => !!c);
    }

    get columnClass(): string | undefined {
        if (this._columnClasses === undefined) return undefined;
        return this._columnClasses.join(" ");
    }

    @Input() tableType?: string;

    @Input() set padding(value: number | string) {
        this._padding = toInteger(value);
    }

    get padding(): number | undefined {
        return this._padding;
    }

    @Input() set source(val: LgColDefinitionSource) {
        this._externalSource = val;
        this._changes$.next();
    }

    get source(): LgColDefinitionSource | undefined {
        return this._externalSource;
    }

    // ---------------------------------------------------------------------------------------------
    definition(): Observable<LgColDefinitionPrepared> {
        return this._definition$;
    }

    // ---------------------------------------------------------------------------------------------
    constructor() {
        this._definition$ = this._changes$.pipe(
            map(() =>
                this._lgColDefinitionService.prepareDefinition(
                    this._externalSource || this._getSourceDefinition(),
                    this._tick$
                )
            ),
            shareReplay(1)
        );
    }

    // ---------------------------------------------------------------------------------------------
    private _columnClasses: string[] | undefined = undefined;
    private _padding: number | undefined = undefined;
    private readonly _destroyed$ = new Subject<void>();
    private readonly _changes$ = new ReplaySubject<void>(1);
    private _definition$: Observable<LgColDefinitionPrepared>;
    private readonly _tick$ = new Subject<void>();
    private _dirty = true;
    private _externalSource: LgColDefinitionSource | undefined = undefined;

    @ContentChildren(LgColRowComponent) _rows!: QueryList<LgColRowComponent>;

    // ---------------------------------------------------------------------------------------------
    _getSourceDefinition(): LgColDefinitionSource {
        return {
            tableType: this.tableType,
            columnClasses: this._columnClasses,
            padding: this._padding,
            rows: this._rows.toArray().map(r => r._getSourceDefinition())
        };
    }

    // ---------------------------------------------------------------------------------------------
    ngOnChanges(): void {
        this._dirty = true;
    }

    // ---------------------------------------------------------------------------------------------
    ngAfterContentInit(): void {
        this._rows.changes
            .pipe(
                startWith(null),
                switchMap(() => from(this._rows.toArray()).pipe(mergeMap(row => row._change$!))),
                takeUntil(this._destroyed$)
            )
            .subscribe(() => {
                if (!this._externalSource) {
                    this._dirty = true;
                    this._changeDetectorRef.markForCheck();
                }
            });
    }

    // ---------------------------------------------------------------------------------------------
    ngAfterContentChecked(): void {
        if (this._dirty) {
            this._changes$.next();
            this._dirty = false;
        } else {
            this._tick$.next();
        }
    }

    // ---------------------------------------------------------------------------------------------
    ngOnDestroy(): void {
        this._destroyed$.next();
        this._destroyed$.complete();
        this._changes$.complete();
    }
}
