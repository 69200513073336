<div class="lg-switch__options">
    <!-- other elements have pointer-events-none -->
    <span
        *ngFor="let option of _options; index as i"
        class="lg-switch__options__option"
        [class.lg-switch__options__option--highlight]="_highlightIndex === i"
        (click)="_click(option)"
        (mouseover)="_onOptionMouseover(i)"
        (mouseout)="_onOptionMouseout(i)"
    >
        <span class="lg-switch__options__option__text-wrapper">
            {{ option.label }}
        </span>
    </span>
</div>

<div class="lg-switch__slider-container">
    <mod-switch-slider
        [command]="_lastSliderCommand"
        [left]="_sliderLeftPosition"
        [width]="_sliderWidth"
    ></mod-switch-slider>
</div>

<div class="lg-switch__labels">
    <span
        *ngFor="let option of _options; index as i"
        class="lg-switch__labels__label"
        (click)="_click(option)"
        [ngClass]="{
            'lg-switch__labels__label--active': option.value === value,
            'lg-switch__labels__label--highlight': _hoveredIndex === i || _highlightIndex === i
        }"
    >
        {{ option.label }}
    </span>
</div>
