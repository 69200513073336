import { Injectable, InjectionToken } from "@angular/core";
import { Observable } from "rxjs";

import {
    IGetVersionsListResponse,
    IVersionResponse,
    IVersionSaveResponse
} from "./lg-scenario-gateway.types";
import { ServerGatewayBase } from "@logex/framework/lg-application";

export const LG_SCENARIO_GATEWAY = new InjectionToken<LgScenarioGateway>("lgScenarioGateway");

@Injectable({ providedIn: "root" })
export class LgScenarioGateway extends ServerGatewayBase {
    getVersionsList(): Observable<IGetVersionsListResponse> {
        return this._get<IGetVersionsListResponse>("api/version/list", {
            browserCache: false
        });
    }

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    setVersion(versionId: number) {
        return this._post<IVersionResponse>("api/version/set", {
            body: { version_id: versionId }
        });
    }

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    saveVersionAs(versionId: number, scenarioName: string, scenarioDescription: string) {
        return this._post<IVersionSaveResponse>("api/version/saveas", {
            body: {
                version_id: versionId,
                scenario_naam: scenarioName,
                scenario_omschrijving: scenarioDescription
            }
        });
    }

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    updateVersion(versionId: number, scenarioName: string, scenarioDescription: string) {
        return this._post<IVersionResponse>("api/version/update", {
            body: {
                version_id: versionId,
                scenario_naam: scenarioName,
                scenario_omschrijving: scenarioDescription
            }
        });
    }

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    setVersionPrimary(versionId: number) {
        return this._post<IVersionResponse>("api/version/primary", {
            body: { version_id: versionId }
        });
    }

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    lockVersion(versionId: number, locked: boolean) {
        return this._post<IVersionResponse>("api/version/lock", {
            body: {
                version_id: versionId,
                locked
            }
        });
    }

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    deleteVersion(versionId: number) {
        return this._post<IVersionResponse>("api/version/delete", {
            body: { version_id: versionId }
        });
    }
}
