import { Injectable } from "@angular/core";
import { BehaviorSubject, ReplaySubject, Subject } from "rxjs";
import { distinctUntilChanged } from "rxjs/operators";

/**
 * Service used for a communication between LgViewportScroller and LgViewportScrollable
 * directive.
 */
@Injectable({
    providedIn: "root"
})
export class LgViewportScrollerService {
    private _scrollPositionState$ = new BehaviorSubject<[number, number]>([0, 0]);
    scrollState$ = this._scrollPositionState$.pipe(distinctUntilChanged());

    private _anchorOffset$ = new BehaviorSubject<[number, number]>([0, 0]);
    anchorOffset$ = this._anchorOffset$.pipe(distinctUntilChanged());

    private _scrollToPosition$ = new Subject<[number, number]>();
    scrollToPosition$ = this._scrollToPosition$.asObservable();

    private _scrollToAnchor$ = new ReplaySubject<string>(1);
    scrollToAnchor$ = this._scrollToAnchor$.asObservable();

    updateCurrentScrollPosition(position: [number, number]): void {
        this._scrollPositionState$.next(position);
    }

    scrollToPosition(position: [number, number]): void {
        this._scrollToPosition$.next(position);
    }

    scrollToAnchor(anchor: string): void {
        this._scrollToAnchor$.next(anchor);
    }

    setAnchorOffset(offset: [number, number]): void {
        this._anchorOffset$.next(offset);
    }
}
