import { inject, Injectable, InjectionToken } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";

import { LG_APP_CONFIGURATION } from "./app-configuration";
import { LG_USER_INFO } from "../user";
import { LG_APP_LOAD_INDICATOR } from "./app-load-indicator.types";
import { lastValueFrom } from "rxjs";

// ----------------------------------------------------------------------------------

export interface ClientInfo {
    id: number;
    code: string;
    name: string;
}

export interface IAppControlService {
    readonly canSwitchClient: Promise<boolean>;

    getClients(): Promise<ClientInfo[]>;

    switchClient(clientId: number): void;

    readonly canSwitchContext: Promise<boolean>;

    showContextSelector(): void;
}

export const LG_APP_CONTROL = new InjectionToken<IAppControlService>("lgAppControl");

// ----------------------------------------------------------------------------------
interface IMergedHospitalItem {
    ziekenhuiscode: number;
    ziekenhuisnaam: string;
}

// ----------------------------------------------------------------------------------

@Injectable()
export class LgAppControlV1Service implements IAppControlService {
    private _appConfiguration = inject(LG_APP_CONFIGURATION);
    private _httpClient = inject(HttpClient);
    private _loadIndicator = inject(LG_APP_LOAD_INDICATOR);
    private _router = inject(Router);
    private _userInfo = inject(LG_USER_INFO);

    // ----------------------------------------------------------------------------------
    // Fields
    private _clientsPromise?: Promise<ClientInfo[]>;
    private _canSwitchClientPromise?: Promise<boolean>;
    private _canSwitchContextPromise?: Promise<boolean>;

    // ----------------------------------------------------------------------------------

    get canSwitchClient(): Promise<boolean> {
        if (this._canSwitchClientPromise == null) {
            this._canSwitchClientPromise = this.getClients().then(data => data.length > 0);
        }

        return this._canSwitchClientPromise;
    }

    getClients(): Promise<ClientInfo[]> {
        if (this._clientsPromise == null) {
            this._clientsPromise = lastValueFrom(
                this._httpClient.get<IMergedHospitalItem[]>("api/user/merged_hospitals")
            ).then(data =>
                data.map(x => {
                    return <ClientInfo>{
                        id: x.ziekenhuiscode,
                        code: x.ziekenhuiscode + "",
                        name: x.ziekenhuisnaam
                    };
                })
            );
        }

        return this._clientsPromise;
    }

    switchClient(clientId: number): void {
        this._loadIndicator.show();
        window.location.href = `boot/switch_merged?new_hospitalcode=${clientId}&path=${this._router.url}`;
    }

    // ----------------------------------------------------------------------------------

    get canSwitchContext(): Promise<boolean> {
        if (this._canSwitchContextPromise == null) {
            this._canSwitchContextPromise = Promise.resolve(
                !!this._appConfiguration.switchHospital &&
                    this._userInfo.hasPermission("switch_hospital")
            );
        }

        return this._canSwitchContextPromise;
    }

    showContextSelector(): void {
        this._loadIndicator.show();
        window.location.href = this._appConfiguration.switchHospitalUrl ?? "";
    }
}
