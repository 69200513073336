import {
    AfterContentInit,
    ChangeDetectionStrategy,
    Component,
    inject,
    Input,
    OnDestroy,
    Renderer2,
    ViewEncapsulation
} from "@angular/core";

import { atNextFrame, toBoolean } from "@logex/framework/utilities";
import { LgTranslatePipe, useTranslationNamespace } from "@logex/framework/lg-localization";

import { LgTagsSelectorPopupComponent } from "./lg-tags-selector-popup.component";
import { LgTagsSelectorBaseDirective } from "./lg-tags-selector-base";
import { LgObserveSizeService } from "../../behavior/index";
import { skip } from "rxjs/operators";
import { LgIconComponent } from "../lg-icon/lg-icon.component";
import { LgIconMenuComponent } from "../lg-icon-menu";
import { LgTooltipDirective } from "../../lg-tooltip";

@Component({
    standalone: true,
    selector: "lg-tags-selector",
    templateUrl: "./lg-tags-selector.component.html",
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        "[class.lg-tags-selector--disabled]": "_isDisabled",
        "[class.lg-tags-selector--adding]": "_isAddingNewTag",
        "[class.lg-tags-selector--add-button-hover-only]": "hoverOnly"
    },
    imports: [LgIconComponent, LgIconMenuComponent, LgTranslatePipe, LgTooltipDirective],
    viewProviders: [useTranslationNamespace("FW._Directives._TagsSelector")]
})
export class LgTagsSelectorComponent
    extends LgTagsSelectorBaseDirective
    implements AfterContentInit, OnDestroy
{
    private _renderer = inject(Renderer2);
    private _observeService = inject(LgObserveSizeService);

    constructor() {
        super();
        this._initMixins();
        this._observeService
            .observe(this._elementRef, this._renderer)
            .change({ type: "width" })
            .pipe(skip(1))
            .subscribe(() => this._syncVisibility());
    }

    // ----------------------------------------------------------------------------------

    @Input()
    public set disabled(value: boolean) {
        this._isDisabled = toBoolean(value);
        this._setTagsDisabled();
    }

    public get disabled(): boolean {
        return this._isDisabled;
    }

    @Input() public hoverOnly = false;

    @Input()
    public set matchWidth(value: boolean | string) {
        this._matchWidth = toBoolean(value);
    }

    public get matchWidth(): boolean {
        return this._matchWidth;
    }

    // ----------------------------------------------------------------------------------
    // Fields
    public _isDisabled = false;
    public _overflowTagsCount = 0;

    // ----------------------------------------------------------------------------------

    ngAfterContentInit(): void {
        this._bindToTagComponents();

        this._syncTags();
        atNextFrame(() => {
            this._setTagsDisabled();
            this._syncVisibility();
        });
    }

    protected override _syncVisibility(): void {
        // If we have any visible tag with adjusted width -> reset width to auto
        this._tagComponents.forEach(x => x.setWidth(undefined));

        // Measure the inner container
        const controlRect = (
            this._elementRef.nativeElement as HTMLDivElement
        ).getBoundingClientRect();
        let controlWidth = controlRect.width;

        const spacing = 8;

        let doSubtractSpacing = false;

        // Adjust the available control width if we have to show "more" text
        if (this._overflowTagsCount > 0) {
            controlWidth -= 24;
            doSubtractSpacing = true;
        }

        // Adjust the available control width if we have to show "+" button
        if (!this._isDisabled && this._emptyTagsCount > 0) {
            controlWidth -= 24;
            doSubtractSpacing = true;
        }

        // If either "..." or "+" (or both) are shown at the end, we must also take spacing into account
        if (doSubtractSpacing) {
            controlWidth -= spacing;
        }

        let overflowCount = 0;
        let left = 0;
        this._tagComponents.forEach(x => {
            if (x.isEmpty) return;

            const width = x.fullWidth;
            const right = left + width;
            let isOverflow = right > controlWidth;
            if (isOverflow) {
                const isLast = left < controlWidth;
                if (isLast) {
                    const remainingWidth = controlWidth - left;
                    if (remainingWidth >= 50) {
                        x.setWidth(remainingWidth);
                        isOverflow = false;
                    }
                }
            }

            // Hide all non-fitting tags
            x.isOverflow = isOverflow;
            overflowCount += isOverflow ? 1 : 0;

            left = right + spacing;
        });

        if (this._overflowTagsCount !== overflowCount) {
            this._overflowTagsCount = overflowCount;

            // If number of overflows changed, we need to reflow
            atNextFrame(() => this._syncVisibility());
        }

        this._changeDetectorRef.markForCheck();
    }

    private _setTagsDisabled(): void {
        if (this._tagComponents == null) return;

        this._tagComponents.forEach(x => {
            x.parentDisabled = this._isDisabled;
        });
    }

    async _openTagsSelectorPopup($event: MouseEvent): Promise<void> {
        $event.stopPropagation();

        await this._doShowPopup<LgTagsSelectorPopupComponent>({
            componentType: LgTagsSelectorPopupComponent,

            anchorElementRef: this._elementRef,
            anchorPositions: [
                {
                    originX: "start",
                    originY: "top",
                    overlayX: "start",
                    overlayY: "top",
                    offsetX: -8,
                    offsetY: -8
                },
                {
                    originX: "start",
                    originY: "bottom",
                    overlayX: "start",
                    overlayY: "bottom",
                    offsetX: -8,
                    offsetY: 8
                },
                { originX: "end", originY: "top", overlayX: "end", overlayY: "top" },
                { originX: "end", originY: "bottom", overlayX: "end", overlayY: "bottom" }
            ],

            initPopupComponent: instance => {
                return instance.init({
                    tagComponents: this._tagComponents.toArray(),
                    disabled: this._isDisabled
                });
            }
        });
    }

    override ngOnDestroy(): void {
        this._doClosePopup(true);
        super.ngOnDestroy();
    }
}
