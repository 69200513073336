import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { ClientInfo, LG_APP_CONTROL, LG_APP_SESSION } from "@logex/framework/lg-application";

@Component({
    selector: "lg-sidebar-merged-hospital-switch",
    // eslint-disable-next-line @angular-eslint/component-max-inline-declarations
    template: `
        @if (_appControlService.canSwitchClient | async) {
            @if (_clients$ | async; as clients) {
                @if (clients.length > 1) {
                    <li class="lg-sidebar-merged-hospital-switch lg-sidebar-list__item">
                        <button
                            class="lg-sidebar-nav-link lg-sidebar-nav-link__toggle"
                            [class.expanded]="_expanded"
                            (click)="_toggleSection()"
                        >
                            <lg-icon icon="icon-building" [inline]="true" />
                            {{ ".SwitchOrganisation" | lgTranslate }}
                            <lg-icon icon="icon-arrow-down" class="toggle-icon" />
                        </button>
                        @if (_expanded) {
                            <ul class="lg-sidebar-list">
                                @for (client of clients; track client.id) {
                                    <li
                                        class="lg-sidebar-list__item"
                                        [class.active]="_isClientActive(client.id)"
                                    >
                                        <button
                                            class="lg-sidebar-nav-link"
                                            (click)="_switchClient(client.id)"
                                        >
                                            {{ client.name }}
                                        </button>
                                    </li>
                                }
                            </ul>
                        }
                    </li>
                }
            }
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LgSidebarMergedHospitalSwitchComponent {
    _appControlService = inject(LG_APP_CONTROL);
    _session = inject(LG_APP_SESSION);

    _clients$: Promise<ClientInfo[]> = this._appControlService.getClients();
    _expanded = false;

    _isClientActive(clientId: number): boolean {
        return clientId === this._session.clientId;
    }

    _switchClient(id: number): void {
        this._appControlService.switchClient(id);
    }

    _toggleSection(): void {
        this._expanded = !this._expanded;
    }
}
