import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    // eslint-disable-next-line @angular-eslint/pipe-prefix
    name: "newlines",
    pure: true
})
export class LgNewlinesPipe implements PipeTransform {
    public transform(text: string | null | undefined): string {
        if (text == null) return "";

        return text
            .replace(/&/g, "&amp;")
            .replace(/</g, "&lt;")
            .replace(/>/g, "&gt;")
            .replace(/\n/g, "<br />");
    }
}
