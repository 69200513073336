// const requestAnimationFrame = ( function () {
//     return window.requestAnimationFrame ||
//         window.webkitRequestAnimationFrame ||
//         ( window as any ).mozRequestAnimationFrame ||
//         ( window as any ).oRequestAnimationFrame ||
//         ( window as any ).msRequestAnimationFrame ||
//         function (/* function */callback, /* DOMElement */element ) {
//             window.setTimeout( callback, 0 );
//         };
// } )();

// export function atNextFrame( callback: Function ) {
//     requestAnimationFrame( callback );
// }

/**
 * Schedule a function to be called at the next render frame. The function will be called within digest cycle
 *
 * @params callback the function to call
 * @deprecated use requestAnimationFrame instead
 */
export function atNextFrame(callback: FrameRequestCallback): void {
    window.requestAnimationFrame(callback); // should be supported by all relevant browsers, so don't bother polyfilling
}
