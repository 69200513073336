/* eslint-disable @typescript-eslint/no-this-alias */
import { coerceBooleanProperty, coerceNumberProperty } from "@angular/cdk/coercion";
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    inject,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    Output,
    Renderer2,
    ViewChild
} from "@angular/core";
import * as d3 from "d3";
import ldIsArray from "lodash-es/isArray";

import { ILookup, LgSimpleChanges } from "@logex/framework/types";
import { toBoolean } from "@logex/framework/utilities";
import {
    BaseBarChartWithReferenceLineComponent,
    REFERENCE_LINE_LABEL_WIDTH
} from "../shared/base-bar-chart-with-reference-line.component";
import { getRecommendedPosition } from "../shared/getRecommendedPosition";
import { getDefaultLegendOptions } from "../shared/getDefaultLegendOptions";
import {
    CHART_SEPARATOR_SIZE,
    ChartValueType,
    LegendItem,
    LegendOptions,
    Margin
} from "../shared/chart.types";
import { getLegendWidth } from "../shared/getLegendWidth";
import { LgColorPalette } from "../shared/lg-color-palette";
import { GroupedBarChartTooltipContext, IGroupedBarChartItem } from "./grouped-bar-chart.types";
import {
    LG_DEFAULT_COLOR_CONFIGURATION,
    LG_USE_NEW_LABELS,
    LgColorsConfiguration
} from "../shared/lg-color-palette-v2/lg-colors.types";
import { LgColorPaletteV2 } from "../shared/lg-color-palette-v2/lg-color-palette-v2";
import { IExportableChart, LgChartExportContainerDirective } from "../shared/lg-chart-export";
import { ValueFn } from "d3-selection";
import { RGBColor } from "d3";

const MARGIN: Required<Margin> = { top: 16, right: 16, bottom: 16, left: 16 };
const Y_AXIS_TITLE_WIDTH = 20;
const X_AXIS_TITLE_HEIGHT = 24;
const X_AXIS_LABELS_LINE_HEIGHT = 20;
const SPACE_FOR_LEGEND_BELOW = 30;
const SPACE_BETWEEN_Y_LABELS_AND_GRID = 8;
const ICON_SIZE = 24;
const OUTER_PADDING = 0.3;

interface ColumnIconOptions {
    icon?: string;
    iconType?: string;
    tooltip?: boolean;
    tooltipText?: string;
}

@Component({
    standalone: false,
    selector: "lg-grouped-bar-chart",
    templateUrl: "./lg-grouped-bar-chart.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LgGroupedBarChartComponent
    extends BaseBarChartWithReferenceLineComponent<
        IGroupedBarChartItem[],
        GroupedBarChartTooltipContext
    >
    implements OnChanges, OnDestroy, AfterViewInit, IExportableChart
{
    private _colorPalette = inject(LgColorPaletteV2);
    private _exportContainer = inject(LgChartExportContainerDirective, { optional: true });
    private _legacyColorPalette = inject(LgColorPalette);
    private _ngZone = inject(NgZone);
    private _renderer = inject(Renderer2);
    private _useNewLabels = inject(LG_USE_NEW_LABELS);

    /**
     * Specifies the data from which the chart is built.
     * Required parameter without default.
     */
    @Input({ required: true }) override data!: any[];

    /**
     * Specifies the Y-axis title. If not specified then there is no Y-axis title.
     */
    @Input() yAxisLabel?: string;

    /**
     * Specifies Y-axis position offset in pixels.
     *
     * @default 0
     */
    @Input() yAxisOffset = 0;

    /**
     * Specifies the X-axis title. If not specified then there is no X-axis title.
     */
    @Input() xAxisLabel = "";

    /**
     * Specifies whether X axis labels are visible or not.
     *
     * @default false
     */
    @Input() showXAxisLabels = false;

    /**
     * Specifies whether Y axis labels are visible or not. Defaults to true.
     *
     * @default true
     */
    @Input() showYAxisLabels = true;

    /**
     * Specifies maximum number of ticks on axis. Defaults to 10.
     *
     * @default 10
     */
    @Input() tickCount = 10;

    /**
     * Specifies which part of the group area is occupied by the spacing. Valid value is number from 0 to 1.
     * Defaults to `0.4` if `overlap` input is true, otherwise 0.5.
     */
    @Input() spacing!: number;

    /**
     * Callback for providing the column name of related data item (required).
     */
    @Input({ required: true }) columnName!: (locals: any) => string;

    /**
     * Callback for providing the group values of related data item (required).
     */
    @Input({ required: true }) groupValues!: (locals: any) => Array<number | null>;

    /**
     * Callback for providing group names of the chart (required).
     */
    @Input({ required: true }) groupNames!: (locals: any) => string[];

    /**
     * Callback for providing group spread ranges.
     * If specified then chart contains spreads.
     */
    @Input() spreadValues?: (locals: any) => Array<[number, number]>;

    /**
     * Callback for providing opacity of group column. Valid value is number from 0 to 1.
     */
    @Input() columnOpacity?: (value: any, groupIndex: number, groupName: string) => number;

    /**
     * Specifies if X-axis labels should be rotated.
     *
     * @default false
     */
    @Input() rotateXAxisLabels = false;

    /**
     * Specifies the height of X-axis labels area in pixels.
     *
     * @default 40
     */
    @Input() rotatedXAxisLabelsHeight = 40;

    /**
     * @deprecated use colorConfiguration instead
     */
    @Input() groupColors?: string;
    /**
     * @deprecated use colorConfiguration instead
     */
    @Input() groupHoverColors?: string;
    @Input() groupBrightness?: string;

    /**
     * Specifies if group columns must overlap one another.
     *
     * @default false
     */
    @Input() overlap?: boolean;

    /**
     * Specifies which part of column is overlapped. Valid value is number from 0 to 1.
     *
     * @default 0.4
     */
    @Input() overlapFraction!: number;

    /**
     * Specifies whether negative values are allowed or not.
     *
     * @default false
     */
    @Input() allowNegative?: boolean;

    /**
     * Specifies whether Y scale is symmetrical or not.
     *
     * @default false
     */
    @Input() ySymmetrical?: boolean;

    /**
     * @deprecated use colorConfiguration instead
     */
    @Input() columnColorFn: any;

    /**
     * @optional
     * Specifies the legend options. If not specified, legend is not visible.
     */
    @Input() legendOptions: LegendOptions = getDefaultLegendOptions();

    /**
     * @deprecated
     */
    @Input() comparingAgainst?: ChartValueType;

    /**
     * Specifies whether initial transition animation is turned on or not.
     *
     * @default true
     */
    @Input() initialRenderAsTransition?: boolean;

    /**
     * Specifies whether spaces between bars needed or not.
     *
     * @default false
     */
    @Input() noSpaceBetweenBars?: boolean;
    @Input() isComparingOnFront = false;

    /**
     * Callback for specifying whether group column contains icon or not.
     */
    @Input() columnIcons?: (item: any) => boolean;

    /**
     * Specifies group column icon options.
     *
     * @default  { icon: "icon-warning", iconType: "regular" }
     */
    @Input() columnIconOptions?: ColumnIconOptions;

    /**
     * @deprecated use colorConfiguration instead
     */
    @Input() groupColumnColors?: string[];
    /**
     * Specifies the color configuration. Defaults to categorical palette.
     *
     * If specified, allows four different configuration
     * - default/categorical - using 20 predefined colors
     * - sequential by color scheme - using predefined sequence of colors by name
     * - predefined - using predefined dictionary
     * - own - array of hexadecimal values
     *
     * For usage, see New Palette in storybook under LgCharts.
     * Palette story contains all possible colors.
     * Gallery story contains all charts using new colors.
     *
     * Example can be seen in 'getAllChartsProps.ts:62'
     */
    @Input() colorConfiguration: LgColorsConfiguration = LG_DEFAULT_COLOR_CONFIGURATION;

    /**
     * @optional
     * Specifies the minimum value on Y axis.
     * If not specified, minimum value is calculated from data.
     */
    @Input() yMax?: number;

    @Output() readonly labelClick = new EventEmitter<any>();

    @ViewChild("chart", { static: true }) private _chartDivRef!: ElementRef;
    @ViewChild("chartWithLegend", { static: true })
    private _chartWithLegendDivRef!: ElementRef<HTMLDivElement>;

    _legendDefinition: LegendItem[] = [];
    _spaceForYAxisLabels = 0;

    _legendWidth = 0; // intended use only for when legend is on the side
    _legendPaddingBottom = 0;

    protected override _margin!: Required<Margin>;
    protected _groupColors!: d3.ScaleOrdinal<string, string>;
    protected _hoverGroupColors!: d3.ScaleOrdinal<string, string>;
    protected _columns: string[] = [];
    protected _groupNames: string[] = [];
    protected _columnColors: string[][] = [];
    protected _groupBrightness?: Array<(arg0: string) => d3.RGBColor>;
    protected _yMin = 0;
    protected _yMax = 0;
    protected _oldYOffset: number | null = null;
    protected _xScale!: d3.ScaleBand<any>;
    protected _xGroupScale!: d3.ScaleBand<any>;
    protected _yScale!: d3.ScaleLinear<number, number>;
    protected _oldYScale!: d3.ScaleLinear<number, number>;
    protected _xAxisG!: d3.Selection<any, any, any, any>;
    protected _yAxisG!: d3.Selection<any, any, any, any>;
    protected _yAxisGridG!: d3.Selection<any, any, any, any>;
    protected _yAxisLabel!: d3.Selection<any, any, any, any>;
    protected _xAxisLabel!: d3.Selection<any, any, any, any>;

    private _columnIconsGroup!: d3.Selection<any, any, any, any>;
    private _groupOnTop = "";
    private _comparedOpacity = 1;
    private _groupToLegendDefinitionDictionary: ILookup<LegendItem> = {};
    private _lastMouseX = 0;
    private _lastMouseY = 0;
    private _trackListener!: () => void;
    private _spaceBelowAxis = 0;

    private get _horizontalPositionOfYAxis(): number {
        return (
            this._margin.left +
            (this.yAxisLabel ? Y_AXIS_TITLE_WIDTH : 0) +
            this._spaceForYAxisLabels +
            SPACE_BETWEEN_Y_LABELS_AND_GRID
        );
    }

    constructor() {
        super();
        this._margin = MARGIN;
        this._trackMousePosition();
    }

    ngOnChanges(changes: LgSimpleChanges<LgGroupedBarChartComponent>): void {
        if (!this._initialized) {
            this._initialize();
            return;
        }

        super._onBaseBarChartChanges(changes);

        let needsRender = false;
        let renderImmediate = false;
        let renderAsTransition = false;

        if (changes.data) {
            this._tooltip?.hide();
        }

        let wasSizeAlreadyUpdated = false;
        if (
            changes.data ||
            changes.columnName ||
            changes.columnOpacity ||
            changes.columnColorFn ||
            changes.groupNames ||
            changes.columnIcons ||
            changes.columnIconOptions
        ) {
            this._triggerDataSpecificMethods();
            this._sizePropsToState();
            this._updateSize();
            wasSizeAlreadyUpdated = true;
            needsRender = true;
            renderAsTransition = true;
        }

        if (changes.width || changes.height || changes.rotateXAxisLabels) {
            if (!wasSizeAlreadyUpdated) {
                this._sizePropsToState();
                this._updateSize();
            }

            needsRender = true;
            renderImmediate = true;
            renderAsTransition = false;
        }

        if (changes.overlapFraction || changes.allowNegative || changes.ySymmetrical) {
            needsRender = true;
            renderImmediate = true;
        }

        if (
            changes.showXAxisLabels ||
            changes.showYAxisLabels ||
            changes.yAxisOffset ||
            changes.groupColors ||
            changes.tickCount ||
            changes.referenceLine ||
            changes.referenceLineLabel
        ) {
            if (changes.groupColors) {
                this._groupColors.range(this.groupColors!.split(",") ?? "");
            }

            needsRender = true;
        }

        if (changes.groupHoverColors) {
            this._hoverGroupColors.range(this.groupHoverColors!.split(",") ?? "");
        }

        if (changes.yAxisLabel && this._chart) {
            this._yAxisLabel.text(this.yAxisLabel ?? "");
        }

        if (changes.xAxisLabel && this._chart) {
            this._xAxisLabel.text(this.xAxisLabel);
        }

        if (needsRender) {
            if (renderAsTransition) {
                d3.transition()
                    .duration(500)
                    .each(() => this._render(false));
            } else {
                this._render(renderImmediate);
            }
        }
    }

    private _initialize(): void {
        this._initializeFormatters();
        this._defaultProps();
        this._propsToState();
        this._triggerDataSpecificMethods();
        this._drawMainSvgHolder(this._chartDivRef.nativeElement);
        this._create();
        this._updateSize();
        this._render(!this.initialRenderAsTransition);
        this._initializeTooltip();
        this._trackMousePosition();
        this._initialized = true;
    }

    ngAfterViewInit(): void {
        this._exportContainer?.register(this);
    }

    ngOnDestroy(): void {
        this._exportContainer?.unregister(this);
        super._onDestroy();
    }

    getHtmlElement(): HTMLElement {
        return this._chartWithLegendDivRef.nativeElement;
    }

    getSvgElement(): SVGElement {
        return this._svg.node();
    }

    protected _defaultProps(): void {
        this.overlapFraction = coerceNumberProperty(this.overlapFraction, 0.4);
        this.yAxisOffset = coerceNumberProperty(this.yAxisOffset, 0);
        this.ySymmetrical = coerceBooleanProperty(this.ySymmetrical);
        this.initialRenderAsTransition = toBoolean(this.initialRenderAsTransition, true);
    }

    protected _propsToState(): void {
        this._sizePropsToState();
    }

    protected _sizePropsToState(): void {
        this.spacing = coerceNumberProperty(this.spacing, this.overlap ? 0.4 : 0.5);
    }

    private _triggerDataSpecificMethods(): void {
        this._convertData();
        this._initializeColorScales(this._data);
        this._updateLegend();
    }

    private _getLegendSize(below: boolean, onTheRight: boolean): number {
        if (!below && !onTheRight) return 0;

        if (below) {
            return SPACE_FOR_LEGEND_BELOW;
        }

        return getLegendWidth(
            this.width,
            this.legendOptions.widthInPercents ?? 0,
            this._groupNames
        );
    }

    protected _updateSize(): void {
        const legendVisible = this.legendOptions.visible;
        const legendBelow = legendVisible && this.legendOptions.position === "bottom";
        const legendOnTheRight = legendVisible && this.legendOptions.position === "right";

        const legendSize = this._getLegendSize(legendBelow, legendOnTheRight);

        const referenceLineLabelSize = this.referenceLineLabel ? REFERENCE_LINE_LABEL_WIDTH : 0;

        this._svg
            .attr("width", Math.max(0, this.width - (legendOnTheRight ? legendSize : 0)))
            .attr("height", Math.max(0, this.height - (legendBelow ? legendSize : 0)));

        this._oldYScale = this._yScale.copy();

        this._spaceBelowAxis =
            this._margin.bottom +
            (legendBelow ? legendSize - 6 : 0) +
            (this.showXAxisLabels ? X_AXIS_LABELS_LINE_HEIGHT : 0) +
            (this.xAxisLabel ? X_AXIS_TITLE_HEIGHT : 0) +
            (this.rotateXAxisLabels ? this.rotatedXAxisLabelsHeight : 0) +
            (this.columnIcons && !this.rotateXAxisLabels ? ICON_SIZE : 0);

        this._yScale
            .domain(this._getYScaleDomain())
            .range([this.height - this._spaceBelowAxis, this._margin.top])
            .nice()
            .interpolate(d3.interpolateRound);

        if (legendOnTheRight) {
            this._legendWidth = legendSize;
            this._legendPaddingBottom = this._spaceBelowAxis;
        }

        this._spaceForYAxisLabels = this._getSpaceForYAxisLabels(this._yScale);

        this._width =
            this.width -
            this._horizontalPositionOfYAxis -
            this._margin.right -
            referenceLineLabelSize;

        this._xScale
            .range([
                this._horizontalPositionOfYAxis,
                this.width -
                    this._margin.right -
                    (legendOnTheRight ? legendSize : 0) -
                    referenceLineLabelSize
            ])
            .domain(this._columns)
            .paddingInner(this.spacing)
            .paddingOuter(OUTER_PADDING)
            .round(true);

        this._xAxisG.attr("transform", `translate(0, ${this._yScale(this.yAxisOffset ?? 0)})`);
        this._xAxisLabel.attr(
            "transform",
            `translate(
                ${
                    this._spaceForYAxisLabels +
                    (this.width - (legendOnTheRight ? legendSize : 0) - referenceLineLabelSize) / 2
                },
                ${
                    this.height -
                    (legendBelow ? legendSize : 0) -
                    (legendBelow ? 10 : this._margin.bottom)
                }
            )`
        );

        this._yAxisG.attr("transform", `translate(${this._horizontalPositionOfYAxis}, 0)`);
        this._yAxisGridG.attr("transform", `translate(${this._horizontalPositionOfYAxis}, 0)`);
        this._yAxisLabel.attr(
            "transform",
            `translate(${this._margin.left + 6}, ${this._yScale(0)}) rotate( -90 )`
        );
    }

    protected _render(immediate: boolean): void {
        const getColor = (
            _column: string,
            group: string,
            columnIndex: number,
            groupIndex: number,
            hover: boolean
        ): string | number | boolean | null => {
            if (this.columnColorFn) {
                const color = this._columnColors[columnIndex][groupIndex];
                if (hover) {
                    return d3.rgb(color).darker(0.2).formatHex();
                } else {
                    return color;
                }
            } else if (hover) {
                if (this.groupHoverColors) {
                    return this._hoverGroupColors(group);
                }
                return d3.rgb(this._groupColors(group)).darker(0.2).formatHex();
            } else {
                return this._groupColors(group);
            }
        };

        if (!this._chart) {
            immediate = false;
            this._create();
        }
        if (!this.data || !this.data.length || !this.height) {
            return;
        }

        this._svg.on("mouseleave", (_event: MouseEvent) => this._tooltip?.hide());

        if (immediate || this._oldYOffset == null) {
            this._oldYOffset = this.yAxisOffset ?? null;
        }

        if (this.overlap) {
            this._xGroupScale.domain(["one"]).range([0, this._xScale.bandwidth()]).round(true);
        } else {
            this._xGroupScale
                .domain(this._groupNames)
                .range([0, this._xScale.bandwidth()])
                .padding(0)
                .round(true);
        }
        this._groupColors.domain(this._groupNames);
        this._hoverGroupColors.domain(this._groupNames);

        this._xAxisG
            .transition()
            .duration(immediate ? 0 : 250)
            .call(this._getXAxis(this._xScale))
            .attr("transform", `translate(0, ${this._yScale(this.yAxisOffset ?? 0)})`);

        if (this.rotateXAxisLabels) {
            this._xAxisG
                .selectAll(".tick text")
                .style("transform-box", "fill-box")
                .style("transform-origin", "center center")
                .style("transform", "rotate(315deg) translate(-55%, 25%)");
        } else {
            this._xAxisG.selectAll(".tick text").style("transform", "");
        }

        this._yAxisG
            .transition()
            .duration(immediate ? 0 : 250)
            .call(this._getYAxis(this._yScale));

        this._yAxisG
            .selectAll(".tick text")
            .transition()
            .duration(immediate ? 0 : 250)
            .attr("transform", `translate(${-SPACE_BETWEEN_Y_LABELS_AND_GRID}, 0)`);

        this._yAxisGridG
            .transition()
            .duration(immediate ? 0 : 250)
            .call(this._getYAxisGrid(this._yScale));

        const groups = this._chart
            .selectAll<SVGGElement, IGroupedBarChartItem[]>(".group")
            .data(this._data, (d: IGroupedBarChartItem[]) => d[0].column);

        // this is needed for fast rerenders when previous selection hasn't stopped exitting
        groups.interrupt();
        groups.transition("reappearing").style("opacity", 1);

        if (immediate) {
            groups.exit().remove();
        } else {
            groups.exit().transition().style("opacity", 0).remove();
        }

        const self = this;

        const groupsMerged = groups.enter().append("g").attr("class", "group").merge(groups);

        groupsMerged
            .on("mouseover", function (_event: MouseEvent, _d: IGroupedBarChartItem[]) {
                const index = groupsMerged.nodes().indexOf(this);
                d3.select(this)
                    .selectAll("rect")
                    .style("cursor", self.clickable ? "pointer" : "default")
                    .style("fill", (dFill, gi) => {
                        const { column = "", group = "" } = dFill as IGroupedBarChartItem;
                        return getColor(column, group, index, gi, true);
                    });
                self._tooltip?.hideShow();
                self._updateTooltipPosition();
            })
            .on("mouseout", function (_event: MouseEvent, _d: IGroupedBarChartItem[]) {
                const index = groupsMerged.nodes().indexOf(this);
                d3.select(this)
                    .selectAll("rect")
                    .style("fill", (dFill, gi) => {
                        const { column = "", group = "" } = dFill as IGroupedBarChartItem;
                        return getColor(column, group, index, gi, false);
                    });
            })
            .on("mouseleave", (_event: MouseEvent) => self._tooltip?.scheduleHide());

        const overlapStep = this.noSpaceBetweenBars
            ? 0
            : Math.max(1, (this._xGroupScale.bandwidth() * this.overlapFraction) / 2);

        const bars = this.isComparingOnFront
            ? groupsMerged.selectAll<SVGRectElement, IGroupedBarChartItem>("rect").data(
                  (d: IGroupedBarChartItem[]) => d.reverse(),
                  (d: IGroupedBarChartItem) => d.group
              )
            : groupsMerged.selectAll<SVGRectElement, IGroupedBarChartItem>("rect").data(
                  d => d,
                  (d: IGroupedBarChartItem) => d.group
              );

        // if ( this.reverseOpacity ) {
        //     bars = groupsMerged.selectAll( "rect" )
        //         .data( ( d: IGroupedBarChartItem[] ) => d.reverse(), ( d: IGroupedBarChartItem ) => d.group )
        //         .each( function ( d: IGroupedBarChartItem ) {
        //             d.opacity = this._isSelectedGroup(d.group) ? 1 : self._comparedOpacity;
        //         } )
        // }

        bars.exit()
            .attr("y", this._yScale(this.yAxisOffset))
            .attr("height", 0)
            .style("opacity", 0)
            .remove();

        const newBars = bars.enter().append("rect");

        let barsMergedOrdered = newBars
            .attr("class", "bar")
            .attr("x", (d, i) => this._calculateXPosition(d, i, overlapStep))
            .attr("width", (_, i) => this._calculateBarWidth(i, overlapStep))
            .attr("y", this._oldYScale(this._oldYOffset))
            .attr("height", 0)
            .style("fill", d => getColor(d.column, d.group, d.barIndex, d.groupIndex, false))
            .style("opacity", d => d.opacity)
            .on("mouseover", function (_event: MouseEvent, data: IGroupedBarChartItem) {
                self.tooltipContext = {
                    currentColumn: data,
                    columnsWithinGroup: self._data[data.barIndex],
                    groupToLegendDefinitionDictionary: self._groupToLegendDefinitionDictionary,
                    iconTooltip: false
                };
            })
            .on("click", function (_event: MouseEvent, d: IGroupedBarChartItem) {
                if (!self.clickable) return;
                const index = newBars.nodes().indexOf(this);
                self.itemClick.emit({ item: d.item, datum: d, index });
            })
            .merge(bars)
            .order();

        barsMergedOrdered.interrupt();

        barsMergedOrdered = immediate ? barsMergedOrdered : (barsMergedOrdered.transition() as any);

        barsMergedOrdered
            .attr("y", (d: IGroupedBarChartItem) =>
                this._yScale(Math.max(d.value, this.yAxisOffset))
            )
            .attr("height", (d: IGroupedBarChartItem) => {
                return (
                    (this.allowNegative
                        ? this._yScale(Math.min(d.value, this.yAxisOffset))
                        : this._yScale(this.yAxisOffset)) -
                    this._yScale(Math.max(d.value, this.yAxisOffset))
                );
            })
            .style("opacity", d => d.opacity)
            .style("fill", d => getColor(d.column, d.group, d.barIndex, d.groupIndex, false))
            .attr("x", (d, i) => this._calculateXPosition(d, i, overlapStep))
            .attr("width", (_, i) => this._calculateBarWidth(i, overlapStep));

        this._renderReferenceLine(immediate, this._horizontalPositionOfYAxis);

        this._xAxisG
            .selectAll(".tick")
            .on("mouseover", function (_event: MouseEvent, name: any) {
                const nameLength = name.length; // can be truncated so that's why all this stuff
                const barGroups = self._chart
                    .selectAll(".group")
                    .data() as IGroupedBarChartItem[][];
                const barGroupsIndex = barGroups
                    .map(group => group[0])
                    .map(groupItem => groupItem.column)
                    .map(columnName => columnName.substr(0, nameLength))
                    .indexOf(name);

                self._chart.selectAll(".group").each(function (_, i) {
                    d3.select(this)
                        .selectAll("rect")
                        .style("fill", (di, gi) => {
                            const { column = "", group = "" } = di as IGroupedBarChartItem;
                            return getColor(column, group, i, gi, i === barGroupsIndex);
                        });
                });

                self.tooltipContext = {
                    columnsWithinGroup: barGroups[barGroupsIndex],
                    groupToLegendDefinitionDictionary: self._groupToLegendDefinitionDictionary,
                    iconTooltip: false
                };

                self._tooltip?.hideShow();
                self._updateTooltipPosition();
            })
            .on("mouseleave", (_event: MouseEvent) => {
                self._chart.selectAll(".group").each(function (_, i) {
                    d3.select(this)
                        .selectAll("rect")
                        .style("fill", (di, gi) => {
                            const { column = "", group = "" } = di as IGroupedBarChartItem;
                            return getColor(column, group, i, gi, false);
                        });
                });
                this._tooltip?.scheduleHide();
            });

        if (this.labelClick.observers.length > 0) {
            this._xAxisG
                .selectAll(".tick")
                .style("cursor", "pointer")
                .on("click", (_event: MouseEvent, name: any) => {
                    const nameLength = name.length; // can be truncated so that's why all this stuff
                    const barGroups = self._chart
                        .selectAll(".group")
                        .data() as IGroupedBarChartItem[][];
                    const barGroupsIndex = barGroups
                        .map(group => group[0])
                        .map(groupItem => groupItem.column)
                        .map(columnName => columnName.substr(0, nameLength))
                        .indexOf(name);
                    if (barGroupsIndex < 0) return;

                    this.labelClick.next(barGroups[barGroupsIndex][0].item);
                });
        }

        this._oldYOffset = this.yAxisOffset;

        if (this.spreadValues) {
            const spread = groupsMerged
                .selectAll<SVGGElement, IGroupedBarChartItem>(".lg-grouped-bar-chart__spread-group")
                .data(
                    (d: IGroupedBarChartItem[]) => d,
                    (d: IGroupedBarChartItem) => d.group
                );

            if (immediate) {
                spread.exit().remove();
            } else {
                spread.exit().transition().style("opacity", 0).remove();
            }

            const spreadEnter = spread
                .enter()
                .append("g")
                .classed("lg-grouped-bar-chart__spread-group", true)
                .style("opacity", d => d.opacity);

            let spreadMerged = spreadEnter.merge(spread);

            const spreadXCoords = (
                selection: d3.Selection<
                    SVGLineElement,
                    IGroupedBarChartItem,
                    SVGGElement,
                    IGroupedBarChartItem[]
                >,
                middle: boolean
            ): d3.Selection<
                SVGLineElement,
                IGroupedBarChartItem,
                SVGGElement,
                IGroupedBarChartItem[]
            > =>
                selection
                    .attr(
                        "x1",
                        (d, i) =>
                            self._calculateXPosition(d, i, overlapStep) +
                            self._calculateBarWidth(i, overlapStep) * (middle ? 0.5 : 0.25)
                    )
                    .attr(
                        "x2",
                        (d, i) =>
                            self._calculateXPosition(d, i, overlapStep) +
                            self._calculateBarWidth(i, overlapStep) * (middle ? 0.5 : 0.75)
                    );

            const spreadYCoords = (
                selection: d3.Selection<
                    SVGLineElement,
                    IGroupedBarChartItem,
                    SVGGElement,
                    IGroupedBarChartItem[]
                >,
                upperIndex: number,
                lowerIndex: number
            ): d3.Selection<
                SVGLineElement,
                IGroupedBarChartItem,
                SVGGElement,
                IGroupedBarChartItem[]
            > =>
                selection
                    .attr("y1", d =>
                        d.spread?.[upperIndex] != null
                            ? this._yScale(Math.max(0, d.spread[upperIndex]))
                            : 0
                    )
                    .attr("y2", d =>
                        d.spread?.[lowerIndex] != null
                            ? this._yScale(Math.max(0, d.spread[lowerIndex]))
                            : 0
                    );

            let spreadLineVertical = spreadEnter
                .append<SVGLineElement>("line")
                .classed("lg-grouped-bar-chart__spread-group__line-vertical", true)
                .call(spreadXCoords, true)
                .attr("y1", 0)
                .attr("y2", 0)
                .merge(spreadMerged.select(".lg-grouped-bar-chart__spread-group__line-vertical"));

            let spreadLineHorizontalLower = spreadEnter
                .append<SVGLineElement>("line")
                .classed("lg-grouped-bar-chart__spread-group__line-horizontal-lower", true)
                .call(spreadXCoords, false)
                .attr("y1", 0)
                .attr("y2", 0)
                .merge(
                    spreadMerged.select(
                        ".lg-grouped-bar-chart__spread-group__line-horizontal-lower"
                    )
                );

            let spreadLineHorizontalUpper = spreadEnter
                .append<SVGLineElement>("line")
                .classed("lg-grouped-bar-chart__spread-group__line-horizontal-upper", true)
                .attr("y1", 0)
                .attr("y2", 0)
                .call(spreadXCoords, false)
                .merge(
                    spreadMerged.select(
                        ".lg-grouped-bar-chart__spread-group__line-horizontal-upper"
                    )
                );

            spreadMerged.interrupt();
            spreadLineVertical.interrupt();
            spreadLineHorizontalLower.interrupt();
            spreadLineHorizontalUpper.interrupt();

            spreadMerged = immediate ? spreadMerged : (spreadMerged.transition() as any);
            spreadLineVertical = immediate
                ? spreadLineVertical
                : (spreadLineVertical.transition() as any);
            spreadLineHorizontalLower = immediate
                ? spreadLineHorizontalLower
                : (spreadLineHorizontalLower.transition() as any);
            spreadLineHorizontalUpper = immediate
                ? spreadLineHorizontalUpper
                : (spreadLineHorizontalUpper.transition() as any);

            spreadLineVertical.call(spreadXCoords, true).call(spreadYCoords, 0, 1);

            spreadLineHorizontalLower.call(spreadXCoords, false).call(spreadYCoords, 0, 0);

            spreadLineHorizontalUpper.call(spreadXCoords, false).call(spreadYCoords, 1, 1);

            spreadMerged.style("opacity", d =>
                d.spread?.[0] != null && d.spread?.[1] != null ? d.opacity : 0
            );
        }

        this._drawColumnIcons();
    }

    private _drawColumnIcons(): void {
        this._columnIconsGroup.selectAll<SVGGElement, IGroupedBarChartItem>(".lg-icon").remove();

        if (this.columnIcons) {
            const self = this;
            const icon = this.columnIconOptions?.icon;
            const columnItems: IGroupedBarChartItem[][] = [];

            this._data.forEach(elements => {
                const row: IGroupedBarChartItem[] = [];
                elements.forEach(element => {
                    if (this.columnIcons!(element.item)) {
                        row.push(element);
                    }
                });
                if (row.length) columnItems.push(row);
            });

            const columnIconsSelection = this._columnIconsGroup
                .selectAll<SVGGElement, IGroupedBarChartItem>(".lg-icon")
                .data(columnItems);

            const bandwidth = this._xScale.bandwidth();
            const outerPadding = OUTER_PADDING * bandwidth * 2;
            const innerPadding = (this.spacing ?? 0) * bandwidth;
            const firstPointPosition =
                this._horizontalPositionOfYAxis + outerPadding + bandwidth / 2 - ICON_SIZE / 2;

            const columnIcons = columnIconsSelection
                .enter()
                .append("g")
                .attr(
                    "transform",
                    d =>
                        "translate(" +
                        (firstPointPosition + d[0].barIndex * (bandwidth + innerPadding * 2)) +
                        "," +
                        (this.height - this._spaceBelowAxis) +
                        ")"
                )
                .attr(
                    "class",
                    `${icon} lg-icon lg-icon--${this.columnIconOptions?.iconType} lg-tooltip-visible`
                )
                .merge(columnIconsSelection)
                .append("use")
                .attr("xlink:href", `#${icon}`)
                .attr("height", ICON_SIZE)
                .attr("width", ICON_SIZE);

            columnIcons
                .on("mousemove", function (_event: MouseEvent) {
                    if (self.columnIconOptions?.tooltip && self.columnIcons)
                        self._onMouseOverColumnIcon(d3.select(this));
                })
                .on("mouseout", (_event: MouseEvent) => {
                    if (self.columnIconOptions?.tooltip && self.columnIcons) self._tooltip?.hide();
                })
                .transition()
                .duration(0)
                .ease(d3.easeCubicOut);
        }
    }

    private _onMouseOverColumnIcon(
        target: d3.Selection<any, IGroupedBarChartItem, any, any>
    ): void {
        const data = target.data();
        this.tooltipContext = {
            iconTooltip: true,
            columnsWithinGroup: data,
            groupToLegendDefinitionDictionary: {}
        };

        this._tooltip?.show({ target: target.node() });
    }

    protected _create(): void {
        this._xScale = d3.scaleBand();
        this._xGroupScale = d3.scaleBand();
        this._yScale = d3.scaleLinear();

        this._yAxisGridG = this._svgG
            .append("g")
            .attr("class", `${this._useNewLabels ? "y__axis__grid" : "y__axis__grid__legacy"}`);

        this._createReferenceLine();

        this._chart = this._svgG.append("g").attr("class", "lg-chart-grouped-bar__groups-wrapper");

        this._yAxisG = this._svgG
            .append("g")
            .attr("class", `${this._useNewLabels ? "y__axis" : "y__axis__legacy"}`);
        this._xAxisG = this._svgG
            .append("g")
            .attr("class", `${this._useNewLabels ? "x__axis" : "x__axis__legacy"}`);

        this._yAxisLabel = this._svgG
            .append("text")
            .attr("class", `${this._useNewLabels ? "axis__title" : "axis__title y__axis"}`)
            .text(this.yAxisLabel ?? "")
            .attr("transform", "rotate(-90)");

        this._xAxisLabel = this._svgG
            .append("text")
            .attr("class", `${this._useNewLabels ? "axis__title" : "axis__title x__axis"}`)
            .text(this.xAxisLabel)
            .attr("text-anchor", "middle");

        this._columnIconsGroup = this._svgG
            .append("g")
            .attr("class", "lg-chart-grouped-bar__columnIcons");
    }

    protected _convertData(): void {
        if (!this.data) {
            return;
        }

        this._yMax = this.yMax ?? 0;
        this._yMin = 0;
        this._data = [];
        this._columns = [];
        this._groupNames = [];
        let colors = [];
        if (this.columnColorFn && this.columnColorFn.const) {
            const parsedColors = this.columnColorFn(null);
            if (typeof parsedColors === "string") {
                colors = parsedColors.split(",");
            } else {
                colors = parsedColors;
            }
        }

        this.data.forEach(value => {
            const columnName = this.columnName(value);
            this._columns.push(columnName);

            if (this._groupNames.length === 0) {
                this._groupNames = this.groupNames(value);
                this._groupOnTop = this.isComparingOnFront
                    ? this._groupNames[0]
                    : this._groupNames[1];
            }
            if (this.columnColorFn && !this.columnColorFn.const) {
                colors.push(this.columnColorFn(value));
            }
            const values = this.groupValues(value);
            if (!values) {
                return;
            }

            let spreadValues: Array<[number, number]> | null = null;
            if (this.spreadValues) {
                spreadValues = this.spreadValues(value);
            }

            const row: IGroupedBarChartItem[] = [];
            for (let i = 0, l = values.length; i < l; ++i) {
                const opacity = this.columnOpacity
                    ? this.columnOpacity(value, i, this._groupNames[i])
                    : 1;
                row.push({
                    column: columnName,
                    group: this._groupNames[i],
                    value: values[i] ?? 0,
                    spread: spreadValues ? spreadValues[i] : undefined,
                    opacity,
                    item: value,
                    barIndex: this._data.length,
                    groupIndex: i
                });

                if (opacity < this._comparedOpacity) this._comparedOpacity = opacity;

                this._yMin =
                    this._yMin == null ? (values[i] ?? 0) : Math.min(this._yMin, values[i] ?? 0);
                if (!this.yMax) {
                    this._yMax =
                        this._yMax == null
                            ? (values[i] ?? 0)
                            : Math.max(this._yMax, values[i] ?? 0);
                    if (spreadValues?.[i]?.[0] != null && spreadValues?.[i]?.[1] != null)
                        this._yMax = Math.max(spreadValues[i][1], this._yMax);
                }
            }
            this._data.push(row);
        });

        if (colors.length) {
            // this will be array of groups of colours
            this._columnColors = [];
            for (let i = 0; i < this._columns.length; ++i) {
                // if not enough colours were specified, just repeat the last one
                const entryStored: string | string[] = colors[Math.min(i, colors.length - 1)];
                let entry: string[];
                const group: string[] = [];
                let mainColor: string;
                if (!ldIsArray(entryStored)) {
                    // if the entry was single item, we'll convert it to group of colours by using the brightnesses (if specified)
                    mainColor = entryStored;
                    entry = [];
                } else {
                    // we've got array ,so in theory no processing, but in case there is not enough colours
                    entry = entryStored;
                    mainColor = entry[0];
                }
                for (let l = 0; l < this._groupNames.length; ++l) {
                    if (l < entry.length) {
                        group.push(entry[l]);
                    } else if (this._groupBrightness != null && l < this._groupBrightness.length) {
                        group.push(this._groupBrightness[l](mainColor).toString());
                    } else {
                        group.push(mainColor);
                    }
                }
                this._columnColors.push(group);
            }
        } else {
            this._columnColors = [];
        }
    }

    private _initializeColorScales(data: IGroupedBarChartItem[][]): void {
        if (this._colorPalette.useNewColorPalette) {
            const colors = this._colorPalette.getColorsForType(this.colorConfiguration);
            this._groupColors = d3.scaleOrdinal(colors);
            this._hoverGroupColors = d3.scaleOrdinal(colors);
            return;
        }
        this._initializeLegacyColorScales(data);
    }

    /**
     * @deprecated
     */
    private _initializeLegacyColorScales(data: IGroupedBarChartItem[][]): void {
        if (!data || !data.length) return;
        const numberOfGroups = data.reduce(
            (result, group) => (result = Math.max(result, group.length)),
            0
        );

        let colors;
        if (this.overlap) {
            colors = [
                this._legacyColorPalette.getColorForCompareColumn(this.comparingAgainst),
                ...this._legacyColorPalette.getPalette(1)
            ];
        } else if (this.groupColumnColors) {
            colors = this.groupColumnColors;
        } else {
            colors = this._legacyColorPalette.getPalette(numberOfGroups);
        }
        this._groupColors = d3.scaleOrdinal(colors);
        this._hoverGroupColors = d3.scaleOrdinal(colors);
    }

    protected _updateLegend(): void {
        this._legendDefinition = [];

        if (this._columnColors.length > 0) {
            for (const item of this._data) {
                const row = {
                    colors: this._columnColors[item[0].barIndex],
                    name: item[0].column,
                    item: item[0].item
                };
                this._legendDefinition.push({
                    color: row.colors[0],
                    name: row.name,
                    opacity: 1
                });
            }
        } else {
            this._groupNames.forEach(group => {
                const row: any = {
                    colors: [this._groupColors(group)],
                    name: group,
                    item: null
                };

                let opacity: number;
                if (this.overlapFraction === 0) {
                    opacity = 1;
                } else {
                    opacity = this.columnOpacity
                        ? this.columnOpacity(null, this._isSelectedGroup(group) ? 1 : 0, group)
                        : 1;
                }
                this._legendDefinition.push({
                    color: row.colors[0],
                    name: row.name,
                    opacity
                });
            });
        }

        const groupToColor: Record<string, LegendItem> = {};
        this._legendDefinition.forEach(def => (groupToColor[def.name] = def));
        this._groupToLegendDefinitionDictionary = groupToColor;
    }

    private _trackMousePosition(): void {
        this._ngZone.runOutsideAngular(() => {
            this._trackListener = this._renderer.listen(
                this._elementRef.nativeElement,
                "mousemove",
                (event: MouseEvent) => {
                    this._lastMouseX = event.clientX;
                    this._lastMouseY = event.clientY;
                    this._updateTooltipPosition();
                }
            );
        });
    }

    private _updateTooltipPosition(): void {
        if (this._tooltip && this._tooltip.visible) {
            if (this._lastMouseX && this._lastMouseY)
                this._tooltip.setPositionAt(
                    this._lastMouseX,
                    this._lastMouseY,
                    getRecommendedPosition(
                        { x: this._lastMouseX, y: this._lastMouseY },
                        this._tooltip.getOverlayElement()
                    )
                );
            else this._tooltip.hide();
        }
    }

    private _getXAxis(scale: d3.ScaleBand<any>): d3.Axis<any> {
        return d3
            .axisBottom(scale)
            .tickSize(0)
            .tickPadding(this.columnIcons && !this.rotateXAxisLabels ? ICON_SIZE : 12)
            .tickFormat((d, i) => this._getXAxisLabels(d, i));
    }

    private _getXAxisLabels(value: any, index: number): string {
        return this.showXAxisLabels ? this._formatXAxisLabel(value, index) : "";
    }

    private _formatXAxisLabel(label: string, index: number): string {
        if (this.rotateXAxisLabels) {
            const bottomLegendHeight =
                this.legendOptions.visible && this.legendOptions.position === "bottom"
                    ? SPACE_FOR_LEGEND_BELOW
                    : 0;
            const xAxisLabelHeight = this.xAxisLabel ? X_AXIS_TITLE_HEIGHT : 0;
            const overlapStep = this.noSpaceBetweenBars
                ? 0
                : Math.max(1, (this._xGroupScale.bandwidth() * this.overlapFraction) / 2);
            const barWidth = this._calculateBarWidth(index, overlapStep);
            const availableWidth = (this._horizontalPositionOfYAxis + (index + 0.5) * barWidth) / 6;
            const availableHeight =
                (this._spaceBelowAxis - bottomLegendHeight - xAxisLabelHeight) / 8;

            if (label.length > availableHeight) {
                label = `${label.substring(0, availableHeight)}...`;
            }
            if (label.length > availableWidth) {
                label = `${label.substring(0, availableWidth)}...`;
            }
        }

        return label;
    }

    private _getYAxis(scale: d3.ScaleLinear<number, number>): d3.Axis<any> {
        return d3
            .axisLeft(scale)
            .tickSize(0)
            .tickPadding(3)
            .tickFormat(item => this._getYAxisLabels(item))
            .ticks(coerceNumberProperty(this.tickCount, 10));
    }

    private _getYAxisLabels(value: any): string {
        return this.showYAxisLabels ? this._numberFormat(value) : "";
    }

    private _getYAxisGrid(scale: d3.ScaleLinear<number, number>): d3.Axis<any> {
        return d3
            .axisRight(scale)
            .tickPadding(0)
            .tickFormat(() => "")
            .ticks(coerceNumberProperty(this.tickCount, 10))
            .tickSizeOuter(0)
            .tickSizeInner(
                this.width -
                    this._horizontalPositionOfYAxis -
                    this._margin.right -
                    (this.referenceLineLabel ? REFERENCE_LINE_LABEL_WIDTH : 0)
            );
    }

    private _getYScaleDomain(): number[] {
        if (this.allowNegative) {
            if (this.ySymmetrical) {
                const delta = Math.max(
                    Math.abs(this._yMax - this.yAxisOffset),
                    Math.abs(this.yAxisOffset - this._yMin)
                );
                return [this.yAxisOffset - delta, this.yAxisOffset + delta];
            } else {
                return [Math.min(this._yMin, this.yAxisOffset), this._yMax];
            }
        } else {
            return [this.yAxisOffset, this._yMax];
        }
    }

    private _getSpaceForYAxisLabels(scale: d3.ScaleLinear<number, number>): number {
        let maxWidth = 0;

        // if text nodes were rendered inside the chart svg,
        // then sometimes `getComputedTextLength` returned 0
        // so appending to `body` instead
        // using css class so that measurement is based on styled text
        const fakeSvg = d3.select("body").append("svg").attr("class", "lg-grouped-bar-chart");

        fakeSvg
            .append("g")
            .selectAll("text")
            .data(scale.ticks().map(x => this._formatter.format(x)))
            .enter()
            .append("text")
            .text(d => d)
            .each(function () {
                maxWidth = Math.max(
                    maxWidth,
                    (this as SVGTextContentElement).getComputedTextLength()
                );
            });

        fakeSvg.remove();

        return Math.min(maxWidth, this.width / Math.PI);
    }

    private _calculateXPosition(
        datum: Partial<IGroupedBarChartItem>,
        index: number,
        overlapStep: number
    ): number {
        return (
            (this._xScale(datum.column) ?? 0) +
            (coerceBooleanProperty(this.overlap)
                ? overlapStep * index
                : (this._xGroupScale(datum.group) ?? 0))
        );
    }

    private _calculateBarWidth(index: number, overlapStep: number): number {
        const bandwidth = this._xGroupScale.bandwidth();
        const overlapWidth = 2 * index * overlapStep;
        return bandwidth - (this.overlap ? overlapWidth : CHART_SEPARATOR_SIZE);
    }

    _onLegendItemClick(item: LegendItem): void {
        if (this._isSelectedGroup(item.name)) return;
        this._groupOnTop = item.name;

        const groups = this._chart.selectAll(".group");
        this._changeAllGroups(groups);
        this._updateLegend();
    }

    private _changeAllGroups(groups: d3.Selection<d3.BaseType, unknown, any, any>): void {
        const self = this;
        const chartNode = this._chart.node();

        groups.each(function () {
            const group = d3.select(this);
            const groupNode = group.node() as SVGElement;
            chartNode.appendChild(groupNode);

            const barNode = groupNode.children[0];
            groupNode.appendChild(barNode);

            const bars = group.selectAll(".bar") as d3.Selection<d3.BaseType, {}, d3.BaseType, {}>;
            self._changeAllBars(bars);
        });
    }

    private _changeAllBars(bars: d3.Selection<d3.BaseType, {}, d3.BaseType, {}>): void {
        const self = this;
        const overlapStep = this.noSpaceBetweenBars
            ? 0
            : Math.max(1, (this._xGroupScale.bandwidth() * this.overlapFraction) / 2);

        bars.each(function (d: Partial<IGroupedBarChartItem>, i: any) {
            if (self.overlapFraction === 0) {
                d.opacity = 1;
            } else {
                d.opacity = self.columnOpacity?.call(this, null, i, d.group ?? "") ?? 1;
            }
            d3.select(this).style("opacity", d.opacity);
        })
            .attr("x", (d: Partial<IGroupedBarChartItem>, i) =>
                self._calculateXPosition(d, i, overlapStep)
            )
            .attr("width", (_, i) => self._calculateBarWidth(i, overlapStep));
    }

    private _isSelectedGroup(group: string): boolean {
        return group === this._groupOnTop;
    }
}
