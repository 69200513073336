import { InjectionToken } from "@angular/core";

import { AppVersionService } from "./app-version.service";

export const LG_APP_VERSION = new InjectionToken<IAppVersion>("lgAppVersion", {
    providedIn: "root",
    factory: () => new AppVersionService()
});

// ----------------------------------------------------------------------------------
//
export interface IAppVersion {
    version: string;
    isUpdated: boolean;
}
