import { InjectionToken, ElementRef } from "@angular/core";
import { Observable } from "rxjs";

export interface ILgScrolledEvent {
    x: number;
    y: number;
}

export interface ILgResizedEvent {
    width: number;
    height: number;
    maxWidth: number | undefined;
    maxHeight: number | undefined;
}

export const LG_SCROLLABLE_CONTAINER = new InjectionToken<ILgScrollableContainer>(
    "ILgScrollableContainer"
);

/**
 * Provides abstraction over containers (directives or components) that scroll. Currently implemented by lgScrollable
 */
export interface ILgScrollableContainer {
    scrolled(auditTimeInMs?: number): Observable<ILgScrolledEvent>;

    resized(auditTimeInMs?: number): Observable<ILgResizedEvent>;

    ensureVisible(el: ElementRef): void;

    getScrollContainerInfo(): {
        position: ILgScrolledEvent;
        size: ILgResizedEvent;
    };

    scrollTo(x: number | undefined | null, y: number | undefined | null): void;

    updateSize(): void;
}
