import ldSize from "lodash-es/size";
import ldClone from "lodash-es/clone";
import ldIsArray from "lodash-es/isArray";

import {
    ChangeDetectionStrategy,
    Component,
    DoCheck,
    EventEmitter,
    HostBinding,
    HostListener,
    inject,
    Input,
    isDevMode,
    Output
} from "@angular/core";

import { toBoolean } from "@logex/framework/utilities";
import { IColumnFilterDictionary, IFilterOption } from "@logex/framework/types";
import {
    ComboFilterRendererBase,
    IComboFilter2Definition,
    IComboFilterDefinition,
    ISelectableComboFilter2Definition,
    LgFilterSet
} from "@logex/framework/lg-filterset";

import { LgPivotTableRowDirective } from "./templates/lg-pivot-table-row.directive";
import { LgPivotTableComponent } from "./lg-pivot-table.component";

interface IConfig {
    /** The current definition set: must be specified */
    set: LgFilterSet;

    /** name of the target filter. If not specified, defaults to definition.column */
    filter?: string;

    /** name of the property containing the ID. Used if idValue is not specified. If missing too, defaults to definition.column */
    id?: string;

    /** value of the item's id  */
    idValue?: number | string;

    /** name of the item's filter. Use only in cases where running through the regular filter definition is not possible (async filters) */
    name?: string;

    /** Allows disabling the clickability */
    disabled?: boolean;
}

@Component({
    standalone: false,
    selector: "lg-pivot-table-filterable",
    template: `
        <lg-hoverable-icon
            [icon]="active ? 'icon-filter' : 'icon-filter-empty'"
            [hoverIcon]="active ? 'icon-filter-empty' : 'icon-filter'"
        ></lg-hoverable-icon>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        "[class.lg-pivot-table-filterable]": "true",
        "[class.lg-pivot-table-filterable--disabled]": "!filterable"
    }
})
export class LgPivotTableFilterableComponent implements DoCheck {
    private _row? = inject(LgPivotTableRowDirective, { optional: true });
    private _table? = inject(LgPivotTableComponent, { optional: true });

    /**
     * Filters config definition (required).
     */
    @Input({ required: true })
    set config(value: LgFilterSet | IConfig | null) {
        if (value == null) {
            this._active = false;
            this._config = null;
            return;
        }

        if (value instanceof LgFilterSet) {
            this._config = { set: value };
        } else {
            this._config = value;
        }

        if (isDevMode()) {
            if (!this._config.set)
                console.error("lgPivotTableFilterable: Set must be specified in ", this._config);
            if (!this._row) {
                if (this._config.idValue === undefined)
                    console.error(
                        " lgPivotTableFilterable outside pivot row: idValue must be specified ",
                        this._config
                    );
                if (this._config.filter === undefined)
                    console.error(
                        " lgPivotTableFilterable outside pivot row: filter must be specified ",
                        this._config
                    );
            } else if (
                this._config.idValue === undefined &&
                this._config.id === undefined &&
                !this._row.definition.column
            )
                console.error(
                    "lgPivotTableFilterable: idValue or id must be specified, or the definition must include column ",
                    this._config
                );
        }
    }

    get config(): IConfig | null {
        return this._config;
    }

    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output("filterableClick") readonly onClick = new EventEmitter<void>();

    /**
     * Specifies, if clicking the filter icon also expands/collapses the pivot level.
     *
     * @default true
     */
    @Input() set expand(value: boolean | "true" | "false") {
        this._expand = toBoolean(value);
    }

    get expand(): boolean {
        return this._expand;
    }

    /**
     * Specifies if nullish values are allowed of not.
     *
     * @default false
     */
    @Input() set allowNullValues(value: boolean | "true" | "false") {
        this._allowNullValues = toBoolean(value);
    }

    get allowNullValues(): boolean {
        return this._allowNullValues;
    }

    /**
     * Specifies if notifications on filter changes is need or not.
     *
     * @default false
     */
    @Input() notifyFilterSet = false;

    // ---------------------------------------------------------------------------------------------
    @HostBinding("class.lg-pivot-table-filterable--active")
    get active(): boolean {
        return this._active && this.filterable;
    }

    get filterable(): boolean {
        return !this._config?.disabled;
    }

    // ---------------------------------------------------------------------------------------------
    @HostListener("click", ["$event"])
    click(event: MouseEvent): void {
        if (!this._config || this._config.disabled) return;
        event.stopPropagation();
        event.preventDefault();

        const filterId = this._config.filter || this._row?.definition.column || "";
        const filterDefinition = this._config.set.getFilterDefinition(filterId);
        if (filterDefinition.filterType === "checkbox") {
            const filter: boolean = this._config.set.filters[filterId];
            this._config.set.filters[filterId] = !filter;
        } else {
            const idValue: number | string = this._getIdValue();
            const filter: IColumnFilterDictionary = this._config.set.filters[filterId];

            if (!filter || (!this._allowNullValues && idValue == null)) return;

            const itemInFilter = !!filter[idValue];
            if (!itemInFilter) {
                let name: string;
                if (this._config.name !== undefined) {
                    name = this._config.name;
                } else {
                    const option = this._getName(filterId, idValue);
                    name = option ? option.name : "";
                }

                delete filter.$empty;
                filter[idValue] = name;

                if (this._expand && !!this._row) {
                    this._wasExpanded = this._row.context.$implicit.$expanded;
                    if (this._row?.context.deeper) {
                        this._row.context.$implicit.$expanded = true;
                    }
                }
            } else {
                delete filter[idValue];
                if (ldSize(filter) === 0) filter.$empty = true;
                if (this._expand && !!this._row) {
                    this._row.context.$implicit.$expanded = !!this._wasExpanded;
                }
            }

            this._config.set.filters[filterId] = ldClone(filter);

            (
                this._config.set.getFilterDefinition(filterId).renderer as ComboFilterRendererBase
            ).onChanged();
        }

        if (this._table) {
            this._table.refilter();
            if (toBoolean(this.notifyFilterSet)) this._config.set.triggerOnChanged(filterId);
        } else {
            this._config.set.triggerOnChanged(filterId);
        }

        this.onClick.next();
    }

    // ---------------------------------------------------------------------------------------------
    private _active = false;
    private _wasExpanded = false;
    private _config: IConfig | null = null;
    private _expand = true;
    private _allowNullValues = false;

    // ---------------------------------------------------------------------------------------------
    // ---------------------------------------------------------------------------------------------
    ngDoCheck(): void {
        if (!this._config || this._config.disabled) return;

        const filterId = this._config.filter || this._row?.definition.column || "";
        const filterDefinition = this._config.set.getFilterDefinition(filterId);
        switch (filterDefinition.filterType) {
            case "checkbox":
                this._active = this._config.set.isActive(filterId);
                break;

            default:
                const idValue: number | string = this._getIdValue();
                const filter: IColumnFilterDictionary = this._config.set.filters[filterId];

                this._active =
                    filter && (idValue != null || this._allowNullValues) && filter[idValue];
                break;
        }
    }

    // ---------------------------------------------------------------------------------------------
    private _getIdValue(): number | string {
        let idValue = this._config?.idValue;
        if (idValue === undefined) {
            if (this._config?.id) {
                idValue = this._row?.context.$implicit[this._config.id];
            } else {
                idValue = this._row?.context.$implicit[this._row.definition.column!];
            }
        }
        return idValue ?? "";
    }

    // ---------------------------------------------------------------------------------------------
    private _getName<T extends number | string>(
        filterId: string,
        idValue: T
    ): IFilterOption | null {
        const filterDef:
            | IComboFilterDefinition<T>
            | IComboFilter2Definition<T>
            | ISelectableComboFilter2Definition<T> = this._config?.set.getFilterDefinition(
            filterId
        ) as any;
        if (!filterDef) {
            console.warn("Cannot find filter definition ", filterId);
            return null;
        }
        switch (filterDef.filterType) {
            case "combo":
                const source = filterDef.source();
                if (ldIsArray(source)) {
                    return source.find(s => s.id === idValue) ?? null;
                } else {
                    console.error(
                        "Filter definition with observable source() not supported; use name config"
                    );
                    return null;
                }
            case "combo2":
                return filterDef.mapToOptions([idValue])[0];
            case "selectablecombo2":
                return filterDef.mapToOptions([idValue])[0];
            default:
                console.error(`Filter type ${(filterDef as any).filterType} not supported`);
                return null;
        }
    }
}
