import ldIsArray from "lodash-es/isArray";
import { StringKeyOf } from "@logex/framework/types";

/**
 * Build a hierarch of lookups for the specified item, based on one their properties (which should be string or number).
 * No special handling is done for duplicate keys (the last item will be stored in the lookup)
 * Note that this function returns potentially dictionaries of dictionaries or dictionaries, it is not compatible with the pivot data structures
 *
 * @param array is the input array of items to assign
 * @param keyNames is the array of property names, in order in which the lookups should nest
 * @returns lookup based on the value of the first specified property (which is itself lookup of values based on second property etc)
 */
export function buildLookupHierarchy<T>(
    array: T[],
    keyNames: StringKeyOf<T> | Array<StringKeyOf<T>>
): any {
    const result: Record<string, any> = {};

    if (array && keyNames) {
        if (!ldIsArray(keyNames)) keyNames = [keyNames];

        for (let i = 0, l = array.length; i < l; ++i) {
            const e = array[i];
            let store = result;
            let j = 0;
            for (j = 0; j < keyNames.length - 1; ++j) {
                const key: any = e[keyNames[j]];
                let store2 = store[key];
                if (!store2) {
                    store2 = store[key] = {};
                }
                store = store2;
            }
            store[(e as any)[keyNames[j]]] = e;
        }
    }
    return result;
}
