import { LegendOptions } from "./chart.types";

export function getDefaultLegendOptions(overrides: Partial<LegendOptions> = {}): LegendOptions {
    return {
        className: "",
        position: "right",
        visible: false,
        widthInPercents: 20,
        cropLongNames: false,
        ...overrides
    };
}
