<div class="form-group">
    <div class="form-row form-row--input form-row--right-align form-row--vertically-centered">
        <div class="form-row__controls form-row__control-column form-row__control-column--fixed">
            <label>{{ _translateCustomized(".Filters_label") }}</label>
        </div>
        <div
            class="form-row__controls form-row__control-column form-row__control-column--fixed"
            *ngFor="let group of _groupConfigs"
        >
            <lg-multi-filter
                [placeholder]="group.filterPlaceholder"
                [source]="group.filterSource"
                [(filter)]="group.filter"
                (filterChange)="_refilter()"
            ></lg-multi-filter>
        </div>
    </div>

    <div class="form-row form-row--multilevel-picker">
        <div class="form-row__controls form-row__controls--3col flexcol">
            <ng-container *ngFor="let group of _groupConfigs">
                <div
                    class="lg-multilevel-picker__collapse"
                    [class.lg-multilevel-picker__collapse--collapsed]="group.collapsed"
                    (click)="_collapseGroup(group)"
                >
                    {{ group.label
                    }}<lg-icon
                        icon="icon-arrow-down"
                        class="lg-multilevel-picker__collapse-icon"
                    ></lg-icon>
                </div>
                <lg-item-selector
                    [hidden]="group.collapsed"
                    class="flex-flexible"
                    [source]="group.list"
                    [disabled]="_readonly"
                    [selection]="group.selection"
                    [config]="group.selectorConfiguration"
                >
                    <ng-template let-item>
                        <lg-icon
                            *ngIf="item.blockedItemsCount"
                            [icon]="_alreadyUsedIcon"
                            lgSimpleTooltip="={{
                                item.blockedWholeGroup ? group.isUsed : group.isPartiallyUsed
                            }}"
                            [tooltipOptions]="{ delayShow: 0, delayHide: 0 }"
                        ></lg-icon>
                        <div
                            class="lg-pivot-table-filterable"
                            [class.lg-pivot-table-filterable--active]="group.filter[item.groupCode]"
                            (click)="_groupFilterToggle($event, group, item)"
                        >
                            <lg-hoverable-icon
                                [icon]="
                                    group.filter[item.groupCode]
                                        ? 'icon-filter'
                                        : 'icon-filter-empty'
                                "
                                [hoverIcon]="
                                    group.filter[item.groupCode]
                                        ? 'icon-filter-empty'
                                        : 'icon-filter'
                                "
                            ></lg-hoverable-icon>
                        </div>
                    </ng-template>
                </lg-item-selector>
            </ng-container>
        </div>
        <div class="form-row__controls form-row__controls--3col flexcol">
            <div class="lg-multilevel-picker__collapse-like">
                {{ _translateCustomized(".Items_select_label") }}
                <ng-container *ngIf="_allowCopyPaste">
                    <lg-copy-button
                        [columnsDefinitions]="_copyPasteColumns"
                        [onGetData]="_getAllValues"
                        notificationPosition="top-left"
                    ></lg-copy-button>
                    <lg-paste-button
                        [columnsDefinitions]="_copyPasteColumns"
                        [onGetData]="_getAllValues"
                        [onPutData]="_setAllValues"
                        [preprocessData]="_preprocessAllValues"
                        [unmatched]="'allow'"
                        [extraInformation]="_copyPasteExtra"
                        *ngIf="!_readonly"
                    ></lg-paste-button>
                </ng-container>
            </div>
            <lg-item-selector
                class="flex-flexible"
                [source]="_availableItemsList"
                [disabled]="_readonly"
                [(selection)]="_itemSelection"
                [config]="_itemSelectorConfig"
            >
                <ng-template let-item>
                    <lg-icon
                        icon="icon-info"
                        [lgSimpleTooltip]="_itemAllocatedTooltip(item)"
                        [tooltipOptions]="{ delayShow: 0, delayHide: 0 }"
                        *ngIf="item.assignedTo"
                    ></lg-icon>
                </ng-template>
            </lg-item-selector>
        </div>
    </div>
</div>

<div class="form-group form-group--buttons">
    <div class="form-row" *ngIf="!_readonly">
        <div class="form-row__button-info">
            <lg-quick-notification [message]="_notification"></lg-quick-notification>
        </div>
        <lg-button
            (click)="_save()"
            buttonClass="button--primary"
            textLc=".Save_button"
            [isDisabled]="!_modified"
        ></lg-button>
        <lg-button (click)="_cancel()" textLc=".Cancel_button"></lg-button>
    </div>
    <div class="form-row" *ngIf="_readonly">
        <lg-button (click)="_cancel()" textLc=".Close_button"></lg-button>
    </div>
</div>
