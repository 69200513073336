import { Injectable, inject } from "@angular/core";
import { LG_FORMATTER_FACTORIES, ILgFormatterFactory } from "./formatter-factory-types";
import { ILgFormatter, ILgFormatterOptions } from "../implementation/formatter-types";

@Injectable({ providedIn: "root" })
export class LgFormatterFactoryService {
    protected _formatterFactories: ILgFormatterFactory[] = inject(LG_FORMATTER_FACTORIES);

    getFormatter(type: string, options?: ILgFormatterOptions): ILgFormatter<any> {
        if (!this._formatterFactories || this._formatterFactories.length === 0) {
            throw new Error("No formatter factories found.");
        }

        const lowerCaseType = type.toLowerCase();
        const factoriesOfType = this._formatterFactories.filter(
            x => x.names.map(name => name.toLowerCase()).indexOf(lowerCaseType) > -1
        );
        const hasMultipleDifferentFactories =
            factoriesOfType.length > 1 &&
            !factoriesOfType.every(
                factory => factory.constructor === factoriesOfType[0]?.constructor
            );
        if (!factoriesOfType?.length || hasMultipleDifferentFactories) {
            throw new Error(
                `There has to be exactly one factory of type '${type}', currently there's ${factoriesOfType.length}`
            );
        }

        return factoriesOfType[0]!.create(options);
    }
}
