/* eslint-disable @typescript-eslint/naming-convention */
import { epsilon, kappa, m, minv, refY, refU, refV, hexChars, HsluvLine } from "./hsluv.types";
import { distanceLineFromOrigin, lengthOfRayUntilIntersect } from "./hsluvGeometry";

/**
For a given lightness, return a list of 6 lines in slope-intercept
form that represent the bounds in CIELUV, stepping over which will
push a value out of the RGB gamut
 */
export function getBounds(L: number): HsluvLine[] {
    const result: HsluvLine[] = [];

    const sub1: number = Math.pow(L + 16, 3) / 1560896;
    const sub2: number = sub1 > epsilon ? sub1 : L / kappa;

    for (let c = 0; c < 3; c++) {
        const m1: number = m[c][0];
        const m2: number = m[c][1];
        const m3: number = m[c][2];

        for (let t = 0; t < 2; t++) {
            const top1: number = (284517 * m1 - 94839 * m3) * sub2;
            const top2: number =
                (838422 * m3 + 769860 * m2 + 731718 * m1) * L * sub2 - 769860 * t * L;
            const bottom: number = (632260 * m3 - 126452 * m2) * sub2 + 126452 * t;

            result.push({
                slope: top1 / bottom,
                intercept: top2 / bottom
            });
        }
    }

    return result;
}

/**
For given lightness, returns the maximum chroma. Keeping the chroma value
below this number will ensure that for any hue, the color is within the RGB
gamut.
 */
export function maxSafeChromaForL(L: number): number {
    const bounds: HsluvLine[] = getBounds(L);
    let min = Infinity;

    bounds.forEach(bound => {
        const length: number = distanceLineFromOrigin(bound);
        min = Math.min(min, length);
    });

    return min;
}

export function maxChromaForLH(L: number, H: number): number {
    const hrad: number = (H / 360) * Math.PI * 2;
    const bounds: HsluvLine[] = getBounds(L);
    let min = Infinity;

    bounds.forEach(bound => {
        const length: number = lengthOfRayUntilIntersect(hrad, bound);
        if (length >= 0) {
            min = Math.min(min, length);
        }
    });

    return min;
}

function dotProduct(a: number[], b: number[]): number {
    let sum = 0;

    for (let i = 0; i < a.length; i++) {
        sum += a[i] * b[i];
    }

    return sum;
}

// Used for rgb conversions
function fromLinear(c: number): number {
    if (c <= 0.0031308) {
        return 12.92 * c;
    } else {
        return 1.055 * Math.pow(c, 1 / 2.4) - 0.055;
    }
}

function toLinear(c: number): number {
    if (c > 0.04045) {
        return Math.pow((c + 0.055) / (1 + 0.055), 2.4);
    } else {
        return c / 12.92;
    }
}

/**
 * XYZ coordinates are ranging in [0;1] and RGB coordinates in [0;1] range.
 *
 * @param tuple An array containing the color's X,Y and Z values.
 * @return An array containing the resulting color's red, green and blue.
 **/
export function xyzToRgb(tuple: number[]): number[] {
    return [
        fromLinear(dotProduct(m[0], tuple)),
        fromLinear(dotProduct(m[1], tuple)),
        fromLinear(dotProduct(m[2], tuple))
    ];
}

/**
 * RGB coordinates are ranging in [0;1] and XYZ coordinates in [0;1].
 *
 * @param tuple An array containing the color's R,G,B values.
 * @return An array containing the resulting color's XYZ coordinates.
 **/
export function rgbToXyz(tuple: number[]): number[] {
    const rgbl: number[] = [toLinear(tuple[0]), toLinear(tuple[1]), toLinear(tuple[2])];

    return [dotProduct(minv[0], rgbl), dotProduct(minv[1], rgbl), dotProduct(minv[2], rgbl)];
}

/*
http://en.wikipedia.org/wiki/CIELUV
In these formulas, Yn refers to the reference white point. We are using
illuminant D65, so Yn (see refY in Maxima file) equals 1. The formula is
simplified accordingly.
*/
export function yToL(Y: number): number {
    if (Y <= epsilon) {
        return (Y / refY) * kappa;
    } else {
        return 116 * Math.pow(Y / refY, 1.0 / 3.0) - 16;
    }
}

export function lToY(L: number): number {
    if (L <= 8) {
        return (refY * L) / kappa;
    } else {
        return refY * Math.pow((L + 16) / 116, 3);
    }
}

/**
 * XYZ coordinates are ranging in [0;1].
 *
 * @param tuple An array containing the color's X,Y,Z values.
 * @return An array containing the resulting color's LUV coordinates.
 **/
export function xyzToLuv(tuple: number[]): number[] {
    const X: number = tuple[0];
    const Y: number = tuple[1];
    const Z: number = tuple[2];

    // This divider fix avoids a crash on Python (divide by zero except.)
    const divider: number = X + 15 * Y + 3 * Z;
    let constU: number = 4 * X;
    let constV: number = 9 * Y;

    if (divider !== 0) {
        constU /= divider;
        constV /= divider;
    } else {
        constU = NaN;
        constV = NaN;
    }

    const L: number = yToL(Y);

    if (L === 0) {
        return [0, 0, 0];
    }

    const U: number = 13 * L * (constU - refU);
    const V: number = 13 * L * (constV - refV);

    return [L, U, V];
}

/**
 * XYZ coordinates are ranging in [0;1].
 *
 * @param tuple An array containing the color's L,U,V values.
 * @return An array containing the resulting color's XYZ coordinates.
 **/
export function luvToXyz(tuple: number[]): number[] {
    const L: number = tuple[0];
    const U: number = tuple[1];
    const V: number = tuple[2];

    if (L === 0) {
        return [0, 0, 0];
    }

    const constU: number = U / (13 * L) + refU;
    const constV: number = V / (13 * L) + refV;

    const Y: number = lToY(L);
    const X: number = 0 - (9 * Y * constU) / ((constU - 4) * constV - constU * constV);
    const Z: number = (9 * Y - 15 * constV * Y - constV * X) / (3 * constV);

    return [X, Y, Z];
}

/**
 * @param tuple An array containing the color's L,U,V values.
 * @return An array containing the resulting color's LCH coordinates.
 **/
export function luvToLch(tuple: number[]): number[] {
    const L: number = tuple[0];
    const U: number = tuple[1];
    const V: number = tuple[2];

    const C: number = Math.sqrt(U * U + V * V);
    let H: number;

    // Greys: disambiguate hue
    if (C < 0.00000001) {
        H = 0;
    } else {
        const Hrad: number = Math.atan2(V, U);
        H = (Hrad * 180.0) / Math.PI;

        if (H < 0) {
            H = 360 + H;
        }
    }

    return [L, C, H];
}

/**
 * @param tuple An array containing the color's L,C,H values.
 * @return An array containing the resulting color's LUV coordinates.
 **/
export function lchToLuv(tuple: number[]): number[] {
    const L: number = tuple[0];
    const C: number = tuple[1];
    const H: number = tuple[2];

    const Hrad: number = (H / 360.0) * 2 * Math.PI;
    const U: number = Math.cos(Hrad) * C;
    const V: number = Math.sin(Hrad) * C;

    return [L, U, V];
}

/**
 * HSLuv values are ranging in [0;360], [0;100] and [0;100].
 *
 * @param tuple An array containing the color's H,S,L values in HSLuv color space.
 * @return An array containing the resulting color's LCH coordinates.
 **/
export function hsluvToLch(tuple: number[]): number[] {
    const H: number = tuple[0];
    const S: number = tuple[1];
    const L: number = tuple[2];

    // White and black: disambiguate chroma
    if (L > 99.9999999) {
        return [100, 0, H];
    }

    if (L < 0.00000001) {
        return [0, 0, H];
    }

    const max: number = maxChromaForLH(L, H);
    const C: number = (max / 100) * S;

    return [L, C, H];
}

/**
 * HSLuv values are ranging in [0;360], [0;100] and [0;100].
 *
 * @param tuple An array containing the color's LCH values.
 * @return An array containing the resulting color's HSL coordinates in HSLuv color space.
 **/
export function lchToHsluv(tuple: number[]): number[] {
    const L: number = tuple[0];
    const C: number = tuple[1];
    const H: number = tuple[2];

    // White and black: disambiguate chroma
    if (L > 99.9999999) {
        return [H, 0, 100];
    }

    if (L < 0.00000001) {
        return [H, 0, 0];
    }

    const max: number = maxChromaForLH(L, H);
    const S: number = (C / max) * 100;

    return [H, S, L];
}

/**
 * HSLuv values are in [0;360], [0;100] and [0;100].
 *
 * @param tuple An array containing the color's H,S,L values in HPLuv (pastel constiant) color space.
 * @return An array containing the resulting color's LCH coordinates.
 **/
export function hpluvToLch(tuple: number[]): number[] {
    const H: number = tuple[0];
    const S: number = tuple[1];
    const L: number = tuple[2];

    if (L > 99.9999999) {
        return [100, 0, H];
    }

    if (L < 0.00000001) {
        return [0, 0, H];
    }

    const max: number = maxSafeChromaForL(L);
    const C: number = (max / 100) * S;

    return [L, C, H];
}

/**
 * HSLuv values are ranging in [0;360], [0;100] and [0;100].
 *
 * @param tuple An array containing the color's LCH values.
 * @return An array containing the resulting color's HSL coordinates in HPLuv (pastel constiant) color space.
 **/
export function lchToHpluv(tuple: number[]): number[] {
    const L: number = tuple[0];
    const C: number = tuple[1];
    const H: number = tuple[2];

    // White and black: disambiguate saturation
    if (L > 99.9999999) {
        return [H, 0, 100];
    }

    if (L < 0.00000001) {
        return [H, 0, 0];
    }

    const max: number = maxSafeChromaForL(L);
    const S: number = (C / max) * 100;

    return [H, S, L];
}

/**
 * RGB values are ranging in [0;1].
 *
 * @param tuple An array containing the color's RGB values.
 * @return A string containing a `#RRGGBB` representation of given color.
 **/
export function rgbToHex(tuple: number[]): string {
    let h = "#";

    for (let i = 0; i < 3; i++) {
        const chan: number = tuple[i];
        const c = Math.round(chan * 255);
        const digit2 = c % 16;
        const digit1 = (c - digit2) / 16;
        h += hexChars.charAt(digit1) + hexChars.charAt(digit2);
    }

    return h;
}

/**
 * RGB values are ranging in [0;1].
 *
 * @param hex A `#RRGGBB` representation of a color.
 * @return An array containing the color's RGB values.
 **/
export function hexToRgb(hex: string): number[] {
    hex = hex.toLowerCase();
    const ret = [];
    for (let i = 0; i < 3; i++) {
        const digit1 = hexChars.indexOf(hex.charAt(i * 2 + 1));
        const digit2 = hexChars.indexOf(hex.charAt(i * 2 + 2));
        const n = digit1 * 16 + digit2;
        ret.push(n / 255.0);
    }
    return ret;
}

/**
 * RGB values are ranging in [0;1].
 *
 * @param tuple An array containing the color's LCH values.
 * @return An array containing the resulting color's RGB coordinates.
 **/
export function lchToRgb(tuple: number[]): number[] {
    return xyzToRgb(luvToXyz(lchToLuv(tuple)));
}

/**
 * RGB values are ranging in [0;1].
 *
 * @param tuple An array containing the color's RGB values.
 * @return An array containing the resulting color's LCH coordinates.
 **/
export function rgbToLch(tuple: number[]): number[] {
    return luvToLch(xyzToLuv(rgbToXyz(tuple)));
}

// RGB <--> HPLuv

/**
 * HSLuv values are ranging in [0;360], [0;100] and [0;100] and RGB in [0;1].
 *
 * @param tuple An array containing the color's HSL values in HSLuv color space.
 * @return An array containing the resulting color's RGB coordinates.
 **/
export function hsluvToRgb(tuple: number[]): number[] {
    return lchToRgb(hsluvToLch(tuple));
}

/**
 * HSLuv values are ranging in [0;360], [0;100] and [0;100] and RGB in [0;1].
 *
 * @param tuple An array containing the color's RGB coordinates.
 * @return An array containing the resulting color's HSL coordinates in HSLuv color space.
 **/
export function rgbToHsluv(tuple: number[]): number[] {
    return lchToHsluv(rgbToLch(tuple));
}

/**
 * HSLuv values are ranging in [0;360], [0;100] and [0;100] and RGB in [0;1].
 *
 * @param tuple An array containing the color's HSL values in HPLuv (pastel constiant) color space.
 * @return An array containing the resulting color's RGB coordinates.
 **/
export function hpluvToRgb(tuple: number[]): number[] {
    return lchToRgb(hpluvToLch(tuple));
}

/**
 * HSLuv values are ranging in [0;360], [0;100] and [0;100] and RGB in [0;1].
 *
 * @param tuple An array containing the color's RGB coordinates.
 * @return An array containing the resulting color's HSL coordinates in HPLuv (pastel constiant) color space.
 **/
export function rgbToHpluv(tuple: number[]): number[] {
    return lchToHpluv(rgbToLch(tuple));
}

// Hex

/**
 * HSLuv values are ranging in [0;360], [0;100] and [0;100] and RGB in [0;1].
 *
 * @param tuple An array containing the color's HSL values in HSLuv color space.
 * @return A string containing a `#RRGGBB` representation of given color.
 **/
export function hsluvToHex(tuple: number[]): string {
    return rgbToHex(hsluvToRgb(tuple));
}

export function hpluvToHex(tuple: number[]): string {
    return rgbToHex(hpluvToRgb(tuple));
}

/**
 * HSLuv values are ranging in [0;360], [0;100] and [0;100] and RGB in [0;1].
 *
 * @param tuple An array containing the color's HSL values in HPLuv (pastel constiant) color space.
 * @return An array containing the color's HSL values in HSLuv color space.
 **/
export function hexToHsluv(s: string): number[] {
    return rgbToHsluv(hexToRgb(s));
}

/**
 * HSLuv values are ranging in [0;360], [0;100] and [0;100] and RGB in [0;1].
 *
 * @param hex A `#RRGGBB` representation of a color.
 * @return An array containing the color's HSL values in HPLuv (pastel constiant) color space.
 **/
export function hexToHpluv(s: string): number[] {
    return rgbToHpluv(hexToRgb(s));
}
