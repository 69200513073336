import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";

import { CoreModule } from "@logex/framework/core";
import { UiCoreModule } from "@logex/framework/ui-core";
import { LgLocalizationModule } from "@logex/framework/lg-localization";
import { LG_FILTERSET_RENDERER_FACTORIES } from "@logex/framework/lg-filterset";

import { LgDownloadFormComponent } from "./download/lg-download-form.component";
import { LgExportMenuComponent } from "./download/lg-export-menu";
import { LgExportDialogComponent } from "./download/lg-export-dialog.component";
import { LgMultilevelPickerDialogComponent } from "./multilevel-picker/lg-multilevel-picker-dialog.component";
import {
    ItemClusterFilterRendererFactory,
    ItemClusterFilterRendererLineComponent,
    ItemClusterFilterRendererPopupComponent,
    LgItemClusterDialogComponent
} from "./itemcluster-filter";
import {
    LgUploadFormComponent,
    LgUploadResumableComponent,
    LgUploadTableComponent
} from "./uploader";
import {
    LgComparisonSourceIndicatorComponent,
    LgComparisonSourceMenuComponent,
    MiComparisonSourceMenuPopupComponent,
    MiComparisonSourceService
} from "./lg-comparison-source";
import { LgCommentDialogComponent, LgCommentIconComponent } from "./comments";
import {
    LgCopyButtonComponent,
    LgCopyMenuComponent,
    LgPasteButtonComponent,
    LgPasteDialogComponent
} from "./copy-paste";
import { LgHelpTooltipComponent } from "./help-tooltips";
import { FileDropDirective, FileSelectDirective } from "./file-upload";

const DIRECTIVES = [FileDropDirective, FileSelectDirective];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        CoreModule,
        UiCoreModule,
        LgLocalizationModule,
        ...DIRECTIVES
    ],
    exports: [
        LgCommentIconComponent,
        LgCopyMenuComponent,
        LgCopyButtonComponent,
        LgUploadFormComponent,
        LgUploadTableComponent,
        LgUploadResumableComponent,
        LgPasteButtonComponent,
        LgDownloadFormComponent,
        LgExportMenuComponent,
        LgMultilevelPickerDialogComponent,
        ItemClusterFilterRendererLineComponent,
        ItemClusterFilterRendererPopupComponent,
        LgItemClusterDialogComponent,
        LgComparisonSourceIndicatorComponent,
        LgComparisonSourceMenuComponent,
        LgHelpTooltipComponent,
        ...DIRECTIVES
    ],
    declarations: [
        LgCommentIconComponent,
        LgCopyMenuComponent,
        LgCopyButtonComponent,
        LgUploadFormComponent,
        LgUploadTableComponent,
        LgUploadResumableComponent,
        LgPasteButtonComponent,
        LgCommentDialogComponent,
        LgPasteDialogComponent,
        LgDownloadFormComponent,
        LgExportMenuComponent,
        LgExportDialogComponent,
        LgMultilevelPickerDialogComponent,
        ItemClusterFilterRendererLineComponent,
        ItemClusterFilterRendererPopupComponent,
        LgItemClusterDialogComponent,
        LgComparisonSourceIndicatorComponent,
        LgComparisonSourceMenuComponent,
        MiComparisonSourceMenuPopupComponent,
        LgHelpTooltipComponent
    ],
    providers: [
        {
            provide: LG_FILTERSET_RENDERER_FACTORIES,
            useClass: ItemClusterFilterRendererFactory,
            multi: true
        },
        MiComparisonSourceService
    ]
})
export class UiToolboxModule {
    // empty
}
