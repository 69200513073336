import { Directive, inject, Input, OnChanges, OnDestroy, OnInit, TemplateRef } from "@angular/core";
import { LgSimpleChanges } from "@logex/framework/types";
import { toBoolean, toInteger } from "@logex/framework/utilities";
import { LgPanelComponent } from "../components";
import { LgPanelContext, LgPanelRegistration } from "../service";

@Directive({
    selector: "[lgPanelIcons]"
})
export class LgPanelIconsDirective implements OnInit, OnDestroy, OnChanges {
    private _panel = inject(LgPanelComponent);
    private _templateRef = inject(TemplateRef<LgPanelContext>);

    @Input("lgPanelIconsOrder")
    set order(val: number | string) {
        this._order = toInteger(val, 0);
    }

    get order(): number {
        return this._order;
    }

    /**
     * Specifies if these panel icons is visible when maximized.
     *
     * @default true
     */
    @Input("lgPanelIconsOrderWhenMaximized")
    set whenMaximized(val: boolean | "true" | "false") {
        this._whenMaximized = toBoolean(val);
    }

    get whenMaximized(): boolean {
        return this._whenMaximized;
    }

    /**
     * Specifies if panel is visible when minimized.
     *
     * @default true
     */
    @Input("lgPanelIconsWhenMinimized")
    set whenMinimized(val: boolean | "true" | "false") {
        this._whenMinimized = toBoolean(val);
    }

    get whenMinimized(): boolean {
        return this._whenMinimized;
    }

    /**
     * Specifies if panel is visible on regular mode.
     *
     * @default true
     */
    @Input("lgPanelIconsWhenRegular")
    set whenRegular(val: boolean | "true" | "false") {
        this._whenRegular = toBoolean(val);
    }

    get whenRegular(): boolean {
        return this._whenRegular;
    }

    private _order = 0;
    private _whenMaximized = true;
    private _whenMinimized = true;
    private _whenRegular = true;
    private _registration: LgPanelRegistration | null = null;

    ngOnInit(): void {
        this._register();
    }

    ngOnChanges(changes: LgSimpleChanges<LgPanelIconsDirective>): void {
        if (this._registration) {
            if (changes.order) {
                this._registration.remove();
                this._register();
            } else if (changes.whenMinimized || changes.whenMaximized || changes.whenRegular) {
                this._registration.updateSettings(
                    this.whenMinimized,
                    this.whenMaximized,
                    this.whenRegular
                );
            }
        }
    }

    ngOnDestroy(): void {
        this._registration?.remove();
        this._registration = null;
    }

    private _register(): void {
        this._registration = this._panel.registerIcon(
            this._order,
            this._whenMinimized,
            this._whenMaximized,
            this._whenRegular,
            this._templateRef
        );
    }
}
