import { Directive, TemplateRef, Inject } from "@angular/core";
import { LgPivotTableRowDefBase } from "./lg-pivot-table-row-def-base";

import { LgPivotTableBodyRegister, IBodyRegister, IRowFooterTemplate } from "../types";

@Directive({
    standalone: true,
    selector: "[lgPivotTableRowFooterDef]",
    // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
    inputs: [
        "level: lgPivotTableRowFooterDefOnLevel",
        "visible: lgPivotTableRowFooterDefVisible",
        "height: lgPivotTableRowFooterDefHeight"
    ]
})
export class LgPivotTableRowFooterDefDirective
    extends LgPivotTableRowDefBase
    implements IRowFooterTemplate
{
    public constructor(
        @Inject(LgPivotTableBodyRegister) _body: IBodyRegister,
        _template: TemplateRef<any>
    ) {
        super(_body, _template);
    }

    protected _notifyMove(oldLevel: number | string): void {
        this._body.rowFooterTemplates.move(this, oldLevel);
    }

    protected _notifyChange(): void {
        this._body.rowFooterTemplates.change(this);
    }

    protected _notifyInit(): void {
        this._body.rowFooterTemplates.add(this);
    }

    protected _notifyDestroy(): void {
        this._body.rowFooterTemplates.remove(this);
    }
}
