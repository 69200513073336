import { Directive, ElementRef, HostListener, inject } from "@angular/core";
import {
    LgDetachmentNotificationEvent,
    LG_DETACHMENT_NOTIFICATION_EVENT_NAME
} from "./lg-detachment-notifier.directive";

// Note: if the following becomes too problematic, an alternative is to use something like this:
// this._subscription = this._ngZone.onMicrotaskEmpty.subscribe(() => this.runViewCheck());
// ..
// runViewCheck(): void {
//     const attached = isElementAttachedToDocument(this._elementRef.nativeElement);
//     if (attached && !this._attached) {
//         if (this._lastPosTop) {
//             this._elementRef.nativeElement.scrollTop = this._lastPosTop;
//         }
//         if (this._lastPosLeft) {
//             this._elementRef.nativeElement.scrollLeft = this._lastPosLeft;
//         }
//     }
//     this._attached = attached;
// }

@Directive({
    selector: "[lgDetachedPreserveScroll]",
    host: {
        LgDetachmentNotificationListener: ""
    }
})
export class LgDetachedPreserveScrollDirective {
    private _elementRef = inject(ElementRef<HTMLElement>);
    private _lastPosTop: number | null = null;
    private _lastPosLeft: number | null = null;
    private _wasAttached = false;

    @HostListener("scroll")
    _onScroll(): void {
        this._lastPosTop = this._elementRef.nativeElement.scrollTop;
        this._lastPosLeft = this._elementRef.nativeElement.scrollLeft;
    }

    @HostListener(LG_DETACHMENT_NOTIFICATION_EVENT_NAME, ["$event"])
    _onDetachmentNotification(event: LgDetachmentNotificationEvent): void {
        const attached = event.detail.attached;
        // when transitioning from detached to attached state, reset the scroll position
        if (attached && !this._wasAttached) {
            if (this._lastPosTop) {
                this._elementRef.nativeElement.scrollTop = this._lastPosTop;
            }
            if (this._lastPosLeft) {
                this._elementRef.nativeElement.scrollLeft = this._lastPosLeft;
            }
        }
        this._wasAttached = attached;
    }
}
