<div class="container">
    <div class="axis" #axis>
        <div class="axis__background">
            <div
                [ngClass]="[
                    'axis__value-line',
                    value !== undefined && value > 0
                        ? 'axis__value-line--positive'
                        : 'axis__value-line--negative'
                ]"
                [style.left]="_axisValueLeft"
                [style.width]="_axisValueWidth"
            ></div>
        </div>
        <div class="axis__separator"></div>
    </div>
    <span class="value" [style.width.px]="textSpace" [innerHTML]="_getFormattedValue()"> </span>
</div>
