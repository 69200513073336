/* eslint-disable @typescript-eslint/no-this-alias */

import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    isDevMode,
    NgZone,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    TemplateRef,
    ViewChild,
    inject,
    ViewContainerRef
} from "@angular/core";
import { coerceNumberProperty } from "@angular/cdk/coercion";
import { TemplatePortal } from "@angular/cdk/portal";
import lgKeyBy from "lodash-es/keyBy";

import * as d3 from "d3";

import {
    ILgFormatter,
    ILgFormatterOptions,
    LgConsole,
    LgFormatterFactoryService
} from "@logex/framework/core";
import { LgSimpleChanges } from "@logex/framework/types";

import { getLegendWidth } from "../shared/getLegendWidth";
import { LegendItem, LegendOptions, Margin } from "../shared/chart.types";
import {
    LgColorsConfiguration,
    LG_DEFAULT_COLOR_CONFIGURATION
} from "../shared/lg-color-palette-v2/lg-colors.types";
import { LgColorPaletteV2 } from "../shared/lg-color-palette-v2/lg-color-palette-v2";

import {
    LgHistogramDatum,
    LgHistogramReference,
    LgHistogramTooltipContext
} from "./lg-histogram-chart.types";
import { IExportableChart, LgChartExportContainerDirective } from "../shared/lg-chart-export";
import { getDefaultLegendOptions } from "../shared/getDefaultLegendOptions";
import { D3TooltipApi, ID3TooltipOptions, LgD3TooltipService } from "../d3/lg-d3-tooltip.service";
import { getRecommendedPosition } from "../shared/getRecommendedPosition";
import {
    IChartTooltipProvider,
    IImplicitContext
} from "../shared/lg-chart-template-context.directive";

const DEFAULT_HEIGHT = 300;
const DEFAULT_WIDTH = 400;
const DEFAULT_MARGIN: Margin = { top: 16, right: 16, bottom: 16, left: 16 };

const SPACE_BETWEEN_Y_LABELS_AND_GRID = 8;
const Y_AXIS_TITLE_WIDTH = 20;

const X_AXIS_TITLE_HEIGHT = 20;
const X_AXIS_LABELS_LINE_HEIGHT = 20;
const Y_AXIS_TITLE_OFFSET = 30;

const SPACE_FOR_LEGEND_BELOW = 30;

const DEFAULT_TICK_COUNT = 5;
const DEFAULT_MAX_LABEL_LENGTH = 10;
const DEFAULT_X_AXIS_TICK_DIVISOR = 10;
const DEFAULT_Y_AXIS_LABELS_WIDTH = 30;

const POINT_RADIUS = 5;
const ISOLATED_POINT_RADIUS = 2;
const REFERENCE_LINE_STROKE_WIDTH = 2;

const DEFAULT_TICKS_FORMATTER_OPTIONS: ILgFormatterOptions = { decimals: 0 };

const SHADOW_BOX_HOVER_COLOR_HEX = "#F5F9FF";

@Component({
    selector: "lg-histogram-chart",
    templateUrl: "./lg-histogram-chart.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LgHistogramChartComponent<TChartDatum extends LgHistogramDatum = LgHistogramDatum>
    implements
        OnChanges,
        OnInit,
        OnDestroy,
        AfterViewInit,
        IExportableChart,
        IChartTooltipProvider<LgHistogramTooltipContext<TChartDatum>>
{
    private _colorPalette = inject(LgColorPaletteV2);
    private _elementRef = inject(ElementRef);
    private _exportContainer = inject(LgChartExportContainerDirective, { optional: true });
    private _formatterFactory = inject(LgFormatterFactoryService);
    private _lgConsole = inject(LgConsole);
    private _ngZone = inject(NgZone);
    private _renderer = inject(Renderer2);
    private _tooltipService = inject(LgD3TooltipService);
    private _viewContainerRef = inject(ViewContainerRef);
    /**
     * Specifies the height of the chart area in pixels. Defaults to 300.
     *
     * @default 300
     */
    @Input() height: number = DEFAULT_HEIGHT;
    /**
     * Specifies the width of the chart area in pixels. Defaults to 400.
     *
     * @default 400
     */
    @Input() width: number = DEFAULT_WIDTH;

    /**
     * Specifies the margin for chart.
     * Defaults to 16 on all sides.
     *
     * @property { number } top specifies the margin of SVG from top.
     * @property { number } right specifies the margin of SVG from right.
     * @property { number } bottom specifies the margin of SVG from bottom.
     * @property { number } left specifies the margin of SVG from left.
     */
    @Input() margin: Margin = DEFAULT_MARGIN;

    /**
     * Specifies the data from which the chart is built. Required.
     */
    @Input({ required: true }) data: TChartDatum[] = [];

    /**
     * Specifies the comparison reference lines.
     */
    @Input() references: LgHistogramReference[] = [];

    /**
     * Specifies the minimal x value from the data to show.
     * If not specified, the lowest x value from the data is used.
     */
    @Input() xMin?: number;

    /**
     * Specifies the maximal x value from the data to show.
     * If not specified, the highest x value from the data is used.
     */
    @Input() xMax?: number;

    /**
     * Specifies the minimal y value from the data to show. Defaults to 0.
     *
     * @default 0
     */
    @Input() yMin = 0;

    /**
     * @optional
     * Specifies the maximal y value from the data to show.
     * If not specified, the highest y value from the data is used.
     */
    @Input() yMax?: number;

    /**
     * Specifies the number to round y max to.
     * If specified, the number Y axis starts with nearest multiple of given number.
     * The maximum is rounded up.
     *
     * If not specified, minimum and maximum values are used and d3 creates domain automatically.
     *
     * @example
     * roundToNearestMultipleOf = 500, min = 499, max = 999;
     * The Y axis ends with 1000.
     */
    @Input() roundToNearestMultipleOf?: number | null = null;

    /**
     * Specifies the X axis title. Defaults to empty string.
     * Can receive empty string to hide X axis title entirely.
     *
     * @default ""
     */
    @Input() xAxisLabel = "";

    /**
     * Specifies whether X axis labels are visible or not. Defaults to true.
     *
     * @default true
     */
    @Input() showXAxisLabels = true;

    /**
     * @optional
     * Specifies whether Y axis labels are visible or not. Defaults to true.
     *
     * @default true
     */
    @Input() showYAxisLabels = true;

    /**
     * Specifies how to format the X axis labels.
     * The callback is provided with the x data value and expects a string label.
     */
    @Input() xAxisLabelFormatter: (xValue: number) => string = xValue => xValue.toString();

    /**
     * Specifies the length of the X axis title text. Defaults to 10 characters.
     *
     * @default 10
     */
    @Input() xAxisLabelLength: number = DEFAULT_MAX_LABEL_LENGTH;
    /**
     * Specifies the distance between displayed tick values on X axis.
     * For example, setting this value to 10 will render tick values 0, 10, 20, ...
     *
     * @default 10
     */
    @Input() showXAxisTickInEvery: number = DEFAULT_X_AXIS_TICK_DIVISOR;

    /**
     * Specifies formatter type for number axis. Defaults to "float".
     *
     * @default "float"
     */
    @Input() yAxisFormatterType = "float";

    /**
     * Specifies the options for number axis formatter. Defaults to 0 decimals.
     *
     * @default 0 decimals
     */
    @Input() yAxisFormatterOptions: ILgFormatterOptions = DEFAULT_TICKS_FORMATTER_OPTIONS;

    /**
     * Specifies maximum number of ticks on axis. Defaults to 5.
     *
     * @default 5
     */
    @Input() yAxisTickCount = DEFAULT_TICK_COUNT;
    /**
     * Specifies the Y axis title. Defaults to empty string.
     * Can receive empty string to hide Y axis title entirely.
     *
     * @default ""
     */
    @Input() yAxisLabel = "";

    /**
     * Specifies the width of the Y-axis labels in pixels. Defaults to 30.
     *
     * @default 30
     */
    @Input() yAxisLabelsWidth = DEFAULT_Y_AXIS_LABELS_WIDTH;

    /**
     * Specifies the color configuration. Defaults to categorical palette.
     *
     * If specified, allows four different configuration
     * - default/categorical - using 20 predefined colors
     * - sequential by color scheme - using predefined sequence of colors by name
     * - predefined - using predefined dictionary
     * - own - array of hexadecimal values
     *
     * For usage, see New Palette in storybook under LgCharts.
     * Palette story contains all possible colors.
     * Gallery story contains all charts using new colors.
     *
     * Example can be seen in 'getAllChartsProps.ts:62'
     */
    @Input() colorConfiguration: LgColorsConfiguration = LG_DEFAULT_COLOR_CONFIGURATION;

    /**
     * Specifies the name of the distribution bars in the chart. Used in legend and tooltip.
     *
     * @default "Main distribution"
     */
    @Input() mainLabel = "Main distribution";

    /**
     * Specifies the legend options. If not specified, legend is not visible.
     *
     * @example ```
     * getDefaultLegendOptions({
     *  visible: true,
     *  position: "bottom"
     * })
     * ```
     */
    @Input() legendOptions: LegendOptions = getDefaultLegendOptions();

    /**
     * Specifies class of tooltip. Defaults to empty.
     *
     * @default ""
     */
    @Input() tooltipClass = "";

    /**
     * Specifies the template to be used when hovering over elements. Defaults to own template.
     */
    @Input() tooltipTemplate?: TemplateRef<
        IImplicitContext<LgHistogramTooltipContext<TChartDatum>>
    >;

    /**
     * Emits data in clicked legend item.
     */
    @Output() readonly legendClick = new EventEmitter<LegendItem>();

    @ViewChild("chartHolder") chartHolder!: ElementRef<HTMLDivElement>;
    @ViewChild("chartWithLegend", { static: true })
    private _chartWithLegendDivRef!: ElementRef<HTMLDivElement>;

    @ViewChild("defaultTemplate", { static: true })
    private _defaultTooltipTemplate!: TemplateRef<
        IImplicitContext<LgHistogramTooltipContext<TChartDatum>>
    >;

    _legendDefinition: LegendItem[] = [];
    _legendWidth = 0;
    _legendPaddingBottom = 0;
    _groupColors!: d3.ScaleOrdinal<string, string>;

    _yAxisFormatter: ILgFormatter<unknown> = this._formatterFactory.getFormatter(
        this.yAxisFormatterType,
        this.yAxisFormatterOptions
    );

    private _svg!: d3.Selection<SVGSVGElement, unknown, null, undefined>;
    private _svgG!: d3.Selection<SVGGElement, unknown, null, undefined>;
    private _xScale!: d3.ScaleBand<number>;
    private _yScale!: d3.ScaleLinear<number, number>;
    private _xAxisGroup!: d3.Selection<SVGGElement, unknown, null, undefined>;
    private _xAxisLabel!: d3.Selection<SVGTextElement, unknown, null, undefined>;
    private _yAxisGroup!: d3.Selection<SVGGElement, unknown, null, undefined>;
    private _yAxisGridG!: d3.Selection<SVGGElement, unknown, null, undefined>;
    private _yAxisLabel!: d3.Selection<SVGTextElement, unknown, null, undefined>;
    private _shadowBoxes!: d3.Selection<SVGRectElement, TChartDatum, SVGGElement, unknown>;
    private _bars!: d3.Selection<SVGRectElement, TChartDatum, SVGGElement, unknown>;
    private _hoverBoxes!: d3.Selection<SVGRectElement, TChartDatum, SVGGElement, unknown>;
    private _lines!: d3.Selection<SVGGElement, unknown, null, undefined>;
    private _referenceLineGroups: Array<d3.Selection<SVGGElement, unknown, null, undefined>> = [];
    private _lineDots: Array<d3.Transition<SVGCircleElement, TChartDatum, SVGGElement, unknown>> =
        [];

    private _highlightedLineDots: Array<d3.Selection<d3.BaseType, unknown, null, undefined>> = [];

    private _slicedData: TChartDatum[] = [];
    private _xScaleDomain: number[] = [];
    private _numberFormat = (x: number): string => this._yAxisFormatter.format(x);
    private _spaceForYAxisLabels = DEFAULT_Y_AXIS_LABELS_WIDTH;
    private _groupToLegendDefinitionDictionary: Record<string, LegendItem> = {};

    private _tooltip!: D3TooltipApi;
    private _tooltipContext: LgHistogramTooltipContext<TChartDatum> | null = null;
    private _tooltipPortal?: TemplatePortal<
        IImplicitContext<LgHistogramTooltipContext<TChartDatum>>
    >;

    get tooltipContext(): LgHistogramTooltipContext<TChartDatum> | null {
        return this._tooltipContext;
    }

    set tooltipContext(context: LgHistogramTooltipContext<TChartDatum>) {
        if (this._tooltipPortal?.context) {
            this._tooltipPortal.context.$implicit = context;
        }
        this._tooltipContext = context;
    }

    private _lastMouseX = 0;
    private _lastMouseY = 0;
    private _initialized = false;

    ngOnChanges(changes: LgSimpleChanges<LgHistogramChartComponent>): void {
        if (changes.yAxisLabelsWidth) {
            this._spaceForYAxisLabels = this.yAxisLabelsWidth ?? DEFAULT_Y_AXIS_LABELS_WIDTH;
        }

        if (changes.xAxisLabel) {
            this._xAxisLabel?.text(this.xAxisLabel);
        }

        if (changes.yAxisLabel) {
            this._yAxisLabel?.text(this.yAxisLabel);
        }

        if (changes.data) {
            this._prepareData();
        }

        if (changes.yAxisFormatterType || changes.yAxisFormatterOptions) {
            this._yAxisFormatter = this._formatterFactory.getFormatter(
                this.yAxisFormatterType,
                this.yAxisFormatterOptions
            );
        }

        this._reRender();
    }

    ngOnInit(): void {
        this.tooltipTemplate = this.tooltipTemplate ?? this._defaultTooltipTemplate;
        this._initializeTooltip();
        this._trackMousePosition();
    }

    ngAfterViewInit(): void {
        this._exportContainer?.register(this);
        this._render();
        this._updateLegend();
        this._initialized = true;
    }

    ngOnDestroy(): void {
        this._exportContainer?.unregister(this);
    }

    getHtmlElement(): HTMLElement {
        return this._chartWithLegendDivRef.nativeElement;
    }

    getSvgElement(): SVGElement {
        return this._svg.node()!;
    }

    _onLegendItemClick(item: LegendItem): void {
        this.legendClick.emit(item);
    }

    private _reRender(): void {
        if (this._initialized) {
            this._render();
            this._updateLegend();
        }
    }

    private _prepareData(): void {
        const xMin = this.xMin ?? d3.min(this.data, d => d.xValue ?? 0) ?? 0;
        const xMax = this.xMax ?? d3.max(this.data, d => d.xValue ?? 0) ?? 0;

        const slicedData: TChartDatum[] = [];
        const dataLookupByX = lgKeyBy(this.data, d => d.xValue);
        for (let x = xMin; x <= xMax; x++) {
            let dataPoint = dataLookupByX[x];
            if (dataPoint == null) {
                dataPoint = <TChartDatum>{
                    xValue: x
                };
            }
            slicedData.push(dataPoint);
        }
        this._slicedData = slicedData;
        this._xScaleDomain = this._slicedData.map(d => d.xValue);
    }

    private _render(): void {
        if (this.data == null || this.data.length === 0) {
            if (isDevMode()) {
                this._lgConsole.warn(
                    "No data are available for rendering. Stopping code execution"
                );
            }
            return;
        }
        this._createSvg();
        this._initializeXScale();
        this._initializeYScale();
        this._createAxes();

        this._initializeColorScales();
        this._createShadowBoxes();
        this._drawData();
        this._createHoverBoxes();
    }

    private _createSvg(): void {
        this._svg?.remove();
        this._svg = d3
            .select(this.chartHolder.nativeElement)
            .append("svg")
            .attr("width", this._svgWidth)
            .attr("height", this._svgHeight);
        this._svgG = this._svg.append("g");
        this._svg.on("mouseleave", (_event: MouseEvent) => this._tooltip.hide());
    }

    private _initializeXScale(): void {
        this._xScale = d3
            .scaleBand<number>()
            .domain(this._xScaleDomain)
            .range([this._horizontalPositionOfYAxis, this._svgWidth - (this.margin?.right ?? 0)])
            .round(true);
    }

    private _initializeYScale(): void {
        const roundTo = this.roundToNearestMultipleOf;
        const yMin = this.yMin;
        let yMax: number;

        if (this.yMax) {
            yMax = this.yMax;
        } else {
            const yMaxFromMain = d3.max(this.data, d => d.yValue ?? 0) ?? 0;
            const yMaxFromReferences = this.references.map(reference => {
                return d3.max(this.data, d => d[reference.referenceKey] ?? 0) ?? 0;
            });
            const yMaxFromData = Math.max(yMaxFromMain, ...yMaxFromReferences);
            yMax = roundTo ? Math.ceil(yMaxFromData / roundTo) * roundTo : yMaxFromData;
        }

        if (yMin === yMax) {
            yMax = yMin + 1;
        }

        this._yScale = d3
            .scaleLinear()
            .domain([yMin, yMax])
            .range([this._verticalPositionOfXAxis, this.margin.top ?? 0]);
    }

    private _createAxes(): void {
        this._addXAxis();
        this._addYAxis();
        this._addYAxisGrid();
    }

    private _addXAxis(): void {
        this._xAxisGroup = this._svgG
            .append("g")
            .attr("class", "lg-histogram-chart__x-axis")
            .attr("transform", `translate(0,${this._verticalPositionOfXAxis})`);

        this._xAxisLabel = this._svgG
            .append("text")
            .attr("class", "lg-histogram-chart__x-axis__title lg-histogram-chart__axis__title")
            .text(this.xAxisLabel)
            .attr("text-anchor", "middle")
            .attr(
                "transform",
                `translate(
                    ${this._svgWidth / 2},
                    ${this._verticalPositionOfxAxisLabel}
                )`
            );

        this._xAxisGroup.call(this._getXAxisDefinition());
    }

    private _getXAxisDefinition(): d3.Axis<number> {
        return d3
            .axisBottom(this._xScale)
            .tickSize(0)
            .tickPadding(12)
            .tickFormat(item => {
                const label = this._getXAxisLabels(item);
                const maxAllowedLength = this.xAxisLabelLength + 2;
                if (label.length > maxAllowedLength) {
                    return label.substring(0, this.xAxisLabelLength) + "...";
                }
                return label;
            });
    }

    private _getXAxisLabels(value: number): string {
        const isLabelVisible = Number(value) % this.showXAxisTickInEvery === 0;
        return this.showXAxisLabels && isLabelVisible ? this.xAxisLabelFormatter(value) : "";
    }

    private _addYAxis(): void {
        this._yAxisGroup = this._svgG
            .append("g")
            .attr("class", "lg-histogram-chart__y-axis")
            .attr("transform", `translate(${this._horizontalPositionOfYAxis}, 0)`);

        this._yAxisLabel = this._svgG
            .append("text")
            .attr("class", "lg-histogram-chart__y-axis__title lg-histogram-chart__axis__title")
            .text(this.yAxisLabel)
            .attr(
                "transform",
                `translate(${this.margin.left}, ${
                    this._verticalPositionOfXAxis - Y_AXIS_TITLE_OFFSET
                }) rotate(-90)`
            );

        this._yAxisGroup.transition().duration(250).call(this._getYAxisDefinition());

        this._yAxisGroup
            .selectAll(".tick text")
            .transition()
            .duration(250)
            .attr("transform", `translate(${-SPACE_BETWEEN_Y_LABELS_AND_GRID}, 0)`);
    }

    private _getYAxisDefinition(): d3.Axis<d3.NumberValue> {
        return d3
            .axisLeft(this._yScale)
            .tickSize(0)
            .tickPadding(3)
            .tickFormat(item => this._getYAxisLabels(item as number))
            .ticks(coerceNumberProperty(this.yAxisTickCount, 5));
    }

    private _getYAxisLabels(value: number): string {
        return this.showYAxisLabels ? this._numberFormat(value) : "";
    }

    private _addYAxisGrid(): void {
        this._yAxisGridG = this._svgG
            .append("g")
            .attr("class", "lg-histogram-chart__y-axis__grid")
            .attr("transform", `translate(${this._horizontalPositionOfYAxis}, 0)`);

        this._yAxisGridG.transition().duration(250).call(this._getYAxisGrid(this._yScale));
    }

    private _getYAxisGrid(scale: d3.ScaleLinear<number, number>): d3.Axis<d3.NumberValue> {
        return d3
            .axisRight(scale)
            .tickPadding(0)
            .tickFormat(() => "")
            .ticks(coerceNumberProperty(this.yAxisTickCount, 5))
            .tickSizeOuter(0)
            .tickSizeInner(
                this._svgWidth - this._horizontalPositionOfYAxis - (this.margin.right ?? 0)
            );
    }

    private _initializeColorScales(): void {
        const colors = this._colorPalette.getColorsForType(this.colorConfiguration);
        this._groupColors = d3.scaleOrdinal(colors);
    }

    private _createShadowBoxes(): void {
        this._shadowBoxes = this._getFullBarBoxes("lg-histogram-chart__shadow-boxes");
    }

    private _getFullBarBoxes(
        className: string
    ): d3.Selection<SVGRectElement, TChartDatum, SVGGElement, unknown> {
        return this._svgG
            .append("g")
            .attr("class", className)
            .on("mouseleave", (_event: MouseEvent) => this._tooltip.hide())
            .selectAll("rect")
            .data(this._slicedData)
            .enter()
            .append("rect")
            .attr("x", d => this._xScale(d.xValue) ?? 0)
            .attr("y", () => this.margin.top ?? 0)
            .attr("width", this._xScale.bandwidth())
            .attr("height", () => this._verticalPositionOfXAxis - (this.margin.top ?? 0))
            .style("fill", "transparent");
    }

    private _drawData(): void {
        this._drawDistributionBars();
        this._drawReferenceLines();
    }

    private _drawDistributionBars(): void {
        const barColor = this._groupColors("0");

        this._bars = this._svgG
            .append("g")
            .attr("class", "lg-histogram-chart__distribution-bars")
            .selectAll("rect")
            .data(this._slicedData)
            .enter()
            .append("rect")
            .attr("x", d => this._xScale(d.xValue) ?? 0)
            .attr("y", d => this._yScale(d.yValue ?? 0))
            .attr("width", this._xScale.bandwidth())
            .attr("height", d => this._verticalPositionOfXAxis - this._yScale(d.yValue ?? 0))
            .style("fill", barColor);
    }

    private _drawReferenceLines(): void {
        if (this.references.length) {
            this._lines = this._svgG
                .append("g")
                .attr("class", "lg-histogram-chart__reference-lines");

            for (let i = 0; i < this.references.length; i++) {
                const reference = this.references[i];
                this._drawReferenceLine(reference, i);
                this._drawReferenceLinePoints(reference, i);
            }
        }
    }

    private _drawReferenceLine(reference: LgHistogramReference, index: number): void {
        const lineGenerator = d3
            .line<TChartDatum>()
            .x(d => this._xScale(d.xValue) ?? 0)
            .y(d => this._yScale(d[reference.referenceKey] ?? 0))
            .defined(d => d?.[reference.referenceKey] != null)
            .curve(d3.curveMonotoneX);

        const lineG = this._lines
            .append("g")
            .attr("class", `lg-histogram-chart__reference-lines__${reference.referenceKey}`)
            .attr("transform", `translate(${this._xScale.bandwidth() / 2},0)`);

        lineG
            .datum(this._slicedData)
            .append("path")
            .attr("d", lineGenerator)
            .style("fill", "none")
            .style("stroke", reference.colorHex)
            .style("stroke-width", REFERENCE_LINE_STROKE_WIDTH);

        this._referenceLineGroups[index] = lineG;
    }

    private _drawReferenceLinePoints(reference: LgHistogramReference, index: number): void {
        const lineDotsGroups = this._referenceLineGroups[index]
            .selectAll("g")
            .data(this._slicedData)
            .enter()
            .append("g");

        this._drawIsolatedPoints(lineDotsGroups, reference);

        this._lineDots[index] = lineDotsGroups
            .append("circle")
            .attr("r", POINT_RADIUS)
            .attr("stroke-width", 0)
            .attr("stroke", reference.colorHex)
            .attr("fill", "transparent")
            .transition()
            .duration(0)
            .ease(d3.easeCubicOut)
            .attr("cx", d => this._xScale(d.xValue) ?? 0)
            .attr("cy", d => this._yScale(d[reference.referenceKey] ?? Number.MIN_SAFE_INTEGER));
    }

    private _drawIsolatedPoints(
        lineDotsGroups: d3.Selection<SVGGElement, TChartDatum, SVGGElement, unknown>,
        reference: LgHistogramReference
    ): void {
        const isIsolated = (datum: TChartDatum, index: number): boolean => {
            if (datum[reference.referenceKey] == null) {
                return false;
            }
            return (
                (index === 0 || lineDotsGroups.data()[index - 1][reference.referenceKey] == null) &&
                (index === lineDotsGroups.size() - 1 ||
                    lineDotsGroups.data()[index + 1][reference.referenceKey] == null)
            );
        };

        lineDotsGroups
            .filter(isIsolated)
            .append("circle")
            .attr("r", ISOLATED_POINT_RADIUS)
            .attr("stroke-width", 0)
            .attr("stroke", reference.colorHex)
            .attr("fill", reference.colorHex)
            .attr("cx", d => this._xScale(d.xValue) ?? 0)
            .attr("cy", d => this._yScale(d[reference.referenceKey] ?? Number.MIN_SAFE_INTEGER));
    }

    private _createHoverBoxes(): void {
        const self = this;

        this._hoverBoxes = this._getFullBarBoxes("lg-histogram-chart__hover-boxes");

        this._hoverBoxes
            .on("mouseover", function (_event: MouseEvent, d: TChartDatum) {
                const index = self._hoverBoxes.nodes().indexOf(this);
                const hasData =
                    d.yValue != null ||
                    self.references.some(reference => d[reference.referenceKey] != null);
                self.tooltipContext = {
                    current: d,
                    hasData,
                    groupToLegendDefinitionDictionary: self._groupToLegendDefinitionDictionary
                };

                self._handleHoverBoxMouseOver(index);
            })
            .on("mouseout", function (_event: MouseEvent, _d: TChartDatum) {
                const index = self._hoverBoxes.nodes().indexOf(this);
                self._handleHoverBoxMouseOut(index);
            });
    }

    get _svgHeight(): number {
        const legendVisible = this.legendOptions.visible;
        const legendBelow = legendVisible && this.legendOptions.position === "bottom";

        const legendSize = this._getLegendSize();
        const { top = 0, bottom = 0 } = this.margin;
        return this.height - top - bottom - (legendBelow ? legendSize : 0);
    }

    private get _svgWidth(): number {
        const legendVisible = this.legendOptions.visible;
        const legendOnTheRight = legendVisible && this.legendOptions.position === "right";

        const legendSize = this._getLegendSize();
        const { right = 0, left = 0 } = this.margin;
        return this.width - left - right - (legendOnTheRight ? legendSize : 0);
    }

    private get _verticalPositionOfXAxis(): number {
        const isLegendBelow = this.legendOptions.position === "bottom";
        const bottomMargin = isLegendBelow ? 10 : this.margin.bottom ?? 0;
        const spaceForAxisTitle = this.xAxisLabel ? X_AXIS_TITLE_HEIGHT : 0;
        const spaceForLabels = this.showXAxisLabels ? X_AXIS_LABELS_LINE_HEIGHT : 0;
        return this._svgHeight - bottomMargin - spaceForAxisTitle - spaceForLabels;
    }

    private get _verticalPositionOfxAxisLabel(): number {
        const isLegendBelow = this.legendOptions.position === "bottom";
        return (
            (this.margin.top ?? 0) +
            this._svgHeight -
            X_AXIS_LABELS_LINE_HEIGHT -
            (isLegendBelow ? 5 : 0)
        );
    }

    private get _horizontalPositionOfYAxis(): number {
        return (
            (this.yAxisLabel ? Y_AXIS_TITLE_WIDTH : 0) +
            this._spaceForYAxisLabels +
            SPACE_BETWEEN_Y_LABELS_AND_GRID
        );
    }

    private _updateLegend(): void {
        this._legendDefinition = [];

        const legendVisible = this.legendOptions.visible;
        const legendBelow = legendVisible && this.legendOptions.position === "bottom";
        const legendSize = this._getLegendSize();
        const legendOnTheRight = legendVisible && this.legendOptions.position === "right";

        const spaceBelowAxis =
            (this.margin.bottom ?? 0) + (legendBelow ? legendSize : 0) + X_AXIS_LABELS_LINE_HEIGHT;

        this._legendWidth = legendOnTheRight ? legendSize : 0;
        this._legendPaddingBottom = spaceBelowAxis ? spaceBelowAxis : 0;
        this._legendDefinition.push({
            color: this._groupColors("0"),
            name: this.mainLabel,
            opacity: 1
        });
        for (const reference of this.references) {
            this._legendDefinition.push({
                color: reference.colorHex,
                name: reference.name,
                opacity: reference.legendOpacity ?? 0,
                symbol: "line-with-circle"
            });
        }

        const groupToColor: Record<string, LegendItem> = {};
        this._legendDefinition.forEach(def => (groupToColor[def.name] = def));
        this._groupToLegendDefinitionDictionary = groupToColor;
    }

    private _getLegendSize(): number {
        const isLegendBelow = this.legendOptions.position === "bottom";
        const isLegendOnRight = this.legendOptions.position === "right";
        if (!isLegendBelow && !isLegendOnRight) return 0;
        if (isLegendBelow) return SPACE_FOR_LEGEND_BELOW;

        const referenceNames = this.references.map(reference => reference.name);
        return getLegendWidth(this.width, this.legendOptions.widthInPercents ?? 0, [
            this.mainLabel,
            ...referenceNames
        ]);
    }

    /**
     * Tooltip
     */
    private _initializeTooltip(): void {
        this._tooltipPortal = this._getTooltipContent();
        const commonOptions: ID3TooltipOptions = {
            stay: false,
            trapFocus: false,
            position: "top-left",
            tooltipClass: `lg-tooltip lg-tooltip--d3 ${
                this.tooltipClass ? " " + this.tooltipClass : ""
            }`,
            panelClass: "chart-overlay",
            content: this._tooltipPortal,
            delayHide: 150,
            target: this._elementRef
        };
        this._tooltip = this._tooltipService.create({
            ...commonOptions
        });
    }

    private _getTooltipContent(): TemplatePortal<
        IImplicitContext<LgHistogramTooltipContext<TChartDatum>>
    > {
        return new TemplatePortal<IImplicitContext<LgHistogramTooltipContext<TChartDatum>>>(
            this.tooltipTemplate!,
            this._viewContainerRef,
            { $implicit: this.tooltipContext! }
        );
    }

    private _trackMousePosition(): void {
        this._ngZone.runOutsideAngular(() => {
            this._renderer.listen(
                this._elementRef.nativeElement,
                "mousemove",
                (event: MouseEvent) => {
                    this._lastMouseX = event.clientX;
                    this._lastMouseY = event.clientY;
                    this._updateTooltipPosition();
                }
            );
        });
    }

    private _updateTooltipPosition(): void {
        if (this._tooltip && this._tooltip.visible) {
            if (this._lastMouseX && this._lastMouseY)
                this._tooltip.setPositionAt(
                    this._lastMouseX,
                    this._lastMouseY,
                    getRecommendedPosition(
                        { x: this._lastMouseX, y: this._lastMouseY },
                        this._tooltip.getOverlayElement()
                    )
                );
            else this._tooltip.hide();
        }
    }

    private _handleHoverBoxMouseOver(index: number): void {
        this._darkenMainBars(index);
        this._darkenShadowBoxes(index);
        this._highlightReferenceLineDots(index);
        if (!this._tooltip.visible) {
            this._tooltip.show();
        }
        this._updateTooltipPosition();
    }

    private _darkenMainBars(index: number): void {
        const mainBarColor = this._groupColors("0");
        const node = this._bars.nodes();
        const darkenedBarColor = d3.color(mainBarColor)?.darker(0.2).formatHex() ?? mainBarColor;
        d3.select(node[index]).style("fill", darkenedBarColor);
    }

    private _darkenShadowBoxes(index: number): void {
        const hoverNodes = this._shadowBoxes.nodes();
        const shadowBoxColor = SHADOW_BOX_HOVER_COLOR_HEX;
        d3.select(hoverNodes[index]).style("fill", shadowBoxColor);
    }

    private _highlightReferenceLineDots(index: number): void {
        for (const lineDot of this._lineDots) {
            const lineDotNodes = lineDot.nodes();
            const lineDotToHighlight: any = d3.select(lineDotNodes[index]);
            lineDotToHighlight.attr("stroke-width", 2).attr("fill", "white");
            this._highlightedLineDots.push(lineDotToHighlight);
        }
    }

    private _handleHoverBoxMouseOut(index: number): void {
        this._recolorMainBars(index);
        this._recolorShadowBoxes(index);
        this._hideReferenceLineDots();
    }

    private _recolorMainBars(index: number): void {
        const mainBarColor = this._groupColors("0");
        const node = this._bars.nodes();
        d3.select(node[index]).style("fill", mainBarColor);
    }

    private _recolorShadowBoxes(index: number): void {
        const hoverNode = this._shadowBoxes.nodes();
        d3.select(hoverNode[index]).style("fill", "transparent");
    }

    private _hideReferenceLineDots(): void {
        this._highlightedLineDots?.forEach(lineDot => {
            lineDot.attr("stroke-width", 0).attr("fill", "transparent");
        });
        this._highlightedLineDots = [];
    }
}
