import ldRange from "lodash-es/range";
import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    ViewChild
} from "@angular/core";
import * as d3 from "d3";
import { LgSimpleChanges } from "@logex/framework/types";
import { BaseBarChartWithReferenceLineComponent } from "../shared/base-bar-chart-with-reference-line.component";
import { IntervalXLabels, LegendItem, Variant } from "../shared/chart.types";
import { IAreaChartItem, IAreaChartTooltipContext } from "./area-chart.types";

@Component({
    standalone: false,
    selector: "lg-area-chart",
    templateUrl: "./lg-area-chart.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LgAreaChartComponent
    extends BaseBarChartWithReferenceLineComponent<IAreaChartItem[], IAreaChartTooltipContext>
    implements OnChanges, OnInit, OnDestroy
{
    /**
     * Specifies maximum number of ticks on axis.
     *
     * @defaults 10
     */
    @Input() tickCount?: number | null = null;

    /**
     * Specifies chart variant.
     *
     * @type {"regular" | "stacked" | "stacked100"}
     *
     * @default "regular"
     */
    @Input() variant: "regular" | "stacked" | "stacked100" | null = null;

    /**
     * Callback for providing the column name of related data item (required).
     */
    @Input({ required: true }) columnName!: (locals: any) => string;

    /**
     * Callback for providing the group values of related data item (required).
     */
    @Input({ required: true }) groupValues!: (locals: any) => number[];

    /**
     * Specifies group names of the chart (required).
     */
    @Input({ required: true }) groupNames!: string[]; // ( locals: any ) => string[];

    /**
     * Specifies the colors for group boxplots. Colors must be separated by comma starting with @.
     * Color names must be keys in ChartValueTypeDictionary from lg-color-palette.
     *
     * @example `"@input, @benchmark"`.
     */
    @Input() groupColors?: string;

    /**
     * Specifies the colors for group boxplots on hover. Colors must be separated by comma starting with @.
     * Color names must be keys in ChartValueTypeDictionary from lg-color-palette.
     *
     * @example `"@input, @benchmark"`.
     */
    @Input() groupHoverColors?: string;

    /**
     * Specifies the Y-axis title. Defaults to "Y axis title not defined".
     * Can receive empty string to hide Y axis title entirely.
     */
    @Input() yAxisLabel?: string;

    /**
     * Specifies the X-axis title. Defaults to "X axis title not defined".
     * Can receive empty string to hide X axis title entirely.
     */
    @Input() xAxisLabel?: string;

    /**
     * Specifies whether X-axis labels are visible or not. Defaults to true.
     *
     * @default true
     */
    @Input() showXAxisLabels = true;

    /**
     * Specifies whether Y-axis labels are visible or not. Defaults to true.
     *
     * @default true
     */
    @Input() showYAxisLabels = true;

    /**
     * Specifies position to interval X-axis labels.
     *
     * @type {"start" | "end" | "no"}
     *
     * @default "no"
     */
    @Input() intervalXLabelsFrom: "start" | "end" | "no" = "no";

    /**
     * Y-axis offset.
     *
     * @default 0
     */
    @Input() yOffset = 0;

    /**
     * Specifies if legend is shown
     */
    @Input() showLegend = false;

    /**
     * Offset of legend (bottom position)
     */
    @Input() showLegendOffset?: string;

    @ViewChild("chart", { static: true }) private _chartDivRef!: ElementRef;

    _legend: LegendItem[] = [];
    protected _intervalXLabels!: IntervalXLabels;
    protected _variant: Variant = Variant.Regular;
    protected _groupColors = d3.scaleOrdinal(d3.schemeCategory10);
    protected _hoverGroupColors = d3.scaleOrdinal(d3.schemeCategory10);
    protected _columns: string[] = [];
    protected _columnIndices: number[] = [];
    protected _groupNames: null | string[] | (() => string[]) = null;
    protected _yMax = 0;
    protected _oldYOffset: number | null = null;
    protected _area!: d3.Area<IAreaChartItem>;

    protected _anchor!: d3.Selection<any, any, any, any>;
    protected _anchorColumn!: d3.Selection<any, any, any, any>;
    protected _oldAreaGroups = 0;
    protected _oldAreaValues = 0;

    protected _xAxis!: d3.Axis<any>;
    protected _yScale!: d3.ScaleLinear<number, number>;
    protected _yAxis!: d3.Axis<any>;
    protected _yAxis2!: d3.Axis<any>;
    protected _yAxisG!: d3.Selection<any, any, any, any>;
    protected _yAxisG2!: d3.Selection<any, any, any, any>;
    protected _yAxisLabelG!: d3.Selection<any, any, any, any>;

    protected _xScale!: d3.ScalePoint<number>;
    protected _xAxisG!: d3.Selection<any, any, any, any>;
    protected _xAxisLabelsG!: d3.Selection<any, any, any, any>;

    ngOnInit(): void {
        super._onInit();

        this._defaultProps();
        this._propsToState();
        this._convertData();
        this._drawMainSvgHolder(this._chartDivRef.nativeElement);
        this._create();
        this._updateSize();
        this._render(true);
        this._initializeTooltip();

        this._initialized = true;
    }

    ngOnChanges(changes: LgSimpleChanges<LgAreaChartComponent>): void {
        if (!this._initialized) {
            return;
        }

        super._onBaseChartChanges(changes);

        let needsRender = false;
        let renderImmediate = false;
        let renderAsTransition = false;

        if (changes.data || changes.columnName) {
            needsRender = true;
            renderAsTransition = true;
            this._convertData();
        }

        if (changes.width || changes.height) {
            needsRender = true;
            renderImmediate = true;
            this._updateSize();
        }

        if (changes.yOffset || changes.showXAxisLabels || changes.showYAxisLabels) {
            needsRender = true;
        }

        if (changes.intervalXLabelsFrom) {
            this._intervalXLabels = this._parseIntervalXLabels(
                this.intervalXLabelsFrom.toLowerCase()
            );
            needsRender = true;
        }

        if (changes.variant) {
            const val = (this.variant || "regular").toLowerCase();

            this._variant = this._parseVariant(val);
        }

        if (this._yAxis && (changes.formatterType || changes.formatterOptions)) {
            this._yAxis.tickFormat(this._numberFormat);
            needsRender = true;
        }

        if (changes.groupColors && this.groupColors) {
            this._groupColors.range(this.groupColors.split(","));
            needsRender = true;
        }

        if (changes.groupHoverColors && this.groupHoverColors) {
            this._hoverGroupColors.range(this.groupHoverColors.split(","));
        }

        if (changes.yAxisLabel && this._chart) {
            this._yAxisLabelG.text(this.yAxisLabel ?? null);
        }

        if (changes.tickCount) {
            if (this._yAxis) {
                this._yAxis.ticks(this.tickCount);
                this._yAxis2.ticks(this.tickCount);
                needsRender = true;
            }
        }

        if (changes.referenceLine && this._chart) {
            needsRender = true;
        }

        if (needsRender) {
            if (renderAsTransition) {
                d3.transition()
                    .duration(500)
                    .each(() => this._render(false));
            } else {
                this._render(renderImmediate);
            }
        }
    }

    ngOnDestroy(): void {
        super._onDestroy();
    }

    protected _defaultProps(): void {
        this.margins = this.margins || "20";
        this.yOffset = this.yOffset || 0;
        this.showXAxisLabels = this.showXAxisLabels || true;
        this.showYAxisLabels = this.showYAxisLabels || true;
    }

    protected _propsToState(): void {
        this._variant = this._parseVariant(this.variant);
        this._intervalXLabels = this._parseIntervalXLabels(this.intervalXLabelsFrom);
        this._sizePropsToState();
    }

    protected _sizePropsToState(): void {
        this._margin = this._getMargin(this.margins.split(","));
        this._width = Math.max(0, +this.width - this._margin.left! - this._margin.right!);
        this._height = Math.max(0, +this.height - this._margin.top! - this._margin.bottom!);
    }

    protected _updateSize(): void {
        this._svgG.attr("transform", `translate( ${this._margin!.left} , ${this._margin!.top} )`);
        this._svg.attr("width", this.width);
        this._svg.attr("height", this.height);
        this._xScale.range([0, this._width]);
        this._yScale.range([this._height, 0]);
        this._xAxisG.attr("transform", "translate(0," + this._height + ")");
        this._yAxisLabelG.attr("y", -this._margin!.left! + 10);
        this._yAxisLabelG.attr("x", -this._height / 2);
    }

    protected _render(immediate: boolean): void {
        const getColor = (
            _column: string,
            group: string,
            _columnIndex: number,
            _groupIndex: number,
            hover: boolean
        ): any => {
            if (hover) {
                if (this.groupHoverColors) {
                    return this._hoverGroupColors(group);
                }
                return d3.rgb(this._groupColors(group)).darker(0.2);
            } else {
                return this._groupColors(group);
            }
        };

        if (!this.data || !this.data.length || !this.height) {
            return;
        }

        // let oldYScale = this._yScale.copy();
        this._yScale.domain([this.yOffset, this._yMax]).nice();

        // if (immediate) {
        //     oldYScale = this._yScale.copy();
        // }
        if (immediate || this._oldYOffset == null) {
            this._oldYOffset = this.yOffset;
        }

        this._xScale.domain(this._columnIndices);
        this._yAxis2.tickSize(this._width).tickSizeOuter(0);
        if (typeof this._groupNames === "function") {
            this._groupColors.domain(this._groupNames());
            this._hoverGroupColors.domain(this._groupNames());
        } else {
            this._groupColors.domain(this._groupNames!);
            this._hoverGroupColors.domain(this._groupNames!);
        }
        this._xAxis.tickSize(this._intervalXLabels === IntervalXLabels.No ? 0 : 2);

        if (immediate) {
            this._xAxisG.call(this._xAxis);
            this._xAxisG.attr("transform", `translate( 0, ${this._yScale(this.yOffset)} )`);
            this._yAxisG.call(this._yAxis);
            this._yAxisG2.call(this._yAxis2);
        } else {
            this._xAxisG.transition().call(this._xAxis);
            this._xAxisG
                .transition()
                .attr("transform", `translate( 0, ${this._yScale(this.yOffset)} )`);
            this._yAxisG.transition().call(this._yAxis);
            this._yAxisG2.transition().call(this._yAxis2);
        }

        if (
            this._oldAreaGroups !== this._data.length ||
            this._oldAreaValues !== this._data[0].length
        ) {
            this._chart.selectAll(".group").remove();
            this._oldAreaGroups = this._data.length;
            this._oldAreaValues = this._data[0].length;
        }

        const groups = this._chart
            .selectAll<SVGPathElement, IAreaChartItem[]>(".group")
            .data(this._data, (d: IAreaChartItem[]) => d[0].group);

        groups.exit().transition().style("opacity", 0).remove();

        const groupPaths = groups.enter().append("path");

        groupPaths
            .attr("class", "group")
            .style("cursor", () => (this.clickable ? "pointer" : "default"))
            .style("fill", d => this._groupColors(d[0].group))
            .merge(groups)
            .on("mouseover", function (_event: MouseEvent, _d: IAreaChartItem[]) {
                const index = groupPaths.nodes().indexOf(this);
                d3.select(this)
                    .selectAll("rect")
                    .style("fill", (dFill: any, gi: number) =>
                        getColor(dFill.column, dFill.group, index, gi, true)
                    );
            })
            .on("mouseout", function (_event: MouseEvent, _d: IAreaChartItem[]) {
                const index = groupPaths.nodes().indexOf(this);
                d3.select(this)
                    .selectAll("rect")
                    .style("fill", (dFill: any, gi: number) =>
                        getColor(dFill.column, dFill.group, index, gi, false)
                    );
            })
            .attr("d", d => this._area(d))
            .style("fill", d => this._groupColors(d[0].group));

        let xLabelOffset = 0;
        let xLabelIgnore = -1;
        if (this._intervalXLabels === IntervalXLabels.End) {
            xLabelOffset =
                -(
                    this._xScale(this._data[0][1].valueIndex)! -
                    this._xScale(this._data[0][0].valueIndex)!
                ) / 2;
            xLabelIgnore = 0;
        } else if (this._intervalXLabels === IntervalXLabels.Start) {
            xLabelOffset =
                +(
                    this._xScale(this._data[0][1].valueIndex)! -
                    this._xScale(this._data[0][0].valueIndex)!
                ) / 2;
            xLabelIgnore = this._data[0].length - 1;
        }

        const xAxisLabels = this._xAxisLabelsG
            .selectAll<SVGTextElement, IAreaChartItem>("text")
            .data(this._data[0], (d: IAreaChartItem) => "" + d.valueIndex)
            .style("opacity", this.showXAxisLabels ? 0 : 1);

        xAxisLabels.exit().transition().style("opacity", 0).remove();

        xAxisLabels
            .enter()
            .append("text")
            .attr("class", "label")
            .attr("text-anchor", "middle")
            .attr("x", d => this._xScale(d.valueIndex)! + xLabelOffset)
            .attr("dy", "0.71em")
            .style("opacity", 0)
            .text((d, i) => (i === xLabelIgnore ? "" : d.column))
            .merge(xAxisLabels)
            .attr("x", (d: IAreaChartItem) => this._xScale(d.valueIndex)! + xLabelOffset)
            .attr("y", 6)
            .style("opacity", 1)
            .text((d: IAreaChartItem, i) => (i === xLabelIgnore ? "" : d.column));

        this._xAxisLabelsG.style("opacity", this.showXAxisLabels ? 0 : 1);
        this._yAxisLabelG.style("opacity", this.showYAxisLabels ? 0 : 1);

        this._renderReferenceLine(immediate);

        this._oldYOffset = this.yOffset;
    }

    protected _create(): void {
        this._xScale = d3.scalePoint<number>().range([0, this._width]);
        this._yScale = d3.scaleLinear().range([this._height, 0]);

        this._yAxisG2 = this._svgG.append("g").attr("class", "y axis grid");

        this._createReferenceLine();

        this._chart = this._svgG.append("g");

        this._yAxis2 = d3
            .axisRight<any>(this._yScale)
            .tickPadding(0)
            .tickFormat(() => "")
            .ticks(this.tickCount || 10);

        this._yAxis = d3
            .axisLeft<any>(this._yScale)
            .tickSize(5)
            .tickSizeOuter(0)
            .tickPadding(2)
            .tickFormat(this._numberFormat)
            .ticks(this.tickCount || 10);

        this._xAxis = d3
            .axisBottom<any>(this._xScale)
            .tickSize(0)
            .tickSizeOuter(0)
            .tickPadding(6)
            .tickFormat(() => "");

        this._yAxisG = this._svgG.append("g").attr("class", "y axis");

        this._xAxisG = this._svgG
            .append("g")
            .attr("class", "x axis")
            .attr("transform", "translate(0," + this._height + ")");

        this._yAxisLabelG = this._svgG
            .append("text")
            .attr("class", "y axis-label")
            .text(this.yAxisLabel ?? "")
            .attr("transform", "rotate(-90)")
            .attr("y", -this._margin!.left! + 10)
            .attr("x", -this._height / 2)
            .attr("text-anchor", "middle");

        this._xAxisLabelsG = this._svgG
            .append("text")
            .text(this.xAxisLabel ?? "")
            .attr("class", "x axis")
            .attr("y", -this._margin!.left! + 40)
            .attr("x", this._margin!.right! - 10)
            .attr("transform", "translate(0," + this._height + ")");

        this._anchorColumn = this._svgG
            .append("polygon")
            .attr("class", "anchor-column")
            .style("opacity", 0);
        this._anchor = this._svgG
            .append("circle")
            .attr("class", "anchor")
            .attr("r", 5)
            .style("opacity", 0);

        this._svg
            .on("mousemove", (event: MouseEvent) => this._tooltipCheck(event))
            .on("mouseleave", (_event: MouseEvent) => this._tooltipHide());

        this._area = d3
            .area<IAreaChartItem>()
            .x(d => this._xScale(d.valueIndex)!)
            .y0(d => this._yScale(d.chartValue0))
            .y1(d => this._yScale(d.chartValue));
    }

    protected _tooltipCheck(event: MouseEvent): void {
        const tolerance = 20;

        const coords = d3.pointer(event);

        const max = this._xScale.range()[1];
        const step = this._xScale.step();
        const range = ldRange(0, max + 1, step);
        const halfWidth: number = (range.length > 1 ? range[1] - range[0] : range[0]) / 2;

        // For regular intervals, offset the X coordinate because we want to find the closest fitting tick; not interval that this falls in
        // For interval-based x asix, do not offset
        const mouseX = coords[0];
        const mouseXOffset =
            this._intervalXLabels === IntervalXLabels.No ? mouseX + halfWidth : mouseX;
        const mouseY = coords[1];
        // Get the interal index (1-indexed)
        let xIndex = d3.bisect(range, mouseXOffset);
        let visible = false;

        if (xIndex !== undefined) {
            let within: boolean;
            if (this._intervalXLabels === IntervalXLabels.No) {
                within = Math.abs(mouseX - range[xIndex - 1]) < tolerance;
            } else {
                // For intervals, just check we're within the chart
                within = mouseX >= 0 && mouseX <= range[range.length - 1];
                xIndex = Math.min(xIndex, this._data[0].length);
            }
            if (within) {
                // find the closest line
                let closest: IAreaChartItem[] | null = null;
                let closestDistance = null;
                // For stacked variants, consider only the last group
                if (this._variant === Variant.Regular) {
                    for (const group of this._data) {
                        const distance = Math.abs(
                            mouseY - this._yScale(group[xIndex - 1].chartValue)
                        );
                        if (
                            distance < tolerance &&
                            (closestDistance == null || distance < closestDistance)
                        ) {
                            closest = group;
                            closestDistance = distance;
                        }
                    }
                } else {
                    // for stacked variants, just make sure we're in the column
                    const lastGroup = this._data[this._data.length - 1];
                    // Remember the Y coordinates are flipped
                    if (
                        this._intervalXLabels === IntervalXLabels.No ||
                        xIndex === lastGroup.length
                    ) {
                        if (
                            mouseY - this._yScale(0) < tolerance &&
                            this._yScale(lastGroup[xIndex - 1].chartValue) - mouseY < tolerance
                        ) {
                            closest = lastGroup;
                        }
                    } else if (
                        mouseY - this._yScale(0) < tolerance &&
                        this._yScale(
                            Math.max(lastGroup[xIndex - 1].chartValue, lastGroup[xIndex].chartValue)
                        ) -
                            mouseY <
                            tolerance
                    ) {
                        closest = lastGroup;
                    }
                }

                if (closest && closest[xIndex - 1]) {
                    visible = true;
                    const item = closest[xIndex - 1];
                    if (this._intervalXLabels === IntervalXLabels.No || closest.length === 1) {
                        this._anchor.style("opacity", 1).attr("cx", () => range[xIndex - 1]);
                        this._anchor
                            .style("opacity", 1)
                            .attr("cy", () => this._yScale(item.chartValue));
                    } else {
                        this._anchor
                            .style("opacity", 0)
                            .attr("cx", () => (range[xIndex - 1] + range[xIndex]) / 2);
                        this._anchor
                            .style("opacity", 0)
                            .attr(
                                "cy",
                                () =>
                                    (this._yScale(item.chartValue) +
                                        this._yScale(closest![xIndex].chartValue)) /
                                    2
                            );

                        const bottom = this._yScale(0);
                        const polygon = `${range[xIndex - 1]},${this._yScale(item.chartValue)} ${
                            range[xIndex]
                        },${this._yScale(closest[xIndex].chartValue)} ${range[xIndex]},${bottom} ${
                            range[xIndex - 1]
                        },${bottom}`;
                        this._anchorColumn.style("opacity", 1).attr("points", polygon);
                    }
                    if (!this.tooltipContext || this.tooltipContext.dataStart !== item) {
                        const group = this._data[item.groupIndex];
                        let data: IAreaChartItem;
                        let dataStart: IAreaChartItem | undefined;
                        let dataEnd: IAreaChartItem | undefined;

                        if (this._intervalXLabels === IntervalXLabels.No) {
                            data = item;
                        } else {
                            data =
                                this._intervalXLabels === IntervalXLabels.Start || group.length < 2
                                    ? item
                                    : this._data[item.groupIndex][item.valueIndex + 1];
                            dataStart = item;
                            dataEnd = group.length < 2 ? item : group[item.valueIndex + 1];
                        }

                        this.tooltipContext = { data, group, dataStart, dataEnd };

                        if (!this._tooltip?.visible) {
                            this._tooltip?.show({ target: this._anchor.node() });
                        } else {
                            this._tooltip?.reposition();
                        }
                    }
                }
            }
        }

        if (!visible) {
            this._tooltipHide();
        }
    }

    protected _tooltipHide(): void {
        if (this._tooltip && this._tooltip.visible) {
            this._tooltip.hide();
        }
        this._anchor.style("opacity", 0);
        this._anchorColumn.style("opacity", 0);
    }

    protected _convertData(): void {
        if (!this.data) {
            return;
        }

        this._columns = [];
        this._columnIndices = [];
        this._groupNames = null;

        const dataSource: IAreaChartItem[][] = [];

        this.data.forEach(value => {
            const columnName = this.columnName(value);
            this._columns.push(columnName);
            this._columnIndices.push(dataSource.length);
            if (this._groupNames == null) {
                this._groupNames = this.groupNames; // this.groupNames( { item: value } );
            }

            const values = this.groupValues(value);
            if (!values) {
                return;
            }

            const row: IAreaChartItem[] = [];
            for (let i = 0, l = values.length; i < l; ++i) {
                row.push({
                    column: columnName,
                    group: this._groupNames[i as keyof typeof this._groupNames],
                    value: values[i],
                    chartValue: Math.max(0, values[i]),
                    chartValue0: 0,
                    item: value,
                    valueIndex: dataSource.length,
                    groupIndex: i
                });
            }
            dataSource.push(row);
        });

        // now swap the order and implement stacking
        this._data = [];
        this._yMax = 0;
        const stacking = this._variant === Variant.Stacked || this._variant === Variant.Stacked100;

        const colMax: number[] = [];
        for (let j = 0; j < dataSource.length; ++j) {
            const groups = dataSource[j];
            colMax[j] = 0;
            for (let i = 0; i < groups.length; ++i) {
                const groupValue = groups[i];
                let store: IAreaChartItem[];
                if (j === 0) {
                    store = [];
                    this._data.push(store);
                } else {
                    store = this._data[i];
                }
                if (stacking && i > 0) {
                    groupValue.chartValue0 = this._data[i - 1][j].chartValue;
                    groupValue.chartValue += groupValue.chartValue0;
                }
                this._yMax = Math.max(this._yMax, groupValue.chartValue);
                colMax[j] = Math.max(colMax[j], groupValue.chartValue);
                store.push(groupValue);
            }
        }

        // if stacked100, normalize the values
        if (this._variant === Variant.Stacked100) {
            for (const group of this._data) {
                for (let i = 0; i < group.length; ++i) {
                    if (colMax[i]) {
                        const value = group[i];
                        value.chartValue0 = value.chartValue0 / colMax[i];
                        value.chartValue = value.chartValue / colMax[i];
                    }
                }
            }
            this._yMax = 1;
        }
    }

    private _parseIntervalXLabels(val: string): IntervalXLabels {
        switch (val) {
            case "start":
                return IntervalXLabels.Start;
            case "end":
                return IntervalXLabels.End;
            default:
                return IntervalXLabels.No;
        }
    }

    private _parseVariant(val: string | null): Variant {
        switch (val) {
            case "stacked":
                return Variant.Stacked;
            case "stacked100":
                return Variant.Stacked100;
            case "regular":
            default:
                return Variant.Regular;
        }
    }
}
