<ng-container *ngIf="!_showAbove">
    <ng-container *ngTemplateOutlet="inputTemplate"></ng-container>
</ng-container>

<div class="lg-calendar-tooltip__wrapper">
    <span class="lg-calendar-tooltip__wrapper__arrow-holder"></span>
    <div class="lg-calendar-tooltip__wrapper__header">
        <div class="lg-calendar-tooltip__wrapper__header__dropdowns">
            <lg-dropdown
                className="lg-reference-dropdown lg-reference-dropdown--header"
                popupClassName="lg-dropdown-popup lg-dropdown-popup--minimal-input"
                [current]="_currentMonth"
                [definition]="_monthDropdown"
                [matchWidth]="false"
                [hideSearch]="false"
                [hideArrow]="true"
                (activeChange)="_dropdownActive($event)"
                (currentChange)="_onMonthChange($event)"
            >
            </lg-dropdown>
            <lg-dropdown
                className="lg-reference-dropdown lg-reference-dropdown--header"
                popupClassName="lg-dropdown-popup lg-dropdown-popup--minimal-input"
                [current]="_currentYear"
                [definition]="_yearDropdown"
                [matchWidth]="false"
                [hideSearch]="false"
                [hideArrow]="true"
                (activeChange)="_dropdownActive($event)"
                (currentChange)="_onYearChange($event)"
            >
            </lg-dropdown>
        </div>

        <span
            class="lg-icon-menu lg-calendar-tooltip__wrapper__header__arrow lg-calendar-tooltip__wrapper__header__arrow--left"
            (click)="_prevMonth()"
            [ngClass]="{ 'lg-icon-menu--disabled': !_canGoLeft }"
        >
            <lg-icon icon="icon-arrow-down"></lg-icon>
        </span>

        <span
            class="lg-icon-menu lg-calendar-tooltip__wrapper__header__arrow lg-calendar-tooltip__wrapper__header__arrow--right"
            (click)="_nextMonth()"
            [ngClass]="{ 'lg-icon-menu--disabled': !_canGoRight }"
        >
            <lg-icon icon="icon-arrow-down"></lg-icon>
        </span>

        <lg-quick-settings-menu
            [definition]="_navigationMenu"
            [compact]="true"
            [onRight]="true"
            icon="icon-go-to-date"
        ></lg-quick-settings-menu>
    </div>

    <div class="lg-calendar-tooltip__wrapper__day-row">
        <div
            *ngFor="let day of _dayNamesInLocalizedOrder"
            class="lg-calendar-tooltip__wrapper__day-row__day"
        >
            {{ day | capitalize }}
        </div>
    </div>

    <div class="lg-calendar-tooltip__wrapper__body">
        <div
            [ngClass]="_holderClassName"
            [@move]="{ value: _transition, params: { height: _monthRows.length * 25 } }"
            (@move.done)="_moveDone()"
        >
            <div
                class="lg-calendar-tooltip__wrapper__body__holder__row"
                *ngFor="let row of _monthRows; let i = index"
            >
                <div
                    *ngFor="let day of row"
                    class="lg-calendar-tooltip__wrapper__body__holder__row__day"
                    (click)="_onDayClick(day)"
                >
                    <span
                        class="lg-calendar-tooltip__wrapper__body__holder__row__day__state"
                        [ngClass]="{
                            'lg-calendar-tooltip__wrapper__body__holder__row__day__state--disabled':
                                day.disabled,
                            'lg-calendar-tooltip__wrapper__body__holder__row__day__state--selected':
                                _isDateSelected(day.date),
                            'lg-calendar-tooltip__wrapper__body__holder__row__day__state--today':
                                day.today,
                            'lg-calendar-tooltip__wrapper__body__holder__row__day__state--other':
                                day.other
                        }"
                    >
                        {{ day.day }}
                    </span>
                </div>
            </div>
        </div>
    </div>

    <div class="lg-calendar-tooltip__wrapper__button-row">
        <div
            class="lg-calendar-tooltip__wrapper__button-row__button-group"
            *ngIf="!_props.required"
        >
            <button
                type="button"
                class="button button--condensed button--blue lg-calendar-tooltip__wrapper__button-row__button"
                [disabled]="!_selectedDate"
                (click)="_clearSelection()"
            >
                {{ "FW._Directives.Calendar_Button_clear" | lgTranslate }}
            </button>
        </div>
        <div
            class="lg-calendar-tooltip__wrapper__button-row__button-group lg-calendar-tooltip__wrapper__button-row__button-group--second"
        >
            <button
                type="button"
                class="button button--condensed button--blue lg-calendar-tooltip__wrapper__button-row__button"
                [class.lg-calendar-tooltip__wrapper__button-row__button--disabled]="
                    !_canSelectToday
                "
                [lgSimpleTooltip]="_canSelectToday ? '=' : '.Calendar_Button_today_outside'"
                (click)="_todayClick($event)"
                [attr.tabindex]="_canSelectToday ? 0 : -1"
            >
                {{ "FW._Directives.Calendar_Button_today" | lgTranslate }}
            </button>
        </div>
    </div>
</div>

<ng-container *ngIf="_showAbove">
    <ng-container *ngTemplateOutlet="inputTemplate"></ng-container>
</ng-container>

<ng-template #inputTemplate>
    <div
        tabindex="0"
        class="lg-calendar-tooltip__input"
        [class.lg-calendar-tooltip__input--condensed]="_condensed"
        [lgCopyHandler]="_copyHandler"
        [lgPasteHandler]="_pasteHandler"
    >
        <div class="center-things">
            <ng-container *ngFor="let element of _elements; let index = index"
                ><!--
            --><ng-container
                    *ngTemplateOutlet="
                        element.template;
                        context: {
                            $implicit: element,
                            index: index
                        }
                    "
                ></ng-container
                >{{ element.separator
                }}<!--
            --></ng-container>
        </div>
    </div>
</ng-template>
<ng-template #dayTemplate let-element let-index="index">
    <input
        [lgDefaultFocus]="element.preselect"
        #dayInput
        class="lg-calendar-tooltip__input__part lg-calendar-tooltip__input__part--day"
        [class.lg-calendar-tooltip__input__part--active]="
            _requiredPosition === index || _focusedElement === 'day' || _focusedElement === null
        "
        [disabled]="_isDisabled(index)"
        [placeholder]="element.placeholder || ('.Calendar_placeholder_day' | lgTranslate)"
        type="number"
        min="1"
        max="31"
        [value]="element.value"
        (keyup)="_onInputKeyUp($event, index, element)"
        (input)="_onDayInput($event, index)"
        (focus)="_onFocus(CalendarInputPart.Day, index)"
        (click)="_onInputClick($event)"
    />
</ng-template>
<ng-template #monthTemplate let-element let-index="index">
    <input
        [lgDefaultFocus]="element.preselect"
        #monthInput
        class="lg-calendar-tooltip__input__part lg-calendar-tooltip__input__part--month"
        [class.lg-calendar-tooltip__input__part--active]="
            _requiredPosition === index || _focusedElement === 'month'
        "
        [class.lg-calendar-tooltip__input__part--month--empty]="
            !element.placeholder && !element.value
        "
        [disabled]="_isDisabled(index)"
        type="number"
        min="1"
        max="12"
        [placeholder]="element.placeholder || ('.Calendar_placeholder_month' | lgTranslate)"
        [value]="element.value"
        (keyup)="_onInputKeyUp($event, index, element)"
        (input)="_onMonthInput($event, index)"
        (focus)="_onFocus(CalendarInputPart.Month, index)"
        (click)="_onInputClick($event)"
    />
</ng-template>
<ng-template #yearTemplate let-element let-index="index">
    <input
        [lgDefaultFocus]="element.preselect"
        #yearInput
        class="lg-calendar-tooltip__input__part lg-calendar-tooltip__input__part--year"
        [class.lg-calendar-tooltip__input__part--active]="
            _requiredPosition === index || _focusedElement === 'year'
        "
        [disabled]="_isDisabled(index)"
        type="number"
        [placeholder]="element.placeholder || ('.Calendar_placeholder_year' | lgTranslate)"
        [value]="element.value"
        (keyup)="_onInputKeyUp($event, index, element)"
        (input)="_onYearInput($event, index)"
        (focus)="_onFocus(CalendarInputPart.Year, index)"
        (click)="_onInputClick($event)"
    />
</ng-template>
