<div class="lg-panel-resize-menu-popup__icon-wrapper">
    <div
        *ngFor="let item of _items"
        class="lg-panel-resize-menu-popup__icon-wrapper__icon"
        [title]="item.tooltipLc | lgTranslate"
        (click)="_onItemClick(item)"
    >
        <lg-icon [icon]="item.iconName"></lg-icon>
    </div>
</div>
