<div class="lg-sankey-chart" #root>
    <div class="lg-sankey-chart__wrapper">
        <div class="lg-sankey-chart__wrapper__titles" *ngIf="showColumnTitle">
            <div *ngFor="let column of _columns" class="lg-sankey-chart__column-title">
                <div
                    class="lg-sankey-chart-column-title__title"
                    [style.height]="columnTitleHeight + 'px'"
                >
                    <div *ngIf="columnTitleTemplate">
                        <ng-container
                            *ngTemplateOutlet="
                                columnTitleTemplate;
                                context: { $implicit: column.column }
                            "
                        ></ng-container>
                    </div>
                    <div *ngIf="getColumnText">
                        {{ getColumnText(column.column) }}
                    </div>
                    <div *ngIf="!getColumnText && !columnTitleTemplate">
                        No callback or template available.
                    </div>
                </div>
            </div>
        </div>

        <svg class="lg-sankey-chart__wrapper__svg"></svg>
        <div class="lg-sankey-chart__wrapper__nodes">
            <div *ngFor="let column of _columns" class="lg-sankey-chart__wrapper__nodes__column">
                <div class="lg-sankey-chart-column__body">
                    <div
                        lgScrollable
                        [lgScrollableAutoHide]="true"
                        [lgScrollableWrapper]="false"
                        (scroll)="_onScrollEnd()"
                    >
                        <div
                            class="lg-sankey-chart-node"
                            *ngFor="let node of column.nodes"
                            [id]="node.id"
                            [class.lg-sankey-chart-node--clickable]="
                                node.dataNode.clickable && clickable
                            "
                            [style.height.px]="fixedNodeHeight ? nodeHeight : null"
                            (click)="_onNodeClick(node)"
                        >
                            <div class="lg-sankey-chart-node__indicator">
                                <div class="lg-sankey-chart-node__indicator__left">
                                    <div
                                        *ngIf="!hasVariableFlow"
                                        class="lg-sankey-chart-node__indicator__left__default"
                                        [style.height.px]="node.value"
                                    ></div>
                                    <div *ngIf="hasVariableFlow">
                                        <div
                                            class="lg-sankey-chart-node__indicator__left__positive"
                                            [style.height.px]="node.valueLeftPositive"
                                        ></div>
                                        <div
                                            class="lg-sankey-chart-node__indicator__left__negative"
                                            [style.height.px]="node.valueLeftNegative"
                                        ></div>
                                        <div
                                            class="lg-sankey-chart-node__indicator__left__remaining"
                                            [style.height.px]="node.value - node.valueLeft"
                                        ></div>
                                    </div>
                                </div>
                            </div>
                            <div class="lg-sankey-chart-node__content">
                                <div
                                    class="lg-sankey-chart-node__content__flow"
                                    [style.height.px]="node.value"
                                >
                                    <div
                                        *ngIf="nodeTemplate"
                                        class="lg-sankey-chart-node__content__flow__text"
                                    >
                                        <ng-container
                                            *ngTemplateOutlet="
                                                nodeTemplate;
                                                context: { $implicit: node.dataNode }
                                            "
                                        ></ng-container>
                                    </div>
                                    <div
                                        *ngIf="getNodeText"
                                        class="lg-sankey-chart-node__content__flow__text"
                                    >
                                        {{ getNodeText(node) }}
                                    </div>
                                    <div
                                        *ngIf="!nodeTemplate && !getNodeText"
                                        class="lg-sankey-chart-node__content__flow__text"
                                    >
                                        No callback or template available.
                                    </div>
                                </div>
                            </div>
                            <div class="lg-sankey-chart-node__indicator">
                                <div class="lg-sankey-chart-node__indicator__right">
                                    <div
                                        *ngIf="!hasVariableFlow"
                                        class="lg-sankey-chart-node__indicator__right__default"
                                        [style.height.px]="node.valueRight"
                                    ></div>
                                    <div
                                        class="lg-sankey-chart-node__indicator__right__positive"
                                        *ngIf="hasVariableFlow"
                                        [style.height.px]="node.valueRightPositive"
                                    ></div>
                                    <div
                                        class="lg-sankey-chart-node__indicator__right__negative"
                                        *ngIf="hasVariableFlow"
                                        [style.height.px]="-node.valueRightNegative"
                                    ></div>
                                    <div
                                        class="lg-sankey-chart-node__indicator__right__remaining"
                                        *ngIf="hasVariableFlow"
                                        [style.height.px]="node.value - node.valueRight"
                                    ></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
