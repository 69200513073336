import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    forwardRef,
    inject,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { DatePipe, NgIf } from "@angular/common";
import { FlexibleConnectedPositionStrategy, ScrollDispatcher } from "@angular/cdk/overlay";
import { ENTER } from "@angular/cdk/keycodes";
import { ComponentPortal } from "@angular/cdk/portal";

import { LgSimpleChanges } from "@logex/framework/types";
import {
    LgDate,
    LgTranslatePipe,
    LgTranslateService,
    useTranslationNamespace
} from "@logex/framework/lg-localization";
import { IOverlayResultApi, LgOverlayService } from "../../lg-overlay/lg-overlay.service";
import { LgPromptDialog } from "../lg-prompt-dialog/lg-prompt-dialog.component";
import { ValueAccessorBase } from "../inputs/value-accessor-base";
import { LgTime } from "./lg-time-picker.types";
import {
    ILgTimePickerTooltipComponentProps,
    LgTimePickerPopupComponent
} from "./lg-time-picker-popup.component";
import { showInvalidTimeDialog } from "./showInvalidTimeDialog";
import { LgCopyHandlerDirective, LgPasteHandlerDirective } from "../../behavior";

@Component({
    standalone: true,
    selector: "lg-time-picker",
    templateUrl: "./lg-time-picker.component.html",
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => LgTimePickerComponent),
            multi: true
        }
    ],
    viewProviders: [useTranslationNamespace("FW._Directives._LgTimePicker")],
    changeDetection: ChangeDetectionStrategy.Default,
    encapsulation: ViewEncapsulation.None,
    imports: [LgCopyHandlerDirective, LgPasteHandlerDirective, LgTranslatePipe, NgIf],
    host: { class: "lg-time-picker", "[class.lg-time-picker--disabled]": "disabled" }
})
export class LgTimePickerComponent
    extends ValueAccessorBase<LgTime>
    implements OnChanges, OnInit, OnDestroy
{
    private _promptDialog = inject(LgPromptDialog);
    private _datePipe = inject(DatePipe);
    private _lgTranslate = inject(LgTranslateService);
    private _overlayService = inject(LgOverlayService);
    private _scrollDispatcher = inject(ScrollDispatcher);

    @Input() disabled = false;

    /**
     * Specifies if value is required.
     */
    @Input() required = false;

    /**
     * Minimum time allowed.
     */
    @Input() minTime?: LgTime | null;

    /**
     * Minimum time allowed.
     */
    @Input() maxTime?: LgTime | null;

    /**
     * Default time value.
     */
    @Input() defaultTime?: LgTime | null;

    /**
     * Reduces the size of the time input field if `true`.
     */
    @Input() condensed = false;

    /**
     * Time format.
     */
    @Input() timeFormat = "HH:mm";

    @Input() tabBackCancels = false;

    /**
     * Emit selected time value.
     */
    @Output() readonly postSelect = new EventEmitter<LgTime>();

    @Input() _postSelectCallback:
        | ((time: LgTime | null, cancelled: boolean, navigationDelta: -1 | 0 | 1 | null) => void)
        | null = null;

    @ViewChild("inputDiv", { static: true }) _inputDiv!: ElementRef<HTMLElement>;

    _hoursSeparator = "";
    _minutesSeparator = "";
    _24h = true;
    _hours: string | null = null;
    _minutes: string | null = null;
    _period: string | null = null;

    readonly _copyHandler = (): string | null => this._getCopyData(this.value);
    readonly _pasteHandler = (text: string): boolean => this._pasteData(text) ?? true;
    // This could be exposed properly through special injection symbol, or input for callbacks. But it's unlikely to be used
    // by anything else than the calendar+time integration, so let's not confuse the api
    _getCopyDataOverride: null | ((currentTime: LgTime | null) => string | null) = null;
    _pasteDataOverride: null | ((text: string, pending: boolean) => boolean) = null;

    private _hoursFormat!: string;
    private _minutesFormat!: string;
    private _overlayInstance!: IOverlayResultApi;
    private _popupInstance: LgTimePickerPopupComponent | undefined | null;
    private _positionStrategy: FlexibleConnectedPositionStrategy | null = null;

    constructor() {
        super();
    }

    ngOnChanges(changes: LgSimpleChanges<LgTimePickerComponent>): void {
        if ("minTime" in changes || "maxTime" in changes) {
            if (this._popupInstance) {
                this._popupInstance.updateProps(this._getPopupProps(), false);
            }
        }
        if (changes.timeFormat) {
            this._parseTimeFormat();
        }
    }

    ngOnInit(): void {
        if (this._hoursFormat === undefined) this._parseTimeFormat();
    }

    ngOnDestroy(): void {
        if (this._popupInstance) this._attemptClose();
    }

    writeValue(value: LgTime): void {
        this._writeValue(value);
        this._updateParts();
    }

    _onKeyDown(event: KeyboardEvent): void {
        if (event.keyCode === ENTER) {
            event.stopPropagation();
            event.preventDefault();
            if (!this._popupInstance) this._onOpenPopup();
        }
    }

    _onOpenPopup(): void {
        if (this.disabled) return;

        const strategy = this._overlayService.positionStrategies
            .flexibleConnectedTo(this._elementRef)
            .withFlexibleDimensions(false)
            .withPush(false)
            .withViewportMargin(0)
            .withPositions([
                {
                    originX: "start",
                    originY: "top",
                    overlayX: "start",
                    overlayY: "top"
                },
                {
                    originX: "start",
                    originY: "bottom",
                    overlayX: "start",
                    overlayY: "bottom"
                }
            ]);

        strategy.withScrollableContainers(
            this._scrollDispatcher.getAncestorScrollContainers(this._elementRef)
        );

        this._positionStrategy = strategy;
        this._overlayInstance = this._overlayService.show({
            onClick: () => {
                this._attemptClose();
            },
            hasBackdrop: true,
            sourceElement: this._elementRef,
            focusPostHide: "ignore",
            positionStrategy: strategy,
            scrollStrategy: this._overlayService.scrollStrategies.reposition({ scrollThrottle: 0 })
        });

        const portal = new ComponentPortal<LgTimePickerPopupComponent>(LgTimePickerPopupComponent);
        this._popupInstance = this._overlayInstance.overlayRef.attach(portal).instance;

        this._popupInstance.initialize(this._getPopupProps(), false);

        strategy.positionChanges.subscribe(change => {
            if (this._popupInstance) {
                this._popupInstance._showAbove =
                    change.connectionPair.originY === "bottom" &&
                    change.connectionPair.overlayY === "bottom";
            }
        });
    }

    private _getPopupProps(timeOverride?: LgTime): ILgTimePickerTooltipComponentProps {
        return {
            maxTime: this.maxTime ?? undefined,
            minTime: this.minTime ?? undefined,
            required: this.required,
            selectedTime: timeOverride ?? this.value ?? null,
            defaultTime: this.defaultTime ?? undefined,
            onSelect: (time, cancelled, navigationDelta) =>
                this._onTimeSelect(time!, cancelled, navigationDelta),
            condensed: this.condensed,
            hoursFormat: this._hoursFormat,
            hoursSeparator: this._hoursSeparator,
            minutesFormat: this._minutesFormat,
            minutesSeparator: this._minutesSeparator,
            is24h: this._24h,
            positionStrategy: this._positionStrategy,
            tabBackCancels: this.tabBackCancels,
            getCopyData: time => this._getCopyData(time),
            pasteData: text => this._pasteData(text)
        };
    }

    private _getCopyData(currentTime: LgTime | null | undefined): string | null {
        if (this._getCopyDataOverride) return this._getCopyDataOverride(currentTime ?? null);
        if (!currentTime) return null;
        const formatter = this._getSafeFormatter();
        return formatter.format(new Date(2020, 0, 1, currentTime.hours, currentTime.minutes));
    }

    private _pasteData(text: string): boolean | null {
        const formatter = this._getSafeFormatter();
        const timeDate = formatter.parse(text);
        if (!timeDate && this._pasteDataOverride) {
            const result = this._pasteDataOverride(text, !!this._popupInstance);
            if (result) {
                requestAnimationFrame(() =>
                    this._popupInstance?.updateProps(this._getPopupProps(this.value), true)
                );
            }
            return result;
        }
        if (timeDate) {
            const time: LgTime = {
                hours: timeDate.getHours(),
                minutes: timeDate.getMinutes()
            };

            const minOk =
                !this.minTime ||
                time.hours > this.minTime.hours ||
                (time.hours === this.minTime.hours && time.minutes >= this.minTime.minutes);
            const maxOk =
                !this.maxTime ||
                time.hours < this.maxTime.hours ||
                (time.hours === this.maxTime.hours && time.minutes <= this.maxTime.minutes);

            if (!minOk || !maxOk) {
                if (this._popupInstance) return null;

                showInvalidTimeDialog(
                    this.minTime,
                    this.maxTime,
                    this.timeFormat,
                    this._datePipe,
                    this._lgTranslate,
                    this._promptDialog
                ).then(() => {
                    this._inputDiv.nativeElement.focus();
                });
            } else if (this._popupInstance) {
                this._popupInstance.updateProps(this._getPopupProps(time), true);
            } else {
                this.value = time;
                this._updateParts();
            }
            return true;
        } else {
            return false;
        }
    }

    private _getSafeFormatter(): LgDate {
        try {
            return new LgDate(this.timeFormat, this._datePipe);
        } catch {
            // just in case we diagree on allowed formats
            return new LgDate("HH:mm");
        }
    }

    private _onTimeSelect(
        time: LgTime,
        cancelled: boolean,
        navigationDelta: -1 | 0 | 1 | null
    ): void {
        if (cancelled) {
            this._attemptClose();
            this._inputDiv.nativeElement.focus();
            if (this._postSelectCallback)
                this._postSelectCallback(this.value, cancelled, navigationDelta);
            return;
        }

        this.value = time;
        this._updateParts();

        this.postSelect.emit(time);

        this._attemptClose();
        if (navigationDelta === 1) {
            this._overlayService.focusFirstTabbableElementAfter(
                this._inputDiv.nativeElement,
                true,
                false
            );
        } else if (navigationDelta != null) {
            this._inputDiv.nativeElement.focus();
        }
        if (this._postSelectCallback)
            this._postSelectCallback(this.value, cancelled, navigationDelta);
    }

    private _attemptClose(): void {
        this._overlayInstance.hide();
        this._popupInstance = null;
        this._positionStrategy = null;
    }

    private _updateParts(): void {
        this._period = null;
        this._minutes = null;
        this._hours = null;
        if (!this.value) return;
        const time = new Date(2020, 0, 1, this.value.hours, this.value.minutes, 0, 0);
        this._hours = this._datePipe.transform(time, this._hoursFormat);
        this._minutes = this._datePipe.transform(time, this._minutesFormat);
        if (!this._24h) {
            this._period = this._datePipe.transform(time, "aa");
        }
    }

    private _formatRegex =
        /^(?<hour>h|hh|H|HH)(?<hourSep>\W)(?<minute>m|mm)(?:(?<minuteSep>\W?)(?<period>a|aa|aaa))?$/;

    private _parseTimeFormat(): void {
        const match = this._formatRegex.exec(this.timeFormat ?? "");
        if (match) {
            this._hoursFormat = match.groups!.hour;
            this._hoursSeparator = match.groups!.hourSep ?? "";
            this._minutesFormat = match.groups!.minute;
            this._minutesSeparator = match.groups!.minuteSep;
            this._24h = !match.groups!.period;
            if (this._24h) {
                this._hoursFormat = this._hoursFormat?.toUpperCase();
            } else {
                this._hoursFormat = this._hoursFormat?.toLowerCase();
            }
            this._updateParts();
            if (this._popupInstance) {
                this._popupInstance.updateProps(this._getPopupProps(), false);
                setTimeout(() => this._positionStrategy?.apply(), 0);
            }
        } else {
            console.warn("Invalid time format", this.timeFormat);
            this.timeFormat = "HH:mm";
            this._parseTimeFormat();
        }
    }
}
