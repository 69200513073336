/* eslint-disable @typescript-eslint/no-unused-vars */
import ldFind from "lodash-es/find";
import type { ILogexPivotDefinition } from "./lg-pivot.types";
import type {
    FunctionParameter,
    IFunctionLocations,
    IFunctionOptions,
    ICalculatorFunction,
    ICalculatorFunctionSource
} from "./lg-pivot-calculator.types";
import type { CalculatorCompiler } from "./lg-pivot-calculator";

const nEPSILON = 1e-12;

// ----------------------------------------------------------------------------------
/**
 * Base class for simple aggregate function, supporting 1 parameter, condition, optional target, 1 temp, no location or options.
 */
// ----------------------------------------------------------------------------------
export class FnSimpleAggregateBase {
    protected name!: string;
    protected targetName!: string;
    protected parameter!: string;
    protected tempName?: string;
    protected condition?: string | null | undefined;

    constructor(name: string) {
        this.name = name;
    }

    prepare(
        targetName: string,
        parameters: FunctionParameter[],
        level: ILogexPivotDefinition,
        locations: IFunctionLocations,
        condition: string,
        options: IFunctionOptions
    ): boolean {
        if (parameters.length !== 1) {
            console.error(this.name + " requires exactly 1 parameter");
            return false;
        }
        if (targetName == null && parameters[0].isGlobal) {
            console.error(this.name + " requires a target, or a property-type parameter");
            return false;
        }
        this.targetName = targetName || parameters[0].name;
        this.parameter = parameters[0].getInstanceOn("element");
        this.condition = condition;
        return true;
    }

    getTarget(): string {
        return this.targetName;
    }

    getTemporaryCount(): number | string[] {
        return 1;
    }

    setTemporaries(names: string[]): void {
        this.tempName = names[0];
    }

    protected _wrapWithCondition(result: string[]): string[] {
        if (this.condition) {
            result.unshift(`if (${this.condition}) {\n  `);
            result.push(`}\n`);
        }
        return result;
    }
}

// ----------------------------------------------------------------------------------
// SUM()
// ----------------------------------------------------------------------------------
class FnSum extends FnSimpleAggregateBase implements ICalculatorFunction {
    constructor() {
        super("SUM");
    }

    getInitializationSource(): ICalculatorFunctionSource {
        return [`let ${this.tempName} = 0;\n`];
    }

    getLoopSource(): ICalculatorFunctionSource {
        const result = [`${this.tempName} += (${this.parameter}) || 0; \n`];
        return this._wrapWithCondition(result);
    }

    getFinalizerSource(): ICalculatorFunctionSource {
        return [`store["${this.targetName}"] = ${this.tempName};\n`];
    }
}
// ----------------------------------------------------------------------------------
// SUMABS()
// ----------------------------------------------------------------------------------
class FnSumAbs extends FnSimpleAggregateBase implements ICalculatorFunction {
    constructor() {
        super("SUMABS");
    }

    getInitializationSource(): ICalculatorFunctionSource {
        return [`let ${this.tempName} = 0;\n`];
    }

    getLoopSource(): ICalculatorFunctionSource {
        const result = [`${this.tempName} += (Math.abs(${this.parameter})) || 0; \n`];
        return this._wrapWithCondition(result);
    }

    getFinalizerSource(): ICalculatorFunctionSource {
        return [`store["${this.targetName}"] = ${this.tempName};\n`];
    }
}
// ----------------------------------------------------------------------------------
// PRODUCT()
// ----------------------------------------------------------------------------------
class FnProduct extends FnSimpleAggregateBase implements ICalculatorFunction {
    constructor() {
        super("PRODUCT");
    }

    getInitializationSource(): ICalculatorFunctionSource {
        return [`let ${this.tempName} = 1;\n`];
    }

    getLoopSource(): ICalculatorFunctionSource {
        const result = [`${this.tempName} *= (${this.parameter}) || 0; \n`];
        return this._wrapWithCondition(result);
    }

    getFinalizerSource(): ICalculatorFunctionSource {
        return [`store["${this.targetName}"] = ${this.tempName};\n`];
    }
}
// ----------------------------------------------------------------------------------
// SOME()
// ----------------------------------------------------------------------------------
class FnSome extends FnSimpleAggregateBase implements ICalculatorFunction {
    constructor() {
        super("SOME");
    }

    getInitializationSource(): ICalculatorFunctionSource {
        return [`let ${this.tempName} = false;\n`];
    }

    getLoopSource(): ICalculatorFunctionSource {
        const result = [`${this.tempName} = ${this.tempName} || !!(${this.parameter});\n`];
        return this._wrapWithCondition(result);
    }

    getFinalizerSource(): ICalculatorFunctionSource {
        return [`store["${this.targetName}"] = ${this.tempName};\n`];
    }
}
// ----------------------------------------------------------------------------------
// EVERY()
// ----------------------------------------------------------------------------------
class FnEvery extends FnSimpleAggregateBase implements ICalculatorFunction {
    constructor() {
        super("EVERY");
    }

    getInitializationSource(): ICalculatorFunctionSource {
        return [`let ${this.tempName} = true;\n`];
    }

    getLoopSource(): ICalculatorFunctionSource {
        const result = [`${this.tempName} = ${this.tempName} && (${this.parameter});\n`];
        return this._wrapWithCondition(result);
    }

    getFinalizerSource(): ICalculatorFunctionSource {
        return [`store["${this.targetName}"] = ${this.tempName};\n`];
    }
}
// ----------------------------------------------------------------------------------
// VALUEIFSAME()
// ----------------------------------------------------------------------------------
class FnValueIfSame extends FnSimpleAggregateBase implements ICalculatorFunction {
    private stateTemp?: string;

    constructor() {
        super("VALUEIFSAME");
    }

    getInitializationSource(): ICalculatorFunctionSource {
        return [`let ${this.tempName};\n`, `let ${this.stateTemp} = 0; \n`];
    }

    getLoopSource(): ICalculatorFunctionSource {
        const result = [
            `if ( !${this.stateTemp} ) {\n`,
            `  ${this.tempName} = (${this.parameter});\n`,
            `  ${this.stateTemp} = 1;\n`,
            `} else if ( ${this.stateTemp} === 1 ) {\n`,
            `  if ( ${this.tempName} !== ( ${this.parameter} ) ) {\n`,
            `    ${this.tempName} = undefined; \n`,
            `    ${this.stateTemp} = 2; \n`,
            `  }\n`,
            `}\n`
        ];
        return this._wrapWithCondition(result);
    }

    getFinalizerSource(): ICalculatorFunctionSource {
        return [`store["${this.targetName}"] = ${this.tempName};\n`];
    }

    override getTemporaryCount(): string[] {
        return ["Result", "State"];
    }

    override setTemporaries(names: string[]): void {
        super.setTemporaries(names);
        this.stateTemp = names[1];
    }
}

// ----------------------------------------------------------------------------------
// FnSimpleBaseFirst()
// ----------------------------------------------------------------------------------
class FnSimpleBaseFirst extends FnSimpleAggregateBase {
    protected helperTemp?: string;

    override getTemporaryCount(): string[] {
        return ["Result", "Helper"];
    }

    override setTemporaries(names: string[]): void {
        super.setTemporaries(names);
        this.helperTemp = names[1];
    }
}

// ----------------------------------------------------------------------------------
// MIN()
// ----------------------------------------------------------------------------------
class FnMin extends FnSimpleBaseFirst implements ICalculatorFunction {
    constructor() {
        super("MIN");
    }

    getInitializationSource(): ICalculatorFunctionSource {
        return [`let ${this.tempName}, ${this.helperTemp};\n`];
    }

    getLoopSource(): ICalculatorFunctionSource {
        // if ( (temp1 = elementValue) < temp0 || temp0 === undefined ) temp0  = temp1
        const result = [
            `if ( (${this.helperTemp} = ${this.parameter}) < ${this.tempName} || ${this.tempName} === undefined ) ${this.tempName} = ${this.helperTemp}; \n`
        ];
        return this._wrapWithCondition(result);
    }

    getFinalizerSource(): ICalculatorFunctionSource {
        return [`store["${this.targetName}"] = ${this.tempName};\n`];
    }
}
// ----------------------------------------------------------------------------------
// MAX()
// ----------------------------------------------------------------------------------
class FnMax extends FnSimpleBaseFirst implements ICalculatorFunction {
    constructor() {
        super("MAX");
    }

    getInitializationSource(): ICalculatorFunctionSource {
        return [`let ${this.tempName}, ${this.helperTemp};\n`];
    }

    getLoopSource(): ICalculatorFunctionSource {
        // if ( (temp1 = elementValue) > temp0 || temp0 === undefined ) temp0  = temp1
        const result = [
            `if ( (${this.helperTemp} = ${this.parameter}) > ${this.tempName} || ${this.tempName} === undefined ) ${this.tempName} = ${this.helperTemp}; \n`
        ];
        return this._wrapWithCondition(result);
    }

    getFinalizerSource(): ICalculatorFunctionSource {
        return [`store["${this.targetName}"] = ${this.tempName};\n`];
    }
}
// ----------------------------------------------------------------------------------
// FIRST()
// ----------------------------------------------------------------------------------
class FnFirst extends FnSimpleAggregateBase implements ICalculatorFunction {
    constructor() {
        super("FIRST");
    }

    getInitializationSource(): ICalculatorFunctionSource {
        return [`let ${this.tempName};\n`];
    }

    getLoopSource(): ICalculatorFunctionSource {
        const result = [
            `if ( ${this.tempName} === undefined ) ${this.tempName} = ${this.parameter};\n`
        ];
        return this._wrapWithCondition(result);
    }

    getFinalizerSource(): ICalculatorFunctionSource {
        return [`store["${this.targetName}"] = ${this.tempName};\n`];
    }
}

// ----------------------------------------------------------------------------------
// COUNT()
// ----------------------------------------------------------------------------------
class FnCount implements ICalculatorFunction {
    protected targetName!: string;
    protected parameter?: string;
    protected counterName!: string;
    protected dictName!: string;
    protected valueName!: string;
    protected distinct = false;
    protected condition?: string | null | undefined;

    prepare(
        targetName: string,
        parameters: FunctionParameter[],
        level: ILogexPivotDefinition,
        locations: IFunctionLocations,
        condition: string,
        options: IFunctionOptions
    ): boolean {
        if (parameters.length > 1) {
            console.error("COUNT requires exactly one or 0 parameters");
            return false;
        }
        if (parameters.length && parameters[0].isGlobal) {
            console.error("COUNT requires property parameter");
            return false;
        }
        if (!targetName && parameters.length === 0) {
            console.error("COUNT requires a target or a parameter");
            return false;
        }
        this.targetName = targetName || parameters[0].name;
        if (parameters.length) {
            this.parameter = parameters[0].getInstanceOn("element");
            this.distinct = true;
        }
        this.condition = condition;
        return true;
    }

    getTarget(): string {
        return this.targetName;
    }

    setTemporaries(names: string[]): void {
        if (this.distinct) {
            this.counterName = names[0];
            this.dictName = names[1];
            this.valueName = names[2];
        } else if (this.condition) {
            this.counterName = names[0];
        }
    }

    getTemporaryCount(): string[] {
        if (this.distinct) return ["Counter", "Dictionary", "Value"];
        if (this.condition) return ["Counter"];
        return [];
    }

    getInitializationSource(): ICalculatorFunctionSource | null {
        if (this.distinct) {
            return [
                `let ${this.counterName} = 0; let ${this.dictName} = {}; let ${this.valueName};\n`
            ];
        } else if (this.condition) {
            return [`let ${this.counterName} = 0; `];
        } else {
            return null;
        }
    }

    getLoopSource(): ICalculatorFunctionSource | null {
        if (this.distinct) {
            const result = [
                `${this.valueName} = ${this.parameter}; \n`,
                `if ( !${this.dictName}[${this.valueName}] ) {\n`,
                `  ${this.dictName}[${this.valueName}] = true;\n`,
                `  ${this.counterName}++; \n`,
                `}\n`
            ];
            if (this.condition) {
                result.unshift("if (" + this.condition + ") {\n");
                result.push("}\n");
            }
            return result;
        } else if (this.condition) {
            return [`if (${this.condition}) ${this.counterName}++;\n`];
        } else {
            return null;
        }
    }

    getFinalizerSource(): ICalculatorFunctionSource {
        if (this.distinct || this.condition) {
            return [`store["${this.targetName}"] = ${this.counterName};\n`];
        } else {
            return [`store["${this.targetName}"] = length;\n`];
        }
    }
}
// ----------------------------------------------------------------------------------
// BREAK()
// ----------------------------------------------------------------------------------
class FnBreak implements ICalculatorFunction {
    protected targetName!: string;
    protected onTop = false;
    protected onBottom = false;
    protected inLoop = true;
    protected code: string[] = [];

    prepare(
        targetName: string,
        parameters: FunctionParameter[],
        level: ILogexPivotDefinition,
        locations: IFunctionLocations,
        condition: string,
        options: IFunctionOptions
    ): boolean {
        if (parameters.length > 0) {
            console.error("BREAK does not accept any parameters");
            return false;
        }
        if (locations) {
            this.onTop = locations.onTop ?? false;
            this.onBottom = locations.onBottom ?? false;
            this.inLoop = locations.inLoop ?? false;
        }
        if (condition) {
            this.code = [`if (${condition}) debugger;\n`];
        } else {
            this.code = ["debugger;\n"];
        }
        this.targetName = targetName || "breakpoint";
        return true;
    }

    getSupportedLocations(): IFunctionLocations {
        return {
            onTop: true,
            onBottom: true,
            inLoop: true
        };
    }

    getTarget(): string {
        return this.targetName;
    }

    getTemporaryCount(): number {
        return 0;
    }

    getInitializationSource(): ICalculatorFunctionSource | null {
        if (this.onTop) return this.code;
        return null;
    }

    getLoopSource(): ICalculatorFunctionSource | null {
        if (this.inLoop) return this.code;
        return null;
    }

    getFinalizerSource(): ICalculatorFunctionSource | null {
        if (this.onBottom) return this.code;
        return null;
    }
}
// ----------------------------------------------------------------------------------
// AVG()
// ----------------------------------------------------------------------------------
class FnAvg implements ICalculatorFunction {
    protected targetName!: string;
    protected parameter!: string;
    protected isGlobal = false;
    protected sumKey?: string;
    protected cntKey?: string;
    protected sumTemp!: string;
    protected cntTemp!: string;
    protected enabledTemp?: string;
    protected condition!: string;

    prepare(
        targetName: string,
        parameters: FunctionParameter[],
        level: ILogexPivotDefinition,
        locations: IFunctionLocations,
        condition: string,
        options: IFunctionOptions
    ): boolean {
        if (parameters.length < 1 || parameters.length > 2) {
            console.error("AVG requires exactly one or 2 parameters");
            return false;
        }
        this.parameter = parameters[0].getInstanceOn("element");
        this.condition = condition;
        if (parameters.length === 2) {
            const converted = parameters[1].name.toLowerCase();
            if (converted === "global") {
                this.isGlobal = true;
                this.sumKey = parameters[0].name + "__avgsum$";
                this.cntKey = parameters[0].name + "__avgcount$";
                if (condition) {
                    console.error("AVG in global mode doesn't currently support conditions");
                    return false;
                }
            } else {
                console.error("Second AVG parameter can be only GLOBAL");
                return false;
            }
        }
        if (parameters[0].isGlobal) {
            if (this.isGlobal) {
                console.error("AVG in global mode requires a property parameter");
                return false;
            } else if (this.targetName == null) {
                console.error("AVG requires a target, or property parameter");
                return false;
            }
        }
        this.targetName = targetName || parameters[0].name;
        return true;
    }

    getTarget(): string {
        return this.targetName;
    }

    setTemporaries(names: string[]): void {
        this.sumTemp = names[0];
        if (this.isGlobal) {
            this.cntTemp = names[1];
            this.enabledTemp = names[2];
        } else if (this.condition) {
            this.cntTemp = names[1];
        }
    }

    getTemporaryCount(): string[] {
        if (this.isGlobal) return ["Sum", "Count", "IsParent"];
        if (this.condition) return ["Sum", "Count"];
        return ["Sum"];
    }

    getInitializationSource(): ICalculatorFunctionSource {
        if (this.isGlobal) {
            return [
                `let ${this.sumTemp} = 0, ${this.cntTemp} = 0;\n`,
                `let ${this.enabledTemp} = first.hasOwnProperty("${this.sumKey}");\n`
            ];
        } else if (this.condition) {
            return [`let ${this.sumTemp} = 0;\n`, `let ${this.cntTemp} = 0;\n`];
        } else {
            return [`let ${this.sumTemp} = 0;\n`];
        }
    }

    getLoopSource(): ICalculatorFunctionSource {
        if (this.isGlobal) {
            return [
                `if (${this.enabledTemp}) {\n`,
                `  ${this.sumTemp} += element["${this.sumKey}"];\n`,
                `  ${this.cntTemp} += element["${this.cntKey}"];\n`,
                `} else {\n`,
                `  ${this.sumTemp} += (${this.parameter}) || 0;\n`,
                `  ${this.cntTemp} += 1;\n`,
                `}\n`
            ];
        } else if (this.condition) {
            return [
                `if ( ${this.condition}) {\n`,
                `  ${this.sumTemp} += (${this.parameter}) || 0;\n`,
                `  ${this.cntTemp} += 1;\n`,
                `}\n`
            ];
        } else {
            return [`${this.sumTemp} += (${this.parameter}) || 0;\n`];
        }
    }

    getFinalizerSource(): ICalculatorFunctionSource {
        if (this.isGlobal) {
            return [
                `store["${this.targetName}"] = ${this.cntTemp} ? ${this.sumTemp} / ${this.cntTemp} : undefined;\n`,
                `store["${this.sumKey}"] = ${this.sumTemp};\n`,
                `store["${this.cntKey}"] = ${this.cntTemp};\n`
            ];
        } else if (this.condition) {
            return [
                `store["${this.targetName}"] = ${this.cntTemp} ? ${this.sumTemp} / ${this.cntTemp} : undefined;\n`
            ];
        } else {
            return [
                `store["${this.targetName}"] = length ? ${this.sumTemp} / length : undefined;\n`
            ];
        }
    }
}
// ----------------------------------------------------------------------------------
// AVGWEIGHTED()
// ----------------------------------------------------------------------------------
class FnAvgWeighted implements ICalculatorFunction {
    protected targetName!: string;
    protected parameter!: string;
    protected weight!: string;
    protected sumTemp!: string;
    protected weightTemp!: string;
    protected condition?: string | null | undefined;

    prepare(
        targetName: string,
        parameters: FunctionParameter[],
        level: ILogexPivotDefinition,
        locations: IFunctionLocations,
        condition: string,
        options: IFunctionOptions
    ): boolean {
        if (parameters.length !== 2) {
            console.error("AVGWEIGHTED requires exactly 2 parameters");
            return false;
        }
        this.parameter = parameters[0].getInstanceOn("element");
        this.weight = parameters[1].getInstanceOn("element");
        this.condition = condition;

        if (parameters[0].isGlobal && this.targetName == null) {
            console.error("AVGWEIGHTED requires a target, or property parameter");
            return false;
        }
        this.targetName = targetName || parameters[0].name;
        return true;
    }

    getTarget(): string {
        return this.targetName;
    }

    setTemporaries(names: string[]): void {
        this.sumTemp = names[0];
        this.weightTemp = names[1];
    }

    getTemporaryCount(): string[] {
        return ["Aggregate", "WeightSum"];
    }

    getInitializationSource(): ICalculatorFunctionSource {
        return [`let ${this.sumTemp} = 0;\n`, `let ${this.weightTemp} = 0;\n`];
    }

    getLoopSource(): ICalculatorFunctionSource {
        const result: string[] = [
            `${this.sumTemp} += ( (${this.parameter}) * (${this.weight}) ) || 0;\n`,
            `${this.weightTemp} += ( ${this.weight} ) || 0;\n`
        ];
        if (this.condition) {
            result.unshift(`if (${this.condition}) {\n  `);
            result.push(`}\n`);
        }
        return result;
    }

    getFinalizerSource(): ICalculatorFunctionSource {
        return [
            `if ( (${this.weightTemp}) > ${-nEPSILON} && (${this.weightTemp}) < ${nEPSILON} ) ${
                this.weightTemp
            } = 0;\n`,
            `store["${this.targetName}"] = ${this.weightTemp} ? ${this.sumTemp} / ${this.weightTemp} : undefined;\n`
        ];
    }
}
// ----------------------------------------------------------------------------------
// LOOKUP()
// ----------------------------------------------------------------------------------
class FnLookup implements ICalculatorFunction {
    protected targetName!: string;
    protected fullTargetName!: string;
    protected parameter!: string;
    protected lookupTemp!: string;
    protected condition?: string | null | undefined;
    protected originalParameter: string | null = null;

    prepare(
        targetName: string,
        parameters: FunctionParameter[],
        level: ILogexPivotDefinition,
        locations: IFunctionLocations,
        condition: string,
        options: IFunctionOptions
    ): boolean {
        if (parameters.length > 1 || (parameters.length === 1 && parameters[0].isGlobal)) {
            console.error("LOOKUP requires exactly one or 0 parameters of property type");
            return false;
        }
        if (parameters.length === 0 && !level.column) {
            console.error("LOOKUP must be in level with defined 'column', or have a parameter");
            return false;
        }
        this.originalParameter = parameters.length ? parameters[0].getInstanceOn("element") : null;
        this.parameter = parameters.length
            ? parameters[0].getInstanceOn("element")
            : 'element["' + level.column + '"]';
        if (!targetName) {
            console.log("LOOKUP requires the target");
            return false;
        }
        this.targetName = targetName;
        if (targetName[0] === "*") {
            if (level.store) {
                this.fullTargetName =
                    level.store +
                    this.targetName.substring(1, 2).toUpperCase() +
                    this.targetName.substring(2);
            } else {
                this.fullTargetName = this.targetName.substring(1);
            }
        } else {
            this.fullTargetName = this.targetName;
        }
        this.condition = condition;
        return true;
    }

    updateLevel(level: ILogexPivotDefinition): boolean {
        if (!this.originalParameter && !level.column) {
            console.error("LOOKUP must be in level with defined 'column', or have a parameter");
            return false;
        }
        this.parameter = this.originalParameter || 'element["' + level.column + '"]';
        if (this.targetName[0] === "*") {
            if (level.store) {
                this.fullTargetName =
                    level.store +
                    this.targetName.substring(1, 2).toUpperCase() +
                    this.targetName.substring(2);
            } else {
                this.fullTargetName = this.targetName.substring(1);
            }
        }
        return true;
    }

    getTarget(): string {
        return this.targetName;
    }

    setTemporaries(names: string[]): void {
        this.lookupTemp = names[0];
    }

    getTemporaryCount(): string[] {
        return ["Dictionary"];
    }

    getInitializationSource(): ICalculatorFunctionSource {
        return [`let ${this.lookupTemp} = {};\n`];
    }

    getLoopSource(): ICalculatorFunctionSource {
        const result = [`${this.lookupTemp}[${this.parameter}] = element; \n`];
        if (this.condition) {
            result.unshift(`if ( ${this.condition}) `);
        }
        return result;
    }

    getFinalizerSource(): ICalculatorFunctionSource {
        return [`store["${this.fullTargetName}"] = ${this.lookupTemp};\n`];
    }
}
// ----------------------------------------------------------------------------------
// SET()
// ----------------------------------------------------------------------------------
class FnSet implements ICalculatorFunction {
    protected targetName!: string;
    protected parameter!: string;

    protected lookupTemp!: string;
    protected lowerTemp?: string;
    protected subsetTemp?: string;
    protected keyTemp!: string;

    protected mergeMode?: number;
    protected condition?: string | null | undefined;

    prepare(
        targetName: string,
        parameters: FunctionParameter[],
        level: ILogexPivotDefinition,
        locations: IFunctionLocations,
        condition: string,
        options: IFunctionOptions
    ): boolean {
        if (parameters.length < 1 || parameters.length > 2) {
            console.error("SET requires exactly one or 2 parameters");
            return false;
        }
        if (parameters[0].isGlobal) {
            console.error("SET requires the first parameter to be a property");
            return false;
        }
        this.parameter = parameters[0].getInstanceOn("element");
        if (parameters.length === 2) {
            switch (parameters[1].name.toLowerCase()) {
                case "yes":
                case "merge":
                    this.mergeMode = 1;
                    break;
                case "full":
                    this.mergeMode = 2;
                    if (!targetName || parameters[0].name === targetName) {
                        console.error(
                            "SET in FULL merge mode requires target to be different than the source"
                        );
                        return false;
                    }
                    break;
                case "no":
                    this.mergeMode = 0;
                    break;
                default:
                    console.error("SET uknnown merge mode %o", parameters[1].name);
                    return false;
            }
        }
        if (this.mergeMode && condition) {
            console.error("SET currently supports condition only in no merge mode");
            return false;
        }
        this.targetName = targetName || parameters[0].name;
        this.condition = condition;
        return true;
    }

    getTarget(): string {
        return this.targetName;
    }

    setTemporaries(names: string[]): void {
        this.lookupTemp = names[0];
        this.keyTemp = names[1];
        if (this.mergeMode) {
            this.lowerTemp = names[2];
            this.subsetTemp = names[3];
        }
    }

    getTemporaryCount(): string[] {
        return this.mergeMode
            ? ["Dictionary", "Key", "HasChildren", "Child"]
            : ["Dictionary", "Key"];
    }

    getInitializationSource(): ICalculatorFunctionSource {
        if (this.mergeMode) {
            return [
                `let ${this.lookupTemp} = {};\n`,
                `let ${this.subsetTemp}, ${this.keyTemp};\n`,
                `let ${this.lowerTemp} = first["${this.targetName}"] !== null && typeof first["${this.targetName}"] === "object" ;\n`
            ];
        } else {
            return [`let ${this.keyTemp}, ${this.lookupTemp} = {};\n`];
        }
    }

    getLoopSource(): ICalculatorFunctionSource {
        if (this.mergeMode) {
            let part1: string[] = [],
                part2: string[] = [],
                part3: string[] = [],
                part4: string[] = [];
            // merge the element first
            part2 = [
                `${this.keyTemp} = ${this.parameter};\n`,
                `if (${this.keyTemp} !== undefined ) ${this.lookupTemp}[${this.keyTemp}] = (${this.lookupTemp}[${this.keyTemp}] || 0) + 1;\n`
            ];
            // merge in the subset
            part4 = [
                `if (${this.lowerTemp}) {\n`,
                `  ${this.subsetTemp} = element["${this.targetName}"];\n`,
                `  for ( ${this.keyTemp} in ${this.subsetTemp} ) {\n`,
                `    ${this.lookupTemp}[${this.keyTemp}] = (${this.lookupTemp}[${this.keyTemp}] || 0) + 1;\n`,
                `  }\n`,
                `}\n`
            ];
            // in normal merge mode, we need to wrap the element part in if
            if (this.mergeMode !== 2) {
                part1 = [`if ( !${this.lowerTemp}) {\n`];
                part3 = [`}\n`];
            }
            return part1.concat(part2, part3, part4);
        } else {
            const result = [
                `${this.keyTemp} = ${this.parameter};\n`,
                `if (${this.keyTemp} !== undefined ) ${this.lookupTemp}[${this.keyTemp}] = (${this.lookupTemp}[${this.keyTemp}] || 0) + 1;\n`
            ];
            if (this.condition) {
                result.unshift(`if ( ${this.condition}  ) {\n`);
                result.push(`}\n`);
            }
            return result;
        }
    }

    getFinalizerSource(): ICalculatorFunctionSource {
        return [`store["${this.targetName}"] = ${this.lookupTemp};\n`];
    }
}
// ----------------------------------------------------------------------------------
/**
 * Base class for function that can be done on node or parent. Also supports conditions
 */
// ----------------------------------------------------------------------------------
export class FnLocationBase {
    protected onNodes = false;
    protected onParent = true;
    protected onTotals = false;
    protected condition?: string | null | undefined;

    prepare(
        targetName: string,
        parameters: FunctionParameter[],
        level: ILogexPivotDefinition,
        locations: IFunctionLocations,
        condition: string,
        options: IFunctionOptions
    ): boolean {
        if (locations) {
            this.onParent = locations.onParent || (!locations.onNodes && !locations.onTotals);
            this.onNodes = locations.onNodes ?? false;
            this.onTotals = locations.onTotals ?? false;
        }
        this.condition = condition;
        return true;
    }

    getSupportedLocations(): IFunctionLocations {
        return {
            onNodes: true,
            onParent: true,
            onTotals: true
        };
    }

    protected _wrapWithCondition(result: string[]): string[] {
        if (this.condition) {
            result.unshift("if (" + this.condition + ") {\n  ");
            result.push("}\n");
        }
        return result;
    }
}

// ----------------------------------------------------------------------------------
/**
 * Base class for simple math operation (DIV/MUL/DIFF/..) that can be executed on node or parent. Supports 2 parameters
 * with optional default as parameter 3, condition and 1 temp.
 */
// ----------------------------------------------------------------------------------
export abstract class FnMatchOpBase extends FnLocationBase {
    protected name: string;
    protected targetName!: string;
    protected parameter1Node!: string;
    protected parameter1Parent!: string;
    protected parameter2Node!: string;
    protected parameter2Parent!: string;
    protected temp?: string;
    protected allowDefault!: boolean;
    protected defaultValueNode?: string;
    protected defaultValueParent?: string;

    constructor(name: string, allowDefault: boolean) {
        super();
        this.name = name;
        this.allowDefault = allowDefault;
    }

    override prepare(
        targetName: string,
        parameters: FunctionParameter[],
        level: ILogexPivotDefinition,
        locations: IFunctionLocations,
        condition: string,
        options: IFunctionOptions
    ): boolean {
        if (!super.prepare(targetName, parameters, level, locations, condition, options))
            return false;

        if (this.allowDefault) {
            if (parameters.length < 2 || parameters.length > 3) {
                console.error(this.name + " requires exactly 2 or 3 parameters");
                return false;
            }
        } else if (parameters.length !== 2) {
            console.error(this.name + " requires exactly 2 parameters");
            return false;
        }
        if (!targetName) {
            console.error(this.name + " requires target");
            return false;
        }
        this.parameter1Node = parameters[0].getInstanceOn("element");
        this.parameter1Parent = parameters[0].getInstanceOn("store");
        this.parameter2Node = parameters[1].getInstanceOn("element");
        this.parameter2Parent = parameters[1].getInstanceOn("store");
        this.targetName = targetName;
        if (this.allowDefault && parameters.length === 3) {
            this.defaultValueNode = parameters[2].getInstanceOn("element");
            this.defaultValueParent = parameters[2].getInstanceOn("store");
        }
        return true;
    }

    override getSupportedLocations(): IFunctionLocations {
        return {
            onNodes: true,
            onParent: true,
            onTotals: true
        };
    }

    getTarget(): string {
        return this.targetName;
    }

    setTemporaries(names: string[]): void {
        if (names.length) this.temp = names[0];
    }

    getTemporaryCount(): number | string[] {
        return 1;
    }

    getInitializationSource(): ICalculatorFunctionSource | null {
        if (this.temp) return [`let ${this.temp};\n`];
        return null;
    }

    getLoopSource(): ICalculatorFunctionSource | null {
        if (!this.onNodes) return null;
        return this._getCode(
            "element",
            this.parameter1Node,
            this.parameter2Node,
            this.defaultValueNode!
        );
    }

    getFinalizerSource(): ICalculatorFunctionSource | null {
        if (!this.onParent) return null;
        return this._getCode(
            "store",
            this.parameter1Parent,
            this.parameter2Parent,
            this.defaultValueParent
        );
    }

    getOnTotalsSource(): ICalculatorFunctionSource | null {
        if (!this.onTotals) return null;
        return this._getCode(
            "store",
            this.parameter1Parent,
            this.parameter2Parent,
            this.defaultValueParent
        );
    }

    protected abstract _getCode(
        store: string,
        param1: string,
        param2: string,
        defaultValueSource: string | null | undefined
    ): ICalculatorFunctionSource;
}
// ----------------------------------------------------------------------------------
// DIV
// ----------------------------------------------------------------------------------
class FnDiv extends FnMatchOpBase implements ICalculatorFunction {
    private infinity = false;
    private temp2?: string;
    private epsilon = nEPSILON;

    constructor() {
        super("DIV", true);
    }

    override prepare(
        targetName: string,
        parameters: FunctionParameter[],
        level: ILogexPivotDefinition,
        locations: IFunctionLocations,
        condition: string,
        options: IFunctionOptions
    ): boolean {
        if (options && options.infinity) {
            this.infinity = true;
        }
        if (parameters.length === 4) {
            if (!parameters[3].isGlobal || isNaN(+parameters[3].name)) {
                console.error("Epsilon parameter for DIV must be a number");
                return false;
            }
            this.epsilon = +parameters[3].name;
            parameters.pop();
        }
        return super.prepare(targetName, parameters, level, locations, condition, options);
    }

    override getInitializationSource(): ICalculatorFunctionSource {
        if (this.infinity) {
            return [`let ${this.temp}, ${this.temp2};\n`];
        } else {
            return [`let ${this.temp};\n`];
        }
    }

    protected _getCode(
        store: string,
        param1: string,
        param2: string,
        defaultValueSource: string
    ): ICalculatorFunctionSource {
        if (this.infinity) {
            return this._wrapWithCondition([
                `${this.temp} = ${param1};\n`,
                `${this.temp2} = ${param2};\n`,
                `if ( ${this.temp2} > ${-this.epsilon} && ${this.temp2} < ${this.epsilon} ) ${
                    this.temp2
                } = 0;\n`,
                `${store}["${this.targetName}"] = ${this.temp} || ${this.temp2} ? (${this.temp} / ${
                    this.temp2
                }) : ${defaultValueSource ?? "0"};\n`
            ]);
        } else {
            return this._wrapWithCondition([
                `${this.temp} = ${param2};\n`,
                `if ( ${this.temp} > ${-this.epsilon} && ${this.temp} < ${this.epsilon} ) ${
                    this.temp
                } = 0;\n`,
                `${store}["${this.targetName}"] = ${this.temp} ? (${param1} / ${this.temp}) : ${
                    defaultValueSource ?? "0"
                };\n`
            ]);
        }
    }

    getSupportedOptions(): IFunctionOptions {
        return {
            infinity: true
        };
    }

    override getTemporaryCount(): string[] {
        return this.infinity ? ["Numer", "Denom"] : ["Denom"];
    }

    override setTemporaries(names: string[]): void {
        this.temp = names[0];
        if (this.infinity) this.temp2 = names[1];
    }
}

// ----------------------------------------------------------------------------------
// GROWTH
// ----------------------------------------------------------------------------------
class FnGrowth extends FnMatchOpBase implements ICalculatorFunction {
    private infinity = false;
    private temp2?: string;
    private epsilon = nEPSILON;

    constructor() {
        super("GROWTH", true);
    }

    override prepare(
        targetName: string,
        parameters: FunctionParameter[],
        level: ILogexPivotDefinition,
        locations: IFunctionLocations,
        condition: string,
        options: IFunctionOptions
    ): boolean {
        if (options && options.infinity) {
            this.infinity = true;
        }
        if (parameters.length === 4) {
            if (!parameters[3].isGlobal || isNaN(+parameters[3].name)) {
                console.error("Epsilon parameter for GROWTH must be a number");
                return false;
            }
            this.epsilon = +parameters[3].name;
            parameters.pop();
        }
        return super.prepare(targetName, parameters, level, locations, condition, options);
    }

    override getInitializationSource(): ICalculatorFunctionSource {
        if (this.infinity) {
            return [`let ${this.temp}, ${this.temp2};\n`];
        } else {
            return [`let ${this.temp};\n`];
        }
    }

    protected _getCode(
        store: string,
        param1: string,
        param2: string,
        defaultValueSource: string
    ): ICalculatorFunctionSource {
        if (this.infinity) {
            return this._wrapWithCondition([
                `${this.temp} = ${param1};\n`,
                `${this.temp2} = ${param2};\n`,
                `if ( ${this.temp2} > ${-this.epsilon} && ${this.temp2} < ${this.epsilon} ) ${
                    this.temp2
                } = 0;\n`,
                `${store}["${this.targetName}"] = ${this.temp} || ${this.temp2} ? (${this.temp} / ${
                    this.temp2
                } - 1) : ${defaultValueSource ?? "0"};\n`
            ]);
        } else {
            return this._wrapWithCondition([
                `${this.temp} = ${param2};\n`,
                `if ( ${this.temp} > ${-this.epsilon} && ${this.temp} < ${this.epsilon} ) ${
                    this.temp
                } = 0;\n`,
                `${store}["${this.targetName}"] = ${this.temp} ? (${param1} / ${this.temp} - 1) : ${
                    defaultValueSource ?? "0"
                };\n`
            ]);
        }
    }

    getSupportedOptions(): IFunctionOptions {
        return {
            infinity: true
        };
    }

    override getTemporaryCount(): string[] {
        return this.infinity ? ["Numer", "Denom"] : ["Denom"];
    }

    override setTemporaries(names: string[]): void {
        this.temp = names[0];
        if (this.infinity) this.temp2 = names[1];
    }
}

// ----------------------------------------------------------------------------------
// MUL
// ----------------------------------------------------------------------------------
class FnMul extends FnMatchOpBase implements ICalculatorFunction {
    constructor() {
        super("MUL", false);
    }

    override getTemporaryCount(): number {
        return 0;
    }

    protected _getCode(
        store: string,
        param1: string,
        param2: string,
        defaultValueSource: string
    ): ICalculatorFunctionSource {
        return this._wrapWithCondition([
            `${store}["${this.targetName}"] = (${param1}) * (${param2});\n`
        ]);
    }
}

// ----------------------------------------------------------------------------------
// ADD
// ----------------------------------------------------------------------------------
class FnAdd extends FnMatchOpBase implements ICalculatorFunction {
    constructor() {
        super("ADD", false);
    }

    override getTemporaryCount(): number {
        return 0;
    }

    protected _getCode(
        store: string,
        param1: string,
        param2: string,
        defaultValueSource: string
    ): ICalculatorFunctionSource {
        return this._wrapWithCondition([
            `${store}["${this.targetName}"] = (${param1}) + (${param2});\n`
        ]);
    }
}

// ----------------------------------------------------------------------------------
// DIFF
// ----------------------------------------------------------------------------------
class FnDiff extends FnMatchOpBase implements ICalculatorFunction {
    constructor() {
        super("DIFF", false);
    }

    override getTemporaryCount(): number {
        return 0;
    }

    protected _getCode(
        store: string,
        param1: string,
        param2: string,
        defaultValueSource: string
    ): ICalculatorFunctionSource {
        return this._wrapWithCondition([
            `${store}["${this.targetName}"] = (${param1}) - (${param2});\n`
        ]);
    }
}

// ----------------------------------------------------------------------------------
// ABSDIFF
// ----------------------------------------------------------------------------------
class FnAbsDiff extends FnMatchOpBase implements ICalculatorFunction {
    constructor() {
        super("AbsDIFF", false);
    }

    override getTemporaryCount(): number {
        return 0;
    }

    protected _getCode(
        store: string,
        param1: string,
        param2: string,
        defaultValueSource: string
    ): ICalculatorFunctionSource {
        return this._wrapWithCondition([
            `${store}["${this.targetName}"] = Math.abs((${param1}) - (${param2}));\n`
        ]);
    }
}

// ----------------------------------------------------------------------------------
// SELECT
// ----------------------------------------------------------------------------------
class FnSelect extends FnLocationBase implements ICalculatorFunction {
    protected targetName!: string;
    protected parameters: FunctionParameter[] = [];

    override prepare(
        targetName: string,
        parameters: FunctionParameter[],
        level: ILogexPivotDefinition,
        locations: IFunctionLocations,
        condition: string,
        options: IFunctionOptions
    ): boolean {
        if (!super.prepare(targetName, parameters, level, locations, condition, options))
            return false;
        if (parameters.length < 3) {
            console.error("SELECT requires at least 3 parameters");
            return false;
        }
        if (!targetName) {
            console.error("SELECT requires target");
            return false;
        }
        this.targetName = targetName;
        this.parameters = parameters;
        return true;
    }

    getTarget(): string {
        return this.targetName;
    }

    getTemporaryCount(): number {
        return 0;
    }

    getInitializationSource(): ICalculatorFunctionSource | null {
        return null;
    }

    getLoopSource(): ICalculatorFunctionSource | null {
        if (!this.onNodes) return null;
        return this._getCode("element");
    }

    getFinalizerSource(): ICalculatorFunctionSource | null {
        if (!this.onParent) return null;
        return this._getCode("store");
    }

    getOnTotalsSource(): ICalculatorFunctionSource | null {
        if (!this.onTotals) return null;
        return this._getCode("store");
    }

    private _getCode(target: string): string[] {
        const result: string[] = [`${target}["${this.targetName}"] = [`];
        let i;
        for (i = 1; i < this.parameters.length; ++i) {
            if (i > 1) result.push(", ");
            result.push(this.parameters[i].getInstanceOn(target));
        }
        result.push(`][+(${this.parameters[0].getInstanceOn(target)})];\n`);
        return this._wrapWithCondition(result);
    }
}

// ----------------------------------------------------------------------------------
// BOOLEAN
// ----------------------------------------------------------------------------------
class FnBoolean extends FnLocationBase implements ICalculatorFunction {
    protected targetName!: string;
    protected parameterNode!: string;
    protected parameterParent!: string;

    override prepare(
        targetName: string,
        parameters: FunctionParameter[],
        level: ILogexPivotDefinition,
        locations: IFunctionLocations,
        condition: string,
        options: IFunctionOptions
    ): boolean {
        if (!super.prepare(targetName, parameters, level, locations, condition, options))
            return false;
        if (parameters.length !== 1) {
            console.error("BOOLEAN requires exactly 1 parameter");
            return false;
        }
        if (!targetName && parameters[0].isGlobal) {
            console.error("BOOLEAN requires target, or a property-type parameter");
            return false;
        }
        this.targetName = targetName || parameters[0].name;
        this.parameterNode = parameters[0].getInstanceOn("element");
        this.parameterParent = parameters[0].getInstanceOn("store");
        return true;
    }

    getTarget(): string {
        return this.targetName;
    }

    getTemporaryCount(): number {
        return 0;
    }

    getInitializationSource(): ICalculatorFunctionSource | null {
        return null;
    }

    getLoopSource(): ICalculatorFunctionSource | null {
        if (!this.onNodes) return null;
        return this._getCode("element", this.parameterNode);
    }

    getFinalizerSource(): ICalculatorFunctionSource | null {
        if (!this.onParent) return null;
        return this._getCode("store", this.parameterParent);
    }

    getOnTotalsSource(): ICalculatorFunctionSource | null {
        if (!this.onTotals) return null;
        return this._getCode("store", this.parameterParent);
    }

    private _getCode(store: string, parameter: string): ICalculatorFunctionSource | null {
        return this._wrapWithCondition([
            `${store}["${this.targetName}"] = !!(${parameter});`,
            "\n"
        ]);
    }
}

// ----------------------------------------------------------------------------------
// IDENTITY
// ----------------------------------------------------------------------------------
class FnIdentity extends FnLocationBase implements ICalculatorFunction {
    protected targetName!: string;
    protected parameter1Node!: string;
    protected parameter1Parent!: string;

    override prepare(
        targetName: string,
        parameters: FunctionParameter[],
        level: ILogexPivotDefinition,
        locations: IFunctionLocations,
        condition: string,
        options: IFunctionOptions
    ): boolean {
        super.prepare(targetName, parameters, level, locations, condition, options);
        if (parameters.length > 1) {
            console.error("IDENTITY accepts 0 or 1 parameters");
            return false;
        }
        if (!targetName) {
            console.error("IDENTITY requires target");
            return false;
        }
        if (parameters.length === 0) {
            if (condition) {
                console.error("IDENTITY with no parameter does not support condition");
                return false;
            }
            if (locations) {
                console.error("IDENTITY with no parameter does not support location");
                return false;
            }
        } else {
            this.parameter1Node = parameters[0].getInstanceOn("element");
            this.parameter1Parent = parameters[0].getInstanceOn("store");
        }
        this.targetName = targetName;
        return true;
    }

    getTarget(): string {
        return this.targetName;
    }

    getTemporaryCount(): number {
        return 0;
    }

    getInitializationSource(): ICalculatorFunctionSource | null {
        return null;
    }

    getLoopSource(): ICalculatorFunctionSource | null {
        return this._getCode("element", this.parameter1Node, this.onNodes);
    }

    getFinalizerSource(): ICalculatorFunctionSource | null {
        return this._getCode("store", this.parameter1Parent, this.onParent);
    }

    getOnTotalsSource(): ICalculatorFunctionSource | null {
        return this._getCode("store", this.parameter1Parent, this.onTotals);
    }

    private _getCode(
        store: string,
        parameter: string,
        enabled: boolean
    ): ICalculatorFunctionSource | null {
        if (enabled && parameter) {
            return this._wrapWithCondition([`${store}["${this.targetName}"] = ${parameter};\n`]);
        } else {
            return null;
        }
    }
}

// ----------------------------------------------------------------------------------
// EVAL
// ----------------------------------------------------------------------------------
class FnEval extends FnLocationBase implements ICalculatorFunction {
    protected targetName!: string;
    protected parameter!: string;

    override prepare(
        targetName: string,
        parameters: FunctionParameter[],
        level: ILogexPivotDefinition,
        locations: IFunctionLocations,
        condition: string,
        options: IFunctionOptions
    ): boolean {
        if (!super.prepare(targetName, parameters, level, locations, condition, options))
            return false;

        if (parameters.length !== 1 || !parameters[0].isGlobal) {
            console.error("EVAL requires exactly 1 global-type parameter");
            return false;
        }
        if (!targetName) {
            console.error("EVAL requires target");
            return false;
        }
        this.targetName = targetName;
        this.parameter = parameters[0].name;
        return true;
    }

    getTarget(): string {
        return this.targetName;
    }

    getTemporaryCount(): number {
        return 0;
    }

    getInitializationSource(): ICalculatorFunctionSource | null {
        return null;
    }

    getLoopSource(): ICalculatorFunctionSource | null {
        if (!this.onNodes) return null;
        return this._getCode("element");
    }

    getFinalizerSource(): ICalculatorFunctionSource | null {
        if (!this.onParent) return null;
        return this._getCode("store");
    }

    getOnTotalsSource(): string[] | null {
        if (!this.onTotals) return null;
        return this._getCode("store");
    }

    private _getCode(store: string): ICalculatorFunctionSource {
        const finalCode = this.parameter.replace(/\$NODE/gi, store);
        return this._wrapWithCondition([`${store}["${this.targetName}"] = (${finalCode});`, "\n"]);
    }
}

// ----------------------------------------------------------------------------------
// LET
// ----------------------------------------------------------------------------------
class FnLet implements ICalculatorFunction {
    protected targetName!: string;
    protected parameter!: string;
    protected onTop = true;
    protected inLoop = false;
    protected onBottom = false;

    prepare(
        targetName: string,
        parameters: FunctionParameter[],
        level: ILogexPivotDefinition,
        locations: IFunctionLocations,
        condition: string,
        options: IFunctionOptions
    ): boolean {
        if (parameters.length !== 1) {
            console.error("LET requires exactly 1 parameter");
            return false;
        }
        if (condition) {
            console.error("LET does not support condition");
            return false;
        }
        if (!parameters[0].isGlobal) {
            console.error("LET requires first parameter to be global-type");
            return false;
        }
        this.onTop = true;
        this.inLoop = false;
        this.onBottom = false;
        if (locations) {
            this.onTop = locations.onTop ?? false;
            this.inLoop = locations.inLoop ?? false;
            this.onBottom = locations.onBottom ?? false;
        }
        if (!targetName) {
            console.error("LET requires target");
            return false;
        }
        this.targetName = targetName;
        this.parameter = parameters[0].name;
        return true;
    }

    getTarget(): string {
        return this.targetName;
    }

    getTemporaryCount(): number {
        return 0;
    }

    getSupportedLocations(): IFunctionLocations {
        return {
            inLoop: true,
            onTop: true,
            onBottom: true
        };
    }

    getInitializationSource(): ICalculatorFunctionSource {
        if (this.onTop) {
            return [`let ${this.targetName} = (${this.parameter});\n`];
        } else {
            return [`let ${this.targetName};\n`];
        }
    }

    getLoopSource(): ICalculatorFunctionSource | null {
        if (!this.inLoop) return null;
        return [`${this.targetName} = (${this.parameter});\n`];
    }

    getFinalizerSource(): ICalculatorFunctionSource | null {
        if (!this.onBottom) return null;
        return [`${this.targetName} = (${this.parameter});\n`];
    }
}

// ----------------------------------------------------------------------------------
// DELETE
// ----------------------------------------------------------------------------------
class FnDelete extends FnLocationBase implements ICalculatorFunction {
    protected parameters: FunctionParameter[] = [];
    protected targetName!: string;

    override prepare(
        targetName: string,
        parameters: FunctionParameter[],
        level: ILogexPivotDefinition,
        locations: IFunctionLocations,
        condition: string,
        options: IFunctionOptions
    ): boolean {
        if (!super.prepare(targetName, parameters, level, locations, condition, options))
            return false;
        if (parameters.length < 1) {
            console.error("DELETE requires at least 1 parameter");
            return false;
        }
        this.targetName = targetName || "DELETE";
        if (ldFind(parameters, { isGlobal: true })) {
            console.error("DELETE expects only property-type parameters");
            return false;
        }
        this.parameters = parameters;
        return true;
    }

    getTarget(): string {
        return this.targetName;
    }

    getTemporaryCount(): number {
        return 0;
    }

    getInitializationSource(): ICalculatorFunctionSource | null {
        return null;
    }

    getLoopSource(): ICalculatorFunctionSource | null {
        if (!this.onNodes) return null;
        return this._getCode("element");
    }

    getFinalizerSource(): ICalculatorFunctionSource | null {
        if (!this.onParent) return null;
        return this._getCode("store");
    }

    getOnTotalsSource(): ICalculatorFunctionSource | null {
        if (!this.onTotals) return null;
        return this._getCode("store");
    }

    private _getCode(target: string): string[] {
        const result: string[] = [];
        let i;
        for (i = 0; i < this.parameters.length; ++i) {
            result.push("delete ");
            result.push(this.parameters[i].getInstanceOn(target));
            result.push(";\n");
        }
        return this._wrapWithCondition(result);
    }
}

// ----------------------------------------------------------------------------------
/**
 * Base class for simple 3 op math operation (ADDMUL/DIFFMUL) that can be executed on node or parent.
 */
// ----------------------------------------------------------------------------------
export abstract class FnThreeArgOpBase extends FnLocationBase {
    protected name: string;
    protected targetName!: string;
    protected parameter1!: FunctionParameter;
    protected parameter2!: FunctionParameter;
    protected parameter3!: FunctionParameter;

    constructor(name: string, allowDefault: boolean) {
        super();
        this.name = name;
    }

    override prepare(
        targetName: string,
        parameters: FunctionParameter[],
        level: ILogexPivotDefinition,
        locations: IFunctionLocations,
        condition: string,
        options: IFunctionOptions
    ): boolean {
        if (!super.prepare(targetName, parameters, level, locations, condition, options))
            return false;

        if (parameters.length !== 3) {
            console.error(this.name + " requires exactly 3 parameters");
            return false;
        }
        if (!targetName) {
            console.error(this.name + " requires target");
            return false;
        }
        this.parameter1 = parameters[0];
        this.parameter2 = parameters[1];
        this.parameter3 = parameters[2];
        this.targetName = targetName;
        return true;
    }

    override getSupportedLocations(): IFunctionLocations {
        return {
            onNodes: true,
            onParent: true,
            onTotals: true
        };
    }

    getTarget(): string {
        return this.targetName;
    }

    getTemporaryCount(): number | string[] {
        return 0;
    }

    getInitializationSource(): ICalculatorFunctionSource | null {
        return null;
    }

    getLoopSource(): ICalculatorFunctionSource | null {
        if (!this.onNodes) return null;
        return this._getCode(
            "element",
            this.parameter1.getInstanceOn("element"),
            this.parameter2.getInstanceOn("element"),
            this.parameter3.getInstanceOn("element")
        );
    }

    getFinalizerSource(): ICalculatorFunctionSource | null {
        if (!this.onParent) return null;
        return this._getCode(
            "store",
            this.parameter1.getInstanceOn("store"),
            this.parameter2.getInstanceOn("store"),
            this.parameter3.getInstanceOn("store")
        );
    }

    getOnTotalsSource(): ICalculatorFunctionSource | null {
        if (!this.onTotals) return null;
        return this._getCode(
            "store",
            this.parameter1.getInstanceOn("store"),
            this.parameter2.getInstanceOn("store"),
            this.parameter3.getInstanceOn("store")
        );
    }

    protected abstract _getCode(
        store: string,
        param1: string,
        param2: string,
        param3: string
    ): ICalculatorFunctionSource;
}

// ----------------------------------------------------------------------------------
// ADDMUL
// ----------------------------------------------------------------------------------
class FnAddMul extends FnThreeArgOpBase implements ICalculatorFunction {
    constructor() {
        super("ADDMUL", false);
    }

    protected _getCode(
        store: string,
        param1: string,
        param2: string,
        param3: string
    ): ICalculatorFunctionSource {
        return this._wrapWithCondition([
            `${store}["${this.targetName}"] = ((${param1}) + (${param2})) * (${param3});\n`
        ]);
    }
}

// ----------------------------------------------------------------------------------
// SUBMUL
// ----------------------------------------------------------------------------------
class FnDiffMul extends FnThreeArgOpBase implements ICalculatorFunction {
    constructor() {
        super("DIFFMUL", false);
    }

    protected _getCode(
        store: string,
        param1: string,
        param2: string,
        param3: string
    ): ICalculatorFunctionSource {
        return this._wrapWithCondition([
            `${store}["${this.targetName}"] = ((${param1}) - (${param2})) * (${param3});\n`
        ]);
    }
}

// ----------------------------------------------------------------------------------
// Note: unless explicitly mentioned, all the functions support the IF condition
export function registerCommonFunctions(compiler: CalculatorCompiler): void {
    // target=SUM(source) or SUM(source). Resolves to 0 for empty level
    compiler.registerFunction("sum", FnSum);

    // target=SUMABS(source) or SUMABS(source). Sums up absolute values of a give argument. Resolves to 0 for empty level
    compiler.registerFunction("sumabs", FnSumAbs);

    // target=PRODUCT(source)  or PRODUCT(source). Resolves to 1 for empty level
    compiler.registerFunction("product", FnProduct);

    // target = SOME(source) or SOME(source). Aggregate OR. Returns false for empty list
    compiler.registerFunction("some", FnSome);

    // target = EVERY(source) or EVERY(source). Aggregate AND. Returns true for empty list
    // Note that currently this doesn't convert the values to boolean - that means you'll get first falsy or last truthy value,
    // instead of true/false
    compiler.registerFunction("every", FnEvery);

    // target=MIN(source) or MIN(source). Uses < operator.
    compiler.registerFunction("min", FnMin);

    // target=MAX(source) or MAX(source). Uses > operator.
    compiler.registerFunction("max", FnMax);

    // target=FIRST(source) or FIRST(source). Returns the value that is not undefined (can be also combined with IF)
    compiler.registerFunction("first", FnFirst);

    // target=COUNT() stores the number of nodes.
    // target=COUNT(source) or COUNT(source) stores the number of distinct values of the property, including null
    compiler.registerFunction("count", FnCount);

    // Note: target serves just as ID for the merging purposes, will default to "breakpoint"
    // BREAK() or target=BREAK()   breaks inside the loop
    // BREAK() ON TOP or BREAK() ON BOTTOM or BREAK() IN LOOP  breaks before / after / in the loop
    // BREAK() IF(element.aantal==10)    breaks only when the condition is fulfilled. Note that the condition must
    //     reference the element explicitly!
    compiler.registerFunction("break", FnBreak);

    // target=AVG(source) or AVG(source) will calculate the average per level
    // target-AVG(source, GLOBAL) or AVG(source, GLOBAL) will evaluate the average "properly" from all the nodes lying under
    //     the current node. So if the tree looks like (tree (tree 1 1) (2) ), then the first form will have top average as 1.5
    //     (because (1 + 2) / 2 = 1.5, evalualed per level). The second firm will have the top average 1.333 ( (1 + 1 + 2) / 3 )
    // Note that the global variant requires property-type paramater, and does not support IF
    compiler.registerFunction("avg", FnAvg);

    // target=LOOKUP(source)  OR target=LOOKUP() store the lookup into field "target". If source is missing, use the pivot level's column (must be present)
    // *target=LOOKUP(source)  OR *target=LOOKUP()  store the lookup into field like activitiesTarget; the prefix is based
    //      on the "store" configuration of the level
    compiler.registerFunction("lookup", FnLookup);

    // SET gathers values of the specified property and convertes them into histogram-like dictionary (id:count), which can
    // be used to quickly check if given value exists in the node.
    // target=SET(source) OR SET(source) or target=SET(source,type) or SET(source,type), where type can be one of the following
    // FULL: merge sets from lower levels, and set for current level. This requires target to be set, and different from source
    // YES or MERGE: merge sets from lower levels.
    // NO: do not merge, compute each level individually. This is default if type is missing
    // FULL and NO make sense only if the source field exists on all levels, YES makes sense if it exists only on the leafs.
    // Note that the source must be property
    // Note that only the NO variant currently supports IF condition
    compiler.registerFunction("set", FnSet);

    // target=DIV(source1, source2) safely divides (if source2==0, result is 0). By default this is done only on the parent node
    // target=DIV(source1, source2) AT NODES does the division per element instead of the parent
    // target=DIV(source1, source2) AT NODES,PARENT does the division both on elements and on the parent
    // target=DIV(source1, source2) AT PARENT is the same like no options, this is the default
    // target=DIV(source1, source2, default) overwrites the default value (0) for situations where source2 is 0
    // target=DIV(source1, source2, default, epsilon) overwrites the epsilon value, which defaults to 1e-12
    // if OPTIONS(infinity) is specified, the funciton will allow +/- infinity results: that is, the default value will be
    //    returned only if both source1 and source2 are zero.
    // Note: either parameter can be "global" (not just property)
    // Note that IF condition is supported, though it's value is questionable (when condition fails, no assignment happens)
    compiler.registerFunction("div", FnDiv);

    // target=GROWTH(source1, source2) calculates source1/source2 - 1. If source2 is 0, returns 0.
    // target=GROWTH(source1, source2, default) overwrites the default value (0) for situations where source2 is 0
    // target=GROWTH(source1, source2, default, epsilion) overwrites the epsilon (which is normally 1e-12)
    // For the rest of the options, see DIV (including infinity handling)
    compiler.registerFunction("growth", FnGrowth);

    // target = MUL(source1, source2) multiplies the two. See DIV for other options
    compiler.registerFunction("mul", FnMul);

    // target = ADD(source1, source2) multiplies the two. See DIV for other options.
    compiler.registerFunction("add", FnAdd);

    // target = DIFF(source1, source2) subtracts. See DIV for other options
    compiler.registerFunction("DIFF", FnDiff);

    // target = ABSDIFF(source1, source2) abs difference of those 2. See DIV for other options
    compiler.registerFunction("ABSDIFF", FnAbsDiff);

    // target = ADDMUL(source1, source2, source3) adds the first 2 operants, and multiplies the result by third.
    // Conditions is supported, as are the 3 basic locations (nodes / parent / totals)
    compiler.registerFunction("addmul", FnAddMul);

    // target = DIFFMUL(source1, source2, source3) evaluates (source1-source2) * source3
    // Conditions is supported, as are the 3 basic locations (nodes / parent / totals)
    compiler.registerFunction("diffmul", FnDiffMul);

    // target = SELECT(selector, value1, value2, ..)  picks the value based on the selector. At least 2 values are required.
    //   both selector and the values can be globals
    // Supports the same options as DIV
    compiler.registerFunction("SELECT", FnSelect);

    // target = BOOLEAN(source) or BOOLEAN(source), converts the source to boolean. See DIV for the options
    compiler.registerFunction("BOOLEAN", FnBoolean);

    // target = EVAL([expression]). This always expects global expression. See DIV for the options. Note that you can use $NODE instead
    // of store/element if you want to be able to run on both parent and nodes
    compiler.registerFunction("EVAL", FnEval);

    // target = LET([expression]) initializes local variable target with the expression.
    // target=LET([expression]) IN LOOP initializes in-loop variable target with the expression
    // I either way, the expression should be global.
    compiler.registerFunction("LET", FnLet);

    // target = DELETE(property, ...). Deletes the specified property/properties. Target serves only for ID,
    // and defaults to DELETE. See DIV for the options
    compiler.registerFunction("DELETE", FnDelete);

    // target = IDENTITY(). This doesn't produce any code, but can be used as placeholder for merging
    // target = IDENTITY(source) This assigns the source to the parent. NODES/PARENT locations, and condition are supported
    // Note: the compiler can recognize simple assignment in the form "target = source" (including optional location/condition)
    //   and convert it to identity call. However, unlike direct call to identity, only local expression is supported (
    //   source must be single identifier)
    compiler.registerFunction("IDENTITY", FnIdentity);

    // target = VALUEIFSAME(source). If the expression is identical for all the nodes, the expression will be returned,
    // otherwise undefined is assigned to the target. Conditions are supported.
    compiler.registerFunction("VALUEIFSAME", FnValueIfSame);

    // target = AVGWEIGHTED(valueSource, weightSource)". Calculates the weighted average of value. Conditions are supported. Because
    // of the weighting, global variant (like AVG has) is not needed. Conditions are supported. Target is optional if valueSource is local expression
    compiler.registerFunction("AVGWEIGHTED", FnAvgWeighted);

    // target = NOOP()  is empty function, used only to eliminiate the equation from lower level
}
