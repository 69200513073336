/* eslint-disable @typescript-eslint/no-this-alias */
import * as d3 from "d3";
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    isDevMode,
    NgZone,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    TemplateRef,
    ViewChild,
    inject,
    ViewContainerRef
} from "@angular/core";
import { TemplatePortal } from "@angular/cdk/portal";
import {
    ILgFormatter,
    ILgFormatterOptions,
    LgConsole,
    LgFormatterFactoryService
} from "@logex/framework/core";
import { LgSimpleChanges } from "@logex/framework/types";
import { D3TooltipApi, ID3TooltipOptions, LgD3TooltipService } from "../d3";
import { getDefaultLegendOptions } from "../shared/getDefaultLegendOptions";
import {
    ChartClickEvent,
    LegendItem,
    LegendOptions,
    Margin,
    PointReference
} from "../shared/chart.types";
import { getRecommendedPosition } from "../shared/getRecommendedPosition";
import { getLegendWidth } from "../shared/getLegendWidth";
import { doCirclePointsOverlap } from "../shared/doCirclePointsOverlap";
import { coerceNumberProperty } from "@angular/cdk/coercion";
import {
    LgScatterChartBoundaries,
    LgScatterChartDatum,
    LgScatterChartTooltipContext
} from "./lg-scatter-chart.types";
import { useTranslationNamespace } from "@logex/framework/lg-localization";
import { IExportableChart, LgChartExportContainerDirective } from "../shared/lg-chart-export";
import {
    IChartTooltipProvider,
    IImplicitContext
} from "../shared/lg-chart-template-context.directive";

const DEFAULT_MARGIN: Margin = { top: 16, right: 16, bottom: 16, left: 16 };

const DEFAULT_TICKS_FORMATTER_OPTIONS: ILgFormatterOptions = { decimals: 0 };
const DEFAULT_TOOLTIP_FORMATTER_OPTIONS: ILgFormatterOptions = { decimals: 0 };

const SPACE_BETWEEN_LABELS_AND_GRID = 8;
const Y_AXIS_TITLE_WIDTH = 20;

const X_AXIS_LABELS_LINE_HEIGHT = 20;
const SPACE_FOR_LEGEND_BELOW = 30;

const DEFAULT_TICK_COUNT = 5;
const DEFAULT_Y_AXIS_LABELS_WIDTH = 30;
const Y_AXIS_TITLE_OFFSET = 30;

const POINT_RADIUS = 4;
const SHADOW_RADIUS = 12;
const SHADOW_COLOR = "#F2FAFF";
const SHADOW_STROKE = 0;

const DATA_POINT_SHADOW_SELECTOR = "shadow-point";
const DATA_POINT_SELECTOR = "point";

function fill(
    element: d3.Selection<any, any, any, any>
): (color: string) => d3.Selection<any, any, any, any> {
    return (color: string) => element.attr("fill", color);
}

@Component({
    standalone: false,
    selector: "lg-scatter-chart",
    templateUrl: "./lg-scatter-chart.component.html",
    viewProviders: [useTranslationNamespace("FW._Directives._Charts._LgScatterPlot")],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LgScatterChartComponent
    implements
        OnInit,
        OnChanges,
        OnDestroy,
        AfterViewInit,
        IExportableChart,
        IChartTooltipProvider<LgScatterChartTooltipContext>
{
    private _elementRef = inject(ElementRef);
    private _exportContainer = inject(LgChartExportContainerDirective, { optional: true });
    private _formatterFactory = inject(LgFormatterFactoryService);
    private _lgConsole = inject(LgConsole).withSource("Logex.Charts.LgScatterChart");
    private _ngZone = inject(NgZone);
    private _renderer = inject(Renderer2);
    private _tooltipService = inject(LgD3TooltipService);
    private _viewContainerRef = inject(ViewContainerRef);
    /**
     * @required
     * Specifies the width of the chart area in pixels.
     */
    @Input({ required: true }) width!: number;
    /**
     * @required
     * Specifies the height of the chart area in pixels.
     */
    @Input({ required: true }) height!: number;
    /**
     * @required
     * Specifies the input data for scatter chart.
     */
    @Input({ required: true }) data!: LgScatterChartDatum[];
    /**
     * Specifies the margin for chart.
     * Defaults to 16 on all sides.
     *
     * @property { number } top specifies the margin of SVG from top.
     * @property { number } right specifies the margin of SVG from right.
     * @property { number } bottom specifies the margin of SVG from bottom.
     * @property { number } left specifies the margin of SVG from left.
     */
    @Input() margin?: Margin;
    /**
     * Specifies maximum number of ticks on axis. Defaults to 5.
     *
     * @default 5
     */
    @Input() tickCount?: number;
    /**
     * Specifies the Y axis title. Defaults to "Y axis title not defined".
     * Can receive empty string to hide Y axis title entirely.
     */
    @Input() yAxisLabel?: string;
    /**
     * Specifies the X axis title. Defaults to "X axis title not defined".
     * Can receive empty string to hide X axis title entirely.
     */
    @Input() xAxisLabel?: string;
    /**
     * Specifies whether X axis labels are visible or not. Defaults to true.
     *
     * @default true
     */
    @Input() showXAxisLabels = true;
    /**
     * Specifies whether Y axis labels are visible or not. Defaults to true.
     *
     * @default true
     */
    @Input() showYAxisLabels = true;
    /**
     * Specifies the width of the Y-axis labels in pixels. Defaults to 30.
     *
     * @default 30
     */
    @Input() yAxisLabelsWidth!: number;
    /**
     * @optional
     * Specifies formatter type for X axis. Defaults to "float"
     *
     * @default "float"
     */
    @Input() xAxisFormatterType?: string;
    /**
     * @optional
     * Specifies the options for X axis formatter. Defaults to 0 decimals.
     *
     * @default 0 decimals
     */
    @Input() xAxisFormatterOptions?: ILgFormatterOptions;
    /**
     * @optional
     * Specifies formatter type for Y axis. Defaults to "percentage"
     *
     * @default "percentage"
     */
    @Input() yAxisFormatterType?: string;
    /**
     * @optional
     * Specifies the options for Y axis formatter. Defaults to 0 decimals.
     *
     * @default 0 decimals
     */
    @Input() yAxisFormatterOptions?: ILgFormatterOptions;
    /**
     * @optional
     * Specifies the legend options. If not specified, legend is not visible.
     */
    @Input() legendOptions: LegendOptions = getDefaultLegendOptions();
    /**
     * Specifies the function to return color of group based on id.
     */
    @Input() groupColorFn!: (datum: LgScatterChartDatum) => string;
    /**
     * @optional
     * Specifies the function to return name of group based on id.
     */
    @Input() groupNameFn?: (datum: LgScatterChartDatum) => string;
    /**
     * @optional
     * Specifies class of tooltip. Default to empty.
     */
    @Input() tooltipClass?: string;
    /**
     * @optional
     * Specifies the template to be used when hovering over elements. Defaults to own template.
     */
    @Input() tooltipTemplate?: TemplateRef<IImplicitContext<LgScatterChartTooltipContext>>;
    /**
     * @optional
     * Specifies formatter type for tooltip numbers. Defaults to "percent".
     *
     * @default "percent"
     */
    @Input() tooltipFormatterTypeYAxis?: string;
    /**
     * @optional
     * Specifies the options for Y axis formatter. Defaults to 0 decimals.
     *
     * @default 0 decimals
     */
    @Input() tooltipFormatterOptionsYAxis?: ILgFormatterOptions;
    /**
     * @optional
     * Specifies formatter type for tooltip numbers. Defaults to "float"
     *
     * @default "float"
     */
    @Input() tooltipFormatterTypeXAxis?: string;
    /**
     * @optional
     * Specifies the options for number axis formatter. Defaults to 0 decimals.
     *
     * @default 0 decimals
     */
    @Input() tooltipFormatterOptionsXAxis?: ILgFormatterOptions;
    /**
     * @optional
     * Specifies whether points are clickable.
     *
     * @default false
     */
    @Input() clickable = false;
    /**
     * @optional
     * Specifies the maximum value on X axis.
     * If not specified, maximum value is calculated from data.
     */
    @Input() xMax?: number;
    /**
     * @optional
     * Specifies the minimum value on X axis.
     * If not specified, minimum value is calculated from data.
     */
    @Input() xMin?: number;
    /**
     * @optional
     * Specifies the minimum value on Y axis.
     * If not specified, minimum value is calculated from data.
     */
    @Input() yMax?: number;
    /**
     * @optional
     * Specifies the maximum value on Y axis.
     * If not specified, maximum value is calculated from data.
     */
    @Input() yMin?: number;

    /**
     * Emits data in clicked box, if the clickable input is set to true.
     */
    @Output() readonly itemClick = new EventEmitter<
        ChartClickEvent<LgScatterChartDatum, LgScatterChartDatum>
    >();

    /**
     * Emits data in clicked legend item.
     */
    @Output() readonly legendClick = new EventEmitter<LegendItem>();

    @ViewChild("chart", { static: true }) private _chartHolder!: ElementRef<HTMLDivElement>;
    @ViewChild("chartWithLegend", { static: true })
    private _chartWithLegendDivRef!: ElementRef<HTMLDivElement>;

    @ViewChild("defaultTemplate", { static: true })
    private _defaultTooltipTemplate!: TemplateRef<IImplicitContext<LgScatterChartTooltipContext>>;

    _width!: number;
    _height!: number;
    _margin!: Margin;
    private _data: LgScatterChartDatum[] = [];

    private _svg!: d3.Selection<any, any, any, any>;
    private _svgG!: d3.Selection<any, LgScatterChartDatum, any, any>;
    private _yScale!: d3.ScaleLinear<number, number>;
    private _xScale!: d3.ScaleLinear<number, number>;
    private _xAxisGroup!: d3.Selection<any, any, any, any>;
    private _yAxisGroup!: d3.Selection<any, any, any, any>;
    private _yAxisGridG!: d3.Selection<any, any, any, any>;
    private _xAxisGridG!: d3.Selection<any, any, any, any>;
    private _dataPointsShadowG!: d3.Selection<any, LgScatterChartDatum, any, any>;
    private _dataPointsShadow!: d3.Selection<any, any, any, any>;
    private _dataPointsG!: d3.Selection<any, LgScatterChartDatum, any, any>;
    private _dataPoints!: d3.Selection<any, any, any, any>;

    private _yAxisLabel!: d3.Selection<any, any, any, any>;
    private _xAxisLabel!: d3.Selection<any, any, any, any>;
    private _spaceForYAxisLabels!: number;
    private _groupNames: string[] = [];

    _tooltipFormatterYAxis!: ILgFormatter<any>;
    _tooltipFormatterXAxis!: ILgFormatter<any>;
    _xAxisFormatter!: ILgFormatter<any>;
    _yAxisFormatter!: ILgFormatter<any>;
    private _yAxisFormat!: (x: number) => string;
    private _xAxisFormat!: (x: number) => string;

    private _trackListener!: () => void;
    private _tooltip?: D3TooltipApi;
    private _tooltipContext: LgScatterChartTooltipContext | null = null;
    private _tooltipPortal?: TemplatePortal<IImplicitContext<LgScatterChartTooltipContext>>;

    get tooltipContext(): LgScatterChartTooltipContext | null {
        return this._tooltipContext;
    }

    set tooltipContext(context: LgScatterChartTooltipContext) {
        if (this._tooltipPortal?.context) {
            this._tooltipPortal.context.$implicit = context;
        }
        this._tooltipContext = context;
    }

    private _lastMouseY = 0;
    private _lastMouseX = 0;

    private _initialized = false;

    private _pointRefs!: Array<PointReference<LgScatterChartDatum>>;
    private _shadowPointRefs!: Array<PointReference<LgScatterChartDatum>>;
    private _overlappingPointRefs!: Array<PointReference<LgScatterChartDatum>>;
    private _overlappingShadowPointRefs!: Array<PointReference<LgScatterChartDatum>>;

    private _boundaries!: LgScatterChartBoundaries;

    _legendDefinition: LegendItem[] = [];
    _legendWidth: number | null = null;
    _legendPaddingBottom = 0;

    ngOnInit(): void {
        this._setDefaultProperties();
        this._initializeTooltip();
        this._trackMousePosition();
        this._triggerDataSpecificMethods();
    }

    ngAfterViewInit(): void {
        this._exportContainer?.register(this);
        this._render();
        this._initialized = true;
    }

    ngOnChanges(changes: LgSimpleChanges<LgScatterChartComponent>): void {
        if (!this._initialized) return;

        if (
            changes.data ||
            changes.groupColorFn ||
            changes.groupNameFn ||
            changes.xMin ||
            changes.xMax ||
            changes.yMin ||
            changes.yMax
        ) {
            this._triggerDataSpecificMethods();
        }

        if (changes.width || changes.height || changes.tickCount) {
            this._width = this.width;
            this._height = this.height;
        }

        if (changes.yAxisLabelsWidth) {
            this._spaceForYAxisLabels =
                this.yAxisLabelsWidth == null ? DEFAULT_Y_AXIS_LABELS_WIDTH : this.yAxisLabelsWidth;
        }

        if (changes.xAxisLabel) {
            this._xAxisLabel.text(this.xAxisLabel ?? "");
        }

        if (changes.margin) this._margin = this.margin!;

        if (changes.yAxisLabel) {
            this._yAxisLabel.text(this.yAxisLabel ?? "");
        }

        if (changes.legendOptions) {
            this._updateLegend();
        }

        if (this._svg) this._svg.remove();
        this._setDefaultHoverOverlapRefs();
        this._render();
    }

    ngOnDestroy(): void {
        this._exportContainer?.unregister(this);
    }

    getHtmlElement(): HTMLElement {
        return this._chartWithLegendDivRef.nativeElement;
    }

    getSvgElement(): SVGElement {
        return this._svg.node();
    }

    private _setDefaultProperties(): void {
        this.tooltipTemplate = this.tooltipTemplate ?? this._defaultTooltipTemplate;

        this._margin = this.margin == null ? DEFAULT_MARGIN : this.margin;
        this.tooltipFormatterTypeYAxis = this.tooltipFormatterTypeYAxis || "percent";
        this.tooltipFormatterTypeXAxis = this.tooltipFormatterTypeXAxis || "float";
        this.tooltipFormatterOptionsYAxis = {
            ...DEFAULT_TOOLTIP_FORMATTER_OPTIONS,
            ...this.tooltipFormatterOptionsYAxis
        };
        this._tooltipFormatterYAxis = this._formatterFactory.getFormatter(
            this.tooltipFormatterTypeYAxis,
            this.tooltipFormatterOptionsYAxis
        );
        this.tooltipFormatterOptionsXAxis = {
            ...DEFAULT_TOOLTIP_FORMATTER_OPTIONS,
            ...this.tooltipFormatterOptionsXAxis
        };
        this._tooltipFormatterXAxis = this._formatterFactory.getFormatter(
            this.tooltipFormatterTypeXAxis,
            this.tooltipFormatterOptionsXAxis
        );

        this.xAxisFormatterType = this.xAxisFormatterType || "float";
        this.yAxisFormatterType = this.yAxisFormatterType || "percent";
        this.xAxisFormatterOptions = {
            ...DEFAULT_TICKS_FORMATTER_OPTIONS,
            ...this.xAxisFormatterOptions
        };
        this.yAxisFormatterOptions = {
            ...DEFAULT_TICKS_FORMATTER_OPTIONS,
            ...this.yAxisFormatterOptions
        };
        this._xAxisFormatter = this._formatterFactory.getFormatter(
            this.xAxisFormatterType,
            this.xAxisFormatterOptions
        );
        this._yAxisFormatter = this._formatterFactory.getFormatter(
            this.yAxisFormatterType,
            this.yAxisFormatterOptions
        );

        this.tooltipTemplate = this.tooltipTemplate || this._defaultTooltipTemplate;

        this.tickCount = this.tickCount == null ? DEFAULT_TICK_COUNT : this.tickCount;
        this._spaceForYAxisLabels =
            this.yAxisLabelsWidth == null ? DEFAULT_Y_AXIS_LABELS_WIDTH : this.yAxisLabelsWidth;
        this._xAxisFormat = x => this._xAxisFormatter.format(x);
        this._yAxisFormat = x => this._yAxisFormatter.format(x);
        this.legendOptions =
            this.legendOptions == null ? getDefaultLegendOptions() : this.legendOptions;
        this._width = this.width;
        this._height = this.height;
        this.showXAxisLabels = this.showXAxisLabels == null ? true : this.showXAxisLabels;
        this.showYAxisLabels = this.showYAxisLabels == null ? true : this.showYAxisLabels;
        this.groupColorFn = this.groupColorFn == null ? () => "#69b3a2" : this.groupColorFn;
        this._groupNames = [];
        this._setDefaultHoverOverlapRefs();
    }

    private _setDefaultHoverOverlapRefs(): void {
        this._pointRefs = [];
        this._shadowPointRefs = [];
        this._overlappingPointRefs = [];
        this._overlappingShadowPointRefs = [];
    }

    private _initializeTooltip(): void {
        this._tooltipPortal = this._getTooltipContent();
        const commonOptions: ID3TooltipOptions = {
            stay: false,
            trapFocus: false,
            position: "top-left",
            tooltipClass: `lg-tooltip lg-tooltip--d3 ${
                this.tooltipClass ? " " + this.tooltipClass : ""
            }`,
            panelClass: "chart-overlay",
            content: this._tooltipPortal,
            delayHide: 150,
            target: this._elementRef
        };
        this._tooltip = this._tooltipService.create({
            ...commonOptions
        });
    }

    private _getTooltipContent(): TemplatePortal<IImplicitContext<LgScatterChartTooltipContext>> {
        return new TemplatePortal<IImplicitContext<LgScatterChartTooltipContext>>(
            this.tooltipTemplate!,
            this._viewContainerRef,
            { $implicit: this.tooltipContext! }
        );
    }

    private _trackMousePosition(): void {
        this._ngZone.runOutsideAngular(() => {
            this._trackListener = this._renderer.listen(
                this._elementRef.nativeElement,
                "mousemove",
                (event: MouseEvent) => {
                    this._lastMouseX = event.clientX;
                    this._lastMouseY = event.clientY;
                    this._updateTooltipPosition();
                }
            );
        });
    }

    private _updateTooltipPosition(): void {
        if (!this._tooltip || !this._tooltip.visible) return;
        this._tooltip.setPositionAt(
            this._lastMouseX,
            this._lastMouseY,
            getRecommendedPosition(
                { x: this._lastMouseX, y: this._lastMouseY },
                this._tooltip.getOverlayElement()
            )
        );
    }

    private _triggerDataSpecificMethods(): void {
        if (this.data == null || this.data.length === 0) {
            if (isDevMode()) {
                this._lgConsole.warn(
                    "No data are available for rendering. Stopping code execution"
                );
            }
            return;
        }
        this._convertData();
        this._updateLegend();
    }

    private _convertData(): void {
        this._data = [...this.data];
    }

    private _updateLegend(): void {
        this._setLegendDefinition();
        this._setLegendSize();
    }

    private _setLegendDefinition(): void {
        this._legendDefinition = [];
        this._groupNames = [];
        const alreadyHasGroup: Record<string | number, boolean> = {};
        this._data.forEach(d => {
            if (alreadyHasGroup[d.groupId]) return;
            const name = this.groupNameFn ? this.groupNameFn(d) : "";
            this._groupNames.push(name);
            this._legendDefinition.push({
                color: this._getColor(d, false),
                name,
                opacity: 1
            });

            alreadyHasGroup[d.groupId] = true;
        });
    }

    private _setLegendSize(): void {
        const legendVisible = this.legendOptions!.visible;
        const legendBelow = legendVisible && this.legendOptions!.position === "bottom";
        const legendSize = this._getLegendSize();
        const legendOnTheRight = legendVisible && this.legendOptions!.position === "right";

        const spaceBelowAxis =
            (this._margin.bottom ?? 0) + (legendBelow ? legendSize : 0) + X_AXIS_LABELS_LINE_HEIGHT;

        this._legendWidth = legendOnTheRight ? legendSize : null;
        this._legendPaddingBottom = spaceBelowAxis ? spaceBelowAxis : 0;
    }

    private _render(): void {
        if (this._data == null || this._data.length === 0) {
            if (isDevMode()) {
                this._lgConsole.warn(
                    "No data are available for rendering. Stopping code execution"
                );
            }
            return;
        }
        this._drawMainSVGHolder();
        this._initializeScales();
        this._drawAxes();
        this._drawPoints();
    }

    private _drawMainSVGHolder(): void {
        this._svg = d3
            .select(this._chartHolder.nativeElement)
            .append("svg")
            .attr("width", this._svgWidth)
            .attr("height", this._svgHeight);

        this._svgG = this._svg.append("g");
    }

    private _initializeScales(): void {
        this._boundaries = this._getBoundaries();

        this._yScale = d3
            .scaleLinear()
            .range([this._verticalPositionOfXAxis, this._margin.top ?? 0])
            .domain([this._boundaries.yMin, this._boundaries.yMax])
            .nice();
        this._xScale = d3
            .scaleLinear()
            .range([this._horizontalPositionOfYAxis, this._svgWidth - (this._margin.right ?? 0)])
            .domain([this._boundaries.xMin, this._boundaries.xMax])
            .nice();
    }

    private _getBoundaries(): LgScatterChartBoundaries {
        const xValues = this._data.map(i => i.xValue);
        const yValues = this._data.map(i => i.yValue);
        const xMin = this.xMin ?? Math.min(...xValues);
        const yMin = this.yMin ?? Math.min(...yValues);
        const xMax = this.xMax ?? Math.max(...xValues);
        const yMax = this.yMax ?? Math.max(...yValues);
        return { yMin, yMax, xMin, xMax };
    }

    private _drawAxes(): void {
        this._addYAxis();
        this._addYAxisGrid();
        this._addXAxis();
        this._addXAxisGrid();
    }

    private _addYAxis(): void {
        this._yAxisGroup = this._svgG
            .append("g")
            .attr("class", "y__axis")
            .attr("transform", `translate(${this._horizontalPositionOfYAxis}, 0)`);
        this._yAxisLabel = this._svgG
            .append("text")
            .attr("class", "axis__title y__axis")
            .text(this.yAxisLabel ?? "")
            .attr(
                "transform",
                `translate(${this._margin.left}, ${
                    this._verticalPositionOfXAxis - Y_AXIS_TITLE_OFFSET
                }) rotate(-90)`
            );

        this._yAxisGroup.transition().duration(250).call(this._getYAxis());

        this._yAxisGroup
            .selectAll(".tick text")
            .transition()
            .duration(250)
            .attr("transform", `translate(${-SPACE_BETWEEN_LABELS_AND_GRID}, 0)`);
    }

    private _getYAxis(): d3.Axis<d3.NumberValue> {
        return d3
            .axisLeft<any>(this._yScale)
            .tickSize(0)
            .tickPadding(3)
            .tickFormat(this._yAxisFormat)
            .ticks(coerceNumberProperty(this.tickCount, 5));
    }

    private _addYAxisGrid(): void {
        this._yAxisGridG = this._svgG
            .append("g")
            .attr("class", "y__axis__grid")
            .attr("transform", `translate(${this._horizontalPositionOfYAxis}, 0)`);

        this._yAxisGridG.transition().duration(250).call(this._getYAxisGrid(this._yScale));
    }

    private _getYAxisGrid(scale: d3.ScaleLinear<number, number>): d3.Axis<d3.NumberValue> {
        return d3
            .axisRight(scale)
            .tickPadding(0)
            .tickFormat(() => "")
            .ticks(coerceNumberProperty(this.tickCount, 5))
            .tickSizeOuter(0)
            .tickSizeInner(
                this._svgWidth - this._horizontalPositionOfYAxis - (this._margin.right ?? 0)
            );
    }

    private _addXAxis(): void {
        this._xAxisGroup = this._svgG
            .append("g")
            .attr("class", "x__axis")
            .attr("transform", `translate(0,${this._verticalPositionOfXAxis})`);

        this._xAxisLabel = this._svgG
            .append("text")
            .attr("class", "axis__title x__axis")
            .text(this.xAxisLabel ?? "")
            .attr("text-anchor", "start")
            .attr(
                "transform",
                `translate(
                    0,
                    ${this._verticalPositionOfXAxis}
                )`
            );

        this._xAxisGroup.transition().duration(250).call(this._getXAxis());

        this._xAxisGroup
            .selectAll(".tick text")
            .transition()
            .duration(250)
            .attr("transform", `translate(0, ${SPACE_BETWEEN_LABELS_AND_GRID})`);
    }

    private _getXAxis(): d3.Axis<d3.NumberValue> {
        return d3
            .axisBottom<any>(this._xScale)
            .tickSize(0)
            .tickPadding(3)
            .tickFormat(this._xAxisFormat)
            .ticks(this.tickCount);
    }

    private _addXAxisGrid(): void {
        this._xAxisGridG = this._svgG
            .append("g")
            .attr("class", "x__axis__grid")
            .attr("transform", `translate(0, 8) rotate(-90)`);

        this._xAxisGridG.transition().duration(250).call(this._getXAxisGrid(this._xScale));
    }

    private _getXAxisGrid(scale: d3.ScaleLinear<number, number>): d3.Axis<d3.NumberValue> {
        return d3
            .axisLeft(scale)
            .tickPadding(0)
            .tickFormat(() => "")
            .ticks(this.tickCount)
            .tickSizeOuter(0)
            .tickSizeInner(this._svgHeight - this._xAxisLabelsHeight - (this._margin.top ?? 0));
    }

    private _drawPoints(): void {
        this._dataPointsShadowG = this._svgG.append("g");
        this._drawShadowPoints();
        this._dataPointsG = this._svgG.append("g").attr("class", "lg-scatter-chart__data-points");
        this._drawDataPoints();
    }

    private _drawShadowPoints(): void {
        const self = this;

        this._dataPointsShadow = this._dataPointsShadowG
            .selectAll(DATA_POINT_SHADOW_SELECTOR)
            .data(this._data)
            .enter();

        this._dataPointsShadow
            .append("circle")
            .attr("cx", d => this._xScale(d.xValue))
            .attr("cy", d => this._yScale(d.yValue))
            .attr("stroke-width", SHADOW_STROKE)
            .attr("r", SHADOW_RADIUS)
            .attr("fill", "transparent")
            .style("opacity", 1)
            .on("mousemove", function (event: MouseEvent) {
                self._onMouseOverPointShadow(d3.select(this), d3.pointer(event));
            })
            .on("mouseout", function (_event: MouseEvent) {
                d3.select(this)
                    .style("cursor", self.clickable ? "pointer" : "default")
                    .attr("fill", "transparent");

                self._tooltip?.hide();
                self._clearOverlappingElements();
            })
            .each(function (d: LgScatterChartDatum) {
                const shadowPointRef = <PointReference<LgScatterChartDatum>>{
                    coordinates: [
                        Math.round(self._xScale(d.xValue)),
                        Math.round(self._yScale(d.yValue))
                    ],
                    fillElementWith: fill(d3.select(this)),
                    data: d
                };
                self._shadowPointRefs.push(shadowPointRef);
            })
            .on("click", function (_event: MouseEvent, d: LgScatterChartDatum) {
                const index = self._dataPointsShadow.nodes().indexOf(this);
                self._onClick(d, index);
            });
    }

    private _onMouseOverPointShadow(
        target: d3.Selection<SVGCircleElement, LgScatterChartDatum, null, undefined>,
        mousecoords: [number, number]
    ): void {
        this._clearOverlappingElements();

        this._overlappingPointRefs = this._pointRefs.filter(x =>
            doCirclePointsOverlap(mousecoords, x.coordinates)
        );
        this._overlappingShadowPointRefs = this._shadowPointRefs.filter(x =>
            doCirclePointsOverlap(mousecoords, x.coordinates)
        );

        this._overlappingPointRefs.forEach(d => d.fillElementWith(this._getColor(d.data, true)));
        this._overlappingShadowPointRefs.forEach(x => x.fillElementWith(SHADOW_COLOR));

        if (this._overlappingShadowPointRefs.length === 0) {
            this._tooltip?.hide();
            return;
        }

        const alreadyAddedGroups: Array<string | number> = [];
        const tooltipItems = this._overlappingShadowPointRefs.map(point => {
            const alreadyAdded = alreadyAddedGroups.indexOf(point.data.groupId) !== -1;
            if (!alreadyAdded) alreadyAddedGroups.push(point.data.groupId);

            return {
                datum: point.data
            };
        });

        const data = target.data();

        this.tooltipContext = {
            displayingItems: tooltipItems,
            activeItem: {
                column: data?.[0],
                group: data?.[0]
            }
        };

        this._tooltip?.show({ target: target.node() as any });
        this._updateTooltipPosition();
    }

    private _clearOverlappingElements(): void {
        this._overlappingPointRefs.forEach(element => {
            const color = this._getColor(element.data, false);
            element.fillElementWith(color);
        });
        this._overlappingShadowPointRefs.forEach(element => {
            element.fillElementWith("transparent");
        });
    }

    private _drawDataPoints(): void {
        const self = this;
        this._dataPoints = this._dataPointsG
            .selectAll(DATA_POINT_SELECTOR)
            .data(this._data)
            .enter()
            .append("circle")
            .attr("cx", d => this._xScale(d.xValue))
            .attr("cy", d => this._yScale(d.yValue))
            .attr("r", POINT_RADIUS)
            .attr("fill", d => this._getColor(d, false))
            .each(function (d: LgScatterChartDatum) {
                const pointRef = <PointReference<LgScatterChartDatum>>{
                    coordinates: [
                        Math.round(self._xScale(d.xValue)),
                        Math.round(self._yScale(d.yValue))
                    ],
                    fillElementWith: fill(d3.select(this)),
                    data: d
                };
                self._pointRefs.push(pointRef);
            });
    }

    private _getColor(datum: LgScatterChartDatum, isHover: boolean): string {
        const color = this.groupColorFn(datum);
        if (isHover) return d3.rgb(color).darker(0.2).formatHex();
        return d3.rgb(color).formatHex();
    }

    private _onClick(value: LgScatterChartDatum, itemIndex: number): void {
        if (!this.clickable) return;

        this.itemClick.emit({ item: value, datum: value, index: itemIndex });
    }

    _onLegendItemClick(item: LegendItem): void {
        this.legendClick.emit(item);
    }

    private get _verticalPositionOfXAxis(): number {
        const isLegendBelow = this.legendOptions.position === "bottom";
        const margin = isLegendBelow ? 10 : (this._margin.bottom ?? 0);
        const spaceForLabels = this.showXAxisLabels ? X_AXIS_LABELS_LINE_HEIGHT : 0;
        return this._svgHeight - margin - spaceForLabels;
    }

    private get _verticalPositionOfxAxisLabel(): number {
        const isLegendBelow = this.legendOptions.position === "bottom";
        return this._svgHeight - (isLegendBelow ? 5 : 0);
    }

    private get _horizontalPositionOfYAxis(): number {
        const spaceForLabels = this.showYAxisLabels ? this._spaceForYAxisLabels : 0;
        return (
            (this._margin.left ?? 0) +
            (this.yAxisLabel ? Y_AXIS_TITLE_WIDTH : 0) +
            spaceForLabels +
            SPACE_BETWEEN_LABELS_AND_GRID
        );
    }

    private get _xAxisLabelsHeight(): number {
        return X_AXIS_LABELS_LINE_HEIGHT + SPACE_BETWEEN_LABELS_AND_GRID;
    }

    private get _yAxisLabelsWidth(): number {
        return this._spaceForYAxisLabels + (this._margin.left ?? 0) + SPACE_BETWEEN_LABELS_AND_GRID;
    }

    private get _svgWidth(): number {
        const legendVisible = this.legendOptions.visible;
        const legendOnTheRight = legendVisible && this.legendOptions.position === "right";

        const legendSize = this._getLegendSize();
        const { left = 0, right = 0 } = this._margin;
        return this._width - left - right - (legendOnTheRight ? legendSize : 0);
    }

    get _svgHeight(): number {
        const legendVisible = this.legendOptions.visible;
        const legendBelow = legendVisible && this.legendOptions.position === "bottom";

        const { top = 0, bottom = 0 } = this._margin;
        const legendSize = this._getLegendSize();
        return this._height - top - bottom - (legendBelow ? legendSize : 0);
    }

    private _getLegendSize(): number {
        const isLegendBelow = this.legendOptions.position === "bottom";
        const isLegendOnRight = this.legendOptions.position === "right";
        if (!isLegendBelow && !isLegendOnRight) return 0;
        if (isLegendBelow) return SPACE_FOR_LEGEND_BELOW;
        return getLegendWidth(
            this._width,
            this.legendOptions.widthInPercents ?? 0,
            this._groupNames
        );
    }
}
