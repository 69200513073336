import { Injectable, inject } from "@angular/core";
import { Observable, catchError } from "rxjs";
import { LG_APP_CONFIGURATION, ServerGatewayBase } from "@logex/framework/lg-application";
import { IChatResponse, IChatConversation } from "./lg-chat.types";

@Injectable({ providedIn: "root" })
export class LgChatGateway extends ServerGatewayBase {
    constructor() {
        super();

        const appConfiguration = inject(LG_APP_CONFIGURATION);
        this._setBaseUrl(appConfiguration.applicationRoot);
    }

    getLastConversation(): Observable<IChatConversation | undefined> {
        return this._get<IChatConversation>("api/chat/get").pipe(
            catchError(error => {
                console.error(error);
                throw error;
            })
        );
    }

    sendMessage(text: string, chatId?: string | null): Observable<IChatResponse> {
        return this._post<IChatResponse>("api/chat/send", { body: { text, chatId } }).pipe(
            catchError(error => {
                console.error(error);
                throw error;
            })
        );
    }
}
