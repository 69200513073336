import { Observable, Subject, Subscription, of, isObservable, iif, from } from "rxjs";
import { map, share, switchMap, take, takeUntil, tap } from "rxjs/operators";

import {
    DialogCloseInitiator,
    DialogCloseOptions,
    IDialogOptions,
    OverridableDialogOptions
} from "./lg-dialog.types";
import { LgDialogHolderComponent } from "./lg-dialog-holder.component";
import { IOverlayResultApi } from "../lg-overlay";

export class LgDialogRef<T, R = any> {
    // an instance of a component which is passed in to lgDialogFactory as componentOrTemplateRef
    // and is used as a content of a dialog
    public componentInstance: T | undefined;

    public get visible(): boolean {
        return this._visible;
    }

    public get options(): IDialogOptions {
        return this._options;
    }

    public get isTop(): boolean {
        return this._overlay.isTop();
    }

    private _dying = false;
    private readonly _beforeClosed = new Subject<R | undefined>();
    private readonly _afterClosed = new Subject<R | undefined>();
    private _dialogResult: R | undefined;
    private _visible = false;
    private _escapeKeyPressSubscription: Subscription | null = null;
    private _backdropClickCloseSubscription: Subscription | null = null;

    constructor(
        readonly id: string,
        private _options: IDialogOptions,
        private _overlay: IOverlayResultApi,
        // an instance of LgDialogHolderComponent which is attached to an overlay
        public _holderInstance: LgDialogHolderComponent
    ) {
        this._holderInstance._requestClose
            .pipe(takeUntil(this._beforeClosed))
            .subscribe(() => this._tryClose({ initiator: "closeButton" }));
    }

    public close(dialogResult?: R, immediately?: boolean): void {
        if (this._dying) return;

        if (!this.visible) {
            console.warn(`The dialog ${this.id} is already hidden`);
            return;
        }

        this._dialogResult = dialogResult;
        this._dying = true;

        this._beforeClosed.next(this._dialogResult);
        this._beforeClosed.complete();

        if (this._options.onClose) {
            this._options.onClose(this);
        }

        this._visible = false;
        this._removeEscapeKeyCloseHandler();
        this._removeBackdropClickCloseHandler();

        if (immediately) {
            this._overlay.hide();
            this._afterClosed.next(this._dialogResult);
            this._afterClosed.complete();
        } else {
            this._overlay.overlayRef.detachBackdrop();
            this._holderInstance
                .hide()
                .pipe(take(1))
                .subscribe(() => {
                    this._overlay.hide();
                    this._afterClosed.next(this._dialogResult);
                    this._afterClosed.complete();
                });
        }
    }

    public updateOptions(options: OverridableDialogOptions): void {
        Object.assign(this._options, options);
        this._holderInstance.updateOptions(options);

        const closeOnEsc = this._options.closeOnEsc;
        if (closeOnEsc && !this._escapeKeyPressSubscription) {
            this._setEscapeKeyCloseHandler();
        } else if (!closeOnEsc && this._escapeKeyPressSubscription) {
            this._removeEscapeKeyCloseHandler();
        }
        const closeOnOverlayClick = this._options.closeOnOverlayClick;
        if (closeOnOverlayClick && !this._backdropClickCloseSubscription) {
            this._setBackdropClickCloseHandler();
        } else if (!closeOnOverlayClick && this._backdropClickCloseSubscription) {
            this._removeBackdropClickCloseHandler();
        }
    }

    public isTopLayer(): boolean {
        return this.visible && this._overlay.isTop();
    }

    public center(): void {
        this._holderInstance._center(true);
    }

    public maybeCenter(overridePosition = false): void {
        this._holderInstance._maybeCenter(overridePosition);
    }

    public beforeClosed(): Observable<R | undefined> {
        return this._beforeClosed.asObservable();
    }

    public afterClosed(): Observable<R | undefined> {
        return this._afterClosed.asObservable();
    }

    public keydownEvents(): Observable<KeyboardEvent> {
        return this._overlay.overlayRef.keydownEvents().pipe(takeUntil(this._beforeClosed));
    }

    private _tryClose(closeOptions: DialogCloseOptions): Observable<boolean> {
        // this is the case when dialog has `forceCloseOnNavigation` option set
        // so it has priority over `tryClose` logic
        const isCalledFromRouteGuard = closeOptions.initiator === "routeGuard";
        const shouldBeForciblyClosed =
            this._options.forceCloseOnNavigation && isCalledFromRouteGuard;

        if (this._options.tryClose && !shouldBeForciblyClosed) {
            const tryCloseResult = this._options.tryClose(this, closeOptions);
            let tryCloseResult$: Observable<boolean>;
            if (isObservable(tryCloseResult)) {
                tryCloseResult$ = tryCloseResult;
            } else if (tryCloseResult instanceof Promise) {
                tryCloseResult$ = from(tryCloseResult);
            } else {
                tryCloseResult$ = of(tryCloseResult as boolean);
            }
            const closeObservable$ = tryCloseResult$.pipe(
                tap(canBeClosed => {
                    if (canBeClosed) {
                        this.close(undefined, closeOptions.immediate);
                    }
                }),
                switchMap(wasClosed =>
                    iif(() => wasClosed, this._beforeClosed.pipe(map(() => true)), of(false))
                ),
                share() // execute pipeline once and share a result
            );
            // execute the chain cause returned observable
            // can be not subscribed in the outer code
            // and side effects won't be executed
            closeObservable$.subscribe();

            return closeObservable$;
        }

        this.close(undefined, closeOptions.immediate);
        // Here I subscribe to _beforeClosed because this is the observable which is listened to by dialodService for removing from stack
        // When I was using _afterClosed I faced many issues which lead to duplicate tryClose calls.
        return this._beforeClosed.pipe(map(() => true));
    }

    public finalizedOptions(options: IDialogOptions): void {
        this._options = options;
        if (this._options.closeOnEsc) {
            // it's expected that finalizedOptions() is called once per dialogRef creation
            this._setEscapeKeyCloseHandler();
        }
        if (this._options.closeOnOverlayClick) {
            this._setBackdropClickCloseHandler();
        }
        this._visible = true;
    }

    // More descriptive alias for finalizedOptions()
    public applyFinalizedOptions = this.finalizedOptions;

    private _setEscapeKeyCloseHandler(): void {
        this._escapeKeyPressSubscription = this.keydownEvents().subscribe((e: KeyboardEvent) => {
            if (e.key?.toLowerCase() !== "escape") {
                return;
            }

            if (
                document.activeElement instanceof HTMLInputElement ||
                document.activeElement instanceof HTMLTextAreaElement
            ) {
                document.activeElement.blur();
            } else {
                this._tryClose({ initiator: "escapeKeyPress" });
            }
        });
    }

    private _removeEscapeKeyCloseHandler(): void {
        this._escapeKeyPressSubscription?.unsubscribe();
        this._escapeKeyPressSubscription = null;
    }

    private _setBackdropClickCloseHandler(): void {
        this._backdropClickCloseSubscription = this._overlay.overlayRef
            .backdropClick()
            .pipe(takeUntil(this._beforeClosed))
            .subscribe(() => {
                this._tryClose({ initiator: "backdropClick" });
            });
    }

    private _removeBackdropClickCloseHandler(): void {
        this._backdropClickCloseSubscription?.unsubscribe();
        this._backdropClickCloseSubscription = null;
    }

    public tryClose(initiator?: DialogCloseInitiator): Observable<boolean> {
        return this._tryClose({ initiator, immediate: true });
    }
}
