import * as d3 from "d3";

export function getLegendWidth(
    totalWidthInPx: number,
    maxAllowedWidthOfLegendInPercents: number,
    options: Array<string | null> = [],
    staticWidth?: number
): number {
    if (staticWidth) return staticWidth;

    const maxWidthInPx = Math.round((totalWidthInPx * maxAllowedWidthOfLegendInPercents) / 100);

    return Math.max(Math.min(maxWidthInPx, longestLegendRow(options)), 0);
}

function longestLegendRow(items: Array<string | null> = []): number {
    if (!items) return 0;
    const fakeLegend = d3.select("body").append("div").attr("class", "fake-legend");

    let maxWidth = 0;

    fakeLegend
        .selectAll("span")
        .data(items)
        .enter()
        .append("span")
        .text(d => d)
        .each(function () {
            maxWidth = Math.max(maxWidth, (this as HTMLElement).getBoundingClientRect().width);
        });

    fakeLegend.remove();

    const legendPadding = 4 + 8; // left + right
    const legendItemPadding = 4;
    const circle = 10;
    const textPadding = 4;

    return 2 * legendPadding + 2 * legendItemPadding + circle + textPadding + maxWidth;
}
