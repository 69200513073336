export const PASSWORD_MIN_LENGTH = 8;

// make sure that X_OF_FOLLOWING_MUST_PASS <= following rules count (upper, lower, number, special)
// if X_OF_FOLLOWING_MUST_PASS is set to 0, following rules are not checked at all
export const X_OF_FOLLOWING_MUST_PASS = 3;
export const PASSWORD_CONTAIN_UPPER_LOWER_NUMBER = true; // contains 3 rules (upper, lower, number)
export const PASSWORD_CONTAIN_SPECIAL = true; // contains 1 rule (special character)

export const PASSWORD_SPECIAL_CHARS = [
    " ",
    "!",
    '"',
    "#",
    "\\$",
    "%",
    "&",
    "'",
    "\\(",
    "\\)",
    "\\*",
    "\\+",
    ",",
    "-",
    "\\.",
    "/",
    ":",
    ";",
    "<",
    "=",
    ">",
    "\\?",
    "@",
    "\\[",
    "\\\\",
    "\\]",
    "\\^",
    "_",
    "`",
    "{",
    "\\|",
    "}",
    "~"
];
