import { inject, Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild } from "@angular/router";

import { LG_USER_INFO } from "../user/user.types";
import { LG_APP_CONFIGURATION } from "../application/app-configuration";
import { LgNavigationService } from "./lg-navigation.service";

@Injectable({ providedIn: "root" })
export class RoleGuardService implements CanActivate, CanActivateChild {
    private _appConfiguration = inject(LG_APP_CONFIGURATION);
    private _navigationService = inject(LgNavigationService); // make sure the tree is processed
    private _userInfo = inject(LG_USER_INFO);

    constructor() {
        // empty
        // todo: add support for processing lazy loaded routes!
    }

    canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
        if (!(this._userInfo.userid ?? this._userInfo.id)) {
            // In case user is not logged in, redirecting to logout page (if configured)
            if (this._appConfiguration.logoutUrl != null) {
                window.location.replace(this._appConfiguration.logoutUrl);
            }

            return Promise.resolve(false);
        }

        return this._navigationService.canAccessRoute(route, false).then(value => {
            if (!value) {
                this._redirect();
                return false;
            } else {
                return true;
            }
        });
    }

    canActivateChild(route: ActivatedRouteSnapshot): Promise<boolean> {
        return this.canActivate(route);
    }

    private _redirect(): void {
        const accessible = this._navigationService.findFirstAccessibleNodeSync();
        if (accessible) {
            this._navigationService.navigateTo(accessible);
        } else {
            window.location.replace(this._appConfiguration.logoutUrl ?? "");
        }
    }
}
