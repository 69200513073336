<div
    class="{{ className || 'lg-dropdown' }} {{ _alignClass }}"
    [ngClass]="{
        'lg-dropdown--disabled': _isDropdownDisabled,
        'lg-dropdown--missing': !_assigned && !emptyAcceptable,
        'lg-dropdown--invalid': !isValid,
        'lg-dropdown--active': _active
    }"
    [attr.title]="_getTitle()"
    tabindex="{{ _allowFocus ? 0 : -1 }}"
    lgTableInputNavigator
    [lgMarkFocusOn]="markFocusOn"
>
    <div *ngIf="_currentValueIcons && _currentValueIcons.length" class="lg-dropdown__icons">
        <lg-icon
            *ngFor="let icon of _currentValueIcons"
            [icon]="icon.icon"
            [class]="icon.iconClass"
            [inline]="true"
            title="{{ icon.title }}"
            [lgTooltip]="icon.styledTitle ?? null"
            lgTooltipClass="lg-tooltip lg-tooltip--simple"
            [clickable]="!!icon.clickable"
            (click)="_multiIconClick($event, icon, current)"
        ></lg-icon>
    </div>
    <div
        class="lg-dropdown__text"
        [ngClass]="{ 'lg-dropdown__text--empty': !_assigned && emptyAcceptable }"
        [ngStyle]="{ paddingLeft: 18 * _currentValueIcons.length }"
    >
        {{ textOverride || _currentValueName }}
    </div>
    <lg-icon *ngIf="!_hideArrow" icon="icon-arrow-down" class="lg-dropdown__arrow"></lg-icon>
    <div *ngIf="!_hideArrow" style="width: 30px"></div>
</div>
