<div class="lg-snackbar">
    <div class="lg-snackbar__title">
        <span>{{ _title }}</span>
        <lg-icon
            *ngIf="options.hideCloseButton !== true"
            class="lg-snackbar__title__button"
            icon="icon-close"
            [clickable]="true"
            (click)="hide()"
        >
        </lg-icon>
    </div>
    <div class="lg-snackbar__text" *ngIf="_text">{{ _text }}</div>
    <div class="lg-snackbar__buttons" *ngIf="options.buttons">
        <lg-button
            *ngFor="let button of options.buttons"
            buttonClass="button--condensed button--{{ button.style ?? 'primary' }}"
            [text]="button.text"
            [textLc]="button.textLc"
            (click)="_onButtonClick(button)"
        >
        </lg-button>
    </div>
</div>
