import {
    SankeyInputLink,
    SankeyInputLinkLeft,
    SankeyInputLinkRight
} from "./lg-sankey-chart.types";

export function isLinkLeft(link: SankeyInputLink): link is SankeyInputLinkLeft {
    return "left" in link;
}

export function isLinkRight(link: SankeyInputLink): link is SankeyInputLinkRight {
    return "right" in link;
}
