/* eslint-disable @angular-eslint/no-conflicting-lifecycle */
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    DoCheck,
    ElementRef,
    HostBinding,
    inject,
    Input,
    OnChanges,
    ViewEncapsulation
} from "@angular/core";
import { EMPTY, Subject, switchMap } from "rxjs";
import { LgSimpleChanges } from "@logex/framework/types";
import {
    GridNodeType,
    HeaderRow,
    ILgPanelComponent,
    LgEffectivePanelState,
    LgPanelGridNode,
    LgPanelGridService
} from "../service";
import { NgForOf, NgIf, NgTemplateOutlet } from "@angular/common";
import { LgPanelResizeMenuComponent } from "./lg-panel-resize-menu.component";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
    selector: "lg-panel-grid-node",
    templateUrl: "./lg-panel-grid-node.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [NgIf, NgTemplateOutlet, NgForOf, LgPanelResizeMenuComponent],
    encapsulation: ViewEncapsulation.None
})
// eslint-disable-next-line @angular-eslint/no-conflicting-lifecycle
export class LgPanelGridNodeComponent implements OnChanges, DoCheck {
    private _changeDetectorRef = inject(ChangeDetectorRef);
    private _elementRef = inject(ElementRef<HTMLElement>);
    private _gridService = inject(LgPanelGridService);

    /**
     * Panel grid node instance (required).
     */
    @Input({ required: true }) node!: LgPanelGridNode;

    @HostBinding("class") _class = "";

    @HostBinding("style.flexBasis.%") _flexBasis: number | null = null;
    @HostBinding("style.maxWidth.%") _maxWidth: number | null = null;
    @HostBinding("style.maxHeight.%") _maxHeight: number | null = null;

    _hasCollapsedParts = false;
    private _lastState: LgEffectivePanelState | null = null;
    private _attachedPanel$ = new Subject<ILgPanelComponent | null>();

    constructor() {
        this._gridService
            .stateChanges()
            .pipe(takeUntilDestroyed())
            .subscribe(() => {
                this._attachedPanel$.next(this.node.attachedPanel);
                this._changeDetectorRef.markForCheck();
            });

        this._attachedPanel$
            .pipe(
                switchMap(panel => (panel ? panel.decorationChange$ : EMPTY)),
                takeUntilDestroyed()
            )
            .subscribe(() => {
                this._changeDetectorRef.markForCheck();
            });
    }

    ngOnChanges(changes: LgSimpleChanges<LgPanelGridNodeComponent>): void {
        if (changes.node) {
            this._updateClassAndStyle();
        }
    }

    ngDoCheck(): void {
        if (this.node && this.node.effectiveState !== this._lastState) {
            this._lastState = this.node.effectiveState;
            this._updateClassAndStyle();
            this._changeDetectorRef.markForCheck();
        }
        const hadCollapsedParts = this._hasCollapsedParts;
        this._hasCollapsedParts = this.node.headersAbove.length + this.node.headersBelow.length > 0;
        if (this._hasCollapsedParts !== hadCollapsedParts) {
            this._changeDetectorRef.markForCheck();
        }
    }

    _clickHeader(node: LgPanelGridNode): void {
        if (!node || !node.attachedPanel || !node.isMinimized) return;

        const toFullscreen =
            node.attachedPanel.resizeActions.indexOf("expand") === -1 ||
            this._gridService.hasFullscreenPanel();
        this._gridService.maximizePanel(node.attachedPanel, toFullscreen);
    }

    _getMinimizedHeaderRatio(row: HeaderRow, node: LgPanelGridNode): number {
        if (row.columnHeaders) {
            return 0;
        } else {
            return node.sizeFraction * 100;
        }
    }

    private _updateClassAndStyle(): void {
        const isMaximized = this.node.isMaximized;
        const isMinimized = this.node.isMinimized;
        let mainClass: string;
        let maxWidth: boolean;

        switch (this.node.type) {
            case GridNodeType.Row:
                mainClass = "lg-panel-grid__node lg-panel-grid__node--row lg-panel-grid__node";
                maxWidth = false;
                break;
            case GridNodeType.Column:
                mainClass = "lg-panel-grid__node lg-panel-grid__node--column lg-panel-grid__node";
                maxWidth = true;
                break;
            case GridNodeType.Leaf:
                mainClass = "lg-panel-grid__node lg-panel-grid__node--leaf";
                maxWidth = !!this.node.parent && this.node.parent.type === GridNodeType.Row;
                break;
        }

        let size: string | null = null;
        this._maxWidth = null;
        this._maxHeight = null;
        this._flexBasis = null;

        if (isMaximized) {
            size = "max";
        }
        if (isMinimized) {
            size = "auto";
        } else {
            this._flexBasis = this.node.sizeFraction * 100;
            if (maxWidth) {
                this._maxWidth = this.node.sizeFraction * 100;
            } else {
                this._maxHeight = this.node.sizeFraction * 100;
            }
        }

        const newClass = `${mainClass} ${size == null ? "" : mainClass + "--" + size}`;

        this._class = newClass;
        // speedup the update
        this._elementRef.nativeElement.className = newClass;
    }
}
