import { InjectionToken } from "@angular/core";

import { IFilterDefinitionSeed } from "./filter-definition-seed";
import { IFilterDefinition } from "./filter-definition";

export const LG_FILTERSET_PREPROCESSORS = new InjectionToken<IFilterPreprocessor>(
    "lgFilterSetPreprocessors"
);

export interface IFilterPreprocessor {
    name: string;

    preprocess(definition: IFilterDefinitionSeed, context: any): IFilterDefinition;
}
