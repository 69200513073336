<div class="lg-multi-filter-popup__trap" (click)="_attemptClose()"></div>
<div
    class="lg-multi-filter-popup__search"
    [style.width.px]="_searchWidthMatchesPopup ? _windowWidth : _targetWidth"
    [class.lg-multi-filter-popup__search--condensed]="_isCondensed"
    [class.lg-multi-filter-popup__search--background]="!_transparentSearchBackground"
>
    <input
        type="text"
        [ngModel]="_quickFilter"
        (ngModelChange)="_quickFilterChanged($event)"
        [placeholder]="_placeholder"
        (blur)="_keepFocus($event)"
        class="empty-input"
        (paste)="_onPaste($event)"
    />
    <lg-icon icon="icon-search"></lg-icon>
</div>
<div
    class="lg-multi-filter-popup__window"
    [class.lg-multi-filter-popup__window--matching]="_targetWidth >= (_windowWidth || 312)"
    [style.width.px]="_windowWidth"
    [style.minWidth.px]="_targetWidth"
>
    <div *ngIf="!_hideHeader" class="lg-multi-filter-popup__window__header">
        <div class="lg-multi-filter-popup__window__header__left">
            <lg-selection-checkbox
                class="lg-selection-checkbox--inline"
                [state]="_checkState"
                [disabled]="_loading || _isReadonly"
                (checked)="_checkAll($event)"
                title="{{ (_checkState === 0 ? '.Add_visible' : '.Remove_visible') | lgTranslate }}"
            ></lg-selection-checkbox>
            {{ ".Selected" | lgTranslate: { count: _selectionSize } }}
        </div>
        <div *ngIf="!_isReadonly" class="lg-multi-filter-popup__window__header__invert">
            <lg-icon
                icon="icon-invert"
                (click)="_invertAll()"
                title="{{ '.Invert_tooltip' | lgTranslate }}"
                [style.visibility]="_loading ? 'hidden' : ''"
            ></lg-icon>
        </div>
        <div
            *ngIf="!_isReadonly && _showSorter"
            class="lg-multi-filter-popup__window__header__item"
        >
            <lg-icon-menu
                [icon]="_currentSorterItem.icon"
                [definition]="_sorterDefinition"
                [title]="_currentSorterItem.title"
                (currentChange)="_sort($event)"
                [style.visibility]="_loading ? 'hidden' : ''"
                [(current)]="_currentSorterItem.id"
            ></lg-icon-menu>
        </div>
        <div
            class="lg-multi-filter-popup__window__header__item"
            (click)="_selectedOnlyToggle(!_selectedOnly)"
            title="{{ '.SelectedOnly' | lgTranslate }}"
        >
            <lg-hoverable-icon
                [icon]="_selectedOnly ? 'icon-eye-selected' : 'icon-eye'"
                [hoverIcon]="'icon-eye-selected'"
                [ngClass]="{ 'lg-multi-filter-popup__window__header__item__active': _selectedOnly }"
            >
            </lg-hoverable-icon>
        </div>
    </div>
    <div class="lg-multi-filter-popup__body">
        <div *ngIf="_loading">
            <div class="lg-folding-loader">
                <div class="lg-folding-loader__cube lg-folding-loader__cube--0"></div>
                <div class="lg-folding-loader__cube lg-folding-loader__cube--1"></div>
                <div class="lg-folding-loader__cube lg-folding-loader__cube--3"></div>
                <div class="lg-folding-loader__cube lg-folding-loader__cube--2"></div>
            </div>
        </div>
        <div [hidden]="_loading">
            <div
                style="max-height: 400px; margin-right: 2px"
                lgScrollable
                lgScrollableAutoHide="true"
                (click)="_onClick($event)"
                #scroller
            >
                <div
                    *ngIf="
                        (!_converted || _converted.length === 0) &&
                        (!_sourceData || _sourceData.length > 0)
                    "
                    class="lg-multi-filter-popup__body__empty"
                >
                    {{ ".NoResults" | lgTranslate: { pattern: _sanitizedFilter } }}
                </div>
                <div
                    *lgVirtualFor="
                        let entry of _converted;
                        let index = index;
                        height: _itemHeight;
                        ensureVisible: _visible$
                    "
                    class="lg-multi-filter-popup__body__row {{ _itemClass }}"
                    [class.lg-multi-filter-popup__body__row--cursor]="index === _currentIndex"
                    [class.lg-multi-filter-popup__body__row--active]="_selection[entry.id]"
                    title="{{ entry.nameUnescaped }}"
                    [attr.data-index]="index"
                >
                    <lg-selection-checkbox
                        [state]="!!_selection[entry.id]"
                        (checked)="_toggleItem(entry)"
                        [disabled]="_isReadonly || entry.disabled"
                    ></lg-selection-checkbox>
                    <ng-container
                        *ngTemplateOutlet="
                            _itemTemplate;
                            context: {
                                $implicit: entry,
                                active: !!_selection[entry.id],
                                regexpFilter: _regexpFilter
                            }
                        "
                    ></ng-container>
                </div>
            </div>
        </div>
    </div>
    <div class="lg-multi-filter-popup__window__footer">
        <a
            href="#"
            class="link link--confirm"
            (click)="_attemptClose()"
            [hidden]="_isReadonly"
            [lgStopPropagation]="'click'"
            >{{ ".Done" | lgTranslate }}</a
        >
        &nbsp;
        <a
            href="#"
            class="link link--cancel"
            (click)="_attemptCancel()"
            [lgStopPropagation]="'click'"
            >{{ ".Cancel" | lgTranslate }}</a
        >
    </div>
</div>

<ng-template let-entry let-filter="regexpFilter" #defaultItemTemplate>
    <div
        class="lg-multi-filter-popup__body__row__left"
        [innerHTML]="entry.name | highlightByRegex: filter"
    ></div>
</ng-template>

<ng-template let-entry let-filter="regexpFilter" #countItemTemplate>
    <div
        class="lg-multi-filter-popup__body__row__left"
        [innerHTML]="entry.name | highlightByRegex: filter"
    ></div>
    <div class="lg-multi-filter-popup__body__row__right" *ngIf="entry.data != null">
        ({{ entry.data | fmtInteger }})
    </div>
</ng-template>
