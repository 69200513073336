import { Directive, Input, HostBinding, OnChanges, OnInit } from "@angular/core";
import { coerceBooleanProperty } from "@angular/cdk/coercion";

@Directive({
    standalone: true,
    selector: "[lgLongText]"
})
export class LgLongTextDirective implements OnInit, OnChanges {
    @Input({ alias: "lgLongText", required: true }) inputText!: string;
    @Input("lgLongTextOnce") once = false;

    @HostBinding("title") title!: string;
    @HostBinding("innerText") text!: string;

    private _initialized!: boolean;

    ngOnInit(): void {
        this._initialized = false;

        this.once = coerceBooleanProperty(this.once);

        this._mapInputToHostState();

        this._initialized = true;
    }

    ngOnChanges(): void {
        if (!this._initialized || this.once) {
            return;
        }

        this._mapInputToHostState();
    }

    private _mapInputToHostState(): void {
        this.text = this.inputText;
        this.title = this.inputText;
    }
}
