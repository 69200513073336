import ldIsString from "lodash-es/isString";
import ldIsArray from "lodash-es/isArray";
import { coerceBooleanProperty } from "@angular/cdk/coercion";
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    inject,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    ViewChild
} from "@angular/core";
import * as d3 from "d3";
import { LgSimpleChanges } from "@logex/framework/types";
import { BaseChartComponent } from "../shared/base-chart.component";
import { IGrowthBarChartItem } from "./growth-bar-chart.types";
import { IExportableChart, LgChartExportContainerDirective } from "../shared/lg-chart-export";
import { Margin } from "../shared/chart.types";

@Component({
    selector: "lg-growth-bar-chart",
    template: `
        <div #chart class="lg-chart-growth-bar lg-chart-small-text"></div>
        <ng-template #defaultTemplate [lgChartTemplateContextType]="this" let-context>
            <b>{{ context.name }}: </b>{{ this._numberFormat(context.value) }}
        </ng-template>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LgGrowthBarChartComponent
    extends BaseChartComponent<IGrowthBarChartItem, IGrowthBarChartItem>
    implements OnInit, OnChanges, OnDestroy, AfterViewInit, IExportableChart
{
    private _exportContainer = inject(LgChartExportContainerDirective, { optional: true });

    /**
     * Specifies which part of the group area is occupied by the spacing. Valid value is number from 0 to 1.
     *
     * @default 0.1
     */
    @Input() spacing = 0.1;

    /**
     * Callback for providing the bar name.
     * If not specified then the name matches bar index.
     */
    @Input() valueName?: (locals: any) => string;

    /**
     * Callback for providing the bar label.
     * If not specified then bar label is empty.
     */
    @Input() xAxisLabel?: (locals: any) => string;

    /**
     * Callback for providing the bar colors. Default color is blue #4A82BD.
     */
    @Input() valueColors?: (locals: any) => any;

    /**
     * Callback for providing the value of related data item.
     * Optional if input data is array of numbers, required otherwise.
     */
    @Input() value?: (locals: any) => number;

    /**
     * Specifies whether X axis labels are visible or not. Defaults to false.
     *
     * @default false
     */
    @Input() showXAxisLabels = false;

    /**
     * Specifies maximum number of ticks on axis. Defaults to 10.
     *
     * @default 10
     */
    @Input() tickCount?: number;

    @ViewChild("chart", { static: true }) private _chartDivRef!: ElementRef;

    protected override _margin!: Required<Margin>;

    protected _xAxis?: d3.Axis<any>;
    protected _yAxis!: d3.Axis<any>;
    protected _spacing = 0.1;
    protected _names: string[] = [];
    protected _yMin = 0;
    protected _yMax = 0;
    protected _hasLabels = false;
    protected _xScale!: d3.ScaleBand<any>;
    protected _yScale!: d3.ScaleLinear<number, number>;
    protected _xAxisG!: d3.Selection<any, any, any, any>;
    protected _xAxisLabelsG!: d3.Selection<any, any, any, any>;
    protected _yAxisG!: d3.Selection<any, any, any, any>;
    protected _yAxisG2!: d3.Selection<any, any, any, any>;
    protected _labelG!: d3.Selection<any, any, any, any>;
    protected _yAxis2!: d3.Axis<any>;

    ngOnInit(): void {
        super._onInit();

        this._defaultProps();
        this._propsToState();
        this._convertData();
        this._drawMainSvgHolder(this._chartDivRef.nativeElement);
        this._create();
        this._updateSize();
        this._render(false);
        this._initializeTooltip();

        this._initialized = true;
    }

    ngOnChanges(changes: LgSimpleChanges<LgGrowthBarChartComponent>): void {
        if (!this._initialized) {
            return;
        }

        super._onBaseChartChanges(changes);

        let needsRender = false;
        let renderImmediate = false;
        let renderAsTransition = false;

        if (changes.data || changes.valueName || changes.valueColors || changes.value) {
            this._convertData();
            needsRender = true;
            renderAsTransition = true;
        }

        if (changes.width || changes.height || changes.spacing) {
            this._updateSize();
            needsRender = true;
            renderImmediate = true;
        }

        if (changes.tickCount) {
            const val = changes.tickCount.currentValue;
            if (this._yAxis) {
                this._yAxis.ticks(parseInt(val));
                this._yAxis2.ticks(parseInt(val));
                needsRender = true;
            }
        }

        if (changes.showXAxisLabels) {
            const val = changes.showXAxisLabels.currentValue;
            this.showXAxisLabels = coerceBooleanProperty(val);

            if (this._xAxis) {
                needsRender = true;
            }
        }

        if (needsRender) {
            if (renderAsTransition) {
                d3.transition()
                    .duration(500)
                    .each(() => this._render(false));
            } else {
                this._render(renderImmediate);
            }
        }
    }

    ngAfterViewInit(): void {
        this._exportContainer?.register(this);
    }

    ngOnDestroy(): void {
        this._exportContainer?.unregister(this);
        super._onDestroy();
    }

    getHtmlElement(): HTMLElement {
        return this._chartDivRef.nativeElement;
    }

    getSvgElement(): SVGElement {
        return this._svg.node();
    }

    protected _defaultProps(): void {
        this.margins = this.margins || "20";
    }

    protected _propsToState(): void {
        this._sizePropsToState();
    }

    protected _sizePropsToState(): void {
        this._margin = this._getMargin(this.margins.split(","));
        this._width = +this.width - this._margin.left - this._margin.right;
        this._height = +this.height - this._margin.top - this._margin.bottom;
        this._spacing = +this.spacing || 0.1;
    }

    protected _updateSize(): void {
        this._svgG.attr("transform", `translate( ${this._margin.left} , ${this._margin.top} )`);
        this._svg.attr("width", +this.width);
        this._svg.attr("height", +this.height);
        this._xScale.rangeRound([0, this._width]).padding(this._spacing);
        this._yScale.range([this._height, 0]);
        this._xAxisG.attr("transform", "translate(0," + this._height + ")");
    }

    protected _create(): void {
        this._xScale = d3.scaleBand().rangeRound([0, this._width]).padding(this._spacing);
        this._yScale = d3.scaleLinear().range([this._height, 0]);

        this._yAxisG2 = this._svgG.append("g").attr("class", "y axis grid");

        this._yAxis2 = d3
            .axisRight(this._yScale)
            .tickPadding(0)
            .tickFormat(() => "")
            .ticks(this.tickCount || 10);

        this._chart = this._svgG.append("g");

        this._yAxis = d3
            .axisLeft<any>(this._yScale)
            .tickSizeInner(5)
            .tickSizeOuter(0)
            .tickPadding(2)
            .tickFormat(this._numberFormat)
            .ticks(this.tickCount || 10);

        this._xAxis = d3
            .axisBottom(this._xScale)
            .tickSizeInner(0)
            .tickSizeOuter(0)
            .tickPadding(6)
            .tickFormat(() => "");

        this._yAxisG = this._svgG.append("g").attr("class", "y axis");

        this._xAxisG = this._svgG
            .append("g")
            .attr("class", "x axis")
            .attr("transform", "translate(0," + this._height + ")");

        this._xAxisLabelsG = this._svgG
            .append("g")
            .attr("class", "x axis")
            .attr("transform", "translate(0," + this._height + ")");

        this._labelG = this._svgG.append("g").attr("class", "xAxisLabels");
    }

    protected _render(immediate?: boolean): void {
        if (!this._chart) {
            this._create();
        }
        if (!this._data || !this._data.length || !this._height || this._height < 0) return;

        const oldYScale = this._yScale.copy();
        if (immediate) {
            oldYScale.domain([this._yMin, this._yMax]).nice();
        }
        this._yScale.domain([this._yMin, this._yMax]).nice();
        this._xScale.domain(this._names);
        // this.xAxis.scale(d3.scale.ordinal().rangeRoundBands([0, this.width], 0.1).domain([""]));

        this._yAxis2.tickSizeInner(this._width).tickSizeOuter(0);
        if (immediate) {
            this._xAxisG.call(this._xAxis!);
            this._yAxisG.call(this._yAxis);
            this._xAxisG.attr("transform", "translate(0," + this._yScale(0) + ")");
            this._yAxisG2.call(this._yAxis2);
        } else {
            this._xAxisG.transition().call(this._xAxis!);
            this._yAxisG.transition().call(this._yAxis);
            this._xAxisG.transition().attr("transform", "translate(0," + this._yScale(0) + ")");
            this._yAxisG2.transition().call(this._yAxis2);
        }
        this._yAxisG2.selectAll("g").classed("hundred", d => d === 1);

        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const self = this;

        const columns = this._chart
            .selectAll<SVGRectElement, IGrowthBarChartItem>(".bar")
            .data(this._data, (d: IGrowthBarChartItem) => {
                return d.name;
            });

        columns
            .exit()
            .transition()
            .attr("y", this._yScale(0))
            .attr("height", 0)
            .style("opacity", 0)
            .remove();

        const newColumns = columns.enter().append("rect");

        let columnsMerged = newColumns
            .attr("class", "bar")
            .attr("x", (d: IGrowthBarChartItem) => this._xScale(d.name) ?? 0)
            .attr("width", this._xScale.bandwidth())
            .attr("y", oldYScale(0))
            .attr("height", 0)
            .style("fill", (d: IGrowthBarChartItem) => (d.value >= 0 ? d.colors[0] : d.colors[1]))
            .style("cursor", this.clickable ? "pointer" : "default")
            .on("mouseover", function (_event: MouseEvent, d: IGrowthBarChartItem) {
                self.tooltipContext = d;
                self._tooltip?.show({ target: this as any });
            })
            .on("mouseleave", (_event: MouseEvent) => this._tooltip?.hide())
            .on("click", function (_event: MouseEvent, d: IGrowthBarChartItem) {
                const index = newColumns.nodes().indexOf(this);
                self._onClick(d, index);
            })
            .merge(columns);

        columnsMerged = immediate ? columnsMerged : (columnsMerged.transition() as any);

        columnsMerged
            .attr("x", (d: IGrowthBarChartItem) => this._xScale(d.name) ?? 0)
            .attr("width", this._xScale.bandwidth())
            .attr("y", (d: IGrowthBarChartItem) => this._yScale(Math.max(0, d.value)))
            .attr(
                "height",
                (d: IGrowthBarChartItem) =>
                    this._yScale(Math.min(0, d.value)) - this._yScale(Math.max(0, d.value))
            )
            .style("fill", (d: IGrowthBarChartItem) => (d.value >= 0 ? d.colors[0] : d.colors[1]));

        const xAxisLabels = this._xAxisLabelsG
            .selectAll<SVGTextElement, IGrowthBarChartItem>("text")
            .data(this._data, (d: IGrowthBarChartItem) => d.name);

        xAxisLabels.exit().transition().style("opacity", 0).remove();

        let mergedXAxisLabels = xAxisLabels
            .enter()
            .append("text")
            .attr("class", "label")
            .attr("text-anchor", "middle")
            .attr(
                "x",
                (d: IGrowthBarChartItem) =>
                    (this._xScale(d.name) ?? 0) + this._xScale.bandwidth() / 2
            )
            .attr("dy", "0.71em")
            .style("opacity", 0)
            .text(d => d.name)
            .merge(xAxisLabels);

        mergedXAxisLabels = immediate ? mergedXAxisLabels : (mergedXAxisLabels.transition() as any);

        mergedXAxisLabels
            .attr(
                "x",
                (d: IGrowthBarChartItem) =>
                    (this._xScale(d.name) ?? 0) + this._xScale.bandwidth() / 2
            )
            .attr("y", this._yMin < 0 && this._hasLabels ? 13 : 6)
            .style("opacity", 1)
            .text(d => d.name);

        this._xAxisLabelsG.style("opacity", this.showXAxisLabels ? 1 : 0);

        if (this._hasLabels) {
            const labels = this._labelG
                .selectAll<SVGTextElement, IGrowthBarChartItem>("text")
                .data(this._data, (d: IGrowthBarChartItem) => d.name);

            let mergedLabels = labels
                .enter()
                .append("text")
                .attr("class", "label")
                .attr("text-anchor", "middle")
                //                    .attr("y", this.yScale(0))
                .attr("y", (d: IGrowthBarChartItem) => this._yScale(d.value))
                .attr(
                    "x",
                    (d: IGrowthBarChartItem) =>
                        (this._xScale(d.name) ?? 0) + this._xScale.bandwidth() / 2
                )
                .attr("dy", -2)
                .style("opacity", 0)
                .text(d => d.label ?? "")
                .merge(labels);

            mergedLabels = immediate ? mergedLabels : (mergedLabels.transition() as any);

            mergedLabels
                .attr(
                    "x",
                    (d: IGrowthBarChartItem) =>
                        (this._xScale(d.name) ?? 0) + this._xScale.bandwidth() / 2
                )
                .attr("y", (d: IGrowthBarChartItem) => this._yScale(d.value))
                .attr("dy", (d: IGrowthBarChartItem) => (d.value < 0 ? 12 : -2))
                .style("opacity", 1)
                .text(d => d.label ?? "");

            labels.exit().transition().style("opacity", 0).remove();
        } else {
            this._labelG
                .selectAll("text")
                .data([])
                .exit()
                .transition()
                .style("opacity", 0)
                .remove();
        }
    }

    protected _onClick(datum: IGrowthBarChartItem, itemIndex: number): void {
        if (this.clickable) {
            this.itemClick.emit({ item: datum.item, datum, index: itemIndex });
        }
    }

    protected _convertData(): void {
        if (!this.data) {
            return;
        }

        this._data = [];
        this._names = [];
        this._yMin = this._yMax = 0;
        this._hasLabels = false;
        this.data.forEach((val, index) => {
            const valueName = this.valueName ? this.valueName(val) : index.toString();
            let xAxisLabel = this.xAxisLabel ? this.xAxisLabel(val) : "";
            if (xAxisLabel != null) {
                this._hasLabels = true;
                if (!ldIsString(xAxisLabel)) {
                    xAxisLabel = this._labelFormat(xAxisLabel);
                }
            }
            let value = this.value ? this.value(val) : +val;
            const colorVal = (this.valueColors ? this.valueColors(val) : null) || "#4A82BD";
            if (isNaN(value) || !isFinite(value)) value = 0;
            if (this._yMin > value) {
                this._yMin = value;
            } else if (this._yMax < value) {
                this._yMax = value;
            }
            let colors: string[];
            if (ldIsArray(colorVal)) {
                if (colorVal.length === 1) {
                    colors = [colorVal[0], colorVal[0]];
                } else {
                    const value1 = +colorVal[0];
                    const value2 = +colorVal[1];
                    if (!isNaN(value1)) {
                        // assume the format is [brightness, color]
                        colorVal[0] =
                            value1 > 0
                                ? d3.rgb(colorVal[1]).brighter(value1)
                                : d3.rgb(colorVal[1]).darker(-value1);
                    } else if (!isNaN(value2)) {
                        // assume the format is [color, brightness]
                        colorVal[1] =
                            value2 > 0
                                ? d3.rgb(colorVal[0]).brighter(value2)
                                : d3.rgb(colorVal[0]).darker(-value2);
                    }
                    colors = [colorVal[0], colorVal[1]];
                }
            } else {
                colors = [colorVal, colorVal];
            }
            this._data.push({
                value,
                item: val,
                colors,
                name: valueName,
                label: xAxisLabel
            });
            this._names.push(valueName);
        });
        if (this._yMin === this._yMax) {
            this._yMax = 1;
            this._yMin = -1;
        }
    }
}
