/**
 * Specifies d3 range in which chart is created
 */
export interface LgFunnelChartBoundaries {
    yMin: number;
    yMax: number;
    xMin: number;
    xMax: number;
}

/**
 * Specifies the data type for funnel chart.
 */
export interface LgFunnelChartDatum {
    xValue: number;
    yValue: number;
    groupId: number | string;
}

/**
 * Specifies the line type for funnel chart
 * The value represents value that is shown in the chart. For %, value must be divided by 100.
 * The colorHex represents the color with which this should be drawn.
 * The legendName property represents name in legend for this line. If not specified, item is not shown.
 * The legendOpacity allows to specify opacity in legend. Range 0-1
 */
export interface LgFunnelChartLine {
    value: number;
    colorHex: string;
    legendName?: string;
    legendOpacity?: number;
}

/**
 * Specifies the type for confidence interval.
 * The calculation for confidence can vary.
 * The upper confidence function receives current X axis point and expects Y axis value.
 * The lower confidence function receives current X axis point and expects Y axis value.
 * The colorHex property specifies the color of confidence interval lines.
 * The legendName property represents name in legend for this line. If not specified, item is not shown.
 * The legendOpacity allows to specify opacity in legend. Range 0-1
 */
export interface LgFunnelChartConfidence {
    upperConfidenceFn: (x: number) => number;
    lowerConfidenceFn: (x: number) => number;
    colorHex: string;
    legendName?: string;
    legendOpacity?: number;
}

/**
 * Specifies the definition for quadrants.
 * The legendName specifies the name in the legend. If not specified, quadrant won't be added to legend.
 * The colorHex specifies the color used when creating quadrants and in legend definition.
 * The opacity specifies the opacity used when creating quadrants and in legend definition.
 */
export interface LgFunnelChartQuadrants {
    upperQuadrantLegendName?: string;
    lowerQuadrantLegendName?: string;
    upperQuadrantColorHex: string;
    lowerQuadrantColorHex: string;
    upperQuadrantOpacity: number;
    lowerQuadrantOpacity: number;
}

/**
 * Confidence interval values are given.
 * Calculations for funnel plot can be found below.
 * https://logexgroup.atlassian.net/wiki/spaces/REG/pages/2228486149/Confidence+intervals+-+funnel+plots
 */
export const CONFIDENCE_INTERVAL_Z_VALUES = {
    [80]: 1.282,
    [85]: 1.44,
    [90]: 1.645,
    [95]: 1.96,
    [99]: 2.576,
    [99.5]: 2.807,
    [99.8]: 3.091,
    [99.9]: 3.291
};

export type ConfidenceZScore = keyof typeof CONFIDENCE_INTERVAL_Z_VALUES;

export interface LgFunnelChartTooltipContext<TChartDatum extends LgFunnelChartDatum> {
    displayingItems: Array<LgFunnelChartTooltipItem<TChartDatum>>;
    activeItem?: { group: string | number; column: TChartDatum };
}

export interface LgFunnelChartTooltipItem<TChartDatum extends LgFunnelChartDatum> {
    datum: TChartDatum;
}
