/* eslint-disable @typescript-eslint/no-this-alias */
import ldCloneDeep from "lodash-es/cloneDeep";
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    inject,
    Input,
    isDevMode,
    NgZone,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    TemplateRef,
    ViewChild,
    ViewContainerRef
} from "@angular/core";
import {
    ILgFormatter,
    ILgFormatterOptions,
    LgConsole,
    LgFormatterFactoryService
} from "@logex/framework/core";
import * as d3 from "d3";
import { ChartClickEvent, LegendItem, LegendOptions, Margin } from "../../shared/chart.types";
import { getDefaultLegendOptions } from "../../shared/getDefaultLegendOptions";
import {
    ILegendItemDefinition,
    LgBoxplotDatum,
    LgBoxplotHighlighted,
    LgBoxplotItem,
    LgBoxplotLineIndices,
    LgBoxplotTooltipContext,
    LgBoxplotTypeIndices,
    LgComparingBoxplotItem,
    LgConvertedBoxplotItem
} from "../lg-boxplot.types";
import { TemplatePortal } from "@angular/cdk/portal";
import {
    D3TooltipApi,
    ID3TooltipOptions,
    LgD3TooltipService
} from "../../d3/lg-d3-tooltip.service";
import { getRecommendedPosition } from "../../shared/getRecommendedPosition";
import { LgColorPalette } from "../../shared/lg-color-palette";
import { LgSimpleChanges } from "@logex/framework/types";
import { getLegendWidth } from "../../shared/getLegendWidth";
import { LgTranslateService, useTranslationNamespace } from "@logex/framework/lg-localization";
import {
    LG_DEFAULT_COLOR_CONFIGURATION,
    LG_USE_NEW_LABELS,
    LgColorsConfiguration
} from "../../shared/lg-color-palette-v2/lg-colors.types";
import { LgColorPaletteV2 } from "../../shared/lg-color-palette-v2/lg-color-palette-v2";
import { IExportableChart, LgChartExportContainerDirective } from "../../shared/lg-chart-export";
import {
    IChartTooltipProvider,
    IImplicitContext
} from "../../shared/lg-chart-template-context.directive";

const DEFAULT_MARGIN: Margin = { top: 16, right: 16, bottom: 16, left: 16 };

const DEFAULT_TICKS_FORMATTER_OPTIONS: ILgFormatterOptions = { decimals: 0 };
const DEFAULT_TOOLTIP_FORMATTER_OPTIONS: ILgFormatterOptions = { decimals: 2 };

const SPACE_BETWEEN_LABELS_AND_GRID = 8;
const X_AXIS_LABELS_LINE_HEIGHT = 20;
const X_AXIS_LABELS_LINE_HEIGHT_CONDENSED = 12;
const SPACE_FOR_LEGEND_BELOW = 30;

const DEFAULT_X_AXIS_TITLE = "€ × 1000";
const DEFAULT_Y_AXIS_TITLE = "Names";
const DEFAULT_TICK_COUNT = 5;
const DEFAULT_GROUP_COLORS = "@input, @benchmark";
const DEFAULT_Y_AXIS_LABELS_WIDTH = 100;
const DEFAULT_MAX_LABEL_LENGTH = 10;

const DEFAULT_SPACE_BETWEEN_BOXES = 8;
const CONDENSED_SPACE_BETWEEN_BOXES = 2;
const MIN_BOX_WIDTH = 6;
const SPACE_BETWEEN_GRAPH_AND_MEDIAN_LABELS = 10;

const ONE_SYMBOL_WIDTH = 6;

const ICON_SIZE = 24;

interface ColumnIconOptions {
    icon?: string;
    iconType?: string;
    tooltip?: boolean;
    tooltipText?: string;
}

@Component({
    standalone: false,
    selector: "lg-boxplot-horizontal-chart",
    templateUrl: "./lg-boxplot-horizontal-chart.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    viewProviders: [useTranslationNamespace("FW._Directives._Charts._LgBoxplotHorizontal")]
})
export class LgBoxplotHorizontalChartComponent
    implements
        OnInit,
        AfterViewInit,
        OnChanges,
        OnDestroy,
        IExportableChart,
        IChartTooltipProvider<LgBoxplotTooltipContext>
{
    private _colorPalette = inject(LgColorPaletteV2);
    private _elementRef = inject(ElementRef);
    private _exportContainer = inject(LgChartExportContainerDirective, { optional: true });
    private _formatterFactory = inject(LgFormatterFactoryService);
    private _legacyColorPalette = inject(LgColorPalette);
    private _lgConsole = inject(LgConsole).withSource("Logex.Charts.LgBoxplotHorizontalChart");
    private _ngZone = inject(NgZone);
    private _renderer = inject(Renderer2);
    private _tooltipService = inject(LgD3TooltipService);
    private _translateService = inject(LgTranslateService);
    private _useNewLabels = inject(LG_USE_NEW_LABELS);
    private _viewContainerRef = inject(ViewContainerRef);
    /**
     * Specifies the data from which the chart is built.
     * Required parameter without default.
     * Either this or data input must be specified.
     *
     * Properties of a single object:
     *
     * @property { string } group - Defines whether the item is main or comparing.
     * If boxplot is comparing, the group must be equal to the comparingAgainst input
     * @property { number } itemValue specifies the value of the item.
     * @property { string } itemName specifies column. This is used for grouping values.
     */
    @Input() data?: LgBoxplotItem[] | null = null;
    /**
     * Specifies the data from which the chart is built.
     * Required parameter without default.
     * Either this or data input must be specified.
     *
     * Properties of a single object:
     *
     * @property { string } key - Specifies the name of the column.
     * @property { ComparingBoxplotItemHorizontalHorizontal } value - Specifies main and comparing values of the column.
     * If comparing is empty, only main data are shown.
     */
    @Input() computedData?: LgBoxplotDatum[] | null = null;
    /**
     * Specifies the margin for chart.
     * Defaults to 16 on all sides.
     *
     * @property { number } top specifies the margin of SVG from top.
     * @property { number } right specifies the margin of SVG from right.
     * @property { number } bottom specifies the margin of SVG from bottom.
     * @property { number } left specifies the margin of SVG from left.
     */
    @Input() margin: Margin | null = null;
    /**
     * Specifies the number to round the min and max to.
     * If specified, the number axis starts with nearest multiple of given number.
     * The minimum is rounded down and maximum is rounded up.
     *
     * If not specified, minimum and maximum values are used and d3 creates domain automatically.
     *
     * @example
     * roundToNearestMultipleOf = 500, min = 499, max = 999;
     * The X axis starts with 0 and ends with 1000.
     */
    @Input() roundToNearestMultipleOf: number | null = null;
    /**
     * Specifies the height of the chart area in pixels. Required parameter without default.
     */
    @Input({ required: true }) height!: number;
    /**
     * Specifies the width of the chart area in pixels. Required parameter without default.
     */
    @Input({ required: true }) width!: number;
    /**
     * Specifies class of tooltip. Default to empty.
     */
    @Input() tooltipClass?: string;
    /**
     * Specifies maximum number of ticks on axis. Defaults to 5.
     *
     * @default 5
     */
    @Input() tickCount?: number;

    /**
     * Specifies the Y axis title. Defaults to "Y axis title not defined".
     * Can receive empty string to hide Y axis title entirely.
     */
    @Input() yAxisLabel?: string;
    /**
     * Specifies the X axis title. Defaults to "X axis title not defined".
     * Can receive empty string to hide X axis title entirely.
     */
    @Input() xAxisLabel?: string;
    /**
     * Specifies whether Y axis labels are visible or not. Defaults to true.
     *
     * @default true
     */
    @Input() showYAxisLabels = true;
    /**
     * Specifies whether X axis labels are visible or not. Defaults to true.
     *
     * @default true
     */
    @Input() showXAxisLabels = true;
    /**
     * Specifies the options for legend
     *
     * @example ```
     * getDefaultLegendOptions({
     *  visible: true,
     *  position: "bottom"
     * })
     * ```
     */
    @Input() legendOptions!: LegendOptions;
    /**
     * @deprecated use colorConfiguration
     *
     * Specifies the colors for main and comparing boxplots. Colors must be separated by comma starting with @.
     * First color is used for main boxplot, second color is used for comparing.
     * Color names must be keys in ChartValueTypeDictionary from lg-color-palette.
     *
     * @example `"@input, @benchmark"`.
     */
    @Input() groupColors?: string;
    /**
     * @deprecated use colorConfiguration
     *
     * Specifies if a color definiton from computedData should be used.
     * The color definition from the computedData input is used to create legend as well as to color the graphs.
     * Color names must be colors in HEX.
     */
    @Input() useColorsFromData?: boolean;

    /**
     * Specifies the name of main boxes in boxplot. Used in legend and tooltip.
     */
    @Input() mainLabel: string | null = null;
    /**
     * Specifies the name of first row comparing boxes in boxplot. Used in legend and tooltip and for grouping values
     * Must be equal to @property { string } group from the data input.
     */
    @Input() comparingAgainst: string | null = null;
    /**
     * Specifies the name of second row comparing boxes in boxplot. Used in legend and tooltip and for grouping values
     * Must be equal to @property { string } group from the data input.
     */
    @Input() comparingAgainst2: string | null = null;

    /**
     * Specifies formatter type for number axis. Defaults to "float".
     *
     * @default "float"
     */
    @Input() axisFormatterType?: string;
    /**
     * Specifies the options for number axis formatter. Defaults to 0 decimals.
     *
     * @default 0 decimals
     */
    @Input() axisFormatterOptions?: ILgFormatterOptions;
    /**
     * Specifies the template to be used when hovering over elements. Defaults to own template.
     */
    @Input() tooltipTemplate?: TemplateRef<IImplicitContext<LgBoxplotTooltipContext>>;
    /**
     * Specifies formatter type for tooltip numbers. Defaults to "float".
     *
     * @default "float"
     */
    @Input() tooltipFormatterType?: string;
    /**
     * Specifies the options for number axis formatter. Defaults to 2 decimals.
     */
    @Input() tooltipFormatterOptions?: ILgFormatterOptions;
    /**
     * Specifies whether boxes are clickable or not. If true, allows emitting from itemClick, which emits clicked box.
     *
     * @default false
     */
    @Input() clickable = false;
    /**
     * @optional
     * Specifies the width of the Y axis labels in pixels. Defaults to 100.
     *
     * @default 100
     */
    @Input() yAxisLabelsWidth?: number;
    /**
     * Specifies the length of the X axis title text. Defaults to 10 characters.
     *
     * @default 10
     */
    @Input() labelLength: number = DEFAULT_MAX_LABEL_LENGTH;
    /**
     * Specifies the color configuration. Defaults to categorical palette.
     *
     * If specified, allows four different configuration
     * - default/categorical - using 20 predefined colors
     * - sequential by color scheme - using predefined sequence of colors by name
     * - predefined - using predefined dictionary
     * - own - array of hexadecimal values
     *
     * For usage, see New Palette in storybook under LgCharts.
     * Palette story contains all possible colors.
     * Gallery story contains all charts using new colors.
     *
     * Example can be seen in 'getAllChartsProps.ts:62'
     */
    @Input() colorConfiguration: LgColorsConfiguration = LG_DEFAULT_COLOR_CONFIGURATION;
    /**
     * Specifies the color for the median line - possible options are 'white' and 'black'. Defaults to white.
     */
    @Input() medianLineColor?: "white" | "black" = "white";
    /**
     * Turns on special condensed view without any axis labels and displaying median on right.
     *
     * @default false
     */
    @Input() condensed = false;
    /**
     * Sets the median decimal count on median label used in condensed view
     *
     * @default 1
     */
    @Input() condensedMedianDecimals = 1;
    /**
     * Sets the median label displayed next to the number in the label
     *
     * @default empty string
     */
    @Input() medianLabelsText?: string = "";
    /**
     * @optional
     * Specifies the minimal number of symbols in value labels. Allows the alignment of multiple unrelated charts.
     *
     * @default = 0
     */
    @Input() minValueLabelSymbolCount = 0;

    /**
     * Callback for specifying whether group column contains icon or not.
     */
    @Input() columnIcons?: (item: any) => boolean;

    /**
     * Specifies group column icon options.
     *
     * @default  { icon: "icon-warning", iconType: "regular" }
     */
    @Input() columnIconOptions?: ColumnIconOptions;

    /**
     * Specifies whether X axis is hidden or not.
     *
     * @default false
     */
    @Input() hideXAxis = false;

    /**
     * @optional
     * Specifies the maximum value on X axis.
     * If not specified, maximum value is calculated from data.
     */
    @Input() xMax?: number;

    /**
     * Emits data in clicked box, if the clickable input is set to true.
     */
    @Output() readonly itemClick = new EventEmitter<ChartClickEvent<any, any>>();
    /**
     * Emits data in clicked legend item.
     */
    @Output() readonly legendClick = new EventEmitter<LegendItem>();
    /**
     * Emits whenever data change and are cropped to fit on screen
     */
    @Output() readonly numberOfDisplayedGroups = new EventEmitter<number>();

    @ViewChild("chartHolder") chartHolder!: ElementRef;
    @ViewChild("chartWithLegend", { static: true })
    private _chartWithLegendDivRef!: ElementRef<HTMLDivElement>;

    @ViewChild("defaultTemplate", { static: true })
    private _defaultTooltipTemplate!: TemplateRef<IImplicitContext<LgBoxplotTooltipContext>>;

    private _svg!: d3.Selection<any, any, any, any>;
    private _svgG!: d3.Selection<any, any, any, any>;
    private _boxGroupsG!: d3.Selection<any, any, any, any>;
    private _yScale!: d3.ScaleBand<string>;
    private _xScale!: d3.ScaleLinear<number, number>;
    private _xAxisGroup!: d3.Selection<any, any, any, any>;
    private _yAxisGroup!: d3.Selection<any, any, any, any>;
    private _xAxisGridG!: d3.Selection<any, any, any, any>;
    private _shadowBoxGroups!: d3.Selection<any, LgBoxplotDatum, any, any>;
    private _boxGroups!: d3.Selection<any, LgBoxplotDatum, any, any>;
    private _groupColors!: d3.ScaleOrdinal<string, string>;
    private _groupColorsHex: string[] = [];
    private _groupToLegendDefinitionDictionary: Record<string, LegendItem> = {};
    private _columnIconsGroup!: d3.Selection<any, any, any, any>;

    private _lastMouseX = 0;
    private _lastMouseY = 0;
    private _trackListener!: () => void;
    private _numberFormat!: (x: number) => string;
    private _tooltipHidden = true;

    private _yAxisLabel!: d3.Selection<any, LgBoxplotDatum, any, any>;
    private _xAxisLabel!: d3.Selection<any, LgBoxplotDatum, any, any>;

    private _spaceForYAxisLabels = 0;

    private _width = 0;
    private _height = 0;

    private _data: LgBoxplotDatum[] = [];
    private _min = 0;
    private _max = 0;

    _legendDefinition: LegendItem[] = [];
    _legendWidth = 0;
    _legendPaddingBottom = 0;
    _margin!: Margin;

    private _groupNames: string[] = [];

    _tooltipFormatter!: ILgFormatter<any>;
    _axisFormatter!: ILgFormatter<any>;
    private _tooltip!: D3TooltipApi;
    private _tooltipContext: LgBoxplotTooltipContext | null = null;
    private _tooltipPortal?: TemplatePortal<IImplicitContext<LgBoxplotTooltipContext>>;

    get tooltipContext(): LgBoxplotTooltipContext | null {
        return this._tooltipContext;
    }

    set tooltipContext(context: LgBoxplotTooltipContext) {
        if (this._tooltipPortal?.context) {
            this._tooltipPortal.context.$implicit = context;
        }
        this._tooltipContext = context;
    }

    private _lastHoveredBoxes?: d3.Selection<any, LgBoxplotDatum, any, any>;
    Highlighted = LgBoxplotHighlighted;
    _highlighted?: LgBoxplotHighlighted;
    _tooltipIsComparing = false;
    _tooltipIsComparing2 = false;
    private _legendDefinitionFromData: ILegendItemDefinition[] = [];

    private _isComparing = false;
    private _isComparing2 = false;
    private _initialized = false;

    _isLabelHovered = false;

    private _spaceForMedianLabel = 0;
    private _boxSpaceFromMiddle = DEFAULT_SPACE_BETWEEN_BOXES / 2;
    private _spaceBetweenLabelsAndGrid = SPACE_BETWEEN_LABELS_AND_GRID;
    private _xAxisLabelsLineHeight = X_AXIS_LABELS_LINE_HEIGHT;

    ngOnInit(): void {
        this._setDefaultProperties();
        this._initializeTooltip();
        this._trackMousePosition();
        this._triggerDataSpecificMethods();
    }

    private _countCondensedValues(): void {
        const spaceForMedianLabels = this.medianLabelsText ? this.medianLabelsText.length + 1 : 0;

        this._margin.left = 0;
        this._spaceForMedianLabel = 1;
        this._data.forEach(element => {
            const main = element.value.main.median;
            const mainLabelLength =
                main == null ? 1 : main.toFixed(this.condensedMedianDecimals).length;
            if (main && mainLabelLength > this._spaceForMedianLabel)
                this._spaceForMedianLabel = mainLabelLength;

            const comparing = element.value.comparing?.median;
            const comparingLabelLength =
                comparing == null ? 1 : comparing.toFixed(this.condensedMedianDecimals).length;
            if (comparingLabelLength > this._spaceForMedianLabel)
                this._spaceForMedianLabel = comparingLabelLength;

            const comparing2 = element.value.comparing2?.median;
            const comparing2LabelLength =
                comparing2 == null ? 1 : comparing2.toFixed(this.condensedMedianDecimals).length;
            if (comparing2LabelLength > this._spaceForMedianLabel)
                this._spaceForMedianLabel = comparing2LabelLength;
        });
        this._spaceForMedianLabel =
            Math.max(
                this._spaceForMedianLabel + this.condensedMedianDecimals + spaceForMedianLabels,
                this.minValueLabelSymbolCount
            ) * ONE_SYMBOL_WIDTH;
    }

    ngAfterViewInit(): void {
        this._exportContainer?.register(this);
        this._render();
        this._initialized = true;
    }

    ngOnChanges(changes: LgSimpleChanges<LgBoxplotHorizontalChartComponent>): void {
        if (!this._initialized) return;

        let triggerDataSpecificMethods = false;

        if (changes.data || changes.computedData) {
            triggerDataSpecificMethods = true;
        }

        if (changes.width || changes.height) {
            this._width = this.width;
            this._height = this.height;
        }

        if (changes.height) {
            triggerDataSpecificMethods = true;
        }

        if (changes.yAxisLabelsWidth) {
            this._spaceForYAxisLabels = this.showYAxisLabels
                ? this.yAxisLabelsWidth == null
                    ? DEFAULT_Y_AXIS_LABELS_WIDTH
                    : this.yAxisLabelsWidth
                : 0;
        }

        if (changes.xAxisLabel) {
            this._xAxisLabel.text(this.xAxisLabel ?? null);
        }

        if (changes.margin) this._margin = this.margin!;

        if (changes.yAxisLabel) {
            this._yAxisLabel.text(this.yAxisLabel ?? null);
        }

        if (changes.groupColors) {
            this._groupColors.range(this.groupColors?.split(",") ?? "");
            triggerDataSpecificMethods = true;
        }

        if (changes.mainLabel || changes.useColorsFromData) {
            this.mainLabel =
                this.mainLabel == null
                    ? this._translateService.translate(".Main_tooltip_label")
                    : this.mainLabel;
            this._updateLegend();
            triggerDataSpecificMethods = true;
        }

        if (changes.condensed || changes.condensedMedianDecimals) {
            triggerDataSpecificMethods = true;
        }

        if (this._svg) this._svg.remove();
        if (triggerDataSpecificMethods) this._triggerDataSpecificMethods();
        this._render();
    }

    ngOnDestroy(): void {
        this._exportContainer?.unregister(this);
        if (this._tooltip) {
            this._tooltip.destroy();
        }
    }

    getHtmlElement(): HTMLElement {
        return this._chartWithLegendDivRef.nativeElement;
    }

    getSvgElement(): SVGElement {
        return this._svg.node();
    }

    private _setDefaultProperties(): void {
        this._tooltipHidden = true;

        this._margin = this.margin == null ? DEFAULT_MARGIN : this.margin;
        this.tooltipFormatterType = this.tooltipFormatterType || "float";
        this.tooltipFormatterOptions = {
            ...DEFAULT_TOOLTIP_FORMATTER_OPTIONS,
            ...this.tooltipFormatterOptions
        };
        this._tooltipFormatter = this._formatterFactory.getFormatter(
            this.tooltipFormatterType,
            this.tooltipFormatterOptions
        );

        this.axisFormatterType = this.axisFormatterType || "float";
        this.axisFormatterOptions = {
            ...DEFAULT_TICKS_FORMATTER_OPTIONS,
            ...this.axisFormatterOptions
        };
        this._axisFormatter = this._formatterFactory.getFormatter(
            this.axisFormatterType,
            this.axisFormatterOptions
        );

        this.tooltipTemplate = this.tooltipTemplate || this._defaultTooltipTemplate;

        this.tickCount = this.tickCount == null ? DEFAULT_TICK_COUNT : this.tickCount;
        this.xAxisLabel = this.xAxisLabel == null ? DEFAULT_X_AXIS_TITLE : this.xAxisLabel;
        this.yAxisLabel = this.yAxisLabel == null ? DEFAULT_Y_AXIS_TITLE : this.yAxisLabel;
        this._spaceForYAxisLabels = this.showYAxisLabels
            ? this.yAxisLabelsWidth == null
                ? DEFAULT_Y_AXIS_LABELS_WIDTH
                : this.yAxisLabelsWidth
            : 0;
        this._numberFormat = x => this._axisFormatter.format(x);
        this.legendOptions =
            this.legendOptions == null ? getDefaultLegendOptions() : this.legendOptions;
        this._width = this.width;
        this._height = this.height;
        this.showYAxisLabels = this.showYAxisLabels == null ? true : this.showYAxisLabels;
        this.showXAxisLabels = this.showXAxisLabels == null ? true : this.showXAxisLabels;
        this.groupColors = this.groupColors == null ? DEFAULT_GROUP_COLORS : this.groupColors;
        this.mainLabel =
            this.mainLabel == null
                ? this._translateService.translate(".Main_tooltip_label")
                : this.mainLabel;
    }

    private _initializeTooltip(): void {
        this._tooltipPortal = this._getTooltipContent();
        const commonOptions: ID3TooltipOptions = {
            stay: false,
            trapFocus: false,
            position: "top-left",
            tooltipClass: `lg-tooltip lg-tooltip--d3 ${
                this.tooltipClass ? " " + this.tooltipClass : ""
            }`,
            panelClass: "chart-overlay",
            content: this._tooltipPortal,
            delayHide: 150,
            target: this._elementRef
        };
        this._tooltip = this._tooltipService.create({
            ...commonOptions
        });
    }

    private _getTooltipContent(): TemplatePortal<IImplicitContext<LgBoxplotTooltipContext>> {
        return new TemplatePortal<IImplicitContext<LgBoxplotTooltipContext>>(
            this.tooltipTemplate!,
            this._viewContainerRef,
            { $implicit: this.tooltipContext! }
        );
    }

    private _trackMousePosition(): void {
        this._ngZone.runOutsideAngular(() => {
            this._trackListener = this._renderer.listen(
                this._elementRef.nativeElement,
                "mousemove",
                (event: MouseEvent) => {
                    this._lastMouseX = event.clientX;
                    this._lastMouseY = event.clientY;
                    this._updateTooltipPosition();
                    this._updateTooltipHighlight();
                }
            );
        });
    }

    private _updateTooltipPosition(): void {
        if (this._tooltip && this._tooltip.visible) {
            if (this._lastMouseX && this._lastMouseY)
                this._tooltip.setPositionAt(
                    this._lastMouseX,
                    this._lastMouseY,
                    getRecommendedPosition(
                        { x: this._lastMouseX, y: this._lastMouseY },
                        this._tooltip.getOverlayElement()
                    )
                );
            else this._tooltip.hide();
        }
    }

    private _updateTooltipHighlight(): void {
        if (!this._lastHoveredBoxes || this._tooltipHidden) return;
        const hoveredGroup = d3.select(this._lastHoveredBoxes.node().parentNode);
        const minMaxLines = hoveredGroup.selectAll("line");
        const boxes = hoveredGroup.selectAll(".box");

        const mainLine = minMaxLines
            .filter((_unused, i) => i === LgBoxplotLineIndices.MainLineVertical)
            .node() as Element;
        const mainBox = boxes
            .filter((_unused, i) => i === LgBoxplotTypeIndices.Main)
            .node() as Element;

        if (!mainLine.getClientRects()[0]) return;
        const mainY = mainLine.getClientRects()[0].top;
        const mainDiffFromY = Math.abs(this._lastMouseY - mainY);

        const comparingLine = minMaxLines
            .filter((_unused, i) => i === LgBoxplotLineIndices.ComparingLineVertical)
            .node() as Element;
        const comparingBox = boxes
            .filter((_unused, i) => i === LgBoxplotTypeIndices.Comparing)
            .node() as Element;

        const comparingY = comparingLine?.getClientRects()[0].top;
        const comparingDiffFromY = Math.abs(this._lastMouseY - comparingY);

        this._tooltipIsComparing = !!(this.comparingAgainst && mainDiffFromY > comparingDiffFromY);

        const comparingLine2 = minMaxLines
            .filter((_unused, i) => i === LgBoxplotLineIndices.ComparingLineVertical2)
            .node() as Element;
        const comparingBox2 = boxes
            .filter((_unused, i) => i === LgBoxplotTypeIndices.Comparing2)
            .node() as Element;

        const comparingY2 = comparingLine2?.getClientRects()[0].top;
        const comparingDiffFromY2 = Math.abs(this._lastMouseY - comparingY2);

        this._tooltipIsComparing2 = !!(
            this.comparingAgainst2 && mainDiffFromY > comparingDiffFromY2
        );

        const minMaxLine = this._tooltipIsComparing2
            ? comparingLine2
            : this._tooltipIsComparing
              ? comparingLine
              : mainLine;
        const box = this._tooltipIsComparing2
            ? comparingBox2
            : this._tooltipIsComparing
              ? comparingBox
              : mainBox;

        const minMaxRect = minMaxLine.getClientRects()[0];
        const boxRect = box.getClientRects()[0];

        const minX = minMaxRect.left;
        const maxX = minMaxRect.right;
        const boxTop = boxRect.right;
        const boxBottom = boxRect.left;

        if (this._lastMouseX < minX) this._highlighted = LgBoxplotHighlighted.Min;
        else if (this._lastMouseX > maxX) this._highlighted = LgBoxplotHighlighted.Max;
        else if (this._lastMouseX > minX && this._lastMouseX < boxBottom) {
            this._highlighted = LgBoxplotHighlighted.LowerQuartile;
        } else if (this._lastMouseX < maxX && this._lastMouseX > boxTop) {
            this._highlighted = LgBoxplotHighlighted.UpperQuartile;
        } else this._highlighted = LgBoxplotHighlighted.Median;

        this.tooltipContext = {
            ...this.tooltipContext!,
            highlighted: this._isLabelHovered ? null : this._highlighted,
            isComparing:
                this._tooltipIsComparing || !!(this.comparingAgainst && this._isLabelHovered),
            isComparing2:
                this._tooltipIsComparing2 || !!(this.comparingAgainst2 && this._isLabelHovered),
            isLabelHovered: this._isLabelHovered,
            iconTooltip: false
        };
    }

    private _triggerDataSpecificMethods(): void {
        const hasNoData = this.data == null || this.data.length === 0;
        const hasNoComputedData = this.computedData == null || this.computedData.length === 0;
        const shouldRender = hasNoData && hasNoComputedData;
        if (shouldRender) {
            if (isDevMode()) {
                this._lgConsole.warn(
                    "No data are available for rendering. Stopping code execution"
                );
            }
            return;
        }
        this._convertData();
        if (!this.condensed) this._updateNumberOfGroups();
        this._initializeColorScales(this._data);
        this._updateLegend();
        if (this.condensed) this._countCondensedValues();
    }

    private _convertData(): void {
        if (this.computedData) {
            this._convertComputedData();
            return;
        }
        this._convertRegularData();
    }

    private _convertComputedData(): void {
        this._data = ldCloneDeep(this.computedData ?? []);
        this._groupNames = this._data.map(i => i.key);
        this._setBoundaries();
        this._isComparing = this.comparingAgainst != null;
        this._isComparing2 = this.comparingAgainst2 != null;

        this._data.forEach((d: LgBoxplotDatum, i) => {
            d.value.index = i;
        });

        this._legendDefinitionFromData = [];
        if (this.useColorsFromData) {
            const addLegendDefinitionItem = (
                d: LgBoxplotDatum,
                type: LgBoxplotTypeIndices
            ): void => {
                const currentLegendItem = (
                    type === LgBoxplotTypeIndices.Comparing
                        ? d.value.comparing
                        : type === LgBoxplotTypeIndices.Comparing2
                          ? d.value.comparing2
                          : d.value.main
                )?.legendItem;
                if (
                    currentLegendItem?.name &&
                    !this._legendDefinitionFromData.find(
                        color => color.name === currentLegendItem.name
                    )
                ) {
                    this._legendDefinitionFromData.push({
                        color: currentLegendItem.color,
                        name: currentLegendItem.name,
                        order: currentLegendItem.order ?? 0
                    });
                }
            };

            this._data.forEach((d: LgBoxplotDatum) => {
                addLegendDefinitionItem(d, LgBoxplotTypeIndices.Main);
                addLegendDefinitionItem(d, LgBoxplotTypeIndices.Comparing);
                addLegendDefinitionItem(d, LgBoxplotTypeIndices.Comparing2);
            });

            this._legendDefinitionFromData.sort((a, b) => (a.order ?? 0) - (b.order ?? 0));
        }
    }

    private _convertRegularData(): void {
        let index = 0;
        this._data = Array.from(
            d3.rollup(
                this.data ?? [],
                (d: LgBoxplotItem[]) => {
                    const main = d.filter(i => i.group !== this.comparingAgainst);
                    const comparing = d.filter(i => i.group === this.comparingAgainst);
                    const comparing2 = d.filter(i => i.group === this.comparingAgainst2);

                    const getItem = (d: LgBoxplotItem[]): LgConvertedBoxplotItem => {
                        const group = d[0]?.group;
                        const q1 =
                            d3.quantile(d.map(g => g.itemValue).sort(d3.ascending), 0.25) ?? 0;
                        const median = d3.quantile(d.map(g => g.itemValue).sort(d3.ascending), 0.5);
                        const average = d3.mean(d.map(g => g.itemValue));
                        const q3 =
                            d3.quantile(d.map(g => g.itemValue).sort(d3.ascending), 0.75) ?? 0;
                        const interQuantileRange = q3 - q1;
                        const min = d.reduce(
                            (x, y) => (x < y.itemValue ? x : y.itemValue),
                            Infinity
                        );
                        const max = d.reduce(
                            (x, y) => (x > y.itemValue ? x : y.itemValue),
                            -Infinity
                        );
                        return <LgConvertedBoxplotItem>{
                            group,
                            q1,
                            median,
                            average,
                            q3,
                            interQuantileRange,
                            min,
                            max
                        };
                    };

                    return <LgComparingBoxplotItem>{
                        main: getItem(main),
                        // Note: this, together with the logic in bound calculation, is broken, because it forces the minimum to be always zero.
                        // This may or may not be intended, but it would be better to refactor the code to make the desired intent clear (or fix it,
                        // if it is in fact wrong). Please do that before any big extensions of the data processing code.
                        comparing: comparing ? getItem(comparing) : null,
                        comparing2: comparing2 ? getItem(comparing2) : null,
                        index: index++
                    };
                },
                (d: LgBoxplotItem) => d.itemName
            )
        ).map(([key, value]) => ({ key, value }));

        this._setBoundaries();
        this._groupNames = this._data.map(i => i.key);
        this._isComparing = this.comparingAgainst != null;
        this._isComparing2 = this.comparingAgainst2 != null;
    }

    private _setBoundaries(): void {
        this._min = Infinity;
        this._max = this.xMax ? this.xMax : -Infinity;
        this._data.forEach(d => {
            let min =
                (d.value.comparing?.min ?? 0) < (d.value.main.min ?? Infinity)
                    ? d.value.comparing?.min
                    : d.value.main.min;
            min =
                (d.value.comparing2?.min ?? 0) < (min ?? Infinity) ? d.value.comparing2?.min : min;
            min = min ?? 0;
            let max =
                (d.value.comparing?.max ?? 0) > (d.value.main.max ?? -Infinity)
                    ? d.value.comparing?.max
                    : d.value.main.max;
            max =
                (d.value.comparing2?.max ?? 0) > (max ?? -Infinity) ? d.value.comparing2?.max : max;
            max = max ?? 0;
            if (this._min > min) this._min = min;
            if (this._max < max) this._max = max;
        });
    }

    /*
        Copied from lg-grouped-bar-chart-horizontal:1136.
        I don't like the constants, but I don't know why these numbers are there.
        To keep it consistent, leaving this as is.
    */
    private _updateNumberOfGroups(): void {
        const numberOfGroups = Math.max(0, Math.floor((this.height - 32 - 20) / 24));
        this._data = this._data.slice(0, numberOfGroups);
        this.numberOfDisplayedGroups.emit(this._data.length);
    }

    private _initializeColorScales(data: LgBoxplotDatum[]): void {
        if (this._colorPalette.useNewColorPalette) {
            const colors = this._colorPalette.getColorsForType(this.colorConfiguration);
            this._groupColors = d3.scaleOrdinal(colors);
            return;
        }
        this._initializeLegacyColorScales(data);
    }

    /**
     * @deprecated
     */
    private _initializeLegacyColorScales(data: LgBoxplotDatum[]): void {
        if (!data || !data.length) {
            return;
        }
        const colors = this._legacyColorPalette.getPaletteForColors(this.groupColors ?? "");
        this._groupColors = d3.scaleOrdinal(colors);
    }

    private _updateLegend(): void {
        this._legendDefinition = [];

        const legendVisible = this.legendOptions.visible;
        const legendBelow = legendVisible && this.legendOptions.position === "bottom";
        const legendSize = this._getLegendSize();
        const legendOnTheRight = legendVisible && this.legendOptions.position === "right";

        const spaceBelowAxis =
            (this._margin.bottom ?? 0) +
            (legendBelow ? legendSize : 0) +
            this._xAxisLabelsLineHeight;

        this._legendWidth = legendOnTheRight ? legendSize : 0;
        this._legendPaddingBottom = spaceBelowAxis ? spaceBelowAxis : 0;

        if (this._legendDefinitionFromData?.length) {
            this._legendDefinitionFromData.forEach(color => {
                this._legendDefinition.push({
                    color: color.color,
                    name: color.name,
                    opacity: 1
                });
            });
        } else {
            this._legendDefinition.push({
                color: this._getColor(null, LgBoxplotTypeIndices.Main),
                name: this.mainLabel ?? "",
                opacity: 1
            });
            if (this._isComparing) {
                this._legendDefinition.push({
                    color: this._getColor(null, LgBoxplotTypeIndices.Comparing),
                    name: this.comparingAgainst ?? "",
                    opacity: 1
                });
            }
            if (this._isComparing2) {
                this._legendDefinition.push({
                    color: this._getColor(null, LgBoxplotTypeIndices.Comparing2),
                    name: this.comparingAgainst2 ?? "",
                    opacity: 1
                });
            }
        }

        const groupToColor: Record<string, LegendItem> = {};
        this._legendDefinition.forEach(def => (groupToColor[def.name] = def));
        this._groupToLegendDefinitionDictionary = groupToColor;
    }

    private _render(): void {
        if (this._data == null || this._data.length === 0) {
            if (isDevMode()) {
                this._lgConsole.warn(
                    "No data are available for rendering. Stopping code execution"
                );
            }
            return;
        }
        if (this.condensed) {
            this._boxSpaceFromMiddle = CONDENSED_SPACE_BETWEEN_BOXES / 2;
            this._spaceBetweenLabelsAndGrid = 0;
            this._xAxisLabelsLineHeight = this.hideXAxis ? 0 : X_AXIS_LABELS_LINE_HEIGHT_CONDENSED;
        }
        this._createSvg();
        this._initializeScales();
        this._createShadowGroups();
        this._createAxes();
        this._createBoxes();
        this._addAxisHoverHandler();
        this._drawColumnIcons();
    }

    private _createSvg(): void {
        this._drawMainSvgHolder();
    }

    private _drawMainSvgHolder(): void {
        this._svg = d3
            .select(this.chartHolder.nativeElement)
            .append("svg")
            .attr("width", this._svgWidth)
            .attr("height", this._svgHeight);
        this._svgG = this._svg.append("g");
        this._svg.on("mouseleave", (_event: MouseEvent) => this._tooltip.hide());
        this._columnIconsGroup = this._svgG
            .append("g")
            .attr("class", "lg-boxplot-horizontal-chart__columnIcons");
    }

    private _drawColumnIcons(): void {
        this._columnIconsGroup.selectAll<SVGGElement, LgBoxplotDatum>(".lg-icon").remove();

        if (this.columnIcons) {
            const self = this;
            const icon = this.columnIconOptions?.icon;
            const columnItems: LgBoxplotDatum[] = this._data.filter(this.columnIcons);

            const columnIconsSelection = this._columnIconsGroup
                .selectAll<SVGGElement, LgBoxplotDatum>(".lg-icon")
                .data(columnItems);

            const bandwidth = this._yScale.bandwidth();
            const firstPointPosition = bandwidth / 2;

            const columnIcons = columnIconsSelection
                .enter()
                .append("g")
                .attr("transform", d => {
                    return (
                        "translate(" +
                        (this._yAxisLabelsWidth - ICON_SIZE) +
                        "," +
                        (firstPointPosition + (d.value.index ?? 0) * bandwidth) +
                        ")"
                    );
                })
                .attr(
                    "class",
                    `${icon} lg-icon lg-icon--${this.columnIconOptions?.iconType} lg-tooltip-visible`
                )
                .merge(columnIconsSelection)
                .append("use")
                .attr("xlink:href", `#${icon}`)
                .attr("height", ICON_SIZE)
                .attr("width", ICON_SIZE);

            columnIcons
                .on("mousemove", function (_event: MouseEvent) {
                    if (self.columnIconOptions?.tooltip && self.columnIcons)
                        self._onMouseOverColumnIcon(d3.select(this));
                })
                .on("mouseout", (_event: MouseEvent) => {
                    if (self.columnIconOptions?.tooltip && self.columnIcons) self._tooltip.hide();
                })
                .transition()
                .duration(0)
                .ease(d3.easeCubicOut);
        }
    }

    private _onMouseOverColumnIcon(target: d3.Selection<any, LgBoxplotDatum, any, any>): void {
        const data = target.data();
        this.tooltipContext = {
            name: data[0].key,
            main: data[0].value.main,
            comparing: data[0].value.comparing!,
            comparing2: data[0].value.comparing2!,
            groupToLegendDefinitionDictionary: this._groupToLegendDefinitionDictionary,
            highlighted: this._highlighted,
            isComparing: this._tooltipIsComparing,
            isComparing2: this._tooltipIsComparing2,
            isLabelHovered: this._isLabelHovered,
            iconTooltip: true
        };

        this._tooltip.show({ target: target.node() });
    }

    private _initializeScales(): void {
        this._yScale = d3
            .scaleBand()
            .range([(this._margin.top ?? 0) + this._xAxisLabelsHeight, this._svgHeight])
            .domain(this._data.map(i => i.key));

        const roundTo = this.roundToNearestMultipleOf;
        const min = roundTo == null ? this._min : Math.floor(this._min / roundTo) * roundTo;
        const max = roundTo == null ? this._max : Math.ceil(this._max / roundTo) * roundTo;
        this._xScale = d3.scaleLinear().range([0, this._rowWidth]).domain([min, max]);
    }

    private _createShadowGroups(): void {
        this._shadowBoxGroups = this._svgG
            .selectAll("boxgroup")
            .data(this._data)
            .enter()
            .append("g")
            .attr("class", "shadow-box-group");
        this._shadowBoxGroups
            .append("rect")
            .attr("class", "shadow-box-rect")
            .attr("width", `${this._rowWidth}`)
            .attr("height", () => this._yScale.bandwidth())
            .attr(
                "transform",
                d =>
                    `translate(${this._yAxisLabelsWidth},${
                        (this._yScale(d.key) ?? 0) - this._xAxisLabelsHeight
                    })`
            )
            .attr("fill", "transparent");
    }

    private _createAxes(): void {
        this._addXAxisGrid();
        this._addYAxis();
        this._addXAxis();
    }

    private _addYAxis(): void {
        this._yAxisGroup = this._svgG
            .append("g")
            .attr("class", `${this._useNewLabels ? "y__axis" : "y__axis__legacy"}`)
            .attr("transform", `translate(${this._yAxisLabelsWidth}, ${-this._xAxisLabelsHeight})`);

        if (this.showYAxisLabels)
            this._yAxisLabel = this._svgG
                .append("text")
                .attr("class", "axis__title")
                .text(this.yAxisLabel ?? "")
                .attr("text-anchor", "middle")
                .attr(
                    "transform",
                    `translate(${this._margin.left}, ${this._svgHeight / 2}) rotate(-90)`
                );

        this._yAxisGroup.call(this._getYAxis());
    }

    private _getYAxis(): d3.Axis<string> {
        return d3
            .axisLeft(this._yScale)
            .tickSize(0)
            .tickPadding(this.columnIcons ? ICON_SIZE : 12)
            .tickFormat(item => {
                const label = this._getYAxisLabels(item);
                const maxAllowedLength = this.labelLength + 2;
                if (label.length > maxAllowedLength) {
                    return label.substring(0, this.labelLength) + "...";
                }
                return label;
            });
    }

    private _getYAxisLabels(value: any): string {
        return this.showYAxisLabels ? value : "";
    }

    private _addXAxisGrid(): void {
        this._xAxisGridG = this._svgG
            .append("g")
            .attr("class", `${this._useNewLabels ? "x__axis__grid" : "x__axis__grid__legacy"}`)
            .attr(
                "transform",
                `translate(${this._yAxisLabelsWidth}, ${this._margin.top}) rotate(-90)`
            );

        this._xAxisGridG.transition().duration(250).call(this._getXAxisGrid(this._xScale));
    }

    private _getXAxisGrid(scale: d3.ScaleLinear<number, number>): d3.Axis<d3.NumberValue> {
        return d3
            .axisLeft(scale)
            .tickPadding(0)
            .tickFormat(() => "")
            .ticks(this.tickCount)
            .tickSizeOuter(0)
            .tickSizeInner(this._svgHeight - this._xAxisLabelsHeight - (this._margin.top ?? 0));
    }

    private _addXAxis(): void {
        this._xAxisGroup = this._svgG
            .append("g")
            .attr(
                "class",
                `${
                    (this._useNewLabels ? "x__axis" : "x__axis__legacy") +
                    (this.showXAxisLabels ? "" : " x__axis--hidden")
                }`
            )
            .attr(
                "transform",
                `translate(${this._yAxisLabelsWidth},${this._verticalPositionOfXAxis})`
            );

        if (this.showXAxisLabels)
            this._xAxisLabel = this._svgG
                .append("text")
                .attr("class", "axis__title")
                .text(this.xAxisLabel ?? "")
                .attr("text-anchor", "start")
                .attr(
                    "transform",
                    `translate(
                    0,
                    ${this._verticalPositionOfXAxis}
                )`
                )
                .attr("dy", "0.38em");

        this._xAxisGroup.transition().duration(250).call(this._getXAxis());

        this._xAxisGroup
            .selectAll(".tick text")
            .transition()
            .duration(250)
            .attr("transform", `translate(0, ${-this._spaceBetweenLabelsAndGrid})`);
    }

    private _getXAxis(): d3.Axis<d3.NumberValue> {
        return d3
            .axisBottom(this._xScale)
            .tickSize(0)
            .tickPadding(3)
            .tickFormat(item => this._getXAxisLabels(item))
            .ticks(this.tickCount);
    }

    private _getXAxisLabels(value: any): string {
        return this.showXAxisLabels ? this._numberFormat(value) : "";
    }

    private _createBoxes(): void {
        this._createBoxGroups();
        this._drawMain();
        if (this._isComparing) this._drawComparing();
        if (this._isComparing2) this._drawComparing2();
        this._addHoverBoxes();
    }

    private _drawMain(): void {
        this._addHorizontalLines(LgBoxplotTypeIndices.Main);
        this._addBoxes(LgBoxplotTypeIndices.Main);
        this._drawMedian(LgBoxplotTypeIndices.Main);
        if (this.condensed) this._addMedians(LgBoxplotTypeIndices.Main);
    }

    private _drawComparing(): void {
        this._addHorizontalLines(LgBoxplotTypeIndices.Comparing);
        this._addBoxes(LgBoxplotTypeIndices.Comparing);
        this._drawMedian(LgBoxplotTypeIndices.Comparing);
        if (this.condensed) this._addMedians(LgBoxplotTypeIndices.Comparing);
    }

    private _drawComparing2(): void {
        this._addHorizontalLines(LgBoxplotTypeIndices.Comparing2);
        this._addBoxes(LgBoxplotTypeIndices.Comparing2);
        this._drawMedian(LgBoxplotTypeIndices.Comparing2);
        if (this.condensed) this._addMedians(LgBoxplotTypeIndices.Comparing2);
    }

    private _createBoxGroups(): void {
        this._boxGroupsG = this._svgG.append("g");
        this._boxGroups = this._boxGroupsG
            .selectAll("boxgroup")
            .data(this._data)
            .enter()
            .append("g")
            .attr("class", "box-group");
    }

    private _addHorizontalLines(type: LgBoxplotTypeIndices): void {
        this._boxGroups
            .data(this._data)
            .append("line")
            .attr("x1", d => {
                if (type === LgBoxplotTypeIndices.Comparing2 && d.value.comparing2?.min == null)
                    return 0;
                else if (type === LgBoxplotTypeIndices.Comparing && d.value.comparing?.min == null)
                    return 0;
                else if (type === LgBoxplotTypeIndices.Main && d.value.main.min == null) return 0;

                const yAxis = this._yAxisLabelsWidth;
                if (type === LgBoxplotTypeIndices.Comparing2)
                    return yAxis + this._xScale(d.value.comparing2?.min ?? 0);
                if (type === LgBoxplotTypeIndices.Comparing)
                    return yAxis + this._xScale(d.value.comparing?.min ?? 0);
                return yAxis + this._xScale(d.value.main.min ?? 0);
            })
            .attr("x2", d => {
                if (type === LgBoxplotTypeIndices.Comparing2 && d.value.comparing2?.max == null)
                    return 0;
                else if (type === LgBoxplotTypeIndices.Comparing && d.value.comparing?.max == null)
                    return 0;
                else if (type === LgBoxplotTypeIndices.Main && d.value.main.max == null) return 0;

                const yAxis = this._yAxisLabelsWidth;
                if (type === LgBoxplotTypeIndices.Comparing2)
                    return yAxis + this._xScale(d.value.comparing2?.max ?? 0);
                if (type === LgBoxplotTypeIndices.Comparing)
                    return yAxis + this._xScale(d.value.comparing?.max ?? 0);
                return yAxis + this._xScale(d.value.main.max ?? 0);
            })
            .attr("y1", d => {
                const boxGroupStart = (this._yScale(d.key) ?? 0) - this._xAxisLabelsHeight;
                const boxGroupHeight = this._yScale.bandwidth();
                const boxGroupMiddleOfTwo = boxGroupHeight / 2;
                const boxGroupMiddleOfThree = boxGroupHeight / 3;
                const boxHeight = this._boxHeight;
                const boxMiddle = boxHeight / 2;
                const startPoint =
                    boxGroupStart +
                    boxMiddle +
                    (this._isComparing2 ? boxGroupMiddleOfThree : boxGroupMiddleOfTwo);

                if (type === LgBoxplotTypeIndices.Comparing2 && this._isComparing2) {
                    return (
                        startPoint +
                        this._boxSpaceFromMiddle +
                        boxHeight +
                        CONDENSED_SPACE_BETWEEN_BOXES
                    );
                } else if (type === LgBoxplotTypeIndices.Comparing) {
                    return startPoint + this._boxSpaceFromMiddle;
                } else if (
                    type === LgBoxplotTypeIndices.Main &&
                    (this._isComparing || this._isComparing2)
                ) {
                    return startPoint - boxHeight - this._boxSpaceFromMiddle;
                }
                return boxGroupStart + boxGroupMiddleOfTwo;
            })
            .attr("y2", d => {
                const boxGroupStart = (this._yScale(d.key) ?? 0) - this._xAxisLabelsHeight;
                const boxGroupHeight = this._yScale.bandwidth();
                const boxGroupMiddleOfTwo = boxGroupHeight / 2;
                const boxGroupMiddleOfThree = boxGroupHeight / 3;
                const boxHeight = this._boxHeight;
                const boxMiddle = boxHeight / 2;
                const startPoint =
                    boxGroupStart +
                    boxMiddle +
                    (this._isComparing2 ? boxGroupMiddleOfThree : boxGroupMiddleOfTwo);

                if (type === LgBoxplotTypeIndices.Comparing2 && this._isComparing2) {
                    return (
                        startPoint +
                        this._boxSpaceFromMiddle +
                        boxHeight +
                        CONDENSED_SPACE_BETWEEN_BOXES
                    );
                } else if (type === LgBoxplotTypeIndices.Comparing) {
                    return startPoint + this._boxSpaceFromMiddle;
                } else if (
                    type === LgBoxplotTypeIndices.Main &&
                    (this._isComparing || this._isComparing2)
                ) {
                    return startPoint - boxHeight - this._boxSpaceFromMiddle;
                }
                return boxGroupStart + boxGroupMiddleOfTwo;
            })
            .attr("stroke", d => {
                return this._getColor(d, type);
            });
    }

    private _addBoxes(type: LgBoxplotTypeIndices): void {
        this._boxGroups
            .data(this._data)
            .append("rect")
            .attr("class", "box")
            .attr("x", d => {
                const yAxis = this._yAxisLabelsWidth;
                const datum =
                    type === LgBoxplotTypeIndices.Comparing2
                        ? d.value.comparing2
                        : type === LgBoxplotTypeIndices.Comparing
                          ? d.value.comparing
                          : d.value.main;
                const startPosition = this._xScale(datum?.q1 ?? 0);
                const endPosition = this._xScale(datum?.q3 ?? 0);
                if (startPosition == null) return 0;
                if (startPosition !== endPosition) return yAxis + startPosition;
                return yAxis + startPosition - MIN_BOX_WIDTH / 2;
            })
            .attr("y", d => {
                const boxGroupStart = (this._yScale(d.key) ?? 0) - this._xAxisLabelsHeight;
                const boxGroupHeight = this._yScale.bandwidth();
                const boxGroupMiddleOfTwo = boxGroupHeight / 2;
                const boxGroupMiddleOfThree = boxGroupHeight / 3;
                const boxHeight = this._boxHeight;
                const boxMiddle = boxHeight / 2;
                const startPoint =
                    boxGroupStart +
                    (this._isComparing2 ? boxGroupMiddleOfThree : boxGroupMiddleOfTwo);

                if (type === LgBoxplotTypeIndices.Comparing2 && this._isComparing2) {
                    return (
                        startPoint +
                        this._boxSpaceFromMiddle +
                        boxHeight +
                        CONDENSED_SPACE_BETWEEN_BOXES
                    );
                } else if (type === LgBoxplotTypeIndices.Comparing) {
                    return startPoint + this._boxSpaceFromMiddle;
                } else if (
                    type === LgBoxplotTypeIndices.Main &&
                    (this._isComparing || this._isComparing2)
                ) {
                    return startPoint - boxHeight - this._boxSpaceFromMiddle;
                }
                return boxGroupStart - boxMiddle + boxGroupMiddleOfTwo;
            })
            .attr("height", this._boxHeight)
            .attr("width", d => {
                const datum =
                    type === LgBoxplotTypeIndices.Comparing2
                        ? d.value.comparing2
                        : type === LgBoxplotTypeIndices.Comparing
                          ? d.value.comparing
                          : d.value.main;
                if (datum?.median == null) return 0;
                const width = this._xScale(datum.q3 ?? 0) - this._xScale(datum.q1 ?? 0);
                return Math.max(width, MIN_BOX_WIDTH);
            })
            .attr("stroke", d => {
                return this._getColor(d, type);
            })
            .style("fill", d => {
                return this._getColor(d, type);
            });
    }

    private _drawMedian(type: LgBoxplotTypeIndices): void {
        this._boxGroups
            .data(this._data)
            .append("line")
            .attr("x1", d => {
                if (type === LgBoxplotTypeIndices.Comparing2 && d.value.comparing2?.median == null)
                    return 0;
                else if (
                    type === LgBoxplotTypeIndices.Comparing &&
                    d.value.comparing?.median == null
                )
                    return 0;
                else if (type === LgBoxplotTypeIndices.Main && d.value.main.median == null)
                    return 0;

                const yAxis = this._yAxisLabelsWidth;
                if (type === LgBoxplotTypeIndices.Comparing2)
                    return yAxis + this._xScale(d.value.comparing2?.median ?? 0);
                if (type === LgBoxplotTypeIndices.Comparing)
                    return yAxis + this._xScale(d.value.comparing?.median ?? 0);
                return yAxis + this._xScale(d.value.main.median ?? 0);
            })
            .attr("x2", d => {
                if (type === LgBoxplotTypeIndices.Comparing2 && d.value.comparing2?.median == null)
                    return 0;
                else if (
                    type === LgBoxplotTypeIndices.Comparing &&
                    d.value.comparing?.median == null
                )
                    return 0;
                else if (type === LgBoxplotTypeIndices.Main && d.value.main.median == null)
                    return 0;

                const yAxis = this._yAxisLabelsWidth;
                if (type === LgBoxplotTypeIndices.Comparing2)
                    return yAxis + this._xScale(d.value.comparing2?.median ?? 0);
                if (type === LgBoxplotTypeIndices.Comparing)
                    return yAxis + this._xScale(d.value.comparing?.median ?? 0);
                return yAxis + this._xScale(d.value.main.median ?? 0);
            })
            .attr("y1", d => {
                const boxGroupStart = (this._yScale(d.key) ?? 0) - this._xAxisLabelsHeight;
                const boxGroupHeight = this._yScale.bandwidth();
                const boxGroupMiddleOfTwo = boxGroupHeight / 2;
                const boxGroupMiddleOfThree = boxGroupHeight / 3;
                const boxHeight = this._boxHeight;
                const boxMiddle = boxHeight / 2;
                const startPoint =
                    boxGroupStart +
                    (this._isComparing2 ? boxGroupMiddleOfThree : boxGroupMiddleOfTwo);

                if (type === LgBoxplotTypeIndices.Comparing2 && this._isComparing2) {
                    return (
                        startPoint +
                        this._boxSpaceFromMiddle +
                        boxHeight +
                        CONDENSED_SPACE_BETWEEN_BOXES
                    );
                } else if (type === LgBoxplotTypeIndices.Comparing) {
                    return startPoint + this._boxSpaceFromMiddle;
                } else if (
                    type === LgBoxplotTypeIndices.Main &&
                    (this._isComparing || this._isComparing2)
                ) {
                    return startPoint - boxHeight - this._boxSpaceFromMiddle;
                }
                return startPoint - boxMiddle;
            })
            .attr("y2", d => {
                const boxGroupStart = (this._yScale(d.key) ?? 0) - this._xAxisLabelsHeight;
                const boxGroupHeight = this._yScale.bandwidth();
                const boxGroupMiddleOfTwo = boxGroupHeight / 2;
                const boxGroupMiddleOfThree = boxGroupHeight / 3;
                const boxHeight = this._boxHeight;
                const boxMiddle = boxHeight / 2;
                const startPoint =
                    boxGroupStart +
                    boxHeight +
                    (this._isComparing2 ? boxGroupMiddleOfThree : boxGroupMiddleOfTwo);

                if (type === LgBoxplotTypeIndices.Comparing2 && this._isComparing2) {
                    return (
                        startPoint +
                        this._boxSpaceFromMiddle +
                        boxHeight +
                        CONDENSED_SPACE_BETWEEN_BOXES
                    );
                } else if (type === LgBoxplotTypeIndices.Comparing) {
                    return startPoint + this._boxSpaceFromMiddle;
                } else if (
                    type === LgBoxplotTypeIndices.Main &&
                    (this._isComparing || this._isComparing2)
                ) {
                    return startPoint - boxHeight - this._boxSpaceFromMiddle;
                }
                return startPoint - boxMiddle;
            })
            .attr("stroke", this.medianLineColor === "white" ? "#fff" : "#000")
            .attr("stroke-width", d => {
                const datum =
                    type === LgBoxplotTypeIndices.Comparing2
                        ? d.value.comparing2
                        : type === LgBoxplotTypeIndices.Comparing
                          ? d.value.comparing
                          : d.value.main;
                if (datum?.median == null) return "0px";
                return "2px";
            });
    }

    private _addHoverBoxes(): void {
        const self = this;

        const boxRects = this._boxGroups.append("rect");

        boxRects
            .attr("class", "box-shadow")
            .attr("width", `${this._rowWidth}`)
            .attr("height", `${this._yScale.bandwidth()}`)
            .attr(
                "transform",
                d =>
                    `translate(${this._yAxisLabelsWidth}, ${
                        (this._yScale(d.key) ?? 0) - this._xAxisLabelsHeight
                    })`
            )

            .attr("fill", "transparent")
            .on("mouseover", function (event: MouseEvent) {
                self._lastMouseX = event.clientX;
                self._lastMouseY = event.clientY;
                const targetBoxes = d3
                    .select(this.parentNode as d3.BaseType)
                    .selectAll(".box") as d3.Selection<any, LgBoxplotDatum, any, any>;
                self._lastHoveredBoxes = targetBoxes;
                self._onBoxMouseOver(targetBoxes);
            })
            .on("mouseout", function (_event: MouseEvent) {
                const targetBoxes = d3
                    .select(this.parentNode as d3.BaseType)
                    .selectAll(".box") as d3.Selection<any, LgBoxplotDatum, any, any>;
                self._onBoxMouseOut(targetBoxes);
                self._tooltip.hide();
                self._tooltipHidden = true;
            })
            .on("mouseleave", (event: MouseEvent) => {
                const x = event.clientX;
                const y = event.clientY;
                if (!x && !y) {
                    this._tooltip.hide();
                    this._tooltipHidden = true;
                    return;
                }
                const rects = (this._boxGroupsG.node() as HTMLElement)?.getBoundingClientRect();
                const isInX = x > rects.left && x < rects.right;
                const isInY = y > rects.top && y < rects.bottom;
                const isInElement = isInX && isInY;
                if (!isInElement) {
                    this._tooltip.hide();
                    this._tooltipHidden = true;
                }
            })
            .on("click", function (_event: MouseEvent, d: LgBoxplotDatum) {
                const index = boxRects.nodes().indexOf(this);
                self._onClick(d, index);
            });
    }

    private _addMedians(type: LgBoxplotTypeIndices): void {
        this._boxGroups
            .data(this._data)
            .append("text")
            .text(d => {
                const currentValue =
                    type === LgBoxplotTypeIndices.Comparing2
                        ? d.value.comparing2?.median
                        : type === LgBoxplotTypeIndices.Comparing
                          ? d.value.comparing?.median
                          : d.value.main.median;
                return (
                    (currentValue == null
                        ? "-"
                        : this._axisFormatter.format(currentValue, {
                              decimals: this.condensedMedianDecimals
                          })) + (this.medianLabelsText ? ` ${this.medianLabelsText}` : "")
                );
            })
            .attr("class", "median-label")
            .attr("text-anchor", "end")
            .attr("width", this._spaceForMedianLabel)
            .attr("height", this._yScale.bandwidth() / 2)
            .attr("x", this.width)
            .attr("y", d => {
                const boxGroupStart =
                    (this._yScale(d.key) ?? 0) -
                    this._xAxisLabelsHeight +
                    SPACE_BETWEEN_GRAPH_AND_MEDIAN_LABELS;
                const boxGroupMiddle = this._yScale.bandwidth() / 2;
                const boxHeight = this._boxHeight;
                if (this._isComparing2) {
                    const boxGroupMiddle2 = this._yScale.bandwidth() / 3;
                    if (type === LgBoxplotTypeIndices.Comparing2) {
                        return boxGroupStart + boxGroupMiddle2 + boxHeight;
                    } else if (type === LgBoxplotTypeIndices.Comparing) {
                        return boxGroupStart + boxGroupMiddle2 - CONDENSED_SPACE_BETWEEN_BOXES;
                    } else if (type === LgBoxplotTypeIndices.Main && this._isComparing) {
                        return boxGroupStart - CONDENSED_SPACE_BETWEEN_BOXES;
                    }
                } else if (type === LgBoxplotTypeIndices.Comparing) {
                    return boxGroupStart + boxGroupMiddle;
                } else if (type === LgBoxplotTypeIndices.Main && this._isComparing) {
                    return boxGroupStart;
                }
                return boxGroupStart;
            });
    }

    private _addAxisHoverHandler(): void {
        const self = this;
        this._yAxisGroup
            .selectAll(".tick")
            .on("mouseover", function (event: MouseEvent, name: string | unknown) {
                self._lastMouseX = event.clientX;
                self._lastMouseY = event.clientY;
                self._boxGroups.each(function (d) {
                    if (d.key !== name) return;
                    const targetBoxes = d3.select(this).selectAll(".box") as d3.Selection<
                        any,
                        LgBoxplotDatum,
                        any,
                        any
                    >;
                    self._lastHoveredBoxes = targetBoxes;
                    self._onBoxMouseOver(targetBoxes);
                });
                self._isLabelHovered = true;
            })
            .on("mouseleave", function (_event: MouseEvent, name: string | unknown) {
                self._boxGroups.each(function (d) {
                    if (d.key !== name) return;

                    const targetBoxes = d3.select(this).selectAll(".box") as d3.Selection<
                        any,
                        LgBoxplotDatum,
                        any,
                        any
                    >;
                    self._onBoxMouseOut(targetBoxes);
                    self._tooltip.hide();
                    self._tooltipHidden = true;
                });
                self._isLabelHovered = false;
            });
    }

    private _onBoxMouseOver(targets: d3.Selection<any, LgBoxplotDatum, any, any>): void {
        this._shadowBoxGroups
            .selectAll(".shadow-box-rect")
            .filter((d: any) => d.key === targets.data()[0].key)
            .style("fill", "#F5F9FF");

        const first = targets.filter((_unused, i) => i === LgBoxplotTypeIndices.Main);
        const second = targets.filter((_unused, i) => i === LgBoxplotTypeIndices.Comparing);
        const third = targets.filter((_unused, i) => i === LgBoxplotTypeIndices.Comparing2);

        first.style("fill", d => {
            return this._getColor(d, LgBoxplotTypeIndices.Main, true);
        });
        second.style("fill", d => {
            return this._getColor(d, LgBoxplotTypeIndices.Comparing, true);
        });
        third.style("fill", d => {
            return this._getColor(d, LgBoxplotTypeIndices.Comparing2, true);
        });

        const hoveredItem = targets.data()[0];
        const itemName = hoveredItem.key;
        const itemValue = hoveredItem.value;
        this.tooltipContext = {
            name: itemName,
            main: itemValue.main,
            comparing: itemValue.comparing,
            comparing2: itemValue.comparing2,
            groupToLegendDefinitionDictionary: this._groupToLegendDefinitionDictionary,
            highlighted: this._highlighted,
            isComparing: this._tooltipIsComparing,
            isComparing2: this._tooltipIsComparing2,
            isLabelHovered: this._isLabelHovered,
            iconTooltip: false
        };
        if (this._tooltipHidden) {
            this._tooltip.show();
            this._tooltipHidden = false;
            this._updateTooltipPosition();
        }
    }

    private _onBoxMouseOut(targets: d3.Selection<any, LgBoxplotDatum, any, any>): void {
        const first = targets.filter((_unused, i) => i === LgBoxplotTypeIndices.Main);
        const second = targets.filter((_unused, i) => i === LgBoxplotTypeIndices.Comparing);
        const third = targets.filter((_unused, i) => i === LgBoxplotTypeIndices.Comparing2);

        first
            .attr("stroke", d => {
                return this._getColor(d, LgBoxplotTypeIndices.Main);
            })
            .attr("stroke-height", 2)
            .style("fill", d => {
                return this._getColor(d, LgBoxplotTypeIndices.Main);
            });
        second
            .attr("stroke", d => {
                return this._getColor(d, LgBoxplotTypeIndices.Comparing);
            })
            .attr("stroke-height", 2)
            .style("fill", d => {
                return this._getColor(d, LgBoxplotTypeIndices.Comparing);
            });
        third
            .attr("stroke", d => {
                return this._getColor(d, LgBoxplotTypeIndices.Comparing2);
            })
            .attr("stroke-height", 2)
            .style("fill", d => {
                return this._getColor(d, LgBoxplotTypeIndices.Comparing2);
            });
        this._shadowBoxGroups
            .selectAll(".shadow-box-rect")
            .filter((d: any) => d.key === targets.data()[0].key)
            .style("fill", "transparent");
    }

    private _onClick(value: any, itemIndex: number): void {
        if (!this.clickable) return;

        this.itemClick.emit({ item: value, datum: value, index: itemIndex });
    }

    _onLegendItemClick(item: LegendItem): void {
        this.legendClick.emit(item);
    }

    private _getColor(
        item: LgBoxplotDatum | null,
        type: LgBoxplotTypeIndices,
        darker?: boolean
    ): string {
        if (this._colorPalette.useNewColorPalette) {
            const color = this._groupColors(type + "");
            return darker ? (d3.color(color)?.darker(0.2).formatHex() ?? color) : color;
        }
        return this._getLegacyColor(item, type, darker);
    }

    /**
     * @deprecated
     */
    private _getLegacyColor(
        item: LgBoxplotDatum | null,
        type: LgBoxplotTypeIndices,
        darker?: boolean
    ): any {
        if (this.useColorsFromData && item?.value) {
            return (
                type === LgBoxplotTypeIndices.Comparing
                    ? item.value.comparing
                    : type === LgBoxplotTypeIndices.Comparing2
                      ? item.value.comparing2
                      : item.value.main
            )?.legendItem?.color;
        }
        const color = d3.rgb(
            this._groupColorsHex && this._groupColorsHex?.[type]
                ? this._groupColorsHex[type]
                : this._groupColors(type.toString())
        );
        if (!darker) return color;
        return color.darker(0.2);
    }

    get _svgHeight(): number {
        const legendVisible = this.legendOptions.visible;
        const legendBelow = legendVisible && this.legendOptions.position === "bottom";

        const { top = 0, bottom = 0 } = this._margin;
        const legendSize = this._getLegendSize();
        return Math.max(this._height - top - bottom - (legendBelow ? legendSize : 0), 0);
    }

    private get _svgWidth(): number {
        const legendVisible = this.legendOptions.visible;
        const legendOnTheRight = legendVisible && this.legendOptions.position === "right";

        const legendSize = this._getLegendSize();
        const { left = 0, right = 0 } = this._margin;
        return Math.max(this._width - left - right - (legendOnTheRight ? legendSize : 0), 0);
    }

    private get _verticalPositionOfXAxis(): number {
        return this._svgHeight - this._xAxisLabelsLineHeight + this._spaceBetweenLabelsAndGrid;
    }

    private get _xAxisLabelsHeight(): number {
        if (!this.showXAxisLabels) return 0;
        return this._xAxisLabelsLineHeight + this._spaceBetweenLabelsAndGrid;
    }

    private get _yAxisLabelsWidth(): number {
        if (!this.showYAxisLabels) return 0;
        return (
            this._spaceForYAxisLabels +
            (this._margin.left ?? 0) +
            this._spaceBetweenLabelsAndGrid +
            (this.columnIcons ? ICON_SIZE : 0)
        );
    }

    private get _boxHeight(): number {
        const boxHeight = this._yScale.bandwidth() / (this.condensed ? 1 : 2);
        const boxHeightComparing = boxHeight / 2;
        const boxHeightComparing2 = boxHeight / 3 - CONDENSED_SPACE_BETWEEN_BOXES;
        const comparingHeight = this._isComparing2 ? boxHeightComparing2 : boxHeightComparing;
        return this._isComparing || this._isComparing2 ? comparingHeight : boxHeight;
    }

    private _getLegendSize(): number {
        const isLegendBelow = this.legendOptions.position === "bottom";
        const isLegendOnRight = this.legendOptions.position === "right";
        if (!isLegendBelow && !isLegendOnRight) return 0;
        if (isLegendBelow) return SPACE_FOR_LEGEND_BELOW;
        return getLegendWidth(
            this._width,
            this.legendOptions.widthInPercents ?? 0,
            this._groupNames
        );
    }

    private get _rowWidth(): number {
        return (
            this._svgWidth -
            this._yAxisLabelsWidth -
            (this._margin.right ?? 0) -
            (this.condensed ? this._spaceForMedianLabel + SPACE_BETWEEN_GRAPH_AND_MEDIAN_LABELS : 0)
        );
    }
}
