/* eslint-disable @typescript-eslint/no-this-alias */
import * as d3 from "d3";
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    isDevMode,
    NgZone,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    TemplateRef,
    ViewChild,
    inject,
    ViewContainerRef
} from "@angular/core";
import { TemplatePortal } from "@angular/cdk/portal";
import {
    ILgFormatter,
    ILgFormatterOptions,
    LgConsole,
    LgFormatterFactoryService
} from "@logex/framework/core";
import { LgSimpleChanges } from "@logex/framework/types";
import { D3TooltipApi, ID3TooltipOptions, LgD3TooltipService } from "../d3";
import { doCirclePointsOverlap } from "../shared/doCirclePointsOverlap";
import { getDefaultLegendOptions } from "../shared/getDefaultLegendOptions";
import {
    ChartClickEvent,
    LegendItem,
    LegendOptions,
    Margin,
    PointReference
} from "../shared/chart.types";
import { getRecommendedPosition } from "../shared/getRecommendedPosition";
import { getLegendWidth } from "../shared/getLegendWidth";
import { coerceNumberProperty } from "@angular/cdk/coercion";
import { LgColorPaletteV2 } from "../shared/lg-color-palette-v2/lg-color-palette-v2";
import { getColor } from "../shared/lg-color-palette-v2/lg-colors";
import {
    LG_DEFAULT_COLOR_CONFIGURATION,
    LgColorPaletteIdentifiers,
    LgColorsConfiguration
} from "../shared/lg-color-palette-v2/lg-colors.types";
import {
    LgBubbleChartBoundaries,
    LgBubbleChartDatum,
    LgBubbleChartTooltipContext
} from "./lg-bubble-chart.types";
import { useTranslationNamespace } from "@logex/framework/lg-localization";
import { IExportableChart, LgChartExportContainerDirective } from "../shared/lg-chart-export";
import {
    IChartTooltipProvider,
    IImplicitContext
} from "../shared/lg-chart-template-context.directive";

const DEFAULT_MARGIN: Margin = { top: 16, right: 16, bottom: 16, left: 16 };

const DEFAULT_TICKS_FORMATTER_OPTIONS: ILgFormatterOptions = { decimals: 0 };
const DEFAULT_TOOLTIP_FORMATTER_OPTIONS: ILgFormatterOptions = { decimals: 0 };

const SPACE_BETWEEN_LABELS_AND_GRID = 8;
const Y_AXIS_TITLE_WIDTH = 20;

const X_AXIS_LABELS_LINE_HEIGHT = 50;
const SPACE_FOR_LEGEND_BELOW = 30;

const DEFAULT_TICK_COUNT = 5;
const DEFAULT_Y_AXIS_LABELS_WIDTH = 30;

const MIN_BUBBLE_RADIUS = 4;
const MAX_BUBBLE_RADIUS = 48;
const SHADOW_RADIUS = 12;
const SHADOW_COLOR = getColor(LgColorPaletteIdentifiers.Cobalt, 10);

const DATA_BUBBLE_SHADOW_SELECTOR = "shadow-bubble";
const DATA_BUBBLE_SELECTOR = "bubble";

function fill(
    element: d3.Selection<any, any, any, any>
): (color: string) => d3.Selection<any, any, any, any> {
    return (color: string) => element.attr("fill", color);
}

@Component({
    standalone: false,
    selector: "lg-bubble-chart",
    templateUrl: "./lg-bubble-chart.component.html",
    viewProviders: [useTranslationNamespace("FW._Directives._Charts._LgBubbleChart")],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LgBubbleChartComponent
    implements
        OnInit,
        OnChanges,
        OnDestroy,
        AfterViewInit,
        IExportableChart,
        IChartTooltipProvider<LgBubbleChartTooltipContext>
{
    private _colorPalette = inject(LgColorPaletteV2);
    private _elementRef = inject(ElementRef);
    private _exportContainer = inject(LgChartExportContainerDirective, { optional: true });
    private _formatterFactory = inject(LgFormatterFactoryService);
    private _lgConsole = inject(LgConsole).withSource("Logex.Charts.LgBubbleChart");
    private _ngZone = inject(NgZone);
    private _renderer = inject(Renderer2);
    private _tooltipService = inject(LgD3TooltipService);
    private _viewContainerRef = inject(ViewContainerRef);
    /**
     * @required
     * Specifies the width of the chart area in pixels.
     */
    @Input({ required: true }) width!: number;
    /**
     * @required
     * Specifies the height of the chart area in pixels.
     */
    @Input({ required: true }) height!: number;
    /**
     * @required
     * Specifies the input data for bubble chart.
     */
    @Input({ required: true }) data!: LgBubbleChartDatum[];
    /**
     * @optional
     * Specifies the margin for chart.
     * Defaults to 16 on all sides.
     *
     * @property { number } top specifies the margin of SVG from top.
     * @property { number } right specifies the margin of SVG from right.
     * @property { number } bottom specifies the margin of SVG from bottom.
     * @property { number } left specifies the margin of SVG from left.
     */
    @Input() margin?: Margin;
    /**
     * @optional
     * Specifies maximum number of ticks on axis. Defaults to 5.
     *
     * @default 5
     */
    @Input() tickCount?: number;
    /**
     * @optional
     * Specifies the Y axis title. Defaults to "Y axis title not defined".
     * Can receive empty string to hide Y axis title entirely.
     */
    @Input() yAxisLabel?: string;
    /**
     * @optional
     * Specifies the X axis title. Defaults to "X axis title not defined".
     * Can receive empty string to hide X axis title entirely.
     */
    @Input() xAxisLabel?: string;
    /**
     * @optional
     * Specifies whether X axis labels are visible or not. Defaults to true.
     *
     * @default true
     */
    @Input() showXAxisLabels = true;
    /**
     * @optional
     * Specifies whether Y axis labels are visible or not. Defaults to true.
     *
     * @default true
     */
    @Input() showYAxisLabels = true;
    /**
     * @optional
     * Specifies the width of the Y axis labels in pixels. Defaults to 30.
     *
     * @default 30
     */
    @Input() yAxisLabelsWidth?: number;
    /**
     * @optional
     * Specifies formatter type for X axis. Defaults to "float"
     *
     * @default "float"
     */
    @Input() xAxisFormatterType?: string;
    /**
     * @optional
     * Specifies the options for X axis formatter. Defaults to 0 decimals.
     *
     * @default 0 decimals
     */
    @Input() xAxisFormatterOptions?: ILgFormatterOptions;
    /**
     * @optional
     * Specifies formatter type for Y axis. Defaults to "float"
     *
     * @defailt "float"
     */
    @Input() yAxisFormatterType?: string;
    /**
     * @optional
     * Specifies the options for Y axis formatter. Defaults to 0 decimals.
     *
     * @default 0 decimals
     */
    @Input() yAxisFormatterOptions?: ILgFormatterOptions;
    /**
     * @optional
     * Specifies how the chart axes are generated.
     * classic - Zero axes are not highlighted. Center of chart is adjusted according to data.
     * centered - Zero axes are highlighted. Zero axes are in middle of the chart.
     *
     * Defaults to "classic".
     */
    @Input() chartType: "classic" | "centered" = "classic";
    /**
     * @optional
     * Specifies the legend options. If not specified, legend is not visible.
     */
    @Input() legendOptions: LegendOptions = getDefaultLegendOptions();
    /**
     * @optional
     * Specifies the color configuration. Defaults to categorical palette.
     */
    @Input() colorConfiguration: LgColorsConfiguration = LG_DEFAULT_COLOR_CONFIGURATION;
    /**
     * @optional
     * Overrides colorConfiguration settings.
     * Applies green color if the value is positive and red color if value is negative.
     *
     * Defaults to false.
     */
    @Input() usePositiveNegativeColors = false;
    /**
     * @optional
     * Specifies the function to return name of group based on id.
     */
    @Input() groupNameFn!: (datum: LgBubbleChartDatum) => string;
    /**
     * @optional
     * Specifies class of tooltip. Default to empty.
     */
    @Input() tooltipClass?: string;
    /**
     * @optional
     * Specifies the template to be used when hovering over elements. Defaults to own template.
     */
    @Input() tooltipTemplate?: TemplateRef<IImplicitContext<LgBubbleChartTooltipContext>>;
    /**
     * @optional
     * Specifies formatter type for tooltip numbers. Defaults to "float"
     *
     * @default "float"
     */
    @Input() tooltipFormatterTypeYAxis?: string;
    /**
     * @optional
     * Specifies the options for Y axis formatter. Defaults to 0 decimals.
     *
     * @default 0 decimals
     */
    @Input() tooltipFormatterOptionsYAxis?: ILgFormatterOptions;
    /**
     * @optional
     * Specifies formatter type for tooltip numbers. Defaults to "float".
     *
     * @default "float"
     */
    @Input() tooltipFormatterTypeXAxis?: string;
    /**
     * @optional
     * Specifies the options for number axis formatter. Defaults to 0 decimals.
     *
     * @default 0 decimals
     */
    @Input() tooltipFormatterOptionsXAxis?: ILgFormatterOptions;
    /**
     * @optional
     * Specifies whether bubbles are clickable.
     *
     * @default false
     */
    @Input() clickable = false;
    /**
     * @optional
     * Specifies the maximum value on X axis.
     * If not specified, maximum value is calculated from data.
     */
    @Input() xMax?: number;
    /**
     * @optional
     * Specifies the minimum value on X axis.
     * If not specified, minimum value is calculated from data.
     */
    @Input() xMin?: number;
    /**
     * @optional
     * Specifies the minimum value on Y axis.
     * If not specified, minimum value is calculated from data.
     */
    @Input() yMax?: number;
    /**
     * @optional
     * Specifies the maximum value on Y axis.
     * If not specified, maximum value is calculated from data.
     */
    @Input() yMin?: number;
    /**
     * @optional
     * Specifies selected group ids which will be highlighted.
     *
     * @default empty array
     */
    @Input() selectedGroups: Array<string | number> = [];

    /**
     * Emits data in clicked box, if the clickable input is set to true.
     */
    @Output() readonly itemClick = new EventEmitter<
        ChartClickEvent<LgBubbleChartDatum, LgBubbleChartDatum>
    >();

    /**
     * Emits data in clicked legend item.
     */
    @Output() readonly legendClick = new EventEmitter<LegendItem>();

    @ViewChild("chart", { static: true }) private _chartHolder!: ElementRef<HTMLDivElement>;
    @ViewChild("chartWithLegend", { static: true })
    private _chartWithLegendDivRef!: ElementRef<HTMLDivElement>;

    @ViewChild("defaultTemplate", { static: true })
    private _defaultTooltipTemplate!: TemplateRef<IImplicitContext<LgBubbleChartTooltipContext>>;

    _width!: number;
    _height!: number;
    _margin!: Margin;
    private _data: LgBubbleChartDatum[] = [];

    private _svg!: d3.Selection<any, any, any, any>;
    private _svgG!: d3.Selection<any, LgBubbleChartDatum, any, any>;
    private _yScale!: d3.ScaleLinear<number, number>;
    private _xScale!: d3.ScaleLinear<number, number>;
    private _bubbleSizeScale!: d3.ScaleLinear<number, number>;
    private _xAxisGroup!: d3.Selection<any, any, any, any>;
    private _yAxisGroup!: d3.Selection<any, any, any, any>;
    private _yAxisGridG!: d3.Selection<any, any, any, any>;
    private _xAxisGridG!: d3.Selection<any, any, any, any>;
    private _dataBubblesShadowG!: d3.Selection<any, LgBubbleChartDatum, any, any>;
    private _dataBubblesShadow!: d3.Selection<any, any, any, any>;
    private _dataBubblesG!: d3.Selection<any, LgBubbleChartDatum, any, any>;
    private _dataBubbles!: d3.Selection<any, any, any, any>;

    private _yAxisLabel!: d3.Selection<any, any, any, any>;
    private _xAxisLabel!: d3.Selection<any, any, any, any>;
    private _spaceForYAxisLabels!: number;
    private _spaceForXAxisLabels!: number;
    private _groupNames: string[] = [];

    private _groupColors!: d3.ScaleOrdinal<string | number, string>;

    _tooltipFormatterYAxis!: ILgFormatter<any>;
    _tooltipFormatterXAxis!: ILgFormatter<any>;
    _xAxisFormatter!: ILgFormatter<any>;
    _yAxisFormatter!: ILgFormatter<any>;
    private _yAxisFormat!: (x: number) => string;
    private _xAxisFormat!: (x: number) => string;

    private _trackListener!: () => void;
    private _tooltip?: D3TooltipApi;
    private _tooltipContext: LgBubbleChartTooltipContext | null = null;
    protected _tooltipPortal?: TemplatePortal<IImplicitContext<LgBubbleChartTooltipContext>>;

    get tooltipContext(): LgBubbleChartTooltipContext | null {
        return this._tooltipContext;
    }

    set tooltipContext(context: LgBubbleChartTooltipContext) {
        if (this._tooltipPortal?.context) {
            this._tooltipPortal.context.$implicit = context;
        }
        this._tooltipContext = context;
    }

    private _lastMouseY = 0;
    private _lastMouseX = 0;

    private _initialized = false;

    private _bubbleRefs!: Array<PointReference<LgBubbleChartDatum>>;
    private _overlappingBubbleRefs: Array<PointReference<LgBubbleChartDatum>> = [];
    private _overlappingShadowBubbleRefs: Array<PointReference<LgBubbleChartDatum>> = [];
    private _shadowBubbleRefs: Array<PointReference<LgBubbleChartDatum>> = [];

    private _boundaries!: LgBubbleChartBoundaries;

    _legendDefinition: LegendItem[] = [];
    _legendWidth: number | null = null;
    _legendPaddingBottom = 0;

    ngOnInit(): void {
        this._setDefaultProperties();
        this._initializeTooltip();
        this._trackMousePosition();
        this._triggerDataSpecificMethods();
    }

    ngAfterViewInit(): void {
        this._render();
        this._initialized = true;
        this._exportContainer?.register(this);
    }

    ngOnChanges(changes: LgSimpleChanges<LgBubbleChartComponent>): void {
        if (!this._initialized) return;

        if (
            changes.data ||
            changes.colorConfiguration ||
            changes.groupNameFn ||
            changes.xMin ||
            changes.xMax ||
            changes.yMin ||
            changes.yMax
        ) {
            this._triggerDataSpecificMethods();
        }

        if (changes.width || changes.height || changes.tickCount) {
            this._width = this.width;
            this._height = this.height;
        }

        if (changes.chartType) {
            this._spaceForXAxisLabels =
                this.chartType === "centered" ? 0 : X_AXIS_LABELS_LINE_HEIGHT;
        }

        if (changes.yAxisLabelsWidth) {
            this._spaceForYAxisLabels =
                this.yAxisLabelsWidth == null ? DEFAULT_Y_AXIS_LABELS_WIDTH : this.yAxisLabelsWidth;
        }

        if (changes.margin) this._margin = this.margin!;

        if (changes.colorConfiguration) {
            this._setColorScales();
        }

        if (changes.legendOptions || changes.selectedGroups) {
            this._updateLegend();
        }

        if (this._svg) this._svg.remove();
        this._setDefaultHoverOverlapRefs();
        this._render();
    }

    ngOnDestroy(): void {
        this._exportContainer?.unregister(this);
    }

    getHtmlElement(): HTMLElement {
        return this._chartWithLegendDivRef.nativeElement;
    }

    getSvgElement(): SVGElement {
        return this._svg.node();
    }

    private _setDefaultProperties(): void {
        this.tooltipTemplate = this.tooltipTemplate ?? this._defaultTooltipTemplate;

        this._margin = this.margin == null ? DEFAULT_MARGIN : this.margin;
        this.tooltipFormatterTypeYAxis = this.tooltipFormatterTypeYAxis || "float";
        this.tooltipFormatterTypeXAxis = this.tooltipFormatterTypeXAxis || "float";
        this.tooltipFormatterOptionsYAxis = {
            ...DEFAULT_TOOLTIP_FORMATTER_OPTIONS,
            ...this.tooltipFormatterOptionsYAxis
        };
        this._tooltipFormatterYAxis = this._formatterFactory.getFormatter(
            this.tooltipFormatterTypeYAxis,
            this.tooltipFormatterOptionsYAxis
        );
        this.tooltipFormatterOptionsXAxis = {
            ...DEFAULT_TOOLTIP_FORMATTER_OPTIONS,
            ...this.tooltipFormatterOptionsXAxis
        };
        this._tooltipFormatterXAxis = this._formatterFactory.getFormatter(
            this.tooltipFormatterTypeXAxis,
            this.tooltipFormatterOptionsXAxis
        );

        this.xAxisFormatterType = this.xAxisFormatterType || "float";
        this.yAxisFormatterType = this.yAxisFormatterType || "float";
        this.xAxisFormatterOptions = {
            ...DEFAULT_TICKS_FORMATTER_OPTIONS,
            ...this.xAxisFormatterOptions
        };
        this.yAxisFormatterOptions = {
            ...DEFAULT_TICKS_FORMATTER_OPTIONS,
            ...this.yAxisFormatterOptions
        };
        this._xAxisFormatter = this._formatterFactory.getFormatter(
            this.xAxisFormatterType,
            this.xAxisFormatterOptions
        );
        this._yAxisFormatter = this._formatterFactory.getFormatter(
            this.yAxisFormatterType,
            this.yAxisFormatterOptions
        );

        this.tooltipTemplate = this.tooltipTemplate || this._defaultTooltipTemplate;

        this.tickCount = this.tickCount == null ? DEFAULT_TICK_COUNT : this.tickCount;
        this._spaceForYAxisLabels =
            this.yAxisLabelsWidth == null ? DEFAULT_Y_AXIS_LABELS_WIDTH : this.yAxisLabelsWidth;
        this._spaceForXAxisLabels = this.chartType === "centered" ? 0 : X_AXIS_LABELS_LINE_HEIGHT;
        this._xAxisFormat = x => this._xAxisFormatter.format(x);
        this._yAxisFormat = x => this._yAxisFormatter.format(x);
        this.legendOptions =
            this.legendOptions == null ? getDefaultLegendOptions() : this.legendOptions;
        this._width = this.width;
        this._height = this.height;
        this.showXAxisLabels = this.showXAxisLabels == null ? true : this.showXAxisLabels;
        this.showYAxisLabels = this.showYAxisLabels == null ? true : this.showYAxisLabels;
        this._groupNames = [];
        this._setDefaultHoverOverlapRefs();
        this._setColorScales();
    }

    private _setColorScales(): void {
        const colors = this._colorPalette.getColorsForType(this.colorConfiguration);
        this._groupColors = d3.scaleOrdinal(colors) as d3.ScaleOrdinal<string | number, string>;
    }

    private _setDefaultHoverOverlapRefs(): void {
        this._bubbleRefs = [];
        this._shadowBubbleRefs = [];
        this._overlappingBubbleRefs = [];
        this._overlappingShadowBubbleRefs = [];
    }

    private _initializeTooltip(): void {
        this._tooltipPortal = this._getTooltipContent();
        const commonOptions: ID3TooltipOptions = {
            stay: false,
            trapFocus: false,
            position: "top-left",
            tooltipClass: `lg-tooltip lg-tooltip--d3 ${
                this.tooltipClass ? " " + this.tooltipClass : ""
            }`,
            panelClass: "chart-overlay",
            content: this._tooltipPortal,
            delayHide: 150,
            target: this._elementRef
        };
        this._tooltip = this._tooltipService.create({
            ...commonOptions
        });
    }

    private _getTooltipContent(): TemplatePortal<IImplicitContext<LgBubbleChartTooltipContext>> {
        return new TemplatePortal<IImplicitContext<LgBubbleChartTooltipContext>>(
            this.tooltipTemplate!,
            this._viewContainerRef,
            { $implicit: this.tooltipContext! }
        );
    }

    private _trackMousePosition(): void {
        this._ngZone.runOutsideAngular(() => {
            this._trackListener = this._renderer.listen(
                this._elementRef.nativeElement,
                "mousemove",
                (event: MouseEvent) => {
                    this._lastMouseX = event.clientX;
                    this._lastMouseY = event.clientY;
                    this._updateTooltipPosition();
                }
            );
        });
    }

    private _updateTooltipPosition(): void {
        if (!this._tooltip || !this._tooltip.visible) return;
        this._tooltip.setPositionAt(
            this._lastMouseX,
            this._lastMouseY,
            getRecommendedPosition(
                { x: this._lastMouseX, y: this._lastMouseY },
                this._tooltip.getOverlayElement()
            )
        );
    }

    private _triggerDataSpecificMethods(): void {
        if (this.data == null || this.data.length === 0) {
            if (isDevMode()) {
                this._lgConsole.warn(
                    "No data are available for rendering. Stopping code execution"
                );
            }
            return;
        }
        this._convertData();
        this._updateLegend();
    }

    private _convertData(): void {
        this._data = [...this.data];
    }

    private _updateLegend(): void {
        this._setLegendDefinition();
        this._setLegendSize();
    }

    private _setLegendDefinition(): void {
        this._legendDefinition = [];
        this._groupNames = [];
        const alreadyHasGroup: Record<string, boolean> = {};
        this._data.forEach(d => {
            if (alreadyHasGroup[d.groupId]) return;
            const name = this.groupNameFn(d);
            this._groupNames.push(name);
            this._legendDefinition.push({
                color: this._getColor(d, false),
                name: this.groupNameFn(d),
                opacity: this._isBubbleGroupSelected(d) ? 1 : 0.3
            });

            alreadyHasGroup[d.groupId] = true;
        });
    }

    private _setLegendSize(): void {
        const legendVisible = this.legendOptions.visible;
        const legendSize = this._getLegendSize();
        const legendOnTheRight = legendVisible && this.legendOptions.position === "right";

        this._legendWidth = legendOnTheRight ? legendSize : null;
        this._legendPaddingBottom = this._margin.bottom ?? 0;
    }

    private _render(): void {
        if (this._data == null || this._data.length === 0) {
            if (isDevMode()) {
                this._lgConsole.warn(
                    "No data are available for rendering. Stopping code execution"
                );
            }
            return;
        }
        this._drawMainSVGHolder();
        this._initializeScales();
        this._drawAxes();
        this._drawBubbles();
    }

    private _drawMainSVGHolder(): void {
        this._svg = d3
            .select(this._chartHolder.nativeElement)
            .append("svg")
            .attr("width", this._svgWidth)
            .attr("height", this._svgHeight);

        this._svgG = this._svg.append("g");
    }

    private _initializeScales(): void {
        this._boundaries = this._getBoundaries();

        // Calculate offsets to not render bubbles outside of chart
        const yRange = Math.abs(this._boundaries.yMax - this._boundaries.yMin);
        const yHeight = this._verticalPositionOfXAxis - (this._margin.top ?? 0);
        const yDomainOffset = (MAX_BUBBLE_RADIUS / yHeight) * yRange;

        const xRange = Math.abs(this._boundaries.xMax - this._boundaries.xMin);
        const xWidth = this._svgWidth - this._horizontalPositionOfYAxis - (this._margin.right ?? 0);
        const xDomainOffset = (MAX_BUBBLE_RADIUS / xWidth) * xRange;
        // ----------------------------------------------------------

        this._yScale = d3
            .scaleLinear()
            .range([this._verticalPositionOfXAxis, this._margin.top ?? 0])
            .domain([this._boundaries.yMin - yDomainOffset, this._boundaries.yMax + yDomainOffset])
            .nice();

        this._xScale = d3
            .scaleLinear()
            .range([this._horizontalPositionOfYAxis, this._svgWidth - (this._margin.right ?? 0)])
            .domain([this._boundaries.xMin - xDomainOffset, this._boundaries.xMax + xDomainOffset])
            .nice();

        const bubbleSizes = this.data.map(x => x.size);
        this._bubbleSizeScale = d3
            .scaleLinear()
            .range([0, MAX_BUBBLE_RADIUS])
            .domain([0, Math.max(...bubbleSizes)]);
    }

    private _getBoundaries(): LgBubbleChartBoundaries {
        const xValues = this._data.map(i => i.xValue);
        const yValues = this._data.map(i => i.yValue);
        const xMin = this.xMin ?? Math.min(...xValues);
        const yMin = this.yMin ?? Math.min(...yValues);
        const xMax = this.xMax ?? Math.max(...xValues);
        const yMax = this.yMax ?? Math.max(...yValues);

        if (this.chartType === "centered") {
            const yAbsMax = Math.max(Math.abs(yMin), Math.abs(yMax));
            const xAbsMax = Math.max(Math.abs(xMin), Math.abs(xMax));

            return { yMin: yAbsMax * -1, yMax: yAbsMax, xMin: xAbsMax * -1, xMax: xAbsMax };
        }

        return { yMin, yMax, xMin, xMax };
    }

    private _drawAxes(): void {
        this._addYAxisGrid();
        this._addYAxis();
        this._addXAxisGrid();
        this._addXAxis();
        if (this.chartType === "centered") this._highlightAxesZeroLines();
    }

    private _addYAxis(): void {
        this._yAxisGroup = this._svgG
            .append("g")
            .attr("class", "y__axis")
            .attr("transform", `translate(${this._horizontalPositionOfYAxis}, 0)`);

        this._yAxisGroup.transition().duration(250).call(this._getYAxis());

        if (this.chartType === "centered") {
            this._yAxisLabel = this._svgG
                .append("text")
                .attr("text-anchor", "end")
                .attr("class", "lg-bubble-chart__axis-title y__axis")
                .text(this.yAxisLabel ?? "")
                .attr(
                    "transform",
                    `translate(
                        ${this._svgWidth / 2 + 16},
                        ${this._margin.top}
                    ) rotate(-90)`
                );

            // Move Y axis labels to middle
            this._yAxisGroup.attr("transform", `translate(${this._svgWidth / 2}, -8)`);

            this._yAxisGroup
                .selectAll("g.tick")
                .filter(value => value === 0)
                .style("display", "none");
        } else {
            this._yAxisLabel = this._svgG
                .append("text")
                .attr("text-anchor", "middle")
                .attr("class", "lg-bubble-chart__axis-title y__axis")
                .text(this.yAxisLabel ?? "")
                .attr(
                    "transform",
                    `translate(${this._margin.left}, ${this._svgHeight / 2}) rotate(-90)`
                );

            this._yAxisGroup
                .selectAll(".tick text")
                .transition()
                .duration(250)
                .attr("transform", `translate(${-SPACE_BETWEEN_LABELS_AND_GRID}, 0)`);
        }
    }

    private _getYAxis(): d3.Axis<d3.NumberValue> {
        return d3
            .axisLeft<any>(this._yScale)
            .tickSize(0)
            .tickPadding(3)
            .tickFormat(this._yAxisFormat)
            .ticks(coerceNumberProperty(this.tickCount, 5));
    }

    private _addYAxisGrid(): void {
        this._yAxisGridG = this._svgG
            .append("g")
            .attr("class", "y__axis__grid")
            .attr("transform", `translate(${this._horizontalPositionOfYAxis}, 0)`);

        this._yAxisGridG.transition().duration(250).call(this._getYAxisGrid(this._yScale));
    }

    private _getYAxisGrid(scale: d3.ScaleLinear<number, number>): d3.Axis<d3.NumberValue> {
        return d3
            .axisRight(scale)
            .tickPadding(0)
            .tickFormat(() => "")
            .ticks(coerceNumberProperty(this.tickCount, 5))
            .tickSizeOuter(0)
            .tickSizeInner(
                this._svgWidth - this._horizontalPositionOfYAxis - (this._margin.right ?? 0)
            );
    }

    private _addXAxis(): void {
        this._xAxisGroup = this._svgG
            .append("g")
            .attr("class", "x__axis")
            .attr("transform", `translate(0,${this._verticalPositionOfXAxis})`);

        this._xAxisGroup.transition().duration(250).call(this._getXAxis());

        if (this.chartType === "centered") {
            // Move X axis labels to middle
            this._xAxisGroup.attr(
                "transform",
                `translate(8, ${(this._verticalPositionOfXAxis + (this._margin.top ?? 0)) / 2 + 10})`
            );

            this._xAxisLabel = this._svgG
                .append("text")
                .attr("class", "lg-bubble-chart__axis-title")
                .text(this.xAxisLabel ?? "")
                .attr("text-anchor", "end")
                .attr(
                    "transform",
                    `translate(
                    ${this._svgWidth - (this._margin.right ?? 0)},
                    ${(this._verticalPositionOfXAxis + (this._margin.top ?? 0)) / 2 - 6}
                )`
                );
        } else {
            this._xAxisLabel = this._svgG
                .append("text")
                .attr("class", "lg-bubble-chart__axis-title")
                .text(this.xAxisLabel ?? "")
                .attr("text-anchor", "middle")
                .attr(
                    "transform",
                    `translate(
                    ${this._svgWidth / 2},
                    ${this._verticalPositionOfXAxis + this._spaceForXAxisLabels}
                )`
                );
        }

        this._xAxisGroup
            .selectAll(".tick text")
            .transition()
            .duration(250)
            .attr("transform", `translate(0, ${SPACE_BETWEEN_LABELS_AND_GRID})`);
    }

    private _getXAxis(): d3.Axis<d3.NumberValue> {
        const axis =
            this.chartType === "centered"
                ? d3.axisTop<any>(this._xScale)
                : d3.axisBottom<any>(this._xScale);

        return axis.tickSize(0).tickPadding(3).tickFormat(this._xAxisFormat).ticks(this.tickCount);
    }

    private _addXAxisGrid(): void {
        this._xAxisGridG = this._svgG
            .append("g")
            .attr("class", "x__axis__grid")
            .attr("transform", `translate(0, ${this._margin.top}) rotate(-90)`);

        this._xAxisGridG.transition().duration(250).call(this._getXAxisGrid(this._xScale));
    }

    private _getXAxisGrid(scale: d3.ScaleLinear<number, number>): d3.Axis<d3.NumberValue> {
        return d3
            .axisLeft(scale)
            .tickPadding(0)
            .tickFormat(() => "")
            .ticks(this.tickCount)
            .tickSizeOuter(0)
            .tickSizeInner(this._svgHeight - this._xAxisLabelsHeight - (this._margin.top ?? 0));
    }

    private _highlightAxesZeroLines(): void {
        this._svgG
            .selectAll("g.tick")
            .filter(value => value === 0)
            .attr("class", "tick zero");
    }

    private _drawBubbles(): void {
        this._dataBubblesShadowG = this._svgG.append("g");
        this._drawShadowBubbles();
        this._dataBubblesG = this._svgG.append("g").attr("class", "lg-bubble-chart__data-bubbles");
        this._drawDataBubbles();
    }

    private _getShadowSize(bubbleSize: number): number {
        const absoluteSize = Math.abs(bubbleSize);
        return this._bubbleSizeScale(absoluteSize) > SHADOW_RADIUS
            ? this._bubbleSizeScale(absoluteSize)
            : SHADOW_RADIUS;
    }

    private _drawShadowBubbles(): void {
        const self = this;
        this._dataBubblesShadow = this._dataBubblesShadowG
            .selectAll(DATA_BUBBLE_SHADOW_SELECTOR)
            .data(this._data)
            .enter()
            .append("circle");

        this._dataBubblesShadow
            .attr("cx", d => this._xScale(d.xValue))
            .attr("cy", d => this._yScale(d.yValue))
            .attr("r", d => this._getShadowSize(d.size))
            .attr("fill", "transparent")
            .style("opacity", 1)
            .style("cursor", self.clickable ? "pointer" : "default")
            .on("mousemove", function (event: MouseEvent) {
                self._onMouseOverBubbleShadow(d3.select(this), d3.pointer(event));
            })
            .on("mouseout", function (_event: MouseEvent) {
                d3.select(this).attr("fill", "transparent");

                self._tooltip?.hide();
                self._clearOverlappingElements();
            })
            .each(function (d: LgBubbleChartDatum) {
                const shadowBubbleRef = <PointReference<LgBubbleChartDatum>>{
                    coordinates: [
                        Math.round(self._xScale(d.xValue)),
                        Math.round(self._yScale(d.yValue))
                    ],
                    fillElementWith: fill(d3.select(this)),
                    data: d,
                    color: this.style.color
                };
                self._shadowBubbleRefs.push(shadowBubbleRef);
            })
            .on("click", function (_event: MouseEvent, d: LgBubbleChartDatum) {
                const index = self._dataBubblesShadow.nodes().indexOf(this);
                self._onClick(d, index);
                self._tooltip?.hide();
            });
    }

    private _onMouseOverBubbleShadow(
        target: d3.Selection<SVGCircleElement, LgBubbleChartDatum, null, undefined>,
        mousecoords: [number, number]
    ): void {
        this._clearOverlappingElements();

        this._overlappingBubbleRefs = this._bubbleRefs.filter(x => {
            return doCirclePointsOverlap(
                mousecoords,
                x.coordinates,
                this._bubbleSizeScale(x.data.size)
            );
        });
        this._overlappingShadowBubbleRefs = this._shadowBubbleRefs.filter(x =>
            doCirclePointsOverlap(mousecoords, x.coordinates, this._getShadowSize(x.data.size))
        );

        this._overlappingBubbleRefs.forEach(d => d.fillElementWith(this._getColor(d.data, true)));
        this._overlappingShadowBubbleRefs.forEach(x => {
            if (SHADOW_RADIUS > this._bubbleSizeScale(x.data.size)) {
                x.fillElementWith(SHADOW_COLOR);
            }
        });

        if (this._overlappingShadowBubbleRefs.length === 0) {
            this._tooltip?.hide();
            return;
        }

        const alreadyAddedGroups: Array<string | number> = [];
        const tooltipItems = this._overlappingShadowBubbleRefs.map(bubble => {
            const alreadyAdded = alreadyAddedGroups.indexOf(bubble.data.groupId) !== -1;
            if (!alreadyAdded) alreadyAddedGroups.push(bubble.data.groupId);

            return {
                datum: bubble.data
            };
        });

        const data = target.data();

        this.tooltipContext = {
            displayingItems: tooltipItems,
            activeItem: {
                column: data?.[0],
                group: data?.[0]
            }
        };

        this._tooltip?.show({ target: target.node() as any });
        this._updateTooltipPosition();
    }

    private _clearOverlappingElements(): void {
        this._overlappingBubbleRefs.forEach(element => {
            const color = this._getColor(element.data, false);
            element.fillElementWith(color);
        });
        this._overlappingShadowBubbleRefs.forEach(element => {
            element.fillElementWith("transparent");
        });
    }

    private _drawDataBubbles(): void {
        const self = this;
        this._dataBubbles = this._dataBubblesG
            .selectAll(DATA_BUBBLE_SELECTOR)
            .data(this._data)
            .enter()
            .append("circle")
            .attr("cx", d => this._xScale(d.xValue))
            .attr("cy", d => this._yScale(d.yValue))
            .attr("r", d => this._getBubbleSize(d.size))
            .attr("stroke", d => this._getColor(d, false))
            .attr("stroke-opacity", d => (this._isBubbleGroupSelected(d) ? "1" : "0.3"))
            .attr("fill", d => this._getColor(d, false))
            .attr("fill-opacity", d => (this._isBubbleGroupSelected(d) ? "0.3" : "0.1"))
            .each(function (d: LgBubbleChartDatum) {
                const bubbleRef = <PointReference<LgBubbleChartDatum>>{
                    coordinates: [
                        Math.round(self._xScale(d.xValue)),
                        Math.round(self._yScale(d.yValue))
                    ],
                    fillElementWith: fill(d3.select(this)),
                    data: d
                };
                self._bubbleRefs.push(bubbleRef);
            });
    }

    private _isBubbleGroupSelected(datum: LgBubbleChartDatum): boolean {
        if (this.selectedGroups == null || this.selectedGroups.length === 0) return true;
        return this.selectedGroups.includes(datum.groupId);
    }

    private _getBubbleSize(bubbleSize: number): number {
        const absoluteSize = Math.abs(bubbleSize);
        return this._bubbleSizeScale(absoluteSize) > MIN_BUBBLE_RADIUS
            ? this._bubbleSizeScale(absoluteSize)
            : MIN_BUBBLE_RADIUS;
    }

    private _getColor(datum: LgBubbleChartDatum, isHover: boolean): string {
        let color: string;

        if (this.usePositiveNegativeColors) {
            color =
                datum.size < 0
                    ? getColor(LgColorPaletteIdentifiers.SalmonRed, 50)
                    : getColor(LgColorPaletteIdentifiers.Green, 40);
        } else {
            color = this._groupColors(datum.groupId);
        }

        if (isHover) return d3.rgb(color).darker(0.5).formatHex();
        return d3.rgb(color).formatHex();
    }

    private _onClick(value: LgBubbleChartDatum, itemIndex: number): void {
        if (!this.clickable) return;

        this.itemClick.emit({ item: value, datum: value, index: itemIndex });
    }

    _onLegendItemClick(item: LegendItem): void {
        this.legendClick.emit(item);
    }

    private get _verticalPositionOfXAxis(): number {
        const isLegendBelow = this.legendOptions.position === "bottom";
        const margin = isLegendBelow ? 10 : (this._margin.bottom ?? 0);
        const spaceForLabels = this.showXAxisLabels ? this._spaceForXAxisLabels : 0;
        return this._svgHeight - margin - spaceForLabels;
    }

    private get _horizontalPositionOfYAxis(): number {
        const left = this._margin.left ?? 0;
        return this.chartType === "centered"
            ? left
            : left +
                  (this.yAxisLabel ? Y_AXIS_TITLE_WIDTH : 0) +
                  this._spaceForYAxisLabels +
                  SPACE_BETWEEN_LABELS_AND_GRID;
    }

    private get _xAxisLabelsHeight(): number {
        return this._spaceForXAxisLabels + SPACE_BETWEEN_LABELS_AND_GRID;
    }

    private get _svgWidth(): number {
        const legendVisible = this.legendOptions.visible;
        const legendOnTheRight = legendVisible && this.legendOptions.position === "right";

        const legendSize = this._getLegendSize();
        const { left = 0, right = 0 } = this._margin;
        return this._width - left - right - (legendOnTheRight ? legendSize : 0);
    }

    get _svgHeight(): number {
        const legendVisible = this.legendOptions.visible;
        const legendBelow = legendVisible && this.legendOptions.position === "bottom";

        const legendSize = this._getLegendSize();
        return (
            this._height -
            (this._margin.top ?? 0) -
            (this._margin.bottom ?? 0) -
            (legendBelow ? legendSize : 0)
        );
    }

    private _getLegendSize(): number {
        const isLegendBelow = this.legendOptions.position === "bottom";
        const isLegendOnRight = this.legendOptions.position === "right";
        if (!isLegendBelow && !isLegendOnRight) return 0;
        if (isLegendBelow) return SPACE_FOR_LEGEND_BELOW;
        return getLegendWidth(
            this._width,
            this.legendOptions.widthInPercents ?? 0,
            this._groupNames
        );
    }
}
