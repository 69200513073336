import { Component, ViewEncapsulation, ChangeDetectionStrategy } from "@angular/core";

@Component({
    selector: "lg-loader",
    // eslint-disable-next-line @angular-eslint/component-max-inline-declarations
    template: `
        <div class="loader-anim">
            <div class="loader-anim__block loader-anim__block--index10"><div></div></div>
            <div class="loader-anim__block loader-anim__block--index9"><div></div></div>
            <div class="loader-anim__block loader-anim__block--index8"><div></div></div>
            <div class="loader-anim__block loader-anim__block--index7"><div></div></div>
            <div class="loader-anim__block loader-anim__block--index6"><div></div></div>
            <div class="loader-anim__block loader-anim__block--index5"><div></div></div>
            <div class="loader-anim__block loader-anim__block--index4"><div></div></div>
            <div class="loader-anim__block loader-anim__block--index3"><div></div></div>
            <div class="loader-anim__block loader-anim__block--index2"><div></div></div>
            <div class="loader-anim__block loader-anim__block--index1"><div></div></div>
            <div class="loader-anim__block loader-anim__block--index0"><div></div></div>
        </div>
    `,
    host: {
        class: "lg-loader-wrapper"
    },
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LgLoaderComponent {}
