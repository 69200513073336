import {
    Component,
    ViewEncapsulation,
    ChangeDetectionStrategy,
    Input,
    OnChanges,
    SimpleChanges,
    inject
} from "@angular/core";
import { Observable, of } from "rxjs";

import { LG_APP_INFO } from "@logex/framework/lg-application";
import { LgTranslateService, useTranslationNamespace } from "@logex/framework/lg-localization";

import { ISidebar } from "../sidebar-context";

export interface IProduct {
    id: string;
    name: string;
    url: string;
    shortDescription?: string;
    /**
     * Existing icon in the atlas, takes precedence over iconClass.
     */
    icon?: string;
    /**
     * Custom icon css class. When both icon and iconClass are specified, icon takes precedence.
     */
    iconClass?: string;
    disabled?: boolean;
}

@Component({
    standalone: false,
    selector: "lg-sidebar-switch",
    templateUrl: "./lg-sidebar-switch.component.html",
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: "lg-sidebar-switch lg-sidebar-panel"
    },
    viewProviders: [useTranslationNamespace("FW._Sidebar._Switch")]
})
export class LgSidebarSwitchComponent implements OnChanges {
    private _appInfo = inject(LG_APP_INFO);
    private _translateService = inject(LgTranslateService);

    /**
     * Sidebar handler (required).
     */
    @Input({ required: true }) sidebar!: ISidebar;

    /**
     * Observable providing list of available products.
     */
    @Input() products$: Observable<IProduct[]> = of([]);

    /**
     * Identifier of selected product
     */
    @Input() currentProductId: string | null = null;

    /**
     * Title of the switch button.
     * Defaults to localized “Switch products@"
     */
    @Input() title: string | null = this._translateService.translate(".SwitchProducts");

    _currentProductId = this._appInfo?.productId?.toUpperCase();

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.currentProductId && this.currentProductId) {
            this._currentProductId = this.currentProductId;
        }
    }
}
