import {
    formatNumber,
    FormatNumberOptions,
    getNumberSeparators,
    safelyShiftScale
} from "@logex/framework/utilities";
import { ILgFormatterOptions } from "../formatter-types";

export function format(
    val: number | null | undefined,
    locale: string,
    options: ILgFormatterOptions
): string {
    const { viewScale, forceFormat } = options;

    if (val == null || isNaN(val)) {
        return "−";
    }

    if (viewScale) {
        val = safelyShiftScale(val, viewScale);
    }

    // inside `formatNumber` the value is also clamped and rounded
    return forceFormat
        ? formatNumber(getOptions(val, locale, options))
        : val.toString().replace(".", getDecimalSymbol(locale));
}

function getOptions(val: number, locale: string, opt: ILgFormatterOptions): FormatNumberOptions {
    return <FormatNumberOptions>{
        val,
        locale,
        renderThousandSeparators: true,
        decimals: opt.decimals,
        zeroDash: opt.zeroDash,
        forcePlus: opt.forceSign,
        min: opt.min,
        max: opt.max
    };
}

function getDecimalSymbol(locale: string): string {
    return getNumberSeparators(locale).decimal.symbol;
}
