import { Component, ViewContainerRef, Input, isDevMode, inject } from "@angular/core";

import { toBoolean } from "@logex/framework/utilities";

import type { IHeaderFooterTemplate } from "../types";
import type { LgPivotTableComponent } from "../lg-pivot-table.component";
import { LgPivotTableHeaderDirective } from "../templates/lg-pivot-table-header.directive";
import { HeaderContext } from "./header-context";

@Component({
    standalone: false,
    selector: "lg-pivot-table-header-container",
    template: ``,
    host: {
        class: "lg-pivot-table__header__container"
    }
})
export class LgPivotTableHeaderContainerComponent {
    _viewContainerRef = inject(ViewContainerRef);

    /**
     * Instance of pivot table component (required).
     */
    @Input({ alias: "table", required: true }) _table!: LgPivotTableComponent;

    /**
     * Header template (required).
     */
    @Input({ required: true }) set template(value: IHeaderFooterTemplate<any> | null | undefined) {
        if (this._template === value) return;

        if (this._template) {
            this._detach();
        }
        this._template = value;
        if (this._template) {
            this._attach();
        }
    }

    get template(): IHeaderFooterTemplate<any> | null | undefined {
        return this._template;
    }

    /**
     * Pivot totals.
     */
    @Input() set totals(value: any) {
        this._totals = value;
        if (this._context) this._context.$implicit = value;
    }

    get totals(): any {
        return this._totals;
    }

    /**
     * Specifies, if this helper component holds the header (`true`) or the footer (`false`).
     */
    @Input("header") set isHeader(value: boolean | "true" | "false") {
        this._isHeader = toBoolean(value);
        if (this._component) this._component.header = this._isHeader;
    }

    get isHeader(): boolean {
        return this._isHeader;
    }

    private _template: IHeaderFooterTemplate<any> | null | undefined;
    private _context: HeaderContext<any> | null = null;
    private _component: LgPivotTableHeaderDirective | null = null;
    private _totals: any;
    private _isHeader = false;

    private _attach(): void {
        this._context = new HeaderContext(this._totals, this._table);
        LgPivotTableHeaderDirective._lastInstance = null;
        LgPivotTableHeaderDirective._lastInstanceCount = 0;
        this._viewContainerRef.createEmbeddedView(this._template!.template, this._context);
        this._component = LgPivotTableHeaderDirective._lastInstance!;
        if (this._component) {
            this._component.table = this._table;
            this._component.header = this._isHeader;
        }
        LgPivotTableHeaderDirective._lastInstance = null;

        if (isDevMode()) {
            if (LgPivotTableHeaderDirective._lastInstanceCount > 1) {
                console.warn(
                    "lgPivotTableHeaderDef/lgPivotTableFooterDef should contain at most one use of lg-pivot-table-header/lg-pivot-table-footer"
                );
            }
        }
    }

    private _detach(): void {
        if (!this._context) return;

        this._viewContainerRef.clear();
        this._context = null;
        this._component = null;
    }
}
