import { safelyShiftScale } from "./safelyShiftScale";

export const toFixedFix = (number: number, precision?: number): number => {
    if (!precision) {
        return Math.round(number);
    }

    const value = Math.round(safelyShiftScale(number, precision));
    return safelyShiftScale(value, -precision);
};
