// Based on lodash sources; removed symbol support and fixed comparison bug.
// The string comparison is ignoring case, and currently based on toLowerCase() call
// TODO: replace wtih Collator if performance seems fine.
// TODO: spec & handle booleans?

const typeOrder = {
    object: 1,
    number: 2,
    string: 3,
    function: 4,
    boolean: 5,
    undefined: 6,
    symbol: 7,
    bigint: 8
};

export function regularComparer(value: any, other: any): number {
    if (value !== other) {
        const valueIsDefined = value !== undefined;
        const valueIsNull = value === null;
        // eslint-disable-next-line no-self-compare
        const valueIsReflexive = value === value;
        // const valIsSymbol = isSymbol( value )  RG: optimization, we don't need these currently
        const valueIsSymbol = false;

        const otherIsDefined = other !== undefined;
        const otherIsNull = other === null;
        // eslint-disable-next-line no-self-compare
        const otherIsReflexive = other === other;
        // const othIsSymbol = isSymbol( other )
        const otherIsSymbol = false;

        let val: any;
        const t1 = typeof value;
        const t2 = typeof other;

        if (t1 === t2) {
            if (t1 === "string") {
                const v1 = value.toLowerCase();
                const v2 = other.toLowerCase();
                val = v1 === v2 ? 0 : v1 < v2 ? -1 : 1;
            } else {
                val = value - other;
            }
        } else if (t1 === "string" && t2 === "number" && +value === value) {
            val = +value - other;
        } else if (t1 === "number" && t2 === "string" && +other === other) {
            val = value - +other;
        } else if (valueIsReflexive && otherIsReflexive) {
            val = typeOrder[t1] - typeOrder[t2];
        }

        if (
            (!otherIsNull && !otherIsSymbol && !valueIsSymbol && val > 0) ||
            (valueIsSymbol &&
                otherIsDefined &&
                otherIsReflexive &&
                !otherIsNull &&
                !otherIsSymbol) ||
            (valueIsNull && otherIsDefined && otherIsReflexive) ||
            (!valueIsDefined && otherIsReflexive) ||
            (!valueIsReflexive && otherIsReflexive)
        ) {
            return 1;
        }
        if (
            (!valueIsNull && !valueIsSymbol && !otherIsSymbol && val < 0) ||
            (otherIsSymbol &&
                valueIsDefined &&
                valueIsReflexive &&
                !valueIsNull &&
                !valueIsSymbol) ||
            (otherIsNull && valueIsDefined && valueIsReflexive) ||
            (!otherIsDefined && valueIsReflexive) ||
            (!otherIsReflexive && valueIsReflexive)
        ) {
            return -1;
        }
    }

    return 0;
}
