<!-- user sees those on the screen -->
<ng-container
    *ngFor="let levelDef of _convertedLevels; let index = index"
    [ngTemplateOutlet]="levelTemplate"
    [ngTemplateOutletContext]="{
        levelDef: levelDef,
        index: index,
        isTheVisibleRow: true
    }"
></ng-container>

<!-- these are hidden and used to calculate total width so we can use compact mode if necessary -->
<span #hiddenLevelsWrapper class="lg-pivot-table-body-level-headers--hidden">
    <ng-container
        *ngFor="let levelDef of _convertedLevels; let index = index"
        [ngTemplateOutlet]="levelTemplate"
        [ngTemplateOutletContext]="{
            levelDef: levelDef,
            index: index,
            isTheVisibleRow: false
        }"
    ></ng-container>
</span>

<ng-template
    #levelTemplate
    let-levelDef="levelDef"
    let-index="index"
    let-isTheVisibleRow="isTheVisibleRow"
>
    <div
        *ngIf="levelDef && !levelDef.hidden"
        class="table__column table__column--header-level"
        [ngClass]="{
            'table__column--header-level-active': _table.maxVisibleLevel >= index,
            'table__column--header-level-highlight': _hoverLevel === index,
            'table__column--header-level-max-level': _table.maxVisibleLevel === index
        }"
        (click)="_toggleNameLevel(index, $event)"
        [lgPivotTableSortByColumnIndicator]="levelDef.orderBy"
        [sortByLevel]="index"
        [sortByDisabled]="_sortByDisabled(levelDef, isTheVisibleRow, index)"
    >
        <lg-pivot-table-expand
            *ngIf="index !== _lastVisibleIndex"
            [level]="index"
        ></lg-pivot-table-expand>
        <span
            *ngIf="_showDotForLastLevel(isTheVisibleRow, index)"
            [class.lg-pivot-table-expand--expanded]="_table.maxVisibleLevel === index"
            class="lg-pivot-table-expand lg-pivot-table-expand--level"
        >
            <lg-icon icon="icon-pivot-dot"></lg-icon>
        </span>
        <ng-container *ngIf="_levelLabelVisible(isTheVisibleRow, index)">
            <span *ngIf="!levelDef.headerHtml">{{ levelDef.header }}</span>
            <span *ngIf="levelDef.headerHtml" [innerHTML]="levelDef.headerHtml"></span>
        </ng-container>
    </div>
</ng-template>
