/* eslint-disable no-useless-escape */
import { IStringLookup } from "@logex/framework/types";
import { CurrencySymbolPosition, LgCurrencyMetadata } from "./i18n.types";

interface ModCurrencyMetadata {
    symbol: string;
    separator?: string;
    removeRegex?: RegExp | ((code: string) => RegExp);
    position?: CurrencySymbolPosition;
}

// ISO 4217 alpha code
export function getCurrencyMetadata(code: string): LgCurrencyMetadata {
    const dictionary = metadataDictionary();
    const supportedCodes = Object.keys(dictionary);
    if (supportedCodes.indexOf(code) === -1) {
        const available = supportedCodes.join(", ");
        throw new RangeError(
            `No metadata for country code '${code}, available codes are ${available}'`
        );
    }

    return publicMetadataFactory(dictionary[code]);
}

function metadataDictionary(): IStringLookup<ModCurrencyMetadata> {
    return {
        EUR: {
            symbol: "€"
        },
        USD: {
            symbol: "$"
        },
        GBP: {
            symbol: "£"
        },
        SEK: {
            symbol: "kr",
            position: "after"
        },
        NOK: {
            symbol: "kr",
            position: "after"
        },
        SAR: {
            symbol: "SR",
            position: "after"
        }
    };
}

function publicMetadataFactory(m: ModCurrencyMetadata): LgCurrencyMetadata {
    const defaults = {
        separator: String.fromCharCode(8239),
        // escaping `${code}` because $ is a special regex symbol
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        removeRegex: (code: string) => new RegExp(`^\s*\\${code}?\s*`),
        position: "before"
    };

    if (m.position === "after") {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        defaults.removeRegex = (code: string) => new RegExp(`\s*\\${code}?\s*$`);
    }

    const { symbol, separator, removeRegex, position } = {
        ...defaults,
        ...m
    };

    return {
        symbol,
        separator,
        removeRegex: removeRegex instanceof RegExp ? removeRegex : removeRegex(symbol),
        position: position as any
    };
}
