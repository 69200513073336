import ldMap from "lodash-es/map";
import ldSize from "lodash-es/size";
import ldSortBy from "lodash-es/sortBy";
import ldClone from "lodash-es/clone";
import ldPull from "lodash-es/pull";
import ldUniq from "lodash-es/uniq";
import ldEach from "lodash-es/each";
import ldGroupBy from "lodash-es/groupBy";

import {
    ChangeDetectionStrategy,
    Component,
    inject,
    Injectable,
    ViewEncapsulation
} from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";

import {
    IDialogComponent,
    LgDialogFactory,
    LgDialogRef,
    LgItemSelectorConfiguration,
    LgPromptDialog
} from "@logex/framework/ui-core";
import { LgTranslateService, useTranslationNamespace } from "@logex/framework/lg-localization";
import { IFilterOption } from "@logex/framework/types";

import { IDataCell, IPasteButtonColumnInfo } from "../copy-paste/copy-paste.types";
import {
    IMultilevelPickerAssignment,
    IMultilevelPickerConfiguration
} from "./lg-multilevel-picker-dialog.types";
import { IGroupConfiguration, IGroupRow, IItemRow } from "./lg-multilevel-picker-dialog.internal";

// TODO: optimize recalculations?
// TODO: provide possibility to specify list of localized items?

interface WithToString {
    toString(): string;
}

// ---------------------------------------------------------------------------------------------
//  Dialog implementation
// ---------------------------------------------------------------------------------------------
@Component({
    selector: "lg-multilevel-picker-dialog",
    templateUrl: "./lg-multilevel-picker-dialog.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    viewProviders: [useTranslationNamespace("FW._Dialogs._MultilevelPickerDialog")]
})
export class LgMultilevelPickerDialogComponent<TItem extends WithToString, TItemDefinition>
    implements IDialogComponent<LgMultilevelPickerDialogComponent<TItem, TItemDefinition>>
{
    private _lgTranslate = inject(LgTranslateService);
    private _dialogRef = inject(
        LgDialogRef<LgMultilevelPickerDialogComponent<TItem, TItemDefinition>>
    );

    private _promptDialog = inject(LgPromptDialog).bindViewContainerRef();

    // ---------------------------------------------------------------------------------------------
    //  Dialog configuration
    _title!: string;
    _dialogClass = "lg-dialog lg-dialog--6col";
    readonly _isReady = new BehaviorSubject<boolean>(false);
    _relatedTo: LgDialogRef<any, any> | (() => LgDialogRef<any, any>) | undefined;

    // ---------------------------------------------------------------------------------------------
    //  State
    _allowCopyPaste!: boolean;
    _readonly!: boolean;
    _modified = false;
    _itemSelection: Record<string, IItemRow<TItem, TItemDefinition>> = {};
    _alreadyUsedIcon!: string;
    readonly _notification = new Subject<string>();

    private readonly _itemRefresh$ = new Subject<void>();

    _itemSelectorConfig: LgItemSelectorConfiguration<IItemRow<TItem, TItemDefinition>> = {
        getId: i => i.uniqueKey,
        getName: i => "" + i.name,
        sortItems: undefined,
        isDisabled: p =>
            !this._configuration.allowBlocked && this._otherAssignment[p.selectionKey] != null,
        addMany: p => this._toggleManyItems(p, true),
        removeMany: p => this._toggleManyItems(p, false),
        filterItems: items => this._filterItems(items, null),
        refresh$: this._itemRefresh$.asObservable()
    };

    _copyPasteColumns: IPasteButtonColumnInfo[] = [];
    _copyPasteExtra = "";

    _availableItemsList: Array<IItemRow<TItem, TItemDefinition>> = [];

    _groupConfigs: Array<IGroupConfiguration<TItem, TItemDefinition>> = [];
    _expandedGroups: Array<IGroupConfiguration<TItem, TItemDefinition>> = []; // used for LRU

    private _configuration!: IMultilevelPickerConfiguration<TItem, TItemDefinition>;
    private _otherAssignment!: IMultilevelPickerAssignment;
    private _customLocalizationPrefix!: string;
    private _availableItems!: Record<string, IItemRow<TItem, TItemDefinition>>;
    private _availableItemsSelectionLookup: Record<
        string,
        Array<IItemRow<TItem, TItemDefinition>>
    > | null = null;

    private _getDefinitionId!: (item: TItem) => any;
    private _getSelectionKey!: (item: TItem) => string;
    private _getUniqueKey!: (item: TItem) => string;
    private _invalidItemsFound = false;
    private _resolve!: (items: any[]) => void;

    _translate: (translationId: string, interpolateParams?: any) => string;
    _translateCustomized: (translationId: string, interpolateParams?: any) => string;

    constructor() {
        this._translate = this._lgTranslate.translate.bind(this._lgTranslate);
        this._translateCustomized = (translationId, interpolateParams) => {
            if (translationId[0] === ".")
                translationId = this._customLocalizationPrefix + translationId;
            return this._lgTranslate.translate(translationId, interpolateParams);
        };
    }

    // ---------------------------------------------------------------------------------------------
    //  Entry point
    // ---------------------------------------------------------------------------------------------
    pickItems(
        configuration: IMultilevelPickerConfiguration<TItem, TItemDefinition>,
        readonly: boolean,
        availableItems: TItem[],
        otherAssignment: IMultilevelPickerAssignment,
        selectedItems: TItem[],
        parentDialog?: LgDialogRef<any>
    ): Promise<TItem[]> {
        this._validateCopyPasteConfiguration(configuration);
        this._customLocalizationPrefix =
            configuration.localizationPrefix ||
            "FW._Dialogs._MultilevelPickerDialog._Customization";
        this._configuration = configuration;
        this._readonly = readonly;
        this._availableItems = {};
        this._otherAssignment = otherAssignment;
        this._relatedTo = parentDialog;
        this._allowCopyPaste = !this._configuration.disableCopyPaste;

        this._title = this._translateCustomized(
            readonly ? ".DialogTitle_View" : ".DialogTitle_Edit"
        );
        this._alreadyUsedIcon = this._configuration.alreadyUsedIcon || "icon-info";

        this._getDefinitionId = this._configuration.getDefinitionId;
        this._getSelectionKey = this._configuration.getSelectionKey || (id => id.toString());
        this._getUniqueKey = this._configuration.getUniqueKey || (id => id.toString());

        const sortMap = ldMap(
            configuration.sortBy,
            sortby => (row: IItemRow<TItem, TItemDefinition>) =>
                sortby(row.item, row.itemDefinition)
        );
        this._itemSelectorConfig.sortItems = items => ldSortBy(items, ...sortMap);

        this._prepareGroupConfigs(configuration);
        this._prepareAvailableItems(availableItems);
        this._prepareItemSelection(selectedItems);
        this._prepareCopyPasteDefinition();

        this._updateGroups();

        return new Promise<any[]>(resolve => (this._resolve = resolve));
    }

    // ---------------------------------------------------------------------------------------------
    //  Activate the dialog
    _activate(): void {
        if (this._invalidItemsFound) {
            this._promptDialog.alert(
                this._translate(".Invalid_items_found_title"),
                this._translateCustomized(".Invalid_items_found_text")
            );
            this._modified = true;
        }

        this._isReady.next(true);
    }

    // ---------------------------------------------------------------------------------------------
    //  Refilter the product list
    _refilter(): void {
        this._itemRefresh$.next();
    }

    _groupFilterToggle(
        event: MouseEvent,
        groupConfig: IGroupConfiguration<TItem, TItemDefinition>,
        group: IGroupRow
    ): void {
        event.stopPropagation();
        event.preventDefault();
        if (groupConfig.filter[group.groupCode]) {
            delete groupConfig.filter[group.groupCode];
            if (ldSize(groupConfig.filter) === 0) {
                groupConfig.filter.$empty = true;
            }
        } else {
            groupConfig.filter[group.groupCode] = group.name;
            delete groupConfig.filter.$empty;
        }
        groupConfig.filter = ldClone(groupConfig.filter);
        this._itemRefresh$.next();
    }

    _collapseGroup(groupConfig: IGroupConfiguration<TItem, TItemDefinition>): void {
        groupConfig.collapsed = !groupConfig.collapsed;
        if (!groupConfig.collapsed) {
            this._expandedGroups.push(groupConfig);
            if (this._expandedGroups.length > 3) {
                const first = this._expandedGroups.shift();
                if (first) first.collapsed = true;
            }
        } else {
            ldPull(this._expandedGroups, groupConfig);
        }
    }

    // ---------------------------------------------------------------------------------------------
    //  Tooltips
    readonly _itemAllocatedTooltip = (item: IItemRow<TItem, TItemDefinition>): string => {
        return this._translateCustomized(".Item_is_used_in") + item.assignedTo;
    };

    // ---------------------------------------------------------------------------------------------
    //  Dialog closing
    _tryClose(): boolean {
        if (!this._readonly && this._modified) {
            this._promptDialog
                .confirm(
                    this._translate(".Close_confirmation_title"),
                    this._translateCustomized(".Close_confirmation_text")
                )
                .then(res => {
                    if (res === "ok") this._dialogRef.close();
                });
            return false;
        }
        return true;
    }

    _cancel(): void {
        if (this._tryClose()) {
            this._dialogRef.close();
        }
    }

    // ---------------------------------------------------------------------------------------------
    //  Save data
    _save(): void {
        if (this._modified) {
            const result = ldUniq(ldMap(this._itemSelection, p => p.item));
            this._dialogRef.close();
            this._resolve(result);
        } else {
            this._dialogRef.close();
        }
    }

    // ---------------------------------------------------------------------------------------------
    // Prepare the grouping configuration
    private _prepareGroupConfigs(
        configuration: IMultilevelPickerConfiguration<TItem, TItemDefinition>
    ): void {
        this._groupConfigs = configuration.groups.map((group, index) => {
            const refresh = new Subject<void>();

            const config: IGroupConfiguration<TItem, TItemDefinition> = {
                index,
                collapsed: !group.expandedByDefault,
                isUsed: group.isUsedLc
                    ? this._translateCustomized(group.isUsedLc)
                    : this._translate(".Group_is_used"),
                isPartiallyUsed: group.isPartiallyUsedLc
                    ? this._translateCustomized(group.isPartiallyUsedLc)
                    : this._translate(".Group_is_partially_used"),
                label: this._translateCustomized(group.labelLc),
                filterPlaceholder: this._translateCustomized(
                    group.filterPlaceholderLc || group.labelLc
                ),
                configSource: group,
                getId: configuration.groupIds[index],
                filter: { $empty: true },
                available: {},
                list: [],
                selection: {},

                filterSource: () => {
                    const products = this._filterItems(this._availableItemsList, config);
                    const ids: string[] = products.map(product => product.groupIds[index]);
                    const uniqIds = [...new Set(ids)];
                    const options: IFilterOption[] = uniqIds.map(id => {
                        const def = config.available[id];
                        return {
                            id,
                            order: def.sortBy,
                            name: def.name
                        };
                    });
                    return ldSortBy(options, "order");
                },

                selectorRefresh: refresh,

                selectorConfiguration: {
                    getId: g => g.groupCode,
                    getName: g => "" + g.name,
                    isPartiallySelected: g => !g.assignedWholeGroup,
                    sortItems: groups => ldSortBy(groups, g => g.sortBy),
                    isDisabled: g => !this._configuration.allowBlocked && g.blockedWholeGroup,
                    addMany: g => this._toggleManyGroups(g, config, true),
                    removeMany: g => this._toggleManyGroups(g, config, false),
                    refresh$: refresh.asObservable()
                }
            };
            return config;
        });
    }

    // ---------------------------------------------------------------------------------------------
    //  Prepare the available items
    private _prepareAvailableItems(availableItems: TItem[]): void {
        // Preprocess the list of available items, converting it to more detailed records
        // Also use it to create list of available groups
        const relatedLookup: Record<string, Array<IItemRow<TItem, TItemDefinition>>> = {};
        this._availableItemsList = [];

        for (const item of availableItems) {
            const definitionId = this._getDefinitionId(item);
            const itemDefinition = this._configuration.items[definitionId];
            if (!itemDefinition) {
                console.warn("Unknown item: " + definitionId);
                continue;
            }

            const selectionKey = this._getSelectionKey(item);
            const assignment = this._otherAssignment[selectionKey];
            const uniqueKey = this._getUniqueKey(item);
            const groupIds: any[] = [];

            let related = relatedLookup[selectionKey];
            if (related === undefined) {
                relatedLookup[selectionKey] = related = [];
            }

            for (const groupConfig of this._groupConfigs) {
                const id = groupConfig.getId(item, itemDefinition);
                groupIds[groupConfig.index] = id;

                const groupDef = groupConfig.configSource.source[id];
                if (!groupDef) {
                    console.warn(`Unknown group ${groupConfig.label}: ${id}`, item, itemDefinition);
                }

                let tGroup = groupConfig.available[id];
                if (!tGroup) {
                    const groupName = groupConfig.configSource.getName(groupDef || null, id);
                    tGroup = {
                        groupCode: id,
                        name: groupName,
                        sortBy: groupConfig.configSource.sortBy
                            ? groupConfig.configSource.sortBy(groupDef || null, id)
                            : groupName,
                        availableItemsUniqueKeys: [],
                        blockedItemsCount: 0,
                        blockedItemsLookup: {},
                        blockedWholeGroup: false,
                        assignedItemsCount: null,
                        assignedWholeGroup: false
                    };
                    groupConfig.available[id] = tGroup;
                    groupConfig.list.push(tGroup);
                }

                tGroup.availableItemsUniqueKeys.push(uniqueKey);
                if (assignment) {
                    tGroup.blockedItemsCount += 1;
                    tGroup.blockedItemsLookup[selectionKey] = assignment;
                }
            }

            const entry: IItemRow<TItem, TItemDefinition> = {
                item,
                itemDefinition,
                selectionKey,
                uniqueKey,
                related,
                assignedTo: assignment,
                name: this._configuration.getItemName(item, itemDefinition),
                groupIds
            };

            related.push(entry);
            // if ( related.length > 1 ) console.log( entry );

            this._availableItems[entry.uniqueKey] = entry;
            this._availableItemsList.push(entry);
        }

        for (const groupConfig of this._groupConfigs) {
            ldEach(groupConfig.available, g => {
                g.blockedWholeGroup = g.blockedItemsCount === g.availableItemsUniqueKeys.length;
            });
        }
    }

    // ---------------------------------------------------------------------------------------------
    // Convert the mode-specific list of selected products into the general one (mapping to available products)
    private _prepareItemSelection(selectedItems: TItem[]): void {
        let invalid = false;
        this._itemSelection = {};
        selectedItems.forEach(item => {
            const definitionId = this._getDefinitionId(item);
            const itemDefinition = this._configuration.items[definitionId];
            if (!itemDefinition) {
                invalid = true;
                return;
            }
            const uniqueKey = this._getUniqueKey(item);
            const availableItem = this._availableItems[uniqueKey];
            if (!availableItem) {
                invalid = true;
                return;
            }
            this._itemSelection[availableItem.uniqueKey] = availableItem;
        });
        this._invalidItemsFound = invalid;
    }

    // ---------------------------------------------------------------------------------------------
    //  Selector changes
    private _toggleManyItems(
        items: Array<IItemRow<TItem, TItemDefinition>>,
        add: boolean
    ): boolean {
        let count = 0;

        for (const item of items) {
            count += this._toggleRelatedItems(item, add);
        }

        this._updateGroups();
        this._groupConfigs.forEach(g => g.selectorRefresh.next());
        this._itemRefresh$.next();
        this._modified = true;
        this._notification.next(
            this._translateCustomized(
                add ? ".Items_added_notification" : ".Items_removed_notification",
                { count }
            )
        );
        return false;
    }

    private _toggleManyGroups(
        groups: IGroupRow[],
        _config: IGroupConfiguration<TItem, TItemDefinition>,
        add: boolean
    ): boolean {
        let count = 0;
        for (const group of groups) {
            for (const uniqueKey of group.availableItemsUniqueKeys) {
                const item = this._availableItems[uniqueKey];
                if (add && !this._configuration.allowBlocked && item.assignedTo) continue;

                count += this._toggleRelatedItems(item, add);
            }
        }

        this._updateGroups();
        this._groupConfigs.forEach(g => g.selectorRefresh.next());
        this._itemRefresh$.next();
        this._modified = true;
        this._notification.next(
            this._translateCustomized(
                add ? ".Items_added_notification" : ".Items_removed_notification",
                { count }
            )
        );
        return false;
    }

    private _toggleRelatedItems(item: IItemRow<TItem, TItemDefinition>, add: boolean): number {
        let count = 0;
        if (add) {
            for (const other of item.related) {
                if (!this._itemSelection[other.uniqueKey]) {
                    this._itemSelection[other.uniqueKey] = other;
                    ++count;
                }
            }
        } else {
            for (const other of item.related) {
                if (this._itemSelection[other.uniqueKey]) {
                    delete this._itemSelection[other.uniqueKey];
                    ++count;
                }
            }
        }
        return count;
    }

    // ---------------------------------------------------------------------------------------------
    //  Update currently selected groups
    // ---------------------------------------------------------------------------------------------
    private _updateGroups(): void {
        for (const groupConfig of this._groupConfigs) {
            groupConfig.selection = {};
        }

        ldEach(this._itemSelection, item => {
            for (const groupConfig of this._groupConfigs) {
                const id = item.groupIds[groupConfig.index];
                let groupRow = groupConfig.selection[id];
                if (!groupRow) {
                    groupRow = groupConfig.selection[id] = groupConfig.available[id];
                    groupRow.assignedItemsCount = 0;
                }
                groupRow.assignedItemsCount! += 1;
            }
        });

        for (const groupConfig of this._groupConfigs) {
            ldEach(groupConfig.selection, t => {
                t.assignedWholeGroup = t.assignedItemsCount === t.availableItemsUniqueKeys.length;
            });
        }
    }

    // ---------------------------------------------------------------------------------------------
    //  Filtering
    private _filterItems(
        items: Array<IItemRow<TItem, TItemDefinition>>,
        ignoredGroup: IGroupConfiguration<TItem, TItemDefinition> | null
    ): Array<IItemRow<TItem, TItemDefinition>> {
        const active = this._groupConfigs.filter(g => g !== ignoredGroup && !g.filter.$empty);

        if (!active.length) return items;

        return items.filter(product =>
            active.every(groupConfig => groupConfig.filter[product.groupIds[groupConfig.index]])
        );
    }

    // ---------------------------------------------------------------------------------------------
    //  Validatie the copy'n'paste configuration
    private _validateCopyPasteConfiguration(
        config: IMultilevelPickerConfiguration<TItem, TItemDefinition>
    ): void {
        if (
            !config.copyPasteDefinition &&
            (config.getCopyPasteValues ||
                config.preprocessPasteValues ||
                config.convertPasteToSelectionKeys)
        ) {
            console.error(
                "lg-multilevel-picker: when copy-paste callbacks are specified, copyPasteDefinition must be present too"
            );
        }
        if (
            config.copyPasteDefinition &&
            (!config.getCopyPasteValues || !config.convertPasteToSelectionKeys)
        ) {
            console.error(
                "lg-multilevel-picker: when copyPasteDefinition is specified, getCopyPasteValues and convertPasteToSelectionKeys must be specified too"
            );
        }
    }

    // ---------------------------------------------------------------------------------------------
    //  Prepare the copy'n'paste button templates
    private _prepareCopyPasteDefinition(): void {
        if (!this._allowCopyPaste) return; // don't bother

        let columns: IPasteButtonColumnInfo[];
        this._copyPasteExtra = this._configuration.pasteExtraInfoLc
            ? this._translateCustomized(this._configuration.pasteExtraInfoLc)
            : "";

        if (this._configuration.copyPasteDefinition) {
            columns = this._configuration.copyPasteDefinition;
        } else {
            columns = [
                {
                    field: "code",
                    name: this._translate(".Copy_id_header"),
                    type: "string",
                    key: true
                }
            ];
        }

        this._copyPasteColumns = columns;
        if (!columns || !columns.length) {
            this._allowCopyPaste = false;
        }
    }

    // ---------------------------------------------------------------------------------------------
    // Copy'n'paste handling
    // ---------------------------------------------------------------------------------------------
    //  Prepare data for copy
    readonly _getAllValues = (): any[] => {
        let result: any[];

        if (this._configuration.copyPasteDefinition && this._configuration.getCopyPasteValues) {
            const currentSelection = ldMap(this._itemSelection, row => ({
                item: row.item,
                definition: row.itemDefinition
            }));
            result = this._configuration.getCopyPasteValues(currentSelection);
        } else {
            const selectionKeys = ldMap(this._itemSelection, i => i.selectionKey);
            result = [...new Set(selectionKeys)].map(code => ({ code }));
        }

        return result;
    };

    // ---------------------------------------------------------------------------------------------
    //  Preprocess the pasted values (reversing damage done by Excel)
    // ---------------------------------------------------------------------------------------------
    readonly _preprocessAllValues = (args: {
        columns: IPasteButtonColumnInfo[];
        data: IDataCell[][];
    }): void => {
        const { columns, data } = args;
        if (this._configuration.preprocessPasteValues) {
            this._configuration.preprocessPasteValues(columns, data);
        }
    };

    // ---------------------------------------------------------------------------------------------
    //  Process pasted values
    // ---------------------------------------------------------------------------------------------
    readonly _setAllValues = (args: { values: any[]; newValues: any[] }): void => {
        const { values, newValues } = args;
        const items = values.concat(newValues);

        let selections: Array<string | null>;

        if (
            this._configuration.copyPasteDefinition &&
            this._configuration.convertPasteToSelectionKeys
        ) {
            selections = this._configuration.convertPasteToSelectionKeys(items) || [];
        } else {
            const codes = items.map(item => item.code as string);
            selections = [...new Set(codes)];
        }

        if (!this._availableItemsSelectionLookup) {
            this._availableItemsSelectionLookup = ldGroupBy(
                this._availableItemsList,
                i => i.selectionKey
            ) as Record<string, Array<IItemRow<TItem, TItemDefinition>>> | null;
        }
        let invalid = false;
        let used = false;
        // note: we could count the products too, but the mismatch between number of rows and actual selections might be confusing?
        for (const key of selections) {
            if (key == null) {
                invalid = true;
                continue;
            }
            const sources = this._availableItemsSelectionLookup![key];
            if (!sources) {
                invalid = true;
                continue;
            }
            for (const source of sources) {
                if (!this._configuration.allowBlocked && this._otherAssignment[key]) {
                    used = true;
                    continue;
                }
                this._itemSelection[source.uniqueKey] = source;
            }
        }

        this._updateGroups();
        this._groupConfigs.forEach(g => g.selectorRefresh.next());
        this._itemRefresh$.next();
        this._modified = true;
        if (invalid) {
            this._promptDialog.alertLc(
                this._translate(".Invalid_items_pasted_title"),
                this._translateCustomized(".Invalid_items_pasted_text")
            );
        } else if (used) {
            this._promptDialog.alert(
                this._translate(".Used_items_pasted_title"),
                this._translateCustomized(".Used_items_pasted_text")
            );
        }
    };
}

// The type system has currently limitations about inheriting from generic base class so our usual getDialogFactoryBase method doesn't work. For now we need to implement it manually
// (it seems to be an open discussion about the TS limitation, so we might be able to modify it in the future)
@Injectable({ providedIn: "root" })
export class LgMultilevelPickerDialog<TItem extends WithToString, TItemDefinition>
    implements Pick<LgMultilevelPickerDialogComponent<TItem, TItemDefinition>, "pickItems">
{
    private _factory = inject(LgDialogFactory);

    pickItems(
        configuration: IMultilevelPickerConfiguration<TItem, TItemDefinition>,
        readonly: boolean,
        availableItems: TItem[],
        otherAssignment: IMultilevelPickerAssignment,
        selectedItems: TItem[],
        parentDialog?: LgDialogRef<any>
    ): Promise<TItem[]> {
        return this._factory.create(undefined, LgMultilevelPickerDialogComponent, "pickItems", [
            configuration,
            readonly,
            availableItems,
            otherAssignment,
            selectedItems,
            parentDialog
        ]);
    }
}
