import {
    AfterContentInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ContentChild,
    ContentChildren,
    ElementRef,
    EventEmitter,
    inject,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    SimpleChanges,
    TemplateRef
} from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";

import { IDropdownDefinition } from "@logex/framework/ui-core";
import { getSwitchDefinition } from "./getSwitchDefinition";
import { LgPanelChoiceComponent } from "./lg-panel-choice.component";
import {
    ChoiceIdType,
    IPanelChoiceDefinition,
    IPanelSwitchDefinition,
    LgPanelSwitchState$
} from "./lg-panel.types";

@Component({
    selector: "lg-panel-switch",
    templateUrl: "./lg-panel-switch.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: "lg-panel-switch"
    }
})
export class LgPanelSwitchComponent implements OnInit, OnChanges, AfterContentInit, OnDestroy {
    private _changeDetectorRef = inject(ChangeDetectorRef);

    @Input() name: string | null = null;
    @Input() title: string | null = null;

    // TODO: allow changing choice externally, after init
    @Input({ required: true }) variantId!: ChoiceIdType;
    @Output() readonly variantIdChange = new EventEmitter<ChoiceIdType>();

    @Input({ required: true }) choiceId!: ChoiceIdType;
    @Output() readonly choiceIdChange = new EventEmitter<ChoiceIdType>();

    @ContentChildren(LgPanelChoiceComponent)
    _choicesDefinitions!: QueryList<LgPanelChoiceComponent>;

    @ContentChild("body") _bodyTemplateRef!: ElementRef<HTMLElement>;
    @ContentChild("additionalMenuItemsForSwitch")
    _leftAdditionalMenuItems!: TemplateRef<any>;

    @ContentChild("rightAdditionalMenuItemsForSwitch")
    _rightAdditionalMenuItems!: TemplateRef<any>;

    _switchDefinition!: IPanelSwitchDefinition;
    _state$!: LgPanelSwitchState$;
    _selectionMenuItems$ = new BehaviorSubject<IPanelChoiceDefinition[]>([]);
    _currentChoice$ = new Subject<IPanelChoiceDefinition | null>();
    _leftAdditionalItems$ = new BehaviorSubject<Array<TemplateRef<any> | null>>([]);
    _rightAdditionalItems$ = new BehaviorSubject<Array<TemplateRef<any> | null>>([]);

    private _destroyed$ = new Subject<void>();

    _dropdownDefinition: IDropdownDefinition<number | string> | null | undefined;

    ngOnInit(): void {
        this._defaultProps();
        this._state$ = new BehaviorSubject({
            choiceId: this.choiceId,
            variantId: this.variantId
        }) as LgPanelSwitchState$;
    }

    ngAfterContentInit(): void {
        this._switchDefinition = getSwitchDefinition(this._choicesDefinitions);
        this._selectionMenuItems$.next(this._switchDefinition.choices);

        this._choicesDefinitions.changes.pipe(takeUntil(this._destroyed$)).subscribe(() => {
            this._switchDefinition = getSwitchDefinition(this._choicesDefinitions);
            this._selectionMenuItems$.next(this._switchDefinition.choices);
            this._changeDetectorRef.markForCheck();
        });

        this._selectionMenuItems$.pipe(takeUntil(this._destroyed$)).subscribe(choices => {
            // todo: change current selection if not found
            this._dropdownDefinition = {
                iconName: "icon",
                groups: [
                    {
                        entries: choices.map(ch => ({
                            ...ch,
                            icon: ch.icon
                                ? {
                                      icon: ch.icon
                                  }
                                : undefined
                        }))
                    }
                ]
            };
            this._changeDetectorRef.markForCheck();
        });

        this._state$
            .pipe(
                takeUntil(this._destroyed$),
                filter(currentSelection => !!currentSelection)
            )
            .subscribe(currentSelection => {
                const choices = this._switchDefinition.choices;
                const currentChoiceDef = choices.find(x => x.id === currentSelection!.choiceId)!;

                this._currentChoice$.next(currentChoiceDef ?? null);
                this._leftAdditionalItems$.next([
                    this._leftAdditionalMenuItems,
                    currentChoiceDef.additionalMenuItemsTemplateRef ?? null
                ]);
                this._rightAdditionalItems$.next([
                    currentChoiceDef.rightAdditionalMenuItemsTemplateRef ?? null,
                    this._rightAdditionalMenuItems
                ]);

                if (this.choiceId !== currentSelection!.choiceId) {
                    this.choiceId = currentSelection!.choiceId;
                    this.choiceIdChange.next(currentSelection!.choiceId);
                }
                if (this.variantId !== currentSelection!.variantId) {
                    this.variantId = currentSelection!.variantId;
                    this.variantIdChange.next(currentSelection!.variantId);
                }
                this._changeDetectorRef.markForCheck();
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.variantId || changes.choiceId) {
            if (this._state$) {
                const choices = this._switchDefinition.choices;
                const choiceDef = choices.find(x => x.id === this.choiceId);
                if (choiceDef) {
                    this._state$.next({ choiceId: this.choiceId!, variantId: this.variantId! });
                }
            }
        }
    }

    ngOnDestroy(): void {
        this._destroyed$.next();
        this._destroyed$.complete();
    }

    _choiceChange(choice: number | string): void {
        this._state$.next({
            choiceId: choice,
            variantId: this.variantId!
        });
    }

    _getPanelTemplate(): TemplateRef<any> | null {
        const currentSelection = this._state$.getValue();

        const choice = this._switchDefinition.choices.find(
            choices => choices.id === currentSelection?.choiceId
        );
        return choice ? choice.mainPaneTemplateRef : null;
    }

    private _defaultProps(): void {
        this.name = this.name || "" + Math.round(Math.random() * 1000);
        this.variantId = this.variantId != null ? this.variantId : 1;
        this.choiceId = this.choiceId != null ? this.choiceId : 1;
    }

    private _hasState(): boolean {
        return !!(this.choiceId && this.variantId);
    }
}
