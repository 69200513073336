export interface Downtime {
    id: string;
    message: string;
    notificationName?: string;
    category: NotificationCategories.Deprecated | NotificationCategories.Maintenance;
    from: string;
    to: string;
    isGlobal: boolean;
    buttonLc?: string | undefined;
    hideButtonLc?: boolean;
    onButtonClick?: () => void;
}

export interface DowntimeServiceConfig {
    url: string;
    toolId: string;
}

export enum NotificationCategories {
    Deprecated = "deprecated",
    Maintenance = "maintenance"
}
