import { Nullable } from "@logex/framework/types";
import { ILgFormatterOptions, IParseResult, ILgFormatter } from "./formatter-types";
import { parse } from "./number-helpers/parse";
import { format } from "./number-helpers/format";
import { formatForEditing } from "./number-helpers/formatForEditing";

const defaultOptions: ILgFormatterOptions = {
    decimals: 2,
    viewScale: 0,
    forceSign: false,
    forceFormat: true
};

export class NumberFormatter implements ILgFormatter<number> {
    names = ["float"];

    constructor(public options: ILgFormatterOptions, private _locale: string) {
        this.options = { ...defaultOptions, ...options };
    }

    parse(val: string, options?: ILgFormatterOptions): IParseResult<number> {
        const { decimals, viewScale } = { ...this.options, ...options };
        return parse(val, decimals, viewScale);
    }

    format(val: Nullable<number>, options?: ILgFormatterOptions): string {
        return format(val, this._locale, { ...this.options, ...options });
    }

    formatForEditing(val: Nullable<number>, options?: ILgFormatterOptions): string {
        if (val == null || "" + val === "") {
            return "";
        }

        return formatForEditing(val, this._locale, { ...this.options, ...options });
    }
}
