import {
    Component,
    Input,
    ViewEncapsulation,
    EventEmitter,
    Output,
    ElementRef,
    SimpleChanges,
    OnChanges,
    OnDestroy,
    inject,
    ChangeDetectorRef,
    ChangeDetectionStrategy
} from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { toInteger, toBoolean } from "@logex/framework/utilities";
import { LgContentGridGroupComponent } from "./lg-content-grid-group.component";

@Component({
    standalone: false,
    selector: "lg-content-grid-item",
    template: ` <ng-content *ngIf="_visible"></ng-content> `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        "[class]": "_class",
        "[hidden]": "!_visible"
    },
    encapsulation: ViewEncapsulation.None
})
export class LgContentGridItemComponent implements OnChanges, OnDestroy {
    private _changeDetectorRef = inject(ChangeDetectorRef);
    private _elementRef = inject(ElementRef);
    private _group = inject(LgContentGridGroupComponent);

    /**
     * Identifier (required).
     */
    @Input({ required: true }) id!: string;

    @Input("class") prop_class = "";

    /**
     * Grid item size
     *  - If number then size in grid columns count is set (integers [1; 12] are valid)
     *  - If null or 'auto' then auto size within grid is used
     */
    @Input() set size(val: number | null | "auto") {
        if (val === "auto" || val === null) {
            this._size = null;
        } else {
            this._size = toInteger(val, 1, 1, 12);
        }
        this._updateClass();
    }

    get size(): number | null {
        return this._size;
    }

    @Input() set maximizeToGroup(val: boolean | "true" | "false") {
        this._maximizeToGroup = toBoolean(val);
    }

    get maximizeToGroup(): boolean {
        return this._maximizeToGroup;
    }

    @Output() readonly maximizedChange = new EventEmitter<boolean>();

    public get isMaximized(): boolean {
        return this._isMaximized;
    }

    @Output() readonly visibilityChange = new EventEmitter<boolean>();

    ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.prop_class) {
            this._updateClass();
        }
    }

    get isVisible(): boolean {
        return this._visible;
    }

    public _visible = true;
    public _class?: string;

    private readonly _destroyed$ = new Subject<void>();
    private _size: number | null = null;
    private _isMaximized = false;
    private _maximizeToGroup = false;

    constructor() {
        this._group.groupVisibilityChange.pipe(takeUntil(this._destroyed$)).subscribe(() => {
            this._checkState();
            this._changeDetectorRef.markForCheck();
        });
        this._group.maximizedItemIdChange.pipe(takeUntil(this._destroyed$)).subscribe(() => {
            this._checkState();
            this._changeDetectorRef.markForCheck();
        });

        this._checkState();
    }

    public toggleMaximize(): void {
        this._group.toggleMaximize(this.id, this._maximizeToGroup);
    }

    private _checkState(): void {
        const groupMaximizedId = this._group.maximizedItemId;
        const isMaximized = this._group._visible && groupMaximizedId === this.id;
        const visible = this._group._visible && (groupMaximizedId === null || isMaximized);

        const triggerVisibility = visible !== this._visible;
        const triggerMaximized = isMaximized !== this._isMaximized;

        this._visible = visible;
        if (this._class === undefined || isMaximized !== this._isMaximized) {
            this._isMaximized = isMaximized;
            this._updateClass();
        }

        if (triggerVisibility) this.visibilityChange.next(visible);
        if (triggerMaximized) this.maximizedChange.next(isMaximized);
    }

    public ngOnDestroy(): void {
        this._destroyed$.next();
        this._destroyed$.complete();
    }

    private _updateClass(): void {
        if (this._size === null || this._isMaximized) {
            this._class = "lgg__cell";
        } else {
            this._class = "lgg__cell lgg__cell--" + this._size;
        }
        if (this.prop_class) {
            this._class = `${this._class} ${this.prop_class}`;
        }
        // speedup the update
        this._elementRef.nativeElement.className = this._class;
    }
}
