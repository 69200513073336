<div
    tabindex="0"
    class="lg-time-picker__input lg-time-picker__input--popup"
    [class.lg-time-picker__input--condensed]="_condensed"
    [class.lg-time-picker__input--24h]="_is24h"
    [class.lg-time-picker__input--with-autocomplete]="_autocomplete.length"
    [lgCopyHandler]="_copyHandler"
    [lgPasteHandler]="_pasteHandler"
>
    <div class="lg-time-picker__input__center">
        <input
            lgDefaultFocus
            #hoursInput
            type="number"
            min="_is24h ? 0 : 1"
            max="_is24h ? 23 : 12"
            class="lg-time-picker__input__part lg-time-picker__input__part--hours"
            [class.lg-time-picker__input__part--active]="
                _hoursInputValue === '' || _focusedElement === 'hours' || _focusedElement === null
            "
            [placeholder]="_hoursInputPlaceholder || ('.Hours_placeholder' | lgTranslate)"
            (keyup)="_onHoursKeyUp($event)"
            (input)="_onHoursInput($any($event))"
            (focus)="_onFocus('hours')"
            (click)="_onInputClick($event)"
            (blur)="_onHoursBlur()"
            (keydown)="_disableSpinner($event)"
        />{{ _hoursSeparator
        }}<!--
        --><input
            #minutesInput
            type="number"
            min="0"
            max="59"
            class="lg-time-picker__input__part lg-time-picker__input__part--minutes"
            [class.lg-time-picker__input__part--active]="
                _hoursInputValue !== '' && _focusedElement === 'minutes'
            "
            [class.lg-time-picker__input__part--minutes--active]="
                _minutesInputValue || _minutesInputPlaceholder
            "
            [placeholder]="_minutesInputPlaceholder || ('.Minutes_placeholder' | lgTranslate)"
            [disabled]="_hoursInputValue === ''"
            (keyup)="_onMinutesKeyUp($event)"
            (input)="_onMinutesInput($any($event))"
            (focus)="_onFocus('minutes')"
            (click)="_onInputClick($event)"
            (blur)="_onMinutesBlur()"
            (keydown)="_disableSpinner($event)"
        /><!--
        --><ng-container *ngIf="!_is24h"
            >{{ _minutesSeparator
            }}<!--
            --><input
                #periodInput
                type="text"
                class="lg-time-picker__input__part lg-time-picker__input__part--period"
                [class.lg-time-picker__input__part--active]="
                    _minutesInputValue !== '' && _focusedElement === 'period'
                "
                [disabled]="_minutesInputValue === ''"
                [placeholder]="_periodInputPlaceholder || ('.Period_placeholder' | lgTranslate)"
                (input)="_onPeriodInput($any($event))"
                (focus)="_onFocus('period')"
                (click)="_onInputClick($event)"
            /><!--
        --></ng-container>
    </div>
</div>
<div
    class="lg-time-picker__autocomplete-wrapper"
    [class.lg-time-picker__autocomplete-wrapper--24h]="_is24h"
    [hidden]="_autocomplete.length === 0"
>
    <div
        class="lg-time-picker__autocomplete"
        lgScrollable
        lgScrollableAutoHide="true"
        lgScrollableWrapper="false"
        #scroller
    >
        <div
            *lgVirtualFor="
                let entry of _autocomplete;
                let index = index;
                height: _autocompleteItemHeight;
                ensureVisible: _visibleAutocompleteIndex$
            "
            class="lg-time-picker__autocomplete__item"
            [class.lg-time-picker__autocomplete__item--cursor]="index === _currentAutocompleteIndex"
            [class.lg-time-picker__autocomplete__item--clear]="entry.time === null"
            (click)="_onClickAutocompleteItem(entry)"
        >
            {{ entry.text }}
        </div>
    </div>
</div>
