import type { LgPivotTableComponent } from "../lg-pivot-table.component";

export class HeaderContext<TTotals> {
    public constructor(public $implicit: TTotals, private _table: LgPivotTableComponent) {
        // empty
    }

    public toggleLevel(level: number, state?: boolean): void {
        return this._table._body.toggleLevel(level, state);
    }

    public get maxVisibleLevel(): number {
        return this._table._body.maxVisibleLevel;
    }
}
