import { inject, Injectable, LOCALE_ID } from "@angular/core";

import { ILgFormatter, ILgFormatterOptions } from "../implementation/formatter-types";
import { MoneyFormatter } from "../implementation/money-formatter";
import { ILgFormatterFactory } from "./formatter-factory-types";
import { getCurrencyMetadata } from "../../i18n/getCurrencyMetadata";
import { LG_CURRENCY_CODE } from "../../i18n/i18n.types";
import { LG_FORMATTER_OPTIONS } from "./lg-formatter-options";

@Injectable({ providedIn: "root" })
export class MoneyFormatterFactory implements ILgFormatterFactory {
    private _currencyCode = inject(LG_CURRENCY_CODE);
    private _locale = inject(LOCALE_ID);
    private _localFormatterOptions = inject(LG_FORMATTER_OPTIONS, { optional: true });

    names = ["money"];

    create(defaultOptions: ILgFormatterOptions = {}): ILgFormatter<number> {
        return new MoneyFormatter(
            { ...defaultOptions, ...this._localFormatterOptions },
            getCurrencyMetadata(this._currencyCode),
            this._locale
        );
    }
}
