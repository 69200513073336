/* eslint-disable @angular-eslint/no-conflicting-lifecycle */

import ldIsNumber from "lodash-es/isNumber";
import ldIsFunction from "lodash-es/isFunction";
import ldIsArray from "lodash-es/isArray";

/*
Conversion notes
1) the virtual for doesn't create its own scrolling area, please wrap it in lg-scrollable
2) it doesn't duplicate the item class on the scrolling area holder, which may affect styling
3) notice that the scroller's class for hidden scrollbar has been shortened
*/

// todo: find out, why the code fails to render correctly if scroll throttle is set to 0
import {
    ChangeDetectorRef,
    Directive,
    DoCheck,
    EmbeddedViewRef,
    Input,
    IterableChangeRecord,
    IterableChanges,
    IterableDiffer,
    IterableDiffers,
    NgIterable,
    OnChanges,
    TemplateRef,
    TrackByFunction,
    ViewContainerRef,
    isDevMode,
    ElementRef,
    OnInit,
    OnDestroy,
    Renderer2,
    NgZone,
    inject
} from "@angular/core";

import { Subject, Observable, Subscription } from "rxjs";

import { CircularBuffer } from "./circular-buffer";
import { LG_SCROLLABLE_CONTAINER } from "../scrolling/lg-scrollable-container";
import { scheduleChangeDetection } from "@logex/framework/utilities";
import { takeUntil } from "rxjs/operators";
import { getTypeNameForDebugging } from "./getTypeNameForDebugging";
import {
    IVirtualForOfCache,
    LgVirtualForOfItemHeightCallback,
    LgVirtualForOfRecordViewTuple
} from "./lg-virtual-for-of.types";
import { LgVirtualForOfContext } from "./lg-virtual-for-of-context";
import { LgSimpleChanges } from "@logex/framework/types";
import { LgVirtualForOfDynamicCache } from "./LgVirtualForOfDynamicCache";
import { LgVirtualForOfStaticCache } from "./LgVirtualForOfStaticCache";

const DEFAULT_RENDERING_BUFFER = 40;
const DEFAULT_ITEM_HEIGHT = 26;

@Directive({
    selector: "[lgVirtualFor][lgVirtualForOf]",
    exportAs: "lgVirtualFor"
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class LgVirtualForOf<T> implements DoCheck, OnChanges, OnInit, OnDestroy {
    private _changeDetectorRef = inject(ChangeDetectorRef);
    private _differs = inject(IterableDiffers);
    private _elementRef = inject(ElementRef);
    private _ngZone = inject(NgZone);
    private _renderer = inject(Renderer2);
    private _scrollable = inject(LG_SCROLLABLE_CONTAINER);
    private _template = inject(TemplateRef<LgVirtualForOfContext<T>>);
    private _viewContainer = inject(ViewContainerRef);

    @Input() lgVirtualForOf: NgIterable<T> | undefined;

    @Input() set lgVirtualForTrackBy(fn: TrackByFunction<T>) {
        if (isDevMode() && fn != null && typeof fn !== "function") {
            console.error("Incorrect trackBy pased to lg-virtual-for, expected function, got ", fn);
        }
        this._trackByFn = fn;
    }

    get lgVirtualForTrackBy(): TrackByFunction<T> | undefined {
        return this._trackByFn;
    }

    get lgVirtualForTrackby(): TrackByFunction<T> | undefined {
        return this._trackByFn;
    }

    @Input() set lgVirtualForTemplate(value: TemplateRef<LgVirtualForOfContext<T>>) {
        if (value) {
            this._template = value;
        }
    }

    get lgVirtualForTemplate(): TemplateRef<LgVirtualForOfContext<T>> {
        return this._template;
    }

    /**
     * Specifies the height of a single row in pixels.
     * If number is passed, all rows will have the same height.
     * If callback is passed, all rows may have different height.
     * Defaults to 26.
     */
    // eslint-disable-next-line accessor-pairs
    @Input() set lgVirtualForHeight(value: LgVirtualForOfItemHeightCallback<T> | number) {
        if (!ldIsNumber(value) && !ldIsFunction(value) && isDevMode()) {
            console.error(
                "Incorrect height parameter for lg-virtual-for, expected number or function, got ",
                value
            );
        }
        if (ldIsFunction(value)) this._cache = new LgVirtualForOfDynamicCache<T>(value);
        if (ldIsNumber(value)) this._cache = new LgVirtualForOfStaticCache<T>(value);
    }

    /**
     * Specifies an observable that allows scrolling to a specific element
     * If number is emitted, scroll happens to a specific index.
     * If item is emitted, item is first found before scrolling to its' index.
     */
    // eslint-disable-next-line accessor-pairs
    @Input("lgVirtualForEnsureVisible") set ensureVisibleObservable(value: Observable<number | T>) {
        if (this._ensureVisibleSubscription) {
            this._ensureVisibleSubscription.unsubscribe();
            this._ensureVisibleSubscription = null;
        }
        if (value) {
            this._ensureVisibleSubscription = value.subscribe(e => {
                this.ensureVisible(e as any);
            });
        }
    }

    /**
     * Rendering buffer is height in pixels, used for rendering previous and next elements.
     * Used when the height is very big and default buffer causes rendering issues.
     * See lgVirtualFor static stories with big height.
     * Defaults to 40px
     */
    @Input() lgVirtualForRenderingBuffer = DEFAULT_RENDERING_BUFFER;

    // ---------------------------------------------------------------------------------------------
    ensureVisible(index: number): void;
    ensureVisible(item: T): void;
    ensureVisible(item: number | T): void {
        this._ensureVisible = item;
        this._changeDetectorRef.markForCheck();
    }

    // ---------------------------------------------------------------------------------------------

    private _differ: IterableDiffer<T> | null = null;
    private _trackByFn: TrackByFunction<T> | undefined = undefined;

    private _wrapper!: HTMLElement;
    private _destroyed = new Subject<void>();
    private _visibleRows = new CircularBuffer<T>();

    private _minRenderedY = 1e23;
    private _maxRenderedY = -1;
    private _maxHeight!: number;
    private _scroll!: number;
    private _contentHeight: number | null = null;

    private _virtualOffset = 0;
    private _itemCount = 0;

    private _ensureVisible: number | T | null = null;
    private _ensureVisibleSubscription: Subscription | null = null;

    private _cache!: IVirtualForOfCache<T>;

    // ---------------------------------------------------------------------------------------------
    ngOnInit(): void {
        if (!this._cache) this._cache = new LgVirtualForOfStaticCache<T>(DEFAULT_ITEM_HEIGHT);
        this._wrapper = this._renderer.createElement("div");
        this._renderer.addClass(this._wrapper, "lgVirtualForOf-holder");
        this._renderer.insertBefore(
            this._renderer.parentNode(this._elementRef.nativeElement),
            this._wrapper,
            this._elementRef.nativeElement
        );
        this._renderer.appendChild(this._wrapper, this._elementRef.nativeElement);

        const status = this._scrollable.getScrollContainerInfo();
        this._maxHeight = status.size.maxHeight || status.size.height;
        this._scroll = status.position.y;

        this._scrollable
            .scrolled(100)
            .pipe(takeUntil(this._destroyed))
            .subscribe(e => {
                this._scroll = e.y;
                if (
                    this._scroll < this._minRenderedY ||
                    this._scroll + this._maxHeight > this._maxRenderedY
                ) {
                    this._changeDetectorRef.markForCheck();
                    this._ngZone.run(() => undefined);
                }
            });

        this._scrollable
            .resized()
            .pipe(takeUntil(this._destroyed))
            .subscribe(e => {
                const newMaxHeight = e.maxHeight || e.height;
                if (newMaxHeight !== this._maxHeight) {
                    this._maxHeight = newMaxHeight;
                    this._changeDetectorRef.markForCheck();
                    scheduleChangeDetection();
                }
            });
    }

    // ---------------------------------------------------------------------------------------------
    ngOnDestroy(): void {
        if (this._renderer.destroyNode) {
            this._renderer.destroyNode(this._wrapper);
        }
        this._destroyed.next();
        this._destroyed.complete();
    }

    // ---------------------------------------------------------------------------------------------
    ngOnChanges(changes: LgSimpleChanges<LgVirtualForOf<T>>): void {
        if (!changes.lgVirtualForOf) return;
        const value = changes.lgVirtualForOf.currentValue;
        if (!this._differ && value) {
            try {
                this._differ = this._differs.find(value).create(this.lgVirtualForTrackBy);
            } catch (e) {
                throw new Error(
                    `Cannot find a differ supporting object '${value}' of type '${getTypeNameForDebugging(
                        value
                    )}'. LgVirtualFor only supports binding to Iterables such as Arrays.`
                );
            }
        }
    }

    // ---------------------------------------------------------------------------------------------
    ngDoCheck(): void {
        if (!this._differ) return;
        if (this.lgVirtualForOf) this._cache.updateCache(this.lgVirtualForOf);
        this._ensureVisibility();
        this._update();
    }

    private _ensureVisibility(): void {
        if (this._ensureVisible === null) return;
        const ensureVisibleIndex = this._cache.getEnsureVisibleIndex(this._ensureVisible);
        this._ensureVisible = null;
        if (ensureVisibleIndex === undefined) return;
        this._scrollToVisible(ensureVisibleIndex);
    }

    private _scrollToVisible(ensureVisibleIndex: number): void {
        const firstVisible = this._cache.getIndexFromPosition(this._scroll, true);
        if (firstVisible > ensureVisibleIndex) {
            this._scrollable.scrollTo(
                undefined,
                this._cache.getYPositionByIndex(ensureVisibleIndex, true)
            );
            return;
        }
        const lastVisible = this._cache.getIndexFromPosition(
            this._scroll + this._maxHeight!,
            false
        );
        if (lastVisible < ensureVisibleIndex) {
            const scrollToOffset =
                this._cache.getYPositionByIndex(ensureVisibleIndex, false) - this._maxHeight!;
            if ((this._contentHeight ?? 0) - this._maxHeight! >= scrollToOffset) {
                this._scrollable.scrollTo(undefined, scrollToOffset);
            } else {
                Promise.resolve().then(() => {
                    this._changeDetectorRef.markForCheck();
                    this._scrollable.scrollTo(undefined, scrollToOffset);
                });
            }
        }
    }

    private _update(): void {
        const startY = Math.max(0, this._scroll - this.lgVirtualForRenderingBuffer);
        const endY = this._scroll + (this._maxHeight ?? 0) + this.lgVirtualForRenderingBuffer;
        const startIndex = this._cache.getIndexFromPosition(startY, false);
        const endIndex = this._cache.getIndexFromPosition(endY, true);

        this._setVisibleRows(endIndex, startIndex);

        const realEndIndex = Math.min(this._cache.length - 1, endIndex);
        const realStartIndex = realEndIndex - this._visibleRows.count + 1;

        const minRenderedY = this._cache.getYPositionByIndex(realStartIndex, true);
        this._maxRenderedY = this._cache.getYPositionByIndex(realEndIndex, false);
        const contentHeight = this._cache.getContentHeight();
        // update should not be called without differ defined
        const changes = this._differ!.diff(this._visibleRows);
        if (changes) this._applyChanges(changes);

        if (
            changes ||
            this._virtualOffset !== realStartIndex ||
            this._itemCount !== this._cache.length
        ) {
            this._virtualOffset = realStartIndex;
            this._itemCount = this._cache.length;
            this._updateIndexation();
        }

        if (this._contentHeight !== contentHeight) {
            this._contentHeight = contentHeight;
            this._renderer.setStyle(this._wrapper, "height", this._contentHeight + "px");
            this._scrollable.updateSize();
        }

        if (this._minRenderedY !== minRenderedY) {
            this._minRenderedY = minRenderedY;
            this._renderer.setStyle(this._wrapper, "paddingTop", this._minRenderedY + "px");
        }
    }

    private _setVisibleRows(endIndex: number, startIndex: number): void {
        if (ldIsArray(this.lgVirtualForOf)) {
            this._visibleRows.setAsView(
                endIndex - startIndex + 1,
                this.lgVirtualForOf,
                startIndex,
                endIndex - startIndex + 1
            );
        } else if (this.lgVirtualForOf) {
            this._visibleRows.reset(endIndex - startIndex + 1);
            let index = 0;
            for (const value of this.lgVirtualForOf) {
                if (index <= endIndex) this._visibleRows.add(value);
                ++index;
            }
        }
    }

    // ---------------------------------------------------------------------------------------------
    private _applyChanges(changes: IterableChanges<T>): void {
        const insertTuples: Array<LgVirtualForOfRecordViewTuple<T>> = [];

        changes.forEachOperation(
            (
                item: IterableChangeRecord<T>,
                adjustedPreviousIndex: number | null,
                adjustedCurrentIndex: number | null
            ) => {
                const isNewItem = item.previousIndex == null;
                const isRemovedItem = item.currentIndex == null;
                if (isNewItem) {
                    const view = this._viewContainer.createEmbeddedView(
                        this._template,
                        new LgVirtualForOfContext<T>(null, this.lgVirtualForOf!, -1, -1),
                        adjustedCurrentIndex === null ? undefined : adjustedCurrentIndex
                    );

                    const tuple = <LgVirtualForOfRecordViewTuple<T>>{
                        record: item,
                        view
                    };
                    insertTuples.push(tuple);
                    return;
                }
                if (isRemovedItem) {
                    this._viewContainer.remove(
                        adjustedPreviousIndex === null ? undefined : adjustedPreviousIndex
                    );
                    return;
                }
                // adjustedPreviousIndex and adjustedCurrentIndex would always be defined at this point
                const view = this._viewContainer.get(adjustedPreviousIndex!)!;
                this._viewContainer.move(view, adjustedCurrentIndex!);
                const tuple = <LgVirtualForOfRecordViewTuple<T>>{
                    record: item,
                    view
                };
                insertTuples.push(tuple);
            }
        );

        for (const insert of insertTuples) {
            this._perViewChange(insert.view, insert.record);
        }

        changes.forEachIdentityChange((record: IterableChangeRecord<T>) => {
            const viewRef = <EmbeddedViewRef<LgVirtualForOfContext<T>>>(
                // current index would be defined at this point
                this._viewContainer.get(record.currentIndex!)
            );
            this._perViewChange(viewRef, record);
        });
    }

    // ---------------------------------------------------------------------------------------------
    private _perViewChange(
        view: EmbeddedViewRef<LgVirtualForOfContext<T>>,
        record: IterableChangeRecord<T>
    ): void {
        view.context.$implicit = record.item;
    }

    // ---------------------------------------------------------------------------------------------
    private _updateIndexation(): void {
        for (let i = 0, ilen = this._viewContainer.length; i < ilen; i++) {
            const viewRef = <EmbeddedViewRef<LgVirtualForOfContext<T>>>this._viewContainer.get(i);
            viewRef.context.index = i + this._virtualOffset;
            viewRef.context.count = this._itemCount;
        }
    }
}
