import {
    Component,
    ChangeDetectionStrategy,
    OnDestroy,
    ChangeDetectorRef,
    Input,
    OnChanges,
    ViewEncapsulation,
    HostListener,
    inject
} from "@angular/core";
import { trigger, state, style, animate, transition, AnimationEvent } from "@angular/animations";
import { Observable, Subscription, isObservable } from "rxjs";

import { LgSimpleChanges } from "@logex/framework/types";
import { toInteger } from "@logex/framework/utilities";

@Component({
    selector: "lg-quick-notification",
    template: ` {{ _currentMessage }} `,
    host: {
        "[class.lg-quick-notification]": "true",
        "[@visible]": "_visible"
    },
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,

    animations: [
        trigger("visible", [
            state("initial, false", style({ opacity: 0 })),
            state("true", style({ opacity: 1 })),

            transition("* => false", animate("200ms", style({ opacity: 0 }))),
            transition("false => true", animate(0, style({ opacity: 1 })))
        ])
    ]
})
export class LgQuickNotificationComponent implements OnChanges, OnDestroy {
    private _changeDetector = inject(ChangeDetectorRef);

    /**
     * Notification message.
     */
    @Input({ required: true }) message: string | Observable<string | null> | null = null;

    /**
     * Delay time before notification close (in ms). Valid value range [100; 100000].
     *
     * @default 5000
     */
    @Input() set delay(val: number) {
        this._delay = toInteger(val, 5000, 100, 100000);
    }

    get delay(): number {
        return this._delay;
    }

    _currentMessage = "";
    _visible = false;

    private _delay = 5000;
    private _subscription: Subscription | null = null;
    private _timer: number | null = null;

    ngOnChanges(changes: LgSimpleChanges<LgQuickNotificationComponent>): void {
        if (changes.message) {
            this._unsubscribe();
            if (this.message == null) {
                this._hide();
            } else if (isObservable(this.message)) {
                this._subscription = this.message.subscribe(newMessage => {
                    if (newMessage == null) {
                        this._hide();
                    } else {
                        this._show(newMessage);
                    }
                    this._changeDetector.markForCheck();
                });
            } else {
                this._show(this.message.toString());
            }
        }
    }

    ngOnDestroy(): void {
        this._unsubscribe();
        this._cancelTimer();
    }

    @HostListener("@visible.done", ["$event"])
    public _animationDone(event: AnimationEvent): void {
        if ((event.toState as any) === false) {
            this._currentMessage = "";
        }
    }

    private _unsubscribe(): void {
        if (this._subscription) {
            this._subscription.unsubscribe();
            this._subscription = null;
        }
    }

    private _cancelTimer(): void {
        if (this._timer) {
            clearTimeout(this._timer);
            this._timer = null;
        }
    }

    private _show(message: string): void {
        this._currentMessage = message;
        this._visible = true;
        this._cancelTimer();
        this._timer = window.setTimeout(() => {
            this._hide();
            this._changeDetector.markForCheck();
        }, this._delay);
    }

    private _hide(): void {
        this._cancelTimer();
        this._visible = false;
        this._changeDetector.markForCheck();
    }
}
