/* eslint-disable consistent-return */
import ldIsArray from "lodash-es/isArray";
import ldIsString from "lodash-es/isString";

import { AnimationEvent } from "@angular/animations";
import { DELETE, DOWN_ARROW, END, ENTER, ESCAPE, HOME, UP_ARROW } from "@angular/cdk/keycodes";
import { ConnectedOverlayPositionChange } from "@angular/cdk/overlay";
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostBinding,
    HostListener,
    OnDestroy,
    ViewChild,
    ViewEncapsulation,
    inject
} from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { Observable, Subject } from "rxjs";

import { LgTranslateService } from "@logex/framework/lg-localization";
import {
    IConvertedEntry,
    IConvertedGroup,
    IDropdownDefinition,
    IDropdownDefinitionExtraRow,
    IDropdownGroup,
    IDropdownInitialization,
    IResult,
    DropdownMatchWidth,
    DropdownItemHeights,
    LgDropdownRenderedItem
} from "./lg-dropdown.types";
import { ITooltipOptions } from "../../lg-tooltip/lg-tooltip.types";
import {
    LgDropdownAnimations,
    LgDropdownAnimation,
    LgDropdownAnimationParams
} from "./lg-dropdown-popup.animations";
import { atNextFrame, sanitizeForRegexp } from "@logex/framework/utilities";

const LEFT_BEAK_REGEX = RegExp("<", "g"); // = /</g;
const RIGHT_BEAK_REGEX = RegExp(">", "g"); // = />/g;
const FAKE_LEFT_REGEX = RegExp("__OPENHIGHLIGHT__", "g"); // = /__OPENHIGHLIGHT__/g;
const FAKE_RIGHT_REGEX = RegExp("__CLOSEHIGHLIGHT__", "g"); // = /__CLOSEHIGHLIGHT__/g;
const GROUP_SEPARATOR_HEIGHT = 1;
/**
 * Default rendering buffer in px.
 * Defines how many pixels are to be rendered outside of current view.
 * The higher the value is, the more items will be rendered.
 */
const DEFAULT_RENDERING_BUFFER = 40;
/**
 * Threshold for when virtual for should be used.
 * For small number of items, we don't mind that all are rendered.
 * For large number of items, we want to use virtual for.
 * Whenever the number of rendered items is less or equal to this value,
 * all items will be rendered, effectively not using virtual for.
 */
const THRESHOLD_FOR_USING_VIRTUAL_FOR = 100;

@Component({
    selector: "lg-dropdown-popup",
    templateUrl: "./lg-dropdown-popup.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    animations: LgDropdownAnimations
})
export class LgDropdownPopupComponent<T> implements AfterViewInit, OnDestroy {
    private _changeDetector = inject(ChangeDetectorRef);
    private _domSanitizer = inject(DomSanitizer);
    private _elementRef = inject(ElementRef);
    @ViewChild("contentHolder", { static: false }) _contentHolder?: ElementRef<HTMLDivElement>;

    @HostBinding("class") _className: string | undefined;
    @HostBinding("style.width.px") _width: number | undefined;
    @HostBinding("style.min-width.px") _minWidth: number | undefined;
    @HostBinding("style.max-width.px") _maxWidth: number | undefined;

    _visibility: "hidden" | "initial" | "onTop" | "onBottom" = "initial";

    @HostBinding("@state")
    get _state(): LgDropdownAnimation {
        return {
            value: this._visibility,
            params: this.getAnimationParams()
        };
    }

    @HostListener("@state.done", ["$event"])
    _animationDone(event: AnimationEvent): void {
        if (event.toState === "hidden") {
            this._onHide$.next();
        }
    }

    @HostListener("document:keydown", ["$event.keyCode"])
    _keyClick(keyCode: number): boolean | undefined {
        if (!this.isTop) return true;

        if (keyCode === ESCAPE) {
            this._attemptClose();
            return false;
        }

        if (keyCode === DELETE) {
            this._clearFilter();
            return false;
        }

        if (this._converted.length === 0) return true;

        if (
            keyCode === ENTER &&
            this._currentEntryIndex != null &&
            !this._renderedEntries[this._currentEntryIndex].disabled
        ) {
            this._doSelect();
            return false;
        }

        const hasFocusChanged = this._handleArrowKeys(keyCode, this._renderedEntries);

        if (hasFocusChanged) {
            const entryToMakeVisible = this._renderedEntries[this._currentEntryIndex ?? 0];
            this._visible$.next(entryToMakeVisible);
            return false;
        }

        return undefined;
    }

    private _clearFilter(): void {
        if (this._quickFilter.length) {
            this._onQuickFilterChange("");
        }
    }

    private _handleArrowKeys(keyCode: number, renderedEntries: LgDropdownRenderedItem[]): boolean {
        let currentEntryIndex = this._currentEntryIndex;
        if (keyCode === UP_ARROW && currentEntryIndex > 0) {
            --currentEntryIndex;
        } else if (
            keyCode === DOWN_ARROW &&
            (currentEntryIndex == null || currentEntryIndex < renderedEntries.length - 1)
        ) {
            currentEntryIndex = currentEntryIndex == null ? 0 : currentEntryIndex + 1;
        } else if (keyCode === HOME) {
            currentEntryIndex = 0;
        } else if (keyCode === END) {
            currentEntryIndex = renderedEntries.length - 1;
        }

        if (currentEntryIndex === this._currentEntryIndex) return false;

        this._currentEntryIndex = currentEntryIndex;
        let focusedEntry = renderedEntries[this._currentEntryIndex];
        if (focusedEntry.isGroup) {
            if (keyCode === UP_ARROW && this._currentEntryIndex === 0) {
                this._currentEntryIndex++;
            } else if (keyCode === UP_ARROW || keyCode === END) this._currentEntryIndex--;
            else if (
                keyCode === DOWN_ARROW &&
                this._currentEntryIndex === renderedEntries.length - 1
            ) {
                this._currentEntryIndex--;
            } else if (keyCode === DOWN_ARROW || keyCode === HOME) this._currentEntryIndex++;
            focusedEntry = renderedEntries[this._currentEntryIndex];
        }
        this._cursorId = focusedEntry.id;
        return true;
    }

    set definition(value: IDropdownDefinition<T>) {
        this._definition = value;
        this._convert();
        this._changeDetector.markForCheck();
    }

    get definition(): IDropdownDefinition<T> {
        return this._definition;
    }

    set searchPrefix(value: boolean) {
        this._searchPrefix = value;
    }

    get searchPrefix(): boolean {
        return this._searchPrefix;
    }

    _condensed = false;
    _hideSearch = false;
    _shownAbove = false;
    _isControlCondensed = false;
    GROUP_SEPARATOR_HEIGHT = GROUP_SEPARATOR_HEIGHT;
    _cursorId!: T;
    _selectedCursorId!: T;
    _quickFilter = "";
    _sanitizedFilter = "";
    _itemTooltips = false;
    _highlightSelected = false;
    _tooltipOptions: ITooltipOptions = {
        tooltipClass: "lg-tooltip lg-tooltip--simple",
        position: "left-bottom",
        delayShow: 100,
        delayHide: 100,
        targetActiveClass: "lg-tooltip-visible"
    };

    _renderedEntries: LgDropdownRenderedItem[] = [];
    _itemHeights!: DropdownItemHeights;
    _renderingBuffer = DEFAULT_RENDERING_BUFFER;

    private _standalone = false;
    private _matchWidth!: DropdownMatchWidth;
    private _popupClassName!: string;
    private _definition!: IDropdownDefinition<T>;
    private _currentEntryIndex = -1;
    private _converted: IConvertedGroup[] = [];
    readonly _visible$ = new Subject<LgDropdownRenderedItem>();

    private readonly _destroyed$ = new Subject<void>();
    private readonly _result$ = new Subject<IResult<T>>();
    private readonly _onHide$: Subject<void> = new Subject();
    private _searchPrefix = false;
    private _searchPattern = "";
    private _regexSearchHighlight: RegExp | null = null;
    private _reposition: undefined | (() => void);

    protected _animationEnabled!: boolean;
    protected _isReference = false;
    protected _translateService!: LgTranslateService;

    isTop = true;

    _initialize(initObject: IDropdownInitialization<T>): Observable<IResult<T>> {
        this._popupClassName = initObject.popupClassName || "lg-dropdown-popup";
        this._matchWidth = initObject.matchWidth;
        this._itemTooltips = initObject.itemTooltips;
        this._hideSearch = this._isReference || initObject.hideSearch;
        this._highlightSelected = initObject.highlightSelected ?? false;
        this._condensed = initObject.condensed;
        this._animationEnabled = initObject.animationEnabled;
        this._standalone = initObject.standalone;
        this._translateService = initObject.translateService;
        this._isControlCondensed = initObject.isControlCondensed;
        this._selectedCursorId = initObject.currentValue!;
        this._cursorId = initObject.currentValue!;
        this._reposition = initObject.reposition;

        this.definition = initObject.definition;
        this.searchPrefix = !!initObject.searchPrefix;

        const width = initObject.target.nativeElement.getBoundingClientRect().width;
        if (this._matchWidth === "max") {
            this._minWidth = width;
            this._maxWidth = 480;
        } else if (this._matchWidth === "control") {
            this._width = width;
        }

        this._updateClassName();

        return this._result$.asObservable();
    }

    ngAfterViewInit(): void {
        const wrapper = this._elementRef.nativeElement;
        const div: HTMLDivElement = wrapper.querySelector("#__LG_DROPDOWN_RENDERED_ITEMS__");
        const entry: HTMLDivElement = div.querySelector("#__LG_DROPDOWN_ENTRY__")!;
        const group: HTMLDivElement = div.querySelector("#__LG_DROPDOWN_GROUP__")!;
        const lastEntry: HTMLDivElement = div.querySelector("#__LG_DROPDOWN_ENTRY--LAST__")!;
        const firstEntry: HTMLDivElement = div.querySelector("#__LG_DROPDOWN_ENTRY--FIRST__")!;
        const firstAndLastEntry: HTMLDivElement = div.querySelector(
            "#__LG_DROPDOWN_ENTRY--FIRST--LAST__"
        )!;
        this._itemHeights = {
            entry: entry.clientHeight,
            group: group.clientHeight,
            lastEntry: lastEntry.clientHeight,
            firstEntry: firstEntry.clientHeight,
            firstAndLastEntry: firstAndLastEntry.clientHeight
        };
        this._renderingBuffer = this._getRenderingBuffer(this._itemHeights, this._renderedEntries);
        div.parentElement!.style.display = "none";
    }

    private _getRenderingBuffer(
        itemHeights: DropdownItemHeights,
        renderedEntries: LgDropdownRenderedItem[]
    ): number {
        const items: number[] = Object.values(itemHeights);
        const highestInPx = Math.max(...items);

        if (!renderedEntries) return DEFAULT_RENDERING_BUFFER;
        return renderedEntries.length <= THRESHOLD_FOR_USING_VIRTUAL_FOR
            ? THRESHOLD_FOR_USING_VIRTUAL_FOR * highestInPx
            : DEFAULT_RENDERING_BUFFER;
    }

    ngOnDestroy(): void {
        this._destroyed$.next();
        this._destroyed$.complete();
        this._result$.complete();
    }

    private _convert(): void {
        const result: IConvertedGroup[] = [];

        const definition = this._definition;
        if (!definition) {
            this._converted = [];
            return;
        }

        const entryNameKey = definition.entryName!;
        const entryIdKey = definition.entryId!;
        const entryIconKey = definition.iconName!;

        this._updateSearchPattern();

        for (const definitionGroup of definition.groups as IDropdownGroup[]) {
            const rg: IConvertedEntry[] = [];
            for (const entry of definitionGroup.entries) {
                if (
                    !this._regexSearchHighlight ||
                    entry[entryNameKey].match(this._regexSearchHighlight)
                ) {
                    let icon = entry[entryIconKey];
                    if (icon) {
                        if (ldIsArray(icon)) icon = icon[0];
                        if (ldIsString(icon)) icon = definition.icons![icon];
                    }
                    const convertedEntry: IConvertedEntry = {
                        id: entry[entryIdKey],
                        name: entry[entryNameKey],
                        icon,
                        disabled: entry.disabled,
                        help: entry.helpLC
                            ? this._translateService.translate(entry.helpLC)
                            : entry.help
                    };
                    rg.push(convertedEntry);
                }
            }
            if (rg.length) {
                result.push({
                    name: definitionGroup[definition.groupName!],
                    entries: rg,
                    help: definitionGroup.helpLC
                        ? this._translateService.translate(definitionGroup.helpLC)
                        : definitionGroup.help
                });
            }
        }
        this._converted = result;
        this._renderedEntries = this._getRenderedItems(this._converted);
        this._currentEntryIndex = this._renderedEntries.findIndex(i => i.id === this._cursorId);
        if (this._reposition !== undefined) atNextFrame(this._reposition);
    }

    private _updateSearchPattern(): void {
        if (this._quickFilter.length) {
            this._sanitizedFilter = this._quickFilter.replace(/\s\s+/g, " ").replace(/</, "&lt;");
            if (this._sanitizedFilter.endsWith("*")) {
                this._searchPattern = `^${sanitizeForRegexp(this._sanitizedFilter.slice(0, -1))}`;
            } else if (this._searchPrefix) {
                this._searchPattern = `^${sanitizeForRegexp(this._sanitizedFilter)}`;
            } else {
                this._searchPattern = sanitizeForRegexp(this._sanitizedFilter);
            }
        } else {
            this._sanitizedFilter = "";
            this._searchPattern = "";
        }
        this._regexSearchHighlight = this._searchPattern
            ? new RegExp(this._searchPattern, "i")
            : null;
    }

    private _getRenderedItems(converted: IConvertedGroup[]): LgDropdownRenderedItem[] {
        const result: LgDropdownRenderedItem[] = [];
        converted.forEach((convertedGroup, groupIndex) => {
            const groupToRender: LgDropdownRenderedItem = {
                name: convertedGroup.name,
                help: convertedGroup.help ?? "",
                isGroup: true,
                isFirstEntryInGroup: !!convertedGroup.name,
                isLastEntryInGroup: false,
                isFirstGroup: groupIndex === 0
            };
            if (groupToRender.name) result.push(groupToRender);
            convertedGroup.entries.forEach((convertedEntry, index) => {
                const entryToRender: LgDropdownRenderedItem = {
                    id: convertedEntry.id,
                    name: convertedEntry.name,
                    icon: convertedEntry.icon,
                    disabled: !!convertedEntry.disabled,
                    help: convertedEntry.help ?? "",
                    isGroup: false,
                    isFirstEntryInGroup: groupToRender.isFirstEntryInGroup ? false : index === 0,
                    isFirstGroup: groupIndex === 0,
                    isLastEntryInGroup: index === convertedGroup.entries.length - 1
                };
                result.push(entryToRender);
            });
        });
        return result;
    }

    _onQuickFilterChange(newValue: string): void {
        this._quickFilter = newValue;
        this._convert();
    }

    _onExtraClick(event: MouseEvent, extra: IDropdownDefinitionExtraRow): void {
        event.stopPropagation();
        event.preventDefault();
        if (extra.close) this._attemptClose();
        if (extra.onClick) {
            if (extra.onClick.call(this)) return;
        }
        if (extra.url) {
            window.location.assign(extra.url);
        }
    }

    _onIconClick(event: MouseEvent, entry: IConvertedEntry): boolean {
        event.stopPropagation();
        event.preventDefault();
        if (entry.icon.onClick) {
            entry.icon.onClick(entry.id, entry.icon);
        }
        if (this.definition.icons?.onClick) {
            (this.definition.icons.onClick as any)(entry.id, entry.icon);
        }
        if (!entry || !entry.icon || !entry.icon.clickable) {
            this._onEntryClick(entry);
        }
        return false;
    }

    _onEntryClick(entry: IConvertedEntry): boolean {
        if (entry.disabled) return true;

        this._cursorId = entry.id;
        this._doSelect();

        return false;
    }

    _getHighlightedEntryName(entry: IConvertedEntry): SafeHtml {
        const name = entry.name
            .replace(this._regexSearchHighlight!, "__OPENHIGHLIGHT__$&__CLOSEHIGHLIGHT__")
            .replace(LEFT_BEAK_REGEX, "&lt;")
            .replace(RIGHT_BEAK_REGEX, "&gt;")
            .replace(FAKE_LEFT_REGEX, '<span class="text-highlight">')
            .replace(FAKE_RIGHT_REGEX, "</span>");
        return this._domSanitizer.bypassSecurityTrustHtml(name);
    }

    /**
     * Used by lgVirtualFor to determine height of current item.
     *
     * @param item specifies the currently render item
     * @returns the height for the item in pixels
     */
    _getItemHeight = (item: LgDropdownRenderedItem): number => {
        const groupHeight = this._getGroupHeight(item);
        if (groupHeight != null) return groupHeight;
        return this._getEntryHeight(item);
    };

    private _getGroupHeight(item: LgDropdownRenderedItem): number | null {
        if (item.isGroup && !item.name) return 0;
        if (item.isGroup && item.isFirstGroup) return this._itemHeights.group;
        if (item.isGroup) return this._itemHeights.group + GROUP_SEPARATOR_HEIGHT;
        return null;
    }

    private _getEntryHeight(item: LgDropdownRenderedItem): number {
        const isOnlyEntryInGroup = item.isFirstEntryInGroup && item.isLastEntryInGroup;
        if (isOnlyEntryInGroup && item.isFirstGroup) {
            return this._itemHeights.firstAndLastEntry;
        }
        if (isOnlyEntryInGroup) {
            return this._itemHeights.firstAndLastEntry + GROUP_SEPARATOR_HEIGHT;
        }
        if (item.isFirstEntryInGroup && item.isFirstGroup) return this._itemHeights.firstEntry;
        if (item.isFirstEntryInGroup) return this._itemHeights.firstEntry + GROUP_SEPARATOR_HEIGHT;
        if (item.isLastEntryInGroup) return this._itemHeights.lastEntry;
        return this._itemHeights.entry;
    }

    /**
     * Method that allows changing animation duration when extending the class.
     *
     * @returns animation parameters.
     */
    protected getAnimationParams(): LgDropdownAnimationParams {
        return {
            animationDuration: this._animationEnabled ? 250 : 0
        };
    }

    /**
     * Public method used by `lg-dropdown.component.ts` that allows to update position based on the holder.
     *
     * @param position - change emitted by strategy when the position changes.
     */
    updatePosition(position: ConnectedOverlayPositionChange): void {
        this._shownAbove = position.connectionPair.overlayY === "bottom";
        this._updateClassName();

        if (this._visibility === "initial") {
            this._visibility = this._shownAbove ? "onTop" : "onBottom";
        }
    }

    /**
     * Public method used by `lg-dropdown.component.ts` that allows to hide the popup.
     */
    hide(): Observable<void> {
        this._visibility = "hidden";
        this._changeDetector.markForCheck();
        return this._onHide$.asObservable();
    }

    private _attemptClose(): void {
        this._result$.next({
            selected: false
        });
    }

    private _doSelect(): void {
        this._result$.next({
            selected: true,
            id: this._cursorId
        });
    }

    private _updateClassName(): void {
        const result = [
            this._popupClassName,
            this._condensed ? "lg-dropdown-popup--condensed" : "",
            this._matchWidth === "control" ? "lg-dropdown-popup--match-width" : "",
            this._shownAbove ? "lg-dropdown-popup--above" : "lg-dropdown-popup--below",
            this._hideSearch ? "" : "lg-dropdown-popup--searchable",
            this._standalone ? "lg-dropdown-popup--standalone" : ""
        ];

        this._className = result.join(" ");
        this._changeDetector.markForCheck();
    }
}
