import { Observable } from "rxjs";
import { TemplateRef } from "@angular/core";
import { PartialBy } from "@logex/framework/types";
import { LgPanelGridService } from "./lg-panel-grid.service";
import { LgPanelGridNode } from "./lg-panel-grid-node";

export enum LgPanelState {
    Default = 1,
    Fullscreen = 2,
    Expanded = 3
}

export enum LgPanelStateExtension {
    Minimized = 4,
    Detached = 5
}

export type LgEffectivePanelState = LgPanelState | LgPanelStateExtension;

export interface LgPanelGridDefBase {
    size: number;
    id?: string;
}

export interface LgPanelGridRowDef extends LgPanelGridDefBase {
    columns: Array<LgPanelGridColumnDef | LgPanelGridLeafDef>;
}

export interface LgPanelGridColumnDef extends LgPanelGridDefBase {
    rows: Array<LgPanelGridRowDef | LgPanelGridLeafDef>;
}

export interface LgPanelGridLeafDef extends LgPanelGridDefBase {
    id: string;
}

export type LgPanelGridDefinition =
    | PartialBy<LgPanelGridRowDef, "size">
    | PartialBy<LgPanelGridColumnDef, "size">;

export interface ILgPanelComponent {
    readonly panelClass: string | null;
    readonly id: string;
    readonly location?: string;
    readonly _context: LgPanelContext;

    readonly _effectiveState: LgEffectivePanelState;
    readonly _panelTemplate: TemplateRef<LgPanelContext>;
    readonly resizeActions: LgPanelResizeAction[];
    readonly decorationChange$: Observable<void>;
    _setEffectiveState(state: LgEffectivePanelState): LgEffectivePanelState;
    _resizeActionsChange: Observable<LgPanelResizeAction[]>;
}

export interface LgPanelContext {
    $implicit: ILgPanelComponent;
    maximized: boolean;
    minimized: boolean;
}

export interface LgPanelRegistration {
    updateSettings(whenMinimized: boolean, whenMaximized: boolean, whenRegular: boolean): void;
    remove(): void;
}

export interface LgPanelOrderedTemplate {
    order: number;
    visibleWhenMinimized: boolean;
    visibleWhenMaximized: boolean;
    whenRegular: boolean;
    template: TemplateRef<LgPanelContext>;
}

export type LgPanelResizeAction = "toFullScreen" | "toDefault" | "expand";

export interface LgPanelResizeTypeDefinition {
    type: LgPanelResizeAction;
    iconName: string;
    tooltipLc: string;
    isDefault: (node: LgPanelGridNode, gridService: LgPanelGridService) => boolean;
    showIf: (node: LgPanelGridNode, gridService: LgPanelGridService) => boolean;
}

export interface LgPanelGridChange {
    previousState: LgEffectivePanelState;
    currentState: LgEffectivePanelState;
    panel: ILgPanelComponent;
}

export interface LgPanelGridChangeEvent {
    [id: string]: LgPanelGridChange;
}
