import {
    ChangeDetectionStrategy,
    Component,
    inject,
    Input,
    ViewEncapsulation
} from "@angular/core";
import { useTranslationNamespace } from "@logex/framework/lg-localization";
import {
    IAuthenticationService,
    IUser,
    LG_AUTHENTICATION_SERVICE,
    LG_USER_INFO
} from "@logex/framework/lg-application";
import { ISidebar } from "../sidebar-context";
import { LgPromptDialog } from "@logex/framework/ui-core";

@Component({
    selector: "lg-sidebar-impersonation",
    templateUrl: "./lg-sidebar-impersonation.component.html",
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: "lg-sidebar-impersonation lg-sidebar-panel"
    },
    viewProviders: [useTranslationNamespace("FW._Sidebar._Impersonation")]
})
export class LgSidebarImpersonationComponent {
    private _authService: IAuthenticationService = inject(LG_AUTHENTICATION_SERVICE);
    private _promptDialog = inject(LgPromptDialog).bindViewContainerRef();
    userInfo: IUser = inject(LG_USER_INFO);

    /**
     * Sidebar handler (required).
     */
    @Input({ required: true }) sidebar!: ISidebar;

    showEndImpersonation: boolean = this._authService.endImpersonation !== undefined;

    public _endImpersonation(): void {
        this._promptDialog
            .confirmLc(".EndImpersonationConfirmation.Title", ".EndImpersonationConfirmation.Text")
            .then(choice => {
                if (choice === "ok") {
                    this._authService.endImpersonation!().then(() => {
                        this.sidebar.dismissPanel();
                        window.location.reload();
                    });
                }
            });
    }
}
