import { InjectionToken } from "@angular/core";

export const LG_AUTHENTICATION_SERVICE = new InjectionToken<
    IAuthenticationService | ITokenAuthenticationService
>("lgAuthenticationService");

export interface IAuthenticationService {
    login(): Promise<boolean>;
    isLoggedIn(): Promise<boolean>;
    logout(): Promise<void>;
    endImpersonation?(): Promise<void>;
    readonly loggedIn: boolean | undefined;
    readonly user: UserIdentity | undefined;
}

export interface ITokenAuthenticationService extends IAuthenticationService {
    getAccessToken(): Promise<string>;
}

export interface UserIdentity {
    id: string; // Internal user identifier
    login: string; // Login that user uses to log into the system (most likely looks like email)
    name: string; // User's full name: first name + last name.
}

export function instanceOfTokenAuthService(
    service: IAuthenticationService | ITokenAuthenticationService
): service is ITokenAuthenticationService {
    return "getAccessToken" in service;
}

export interface IUserProfileResponseV2 {
    accountId: number;
    email: string;
    firstName: string;
    impersonation: {
        OriginalUserName: string;
        OriginalUserEmail: string;
    };
    isDisabled: boolean;
    isInternal: boolean;
    lastName: string;
    metadata: {
        UserId: string;
        LastLogin: string;
    };
    organizations: Array<{
        agbCode: string;
        cicCode: number;
        clientId: number;
        mrdmUri: string;
        name: string;
        organizationId: number;
        position: string;
        ziekenhuisCode: number;
    }>;
}
