import {
    ChangeDetectionStrategy,
    Component,
    ContentChild,
    ContentChildren,
    Input,
    QueryList,
    TemplateRef
} from "@angular/core";

import { LgPanelChoiceVariantComponent } from "./lg-panel-choice-variant.component";
import { IPanelChoiceDefinition } from "./lg-panel.types";

@Component({
    selector: "lg-panel-choice",
    template: "",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LgPanelChoiceComponent {
    /**
     * Choice identifier (required).
     */
    @Input({ required: true }) id!: number;

    /**
     * Choice name (required).
     */
    @Input({ required: true }) name!: string;

    /**
     * Choice icon (required).
     */
    @Input({ required: true }) icon!: string;

    @Input() title?: string;

    @Input() panelTitle?: string;

    @ContentChildren(LgPanelChoiceVariantComponent)
    _variantDefinitions!: QueryList<LgPanelChoiceVariantComponent>;

    @ContentChild("body") mainPane!: TemplateRef<any>;
    @ContentChild("additionalMenuItemsForChoice")
    additionalMenuItems?: TemplateRef<any>;

    @ContentChild("additionalMenuItemsForChoice2")
    additionalMenuItems2?: TemplateRef<any>;

    getDefinition(): IPanelChoiceDefinition {
        const variantDefinitions = this._variantDefinitions.map(x => x.getDefinition());

        return {
            id: this.id,
            name: this.name,
            icon: this.icon,
            title: this.title,
            additionalMenuItemsTemplateRef: this.additionalMenuItems,
            rightAdditionalMenuItemsTemplateRef: this.additionalMenuItems2,
            variants: variantDefinitions,
            mainPaneTemplateRef: this.mainPane
        };
    }
}
