import ldRemove from "lodash-es/remove";
import ldSize from "lodash-es/size";
import ldClone from "lodash-es/clone";
import ldIsString from "lodash-es/isString";
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    HostListener,
    inject,
    NgZone,
    OnDestroy,
    Renderer2,
    TemplateRef,
    ViewChild
} from "@angular/core";
import { animate, AnimationEvent, state, style, transition, trigger } from "@angular/animations";
import { ConnectedOverlayPositionChange, ViewportRuler } from "@angular/cdk/overlay";

import { Observable, Subject } from "rxjs";
import { first, takeUntil } from "rxjs/operators";

import {
    IColumnFilterDictionary,
    IFilterOption,
    IFilterSorterOption
} from "@logex/framework/types";
import { LgTranslateService, useTranslationNamespace } from "@logex/framework/lg-localization";
import {
    easingDefs,
    elementHasClass,
    sanitizeForRegexp,
    scheduleChangeDetection
} from "@logex/framework/utilities";
import {
    LgMultiFilterItemContext,
    LgMultiFilterItemCustomization,
    LgMultiFilterLook
} from "./lg-multi-filter.types";
import { I } from "@angular/cdk/keycodes";
import { IDropdownDefinition } from "../lg-dropdown/lg-dropdown.types";

export type LgMultiFilterSourceValue =
    | IFilterOption[]
    | Promise<IFilterOption[]>
    | Observable<IFilterOption[]>;
export type LgMultiFilterSource = (() => LgMultiFilterSourceValue) | LgMultiFilterSourceValue;

// ----------------------------------------------------------------------------------
//
interface IItem extends IFilterOption {
    nameUnescaped: string;
}

@Component({
    standalone: false,
    selector: "lg-multi-filter-popup",
    templateUrl: "./lg-multi-filter-popup.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,

    animations: [
        trigger("state", [
            state("initial, hidden", style({ opacity: 0 })),
            state("visible", style({ opacity: 1 })),

            transition("* => visible", [
                style({ opacity: 0 }),
                animate(`200ms ${easingDefs.easeOutCubic}`, style({ opacity: 1 }))
            ]),

            transition("* => hidden", [animate(`200ms ${easingDefs.easeOutCubic}`)])
        ])
    ],

    host: {
        class: "lg-multi-filter-popup",
        "[class.lg-multi-filter-popup--above]": "_shownAbove",
        "[class.lg-multi-filter-popup--grid]": "_look === 'grid'",
        "[class.lg-multi-filter-popup--wide]": "_isWide"
    },

    viewProviders: [useTranslationNamespace("FW._Directives._lgMultiFilter")]
})
export class LgMultiFilterPopupComponent implements OnDestroy {
    private _changeDetectorRef = inject(ChangeDetectorRef);
    private _elementRef = inject(ElementRef);
    private _ngZone = inject(NgZone);
    private _renderer = inject(Renderer2);
    private _translate = inject(LgTranslateService);
    private _viewportRuler = inject(ViewportRuler);

    @HostBinding("@state")
    _visibility: "hidden" | "initial" | "visible" = "initial";

    _targetWidth!: number;
    _windowWidth!: number;

    _placeholder = "";

    _quickFilter = "";
    _sanitizedFilter = "";
    _regexpFilter?: RegExp;

    _converted: IItem[] = [];

    _selection!: Record<string, string>;

    _selectionSize = 0;

    readonly _visible$ = new Subject<number>();

    _loading = false;

    _empty = true;

    _checkState: 0 | 1 | 2 = 0;

    _selectedOnly = false;

    _currentIndex: number | null = null;

    _sourceData: IFilterOption[] = [];

    _isTop = true;

    _shownAbove = false;

    _isReadonly = false;

    _isCondensed = false;

    _isWide = false;

    _transparentSearchBackground = true;

    _look: LgMultiFilterLook = "default";

    _itemTemplate: TemplateRef<LgMultiFilterItemContext> | null = null;
    _itemClass = "";
    _itemHeight = 0;

    _searchWidthMatchesPopup = false;
    _unknownsRemoved = false;

    _showSorter = false;
    _sorterOptions: IFilterSorterOption[] | undefined;
    _baseSorterDefinition: IFilterSorterOption[] = [
        {
            property: "name",
            name: this._translate.translate(
                "FW._Directives._lgMultiFilter.Sorter.AlphabeticallyAscName"
            ),
            icon: "icon-alphabetically",
            title: this._translate.translate(
                "FW._Directives._lgMultiFilter.Sorter.AlphabeticallyAscTitle"
            )
        },
        {
            property: "name",
            name: this._translate.translate(
                "FW._Directives._lgMultiFilter.Sorter.AlphabeticallyDescName"
            ),
            icon: "icon-alphabetically-desc",
            title: this._translate.translate(
                "FW._Directives._lgMultiFilter.Sorter.AlphabeticallyDescTitle"
            ),
            reverse: true
        },
        {
            property: "id",
            name: this._translate.translate("FW._Directives._lgMultiFilter.Sorter.IdAscName"),
            icon: "icon-sort-id-asc",
            title: this._translate.translate("FW._Directives._lgMultiFilter.Sorter.IdAscTitle")
        },
        {
            property: "id",
            name: this._translate.translate("FW._Directives._lgMultiFilter.Sorter.IdDescName"),
            icon: "icon-sort-id-desc",
            title: this._translate.translate("FW._Directives._lgMultiFilter.Sorter.IdDescTitle"),
            reverse: true
        }
    ];

    _sorterDefinition: IDropdownDefinition<string> = {
        groupId: "id",
        groupName: "name",
        entryId: "id",
        groups: [
            {
                id: 0,
                entries: []
            }
        ]
    };

    _currentSorterItem!: IFilterSorterOption;

    private _show!: EventEmitter<void>;

    private readonly _result$ = new Subject<IColumnFilterDictionary | undefined>();

    private readonly _onHide$ = new Subject<void>();

    @ViewChild("scroller", { static: true }) _scroller!: ElementRef<HTMLElement>;

    @ViewChild("defaultItemTemplate", { static: true })
    _defaultItemTemplate: TemplateRef<LgMultiFilterItemContext> | null = null;

    @ViewChild("countItemTemplate", { static: true })
    _countItemTemplate: TemplateRef<LgMultiFilterItemContext> | null = null;

    private readonly _destroyed$ = new Subject<void>();

    private _selectionChange$: Subject<IColumnFilterDictionary> | undefined;

    public _hideHeader = false;
    // --------------------------------------------------------------------------------------------------------
    //  Popup initialization
    // --------------------------------------------------------------------------------------------------------
    _initialize(args: {
        target: ElementRef;
        filter: IColumnFilterDictionary;
        placeholder: string;
        source: LgMultiFilterSource;
        show: EventEmitter<void>;
        condensed: boolean;
        wide: boolean;
        reposition: () => void;
        look: LgMultiFilterLook;
        readonly?: boolean; // Do Not Remove
        // `readonly` (`_isReadonly`) is always false if you only look at the fw code
        // that's because we call it only from one place and this argument isn't provided there
        // however this (`lg-multi-filter-popup`) component is used in 2020 codebase where they do provide it
        // until we merge the 2020 component into fw it has to remain in this unfortunate state
        width?: number; // Optional width of the popup window
        showDataCounts?: boolean;
        itemCustomization?: LgMultiFilterItemCustomization | undefined;
        transparentSearchBackground?: boolean;
        searchWidthMatchesPopup?: boolean;
        selectionChange$?: Subject<IColumnFilterDictionary>;
        hideHeader?: boolean;
        sorterOptions?: IFilterSorterOption[] | undefined;
        showSorter?: boolean;
    }): Observable<IColumnFilterDictionary | undefined> {
        const filter = args.filter || { $empty: true };
        this._placeholder = args.placeholder;
        this._show = args.show;
        this._isCondensed = args.condensed;
        this._look = args.look;
        this._isReadonly = args.readonly || false;
        this._isWide = args.wide;
        this._searchWidthMatchesPopup = args.searchWidthMatchesPopup || false;
        this._selectionChange$ = args.selectionChange$;
        this._hideHeader = args.hideHeader || false;
        this._sorterOptions = args.sorterOptions;
        this._showSorter = args.showSorter || false;

        if (this._showSorter) {
            if (!this._sorterOptions) {
                this._sorterOptions = this._baseSorterDefinition;
            }
            this._sorterOptions.forEach((option, index) => {
                option.id = index.toString();
                this._sorterDefinition.groups![0].entries.push({
                    id: option.id,
                    name: option.name,
                    icon: { icon: option.icon },
                    title: option.title
                });
            });
            this._currentSorterItem = this._sorterOptions[0];
        }

        if (args.transparentSearchBackground != null) {
            this._transparentSearchBackground = args.transparentSearchBackground;
        }

        this.setSelection(filter);

        let sourceValue: LgMultiFilterSourceValue;

        if (typeof args.source === "function") {
            sourceValue = args.source();
        } else {
            sourceValue = args.source;
        }

        if ("then" in sourceValue) {
            this._loading = true;
            (sourceValue as Promise<any>)
                .then(sourceData => {
                    this._prepareSource(sourceData, args.reposition);
                })
                .catch(e => {
                    this._prepareSource(null);
                    console.error(e);
                });
        } else if ("subscribe" in sourceValue) {
            this._loading = true;
            (sourceValue as Observable<any>).pipe(takeUntil(this._destroyed$)).subscribe(
                sourceData => {
                    this._prepareSource(sourceData, args.reposition);
                },
                error => {
                    this._prepareSource(null);
                    console.error(error);
                }
            );
        } else {
            this._prepareSource(sourceValue as any);
        }

        this._visibility = "visible";

        if (this._look !== "grid") {
            this._targetWidth = args.target.nativeElement.offsetWidth;
        } else {
            const width = Math.max(args.target.nativeElement.offsetWidth, 180);
            this._targetWidth = width;
            this._windowWidth = width;
        }

        if (args.width != null) {
            this._targetWidth = args.width;
            this._windowWidth = args.width;
        }

        this._ngZone.onMicrotaskEmpty.pipe(first()).subscribe(() => {
            let totalHeight = 0; // flexbox doesn't let us get proper height for the whole element
            for (const child of this._elementRef.nativeElement.childNodes) {
                let childHeight = child.offsetHeight;
                if (childHeight === undefined) continue;
                if (elementHasClass(child, "lg-multi-filter-popup__trap")) continue;
                if (childHeight === 0) {
                    for (const second of child.childNodes) {
                        if (second.offsetHeight === undefined) continue;
                        childHeight += second.offsetHeight;
                    }
                }
                totalHeight += childHeight;
            }
            const viewport = this._viewportRuler.getViewportSize();
            const padding = totalHeight - this._scroller.nativeElement.clientHeight;
            const maxHeight = Math.max(
                50,
                Math.min(500, Math.floor(viewport.height * 0.49 - padding))
            );
            this._renderer.setStyle(this._scroller.nativeElement, "maxHeight", maxHeight + "px");
        });

        // note: could be triggered from lg-multi-filter, but keeping it here in case we decide to synchronise it with the animation
        this._show.next();

        const customization = args.itemCustomization || {};
        this._itemClass = customization.class || "";
        this._itemHeight = customization.itemHeight ?? 24;
        if (customization.template) {
            if (typeof customization.template === "function") {
                this._itemTemplate = customization.template();
            } else {
                this._itemTemplate = customization.template;
            }
        } else {
            this._itemTemplate = args.showDataCounts
                ? this._countItemTemplate
                : this._defaultItemTemplate;
        }

        return this._result$.asObservable();
    }

    setSelection(filter: IColumnFilterDictionary): void {
        if (filter.$empty || ldSize(filter) === 0) {
            this._empty = true;
            this._selection = {};
            this._selectionSize = 0;
        } else {
            this._empty = false;
            this._selection = ldClone(filter);
            this._selectionSize = ldSize(filter);
        }
        this._updateCheckState();
    }

    _updatePosition(change: ConnectedOverlayPositionChange): void {
        this._shownAbove = change.connectionPair.overlayY === "bottom";
        if (this._shownAbove) {
            this._renderer.addClass(this._elementRef.nativeElement, "lg-multi-filter-popup--above");
        } else {
            this._renderer.removeClass(
                this._elementRef.nativeElement,
                "lg-multi-filter-popup--above"
            );
        }
        scheduleChangeDetection();
    }

    // ----------------------------------------------------------------------------------
    hide(): Observable<void> {
        this._visibility = "hidden";
        this._changeDetectorRef.markForCheck();
        return this._onHide$.asObservable();
    }

    // --------------------------------------------------------------------------------------------------------
    @HostListener("@state.done", ["$event"])
    _animationDone(event: AnimationEvent): void {
        if (event.toState === "hidden") {
            this._onHide$.next();
        }
    }

    // --------------------------------------------------------------------------------------------------------
    _checkAll(add: boolean): void {
        if (add) {
            this._addVisible();
        } else {
            this._removeVisible();
        }
    }

    // --------------------------------------------------------------------------------------------------------
    _toggleItem(entry?: IItem): void {
        if (!entry) {
            if (this._currentIndex === null) return;
            entry = this._converted[this._currentIndex];
        }
        if (this._selection[entry.id]) {
            this._remove(entry);
        } else {
            this._add(entry);
        }
        this._empty = !this._selectionSize;
        this._updateCheckState();
        this._emitSelectChange();
    }

    // --------------------------------------------------------------------------------------------------------
    _invertAll(): void {
        if (this._checkState === 1) {
            this._removeVisible();
        } else if (this._checkState === 0) {
            this._addVisible();
        } else {
            for (const entry of this._converted) {
                if (this._selection[entry.id]) {
                    this._remove(entry);
                } else {
                    this._add(entry);
                }
            }
            this._empty = !this._selectionSize;
            this._updateCheckState();
            this._emitSelectChange();
        }
    }

    // --------------------------------------------------------------------------------------------------------
    _sort(sortedBy: string): void {
        this._currentSorterItem = this._sorterOptions!.filter(item => item.id === sortedBy)[0];

        const sortedByProperty = this._currentSorterItem.property;

        const sortBy = ldIsString(sortedByProperty)
            ? (item: Record<string, any>) => item[sortedByProperty]
            : sortedByProperty;

        if (this._currentSorterItem.reverse) {
            this._converted.sort((a, b) => {
                const sa = sortBy(a);
                const sb = sortBy(b);
                if (sa > sb) {
                    return -1;
                }
                if (sb > sa) {
                    return 1;
                }
                return 0;
            });
        } else {
            this._converted.sort((a, b) => {
                const sa = sortBy(a);
                const sb = sortBy(b);
                if (sa < sb) {
                    return -1;
                }
                if (sb < sa) {
                    return 1;
                }
                return 0;
            });
        }
    }

    // --------------------------------------------------------------------------------------------------------
    private _addVisible(): void {
        for (const entry of this._converted) {
            if (!this._selection[entry.id]) {
                this._add(entry);
            }
        }
        this._updateCheckState();
        this._emitSelectChange();
        this._empty = !this._selectionSize;
    }

    // --------------------------------------------------------------------------------------------------------
    private _removeVisible(): void {
        // cannot do simple loop, because remove can modify the array
        let i = 0;
        while (i < this._converted.length) {
            const entry = this._converted[i];
            if (this._selection[entry.id] && !entry.disabled) {
                this._remove(entry);
            } else {
                ++i;
            }
        }
        if (!this._unknownsRemoved && this._quickFilter === "") this._removeUnknownSelections();
        this._empty = !this._selectionSize;
        this._updateCheckState();
        this._emitSelectChange();
    }

    // --------------------------------------------------------------------------------------------------------
    private _removeUnknownSelections(): void {
        for (const key in this._selection) {
            if (this._selection[key]) {
                delete this._selection[key];
                --this._selectionSize;
            }
        }

        this._unknownsRemoved = true;
    }

    // --------------------------------------------------------------------------------------------------------

    private _add(entry: IItem): void {
        if (entry.disabled) return;

        if (!this._selection[entry.id]) {
            this._selection["" + entry.id] = entry.nameUnescaped || "?";
            ++this._selectionSize;
        }
    }

    // --------------------------------------------------------------------------------------------------------
    private _remove(entry: IItem): void {
        if (entry.disabled) return;

        delete this._selection[entry.id];
        --this._selectionSize;
        if (this._selectedOnly) {
            ldRemove(this._converted, item => item.id === entry.id);
            if (this._currentIndex !== null && this._currentIndex >= this._converted.length)
                this._currentIndex -= 1;
            if (this._selectionSize === 0) {
                this._selectedOnly = false;
                this._convert();
            }
        }
    }

    // --------------------------------------------------------------------------------------------------------
    private _prepareSource(data: IFilterOption[] | null, reposition?: () => void): void {
        this._sourceData = data ?? [];
        for (const item of this._sourceData) {
            if (item.name == null) {
                item.name = "";
            } else {
                item.name = item.name.replace(/</gi, "&lt;");
            }
        }

        this._convert();

        this._loading = false;

        if (reposition) {
            this._ngZone.onStable.pipe(first()).subscribe(reposition);
        }
        this._changeDetectorRef.markForCheck();
    }

    // --------------------------------------------------------------------------------------------------------
    private _convert(): void {
        const currentEntry =
            this._converted && this._currentIndex !== null && this._converted[this._currentIndex];
        const currentId = currentEntry ? currentEntry.id : null;
        this._checkState = 0;

        if (!this._sourceData.length) {
            this._converted = [];
            return;
        }

        const result: IItem[] = [];

        let searchPattern = "";
        let search: RegExp | null = null;
        if (this._quickFilter.trim().length) {
            this._sanitizedFilter = this._quickFilter.replace(/\s\s+/g, " ").replace(/</, "&lt;");
            const inputArray = this._quickFilter
                .split(";")
                .sort((a, b) => (a.length > b.length ? -1 : 1))
                .map(item => item.replace(/^\s+/, ""))
                .filter(item => item.length !== 0);

            const isAsteriskLast = inputArray.slice(-1)[0].trim() === "*";

            if (isAsteriskLast) {
                inputArray.pop();
                searchPattern = inputArray.map(i => `^(${sanitizeForRegexp(i)})`).join("|");
            } else {
                const mapped = inputArray.map(i => {
                    if (i.endsWith("*")) {
                        return `^(${sanitizeForRegexp(i.slice(0, -1))})`;
                    }
                    return `(${sanitizeForRegexp(i)})`;
                });
                searchPattern = mapped.join("|");
            }

            search = new RegExp(searchPattern, "i");
            this._regexpFilter = search;
        } else {
            this._sanitizedFilter = "";
        }

        let found = false;
        for (const entry of this._sourceData) {
            if (!search || entry.name.match(search)) {
                if (!this._selectedOnly || this._selection["" + entry.id]) {
                    result.push({
                        ...entry,
                        nameUnescaped: entry.name.replace(/&lt;/gi, "<")
                    });
                    if (entry.id === currentId) {
                        this._currentIndex = result.length - 1;
                        found = true;
                    }
                }
            }
        }

        this._converted = result;
        if (this._showSorter) {
            this._sort(this._currentSorterItem!.id!);
        }
        if (result.length && !found) {
            this._currentIndex = currentEntry ? 0 : null;
        }

        this._updateCheckState();

        if (this._currentIndex !== null) {
            this._visible$.next(this._currentIndex);
        }
    }

    // --------------------------------------------------------------------------------------------------------
    private _updateCheckState(): void {
        let checked = false;
        let unchecked = false;

        for (const entry of this._converted) {
            if (entry.disabled) continue;

            if (this._selection[entry.id]) {
                checked = true;
            } else {
                unchecked = true;
            }
        }

        this._checkState = checked ? (unchecked ? 2 : 1) : 0;
    }

    private _emitSelectChange(): void {
        if (this._selectionChange$ != null) {
            if (this._empty) {
                this._selectionChange$.next({ $empty: true });
            } else {
                this._selectionChange$.next(this._selection);
            }
        }
    }

    // --------------------------------------------------------------------------------------------------------
    @HostListener("document:keypress", ["$event"])
    keypress(event: KeyboardEvent): boolean {
        if (event.shiftKey) {
            if (event.key === "+") {
                this._addVisible();
                return false;
            }
            if (event.key === "-") {
                this._removeVisible();
                return false;
            }
        }
        return true;
    }

    @HostListener("document:keydown", ["$event"])
    keyClick(event: KeyboardEvent): boolean {
        if (event.ctrlKey && event.keyCode === I) {
            this._invertAll();
            return false;
        }

        if (event.keyCode === 27) {
            this._attemptCancel();
            return true;
        }

        if (event.keyCode === 46) {
            if (this._quickFilter.length) {
                this._quickFilter = "";
                this._convert();
            }
            return false;
        }

        if (this._converted.length) {
            // First keyboard touch, choose index
            if (
                this._currentIndex === null &&
                (event.keyCode === 38 ||
                    event.keyCode === 40 ||
                    event.keyCode === 36 ||
                    event.keyCode === 35 ||
                    event.keyCode === 13)
            ) {
                this._currentIndex = 0;
                // If this was keyboard up or down or enter, do not do anything else
                if (event.keyCode === 38 || event.keyCode === 40 || event.keyCode === 13) {
                    this._visible$.next(this._currentIndex);
                    return false;
                }
            }
            if (event.keyCode === 38 && this._currentIndex! > 0) {
                --this._currentIndex!;
            } else if (event.keyCode === 40) {
                if (this._currentIndex! < this._converted.length - 1) {
                    ++this._currentIndex!;
                }
            } else if (event.keyCode === 36) {
                this._currentIndex = 0;
            } else if (event.keyCode === 35) {
                this._currentIndex = this._converted.length - 1;
            } else if (event.keyCode === 13) {
                this._toggleItem();
                return false;
            } else {
                return true;
            }

            this._visible$.next(this._currentIndex!);
            return false;
        }

        return true;
    }

    // --------------------------------------------------------------------------------------------------------
    _onClick(event: MouseEvent): boolean {
        if (this._isReadonly) return true;

        let target = event.target as HTMLElement;

        while (
            target.parentElement &&
            !elementHasClass(target, "lg-multi-filter-popup__body__row") &&
            !elementHasClass(target, "lg-scrollable__holder")
        ) {
            target = target.parentElement;
        }

        if (elementHasClass(target, "lg-multi-filter-popup__body__row")) {
            const targetIndex = +target.attributes["data-index" as any].value;
            if (this._currentIndex !== null) this._currentIndex = targetIndex;
            if (!this._converted[targetIndex].disabled) {
                this._toggleItem(this._converted[targetIndex]);
            }
            event.stopPropagation();
            event.preventDefault();
            return false;
        }

        return true;
    }

    // --------------------------------------------------------------------------------------------------------
    _onPaste(e: ClipboardEvent): void {
        e.preventDefault();
        const clipboardText = e.clipboardData?.getData("Text") ?? "";
        this._quickFilter = clipboardText.trim().replace(/[\r\n:;]+/g, ";");
        this._convert();
    }

    // ---------------------------------------------------------------------------------------------
    _quickFilterChanged(newValue: string): void {
        this._quickFilter = newValue;
        if (!this._loading) {
            this._convert();
        }
    }

    // --------------------------------------------------------------------------------------------------------
    _selectedOnlyToggle(_value: boolean): void {
        this._selectedOnly = !this._selectedOnly;
        this._convert();
    }

    // --------------------------------------------------------------------------------------------------------
    _keepFocus(event: FocusEvent): void {
        const input = event.target as HTMLElement;
        if (input.focus) {
            setTimeout(() => {
                input.focus();
            }, 0);
        }
    }

    // ---------------------------------------------------------------------------------------------
    //  Close and selection
    // ---------------------------------------------------------------------------------------------
    _attemptClose(): void {
        if (this._empty) {
            this._result$.next({ $empty: true });
        } else {
            this._result$.next(this._selection);
        }
    }

    _attemptCancel(): void {
        this._result$.next(undefined);
    }

    ngOnDestroy(): void {
        this._destroyed$.next();
        this._destroyed$.complete();
    }
}
