/* eslint-disable @typescript-eslint/no-this-alias */
import ldSortBy from "lodash-es/sortBy";
import ldIsEmpty from "lodash-es/isEmpty";
import ldIsArray from "lodash-es/isArray";
import * as d3 from "d3";
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    TemplateRef,
    ViewChild,
    inject,
    ViewContainerRef
} from "@angular/core";

import { LgSimpleChanges } from "@logex/framework/types";
import {
    SankeyColumn,
    SankeyLink,
    SankeyNode,
    SankeyInputDatum,
    SankeyChartLinkTooltipContext,
    SankeyChartNodeTooltipContext,
    SankeyNodeTemplateContext,
    SankeyTooltipContext
} from "./lg-sankey-chart.types";
import { atNextFrame } from "@logex/framework/utilities";
import { isLinkLeft, isLinkRight } from "./linkTypeGuard";
import { D3TooltipApi, ID3TooltipOptions, LgD3TooltipService } from "../d3/lg-d3-tooltip.service";
import { TemplatePortal } from "@angular/cdk/portal";
import { BehaviorSubject } from "rxjs";
import { getRecommendedPosition } from "../shared/getRecommendedPosition";
import { IExportableChart, LgChartExportContainerDirective, SankeyColumnTemplateContext } from "..";

type SankeyColumnMap = Record<string, ColumnMap>;
interface ColumnMap {
    column: number;
    nodes: SankeyInputDatum[];
}

const COLUMN_TITLE_HEIGHT = 50;
const DEFAULT_COLUMN_WIDTH = 250;
const DEFAULT_LINK_WIDTH = 120;
const DEFAULT_NODE_HEIGHT = 70;
const MIN_LINK_TEXT_Y_POSITION = 10;

// If low amount of links, use default + 0.25 opacity
const COLORS = {
    default: "#CCCCCC",
    default_lighter: "#F2F2F2",
    add: "#83D8D8",
    add_lighter: "#E0F5F5",
    substract: "#E58C82",
    substract_lighter: "#F8E2DF"
};

/**
 * Enum for selecting indicator elements.
 * If the link is going from source element, we want to select indicator on the right side of the node.
 * If the link is going to destination element, we want to select the indicator on left side of the node.
 * Used for connecting right side of source node to left side of destination node.
 */
enum IndicatorSide {
    Source = "right",
    Destination = "left"
}

interface LinkProperties {
    top: number;
    left: number;
    element: HTMLElement;
}

@Component({
    standalone: false,
    selector: "lg-sankey-chart",
    templateUrl: "./lg-sankey-chart.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LgSankeyChartComponent
    implements OnInit, AfterViewInit, OnChanges, OnDestroy, IExportableChart
{
    private _cdRef = inject(ChangeDetectorRef);
    private _elementRef = inject(ElementRef);
    private _exportContainer = inject(LgChartExportContainerDirective, { optional: true });
    private _hostElement = inject(ElementRef<HTMLElement>);
    private _ngZone = inject(NgZone);
    private _renderer = inject(Renderer2);
    private _tooltipService = inject(LgD3TooltipService);
    private _viewContainerRef = inject(ViewContainerRef);
    /**
     * @requires
     * Specifies the width of the chart area in pixels.
     */
    @Input({ required: true }) width!: number;

    /**
     * @requires
     * Specifies the height of the chart area in pixels.
     */
    @Input({ required: true }) height!: number;

    /**
     * Specifies the width of links and defines space between columns. Defaults to 120.
     *
     * @default 120
     */
    @Input() linkWidth?: number = DEFAULT_LINK_WIDTH;

    /**
     * Specifies the width of columns. Defaults to 250.
     *
     * @default 250
     */
    @Input() nodeWidth?: number = DEFAULT_COLUMN_WIDTH;

    /**
     * Specifies the maximum height of a node. Defaults to 70.
     * Other nodes will be scaled down.
     *
     * @default 70
     */
    @Input() nodeHeight: number = DEFAULT_NODE_HEIGHT;

    /**
     * Specifies whether the node height is fixed or not.
     * If true, the node height is always equal to nodeHeight input parameter.
     * If false, node height is scaled relative to node with highest value.
     *
     * @default false
     */
    @Input() fixedNodeHeight = false;

    /**
     * Specifies whether flow can be variable, allowing for different colors. Defaults to false.
     *
     * @default false
     */
    @Input() hasVariableFlow = false;

    /**
     * Specifies whether flow has opacity, allowing for stacking colors. Defaults to false.
     *
     * @default false
     */
    @Input() hasLinkOpacity = false;

    /**
     * Specifies whether central column should be highlighted or not. Defaults to false.
     *
     * @default false
     */
    @Input() hasHighlightedCentral = false;

    /**
     * @requires
     * Specifies the input data for the chart.
     */
    @Input({ required: true }) data!: SankeyInputDatum[];

    /**
     * Specifies whether column titles are shown or not.
     *
     * @default false
     */
    @Input() showColumnTitle?: boolean;

    /**
     * Overrides default column titles height, if it is shown. Default value is 50.
     *
     * @default 50
     */
    @Input() columnTitleHeight: number = COLUMN_TITLE_HEIGHT;

    /**
     * Template allowing custom text in nodes.
     * Can be used for custom HTML manipulation of content inside the nodes.
     * Either nodeTemplate or getNodeText must be specified.
     */
    @Input() nodeTemplate?: TemplateRef<SankeyNodeTemplateContext>;

    /**
     * Template allowing custom text in columns.
     * Can be used for custom HTML manipulation of content inside the columns.
     * Either columnTitleTemplate or getColumnText must be specified.
     */
    @Input() columnTitleTemplate?: TemplateRef<SankeyColumnTemplateContext>;

    /**
     * Custom color for links. If not specified, default colors are used.
     */
    @Input() customLinkColor?: string;

    /**
     * Custom color for links if hovered. If not specified, default colors are used.
     */
    @Input() customLinkHoverColor?: string;

    /**
     * Custom color for stripes on the left of the indicator. If not specified, default colors are used.
     */
    @Input() customIndicatorColor?: string;

    /**
     * Function allowing custom text in nodes.
     * Can be used for simple text content inside the nodes.
     * Either nodeTemplate or getNodeText must be specified.
     */
    @Input() getNodeText?: (node: SankeyNode) => string;

    /**
     * Function allowing custom text in columns.
     * Can be used for simple text content inside the columns.
     * Either columnTitleTemplate or getColumnText must be specified.
     */
    @Input() getColumnText?: (column: number) => string;

    /**
     * Function allowiing custom text in link.
     * If defined, hovering over a link will also show text in the hovered link.
     * Otherwise no text is shown.
     */
    @Input() getLinkText?: (link: SankeyLink) => string;

    /**
     * Specifies if column nodes are also sorted by order parameter.
     *
     * @default false
     */
    @Input() sortNodesByOrder = false;

    /**
     * @optional
     * Specifies whether nodes are clickable. Also allows "nodeClick" event emitter.
     *
     * @default false
     */
    @Input() clickable = false;

    /**
     * Template to be used when hovering over elements.
     * If not defined, no template is shown
     */
    @Input() nodeTooltipTemplate?: TemplateRef<SankeyTooltipContext<SankeyChartNodeTooltipContext>>;

    /**
     * Template to be used when hovering over elements.
     * If not defined, no template is shown
     */
    @Input() linkTooltipTemplate?: TemplateRef<SankeyTooltipContext<SankeyChartLinkTooltipContext>>;

    /**
     * Specifies the chart id. Used for creating unique ids of nodes and links between more sankeys.
     */
    @Input() chartId = "sankey";

    /**
     * Emits whenever node is clicked. Requires clickable input to be true.
     */
    @Output() readonly nodeClick: EventEmitter<SankeyInputDatum> =
        new EventEmitter<SankeyInputDatum>();

    @ViewChild("root") _rootElementRef!: ElementRef<HTMLDivElement>;

    private _nodeTooltip?: D3TooltipApi;
    private _nodeTooltipContext$ = new BehaviorSubject<SankeyChartNodeTooltipContext | null>(null);
    private _linkTooltip?: D3TooltipApi;
    private _linkTooltipContext$ = new BehaviorSubject<SankeyChartLinkTooltipContext | null>(null);
    private _lastMouseX = 0;
    private _lastMouseY = 0;
    private _trackListener!: () => void;

    _columns: SankeyColumn[] = [];
    private _nodes = new Map<string, SankeyNode>();
    private _columnsScrollTop: Record<string, number> = {};
    private _rootElement!: HTMLDivElement;

    private _maxHeight = 0;
    private _isInitialized = false;
    private _scrolling = false;

    private _svg!: d3.Selection<any, any, any, any>;
    private _svgG!: d3.Selection<any, any, any, any>;
    private _linkCount = 1;

    @ViewChild("root", { static: true })
    private _chartWithLegendDivRef!: ElementRef<HTMLDivElement>;

    @ViewChild("chartExportContainer") chartExportContainer!: ElementRef;

    get _svgHeight(): number {
        return this.showColumnTitle ? this.height - this.columnTitleHeight : this.height;
    }

    get _columnTitleHeight(): number {
        return this.showColumnTitle ? this.columnTitleHeight : 0;
    }

    ngOnInit(): void {
        this._setDefaultProperties();
    }

    ngAfterViewInit(): void {
        this._exportContainer?.register(this);
        this._rootElement = this._rootElementRef.nativeElement;
        this._draw();
    }

    ngOnChanges(changes: LgSimpleChanges<LgSankeyChartComponent>): void {
        if (!this._isInitialized) {
            this._prepareData();
            this._isInitialized = true;
            return;
        }
        let needsRender = false;
        let needsScrollRestore = false;
        if (changes.data) {
            d3.select(this._rootElementRef.nativeElement)
                .selectAll(".lg-sankey-chart-link")
                .remove();
            this._saveScrollPositions();
            this._prepareData();
            needsRender = true;
            needsScrollRestore = true;
        }

        if (
            changes.height ||
            changes.width ||
            changes.showColumnTitle ||
            changes.nodeWidth ||
            changes.linkWidth ||
            changes.hasVariableFlow ||
            changes.hasLinkOpacity
        ) {
            needsRender = true;
        }

        if (needsRender) {
            this._draw(needsScrollRestore);
        }
    }

    ngOnDestroy(): void {
        this._exportContainer?.unregister(this);
        this._nodeTooltip?.destroy();
        this._linkTooltip?.destroy();
    }

    getHtmlElement(): HTMLElement {
        return this._chartWithLegendDivRef.nativeElement;
    }

    getSvgElement(): SVGElement {
        return this._svg.node();
    }

    private _setDefaultProperties(): void {
        if (this.nodeTooltipTemplate || this.linkTooltipTemplate) {
            this._trackMousePosition();
        }
        if (this.nodeTooltipTemplate) this._initializeNodeTooltip();
        if (this.linkTooltipTemplate) this._initializeLinkTooltip();
    }

    scrollToLeftColumn(): void {
        const firstColumn = this._rootElement.querySelector(
            ".lg-sankey-chart__wrapper__nodes__column"
        );
        if (firstColumn == null) return;
        firstColumn.scrollIntoView({ behavior: "auto", inline: "end" });
    }

    scrollToRightColumn(): void {
        const lastColumn = this._rootElement.querySelector(
            ".lg-sankey-chart__wrapper__nodes__column:last-child"
        );
        if (lastColumn == null) return;
        lastColumn.scrollIntoView({ behavior: "auto", inline: "end" });
    }

    private _prepareData(): void {
        this._nodes = new Map<string, SankeyNode>();
        this._columns = this._convertNodesToNodeInternal();
        this._remapLinks();
        this._sumLinkValues();
        this._sortByLinkValue();
        if (this.sortNodesByOrder) this._sortByOrder();
    }

    private _convertNodesToNodeInternal(): SankeyColumn[] {
        const columnsMap: SankeyColumnMap = this._getColumnsMap();
        const sortedColumns = ldSortBy(columnsMap, "column");
        const result: SankeyColumn[] = sortedColumns.map(column => {
            const columnId = column.column;
            const nodes = column.nodes.map(dataNode => {
                const nodeInternal = this._createNewInternalNode(dataNode);
                this._nodes.set(nodeInternal.id, nodeInternal);
                return nodeInternal;
            });
            return { column: columnId, nodes };
        });
        return result;
    }

    private _createNewInternalNode(dataNode: SankeyInputDatum): SankeyNode {
        return {
            id: this._getNodeId(dataNode.id),
            dataNode,
            linksLeft: [],
            linksRight: [],
            order: dataNode.order ?? undefined,
            value: this._adjustHeight(dataNode.value),
            valueLeftNegative: 0,
            valueLeftPositive: 0,
            valueLeft: 0,
            valueRightNegative: 0,
            valueRightPositive: 0,
            valueRight: 0
        };
    }

    private _getNodeId(id: number | string): string {
        return `lg-sankey-chart__node-${id}`;
    }

    private _getColumnsMap(): SankeyColumnMap {
        const result: SankeyColumnMap = {};
        this.data.forEach(node => {
            this._maxHeight = this._getMaxHeight(node);
            let column = result[node.column];

            if (column == null) {
                column = { column: node.column, nodes: [] };
                result[node.column] = column;
            }

            column.nodes.push(node);
        });
        return result;
    }

    private _getMaxHeight(node: SankeyInputDatum): number {
        let result = Math.max(this._maxHeight, Math.abs(node.value));
        if (!node.links) return result;
        node.links.forEach(link => {
            result = Math.max(this._maxHeight, result, Math.abs(link.value));
        });
        return result;
    }

    private _remapLinks(): void {
        this._nodes.forEach(node => {
            if (node.dataNode.links == null) return;
            if (this.sortNodesByOrder && node.order === undefined) {
                console.error("Node must have order parameter!");
            }
            this._addLinks(node);
        });
    }

    private _addLinks(node: SankeyNode): void {
        node.dataNode.links?.forEach(dataLink => {
            let link: SankeyLink;
            if (isLinkLeft(dataLink)) {
                const leftNode = this._nodes.get(this._getNodeId(dataLink.left))!;
                link = this._findOrAddLink(leftNode, node);
            }
            if (isLinkRight(dataLink)) {
                const rightNode = this._nodes.get(this._getNodeId(dataLink.right))!;
                link = this._findOrAddLink(node, rightNode);
            }
            link!.height = this._adjustHeight(dataLink.value);
            link!.dataValue = dataLink.value;
        });

        node.linksLeft = node.linksLeft.sort((x, y) => y.dataValue - x.dataValue);
        node.linksRight = node.linksRight.sort((x, y) => y.dataValue - x.dataValue);
    }

    private _sumLinkValues(): void {
        this._nodes.forEach(node => {
            const leftValue = this._getLinkValue(node.linksLeft, true);
            const rightValue = this._getLinkValue(node.linksRight, false);

            node.valueLeftPositive = leftValue;
            node.valueLeftNegative = 0;
            node.valueLeft = leftValue;

            node.valueRightPositive = rightValue;
            node.valueRightNegative = 0;
            node.valueRight = rightValue;
        });
    }

    private _sortByLinkValue(): void {
        this._columns.forEach(column => {
            column.nodes = column.nodes.sort((x, y) => y.value - x.value);
        });
    }

    private _sortByOrder(): void {
        this._columns.forEach(column => {
            column.nodes = column.nodes.sort((x, y) => (y.order ?? 0) - (x.order ?? 0));
        });
    }

    private _adjustHeight(height: number): number {
        if (this._maxHeight === 0) return 0;
        return (height / this._maxHeight) * this.nodeHeight;
    }

    private _getLinkValue(links: SankeyLink[], isLeft: boolean): number {
        let result = 0;
        for (const link of links) {
            const value = link.height;
            if (isLeft) link.rightOffset = result;
            else link.leftOffset = result;
            result += value;
        }
        return result;
    }

    private _findOrAddLink(left: SankeyNode, right: SankeyNode): SankeyLink {
        const link = left?.linksRight.find(i => i.right === right);
        return link ? link : this._createNewLink(left, right);
    }

    private _createNewLink(left: SankeyNode, right: SankeyNode): SankeyLink {
        const link = { left, right } as SankeyLink;
        left.linksRight.push(link);
        right.linksLeft.push(link);
        return link;
    }

    private _saveScrollPositions(): void {
        this._columnsScrollTop = {};

        if (!ldIsEmpty(this._columns)) {
            const containers = this._selectNodesContainers();
            containers.forEach((x, i) => {
                this._columnsScrollTop[this._columns[i].column] = x.scrollTop;
            });
        }
    }

    private _restoreScrollPositions(): void {
        if (!ldIsEmpty(this._columns)) {
            const containers = this._selectNodesContainers();
            containers.forEach((x, i) => {
                x.scrollTop = this._columnsScrollTop[this._columns[i].column] || 0;
            });
        }
    }

    private _selectNodesContainers(): NodeListOf<Element> {
        return this._rootElement.querySelectorAll(
            ".lg-sankey-chart-column__body .lg-scrollable__holder"
        );
    }

    _onScrollEnd(): void {
        this._draw();
    }

    private _draw(needsScrollRestore: boolean = false): void {
        this._hostElement.nativeElement.style.width = `${this.width}px`;
        this._hostElement.nativeElement.style.height = `${this.height}px`;
        this._rootElement.style.width = "100%";
        this._rootElement.style.height = "100%";
        this._updateSvg();
        this._cdRef.markForCheck();
        atNextFrame(() => {
            this._drawSvg(needsScrollRestore);
        });
    }

    private _updateSvg(): void {
        this._svg = d3.select(this._rootElement).select("svg");
        this._svg.select("g").remove();
        this._svgG = this._svg.append("g");
        this._svg.style("top", `${this._columnTitleHeight}px`);
        d3.select(".lg-sankey-chart__wrapper__nodes").style("max-height", `${this._svgHeight}px`);
    }

    private _drawSvg(needsScrollRestore: boolean): void {
        d3.select(this._rootElementRef.nativeElement)
            .selectAll(".lg-sankey-chart__wrapper__nodes__column")
            .style("margin-right", `${this.linkWidth}px`)
            .style("width", `${this.nodeWidth}px`)
            .style("min-width", `${this.nodeWidth}px`)
            .style("max-width", `${this.nodeWidth}px`);
        d3.select(this._rootElementRef.nativeElement)
            .selectAll(".lg-sankey-chart__column-title")
            .style("margin-right", `${this.linkWidth}px`)
            .style("width", `${this.nodeWidth}px`)
            .style("min-width", `${this.nodeWidth}px`)
            .style("max-width", `${this.nodeWidth}px`);

        this._drawLinks(needsScrollRestore);
        this._cdRef.markForCheck();
    }

    private _drawLinks(needsScrollRestore: boolean): void {
        this._nodes.forEach(srcNode => {
            srcNode.linksRight.forEach(link => {
                this._drawLink(link, srcNode);
            });

            this._addHoverHandlerToNode(srcNode);
        });

        const svgG = this._svg.select("g").node() as Element;
        const clientRects = svgG.getClientRects()[0];
        const width = Math.max(this.width, clientRects.right);
        const height = Math.max(this.height, clientRects.bottom);
        this._svg.attr("width", `${width}px`).attr("height", `${height}px`);

        if (needsScrollRestore) {
            this._restoreScrollPositions();
        }
    }

    private _addHoverHandlerToNode(srcNode: SankeyNode): void {
        d3.select(`#${this.chartId + srcNode.id}`)
            .on("mouseover", (_event: MouseEvent) => {
                if (this._scrolling) return;
                this._openNodeTooltip(srcNode);
                const linksToShowOnTop: SankeyLink[] = [];
                srcNode.linksLeft.forEach(link => {
                    linksToShowOnTop.push(link);
                    link.svgLink
                        ?.attr("stroke", () => this._getColor(link.height, true))
                        .attr("fill", "transparent")
                        .attr("opacity", "1");
                    this._createHoverTextNode(link);
                });
                srcNode.linksRight.forEach(link => {
                    linksToShowOnTop.push(link);
                    link.svgLink
                        ?.attr("stroke", () => this._getColor(link.height, true))
                        .attr("fill", "transparent")
                        .attr("opacity", "1");
                    this._createHoverTextNode(link);
                });
                this._sortLinks(linksToShowOnTop);
            })
            .on("mouseleave", (_event: MouseEvent) => {
                if (this._scrolling) return;
                srcNode.linksLeft.forEach(link => {
                    link.svgLink
                        ?.attr("stroke", () => this._getColor(link.height))
                        .attr("fill", "transparent")
                        .attr("opacity", `${this.hasLinkOpacity ? "0.25" : "1"}`);
                });
                srcNode.linksRight.forEach(link => {
                    link.svgLink
                        ?.attr("stroke", () => this._getColor(link.height))
                        .attr("fill", "transparent")
                        .attr("opacity", `${this.hasLinkOpacity ? "0.25" : "1"}`);
                });
                this._svgG.selectAll("text").remove();
                this._closeLinkTooltip();
                this._closeNodeTooltip();
            });
    }

    private _sortLinks(links: SankeyLink | SankeyLink[]): void {
        const linksToUpdate = ldIsArray(links) ? links : [links];
        const ids = linksToUpdate.map(link => link.svgLink?.data()[0]);
        this._svg.selectAll("path").sort(a => (ids.includes(a) ? 1 : -1));
    }

    private _drawLink(link: SankeyLink, srcNode: SankeyNode): void {
        const source: LinkProperties = this._getLinkProperties(
            this.chartId + srcNode.id,
            link.height,
            IndicatorSide.Source
        );

        const target: LinkProperties = this._getLinkProperties(
            this.chartId + link.right.id,
            link.height,
            IndicatorSide.Destination
        );

        this._createLinkPath(link);

        const strokeWidth = Math.abs(link.height);
        const halfStrokeWidth = strokeWidth / 2;

        const self = this;
        const borderRadius = 4;

        link.sourceX = source.left - borderRadius + source.element.offsetWidth;
        link.sourceY = source.top + link.leftOffset + halfStrokeWidth - this._columnTitleHeight;
        link.targetX = target.left + borderRadius;
        link.targetY = target.top + link.rightOffset + halfStrokeWidth - this._columnTitleHeight;
        const sourceCoords: [number, number] = [link.sourceX, link.sourceY];
        const targetCoords: [number, number] = [link.targetX, link.targetY];

        link.svgLink
            ?.attr(
                "d",
                d3
                    .linkHorizontal()
                    .source(() => sourceCoords)
                    .target(() => targetCoords)
            )
            .attr("stroke-width", Math.max(strokeWidth, 1))
            .attr("stroke", () => this._getColor(link.height))
            .attr("fill", "transparent")
            .attr("opacity", `${this.hasLinkOpacity ? "0.25" : "1"}`)
            .on("mouseover", function (_event: MouseEvent) {
                if (this._scrolling) return;
                self._sortLinks(link);
                d3.select(this)
                    .attr("stroke", () => self._getColor(link.height, true))
                    .attr("fill", "transparent")
                    .attr("opacity", "1");
                self._createHoverTextNode(link);
                self._openLinkTooltip(link);
            })
            .on("mouseleave", function (_event: MouseEvent) {
                if (this._scrolling) return;
                d3.select(this)
                    .attr("stroke", () => self._getColor(link.height))
                    .attr("fill", "transparent")
                    .attr("opacity", `${self.hasLinkOpacity ? "0.25" : "1"}`);
                self._svgG.selectAll("text").remove();
                self._closeLinkTooltip();
            });

        this._nodes.forEach(i => {
            const element = document.querySelector(`#${i.id}`);
            const height = element?.clientHeight;
            if (i.value !== height) return;
            d3.select(element)
                .select(".lg-sankey-chart-node__indicator__left__default")
                .style("border-radius", "4px 0 0 4px");
            d3.select(element)
                .select(".lg-sankey-chart-node__indicator__right__default")
                .style("border-radius", "0 4px 4px 0");
        });
    }

    private _getColor(linkValue: number, isHover?: boolean): string {
        if (isHover && this.customLinkHoverColor) return this.customLinkHoverColor;
        if (this.customLinkColor) return this.customLinkColor;
        if (!this.hasVariableFlow) {
            if (this.hasLinkOpacity || isHover) return COLORS.default;
            return COLORS.default_lighter;
        }
        if (this.hasLinkOpacity || isHover) {
            return linkValue > 0 ? COLORS.add : COLORS.substract;
        }
        return linkValue > 0 ? COLORS.add_lighter : COLORS.substract_lighter;
    }

    private _getLinkProperties(id: string, value: number, isSource: IndicatorSide): LinkProperties {
        const element = this._getIndicatorElement(id, isSource, value);

        let top = 0;
        let left = 0;
        let current = element;
        while (current !== this._rootElement) {
            top += current.offsetTop - current.scrollTop;
            left += current.offsetLeft - current.scrollLeft;
            current = current.offsetParent as HTMLElement;
        }

        return {
            top,
            left,
            element
        };
    }

    private _getIndicatorElement(id: string, isSource: IndicatorSide, value: number): HTMLElement {
        const documentId = `#${id}`;
        const indicatorWrapper = ".lg-sankey-chart-node__indicator";
        const indicatorPart =
            isSource === IndicatorSide.Source
                ? ".lg-sankey-chart-node__indicator__right"
                : ".lg-sankey-chart-node__indicator__left";
        const typePart = this._getTypePart(indicatorPart, value);
        const pathToElement = `${documentId} ${indicatorWrapper} ${indicatorPart} ${typePart}`;
        const element = this._rootElement.querySelector(pathToElement) as HTMLElement;
        if (this.customIndicatorColor && isSource !== IndicatorSide.Source)
            element.style.backgroundColor = this.customIndicatorColor;
        return element;
    }

    private _getTypePart(indicatorPart: string, value: number): string {
        if (!this.hasVariableFlow) return `${indicatorPart}__default`;
        return `${indicatorPart}__${value >= 0 ? "positive" : "negative"}`;
    }

    private _createLinkPath(link: SankeyLink): void {
        link.svgLink = this._svg.select("g").append("path").data([this._linkCount++]);
    }

    private _openNodeTooltip(node: SankeyNode): void {
        if (!this._nodeTooltip) return;
        this._nodeTooltipContext$.next({
            data: node
        });
        this._nodeTooltip.hideShow();
        this._updateTooltipPosition(this._nodeTooltip);
    }

    private _closeNodeTooltip(): void {
        if (this._nodeTooltip) this._nodeTooltip.scheduleHide();
    }

    private _openLinkTooltip(link: SankeyLink): void {
        if (!this._linkTooltip) return;
        this._linkTooltipContext$.next({
            data: link
        });
        this._linkTooltip.hideShow();
        this._updateTooltipPosition(this._linkTooltip);
    }

    private _closeLinkTooltip(): void {
        this._linkTooltip?.scheduleHide();
    }

    private _createHoverTextNode(link: SankeyLink): void {
        if (!this.getLinkText) return;
        this._svgG
            .append("text")
            .attr("fill", "black")
            .attr("dy", ".35em")
            .attr("text-anchor", "middle")
            .attr(
                "transform",
                `translate(${(link.sourceX + link.targetX) / 2}, ${Math.max(
                    (link.sourceY + link.targetY) / 2,
                    MIN_LINK_TEXT_Y_POSITION
                )})`
            )
            .attr("pointer-events", "none")
            .text(this.getLinkText(link));
    }

    _onNodeClick(node: SankeyNode): void {
        if (!this.clickable) return;
        this.nodeClick.emit(node.dataNode);
    }

    private _initializeNodeTooltip(): void {
        const commonOptions: ID3TooltipOptions = {
            stay: false,
            trapFocus: false,
            position: "top-left",
            tooltipClass: "lg-tooltip lg-tooltip--d3",
            panelClass: "chart-overlay",
            content: this._getNodeTooltipContent(),
            delayHide: 50,
            target: this._elementRef
        };
        this._nodeTooltip = this._tooltipService.create({
            ...commonOptions
        });
    }

    private _initializeLinkTooltip(): void {
        const commonOptions: ID3TooltipOptions = {
            stay: false,
            trapFocus: false,
            position: "top-left",
            tooltipClass: "lg-tooltip lg-tooltip--d3",
            panelClass: "chart-overlay",
            content: this._getLinkTooltipContent(),
            delayHide: 50,
            target: this._elementRef
        };
        this._linkTooltip = this._tooltipService.create({
            ...commonOptions
        });
    }

    private _getNodeTooltipContent(): TemplatePortal | undefined {
        // Note: With the code’s logic it's guaranteed the template exist here, but it’s hard to express in the typing.
        return this.nodeTooltipTemplate
            ? new TemplatePortal(this.nodeTooltipTemplate, this._viewContainerRef, {
                  $implicit: this._nodeTooltipContext$.asObservable()
              })
            : undefined;
    }

    private _getLinkTooltipContent(): TemplatePortal | undefined {
        return this.linkTooltipTemplate
            ? new TemplatePortal(this.linkTooltipTemplate, this._viewContainerRef, {
                  $implicit: this._linkTooltipContext$.asObservable()
              })
            : undefined;
    }

    private _trackMousePosition(): void {
        this._ngZone.runOutsideAngular(() => {
            this._trackListener = this._renderer.listen(
                this._elementRef.nativeElement,
                "mousemove",
                (event: MouseEvent) => {
                    this._lastMouseX = event.clientX;
                    this._lastMouseY = event.clientY;
                    this._updateTooltipPosition(this._nodeTooltip!);
                    this._updateTooltipPosition(this._linkTooltip!);
                }
            );
        });
    }

    private _updateTooltipPosition(tooltip: D3TooltipApi): void {
        if (tooltip && tooltip.visible) {
            tooltip.setPositionAt(
                this._lastMouseX,
                this._lastMouseY,
                getRecommendedPosition(
                    { x: this._lastMouseX, y: this._lastMouseY },
                    tooltip.getOverlayElement()
                )
            );
        }
    }
}
