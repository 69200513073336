import { TemplateRef } from "@angular/core";
import type { RowContext } from "./row-context";

export interface IRowTemplateBase {
    level: number | string;
    template: TemplateRef<RowContext<any, any, any>>;
}

export class RowTemplateCollection<
    T extends IRowTemplateBase,
    CreateDefault extends boolean = true
> {
    public constructor(
        private _levelMapper: (level: number | string) => number,
        private _onChanged: (template: T) => void,
        private _createDefault: CreateDefault extends true ? (level: number) => T : null
    ) {
        // empty
    }

    public add(template: T): void {
        const depth = this._levelMapper(template.level);
        this._templates[depth] = template;
        this._onChanged(template);
    }

    public move(template: T, oldLevel: number | string): void {
        const oldDepth = this._levelMapper(oldLevel);
        if (this._templates[oldDepth] === template) {
            this._templates[oldDepth] = undefined;
        }
        this.add(template);
    }

    public change(template: T): void {
        this._onChanged(template);
    }

    public remove(template: T): void {
        const depth = this._levelMapper(template.level);
        if (this._templates[depth] === template) {
            this._templates[depth] = undefined;
            this._onChanged(template);
        }
    }

    public get(depth: number): CreateDefault extends true ? T : T | undefined;
    public get(depth: number): T | undefined {
        let result = this._templates[depth];
        if (!result && this._createDefault) {
            result = this._createDefault(depth);
            if (result) this._templates[depth] = result;
        }
        return result;
    }

    public contains(depth: number): boolean {
        return this._templates[depth] !== undefined;
    }

    private _templates: Array<T | undefined> = [];
}
