/* eslint-disable @typescript-eslint/no-unsafe-function-type */

import { InjectionToken } from "@angular/core";
import { Observable } from "rxjs";

/**
 * Injection token for the message bus service.
 */
export const LG_MESSAGE_BUS_SERVICE = new InjectionToken<IMessageBusService>(
    "LG_MESSAGE_BUS_SERVICE"
);

/**
 * Interface representing a message bus service.
 */
export interface IMessageBusService {
    /**
     * Gets the connection ID.
     *
     * @returns The connection ID or null if not connected.
     */
    getConnectionId(): string | null;

    /**
     * Subscribes to a message.
     *
     * @param message The message to subscribe to.
     * @param cb The callback to invoke when the message is received.
     * @returns A subscription ticket.
     */
    on(message: string, cb: Function): ISubscriptionTicket;

    /**
     * @deprecated Use `on` instead
     * Subscribes to a connection event.
     * @param eventName The name of the event.
     * @param cb The callback to invoke when the event occurs.
     * @param scope Optional scope for the callback.
     * @returns A subscription ticket.
     */
    onConnectionEvent(eventName: string, cb: Function, scope?: any): ISubscriptionTicket;

    /**
     * Observable that emits a boolean value indicating whether the connection is established.
     *
     * **New tools using LgMessageBusService and .NET Core backend:**
     * Connection should be established during the app bootstrap so consumers shouldn't need to care about this and assume everything is already set up - i.e. ignore this.
     *
     * **Old tools using LgMessageBusOldService and .NET Framework backend**:
     * NOTE: LgMessageBusOldService is removed from Framework.
     * The message bus connection is being established lazily with the first use
     * and in order for it to work correctly the cookie needs to be set before your request is made.
     * So unless you're sure that the connection is already established do something similar:
     * `bus.connectionEstablished$.pipe(first()).subscribe(() => this.http.post())`
     */
    connectionEstablished$: Observable<boolean>;
}

export interface ISubscriptionTicket {
    cancel(): void;
}
