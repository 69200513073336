import ldPadEnd from "lodash-es/padEnd";
import { Injectable, inject } from "@angular/core";

import { LgTranslateService } from "../translate/lg-translate.service";
import { LgDate } from "./lg-date";

@Injectable({ providedIn: "root" })
export class LgDateService {
    private _lgTranslate = inject(LgTranslateService);

    private _isoDateRegex =
        /^(\d{4}|[+-]\d{6})(?:-(\d{2})(?:-(\d{2}))?)?(?:T(\d{2}):(\d{2})(?::(\d{2})(?:\.(\d{1,}))?)?(?:(Z)|([+-])(\d{2})(?::(\d{2}))?)?)?$/;

    private _defaultDate = new LgDate("yyyy-MM-dd");

    getLocaleDate(): LgDate {
        const format = this._lgTranslate.translate("FW.DATE_FORMAT");
        if (!format || format === "FW.DATE_FORMAT") {
            console.error("Locale date format not found, using default");
            return this._defaultDate;
        }
        return new LgDate(format);
    }

    get defaultDate(): LgDate {
        return this._defaultDate;
    }

    getDefaultDate(): LgDate {
        return this._defaultDate;
    }

    daysBetween(startDate: Date, endDate: Date): number {
        return this._defaultDate.daysBetween(startDate, endDate);
    }

    parseIsoDate(isoDate: string): Date {
        function prs(str: string, def = 0): number {
            return parseInt(str) || def;
        }

        const m = this._isoDateRegex.exec(isoDate);

        if (!m) {
            throw new Error("Specified date is not in ISO format");
        }

        // avoid NaN timestamps caused by “undefined” values being passed to Date.UTC
        let minutesOffset = 0;
        if (m[8] !== "Z" && m[9] !== undefined) {
            minutesOffset = prs(m[10]) * 60 + prs(m[11]);

            if (m[9] === "+") {
                minutesOffset = 0 - minutesOffset;
            }
        }

        return new Date(
            Date.UTC(
                prs(m[1]),
                prs(m[2], 1) - 1,
                prs(m[3], 1),
                prs(m[4]),
                prs(m[5]) + minutesOffset,
                prs(m[6]),
                prs(ldPadEnd(m[7], 3, "0"))
            )
        );
    }

    // ----------------------------------------------------------------------------------
    treatAsUTC(date: Date): Date {
        return this._defaultDate.treatAsUTC(date);
    }
}
