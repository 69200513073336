<ng-template #panelTemplate>
    <div
        class="{{ panelClass }} lg-panel__body-holder"
        [hidden]="_context.minimized"
        [lgLoaderOverlay]="!!(_showLoaderOverlay$ | async) && !_context.minimized"
        [lgLoaderOverlayDelay]="loaderOverlayDelay"
        [lgLoaderOverlaySkip]="loaderOverlaySkip"
    >
        <ng-container *ngIf="!_context.minimized">
            <div
                class="lg-panel__body"
                *ngIf="!_panelBody; else panelBodyBlock"
                lgDetachmentNotifier
            >
                <ng-content></ng-content>
            </div>
            <ng-template #panelBodyBlock>
                <div class="lg-panel__body">
                    <ng-container
                        *ngTemplateOutlet="_panelBody!._bodyTemplate ?? null; context: _context"
                    ></ng-container>
                </div>
            </ng-template>
        </ng-container>
    </div>
</ng-template>
