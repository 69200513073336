import { isDevMode } from "@angular/core";
import { MonoTypeOperatorFunction } from "rxjs";
import { tap } from "rxjs/operators";

export function debugObservable<T>(message: string): MonoTypeOperatorFunction<T> {
    if (!isDevMode) {
        return o => o;
    }

    return tap(
        next => {
            console.log(message, next);
        },
        err => {
            console.error(message, err);
        },
        () => {
            console.info(message, "completed");
        }
    );
}
