import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnChanges,
    OnInit,
    OnDestroy,
    inject,
    ChangeDetectorRef
} from "@angular/core";
import { interval, Subscription } from "rxjs";
import { LgSimpleChanges } from "@logex/framework/types";
import { coerceNumberProperty } from "@angular/cdk/coercion";

// ----------------------------------------------------------------------------------
//
@Component({
    standalone: true,
    selector: "lg-progress-bar",
    template: `
        <div class="lg-progress-bar">
            <div class="lg-progress-bar__holder">
                <div
                    class="lg-progress-bar__bar"
                    [style.width.%]="_width"
                    [class.lg-progress-bar__bar--completed]="completed"
                ></div>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LgProgressBarComponent implements OnInit, OnChanges, OnDestroy {
    private _changeDetectorRef = inject(ChangeDetectorRef);
    // use 'current'+'total' for simple scenarios when 'progress = current / total'
    /**
     * Current progress.
     * Use combination of `current` and `total` inputs for simple scenarios (when `progress = current / total`).
     */
    @Input() current?: number | undefined;

    /**
     * Total progress value.
     * Use combination of `current` and `total` inputs for simple scenarios (when `progress = current / total`).
     */
    @Input() total?: number | undefined;

    /**
     * Progress is completed.
     */
    @Input() completed = false;

    // use progress otherwise - see e.g. lg-excel-export-dialog.component.ts usage
    /**
     * Progress value.
     * Use `progress` input instead of combination of `current` and `total` for more complex scenarios.
     */
    @Input() set progress(value: number) {
        this._setWidth(value * 100);
        this._hasProgress = true;
    }

    get progress(): number {
        return this._width / 100;
    }

    _width = 0;

    private _ticket: Subscription | null = null;
    private _hasProgress = false;

    ngOnInit(): void {
        if (this._hasProgress) return;

        if (this.current === undefined || !this.total) {
            this._fakeProgress();
        } else {
            this._setWidth(this._getCalculatedWidth());
        }
    }

    private _fakeProgress(): void {
        this.current = 0;
        this.total = 100;
        let step = 23;
        let ratio = 0.954;
        this._ticket = interval(1000).subscribe(() => {
            this.current! += step;
            step = step * ratio;
            ratio = ratio * ratio;
            this._setWidth(this._getCalculatedWidth());
            this._changeDetectorRef.markForCheck();
        });
    }

    ngOnChanges(changes: LgSimpleChanges<LgProgressBarComponent>): void {
        let setWidthRequired = false;

        if (changes.current) {
            this.current = coerceNumberProperty(this.current, 0);
            setWidthRequired = true;
        }

        if (changes.current) {
            this.total = coerceNumberProperty(this.total, 1);
            setWidthRequired = true;
        }

        if (setWidthRequired) this._setWidth(this._getCalculatedWidth());
    }

    ngOnDestroy(): void {
        if (this._ticket) this._ticket.unsubscribe();
    }

    private _setWidth(value: number): void {
        this._width = value;
    }

    private _getCalculatedWidth(): number {
        return this.current && this.total
            ? Math.min(100, Math.round((100 * this.current) / this.total))
            : 100;
    }
}
