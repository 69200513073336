import { Provider } from "@angular/core";
import { lgProvide, LgProvider, LgProviders } from "@logex/framework/types";
import {
    ILgErrorHandlerGateway,
    LgConsoleConfiguration,
    LgErrorHandlerGateway
} from "../debugging";
import {
    ILgFormatterFactory,
    ILgFormatterOptions,
    IntegerFormatterFactory,
    LG_FORMATTER_FACTORIES,
    LG_FORMATTER_OPTIONS,
    LgFormatterFactoryService,
    MoneyFormatterFactory,
    MoneyScaledFormatterFactory,
    NoFormatterFactory,
    NumberFormatterFactory,
    PercentageFormatterFactory
} from "../formatters";
import { ApplicationEventTracerHub, LG_APPLICATION_EVENT_TRACER } from "../tracing";

export interface CoreConfig {
    /**
     * Provides console configuration (class `LgConsoleConfiguration`).
     * Optional. `LgConsoleConfiguration` is used by default in `CoreModule`.
     */
    console?: LgProvider<LgConsoleConfiguration>;
    /**
     * Provides error handler gateway.
     * Optional
     */
    errorHandlerGateway?: LgProvider<ILgErrorHandlerGateway>;
    /**
     * Provides event tracer (token `LG_APPLICATION_EVENT_TRACER`).
     * Optional. `ApplicationEventTracerHub` is used by default in `CoreModule`.
     */
    eventTracer?: LgProvider<ApplicationEventTracerHub>;
    /**
     * Provides formatters' factories (tokens `LG_FORMATTER_FACTORIES`).
     * Optional.
     * The following formatters are used by default in `CoreModule`:
     *  - `MoneyFormatterFactory`
     *  - `MoneyScaledFormatterFactory`
     *  - `NoFormatterFactory`
     *  - `PercentageFormatterFactory`
     *  - `NumberFormatterFactory`
     *  - `IntegerFormatterFactory`
     */
    formatterFactories?: Array<LgProvider<ILgFormatterFactory>>;
    /**
     * Provides formatter options (token `LG_FORMATTER_OPTIONS`).
     * Optional. No default value.
     */
    formatterOptions?: LgProvider<ILgFormatterOptions>;
}

/**
 * Helper utility to configure and provide framework `CoreModule` providers
 */
export const coreProviders: LgProviders<CoreConfig> = (config?: CoreConfig): Provider[] => {
    return [
        provideConsole(config?.console),
        provideErrorHandlerGateway(config?.errorHandlerGateway),
        provideEventTracer(config?.eventTracer),
        ...provideFormatters(config)
    ];
};

export const provideConsole = (console?: LgProvider<LgConsoleConfiguration>): Provider => {
    return lgProvide(LgConsoleConfiguration, console ?? { useValue: new LgConsoleConfiguration() });
};

export const provideErrorHandlerGateway = (
    gateway?: LgProvider<ILgErrorHandlerGateway>
): Provider => {
    return lgProvide(LgErrorHandlerGateway, gateway ?? { useExisting: LgErrorHandlerGateway });
};

export const provideEventTracer = (tracer?: LgProvider<ApplicationEventTracerHub>): Provider => {
    return lgProvide(
        LG_APPLICATION_EVENT_TRACER,
        tracer ?? { useExisting: ApplicationEventTracerHub }
    );
};

/**
 * Helper utility to configure and provide formatters (factories and options)
 */
export const provideFormatters = (
    config?: Pick<CoreConfig, "formatterFactories" | "formatterOptions">
): Provider[] => {
    const providers: Provider[] = [];
    if (config?.formatterOptions) {
        providers.push(provideFormatterOptions(config.formatterOptions));
    }
    providers.push(
        MoneyFormatterFactory,
        MoneyScaledFormatterFactory,
        PercentageFormatterFactory,
        NumberFormatterFactory,
        IntegerFormatterFactory,
        { provide: LG_FORMATTER_FACTORIES, useExisting: MoneyFormatterFactory, multi: true },
        { provide: LG_FORMATTER_FACTORIES, useExisting: MoneyScaledFormatterFactory, multi: true },
        { provide: LG_FORMATTER_FACTORIES, useExisting: NoFormatterFactory, multi: true },
        { provide: LG_FORMATTER_FACTORIES, useExisting: PercentageFormatterFactory, multi: true },
        { provide: LG_FORMATTER_FACTORIES, useExisting: NumberFormatterFactory, multi: true },
        { provide: LG_FORMATTER_FACTORIES, useExisting: IntegerFormatterFactory, multi: true }
    );
    if (config?.formatterFactories) {
        providers.push(provideFormatterFactories(config.formatterFactories));
    }
    providers.push(LgFormatterFactoryService);

    return providers;
};

/**
 * Helper utility to provide formatter factories
 */
export const provideFormatterFactories = (
    factories: Array<LgProvider<ILgFormatterFactory>>
): Provider[] => {
    return factories.map(factory => ({
        provide: LG_FORMATTER_FACTORIES,
        ...factory,
        multi: true
    }));
};

/**
 * Helper utility to provide formatter options
 */
export const provideFormatterOptions = (options: LgProvider<ILgFormatterOptions>): Provider => {
    return lgProvide(LG_FORMATTER_OPTIONS, options);
};
