import { Injectable, inject } from "@angular/core";
import { LG_CURRENCY_CODE } from "@logex/framework/core";
import { IStringLookup } from "@logex/framework/types";

import { LgFrameworkIcons } from "./lg-framework-icons";

let iconMap: IStringLookup<string>;

@Injectable({ providedIn: "root" })
export class LgIconRemappingService {
    constructor() {
        const currencyCode = inject(LG_CURRENCY_CODE);

        if (iconMap) return;

        const currencyIcon = this._getCurrencyIcon(currencyCode);

        iconMap = {
            "icon-money": currencyIcon,
            "icon-pricefigures": currencyIcon
        };
    }

    remap(virtualIcon: string): string {
        return iconMap[virtualIcon] || virtualIcon;
    }

    private _getCurrencyIcon(currency: string): string {
        const currencyIcon = values(LgFrameworkIcons).find(
            icon => icon.toLowerCase() === `icon-money-${currency.toLowerCase()}`
        );

        if (!currencyIcon) return "icon-money-universal";

        const theKey = Object.keys(LgFrameworkIcons).find(
            key => LgFrameworkIcons[key as keyof typeof LgFrameworkIcons] === currencyIcon
        );

        return LgFrameworkIcons[theKey as keyof typeof LgFrameworkIcons];
    }
}

function values(obj: object): any[] {
    return Object.keys(obj).map(k => obj[k as keyof typeof obj]);
}
