import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    forwardRef,
    HostBinding,
    HostListener,
    inject,
    Input,
    OnChanges,
    OnInit
} from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { ENTER, ESCAPE, LEFT_ARROW, RIGHT_ARROW } from "@angular/cdk/keycodes";

import ldMax from "lodash-es/max";
import ldSum from "lodash-es/sum";

import { LgPrimitive, LgSimpleChanges } from "@logex/framework/types";
import { LgTranslateService } from "@logex/framework/lg-localization";

import { ValueAccessorBase } from "../inputs";
import { LgSwitchMode, LgSwitchOption, ModSwitchOption, SwitchOption } from "./lg-switch.types";
import { NgClass, NgForOf } from "@angular/common";
import { ModSwitchSliderComponent } from "./mod-switch-slider.component";

const DEFAULT_BINARY_OPTIONS: SwitchOption[] = [
    { value: true, labelLc: ".Yes" },
    { value: false, labelLc: ".No" }
];

// TODO: consider replacing isBinary with 2 flags: allowEmpty, clickForNext
@Component({
    standalone: true,
    selector: "lg-switch",
    templateUrl: "./lg-switch.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => LgSwitchComponent),
            multi: true
        }
    ],
    imports: [NgForOf, NgClass, ModSwitchSliderComponent],
    host: {
        "[class]": "'lg-switch ' + class",
        "[class.lg-switch--condensed]": "condensed",
        "[class.lg-switch--disabled]": "disabled",
        "[class.lg-switch--negative]": "mode === 'negative'",
        "[class.lg-switch--positive]": "mode === 'positive'",
        "[class.lg-switch--binary]": "_options && _options.length === 2"
    }
})
export class LgSwitchComponent
    extends ValueAccessorBase<LgPrimitive>
    implements OnInit, OnChanges, AfterViewInit
{
    private _translateService = inject(LgTranslateService);

    /**
     * Switch options.
     */
    @Input() options: LgSwitchOption[] | null = null;

    /**
     * Custom translation namespace
     */
    @Input() translationNamespace = "";

    @Input() isBinary = true;

    /**
     * Reduces the size of the switch component if `true`.
     *
     * @default true
     */
    @Input() condensed = true;

    @Input() disabled = false;

    /**
     * Switch mode (color theme).
     */
    @Input() mode: LgSwitchMode = "neutral";

    /**
     * Auto select first option if no value.
     */
    @Input() autoSelectFirstIfNoValue = true;

    /**
     * Makes all options the same width.
     */
    @Input() sameWidthOptions = true;

    /**
     * Apply css class to host
     */
    @Input() class = "";

    @HostListener("focus") _onFocus(): void {
        super.touch();
    }

    @HostListener("keydown", ["$event.keyCode"]) _onKeydown(keyCode: number): void {
        if (this.disabled) return undefined;

        switch (true) {
            case keyCode === ENTER:
                return this._onEnter();
            case keyCode === LEFT_ARROW:
                return this._onLeftArrow();
            case keyCode === RIGHT_ARROW:
                return this._onRightArrow();
            case keyCode === ESCAPE:
                return this._onEscape();
        }
        return undefined;
    }

    @HostBinding("attr.tabindex") get _tabIndex(): number {
        return this.disabled ? -1 : 0;
    }

    _options: ModSwitchOption[] = [];
    _lastSliderCommand = "";
    _sliderLeftPosition = 0;
    _sliderWidth = 0;
    _highlightIndex = -1;
    _hoveredIndex = -1;

    private _initialized = false;
    private _viewInitialized = false;
    private _usingDefaultOptions = false;
    private _defaultTranslationNamespace = "FW._Directives._SwitchComponent";
    private _optionsWidths: number[] = [];
    private _previousValue?: LgPrimitive;
    private _currentValueIndex = -1;
    private _writeCalledWithInitNullShit = false;
    private _valueSetForTheFirstTime = true;

    constructor() {
        super(true);
    }

    ngOnInit(): void {
        if (!this._initialized) {
            this._init();
        }
    }

    ngAfterViewInit(): void {
        this._viewInitialized = true;
        this._updateWidths();
        this._udpateSlider();
    }

    ngOnChanges(changes: LgSimpleChanges<LgSwitchComponent>): void {
        if (!this._initialized) {
            this._init();
            return;
        }

        if (changes.options || (changes.isBinary && this._usingDefaultOptions)) {
            this._processOptions();
            this._tryPreselectValue();
        }

        // Option elements are recreated in DOM,
        // so we need to work with the new elements and not the old ones
        // this could be resolved by using `trackBy` in `*ngFor`
        // however we would still need to cover the case when number of options change
        // + remove the explicitly set `width` to get relevant results when measuring
        Promise.resolve().then(() => {
            this._updateWidths();
            this._udpateSlider();
        });
    }

    _click({ value }: ModSwitchOption): void {
        if (this.disabled) return;
        let nextVal: LgPrimitive | null = value;
        if (this.isBinary || this._options.length === 2) {
            // without any current selection, use the actual click, otherwise choose next
            nextVal = this.value == null ? value : this._getNextValue();
        } else {
            nextVal = value === this.value ? null : value;
        }
        this._updateValue(nextVal);
        this._changeDetectorRef.markForCheck();
    }

    writeValue(value: LgPrimitive): void {
        if (value === null && !this._writeCalledWithInitNullShit) {
            super._writeValue(value);
            this._writeCalledWithInitNullShit = true;
            return;
        }

        this._updateValue(value);
    }

    _updateValue(value: LgPrimitive | null): void {
        if (this._valueSetForTheFirstTime && value == null && this.autoSelectFirstIfNoValue) {
            this._tryPreselectValue();
        } else {
            this._previousValue = this.value;
            this.value = value!;
        }

        if (this._valueSetForTheFirstTime) this._valueSetForTheFirstTime = false;

        this._currentValueIndex = this._options.findIndex(o => o.value === this.value);
        this._highlightIndex = this._currentValueIndex;
        if (this._viewInitialized) {
            this._udpateSlider();
        }
    }

    _onOptionMouseover(index: number): void {
        this._hoveredIndex = index;
    }

    _onOptionMouseout(index: number): void {
        if (this._hoveredIndex === index) {
            this._hoveredIndex = -1;
        }
    }

    private _init(): void {
        this._processOptions();
        this._initialized = true;
    }

    private _onEnter(): void {
        if (this._highlightIndex === this._currentValueIndex) return;

        this._updateValue(this._getValueAtIndex(this._highlightIndex));
    }

    private _onLeftArrow(): void {
        this._highlightIndex = this._getPreviousIndex(this._highlightIndex);
    }

    private _onRightArrow(): void {
        this._highlightIndex = this._getNextIndex(this._highlightIndex);
    }

    private _onEscape(): void {
        this._highlightIndex = this._currentValueIndex;
    }

    private _getValueAtIndex(index: number): LgPrimitive {
        return this._options[index].value;
    }

    private _getNextValue(): LgPrimitive {
        return this._options[this._getNextIndex(this._currentValueIndex)].value;
    }

    private _getNextIndex(index: number): number {
        return this._clampIndex(index + 1);
    }

    private _getPreviousIndex(index: number): number {
        return this._clampIndex(index - 1);
    }

    private _clampIndex(index: number): number {
        if (index === -1) return this._options.length - 1;

        return index % this._options.length;
    }

    private _processOptions(): void {
        this._usingDefaultOptions = !this.options || !this.options.length;
        this._options = this._definitionToOptions(this.options || DEFAULT_BINARY_OPTIONS);
    }

    private _tryPreselectValue(): void {
        if (
            this.autoSelectFirstIfNoValue &&
            (this.value == null || !this._options.find(o => o.value === this.value))
        ) {
            this._updateValue(this._options[0].value);
        }
    }

    private _definitionToOptions(options: LgSwitchOption[]): ModSwitchOption[] {
        // TODO:
        // - support properly localization of string-based options (add `.`)
        // - support absolute labelLC when translationNamespace is set
        if (isStringArr(options)) {
            return options.map((o, i) => {
                const label = this.translationNamespace
                    ? this._translateService.translate(this.translationNamespace + "." + o)
                    : o;

                return {
                    value: i,
                    label
                };
            });
        }

        // casting shouldn't be necessary(?), but even if below code is inside `else`
        // branch of the above `if` it currently is (TS v3.5.3)
        return (options as SwitchOption[]).map(({ value, label, labelLc }) => {
            return { value, label: this._getLabel(label, labelLc) };
        });
    }

    private _getLabel(label: string | undefined, labelLc: string | undefined): string {
        if (label) return label;

        if (this._usingDefaultOptions) {
            return this._translateService.translate(this._defaultTranslationNamespace + labelLc);
        }
        if (labelLc === undefined) return "";
        return this._translateService.translate(
            labelLc.startsWith(".") ? this.translationNamespace + labelLc : labelLc
        );
    }

    private _updateWidths(): void {
        const elements = this._getOptionElementRefs();
        this._removeAnyExplicitWidth(elements);
        if (this.sameWidthOptions) {
            this._makeOptionsSameWidth(elements);
        }
        this._setOptionsWidths();
    }

    private _getOptionElementRefs(): HTMLElement[] {
        return [
            ...Array.from<HTMLElement>(
                this._elementRef.nativeElement.getElementsByClassName("lg-switch__options__option")
            ),
            ...Array.from<HTMLElement>(
                this._elementRef.nativeElement.getElementsByClassName("lg-switch__labels__label")
            )
        ];
    }

    private _removeAnyExplicitWidth(elements: HTMLElement[]): void {
        elements.forEach(el => this._renderer.removeStyle(el, "width"));
    }

    private _makeOptionsSameWidth(elements: HTMLElement[]): void {
        const maxWidth = ldMax(elements.map((el: HTMLElement) => el.clientWidth));

        elements.forEach(el => this._renderer.setStyle(el, "width", `${maxWidth}px`));
    }

    private _setOptionsWidths(): void {
        const elements = this._elementRef.nativeElement.getElementsByClassName(
            "lg-switch__options__option"
        );
        this._optionsWidths = Array.prototype.slice
            .call(elements)
            .map((el: HTMLElement) => el.clientWidth);
    }

    private _udpateSlider(): void {
        const command = this._getSliderCommand();

        // if hiding then leave the `left` and `width` as they are
        if (command !== "hide") {
            // no option selected
            if (this._currentValueIndex === -1 || this._currentValueIndex == null) {
                this._sliderLeftPosition = 0;
                this._sliderWidth = 0;
            } else {
                // add 1px for border between options
                this._sliderLeftPosition =
                    ldSum(this._optionsWidths.filter((_, i) => i < this._currentValueIndex)) +
                    1 * this._currentValueIndex;
                this._sliderWidth = this._optionsWidths[this._currentValueIndex];
            }
        }

        if (this._viewInitialized) {
            this._lastSliderCommand = command;
        }

        // to adjust the slider width when `@Input sameWidthOptions` changes
        this._changeDetectorRef.detectChanges();
    }

    private _getSliderCommand(): string {
        if (someValue(this._previousValue) && !someValue(this.value)) {
            return "hide";
        }
        if (someValue(this._previousValue) && someValue(this.value)) {
            return `slide${this._currentValueIndex}`;
        }
        if (!someValue(this._previousValue) && !someValue(this.value)) {
            return "initHidden";
        }
        if (!someValue(this._previousValue) && someValue(this.value)) {
            return "appear";
        }
        return "initVisible";
    }
}

function someValue(val?: LgPrimitive): boolean {
    return val != null && val !== "";
}

function isStringArr(options: LgSwitchOption[]): options is string[] {
    return typeof options[0] === "string";
}
