import { IParseResult, ILgFormatter } from "./formatter-types";

export class NoFormatter implements ILgFormatter<any> {
    names = ["none", "text"];
    options = {};

    parse(val: string): IParseResult<any> {
        return { isValid: true, result: val };
    }

    format(val: any): string {
        return "" + val;
    }

    formatForEditing(val: any): string {
        return "" + val;
    }
}
