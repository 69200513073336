import { Injectable, OnDestroy, inject } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { Subscription } from "rxjs";

import { atNextFrame } from "@logex/framework/utilities";
import {
    ApplicationTraceSeverity,
    IApplicationEventTracer,
    LgApplicationEventTracerNames
} from "@logex/framework/core";

import { IAppInfo, LG_APP_INFO } from "../application/app-info";
import { IUser, LG_USER_INFO } from "../user/user.types";

declare let ga: any;

@Injectable({ providedIn: "root" })
export class LgGaTrackingService implements OnDestroy, IApplicationEventTracer {
    private _appInfo: IAppInfo | null = inject(LG_APP_INFO, { optional: true });
    private _router: Router = inject(Router);
    private _user: IUser | null = inject(LG_USER_INFO, { optional: true });

    public readonly tracerName = LgApplicationEventTracerNames.GoogleAnalytics;

    public constructor() {
        this._subscription = this._router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this._init();
                this.pageChange();
            }
        });
    }

    // ----------------------------------------------------------------------------------
    //
    public ngOnDestroy(): void {
        this._subscription.unsubscribe();
    }

    // ----------------------------------------------------------------------------------
    //
    private readonly GA_IDENTIFIER = "UA-72668733-1";
    private _isInited = false;
    private _subscription: Subscription;

    // ----------------------------------------------------------------------------------
    //
    private _init(): void {
        if (
            !this._appInfo ||
            this._appInfo.doNotDoGaTracking() ||
            !this._appInfo.isProduction() ||
            this._isInited
        ) {
            return;
        }

        this._isInited = true;

        const gaNewElem: any = {};
        const gaElems: any = {};

        ((i: any, s, o, g, r, a, m) => {
            // eslint-disable-next-line @typescript-eslint/dot-notation
            i["GoogleAnalyticsObject"] = r;
            i[r] =
                i[r] ||
                function () {
                    // eslint-disable-next-line prefer-rest-params
                    (i[r].q = i[r].q || []).push(arguments);
                };
            i[r].l = 1 * (new Date() as any);
            a = s.createElement(o);
            m = s.getElementsByTagName(o)[0];
            a.async = 1;
            a.src = g;
            m.parentNode.insertBefore(a, m);
        })(
            window,
            document,
            "script",
            "https://www.google-analytics.com/analytics.js",
            "ga",
            gaNewElem,
            gaElems
        );

        ga("create", this.GA_IDENTIFIER, "auto");
        ga("set", "dimension1", this._user && this._user.ziekenhuiscode);
        ga("set", "dimension2", this._appInfo.productId);
        ga("set", "dimension3", this._appInfo.toolInstanceName);
        ga("set", "dimension4", this._appInfo.versionNumber);
    }

    // ----------------------------------------------------------------------------------
    //
    public pageChange(): void {
        if (!this._isInited) {
            return;
        }

        atNextFrame(() => {
            ga(
                "set",
                "page",
                window.location.pathname + window.location.search + window.location.hash
            );
            ga("send", "pageview");
        });
    }

    // ----------------------------------------------------------------------------------
    //
    public trackEvent(category: string, action: string, label?: string, value?: number): void {
        if (!this._isInited) {
            return;
        }

        atNextFrame(() => {
            ga("send", "event", category, action, label, value);
        });
    }

    // ----------------------------------------------------------------------------------
    //
    public trackTime(category: string, variable: string, value: number, label?: string): void {
        if (!this._isInited) {
            return;
        }

        atNextFrame(() => {
            ga("send", "timing", category, variable, value, label);
        });
    }

    // ----------------------------------------------------------------------------------
    //
    public trackTrace(
        severity: ApplicationTraceSeverity,
        message: string,
        _customProperties?: unknown
    ): void {
        this.trackEvent("Track", "Trace", `${ApplicationTraceSeverity[severity]}: ${message}`);
    }

    // ----------------------------------------------------------------------------------
    //
    public trackException(exception: Error, _customProperties?: unknown): void {
        this.trackEvent("Track", "Exception", `${exception.name}: ${exception.message}`);
    }
}
