import { inject, Injectable } from "@angular/core";
import { VersionService } from "../version/version-service";
import { LgVersionModel } from "../version/lg-version.model";

@Injectable({ providedIn: "root" })
export class LgAppInitializerService {
    private _versionService = inject(VersionService<any>);

    initialize(): Promise<void> {
        this._versionService.initialize();
        return new Promise(resolve => {
            this._versionService.version.subscribe(v => {
                LgVersionModel.version = v;
                resolve();
            });
        });
    }
}

export function appInitFactory(s: LgAppInitializerService): () => Promise<void> {
    return () => s.initialize();
}
