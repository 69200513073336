import { coerceNumberProperty } from "@angular/cdk/coercion";
import { HostBinding, SimpleChanges, Directive, inject } from "@angular/core";
import {
    ILgFormatter,
    ILgFormatterOptions,
    PercentageFormatterFactory
} from "@logex/framework/core";
import { isAnyPropInSimpleChanges, toFixedFix } from "@logex/framework/utilities";

import ldClamp from "lodash-es/clamp";

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class BigNumberDisplayBase {
    protected _formatterFactory = inject(PercentageFormatterFactory);

    @HostBinding("title") title = "";
    @HostBinding("innerText") text = "";

    abstract inputValue?: string | null;
    abstract decimals?: number;
    abstract min?: number;
    abstract max?: number;

    private _initialized!: boolean;
    private _formatter!: ILgFormatter<number>;

    protected _onInit(): void {
        this._initialized = false;

        this._defaultProps();
        this._initializeFormatter();

        this._mapInputToHostState();

        this._initialized = true;
    }

    protected _onChanges(changes: SimpleChanges): void {
        if (
            !this._initialized ||
            !isAnyPropInSimpleChanges(["inputValue", "decimals", "min", "max"], changes)
        ) {
            return;
        }

        this._defaultProps();

        this._mapInputToHostState();
    }

    protected abstract _defaultProps(): void;

    protected abstract _getFormatterOptions(): ILgFormatterOptions;

    private _initializeFormatter(): void {
        this._formatter = this._formatterFactory.create(this._getFormatterOptions());
    }

    private _mapInputToHostState(): void {
        if (this.inputValue == null) {
            this._setDefaultHostState();
            return;
        }

        const input = coerceNumberProperty(this.inputValue);

        if (toFixedFix(input, this.decimals) === 0) {
            this._setDefaultHostState();
            return;
        }

        const title = this._formatter.format(input, { decimals: this.decimals });

        this._setHostState(this._getText(input, title), title);
    }

    private _setDefaultHostState(): void {
        this.text = "-";
        this.title = "-";
    }

    private _setHostState(text: string, title: string): void {
        this.text = text;
        this.title = title;
    }

    private _getText(input: number, title: string): string {
        if (
            this.min !== undefined &&
            this.max !== undefined &&
            (input < this.min || input > this.max)
        ) {
            return (
                (input > this.max ? ">" : "<") +
                this._formatter.format(ldClamp(input, this.min, this.max), {
                    decimals: this.decimals
                })
            );
        } else return title;
    }
}
