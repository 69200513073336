/**
 * @deprecated
 * Use native DOM CustomEvent() constructor instead
 */
export function createCustomEvent<T extends CustomEvent>(
    type: string,
    detail: any,
    bubbles?: boolean,
    cancelable?: boolean
): T {
    return new CustomEvent(type, { bubbles, cancelable, detail } as CustomEventInit) as T;
}
