export class LgApplicationPermissionsBase {
    // Mapper roles
    readonly AdminRole?: string;
    readonly AccessRole?: string;
    readonly OpenRole?: string;
    readonly SwitchHospitalRole?: string;
    readonly SwitchLanguageRole?: string;
    readonly MergedHospitalsRole?: string;
    readonly InternalRole?: string;
}
