/* eslint-disable @typescript-eslint/naming-convention */
import { coerceBooleanProperty } from "@angular/cdk/coercion";
import {
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    inject,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    OnInit,
    Renderer2
} from "@angular/core";
import * as d3 from "d3";
import { LG_D3_PIE_DEFAULT_DURATION, LgD3PieInterpolator } from "../d3";

import { LgD3PieInterpolatorFactory } from "../d3/lg-d3-pie-interpolator-factory.service";
import { LgSimpleChanges } from "@logex/framework/types";
import { toFixedFix } from "@logex/framework/utilities";

import { BaseChartComponent } from "../shared/base-chart.component";
import { CHART_SEPARATOR_SIZE, Margin } from "../shared/chart.types";
import { getRecommendedPosition } from "../shared/getRecommendedPosition";
import { IChartIcon, IPieChartItem, IPieChartTooltipContext } from "./pie-chart.types";
import { LgTranslateService, useTranslationNamespace } from "@logex/framework/lg-localization";
import {
    LG_DEFAULT_COLOR_CONFIGURATION,
    LG_USE_NEW_LABELS,
    LgColorsConfiguration
} from "../shared/lg-color-palette-v2/lg-colors.types";
import { LgColorPaletteV2 } from "../shared/lg-color-palette-v2/lg-color-palette-v2";

const MIN_HORIZONTAL_PADDING_FOR_LABELS = 40;
const MIN_VERTICAL_PADDING_FOR_LABELS = 20;
const PIE_TITLE_HEIGHT = 20;
const SPACE_BETWEEN_TITLE_AND_SVG_AREA = 8;
const FIXED_SPACE_BETWEEN_PIES = 16;
const INNER_RADIUS_RATIO = 0.6;
const HEIGHT_WHEN_LABELS_AND_ICONS_ARE_HIDDEN = 220;

const PIE_MARGINS = {
    top: 8,
    right: 16,
    bottom: 12 + PIE_TITLE_HEIGHT + SPACE_BETWEEN_TITLE_AND_SVG_AREA,
    left: 16
};

@Component({
    standalone: false,
    selector: "lg-compare-pie-chart",
    viewProviders: [useTranslationNamespace("FW._Directives._Charts._LgPieChart")],
    // eslint-disable-next-line @angular-eslint/component-max-inline-declarations
    template: `
        <ng-template #defaultTemplate [lgChartTemplateContextType]="this" let-context>
            <div
                *ngIf="context.data.item != null"
                class="lg-tooltip__title insurer-logo insurer-logo--size16 insurer-logo--{{
                    context.data.item.id
                }}"
            ></div>
            <div class="lg-tooltip__title">{{ context.data.valueName }}</div>
            <div class="lg-tooltip__table">
                <div class="lg-tooltip__table-row">
                    <div class="lg-tooltip__table-column lg-tooltip__table-column--name">
                        {{ context.data.group }}:
                    </div>
                    <div
                        class="lg-tooltip__table-column lg-tooltip__table-column--value"
                        [innerHTML]="context.data.value | fmtType: formatterType | lgMarkSymbols"
                    ></div>
                </div>
                <div class="lg-tooltip__table-row lg-tooltip__table-row--emphasize">
                    <div class="lg-tooltip__table-column lg-tooltip__table-column--name">
                        {{ "FW._Directives._Charts.Market_share" | lgTranslate }}
                    </div>
                    <div
                        class="lg-tooltip__table-column lg-tooltip__table-column--value"
                        [innerHTML]="context.fraction | fmtPercent: 1 | lgMarkSymbols"
                    ></div>
                </div>
            </div>
        </ng-template>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LgComparePieChartComponent
    extends BaseChartComponent<IPieChartItem[], IPieChartTooltipContext>
    implements OnInit, OnChanges, OnDestroy
{
    private _colorPalette = inject(LgColorPaletteV2);
    private _interpolatorFactory = inject(LgD3PieInterpolatorFactory);
    private _ngZone = inject(NgZone);
    private _renderer = inject(Renderer2);
    private _translateService = inject(LgTranslateService);
    private useNewLabels = inject(LG_USE_NEW_LABELS);

    /**
     * Callback for providing the displayed labels of related data items (required).
     */
    @Input({ required: true }) valueName!: (locals: any) => string;

    /**
     * Callback for providing the icon of related data item.
     */
    @Input() valueIcon?: (locals: any) => IChartIcon;

    /**
     * Callback for providing the group values of related data items (required).
     */
    @Input({ required: true }) groupValues!: (locals: any) => number[];

    /**
     * Callback for providing group names of the chart (required).
     */
    @Input({ required: true }) groupNames!: (locals: any) => string[];

    /**
     * Specifies whether data item labels are visible or not.
     *
     * @default false
     */
    @Input() noLabels = false;

    /**
     * Specifies whether data item inner labels are visible or not.
     *
     * @default false
     */
    @Input() noInnerLabels = false;

    /**
     * Specifies maximum width of icons
     *
     * @default 50
     */
    @Input() maxIconWidth?: string;

    /**
     * Callback for providing the opacity of displayed data item labels. If not specified then opacity is 1.
     */
    @Input() valueOpacityFn: (locals: any) => number = (_: any) => 1;

    /**
     * Specifies whether opacity of inactive items should be processed or not.
     *
     * @default false
     */
    @Input() noActiveItemsOpacity = false;

    /**
     * @deprecated use colorConfiguration instead
     */
    @Input() valueColorFn?: (item: any) => string;

    /**
     * Specifies limit of displayed data items.
     */
    @Input() itemsLimit?: number;

    /**
     * Specifies whether transition animation is turned off or not.
     *
     * @default false
     */
    @Input() noTransition = false;
    /**
     * Specifies the color configuration. Defaults to categorical palette.
     *
     * If specified, allows four different configuration
     * - default/categorical - using 20 predefined colors
     * - sequential by color scheme - using predefined sequence of colors by name
     * - predefined - using predefined dictionary
     * - own - array of hexadecimal values
     *
     * For usage, see New Palette in storybook under LgCharts.
     * Palette story contains all possible colors.
     * Gallery story contains all charts using new colors.
     *
     * Example can be seen in 'getAllChartsProps.ts:62'
     */
    @Input() colorConfiguration: LgColorsConfiguration = LG_DEFAULT_COLOR_CONFIGURATION;

    /**
     * Specifies colors of data items on chart.
     */
    @Input() set valueColors(input: string | string[]) {
        this._valueColors = typeof input === "string" ? input.split(",").map(x => x.trim()) : input;
    }

    get valueColors(): string[] {
        return this._valueColors;
    }

    /**
     * Callback for defining if the data item is active or not. If not provided all items are active.
     */
    @Input() isItemActive: (item: any) => boolean = (_item: any) => true;

    @HostBinding("class") get class(): string {
        return "lg-compare-pie-chart";
    }

    @HostBinding("style.maxWidth.px") get maxWidth(): number {
        return this.width;
    }

    @HostBinding("style.maxHeight.px") get maxHeight(): number {
        return this.height;
    }

    _valueColorsScale!: d3.ScaleOrdinal<string, string>;

    protected override _margin!: Required<Margin>;

    private _valueColors: string[] = [];
    private _valueNames: string[] = [];
    private _valueIcons: string[] = [];
    private _groupNames: string[] = [];
    private _maxIconWidth = 0;
    private _pie!: d3.Pie<any, any>;
    private _xScale!: d3.ScaleBand<any>;
    private _interpolator!: LgD3PieInterpolator;
    private _trackListener!: () => void;
    private _lastMouseX = 0;
    private _lastMouseY = 0;
    private _namesOfActiveItems: string[] = [];
    private _noLabels = false;

    constructor() {
        super();
        this._trackMousePosition();
    }

    ngOnInit(): void {
        super._onInit();

        this._initState();
        this._propsToState();
        this._drawMainSvgHolder(this._elementRef.nativeElement);
        this._updateSize();
        this._initializePieInterpolator();
        this._convertData();
        this._create();
        this._render(true);
        this._initializeTooltip();

        this._initialized = true;
    }

    ngOnChanges(changes: LgSimpleChanges<LgComparePieChartComponent>): void {
        if (!this._initialized) {
            return;
        }

        super._onBaseChartChanges(changes);

        let needsRender = false;
        let renderAsTransition = false;

        if (
            changes.data ||
            changes.valueName ||
            changes.groupNames ||
            changes.valueColorFn ||
            changes.valueColors ||
            changes.valueOpacityFn ||
            changes.itemsLimit
        ) {
            this._convertData();
            needsRender = true;
            renderAsTransition = true;
        }

        if (changes.width || changes.height) {
            this._updateSize();
            this._sizePropsToState();
            needsRender = true;
        }

        if (changes.maxIconWidth) {
            this._maxIconWidth = parseInt(changes.maxIconWidth.currentValue);

            if (isNaN(this._maxIconWidth) || this._maxIconWidth === 0) {
                this._maxIconWidth = 50;
            }
        }

        if (needsRender) {
            if (renderAsTransition && !this.noTransition) {
                d3.transition()
                    .duration(500)
                    .each(() => this._render(true));
            } else {
                this._render(false);
            }
        }
    }

    ngOnDestroy(): void {
        super._onDestroy();

        // destroys render listener
        this._trackListener();
    }

    protected _initState(): void {
        this._valueNames = [];
        this._valueIcons = [];
        this._data = [];
        this._groupNames = [];
        this._maxIconWidth = 50;
    }

    protected _propsToState(): void {
        this._sizePropsToState();
    }

    protected _sizePropsToState(): void {
        this._margin = PIE_MARGINS;
        this._width = +this.width - this._margin.left - this._margin.right;
        this._height = +this.height - this._margin.top - this._margin.bottom;

        this._noLabels =
            this.height < HEIGHT_WHEN_LABELS_AND_ICONS_ARE_HIDDEN ? true : this.noLabels;
    }

    protected _initializePieInterpolator(): void {
        this._interpolator = this._interpolatorFactory.interpolator();
        this._interpolator
            .chartId(function (d: any) {
                return d.data.group;
            })
            .valueId(function (d) {
                return d.data.valueName;
            })
            .valueName(function (d) {
                return d.data.valueName;
            })
            .valueIcon(function (d) {
                return d.data.icon;
            })
            .opacity(function (d) {
                return d.data.opacity;
            })
            .xAxisLabel(function (d) {
                return (100 * d.data.percentValue).toFixed(0);
            })
            .tickOffset(1)
            .tickLength(4)
            .tickPadding(1)
            .labelHeight(14);
    }

    protected _updateSize(): void {
        this._svg.attr("width", +this.width);
        this._svg.attr("height", +this.height);
    }

    protected _render(useTransition: boolean): void {
        if (!this._groupNames.length || this._height < 0) {
            return;
        }

        this._xScale.rangeRound([0, this._width]).padding(0).domain(this._groupNames);

        const pieDimensions = getPieDimensions({
            width: this.width,
            height: this.height,
            margin: this._margin,
            fixedSpaceBetweenPies: FIXED_SPACE_BETWEEN_PIES,
            minHorizontalPadding: MIN_HORIZONTAL_PADDING_FOR_LABELS,
            minVerticalPadding: this._noLabels ? 0 : MIN_VERTICAL_PADDING_FOR_LABELS,
            graphsCount: this._data.length
        });
        const { radius } = pieDimensions;

        const arc = d3
            .arc<number>()
            .startAngle(0)
            .endAngle(2 * Math.PI)
            .innerRadius(radius * INNER_RADIUS_RATIO)
            .outerRadius(radius);

        this._interpolator.outerRadius(radius, radius * INNER_RADIUS_RATIO).prepareRender();

        const groupNames = this.groupNames({});
        // Duplicate group names triggers d3 error
        if (new Set(groupNames).size !== groupNames.length) {
            throw Error("Group names can't be duplicate!");
        }

        const groups = this._chart
            .selectAll<SVGGElement, IPieChartItem[]>(".lg-compare-pie-chart__group")
            .data(this._data, (_, i) => groupNames[i]);

        const groupsG = groups
            .merge(groups)
            .attr(
                "transform",
                (_, i) =>
                    `translate( ${getTranslateX(
                        i,
                        pieDimensions,
                        this._data.length,
                        this.width
                    )}, ${this._margin.top + radius + pieDimensions.verticalPadding} )`
            );

        groupsG
            .select<SVGTextElement>(
                `${
                    this.useNewLabels
                        ? ".lg-compare-pie-chart__group__label"
                        : ".lg-compare-pie-chart__group__label__legacy"
                }`
            )
            .style("opacity", 0)
            .attr("y", this._height / 2 + PIE_TITLE_HEIGHT + SPACE_BETWEEN_TITLE_AND_SVG_AREA - 2)
            .text((_, i) => groupNames[i])
            .style("opacity", 1);

        if (useTransition) groupsG.transition();
        groups.exit().transition().style("opacity", 0).remove();

        const axis = this._chart
            .selectAll(".lg-compare-pie-chart__group")
            .select("g.lg-compare-pie-chart__group__axis");

        axis.select("path").attr("d", arc(0));

        const pieData: Array<d3.PieArcDatum<any>> = this._pie as unknown as Array<
            d3.PieArcDatum<any>
        >;
        const slices = this._chart
            .selectAll(".lg-compare-pie-chart__group")
            .select("g.lg-compare-pie-chart__group__slices")
            .selectAll<SVGPathElement, d3.PieArcDatum<any>>(
                "path.lg-compare-pie-chart__group__slices__slice"
            )
            .data(pieData, (d: d3.PieArcDatum<any>) => d.data.valueName);

        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const self = this;

        this._chart
            .selectAll(".lg-compare-pie-chart__group")
            .select<SVGGElement>("g.lg-compare-pie-chart__group__slices")
            .on("mouseover", function (_event: MouseEvent) {
                self._tooltip?.show({ target: this as any });
                self._updateTooltipPosition();
            })
            .on("mouseleave", (_event: MouseEvent) => this._tooltip?.hide());

        slices
            .enter()
            .append("path")
            .attr("class", "lg-compare-pie-chart__group__slices__slice")
            .style("fill", d => this._valueColorsScale(d.data.valueName))
            .style("opacity", d => d.data.opacity)
            .style("cursor", d => (this.clickable && d.data.item != null ? "pointer" : "default"))
            .attr("stroke", "#fff")
            .attr("stroke-width", CHART_SEPARATOR_SIZE)
            .on(
                "mouseover",
                function onMouseOver(
                    _event: MouseEvent,
                    {
                        data,
                        startAngle,
                        endAngle
                    }: {
                        data: IPieChartItem;
                        startAngle: number;
                        endAngle: number;
                    }
                ) {
                    self._onArcMouseOver(this, data, startAngle, endAngle);
                }
            )
            .on(
                "mouseleave",
                function onMouseOver(_event: MouseEvent, { data }: { data: IPieChartItem }) {
                    self._onArcMouseLeave(this, data);
                }
            )
            .on("click", (_event: MouseEvent, d) => this._onClick(d.data))
            .merge(slices)
            .transition()
            .duration(useTransition ? LG_D3_PIE_DEFAULT_DURATION : 0)
            .attrTween("d", this._interpolator.pieTween)
            .style("fill", d => this._valueColorsScale(d.data.valueName))
            .style("opacity", d => d.data.opacity);

        slices.exit().transition().attrTween("d", this._interpolator.pieExitTween).remove();

        if (!coerceBooleanProperty(this._noLabels)) {
            axis.call(
                this._interpolator.renderTicks,
                useTransition ? LG_D3_PIE_DEFAULT_DURATION : 0
            );

            if (this._valueIcons.length) {
                axis.call(
                    this._interpolator.renderIconLabels,
                    this._xScale.bandwidth(),
                    useTransition ? LG_D3_PIE_DEFAULT_DURATION : 0
                );
            } else {
                axis.call(
                    this._interpolator.renderLabels,
                    useTransition ? LG_D3_PIE_DEFAULT_DURATION : 0
                );
                axis.selectAll("text").attr(
                    "class",
                    `${
                        this.useNewLabels
                            ? "lg-compare-pie-chart__axis__label"
                            : "lg-compare-pie-chart__axis__label__legacy"
                    }`
                );
            }
        } else {
            this._chart.selectAll(".lg-compare-pie-chart__group__axis image").remove();
        }
        const showInnerLabels =
            !coerceBooleanProperty(this.noInnerLabels) && !coerceBooleanProperty(this._noLabels);

        if (showInnerLabels) {
            this._chart
                .selectAll(".lg-compare-pie-chart__group")
                .select("g.lg-compare-pie-chart__group__xAxisLabels")
                .call(
                    this._interpolator.renderxAxisLabels,
                    useTransition ? LG_D3_PIE_DEFAULT_DURATION : 0
                );
        } else {
            this._chart
                .selectAll("g.lg-compare-pie-chart__group__xAxisLabels")
                .selectAll("text")
                .remove();
        }
    }

    protected _create(): void {
        this._chart = this._svgG.append("g");
        this._xScale = d3.scaleBand();
        this._pie = d3.pie();
        this._pie.value(d => d.value);
        this._pie.sort(null);
        this._interpolator.layout(this._pie);

        this._data.forEach(() => {
            this._chart.append("g").attr("class", "lg-compare-pie-chart__group");
        });

        const groupsG = this._chart.selectAll(".lg-compare-pie-chart__group");

        groupsG
            .append("text")
            .attr(
                "class",
                `${
                    this.useNewLabels
                        ? "lg-compare-pie-chart__group__label"
                        : "lg-compare-pie-chart__group__label__legacy"
                }`
            )
            .attr("text-anchor", "middle");

        groupsG.append("g").attr("class", "lg-compare-pie-chart__group__axis").append("path");

        groupsG.append("g").attr("class", "lg-compare-pie-chart__group__slices");
        groupsG.append("g").attr("class", "lg-compare-pie-chart__group__xAxisLabels");
        groupsG
            .append("g")
            .attr("class", "lg-compare-pie-chart__group__percent-symbol")
            .append("text")
            .text("%");
    }

    protected _convertData(): void {
        if (!this.data) {
            return;
        }

        let colors: string[] = [];
        this._data = [];
        this._groupNames = [];
        this._valueNames = [];
        this._valueIcons = [];
        let opacity = 1;
        let valueIcon: any = null;
        const activeItems: string[] = [];

        let valueIndex = 0;

        const totalValues: number[] = [];
        let othersValues: number[] = [];

        this.data.forEach((value, i) => {
            if (this._groupNames.length === 0) {
                this._groupNames = this.groupNames(value);
                for (let z = 0; z < this._groupNames.length; ++z) this._data[z] = [];
            }

            const groupValues = this.groupValues(value);

            // Sum values over items limit
            if (this.itemsLimit != null && i >= this.itemsLimit) {
                othersValues = groupValues.map((groupValue, groupIndex) =>
                    othersValues[groupIndex] ? groupValue + othersValues[groupIndex] : groupValue
                );
                return;
            }

            const valueName = this.valueName(value);
            this._valueNames.push(valueName);

            if (this.isItemActive(value)) activeItems.push(valueName);

            if (this.valueIcon) {
                valueIcon = this.valueIcon(value);
                if (valueIcon) {
                    this._valueIcons.push(valueIcon);
                }
            }

            if (this.valueColorFn) {
                colors.push(this.valueColorFn(value));
            }

            if (this.valueOpacityFn) {
                opacity = this.valueOpacityFn(value);
            }

            if (!groupValues) return;

            for (let z = 0; z < groupValues.length; ++z) {
                this._data[z].push({
                    valueName,
                    group: this._groupNames[z],
                    value: groupValues[z],
                    valueIndex,
                    groupIndex: z,
                    icon: valueIcon,
                    opacity,
                    item: value
                });

                totalValues[z] = totalValues[z] ? groupValues[z] + totalValues[z] : groupValues[z];
            }
            ++valueIndex;
        });

        // Add Others to chart data
        if (othersValues.length > 0) {
            if (this.valueColorFn) {
                colors.push(this.valueColorFn(null));
            }

            if (this.valueOpacityFn) {
                opacity = this.valueOpacityFn(null);
            }

            for (let z = 0; z < othersValues.length; ++z) {
                this._data[z].push({
                    valueName: this._translateService.translate(".Others"),
                    group: this._groupNames[z],
                    value: othersValues[z],
                    valueIndex,
                    groupIndex: z,
                    icon: valueIcon,
                    opacity,
                    item: null
                });

                totalValues[z] = totalValues[z]
                    ? totalValues[z] + othersValues[z]
                    : othersValues[z];
            }
        }

        this._data.forEach((group, groupIndex) => {
            group.forEach(
                item => (item.percentValue = toFixedFix(item.value / totalValues[groupIndex], 3))
            );
        });

        if (this._groupNames.length === 0) {
            this._groupNames = this.groupNames({});
            for (let z = 0; z < this._groupNames.length; ++z) this._data[z] = [];
        }

        const length = colors.length;
        colors = this._getColors(colors).slice(0, length - 1);
        colors.push(this._colorPalette.getOtherColor());

        this._valueColorsScale = d3
            .scaleOrdinal<string, string>()
            .domain(this._valueNames)
            .range(colors);

        this._namesOfActiveItems = activeItems;
    }

    private _getColors(colors: string[]): string[] {
        if (this._colorPalette.useNewColorPalette) {
            return this._colorPalette.getColorsForType(this.colorConfiguration);
        }
        return takeFirstNonEmptyArray(colors, this._valueColors, d3.schemeCategory10 as []);
    }

    protected _onClick(data: IPieChartItem): void {
        const wasAlreadyActive = this._namesOfActiveItems.indexOf(data.valueName) > -1;

        if (wasAlreadyActive) {
            this._namesOfActiveItems = this._namesOfActiveItems.filter(x => x !== data.valueName);
        } else {
            this._namesOfActiveItems.push(data.valueName);
        }

        this.itemClick.emit({ item: data.item, datum: data, index: data.valueIndex });

        if (this.noActiveItemsOpacity) {
            this._data.forEach(group =>
                group.forEach(value => (value.opacity = this.valueOpacityFn(value.item)))
            );
        }
    }

    private _trackMousePosition(): void {
        this._ngZone.runOutsideAngular(() => {
            this._trackListener = this._renderer.listen(
                this._elementRef.nativeElement,
                "mousemove",
                (event: MouseEvent) => {
                    this._lastMouseX = event.clientX;
                    this._lastMouseY = event.clientY;
                    this._updateTooltipPosition();
                }
            );
        });
    }

    private _updateTooltipPosition(): void {
        if (this._tooltip && this._tooltip.visible) {
            if (this._lastMouseX && this._lastMouseY)
                this._tooltip.setPositionAt(
                    this._lastMouseX,
                    this._lastMouseY,
                    getRecommendedPosition(
                        { x: this._lastMouseX, y: this._lastMouseY },
                        this._tooltip.getOverlayElement()
                    )
                );
            else this._tooltip.hide();
        }
    }

    private _onArcMouseOver(
        arc: any,
        data: IPieChartItem,
        startAngle: number,
        endAngle: number
    ): void {
        this.tooltipContext = {
            data,
            fraction: data.percentValue ?? 0 // ( endAngle - startAngle ) / ( 2 * Math.PI )
        };

        const position = (startAngle + endAngle) / 2 < Math.PI ? "right-bottom" : "left-bottom";

        if (this._tooltip?.options().position !== position) {
            this._tooltip?.setPosition(position);
        }

        const currentArc = d3.select<any, { data: IPieChartItem }>(arc);
        currentArc.style("fill", "" + d3.rgb(this._valueColorsScale(data.valueName)).darker(0.2));

        if (this._namesOfActiveItems.length > 0) {
            currentArc.style("opacity", 1);

            const currentGroup = d3.select(
                this._chart.selectAll(".lg-compare-pie-chart__group").nodes()[data.groupIndex]
            );
            const currentName = currentArc.datum().data.valueName;

            currentGroup.selectAll(".lg-compare-pie-chart__group__axis image").each(function () {
                const icon = d3.select(arc);

                if (icon.style("opacity") === "" + 0) return;

                if (icon.attr("data_name") === "" + currentName) icon.style("opacity", 1);
            });
        }
    }

    private _onArcMouseLeave(arc: any, data: IPieChartItem): void {
        d3.select(arc).style("fill", this._valueColorsScale(data.valueName));
        const currentArc = d3.select<any, { data: IPieChartItem }>(arc);

        if (this.noActiveItemsOpacity) {
            currentArc.style("opacity", this.valueOpacityFn(data.item));
            return;
        }

        if (this._namesOfActiveItems.length > 0) {
            const currentName = currentArc.datum().data.valueName;

            if (this._namesOfActiveItems.indexOf(currentName) > -1) {
                currentArc.style("opacity", 1);
            } else {
                currentArc.style("opacity", 0.1);

                const currentGroup = d3.select(
                    this._chart.selectAll(".lg-compare-pie-chart__group").nodes()[
                        currentArc.datum().data.groupIndex
                    ]
                );
                const activeNames = this._namesOfActiveItems.map(x => "" + x);

                currentGroup
                    .selectAll(".lg-compare-pie-chart__group__axis image")
                    .each(function () {
                        const icon = d3.select(arc);

                        if (icon.style("opacity") === "" + 0) return;

                        if (activeNames.indexOf(icon.attr("data_name")) === -1)
                            icon.style("opacity", 0.1);
                    });
            }
        }
    }
}

type PieDimensionCalcInput = {
    width: number;
    height: number;
    margin: Margin;
    fixedSpaceBetweenPies: number;
    minHorizontalPadding: number;
    minVerticalPadding: number;
    graphsCount: number;
};

type PieDimensions = {
    radius: number;
    verticalPadding: number;
    horizontalPadding: number;
    width: number;
};

function getTranslateX(
    index: number,
    pieDimensions: PieDimensions,
    groupsCount: number,
    totalWidth: number
): number {
    const offset = (totalWidth - pieDimensions.width * groupsCount) / 2 + pieDimensions.width / 2;
    return pieDimensions.width * index + offset;
}

function getPieDimensions({
    width,
    height,
    margin,
    fixedSpaceBetweenPies,
    minHorizontalPadding,
    minVerticalPadding,
    graphsCount
}: PieDimensionCalcInput): PieDimensions {
    const { top = 0, bottom = 0, left = 0 } = margin;
    const innerWidth =
        graphsCount > 1
            ? (width - left - fixedSpaceBetweenPies / graphsCount) / graphsCount
            : width;
    const innerHeight = height - top - bottom;

    const radiusBasedOnWidth = (innerWidth - 2 * minHorizontalPadding) / 2;
    const radiusBasedOnHeight = (innerHeight - 2 * minVerticalPadding) / 2;

    const radius = Math.min(radiusBasedOnWidth, radiusBasedOnHeight);

    return {
        radius,
        verticalPadding:
            radius === radiusBasedOnWidth ? (innerHeight - 2 * radius) / 2 : minVerticalPadding,
        horizontalPadding:
            radius === radiusBasedOnWidth ? minHorizontalPadding : (innerWidth - 2 * radius) / 2,
        width: innerWidth
    };
}

function takeFirstNonEmptyArray(...args: string[][]): string[] {
    for (const arr of args) {
        if (arr && arr.length) return arr;
    }

    return [];
}
