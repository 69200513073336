import { NgIterable } from "@angular/core";

/**
 *  Data context for the template modelled after NgFor, but we can customize it in the future
 */
export class LgVirtualForOfContext<T> {
    constructor(
        public $implicit: T | null,
        public lgVirtualForOf: NgIterable<T>,
        public index: number,
        public count: number
    ) {}

    get first(): boolean {
        return this.index === 0;
    }

    get last(): boolean {
        return this.index === this.count - 1;
    }

    get even(): boolean {
        return this.index % 2 === 0;
    }

    get odd(): boolean {
        return !this.even;
    }
}
