import ldRange from "lodash-es/range";
import { animate, group, query, style, transition, trigger } from "@angular/animations";
import {
    DELETE,
    DOWN_ARROW,
    ENTER,
    ESCAPE,
    TAB,
    UP_ARROW,
    SHIFT,
    BACKSPACE
} from "@angular/cdk/keycodes";
import {
    FormStyle,
    getLocaleDayNames,
    getLocaleFirstDayOfWeek,
    getLocaleMonthNames,
    NgClass,
    NgForOf,
    NgIf,
    NgTemplateOutlet,
    TranslationWidth
} from "@angular/common";
import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    HostListener,
    LOCALE_ID,
    Renderer2,
    ViewChild,
    ViewEncapsulation,
    ChangeDetectorRef,
    TemplateRef,
    ApplicationRef,
    inject
} from "@angular/core";

import {
    LgTranslatePipe,
    LgTranslateService,
    useTranslationNamespace
} from "@logex/framework/lg-localization";
import { easingDefs } from "@logex/framework/utilities";
import { eatOneKeyUpEvent } from "../../helpers";

import { LgCapitalizePipe } from "../../pipes/lg-capitalize.pipe";
import { IDropdownDefinition, LgDropdownComponent } from "../lg-dropdown";
import { CalendarInputElement, CalendarInputPart, IDateCell } from "./calendar.types";
import { LgPromptDialog } from "../lg-prompt-dialog/lg-prompt-dialog.component";
import { IPromptDialogOptions } from "../lg-prompt-dialog/lg-prompt-dialog.types";
import { IQuickSettingsMenuChoice, QuickSettingsMenuType } from "../lg-quick-settings-menu";
import { CalendarInputElementWrapper } from "./calendar-input-element-wrapper";
import { LgIconComponent } from "../lg-icon/lg-icon.component";
import { LgQuickSettingsMenuComponent } from "../lg-quick-settings-menu/lg-quick-settings-menu.component";
import { LgSimpleTooltipDirective } from "../../lg-tooltip";
import {
    LgCopyHandlerDirective,
    LgDefaultFocusDirective,
    LgPasteHandlerDirective
} from "../../behavior";

const SHOULD_CLOSE_AFTER_SELECT = true;
const MAX_NUMBER_OF_DAYS = 31;
const NUMBER_OF_MONTHS_IN_YEAR = 12;

interface ISelectDateArgs {
    cancelled?: boolean;
    navigationDelta?: 0 | 1;
    closeAfterSelect?: boolean;
}
export interface ILgCalendarTooltipComponentProps {
    minDate: Date;
    maxDate: Date;
    required: boolean;
    canSeeToday: boolean;
    onSelect: (
        date: Date | null,
        shouldClose: boolean,
        cancelled: boolean,
        navigationDelta: 0 | 1
    ) => void;
    selectedDate?: Date | undefined;
    defaultDate?: Date | undefined;
    position: "left" | "right";
    condensed?: boolean;
    fillPotentialDate?: boolean;
    elements: CalendarInputElement[];
    getCopyData(selectedDate: Date | null): string;
    pasteData(text: string): boolean | null;
}

@Component({
    standalone: true,
    selector: "lg-calendar-tooltip",
    templateUrl: "./lg-calendar-tooltip.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    viewProviders: [useTranslationNamespace("FW._Directives")],
    // eslint-disable-next-line @angular-eslint/component-max-inline-declarations
    animations: [
        // todo: move the height animation here as well?
        trigger("move", [
            transition("* => up", [
                group([
                    query(".lg-calendar-tooltip__wrapper__body__holder__row:leave", [
                        style({ transform: "translateY(0%)", opacity: 1 }),
                        animate(
                            `0.5s ${easingDefs.easeOutCubic}`,
                            style({ transform: "translateY(-{{height}}px)", opacity: 0 })
                        )
                    ]),
                    query(".lg-calendar-tooltip__wrapper__body__holder__row:enter", [
                        style({ transform: "translateY({{height}}px)", opacity: 0 }),
                        animate(
                            `0.5s ${easingDefs.easeOutCubic}`,
                            style({ transform: "translateY(0)", opacity: 1 })
                        )
                    ])
                ])
            ]),
            transition("* => down", [
                group([
                    query(".lg-calendar-tooltip__wrapper__body__holder__row:leave", [
                        style({ transform: "translateY(0%)", opacity: 1 }),
                        animate(
                            `0.5s ${easingDefs.easeOutCubic}`,
                            style({ transform: "translateY({{height}}px)", opacity: 0 })
                        )
                    ]),
                    query(".lg-calendar-tooltip__wrapper__body__holder__row:enter", [
                        style({ transform: "translateY(-{{height}}px)", opacity: 0 }),
                        animate(
                            `0.5s ${easingDefs.easeOutCubic}`,
                            style({ transform: "translateY(0)", opacity: 1 })
                        )
                    ])
                ])
            ])
        ])
    ],
    imports: [
        NgIf,
        NgTemplateOutlet,
        LgCapitalizePipe,
        LgDropdownComponent,
        NgClass,
        LgIconComponent,
        LgQuickSettingsMenuComponent,
        NgForOf,
        LgTranslatePipe,
        LgSimpleTooltipDirective,
        LgCopyHandlerDirective,
        LgPasteHandlerDirective,
        LgDefaultFocusDirective
    ],
    host: {
        class: "lg-calendar-tooltip",
        "[class.lg-calendar-tooltip--right]": "_props.position === 'right'",
        "[class.lg-calendar-tooltip--left]": "_props.position === 'left'",
        "[class.lg-calendar-tooltip--condensed]": "_condensed",
        "[class.lg-calendar-tooltip--above]": "_showAbove",
        "[class.lg-calendar-tooltip--below]": "!_showAbove"
    }
})
export class LgCalendarTooltipComponent {
    private _applicationRef = inject(ApplicationRef);
    private _capitalizePipe = inject(LgCapitalizePipe);
    private _changeDetector = inject(ChangeDetectorRef);
    private _promptDialog = inject(LgPromptDialog);
    private _lgTranslate = inject(LgTranslateService);
    private _locale = inject(LOCALE_ID);
    private _renderer = inject(Renderer2);

    @HostListener("document:keydown", ["$event"]) keydownEvent(event: KeyboardEvent): void {
        this._onDocumentKeyPressed(event);
    }

    @ViewChild("dayInput") dayInputRef!: ElementRef;
    @ViewChild("monthInput") monthInputRef!: ElementRef;
    @ViewChild("yearInput") yearInputRef!: ElementRef;

    @ViewChild("dayTemplate", { static: true }) _dayTemplate!: TemplateRef<any>;
    @ViewChild("monthTemplate", { static: true }) _monthTemplate!: TemplateRef<any>;
    @ViewChild("yearTemplate", { static: true }) _yearTemplate!: TemplateRef<any>;

    _showAbove = false;

    _props!: ILgCalendarTooltipComponentProps;

    _elements: CalendarInputElementWrapper[] = [];
    _initialized = false;
    _monthRows!: IDateCell[][];
    _dayNamesInLocalizedOrder!: string[];
    _currentDay!: number;
    _currentMonth!: number;
    _currentYear!: number;
    _monthDropdown!: IDropdownDefinition<number>;
    _yearDropdown!: IDropdownDefinition<number>;
    _holderClassName = "lg-calendar-tooltip__wrapper__body__holder";
    _transition: string | null = null;
    _selectedDate: Date | null = null;
    _focusedElement: CalendarInputPart | null = null;
    _condensed = false;
    _navigationMenu!: IQuickSettingsMenuChoice[];
    _canSelectToday = false;

    CalendarInputPart = CalendarInputPart;

    get _canGoLeft(): boolean {
        return this._checkIfCurrentMonthYearIsSameAs(this._minDate);
    }

    get _canGoRight(): boolean {
        return this._checkIfCurrentMonthYearIsSameAs(this._maxDate);
    }

    get _requiredPosition(): number {
        const result = this._elements.findIndex(
            e => !e.hasValue || e.part === this._focusedElement
        );
        if (result === -1) return this._elements.length;
        return result;
    }

    readonly _copyHandler = (): string | null => this._props.getCopyData(this._selectedDate);
    readonly _pasteHandler = (text: string): boolean => this._pasteData(text);

    private _firstDayOfWeek!: number;
    private _minDate!: Date;
    private _maxDate!: Date;
    private _deleteOptions!: IPromptDialogOptions;
    private _dropdownIsActive = false;
    private _fillPotentialDate = true;
    private _elementsLookup: { [key in CalendarInputPart]?: CalendarInputElementWrapper } = {};

    initialize(props: ILgCalendarTooltipComponentProps, preselectLast: boolean): void {
        this._props = { ...this._props, ...props };

        this._setSelectedDate(this._props.selectedDate ?? null);
        const initialDate = this._getInitialDate();
        this._currentMonth = initialDate.getMonth() + 1;
        this._currentYear = initialDate.getFullYear();
        this._currentDay = initialDate.getDate();
        this._maxDate = this._props.maxDate
            ? new Date(
                  this._props.maxDate.getFullYear(),
                  this._props.maxDate.getMonth(),
                  this._props.maxDate.getDate(),
                  23,
                  59,
                  59,
                  999
              )
            : new Date(new Date().getFullYear() + 5, NUMBER_OF_MONTHS_IN_YEAR, MAX_NUMBER_OF_DAYS);
        this._minDate = this._props.minDate
            ? new Date(
                  this._props.minDate.getFullYear(),
                  this._props.minDate.getMonth(),
                  this._props.minDate.getDate()
              )
            : new Date(this._maxDate.getFullYear() - 50, 0, 1);

        this._clampDate();

        this._prepareMonthDropdown();
        this._prepareYearDropdown();
        this._prepareNavigationMenu();
        this._prepareCanSelectToday();

        this._firstDayOfWeek = getLocaleFirstDayOfWeek(this._locale);
        const dayNames = getLocaleDayNames(
            this._locale,
            FormStyle.Format,
            TranslationWidth.Abbreviated
        );
        this._dayNamesInLocalizedOrder = [
            ...dayNames.slice(this._firstDayOfWeek),
            ...dayNames.slice(0, this._firstDayOfWeek)
        ];

        this._monthRows = this._getMonth();
        this._holderClassName = `lg-calendar-tooltip__wrapper__body__holder`;

        this._deleteOptions = this._getDeleteOptions();
        this._condensed = this._props.condensed || false;
        this._fillPotentialDate = this._props.fillPotentialDate ?? true;

        this._elementsLookup = {};
        this._elements = this._props.elements.map(element => {
            const wrapper = CalendarInputElementWrapper.create(element, this._renderer, this);
            this._elementsLookup[element.part] = wrapper;
            return wrapper;
        });

        if (props.selectedDate) {
            this._elements.forEach(element => element.setPotentialValue(props.selectedDate!));
        }

        if (preselectLast) {
            this._elements[this._elements.length - 1].preselect = true;
            for (let i = 0; i < this._elements.length - 1; ++i) {
                this._elements[i].setValueFromPlaceholder();
            }
            this._focusedElement = this._elements[this._elements.length - 1].part;
        } else {
            this._elements[0].preselect = true;
        }

        this._initialized = true;
    }

    updateProps(props: ILgCalendarTooltipComponentProps): void {
        this._initialized = false;

        this.initialize(props, false);
        this._changeDetector.markForCheck();
        setTimeout(() => {
            this._applicationRef.tick();
        }, 0);
    }

    _onDayClick(day: IDateCell): void {
        if (day.disabled) return;

        if (day.other) {
            this._handleDayInOtherMonthClick(day.date);
        } else {
            this._handleDayInCurrentMonthClick(day.date);
        }
    }

    _isDateSelected(date: Date): boolean {
        if (!this._selectedDate) return false;

        return (
            this._selectedDate.getFullYear() === date.getFullYear() &&
            this._selectedDate.getMonth() === date.getMonth() &&
            this._selectedDate.getDate() === date.getDate()
        );
    }

    _isDisabled(index: number): boolean {
        for (let i = 0; i < index; ++i) {
            if (!this._elements[i].hasValue) return true;
        }
        return false;
    }

    _jumpToSelected(): void {
        if (!this._selectedDate) return;

        this._jumpTo(this._selectedDate.getMonth() + 1, this._selectedDate.getFullYear());
    }

    _jumpToToday(): void {
        const today = new Date();

        this._jumpTo(today.getMonth() + 1, today.getFullYear());
    }

    _prevMonth(): void {
        if (!this._canGoLeft) return;

        this._currentMonth--;
        if (this._currentMonth === 0) {
            this._currentMonth = NUMBER_OF_MONTHS_IN_YEAR;
            this._currentYear--;
        }
        this._renderPrev();
    }

    _nextMonth(): void {
        if (!this._canGoRight) return;

        this._currentMonth++;
        if (this._currentMonth === 13) {
            this._currentMonth = 1;
            this._currentYear++;
        }
        this._renderNext();
    }

    _clearSelection({
        closeAfterClear = SHOULD_CLOSE_AFTER_SELECT
    }: {
        closeAfterClear?: boolean;
    } = {}): void {
        this._selectDate(null, { closeAfterSelect: closeAfterClear });
    }

    _onYearChange(year: number): void {
        this._jumpTo(this._currentMonth, year);
        this._prepareMonthDropdown();
    }

    _onMonthChange(month: number): void {
        this._jumpTo(month, this._currentYear);
    }

    _moveDone(): void {
        this._transition = null;
    }

    _onInputKeyUp(event: KeyboardEvent, index: number, element: CalendarInputElementWrapper): void {
        if (
            event.keyCode === TAB ||
            event.keyCode === SHIFT ||
            event.keyCode === UP_ARROW ||
            event.keyCode === DOWN_ARROW ||
            event.keyCode === ENTER
        )
            return;
        const goNext = element.shouldGoToNext();
        if (goNext) {
            element.normalizeFormat();
            const potentialNewDate = this._getPotentialDate(index);
            if (this._isDateInRange(potentialNewDate)) {
                this._setSelectedDate(potentialNewDate);
                this._jumpTo(potentialNewDate.getMonth() + 1, potentialNewDate.getFullYear());
            }
            this._changeDetector.markForCheck();
            if (index < this._elements.length - 1) {
                this._elements[index + 1].focus(50);
            }
        }
    }

    _onDayInput(event: Event, index: number): void {
        const max = this._getNumberOfDays(
            this._elementsLookup[CalendarInputPart.Month]!.value,
            this._elementsLookup[CalendarInputPart.Year]!.value
        );
        const { value, overwriteInput } = this._coerceInput(event, max);

        this._elements[index].setInputValue(value, overwriteInput);
        const date = this._maybeInitializeDate(index);
        if (date) {
            this._selectDate(date, { closeAfterSelect: false });
            this._elements[index].setPotentialValue(date);
        }
    }

    _onMonthInput(event: Event, index: number): void {
        let { value, overwriteInput } = this._coerceInput(event, NUMBER_OF_MONTHS_IN_YEAR);
        const dayValue = this._elementsLookup[CalendarInputPart.Day]!.value;
        if (dayValue !== "") {
            const max = this._getNumberOfDays(
                value,
                this._elementsLookup[CalendarInputPart.Year]!.value
            );
            if (+dayValue > max) {
                value = "";
                overwriteInput = true;
            }
        }

        this._elements[index].setInputValue(value, overwriteInput);
        const date = this._maybeInitializeDate(index);
        if (date) {
            this._selectDate(date, { closeAfterSelect: false });
            this._elements[index].setPotentialValue(date);
        }
    }

    _onYearInput(event: Event, index: number): void {
        const { value, overwriteInput } = this._coerceInput(event, this._maxDate.getFullYear());

        this._elements[index].setInputValue(value, overwriteInput);
        const date = this._maybeInitializeDate(index);
        if (date) {
            this._selectDate(date, { closeAfterSelect: false });
        }
    }

    _onInputClick(event: UIEvent): void {
        const targ = event.target as HTMLInputElement;
        const t = targ.value;
        // remove value and reinsert it so the cursor is always after last character
        this._renderer.setProperty(event.target, "value", "");
        this._renderer.setProperty(event.target, "value", t);
    }

    _onFocus(inputType: CalendarInputPart, index: number): void {
        this._focusedElement = inputType;
        this._maybeInitializeDate(index);
    }

    _todayClick(event: MouseEvent): boolean {
        // just in case we crossed the boundary
        this._prepareCanSelectToday();
        if (!this._canSelectToday) {
            (event.target as any).blur();
            return false;
        }
        this._selectDate(new Date());
        return true;
    }

    _dropdownActive(active: boolean): void {
        this._dropdownIsActive = active;
    }

    private _pasteData(text: string): boolean {
        const result = this._props.pasteData(text);
        if (result === null) {
            const active = document.activeElement as HTMLElement;
            this._promptDialog
                .alert(
                    this._lgTranslate.translate(".Calendar_InvalidDateDialog_Title"),
                    this._lgTranslate.translate(".Calendar_InvalidDateDialog_Text")
                )
                .then(() => {
                    active?.focus?.();
                });
            return true;
        } else {
            return result;
        }
    }

    private _onDocumentKeyPressed(event: KeyboardEvent): void {
        if (this._dropdownIsActive) return;

        let cancelEvent = false;
        const separator = this._elements[this._requiredPosition].separator;
        // always trigger on some default separators so that people's muscle memory works no matter the format
        if (
            event.key === "/" ||
            event.key === "-" ||
            event.key === "." ||
            event.key === "," ||
            event.key === " " ||
            (separator && event.key === separator)
        ) {
            this._onTabbingForward(false);
            event.preventDefault();
            event.stopPropagation();
            return;
        }
        let attemptSelect = false;
        let enterPressed = false;
        switch (event.keyCode) {
            case TAB:
                event.preventDefault();
                if (event.shiftKey) {
                    this._onTabbingBackwards();
                } else {
                    attemptSelect = this._onTabbingForward(false);
                }
                break;
            case ESCAPE:
                this._selectDate(this._props.selectedDate ?? null, { cancelled: true });
                cancelEvent = true;
                break;
            case DELETE:
                this._clearSelection({ closeAfterClear: false });

                for (const element of this._elements) {
                    element.value = "";
                    element.placeholder = "";
                }

                if (this._fillPotentialDate) {
                    this._maybeInitializeDate(0);
                }

                this._elements[0].focus(0);

                cancelEvent = true;
                break;
            case BACKSPACE:
                if (this._focusedElement && this._elementsLookup[this._focusedElement]?.value) {
                    return;
                }
                this._onTabbingBackwards();
                cancelEvent = true;
                break;
            case ENTER:
                this._onTabbingForward(true);
                enterPressed = true;
                break;
        }
        if (attemptSelect || enterPressed) {
            if (!this._elements[0].hasValue) {
                // nothing entered - maybe add this._dayInputPlaceholder here as well
                this._selectDate(this._props.selectedDate ?? null, {
                    navigationDelta: attemptSelect ? 1 : 0
                });
                cancelEvent = true;
            } else if (this._selectedDate) {
                // _selectedDate represents the visual cursor, but that might not match the text input
                const inputDate = this._elements.reduce(
                    (date, element) => element.applyValueOrPlaceholder(date),
                    new Date(2020, 0, 1)
                );
                if (
                    inputDate?.getTime() === this._selectedDate.getTime() &&
                    (!this._minDate || this._minDate <= this._selectedDate) &&
                    (!this._maxDate || this._selectedDate <= this._maxDate)
                ) {
                    this._selectDate(this._selectedDate, {
                        navigationDelta: attemptSelect ? 1 : 0
                    });
                    cancelEvent = true;
                } else if (enterPressed) {
                    this._onInvalidDateEntered();
                }
            }
        }
        if (cancelEvent) {
            event.stopPropagation();
            event.preventDefault();
            eatOneKeyUpEvent(event);
        }
    }

    private _onTabbingForward(updateOnly: boolean): boolean {
        const required = this._requiredPosition;
        if (required === this._elements.length) return true;

        const element = this._elements[required];
        element.markAsFilled();

        const potentialDate = this._maybeInitializeDate(required);
        if (potentialDate !== null) {
            this._jumpTo(potentialDate.getMonth() + 1, potentialDate.getFullYear());
            this._setSelectedDate(potentialDate);
        }
        if (required < this._elements.length - 1) {
            if (!updateOnly) this._elements[required + 1].focus(0);
            return false;
        }

        for (let i = required; i < this._elements.length; i++) {
            this._elements[i].prepareForInput();
        }

        return potentialDate !== null;
    }

    private _onTabbingBackwards(): boolean {
        const required = this._requiredPosition;
        if (required > 0) {
            for (let i = required - 1; i < this._elements.length; i++) {
                this._elements[i].prepareForInput();
            }

            this._elements[required - 1].focus();
            return false;
        } else {
            return true;
        }
    }

    private _maybeInitializeDate(index: number): Date | null {
        const potentialDate = this._getPotentialDate(index);
        if (this._isDateInRange(potentialDate)) {
            if (this._fillPotentialDate) {
                for (let i = index + 1; i < this._elements.length; ++i) {
                    this._elements[i].setPotentialValue(potentialDate);
                }
            }

            return potentialDate;
        } else {
            return null;
        }
    }

    private _getInitialDate(): Date {
        if (this._props.selectedDate) {
            return this._props.selectedDate;
        }

        if (this._props.defaultDate) {
            return this._props.defaultDate;
        }

        return new Date();
    }

    private _getCurrentDate(): Date {
        return new Date(
            this._currentYear,
            this._currentMonth - 1,
            this._selectedDate?.getDate() ?? this._currentDay
        );
    }

    private _getPotentialDate(index: number): Date {
        let result = this._getCurrentDate();
        for (let i = 0; i <= index; ++i) {
            result = this._elements[i].applyValue(result);
        }
        return result;
    }

    private _jumpTo(month: number, year: number): void {
        const previousYear = this._currentYear;
        const previousMonth = this._currentMonth;
        this._currentMonth = Math.max(1, month);
        this._currentYear = year;
        this._clampDate();
        const yearDiff = this._currentYear - previousYear;
        const monthDiff = this._currentMonth - previousMonth;
        if (yearDiff === 0 && monthDiff === 0) return;

        if (yearDiff < 0 || (yearDiff === 0 && monthDiff < 1)) {
            this._renderPrev();
        } else {
            this._renderNext();
        }
    }

    private _clampDate(): void {
        let change = false;

        if (this._minDate != null) {
            const year = this._minDate.getFullYear();
            if (this._currentYear <= year) {
                change = true;
                if (this._currentYear < year || this._currentMonth < this._minDate.getMonth() + 1) {
                    this._currentMonth = this._minDate.getMonth() + 1;
                }
                this._currentYear = year;
            }
        }

        if (this._maxDate != null) {
            const year = this._maxDate.getFullYear();
            if (this._currentYear >= year) {
                change = true;
                if (this._currentYear > year || this._currentMonth > this._maxDate.getMonth() + 1) {
                    this._currentMonth = this._maxDate.getMonth() + 1;
                }
                this._currentYear = year;
            }
        }

        if (change) this._prepareMonthDropdown();
    }

    private _handleDayInOtherMonthClick(date: Date): void {
        const yearDiff = date.getFullYear() - this._currentYear;
        const monthDiff = date.getMonth() + 1 - this._currentMonth;

        if (yearDiff < 0 || (yearDiff === 0 && monthDiff < 1)) {
            this._prevMonth();
        } else {
            this._nextMonth();
        }
    }

    private _handleDayInCurrentMonthClick(date: Date): void {
        this._selectDate(date);
    }

    private _selectDate(
        date: Date | null,
        {
            cancelled = false,
            navigationDelta = 0,
            closeAfterSelect = SHOULD_CLOSE_AFTER_SELECT
        }: ISelectDateArgs = {}
    ): void {
        this._setSelectedDate(date);

        if (this._props.onSelect) {
            this._props.onSelect(date, closeAfterSelect, cancelled, navigationDelta);
        }
    }

    private _setSelectedDate(date: Date | null): void {
        this._selectedDate = date;
    }

    private _renderPrev(): void {
        const newRows = this._getMonth();

        let transitionHeight = this._monthRows.length;
        if (
            newRows[newRows.length - 1][0].date.getDate() === this._monthRows[0][0].date.getDate()
        ) {
            --transitionHeight;
        }

        this._holderClassName = `lg-calendar-tooltip__wrapper__body__holder transition-down-${transitionHeight}`;
        this._transition = "down";
        this._monthRows = newRows;
        this._prepareMonthDropdown();
    }

    private _renderNext(): void {
        const newRows = this._getMonth();

        let transitionHeight = this._monthRows.length;
        if (
            this._monthRows[this._monthRows.length - 1][0].date.getDate() ===
            newRows[0][0].date.getDate()
        ) {
            --transitionHeight;
        }

        this._holderClassName = `lg-calendar-tooltip__wrapper__body__holder transition-up-${transitionHeight}`;
        this._transition = "up";
        this._monthRows = newRows;
        this._prepareMonthDropdown();
    }

    private _prepareYearDropdown(): void {
        const from = this._minDate.getFullYear();
        const to = this._maxDate.getFullYear();

        this._yearDropdown = {
            groups: ldRange(from, to + 1).map(i => ({ id: i, name: "" + i }))
        };
    }

    private _prepareMonthDropdown(): void {
        const from =
            this._currentYear === this._minDate.getFullYear() ? this._minDate.getMonth() + 1 : 1;
        const to =
            this._currentYear === this._maxDate.getFullYear()
                ? this._maxDate.getMonth() + 1
                : NUMBER_OF_MONTHS_IN_YEAR;

        const monthNames = [
            "",
            ...getLocaleMonthNames(this._locale, FormStyle.Format, TranslationWidth.Wide)
        ];

        this._monthDropdown = {
            groups: ldRange(from, to + 1).map(i => ({
                id: i,
                name: this._capitalizePipe.transform(monthNames[i])
            }))
        };
    }

    private _getMonth(): IDateCell[][] {
        const cursor = new Date(this._currentYear, this._currentMonth - 1, 1);
        let dayOfWeek = (cursor.getDay() + 6) % 7; // convert to 0 = Monday
        const weekOffset = (7 + dayOfWeek - this._firstDayOfWeek + 1) % 7;
        cursor.setDate(1 - weekOffset);

        const result: IDateCell[][] = [];
        let row: IDateCell[] = [];
        const today = new Date();
        const todayTime = new Date(
            today.getFullYear(),
            today.getMonth(),
            today.getDate()
        ).getTime();
        let cursorMonth = cursor.getMonth() + 1;
        const minTime = this._minDate.getTime();
        const maxTime = this._maxDate.getTime();
        dayOfWeek = this._firstDayOfWeek;

        // eslint-disable-next-line no-constant-condition
        while (true) {
            const cursorTime = cursor.getTime();

            row.push({
                day: cursor.getDate(),
                date: new Date(cursorTime),
                today: cursorTime === todayTime,
                other: cursorMonth !== this._currentMonth,
                disabled:
                    (minTime !== null && cursorTime < minTime) ||
                    (maxTime !== null && cursorTime > maxTime)
            });
            cursor.setDate(cursor.getDate() + 1);
            cursorMonth = cursor.getMonth() + 1;
            dayOfWeek = (dayOfWeek + 1) % 7;

            if (row.length === 7) {
                result.push(row);
                row = [];

                if (result.length === 6) break;
            }
        }
        return result;
    }

    private _checkIfCurrentMonthYearIsSameAs(date: Date): boolean {
        return (
            !date ||
            date.getFullYear() !== this._currentYear ||
            date.getMonth() + 1 !== this._currentMonth
        );
    }

    private _coerceInput(event: any, max: number): { value: string; overwriteInput: boolean } {
        const input = event.target.value || event.data; // when user enters "-" or "+" then event.target.value is empty string which 'isNaN' treats like a number

        let value: string;
        let overwriteInput = true;

        if (isNaN(input) || input === "00") {
            value = "";
        } else if (input && (input > max || input.length > max.toString().length)) {
            value = event.data;
        } else {
            value = event.target.value;
            overwriteInput = false;
        }

        return { value, overwriteInput };
    }

    private _onInvalidDateEntered(): void {
        const lastFocus = document.activeElement;
        this._promptDialog
            .alert(
                this._lgTranslate.translate(".Calendar_InvalidDateDialog_Title"),
                this._lgTranslate.translate(".Calendar_InvalidDateDialog_Text"),
                this._deleteOptions
            )
            .then(() => {
                this._props.onSelect(this._props.selectedDate ?? null, true, true, 0);
            })
            .catch(() => {
                // It seems keeping the old invalid value might be better?
                // const today = new Date();
                // const todayOrSelected = this._props.selectedDate ? this._props.selectedDate : today;
                // this._setSelectedDate(null);
                // this._currentYear = todayOrSelected.getFullYear();
                // this._currentMonth = todayOrSelected.getMonth() + 1;
                // this._yearInputValue = "";
                // this._monthInputValue = "";
                // this._dayInputValue = "";
                // this._yearInputPlaceholder = "" + this._currentYear;
                // this._monthInputPlaceholder = "" + this._currentMonth;
                // this._dayInputPlaceholder = "" + todayOrSelected.getDate();
                // this._renderer.setProperty(this.yearInputRef.nativeElement, "value", "");
                // this._renderer.setProperty(this.monthInputRef.nativeElement, "value", "");
                // this._renderer.setProperty(this.dayInputRef.nativeElement, "value", "");
                if (lastFocus && "focus" in lastFocus) {
                    setTimeout(() => {
                        (lastFocus as HTMLElement).focus();
                    }, 0);
                }
                this._changeDetector.markForCheck();
            });
    }

    private _getDeleteOptions(): IPromptDialogOptions {
        return {
            allowClose: true,
            buttons: [
                {
                    id: "clear",
                    name: this._lgTranslate.translate(".Calendar_InvalidDateDialog_ClearSelection"),
                    isConfirmAction: true
                },
                {
                    id: "changeDate",
                    name: this._lgTranslate.translate(".Calendar_InvalidDateDialog_ChangeTheDate"),
                    isCancelAction: true,
                    isReject: true
                }
            ]
        };
    }

    private _isDateInRange(date: Date): boolean {
        return (
            (!this._minDate || this._minDate <= date) && (!this._maxDate || date <= this._maxDate)
        );
    }

    private _getNumberOfDays(monthValue: string, yearValue: string): number {
        switch (monthValue) {
            case "2":
                if (yearValue !== "") {
                    const test = new Date(2020, 1, 29);
                    test.setFullYear(+yearValue); // workaround for the 0-99 idiotic rule
                    return test.getMonth() === 1 ? 29 : 28;
                } else {
                    return 29; // we don't know
                }
            case "4":
            case "6":
            case "9":
            case "11":
                return MAX_NUMBER_OF_DAYS - 1;
            default:
                return MAX_NUMBER_OF_DAYS;
        }
    }

    private _prepareNavigationMenu(): void {
        this._navigationMenu = [
            {
                type: QuickSettingsMenuType.Choice,
                onClick: () => {
                    this._jumpToSelected();
                    this._changeDetector.markForCheck();
                },
                visible: () => !!this._selectedDate,
                nameLC: ".Calendar_navigate_go_to_selected"
            },
            {
                type: QuickSettingsMenuType.Choice,
                onClick: () => {
                    this._jumpToToday();
                    this._changeDetector.markForCheck();
                },
                nameLC: ".Calendar_navigate_go_to_today"
            }
        ];
    }

    private _compareDates(d1: Date, d2: Date): -1 | 0 | 1 {
        const t1 = new Date(d1.getFullYear(), d1.getMonth(), d1.getDate()).valueOf();
        const t2 = new Date(d2.getFullYear(), d2.getMonth(), d2.getDate()).valueOf();
        if (t1 < t2) return -1;
        if (t1 > t2) return 1;
        return 0;
    }

    private _prepareCanSelectToday(): void {
        this._canSelectToday = true;
        const today = new Date();
        if (this._minDate != null) {
            if (this._compareDates(this._minDate, today) === 1) {
                this._canSelectToday = false;
                return;
            }
        }
        if (this._maxDate != null) {
            if (this._compareDates(this._maxDate, today) === -1) {
                this._canSelectToday = false;
            }
        }
    }
}
