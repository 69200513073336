import {
    Component,
    ViewEncapsulation,
    ChangeDetectionStrategy,
    ElementRef,
    Renderer2,
    inject
} from "@angular/core";

@Component({
    selector: "lg-loader-working",
    template: ``,
    host: {
        class: "lg-loader-working-wrapper"
    },
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LgLoaderWorkingComponent {
    private _elementRef = inject(ElementRef);
    private _renderer = inject(Renderer2);
    // eslint-disable-next-line accessor-pairs
    set _topOffset(value: number) {
        this._renderer.setStyle(this._elementRef.nativeElement, "top", value + "px");
    }
}
