/* ---------------------------------------------------------------------------------------------------------------------------------------- */

/**
 * This is a callback function used for filtering the items.
 */
export type IColumnFilterFunction =
    | {
          /**
           * For given item and column, decide if the item should be included in the result or not. It's up to the concrete function to decide whether
           * it needs the column value or the whole item parameter.
           *
           * @param columnValue is the item's property identified by the filter's key (in IColumnFilterList)
           * @param item is the object to be filtered
           */
          (columnValue: any, item: any): boolean;
          /**
           * Some of the filtering code will first call the function without any arguments to find out, of the filter is inactive and can be skipped for
           * the whole collection. The function can return "$empty" to indicate inactive state, anything else is taken as active. The call is wrapped
           * in try/catch block, so the test will work even if the function doesn't handle it.
           */
          (): any;
      }
    /**
     * For given item and column, decide if the item should be included in the result or not. It's up to the concrete function to decide whether
     * it needs the column value or the whole item parameter.
     *
     * @param columnValue is the item's property identified by the filter's key (in IColumnFilterList)
     * @param item is the object to be filtered
     */
    | ((columnValue: any, item: any) => boolean);

/**
 * Dictionary for filtering items. For given column (or property) name, the dictionary should contain truthy value for all property values that are
 * accepted. The dictionary must contain special key "$empty" to indicate that it's inactive (all items should be accepted)
 */
export interface IColumnFilterDictionary {
    [id: string]: any;
    [id: number]: any;
    $empty?: boolean;
}

/**
 * Dictionary defining all the filters applied to item during the filtering call. The key normally defines which column is given filter (stored as value)
 * applied to: for function-based filters that is optional, but for dictionary-based filters essential.
 *
 * If multiple filters need to refer to the same column, they can use key in the form 'column_name#1' - the # sign and following text will be removed.
 */
export interface IColumnFilterList {
    [id: string]: IColumnFilterDictionary | IColumnFilterFunction;
}

/**
 * Entry for preprocessed filter used by filterByColumnPrepare / filterByColumnPrepared, and internally by the pivot filters.
 */
type ColumnFilterDic = {
    isFunction: false;
    filter: IColumnFilterDictionary;
};

type ColumnFilterFunc = {
    isFunction: true;
    filter: IColumnFilterFunction;
};

export type ICleanedColumnFilter = {
    keyCleaned: string;
} & (ColumnFilterDic | ColumnFilterFunc);
