<div class="lg-dropdown-popup__wrapper" cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
    <ng-container *ngIf="!_shownAbove" [ngTemplateOutlet]="searchInput"></ng-container>

    <div
        *ngIf="definition?.extraTop"
        class="lg-dropdown-popup__extra lg-dropdown-popup__extra--top"
        [class.lg-dropdown-popup__extra--clickable]="
            definition.extraTop!.onClick || definition.extraTop!.url
        "
        (click)="_onExtraClick($event, definition.extraTop!)"
    >
        <lg-icon [icon]="definition.extraTop!.icon" [inline]="true"></lg-icon>
        {{ definition.extraTop!.name }}
    </div>

    <div class="lg-dropdown-popup__main">
        <div id="__LG_DROPDOWN_RENDERED_ITEMS__" class="lg-dropdown-popup__entries">
            <div
                class="lg-dropdown-popup__entries lg-dropdown-popup__entries--first"
                id="__LG_DROPDOWN_GROUP__"
            >
                <div class="lg-dropdown-popup__entries__group">
                    <div class="lg-dropdown-popup__entries__group__name">G</div>
                </div>
            </div>
            <div class="lg-dropdown-popup__entries" id="__LG_DROPDOWN_ENTRY__">
                <div class="lg-dropdown-popup__entries__entry">
                    <span class="lg-dropdown-popup__entries__entry__name">E</span>
                </div>
            </div>
            <div
                class="lg-dropdown-popup__entries lg-dropdown-popup__entries--last"
                id="__LG_DROPDOWN_ENTRY--LAST__"
            >
                <div class="lg-dropdown-popup__entries__entry">
                    <span class="lg-dropdown-popup__entries__entry__name">E</span>
                </div>
            </div>
            <div
                class="lg-dropdown-popup__entries lg-dropdown-popup__entries--first"
                id="__LG_DROPDOWN_ENTRY--FIRST__"
            >
                <div class="lg-dropdown-popup__entries__entry">
                    <span class="lg-dropdown-popup__entries__entry__name">E</span>
                </div>
            </div>
            <div
                class="lg-dropdown-popup__entries lg-dropdown-popup__entries--first lg-dropdown-popup__entries--last"
                id="__LG_DROPDOWN_ENTRY--FIRST--LAST__"
            >
                <div class="lg-dropdown-popup__entries__entry">
                    <span class="lg-dropdown-popup__entries__entry__name">E</span>
                </div>
            </div>
        </div>
    </div>

    <div
        *ngIf="_itemHeights"
        class="lg-dropdown-popup__main"
        #contentHolder
        lgScrollable
        lgScrollableAutoHide="true"
        lgScrollableWrapperClass="lg-dropdown-popup__scrollable"
        #scroller
    >
        <div
            *lgVirtualFor="
                let entry of _renderedEntries;
                let first = first;
                height: _getItemHeight;
                ensureVisible: _visible$;
                renderingBuffer: _renderingBuffer
            "
            class="lg-dropdown-popup__entries"
            [style.border-top-width.px]="first ? '0' : GROUP_SEPARATOR_HEIGHT"
            [class.lg-dropdown-popup__entries--first]="entry.isFirstEntryInGroup"
            [class.lg-dropdown-popup__entries--last]="entry.isLastEntryInGroup"
        >
            <div class="lg-dropdown-popup__entries__group" *ngIf="entry.isGroup">
                <div
                    *ngIf="entry.name"
                    class="lg-dropdown-popup__entries__group__name"
                    [class.lg-dropdown-popup__entries__group__name--with-help]="entry.help"
                >
                    {{ entry.name }}
                </div>
                <lg-icon
                    *ngIf="entry.help"
                    [icon]="'icon-help'"
                    [inline]="true"
                    class="lg-dropdown-popup__entries__group-help"
                    [lgTooltip]="entry.help"
                    [lgTooltipOptions]="_tooltipOptions"
                ></lg-icon>
            </div>

            <div
                *ngIf="!entry.isGroup"
                (click)="_onEntryClick(entry)"
                class="lg-dropdown-popup__entries__entry"
                [class.lg-dropdown-popup__entries__entry--focused]="
                    _cursorId !== _selectedCursorId && _cursorId === entry.id
                "
                [class.lg-dropdown-popup__entries__entry--disabled]="entry.disabled"
                [class.lg-dropdown-popup__entries__entry--selected]="
                    entry.id === _selectedCursorId && _highlightSelected
                "
            >
                <lg-icon
                    *ngIf="entry.icon"
                    [icon]="entry.icon.icon"
                    [title]="entry.icon.title"
                    [inline]="true"
                    [clickable]="entry.icon.clickable"
                    class="lg-icon--inline {{ entry.icon.iconClass }}"
                    (click)="_onIconClick($event, entry)"
                ></lg-icon>

                <span
                    [title]="_itemTooltips ? entry.name : ''"
                    class="lg-dropdown-popup__entries__entry__name"
                    [class.lg-dropdown-popup__entries__entry__name--with-help]="entry.help"
                >
                    <span *ngIf="_sanitizedFilter" [innerHtml]="_getHighlightedEntryName(entry)">
                    </span>
                    <span *ngIf="!_sanitizedFilter">
                        {{ entry.name }}
                    </span>
                </span>
                <lg-icon
                    *ngIf="entry.help"
                    [inline]="true"
                    class="lg-dropdown-popup__entries__entry-help"
                    [icon]="'icon-help'"
                    [lgTooltip]="entry.help"
                    [lgTooltipOptions]="_tooltipOptions"
                ></lg-icon>
            </div>
        </div>
    </div>

    <div
        *ngIf="definition?.extraBottom"
        class="lg-dropdown-popup__extra lg-dropdown-popup__extra--bottom"
        [class.lg-dropdown-popup__extra--clickable]="
            definition.extraBottom!.onClick || definition.extraBottom!.url
        "
        (click)="_onExtraClick($event, definition.extraBottom!)"
    >
        <lg-icon [icon]="definition.extraBottom?.icon" [inline]="true"></lg-icon>
        {{ definition.extraBottom?.name }}
    </div>

    <ng-container *ngIf="_shownAbove" [ngTemplateOutlet]="searchInput"></ng-container>
</div>

<ng-template #searchInput>
    <input
        *ngIf="!_hideSearch"
        type="text"
        class="input input--no-highlight input--no-border input--no-shadow"
        [ngClass]="{ 'input--condensed': _isControlCondensed }"
        placeholder="{{ 'FW._Directives.lgDropdown_Search_placeholder' | lgTranslate }}"
        [ngModel]="_quickFilter"
        (ngModelChange)="_onQuickFilterChange($event)"
        lgDefaultFocus
    />
</ng-template>
