import { InjectionToken } from "@angular/core";
import { LG_PREDEFINED_COLORS } from "./lg-colors";

export interface LgPredefinedChartColors {
    output: string;
    historic: string;
    input: string;
    input_darker: string;
    forecast_darker: string;
    forecast: string;
    forecast_lighter: string;
    budget_darker: string;
    budget: string;
    budget_lighter: string;
    efficiency_darker: string;
    efficiency: string;
    efficiency_lighter: string;
    benchmark_darker: string;
    benchmark: string;
    benchmark_lighter: string;
}

export enum LgColorPaletteIdentifiers {
    Cobalt = "LOGEX_COBALT",
    FinancialBlue = "FINANCIAL_BLUE",
    Mint = "MINT",
    OutcomesGreen = "OUTCOMES_GREEN",
    Green = "GREEN",
    Forecast = "FORECAST",
    SalmonRed = "SALMON_RED",
    Pink = "PINK",
    ValuePurple = "VALUE_PURPLE",
    Grey = "GREY",
    ClinicalOutcomes = "CLINICAL_OUTCOMES",
    Ichom = "ICHOM"
}

export type LgColorLightnessIdentifier = 10 | 20 | 30 | 40 | 50 | 60 | 70 | 80 | 90;

export interface LgColorBases {
    LOGEX_COBALT: string;
    FINANCIAL_BLUE: string;
    MINT: string;
    OUTCOMES_GREEN: string;
    GREEN: string;
    FORECAST: string;
    SALMON_RED: string;
    PINK: string;
    VALUE_PURPLE: string;
    GREY: string;
    CLINICAL_OUTCOMES: string;
    ICHOM: string;
}

export type LgHsluvColorBase = { [K in keyof Partial<LgColorBases>]: number[] };
export type LgSequentialPaletteByColor = { [K in keyof Partial<LgColorBases>]: string[] };

/**
 * Injection token used for specifying whether new palette should be used or not.
 * Injection comes from lgChartsModule and can be redefined using forRoot().
 * Defaults to false using factory so there is no need for `@Optional` decorator.
 */
export const LG_USE_NEW_COLOR_PALETTE = new InjectionToken<boolean>("LG_USE_NEW_COLOR_PALETTE", {
    factory: () => false
});
/**
 * Injection token used for specifying whether new chart labels should be used or not.
 * Injection comes from lgChartsModule and can be redefined using forRoot().
 * Defaults to false using factory so there is no need for `@Optional` decorator.
 */
export const LG_USE_NEW_LABELS = new InjectionToken<boolean>("LG_USE_NEW_LABELS", {
    factory: () => false
});
export type LgPredefinedColorKey = keyof typeof LG_PREDEFINED_COLORS;

export enum LgColorTypes {
    Categorical = "Categorical",
    SequentialByColor = "SequentialByColor",
    Sequential = "Sequential",
    Predefined = "Predefined",
    Own = "Own"
}

export interface LgCategoricalColorConfiguration {
    /**
     * Specifies the color type to use.
     * Defaults to Categorical.
     */
    colorType: LgColorTypes.Categorical;
}

export interface LgSequentialPaletteByColorConfiguration {
    /**
     * Specifies the color type to use.
     * Defaults to Categorical.
     */
    colorType: LgColorTypes.SequentialByColor;
    /**
     * Specifies the color base to use when working with LgColorTypes.SequentialByColor.
     */
    colorIdentifier: LgColorPaletteIdentifiers;
}

export interface LgPredefinedColorConfiguration {
    /**
     * Specifies the color type to use.
     * Defaults to Categorical.
     */
    colorType: LgColorTypes.Predefined;
    /**
     * Specifies the color keys to use when working with LgColorTypes.Predefined.
     */
    colorKeys: LgPredefinedColorKey[];
}

export interface LgOwnColorConfiguration {
    /**
     * Specifies the color type to use.
     * Defaults to Categorical.
     */
    colorType: LgColorTypes.Own;
    /**
     * Specifies own colors to use when working with LgColorTypes.Own.
     */
    colors: string[];
}

export type LgColorsConfiguration =
    | LgCategoricalColorConfiguration
    | LgSequentialPaletteByColorConfiguration
    | LgPredefinedColorConfiguration
    | LgOwnColorConfiguration;

export const LG_DEFAULT_COLOR_CONFIGURATION: LgColorsConfiguration = {
    colorType: LgColorTypes.Categorical
};
