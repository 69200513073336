<svg
    [ngSwitch]="_symbol"
    class="lg-chart-legend__item--line-chart-custom__icon"
    height="16"
    width="16"
>
    <line
        *ngSwitchCase="'line'"
        x1="0"
        y1="8"
        x2="16"
        y2="8"
        [style.stroke]="color"
        [style.stroke-width]="_lineStrokeWidth"
    />
    <line
        *ngSwitchCase="'line-dashed'"
        x1="0"
        y1="8"
        x2="16"
        y2="8"
        stroke-dasharray="5"
        [style.stroke]="color"
        [style.stroke-width]="_lineStrokeWidth"
    />
    <rect
        *ngSwitchCase="'square'"
        width="16"
        height="16"
        [style.fill]="color"
        [style.fill-opacity]="opacity"
        [ngStyle]="{ 'stroke-width': _symbolBorderWidth }"
        [style.stroke]="color"
    />
    <circle
        *ngSwitchCase="'circle'"
        cx="8"
        cy="8"
        r="5"
        [style.fill]="color"
        [style.fill-opacity]="opacity"
        [ngStyle]="{ 'stroke-width': _symbolBorderWidth }"
        [style.stroke]="color"
    />
    <polygon
        *ngSwitchCase="'triangle'"
        points="8,3 2,13 14,13"
        [style.fill]="color"
        [style.fill-opacity]="opacity ?? 1"
        [ngStyle]="{ 'stroke-width': _symbolBorderWidth }"
        [style.stroke]="color"
    />
    <ng-container *ngSwitchCase="'line-with-circle'">
        <line
            x1="0"
            y1="8"
            x2="16"
            y2="8"
            [style.stroke]="color"
            [style.stroke-width]="_lineStrokeWidth"
        />
        <circle cx="8" cy="8" r="4" [style.stroke]="color" stroke-width="2" fill="white" />
    </ng-container>
</svg>
