import { InjectionToken } from "@angular/core";
import { ILgFormatterOptions, ILgFormatter } from "../implementation";

export const LG_FORMATTER_FACTORIES = new InjectionToken<ILgFormatterFactory[]>(
    "LgFormatterFactories"
);

export interface ILgFormatterFactory {
    names: string[];
    create(defaultOptions?: ILgFormatterOptions): ILgFormatter<any>;
}
