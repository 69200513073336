<div class="lg-sidebar-impersonation">
    <div class="lg-sidebar-impersonation__title" lgTranslate=".Title"></div>
    <div class="lg-sidebar-impersonation__info" lgTranslate=".Info"></div>
    <div class="lg-sidebar-impersonation__impersonatee">
        <div
            class="lg-sidebar-impersonation__impersonatee__title"
            lgTranslate=".CurrentlyImpersonating"
        ></div>
        <div class="lg-sidebar-impersonation__impersonatee__name">
            {{ userInfo.name }}
        </div>
        <div class="lg-sidebar-impersonation__impersonatee__account">
            {{ userInfo.login }}
        </div>
    </div>
    <lg-button
        *ngIf="showEndImpersonation"
        buttonClass="button--small button--primary lg-sidebar-impersonation__end-impersonation"
        textLc=".EndImpersonation"
        (click)="_endImpersonation()"
    ></lg-button>
</div>
