import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    inject,
    NgZone,
    Renderer2,
    ViewChild
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
    selector: "lg-digest-indicator",
    template: `
        <svg width="24px" height="24px" xmlns="http://www.w3.org/2000/svg" viewbox="0 0 24 24" #svg>
            <circle r="8" cx="12" cy="12" fill="#f0f0f0" stroke="#808080" stroke-width="1" />
            <path
                d="M12 4 A 8 8 0 0 1 12 20"
                fill="#a0a0a0"
                stroke="#808080"
                stroke-width="1"
            ></path>
        </svg>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LgDigestIndicatorComponent {
    private _renderer = inject(Renderer2);
    private _ngZone = inject(NgZone);
    @ViewChild("svg", { static: true }) public _svg!: ElementRef;

    private _angle = 0;
    private readonly _speed = 10;

    constructor() {
        this._ngZone.onMicrotaskEmpty.pipe(takeUntilDestroyed()).subscribe(() => {
            this._angle += this._speed;
            if (this._angle >= 360) this._angle -= 360;
            this._renderer.setStyle(
                this._svg.nativeElement,
                "transform",
                `rotate(${this._angle}deg)`
            );
        });
    }
}
