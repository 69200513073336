import { InjectionToken } from "@angular/core";

export const LG_LOCALIZATION_SETTINGS = new InjectionToken<LgLocalizationSettings>(
    "LG_LOCALIZATION_SETTINGS"
);

export type LocalizationJson = { [key: string]: LocalizationJson | string };

export interface LgLocalizationSettings {
    readonly bootstrapDone: Promise<void>;
    readonly languages: Record<string, LocalizationJson[]>;
    readonly fallbackLanguage: string;
    readonly preferredLanguage: string;
    readonly availableLanguages: string[];
    readonly locale: string;
    readonly currency: string;

    addStrings(lang: string, strings: LocalizationJson): void;
    setPreferredLanguage(lang: string): void;
    switchCurrentLanguage(lang: string): void;
}
