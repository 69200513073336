<ng-template #defaultTemplate [lgChartTemplateContextType]="this" let-context>
    <ng-container *ngIf="context.columnsWithinGroup">
        <div class="lg-d3-tooltip__tip-name">
            {{ context.columnsWithinGroup[0].column }}
        </div>
        <table class="lg-chart__tooltip__table">
            <tr
                *ngFor="let item of context.columnsWithinGroup"
                class="lg-chart__tooltip__table__item"
                [class.lg-chart__tooltip__table__item--not-active]="
                    context.currentColumn && context.currentColumn.group !== item.group
                "
            >
                <td class="lg-chart__tooltip__table__item__color_column">
                    <span
                        [style.background]="
                            context.groupToLegendDefinitionDictionary[item.group]?.color
                        "
                        [style.opacity]="
                            context.groupToLegendDefinitionDictionary[item.group]?.opacity || 1
                        "
                    ></span>
                </td>
                <td class="lg-chart__tooltip__table__item__name_column">{{ item.groupName }}:</td>
                <td align="right" class="lg-chart__tooltip__table__item__value_column">
                    {{ _numberFormat(item.value) }}
                </td>
            </tr>
        </table>
    </ng-container>
</ng-template>

<div
    #chartWithLegend
    class="lg-grouped-bar-horizontal-chart"
    [class.lg-grouped-bar-horizontal-chart--flex-row]="legendOptions.position === 'right'"
    [class.lg-grouped-bar-horizontal-chart--flex-column]="legendOptions.position === 'bottom'"
    [style.height.px]="height"
    [style.width.px]="width"
>
    <div #chart></div>

    <lg-chart-legend
        *ngIf="legendOptions.visible"
        [className]="legendOptions.className"
        [definition]="_legendDefinition"
        [position]="legendOptions.position"
        [cropLongNames]="legendOptions.cropLongNames ?? false"
        [wrapMultilineDefinition]="legendOptions.wrapMultilineDefinition ?? false"
        [width]="_legendWidth"
        (onClick)="_onLegendItemClick($event)"
    >
    </lg-chart-legend>
</div>
