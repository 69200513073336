<div class="lg-month-range-slider__background-track">
    <div
        #focusHandle
        class="lg-month-range-slider__focus-handle"
        [style.left.%]="_focusHandlePosition"
    ></div>
</div>

<div class="lg-month-range-slider__background-bar"></div>

<div
    *ngIf="_hasInvalidBar()"
    class="lg-month-range-slider__invalid-bar"
    [ngClass]="{
        'lg-month-range-slider__invalid-bar--on-first': _invalidStartsOnFirst(),
        'lg-month-range-slider__invalid-bar--on-last': _invalidEndsOnLast()
    }"
>
    <div
        class="lg-month-range-slider__invalid-bar__strip"
        [style.width.%]="_invalidStripWidth"
        [style.left.%]="_invalidStripLeftPosition"
    ></div>
</div>

<div class="lg-month-range-slider__active-bar" *ngIf="canChange">
    <div
        class="lg-month-range-slider__active-bar__strip"
        [style.width.%]="_stripWidth"
        [style.left.%]="_stripLeftPosition"
    ></div>
</div>
<div class="lg-month-range-slider__ruler">
    <div
        *ngFor="let ticker of _tickers; let index = index"
        class="lg-month-range-slider__ruler__tick"
        [ngClass]="{
            'lg-month-range-slider__ruler__tick--highlighted': ticker.css === 'highlight',
            'lg-month-range-slider__ruler__tick--zero': ticker.css === 'zero',
            'lg-month-range-slider__ruler__tick--active': _value
                ? _value.from <= ticker.value && ticker.value <= _value.to
                : false,
            'lg-month-range-slider__ruler__tick--invalid': _isTickInvalid(ticker)
        }"
        [style.left.%]="ticker.position"
    ></div>
</div>
<div *ngIf="canChange" #track class="lg-month-range-slider__track">
    <a
        href="#"
        #handleFrom
        class="isDisabled ? lg-month-range-slider__track__handle__element lg-month-range-slider__track__handle__element--not-editable : lg-month-range-slider__track__handle__element"
        [style.left.%]="_fromHandlePosition"
        (mousedown)="_onMouseDown(true)"
    >
        <div class="lg-month-range-slider__track__handle__element"></div>
    </a>
    <a
        href="#"
        #handleTo
        class="isDisabled ? lg-month-range-slider__track__handle__element lg-month-range-slider__track__handle__element--not-editable : lg-month-range-slider__track__handle__element"
        [style.left.%]="_toHandlePosition"
        (mousedown)="_onMouseDown(false)"
    >
        <div class="lg-month-range-slider__track__handle__element"></div>
    </a>
</div>
