import ldEach from "lodash-es/each";
import ldCompact from "lodash-es/compact";
import ldAssign from "lodash-es/assign";
import ldMap from "lodash-es/map";
import ldIsEmpty from "lodash-es/isEmpty";
import ldUniq from "lodash-es/uniq";
import ldFilter from "lodash-es/filter";
import ldKeyBy from "lodash-es/keyBy";
import { Observable, forkJoin, of, isObservable } from "rxjs";
import { map, switchMap, shareReplay } from "rxjs/operators";

import { StringKeyOf } from "@logex/framework/types";
import { LgTranslateService } from "@logex/framework/lg-localization";
import { expandPackedRows, retryOnNetworkError } from "@logex/framework/utilities";
import { ServerGatewayBase } from "./ServerGatewayBase";
import { IAppSession, LG_APP_SESSION } from "../application";
import {
    CustomDisplayNameFormatter,
    DefinitionDisplayMode,
    DefinitionPostProcessor,
    DefinitionSection,
    GeneratorDefinitionSection,
    IDefinitionEntry,
    IDefinitions,
    LookupGeneratorDefinitionSection,
    IDefinitionSectionInfo,
    OrderByType,
    ItemType
} from "./definitions.types";
import { inject } from "@angular/core";

// ----------------------------------------------------------------------------------
//
// ----------------------------------------------------------------------------------
//
abstract class DefinitionEntryBase<TDefinitions> implements IDefinitionEntry {
    private _displayName: string | null = null;
    private _orderBy: any;

    // ----------------------------------------------------------------------------------
    //
    protected abstract _getDefinitions(): ServerDefinitionsBase<TDefinitions>;

    // ----------------------------------------------------------------------------------
    //
    protected abstract _getDefinitionSection(): DefinitionSection;

    // ----------------------------------------------------------------------------------
    //
    get displayName(): string {
        if (this._displayName === null) {
            this._displayName = this.getDisplayName();
        }

        return this._displayName;
    }

    // ----------------------------------------------------------------------------------
    //
    get orderBy(): any {
        if (this._orderBy == null) {
            const section = this._getDefinitionSection();
            this._orderBy = (this as any)[section.orderByField ?? ""];
        }

        return this._orderBy;
    }

    // ----------------------------------------------------------------------------------
    //
    getDisplayName(displayMode?: DefinitionDisplayMode): string {
        const section = this._getDefinitionSection();
        return getDisplayNameImplementation(
            section,
            (this as any)[section.codeField ?? ""],
            this,
            displayMode,
            () => (this as any)[section.nameField ?? ""]
        );
    }
}

// ----------------------------------------------------------------------------------
//
type Constructor<T = {}> = new (...args: any[]) => T;

function makeDefinitionEntryClass<TDefinitions, TEntry>(
    definitions: ServerDefinitionsBase<TDefinitions>,
    section: DefinitionSection
): Constructor<TEntry & IDefinitionEntry> {
    class DefinitionEntry extends DefinitionEntryBase<TDefinitions> implements IDefinitionEntry {
        constructor(entry: TEntry) {
            super();
            ldAssign(this, entry);
        }

        protected _getDefinitions(): ServerDefinitionsBase<TDefinitions> {
            return definitions;
        }

        protected _getDefinitionSection(): DefinitionSection {
            return section;
        }
    }

    return DefinitionEntry as any as Constructor<TEntry & IDefinitionEntry>;
}

// ----------------------------------------------------------------------------------
//
export class ServerDefinitionsBase<TDefinitions>
    extends ServerGatewayBase
    implements IDefinitions<TDefinitions>
{
    // ----------------------------------------------------------------------------------
    // Dependencies
    protected _appSession: IAppSession = inject(LG_APP_SESSION);
    protected _translate: LgTranslateService = inject(LgTranslateService);

    constructor() {
        super();
    }

    // ----------------------------------------------------------------------------------
    // Fields
    private _allSections: Record<string, DefinitionSection> = {};

    // ----------------------------------------------------------------------------------
    //
    protected def<TItem>(
        customDisplayNameFormatter?: CustomDisplayNameFormatter<any, TItem>,
        postProcessor?: DefinitionPostProcessor<TItem>
    ): Record<string, TItem> {
        return { $dummy: true, customDisplayNameFormatter, postProcessor } as any;
    }

    // ----------------------------------------------------------------------------------
    //
    protected generate<TItem, TResult>(
        options: GeneratorDefinitionSection<TItem, TResult>
    ): TResult {
        return { $dummy: true, generatorOptions: options } as any;
    }

    // ----------------------------------------------------------------------------------
    //
    protected generateLookup<TItem>(
        options: LookupGeneratorDefinitionSection<TItem>
    ): Record<string, TItem> {
        return { $dummy: true, generatorOptions: options, generateLookup: true } as any;
    }

    // ----------------------------------------------------------------------------------
    //
    protected init(): void {
        const names = Object.keys(this).filter(
            name => (this as any)[name] && !!(this as any)[name].$dummy
        );

        // Collect all available sections
        this._allSections = names.reduce(
            (a, name) => {
                a[name] = {
                    name,
                    isLoaded: false,
                    customDisplayNameFormatter: (this as any)[name].customDisplayNameFormatter,
                    postProcessor: (this as any)[name].postProcessor,
                    generatorOptions: (this as any)[name].generatorOptions,
                    generateLookup: (this as any)[name].generateLookup
                };
                return a;
            },
            {} as Record<string, DefinitionSection>
        );

        // Generate getters that warns if requested definition is not loaded yet
        ldEach(this._allSections, section => {
            Object.defineProperty(this, section.name, {
                get: () => {
                    if (section.data == null) {
                        this._console.error(`Definition "${section.name}" is not loaded`);
                    }
                    return section.data;
                },
                set: () => {
                    throw Error("Definitions are read-only.");
                }
            });
        });
    }

    // ----------------------------------------------------------------------------------
    //
    load(...requiredDefinitions: Array<StringKeyOf<TDefinitions>>): Observable<void> {
        let sectionsToLoad: Array<StringKeyOf<TDefinitions>>;
        if (!ldIsEmpty(requiredDefinitions)) {
            sectionsToLoad = ldUniq(requiredDefinitions);
        } else {
            return of(undefined);
            // this._console.warn( "load() method call without parameters detected." );
            // sectionsToLoad = _.keys( this._allSections ) as ( StringKeyOf<TDefinitions> )[];
        }

        return forkJoin(
            ldCompact(
                sectionsToLoad.map((section: StringKeyOf<TDefinitions>) => {
                    if (this.isLoaded(section)) {
                        return of(this._cached(section));
                    }
                    return this._doLoad(section);
                })
            )
        ).pipe(map(() => undefined));
    }

    // ----------------------------------------------------------------------------------
    //
    reload(...requiredDefinitions: Array<StringKeyOf<TDefinitions>>): Observable<void> {
        // todo: fix typing
        const sectionsToLoad = !ldIsEmpty(requiredDefinitions)
            ? ldUniq(requiredDefinitions as string[])
            : ldFilter(Object.keys(this._allSections), (x: StringKeyOf<TDefinitions>) =>
                  this.isLoaded(x)
              );

        return forkJoin(
            ldCompact(ldMap(sectionsToLoad, (section: string) => this._doLoad(section as any)))
        ).pipe(map(() => undefined));
    }

    // ----------------------------------------------------------------------------------
    //
    getSection(sectionName: StringKeyOf<TDefinitions>, checkIsLoaded = false): DefinitionSection {
        // todo: fix typing
        const section = this._allSections[sectionName as string];

        if (section == null) {
            throw new Error(`AppDefinitions: Unknown definition ${sectionName} have been required`);
        }

        if (checkIsLoaded && !section.isLoaded) {
            throw new Error(`Definition "${section.name}" is not loaded`);
        }
        return section;
    }

    // ----------------------------------------------------------------------------------
    //
    protected _doLoad(sectionName: StringKeyOf<TDefinitions>): Observable<Record<string, any>> {
        const section = this.getSection(sectionName);

        if (section.observable != null) {
            // Already loading
            return section.observable;
        }

        if (section.generatorOptions) {
            section.observable = this._generateSection(section).pipe(
                map(sourceData => {
                    section.codeField = section.generatorOptions?.codeField;
                    section.codeType = section.generatorOptions?.codeType;
                    section.nameField = section.generatorOptions?.nameField;
                    section.orderByField = section.generatorOptions?.orderByField;
                    section.displayMode = section.generatorOptions?.displayMode;
                    const entryClass = makeDefinitionEntryClass(this, section);

                    if (section.generateLookup) {
                        let data: Record<string, any>;

                        if (sourceData.length !== 0) {
                            const wrapped = ldMap(sourceData, x => new entryClass(x));
                            data = ldKeyBy(wrapped, section.codeField);
                        } else {
                            data = {};
                        }
                        section.data = data;
                        if (section.generatorOptions?.fallbackValue) {
                            section.fallbackValue = new entryClass(
                                section.generatorOptions.fallbackValue
                            );
                        }
                    } else {
                        section.data = sourceData;
                        // without generateLookup we take data as they come
                        if (section.generatorOptions?.fallbackValue)
                            section.fallbackValue = section.generatorOptions.fallbackValue;
                    }

                    section.isLoaded = true;
                    section.observable = null;

                    return section.data ?? {};
                }),
                shareReplay(1)
            );
        } else {
            section.observable = this._loadSection(section).pipe(
                map(sectionInfo => {
                    section.codeField = sectionInfo.codeField;
                    section.codeType = sectionInfo.codeType;
                    section.nameField = sectionInfo.nameField;
                    section.orderByField = sectionInfo.orderByField;
                    section.displayMode = sectionInfo.displayMode;

                    let data: Record<string, any>;
                    const entryClass = makeDefinitionEntryClass(this, section);

                    if (sectionInfo.rows.length !== 0) {
                        let expanded = expandPackedRows(sectionInfo.rows);
                        if (section.postProcessor) expanded = section.postProcessor(expanded);
                        const wrapped = ldMap(expanded, x => new entryClass(x));
                        data = ldKeyBy(wrapped, section.codeField);
                    } else {
                        data = {};
                    }

                    section.data = data;
                    section.isLoaded = true;
                    section.observable = null;
                    if (sectionInfo.fallbackValue) {
                        section.fallbackValue = new entryClass(sectionInfo.fallbackValue);
                    }

                    return data;
                }),
                shareReplay(1)
            );
        }

        return section.observable;
    }

    // ----------------------------------------------------------------------------------
    //
    protected _generateSection(section: DefinitionSection): Observable<any> {
        const options = section.generatorOptions!;
        let requirement: Observable<void>;
        if (options.dependsOn) {
            requirement = this.load(...(options.dependsOn as any[]));
        } else {
            requirement = of(undefined);
        }

        return requirement.pipe(
            switchMap(() => {
                const result = options.generator();
                if (!isObservable(result)) return of(result);
                return result;
            })
        );
    }

    // ----------------------------------------------------------------------------------
    //
    protected _loadSection(section: DefinitionSection): Observable<IDefinitionSectionInfo> {
        return this._get<IDefinitionSectionInfo>("api/definitions", {
            params: {
                name: section.name,
                clientId: this._appSession.clientId,
                scenarioId: this._appSession.scenarioId
            }
        }).pipe(retryOnNetworkError());
    }

    // ----------------------------------------------------------------------------------
    //
    protected _cached<TField extends StringKeyOf<TDefinitions>>(
        sectionName: TField
    ): TDefinitions[TField] {
        return this.getSection(sectionName).data as any;
    }

    // ----------------------------------------------------------------------------------
    //
    clearCache(...sections: Array<keyof TDefinitions>): void {
        // todo: fix typing
        const sectionsToClear = !ldIsEmpty(sections)
            ? ldUniq(sections)
            : (Object.keys(this._allSections) as any as Array<keyof TDefinitions>);

        sectionsToClear.forEach(x => {
            this._allSections[x as string].isLoaded = false;
        });
    }

    // ----------------------------------------------------------------------------------
    //
    isLoaded<TField extends StringKeyOf<TDefinitions>>(name: TField): boolean {
        return this.getSection(name).isLoaded;
    }

    // ----------------------------------------------------------------------------------
    //
    getEntry<TField extends StringKeyOf<TDefinitions>>(
        sectionName: TField,
        code: any
    ): ItemType<TDefinitions, TField> {
        const section = this.getSection(sectionName, true);
        return this._getEntry(section, code);
    }

    private _getEntry(section: DefinitionSection, code: any): any {
        let item = section.data && section.data[code];

        if (item === undefined && section.fallbackValue !== undefined) {
            item = section.fallbackValue;
        }

        return item;
    }

    // ----------------------------------------------------------------------------------
    //
    getDisplayName(
        sectionName: StringKeyOf<TDefinitions>,
        code: any,
        displayMode?: DefinitionDisplayMode
    ): string {
        const item: any = this.getEntry(sectionName, code);

        if (item !== undefined) {
            return item.getDisplayName(displayMode);
        }

        // If definition item is not found, show it as "Unknown"
        const section = this.getSection(sectionName);
        return getDisplayNameImplementation(section, code, null, displayMode, () =>
            this._translate.translate("FW.UNKNOWN")
        );
    }

    // ----------------------------------------------------------------------------------
    //
    getOrderBy<TField extends StringKeyOf<TDefinitions>>(
        sectionName: TField,
        code: any
    ): OrderByType<TDefinitions, TField> | null {
        if (code === null) {
            return null;
        }

        const item = this.getEntry(sectionName, code);

        if (item !== undefined) {
            const value = (item as DefinitionEntryBase<any>).orderBy;

            if (value !== undefined) {
                return value;
            }
        }

        return null;
    }

    // ----------------------------------------------------------------------------------
    //
    getFallbackValue<TField extends StringKeyOf<TDefinitions>>(
        sectionName: TField
    ): ItemType<TDefinitions, TField> {
        const section = this.getSection(sectionName);

        if (!section.isLoaded) {
            throw new Error(`Definition "${sectionName}" is not loaded`);
        }

        return section.fallbackValue;
    }
}

// ----------------------------------------------------------------------------------
//
function getDisplayNameImplementation(
    section: DefinitionSection,
    code: any,
    item: any,
    displayMode: DefinitionDisplayMode | undefined,
    nameFn: () => string
): string {
    if (displayMode == null) displayMode = section.displayMode ?? "codeAndName";

    if (section.customDisplayNameFormatter != null) {
        const result = section.customDisplayNameFormatter(code, item, displayMode);
        if (result != null) {
            return result;
        }
    }

    if (code === null) {
        return "-";
    }

    switch (displayMode) {
        case "code":
            return code?.toString() ?? "-";

        case "name":
            return nameFn();

        case "codeAndName":
            return `${getDisplayNameImplementation(
                section,
                code,
                item,
                "code",
                nameFn
            )} - ${getDisplayNameImplementation(section, code, item, "name", nameFn)}`;

        default:
            throw new Error(
                `Display mode "${displayMode}" is not supported by definition "${section.name}"`
            );
    }
}
