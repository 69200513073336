import {
    ChangeDetectionStrategy,
    Component,
    forwardRef,
    HostBinding,
    Input,
    OnInit,
    ViewEncapsulation
} from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { LgDropdownComponent } from "./lg-dropdown.component";
import { LgReferenceDropdownPopupComponent } from "./lg-reference-dropdown-popup.component";

@Component({
    selector: "lg-reference-dropdown",

    templateUrl: "./lg-dropdown.component.html",

    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,

    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => LgReferenceDropdownComponent),
            multi: true
        }
    ]
})
export class LgReferenceDropdownComponent<T extends number | string>
    extends LgDropdownComponent<T>
    implements OnInit
{
    /**
     * Specifies if dropdown in header.
     *
     * @default false
     */
    @Input() isHeader = false;

    constructor() {
        super();
        this.className = "lg-reference-dropdown";
        this.popupClassName = "lg-reference-dropdown-popup";
        this.hideSearch = true;
    }

    override ngOnInit(): void {
        if (this.isHeader) this.className += " lg-reference-dropdown__pivot-header";
    }

    @HostBinding("class")
    readonly outerClass = "reference-drop-outer";

    protected override _getPopupClass(): typeof LgReferenceDropdownPopupComponent {
        return LgReferenceDropdownPopupComponent;
    }
}
