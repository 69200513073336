/* eslint-disable @angular-eslint/template/conditional-complexity */
import ldSize from "lodash-es/size";
import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from "@angular/core";
import { toInteger, toBoolean } from "@logex/framework/utilities";
import { NgModel } from "@angular/forms";

type LgGridInputType = "text" | "float" | "eur" | "money" | "int" | "percent" | "moneyScaled";

@Component({
    selector: "lg-grid-input",
    templateUrl: "./lg-grid-input.component.html",
    host: {
        "[class]": "_class"
    },
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LgGridInputComponent {
    // #region Inputs/Outputs

    /**
     * Specifies the type of formatter.
     * Can be one of: "text" | "float" | "eur" | "money" | "int" | "percent" | "moneyScaled"
     *
     * @param val
     */
    @Input() set type(val: LgGridInputType) {
        this._type = val;
        switch (val.toLowerCase()) {
            case "text":
                this._formatter = "text";
                this._align = "";
                break;
            case "float":
                this._formatter = "float";
                break;
            case "eur":
            case "money":
                this._formatter = "money";
                break;
            case "moneyScaled":
                this._formatter = "moneyScaled";
                break;
            case "int":
                this._formatter = "int";
                this._decimals = 0;
                break;
            case "percent":
                this._formatter = "percent";
                break;
            default:
                this._formatter = val;
        }
    }

    get type(): LgGridInputType {
        return this._type;
    }

    /**
     * Specifies the number of decimals for formatting
     *
     * @param decimals default = 2
     */
    @Input() set decimals(val: number) {
        this._decimals = toInteger(val, 2);
    }

    get decimals(): number {
        return this._decimals;
    }

    /**
     * Specifies the number of viewScale for formatting
     *
     * @param decimals default = 0
     */
    @Input() set viewScale(val: number) {
        this._viewScale = toInteger(val, 0);
    }

    get viewScale(): number {
        return this._viewScale;
    }

    /**
     * Specifies whether value should have plus or minus sign
     *
     * @param forceSign default = false
     */
    @Input() set forceSign(val: boolean) {
        this._forceSign = toBoolean(val);
    }

    get forceSign(): boolean {
        return this._forceSign;
    }

    /**
     * Specifies whether the value should be classified as factor. Default scale for percent is 2.
     *
     * @example [value]="42" => visible value is 4,200.00 (while asFactor is false)
     * @param asFactor default = false
     */
    @Input() set asFactor(val: boolean) {
        this._asFactor = (val as any) === "" ? true : toBoolean(val);
    }

    get asFactor(): boolean {
        return this._asFactor;
    }

    // Update the model even for invalid state?s
    @Input() changeForInvalid = false;

    /**
     * Specifies the value for [lgDefaultFocus] directive (for initial input focusing)
     *
     * @param defaultFocus default = false
     */
    @Input() defaultFocus = false;

    /**
     * Specifies the placeholder in input. If "true" model value will be used
     *
     * @param placeholder default = undefined
     */
    @Input() placeholder?: string | boolean | null | undefined;

    /**
     * Specifies the minimum or maximum value for displayed effective value
     *
     * @param effectiveCeiling default = undefined
     */
    @Input() effectiveCeiling?: number | null;

    /**
     * Specifies the minimum value to show
     *
     * @param min default = undefined
     */
    @Input() min?: number | null;

    /**
     * Specifies the maximum value to show
     *
     * @param max default = undefined
     */
    @Input() max?: number | null;

    /**
     * Selector of the closest element on which to set class 'lg-contains-focus' when input is focused
     * Closest element meaning: https://developer.mozilla.org/en-US/docs/Web/API/Element/closest
     *
     * @param markFocusOn
     */
    @Input() markFocusOn?: string | null;

    // note: the following inputs are used only in the template and therefore we don't need to coerce them
    /**
     * Specifies whether inpiut can be edited
     *
     * @param noEdit default = false
     */
    @Input() noEdit = false; // todo: animation

    /**
     * Specifies whether the value be visible only during hover
     *
     * @param hoverOnly default = false
     */
    @Input() hoverOnly = false;

    /**
     * Specifies whether current row has subinputs. If true, the indicator will be displayed.
     * Consider using hasSubinputs instead.
     *
     * @param overriden default = false
     */
    @Input() overriden = false;

    /**
     * Specifies if arrow icon need to be rendered. By default arrow will be shown for overriden=true,
     * but you might want to have more control over it
     *
     * @param hasSubinputs default = false
     */
    @Input() hasSubinputs = false;

    /**
     * TODO: Consider to remove
     * Specifies whether the value is overriden. If true, the component remembers if the value was changed, but doesn't change color
     *
     * @param overridenSilent default = false
     */
    @Input() overridenSilent = false;

    /**
     * Specifies the value that is shown in the input.
     *
     * @param effective default = null
     */
    @Input("effective") effective: any = null;

    /**
     * Specified whether the input should be always visible.
     *
     * @param specified default = false
     */
    @Input() specified = false;

    /**
     * Specifies whether the value is missing. If true, the value is required
     *
     * @param missing default = false
     */
    @Input() missing = false;

    /**
     * Specifies whether the value is invalid.
     *
     * @param invalid default = false
     */
    @Input() invalid = false;

    /**
     * Specifies the ngModel for this value. This value is not shown within the input, however, the component holds the value.
     *
     * @param model
     */
    @Input({ required: true }) model!: any;

    /**
     * Specifies whether input field should be large (36px), default 24px.
     *
     * @param model
     */
    @Input() isInputFieldSmall = true;

    /**
     * Defines the event upon which the form control value and validity update. Possible values: blur, change, submit. Defaults to blur.
     *
     * @param modelUpdateOn "blur" | "change" | "submit"
     */
    @Input("modelUpdateOn") modelUpdateOn: "blur" | "change" | "submit" = "blur";

    /**
     * Specifies the class of the host component
     *
     * @param class default = table__column__input
     */
    @Input("class") _class = "table__column__input";

    @Input({ required: false }) set autoComplete(value: string) {
        this._autoComplete = value;
    }

    get autoComplete(): string {
        return this._autoComplete;
    }

    /**
     * Emits the event when input looses focus
     *
     * @param blur
     */
    @Output("lgBlur") readonly lgBlur = new EventEmitter<FocusEvent>();

    /**
     * Emits the event when input focused
     *
     * @param focus
     */
    @Output("lgFocus") readonly lgFocus = new EventEmitter<FocusEvent>();

    /**
     * Emits the event when input has received focus, after the focus event
     *
     * @param focusin
     */
    @Output("lgFocusIn") readonly lgFocusIn = new EventEmitter<FocusEvent>();

    /**
     * Emits the event when input has lost focus, after the blur event
     *
     * @param focus
     */
    @Output("lgFocusOut") readonly lgFocusOut = new EventEmitter<FocusEvent>();

    /**
     * Emits the value whenever it is changed
     *
     * @param modelChange
     */
    @Output("modelChange") readonly modelChange = new EventEmitter<any>();

    /**
     * Emits the value after change. This event is async and allows proper propagation of input overrides in the event handler
     */
    @Output("postChange") readonly postChange = new EventEmitter<any>(true);
    // #endregion

    _formatter = "float";
    _align = "input--right-align";
    _hasError = false;
    _autoComplete = "off";

    private _type: LgGridInputType = "float";
    private _decimals = 2;
    private _viewScale = 0;
    private _forceSign = false;
    private _asFactor = false;

    constructor() {
        // empty
    }

    _handleModelChange(value: any, input: NgModel): void {
        // TODO: find our why C2020 uses this
        if (this.type === "text" && value == null) {
            value = "";
        }
        // TODO: this still doesn't behave completely how we want to, namely going input -> invalid text -> empty doesn't trigger the event if missing=true
        const requiredOnly = input.invalid && input.errors?.required && ldSize(input.errors) === 1;
        if (input.valid || requiredOnly || this.changeForInvalid) {
            this.modelChange.next(value);
            this.postChange.next(value);
        }
    }

    _onBlur(event: FocusEvent): void {
        this.lgBlur.next(event);
    }

    _onFocus(event: FocusEvent): void {
        this.lgFocus.next(event);
    }

    _onFocusIn(event: FocusEvent): void {
        this.lgFocusIn.next(event);
    }

    _onFocusOut(event: FocusEvent): void {
        this.lgFocusOut.next(event);
    }
}
