<div class="form-row__controls form-row__controls--right-align">
    <label class="form-row__label" style="align-self: center; width: auto">
        {{ "FW._Common.Filters" | lgTranslate }}:
    </label>
    <div
        *ngFor="let entry of _filterSet.visibleDefinitions; trackBy: _entryIdentity"
        class="lg-filterset-form-row__item"
        [attr.lg-filter-id]="entry.id"
    >
        <lg-filterset-list-item
            [definition]="entry"
            [list]="_filterSet"
            (filterChange)="_filterChanged(entry)"
        ></lg-filterset-list-item>
    </div>
</div>
