import ldIsNumber from "lodash-es/isNumber";

export function toNumber(value: any, fallback?: number): number;
export function toNumber(
    value: any,
    fallback: number,
    min: number | null,
    max: number | null
): number;
export function toNumber(
    value: any,
    fallback?: number,
    min?: number | null,
    max?: number | null
): number {
    if (value == null) {
        value = fallback || 0;
    } else {
        if (!ldIsNumber(value)) value = Number(value);
        if (isNaN(value)) value = fallback || 0;
    }
    if (min != null && min > value) value = min;
    if (max != null && max < value) value = max;

    return value;
}
