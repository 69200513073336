import { ChangeDetectionStrategy, Component, HostListener } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { LgTranslatePipe, useTranslationNamespace } from "@logex/framework/lg-localization";
import { LgPanelResizeAction, LgPanelResizeTypeDefinition } from "../service";
import { LgIconComponent } from "@logex/framework/ui-core";
import { NgForOf } from "@angular/common";

@Component({
    selector: "lg-panel-resize-menu-popup",
    templateUrl: "./lg-panel-resize-menu-popup.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    viewProviders: [useTranslationNamespace("FW._Directives._lgPanelGrid")],
    imports: [LgIconComponent, LgTranslatePipe, NgForOf],
    host: {
        class: "lg-panel-resize-menu-popup"
    }
})
export class LgPanelResizeMenuPopupComponent {
    _items: LgPanelResizeTypeDefinition[] = [];

    @HostListener("mouseleave")
    _onMouseOut(): void {
        this._result.next("hide");
    }

    private _result = new Subject<LgPanelResizeAction | "hide">();

    initialize(items: LgPanelResizeTypeDefinition[]): Observable<LgPanelResizeAction | "hide"> {
        this._items = items;
        return this._result.asObservable();
    }

    _onItemClick(item: LgPanelResizeTypeDefinition): void {
        this._result.next(item.type);
    }
}
