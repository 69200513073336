import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    SimpleChanges,
    inject
} from "@angular/core";

import { LgFilterSet, IFilterDefinition } from "@logex/framework/lg-filterset";
import { Subscription } from "rxjs";

@Component({
    selector: "lg-filterset-form-row",
    templateUrl: "./lg-filterset-form-row.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        "[class.form-row]": "true"
    }
})
export class LgFiltersetFormRowComponent implements OnChanges, OnDestroy {
    private _changeDetectorRef = inject(ChangeDetectorRef);
    /**
     * @Required
     * All filters definitions
     * @param value
     */
    @Input({ required: true })
    public set filterSet(value: LgFilterSet) {
        this._setFilterSet(value);
    }

    public get filterSet(): LgFilterSet {
        return this._filterSet;
    }

    public readonly onFilterChange = new EventEmitter<void>();

    _filterSet!: LgFilterSet;

    private _subscription: Subscription | null = null;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.filterSet) {
            if (this._subscription) {
                this._subscription.unsubscribe();
                this._subscription = null;
            }
            if (this._filterSet) {
                this._subscription = this._filterSet.onChanged.subscribe(() => {
                    this._changeDetectorRef.markForCheck();
                });
                this._subscription.add(
                    this._filterSet.visibleGroups$.subscribe(() => {
                        this._changeDetectorRef.markForCheck();
                    })
                );
            }
        }
    }

    ngOnDestroy(): void {
        this._subscription?.unsubscribe();
    }

    public _filterChanged(entry: IFilterDefinition): void {
        this._filterSet.triggerOnChanged(entry);
        this.onFilterChange.next();
    }

    public _entryIdentity(_index: number, entry: IFilterDefinition): string {
        return entry.id;
    }

    private _setFilterSet(value: LgFilterSet): void {
        if (value === this._filterSet) return;
        this._filterSet = value;
    }
}
