import { Directive, EventEmitter, Output } from "@angular/core";
import { IExportableChart } from ".";

@Directive({
    selector: "[lgChartExportContainer]"
})
export class LgChartExportContainerDirective {
    @Output() readonly chartChanged = new EventEmitter<IExportableChart | null>();

    private _chart: IExportableChart | null = null;

    register(chart: IExportableChart): void {
        queueMicrotask(() => {
            this._chart = chart;
            this.chartChanged.next(chart);
        });
    }

    unregister(chart: IExportableChart): void {
        queueMicrotask(() => {
            if (this._chart === chart) {
                this._chart = null;
            }
            this.chartChanged.next(null);
        });
    }

    get chart(): IExportableChart | null {
        return this._chart;
    }
}
