<div class="lg-chat-window">
    <div class="lg-panel__header">
        <lg-icon class="lg-chat-window__bot-icon" icon="icon-chat-bot"></lg-icon>
        <h2>{{ chatTitle }}</h2>
        <div class="lg-block__icons">
            <lg-icon
                *ngIf="_chatState.messages?.length"
                class="lg-block__button clickable"
                [icon]="'icon-recent'"
                [clickable]="true"
                (click)="_resetChat()"
                [title]="'.ResetChat_tooltip' | lgTranslate"
            >
            </lg-icon>
            <lg-icon
                *ngIf="_chatState.messages?.length"
                class="lg-block__button clickable"
                [icon]="'icon-message'"
                [clickable]="true"
                (click)="_prepareMail()"
                [title]="'.PrepareMail_tooltip' | lgTranslate"
            >
            </lg-icon>
            <lg-icon
                class="lg-block__button clickable"
                [icon]="'icon-close'"
                [clickable]="true"
                (click)="_close()"
                [title]="'FW.CLOSE' | lgTranslate"
            >
            </lg-icon>
        </div>
    </div>
    <div class="lg-panel__body" [lgLoaderOverlay]="!_chatState.messages">
        <div class="lg-chat-window__chat-messages">
            <!-- Must be first, because list is reversed (chat scrolls from bottom) -->
            <div
                *ngIf="_loadingResponse"
                class="lg-chat-window__chat-messages__bubble lg-chat-window__chat-messages__bubble--loading"
            >
                <span></span><span></span><span></span>
            </div>
            <ng-container *ngFor="let message of _reversedMessages">
                <div
                    class="lg-chat-window__chat-messages__bubble"
                    [ngClass]="{
                        'lg-chat-window__chat-messages__bubble--sent': message.sent,
                        'lg-chat-window__chat-messages__bubble--received': !message.sent
                    }"
                >
                    <span *ngIf="!message.sent" [innerHTML]="message.text"></span>
                    <span *ngIf="message.sent">{{ message.text }}</span>
                    <lg-icon
                        *ngIf="message.isError"
                        icon="icon-warning"
                        [lgTooltip]="'.Error_tooltip' | lgTranslate"
                    ></lg-icon>
                    <div
                        *ngIf="message.buttons?.length"
                        class="lg-chat-window__chat-messages__bubble__buttons"
                    >
                        <ng-container *ngFor="let button of message.buttons">
                            <ng-container *ngIf="button.url.startsWith('/'); else externalUrl">
                                <button
                                    class="button button--condensed"
                                    (click)="_onButtonClick(button.url)"
                                >
                                    <span>{{ button.label }}</span>
                                </button>
                            </ng-container>
                            <ng-template #externalUrl>
                                <a
                                    [href]="button.url"
                                    target="_blank"
                                    class="button button--condensed"
                                >
                                    <span>{{ button.label }}</span>
                                </a>
                            </ng-template>
                        </ng-container>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngTemplateOutlet="introductionTemplate"></ng-container>
        </div>
        <div class="lg-chat-window__chat-input">
            <input
                type="text"
                class="input input--wide"
                [(ngModel)]="_newMessage"
                (keyup.enter)="_sendMessage()"
                [placeholder]="'.Input_placeholder' | lgTranslate"
                maxlength="1500"
            />
            <lg-button
                (click)="_sendMessage()"
                textLc=".Send_button"
                [isDisabled]="_loadingResponse ?? false"
                buttonClass="button button--primary"
            >
            </lg-button>
        </div>
    </div>
</div>
