/* eslint-disable @angular-eslint/no-conflicting-lifecycle */
import ldFindLastIndex from "lodash-es/findLastIndex";
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    DoCheck,
    ElementRef,
    EventEmitter,
    forwardRef,
    inject,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    ViewChild
} from "@angular/core";
import { ViewportRuler } from "@angular/cdk/overlay";
import { Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { LgSimpleChanges } from "@logex/framework/types";
import { LgTranslateService } from "@logex/framework/lg-localization";
import { IOrderByPerLevelSpecification } from "@logex/framework/lg-pivot";
import { LgPivotTableBodyComponent } from "./lg-pivot-table-body.component";
import {
    ILevelExpander,
    IPivotTableLevelHeader,
    IPivotTableLevelHeaderDefinition,
    LgPivotTableLevelExpander
} from "./types";
import { convertPivotLevelHeaders } from "./helpers/convertPivotLevelHeaders";

@Component({
    selector: "lg-pivot-table-body-level-headers",
    templateUrl: "./lg-pivot-table-body-level-headers.component.html",
    viewProviders: [
        {
            provide: LgPivotTableLevelExpander,
            useExisting: forwardRef(() => LgPivotTableBodyLevelHeadersComponent)
        }
    ],
    host: {
        class: "table__column--header-levels",
        "[class.table__column--header-levels--compact]": "!_areLabelsVisible"
    }
})
// eslint-disable-next-line @angular-eslint/no-conflicting-lifecycle
export class LgPivotTableBodyLevelHeadersComponent
    implements DoCheck, OnChanges, OnDestroy, AfterViewInit, ILevelExpander
{
    private _changeDetectorRef = inject(ChangeDetectorRef);
    private _elementRef = inject(ElementRef);
    private _lgTranslate = inject(LgTranslateService);
    private _ruler = inject(ViewportRuler);

    /**
     * Pivot table body component (required).
     */
    @Input({ required: true }) body!: LgPivotTableBodyComponent;

    /**
     * Levels header definition (required).
     */
    @Input({ required: true }) levels!: IPivotTableLevelHeaderDefinition;

    /**
     * Sorting specification varied per level. This is an array of per-level specifications.
     */
    @Input() sortBy: IOrderByPerLevelSpecification = [];

    @Output() readonly sortByChange = new EventEmitter<IOrderByPerLevelSpecification>();

    @ViewChild("hiddenLevelsWrapper", { static: true }) _hiddenLevelsWrapper!: ElementRef;

    _offset = 0;
    _maxVisibleLevel = 0;
    _empty = false;
    _hoverLevel: number | null = null;
    _lastIndex = 0;
    _areLabelsVisible = true;
    _levelsWidth = 0;
    _convertedLevels: Array<IPivotTableLevelHeader | undefined> = [];

    private _width = 0;
    private _destroyed$ = new Subject<void>();
    private _subscription: Subscription | null = null;

    constructor() {
        this._ruler
            .change()
            .pipe(takeUntil(this._destroyed$))
            .subscribe(() => this._onViewPortChange());
    }

    getAllExpanded(level: number): boolean {
        return this.body && this.body.getAllExpanded(level);
    }

    toggleLevel(level: number, _expanded?: boolean): void {
        if (this.body) this.body.toggleLevel(level);
    }

    ngDoCheck(): void {
        if (this.body) {
            const offset = this.body.definition ? this.body.definition.$levelIndex : 0;
            const maxVisibleLevel = this.body.maxVisibleLevel;
            const empty = this.body.filteredData == null;

            if (
                offset !== this._offset ||
                maxVisibleLevel !== this._maxVisibleLevel ||
                empty !== this._empty
            ) {
                this._offset = offset;
                this._maxVisibleLevel = maxVisibleLevel;
                this._empty = empty;
                this._changeDetectorRef.markForCheck();
            }
        }
    }

    ngOnChanges(changes: LgSimpleChanges<LgPivotTableBodyLevelHeadersComponent>): void {
        if (changes.body) {
            this._unsubscribe();
            if (this.body) {
                this._subscription = this.body
                    ._hoverLevelObservable()
                    .pipe(takeUntil(this._destroyed$))
                    .subscribe(level => {
                        this._hoverLevel = level;
                    });
            }
        }
        if (changes.levels) {
            this._convertLevels();
            this._onLevelsChange();
        }
    }

    ngAfterViewInit(): void {
        window.setTimeout(() => {
            this._onLevelsChange();
            this._onViewPortChange();
        }, 0);
    }

    ngOnDestroy(): void {
        this._destroyed$.next();
        this._destroyed$.complete();
    }

    _sortByDisabled(
        levelDef: IPivotTableLevelHeader,
        isTheVisibleRow: boolean,
        index: number
    ): boolean {
        return (
            !levelDef.orderBy ||
            this._maxVisibleLevel < index ||
            this._empty ||
            !this._levelLabelVisible(isTheVisibleRow, index)
        );
    }

    _levelLabelVisible(isTheVisibleRow: boolean, index: number): boolean {
        return !isTheVisibleRow || this._areLabelsVisible || this._maxVisibleLevel === index;
    }

    _showDotForLastLevel(isTheVisibleRow: boolean, index: number): boolean {
        return isTheVisibleRow && !this._areLabelsVisible && index === this._lastIndex;
    }

    _toggleNameLevel(level: number, $event: MouseEvent): void {
        $event.stopPropagation();
        $event.preventDefault();

        if (!this.body || this._empty) return;

        const last = level === this._lastIndex;
        if (this._maxVisibleLevel < level) {
            for (let i = this._maxVisibleLevel; i < level; ++i) {
                this.body.toggleLevel(i, true);
            }
        }
        if (!last) {
            this.body.toggleLevel(level);
        }
    }

    private _convertLevels(): void {
        this._convertedLevels = convertPivotLevelHeaders(this.levels, this._lgTranslate);
        this._lastIndex = ldFindLastIndex(this._convertedLevels, l => !!l);
    }

    private _onLevelsChange(): void {
        if (this._hiddenLevelsWrapper) {
            const newWidth = this._hiddenLevelsWrapper.nativeElement.clientWidth;

            if (newWidth !== this._levelsWidth) {
                this._levelsWidth = newWidth;
                this._setLabelsVisibility();
            }
        }
    }

    private _onViewPortChange(): void {
        if (this._elementRef.nativeElement) {
            const width =
                this._elementRef.nativeElement.style.width === ""
                    ? this._elementRef.nativeElement.parentElement.clientWidth
                    : this._elementRef.nativeElement.clientWidth;

            if (width !== this._width) {
                this._width = width;
                this._setLabelsVisibility();
            }
        }
    }

    private _setLabelsVisibility(): void {
        if (!this._levelsWidth || !this._width) return;

        this._areLabelsVisible = this._levelsWidth < this._width;
    }

    private _unsubscribe(): void {
        if (this._subscription) {
            this._subscription.unsubscribe();
            this._subscription = null;
        }
    }
}
