import ldKeyBy from "lodash-es/keyBy";
import ldSortBy from "lodash-es/sortBy";
import {
    Component,
    Input,
    DoCheck,
    ChangeDetectorRef,
    ChangeDetectionStrategy,
    ElementRef,
    OnDestroy,
    inject
} from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ComponentPortal } from "@angular/cdk/portal";
import { Overlay, ScrollDispatcher } from "@angular/cdk/overlay";

import { LgTranslateService } from "@logex/framework/lg-localization";
import { LgNavigationService } from "@logex/framework/lg-application";
import {
    IDropdownDefinition,
    IOverlayResultApi,
    LgDropdownPopupComponent,
    LgOverlayService
} from "@logex/framework/ui-core";
import {
    IDrilldownBreadcrumbEntry,
    IDrilldownBreadcrumbOption
} from "./lg-drilldown-breadcrumb.types";

@Component({
    selector: "lg-drilldown-breadcrumb",
    templateUrl: "./lg-drilldown-breadcrumb.component.html",
    host: {
        class: "lg-breadcrumb lg-breadcrumb--secondary"
    },
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LgDrilldownBreadcrumbComponent implements DoCheck, OnDestroy {
    private _changeDetectorRef = inject(ChangeDetectorRef);
    private _lgTranslate = inject(LgTranslateService);
    private _navigationService = inject(LgNavigationService);
    private _overlay = inject(Overlay);
    private _overlayService = inject(LgOverlayService);
    private _scrollDispatcher = inject(ScrollDispatcher);

    /**
     * Breadcrumb entries configuration (required).
     */
    @Input({ required: true })
    set config(value: Array<IDrilldownBreadcrumbEntry<number | string>>) {
        this._config = value;
        this._state = [];
        this._updateState();
    }

    get config(): Array<IDrilldownBreadcrumbEntry<number | string>> {
        return this._config;
    }

    _state: Array<IDrilldownBreadcrumbOption<number | string>> = [];
    _config: Array<IDrilldownBreadcrumbEntry<number | string>> = [];
    _activeIndex: number | null = null;
    _lastIndex: number | null = null;
    _extraIndex: number | null = null;

    private readonly _destroyed$ = new Subject<void>();
    private _popupHidden$ = new Subject<void>();
    private _overlayInstance: IOverlayResultApi | null = null;
    private _popupInstance: LgDropdownPopupComponent<number | string> | null = null;

    // --------------------------------------------------------------------------------------------------------
    constructor() {
        this._updateState();
        this._navigationService
            .afterNavigation$()
            .pipe(takeUntil(this._destroyed$))
            .subscribe(ev => {
                if (
                    ev.result === null &&
                    this._state.length &&
                    !this._config[0].noResetOnEmptyNavigation
                ) {
                    this._config[0].onReset();
                }
            });
    }

    // --------------------------------------------------------------------------------------------------------
    ngDoCheck(): void {
        this._updateState();
    }

    // --------------------------------------------------------------------------------------------------------
    ngOnDestroy(): void {
        if (this._activeIndex !== null) {
            this._doClose();
        }
        this._destroyed$.next();
        this._destroyed$.complete();
    }

    // --------------------------------------------------------------------------------------------------------
    _showMenu(_event: MouseEvent, index: number, breadcrumbElement: HTMLElement): boolean {
        this._activeIndex = index;
        this._doShow(breadcrumbElement);
        return false;
    }

    private _updateState(): void {
        if (!this._config) return;

        let modified = false;

        this._extraIndex = null;
        for (let i = 0; i < this._config.length; ++i) {
            const current = this._config[i].getCurrent();

            let currentId: any = undefined;
            let isOption = false;
            if (current !== undefined && typeof current === "object") {
                isOption = true;
                currentId = current.id;
            } else {
                currentId = current;
            }

            if (currentId === undefined) {
                if (this._state.length > i) {
                    this._state.splice(i);
                    modified = true;
                }
                if (!this._config[i].noDrilldown) {
                    this._extraIndex = i;
                }
                break;
            } else if (this._state.length <= i || this._state[i].id !== currentId) {
                this._state[i] = isOption
                    ? (current as IDrilldownBreadcrumbOption<any>)
                    : { id: currentId, name: this._config[i].mapToName(currentId) };
                modified = true;
            }
        }

        if (modified) this._changeDetectorRef.markForCheck();
    }

    // --------------------------------------------------------------------------------------------------------
    private _doShow(element: HTMLElement): void {
        this._popupHidden$ = new Subject<void>();

        const alignment = "start";
        const elementRef = new ElementRef(element);

        const strategy = this._overlay
            .position()
            .flexibleConnectedTo(elementRef)
            .withFlexibleDimensions(false)
            .withPush(false)
            .withViewportMargin(0)
            .withPositions([
                { originX: alignment, originY: "bottom", overlayX: alignment, overlayY: "top" },
                { originX: alignment, originY: "top", overlayX: alignment, overlayY: "bottom" }
            ]);

        strategy.withScrollableContainers(
            this._scrollDispatcher.getAncestorScrollContainers(elementRef)
        );

        this._overlayInstance = this._overlayService.show({
            class: "empty-overlay",
            onClick: () => this._doClose(),
            hasBackdrop: true,
            sourceElement: elementRef,
            positionStrategy: strategy,
            scrollStrategy: this._overlay.scrollStrategies.reposition({ scrollThrottle: 0 })
        });

        const portal = new ComponentPortal<LgDropdownPopupComponent<string | number>>(
            LgDropdownPopupComponent
        );
        this._popupInstance = this._overlayInstance.overlayRef.attach(portal).instance;
        strategy.positionChanges.pipe(takeUntil(this._popupHidden$)).subscribe(change => {
            this._popupInstance?.updatePosition(change);
        });

        const currentDefinition: IDropdownDefinition<any> = this._createDefinition(
            this._config[this._activeIndex!],
            this._activeIndex! >= this._state.length
        )!;

        this._popupInstance
            ._initialize({
                animationEnabled: false,
                condensed: true,
                currentValue: this._state[this._activeIndex!]
                    ? this._state[this._activeIndex!].id
                    : null,
                hideSearch: false,
                matchWidth: "content",
                itemTooltips: false,
                popupClassName: "",
                target: elementRef,
                standalone: true,
                translateService: this._lgTranslate,
                definition: currentDefinition,
                isControlCondensed: false,
                reposition: () => strategy.apply()
            })
            .pipe(takeUntil(this._popupHidden$))
            .subscribe(result => {
                this._lastIndex = this._activeIndex;
                this._doClose();
                if (result.selected) {
                    this._config[this._lastIndex!].onSet(result.id!);
                }
            });

        this._changeDetectorRef.markForCheck();
    }

    // --------------------------------------------------------------------------------------------------------
    private _doClose(): void {
        if (this._activeIndex === null) return;

        this._activeIndex = null;
        this._popupHidden$.next();
        this._popupHidden$.complete();
        this._overlayInstance!.hide();

        this._overlayInstance = null;
        this._popupInstance = null;

        this._changeDetectorRef.markForCheck();
        this._changeDetectorRef.detectChanges();
    }

    // --------------------------------------------------------------------------------------------------------
    private _createDefinition<T extends number | string = any>(
        config: IDrilldownBreadcrumbEntry<T>,
        isExtra: boolean
    ): IDropdownDefinition<T> | null {
        const indices = config.getOptions();
        if (!indices || indices.length === 0) {
            return null;
        }

        let options: Array<IDrilldownBreadcrumbOption<T>>;
        if (typeof indices[0] === "object") {
            options = indices as any;
        } else {
            const rawOptions = (indices as T[]).map((id, index) => ({
                id,
                name: config.mapToName(id),
                index
            }));
            options = ldSortBy(rawOptions, o =>
                config.sortById === "noSort" ? o.index : config.sortById ? o.id : o.name
            );
        }

        const backName =
            config.goBackLabel != null
                ? typeof config.goBackLabel === "function"
                    ? config.goBackLabel()
                    : config.goBackLabel
                : null;

        return {
            entryId: "id",
            entryName: "name",
            groupId: "id",
            groupName: "name",
            lookup: ldKeyBy(options, o => o.id),
            groups: [
                {
                    entries: options
                }
            ],
            extraTop: isExtra
                ? undefined
                : {
                      close: true,
                      onClick: () => {
                          this._config[this._lastIndex!].onReset();
                          return false;
                      },
                      name: backName || this._lgTranslate.translate("FW.Drill_up"),
                      icon: "icon-go-back"
                  }
        };
    }
}
