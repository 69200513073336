import { HsluvAngle, HsluvLine, HsluvPoint } from "./hsluv.types";

export function intersectLineLine(a: HsluvLine, b: HsluvLine): HsluvPoint {
    const x = (a.intercept - b.intercept) / (b.slope - a.slope);
    const y = a.slope * x + a.intercept;
    return { x, y };
}

export function distanceFromOrigin(point: HsluvPoint): number {
    return Math.sqrt(Math.pow(point.x, 2) + Math.pow(point.y, 2));
}

export function distanceLineFromOrigin(line: HsluvLine): number {
    // https://en.wikipedia.org/wiki/Distance_from_a_point_to_a_line
    return Math.abs(line.intercept) / Math.sqrt(Math.pow(line.slope, 2) + 1);
}

export function perpendicularThroughPoint(line: HsluvLine, point: HsluvPoint): HsluvLine {
    const slope = -1 / line.slope;
    const intercept = point.y - slope * point.x;
    return {
        slope,
        intercept
    };
}

export function angleFromOrigin(point: HsluvPoint): HsluvAngle {
    return Math.atan2(point.y, point.x);
}

export function normalizeAngle(angle: HsluvAngle): HsluvAngle {
    const m = 2 * Math.PI;
    return ((angle % m) + m) % m;
}

export function lengthOfRayUntilIntersect(theta: HsluvAngle, line: HsluvLine): number {
    return line.intercept / (Math.sin(theta) - line.slope * Math.cos(theta));
}
