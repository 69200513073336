import { ErrorHandler, inject, Injectable, OnDestroy } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { IStringLookup } from "@logex/framework/types";

import { DebugEventAggregator } from "./debug-event-aggregator";
import { DebugNotificationService, IDebugNotification } from "./debug-notification.service";
import { DebugEventType } from "./debug-event";
import { ApplicationTraceSeverity, LG_APPLICATION_EVENT_TRACER } from "../tracing";
import { LgErrorHandlerGateway } from "./lg-error-handler-gateway";

@Injectable()
export class LgErrorHandler extends ErrorHandler implements OnDestroy {
    private _debugEventAggregator = inject(DebugEventAggregator);
    private _debugNotificationService = inject(DebugNotificationService);
    private _gateway = inject(LgErrorHandlerGateway);
    private _tracer = inject(LG_APPLICATION_EVENT_TRACER);

    private _serverLogEnabled: IStringLookup<boolean>;
    private _clientNotificationEnabled: IStringLookup<boolean>;
    private _traceEnabled: IStringLookup<boolean>;
    private _destroyed = new Subject<void>();

    constructor() {
        super();

        this._serverLogEnabled = {};

        this._clientNotificationEnabled = {};

        this._traceEnabled = {
            angular: true,
            onerror: true,
            error: true
        };

        this._debugNotificationService
            .listen$()
            .pipe(takeUntil(this._destroyed))
            .subscribe(notification => {
                this._onNotification(notification);
            });
    }

    /**
     * Enable or disable server log for the specified event type.     *
     * Examples:
     * Enable one event type: `enableServerLog('error')` OR `enableServerLog('error', true)`
     * Enable multiple event types: `enableServerLog('error', 'warn', 'info')`;
     * Disable one event type: `enableServerLog('error', false)`
     */
    enableServerLog(type: DebugEventType, enable: boolean): void;
    enableServerLog(...types: DebugEventType[]): void;
    enableServerLog(...types: Array<DebugEventType | boolean>): void {
        if (types.length === 1) {
            this._serverLogEnabled[types[0] as string] = true;
        } else if (types.length === 2 && typeof types[1] === "boolean") {
            this._serverLogEnabled[types[0] as string] = types[1];
        } else {
            types.forEach(type => {
                this._serverLogEnabled[type as string] = true;
            });
        }
    }

    /**
     * Enable or disable client notification for the specified event type.
     * Examples:
     * Enable one event type: `enableClientNotification('error')` OR `enableClientNotification('error', true)`
     * Enable multiple event types: `enableClientNotification('error', 'warn', 'info')`;
     * Disable one event type: `enableClientNotification('error', false)`
     */
    enableClientNotification(type: DebugEventType, enable: boolean): void;
    enableClientNotification(...types: DebugEventType[]): void;
    enableClientNotification(...types: Array<DebugEventType | boolean>): void {
        if (types.length === 1) {
            this._clientNotificationEnabled[types[0] as string] = true;
        } else if (types.length === 2 && typeof types[1] === "boolean") {
            this._clientNotificationEnabled[types[0] as string] = types[1];
        } else {
            types.forEach(type => {
                this._clientNotificationEnabled[type as string] = true;
            });
        }
    }

    ngOnDestroy(): void {
        this._destroyed.next();
        this._destroyed.complete();
    }

    override handleError(error: Error): void {
        // error.ngDebugContext.componentRenderElement.localName ~~> filename

        let filename: string | null = null;
        const debugContext = (error as any).ngDebugContext;
        if (debugContext) {
            try {
                // This can fail in some angular phases
                filename =
                    debugContext.componentRenderElement &&
                    debugContext.componentRenderElement.localName;
            } catch (err) {
                // ignore
            }
        }

        this._javascriptError(error.message, filename, 123, 123, error);
        console.error(error);
    }

    private _onNotification(notification: IDebugNotification): void {
        if (
            !this._serverLogEnabled[notification.methodName] &&
            !this._clientNotificationEnabled[notification.methodName] &&
            !this._traceEnabled[notification.methodName]
        ) {
            return;
        }

        if (notification.methodName === "assert") {
            const test = notification.args.shift();
            if (test) return; // did not fail
        }
        const formated = this._formatConsole(notification.args);
        const fullMessage = `${notification.source}: ${formated} (console.${notification.methodName}())`;
        if (this._serverLogEnabled[notification.methodName]) {
            this._gateway.logToServerThrottled(
                window.name,
                formated,
                fullMessage,
                window.location.toString(),
                notification.source,
                null,
                null,
                null
            );
        }
        if (this._clientNotificationEnabled[notification.methodName]) {
            this._debugEventAggregator.publish({
                type: notification.methodName,
                shortDescription: formated,
                fullDescription: fullMessage,
                time: new Date()
            });
        }
        if (this._traceEnabled[notification.methodName]) {
            this._tracer.trackTrace(this._convertSeverity(notification.methodName), formated, {
                fullMessage,
                source: notification.source
            });
        }
    }

    private _javascriptError(
        errorMsg: string,
        filename?: string | null,
        lineNumber?: number,
        column?: number,
        errorObj?: any
    ): void {
        const typeName = "onerror";
        if (
            !this._serverLogEnabled[typeName] &&
            !this._clientNotificationEnabled[typeName] &&
            !this._traceEnabled[typeName]
        )
            return;

        let errorFull = "";
        if (errorObj) {
            errorFull = this._formatError(errorObj);
        }
        if (!errorFull) errorFull = errorMsg;

        if (this._serverLogEnabled[typeName]) {
            this._gateway.logToServer(
                typeName,
                errorMsg,
                errorFull,
                window.location.toString(),
                filename,
                lineNumber,
                column,
                null
            );
        }

        if (this._clientNotificationEnabled[typeName]) {
            this._debugEventAggregator.publish({
                type: typeName,
                shortDescription: errorMsg,
                fullDescription: errorFull,
                time: new Date()
            });
        }

        if (this._traceEnabled[typeName]) {
            this._tracer.trackException(errorObj ?? new Error(errorMsg), {
                filename,
                lineNumber,
                column
            });
        }
    }

    private _convertSeverity(methodName: string): ApplicationTraceSeverity {
        switch (methodName) {
            case "info":
                return ApplicationTraceSeverity.Information;
            case "warn":
                return ApplicationTraceSeverity.Warning;
            case "error":
                return ApplicationTraceSeverity.Error;
            case "debug":
            case "assert":
            case "perf":
            case "count":
            case "trace":
            case "log":
            default:
                return ApplicationTraceSeverity.Verbose;
        }
    }

    private _formatError(arg: any): string {
        if (arg instanceof Error) {
            // note: the Error content is not actually standardized between browsers, so TS is too restrictive here. We need cast to any
            if (arg.stack) {
                arg =
                    arg.message && arg.stack.indexOf(arg.message) === -1
                        ? "Error: " + arg.message + "\n" + arg.stack
                        : arg.stack;
            } else if ((<any>arg).sourceURL) {
                arg = arg.message + "\n" + (<any>arg).sourceURL + ":" + (<any>arg).line;
            }
        }

        return arg.toString();
    }

    private _formatConsole(args: string[]): string {
        let result = "";
        const l = args.length;
        let i = 0;
        if (l && typeof args[0] === "string") {
            ++i;
            // note: we ignore the formatting type and dump everything as string
            result = (args[0] as string).replace(/%(s|d|i|f|o)/g, function () {
                return "" + args[i++];
            });
        }
        while (i < l) {
            if (i) result += ", ";
            result += args[i];
            ++i;
        }
        return result;
    }
}
