import { inject, Injectable } from "@angular/core";
import { LgChatStateService } from "./lg-chat-state.service";
import { LgChatGateway } from "./lg-chat-gateway";
import { IChatButton, TSender } from "./lg-chat.types";
import { take } from "rxjs";

@Injectable({ providedIn: "root" })
export class LgChatController {
    private _chatState = inject(LgChatStateService);
    private _chatGateway = inject(LgChatGateway);

    static addManualPageIfNeeded(url?: string | null, label?: string | null): IChatButton[] {
        return url && label ? [{ url, label }] : [];
    }

    static prepareResponse(text: string): string {
        return text.replace(/\n/g, "<br />");
    }

    get isChatVisible(): boolean {
        return this._chatState.isChatVisible;
    }

    closeChat(): void {
        this._chatState.chatToggle(false);
    }

    openChat(): void {
        if (this._chatState.isChatVisible) {
            return;
        }
        if (!this._chatState.messages) {
            if (!this._chatState.chatId) {
                this._chatGateway
                    .getLastConversation()
                    .pipe(take(1))
                    .subscribe({
                        next: existingConversation => {
                            this._chatState.setChatId(existingConversation?.conversationId ?? null);
                            this._chatState._initChat(
                                existingConversation?.conversationMessages
                                    .sort((a, b) => a.order - b.order)
                                    .map(c => ({
                                        sent: c.sender === TSender.User,
                                        text: LgChatController.prepareResponse(c.messageText),
                                        buttons: [
                                            ...LgChatController.addManualPageIfNeeded(
                                                c.manualUrl,
                                                c.manualTitle
                                            )
                                        ]
                                    }))
                            );
                        },
                        error: () => this._chatState._initChat()
                    });
            } else {
                this._chatState._initChat();
            }
        }
        this._chatState.chatToggle(true);
    }

    toggleChat(): void {
        if (this.isChatVisible) {
            this.closeChat();
        } else {
            this.openChat();
        }
    }
}
