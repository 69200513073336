import { DatePipe } from "@angular/common";

export class LgDate {
    private formatValidationReg =
        /^((d|dd|M|MM|yy|yyyy|H|HH|h|hh|m|mm|s|ss|a|aa)\W+)*(d|dd|M|MM|yy|yyyy|H|HH|a|hh|m|mm|s|ss|a|aa)$/;

    private formatReg = /(d|dd|M|MM|yy|yyyy|H|HH|h|hh|m|mm|s|ss|a|aa)(?:(\W+)|$)/g;
    private sanitizingRegExp = /[#-.]|[[-^]|[?|{}]/g;

    private parseReg: RegExp;
    private dateIndex!: number;
    private monthIndex!: number;
    private yearIndex!: number;
    private hourIndex!: number;
    private minuteIndex!: number;
    private secondIndex!: number;
    private formatFn: Function;
    private periodIndex: number | null = null;
    private is24h = true;
    private amPeriod: string | null = null;
    private pmPeriod: string | null = null;

    // todo: use moment.js?
    constructor(format: string, datePipe?: DatePipe) {
        if (datePipe) {
            this.amPeriod = datePipe.transform(new Date(2020, 0, 1, 0, 0, 0), "a");
            this.pmPeriod = datePipe.transform(new Date(2020, 0, 1, 13, 0, 0), "a");
        }
        let regDef = "^";
        let formatDef = "if ( value==null ) return '';\nlet temp = 0; let formatted = '';\n";

        let match = this.formatValidationReg.exec(format);
        if (!match) throw new Error("Invalid format specification");

        match = this.formatReg.exec(format);
        let i = 1;
        while (match !== null) {
            const element = match[1];
            switch (element) {
                case "d":
                    this.dateIndex = i;
                    regDef += "([0-3]?[0-9])";
                    formatDef += "formatted += value.getDate();";
                    break;
                case "dd":
                    this.dateIndex = i;
                    regDef += "([0-3][0-9])";
                    formatDef +=
                        "if ( value.getDate() < 10 ) formatted += '0'; formatted += value.getDate();";
                    break;
                case "M":
                    this.monthIndex = i;
                    regDef += "([0-1]?[0-9])";
                    formatDef += "formatted += (value.getMonth() + 1);";
                    break;
                case "MM":
                    this.monthIndex = i;
                    regDef += "([0-1][0-9])";
                    formatDef +=
                        "if ( value.getMonth() < 9 ) formatted += '0'; formatted += (value.getMonth() + 1);";
                    break;
                case "yy":
                    this.yearIndex = i;
                    regDef += "([0-9]{2})";
                    formatDef += "formatted += ('' + value.getFullYear()).substring(2);";
                    break;
                case "yyyy":
                    this.yearIndex = i;
                    regDef += "([0-9]{4})";
                    formatDef += "formatted += value.getFullYear();";
                    break;
                case "H":
                    this.hourIndex = i;
                    regDef += "([0-2]?[0-9])";
                    formatDef += "formatted += value.getHours();";
                    break;
                case "HH":
                    this.hourIndex = i;
                    regDef += "([0-2][0-9])";
                    formatDef +=
                        "if ( value.getHours() < 10 ) formatted += '0'; formatted += value.getHours();";
                    break;
                case "h":
                    this.hourIndex = i;
                    regDef += "([0-1]?[0-9])";
                    formatDef +=
                        "temp = value.getHours() % 12; formatted += temp === 0 ? '12' : temp;";
                    this.is24h = false;
                    break;
                case "hh":
                    this.hourIndex = i;
                    regDef += "([0-1][0-9])";
                    formatDef +=
                        "temp = value.getHours() % 12; if ( temp && temp < 10 ) formatted += '0'; formatted += temp === 0 ? '12' : temp;";
                    this.is24h = false;
                    break;
                case "m":
                    this.minuteIndex = i;
                    regDef += "([0-5]?[0-9])";
                    formatDef += "formatted += value.getMinutes();";
                    break;
                case "mm":
                    this.minuteIndex = i;
                    regDef += "([0-5][0-9])";
                    formatDef +=
                        "if ( value.getMinutes() < 10 ) formatted += '0'; formatted += value.getMinutes();";
                    break;
                case "s":
                    this.secondIndex = i;
                    regDef += "([0-5]?[0-9])";
                    formatDef += "formatted += value.getSeconds();";
                    break;
                case "ss":
                    this.secondIndex = i;
                    regDef += "([0-5][0-9])";
                    formatDef +=
                        "if ( value.getSeconds() < 10 ) formatted += '0'; formatted += value.getSeconds();";
                    break;
                case "a":
                case "aa":
                    this.periodIndex = i;
                    regDef += datePipe ? `(${this.amPeriod}|${this.pmPeriod}|am|pm)` : "(am|pm)";
                    formatDef += `formatted += value.getHours() >=12 ? '${
                        this.pmPeriod ?? "pm"
                    }' : '${this.amPeriod ?? "am"}';`;
                    break;
            }
            if (match[2]) {
                // the separator
                regDef += "(?:" + match[2].replace(this.sanitizingRegExp, "\\$&") + ")";
                formatDef +=
                    "formatted += '" +
                    match[2].replace("'", "\\'").replace("\n", "\\n").replace("\r", "\\r") +
                    "'";
            }
            formatDef += "\n";
            ++i;
            match = this.formatReg.exec(format);
        }
        regDef += "$";
        formatDef += "return formatted;\n";
        this.parseReg = new RegExp(regDef, "i");
        // eslint-disable-next-line no-new-func
        this.formatFn = Function("value", formatDef);
    }

    /**
     * Parse the date according to the specified format. Note that elements missing in the format (for example year in dd.MM) are taken from today
     *
     * @param value
     */
    parse(value: Date | string): Date | null {
        let date: Date | null = null;
        if (this._isDate(value)) {
            return value as Date;
        }
        if (!value) return null;

        const match = this.parseReg.exec(value as string);
        if (match) {
            const now = new Date();
            let month = now.getMonth();
            let day = now.getDay();
            let year = now.getFullYear();
            let hours = 0;
            let minutes = 0;
            let seconds = 0;
            let pm = false;

            if (this.dateIndex) {
                day = +match[this.dateIndex];
                if (day < 1 || day > 31) return null;
            }
            if (this.monthIndex) {
                month = +match[this.monthIndex] - 1;
                if (month < 0 || month > 11) return null;
            }
            if (this.yearIndex) {
                year = +match[this.yearIndex];
                if (year < 50) {
                    year += 2000;
                } else if (year < 100) {
                    year += 1900;
                }
            }
            if (this.periodIndex) {
                const period = match[this.periodIndex].toLowerCase();
                if (period === this.pmPeriod || (period !== this.amPeriod && period === "pm"))
                    pm = true;
            }
            if (this.hourIndex) {
                hours = +match[this.hourIndex];
                if (!this.is24h) hours = hours % 12;
                if (hours >= 24) return null;
            }
            if (this.minuteIndex) {
                minutes = +match[this.minuteIndex];
            }
            if (this.secondIndex) {
                seconds = +match[this.secondIndex];
            }

            if (!this.is24h) {
                if (pm || hours === 0) hours += 12;
            }

            date = new Date(year, month, day, hours, minutes, seconds);
            if (date.getMonth() !== month) {
                date = null;
            }
        }
        return date;
    }

    /**
     * Format the date according to the specifications. If the specified parameter is a string, it will be parsed first
     *
     * @param date to format
     */
    format(date: Date | string | null): string {
        if (typeof date === "string") {
            date = this.parse(date);
        }
        return this.formatFn(date);
    }

    /**
     * Detect, if the specified string is a proper date according to the format
     *
     * @param date
     */
    isValid(date: string): boolean {
        return this.parse(date) != null;
    }

    // http://stackoverflow.com/questions/542938/how-do-i-get-the-number-of-days-between-two-dates-in-javascript
    /**
     * Convert the specified date into UTC
     *
     * @param date to format
     */
    treatAsUTC(date: Date): Date {
        const result = new Date(date.getTime());
        result.setMinutes(result.getMinutes() - result.getTimezoneOffset());
        return result;
    }

    /**
     * Return the distance between 2 dates, in days. The code should correctly handle overlapping years
     *
     * @param startDate the start date
     * @param endDate the end date
     */
    daysBetween(startDate: Date, endDate: Date): number {
        const millisecondsPerDay = 24 * 60 * 60 * 1000;
        return (
            (this.treatAsUTC(endDate).getTime() - this.treatAsUTC(startDate).getTime()) /
            millisecondsPerDay
        );
    }

    // https://stackoverflow.com/questions/31697369/angularjs-isdate-returns-false-if-passed-argument-is-not-a-date-object
    private _isDate(date: Date | string): boolean {
        return Object.prototype.toString.call(date) === "[object Date]";
    }
}
