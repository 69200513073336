export async function loadJsonFile<T>(url: string, ignoreNotFound: boolean): Promise<T | null> {
    let responseBody: string;

    try {
        const response = await fetch(url);
        responseBody = await response.text();
    } catch (e: any) {
        if ("status" in e && e.status === 404 && ignoreNotFound) {
            return null;
        } else {
            throw e;
        }
    }

    if (responseBody) {
        // Strip line comments (in a very dumb way) and parse JSON
        return JSON.parse(responseBody.replace(/^\s*\/\/.*$/gm, ""));
    } else if (ignoreNotFound) {
        return null;
    } else {
        throw Error("Empty configuration received");
    }
}
