import { Directive, Input, HostListener, ElementRef, OnInit, inject } from "@angular/core";
import { toBoolean } from "@logex/framework/utilities";

/**
 * Directive that makes the target component obtain and hold the focus.
 */
@Directive({
    selector: "[lgCaptureFocus]"
})
export class LgCaptureFocusDirective implements OnInit {
    private _element = inject(ElementRef);

    /**
     * Specifies if element is focusable.
     */
    @Input("lgCaptureFocus") set captureFocus(val: boolean | "") {
        if (val === undefined || (val as any) === "") return;
        this._capture = toBoolean(val);
    }

    get captureFocus(): boolean {
        return this._capture;
    }

    private _capture = true;

    @HostListener("blur")
    _onBlur(): void {
        if (this._capture) this._element.nativeElement.focus();
    }

    ngOnInit(): void {
        if (this._capture) this._element.nativeElement.focus();
    }
}
