import {
    Component,
    ViewEncapsulation,
    ChangeDetectionStrategy,
    ElementRef,
    Renderer2,
    inject
} from "@angular/core";

@Component({
    standalone: true,
    selector: "lg-loading-bars",
    // eslint-disable-next-line @angular-eslint/component-max-inline-declarations
    template: `
        <div class="lg-loading-bars">
            <div class="lg-loading-bars__bar lg-loading-bars__bar--0"></div>
            <div class="lg-loading-bars__bar lg-loading-bars__bar--1"></div>
            <div class="lg-loading-bars__bar lg-loading-bars__bar--2"></div>
        </div>
    `,
    host: {
        class: "lg-loading-bars-wrapper"
    },
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LgLoadingBarsComponent {
    private _elementRef = inject(ElementRef);
    private _renderer = inject(Renderer2);

    // eslint-disable-next-line accessor-pairs
    set _topOffset(value: number) {
        this._renderer.setStyle(this._elementRef.nativeElement, "top", value + "px");
    }
}
