import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    HostListener,
    inject
} from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { Observable, Subject } from "rxjs";

import { useTranslationNamespace } from "@logex/framework/lg-localization";

import {
    LgComparisonType,
    MiComparisonSource,
    MiComparisonSourceMenuPopupResult,
    LgComparisonSourceDifferenceTypeItem
} from "./lg-comparison-source.types";
import { MiComparisonSourceService } from "./mi-comparison-source.service";

// eslint-disable-next-line @angular-eslint/use-component-selector
@Component({
    standalone: false,
    templateUrl: "./mi-comparison-source-menu-popup.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: "mi-comparison-source-menu-popup lg-menu-popup lg-menu-popup--bottom lg-menu-popup--compact lg-menu-popup--no-scrollbar"
    },
    viewProviders: [useTranslationNamespace("FW._Dialogs._ComparisonSource")]
})
export class MiComparisonSourceMenuPopupComponent {
    private _changeDetector = inject(ChangeDetectorRef);

    @HostListener("document:keydown.escape") onEsc(): void {
        this._result.next({ type: "exit" });
    }

    _isTop = false;
    _showComparisonTypeSwitch!: boolean;
    _selectedComparisonType!: LgComparisonType;
    _sources$!: Observable<MiComparisonSource[]>;
    _form!: UntypedFormGroup;
    _currentComparisonType!: LgComparisonType;
    _differenceTypes: LgComparisonSourceDifferenceTypeItem[] = [];
    _currentDifferenceTypeId!: string | number;

    private _initialComparisonType!: LgComparisonType;
    private _result = new Subject<MiComparisonSourceMenuPopupResult>();

    initialize(
        showTypeSwitch: boolean,
        service: MiComparisonSourceService,
        firstComparateId: string,
        secondComparateId: string,
        selectedComparisonType: LgComparisonType,
        differenceTypes: LgComparisonSourceDifferenceTypeItem[],
        currentDifferenceTypeId: string | number,
        scope: string
    ): Observable<MiComparisonSourceMenuPopupResult> {
        this._showComparisonTypeSwitch = showTypeSwitch;
        this._initialComparisonType = selectedComparisonType || "";
        this._currentComparisonType = this._initialComparisonType;
        this._sources$ = service.getSourceScope$(scope);
        this._initForm(firstComparateId, secondComparateId);
        this._differenceTypes = differenceTypes;
        this._currentDifferenceTypeId = currentDifferenceTypeId;

        return this._result.asObservable();
    }

    _onSubmit(): void {
        this._result.next({
            type: "submit",
            comparisonType: this._currentComparisonType,
            comparisonSources: {
                firstId: this._form.value.firstComparate,
                secondId: this._form.value.secondComparate
            },
            differenceTypeId: this._currentDifferenceTypeId
        });
    }

    _onFakeRadioSelect(radioGroupIdentifier: string, sourceId: string): void {
        // const el = document.getElementById(radioGroupIdentifier + sourceId);
        // if (el) el.click();
        document.getElementById(radioGroupIdentifier + sourceId)?.click();
        this._changeDetector.markForCheck();
    }

    _onComparisonTypeSelect(type: LgComparisonType): void {
        this._currentComparisonType = type;
    }

    _onDifferenceTypeSelect(id: string | number): void {
        this._currentDifferenceTypeId = id;
    }

    _isComparsionTypeActive(type: LgComparisonType): boolean {
        return type === this._currentComparisonType;
    }

    private _initForm(firstComparateId: string, secondComparateId: string): void {
        this._form = new UntypedFormGroup({
            firstComparate: new UntypedFormControl(firstComparateId),
            secondComparate: new UntypedFormControl(secondComparateId)
        });
    }
}
