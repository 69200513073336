import { Pipe, PipeTransform } from "@angular/core";

/**
 * Allows filtering the value through a type guard. If the guard passes, the output is the value properly typed;
 * otherwise undefined is passed.
 *
 * @example
 * <div *ngIf="value | lgTypeGuard: isPerson as p">{{p.name}}</div>
 * ..
 * ..
 * isPerson = (value:any): value is Person => { return value && "name" in value; }
 */
@Pipe({
    standalone: true,
    name: "lgTypeGuard",
    pure: true
})
export class LgTypeGuardPipe implements PipeTransform {
    transform<G extends (value: any) => boolean>(
        value: any,
        guard: G
    ): G extends (value: any) => value is infer R ? R | undefined | null : never {
        if (value == null) return value;
        if (guard(value)) {
            return value;
        }
        return undefined as any;
    }
}
