import { regularComparer } from "./regularComparer";
import { toReverseAwareCompare } from "./toReverseAwareCompare";

export type ReverseAwareComparer<T> = (property1: T, property2: T, reverse: boolean) => number;
export type SimpleComparer<T> = (property1: T, property2: T) => number;

/**
 * Custom sorter definition. This allows us to sort arrays by calculated fields, or to customize the
 * way the actual comparison work
 */
export interface LgSorterDefinition<TItem = any, TProperty = any> {
    /**
     * Define extractor for the property. This can be used when the property doesn't correspond to ID used by the sorter,
     * or when it's calculated. The extract will be called only once per item (within one sorting operation)
     *
     * @param item the array element from which the property is to be extracted
     */
    extract?(item: TItem): TProperty;

    /**
     * Define a comparer, which will be used to compare property of 2 elements of the array. As for standard Array.sort,
     * the comparer should return negative value if first element is less than second, positive value if it's greater,
     * or 0 otherwise. For reverse sorting, the result will be automatically negated.
     */
    comparer?: SimpleComparer<TProperty>;

    /**
     * Define a comparer, which will be used to compare property of 2 elements of the array. As for standard Array.sort,
     * the comparer should return negative value if first element is less than second, positive value if it's greater,
     * or 0 otherwise.
     *
     * Unlike comparer, this callback receives also the "reverse" parameter and must swap the sort order as necessary.
     * Doing it itself will allow the implementation to implement special behaviour, for example always push undefined
     * values to the end of the array, no matter the sorting order.
     */
    reversibleComparer?: ReverseAwareComparer<TProperty>;
}

/**
 * Definition of multiple sorters, identified by their names.
 */
export interface LgSorterDefinitions {
    [index: string]: LgSorterDefinition;
}

/**
 * Callback that sorts the array based on prepared criteria.
 */
export type LgPreparedSortBy<T> = (
    array: ArrayLike<T> | null | undefined,
    reverseOrder?: boolean
) => T[];

// ---------------------------------------------------------------------------------------------
interface ArrayElementExtract<T> {
    properties: any[] | null;
    index: number;
    value: T;
}

const emptySorter = <LgSorterDefinition>(
    (<unknown>{ extract: null, reversibleComparer: null, comparer: null })
);
const defaultComparer = toReverseAwareCompare(regularComparer);

function mergedComparer<T>(
    value1: ArrayElementExtract<T>,
    value2: ArrayElementExtract<T>,
    comparers: Array<ReverseAwareComparer<T>>,
    reverseFlags: boolean[]
): number {
    if (value1.properties !== null && value2.properties !== null) {
        const length = comparers.length;
        let index = -1;

        while (++index < length) {
            const comparison = comparers[index](
                value1.properties[index],
                value2.properties[index],
                reverseFlags[index]
            );
            if (comparison !== 0) return comparison;
        }
    } else if (value1.properties !== null) {
        // empty values go to the end
        return -1;
    } else if (value2.properties !== null) {
        // empty values go to the end
        return 1;
    }

    return value1.index - value2.index;
}

function arraySorter<T>(
    array: ArrayLike<T> | null | undefined,
    extractors: Array<(source: T) => any>,
    comparers: Array<ReverseAwareComparer<T>>,
    reverseFlags: boolean[],
    reverseOrder?: boolean
): T[] {
    if (array == null) return [];

    const length = array.length;
    let index = -1;
    const extract: Array<ArrayElementExtract<T>> = [];
    reverseOrder = !!reverseOrder;

    // extract properties
    while (++index < length) {
        const value = array[index];
        const properties = value ? extractors.map(extractor => extractor(value)) : null;
        extract.push({
            properties,
            index,
            value
        });
    }

    // use native sort with our comparer
    if (reverseOrder) {
        extract.sort((value, other) => -mergedComparer(value, other, comparers, reverseFlags));
    } else {
        extract.sort((value, other) => mergedComparer(value, other, comparers, reverseFlags));
    }

    // convert the extract back into the original values
    return extract.map(element => element.value);
}

// ---------------------------------------------------------------------------------------------
function simpleMergedComparer<T>(
    value1: ArrayElementExtract<T>,
    value2: ArrayElementExtract<T>,
    ids: string[],
    comparers: Array<ReverseAwareComparer<T>>,
    reverseFlags: boolean[]
): number {
    if (value1.value != null && value2.value != null) {
        const length = comparers.length;
        let index = -1;

        while (++index < length) {
            const id = ids[index];
            const comparison = comparers[index](
                (value1.value as any)[id],
                (value2.value as any)[id],
                reverseFlags[index]
            );
            if (comparison !== 0) return comparison;
        }
    } else if (value1.value != null) {
        // empty values go to the end
        return -1;
    } else if (value2.value != null) {
        // empty values go to the end
        return 1;
    }

    return value1.index - value2.index;
}

// ---------------------------------------------------------------------------------------------
function simpleArraySorter<T>(
    array: ArrayLike<T> | null | undefined,
    ids: string[],
    comparers: Array<ReverseAwareComparer<T>>,
    reverseFlags: boolean[],
    reverseOrder: boolean
): T[] {
    if (array == null) return [];

    const length = array.length;
    let index = -1;
    const extract: Array<ArrayElementExtract<T>> = [];
    reverseOrder = !!reverseOrder;

    // extract properties
    while (++index < length) {
        const value = array[index];
        extract.push({
            properties: null,
            index,
            value
        });
    }

    // use native sort with our comparer
    if (reverseOrder) {
        extract.sort(
            (value, other) => -simpleMergedComparer(value, other, ids, comparers, reverseFlags)
        );
    } else {
        extract.sort((value, other) =>
            simpleMergedComparer(value, other, ids, comparers, reverseFlags)
        );
    }

    // convert the extract back into the original values
    return extract.map(element => element.value);
}

// ---------------------------------------------------------------------------------------------
/**
 * Prepare sorting callback, which can be later used to sort multiple arrays by the same criteria. This can
 * be used for situations, when we do multiple sorts using the same specification (like in pivot).
 *
 * @param predicates Predicate or list of predicates. The predicates are simple strings corresponding to the
 *   properties to sort by. The identifiers can be prefixed by - to indicate reverse sorting. They can be
 *   also prefixed by +, which has no effect and will be simply stripped.
 * @param sorters Definition of special sorters to be used. These sorters can override either the way the
 *   property is obtained, or the way the comparison is done, or both.
 */
export function lgSortByPrepare<T = any>(
    predicates: string | string[] | null | undefined,
    sorters?: LgSorterDefinitions | null | undefined
): LgPreparedSortBy<T> {
    if (!predicates || predicates.length === 0) {
        // when no predicates are specified, just do a simple sort (which is however aware of numeric values)
        return array => {
            if (array == null) return [];
            return Array.from(array).sort(regularComparer);
        };
    }

    if (!Array.isArray(predicates)) predicates = [predicates];
    if (!sorters) sorters = {};

    const reverseFlags: boolean[] = [];
    const ids: string[] = [];
    let extractorFound = false;

    // split the predicates into IDs and directions, and look for callbacks
    for (const predicate of predicates) {
        let id = predicate;
        let reversed = false;
        if (predicate[0] === "+" || predicate[0] === "-") {
            reversed = predicate[0] === "-";
            id = predicate.substring(1);
        }

        reverseFlags.push(reversed);
        ids.push(id);

        const sorter = sorters[id];
        if (sorter && sorter.extract) {
            extractorFound = true;
        }
    }

    const extractors: Array<(source: T) => any> = [];
    const comparers: Array<ReverseAwareComparer<T>> = [];
    // gather extractors and comparers
    for (const id of ids) {
        const {
            extract,
            reversibleComparer: reversableComparer,
            comparer
        } = sorters[id] || emptySorter;

        if (extractorFound) {
            extractors.push(extract || (val => val[id as keyof T]));
        }

        if (reversableComparer) {
            comparers.push(reversableComparer);
        } else if (comparer) {
            comparers.push(toReverseAwareCompare(comparer));
        } else {
            comparers.push(defaultComparer);
        }
    }

    if (extractorFound) {
        return (array: ArrayLike<T> | null | undefined, reverseOrder: boolean = false) => {
            return arraySorter(array, extractors, comparers, reverseFlags, reverseOrder);
        };
    } else {
        return (array: ArrayLike<T> | null | undefined, reverseOrder: boolean = false) => {
            return simpleArraySorter(array, ids, comparers, reverseFlags, reverseOrder);
        };
    }
}

// ---------------------------------------------------------------------------------------------
/**
 * Sort array according to the specified predicates. This is simple wrapper of lgSortByPrepare, followed
 * by immediate invocation of the callback.
 *
 * @param array The array to be sorted. Note that if null or undefined is passed, empty array is returned.
 * @param predicates Predicate or list of predicates. The predicates are simple strings corresponding to the
 *   properties to sort by. The identifiers can be prefixed by - to indicate reverse sorting. They can be
 *   also prefixed by +, which has no effect and will be simply stripped.
 * @param sorters Definition of special sorters to be used. These sorters can override either the way the
 *   property is obtained, or the way the comparison is done, or both.
 * @prams reverseOrder specifies, if the whole array should be swapped. Note that this is always implemented
 *   by reversing the order, not by swapping the predicates - this may play role if reversableComparer is used.
 */
export function lgSortBy<T>(
    array: ArrayLike<T> | null | undefined,
    predicates: string | string[] | null | undefined,
    sorters?: LgSorterDefinitions | null | undefined,
    reverseOrder?: boolean
): T[] {
    const preparedSorter = lgSortByPrepare<T>(predicates, sorters);
    return preparedSorter(array, reverseOrder);
}
