import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    standalone: true,
    name: "fmtText",
    pure: true
})
export class LgFormatTextPipe implements PipeTransform {
    public transform(value: string | null | undefined): string {
        return value == null ? "" : value;
    }
}
