import { InjectionToken } from "@angular/core";

export const LG_APPLICATION_EVENT_TRACER = new InjectionToken<IApplicationEventTracer>(
    "lgApplicationEventTracer"
);

export const LG_APPLICATION_MULTI_EVENT_TRACERS = new InjectionToken<IApplicationEventTracer[]>(
    "lgApplicationMultiEventTracers"
);

export enum LgApplicationEventTracerNames {
    Matomo = "LgMatTrackingService",
    GoogleAnalytics = "LgGaTrackingService",
    ApplicationInsights = "LgApplicationInsightsService"
}

export enum ApplicationTraceSeverity {
    Verbose = 0,
    Information = 1,
    Warning = 2,
    Error = 3,
    Critical = 4
}

export interface IApplicationEventTracer {
    tracerName: string;
    pageChange(): void;
    trackEvent(category: string, action: string, label?: string, value?: number): void;
    trackTime(category: string, variable: string, value: number, label?: string): void;
    trackTrace(
        severity: ApplicationTraceSeverity,
        message: string,
        customProperties?: unknown
    ): void;
    trackException(exception: Error, customProperties?: unknown): void;
}
