import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    inject,
    Injectable
} from "@angular/core";
import { take } from "rxjs/operators";
import { getDialogFactoryBase, IDialogOptions, LgDialogRef } from "@logex/framework/ui-core";
import { atNextFrame } from "@logex/framework/utilities";
import { LgTranslateService, useTranslationNamespace } from "@logex/framework/lg-localization";
import { LgReleaseNotesService } from "./lg-release-notes.service";

@Component({
    standalone: false,
    selector: "lg-release-notes-dialog",
    templateUrl: "./lg-release-notes-dialog.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    viewProviders: [useTranslationNamespace("FW._Dialogs._ReleaseNotes")],
    styles: [":host {display: block; max-height: inherit;}"]
})
export class LgReleaseNotesDialogComponent {
    private _changeDetectorRef = inject(ChangeDetectorRef);
    private _dialogRef = inject(LgDialogRef<LgReleaseNotesDialogComponent>);
    private _releaseNotesService = inject(LgReleaseNotesService);
    private _translateService = inject(LgTranslateService);

    _options?: IDialogOptions;
    _title = "";
    _dialogClass = "lg-dialog lg-dialog--4col release-notes-dialog";
    _notes = "";

    constructor() {
        this._title = this._translateService.translate(".Title");
        this._releaseNotesService
            .getReleaseNotes()
            .pipe(take(1))
            .subscribe(data => {
                this._notes = data;
                if (this._dialogRef.visible) {
                    atNextFrame(() => {
                        this._dialogRef.center();
                    });
                }
                this._changeDetectorRef.markForCheck();
            });
    }

    show(): void {
        this._releaseNotesService.markAsRead();
    }

    _hide(): void {
        this._dialogRef.close();
    }
}

@Injectable()
export class LgReleaseNotesDialogFactory extends getDialogFactoryBase(
    LgReleaseNotesDialogComponent,
    "show"
) {}
