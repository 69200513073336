import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    inject,
    Injector,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    SimpleChanges,
    ViewChild
} from "@angular/core";
import { CdkPortalOutlet, ComponentPortal } from "@angular/cdk/portal";

import type { IFilterDefinition } from "../filter-definition";
import type { LgFilterSet } from "../lg-filterset";
import { Subscription } from "rxjs";
import { FilterRendererComponentBase } from "../filter-renderer-component-base";

@Component({
    standalone: false,
    selector: "lg-filterset-list-item",
    template: ` <ng-template [cdkPortalOutlet] #outlet></ng-template> `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: "lg-filterset-list__item"
    }
})
export class LgFiltersetListItemComponent implements OnChanges, OnDestroy {
    private _injector = inject(Injector);
    private _changeDetectorRef = inject(ChangeDetectorRef);

    /**
     * Definition of filter (required).
     */
    @Input({ required: true }) definition!: IFilterDefinition;

    /**
     * List of all filters (required).
     */
    @Input({ alias: "list", required: true }) filterSet!: LgFilterSet;

    @Output() readonly filterChange = new EventEmitter<void>();

    @ViewChild("outlet", { read: CdkPortalOutlet, static: true }) _outlet!: CdkPortalOutlet;

    private _listSubscription: Subscription | null = null;
    private _instance: FilterRendererComponentBase<any, any> | null = null;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.definition) {
            if (this._outlet.attached) {
                this._outlet.detach();
                this._instance = null;
            }
            if (this.definition && this.definition.renderer) {
                const portal = new ComponentPortal(
                    this.definition.renderer.getFilterLineComponent(),
                    null,
                    this._injector
                );
                this._instance = this._outlet.attachComponentPortal(portal).instance;

                this._instance._initialize(
                    this.definition,
                    this.filterSet.filters,
                    false,
                    () => this._change(),
                    () => undefined
                );
            }
        }

        if (changes.filterSet) {
            if (this._listSubscription) {
                this._listSubscription.unsubscribe();
                this._listSubscription = null;
            }
            if (this.filterSet) {
                this._listSubscription = this.filterSet.onChanged.subscribe(filters => {
                    if (this.definition && filters?.includes(this.definition.id)) {
                        this._changeDetectorRef.markForCheck();
                        this._instance?._markForChanges();
                    }
                });
            }
        }
    }

    ngOnDestroy(): void {
        this._listSubscription?.unsubscribe();
    }

    private _change(): void {
        this.filterChange.next();
    }
}
