import {
    Directive,
    AfterViewInit,
    OnDestroy,
    Input,
    ElementRef,
    Renderer2,
    inject
} from "@angular/core";

import { toBoolean, toInteger } from "@logex/framework/utilities";

@Directive({
    selector: "[lgCellLoaderOverlay]",
    host: {
        "[attr.lgCellLoaderOverlayHost]": "true"
    }
})
export class LgCellLoaderOverlayDirective implements AfterViewInit, OnDestroy {
    private _elementRef = inject(ElementRef<HTMLElement>);
    private _renderer = inject(Renderer2);

    /**
     * Specifies if loader overlay should be shown.
     *
     * @default false
     */
    @Input("lgCellLoaderOverlay") set visible(val: boolean | "true" | "false") {
        val = toBoolean(val);
        if (val === this._visible) return;

        if (val) {
            this.show();
        } else {
            this.hide();
        }
        this._visible = val;
    }

    get visible(): boolean {
        return this._visible;
    }

    /**
     * Specifies delay before show overlay (in ms, if true then 100).
     *
     * @default 0
     */
    @Input("lgLCelloaderOverlayDelay") set delay(val: boolean | number | string) {
        if (val === true || val === "true") {
            this._delay = 100;
        } else {
            this._delay = toInteger(val);
        }
    }

    get delay(): number {
        return this._delay;
    }

    private _refCount = 0;
    private _initialized = false;
    private _visible = false;
    private _delay = 0;
    private _instance: HTMLElement | null = null;
    private _timer: number | null = null;

    show(): void {
        this._refCount += 1;
        if (this._initialized && this._refCount === 1) {
            if (!this._delay) {
                this._create();
                this._cancelTimer();
            } else if (!this._timer) {
                this._timer = window.setTimeout(() => {
                    this._timer = null;
                    this._create();
                }, this._delay);
            }
        }
    }

    hide(): void {
        this._refCount -= 1;
        if (this._initialized && this._refCount === 0) {
            this._remove();
            this._cancelTimer();
        }
    }

    ngAfterViewInit(): void {
        this._initialized = true;
        if (this._refCount) {
            this._create();
        }
    }

    ngOnDestroy(): void {
        this._remove();
        this._cancelTimer();
    }

    private _cancelTimer(): void {
        if (this._timer !== null) {
            clearTimeout(this._timer);
            this._timer = null;
        }
    }

    private _create(): void {
        if (!this._instance) {
            this._instance = this._renderer.createElement("div");
            this._renderer.addClass(this._instance, "lg-cell-loader-overlay");
            this._renderer.appendChild(this._elementRef.nativeElement, this._instance);
        }
    }

    private _remove(): void {
        if (this._instance) {
            this._renderer.removeChild(this._elementRef.nativeElement, this._instance);
            if (this._renderer.destroyNode) {
                this._renderer.destroyNode(this._instance);
            }
            this._instance = null;
        }
    }
}
