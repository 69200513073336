import { inject, Injectable } from "@angular/core";
import ldIsEmpty from "lodash-es/isEmpty";
import { LgConsole } from "@logex/framework/core";
import { ngParamSerializer } from "./BrowserCacheProviderHelper";
import { LG_USER_INFO } from "../user/index";
import { LG_APP_SESSION } from "../application/app-session";
import { LG_APP_CONFIGURATION } from "../application/app-configuration";

// TODO: move to module

@Injectable({ providedIn: "root" })
export class BrowserCacheProvider {
    private _appConfiguration = inject(LG_APP_CONFIGURATION);
    private _console = inject(LgConsole).withSource("Logex.Application.ServerGatewayBase");
    private _session = inject(LG_APP_SESSION, { optional: true });
    private _userInfo = inject(LG_USER_INFO, { optional: true });

    isAvailable: boolean;

    get localhost(): boolean {
        return this._localhost;
    }

    private _caches!: CacheStorage;
    private _localhost!: boolean;

    constructor() {
        this._localhost = location.hostname === "localhost";
        const isHttpsConnection = location.protocol === "https:";
        this._caches = caches;

        this.isAvailable = isHttpsConnection || this._localhost;
    }

    getCacheKeyInUrlLikeFormat(requestUrl: string, params: any, data: any): string {
        return this._getCacheKeyInUrlLikeFormat(requestUrl, params, data);
    }

    getCache(cacheName?: string): Promise<BrowserCache> {
        if (cacheName == null) cacheName = this._getCacheName();

        return this._getConnectedCache(cacheName).then(
            cache => new BrowserCache(cacheName!, cache)
        );
    }

    private _getConnectedCache(cacheName?: string): Promise<Cache> {
        return this._caches.open(cacheName || this._getCacheName());
    }

    private _getCacheKeyInUrlLikeFormat(requestUrl: string, params: any, data: any): string {
        let separator = "?";
        if (!ldIsEmpty(params)) {
            requestUrl += separator + ngParamSerializer(params);
            separator = "&";
        }
        if (!ldIsEmpty(data)) {
            requestUrl += separator + "__body__=" + JSON.stringify(data);
        }

        return requestUrl;
    }

    private _getCacheName(): string {
        const appName = location.pathname.replace(/\//g, "");

        let name = `requests-${appName}`;
        if (this._localhost) {
            name += `-i${this._appConfiguration.instance}`;
        }

        const clientId = this._session?.clientId;
        if (clientId) {
            name += `-c${clientId}`;
        }

        const scenarioId = this._session?.scenarioId;
        if (scenarioId) {
            name += `-s${scenarioId}`;
        }

        const userId = this._userInfo?.userid ?? this._userInfo?.id;
        if (userId) {
            name += `-u${userId}`;
        }

        return name;
    }
}

export class BrowserCache {
    get cacheName(): string {
        return this._cacheName;
    }

    constructor(
        private _cacheName: string,
        private _cache: Cache
    ) {}

    updateCache(key: string, data: Response): Promise<void> {
        return this._cache.put(key, data);
    }

    deleteFromCache(key: string): Promise<boolean> {
        return this._cache.delete(key, { ignoreMethod: true });
    }

    reduceCache(): Promise<void> {
        return this._cache.keys().then(keys => {
            keys.forEach(request => {
                // todo: do something more inteligent? But currently Chrome doesn't return us the proper headers
                this._cache.delete(request);
                // we need to match() in order to get full request data
                // cache.match( request ).then( v => {
                // } )
            });
        });
    }

    getResponse(key: string): Promise<Response | undefined> {
        return this._cache.match(key);
    }

    tryGetResponse(key: string): Promise<Response | null | undefined> {
        return this._cache.match(key).catch(() => null);
    }

    private _onCacheOpenFailure(
        _cacheName: string,
        _error: any,
        cacheConnectionErrorFunction: () => void
    ): void {
        //        this._console.error( `Error getting browser cache "${cacheName}"`, error );

        cacheConnectionErrorFunction();
    }
}
