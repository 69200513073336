import ldSome from "lodash-es/some";
import ldIsString from "lodash-es/isString";
import ldTrimStart from "lodash-es/trimStart";

import {
    Component,
    Input,
    OnChanges,
    SimpleChanges,
    EventEmitter,
    OnDestroy,
    Directive,
    ChangeDetectionStrategy
} from "@angular/core";

import { toInteger, toBoolean } from "@logex/framework/utilities";
import {
    LgColDefinitionColumnTypeSource,
    LgColDefinitionColumnSource,
    LgColDefinitionLikeSource,
    LgColDefinitionInheritSource
} from "../services";

// ----------------------------------------------------------------------------------
//  Base class shared by all the column definitions
// ----------------------------------------------------------------------------------
@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class LgColBase implements OnChanges, OnDestroy {
    public readonly _change$ = new EventEmitter<LgColBase>();

    public abstract _getSourceDefinition(): LgColDefinitionColumnTypeSource;

    public ngOnChanges(changes: SimpleChanges): void {
        // if is handled in LgColDefinitionPrepared and doesn't need force rebuilding
        if (ldSome(changes, (_change, id) => id !== "if")) {
            this._change$.next(this);
        }
    }

    public ngOnDestroy(): void {
        this._change$.complete();
    }
}

// ----------------------------------------------------------------------------------
//  Regular column definition
// ----------------------------------------------------------------------------------
@Component({
    selector: "lg-col",
    template: "",
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{ provide: LgColBase, useExisting: LgColComponent }]
})
export class LgColComponent extends LgColBase {
    @Input() public id?: string;

    @Input() public set width(val: number | string | null | undefined) {
        this._flexibility = 0;

        if (ldIsString(val)) {
            if (val.charAt(0) === "*") {
                this._numericWidth = 0;
                this._flexibility = 1;
                if (val.length > 1) {
                    if (val.includes("+") || val.includes("-")) {
                        const [flexibility, numericWidth] = val.replace(/\*|\s/g, "").split(/\+|-/);
                        this._flexibility = flexibility ? parseFloat(flexibility) : 1;
                        this._numericWidth = parseFloat(
                            val.includes("+") ? `+${numericWidth}` : `-${numericWidth}`
                        );
                    } else {
                        this._flexibility = parseFloat(val.substring(1));
                    }
                }
            } else {
                this._numericWidth = parseFloat(val);
            }
        } else {
            this._numericWidth = val;
        }
    }

    get width(): number | string | null | undefined {
        if (this._flexibility === 0) return this._numericWidth;
        const prefix = this._flexibility === 1 ? "*" : `*${this._flexibility}`;
        if (this._numericWidth === 0) {
            return prefix;
        } else if (this._numericWidth && this._numericWidth > 0) {
            return `${prefix}+${this._numericWidth}`;
        } else {
            return `${prefix}${this._numericWidth}`;
        }
    }

    @Input() set paddingLeft(val: number | string) {
        this._paddingLeft = toInteger(val);
    }

    get paddingLeft(): number | undefined {
        return this._paddingLeft;
    }

    @Input() set paddingRight(val: number | string) {
        this._paddingRight = toInteger(val);
    }

    get paddingRight(): number | undefined {
        return this._paddingRight;
    }

    @Input() set widthTweak(val: number | string) {
        this._widthTweak = toInteger(val);
    }

    get widthTweak(): number | undefined {
        return this._widthTweak;
    }

    @Input() public type?: string | null;

    @Input("class") public set columnClass(val: string | undefined) {
        this._mergeColumnClasses = false;
        this._columnClasses = undefined;
        if (val === undefined) return;

        val = val.trim();
        if (!val) return;

        if (val.charAt(0) === "+") {
            this._mergeColumnClasses = true;
            val = ldTrimStart(val.substring(1));
        }
        this._columnClasses = val
            .split(" ")
            .map(c => c.trim())
            .filter(c => !!c);
    }

    get columnClass(): string | undefined {
        if (this._columnClasses === undefined) return undefined;
        return `${this._mergeColumnClasses ? "+" : ""}${this._columnClasses.join(" ")}`;
    }

    @Input() public set if(val: boolean | "true" | "false") {
        this._if = toBoolean(val);
    }

    get if(): boolean | undefined {
        return this._if;
    }

    private _numericWidth: number | null | undefined = 0;
    private _flexibility = 0;
    private _paddingLeft: number | undefined = undefined;
    private _paddingRight: number | undefined = undefined;
    private _widthTweak: number | undefined = undefined;
    private _if: boolean | undefined;
    private _mergeColumnClasses = false;
    private _columnClasses: string[] | undefined = undefined;

    // ----------------------------------------------------------------------------------
    public _getSourceDefinition(): LgColDefinitionColumnSource {
        return {
            node: "column",
            id: this.id || "UnknownColumnId",
            width: this._numericWidth,
            flexibilityFactor: this._flexibility,
            columnClasses: this._columnClasses,
            mergeColumnClasses: this._mergeColumnClasses,
            columnType: this.type,
            paddingLeft: this._paddingLeft,
            paddingRight: this._paddingRight,
            widthTweak: this._widthTweak,
            if: this._if === undefined ? undefined : () => this._if!
        };
    }
}

// ----------------------------------------------------------------------------------
//  Column inheritance
// ----------------------------------------------------------------------------------
@Component({
    selector: "lg-col-inherit",
    template: "",
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{ provide: LgColBase, useExisting: LgColInheritComponent }]
})
export class LgColInheritComponent extends LgColBase {
    @Input({ required: true }) public row!: string;

    @Input() public from?: string;

    @Input() public to?: string;

    @Input() public set rename(val: string | string[] | Record<string, string>) {
        if (ldIsString(val)) {
            val = val.trim();
            if (!val) {
                this._rename = undefined;
                return;
            }
            this._rename = val.split(",").map(id => id.trim());
        } else {
            this._rename = val;
        }
    }

    get rename(): string[] | Record<string, string> | undefined {
        return this._rename;
    }

    private _rename: string[] | Record<string, string> | undefined;

    // ----------------------------------------------------------------------------------
    public _getSourceDefinition(): LgColDefinitionInheritSource {
        return {
            node: "inherit",
            row: this.row,
            from: this.from,
            to: this.to,
            rename: this._rename
        };
    }
}

// ----------------------------------------------------------------------------------
//  Column copy
// ----------------------------------------------------------------------------------
@Component({
    selector: "lg-col-like",
    template: "",
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{ provide: LgColBase, useExisting: LgColLikeComponent }]
})
export class LgColLikeComponent extends LgColBase {
    @Input({ required: true }) public row!: string;

    @Input() public id?: string;

    @Input() public column?: string;

    @Input() public set paddingLeft(val: number | string) {
        this._paddingLeft = toInteger(val);
    }

    get paddingLeft(): number | undefined {
        return this._paddingLeft;
    }

    @Input() public set paddingRight(val: number | string) {
        this._paddingRight = toInteger(val);
    }

    get paddingRight(): number | undefined {
        return this._paddingRight;
    }

    @Input("class") public set columnClass(val: string | undefined) {
        this._mergeColumnClasses = false;
        this._columnClasses = undefined;
        if (val === undefined) return;

        val = val.trim();
        if (!val) return;

        if (val.charAt(0) === "+") {
            this._mergeColumnClasses = true;
            val = ldTrimStart(val.substring(1));
        }
        this._columnClasses = val
            .split(" ")
            .map(c => c.trim())
            .filter(c => !!c);
    }

    get columnClass(): string | undefined {
        if (this._columnClasses === undefined) return undefined;
        return `${this._mergeColumnClasses ? "+" : ""}${this._columnClasses.join(" ")}`;
    }

    @Input() public set colSpan(val: number | string | undefined) {
        this._colSpan = toInteger(val);
        if (val === 1) val = undefined;
    }

    get colSpan(): number | undefined {
        return this._colSpan;
    }

    private _paddingLeft: number | undefined;
    private _paddingRight: number | undefined;
    private _colSpan: number | undefined;
    private _mergeColumnClasses = false;
    private _columnClasses: string[] | undefined = undefined;

    // ----------------------------------------------------------------------------------
    public _getSourceDefinition(): LgColDefinitionLikeSource {
        return {
            node: "like",
            id: this.id || "UnknownColumnId",
            column: this.column,
            row: this.row,
            paddingLeft: this._paddingLeft,
            paddingRight: this._paddingRight,
            columnClasses: this._columnClasses,
            mergeColumnClasses: this._mergeColumnClasses,
            colSpan: this._colSpan
        };
    }
}
