import { APP_INITIALIZER, ModuleWithProviders, NgModule } from "@angular/core";
import { UiCoreModule } from "@logex/framework/ui-core";
import { CommonModule } from "@angular/common";

import { LgForRoutesDirective } from "./navigation";
import {
    AppUser,
    LG_USER_INFO,
    LG_USER_STORAGE_SERVICE_GATEWAY,
    LgUserStorageGateway
} from "./user";
import {
    AppInfo,
    LG_APP_CONTROL,
    LG_APP_INFO,
    LG_APP_SESSION,
    LgAppControlV1Service,
    lgAppSessionMockFactory
} from "./application";
import { appInitFactory, LgAppInitializerService } from "./application/lg-app-initializer.service";
import { LG_AUTHENTICATION_SERVICE, LgStubAuthenticationService } from "./auth";
import { LG_MESSAGE_BUS_SERVICE, LgMessageBusService } from "./network";
import { LG_USERFLOW_SERVICE, LgUserflowService } from "./integration";

@NgModule({
    imports: [CommonModule, LgForRoutesDirective, UiCoreModule],
    exports: [LgForRoutesDirective]
})
export class LgApplicationModule {
    static forRoot(): ModuleWithProviders<LgApplicationModule> {
        return {
            ngModule: LgApplicationModule,
            providers: [
                AppInfo,
                {
                    provide: LG_APP_INFO,
                    useExisting: AppInfo
                },
                {
                    provide: LG_USER_STORAGE_SERVICE_GATEWAY,
                    useClass: LgUserStorageGateway
                },
                {
                    provide: APP_INITIALIZER,
                    useFactory: appInitFactory,
                    deps: [LgAppInitializerService],
                    multi: true
                },
                {
                    provide: LG_APP_SESSION,
                    useFactory: lgAppSessionMockFactory
                },
                {
                    provide: LG_AUTHENTICATION_SERVICE,
                    useClass: LgStubAuthenticationService
                },
                {
                    provide: LG_MESSAGE_BUS_SERVICE,
                    useClass: LgMessageBusService
                },
                {
                    provide: LG_APP_CONTROL,
                    useClass: LgAppControlV1Service
                },
                {
                    provide: LG_USER_INFO,
                    useClass: AppUser
                },
                {
                    provide: LG_USERFLOW_SERVICE,
                    useExisting: LgUserflowService
                }
            ]
        };
    }
}
