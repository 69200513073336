import { Directive, Input, OnChanges, OnDestroy, inject } from "@angular/core";
import { Subject, Observable, Subscription } from "rxjs";

import { LgColDefinitionInstantiated, LgColDefinitionColumnParameters } from "../services";
import { LgColDefinitionDirective } from "./lg-col-definition.directive";

@Directive({
    standalone: true,
    selector: "[lgColRow]"
})
export class LgColRowDirective implements OnChanges, OnDestroy {
    private _definition = inject(LgColDefinitionDirective);
    @Input("lgColRow") rowId: string | null = null;

    @Input("lgColClass") set columnClasses(val: string | undefined) {
        this._columnClasses = undefined;
        if (val === undefined) return;

        val = val.trim();
        if (!val) return;

        this._columnClasses = val
            .split(" ")
            .map(c => c.trim())
            .filter(c => !!c);
    }

    get columnClasses(): string | undefined {
        if (this._columnClasses == null) return undefined;
        return this._columnClasses.join(" ");
    }

    // ---------------------------------------------------------------------------------------------
    public onChange(): Observable<LgColRowDirective> {
        return this._change$.asObservable();
    }

    // ---------------------------------------------------------------------------------------------
    public getColumnParameters(
        columnId: string,
        colspan: number
    ): LgColDefinitionColumnParameters | null {
        if (!this._instantiated) return null;
        const result = this._instantiated.getColumnParameters(
            !this.rowId ? false : this.rowId,
            columnId,
            colspan
        );
        if (result && this._columnClasses) result.rowColumnClasses = this._columnClasses;
        return result;
    }

    // ---------------------------------------------------------------------------------------------
    public constructor() {
        this._subscription = this._definition.instantiatedDefinition().subscribe(instantiated => {
            this._instantiated = instantiated;
            this._change$.next(this);
        });
    }

    // ---------------------------------------------------------------------------------------------
    private _columnClasses: string[] | undefined = undefined;
    private readonly _change$ = new Subject<LgColRowDirective>();
    private _subscription: Subscription | null;
    private _instantiated!: LgColDefinitionInstantiated;

    // ---------------------------------------------------------------------------------------------
    public ngOnChanges(): void {
        if (this._instantiated) this._change$.next(this);
    }

    // ---------------------------------------------------------------------------------------------
    public ngOnDestroy(): void {
        if (this._subscription) this._subscription.unsubscribe();
        this._subscription = null;
        this._change$.complete();
    }
}
