<div class="lg-scrollable">
    <div
        [class.lg-quick-settings-menu-popup--leftIcons]="_alwaysLeftIcon"
        [class.lg-quick-settings-menu-popup--rightIcons]="_hasRightIcons"
        [class.lg-quick-settings-menu-popup--rightIcons--two]="_numberOfRightIcons === 2"
        [class.lg-quick-settings-menu-popup--rightIcons--three]="_numberOfRightIcons === 3"
        [class.lg-quick-settings-menu-popup--rightIcons--four]="_numberOfRightIcons === 4"
        lgScrollable
        lgScrollableAutoHide="true"
        [lgScrollableWrapper]="false"
        #content
        #scrollable
    >
        <div class="lg-quick-settings-menu-popup__group" *ngFor="let group of _popupGroups">
            <div
                class="lg-quick-settings-menu-popup__group-title"
                *ngIf="group.name"
                [lgTooltip]="_hideTooltipIcons && group.help ? group.help : null"
                lgTooltipPosition="left-bottom"
            >
                {{ group.name }}
                <lg-icon
                    icon="icon-help"
                    class="lg-quick-settings-menu-popup__group-help"
                    *ngIf="!_hideTooltipIcons && group.help"
                    [lgTooltip]="group.help"
                    [lgTooltipOptions]="{ delayShow: 0, delayHide: 0 }"
                    lgTooltipPosition="left-bottom"
                ></lg-icon>
            </div>
            <ng-container *ngFor="let item of group.items">
                <div
                    *ngIf="item === 'separator'"
                    class="lg-quick-settings-menu-popup__item lg-quick-settings-menu-popup__separator"
                ></div>
                <div
                    *ngIf="item !== 'separator'"
                    class="lg-quick-settings-menu-popup__item"
                    [ngClass]="{
                        'lg-quick-settings-menu-popup__item--left-icon': item.icon,
                        'lg-quick-settings-menu-popup__item--submenu': item.isSubmenu,
                        'lg-quick-settings-menu-popup__item--expanded': item === _expandedItem,
                        'lg-quick-settings-menu-popup__item--path': item.index === _pathIndex,
                        'lg-quick-settings-menu-popup__item--cursor': item.index === _cursorIndex
                    }"
                    [lgTooltip]="_hideTooltipIcons && item.help ? item.help : null"
                    lgTooltipPosition="left-bottom"
                    (mouseenter)="_hoverItem($event, item)"
                    (click)="_itemClick(item)"
                >
                    <span>
                        <lg-icon
                            [icon]="item.icon"
                            class="lg-quick-settings-menu-popup__item-icon"
                            *ngIf="item.icon"
                        ></lg-icon>
                        {{ item.name }}
                        <!-- just being explicit that the current implementation doesn't support both 'help/submenu icon' and 'rightIcons' for the same row -->
                        <span
                            *ngIf="
                                item.rightIcons && item.rightIcons.length;
                                else helpAndSubmenuIcon
                            "
                            class="lg-quick-settings-menu-popup__item__right-icons"
                        >
                            <span *ngFor="let icon of item.rightIcons" [title]="icon.title">
                                <lg-icon
                                    [class]="icon.iconClass"
                                    [inline]="true"
                                    [icon]="icon.icon"
                                    [clickable]="!!icon.clickable"
                                    (click)="
                                        icon.onClick &&
                                            icon.onClick($event, _menuContext, item.context)
                                    "
                                ></lg-icon>
                            </span>
                        </span>
                        <ng-template #helpAndSubmenuIcon>
                            <lg-icon
                                icon="icon-help"
                                class="lg-quick-settings-menu-popup__item-help"
                                *ngIf="!_hideTooltipIcons && item.help"
                                [lgTooltip]="item.help"
                                [lgTooltipOptions]="{ delayShow: 0, delayHide: 0 }"
                                lgTooltipPosition="left-bottom"
                            ></lg-icon>
                            <lg-icon
                                icon="icon-arrow-right"
                                class="lg-quick-settings-menu-popup__submenu-icon"
                                *ngIf="item.isSubmenu"
                            ></lg-icon>
                        </ng-template>
                    </span>
                </div>
            </ng-container>
        </div>
    </div>
</div>
