import { Component, inject, Input, OnInit } from "@angular/core";
import { LgTranslateService, useTranslationNamespace } from "@logex/framework/lg-localization";
import { LgChatGateway } from "./lg-chat-gateway";
import { LgChatStateService } from "./lg-chat-state.service";
import { LgChatController } from "./lg-chat-controller";

@Component({
    selector: "lg-chat",
    templateUrl: "./lg-chat.component.html",
    host: { "[attr.disabled]": "enabled ? null : true" },
    providers: [useTranslationNamespace("FW._Chat")]
})
export class LgChatComponent implements OnInit {
    @Input() enabled = false;
    /**
     * Specifies if the Chat toggle bubble button is hidden or not.
     * Default is true.
     */
    @Input() hideBubble = true;
    @Input() supportMail?: string;
    @Input() supportSubject?: string;
    @Input() chatTitle = "";

    private _controller = inject(LgChatController);
    private _gateway = inject(LgChatGateway);
    private _translateService = inject(LgTranslateService);
    protected _chatState = inject(LgChatStateService);

    ngOnInit(): void {
        if (this.enabled) {
            this.supportSubject ??= this._translateService.translate(".Support.Subject");
            this.supportMail ??= this._translateService.translate(".Support.Mail");
            this.chatTitle ??= this._translateService.translate(".Title");
        }
    }

    _sendMessage(text: string): void {
        this._chatState.notifyLoadingResponseChanged(true);
        this._chatState.addMessage({ sent: true, text });
        return this._gateway
            .sendMessage(text, this._chatState.chatId)
            .subscribe({
                next: response => {
                    this._chatState.setChatId(response.conversationId);
                    this._chatState.addMessage({
                        sent: false,
                        text: LgChatController.prepareResponse(response.response),
                        buttons: [
                            ...LgChatController.addManualPageIfNeeded(
                                response.manualUrl,
                                response.manualTitle
                            )
                        ]
                    });
                },
                error: err => {
                    this._chatState.addMessage({
                        sent: false,
                        text: err.error?.ExceptionMessage ?? err.message,
                        isError: true
                    });
                }
            })
            .add(() => {
                this._chatState.chatToggle(true);
                this._chatState.notifyLoadingResponseChanged(false);
            });
    }

    _chatToggle(): void {
        this._controller.toggleChat();
    }

    _prepareMail(): void {
        const newLine = "\n";
        const mail = this.supportMail;
        const subject = this.supportSubject;
        const chatId = this._chatState.chatId ?? "-";
        const transcript = this._chatState.messages!.map(m => `${m.sent ? " <" : " >"} ${m.text}`);
        let body = this._translateService.translate(".Support.Body");
        body += `${newLine}${newLine}`;
        body += `${this._translateService.translate(".Support.ChatId", { chatId })}${newLine}`;
        body += `${this._translateService.translate(".Support.Transcript")}${newLine}`;
        body += transcript.join(newLine);

        const url = encodeURI(`mailto:${mail}?subject=${subject}&body=${body}`);
        window.location.assign(url);
    }
}
