// this is nowhere near RFC specifications, but should suffice for our use
const AbsUrlRegex = /^[a-z][\w-]+:\/{1,3}/i;

export function urlConcat(base: string, path: string | null): string {
    if (base == null) throw Error("base parameter cannot be null");
    if (path?.match(AbsUrlRegex)) return path;

    const res: string[] = [];
    if (!base.endsWith("/")) {
        res.push(base);
    } else {
        res.push(base.substring(0, base.length - 1));
    }

    if (path == null) {
        res.push("");
    } else if (!path.startsWith("/")) {
        res.push(path);
    } else {
        res.push(path.substring(1));
    }

    return res.join("/");
}

/* Too universal
export function urlConcat( ...parts: string[] ): string {
    let res: string[] = [];

    let part: string;
    for ( let i = 0; i < parts.length; i++ ) {
        if ( i > 0 ) {
            if ( !part.endsWith( "/" ) ) {
                res.push( "/" );
            }
        }

        part = parts[i];

        if ( i > 0 && part.startsWith( "/" ) ) {
            res.push( part.substr( 1 ));
        } else {
            res.push( part );
        }
    }

    return res.join( "" );
}
*/
