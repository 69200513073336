import {
    Directive,
    Input,
    Output,
    isDevMode,
    EventEmitter,
    ElementRef,
    Renderer2,
    OnDestroy,
    AfterViewInit,
    inject
} from "@angular/core";
import { Observable, Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import {
    LgObserveSizeService,
    LgObserveSizeApi,
    LgObserveSizeOptions,
    SizeEvent,
    LgObserveSizeType
} from "./lg-observe-size.service";

/**
 * Directive to observe element size changes.
 */
@Directive({
    selector: "[lgObserveSize]",
    exportAs: "lgObserveSize"
})
export class LgObserveSizeDirective implements AfterViewInit, OnDestroy {
    private _elementRef = inject(ElementRef);

    /**
     * Definition of size changes to be observed.
     */
    @Input("lgObserveSize") public set options(
        value: LgObserveSizeType | Partial<LgObserveSizeOptions> | number | undefined
    ) {
        if (value === undefined) {
            this._options = { ...LgObserveSizeService._defaults };
            // eslint-disable-next-line eqeqeq
        } else if (typeof value === "number" || value == +value) {
            this._options = { ...LgObserveSizeService._defaults, auditTime: +value };
        } else if (typeof value === "string") {
            this._options = { ...LgObserveSizeService._defaults, type: value };
        } else {
            this._options = { ...LgObserveSizeService._defaults, ...value };
        }

        if (this._initialized && isDevMode()) {
            console.warn("lgObserveSize: cannot change auditTime after creation");
        }
    }

    get options(): LgObserveSizeOptions {
        return this._options;
    }

    @Output("sizeChange") readonly sizeChange = new EventEmitter<SizeEvent>();

    change(options?: Partial<LgObserveSizeOptions>): Observable<SizeEvent> {
        if (!options) {
            options = this._options;
        } else {
            options = { ...this._options, ...options };
        }

        return this._api.change(options).pipe(takeUntil(this._destroyed$));
    }

    private _options!: LgObserveSizeOptions;
    private _initialized = false;
    private readonly _destroyed$ = new Subject<void>();
    private _eventSubscription!: Subscription;
    private _api: LgObserveSizeApi;

    constructor() {
        const _renderer = inject(Renderer2);
        const _observeService = inject(LgObserveSizeService);
        this._api = _observeService.observe(this._elementRef, _renderer);
    }

    ngAfterViewInit(): void {
        if (this.sizeChange.observed) {
            this._eventSubscription = this.change().subscribe(ev => {
                this.sizeChange.next(ev);
                Promise.resolve().then(() => undefined);
            });
        }

        this._initialized = true;
    }

    ngOnDestroy(): void {
        if (this._eventSubscription) this._eventSubscription.unsubscribe();
        this._destroyed$.next();
        this._destroyed$.complete();
    }
}
