<lg-tags-selector-tag
    *ngFor="let tag of _parentTagComponents"
    [tagName]="tag.tagName"
    [current]="tag.current"
    [idType]="tag.idType"
    (currentChange)="_tagCurrentChange(tag, $event)"
    [getOptionDisplayName]="tag.getOptionDisplayNameCallback"
    [getOptionDisabledState]="tag.getOptionDisabledStateCallback"
    [getOptionOrderBy]="tag.getOptionOrderByCallback"
    [getOptions]="tag.getOptionsCallback"
    [disabled]="_isDisabled || tag.disabled"
>
</lg-tags-selector-tag>
<lg-icon-menu
    [definition]="_getAddTagDropdown()"
    (currentChange)="_addTag($event)"
    [lgTooltip]="'.AddTagTooltip' | lgTranslate"
    [attachOnLeft]="true"
    [condensed]="true"
    [hidden]="_isDisabled || _emptyTagsCount === 0"
    icon="icon-add"
    class="lg-tags-selector__add"
    popupClassName="lg-dropdown-popup lg-tags-selector__add-popup"
></lg-icon-menu>
