<span class="lg-chart-legend__item__wrapper">
    <ng-container *ngIf="!legendTemplate">
        <span
            *ngFor="let item of definition"
            class="lg-chart-legend__item"
            [class.lg-chart-legend__item--interactive]="shouldItemHavePointerCursor"
            (click)="_onClick(item)"
            (mouseenter)="_onMouseEnter(item)"
        >
            <lg-chart-icon
                [symbol]="item.symbol"
                [color]="item.color"
                [opacity]="item.opacity"
                [hasBorder]="!!item.symbolBorder"
            ></lg-chart-icon>
            <span
                class="lg-chart-legend__item__name"
                [class.lg-chart-legend__item__name--crop]="cropLongNames"
                [class.lg-chart-legend__item__name--legacy]="!_useNewLabels"
                [title]="item.name"
            >
                {{ item.name }}
            </span>
        </span>
    </ng-container>
    <ng-container *ngIf="!!legendTemplate" #legendContainer> </ng-container>
</span>
