<div class="lg-tags-selector__wrapper">
    <ng-content></ng-content>
</div>
<lg-icon
    icon="icon-dots"
    class="lg-tags-selector__more"
    lgTooltip="{{ _overflowTagsCount }} {{ '.More' | lgTranslate }}"
    [hidden]="_overflowTagsCount === 0"
    (click)="_openTagsSelectorPopup($event)"
></lg-icon>
<lg-icon-menu
    [definition]="_getAddTagDropdown()"
    (currentChange)="_addTag($event)"
    [lgTooltip]="'.AddTagTooltip' | lgTranslate"
    [attachOnLeft]="true"
    [condensed]="true"
    [hidden]="_isDisabled || _emptyTagsCount === 0"
    icon="icon-add"
    class="lg-tags-selector__add"
    popupClassName="lg-dropdown-popup lg-tags-selector__add-popup"
></lg-icon-menu>
