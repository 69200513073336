import { ChangeDetectionStrategy, Component, ViewEncapsulation } from "@angular/core";

@Component({
    selector: "lg-spinner",
    encapsulation: ViewEncapsulation.None,
    template: `
        <svg class="lg-spinner" viewBox="0 0 20 20">
            <circle class="path" cx="10" cy="10" r="8" fill="none" stroke-width="2"></circle>
        </svg>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ["./lg-spinner.component.scss"]
})
export class LgSpinnerComponent {}
