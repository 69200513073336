import { Injectable } from "@angular/core";
import { triggerBlobFileDownload } from "@logex/framework/utilities";

export class CsvOptions {
    separator = ";";
    lineEnd = "\r\n";
}

export class ColumnDefinition {
    name = "";
    content?: string;
    contentFn?: (item: any) => string | number;
}

@Injectable()
export class LgCsvExportService {
    exportCsv(csv: string, filename: string): void {
        const blob = new Blob([csv], { type: "text/csv" });
        triggerBlobFileDownload(blob, filename);
    }

    convertToCsv(rows: any[], columns: ColumnDefinition[], options: CsvOptions): string {
        let result = "";

        const header = [];
        for (const column of columns) {
            header.push(column.name);
        }
        result += header.join(options.separator) + options.lineEnd;

        for (const row of rows) {
            const propertiesList = [];
            for (const column of columns) {
                let propertyValue: string | number;
                if (typeof column.contentFn === "function") {
                    propertyValue = column.contentFn(row);
                } else {
                    propertyValue = row[column.content!];
                }
                propertiesList.push(this._normalizeCellValue(propertyValue));
            }
            result += propertiesList.join(options.separator) + options.lineEnd;
        }

        return result;
    }

    private _normalizeCellValue = (value: string | number): string => {
        if (value === undefined) return "";
        if (typeof value === "number") return `${value.toLocaleString()}`;
        else return `"${(value || "").replace(/"/g, '""')}"`;
    };
}
