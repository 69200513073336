import { Component, ChangeDetectionStrategy, ViewEncapsulation } from "@angular/core";

import { SidebarButtonBase } from "./sidebar-button-base";

@Component({
    standalone: false,
    selector: "lg-sidebar-button",
    template: ``,
    // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
    inputs: [
        "nameLc",
        "nameAlwaysVisible",
        "onTop",
        "icon",
        "large",
        "badge",
        "isVisible",
        "order",
        "id",
        "url",
        "buttonClass",
        "imageClass"
    ],
    // eslint-disable-next-line @angular-eslint/no-outputs-metadata-property, @angular-eslint/no-output-native, @angular-eslint/no-output-on-prefix
    outputs: ["onClick:click"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class LgSidebarButtonComponent extends SidebarButtonBase {}
