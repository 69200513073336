import { NgModule } from "@angular/core";
import { LgPanelBodyDirective, LgPanelHeaderDirective, LgPanelIconsDirective } from "./directives";
import {
    LgPanelComponent,
    LgPanelGridComponent,
    LgPanelGridNodeComponent,
    LgPanelResizeMenuComponent,
    LgPanelResizeMenuPopupComponent
} from "./components";

const COMPONENTS = [
    LgPanelComponent,
    LgPanelGridComponent,
    LgPanelGridNodeComponent,
    LgPanelResizeMenuComponent,
    LgPanelResizeMenuPopupComponent
];

const DIRECTIVES = [LgPanelBodyDirective, LgPanelHeaderDirective, LgPanelIconsDirective];

@NgModule({
    imports: [...COMPONENTS, ...DIRECTIVES],
    exports: [...COMPONENTS, ...DIRECTIVES]
})
export class LgPanelGridModule {}
