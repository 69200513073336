import ldIsNumber from "lodash-es/isNumber";
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    standalone: true,
    // eslint-disable-next-line @angular-eslint/pipe-prefix
    name: "highlight",
    pure: true
})
export class LgHighlightPipe implements PipeTransform {
    public transform(
        text: string,
        search: string,
        caseSensitive = false,
        asteriskSuffix = false
    ): string {
        if ((search != null && search !== "") || ldIsNumber(search)) {
            text = text.toString();
            search = search.toString();
            let asteriskSuffixFound = false;

            if (asteriskSuffix && search.endsWith("*")) {
                asteriskSuffixFound = true;
                search = search.slice(0, -1);
            }

            if (caseSensitive) {
                const separator = asteriskSuffixFound ? `^${search}` : search;
                return text.split(separator).join(`<span class="text-highlight">${search}</span>`);
            } else {
                let regExpPattern = search.replace(/[#-.]|[[-^]|[?|{}]/g, "\\$&");
                regExpPattern = asteriskSuffixFound ? `^${regExpPattern}` : regExpPattern;

                return text.replace(
                    new RegExp(regExpPattern, "gi"),
                    `<span class="text-highlight">$&</span>`
                );
            }
        } else {
            return text;
        }
    }
}
