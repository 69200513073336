import {
    ChangeDetectionStrategy,
    Component,
    inject,
    OnInit,
    ViewEncapsulation
} from "@angular/core";
import {
    LG_LOCALIZATION_SETTINGS,
    LgI18nService,
    LgTranslateService,
    useTranslationNamespace
} from "@logex/framework/lg-localization";
import { IDropdownDefinition } from "@logex/framework/ui-core";

@Component({
    selector: "lg-language-switch",
    templateUrl: "./lg-language-switch.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: "lg-language-switch"
    },
    encapsulation: ViewEncapsulation.None,
    viewProviders: [useTranslationNamespace("FW._Language_switch")]
})
export class LgLanguageSwitchComponent implements OnInit {
    private _lgI18nService = inject(LgI18nService);
    private _localizationSettings = inject(LG_LOCALIZATION_SETTINGS);
    private _translateService = inject(LgTranslateService);
    _dropdownDef!: IDropdownDefinition<string>;
    _current!: string;

    ngOnInit(): void {
        const { availableLanguages, preferredLanguage } = this._lgI18nService.settings;

        this._dropdownDef = this._getDropdownDefinition(availableLanguages);
        this._current = preferredLanguage;
    }

    _onCurrentChange(current: string): void {
        if (this._current !== current) {
            this._localizationSettings.switchCurrentLanguage(current);
        }
    }

    private _getDropdownDefinition(availableLanguages: string[]): IDropdownDefinition<string> {
        return {
            lookup: availableLanguages.reduce((result: any, lang) => {
                result[lang] = this._translateService.translate(`.${lang}`);
                return result;
            }, {})
        };
    }
}
