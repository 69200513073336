import { Nullable } from "@logex/framework/types";
import {
    ILgFormatterOptions,
    IParseResult,
    LgFormattingSymbolMetadata,
    ILgFormatter
} from "./formatter-types";
import { parse } from "./number-helpers/parse";
import { format } from "./number-helpers/format";
import { formatForEditing } from "./number-helpers/formatForEditing";

const defaultOptions: ILgFormatterOptions = {
    decimals: 0,
    viewScale: 0,
    forceSign: false,
    forceFormat: true
};

export class MoneyFormatter implements ILgFormatter<number> {
    names = ["money"];

    constructor(
        public options: ILgFormatterOptions,
        private _symbol: LgFormattingSymbolMetadata,
        private _locale: string
    ) {
        this.options = { ...defaultOptions, ...this.options };
    }

    parse(val: string, options?: ILgFormatterOptions): IParseResult<number> {
        val = val.replace(this._symbol.removeRegex, "");

        const { decimals, viewScale } = { ...this.options, ...options };
        return parse(val, decimals, viewScale);
    }

    format(val: Nullable<number>, options?: ILgFormatterOptions): string {
        const result = format(val, this._locale, { ...this.options, ...options });

        return this._decorateWithSymbol(result, this._symbol, result === "−");
    }

    formatForEditing(val: Nullable<number>, options?: ILgFormatterOptions): string {
        if (val == null || "" + val === "") {
            return "";
        }

        return formatForEditing(val, this._locale, { ...this.options, ...options });
    }

    private _decorateWithSymbol(
        val: string,
        formattingSymbol: LgFormattingSymbolMetadata,
        isDash: boolean
    ): string {
        return isDash
            ? val
            : formattingSymbol.position === "before"
            ? formattingSymbol.symbol + formattingSymbol.separator + val
            : val + formattingSymbol.separator + formattingSymbol.symbol;
    }
}
