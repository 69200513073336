import { getRootNodeSimple } from "./getRootNodeSimple";

/**
 * Determine, if the specified element is attached to the document (e.g. visible in ng-content)
 *
 * @param     element  Element to check
 *
 * @return           Returns true if the element is attached to document
 */
export function isElementAttachedToDocument(element: Node): boolean {
    if (!element) return false;
    return getRootNodeSimple(element)?.nodeName === "#document";
}
