import { animate, AnimationEvent, state, style, transition, trigger } from "@angular/animations";
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostBinding,
    HostListener,
    Input,
    Output,
    ViewEncapsulation,
    AfterViewInit
} from "@angular/core";
import { easingDefs } from "@logex/framework/utilities";

import { LgSlideoutVariant } from "./lg-slideout.types";

@Component({
    selector: "lg-slideout-holder",
    templateUrl: "./lg-slideout-holder.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: "lg-slideout-holder",
        "[class.lg-slideout-holder--pinned]": "pinned",
        "[class.lg-slideout-holder--expanded]": "expanded",
        "[class.lg-slideout-holder--force-closing-shadow]": "_forceShadow",
        "[class.lg-slideout-holder--left]": "variant === 'left'",
        "[class.lg-slideout-holder--right]": "variant === 'right'",
        "[style.width.px]": "width"
    },
    animations: [
        trigger("isExpanded", [
            state("false", style({ transform: "translateX({{translateXPercentage}}%)" }), {
                params: { translateXPercentage: 100, animationDuration: 300 }
            }),
            state("true", style({ transform: "translateX(0)" })),
            transition("* => true", animate(`{{animationDuration}}ms ${easingDefs.easeOutAdam}`)),
            transition("* => false", animate(`{{animationDuration}}ms ${easingDefs.easeInAdam}`))
        ])
    ],
    encapsulation: ViewEncapsulation.None
})
export class LgSlideoutHolderComponent implements AfterViewInit {
    /**
     * Slideout variant
     *
     * @type {"left" | "right"}
     */
    @Input({ required: true }) variant!: LgSlideoutVariant;
    @Input() pinned = false;
    @Input() hidePin = false;
    @Input() width?: number;
    @Input() expanded = false;

    /**
     * Emits on pin toggle
     */
    @Output() readonly pinToggled = new EventEmitter<void>();

    /**
     * Emits on panel hide
     */
    @Output() readonly hidden = new EventEmitter<void>();

    _hasFinishedHiding = true;

    @HostBinding("@isExpanded")
    get _isExpanded(): object {
        return {
            value: this.expanded,
            params: this._getAnimationParams()
        };
    }

    @HostListener("@isExpanded.done", ["$event"])
    _animationDone(event: AnimationEvent): void {
        const toState = !!event.toState;
        if (toState === false && !this.expanded) {
            this._hasFinishedHiding = true;
            this.hidden.emit();
        } else if (toState === true) {
            this._hasFinishedHiding = false;
        }
    }

    @HostListener("@isExpanded.start")
    _animationStarted(): void {
        this._hasFinishedHiding = false;
    }

    get _forceShadow(): boolean {
        return this._initialized && !this.pinned && !this._hasFinishedHiding;
    }

    ngAfterViewInit(): void {
        // to prevent the `Expression has changed after checked` error
        // we allow forcing the shadow only after the first paint
        // Promise.resolve().then(() => /* ... */) was not enough
        setTimeout(() => (this._initialized = true));
    }

    private _initialized = false;

    private _getAnimationParams(): object {
        return {
            translateXPercentage: this.variant === "right" ? 100 : -100,
            animationDuration: this.pinned ? 0 : 300
        };
    }
}
