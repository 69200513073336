import { Provider } from "@angular/core";
import { LgTranslateNamespace, LgTranslateService } from "./lg-translate.service";
import { LgTranslatePipe } from "./lg-translate.pipe";

export function useTranslationNamespace(namespace?: string): Provider[] {
    return [
        {
            provide: LgTranslateNamespace,
            useValue: namespace
        },
        LgTranslateService,
        LgTranslatePipe
    ];
}
