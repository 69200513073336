import { CommonModule } from "@angular/common";
import { InjectionToken, NgModule } from "@angular/core";

import { LG_FORMATTER_FACTORIES } from "./formatters/factories/formatter-factory-types";
import { MoneyFormatterFactory } from "./formatters/factories/lg-money-formatter-factory.service";
import { MoneyScaledFormatterFactory } from "./formatters/factories/lg-money-scaled-formatter-factory.service";
import { NoFormatterFactory } from "./formatters/factories/lg-no-formatter-factory.service";
import { PercentageFormatterFactory } from "./formatters/factories/lg-percentage-formatter-factory.service";
import { NumberFormatterFactory } from "./formatters/factories/lg-number-formatter-factory.service";
import { LgFormatterFactoryService } from "./formatters/factories/lg-formatter-factory.service";
import { IntegerFormatterFactory } from "./formatters/factories/lg-integer-formatter-factory.service";
import { LG_APPLICATION_EVENT_TRACER } from "./tracing/application-event-tracer";
import { ApplicationEventTracerHub } from "./tracing/application-event-tracer-hub";
import { LgConsoleConfiguration } from "./debugging";

export const LG_SERVICES_FORROOT_GUARD = new InjectionToken<void>("LG_SERVICES_FORROOT_GUARD");

@NgModule({
    imports: [CommonModule],
    providers: [
        { provide: LG_FORMATTER_FACTORIES, useClass: MoneyFormatterFactory, multi: true },
        { provide: LG_FORMATTER_FACTORIES, useClass: MoneyScaledFormatterFactory, multi: true },
        { provide: LG_FORMATTER_FACTORIES, useClass: NoFormatterFactory, multi: true },
        { provide: LG_FORMATTER_FACTORIES, useClass: PercentageFormatterFactory, multi: true },
        { provide: LG_FORMATTER_FACTORIES, useClass: NumberFormatterFactory, multi: true },
        { provide: LG_FORMATTER_FACTORIES, useClass: IntegerFormatterFactory, multi: true },
        { provide: LG_APPLICATION_EVENT_TRACER, useExisting: ApplicationEventTracerHub },
        LgFormatterFactoryService,
        { provide: LgConsoleConfiguration, useClass: LgConsoleConfiguration }
    ]
})
export class CoreModule {
    // empty
}
