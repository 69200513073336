/**
 * Convert the specified value to boolean. Most values are considered truthy, apart from 0, "f", "no", "n", false, "false" and empty array
 *
 * @params value is the value to be converted
 * @returns true or false
 */
export function toBoolean(value: any, fallback?: boolean): boolean {
    if (value == null && fallback != null) {
        return fallback;
    }
    if (value && value.length !== 0) {
        const v = ("" + value).toLowerCase();
        value = !(v === "f" || v === "0" || v === "false" || v === "no" || v === "n" || v === "[]");
    } else {
        value = false;
    }
    return value;
}
