import { Directive, inject, TemplateRef } from "@angular/core";
import { LgPanelContext } from "../service";

@Directive({
    standalone: true,
    selector: "[lgPanelBody]"
})
export class LgPanelBodyDirective {
    _bodyTemplate = inject(TemplateRef<LgPanelContext>);
}
