import {
    Directive,
    Input,
    HostListener,
    OnDestroy,
    OnInit,
    ElementRef,
    Renderer2,
    HostBinding,
    AfterViewInit,
    inject
} from "@angular/core";
import { Subscription } from "rxjs";

import * as Pivots from "@logex/framework/lg-pivot";
import { toBoolean } from "@logex/framework/utilities";
import { HoverEvent } from "@logex/framework/ui-core";

import type { RowContext } from "../helpers/row-context";
import { IItemExpander, LgPivotTableBodyRegister } from "../types";

@Directive({
    selector: "lg-pivot-table-row",
    host: {
        class: "lg-pivot-table__row"
    }
})
export class LgPivotTableRowDirective<ItemType = any, ItemsType = any, ParentType = any>
    implements OnInit, OnDestroy, AfterViewInit
{
    private _body = inject(LgPivotTableBodyRegister, { optional: true });
    private _elementRef = inject(ElementRef);
    private _renderer = inject(Renderer2);

    static _lastInstance: null | LgPivotTableRowDirective<any, any, any>;
    static _lastInstanceCount = 0;

    /**
     * Specifies if row should be toggled on click.
     *
     * @default false
     */
    @Input() set toggleOnClick(value: boolean) {
        this._toggleOnClick = toBoolean(value);
    }

    get toggleOnClick(): boolean {
        return this._toggleOnClick;
    }

    get context(): RowContext<ItemType, ItemsType, ParentType> {
        return this._context;
    }

    set context(value: RowContext<ItemType, ItemsType, ParentType>) {
        this._context = value;
    }

    get level(): number {
        return this._context!.level;
    }

    get isExpanded(): boolean {
        return (this._context!.$implicit as any).$expanded;
    }

    get definition(): Pivots.INormalizedLogexPivotDefinition {
        return this._definition;
    }

    set definition(value: Pivots.INormalizedLogexPivotDefinition) {
        this._definition = value;
    }

    /**
     * Specifies if expanded row should be highlighted.
     *
     * @default true
     */
    @Input() set expandHighlight(value: boolean) {
        this._expandHighlight = toBoolean(value);
    }

    get expandHighlight(): boolean {
        return this._expandHighlight;
    }

    @HostListener("click")
    _onClick(): boolean {
        if (!this._toggleOnClick || !this._context?.deeper) return true;
        this._expander?.toggleExpand(this._context?.$implicit);

        return false;
    }

    @HostListener("hover", ["$event"])
    _onHover(event: HoverEvent): void {
        if (this._body && this._context) {
            this._body._notifyHover(
                this._context.$implicit,
                this._context.level,
                event.detail.over
            );
        }
    }

    @HostBinding("class.lg-pivot-table__row--expanded")
    get _hasExpandedHighlight(): boolean {
        // note: we need to take into account the possibility the children level is hidden in the current table, that's why we test also deeper
        return (
            this._context &&
            this._context.$implicit &&
            (this._context.$implicit as any).$expanded &&
            this._context.deeper &&
            this._expandHighlight
        );
    }

    private _context!: RowContext<ItemType, ItemsType, ParentType>;
    private _definition!: Pivots.INormalizedLogexPivotDefinition;
    private _hoverSubscription?: Subscription;
    private _expandHighlight = true;
    _toggleOnClick = false;
    _expander?: IItemExpander;
    _parentHover = false;

    constructor() {
        LgPivotTableRowDirective._lastInstance = this;
        LgPivotTableRowDirective._lastInstanceCount += 1;
    }

    ngOnInit(): void {
        if (this._body) {
            this._hoverSubscription = this._body._hoverObservable().subscribe(target => {
                const parentHover =
                    target !== null && this._context && this._context.parent === target;
                if (parentHover !== this._parentHover) {
                    this._parentHover = parentHover ?? false;
                    if (parentHover) {
                        this._renderer.addClass(
                            this._elementRef.nativeElement,
                            "lg-pivot-table__row--parent-hover"
                        );
                    } else {
                        this._renderer.removeClass(
                            this._elementRef.nativeElement,
                            "lg-pivot-table__row--parent-hover"
                        );
                    }
                }
            });
        }
    }

    ngOnDestroy(): void {
        this._hoverSubscription?.unsubscribe();
    }

    ngAfterViewInit(): void {
        if (this._elementRef && this._context) {
            this._renderer.setAttribute(
                this._elementRef.nativeElement,
                "data-global-index",
                "" + this._context.globalIndex
            );
        }
    }
}
