import ldIsFunction from "lodash-es/isFunction";

import {
    Directive,
    OnDestroy,
    OnChanges,
    ElementRef,
    HostListener,
    Input,
    SimpleChanges,
    inject
} from "@angular/core";

import { LgTranslateService } from "@logex/framework/lg-localization";
import { TooltipApi, ITooltipOptions, TooltipPosition } from "./lg-tooltip.types";
import { LgTooltipService } from "./lg-tooltip.service";

@Directive({
    selector: "[lgSimpleTooltip]",
    host: {
        "[class.lg-tooltip-visible]": "_visible"
    }
})
export class LgSimpleTooltipDirective implements OnDestroy, OnChanges {
    private _elementRef = inject(ElementRef);
    private _tooltipService = inject(LgTooltipService);
    private _translateService = inject(LgTranslateService);

    /**
     * Apply css class to the tooltip root element.
     */
    @Input() tooltipClass?: string;

    @Input() tooltipPosition?: TooltipPosition;

    @Input() tooltipOptions?: ITooltipOptions;

    @Input("tooltipParams") parameters?: any;

    @Input() tooltipInterpolation?: string;

    /**
     * Tooltip content: string value or callback to get string value (required).
     * Value text is localized.
     */
    @Input({ alias: "lgSimpleTooltip", required: false }) content!: string | (() => string);

    public _visible = false;
    private _api: TooltipApi;

    public constructor() {
        this._api = this._tooltipService.create({
            content: () => this._getContent(),
            target: this._elementRef,
            tooltipClass: "lg-tooltip lg-tooltip--simple"
        });

        this._api.afterVisibilityChanged().subscribe(visible => (this._visible = visible));
    }

    @HostListener("mouseenter")
    public onMouseEnter(): void {
        this._api.scheduleShow();
    }

    @HostListener("mouseleave")
    public onMouseLeave(): void {
        this._api.scheduleHide();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.tooltipClass) {
            this._api.options({
                tooltipClass: changes.tooltipClass.currentValue || "lg-tooltip lg-tooltip--simple"
            });
        }
        if (changes.tooltipPosition) {
            this._api.options({ position: changes.tooltipPosition.currentValue || "bottom-left" });
        }
        if (changes.tooltipOptions && changes.tooltipOptions.currentValue) {
            this._api.options(changes.tooltipOptions.currentValue);
        }
    }

    public ngOnDestroy(): void {
        this._api.destroy();
    }

    private _getContent(): string {
        let content: string;
        if (ldIsFunction(this.content)) {
            content = this.content();
        } else if (this.content[0] === "=") {
            content = this.content.substring(1);
        } else {
            // todo: interpolation
            content = this._translateService.translate(this.content, this.parameters);
        }
        return content?.replace(/\n/g, "<br />");
    }
}
