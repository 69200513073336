<form
    #downloadForm
    action="{{ _url }}"
    method="POST"
    onsubmit="event.preventDefault()"
    enctype="application/x-www-form-urlencoded"
    style="top: 0; left: 0; width: 1px; height: 1px; position: absolute; visibility: hidden"
>
    <div *ngFor="let item of _paramsKeys">
        <ng-container *ngIf="!item.isArray">
            <input
                type="hidden"
                value="{{ _params[item.key] }}"
                [id]="item.key"
                [name]="item.key"
            />
        </ng-container>
        <ng-container *ngIf="item.isArray">
            <input
                *ngFor="let arrEntry of _params[item.key]"
                type="hidden"
                value="{{ arrEntry }}"
                [id]="arrEntry"
                [name]="item.key"
            />
        </ng-container>
    </div>
</form>
