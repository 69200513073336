import { trigger, state, animate, query, transition, group, style } from "@angular/animations";

export interface LgDropdownAnimation {
    value: "hidden" | "initial" | "onTop" | "onBottom";
    params: LgDropdownAnimationParams;
}

export interface LgDropdownAnimationParams {
    animationDuration: number;
}

export function LgDropdownPopupComponentGetDefaultParameters(): any {
    return { params: { animationDuration: 0 } };
}

export const LgDropdownAnimations = [
    trigger("state", [
        state("initial, hidden", style({ opacity: 0 })),
        state("onTop, onBottom", style({ opacity: 1 })),

        transition(
            "* => onBottom",
            group([
                style({ opacity: 0, transform: "translateY(-4px)" }),
                animate(
                    "{{animationDuration}}ms",
                    style({ opacity: 1, transform: "translateY(0)" })
                ),
                query(
                    ".lg-dropdown--headerWrapper",
                    [
                        style({ transform: "translateY(4px)" }),
                        animate("{{animationDuration}}ms", style({ transform: "translateY(0)" }))
                    ],
                    { optional: true }
                )
            ]),
            LgDropdownPopupComponentGetDefaultParameters()
        ),
        transition(
            "* => onTop",
            group([
                style({ opacity: 0, transform: "translateY(4px)" }),
                animate(
                    "{{animationDuration}}ms",
                    style({ opacity: 1, transform: "translateY(0)" })
                ),
                query(
                    ".lg-dropdown--headerWrapper",
                    [
                        style({ transform: "translateY(-4px)" }),
                        animate("{{animationDuration}}ms", style({ transform: "translateY(0)" }))
                    ],
                    { optional: true }
                )
            ]),
            LgDropdownPopupComponentGetDefaultParameters()
        ),
        transition("* => hidden", [animate(0, style({ opacity: 0 }))])
    ])
];
