import { Injectable, inject } from "@angular/core";
import * as d3 from "d3";
import {
    LG_CATEGORICAL_COLOR_PALETTE,
    LG_PREDEFINED_COLORS,
    LG_SEQUENTIAL_PALETTE_BY_COLOR,
    OTHER_COLOR
} from "./lg-colors";
import {
    LgColorPaletteIdentifiers,
    LgColorsConfiguration,
    LgColorTypes,
    LgPredefinedChartColors,
    LgPredefinedColorKey,
    LG_USE_NEW_COLOR_PALETTE
} from "./lg-colors.types";

@Injectable({ providedIn: "root" })
export class LgColorPaletteV2 {
    useNewColorPalette = inject(LG_USE_NEW_COLOR_PALETTE);
    private _categoricalColorPalette: d3.ScaleOrdinal<string, string>;

    constructor() {
        this._categoricalColorPalette = d3.scaleOrdinal(LG_CATEGORICAL_COLOR_PALETTE);
    }

    /**
     * Method to retrieve default scale of colors.
     *
     * @returns scale of categorical palette.
     */
    getCategoricalColorScale(): d3.ScaleOrdinal<string, string> {
        return this._categoricalColorPalette;
    }

    /**
     * Method to retrieve default array of colors.
     *
     * @returns categorical palette in the form of array of hexadecimal representations of the color.
     */
    getCategoricalPalette(): string[] {
        return LG_CATEGORICAL_COLOR_PALETTE;
    }

    /**
     * Method to retrieve dictionary of predefined colors.
     *
     * @returns dictionary of available predefined colors
     */
    getPredefinedPalette(): LgPredefinedChartColors {
        return LG_PREDEFINED_COLORS;
    }

    /**
     * Method to retrieve specific predefined color.
     *
     * @param key specifies the desired color key.
     * @returns hexadecimal representation of the color.
     */
    getPredefinedColor(key: LgPredefinedColorKey): string {
        return LG_PREDEFINED_COLORS[key];
    }

    /**
     * Method to retrieve sequential color palette
     *
     * @param colorIdentifier specifies the color to use
     * @returns array of hexadecimal representations of the color.
     */
    getSequentialPalette(colorIdentifier: LgColorPaletteIdentifiers): string[] {
        return LG_SEQUENTIAL_PALETTE_BY_COLOR[colorIdentifier] ?? [];
    }

    /**
     * Method to retrieve color for "other" kind of data.
     *
     * @returns hexadecimal representation of the color.
     */
    getOtherColor(): string {
        return OTHER_COLOR;
    }

    getColorsForType(colorConfiguration: LgColorsConfiguration): string[] {
        if (colorConfiguration.colorType === LgColorTypes.Categorical) {
            return this.getCategoricalPalette();
        }
        if (colorConfiguration.colorType === LgColorTypes.Predefined) {
            const result = colorConfiguration.colorKeys.map(key => this.getPredefinedColor(key));
            return result;
        }
        if (colorConfiguration.colorType === LgColorTypes.Own) return colorConfiguration.colors;
        if (colorConfiguration.colorType === LgColorTypes.SequentialByColor) {
            const result = this.getSequentialPalette(colorConfiguration.colorIdentifier);
            return result;
        }
        throw new Error("Unknown configuration");
    }
}
