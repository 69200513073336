import {
    Component,
    Input,
    Output,
    EventEmitter,
    ViewEncapsulation,
    ChangeDetectionStrategy,
    HostListener
} from "@angular/core";
import { toBoolean } from "@logex/framework/utilities";

@Component({
    selector: "lg-slider-switch",
    template: `
        <div class="lg-slider-switch__slide"></div>
        <div class="lg-slider-switch__handle"></div>
    `,
    host: {
        class: "lg-slider-switch",
        "[class.lg-slider-switch--on]": "state",
        "[class.lg-slider-switch--off]": "!state",
        "[class.lg-slider-switch--disabled]": "disabled"
    },
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LgSliderSwitchComponent {
    /**
     * Slider state.
     *
     * @default false
     * `true` - on, `false` - off
     */
    @Input({ required: true }) public set state(value: boolean) {
        this._state = toBoolean(value);
    }

    public get state(): boolean {
        return this._state;
    }

    @Input() public set disabled(value: boolean) {
        this._disabled = toBoolean(value);
    }

    public get disabled(): boolean {
        return this._disabled;
    }

    @Output() public readonly stateChange = new EventEmitter<boolean>();

    @HostListener("click", ["$event"])
    public _clicked($event: MouseEvent): void {
        $event.stopPropagation();
        $event.preventDefault();
        if (this._disabled) return;

        this._state = !this._state;
        this.stateChange.next(this._state);
    }

    private _state = false;
    private _disabled = false;
}
