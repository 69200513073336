import { inject, Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";

import { LgTranslateService } from "@logex/framework/lg-localization";

@Injectable()
export class LgAcceptLanguageInterceptor implements HttpInterceptor {
    private _translate = inject(LgTranslateService);

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let headers = req.headers;

        const currentLanguage = this._translate.getLanguage();
        if (currentLanguage != null) {
            headers = headers.set("Accept-Language", currentLanguage);
        }

        const modifiedRequest = req.clone({
            headers
        });

        return next.handle(modifiedRequest);
    }
}
