import {
    ChangeDetectionStrategy,
    Component,
    Input,
    Output,
    EventEmitter,
    OnDestroy,
    ElementRef,
    ViewChild,
    inject
} from "@angular/core";
import { ConnectedPosition } from "@angular/cdk/overlay";

import {
    toBoolean,
    cloneElementWithStyles,
    exportHtmlToPng,
    exportSvg
} from "@logex/framework/utilities";
import {
    LgQuickSettingsMenuHost,
    QuickSettingsMenuType,
    LgQuickSettingsMenuPopupComponent,
    IQuickSettingsMenuChoice
} from "@logex/framework/ui-core";
import { LgChartExportContainerDirective } from "./lg-chart-export-container.directive";

export enum ExportOption {
    XLSX = "XLSX",
    SVG = "SVG",
    PNG = "PNG"
}

export interface ExportHelp {
    name: ExportOption;
    help: string;
}

export interface IExportableChart {
    getSvgElement(): SVGElement;

    getHtmlElement(): HTMLElement;
}

@Component({
    selector: "lg-chart-export",
    template: ` <lg-icon [icon]="icon" (click)="_showMenu($event)"></lg-icon>
        <div id="download">
            <img #canvas />
            <a #downloadLink></a>
        </div>`,
    host: {
        class: "lg-icon-menu lg-icon-menu--condensed",
        "[class.lg-icon-menu__default]": "!class",
        "[class.lg-icon-menu--active]": "_popupActive",
        "[class.lg-icon-menu--disabled]": "_disabled || !_hasTarget"
    },
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LgChartExportComponent extends LgQuickSettingsMenuHost implements OnDestroy {
    private _chartContainer = inject(LgChartExportContainerDirective, { optional: true });
    private _elementRef = inject(ElementRef);

    /**
     * Specifies icon of export action.
     *
     * @default "icon-sidebar-export"
     */
    @Input() icon? = "icon-sidebar-export";

    /**
     * Specifies allowed formats of exported files.
     *
     * @default [ExportOption.XLSX, ExportOption.SVG, ExportOption.PNG]
     */
    @Input() allowedFormats: ExportOption[] = [
        ExportOption.XLSX,
        ExportOption.SVG,
        ExportOption.PNG
    ];

    /**
     * Definition of menu items.
     */
    @Input() definition: IQuickSettingsMenuChoice[] = [
        {
            type: QuickSettingsMenuType.Choice,
            name: ExportOption.XLSX,
            onClick: () => this._onClickXLSX()
            // help: "<a href = 'https://en.wikipedia.org/wiki/Microsoft_Excel'> What is XLSX? </a>"
        },
        {
            type: QuickSettingsMenuType.Choice,
            name: ExportOption.SVG,
            onClick: () => this._onClickSVG()
            // help: "<a href = 'https://en.wikipedia.org/wiki/Scalable_Vector_Graphics'> What is SVG? </a>"
        },
        {
            type: QuickSettingsMenuType.Choice,
            name: ExportOption.PNG,
            onClick: () => this._onClickPNG()
            // help: "<a href = 'https://en.wikipedia.org/wiki/Portable_Network_Graphics'> What is PNG? </a>"
        }
    ];

    /**
     * Specifies export help data,
     *
     * @default `[]`
     */
    @Input() help: ExportHelp[] = [];

    /**
     * Specifies whether default menu css styles are applied to component or not.
     */
    @Input() class?: string;

    /**
     * Specifies title of exported file name.
     * File name consists of time stamp and specified title.
     */
    @Input() title?: string;

    /**
     * Specifies whether export action is disabled or not.
     *
     * @default false
     */
    @Input() set disabled(value: boolean) {
        this._disabled = toBoolean(value, false);
    }

    get disabled(): boolean {
        return this._disabled;
    }

    /**
     * Specifies whether export menu option items should be compacted or not.
     *
     * @type {boolean | "auto"}
     *
     * @default "auto"
     */
    @Input() set compact(value: boolean | "auto") {
        if (value === "auto") {
            this._compact = null;
        } else {
            this._compact = toBoolean(value);
        }
    }

    get compact(): boolean | "auto" {
        return this._compact === null ? "auto" : this._compact;
    }

    /**
     * Specifies target chert to export.
     */
    @Input() target?: IExportableChart;

    /**
     * Specifies description to be added to the exported file.
     */
    @Input() description = "";

    /**
     * Emits on export action click event.
     */
    @Output() readonly xlsxExportClicked = new EventEmitter<void>();

    @ViewChild("chart") chart: any;
    @ViewChild("canvas") canvas!: ElementRef;
    @ViewChild("downloadLink") downloadLink!: ElementRef;

    get _hasTarget(): boolean {
        return (
            !!(this.target || this._chartContainer!.chart) ||
            !(
                this.allowedFormats.includes(ExportOption.SVG) ||
                this.allowedFormats.includes(ExportOption.PNG)
            )
        );
    }

    _disabled = false;

    private _compact: boolean | null = null;

    constructor() {
        super(LgQuickSettingsMenuPopupComponent);
    }

    ngOnDestroy(): void {
        super._onDestroy();
    }

    _showMenu($event: MouseEvent): boolean {
        if (this._popupActive || this._disabled || !this._hasTarget) return true;

        $event.stopPropagation();

        const positions: ConnectedPosition[] = [
            { originX: "end", originY: "bottom", overlayX: "end", overlayY: "top" },
            { originX: "end", originY: "top", overlayX: "end", overlayY: "bottom" },
            { originX: "start", originY: "bottom", overlayX: "start", overlayY: "top" },
            { originX: "start", originY: "top", overlayX: "start", overlayY: "bottom" }
        ];

        const strategy = this._overlay
            .position()
            .flexibleConnectedTo(this._elementRef)
            .withFlexibleDimensions(false)
            .withPush(false)
            .withViewportMargin(0)
            .withPositions(positions);

        super
            ._showSettingsPopup(this._elementRef, strategy, true, {
                definition: this._filterDefinition(),
                compact: this._compact
            })
            .subscribe({
                next: () => {
                    this._close();
                },
                error: () => {
                    this._close();
                },
                complete: () => {
                    this._close();
                }
            });

        return false;
    }

    private _filterDefinition(): IQuickSettingsMenuChoice[] {
        const result = this.definition.filter(item =>
            this.allowedFormats.includes(item.name as ExportOption)
        );
        result.forEach(element => {
            const help = this.help.find(el => el.name === element.name);
            if (help) {
                element.help = help.help;
            }
        });
        return result;
    }

    private _onClickXLSX(): void {
        this.xlsxExportClicked.emit();
    }

    private _onClickSVG(): void {
        const svg = cloneElementWithStyles(
            (this.target ?? this._chartContainer!.chart!).getSvgElement()
        ) as SVGElement;
        exportSvg(svg, this._getExportFilename(this.title), this.description);
    }

    private _onClickPNG(): void {
        exportHtmlToPng(
            (this.target ?? this._chartContainer!.chart!).getHtmlElement(),
            this._getExportFilename(this.title),
            this.description
        );
    }

    private _close(): void {
        this._hideSettingsPopup();
    }

    _getExportFilename(exportName?: string): string {
        const date = new Date().toISOString().slice(0, 10).replace(/-/g, "");
        return `${date} ${exportName}`;
    }
}
