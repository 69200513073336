import { inject, Injectable, isDevMode } from "@angular/core";
import {
    HttpEvent,
    HttpHandler,
    HttpHeaders,
    HttpInterceptor,
    HttpRequest
} from "@angular/common/http";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";

import { LgTranslateService } from "@logex/framework/lg-localization";
import { Nullable } from "@logex/framework/types";
import {
    IPromptDialogOptions,
    LgPromptDialog,
    LgPromptDialogButton
} from "@logex/framework/ui-core";

import { LG_USER_INFO } from "../user/user.types";
import { IVersion, VersionService } from "../version/index";

const VERSION_HEADER = "x-version-id";
const HOSPITAL_CODE_HEADER = "X-HOSPITALCODE";

@Injectable()
export class StandardInterceptor implements HttpInterceptor {
    private _promptDialog = inject(LgPromptDialog);
    private _translate = inject(LgTranslateService);
    private _userInfo = inject(LG_USER_INFO);
    private _versionService = inject(VersionService<IVersion>);

    private static _sessionDialogVisible = false;
    private _version: Nullable<number>;

    constructor() {
        this._versionService.version.subscribe(x => (this._version = x.id));
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let modifiedRequest: HttpRequest<any> = req;

        if (this._userInfo.ziekenhuiscode || this._version) {
            modifiedRequest = req.clone({
                headers: this._getHttpHeaders(req, this._userInfo.ziekenhuiscode, this._version)
            });
        }

        return next.handle(modifiedRequest).pipe(
            tap(null, (e: any) => {
                // todo: how to simulate the config object to skip error dialogs?
                /*
                    if ( response && response.config && response.config.disableLogexInterceptor ) {
                        return $q.reject( response );
                    }
                    */

                if (e.status && e.status === 401) {
                    if (!StandardInterceptor._sessionDialogVisible) {
                        StandardInterceptor._sessionDialogVisible = true;

                        this._promptDialog
                            .alert(
                                this._translate.translate("FW.FAILED_REQUEST_TO_SERVER"),
                                this._translate.translate("FW.SESSION_EXPIRED"),
                                { allowClose: false }
                            )
                            .then(() => {
                                window.location.reload();
                            });
                    }
                    e.force_quiet = true;
                } else if (e.status === 403 && e.error === "VERSION CHANGED") {
                    if (!StandardInterceptor._sessionDialogVisible) {
                        this._onVersionChanged(e);
                    }
                    e.force_quiet = true;
                } else if (e.status >= 400 && modifiedRequest.method === "GET") {
                    let error = "Error status " + e.status;

                    const debug: boolean = isDevMode();

                    if (debug) {
                        if (e.error) {
                            if (e.error.Message) {
                                error = e.error.Message;
                                if (e.error.ExceptionMessage)
                                    error += `<br /><b>${e.error.ExceptionMessage}</b>`;
                                if (e.error.StackTrace) {
                                    error += `<br /><div class="confirm-message__section confirm-message__scrollable" ><b>Stack:</b><br />${e.error.StackTrace.replace(
                                        /\n/g,
                                        "<br/><br />"
                                    )}</div>`;
                                    console.log(e.error.ExceptionMessage);
                                    console.log(e.error.StackTrace);
                                }
                            } else {
                                const find = /<b>\s*Description:\s*<\/b>([^<]*)<br>/.exec(
                                    e.error.data
                                );
                                if (find && find[1]) {
                                    error = find[1];
                                }
                            }
                        }
                    } else {
                        error = this._translate.translate("FW._Common.Error_loading_data");
                    }

                    const ignoreButton: LgPromptDialogButton = {
                        id: "ignore",
                        name: "Ignore"
                    };
                    const dlgOptions: IPromptDialogOptions = {
                        buttons: [
                            { id: "retry", nameLc: "FW.RETRY", isConfirmAction: true },
                            LgPromptDialog.CANCEL_BUTTON,
                            ...(debug ? [ignoreButton] : [])
                        ],
                        dialogType: "alert",
                        columns: 5
                    };

                    this._promptDialog
                        .alert(
                            this._translate.translate("FW.FAILED_REQUEST_TO_SERVER"),
                            error + "<br />",
                            dlgOptions
                        )
                        .then(result => {
                            if (result === "retry") {
                                window.location.reload();
                            } else if (result === "cancel") {
                                window.location.assign("#!");
                            }
                        });

                    e.force_quiet = true;
                }
            })
        );
    }

    private _getHttpHeaders(
        req: HttpRequest<any>,
        hospitalCode: Nullable<number>,
        version: Nullable<number>
    ): HttpHeaders {
        let headers = new HttpHeaders();
        if (hospitalCode) headers = req.headers.set(HOSPITAL_CODE_HEADER, "" + hospitalCode);
        if (version) headers = headers.set(VERSION_HEADER, "" + version);
        return headers;
    }

    private _onVersionChanged(response: any): void {
        this._promptDialog
            .alert(
                this._translate.translate("FW.FAILED_REQUEST_TO_SERVER"),
                this._translate.translate("FW.SESSION_VERSION_CHANGED"),
                { allowClose: false }
            )
            .then(result => {
                if (result === "ok") {
                    window.location.reload();
                } else if (result === "close") {
                    window.location.assign("#!");
                }
            });
        response.force_quiet = true;
    }
}
