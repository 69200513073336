import {
    Component,
    ViewEncapsulation,
    ChangeDetectionStrategy,
    ElementRef,
    Renderer2,
    inject
} from "@angular/core";

@Component({
    standalone: true,
    selector: "lg-loader-tab",
    // eslint-disable-next-line @angular-eslint/component-max-inline-declarations
    template: `
        <div class="lg-loader-tabAnim">
            <div class="lg-loader-tabAnim--background">
                <div class="lg-loader-tabAnim__p lg-loader-tabAnim__p--0"></div>
                <div class="lg-loader-tabAnim__p lg-loader-tabAnim__p--1"></div>
                <div class="lg-loader-tabAnim__p lg-loader-tabAnim__p--2"></div>
                <div class="lg-loader-tabAnim__p lg-loader-tabAnim__p--3"></div>
                <div class="lg-loader-tabAnim__p lg-loader-tabAnim__p--4"></div>
                <div class="lg-loader-tabAnim__p lg-loader-tabAnim__p--5"></div>
                <div class="lg-loader-tabAnim__p lg-loader-tabAnim__p--6"></div>
                <div class="lg-loader-tabAnim__p lg-loader-tabAnim__p--7"></div>
            </div>
            <div class="lg-loader-tabAnim__p lg-loader-tabAnim__p--0"></div>
            <div class="lg-loader-tabAnim__p lg-loader-tabAnim__p--1"></div>
            <div class="lg-loader-tabAnim__p lg-loader-tabAnim__p--2"></div>
            <div class="lg-loader-tabAnim__p lg-loader-tabAnim__p--3"></div>
            <div class="lg-loader-tabAnim__p lg-loader-tabAnim__p--4"></div>
            <div class="lg-loader-tabAnim__p lg-loader-tabAnim__p--5"></div>
            <div class="lg-loader-tabAnim__p lg-loader-tabAnim__p--6"></div>
            <div class="lg-loader-tabAnim__p lg-loader-tabAnim__p--7"></div>
        </div>
    `,
    host: {
        class: "lg-loader-tab-wrapper"
    },
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LgLoaderTabComponent {
    private _elementRef = inject(ElementRef);
    private _renderer = inject(Renderer2);
    // eslint-disable-next-line accessor-pairs
    set _topOffset(value: number) {
        this._renderer.setStyle(this._elementRef.nativeElement, "top", value + "px");
    }
}
