import { Injectable } from "@angular/core";
import { IStringLookup } from "@logex/framework/types";
import { BehaviorSubject, Observable } from "rxjs";

import {
    LgSourcesBeingCompared,
    MiComparisonSource,
    MiComparisonSourceDef
} from "./lg-comparison-source.types";

@Injectable()
export class MiComparisonSourceService {
    get sourcesBeingCompared$(): Observable<LgSourcesBeingCompared> {
        return this._sourcesBeingCompared$.asObservable();
    }

    get triggerSourceChange$(): Observable<LgSourcesBeingCompared> {
        return this._triggerSourceChange$.asObservable();
    }

    get firstComparedId(): string {
        return this._firstComparedId;
    }

    get secondComparedId(): string {
        return this._secondComparedId;
    }

    private _firstComparedId = "";
    private _secondComparedId = "";
    private _debounceHandler: any;
    private _sourceScopes: IStringLookup<IStringLookup<MiComparisonSourceDef>>;
    private _sourceScopes$: IStringLookup<BehaviorSubject<MiComparisonSource[]>>;
    private _sourcesBeingCompared$: BehaviorSubject<LgSourcesBeingCompared>;
    private _triggerSourceChange$: BehaviorSubject<LgSourcesBeingCompared> = new BehaviorSubject(
        {}
    );

    constructor() {
        this._sourceScopes = {};
        this._sourceScopes$ = {};
        this._sourcesBeingCompared$ = new BehaviorSubject({});
    }

    registerSource(source: MiComparisonSourceDef): void {
        this._sourceScopes[source.scope] = this._sourceScopes[source.scope] || {};
        this._sourceScopes$[source.scope] =
            this._sourceScopes$[source.scope] || new BehaviorSubject(null);
        this._sourceScopes[source.scope][source.id] = source;
        this._onSourcesScopeChange(source.scope);
        if (this._debounceHandler) {
            clearTimeout(this._debounceHandler);
        }

        this._debounceHandler = window.setTimeout(
            this._recheckReferences.bind(this, source.scope),
            100
        );
    }

    unregisterSource(id: string, scope: string): void {
        delete this._sourceScopes[scope][id];
        this._onSourcesScopeChange(scope);
    }

    updateSourceName(sourceId: string, name: string, scope: string): void {
        if (this._sourceScopes[scope][sourceId] == null) {
            console.error(`attempting to update non-existing source ${sourceId}`);
            return;
        }
        this._sourceScopes[scope][sourceId].name = name;

        this._onSourcesScopeChange(scope);
    }

    getSourceScope$(scope: string): Observable<MiComparisonSource[]> {
        return this._sourceScopes$[scope].asObservable();
    }

    updateComparing(firstId: string, secondId: string): void {
        this._firstComparedId = firstId;
        this._secondComparedId = secondId;
        this._sourcesBeingCompared$.next({ firstId, secondId });
        this._triggerSourceChange$.next({ firstId, secondId });
    }

    private _onSourcesScopeChange(scope: string): void {
        this._sourceScopes$[scope].next(
            Object.keys(this._sourceScopes[scope])
                .map(k => this._sourceScopes[scope][k])
                .sort((s1, s2) => (s1.order < s2.order ? -1 : 1))
                .map(source => ({
                    id: source.id,
                    name: source.name
                }))
        );
    }

    private _recheckReferences(scope: string): void {
        // One of comparing columns is hidden? Switching to the next visible
        const sourceIds = Object.keys(this._sourceScopes[scope]);

        let newFirstComparedId;
        if (!this._sourceScopes[scope][this._firstComparedId]) {
            newFirstComparedId = sourceIds[0];
        }

        let newSecondComparedId;
        if (!this._sourceScopes[scope][this._secondComparedId]) {
            newSecondComparedId = sourceIds[0];
        }

        if (newFirstComparedId || newSecondComparedId) {
            this.updateComparing(
                newFirstComparedId || this._firstComparedId,
                newSecondComparedId || this._secondComparedId
            );
        }
    }
}
