import {
    Directive,
    ElementRef,
    Input,
    OnChanges,
    OnDestroy,
    SimpleChanges,
    inject
} from "@angular/core";

/*
  Possible extensions:
  - allow other than text data
  - allow providing animation (through class, or just (activate) output?)
*/

@Directive({
    standalone: true,
    selector: "[lgPasteHandler]"
})
/**
 * Implement possibility to handle paste event on any element (focusable, or containing focusable children). When the
 * event is triggered, the given callback will obtain the text (if any) and indicate, whether the event should be
 * consumed or not.
 */
export class LgPasteHandlerDirective implements OnChanges, OnDestroy {
    private _elementRef = inject(ElementRef<Node>);

    /**
     * Callback for handling the value to be pasted from the clipboard.
     */
    @Input("lgPasteHandler") pasteValue: null | undefined | ((text: string) => boolean);

    private _attached = false;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.pasteValue) {
            this._bindHandlers();
        }
    }

    ngOnDestroy(): void {
        this._detachHandlers();
    }

    private readonly _onPasteBound = (event: ClipboardEvent): void => this._onPaste(event);

    private _bindHandlers(): void {
        const active = this.pasteValue != null;
        if (active) {
            this._attachHandlers();
        } else {
            this._detachHandlers();
        }
    }

    private _attachHandlers(): void {
        if (this._attached) return;
        document.addEventListener("paste", this._onPasteBound);
        this._attached = true;
    }

    private _detachHandlers(): void {
        if (!this._attached) return;
        document.removeEventListener("paste", this._onPasteBound);
        this._attached = false;
    }

    // Note: global binding on document level, because binding on non-editable element doesn't seem to
    // work reliably in chrome (only element selected by mouse click reacts, not elements to which we tabbed)
    private _onPaste(event: ClipboardEvent): void {
        if (this._isFocusInside()) {
            const clipboardText = event.clipboardData?.getData("text/plain") ?? "";
            if (clipboardText) {
                if (this.pasteValue?.(clipboardText)) {
                    event.preventDefault();
                }
            }
        }
    }

    private _isFocusInside(): boolean {
        let current = document.activeElement;
        while (current) {
            if (current === this._elementRef.nativeElement) return true;
            current = current.parentElement;
        }
        return false;
    }
}
