import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

const G_URL = "https://translate.googleapis.com/";

let instance: ILgGoogleTranslateDetectionService;

export interface ILgGoogleTranslateDetectionService {
    ran$: Observable<boolean>;
}

@Injectable({ providedIn: "root" })
export class LgGoogleTranslateDetectionFactoryService {
    create(): ILgGoogleTranslateDetectionService {
        return instance || (instance = new LgGoogleTranslateDetectionService());
    }
}

// singleton
class LgGoogleTranslateDetectionService {
    public ran$: Observable<boolean>;

    private _ran$: BehaviorSubject<boolean>;

    constructor() {
        this._ran$ = new BehaviorSubject(false);
        this.ran$ = this._ran$.asObservable();
        this._init();
    }

    private _init(): void {
        this._observeHeadElement();
    }

    private _observeHeadElement(): void {
        // source: https://glitch.com/edit/#!/detect-google-translate?path=script.js:17:53
        const observer = new MutationObserver(([{ addedNodes }]) => {
            addedNodes.forEach(addedNode => {
                if (
                    !("href" in addedNode) ||
                    (addedNode as any).href == null ||
                    (addedNode as any).href.startsWith(G_URL) === false
                ) {
                    return;
                }
                observer.disconnect();
                this._onGoogleTranslate();
            });
        });
        observer.observe(document.head, { childList: true });
    }

    private _onGoogleTranslate(): void {
        this._ran$.next(true);
    }
}
