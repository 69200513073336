import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    inject,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    ViewChild
} from "@angular/core";
import { LgTranslateService, useTranslationNamespace } from "@logex/framework/lg-localization";
import { LgSimpleChanges } from "@logex/framework/types";
import { toFixedFix } from "@logex/framework/utilities";
import * as d3 from "d3";
import { BaseChartComponent } from "../shared/base-chart.component";
import { ChartClickEvent, LegendItem } from "../shared/chart.types";
import { getRecommendedPosition } from "../shared/getRecommendedPosition";
import {
    IIconChartInputDataItem,
    IIconChartItem,
    IIconChartTooltipContext
} from "./lg-icon-chart.types";
import { LgFrameworkIcons } from "@logex/framework/ui-core";
import {
    LG_DEFAULT_COLOR_CONFIGURATION,
    LgColorsConfiguration
} from "../shared/lg-color-palette-v2/lg-colors.types";
import { LgColorPaletteV2 } from "../shared/lg-color-palette-v2/lg-color-palette-v2";

@Component({
    standalone: false,
    selector: "lg-icon-chart",
    templateUrl: "./lg-icon-chart.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    viewProviders: [useTranslationNamespace("FW._Directives._Charts._LgIconChart")]
})
export class LgIconChartComponent
    extends BaseChartComponent<IIconChartItem, IIconChartTooltipContext>
    implements OnInit, OnChanges, OnDestroy
{
    private _colorPalette = inject(LgColorPaletteV2);
    private _ngZone = inject(NgZone);
    private _renderer = inject(Renderer2);
    private _translateService = inject(LgTranslateService);

    @ViewChild("iconChart", { static: true }) private _chartDivRef!: ElementRef;

    /**
     * @required
     * Specifies the input data for the chart.
     */
    @Input({ required: true }) override data!: IIconChartInputDataItem[];

    /**
     * @deprecated
     * Title is not used anymore.
     */
    @Input() title = "";

    /**
     * Chart icon.
     *
     * @default "icon-patient"
     */
    @Input() icon: string = LgFrameworkIcons.Patient;

    /**
     * Specifies limit of displayed data items.
     */
    @Input() itemsLimit?: number;

    /**
     * Specifies limit of displayed tooltip items.
     *
     * @default 10
     */
    @Input() tooltipItemsLimit = 10;
    /**
     * @deprecated use colorConfiguration instead
     */
    @Input() colors?: string[];

    /**
     * Callback for providing the opacity of displayed data item labels. If not specified then opacity is 1.
     */
    @Input() valueOpacityFn?: (item: unknown) => number;
    /**
     * Specifies the color configuration. Defaults to categorical palette.
     *
     * If specified, allows four different configuration
     * - default/categorical - using 20 predefined colors
     * - sequential by color scheme - using predefined sequence of colors by name
     * - predefined - using predefined dictionary
     * - own - array of hexadecimal values
     *
     * For usage, see New Palette in storybook under LgCharts.
     * Palette story contains all possible colors.
     * Gallery story contains all charts using new colors.
     *
     * Example can be seen in 'getAllChartsProps.ts:62'
     */
    @Input() colorConfiguration?: LgColorsConfiguration = LG_DEFAULT_COLOR_CONFIGURATION;

    /**
     * @optional
     * @override
     * Specifies whether bubbles are clickable.
     *
     * @default false
     */
    @Output() override readonly itemClick = new EventEmitter<
        ChartClickEvent<unknown, IIconChartItem>
    >();

    readonly DEFAULT_MARGIN = 20;

    private _trackListener!: () => void;

    private _lastMouseX = 0;
    private _lastMouseY = 0;
    _legendDefinition: LegendItem[] = [];

    ngOnInit(): void {
        super._onInit();

        this._drawMainSvgHolder(this._chartDivRef.nativeElement);
        this._updateSize();
        this._createChart();
        this._convertData();
        this._setLegendData();
        this._render();
        this._initializeTooltip();
        this._trackMousePosition();

        this._initialized = true;
    }

    ngOnChanges(changes: LgSimpleChanges<LgIconChartComponent>): void {
        if (!this._initialized) {
            return;
        }

        super._onBaseChartChanges(changes);

        let needsRender = false;

        if (changes.data || changes.itemsLimit || changes.valueOpacityFn) {
            this._convertData();
            this._setLegendData();
            needsRender = true;
        }

        if (changes.width || changes.height) {
            this._updateSize();
            needsRender = true;
        }

        if (needsRender) {
            this._render();
        }
    }

    private _updateSize(): void {
        this._svg.attr("width", this.width / 2);
        this._svg.attr("height", +this.height);
    }

    private _createChart(): void {
        this._chart = this._svgG.append("g").attr("id", "chart");
    }

    private _convertData(): void {
        if (!this.data) {
            return;
        }

        this._updateColors();
        const colors = !this.colors?.length ? d3.schemeCategory10 : this.colors;

        this._data = [];

        let totalValue = 0;
        let othersValue = 0;

        this.data.forEach((item, i) => {
            // Sum values over items limit
            if (this.itemsLimit != null && i >= this.itemsLimit) {
                othersValue += item.value;
                return;
            }

            this._data.push({
                id: this._getUniqId(),
                valueName: item.name,
                value: item.value,
                color: colors[i % colors.length],
                customItem: item.customItem
            });

            totalValue += item.value;
        });

        // Add Others to chart data
        if (othersValue > 0) {
            this._data.push({
                id: this._getUniqId(),
                valueName: this._translateService.translate(".Others"),
                value: othersValue,
                color: this._colorPalette.getOtherColor(),
                customItem: null
            });
            totalValue += othersValue;
        }

        this._data.forEach(item => (item.percentValue = toFixedFix(item.value / totalValue, 3)));
    }

    private _getUniqId(): string {
        return Date.now() + Math.random().toString().slice(2);
    }

    private _updateColors(): void {
        // No changes necessary for old palette
        if (!this._colorPalette.useNewColorPalette) return;
        this.colors = this._colorPalette.getColorsForType(this.colorConfiguration!);
    }

    private _setLegendData(): void {
        this._legendDefinition = this._data.map(item => ({
            color: item.color,
            name: item.valueName
        }));
    }

    private _render(): void {
        this._chart.selectAll("g").remove();

        if (this._data == null || this._data.length === 0) {
            this._tooltip?.hide();
            return;
        }

        const iconGroup = this._chart
            .append("g")
            .attr("id", "iconGroup")
            .attr("transform", `translate(${this.DEFAULT_MARGIN},${this.DEFAULT_MARGIN})`);

        const iconHeight = this.height - 2 * this.DEFAULT_MARGIN;
        let lastYPosition = 0;

        this._data.forEach((datum, index) => {
            const iconItemGroup = iconGroup
                .append("g")
                .attr("class", "iconItemGroup")
                .attr("clip-path", `url(#iconClipPath${datum.id})`);

            const iconClipPath = iconItemGroup
                .append("clipPath")
                .attr("id", `iconClipPath${datum.id}`);

            iconClipPath
                .append("rect")
                .attr("width", this.width / 2)
                .attr("height", Math.round(iconHeight * (datum.percentValue ?? 0)) - 1)
                .attr("y", lastYPosition);

            iconItemGroup
                .append("use")
                .attr("id", `iconSvg${datum.id}`)
                .attr("xlink:href", `#${this.icon}`)
                .attr("width", this.width / 2)
                .attr("height", iconHeight)
                .attr("fill", datum.color)
                .attr("opacity", this.valueOpacityFn ? this.valueOpacityFn(datum.customItem) : 1);

            // Tooltip area
            iconItemGroup
                .append("rect")
                .attr("width", this.width / 2)
                .attr("height", Math.round(iconHeight * (datum.percentValue ?? 0)))
                .attr("y", lastYPosition)
                .attr("fill", "transparent")
                .attr("cursor", this.clickable ? "pointer" : "default")
                .on("click", (_event: MouseEvent) => {
                    this.itemClick.emit({ item: datum.customItem, datum, index });
                })
                .on("mouseover", (_event: MouseEvent) => {
                    let tooltipItems: IIconChartItem[];
                    if (this._data.length > this.tooltipItemsLimit) {
                        tooltipItems = this._data.slice(0, this.tooltipItemsLimit);

                        if (!tooltipItems.includes(datum)) {
                            tooltipItems.push(datum);
                        }
                    } else {
                        tooltipItems = this._data;
                    }

                    this.tooltipContext = {
                        selectedId: datum.id,
                        items: tooltipItems
                    };

                    this._tooltip?.show();
                    this._updateTooltipPosition();
                })
                .on("mouseleave", (_event: MouseEvent) => this._tooltip?.hide());

            lastYPosition += Math.round((datum.percentValue ?? 0) * iconHeight);
        });
    }

    private _trackMousePosition(): void {
        this._ngZone.runOutsideAngular(() => {
            this._trackListener = this._renderer.listen(
                this._elementRef.nativeElement,
                "mousemove",
                (event: MouseEvent) => {
                    this._lastMouseX = event.clientX;
                    this._lastMouseY = event.clientY;
                    this._updateTooltipPosition();
                }
            );
        });
    }

    private _updateTooltipPosition(): void {
        if (this._tooltip && this._tooltip.visible) {
            this._tooltip.setPositionAt(
                this._lastMouseX,
                this._lastMouseY,
                getRecommendedPosition(
                    { x: this._lastMouseX, y: this._lastMouseY },
                    this._tooltip.getOverlayElement()
                )
            );
        }
    }

    ngOnDestroy(): void {
        super._onDestroy();

        // destroys renderer listener
        this._trackListener();
    }
}
