import ldReduce from "lodash-es/reduce";
import ldMap from "lodash-es/map";
import { Observable } from "rxjs";

export interface INavExtraData {
    [key: string]: any;
    expanded?: boolean;
    navigable?: boolean;
}

export interface INavNodeBase {
    /** Specify id if the node (special names are "root" and "settings") */
    id?: string;
    /** Specify path of the node (relative, absolute, or external) */
    path: string;

    params?: INavNodeParameter[];
    queryParams?: INavNodeParameter[];

    /** Specify if navigation node in the breadcrumb is narrowed */
    narrowed?: boolean;

    /** Specify name of the node in the menu (use lid if you want it localized) */
    name?: string;
    /** Specify prefix of the name. Note that this will be ignored if number is enabled */
    namePrefix?: string;
    /** Localization id to use instead of name. If relative, it will be looked up in namespace APP._Navigation */
    lid?: string;

    /**  Specify title of the page (to be used in the browser window). Name is used if not specified */
    pageTitle?: string;
    /** Specify localization id of the title of the page (if relative, it will be lookup up in APP._Navigation) */
    pageTitleLC?: string;
    /** Specify that the component should not set page title. Title of closest parent will be used instead */
    noPageTitle?: boolean;

    /** Specify, that the page is meant for anonymous users only */
    anonymous?: boolean;
    /** Specify permissions. User must have at least one to access this node (or its children) */
    permissions?: string[];

    /**
     * Specify that the navigation entry is disabled.
     * Disabled entry can be still navigated to manually. Also the processing logic can set items to disabled
     * based on the access rights */
    disabled?: boolean;

    /**
     * Specify, that the link should be opened in new tab. This applies only for external links
     */
    inNewTab?: boolean;

    /**
     * Specify that the navigation entry is hidden.
     * Hidden entry can be still navigated to manually. Also the processing logic can set items to hidden, based
     * on access rights
     */
    hidden?: boolean;

    /**
     * Specify, that the node (and its children) should not receive a number. Typically done for settings
     */
    noNumber?: boolean;

    /**
     * Specify, that the node should not be visible in the breadcrumb. Its children can be still shown
     */
    noBreadcrumb?: boolean;

    /**
     * Specify, that the node's children represent independent subtree. When obtaining the node from any of
     * the queries in the navigation service, its chilren won't be included.
     */
    subtree?: boolean;

    /** Custom navigation data */
    data?: INavExtraData;

    /**
     * Specifies, that the node (and its children) should be removed from the final tree. This serves as
     * a quick way to cut parts of the tree
     */
    removed?: boolean;

    onClickFn?: (node: ProcessedNavNode) => void | null;

    /** Children navigation nodes */
    children?: INavNode[];

    /**
     * Children node will be navigated by query params.
     * Navigation within children nodes skips the navigation path and relies on query params.
     */
    matchChildrenByQueryParams?: string[];
}

type OrObservable<T> = {
    [K in keyof T]: T[K] | Observable<T[K]>;
};

export interface INavNode extends OrObservable<INavNodeBase> {
    /** Children navigation nodes */
    children?: INavNode[];
}

export interface INavNodeMaterialized extends INavNodeBase {
    children?: INavNodeMaterialized[];
}

export interface INavNodeParameter {
    name: string;
    value?: string;
}

const TemplateMatcher = /{{\s?([^{}\s]*)\s?}}/g;

export class ProcessedNavNode {
    id?: string | undefined;
    href = "";
    hrefNoQuery = "";

    name = "";
    namePrefix?: string | undefined;
    current = false;
    pageTitle?: string | undefined | null;

    narrowed = false;
    anonymous = false;
    permissions: string[] | null = null;
    disabled = false;
    hidden = false;
    noNumber = false;
    noBreadcrumb = false;
    noPageTitle = false;
    subtree = false;
    noAccessRights = false;
    isExternalLink = false;
    inNewTab?: boolean | undefined;
    onClickFn: ((node: ProcessedNavNode) => void) | null = null;
    data?: INavExtraData | undefined;

    parent: ProcessedNavNode | null = null;
    children: ProcessedNavNode[] | null = null;
    matchChildrenByQueryParams?: string[] | undefined;
    queryParams?: Record<string, any> | undefined;

    public static fromDefinition(
        def: INavNodeMaterialized,
        path: string,
        name: string,
        pageTitle: string | null | undefined,
        parent: ProcessedNavNode | null
    ): ProcessedNavNode {
        let href = "";
        const isExternalLink = def.path.substr(0, 4) === "http";
        if (isExternalLink) href = def.path;
        else {
            href = path + ldReduce(def.params, (p, x) => p + `/${x.value || `{{${x.name}}}`}`, "");
        }

        const params = ldMap(def.queryParams, x => {
            const value = x.value || `{{${x.name}}}`;
            if (!value) return null;
            return `${x.name}=${value}`;
        });

        let queryParams: Record<string, any> | undefined;
        if (def.queryParams !== undefined) {
            queryParams = def.queryParams.reduce(
                (acc, param) => {
                    acc[param.name] = param.value;
                    return acc;
                },
                {} as Record<string, any>
            );
        }

        const noQuery = href;

        if (params.length > 0) {
            href = href + "?" + params.join("&");
        }

        const processed = new ProcessedNavNode();
        processed.id = def.id;
        processed.href = href;
        // We assume query is always added through queryParams, not directly
        processed.hrefNoQuery = noQuery;
        processed.name = name;
        processed.pageTitle = pageTitle;
        processed.current = false;
        processed.narrowed = def.narrowed ?? false;
        processed.permissions = def.permissions || null;
        processed.anonymous = def.anonymous || false;
        processed.disabled = def.disabled || false;
        processed.hidden = def.hidden || false;
        processed.noNumber = def.noNumber || false;
        processed.noBreadcrumb = def.noBreadcrumb || false;
        processed.noPageTitle = def.noPageTitle || false;
        processed.subtree = def.subtree || false;
        processed.noAccessRights = false;
        processed.data = def.data || {};
        processed.parent = parent || null;
        processed.children = null;
        processed.isExternalLink = isExternalLink;
        processed.inNewTab = def.inNewTab;
        processed.matchChildrenByQueryParams = def.matchChildrenByQueryParams;
        processed.queryParams = queryParams;
        processed.onClickFn = def.onClickFn ?? null;
        return processed;
    }

    // note: do we need special handling for query parameters?
    public getHref(parameters?: Record<string, any>): string {
        if (!parameters) return this.href;

        return this.href.replace(TemplateMatcher, (_part: string, name: string) => {
            const val = parameters[name];
            return val == null ? "" : val;
        });
    }

    public getHrefNoQuery(parameters?: Record<string, any>): string {
        if (!parameters) return this.hrefNoQuery;

        return this.hrefNoQuery.replace(TemplateMatcher, (_part: string, name: string) => {
            const val = parameters[name];
            return val == null ? "" : val;
        });
    }

    public copy(
        pathPrefix: string,
        skipHidden?: boolean,
        cb?: (target: ProcessedNavNode, source: ProcessedNavNode) => void
    ): ProcessedNavNode | undefined {
        if (skipHidden && this.hidden) return undefined;
        return this._copyImpl(pathPrefix, null, skipHidden, cb);
    }

    private _copyImpl(
        pathPrefix: string,
        parent: ProcessedNavNode | null,
        skipHidden?: boolean,
        cb?: (target: ProcessedNavNode, source: ProcessedNavNode) => void
    ): ProcessedNavNode {
        const copy = new ProcessedNavNode();
        copy.id = this.id;
        copy.name = this.name;
        copy.namePrefix = this.namePrefix;
        copy.pageTitle = this.pageTitle;
        copy.current = false;
        copy.narrowed = this.narrowed;
        copy.data = this.data;
        copy.anonymous = this.anonymous;
        copy.permissions = this.permissions;
        copy.disabled = this.disabled;
        copy.hidden = this.hidden;
        copy.noNumber = this.noNumber;
        copy.noBreadcrumb = this.noBreadcrumb;
        copy.noPageTitle = this.noPageTitle;
        copy.subtree = this.subtree;
        copy.noAccessRights = this.noAccessRights;
        copy.parent = parent;
        copy.onClickFn = this.onClickFn;
        copy.isExternalLink = this.isExternalLink;
        copy.matchChildrenByQueryParams = this.matchChildrenByQueryParams;
        copy.queryParams = { ...this.queryParams };
        if (this.isExternalLink || !pathPrefix) {
            copy.href = this.href;
            copy.hrefNoQuery = this.hrefNoQuery;
        } else {
            copy.href = pathPrefix + "/" + this.href;
            copy.hrefNoQuery = pathPrefix + "/" + this.hrefNoQuery;
        }

        if (cb) cb(copy, this);

        if (this.children) {
            copy.children = [];
            for (const child of this.children) {
                if (child.subtree) continue;
                if (!skipHidden || !child.hidden) {
                    copy.children.push(child._copyImpl(pathPrefix, copy, true, cb));
                }
            }
        }
        return copy;
    }
}
