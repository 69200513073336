import html2canvas from "html2canvas";

interface DesciptionSettings {
    fullHeight: number;
    lines: string[];
    oneLineHeight: number;
    padding: number;
    fontSize: number;
}

export function exportHtmlToPng(
    elementToExport: HTMLElement,
    name: string,
    description: string
): void {
    html2canvas(elementToExport).then(elementCanvas => {
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d")!;
        const descriptionSettings = getDescriptionSettings(description, elementCanvas.width);
        canvas.width = elementCanvas.width;
        canvas.height = elementCanvas.height + descriptionSettings.fullHeight;

        const img = new Image();
        img.onload = function () {
            // drawing description background
            context.beginPath();
            context.rect(
                0,
                elementCanvas.height,
                elementCanvas.width,
                descriptionSettings.fullHeight
            );
            context.fillStyle = "white";
            context.fill();

            // drawing chart image
            context.drawImage(img, 0, 0);

            // drawing description
            context.font = `${descriptionSettings.fontSize}px Courier`;
            context.fillStyle = "black";
            context.textAlign = "left";
            context.textBaseline = "top";
            descriptionSettings.lines.forEach((line, i) => {
                context.fillText(
                    line,
                    descriptionSettings.padding,
                    elementCanvas.height + i * descriptionSettings.oneLineHeight
                );
            });

            canvas.toBlob(blob => {
                triggerBlobFileDownload(blob, name);
            });
        };
        img.src = elementCanvas.toDataURL();
    });
}

function getDescriptionSettings(description: string, canvasWidth: number): DesciptionSettings {
    const padding = 10;
    const oneLineHeight = 17;
    const fontSize = 15;
    const oneCharacterWidth = 10;
    const oneLineCharacterCount = Math.round((canvasWidth - padding * 2) / oneCharacterWidth);

    const lines = [];

    if (description) {
        const space = " ";
        let currentLine = "";
        let currentCount = 0;
        const descriptionWords = description.split(space);
        descriptionWords.forEach(word => {
            if (currentCount + word.length <= oneLineCharacterCount) {
                currentLine += word + space;
                currentCount += word.length + 1;
            } else {
                lines.push(currentLine);
                currentLine = word + space;
                currentCount = word.length;
            }
        });
        if (currentLine.length) lines.push(currentLine);
    }

    const fullHeight = lines.length * oneLineHeight + padding;

    return { fullHeight, lines, oneLineHeight, padding, fontSize };
}

export function exportSvg(svgEl: SVGElement, name: string, description: string): void {
    svgEl.setAttribute("xmlns", "http://www.w3.org/2000/svg");
    svgEl.setAttribute("xmlns:xlink", "http://www.w3.org/1999/xlink");
    const width = Number(svgEl.getAttribute("width"));
    const height = Number(svgEl.getAttribute("height"));
    const descriptionSettings = getDescriptionSettings(description, width);
    svgEl.setAttribute("height", height + descriptionSettings.fullHeight + "");

    descriptionSettings.lines.forEach((line, i) => {
        svgEl.innerHTML =
            svgEl.innerHTML +
            `<text style="font-family:Courier; font-size: ${descriptionSettings.fontSize}px" x="${
                descriptionSettings.padding
            }" y="${
                height + descriptionSettings.padding + i * descriptionSettings.oneLineHeight
            }" fill="black">${line}</text>`;
    });

    const svgData = svgEl.outerHTML;
    const preface = '<?xml version="1.0" standalone="no"?>\r\n';
    const blob = new Blob([preface, svgData], { type: "image/svg+xml;charset=utf-8" });
    triggerBlobFileDownload(blob, name);
}

export function triggerBlobFileDownload(blob: Blob | null, filename: string): void {
    // extensions based on https://github.com/eligrey/FileSaver.js/blob/master/src/FileSaver.js
    const downloadLink = document.createElementNS(
        "http://www.w3.org/1999/xhtml",
        "a"
    ) as any as HTMLAnchorElement;
    if (blob === null) {
        return;
    }
    const blobUri = URL.createObjectURL(blob);
    downloadLink.href = blobUri;
    downloadLink.download = filename;
    downloadLink.rel = "noopener";
    setTimeout(() => URL.revokeObjectURL(downloadLink.href), 40 * 1000);
    setTimeout(() => downloadLink.click(), 0);
}
