import { Directive, TemplateRef, OnInit, OnDestroy, inject } from "@angular/core";

import { IHeaderFooterTemplate, LgPivotTableBodyRegister } from "../types";

@Directive({
    standalone: true,
    selector: "[lgPivotTableEmptyRowDef]"
})
export class LgPivotTableEmptyRowDefDirective
    implements IHeaderFooterTemplate<any>, OnInit, OnDestroy
{
    private _body = inject(LgPivotTableBodyRegister);
    private _template = inject(TemplateRef<any>);

    public get template(): TemplateRef<any> {
        return this._template;
    }

    public ngOnInit(): void {
        this._body.addEmptyRowTemplate(this);
    }

    public ngOnDestroy(): void {
        this._body.removeEmptyRowTemplate(this);
    }
}
