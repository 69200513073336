import { inject, Injectable } from "@angular/core";
import { IAuthenticationService, UserIdentity } from "./authentication.types";
import { LG_APP_CONFIGURATION } from "../application/app-configuration";
import { LG_USER_INFO } from "../user/user.types";

@Injectable()
export class LgStubAuthenticationService implements IAuthenticationService {
    private _appConfig = inject(LG_APP_CONFIGURATION);
    private _user = inject(LG_USER_INFO);

    get loggedIn(): boolean {
        return true;
    }

    get user(): UserIdentity {
        return {
            id: "" + this._user.id,
            login: "" + this._user.userid,
            name: this._user.name
        };
    }

    login(): Promise<boolean> {
        window.location.reload();
        return Promise.resolve(true);
    }

    isLoggedIn(): Promise<boolean> {
        return Promise.resolve(true);
    }

    logout(): Promise<void> {
        window.location.replace(this._appConfig.logoutUrl ?? "");
        return Promise.resolve();
    }
}
