import { Directive, inject, Input, OnDestroy, OnInit } from "@angular/core";
import { race, Subject, timer } from "rxjs";
import { debounceTime, filter, skip, takeUntil, takeWhile } from "rxjs/operators";
import ldIsEqual from "lodash-es/isEqual";

import { LgScrollableDirective } from "./../lg-scrollable.directive";
import { LgViewportScrollerService } from "./lg-viewport-scroller.service";
import { ILgScrolledEvent } from "./../lg-scrollable-container";

/**
 * This directive automatically restores previous scroll position when going back or
 * forward. If you enable anchor scrolling, it will also scroll to the element which has
 * the same id as the fragment in url. The scrollable works as a replacement for the default
 * browser scroller and should only be used once.
 *
 * Use:
 * - Provide LgViewportScroller as ViewportScroller to AppModule.
 * - Enable scrollPositionRestoration in the ExtraOptions of a router module. Optionally,
 *   anchorScrolling and scrollOffset can be enabled/configured there.
 * - Use this directive in the element where the current page content is rendered.
 */
@Directive({
    standalone: true,
    selector: "[lgViewportScrollable]"
})
export class LgViewportScrollableDirective
    extends LgScrollableDirective
    implements OnInit, OnDestroy
{
    private _viewportScrollerService = inject(LgViewportScrollerService);

    @Input("centerAnchorElement") _centerAnchorElement = false;

    @Input("disablePositionScrollRestoring") _positionScrollDisabled = false;
    @Input("disableAnchorScrolling") _anchorScrollDisabled = false;

    @Input("positionScrollTimeout") _positionScrollTimeout = 3000;
    @Input("anchorScrollTimeout") _anchorScrollTimeout = 3000;

    private _offset: [number, number] = [0, 0];
    private readonly _destroyed$ = new Subject<void>();

    override ngOnInit(): void {
        super.ngOnInit();
        this._notifyOnScroll();
        this._observeScrollCommands();
        this._observeAnchorOffset();
    }

    override ngOnDestroy(): void {
        super.ngOnDestroy();
        this._destroyed$.next();
        this._destroyed$.complete();
    }

    scrollToAnchor(anchor: string): void {
        const anchorPosition = this._getAnchorPosition(anchor);
        if (!anchorPosition) return;

        this.scrollTo(anchorPosition[0], anchorPosition[1]);
    }

    private _getAnchorPosition(anchor: string): [number, number] | null {
        const anchorElement = this.getElementRef().nativeElement.querySelector("#" + anchor);
        if (!anchorElement) return null;

        const containerPosition = this.getElementRef().nativeElement.getBoundingClientRect();
        const anchorPosition = anchorElement.getBoundingClientRect();
        const scrollPosition = this.getScrollContainerInfo().position;

        let leftToCenter = 0;
        let topToCenter = 0;
        if (this._centerAnchorElement) {
            leftToCenter = (containerPosition.width - anchorPosition.width) / 2;
            topToCenter = (containerPosition.height - anchorPosition.height) / 2;
        }

        const left = anchorPosition.left + scrollPosition.x - containerPosition.left - leftToCenter;
        const top = anchorPosition.top + scrollPosition.y - containerPosition.top - topToCenter;

        return [
            Math.floor(Math.max(0, left - this._offset[0])),
            Math.floor(Math.max(0, top - this._offset[1]))
        ];
    }

    private _notifyOnScroll(): void {
        this.scrolled()
            .pipe(takeUntil(this._destroyed$))
            .subscribe(position => {
                this._viewportScrollerService.updateCurrentScrollPosition([position.x, position.y]);
            });
    }

    private _observeAnchorOffset(): void {
        this._viewportScrollerService.anchorOffset$
            .pipe(takeUntil(this._destroyed$))
            .subscribe(offset => {
                this._offset = offset;
            });
    }

    private _observeScrollCommands(): void {
        this._viewportScrollerService.scrollToPosition$
            .pipe(
                takeUntil(this._destroyed$),
                filter(_ => !this._positionScrollDisabled)
            )
            .subscribe(position => {
                this._scrollUntilPositionIsSet(position);
            });

        this._viewportScrollerService.scrollToAnchor$
            .pipe(
                takeUntil(this._destroyed$),
                filter(_ => !this._anchorScrollDisabled)
            )
            .subscribe(anchor => {
                this._scrollUntilAnchorPositionIsSet(anchor);
            });
    }

    private _scrollUntilPositionIsSet(position: [number, number]): void {
        this.scrollTo(position[0], position[1]);
        this.resized()
            .pipe(
                takeUntil(
                    race([
                        this.scrolled().pipe(
                            filter(
                                scrolledTo =>
                                    scrolledTo.x === position[0] && scrolledTo.y === position[1]
                            )
                        ),
                        this._viewportScrollerService.scrollToPosition$,
                        this._viewportScrollerService.scrollToAnchor$.pipe(skip(1)),
                        timer(this._positionScrollTimeout)
                    ])
                )
            )
            .subscribe(_ => {
                this.scrollTo(position[0], position[1]);
            });
    }

    private _scrollUntilAnchorPositionIsSet(anchor: string): void {
        this.scrollToAnchor(anchor);
        this.resized()
            .pipe(
                takeWhile(_ => !ldIsEqual(this._getAnchorPosition(anchor), [0, 0])),
                takeUntil(
                    race([
                        this.scrolled().pipe(
                            debounceTime(10),
                            filter(scrolledTo =>
                                this._anchorScrolledCorrectly(
                                    scrolledTo,
                                    this._getAnchorPosition(anchor)
                                )
                            )
                        ),
                        this._viewportScrollerService.scrollToPosition$,
                        this._viewportScrollerService.scrollToAnchor$.pipe(skip(1)),
                        timer(this._anchorScrollTimeout)
                    ])
                )
            )
            .subscribe(_ => {
                this.scrollToAnchor(anchor);
            });
    }

    private _anchorScrolledCorrectly(
        scrolledTo: ILgScrolledEvent,
        anchorPosition: [number, number] | null
    ): boolean {
        if (!anchorPosition) return false;

        const isTargetPosition =
            scrolledTo.x === anchorPosition[0] && scrolledTo.y === anchorPosition[1];
        if (isTargetPosition) {
            return true;
        }

        const container = this.getScrollContainerInfo();

        const isAtBottom =
            this.getScrollHeight() !== 0 &&
            scrolledTo.y + container.size.height === this.getScrollHeight();
        if (isAtBottom) {
            return true;
        }

        const isOnTheRightEdge =
            this.getScrollWidth() !== 0 &&
            scrolledTo.x + container.size.width === this.getScrollWidth();
        return isOnTheRightEdge;
    }
}
