<div
    [tabindex]="disabled ? -1 : 0"
    class="lg-time-picker__input"
    [class.lg-time-picker__input--condensed]="condensed"
    [class.lg-time-picker__input--24h]="_24h"
    (click)="_onOpenPopup()"
    (keydown)="_onKeyDown($event)"
    [lgCopyHandler]="_copyHandler"
    [lgPasteHandler]="_pasteHandler"
    #inputDiv
>
    <div class="lg-time-picker__input__center">
        <input
            type="text"
            class="lg-time-picker__input__part lg-time-picker__input__part--hours"
            [disabled]="true"
            [placeholder]="'.Hours_placeholder' | lgTranslate"
            [value]="_hours"
        />{{ _hoursSeparator
        }}<!--
        --><input
            type="text"
            class="lg-time-picker__input__part lg-time-picker__input__part--minutes"
            [class.lg-time-picker__input__part--minutes--active]="value"
            [placeholder]="'.Minutes_placeholder' | lgTranslate"
            [disabled]="true"
            [value]="_minutes"
        /><!--
        --><ng-container *ngIf="!_24h"
            >{{ _minutesSeparator
            }}<!--
            --><input
                type="text"
                class="lg-time-picker__input__part lg-time-picker__input__part--period"
                [disabled]="true"
                [placeholder]="'.Period_placeholder' | lgTranslate"
                [value]="_period"
            /><!--
        --></ng-container>
    </div>
</div>
