import { inject, Injectable } from "@angular/core";
import { LgRouterStateService } from "@logex/framework/ui-core";
import { LG_USER_INFO } from "../user/user.types";
import { INavigationChangeHandler, LgNavigationService } from "./lg-navigation.service";

@Injectable()
/**
 * Reload the application whenever the navigation definition change. Use this handler
 * only if the navigation change is stable (persistent data, such as user's selected
 * scenario)
 */
export class LgNavigationChangeHandlerReload implements INavigationChangeHandler {
    onNavigationChanged(_navigationService: LgNavigationService): void {
        window.location.reload();
    }
}

@Injectable()
/**
 * Evaluate the permissions and if the current page is no longer accessible, navigate to
 * first visible. This logic basically duplicates the behaviour of RoleGuard, and is suitable
 * only if you're using it at top level of your routing.
 */
export class LgNavitationChangeHandlerEvaluatePermissions implements INavigationChangeHandler {
    private _routerState = inject(LgRouterStateService);
    private _userInfo = inject(LG_USER_INFO);

    onNavigationChanged(navigationService: LgNavigationService): void {
        navigationService
            .canAccessRoute(
                this._routerState.currentDeepestSnapshot,
                !(this._userInfo.userid ?? this._userInfo.id)
            )
            .then(value => {
                if (!value) {
                    const accessible = navigationService.findFirstAccessibleNodeSync();
                    if (accessible) {
                        navigationService.navigateTo(accessible);
                    } else {
                        window.location.reload();
                    }
                }
            });
    }
}
