<div class="lg-icon-chart" [style.width.px]="width">
    <div #iconChart></div>
    <div class="lg-icon-chart__legend-wrapper">
        <lg-chart-legend
            class="lg-icon-chart__legend"
            [definition]="_legendDefinition"
            [cropLongNames]="true"
            [style.max-height.px]="height - 2 * DEFAULT_MARGIN"
        ></lg-chart-legend>
    </div>
</div>

<ng-template #defaultTemplate [lgChartTemplateContextType]="this" let-context>
    <div class="lg-tooltip__table">
        <div
            *ngFor="let item of context.items"
            class="lg-tooltip__table-row"
            [ngClass]="{ 'lg-tooltip__table-row--inactive': item.id !== context.selectedId }"
        >
            <div class="lg-tooltip__table-column lg-tooltip__table-column--legend">
                <span [ngStyle]="{ 'background-color': item.color }"></span>
            </div>
            <div class="lg-tooltip__table-column lg-tooltip__table-column--name">
                {{ item.valueName }}:
            </div>
            <div
                class="lg-tooltip__table-column lg-tooltip__table-column--value"
                [innerHTML]="_numberFormat(item.value) | lgMarkSymbols"
            ></div>
            <div
                class="lg-tooltip__table-column lg-tooltip__table-column--value"
                [innerHTML]="item.percentValue | fmtPercent : 1 | lgMarkSymbols"
            ></div>
        </div>
    </div>
</ng-template>
