import { InjectionToken } from "@angular/core";

export const LG_NEW_PASSWORD_CONFIG = new InjectionToken<PasswordConfig>("LG_NEW_PASSWORD_CONFIG");

export interface PasswordRequirement {
    active: boolean;
    value?: number;
}

export interface PasswordRequirementState extends PasswordRequirement {
    passed: boolean;
    lcCode:
        | "MinLength"
        | "XOfFollowing"
        | "ConfirmMatch"
        | "Upper"
        | "Lower"
        | "Number"
        | "Special";
    level: number;
}

export interface PasswordConfig {
    minLength?: PasswordRequirement;
    xOfFollowing?: PasswordRequirement;
    upper?: PasswordRequirement;
    lower?: PasswordRequirement;
    number?: PasswordRequirement;
    special?: PasswordRequirement;
}

export interface PasswordConfigState {
    minLength: PasswordRequirementState;
    xOfFollowing: PasswordRequirementState;
    upper: PasswordRequirementState;
    lower: PasswordRequirementState;
    number: PasswordRequirementState;
    special: PasswordRequirementState;
    confirmMatch: PasswordRequirementState;
}
