<div class="lg-item-selector__search" [style.display]="hideSearch ? 'none' : 'block'">
    <input
        type="text"
        [(ngModel)]="_search"
        (ngModelChange)="_filterSearch()"
        (blur)="_focus = false"
        (focus)="_focus = true"
        #input
        class="empty-input"
    />
    <lg-icon icon="icon-search"></lg-icon>
</div>
<div class="lg-item-selector__header">
    <div class="lg-item-selector__header__left">
        <lg-selection-checkbox
            class="lg-selection-checkbox--inline"
            [state]="_checkState"
            [disabled]="disabled"
            (checked)="_checkAll($event)"
            title="{{ (_checkState !== 1 ? '.Add_visible' : '.Remove_visible') | lgTranslate }}"
        ></lg-selection-checkbox
        >{{ ".Selected" | lgTranslate: { count: _selectionSize } }}
    </div>
    <div
        class="lg-item-selector__header__right"
        (click)="_selectedOnlyToggle()"
        title="{{ '.SelectedOnly' | lgTranslate }}"
    >
        <lg-slider-switch
            [state]="_selectedOnly"
            (stateChange)="_selectedOnlyToggle()"
            class="lg-slider-switch--inline"
        ></lg-slider-switch>
        {{ ".SelectedOnly" | lgTranslate }}
    </div>
</div>
<div class="lg-item-selector__content">
    <div style="margin-right: 2px" lgScrollable lgScrollableAutoHide="true" #scroller>
        <div
            *lgVirtualFor="
                let entry of _visible;
                let index = index;
                height: 24;
                ensureVisible: _visible$
            "
            class="lg-item-selector__row"
            [class.lg-item-selector__row--cursor]="index === _currentIndex"
            [class.lg-item-selector__row--active]="_getState(entry)"
            [class.lg-item-selector__row--disabled]="
                disabled || (entry !== true && config.isDisabled(entry))
            "
            (click)="_toggleItem(entry, index)"
        >
            <lg-selection-checkbox
                [state]="_getState(entry)"
                [disabled]="disabled || (entry !== true && config.isDisabled(entry))"
                (checked)="_toggleItem(entry)"
            ></lg-selection-checkbox>
            <div
                class="lg-item-selector__row__name"
                [title]="entry === true ? config.exclusiveItemName : config.getItemTitle(entry)"
                [innerHTML]="
                    entry === true
                        ? config.exclusiveItemName
                        : (config.getName(entry) | highlight: _sanitizedSearch)
                "
            ></div>
            <ng-container
                *ngTemplateOutlet="_itemInfoTemplate; context: { $implicit: entry }"
            ></ng-container>
        </div>
    </div>
</div>
