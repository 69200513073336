import { Injectable } from "@angular/core";
import {
    ILogexPivotDefinition,
    ILogexPivotDefinitionOptions,
    PivotProviderBase
} from "@logex/framework/lg-pivot";

@Injectable({ providedIn: "root" })
export class ScenarioDialogPivot extends PivotProviderBase {
    getPivotDefinition(): ILogexPivotDefinition {
        return {
            column: "data_version_full_name",
            levelId: "dataVersionName",
            attachedColumns: [node => (node.$expanded = true)],
            children: {
                column: "scenario_naam",
                levelId: "scenario"
            }
        };
    }

    override getPivotOptions(): ILogexPivotDefinitionOptions {
        return {
            options: {
                parentLink: true
            },
            calculations: []
        };
    }
}

@Injectable({ providedIn: "root" })
export class ScenarioDialogSimplePivot extends PivotProviderBase {
    override getPivotDefinition(): ILogexPivotDefinition {
        return {
            column: "scenario_naam",
            levelId: "scenario"
        };
    }

    override getPivotOptions(): ILogexPivotDefinitionOptions {
        return {
            options: {
                parentLink: true
            },
            calculations: []
        };
    }
}
