import { Injectable, TemplateRef } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { LgFilterSet } from "@logex/framework/lg-filterset";

import { LgSlideoutApi } from "../../lg-slideout/lg-slideout.types";

export interface LgFiltersPanelState {
    filterSetKey: string | null;
    filterSet: LgFilterSet | null;
    slideoutApi: LgSlideoutApi | null;
    customFooterTemplate: TemplateRef<void> | null;
}

@Injectable({ providedIn: "root" })
export class LgFiltersPanelService {
    state$: Observable<LgFiltersPanelState>;

    private _filterSetKey = "";
    private _filterSet: LgFilterSet | null = null;
    private _slideoutPanelApi: LgSlideoutApi | null = null;
    private _customFooterTemplate?: TemplateRef<void>;
    private _state$ = new BehaviorSubject(this._getNextState());

    constructor() {
        this.state$ = this._state$.asObservable();
    }

    setKey(id: string): void {
        this._filterSetKey = id;
        this._notifySubscribers();
    }

    setSet(set: LgFilterSet): void {
        this._filterSet = set;
        this._notifySubscribers();
    }

    setApi(api: LgSlideoutApi): void {
        this._slideoutPanelApi = api;
        this._notifySubscribers();
    }

    setCustomFooterTemplate(ref: TemplateRef<void>): void {
        this._customFooterTemplate = ref;
        this._notifySubscribers();
    }

    private _notifySubscribers(): void {
        this._state$.next(this._getNextState());
    }

    private _getNextState(): LgFiltersPanelState {
        return {
            filterSetKey: this._filterSetKey,
            filterSet: this._filterSet,
            slideoutApi: this._slideoutPanelApi,
            customFooterTemplate: this._customFooterTemplate ?? null
        };
    }
}
