import { Directive, Input } from "@angular/core";

export interface IChartTooltipProvider<TContext> {
    tooltipContext: TContext | null;
}

export interface IImplicitContext<T extends object> {
    $implicit: T;
}

/**
 * Provides a correct type to the tooltip context.
 * It is necessary to provide the component reference as directive input.
 * When using within the chart component template, provide
 *
 * Firstly, the chart needs to implement the IChartTooltipProvider<TContext> interface using tooltipContext: TContext.
 * Then, the chart reference needs to be passed as this input.
 * If referenced within the chart template, simply use:
 * ```ts
 * <ng-template [lgChartTemplateContextType]="this" let-context>
 * ```
 * If referenced from outside of the component template, use the external chart reference:
 * ```ts
 * <lg-chart #chart [tooltipTemplate]="tooltipTemplate"/>
 * <ng-template #tooltipTemplate [lgChartTemplateContextType]="chart" let-context>
 * ```
 */
@Directive({
    selector: "ng-template[lgChartTemplateContextType]"
    // standalone: true
})
export class LgChartTemplateContextTypeDirective<TContext extends object> {
    /**
     * Required to provide a correct typing of the chart context.
     * See directive description on recommended usage.
     */
    @Input({ required: true }) lgChartTemplateContextType!: IChartTooltipProvider<TContext>;

    static ngTemplateContextGuard<TTooltipContext extends object>(
        directive: LgChartTemplateContextTypeDirective<TTooltipContext>,
        context: unknown
    ): context is IImplicitContext<TTooltipContext> {
        return true;
    }
}
