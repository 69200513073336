<lg-rectangle [lgLoaderOverlay]="_loading" [lgLoaderOverlayDelay]="false">
    <div class="form-group">
        <div class="form-row form-row--input" [class.form-row--blocked]="!!_editMode">
            <label class="form-row__label">{{ ".DataVersion" | lgTranslate }}</label>
            <div class="form-row__controls">
                <lg-dropdown
                    [definition]="_dataVersionDropdownDefinition"
                    [(current)]="_currentDataVersion"
                    (currentChange)="_onDataVersionChange()"
                ></lg-dropdown>
            </div>
        </div>
        <div class="form-row form-row--input" [class.form-row--blocked]="!!_editMode">
            <label class="form-row__label" for="data-version-description">
                {{ ".DataDescription" | lgTranslate }}
            </label>
            <div class="form-row__controls">
                <textarea
                    id="data-version-description"
                    [ngModel]="_dataVersionDescription"
                    [disabled]="true"
                    rows="2"
                ></textarea>
            </div>
        </div>
    </div>
    <div class="form-group">
        <div class="form-row form-row--input">
            <label class="form-row__label" for="scenario-name">
                {{ ".Scenario" | lgTranslate }}
            </label>
            <div class="form-row__controls form-row__controls--flex">
                <div class="form-row__control-column">
                    <lg-dropdown
                        *ngIf="!_editMode"
                        [definition]="_scenarioDropdownDefinition"
                        [(current)]="_currentScenario"
                        (currentChange)="_onScenarioChange()"
                    ></lg-dropdown>

                    <input
                        type="text"
                        id="scenario-name"
                        class="input input--wide"
                        [(ngModel)]="_scenarioName"
                        *ngIf="!!_editMode"
                    />
                </div>
                <div
                    class="form-row__control-column form-row__control-column--fixed"
                    *ngIf="!_editMode"
                >
                    <lg-quick-settings-menu
                        [definition]="_scenarioMenuDefinition"
                        class="lg-icon-menu__button"
                        icon="icon-dots"
                        [disabled]="!_scenarioMenuDefinition?.length"
                    ></lg-quick-settings-menu>
                </div>
            </div>
        </div>
        <div class="form-row form-row--input">
            <label class="form-row__label" for="scenario-description">
                {{ ".ScenarioDescription" | lgTranslate }}
            </label>
            <div class="form-row__controls">
                <textarea
                    id="scenario-description"
                    [(ngModel)]="_scenarioDescription"
                    [disabled]="!_editMode"
                    rows="2"
                ></textarea>
            </div>
        </div>
        <div class="form-row form-row--input" *ngIf="!!_editMode">
            <label class="form-row__label">&nbsp;</label>
            <div class="form-row__controls text-right">
                <lg-button
                    buttonClass="button--small button--primary"
                    (click)="_saveScenario()"
                    textLc=".Save"
                ></lg-button
                >&nbsp;

                <lg-button
                    buttonClass="button--small"
                    textLc="FW.CANCEL"
                    (click)="_setEditMode(null)"
                ></lg-button>
            </div>
        </div>
    </div>
    <div class="form-group" [class.form-group--blocked]="!!_editMode">
        <div class="form-row form-row--with-buttons">
            <div>
                {{ ".LastSavedBy" | lgTranslate }} {{ _lastSaveBy }} {{ ".On" | lgTranslate }}
                {{ _lastSaveAt | date : "dd-MM-yyyy" }} {{ ".At" | lgTranslate }}
                {{ _lastSaveAt | date : "HH:mm" }}<br />
                {{ ".VersionId" | lgTranslate }} {{ _displayVersionId }},
                {{ ".SystemId" | lgTranslate }} {{ _currentScenario }}

                &nbsp;

                <span *ngIf="_userInfo.roles['internal']">
                    <lg-button
                        icon="icon-search"
                        *ngIf="!_searchVersionState"
                        (click)="_searchVersionState = true"
                        buttonClass="button--small disable-overlay-focus"
                    ></lg-button>

                    <span *ngIf="_searchVersionState">
                        <input
                            type="text"
                            class="input input--small"
                            [(ngModel)]="_searchVersionId"
                            (keydown.enter)="_setVersion()"
                            placeholder="ID"
                            [style.width.px]="40"
                        />&nbsp;

                        <lg-button
                            buttonClass="button--small button--primary"
                            (click)="_searchVersion()"
                            textLc=".SearchVersion"
                        ></lg-button
                        >&nbsp;

                        <lg-button
                            buttonClass="button--small"
                            textLc="FW.CANCEL"
                            (click)="_searchVersionState = false"
                        ></lg-button>
                    </span>
                </span>
            </div>
        </div>
    </div>
    <div class="form-group form-group--buttons" [class.form-group--blocked]="!!_editMode">
        <div class="form-row">
            <lg-button (click)="_open()" buttonClass="button--primary" textLc=".Open"></lg-button>
            <lg-button (click)="_dialogRef.close()" textLc=".Cancel"></lg-button>
        </div>
    </div>
</lg-rectangle>
