<div
    class="lg-breadcrumb__element lg-breadcrumb__element--toolname"
    [class.lg-breadcrumb__element--clickable]="!!homeId && _state.length !== 0"
    (click)="_navigateHome($event)"
>
    <div>{{ toolNameLc | lgTranslate: toolNameParams }}</div>
</div>
<div
    class="lg-breadcrumb__element lg-breadcrumb__element--clickable"
    [class.lg-breadcrumb__element--active]="_activeIndex === index"
    #breadcrumb
    *ngFor="let element of _state; let index = index"
    (click)="_showMenu($event, index, breadcrumb)"
>
    <div class="lg-breadcrumb__arrow">
        <lg-icon icon="icon-arrow-down"></lg-icon>
    </div>
    <div
        class="lg-breadcrumb__element__text"
        [class.lg-breadcrumb__element__text--narrowed]="
            isNarrowedChildren(index) ?? element.narrowed
        "
    >
        {{ (element.namePrefix || "") + element.name }}
    </div>
</div>
