const ID_PREFIX = "lgLoadScript_";

export function loadScript(url: string, disableCache: boolean, reload = false): Promise<boolean> {
    if (!reload && scriptAlreadyLoaded(url)) {
        return new Promise(resolve => {
            resolve(false);
        });
    }

    const cacheQueryParam = disableCache ? `?q=${new Date().getTime()}` : "";
    const src = url + cacheQueryParam;

    return new Promise((resolve, reject) => {
        const body = <HTMLDivElement>document.body;
        const script = document.createElement("script");
        script.innerHTML = "";
        script.src = src;
        script.id = `${ID_PREFIX}${url}`;
        script.type = "text/javascript";
        script.async = true;
        script.defer = true;
        script.onload = () => resolve(true);
        script.onerror = () => reject();
        body.appendChild(script);
    });
}

function scriptAlreadyLoaded(url: string): boolean {
    return !!document.querySelector(`script[id="${ID_PREFIX}${url}"`);
}
