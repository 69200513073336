import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { DebugEventType } from "./debug-event";
import { filter } from "rxjs/operators";

export interface IDebugNotification {
    source: string;
    methodName: DebugEventType;
    args: any;
}

/**
 * Service for notifying about console events
 * (fka ExceptionsService).
 */
@Injectable({ providedIn: "root" })
export class DebugNotificationService {
    private _notification$ = new BehaviorSubject<IDebugNotification | null>(null);

    clear(): void {
        this._notification$.next(null);
    }

    listen$(type?: DebugEventType): Observable<IDebugNotification> {
        return this._notification$
            .asObservable()
            .pipe(
                filter(
                    notification => !!notification && (!type || notification.methodName === type)
                )
            ) as Observable<IDebugNotification>;
    }

    notify(notification: IDebugNotification): void {
        this._notification$.next(notification);
    }
}
