<div class="lg-sidebar-dev-widget__version">ID: {{ _versionId }}</div>
<div class="lg-sidebar-dev-widget__hospital" title="{{ userInfo.ziekenhuisnaam }}">
    <div>
        {{ userInfo.ziekenhuiscode }}
    </div>
    <div>
        {{ userInfo.ziekenhuisnaam }}
    </div>
</div>
<div class="lg-sidebar-dev-widget__indicators">
    <div class="lg-sidebar-dev-widget__indicators--environment">
        <div
            [class.lg-sidebar-dev-widget__indicators--environment_dev]="_testMachine === 'DEV'"
            [class.lg-sidebar-dev-widget__indicators--environment_test]="_testMachine === 'TEST'"
            [class.lg-sidebar-dev-widget__indicators--environment_exec]="_testMachine === 'EXEC'"
        >
            {{ _testMachine }}
        </div>
    </div>
    <div class="lg-sidebar-dev-widget__indicators--digest">
        <lg-digest-indicator></lg-digest-indicator>
    </div>
</div>
