import ldFind from "lodash-es/find";
import ldIsArray from "lodash-es/isArray";
import ldIsNumber from "lodash-es/isNumber";
import ldIsString from "lodash-es/isString";
import ldClone from "lodash-es/clone";
import ldSortBy from "lodash-es/sortBy";
import ldMap from "lodash-es/map";
import { ChangeDetectionStrategy, Component } from "@angular/core";

import { LgTranslateService } from "@logex/framework/lg-localization";
import * as Pivots from "@logex/framework/lg-pivot";
import { IDropdownDefinition } from "@logex/framework/ui-core";
import { IFilterOption } from "@logex/framework/types";
import { IFilterExportDefinition } from "@logex/framework/lg-exports";

import type { IFilterDefinition } from "../filter-definition";
import type { IFilterRenderer, IFilterRendererFactory } from "../filter-renderer";
import { FilterRendererComponentBase } from "../filter-renderer-component-base";
import type { LgFilterSet } from "../lg-filterset";
import { ComponentType } from "@angular/cdk/portal";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export interface IDropdownFilterDefinition<T> extends IFilterDefinition {
    filterType: "dropdown";
    /**
     * Name of the "Off" state. Defaults to "-"
     */
    emptyOption?: string;

    /**
     * Translation id for empty option. Note that emptyOption has precedence
     */
    emptyOptionLC?: string;

    /**
     * The function that should return the currently available selections for the filter. Can be replaced by
     * sourceDefinition/sourceDefinitionBase.
     *
     * The function can either return a dictionary of strings which will be used directly (key=id, value=name),
     * or array identical to the one used by the combo filter. This makes it possible to use the usual gather
     * functions from logex pivot
     *
     * Note that if you're returning the array if {id:, name:} objects (IFilterOption), you can include extra field "icon"
     * to show for that item (this is class name if icon-16 type icon)
     */
    source: Pivots.IGatherFilterFactoryResult | (() => Record<string, string>);
}
// Dropdown renderer -----------------------------------------------------------------------------------------------
/**
 * Renderer for simple filter based on dropdown (with quick search), and possibility to be off. See IDropdownFilterDefinition
 * for additional configuration options.
 * The "off" state is indicated by null value of the filter.
 *
 * Note: for technical reasons, the "off" value can be temporarily stored as "null" or "". The onChange event won't be
 * triggered before this is normalized, so this is issue only if you're watching on the filter value (which you shouldn't)
 */
export class DropdownFilterRenderer<T> implements IFilterRenderer {
    dropdownDefinition: IDropdownDefinition<T>;
    protected fakeDefinition = true;

    constructor(
        private _definition: IDropdownFilterDefinition<T>,
        private _filters: any,
        lgTranslate: LgTranslateService
    ) {
        if (!this._definition.source) {
            throw Error(`Source definition for filter "${this._definition.id}" is not found.`);
        }

        if (!_definition.emptyOption) {
            _definition.emptyOption = lgTranslate.translate(
                _definition.emptyOptionLC || "FW._Directives.DropdownFilterRenderer__empty"
            );
        }

        this.dropdownDefinition = {
            groups: [{ id: null, name: this._definition.emptyOption }],
            entryId: "id",
            entryName: "name",
            empty: this._definition.emptyOption
        };
    }

    createStorage(): void {
        if (this._filters[this._definition.storage!] === undefined) {
            this._filters[this._definition.storage!] = null;
        }
    }

    active(): boolean {
        return this._filters[this._definition.storage!] != null;
    }

    previewVisible(): boolean {
        return this._filters[this._definition.storage!] != null;
    }

    clear(): boolean {
        if (this._filters[this._definition.storage!] != null) {
            this._filters[this._definition.storage!] = null;
            return true;
        }
        return false;
    }

    private _ensureDefinition(): void {
        if (
            this.dropdownDefinition == null ||
            (this.fakeDefinition && this._filters[this._definition.storage!] != null)
        ) {
            this.prepareDefinition();
        }
    }

    getFilterLineComponent(): ComponentType<FilterRendererComponentBase<any, any>> {
        this._ensureDefinition();
        return DropdownFilterRendererLineComponent;
    }

    getPopupComponent(): ComponentType<FilterRendererComponentBase<any, any>> {
        this._ensureDefinition();
        return DropdownFilterRendererPopupComponent;
    }

    select(value: T | null): void {
        if ((value as any) === "" || (value as any) === "null") {
            value = null;
        }
        this._filters[this._definition.storage!] = value;
    }

    prepareDefinition(): void {
        const source = this._definition.source();
        let sourceArray: IFilterOption[];

        if (!ldIsArray(source)) {
            sourceArray = ldSortBy(
                ldMap(<Record<string, string>>source, function (e, k) {
                    return {
                        id: k,
                        name: e
                    };
                })
            );
        } else {
            sourceArray = <IFilterOption[]>ldClone(source);
            // convert any potential string icons into in-place definitions
            sourceArray.forEach((e: any) => {
                const icon = e.icon;
                if (icon && ldIsString(icon)) {
                    e.icon = { icon, inCurrent: true, clickable: false };
                }
            });
        }
        sourceArray.unshift({ id: "null", name: this._definition.emptyOption! });
        this.dropdownDefinition = {
            groups: [{ entries: sourceArray }],
            entryId: "id",
            entryName: "name",
            iconName: "icon",
            empty: this._definition.emptyOption
        };
        this.fakeDefinition = false;
    }

    prepareDefinitionBound = this.prepareDefinition.bind(this);

    getExportDefinition(): IFilterExportDefinition {
        return {
            name: this._definition.name ?? "",
            activeFn: () => this.active(),
            exportFn: () => {
                if (this.fakeDefinition) this.prepareDefinition();
                const source = this.dropdownDefinition.groups![0].entries;
                if (source == null || source.length === 0) {
                    return ["???"];
                }
                const value = this._filters[this._definition.storage!];
                const item: any = ldFind(source, (entry: any) => entry.id === value);
                return [item ? item.name : "???"];
            }
        };
    }

    serialize(): string | null {
        if (!this.active()) return null;
        return "" + this._filters[this._definition.storage!];
    }

    deserialize(state: string): boolean {
        if (!ldIsString(state) && !ldIsNumber(state)) return false;

        const oldState = this._filters[this._definition.storage!];
        this._filters[this._definition.storage!] = state;
        return state !== oldState;
    }
}

// Factory ---------------------------------------------------------------------------------------------------------
export class DropdownFilterRendererFactory<T> implements IFilterRendererFactory {
    readonly name: string = "dropdown";

    create(
        definition: IDropdownFilterDefinition<T>,
        filters: Record<string, any>,
        _definitions: IFilterDefinition[],
        filterSet: LgFilterSet
    ): IFilterRenderer {
        return new DropdownFilterRenderer(definition, filters, filterSet.lgTranslate);
    }
}

// Line template  --------------------------------------------------------------------------------------------------
@Component({
    selector: "lg-dropdown-filter-renderer-line",
    // eslint-disable-next-line @angular-eslint/component-max-inline-declarations
    template: `
        <label>{{ _definition.label }}:</label>
        <div class="control">
            <lg-dropdown
                [definition]="_renderer.dropdownDefinition"
                [current]="_filters[_definition.storage!]"
                [preSelect]="_renderer.prepareDefinitionBound"
                (currentChange)="_change($event)"
                [emptyAcceptable]="true"
            ></lg-dropdown>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DropdownFilterRendererLineComponent<T> extends FilterRendererComponentBase<
    IDropdownFilterDefinition<T>,
    DropdownFilterRenderer<T>
> {
    _change(value: T): void {
        this._renderer.select(value);
        this._triggerChange();
    }
}

// Popup template --------------------------------------------------------------------------------------------------
@Component({
    selector: "lg-dropdown-filter-renderer-popup",
    // eslint-disable-next-line @angular-eslint/component-max-inline-declarations
    template: `
        <div class="header">
            {{ "FW._Directives.DropdownFilterRenderer_Popup_title" | lgTranslate }}:
            {{ _definition.name }}
            <div
                class="icon-16 icon-16-erase"
                title="{{ 'FW._Directives.DropdownFilterRenderer_Clear_selections' | lgTranslate }}"
                (click)="this._clear()"
            ></div>
        </div>
        <lg-dropdown
            [definition]="_renderer.dropdownDefinition"
            [current]="_filters[_definition.storage!]"
            [preSelect]="_renderer.prepareDefinitionBound"
            (currentChange)="_change($event)"
            [emptyAcceptable]="true"
        ></lg-dropdown>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DropdownFilterRendererPopupComponent<T> extends FilterRendererComponentBase<
    IDropdownFilterDefinition<T>,
    DropdownFilterRenderer<T>
> {
    _change(value: T): void {
        this._renderer.select(value);
        this._triggerChange();
    }
}
