import ldIsEqual from "lodash-es/isEqual";
import ldRange from "lodash-es/range";

import {
    FormStyle,
    getLocaleMonthNames,
    NgClass,
    NgForOf,
    NgIf,
    TranslationWidth
} from "@angular/common";
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    inject,
    LOCALE_ID,
    NgZone,
    OnDestroy,
    QueryList,
    ViewChildren,
    ViewEncapsulation
} from "@angular/core";
import { ESCAPE } from "@angular/cdk/keycodes";
import { BehaviorSubject, fromEvent, Observable, Subject } from "rxjs";
import { take, takeUntil, takeWhile } from "rxjs/operators";
import classNames from "classnames";

import { LgTranslatePipe, useTranslationNamespace } from "@logex/framework/lg-localization";
import { clamp } from "@logex/framework/utilities";

import { MonthRange, MonthRangeConfiguration } from "./lg-month-range-selector.types";
import { LgCapitalizePipe, LgRemoveSymbolPipe } from "../../pipes";

export const FIRST_MONTH = 1;
export const LAST_MONTH = 12;
export const WHOLE_YEAR: () => MonthRange = () => ({ from: FIRST_MONTH, to: LAST_MONTH });

@Component({
    standalone: true,
    selector: "lg-month-range-selector-popup",
    templateUrl: "./lg-month-range-selector-popup.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    imports: [LgTranslatePipe, LgCapitalizePipe, LgRemoveSymbolPipe, NgClass, NgIf, NgForOf],
    viewProviders: [useTranslationNamespace("FW._Dialogs._EditComments")]
})
export class LgMonthRangeSelectorPopupComponent implements OnDestroy {
    private _changeDetector = inject(ChangeDetectorRef);
    private _locale = inject(LOCALE_ID);
    private _ngZone = inject(NgZone);

    @ViewChildren("month") months?: QueryList<ElementRef>;

    @HostListener("document:keydown", ["$event.keyCode"])
    _onEsc(keyCode: number): boolean {
        if (keyCode === ESCAPE) {
            this._setRange(this._config.initialRange);
            return false;
        }
        return true;
    }

    _initialized: boolean;
    _months: number[] = [];
    _shortNames: string[] = [];
    _names: string[] = [];
    _dragging = false;
    _hover = true;
    _selectedRange?: MonthRange | undefined;

    private _config!: MonthRangeConfiguration;
    private _current?: MonthRange | undefined;
    private _selectedRange$ = new BehaviorSubject<MonthRange | undefined>(undefined);
    private _destroyed$ = new Subject<void>();

    constructor() {
        this._initialized = false;
    }

    initialize(config: MonthRangeConfiguration): Observable<MonthRange | undefined> {
        this._config = config;

        if (!config.initialRange) {
            config.initialRange = WHOLE_YEAR();
        }
        this._selectedRange$ = new BehaviorSubject<MonthRange | undefined>(config.initialRange);

        this._selectedRange$.pipe(takeUntil(this._destroyed$)).subscribe(range => {
            this._selectedRange = range;
        });

        this._names = [
            "",
            ...getLocaleMonthNames(this._locale, FormStyle.Format, TranslationWidth.Wide)
        ];
        this._shortNames = [
            "",
            ...getLocaleMonthNames(this._locale, FormStyle.Format, TranslationWidth.Abbreviated)
        ];

        const zeroIndexedMonths = ldRange(0, 12);
        this._months = zeroIndexedMonths.map(x => (x + config.startMonth!) % 12 || 12);

        this._initialized = true;
        return this._selectedRange$.asObservable();
    }

    ngOnDestroy(): void {
        this._destroyed$.next();
        this._destroyed$.complete();
    }

    _onMouseDown(m: number): boolean {
        this._dragging = true;

        const monthRects = this._getMonthRects();

        this._ngZone.runOutsideAngular(() => {
            fromEvent<MouseEvent>(document, "mousemove")
                .pipe(
                    takeUntil(this._destroyed$),
                    takeWhile(() => this._dragging)
                )
                .subscribe((event2: MouseEvent) => {
                    this._updateValue(m, event2, monthRects);
                    return false;
                });
        });

        fromEvent<MouseEvent>(document, "mouseup")
            .pipe(takeUntil(this._destroyed$), take(1))
            .subscribe((event2: MouseEvent) => {
                this._dragging = false;

                if (!this._current) {
                    this._onClick(event2);
                } else {
                    this._setRange(this._current);
                }
                this._changeDetector.markForCheck();

                return false;
            });

        return false;
    }

    _onMouseOver(event: MouseEvent, m: number): void {
        this._hover = true;
        const monthRects = this._getMonthRects();
        this._updateValue(m, event, monthRects);
    }

    _onMouseOut(event: MouseEvent, m: number): void {
        this._hover = false;
        const monthRects = this._getMonthRects();
        this._updateValue(m, event, monthRects);
    }

    _onClick(event: MouseEvent): boolean {
        event.stopPropagation();
        event.preventDefault();

        const monthRects = this._getMonthRects();
        const index = this._getCurrentMonthIndex(event.clientX, monthRects);

        this._setRange({ from: index, to: index });

        return false;
    }

    _selectAll(): void {
        this._setRange(WHOLE_YEAR());
    }

    _className(index: number): string {
        const classes = classNames(
            {
                "lg-month-range-selector-popup__month--selected":
                    this._selectedRange &&
                    index + 1 >= this._selectedRange.from &&
                    index + 1 <= this._selectedRange.to
            },
            {
                "lg-month-range-selector-popup__month--selected_first":
                    this._selectedRange && index + 1 === this._selectedRange.from
            },
            {
                "lg-month-range-selector-popup__month--selected_last":
                    this._selectedRange && index + 1 === this._selectedRange.to
            },
            {
                "lg-month-range-selector-popup__month--selected_inactive":
                    this._selectedRange &&
                    index + 1 >= this._selectedRange.from &&
                    index + 1 <= this._selectedRange.to &&
                    this._dragging
            },
            {
                "lg-month-range-selector-popup__month--active":
                    this._current &&
                    index + 1 >= this._current.from &&
                    index + 1 <= this._current.to &&
                    (this._dragging || this._hover)
            },
            {
                "lg-month-range-selector-popup__month--active_dragging":
                    this._current &&
                    index + 1 >= this._current.from &&
                    index + 1 <= this._current.to &&
                    this._dragging
            },
            {
                "lg-month-range-selector-popup__month--active_first":
                    this._current && index + 1 === this._current.from
            },
            {
                "lg-month-range-selector-popup__month--active_last":
                    this._current && index + 1 === this._current.to
            },
            {
                "lg-month-range-selector-popup__month--forecast":
                    index + 1 > this._config.firstIntervalEnd!
            },
            {
                "lg-month-range-selector-popup__month--forecast_selected":
                    this._selectedRange &&
                    index + 1 > this._config.firstIntervalEnd! &&
                    index + 1 >= this._selectedRange.from &&
                    index + 1 <= this._selectedRange.to
            },
            {
                "lg-month-range-selector-popup__month--forecast_active":
                    index + 1 > this._config.firstIntervalEnd! &&
                    this._current &&
                    index + 1 >= this._current.from &&
                    index + 1 <= this._current.to &&
                    (this._dragging || this._hover)
            }
        );
        return classes;
    }

    _getMonthTooltip(currentMonth: number): string {
        const year: number =
            currentMonth - this._config.startMonth! > 0
                ? this._config.year!
                : this._config.year! - 1;

        currentMonth = (currentMonth + this._config.startMonth! - 1) % 12 || 12;

        switch (this._config.tooltipDisplayType) {
            case "month":
                return this._names[currentMonth];
            case "date":
                return `${this._names[currentMonth]} ` + year;
            case "full":
                if (
                    !this._config.firstIntervalEnd ||
                    !this._config.firstIntervalName ||
                    !this._config.secondIntervalName
                )
                    return `${this._names[currentMonth]} ` + year;
                return (
                    `${this._names[currentMonth]} ` +
                    year +
                    ` (${
                        currentMonth <= this._config.firstIntervalEnd
                            ? this._config.firstIntervalName
                            : this._config.secondIntervalName
                    })`
                );
            default:
                return this._names[currentMonth];
        }
    }

    private _updateValue(initial: number, event: MouseEvent, monthRects: ClientRect[]): void {
        this._ngZone.run(() => {
            const index = this._getCurrentMonthIndex(event.clientX, monthRects);

            this._current = { from: Math.min(initial, index), to: Math.max(initial, index) };
            this._changeDetector.markForCheck();
        });
    }

    private _getCurrentMonthIndex(currentCursorX: number, monthRects: ClientRect[]): number {
        return (
            monthRects.findIndex(
                rect => rect.left <= currentCursorX && currentCursorX <= rect.right
            ) + 1
        );
    }

    private _getMonthRects(): ClientRect[] {
        return this.months?.map(x => x.nativeElement.getBoundingClientRect() as ClientRect) ?? [];
    }

    private _setRange(range?: MonthRange): void {
        if (range) {
            this._emitRange(this._clampRange(range));
        } else {
            this._emitRange(WHOLE_YEAR());
        }
        this._current = undefined;
    }

    private _emitRange(range: MonthRange): void {
        if (ldIsEqual(this._selectedRange$.getValue(), range)) {
            return;
        }

        this._selectedRange$.next(range);
    }

    private _clampRange(range: MonthRange): MonthRange {
        return {
            from: clamp(range.from, FIRST_MONTH, LAST_MONTH),
            to: clamp(range.to, FIRST_MONTH, LAST_MONTH)
        };
    }
}
