/**
 * Make the element temporary rendered (display:block), albeit invisible (visibility:hidden) to allow better measurement of dimensions.
 * Inspired by jQuery, but simplified - let's hope it suffices
 *
 * @param element is the target element
 * @param callback  will be called while the element is visible
 */
export function makeVisibleForMeasurements<T>(
    element: HTMLElement,
    callback: (el: HTMLElement) => T
): T {
    const oldDisplay = element.style.display;
    const oldVisibility = element.style.visibility;
    const oldPosition = element.style.position;
    element.style.display = "block";
    element.style.visibility = "hidden";
    element.style.position = "absolute";
    const result: T = callback(element);
    element.style.display = oldDisplay;
    element.style.visibility = oldVisibility;
    element.style.position = oldPosition;
    return result;
}
