import { IVersion } from "./version-service";

let version: IVersion;

export const LgVersionModel = {
    get version(): IVersion {
        return version;
    },
    set version(val: IVersion) {
        version = val;
    }
};
