import { ChangeDetectionStrategy, Component, Injectable, inject } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Subject } from "rxjs";
import { first, takeUntil } from "rxjs/operators";

import { LgLocalizationModule, LgTranslateService } from "@logex/framework/lg-localization";
import { LgButtonComponent } from "../lg-button.component";

import { DialogType, IDialogComponent } from "../../lg-dialog/lg-dialog.types";
import { getDialogFactoryBase } from "../../lg-dialog/lg-dialog-factory";
import { LgDialogRef } from "../../lg-dialog/lg-dialog-ref";
import { IPromptDialogOptions, LgPromptDialogButton } from "./lg-prompt-dialog.types";

const OK_BUTTON: LgPromptDialogButton = {
    id: "ok",
    nameLc: "FW._Directives.DialogService_Button_OK",
    class: "button--primary",
    isConfirmAction: true
};

const CANCEL_BUTTON: LgPromptDialogButton = {
    id: "cancel",
    nameLc: "FW._Directives.DialogService_Button_Cancel",
    isCancelAction: true
};

const CLOSE_BUTTON: LgPromptDialogButton = {
    id: "close",
    nameLc: "FW.CLOSE",
    isCancelAction: true
};

@Component({
    selector: "lg-prompt-dialog",
    templateUrl: "./lg-prompt-dialog.component.html",
    imports: [CommonModule, LgButtonComponent, LgLocalizationModule],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LgPromptDialogComponent implements IDialogComponent<LgPromptDialogComponent> {
    private _lgTranslate = inject(LgTranslateService);
    private _dialogRef = inject(LgDialogRef<LgPromptDialogComponent, string>);

    _options!: IPromptDialogOptions;
    _message!: string;

    _title!: string;
    _dialogType?: DialogType;
    _dialogClass?: string;
    _icon?: string;
    _closeOnEsc = false;
    _forceCloseOnNavigation?: boolean;
    _closeOnOverlayClick?: boolean;
    _allowClose = (): boolean => !!this._options.allowClose;
    _allowMaximize = (): boolean => !!this._options.allowMaximize;

    private _fired = false;
    private _resolve!: (actionId: string) => void;
    private _reject!: (actionId: string) => void;
    private readonly _destroyed$ = new Subject<void>();

    /**
     * Shows an information dialog with the specified message. The dialog contains only OK button (though it is possible to override it)
     *
     * @param title is the title of the window
     * @param message is the message to show
     * @param options specifies additional options (when necessary), such as the button texts
     * @returns promise that resolves with the id of the button pressed.
     */
    public alert(title: string, message: string, options?: IPromptDialogOptions): Promise<string> {
        options = {
            titleIcon: "icon-warning",
            buttons: [OK_BUTTON],
            ...options,
            dialogType: "alert"
        };

        return this._confirm(title, message, options);
    }

    public alertLc(
        titleLc: string,
        messageLc: string,
        options?: IPromptDialogOptions
    ): Promise<string> {
        return this.alert(
            this._lgTranslate.translate(titleLc, options?.localizationContext),
            this._lgTranslate.translate(messageLc, options?.localizationContext),
            options
        );
    }

    public warning(
        title: string,
        message: string,
        options?: IPromptDialogOptions
    ): Promise<string> {
        options = {
            titleIcon: "icon-warning",
            buttons: [OK_BUTTON],
            ...options,
            dialogType: "warning"
        };

        return this._confirm(title, message, options);
    }

    public warningLc(
        titleLc: string,
        messageLc: string,
        options?: IPromptDialogOptions
    ): Promise<string> {
        return this.warning(
            this._lgTranslate.translate(titleLc, options?.localizationContext),
            this._lgTranslate.translate(messageLc, options?.localizationContext),
            options
        );
    }

    public info(title: string, message: string, options?: IPromptDialogOptions): Promise<string> {
        options = {
            allowClose: false,
            buttons: [],
            ...options
        };

        return this._confirm(title, message, options);
    }

    public infoLc(
        titleLc: string,
        messageLc: string,
        options: IPromptDialogOptions = {}
    ): Promise<string> {
        return this.info(
            this._lgTranslate.translate(titleLc, options?.localizationContext),
            this._lgTranslate.translate(messageLc, options?.localizationContext),
            options
        );
    }

    /**
     * Shows a confirmation dialog with the specified big icon and message. The dialog can contain up to three buttons (in which case
     * they're grouped as 2 + 1). By default, a warning-type dialog with OK/cancel buttons is shown
     *
     * @param title is the title of the window
     * @param message is the message to show
     * @param options specifies additional options (when necessary), such as the button texts
     * @returns promise that resolves with the id of the button pressed. Closed dialog (where allowed) will resolve as button id that has isCancelAction specfied.
     *      if button2AsReject is specified in the options, the button 2 will result in reject instead.
     */
    public confirm(
        title: string,
        message: string,
        options: IPromptDialogOptions = {}
    ): Promise<string> {
        options = {
            buttons: [OK_BUTTON, CANCEL_BUTTON],
            ...options,
            dialogType: "normal"
        };

        return this._confirm(title, message, options);
    }

    public confirmLc(
        titleLc: string,
        messageLc: string,
        options?: IPromptDialogOptions
    ): Promise<string> {
        return this.confirm(
            this._lgTranslate.translate(titleLc, options?.localizationContext),
            this._lgTranslate.translate(messageLc, options?.localizationContext),
            options
        );
    }

    private _confirm(
        title: string,
        message: string,
        options: IPromptDialogOptions = {}
    ): Promise<string> {
        this._translateButtons(options);
        this._options = options;

        this._title = title;
        this._message = message;

        this._dialogType = this._options.dialogType = options.dialogType ?? "normal";
        this._forceCloseOnNavigation = !!this._options.forceCloseOnNavigation;
        this._closeOnOverlayClick = !!this._options.closeOnOverlayClick;
        if (this._options.columns) {
            this._dialogClass = `lg-dialog lg-dialog--${this._options.columns}col`;
        }
        if (options.titleIcon) {
            this._icon = options.titleIcon;
        }

        if (!this._options.noKeyboard) {
            this._dialogRef
                .keydownEvents()
                .pipe(takeUntil(this._destroyed$))
                .subscribe(event => this._handleKeyboardEvent(event));
        }

        if (this._options.forceClick$) {
            this._options.forceClick$
                .pipe(first(), takeUntil(this._destroyed$))
                .subscribe(buttonId => this._handleForceClick(buttonId));
        }

        return this._getResultPromise();
    }

    public _onClose(): void {
        this._destroyed$.next();
        this._destroyed$.complete();
        if (!this._fired) {
            const cancelButton = this._options.buttons?.find(button => button.isCancelAction);
            if (cancelButton) {
                this._buttonClick(cancelButton);
            } else {
                this._buttonClick(CLOSE_BUTTON);
            }
        }
    }

    protected _buttonClick(button: LgPromptDialogButton): void {
        this._fired = true;
        this._dialogRef.close(button.id);

        if (button.isReject) {
            this._reject(button.id);
        } else {
            this._resolve(button.id);
        }
    }

    private _translateButtons(options: IPromptDialogOptions): void {
        options.buttons?.forEach(button => {
            if (button.nameLc) {
                button.name = this._lgTranslate.translate(
                    button.nameLc,
                    options.localizationContext
                );
            }
        });
    }

    private _getResultPromise(): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            this._resolve = resolve;
            this._reject = reject;
        });
    }

    private _handleForceClick(buttonId: string | void | null): void {
        if (this._fired || !this._dialogRef.visible) return;

        if (typeof buttonId === "string") {
            const targetButton = this._options.buttons?.find(button => button.id === buttonId);
            if (targetButton) {
                this._buttonClick(targetButton);
            }
        } else {
            this._fired = true;
            this._dialogRef.close();
        }
    }

    private _handleKeyboardEvent(event: KeyboardEvent): void {
        if (event.key === "Escape") {
            const cancelButton = this._options.buttons?.find(button => button.isCancelAction);
            if (cancelButton != null) {
                this._buttonClick(cancelButton);
            } else {
                this._buttonClick(CLOSE_BUTTON);
            }
            event.stopPropagation();
            event.preventDefault();
            return;
        }

        if (event.key === "Enter" || event.key === " ") {
            const confirmButton = this._options.buttons?.find(button => button.isConfirmAction);
            const firstButton = this._options.buttons?.[0];
            if (confirmButton) {
                this._buttonClick(confirmButton);
            } else if (firstButton != null && !firstButton.isCancelAction) {
                this._buttonClick(firstButton);
            }
            event.stopPropagation();
            event.preventDefault();
        }
    }
}

// ---------------------------------------------------------------------------------------------
//  Factory
@Injectable({ providedIn: "root" })
export class LgPromptDialog extends getDialogFactoryBase(
    LgPromptDialogComponent,
    "alert",
    "confirm",
    "alertLc",
    "confirmLc",
    "warning",
    "warningLc",
    "info",
    "infoLc"
) {
    static readonly OK_BUTTON = OK_BUTTON;
    static readonly CANCEL_BUTTON = CANCEL_BUTTON;
    static readonly CLOSE_BUTTON = CLOSE_BUTTON;
}
