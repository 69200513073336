import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    inject,
    Input,
    OnDestroy,
    Output,
    ViewEncapsulation
} from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { toInteger } from "@logex/framework/utilities";
import { LgContentGridComponent } from "./lg-content-grid.component";

@Component({
    standalone: false,
    selector: "lg-content-grid-group",
    template: ` <ng-content *ngIf="_visible"></ng-content> `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        "[class]": "_class",
        "[hidden]": "!_visible"
    },
    encapsulation: ViewEncapsulation.None
})
export class LgContentGridGroupComponent implements OnDestroy {
    private _changeDetectorRef = inject(ChangeDetectorRef);
    private _elementRef = inject(ElementRef);
    private _grid = inject(LgContentGridComponent);

    /**
     * Grid group identifier (required).
     */
    @Input({ required: true }) id!: string;

    /**
     * Content size
     *  - If number then size in grid columns count is set (integers [1; 12] are valid)
     *  - If null or 'auto' then auto size within grid
     */
    @Input() set size(val: number | null | "auto") {
        if (val === "auto" || val === null) {
            this._size = null;
        } else {
            this._size = toInteger(val, 1, 1, 12);
        }
        this._updateClass();
    }

    get size(): number | null {
        return this._size;
    }

    @Output() readonly maximizedItemIdChange = new EventEmitter<string | null>();

    public get maximizedItemId(): string | null {
        return this._maximizedItemId;
    }

    @Output("visibilityChange") readonly groupVisibilityChange = new EventEmitter<boolean>();

    public _visible = true;
    public _class = "";

    private readonly _destroyed$ = new Subject<void>();
    private _size: number | null = null;
    private _isMaximized = false;
    private _maximizedItemId: string | null = null;

    constructor() {
        this._grid
            .columnMajorChangeAsObservable()
            .pipe(takeUntil(this._destroyed$))
            .subscribe(() => {
                this._updateClass();
                this._changeDetectorRef.markForCheck();
            });

        this._grid.maximizedGroupIdChange.pipe(takeUntil(this._destroyed$)).subscribe(() => {
            this._checkMaximize();
            this._changeDetectorRef.markForCheck();
        });

        this._checkMaximize();
        this._updateClass();
    }

    public toggleMaximize(itemId: string, groupOnly: boolean): void {
        if (!this._visible) return;

        if (this._maximizedItemId === null || this._maximizedItemId !== itemId) {
            if (this._isMaximized !== !groupOnly) {
                this._grid.setMaximizedGroupId(this.id, !groupOnly, itemId);
            }
            this._maximizedItemId = itemId;
            this.maximizedItemIdChange.next(itemId);
        } else {
            if (this._isMaximized) this._grid.setMaximizedGroupId(this.id, false, itemId);
            this._maximizedItemId = null;
            this.maximizedItemIdChange.next(null);
        }
    }

    private _checkMaximize(): void {
        const maximizedId = this._grid.maximizedGroupId;
        const isMaximized = maximizedId === this.id;
        const visible = maximizedId === null || isMaximized;

        let triggerVisibilityChange = false;

        if (visible !== this._visible) {
            this._visible = visible;
            triggerVisibilityChange = true;
        }

        if (isMaximized !== this._isMaximized) {
            this._isMaximized = isMaximized;
            this._updateClass();
        }

        // Just when we have all other information updated
        if (triggerVisibilityChange) {
            this.groupVisibilityChange.next(visible);
        }
    }

    public ngOnDestroy(): void {
        this._destroyed$.next();
        this._destroyed$.complete();
    }

    private _updateClass(): void {
        const mainClass = this._grid.columnMajor ? "lgg__col" : "lgg__row";
        const size = this._isMaximized ? 12 : this._size;
        if (size === null) {
            this._class = mainClass;
        } else {
            this._class = mainClass + " " + mainClass + "--" + size;
        }
        // speedup the update
        this._elementRef.nativeElement.className = this._class;
    }
}
