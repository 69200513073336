import { inject, Injectable, OnDestroy } from "@angular/core";
import { bufferTime, EMPTY, exhaustMap, Subject } from "rxjs";
import { catchError, filter, map, takeUntil } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";

interface IErrorBatch {
    error_type: string;
    error_short: string;
    error_full: string;
    url: string;
    filename: string;
    line_no: number | null;
    column_no: number | null;
    additional_data?: string | null | undefined;
}

export interface ILgErrorHandlerGateway {
    logToServer(
        errorType: string,
        errorShort: string,
        errorFull: string,
        url: string,
        filename: string | null | undefined,
        lineNo: number | null | undefined,
        columnNo: number | null | undefined,
        additionalData?: string | null | undefined
    ): void;

    logToServerThrottled(
        errorType: string,
        errorShort: string,
        errorFull: string,
        url: string,
        filename: string,
        lineNo: number | null,
        columnNo: number | null,
        additionalData?: string | null
    ): void;
}

/**
 * Gateway for logging errors to the server.
 */
@Injectable({ providedIn: "root" })
export class LgErrorHandlerGateway implements ILgErrorHandlerGateway, OnDestroy {
    protected readonly _destroyed$ = new Subject<void>();
    protected _httpClient = inject(HttpClient);

    private _errorBatch$ = new Subject<IErrorBatch>();

    constructor() {
        this._listenErrorBatch();
    }

    ngOnDestroy(): void {
        this._destroyed$.next();
        this._destroyed$.complete();
    }

    logToServer(
        errorType: string,
        errorShort: string,
        errorFull: string,
        url: string,
        filename: string | null | undefined,
        lineNo: number | null | undefined,
        columnNo: number | null | undefined,
        additionalData?: string | null | undefined
    ): void {
        this._httpClient
            .post("api/log/error", {
                body: {
                    error_type: errorType,
                    error_short: errorShort,
                    error_full: errorFull,
                    url,
                    filename,
                    line_no: lineNo,
                    column_no: columnNo,
                    additional_data: additionalData
                }
            })
            .pipe(
                catchError(error => {
                    console.log(error);
                    return EMPTY;
                })
            )
            .subscribe();
    }

    logToServerThrottled(
        errorType: string,
        errorShort: string,
        errorFull: string,
        url: string,
        filename: string,
        lineNo: number | null,
        columnNo: number | null,
        additionalData?: string | null
    ): void {
        this._errorBatch$.next({
            error_type: errorType,
            error_short: errorShort,
            error_full: errorFull,
            url,
            filename,
            line_no: lineNo,
            column_no: columnNo,
            additional_data: additionalData
        });
    }

    private _listenErrorBatch(): void {
        this._errorBatch$
            .asObservable()
            .pipe(
                takeUntil(this._destroyed$),
                bufferTime(1000),
                filter(events => events.length > 0),
                map(events => (events.length > 10 ? events.slice(0, 10) : events)),
                exhaustMap(events => this._httpClient.post("api/log/error", { body: events }))
            )
            .subscribe();
    }
}
