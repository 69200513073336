/**
 * Attempts to convert the specified string to the primitive value it represents (number or boolean)
 *
 * @params value is the string to be converted
 * @returns the converted primitive type, or string if not possible
 */
export function toPrimitive(value: string): number | boolean | string | null | undefined {
    if (value == null) return value;
    // eslint-disable-next-line eqeqeq
    if (<any>value == +value) return +value;
    if (value === "true") return true;
    if (value === "false") return false;
    return value;
}
