<!-- seems like ng bug but 'defaultTemplate' is not found by ViewChildren if it's below the main div -->
<ng-template #defaultTemplate [lgChartTemplateContextType]="this" let-context>
    <ng-container *ngIf="context.data">
        <b>{{ context.data.column }}</b>
        <br /><br />
        {{ context.data.group }}: {{ this._numberFormat(context.data.value) }}
    </ng-container>
</ng-template>
<div class="lg-chart-area lg-chart-small-text">
    <div #chart [style.height.px]="height"></div>
    <div *ngIf="showLegend" [style.bottom]="showLegendOffset">
        <div class="entry" *ngFor="let item of _legend">
            <div class="entry__icon lg-chart-legend-icon" [style.background]="item.color"></div>
            <div class="entry__name">
                {{ item.name }}
            </div>
        </div>
        <div></div>
    </div>
</div>
