import { ChangeDetectorRef, ElementRef, inject, Renderer2 } from "@angular/core";
import { ControlValueAccessor } from "@angular/forms";

export abstract class ValueAccessorBase<T> implements ControlValueAccessor {
    protected _changeDetectorRef = inject(ChangeDetectorRef);
    protected _elementRef = inject(ElementRef);
    protected _renderer = inject(Renderer2);

    private _innerValue!: T;

    protected _changed!: (value: T) => void;
    protected _touched!: () => void;

    constructor(private readonly _supportsNullValues = false) {}

    get value(): T {
        return this._innerValue;
    }

    set value(value: T) {
        this._setValueImpl(value);
    }

    registerOnChange(fn: (value: T) => void): void {
        this._changed = fn;
    }

    registerOnTouched(fn: () => void): void {
        this._touched = fn;
    }

    touch(): void {
        if (this._touched) {
            this._touched();
        }
    }

    abstract writeValue(value: T): void;

    protected _setValueImpl(value: T): void {
        if (this._innerValue !== value) {
            this._innerValue = value;
            if (this._changed) {
                this._changed(value);
            }
        }
    }

    protected _writeValue(value: T): void {
        if (!this._supportsNullValues) {
            value = value == null ? ("" as any) : value;
        }
        this._innerValue = value;
        this._changeDetectorRef.markForCheck();
    }
}
