import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LgPrimitive } from "@logex/framework/types";
import { LgSwitchOption, LgSwitchMode } from "@logex/framework/ui-core";
import { LgTranslateService } from "@logex/framework/lg-localization";
import { IFilterExportDefinition } from "@logex/framework/lg-exports";

import { IFilterDefinition } from "../filter-definition";
import type { IFilterRenderer, IFilterRendererFactory } from "../filter-renderer";
import { FilterRendererComponentBase } from "../filter-renderer-component-base";
import type { LgFilterSet } from "../lg-filterset";
import { ComponentType } from "@angular/cdk/portal";

export interface ISwitchFilterDefinition extends IFilterDefinition {
    filterType: "switch";

    class: string;
    options: LgSwitchOption[];
    condensed: boolean;
    isBinary: boolean;
    mode: LgSwitchMode;
    sameWidthOptions: boolean;

    inactiveValue?: LgPrimitive;
    defaultValue: LgPrimitive;
}

export class SwitchFilterRenderer<T extends LgPrimitive> implements IFilterRenderer {
    constructor(
        private _definition: ISwitchFilterDefinition,
        private _filters: any,
        private _lgTranslate: LgTranslateService
    ) {
        this._definition.inactiveValue =
            this._definition.inactiveValue === undefined
                ? this._definition.defaultValue
                : this._definition.inactiveValue;
    }

    select(value: T | null): void {
        if ((value as any) === "" || (value as any) === "null") {
            value = null;
        }
        this._filters[this._definition.storage!] = value;
    }

    createStorage(): void {
        if (this._definition.storage && this._filters[this._definition.storage] === undefined) {
            this._filters[this._definition.storage] = null;
        }
    }

    clear(): boolean {
        if (this._definition.storage && this._filters[this._definition.storage] != null) {
            this._filters[this._definition.storage] = null;
            return true;
        }
        return false;
    }

    active(): boolean {
        return (
            !!this._definition.storage &&
            this._filters[this._definition.storage] !== this._definition.inactiveValue
        );
    }

    previewVisible(): boolean {
        return (
            !!this._definition.storage &&
            this._filters[this._definition.storage] !== this._definition.defaultValue
        );
    }

    getFilterLineComponent(): ComponentType<FilterRendererComponentBase<any, any>> {
        return SwitchFilterRendererLineComponent;
    }

    getExportDefinition(): IFilterExportDefinition {
        return {
            name: this._definition.name ?? "",
            activeFn: () => this.active(),
            exportFn: () => {
                const value = this._filters[this._definition.storage!];
                if (value == null) return [""];

                for (const o of this._definition.options) {
                    if (typeof o === "string") {
                        if (o === value) return [o];

                        continue;
                    }

                    if (o.value === value) {
                        return [o.label || this._lgTranslate.translate(o.labelLc ?? "")];
                    }
                }

                return [];
            }
        };
    }

    serialize(): string | null {
        if (!this.active()) return null;
        return "" + this._filters[this._definition.storage!];
    }

    deserialize(state: string): boolean {
        const oldState = this._filters[this._definition.storage!];
        this._filters[this._definition.storage!] = state;
        return state !== oldState;
    }
}

// Factory ---------------------------------------------------------------------------------------------------------
export class SwitchFilterRendererFactory<T extends LgPrimitive> implements IFilterRendererFactory {
    readonly name: string = "switch";

    create(
        definition: ISwitchFilterDefinition,
        filters: Record<string, any>,
        _definitions: IFilterDefinition[],
        filterSet: LgFilterSet
    ): IFilterRenderer {
        return new SwitchFilterRenderer<T>(definition, filters, filterSet.lgTranslate);
    }
}

// Line template  --------------------------------------------------------------------------------------------------
@Component({
    selector: "lg-switch-filter-renderer-line",
    // eslint-disable-next-line @angular-eslint/component-max-inline-declarations
    template: `
        <label>{{ _definition.label }}:</label>
        <div class="control">
            <lg-switch
                [class]="_definition.class"
                [options]="_definition.options"
                [condensed]="_definition.condensed"
                [isBinary]="_definition.isBinary"
                [mode]="_definition.mode"
                [sameWidthOptions]="_definition.sameWidthOptions"
                name="switchFilter"
                #switchFilter="ngModel"
                [ngModel]="_filters[_definition.storage!]"
                (ngModelChange)="_change($event)"
            >
            </lg-switch>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SwitchFilterRendererLineComponent<
    T extends LgPrimitive
> extends FilterRendererComponentBase<ISwitchFilterDefinition, SwitchFilterRenderer<T>> {
    _change(value: T): void {
        this._renderer.select(value);
        this._triggerChange();
    }
}
