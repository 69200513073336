import { ICleanedColumnFilter } from "@logex/framework/types";

/**
 * Filters the array of items using the preprocessed filters.
 *
 * @param tableArray are the items to be filtered
 * @filters is the preprocessed filter specification
 * @return the array if items fulfilling the criteria
 */
export function filterByColumnPrepared<T extends Record<string, string | number> = any>(
    tableArray: T[],
    preparedFilters: ICleanedColumnFilter[] | null
): T[] {
    if (preparedFilters == null) return tableArray;
    if (!tableArray) return tableArray;

    const result: T[] = [];
    const filterCount = preparedFilters.length;
    for (let i = 0, l = tableArray.length; i < l; ++i) {
        const value = tableArray[i];
        let include = true;
        for (let fIndex = 0; fIndex < filterCount; ++fIndex) {
            const filter = preparedFilters[fIndex];
            switch (filter.isFunction) {
                case true:
                    if (!filter.filter(value[filter.keyCleaned as keyof T], value)) {
                        include = false;
                        break;
                    }
                    break;
                case false:
                    if (!filter.filter[value[filter.keyCleaned]]) {
                        include = false;
                        break;
                    }
                    break;
            }
        }
        if (include) result.push(value);
    }
    return result;
}
