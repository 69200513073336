import { Injectable } from "@angular/core";
import { ILgLastFilterStorage } from "./last-filter-storage.types";

// TODO: consider moving to FADP library
@Injectable()
export class LgLastFilterStorageService implements ILgLastFilterStorage {
    private _state: Record<string, string> = {};

    get(page: string): Record<string, string> {
        // `page` is ignored in default implementation
        return this._state;
    }

    store(page: string, state: Record<string, string>): void {
        // `page` is ignored in default implementation
        this._state = state;
    }
}
